/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '@orientaction/utils';
import clsx from 'clsx';
import { TitleChapitre, Page } from '@orientaction/components';

const Page31 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={33}>
      <TitleChapitre nbr={4} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            QUELLE EST VOTRE VÉRITABLE <br />
            PERSONNALITÉ ?
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <div>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'justify', fontWeight: 'lighter' }}>
            Connaître votre véritable personnalité va vous permettre d’identifier les activités qui
            vous correspondent et dans lesquelles vous réussirez. Ce travail va vous demander
            d’être authentique, c’est-à-dire honnête et sincère vis-à-vis de vous-même.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Ne cherchez pas à donner une bonne image de vous, soyez juste vous-même
            en affirmant vos préférences. Ne pensez pas à votre façon de vous comporter
            aujourd’hui, mais plutôt à la façon dont vous aimeriez vous comporter si tout était
            possible, si le regard des autres n’existait pas.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={8}>
            <img className={classes.img} src={`${imgBook2}/illustration_page_41.jpg`} alt="logo" />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    }
  })
);
export default Page31;
