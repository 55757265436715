const INITIAL_STATE: any = {
    listMajorTest: [],
    loading: true,
};

const stateMajorTest = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'LISTMAJORTEST': {
            return {
                ...state,
                listMajorTest: action.payload,
            };
        }
        case 'LOADING': {
            return {
                ...state,
                loading: action.payload,
            };
        }
        default:
            return state;
    }
};

export default stateMajorTest;
