import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  infoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 75,
  },
  infoContent: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  itemContainer: {
    maringTop: 5,
    marginBottom: 5,
  },
  itemLabelFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    color: '#0E1247',
  },
  itemInfoFont: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#0E1247',
  },
}));
