/* eslint-disable no-use-before-define */
// eslint-disable-next-line no-use-before-define
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TablePaginationProps } from '@material-ui/core/TablePagination';
import FirstPage from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPage from '@material-ui/icons/LastPage';
import React, { FC } from 'react';

type TablePaginationActionsProps = {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
};

const TablePaginationActions: FC<TablePaginationActionsProps & TablePaginationProps> = props => {
  const classes = useStyles();
  const { count, page, rowsPerPage, onChangePage } = props;
  const interval = 2;
  const pageFirstShowed = 5;
  let pageCount = count / rowsPerPage;

  if (!Number.isInteger(pageCount)) {
    // eslint-disable-next-line
    pageCount = Math.floor(pageCount + 1);
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handlePageButtonClick = (event: React.MouseEvent<HTMLButtonElement>, newpage: number) => {
    onChangePage(event, newpage);
  };

  const getArrayPagination = () => {
    const pageModified = page || 0;
    let begin = pageModified <= interval ? 0 : pageModified - interval;
    let end = pageModified + interval >= pageCount ? pageCount : pageModified + interval + 1;
    if (pageModified <= interval) end = pageFirstShowed;
    if (pageModified + interval >= pageCount && pageCount >= pageFirstShowed)
      begin = pageCount - pageFirstShowed;
    return {
      begin,
      end,
    };
  };

  const arrayPagination = getArrayPagination();

  const paginationList = Array.from({ length: pageCount }, (v, k) => k).slice(
    arrayPagination.begin,
    arrayPagination.end
  );
  return (
    <div className={classes.paginationFooter}>
      <Button
        onClick={event => handlePageButtonClick(event, 0)}
        disabled={page === 0}
        aria-label="first page">
        <FirstPage />
      </Button>
      <Button onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </Button>

      {paginationList.map((e, i) => (
        <Button
          key={Math.random()}
          className={`button-page ${page === e ? 'active' : ''}`}
          onClick={event => handlePageButtonClick(event, e)}>
          {e + 1}
        </Button>
      ))}

      <Button
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        <KeyboardArrowRight />
      </Button>
      <Button
        onClick={event => handlePageButtonClick(event, pageCount - 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        <LastPage />
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paginationFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    marginBottom: 75,
    textAlign: 'right',
    '& button': {
      fontSize: 13,
      minWidth: 42,
      padding: 0,
      background: '#fff',
      marginLeft: 5,
      border: '1px solid #E3E8F0',
      '&-page': {
        padding: 0,
      },
      '&.active': {
        color: '#000A8C;',
        border: '1px solid #000A8C',
      },
      '&:hover': {
        color: '#000A8C;',
        border: '1px solid #000A8C',
      },
      '&:disabled': {
        display: 'none',
      },
    },
  },
}));

export default TablePaginationActions;
