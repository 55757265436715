import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FC } from 'react';
import IOSSwitch from './IosSwitch';

interface ISwitch {
  checked: boolean;
  disabled?: boolean;
  onChange: (e: any) => void;
  name?: string;
  label?: string;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  cy?: string;
}

const Switch: FC<ISwitch> = ({
  checked,
  disabled = false,
  onChange,
  name,
  label,
  labelPlacement,
  cy
}) => {
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name={name || ''}
          data-cy={`switch-${cy}`}
        />
      }
      label={label}
      labelPlacement={labelPlacement || 'end'}
    />
  );
};

export default Switch;
