/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, IconButton, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { FC } from 'react';
import { useStyles } from './style';
import clsx from 'clsx';


interface ISecondView {
    listPrincipaux: any[];
    handleOpenDrawer: (data: string) => (e: any) => void;
}

const SecondView: FC<ISecondView> = ({
    listPrincipaux, handleOpenDrawer
}) => {

    const classe = useStyles();

    return (

        <div className={classe.root}>

            <MarginContainer big={true}>

                <h1 className={classe.title}>
                    Découvrez ici vos trois besoins professionnels principaux
                </h1>

            </MarginContainer>

            <MarginContainer>

                <div
                    className={classe.container}
                >

                    {
                        listPrincipaux.map((item: any, index: number) =>

                            <div
                                className={clsx({
                                    [classe.content]: true,
                                    [classe.isFavorite]: index === 0,
                                })}
                                style={{
                                    borderColor: item.color
                                }}
                            >

                                <div>

                                    <div
                                        className={clsx({
                                            [classe.head]: true,
                                            [classe.isFavoriteHead]: index === 0,
                                            [classe.colorBlack]: item.key === 'eternity_pro',
                                        })}
                                        style={{
                                            backgroundColor: item.color
                                        }}
                                    >

                                        <Typography
                                            className={classe.titleHead}
                                        >
                                            {
                                                item.label || ""
                                            }
                                        </Typography>

                                        <Typography
                                            className={clsx({
                                                [classe.score]: true,
                                                [classe.scoreFavorite]: index === 0,
                                            })}
                                        >
                                            {
                                                item.score || 0
                                            }
                                        </Typography>

                                    </div>

                                    <img
                                        src={item.path}
                                        className={classe.img}
                                        alt="Pyramide"
                                    />

                                    <Grid
                                        container={true}
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <Grid>
                                            <IconButton
                                                className={classe.iconButton}
                                                onClick={handleOpenDrawer(item)}
                                            >
                                                <ArrowForwardIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>

                        )
                    }

                </div>
            </MarginContainer>

        </div>

    );
};

export default SecondView; 