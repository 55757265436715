import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import style from './style';

interface IProps {
  children: ReactNode;
  redirectUri: string;
  overrideStyle?: string;
}

const CardButton: FC<IProps> = ({ children, redirectUri, overrideStyle = '', ...rest }) => {
  const classes = style();

  const handleClick = async (e: any) => {
    e.preventDefault();
    window.open(redirectUri, '_blank');
    return true;
  };

  return (
    <Button
      {...rest}
      className={classNames(classes.cardButton, overrideStyle)}
      onClick={handleClick}>
      {children}
    </Button>
  );
};

export default CardButton;
