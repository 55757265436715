import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    number: {
        width: 40,
        height: 35,
        borderRadius: 40,
        textAlign: "center",
        fontSize: 30,
        color: "white",
        alignContent: "center",
        justifyContent: "center",
        backgroundColor: "yellow"
    },
    text: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '16px',
        color: '#2B56A5',
        display: 'inline-block',
        style: 'normal',
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            margin: '0 2vh'
        },
    },
    paragrapth: {
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
        fontSize: '15px',
        color: '#2B56A5',
        display: 'inline-block',
        style: 'normal',
        lineHeight: 1.5,
    },
    ParagrapthList: {
        padding: 10,
        marginTop: "10px",
        [theme.breakpoints.down('xs')]: {
            padding: 0
        },
    },
    titleUpCase: {
        fontSize: 20,
        fontFamily: "Arial",
        fontWeight: 600,
        color: "#2B56A5",
        display: "flex"
    },
    colorRed: {
        color: "red",
    },
    upString: {
        fontSize: 40,
        margin: 0,
        padding: 0,
    },
    page: {
        paddingTop: '75px',
        paddingBottom: '75px',
        paddingLeft: '100px',
        paddingRight: '100px',
        maxHeight: "1754px",
        maxWidth: '1240px',
        minHeight: '147vh',
        display: 'block',
        flex: 'none',
        color: '#2B56A5',
        order: 0,
        flexGrow: 0,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '75px',
            paddingBottom: '75px',
            paddingLeft: '75px',
            paddingRight: '75px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: "auto",
            minHeight: "auto",
            maxHeight: "none"
        },
    },
    pageNoTruncate: {
        paddingTop: '75px',
        paddingBottom: '75px',
        paddingLeft: '100px',
        paddingRight: '100px',
        maxWidth: '1240px',
        minHeight: '147vh',
        display: 'block',
        flex: 'none',
        color: '#2B56A5',
        order: 0,
        flexGrow: 0,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '75px',
            paddingBottom: '75px',
            paddingLeft: '75px',
            paddingRight: '75px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: "auto",
            minHeight: "auto",
            maxHeight: "none"
        },
    },
    titleChapitre: {
        width: "35%",
        height: "6vh",
        color: 'white',
        backgroundRepeat: "no-repeat",
        margin: "2vh 0"
    },
    simpleContent: {
        margin: '0',
        '@media only screen and (max-width: 600px)': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
        '@media only screen and (max-width: 361px)': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
    },
    textChapitre: {
        textAlign: 'center',
        top: "1.5vh",
        position: "relative",
        fontSize: "2.5vh"
    },
    number1: {
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        color: 'white',
        borderRadius: '20px',
        padding: '7px',
        background: '#fff350',
        [theme.breakpoints.down('sm')]: {
            border: '1px solid black',
            width: '30px',
            height: '25px',
            borderRadius: '30px',
        },
    },
    numberRomain: {
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        padding: '7px',
        background: '#2B56A5',
    },
    h3: {
        fontSize: '20px',
        color: '#2B56A5',
        fontWeight: 'bold',
    },
    h2: {
        fontSize: '27px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        marginLeft: '10px',
        color: '#2B56A5',
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
        height: 'auto',
        border: '0px !important'
    }
}));
