import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Add from '@material-ui/icons/Add';
import { FC } from 'react';
import OrientButton from '../../../commons/OrientButton';
import { publicSvgPath } from '../../../utils/constants';
import locale from './locale.json';
import style from './style';

const NoResult = () => {
  const cls = style();
  return (
    <Box className={cls.noResultContainer}>
      <Box className={cls.noResultImageContainer}>
        <img
          width="600"
          height="600"
          src={`${publicSvgPath}/flecheBackground.svg`}
          alt="no_result_img"
        />
      </Box>
      <Box className={cls.noResultTextContainer}>
        <Box>
          <h5 className={cls.noResultText}>
            {locale.noResultNoTest}
            <br />
            {locale.noResultDoNotWait}
          </h5>
        </Box>
        <OrientButton
          style={{ marginTop: 30 }}
          onClick={() =>
            window.open(
              'https://www.orientaction.com/test-soft-skills-competences-douces-qualites/',
              '_blank'
            )
          }>
          <Add />
          Faire le test
        </OrientButton>
      </Box>
    </Box>
  );
};

export default NoResult;
