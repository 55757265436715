import { Theme, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { publicLogoPath } from '../../utils/constants';
import style from './style';

const LayoutSimple = ({ children, isGradient, noPadding, showLogo, isWhite }: any) => {
  const history = useHistory();
  const cls = style();

  const LeftElementBar = ({ show }: any) => {
    if (show) {
      return (
        <div className={cls.logoContainer} onClick={() => history.push('/')}>
          <picture className={cls.logo}>
            <source srcSet={`${publicLogoPath}/e-orientaction.png`} />
            <img
              src={`${publicLogoPath}/e-orientaction.png`}
              alt="logo-orientation"
              className={cls.logoImg}
            />
          </picture>
          {/* <img className={cls.logo} src={`${publicLogoPath}/logo-orientaction.png`} alt="logo" /> */}
        </div>
      );
    }
    return (
      <Button
        className={cls.buttonBack}
        startIcon={<ArrowBackIcon />}
        onClick={() => history.goBack()}>
        Retour
      </Button>
    );
  };

  return (
    <div className={cls.layout}>
      <div
        className={clsx(
          cls.content,
          isGradient ? cls.gradientBG : isWhite ? cls.bgWhite : cls.gradientGrey,
          noPadding ? cls.noPadding : null
        )}>
        <AppBar position="sticky" color="transparent" elevation={0} className={cls.appBar}>
          <nav className={showLogo ? cls.navbar : cls.navbarWithoutLogo}>
            <LeftElementBar show={showLogo} />
          </nav>
        </AppBar>
        <div>{children}</div>
      </div>
    </div>
  );
};
LayoutSimple.defaultProps = {
  showLogo: true,
};
export default LayoutSimple;
