/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import withWidth from '@material-ui/core/withWidth';
import Timeline from '@material-ui/lab/Timeline';
import { CompassTestHistoryPerYear, Layout } from '@orientaction/components';
import CompassCard from './CompassCard';
import PersonalityCard from './PersonalityCard';
import style from './style';
import TalentsCard from './TalentsCard';
import ValueCard from './ValueCard';
import NeedsProCard from './NeedsProCard';
import Content from './Components/Content';
import { CircularProgress } from '@material-ui/core';
import { useCompassMenu } from '@orientaction/hooks';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { userService } from '@orientaction/services';
import { CompassUserContext, CompassUserProvider } from '@orientaction/Context';
import NeedsCardPersoPart from './NeedsCard/Perso';
import NeedsCardProPart from './NeedsCard/Pro';

const containingNeedsTestServiceIds = [3, 5];

const Restitution = () => {
  const { year }: any = useParams();

  const { userInfos } = useContext<any>(CompassUserContext);

  const classes = style();

  const { getMyData, loading, harmonyTestLastResult, user, token } = useCompassMenu();

  const getMe = async () => {
    const { data: userData } = await userService.getMe(token, year);
    localStorage.setItem(
      'userInfos',
      JSON.stringify({
        ...user,
        upper_hierarchy: userData.upper_hierarchy,
        rattachement: userData.rattachement,
        company_user_role: userData.company_user_role,
      }),
    );
  };

  useEffect(() => {
    // update user info on every first render
    if (year) {
      getMe();
      window.scrollTo(0, 0);
    }
  }, [year]);

  useEffect(() => {
    if (year && typeof +year === 'number') {
      getMyData();
    }
  }, [year]);

  // Non rattached users should see neutral version so they can buy
  const isNonRattachedBeneficiary = !userInfos?.rattachement;

  const canAccessNeeds =
    containingNeedsTestServiceIds.includes(userInfos?.service?.id) || isNonRattachedBeneficiary;

  const showAllTest = userInfos?.service?.testToShow === 'all';

  const showNeeds = showAllTest || userInfos?.service?.testToShow === 'needs';
  const showNeedsPro = showAllTest || userInfos?.service?.testToShow === 'needsPro';

  return (
    <CompassUserProvider>
      <Layout isaddBackground={true} noPadding={true} isNew={true} footer={false}>
        {loading ? (
          <div className={classes.contentLoader}>
            <CircularProgress className={classes.colorWhite} />
          </div>
        ) : (
          <div>
            <div className={classes.btnHistory}>
              <CompassTestHistoryPerYear />
            </div>

            <Timeline className={classes.timelineAlignLeft}>
              <Content
                title="Votre boussole Orientaction"
                overrideTitleStyle={classes.overrideTitleStyle}
                overrideChildrenStyle={classes.overrideChildrenStyle}
                dotStyleOverride={classes.dotStyleOverride}
                horizontalLineOverrideStyle={classes.horizontalLineOverrideStyle}
                to=""
              >
                <CompassCard />
              </Content>

              <Content
                title="Vos valeurs"
                to="meaning-value"
                overrideTitleStyle={classes.overrideSubtitleStyle}
                timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
                cardMarginBottomStyle={classes.cardMarginBottomStyle}
              >
                <ValueCard />
              </Content>
              {canAccessNeeds && showNeeds && (
                <>
                  <Content
                    title="Vos besoins personnels"
                    to="besoins"
                    overrideTitleStyle={classes.overrideSubtitleStyle}
                    timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
                    cardMarginBottomStyle={classes.cardMarginBottomStyle}
                  >
                    <NeedsCardPersoPart />
                  </Content>

                  <Content
                    title="Vos besoins professionnels"
                    to=""
                    overrideTitleStyle={classes.overrideSubtitleStyle}
                    timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
                    cardMarginBottomStyle={classes.cardMarginBottomStyle}
                  >
                    <NeedsCardProPart />
                  </Content>
                </>
              )}

              {showNeedsPro && (
                <Content
                  title="Vos besoins professionnels"
                  to="besoins-pro"
                  overrideTitleStyle={classes.overrideSubtitleStyle}
                  timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
                  cardMarginBottomStyle={classes.cardMarginBottomStyle}
                >
                  <NeedsProCard />
                </Content>
              )}

              <Content
                title="Votre personnalité"
                to="preference-personality"
                overrideTitleStyle={classes.overrideSubtitleStyle}
                timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
                cardMarginBottomStyle={classes.cardMarginBottomStyle}
              >
                <PersonalityCard />
              </Content>

              <Content
                title="Vos talents (Soft skills)"
                to="soft-skills"
                overrideTitleStyle={classes.overrideSubtitleStyle}
                timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
                cardMarginBottomStyle={classes.lastCardMarginBottomStyle}
              >
                <TalentsCard result={harmonyTestLastResult} loading={loading} />
              </Content>
            </Timeline>
          </div>
        )}
      </Layout>
    </CompassUserProvider>
  );
};

export default withWidth()(Restitution);
