import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import useStyle from './style';

interface ITitleNbr {
  number: number;
  text: string;
  isSmall?: boolean;
  isItalic?: boolean;
  textOverrideStyle?: any;
  puceContentOverrideStyle?: any;
  textContainerOverrideStyle?: any;
}

const TitleNbr: FC<ITitleNbr> = ({
  number,
  text,
  isSmall,
  isItalic,
  textOverrideStyle = undefined,
  puceContentOverrideStyle = undefined,
  textContainerOverrideStyle = undefined,
}) => {
  const classe = useStyle();

  return (
    <div className={classe.content}>
      <span
        className={
          puceContentOverrideStyle ? clsx(puceContentOverrideStyle, classe.number1) : classe.number1
        }>
        <strong>{number || 0}</strong>
      </span>
      <Typography
        style={{
          fontFamily: 'ITC Avant Garde Gothic Std Medium',
          fontWeight: 'lighter',
          textAlign: 'start',
          margin: '0 1vh',
        }}>
        <strong className={clsx(textContainerOverrideStyle, isSmall ? classe.small : classe.h3)}>
          <span className={isItalic ? classe.italic : textOverrideStyle}>{text || ''}</span>
        </strong>
      </Typography>
    </div>
  );
};

export default TitleNbr;
