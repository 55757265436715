// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { FC } from 'react';
import useStyles from './style';

const ArrayScroreHead: FC = () => {

    const classes = useStyles();

    return (

        <div className={classes.borderTable}>

            <Grid
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >

                <Grid
                    item={true}
                    xs={9}
                    className={classes.categorieA}
                    style={{textAlign: 'center'}}
                >
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)} style={{textAlign:'center'}}>
                        « SOFT SKILLS »
                    </Typography>
                </Grid>

                <Grid
                    item={true}
                    xs={3}
                    className={classes.categorieB}
                >
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)} style={{textAlign:'center'}}>
                        SCORE
                    </Typography>
                </Grid>

            </Grid>

        </div>
    );
};

export default ArrayScroreHead;
