/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Grid from '@material-ui/core/Grid';
import { TestContext } from '@orientaction/Context';
import { Company } from '@orientaction/services';
import { getFromLS } from '@orientaction/utils';
import clsx from 'clsx';
import { FC, useContext, useEffect, useState } from 'react';
import EnterpriseSelect from './EnterpriseSelect';
import PermissionSelect from './PermissionSelect';
import style from './style';

interface IProps {
  testVisibilityRef: any;
}

const TestVisibilityPermissionSection: FC<IProps> = ({ testVisibilityRef }) => {
  const [companies, setCompanies] = useState([]);

  const { test, updateTest } = useContext<any>(TestContext);

  const cls = style();

  useEffect(() => {
    if (companies.length === 0) {
      const token = getFromLS('userToken') || '';
      (async () => {
        const companiesData = await Company.getCompanies(token);
        setCompanies(companiesData?.data?.data?.filter((c: any) => c.id !== 1));
      })();
    }
  }, []);

  return (
    <>
      <h1
        className={clsx({
          [cls.subtitle]: true,
          [cls.textError]: !!test?.manageError?.visibilityPermission,
        })}>
        Droits de visibilité
      </h1>

      <Grid innerRef={testVisibilityRef} container={true} spacing={2}>
        <Grid item={true} xs={4} md={4} sm={4} xl={4}>
          <PermissionSelect test={test} updateTest={updateTest} />
        </Grid>

        {(companies?.length > 0 &&
          test?.visibilityPermission &&
          Array.isArray(test.visibilityPermission) &&
          test.visibilityPermission.some((vp: any) => vp.value === 4)) ||
        (companies?.length > 0 &&
          test?.visibilityPermission &&
          Array.isArray(test.visibilityPermission) &&
          test?.visibilityPermission[0]?.value === 0) ? (
          <Grid item={true} xs={3} md={3} sm={3} xl={3}>
            <EnterpriseSelect
              companies={companies.map((c: any) => {
                return { ...c, value: c.id, label: c.attributes.name };
              })}
              test={test}
              updateTest={updateTest}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default TestVisibilityPermissionSection;
