import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbar from '../../commons/Scrollbar';
import useTest from '../../hooks/useTest';
import { publicLogoPath } from '../../utils/constants';
import { getFromLS } from '../../utils/utils';
import style from './style';
import Button from '@material-ui/core/Button';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import floor from 'lodash/floor';

const LayoutLinearBar = ({ children, isGradient, noPadding }: any) => {
  const history = useHistory();
  const cls = style();
  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const mainLink = user?.role?.id === 3 ? '/results' : '/main';

  const { progress } = useTest();

  return (
    <div className={cls.layout}>
      <div
        className={clsx(
          cls.content,
          isGradient ? cls.gradientBG : null,
          noPadding ? cls.noPadding : null
        )}>
        <AppBar position="sticky" color="transparent" elevation={0} className={cls.appBar}>

          <nav className={cls.navbar}>

            <Grid container={true} spacing={1} alignItems="center">

              <Grid item={true} xs={4} sm={4} md={4}>
                <div
                  className={cls.logoContainer}
                  onClick={() => (user ? history.push(mainLink) : history.push('/'))}>
                  <picture className={cls.logo}>
                    <source
                      media="(min-width:650px)"
                      srcSet={`${publicLogoPath}/e-orientaction.png`}
                    />
                    <img
                      style={{ width: '100%' }}
                      src={`${publicLogoPath}/e-orientaction.png`}
                      alt="logo-orientation"
                    />
                  </picture>
                  {/* <img className={cls.logo} src={`${publicLogoPath}/logo-orientaction.png`} alt="logo" /> */}
                </div>
              </Grid>

              <Grid item={true} xs={6} sm={4} md={4} className={cls.flexCenter}>
                <div className={cls.progressBar}>
                  <LinearProgress
                    classes={{
                      colorPrimary: cls.colorPrimary,
                      barColorPrimary: cls.barColorPrimary,
                    }}
                    variant="determinate"
                    value={progress}
                  />
                </div>
                <p style={{ marginLeft: '5px' }}>{floor(progress)}%</p>
              </Grid>
              <Grid item={true} xs={2} sm={2} md={4} className={clsx(cls.flexCenter, cls.flexCenterMobile)}>
                <Hidden only={['xs', 'sm']}>
                  <span style={{ textAlign: 'left', lineHeight: 1.2, paddingLeft: 40, paddingRight: 20 }}>
                    <Grid container={true} spacing={1}>
                      <Grid item={true} sm={2}>
                        <PhoneIcon style={{ fontSize: '18px' }} />
                      </Grid>
                      <Grid item={true} sm={10}>
                        <div>02 43 72 25 88*</div>
                        <div style={{ fontSize: '12px' }}>
                          *Numéro national non surtaxé
                        </div>
                      </Grid>
                    </Grid>
                  </span>
                  <Button className={cls.btnContacter} onClick={() => window.open("https://www.orientaction-groupe.com/contact/", "_blank")}>
                    <MailOutlineIcon style={{ fontSize: '18px' }} />
                    <span style={{ textAlign: 'left', lineHeight: 1.2, marginLeft: 10 }}>
                      <div>Nous</div>
                      <div>Contacter</div>
                    </span>
                  </Button>
                </Hidden>
                <Hidden only={['xl', 'lg', 'md']}>
                  <IconButton className={cls.btnIcon} onClick={() => history.push('/user/tests')} style={{ float: 'right' }}>
                    <ClearIcon className={cls.styleIcon} />
                  </IconButton>
                </Hidden>
              </Grid>
            </Grid>
          </nav>
        </AppBar>
        <Scrollbar style={{ padding: 0 }}>{children}</Scrollbar>
      </div>
    </div>
  );
};

export default LayoutLinearBar;
