import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -136,
      [theme.breakpoints.down(1300)]: {
        marginTop: -88,
      },
    },
    bannerTitleContainer1: {
      margin: 'auto',
      marginTop: 50,
      [theme.breakpoints.down(1300)]: {
        marginTop: 30,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      textAlign: 'center',
      fontSize: 20,
      [theme.breakpoints.down(1300)]: {
        fontSize: 12,
      },
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '35%',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        top: '0',
        width: '35%',
      },
    },
  }),
);

export default useStyles;
