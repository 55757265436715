/* eslint-disable react/no-unescaped-entities */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import { useHistory } from 'react-router-dom';
import { publicSvgPath } from '../../utils/constants';
import style from './style';

const Footer = (props: any) => {
  const cls = style();
  const { toBack } = props;
  const history = useHistory();

  return (
    <div className={cls.container}>
      <Grid container={true}>
        <Grid item={true} md={4} style={{ marginBottom: 20 }}>
          <Typography variant="h2" className={cls.textHeader}>
            Contact{' '}
          </Typography>
          <div>
            <img src={`${publicSvgPath}/orientaction.png`} alt="" className={cls.imageLogo} />
          </div>
          <Button
            className={cls.btnContacter}
            onClick={() => window.open('https://www.orientaction-groupe.com/contact/', '_blank')}
          >
            <MailOutlineIcon style={{ fontSize: '18px' }} />
            <span style={{ textAlign: 'left', marginLeft: 10 }}>
              <div>Nous contacter</div>
            </span>
          </Button>
          <span style={{ textAlign: 'left', lineHeight: 1.2, paddingLeft: 40, paddingRight: 20 }}>
            <Grid
              container={true}
              spacing={1}
              style={{ marginTop: 20, display: 'flex', alignItems: 'center' }}
            >
              <Grid item={true}>
                <PhoneIcon style={{ fontSize: '18px', color: 'white' }} />
              </Grid>
              <Grid item={true} sm={true} style={{ color: 'white' }}>
                <div style={{ color: 'white' }}>02 43 72 25 88*</div>
                <div style={{ fontSize: '12px', color: 'white' }}>*Numéro national non surtaxé</div>
              </Grid>
            </Grid>
          </span>
        </Grid>
        <Grid item={true} md={4} style={{ marginBottom: 20 }}>
          <Typography variant="h2" className={cls.textHeader}>
            Nos services{' '}
          </Typography>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/bilan-de-competences/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Osez le bilan de compétences
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/outplacement/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Trouvez un nouveau job
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/recrutement/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Accomplissez le parfait recrutement
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://orientaction.softy.pro/offres"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Devenez consultant(e) expert(e)
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/accompagnement-creation-rerprise-entreprise-cpf/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Réussir sa création / Reprise d’entreprise
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/accompagnement-vae/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Réussir sa V.A.E – accompagnement post-recevabilité
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/bilan-orientation-collegien-lyceen-etudiant-parcoursup/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Réalisez votre bilan d’orientation
            </a>
          </span>
          <span className={cls.spanText}>
            <a
              href="https://www.orientaction-groupe.com/qui-sommes-nous/contactez-un-coach/"
              target="_blank"
              className={cls.textLink}
              rel="noreferrer"
            >
              Contactez un coach
            </a>
          </span>
        </Grid>
        <Grid item={true} md={4}>
          <Typography variant="h2" className={cls.textHeader}>
            Certifications{' '}
          </Typography>
          <div style={{ marginBottom: 10 }}>
            <img src={`${publicSvgPath}/qualiopi.png`} alt="" className={cls.imageLogo} />
          </div>
          <span className={cls.textLink}>
            La certification qualité a été délivrée au titre des catégories d’actions suivantes :
            ACTIONS DE FORMATION - BILANS DE COMPÉTENCES - ACTIONS PERMETTANT DE VALIDER LES ACQUIS
            DE L'EXPÉRIENCE
          </span>
          <div style={{ marginTop: 25, marginBottom: 10 }}>
            <img src={`${publicSvgPath}/europe.png`} alt="" className={cls.imageEurope} />
          </div>
          <span className={cls.textLink}>
            En 2009 ORIENTACTION® a été sélectionné par la communauté Européenne parmi les 100
            projets les plus innovants dans la catégorie innovation sociale.
          </span>
        </Grid>
      </Grid>
      <div className={cls.trait} />
      <Grid container={true} className={cls.footerContainer}>
        <Grid item={true} xs={12} sm={6}>
          <Typography variant="h2" className={cls.textFooter}>
            © ORIENTACTION® 2022
          </Typography>
        </Grid>
        <Grid item={true} xs={12} sm={6} className={cls.textAlignRight}>
          <span className={cls.spanText}>
            <a href="../../../../../orientaction-cgu.pdf" target="_blank" className={cls.textLink}>
              Conditions Générales d'Utilisation
            </a>
          </span>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
