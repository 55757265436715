/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Layout, HistoryMajorTest } from '@orientaction/components';
import { useLocation } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { dataFirstView, fakeResult } from './context';
import FirstView from './FirstView';
import SecondView from './SecondView';
import ThirdView from './ThirdView';
import { useStyles } from './style';
import BtnCanceled from '../BtnCanceled';

const ResultatNeedPro = () => {
  const classe = useStyles();

  const myResult = fakeResult;

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const handleOpen = (prop: any) => (e: any) => {
    console.log('open');
  };

  return (
    <div className={classe.root}>
      <div className={classe.btnHistory}>
        <HistoryMajorTest
          testResults={myResult.listHistory || []}
          idTest={+id}
          toRedirect="/resultat-need-pro"
        />
      </div>

      <BtnCanceled id={myResult?.users_permissions_user} />

      <FirstView
        img={myResult?.favorite?.imgPyramid || ''}
        description={dataFirstView.description}
      />

      <SecondView listPrincipaux={myResult?.treeMax || []} handleOpenDrawer={handleOpen} />

      <ThirdView />
    </div>
  );
};

export default withWidth()(ResultatNeedPro);
