import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  headerContainer: {
    height: '100%',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 45,
    color: '#ffffff',
    position: 'relative',
    top: '8vh',
    [theme.breakpoints.down('xs')]: {
      top: '0',
      left: '0'
    }
  },
  header: {
    background: 'linear-gradient(268.04deg, #FFDC27 -0.29%, #E63C3C 48.81%, #000A8C 100%)',
    minHeight: 150,
    [theme.breakpoints.down(1000)]: {
      paddingTop: '5vh',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  },
  userPhoto: {
    position: 'relative',
    top: '8vh',
    left: '12vh',
    [theme.breakpoints.down(1000)]: {
      top: '0',
      left: '0'
    }
  }
}));
