import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            width: '50%',
        },
      
    }))

export default useStyles; 