/* eslint-disable no-use-before-define */

// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { ColorSelection } from '@orientaction/commons';
import cloneDeep from 'lodash/cloneDeep';
import { useBook2 } from '@orientaction/hooks_book';

ChartJS.register(ArcElement);

const CamembertBesoin = (props: any) => {
  const [openModal, setOpenModal] = useState(false);
  const [index, setIndex] = useState(0);
  const { setDataColor1, setDataColor11, setDataColor2, setDataColor21 } = useBook2();
  const { dataColorNum, defaultColor } = props;
  const [dataCouleur, setDataCouleur] = useState(
    defaultColor || [
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
      'white',
    ]
  );

  const data = {
    datasets: [
      {
        label: '# of Votes',
        data: [
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
          '1',
        ],
        backgroundColor: dataCouleur,
        borderColor: '#000A8C',
        borderWidth: 1,
      },
    ],
  };
  const changeCouleur = (couleur: any) => {
    const dataC = cloneDeep(dataCouleur);
    dataC[index] = couleur;
    setDataCouleur(dataC);
    if (dataColorNum === 1) {
      setDataColor1(dataC);
    } else if (dataColorNum === 11) {
      setDataColor11(dataC);
    } else if (dataColorNum === 2) {
      setDataColor2(dataC);
    } else if (dataColorNum === 21) {
      setDataColor21(dataC);
    }
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <Pie
        data={data}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            datalabels: {
              display: false,
            },
          },
        }}
        getElementAtEvent={(element, event) => {
          if (event.type === 'click') {
            handleOpenModal();
            setIndex(element[0].index);
          }
        }}
      />
      <ColorSelection
        open={openModal}
        callBack={(event: any) => setOpenModal(event)}
        colorChoosed={(color: any) => changeCouleur(color)}
      />
    </div>
  );
};

export default CamembertBesoin;
