/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, } from '@orientaction/components';
import { Grid } from '@material-ui/core';
import { useBook3 } from "@orientaction/hooks_book";
import { context } from './context';
import Total from "./Total";
import useStyles from './style';

const Page36 = (props: any) => {

    const classes = useStyles();

    const { personnelTest, setPersonnelTest, totalPersonnelTest } = useBook3();

    return (
        <Page id={36} readOnly={props.readOnly}>

            {
                context.map((item: any) => (
                    <>
                        <MarginContainer>
                            <div className={classes.cardBgGrey}>

                                <Paragrapth
                                    align='center'
                                    colorBlue={true}
                                    addBackground={true}
                                >
                                    <h3>
                                        {
                                            item.title || ""
                                        }
                                    </h3>
                                </Paragrapth>

                            </div>
                        </MarginContainer>

                        {
                            item.paragraph && (
                                <MarginContainer>
                                    <Paragrapth
                                        align='justify'
                                    >
                                        {item.paragraph}
                                    </Paragrapth>
                                </MarginContainer>
                            )
                        }

                        <Total
                            total={item.verification}
                            id={item.id}
                        />

                        {
                            item.listContext.map((itemList: any) => (
                                <MarginContainer>

                                    <Paragrapth align='start'>

                                        <Paragrapth align='start' bold={true} colorBlue={true}>
                                            {itemList.title} :
                                        </Paragrapth> {itemList.content}
                                    </Paragrapth>

                                </MarginContainer>
                            ))
                        }
                    </>
                ))

            }

        </Page>
    );
};

export default Page36;
