/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import update from 'immutability-helper';
import { FC, useCallback } from 'react';
import { Card } from './Card';
import { useCategoryTest } from '@orientaction/hooks';
import ContentOneCategory from '../ContentOneCategory';

const DragAndDrop: FC = () => {
  const { list, setList } = useCategoryTest();

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const item: any[] = list || [];

      const x = update(item, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, item[dragIndex] as any],
        ],
      });

      setList(x);
    },
    [list]
  );

  const renderCard = (card: any, index: number) => {
    if (card.type === 'delete') {
      return;
    }

    return (
      <Card key={card.id} index={index} id={card?.id || 0} moveCard={moveCard}>
        <ContentOneCategory id={card.id} name={card.name} required={!!card?.required} />
      </Card>
    );
  };

  return <>{list.map((card: any, i: number) => renderCard(card, i))}</>;
};

export default DragAndDrop;
