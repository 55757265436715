export const modalStaticBlockElement: any = {
  security_pro: {
    img: 'security_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous avez besoin de travailler pour une organisation, qui, par sa nature (service public, association, etc.), saura vous apporter la sécurité de l’emploi. Vous avez besoin de bénéficier d’un statut très protecteur au sein de cette organisation (statut de fonctionnaire, CDI, etc.). Enfin, vous avez besoin de savoir que cette organisation est solide financièrement et qu’elle ne risque pas de disparaître du jour au lendemain. Vous aspirez aussi à bénéficier d’une certaine sécurité pour vous-même et votre famille à travers différents avantages comme une bonne protection sociale, l’intéressement et/ou la participation, des actions qui vous aideront à faire face aux coups durs et/ou à mettre de l’argent de côté.',
    },
    environmentToPrivilege: {
      type: 'list',
      content: [
        'Les grandes entreprises publiques ou privées ;',
        'Les grandes administrations ;',
        'Les environnements professionnels dont la culture est de prendre soin de ses collaborateurs(trices) et de ne pas les licencier.',
      ],
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les start-up ou plus généralement les entreprises en très fort développement qui n’aspirent qu’à conquérir de nouveaux marchés, dont la stratégie varie et où les risques de fin de contrat sont importants.',
    },
  },
  accomplishment_pro: {
    img: 'accomplishment_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous aspirez à vous accomplir. Aussi vous recherchez des environnements professionnels qui vous mettent au défi et vous font « sortir de votre zone de confort ». Vous avez besoin d’être stimulé(e) par votre manager(euse) et/ou par l’organisation pour laquelle vous travaillez. Vous aspirez à évoluer dans des environnements toujours plus complexes, qui vous permettent d’acquérir de nouvelles connaissances et/ou de nouvelles compétences, afin de prendre de nouvelles responsabilités. C’est un moteur pour vous et une source de satisfaction que de sentir que vous êtes constamment en train de vous développer et de découvrir de nouvelles facettes de vous-même et de la vie.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les start-up, les entreprises dirigées par un(e) leader(euse) visionnaire qui a de fortes ambitions, certaines filiales de grands groupes en très fort développement, des postes d’expatrié(e)s.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les administrations ou les grandes entreprises dans lesquelles cela ronronne. Les entreprises en général qui sont dans un développement lent et/ou n’ont pas de réelles perspectives.',
    },
  },
  self_realization_pro: {
    img: 'self_realization_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous avez besoin d’évoluer dans un environnement qui favorise la réalisation de soi, c’est-à-dire qui vous offre l’occasion d’exprimer tout votre potentiel et d’exercer un métier qui a du sens. Ce type d’environnement n’est pas toujours offert par une organisation dirigée par un tiers, aussi il est recommandé de créer sa propre activité pour pouvoir se réaliser pleinement. Vous pouvez néanmoins trouver une organisation dont la mission vous parle, qui porte suffisamment haut les valeurs que vous partagez pour pouvoir les embrasser pleinement. Certaines organisations, qui vous laisseront être réellement vous-même, sont également intéressantes.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Créer votre propre entreprise vous offrira un maximum de liberté pour exercer le métier qui a vraiment un sens pour vous ; les organisations à mission.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les entreprises trop orientées business sauf si vous êtes vous-même très orienté(e) business. Les entreprises avec un cadre trop rigide, qui vous empêchent d’être vous-même.',
    },
  },
  physiological_pro: {
    img: 'physiological_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Vous avez besoin que votre entourage professionnel réponde à vos besoins physiologiques. La relation que vous cherchez à développer avec vos collègues est une relation qui s’inspire de la relation dans une fratrie. La relation que vous entretenez avec votre manager(euse) peut s’apparenter à la relation que vous auriez avec un père et/ou une mère, idéal(e) bien sûr. Vous avez besoin de sentir que votre entourage professionnel vous aime, qu’il puisse vous pardonner vos erreurs de la même manière qu’un(e) père/mère et/ou un(e) frère/sœur le feraient. Vous avez /Vous éprouvez/ un fort sentiment d’appartenance à l’égard de votre entreprise que vous pouvez voir comme une deuxième famille et entretenez de vraies relations affectives avec celles et ceux qui y travaillent',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les TPE et PME familiales, les petites administrations comme les mairies et/ou les petites collectivités locales. Vous pourriez aussi être tenté(e) de créer une entreprise, mais de préférence en famille.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les grandes entreprises, les start-up et plus généralement les entreprises en très forte croissance qui n’aspirent qu’à conquérir de nouveaux marchés et à se développer rapidement.',
    },
  },
  horizontal_belonging_pro: {
    img: 'appartenance_h_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous aspirez à développer de nombreuses relations dans votre travail. Vous êtes une personne très sociable et vous vous nourrissez des relations avec les autres. Discuter, échanger, contribuer à la prise de décision, travailler en équipe, sont autant de sources de satisfaction. Vous appréciez particulièrement les temps collectifs. Vous avez également besoin que l’organisation pour laquelle vous travaillez ait une marque forte et facilement identifiable, de façon à pouvoir porter ses couleurs. Vous avez un fort besoin d’appartenance à l’égard de votre organisation. Vous êtes prêt(e) à promouvoir et défendre ses valeurs, ses produits/services ou missions et son image.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les organisations dans lesquelles le sentiment d’appartenance et la fierté sont forts, où l’on peut interagir avec de nombreuses personnes et travailler en équipe.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Travailler en free-lance seul(e) ne peut pas vous convenir, pas plus que d’avoir un métier trop solitaire qui vous priverait de la richesse des échanges du travail en équipe.',
    },
  },
  vertical_belonging_pro: {
    img: 'appartenance_v_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous avez besoin de travailler pour des organisations qui sont connues et reconnues et qui possèdent un certain prestige, une certaine aura et une certaine notoriété. Vous privilégiez des secteurs comme le luxe, l’industrie de pointe, le conseil, etc. Vous aspirez à travailler pour une organisation qui est leadeuse dans son domaine, dans laquelle beaucoup de personnes rêveraient de travailler. Pour vous, intégrer ce type d’organisation est la preuve que vous faites partie d’un petit groupe de privilégié(e)s, supérieur(e)s par leurs savoirs, leurs savoir-faire et leurs savoir-être. Une fois intégré(e) dans ce type d’organisation, vous aspirez à en gravir les échelons pour faire partie des décideurs(ses).',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les grandes entreprises, certaines PME qui sont reconnues pour leur niveau d’excellence dans un domaine spécifique d’activité et qui sont perçues comme prestigieuses.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les petites entreprises et/ou les PME dont la marque n’est pas connue du grand public ou qui n’occupent pas une position de leadeuse dans leur domaine.',
    },
  },
  spirituality_pro: {
    img: 'spirituality_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, votre besoin de spiritualité se traduit par l’aspiration à travailler pour une organisation qui intègre dans ses objectifs, ses méthodes, ses produits/services ou sa mission, une dimension spirituelle. <br/>Vous aspirez à évoluer avec des collègues et/ou un(e) manager(euse) avec lequel (laquelle) il est possible de discuter de ce type de sujet, qui sont suffisamment ouvert(e)s et tolérant(e)s à ce type de croyances et/ou de pratiques. <br/>Dans l’idéal, vous aimeriez travailler avec des personnes qui partagent vos croyances religieuses ou parareligieuses. <br/>Cette dimension spirituelle touche également à la question de vos valeurs, d’une forme de transcendance à laquelle n’échappe pas le travail.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les organisations qui ont une vocation religieuse ; des métiers qui sont en lien avec des pratiques religieuses ou parareligieuses ; des métiers qui sont en lien avec la question de la mort.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les environnements professionnels dont les produits/services et/ou les pratiques seraient contraires à vos croyances et/ou qui ne les tolèreraient pas.',
    },
  },
  eternity_pro: {
    img: 'eternity_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous aspirez à travailler pour une organisation dont l’objet vous permettra de laisser une trace dans l’histoire de l’humanité. Une organisation capable de changer le monde, de marquer l’histoire grâce à une innovation majeure dans le domaine de la santé, du transport et/ou de la technologie, etc. serait une organisation idéale pour vous. Vous n’avez pas envie juste de travailler. Vous voulez que votre travail ait une dimension supérieure, qui s’inscrive dans le temps long. Vous avez envie que votre travail marque votre époque et les époques futures. Vous serez d’autant plus engagé(e) si une organisation vous offre cette opportunité.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les organisations qui ont pour projet de changer le monde, de transformer les pratiques et/ou les mentalités ; des métiers comme écrivain(e), réalisateur(trice), artiste qui permettent de laisser une trace.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les organisations qui ne sont pas animées par le projet ambitieux de réaliser de grandes choses, qui servent juste aux êtres humains à vivre au quotidien, dont la mission est trop « terre à terre ».',
    },
  },
};
