/* eslint-disable react/destructuring-assignment */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage, TitleNbr } from '@orientaction/components';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { useBook3 } from "@orientaction/hooks_book";
import { context } from './context';
import Item from './item';
import useStyles from './style';

const Page34 = (props: any) => {

    const classes = useStyles();

    const { personnelTest, setPersonnelTest } = useBook3();

    const handleChange = (e: any) => {

        const { name, checked } = e.target;

        const nameIt = name.split('-')[1];

        const groupBy = name.split('-')[0];

        if (checked) {
            personnelTest[groupBy] = [+nameIt];
        }

        if (!checked) {
            personnelTest[groupBy] = [];
        }

        setPersonnelTest(personnelTest);

    }

    const clickAction = (data: any) => (e: any) => {
        e.preventDefault();
        handleChange({ target: data });
    }

    return (
        <Page id={34} readOnly={props.readOnly}>

            <TitlePage>
                TEST DU (DE LA) CRÉATEUR(TRICE) <br />
                & REPRENEUR(SE) D’ENTREPRISE
            </TitlePage>

            <MarginContainer>
                <h4 className={classes.alignCenter}>
                    « SUIS-JE FAIT(E) POUR UN PROJET DE CRÉATION OU DE REPRISE D’ENTREPRISE ? »
                </h4>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Ce test est constitué de deux parties que vous devez « jouer » dans l’ordre. Les résultats
                    combinés de ces deux parties déterminent votre « profil » dans une synthèse globale.
                </Paragrapth>
            </MarginContainer>

            <Item
                label1='I'
                label2='1<sup>re</sup> PARTIE : AVEZ-VOUS LE « PROFIL » D’UN(E) CRÉATEUR(TRICE) OU D’UNE REPRENEUR(SE) D’ENTREPRISE ?'
            />

            <MarginContainer>
                <Paragrapth align='justify'>
                    Voici douze cas ou situations, suivis de trois propositions désignées par les lettres A, B et C.
                    Pour chaque question, choisissez la proposition qui vous correspond le mieux (vous ne devez choisir qu’une réponse et une seule).
                </Paragrapth>
            </MarginContainer>

            {
                context.map((item: any) => (

                    <MarginContainer>

                        <TitleNbr
                            number={item.id}
                            text={item.title || ""}
                        />

                        <div className={classes.contentAsk}>

                            {
                                item.ask.map((itemAsk: any, index: number) => <>

                                    <Grid
                                        container={true}
                                        direction='row'
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item={true} xs={2} md={1}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={(personnelTest[item.id] || []).includes(itemAsk.point)}
                                                        onChange={handleChange}
                                                        name={`${item.id}-${itemAsk.point}`}
                                                        color="primary"
                                                    />}
                                                label=""
                                            />
                                        </Grid>

                                        <Grid item={true} xs={10} md={11}>

                                            <div
                                                onClick={clickAction({
                                                    name: `${item.id}-${itemAsk.point}`,
                                                    checked: !(personnelTest[item.id] || []).includes(itemAsk.point)
                                                })}
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                            >

                                                <Paragrapth
                                                    align='start'
                                                    colorBlue={true}
                                                >

                                                    <span className={classes.labelName} >
                                                        {itemAsk.name || ""}.
                                                    </span>

                                                    {itemAsk.label || ""}

                                                </Paragrapth>

                                            </div>

                                        </Grid>

                                    </Grid>

                                </>)
                            }

                        </div>

                    </MarginContainer>
                ))
            }


        </Page>
    );
};

export default Page34;
