/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import QuestionCardItem from '../QuestionCardItem';

interface IProps {
  questions: any;
}

const QuestionCardList: FC<IProps> = ({ questions }) => {
  return questions.map((question: any, index: number) => (
    <QuestionCardItem key={index} questionIndex={index} question={question} />
  ));
};

export default QuestionCardList;
