/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { UserResultRadarGraph } from '@orientaction/components';

import { FC, useContext, useEffect, useState } from 'react';
import { useStyles } from './style';
import Card from '../Components/Card';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Description from '../Components/Description';
import { dataEmpty } from './data';
import CardButton from '../Components/Button';
import { CompassUserContext } from '@orientaction/Context';
import { useLanguage } from '@orientaction/hooks';

Chart.register(ChartDataLabels);

interface IProps {
  result: any;
  loading: boolean;
}

const TalentsCard: FC<IProps> = ({ result, loading }) => {
  const { language } = useLanguage();

  const { userInfos } = useContext<any>(CompassUserContext);
  const [hasAccess, setHasAccess] = useState<boolean>();
  const [showNeutralVersion, setShowNeutralVersion] = useState<boolean>(false);
  const [shouldBuy, setShouldBuy] = useState<boolean>();
  const [currentTest, setCurrentTest] = useState<any>();

  // const currentTest = userInfos?.service?.major_tests_templates?.find((test: any) => test.id === 5);
  // const shouldBuy = currentTest?.shouldBuy || !userInfos?.rattachement || false;

  // const hasAccess = currentTest?.hasRight || currentTest?.hasResult || false;

  const classe = useStyles();

  // const showNeutralVersion = !hasAccess || !currentTest?.hasResult;

  useEffect(() => {
    const currentTestData = userInfos?.service?.major_tests_templates?.find((test: any) => test.id === 5);
    console.log({userInfos, currentTestData})
    setCurrentTest(currentTestData);
    setShouldBuy(currentTestData?.shouldBuy || !userInfos?.rattachement || false);
    setHasAccess(currentTestData?.hasRight || currentTestData?.hasResult || false);
  }, [userInfos, language]);

  useEffect(() => {
    setShowNeutralVersion(!hasAccess || !currentTest?.hasResult);
    console.log({hasAccess, shouldBuy})
  }, [hasAccess, shouldBuy, currentTest]);

  const withoutAccessRedirectButton = shouldBuy && (
    <CardButton redirectUri="https://orientaction.kneo.me/shop/view/1755C3">
      Acheter le test
    </CardButton>
  );

  return (
    <Card
      to={`${language === 'GB' ? `/en/tests/harmony` : `/tests/soft-skills`}`}
      disable={showNeutralVersion}
      loading={loading}
      to_resultat={`/resultat-harmony/${result?.id}`}
      btnRedirectOverride={classe.btnRedirectOverride}
      withAccess={hasAccess}
      withoutAccessRedirectButton={withoutAccessRedirectButton}
    >
      <div className={classe.root}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <Description>
              Les soft skills ou compétences douces sont des compétences que l’on acquiert par
              l’expérience, par opposition aux hard skills qui s’acquièrent dans le cadre d’une
              formation ou de ses études. Les soft skills sont aujourd’hui la clef de la réussite
              professionnelle. Les développer vous aidera à devenir un(e) collaborateur (trice)
              reconnu(e) et apprécié(e) dans son organisation. Dans votre sphère de vie personnelle,
              cela fera de vous une personne accomplie et respectée pour ses qualités humaines. Le
              fait de les tester est une première étape avant de progresser. À vous de jouer !
            </Description>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <div className={classe.chartContent}>
              <UserResultRadarGraph
                isEmpty={showNeutralVersion}
                resultToShow={showNeutralVersion ? dataEmpty : result}
                fromCompass={true}
                canUserAccessHarmony={hasAccess}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default TalentsCard;
