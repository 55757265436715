/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useStyles } from './style';


const Footer: FC<any> = ({ children }) => {

    const classe = useStyles();

    return (
        <div className={classe.root}>
            {children}
        </div>
    );
};

export default Footer;
