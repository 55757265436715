export const paragraphs = [
  {
    id: '12-1',
    text: `
      De mon point de vue, ce qui a fait la différence entre toutes ces personnes, c’est une
      compétence. Non pas un savoir-faire, mais plutôt une aptitude à réussir. La compétence,
      c’est la capacité que nous avons à mobiliser l’ensemble de nos ressources pour réussir à
      atteindre un objectif. La compétence fait donc appel à une multitude de ressources diverses
      : des ressources économiques, culturelles, sociales, symboliques, mais aussi des ressources
      dans notre personnalité, notre caractère, notre façon de vivre et d’évoluer.
              `,
  },
  {
    id: '12-2',
    text: `
    La compétence implique également la motivation et le désir. Nombreux(ses) sont ceux (celles)
    qui partent avec les mêmes atouts dans leur jeu, mais qui n’en font pas la même chose, parce
    qu’avec des capacités similaires, leur désir est différent, parce que leur confiance en soi
    est différente et parce qu’ils (elles) ne se mettent pas les mêmes freins. Ayant une vision
    de leur avenir différente, ils (elles) n’en font pas la même chose. Chacun(e) est
    responsable de son destin. Nous sommes maîtres de ce que nous voulons devenir.
                `,
  },
];

export const remainingParagraphs = [
  {
    id: '12-3',
    text: `
    Dans ma famille, j’ai deux cousins germains qui sont jumeaux. Ils sont nés le même jour à
    la même heure dans la même famille. Aujourd’hui, à trente-deux ans, l’un est kinésithérapeute-ostéopathe, épanoui dans sa vie de couple, propriétaire d’une maison et de son cabinet médical. Le second vient de reprendre ses études à l’université. Il a passé
    cinq fois le concours pour devenir professeur de sport, sans succès.
                `,
  },
  {
    id: '12-4',
    text: `
    Après avoir travaillé à l’étranger, fait des tas de petits boulots, voyagé, il reprend des
    études pour repasser une sixième fois le concours, s’obstinant comme un automate dans une
    voie « bouchée » par la réduction des recrutements alors que son frère a choisi un emploi
    bien rémunéré et porteur.
                  `,
  },
  {
    id: '12-4',
    text: `
    Qu’est-ce qui distingue ces deux frères ? D’abord une certaine vision de leur avenir et
          ensuite une aptitude à mobiliser leurs ressources pour réussir. Je ne porte aucun jugement
          de valeur sur leur situation respective, mais objectivement, l’un a une situation bien
          plus enviable que le second et cette différence m’interroge tant ils sont semblables sur
          les plans génétique, économique et culturel.
                  `,
  },
];

export const lastParagraphs = [
  {
    id: '12-5',
    text: `
        Cette différence tient à un ensemble d’aptitudes, de capacités, de ressources que la
        personne possède et à sa capacité à les mobiliser de façon intelligente. D’un côté, il y a
        les ressources génétiques, économiques, sociales, culturelles, symboliques dont la personne
        hérite au départ : cela correspond à son potentiel. Il y a ensuite sa capacité à exprimer, à
        chaque seconde de sa vie, ce potentiel, et à le transformer en quelque chose afin de le
        développer. Enfin, il y a le résultat : là où on arrive. Ce résultat est ce que l’on peut
        évaluer, mesurer, ce qui est visible.
                      `,
  },
];
