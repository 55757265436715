import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';

interface StyledTabProps {
  label: any;
  value: any;
}

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 50,
      fontSize: '13px',
      background: 'white',
      paddingLeft: '2.8vh',
      paddingRight: '2.8vh',
      fontFamily: ['Poppins'].join(','),
      '& span div span': {
        display: 'block',
        background: '#0E1247',
        float: 'right',
        paddingLeft: 7,
        paddingRight: 7,
        color: 'white',
        borderRadius: 35,
        marginLeft: 7,
        fontSize: '12px',
      },
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&$selected': {
        fontWeight: 50,
        background:
          'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
        color: 'white',
        borderRadius: '50px',
        '& span div span': {
          background: 'white',
          color: '#0E1247',
        },
      },
      '&:focus': {
        color: 'white',
      },
    },
    selected: {},
    scrollButtons: {
      background: 'red',
    },
  })
)((props: StyledTabProps) => <Tab disableRipple={true} {...props} />);

export default AntTab;
