/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TabPanel, CardTest } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { useCategoryTest, useTest } from '@orientaction/hooks';
import FirstEtage from './ViewTest/FirstEtage';
import SecondEtage from './ViewTest/SecondEtage';
import { FC } from 'react';
import { useStyles } from './style';

interface ITabPannelAdminTest {
  tabs: number;
  loading: boolean;
}

const TabPannelAdminTest: FC<ITabPannelAdminTest> = ({ tabs, loading }) => {
  const classes = useStyles();

  const { tests, disabledTests, archivedTests } = useTest();

  const { list } = useCategoryTest();

  return (
    <MarginContainer>
      {loading ? (
        <div className={classes.bodycenter}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          <TabPanel value={tabs} index={0}>
            {list.map((category: any, i: number) => {
              if (!category?.contentList || !category.contentList.length) {
                return <div />;
              }

              return (
                <>
                  <div>
                    <MarginContainer>
                      <Typography variant="h4" data-cy={`default-category-${i}`}>
                        {category.name}
                      </Typography>
                    </MarginContainer>

                    <MarginContainer>
                      <div className={classes.cardContent}>
                        <FirstEtage listFirstEtape={category.contentList} />

                        <SecondEtage listSecondEtape={category.contentList} />

                        <br className={classes.both} />
                      </div>
                    </MarginContainer>
                  </div>
                </>
              );
            })}
          </TabPanel>

          <TabPanel value={tabs} index={1}>
            <Grid container={true} spacing={1}>
              {Array.isArray(disabledTests) && disabledTests.length > 0 ? (
                disabledTests.map(test => {
                  return (
                    <Grid item={true} xs={4} key={test.id}>
                      <CardTest
                        status="desactive"
                        key={test.id}
                        id={test.id}
                        isArchived={test.attributes.isArchived}
                        isDesabled={test.attributes.isDesabled}
                        title={test.attributes.title}
                        description={test.attributes.description}
                        image={test.attributes.image}
                        time={test.attributes.time}
                        category={test.category}
                      />
                    </Grid>
                  );
                })
              ) : (
                <div className={classes.bodycenter}>Aucun test désactivé</div>
              )}
            </Grid>
          </TabPanel>

          <TabPanel value={tabs} index={2}>
            <Grid container={true} spacing={1}>
              {Array.isArray(archivedTests) && archivedTests.length > 0 ? (
                archivedTests.map(test => {
                  return (
                    <Grid item={true} xs={4} key={test.id}>
                      <CardTest
                        status="archive"
                        key={test.id}
                        id={test.id}
                        isArchived={test.attributes.isArchived}
                        isDesabled={test.attributes.isDesabled}
                        title={test.attributes.title}
                        description={test.attributes.description}
                        image={test.attributes.image}
                        time={test.attributes.time}
                        category={test.category}
                      />
                    </Grid>
                  );
                })
              ) : (
                <div className={classes.bodycenter}>Aucun test archivé</div>
              )}
            </Grid>
          </TabPanel>
        </>
      )}
    </MarginContainer>
  );
};

export default TabPannelAdminTest;
