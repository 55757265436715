// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import { MarginContainer } from '@orientaction/commons';
import {
  TitlePage,
  Page,
  TitleChapitre,
  Paragrapth,
  Title,
  ListParagrapth,
} from '@orientaction/components';
import Img from '../../components/Image';
import useStyles from './style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page42 = () => {
  const classes = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_cv.png' : 'cv.jpg';
  }, [language]);
  return (
    <Page id={42}>
      <TitlePage>MAINTENANT, METTEZ À JOUR VOTRE CV !</TitlePage>
      <MarginContainer>
        <Img filename={image} alt="cv" className={classes.imgTronc} />
      </MarginContainer>
    </Page>
  );
};

export default Page42;
