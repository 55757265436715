import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { publicSvgPath } from '../../utils/constants';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '100vh',
    overflow: 'auto',
  },
  cardContainer: {
    borderRadius: '10px',
    boxShadow: 'none',
    backgroundColor: 'white',
    height: 'auto',
    '@media only screen and (max-width: 600px)': {
      height: 'auto !important',
    },
    '@media only screen and (max-width: 361px)': {
      height: 'auto !important',
    },
  },
  cardPseudo: {
    boxShadow: '-11px 11px 0px -3px rgba(255,255,255,0.6)',
  },
  img: {
    width: '100%',
    height: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '103%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '103%',
    },
  },
  imgBan: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  fontTitleCard: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '140%',
    color: '#0E1247',
    textTransform: 'none',
  },
  fontTextCard: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#0E1247',
  },
  fontTextCardDescription: {
    textAlign: 'center',
    marginTop: '10px',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left',
    },
  },
  fontSecondDesc: {
    textAlign: 'center',
    marginTop: '20px',
    color: 'white',
    fontSize: '17px',
    textTransform: 'none',
    lineHeight: '1.3',
    '@media only screen and (max-width: 600px)': {
      fontSize: '15px',
      textAlign: 'left',
    },
    '@media only screen and (max-width: 361px)': {
      fonSize: '15px',
      textAlign: 'left',
    },
  },
  fontRight: {
    textAlign: 'right',
    marginTop: '10px',
    color: 'white',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left',
    },
  },
  paddingContainer: {
    padding: 75,
    '@media only screen and (max-width: 600px)': {
      padding: 20,
    },
    '@media only screen and (max-width: 361px)': {
      padding: 20,
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnTest: {
    width: '100%',
    height: '100%',
    paddingTop: '30px',
    paddingBottom: '30px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 10,
    display: 'block',
    '@media only screen and (max-width: 600px)': {
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: 20,
      paddingRight: 20,
      height: '113px',
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: 20,
      paddingRight: 20,
      height: '113px',
    },
    '& .MuiButton-label': {
      display: 'block !important',
      '@media only screen and (max-width: 600px)': {
        display: 'flex !important',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'flex !important',
      },
    },
  },
  iconBtn: {
    padding: 10,
    background: 'white',
    borderRadius: '50%',
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media only screen and (max-width: 600px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '10px',
    },
    '@media only screen and (max-width: 361px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '10px',
    },
  },
  link: {
    textDecoration: 'underline',
    color: '#000A8C',
  },
  paddingDesktop: {
    paddingTop: 15,
    paddingBottom: 15,
    '@media only screen and (max-width: 600px)': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  gridRow: {
    '@media only screen and (max-width: 600px)': {
      flexDirection: 'row !important',
    },
    '@media only screen and (max-width: 361px)': {
      flexDirection: 'row !important',
    },
  },
  labelBtn: {
    marginLeft: 20,
    color: '#000A8C',
    '@media only screen and (max-width: 600px)': {
      marginLeft: 0,
    },
    '@media only screen and (max-width: 361px)': {
      marginLeft: 0,
    },
  },
  yellowBtn: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/yellow.png)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/yellow_mobile.png)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/yellow_mobile.png)`,
    },
  },
  blueBtn: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/blue.png)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/blue_mobile.png)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/blue_mobile.png)`,
    },
  },
  redBtn: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/red.png)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/red_mobile.png)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/red_mobile.png)`,
    },
  },
  newBtnTest: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_bouton_faire_un_test.svg)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_faire_test.svg)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_faire_test.svg)`,
    },
  },
  newBtnRes: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/mes_ressources_fond.svg)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_ressources.svg)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_ressources.svg)`,
    },
  },
  newBtnResu: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/mes_resultats_fond.svg)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_mes_result.svg)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_mes_result.svg)`,
    },
  },
  imgResuResp: {
    '@media only screen and (max-width: 600px)': {
      width: '25% !important',
    },
    '@media only screen and (max-width: 361px)': {
      width: '25% !important',
    },
  },
  newBtnB: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/ma_boussole_petit_fond.svg)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_boussole_haut.svg)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/fond_mobile_boussole_haut.svg)`,
    },
  },
  paddingLargeHome: {
    padding: '75px 150px !important',
    '@media only screen and (max-width: 600px)': {
      padding: '45px 25px !important',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '40px 20px !important',
    },
  },
  newPictoImage: {
    width: '100%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    objectFit: 'cover',
  },
  newLabelBtn: {
    color: 'white',
    fontSize: '27px',
    textAlign: 'center',
    display: 'block',
    marginTop: '10px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '21px',
      display: 'inline',
      marginTop: '0px',
    },
    '@media only screen and (max-width: 361px)': {
      fontSize: '21px',
      display: 'inline',
      marginTop: '0px',
    },
  },
  imgContainer: {
    width: '30%',
    height: 'auto',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      margin: '0px 15px 0px 0px',
    },
    '@media only screen and (max-width: 361px)': {
      margin: '0px 15px 0px 0px',
    },
  },
  imgContainerRes: {
    '@media only screen and (max-width: 600px)': {
      margin: '0px 20px 0px 0px',
    },
    '@media only screen and (max-width: 361px)': {
      margin: '0px 20px 0px 0px',
    },
  },
  tagChip: {
    position: 'absolute',
    top: 10,
    left: 10,
    borderRadius: 5,
    fontSize: '14px',
    padding: '4px 2px 2px 2px',
  },
  imageWithBtn: {
    position: 'absolute',
    zIndex: 0,
    display: 'block',
    width: '100%',
    height: 'auto',
    top: 0,
    left: 0,
  },
  backImg: {
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: '100% 100% !important',
  },
  backImgCarte: {
    position: 'relative',
    background: `url(${publicSvgPath}/fond_bouton_jeu_carte.png), #0E1444`,
    height: '100%',
    '@media only screen and (max-width: 600px)': {
      background: `url(${publicSvgPath}/backgroundBtn/fond_mobile_jeu_carte.png), #0E1444`,
      height: '570px !important',
      width: '100%',
    },
    '@media only screen and (max-width: 361px)': {
      background: `url(${publicSvgPath}/backgroundBtn/fond_mobile_jeu_carte.png), #0E1444`,
      height: '570px !important',
      width: '100%',
    },
  },
  backImgFormation: {
    position: 'relative',
    background: `url(${publicSvgPath}/backgroundBtn/robot_fond.svg)`,
    height: '100%',
    '@media only screen and (max-width: 600px)': {
      background: `url(${publicSvgPath}/backgroundBtn/fond_mobile_robot.svg)`,
      height: '505px !important',
    },
    '@media only screen and (max-width: 361px)': {
      background: `url(${publicSvgPath}/backgroundBtn/fond_mobile_robot.svg)`,
      height: '505px !important',
    },
  },
  backImgCatalogue: {
    position: 'relative',
    background: `url(${publicSvgPath}/backgroundBtn/formation_fond.svg)`,
    height: '100%',
    '@media only screen and (max-width: 600px)': {
      background: `url(${publicSvgPath}/backgroundBtn/fond_mobile_formations.svg)`,
      height: '448px',
    },
    '@media only screen and (max-width: 361px)': {
      background: `url(${publicSvgPath}/backgroundBtn/fond_mobile_formations.svg)`,
      height: '448px',
    },
  },
  imageSlide: {
    width: '40%',
    height: 'auto',
    display: 'block',
    marginTop: 55,
    '@media only screen and (max-width: 600px)': {
      marginTop: 15,
      width: '90%',
      margin: 'auto',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 15,
      width: '90%',
      margin: 'auto',
    },
  },
  titleCard: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '26px',
    color: '#242424',
    textAlign: 'center',
    textTransform: 'none',
    lineHeight: '1.3',
    '@media only screen and (max-width: 600px)': {
      marginTop: 40,
      textAlign: 'left !important',
      fontSize: '20px',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 40,
      textAlign: 'left !important',
      fontSize: '20px',
    },
  },
  btnBlock1: {
    position: 'absolute',
    bottom: '17%',
    right: '15%',
    color: 'white',
    textTransform: 'none',
    '@media only screen and (max-width: 600px)': {
      right: '35%',
    },
    '@media only screen and (max-width: 361px)': {
      right: '35%',
    },
  },
  containerImage: {
    display: 'flex',
    width: '50%',
    height: 'auto',
    position: 'relative',
  },
  containerBtn: {
    width: '100%',
    position: 'relative',
    height: 'auto',
    marginTop: '20px',
  },
  btnInsideRobot: {
    position: 'absolute',
    top: 0,
    zIndex: 2,
    right: 0,
    border: '2px solid black',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    opacity: 0,
  },
  titleHome: {
    marginTop: 0,
    marginBottom: 20,
    fontSize: '50px',
    color: 'white',
    textTransform: 'none',
    textAlign: 'center',
    '@media only screen and (max-width: 600px)': {
      fontSize: 30,
    },
    '@media only screen and (max-width: 361px)': {
      fontSize: 25,
    },
  },
  marginTopImage: {
    '@media only screen and (max-width: 600px)': {
      marginTop: 40,
      flexWrap: 'nowrap',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 40,
      flexWrap: 'nowrap',
    },
  },
  marginTopTitle: {
    '@media only screen and (max-width: 600px)': {
      marginTop: 4,
      flexWrap: 'nowrap',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 4,
      flexWrap: 'nowrap',
    },
  },
  floatBtn: {
    '@media only screen and (max-width: 600px)': {
      marginTop: 10,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 10,
    },
  },
  imgBoussole: {
    width: '118%',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '100%',
    },
  },
  paddingBtnBoussole: {
    padding: '0px 20px 30px 0px',
    position: 'relative',
    '@media only screen and (max-width: 600px)': {
      padding: '0px 0px 30px 20px',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '0px 0px 30px 20px',
    },
  },
  btnBoussole: {
    position: 'absolute',
    bottom: '45px',
    right: '30px',
    '@media only screen and (max-width: 600px)': {
      position: 'static',
    },
    '@media only screen and (max-width: 361px)': {
      position: 'static',
    },
  },
  textTitleBoussole: {
    padding: '30px 20px 0px 0px',
    '@media only screen and (max-width: 600px)': {
      padding: '30px 20px',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '30px 20px',
    },
  },
  textBoussole: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: '26px',
    color: 'white',
    textAlign: 'right',
    lineHeight: '1.3',
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '100%',
    },
  },
  btn40Per: {
    width: '40%',
  },
  btn45Per: {
    width: '45%',
    '@media only screen and (max-width: 600px)': {
      width: '35%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '35%',
    },
  },
  marginTopTest: {
    marginTop: '23px',
    '@media only screen and (max-width: 600px)': {
      marginTop: '0px',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: '0px',
    },
  },
  marginTopResp: {
    '@media only screen and (max-width: 600px)': {
      marginTop: '35px',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: '35px',
    },
  },
  drawerContent: {
    borderTop: '2px solid #23296F',
    borderRadius: 16,
    overflowX: 'hidden',
    minHeight: 300,
    maxHeight: '70vh',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
  contentDrawer: {
    position: 'relative',
    width: '50%',
    margin: '20px auto',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
  titleTests: {
    fontSize: 30,
    fontFamily: 'Poppins',
    textTransform: 'none',
    lineHeight: '36px',
  },
  separator: {
    width: '100%',
    height: 1,
    background: '#E4E4F2',
    margin: '25px 0px 25px',
  },
  testCard: {
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #5151512b',
    padding: '15px 25px',
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#000A8C',
    margin: '15px 0px',
    cursor: 'pointer',
    transition: 'all 0.4s ease allow-discrete',
    backgroundColor: 'white',
    '&:hover': {
      color: 'white',
      background:
        'linear-gradient(88.68deg, #000A8C 2.25%, #E63C3C 47.27%, #FFDC37 97.75%) 0% 0% no-repeat padding-box',
    },
  },
  subTitleTests: {
    color: '#A9AAB8 !important',
    fontSize: 14,
    fontStyle: 'italic',
  },
  iconBtnImage: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '1.5%',
    right: '1%',
  },
}));
