// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import useStyles from "./style";
import { Typography, TextField } from '@material-ui/core';


interface IAliasRoleAdmin {
    handleChangeForm: (data: any) => void;
    errorForm: any;
    dataValue: any;
    readOnly: boolean;
}

const AliasRoleAdmin: FC<IAliasRoleAdmin> = ({
    handleChangeForm,
    errorForm,
    dataValue,
    readOnly
}) => {

    const classes = useStyles();

    const handleChange = (event: any) => {

        const { name, value } = event.target;

        handleChangeForm({
            ...dataValue,
            companyUserRoleAlias: {
                ...dataValue.companyUserRoleAlias,
                [name]: value
            }
        });

    };

    const listForm = [
        {
            label: "Alias Manager N+3",
            name: "manager_3"
        },
        {
            label: "Alias Manager N+2",
            name: "manager_2"
        },

        {
            label: "Alias Manager N+1",
            name: "manager_1"
        },
    ];

    return (
        <div>

            <div className={classes.contentAssignation}>

                <Typography className={classes.textContent}>
                    Renseignez les alias des rôles administrateurs de l&apos;espace entreprise
                </Typography>

                {
                    listForm.map((item: any) =>

                        <div style={{
                            marginTop: '15px',
                            width: "100%"
                        }}>

                            <Typography style={{ marginBottom: '2px', fontSize: '14px', fontWeight: 'bold' }}>
                                {item.label || ""}
                            </Typography>

                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                fullWidth={true}
                                className={classes.formInput}
                                onChange={handleChange}
                                name={item.name}
                                value={dataValue?.companyUserRoleAlias?.[item.name] || ''}
                                error={!!errorForm?.[item.name]}
                                InputProps={{
                                    readOnly: readOnly as boolean,
                                }}
                            />

                        </div>

                    )
                }

            </div>

        </div >
    );
};

export default AliasRoleAdmin;
