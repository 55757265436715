import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '3.75%',
      marginBottom: '4%',
      // [theme.breakpoints.down(1440)]: {
      //   marginTop: '0',
      // },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      color: '#0E1247',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down(800)]: {
        margin: '2vh 1.5vh',
        fontSize: '16px',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(800)]: {
        flexDirection: 'column',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      width: '63.50%',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      padding: '1.5vh',
      justifyContent: 'center',
      [theme.breakpoints.down(800)]: {
        width: '100%',
        padding: '0',
      },
    },
    contentElement: {
      maxWidth: '769px',
    },
    cardContainer: {
      width: '30.5%',
      borderRadius: '10px',
      [theme.breakpoints.up(1445)]: {
        width: '30%',
      },
      [theme.breakpoints.down(800)]: {
        margin: '2.25vh 0',
        width: '100%',
      },
    },
    iconButton: {
      backgroundColor: 'rgba(0, 10, 140, 1)',
      color: 'white',
      margin: '1vh',
      '&::hover': {
        backgroundColor: 'rgba(0, 10, 140, 0.5)',
      },
    },
    imgBan: {
      width: '100%',
      height: 'auto',
      borderRadius: '16px',
      cursor: 'pointer',
    },
    paragraph: {
      margin: '1vh 0',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '170%',
      color: '#0E1247',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 1.5vh',
        fontSize: '16px',
        lineHeight: '150%',
      },
    },
  }),
);
