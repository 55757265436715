/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../Img';
import useStyles from './style';

const Page08 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={8}>
      <div className={classes.cardBgGrey}>
        <div className={classes.content}>
          <MarginContainer>
            {/* <Img
                            src='title_la_diva.jpg'
                            alt='title_la_diva'
                            className={classes.title}
                        /> */}
            <SimpleBanner>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                LA DIVA D&#39;HÉLÉNA RUBINSTEIN
              </Typography>
            </SimpleBanner>
          </MarginContainer>

          <Img
            src="la_diva.jpg"
            alt="Chaque homme doit inventer son chemin."
            style={{
              width: '45%',
            }}
          />

          <MarginContainer mormal={true}>
            <Typography className={classes.paragraphe}>
              Marie a 45 ans. Elle travaille dans l’industrie du luxe depuis 30 ans. Arrivée de Nice
              à Paris à 18 ans avec un CAP d’esthétique en poche, art dans lequel elle excelle,
              Marie est devenue maquilleuse internationale.
            </Typography>

            <Typography className={classes.paragraphe}>
              Tokyo, Londres, Berlin, New York, elle a voyagé dans le monde entier. Elle a
              représenté les plus grandes marques de cosmétiques. C’est elle que les journalistes
              interviewaient, qu’ils (elles) photographiaient. C’était son visage que l’on voyait
              sur les pages glacées des magazines, donnant ses conseils avisés aux femmes du monde
              entier sur l’art de se maquiller à la française.
            </Typography>

            <Typography className={classes.paragraphe}>
              Elle a voyagé «
              <Paragrapth align="justify" colorBlue={true} addBackground={true}>
                comme un spoutnik !
              </Paragrapth>{' '}
              » dit-elle, des étoiles encore plein les yeux. Mais voilà, les années ont passé. Dans
              une multinationale qui vend du rêve et la promesse d’une jeunesse éternelle, les dames
              n’ont leur place que dans l’actionnariat.
            </Typography>

            <Typography className={classes.paragraphe}>
              Marie a perdu sa place de diva. Son manager ne voit plus en elle qu’un coût
              supplémentaire. Marie se trouve à la croisée des chemins. Elle peut rester, défendre
              sa place ou accepter le poste de vendeuse en boutique qu’on lui propose en interne.
              Elle peut aussi réaliser son rêve de créer un centre de beauté à Nice, la ville où
              elle rêve de retourner vivre…
            </Typography>
          </MarginContainer>
        </div>
      </div>
    </Page>
  );
};

export default Page08;
