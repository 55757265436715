import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/styles/createStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import { ebooksImagePath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC } from 'react';

interface IProps {
  children: any;
  bannerContainerOverride?: string;
  bannerStyleOverride?: string;
  childrenContainerOverride?: string;
  isLong?: boolean;
}

const SimpleBanner: FC<IProps> = ({
  children,
  bannerContainerOverride = '',
  bannerStyleOverride = '',
  childrenContainerOverride = '',
  isLong = false,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.bannerContainer, bannerContainerOverride)}>
      <img
        className={clsx(classes.banner, bannerStyleOverride)}
        src={
          isLong ? `${ebooksImagePath}/longSimpleBanner.svg` : `${ebooksImagePath}/simpleBanner.svg`
        }
        alt="simple banner"
      />
      <div className={clsx(classes.childrenContainer, childrenContainerOverride)}>{children}</div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: 500,
      height: 100,
      margin: 'auto',
      marginTop: 0,
      [theme.breakpoints.down(1300)]: {
        width: 300,
      },
    },
    banner: {
      width: '100%',
      // height: '100%',
      // display: 'block',
      margin: 'auto',
      marginTop: 0,
    },
    leftBanner: {
      flex: 1,
    },
    rightBanner: {
      flex: 1,
    },
    bannerRotated: {
      transform: 'rotate(180deg)',
    },
    childrenContainer: {
      // marginTop: -97,
      width: '100%',
    },
  }),
);

export default SimpleBanner;
