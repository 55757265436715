export const tableData = [
  {
    id: '23-table-head-1',
    firstColumn: 'Patrimoine',
    children: [
      {
        id: '23-table-1-1',
        content: 'Êtes-vous propriétaire de votre résidence principale ?',
        name: 'A1',
      },
      {
        id: '23-table-1-2',
        content: 'Si oui, avez-vous terminé de rembourser votre crédit ?',
        name: 'A2'
      },
      {
        id: '23-table-1-3',
        content: 'Possédez-vous des placements financiers (livret A, assurances-vie, etc.) ?',
        name: 'A3'
      },
      {
        id: '23-table-1-4',
        content: 'Possédez-vous des biens immobiliers (autres que votre résidence principale) ?',
        name: 'A4'
      },
      {
        id: '23-table-1-5',
        content: 'Disposez-vous d’un capital spécial pour réussir votre projet ?',
        name: 'A5'
      },
    ],
  },
  {
    id: '23-table-head-2',
    firstColumn: 'Salaire et rémunération',
    children: [
      {
        id: '23-table-2-1',
        content: 'Cotisez-vous à l’Assurance chômage ?',
        name: 'B1'
      },
      {
        id: '23-table-2-2',
        content: 'Êtes-vous actuellement en CDD ou en contrat de mission ?',
        name: 'B2'
      },
      {
        id: '23-table-2-3',
        content: 'Êtes-vous âgé(e) de plus de 50 ans ?',
        name: 'B3'
      },
      {
        id: '23-table-2-4',
        content: 'Travaillez-vous dans un groupe ou une administration de plus de 1000 salarié(e)s ?',
        name: 'B4'
      },
      {
        id: '23-table-2-5',
        content: 'Votre structure actuelle cherche-t-elle à réduire ses effectifs ?',
        name: 'B5'
      },
    ],
  },
  {
    id: '23-table-head-3',
    firstColumn: 'Votre famille et vos proches',
    children: [
      {
        id: '23-table-3-1',
        content: 'Votre conjoint(e) a-t-il (elle) une situation professionnelle stable ?',
        name: 'C1'
      },
      {
        id: '23-table-3-2',
        content: 'La rémunération de votre conjoint(e) suffit-elle à payer les charges du foyer ?',
        name: 'C2'
      },
      {
        id: '23-table-3-3',
        content: 'Avez-vous ou aurez-vous à charge les études supérieures de vos enfants ?',
        name: 'C3'
      },
      {
        id: '23-table-4-4',
        content: 'Avez-vous ou aurez-vous à charge la maison de retraite de vos parents ?',
        name: 'C4'
      },
      {
        id: '23-table-4-5',
        content: 'Des proches sont-ils prêts à soutenir financièrement votre projet ?',
        name: 'C5'
      },
    ],
  },
];
