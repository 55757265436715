/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import {
  Page,
  ParagraphListNumberWithMultipleParagraphs,
  Paragrapth,
  TitleNbr,
  TitlePage,
} from '@orientaction/components';
import ParagraphWithMargin from '../../components/ParagraphWithMargin';
import { paragraphs } from './constant';
import useStyles from './style';

const softSkills = [
  {
    firstColumn: 'RÉSOLUTION DE PROBLÈMES',
    secondColumn: 'ESPRIT D’ENTREPRENDRE',
  },
  {
    firstColumn: 'COMMUNICATION',
    secondColumn: 'AUDACE',
  },
  {
    firstColumn: 'CONFIANCE',
    secondColumn: 'AUTOMOTIVATION',
  },
  {
    firstColumn: 'INTELLIGENCE ÉMOTIONNELLE',
    secondColumn: 'VISION',
  },
  {
    firstColumn: 'EMPATHIE',
    secondColumn: 'PRÉSENCE',
  },
  {
    firstColumn: 'GESTION DU TEMPS',
    secondColumn: 'SENS DU COLLECTIF',
  },
  {
    firstColumn: 'GESTION DU STRESS',
    secondColumn: 'CURIOSITÉ',
  },
  {
    firstColumn: 'CRÉATIVITÉ',
    secondColumn: 'ESPRIT CRITIQUE',
  },
];

const savoirs = [
  {
    label: 'Les savoirs',
    texts: [
      `Ils correspondent à l’ensemble de nos connaissances. Il s’agit de notre encyclopédie
    personnelle. Nous mobilisons cette encyclopédie de connaissances chaque fois que
    nous devons résoudre un problème ou répondre à une question.`,
      `
    Des connaissances en droit, en biologie, en psychologie constituent autant de savoirs
utiles, voire indispensables pour guider notre action. Aujourd’hui, avec Internet, les
savoirs sont très liés à un savoir-faire : la capacité à rechercher et à trouver la bonne
information.
    `,
    ],
    number: 1,
  },
  {
    label: 'Les savoir-faire',
    texts: [
      `Ils correspondent à notre capacité à réaliser des actions qui
        produisent un résultat. Les savoir-faire s’acquièrent par la pratique et sont consolidés
        par l’expérience. Plus une personne est expérimentée, plus ses chances d’obtenir le
        résultat souhaité sont élevées. Attention, un savoir-faire est contextuel. Nous savons
        faire quelque chose dans un environnement donné, pas forcément dans un autre. Par
        exemple, un(e) commercial(e) ne sait pas vendre. Il (elle) sait vendre certains produits
        à un certain type de personnes. `,
    ],
    number: 2,
  },
  {
    label: 'Les savoir-être',
    texts: [
      `Ils correspondent à un répertoire d’attitudes qui nous permettent de
      nous adapter aux codes et aux rituels sociaux.`,
      `Savoir rentrer en relation, conduire un échange et le terminer, font appel à un ensemble
      d’attitudes comportementales et verbales qui constituent nos savoir-être.`,
      `Plus on progresse dans la hiérarchie et plus les savoir-être sont importants au détriment
      des savoir-faire. Le manager (la manageuse) fait plus appel à des savoir-être qu’à des
      savoir-faire.`,
    ],
    number: 3,
  },
];

const Page12 = () => {
  const classes = useStyles();
  return (
    <Page id={12}>
      <TitlePage titlePageOverrideStyle={classes.titlePageOverrideStyle}>
        LES 16 « SOFT SKILLS » LES PLUS PRISÉES <br />
        DANS LE DOMAINE PROFESSIONNEL :
      </TitlePage>
      <Card variant="outlined" style={{marginBottom: 40}} >
        {softSkills.map((sk: any, i: any) => (
          <Grid
            key={i}
            container={true}
            style={{
              borderTop: '1px solid #2B56A5',
              borderLeft: '1px solid #2B56A5',
              borderBottom: i === softSkills.length - 1 ? '1px solid #2B56A5' : '',
            }}
            justifyContent="flex-end">
            <Grid
              item={true}
              xs={12}
              sm={6}
              style={{
                position: 'relative',
                textAlign: 'center',
                border: '1px solid #2B56A5',
              }}>
              <Typography component="p" className={classes.h7}>
                {sk.firstColumn}
              </Typography>
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={6}
              style={{
                position: 'relative',
                textAlign: 'center',
                border: '1px solid #2B56A5',
              }}>
              <Typography component="p" className={classes.h7}>
                {sk.secondColumn}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Card>
      <MarginContainer>
        <TitleNbr number={3} text="LES 3 SAVOIRS (LES BRANCHES)" />
      </MarginContainer>
      <ParagraphWithMargin lists={paragraphs} />
      <MarginContainer>
        <ParagraphListNumberWithMultipleParagraphs
          overrideParagraphStyle={classes.overrideParagraphStyle}
          list={savoirs}
          paragraphAlign="justify"
        />
      </MarginContainer>
    </Page>
  );
};

export default Page12;
