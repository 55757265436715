import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '2.5vh 3%',
      borderRadius: '8px',
      backgroundColor: 'white',
      marginTop: '5%',
      [theme.breakpoints.down(800)]: {
        padding: '2.5vh 4%',
      },
    },
    resultDetailsTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      color: '#0E1247',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down(800)]: {
        margin: '2vh 1.5vh',
        fontSize: '16px',
      },
    },
    resultDetailsContentElement: {
      // maxWidth: '769px',
    },
    resultDetailsParagraph: {
      margin: '1vh 0',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '19px',
      lineHeight: '170%',
      color: '#0E1247',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 1.5vh',
        fontSize: '16px',
        lineHeight: '150%',
      },
    },
  }),
);
