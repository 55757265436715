import EmptyPlaceholder from '@orientaction/commons/EmptyPlaceholder';
import classNames from 'classnames';
import { FC } from 'react';
import style from './style';

interface IProps {
  hasError?: boolean;
}

const NoQuestion: FC<IProps> = ({ hasError = false }) => {
  const classes = style();
  return (
    <EmptyPlaceholder
      overrideStyle={classNames(classes.root, hasError && classes.error)}
      placeholder="Aucune question créée"
    />
  );
};

export default NoQuestion;
