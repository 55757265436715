/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { stopRenderAction } from '@orientaction/api-actions';
import { appStopRender } from '@orientaction/reducers';
import { IStopRender } from '@orientaction/types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStopRender = () => {
  const dispatch = useDispatch();

  const state: IStopRender = useSelector(appStopRender);

  const setYouCan = (bool: boolean) => {
    dispatch(stopRenderAction.setYouCan(bool));
  };

  const setRef = (ref: any) => {
    dispatch(stopRenderAction.setRef(ref));
  };

  return {
    ...state,
    setYouCan,
    setRef,
  };
};

export default useStopRender;
