import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    imageBtn: {
      width: '90%',
      height: 'auto',
      display: 'block',
      marginTop: 45,
    },
    displayFlexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    titleHeader: {
      fontSize: 45,
      color: 'white',
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.down(600)]: {
        padding: '40px 0px 0px 0px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 32,
      },
    },
    header: {
      background:
        'transparent linear-gradient(106deg, #000A8C 0%, #E63C3C 47%, #FFDC37 100%) 0% 0% no-repeat padding-box',
    },
    imageCenterMobile: {
      '@media only screen and (max-width: 600px)': {
        display: 'flex',
        justifyContent: 'center',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  }),
);
