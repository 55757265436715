// eslint-disable-next-line import/no-unresolved
import {
  getHeaders,
  getStrapiFilter,
  getUserStatusStrapiFilter,
  makeRequest,
} from '@orientaction/utils';
import config from './config';

const url = config.api_url;

export const getResultOfTest = async (
  companyId: any,
  token: string,
  data: any,
  isHarmony = false,
  testFilters = [],
  userFilters = {}
) => {
  const filtersTransformed = testFilters.map((filter: any) => getStrapiFilter(filter, isHarmony));
  const userStatusFiltersTransformed = getUserStatusStrapiFilter(userFilters, isHarmony);
  const filters = filtersTransformed.concat(userStatusFiltersTransformed);
  // add filters into data object
  const postData = { ...data, company: companyId, filters };
  return makeRequest(`${url}/api/result-of-test-custom`, 'POST', postData, getHeaders(token));
};

export const deleteResultOfTest = async (token: string, data: any) => {
  return makeRequest(`${url}/api/delete-result-of-test-custom`, 'POST', data, getHeaders(token));
};
