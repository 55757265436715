import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import ReactHtmlParser from 'react-html-parser';
import AccountConfirm from './AccountConfirm';
import AuthFormHeaderLink from './AuthFormHeaderLink';

interface IProps {
  title: string;
  subtitle: string;
  classname?: string;
  showLink?: boolean;
  noSubtitle?: boolean;
  confirmType?: string;
  questionText: string;
  redirectText: string;
  linkTo: string;
  handleAccountConfirm?: any;
  showAccountConfirm?: boolean;
}

const AuthHeader: FC<IProps> = ({
  title,
  subtitle,
  classname,
  showLink = true,
  questionText,
  redirectText,
  linkTo,
  handleAccountConfirm,
  showAccountConfirm = false,
  noSubtitle = false,
  confirmType = 'register',
}) => {
  const cls = style();
  return (
    <div className={cls.formHeader}>
      {showAccountConfirm ? (
        <AccountConfirm
          title="Confirmation"
          subtitle={
            confirmType === 'register'
              ? 'Vous venez de recevoir un email pour activer votre compte'
              : 'Un email contenant les instructions pour'
          }
          additionalText={
            confirmType === 'register'
              ? 'Veuillez suivre les instructions'
              : 'réinitialiser votre mot de passe vient de vous être envoyé'
          }
        />
      ) : (
        <>
          <h1 className={cls.title} data-cy="title-header-account">
            {ReactHtmlParser(title)}
          </h1>
          {!noSubtitle && <p className={cls.paragraph}>{subtitle}</p>}
          {showLink && (
            <AuthFormHeaderLink
              questionText={questionText}
              redirectText={redirectText}
              toText={linkTo}
            />
          )}
        </>
      )}
    </div>
  );
};
const style = makeStyles((theme: Theme) => ({
  formHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    // '@media (min-width: 1250px)': {
    //   marginTop: '10%',
    // },
  },
  paragraph: {
    height: 25,
    font: 'normal normal bold 20px ITC Avant Garde Gothic Std Medium',
    color: '#0E1247',
  },
  title: {
    margin: 'auto',
    color: '#000A8C',
    textAlign: 'center',
    font: 'normal normal bold 36px/48px ITC Avant Garde Gothic Std Bold',
    letterSpacing: 0,
    opacity: 1,
  },
  alreadyRegisteredContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));
export default AuthHeader;
