import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  subtitle: {
    color: '#000A8C',
    fontSize: 26,
    marginTop: 15,
  },
}));
