/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress } from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useResponseUser, useResultsClient, useAlert } from '@orientaction/hooks';
import localText from '../../../components/UserResult/WithResult/locale.json';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFromLS } from '@orientaction/utils';
import withWidth from '@material-ui/core/withWidth';
import { NoResult, Layout, HistoryMajorTest } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { useStyles } from './style';
import BtnCanceled from '../BtnCanceled';
import FirstView from './FirstView';
import ViewResume from './ViewResume';
import { fakeResultToShow, fakeResults } from './context';

Chart.register(ChartDataLabels);

const ResultatHarmony = () => {
  const classes = useStyles();

  const { id: testId }: any = useParams();

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const results = fakeResults;
  const resultToShow = fakeResultToShow[0];

  return (
    <div>
      <div className={classes.btnHistory}>
        <HistoryMajorTest
          testResults={results || []}
          idTest={+testId}
          toRedirect="/resultat-harmony"
        />
      </div>

      <div className={classes.root}>
        <BtnCanceled id={1} />

        <MarginContainer mormal={true}>
          <h1 className={classes.resultTitle}>Vos résultats - Test Harmony 16 soft skills </h1>
        </MarginContainer>

        <div className={classes.grid}>
          <div className={classes.first}>
            <FirstView resultToShow={resultToShow} />
          </div>

          <div className={classes.second}>
            <ViewResume resultToShow={resultToShow} localText={localText} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withWidth()(ResultatHarmony);
