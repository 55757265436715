import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  inputError: {
    border: `2px solid red`,
  },
  textError: {
    color: 'red',
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#0E1247',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  marginTop: {
    marginTop: 15,
  },
  fullWidth: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
  },
}));
