import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 255,
        display: 'block',
        maxWidth: 400,
        overflow: 'hidden',
        width: '100%',
    },
    contentTitle: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px 0",
        gap: "10px",
        textAlign: 'center',
        width: "100%",
        height: "62px",
        background: "#FFF350",
        borderRadius: '4px',
        flex: "none",
        order: 0,
        flexGrow: 0,

    },
    title: {
        width: "27px",
        height: "50px",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '140 %',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#2B56A5',
        flex: 'none',
        order: 0,
        flexGrow: 0
    },
    contentNumber: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px 5px',
        gap: '10px',
        height: '175px',
        background: ' #FFFFFF',
        borderRadius: '4px',
        flex: 'none',
        order: 0,
        alignSelf: 'stretch',
        flexGrow: 1,
        border: ' 1px solid #CDCEDA',
    },
    titleContentNumber: {
        width: '100%',
        fontFamily: 'Poppins',
        fontFtyle: 'normal',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: ' 140%',
        alignItems: 'center',
        textAlign: 'center',
        color: '#2B56A5',
        flex: 'none',
        order: 0,
        flexGrow: 0,
    },
    fabActive: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        height: '44px',
        background: '#2B56A5',
        borderRadius: '50px',
        flex: 'none',
        order: 0,
        flexGrow: 1,
        color: "white",
    },
    fabDefault: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        width: '100%',
        height: '44px',
        background: '#FFFFFF',
        border: ' 1px solid #CDCEDA',
        borderRadius: '44px',
        flex: 'none',
        order: 3,
        flexGrow: 1,
        margin: "0",
        cursor: 'pointer',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '6px 0px',
        width: '100%',
        height: '37px',
        background: '#2B56A5',
        borderRadius: '4px',
        flex: 'none',
        order: 3,
        flexGrow: 0
    },
    footerTitle: {
        height: '25px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '140 %',
        alignItems: 'center',
        color: 'white',
    }
}));


export default useStyles;