/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, Paragrapth } from '@orientaction/components';
import { useMemo, useState } from 'react';
import { useLanguage } from '@orientaction/hooks';

const Page14 = (props: any) => {
  const classes = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_illustration_page_14.png' : 'illustration_page_20.jpg';
  }, [language]);
  return (
    <Page id={14}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true}>
            LA PYRAMIDE DE MASLOW<sup>1</sup>
            <br />
            ET LES 6 FIGURES DU CHEF
          </Typography>
        </div>
        <div
          className={clsx(classes.trait, classes.positionCenter)}
          style={{ marginTop: '50px', marginBottom: '50px' }}
        />
        <div>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Comme Robinson, chaque être humain a besoin de satisfaire 6 besoins pour être heureux et
            performant. Ces besoins peuvent être représentés sous la forme d’une pyramide :{' '}
            <Paragrapth align="justify" colorBlue={true}>
              {' '}
              <strong>
                La pyramide de Maslow <sup>1</sup>
              </strong>{' '}
            </Paragrapth>
            .
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Maslow considère que l’on doit tout d’abord satisfaire nos besoins primaires (situés à
            la base de la pyramide), avant de pouvoir satisfaire nos besoins secondaires (situés
            plus haut dans la pyramide). En réalité, chaque être humain est gouverné par ses propres
            besoins sans qu’il soit possible d’établir une hiérarchie entre eux.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={1} text="LA PYRAMIDE DES BESOINS" />
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={12}>
            <img className={classes.img} src={`${imgBook2}/${image}`} alt="logo" />
            <Typography
              className={classes.paragraphe1}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              <sup>1</sup> Celles et ceux qui connaissent déjà la pyramide de Maslow remarqueront
              qu’il s’agit ici d’une adaptation de la pyramide originale.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px' }}>
        <TitleNbr number={2} text="LE MODÈLE DES CHEF(FE)S" />
        <div>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Il y a plusieurs choses qui peuvent vous rendre heureux(se) et performant(e) dans une
            organisation (entreprise, administration, association). Parmi elles, une me semble
            particulièrement importante, c’est la qualité de la relation avec votre hiérarchie. La
            qualité de cette relation est en grande partie déterminée par la capacité de votre
            manager(euse) à répondre à vos besoins, d’où l’intérêt de mieux les connaître. Afin de
            parvenir à cette meilleure connaissance de soi, j’ai élaboré un modèle qui permet
            d’identifier facilement les types de chef(fe) qui existent.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Pour cela, je suis parti du postulat qu’il n’existe aucune école qui forme véritablement
            au métier de manager(euse). Lorsque l’on devient chef(fe), c’est le plus souvent à cause
            du degré d’expertise technique, du niveau de diplôme ou de l’âge. Pour exercer cette
            fonction, on s’inspire alors de figures historiques d’autorité que l’on connaît et dont
            on va adopter les réflexes : le père (la mère), le seigneur (la seigneuresse), le roi
            (la reine), le général (la générale), le maître et l’empereur (l’impératrice)
            constituent ces grandes figures d’autorité.
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    colorPrimaryBook: {
      color: '#2b56a5',
    },
  })
);
export default Page14;
