
// eslint-disable-next-line no-unused-vars
import { createStyles, makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            padding: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiBackdrop-root': {
                background: '#0e124780 0% 0% no-repeat padding-box',
            },
        },
        '@media screen and (min-width:720px)': {
            modal: {
                '& .MuiDialog-paperWidthSm': {
                    minWidth: 660,
                    textAlign: 'center',
                    borderRadius: 20,
                },
            },
        },
        centerData: {
            justifyContent: 'center !important',
        },
        btnRight: {
            '&.MuiButton-root': {
                marginLeft: 20,
            },
        },
        validate: {
            width: 'auto',
            background:
                'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
            color: '#fff',
        },
        containerBtn: {
            margin: "2vh"
        },
        modalContent: {},
        modalTitle: {
            color: '#E63C3C',
            marginBottom: 30,
        },
        modalText: {
            fontSize: 14,
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
        },
        loading: {
            padding: "5vh"
        }
    })
);


const styles = (theme: any) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
            color: '#0E1247',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: '#0E1247',
        },
        colorStandar: {
            color: '#0E1247',
        }
    });

export { styles, useStyles }