import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: '#0E1247',
      fontSize: '13px',
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      letterSpacing: 0,
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247',
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    btnWhite: {
      backgroundColor: '#fff#',
      color: '#0E1247',
      marginTop: 0,
      marginRight: 10,
      borderRadius: 3,
      opacity: 1,
      border: '1px solid grey',
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {
        padding: '1vh',
        minHeight: 48,
        height: 48,
        width: 140,
        minWidth: 130,
      },
    },
    textHeader: {
      display: 'none',
      marginLeft: 0,
      '@media(min-width: 600px)': {
        display: 'block',
      },
    },
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      '@media(min-width: 600px)': {},
    },
    marginTop: {
      marginTop: 15,
    },
    modalContent: {},
    modalText: {
      fontSize: 14,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
    bookChoiceContainer: {
      backgroundColor: '#EDEEF2',
      color: '#0E1247',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      marginTop: 10,
      paddingLeft: 10,
    },
    bookChoiceLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#0E1247',
      fontSize: '16px',
      marginBottom: 10,
    },
  })
);
