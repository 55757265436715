/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2, imgBookIntro } from '../../../../utils/constants';

const Page16 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={16}>
      <div style={{ marginBottom: '50px' }}>
        <TitleNbr number={2} text="LA VIE EST FAITE POUR ÊTRE HEUREUX(SE)" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Dans la culture judéo-chrétienne, l’existence est parfois synonyme de sacrifice. C’est
              en surmontant les épreuves et en résistant aux tentations que nous gagnerions alors
              notre place au paradis.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Doit-on forcément adhérer à cette vision? De notre point de vue, la vie est faite pour
              être heureux(se), pour prendre du plaisir, pour s’épanouir, dans le respect de
              soi-même et des autres.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Cela signifie penser à son propre bonheur et le rechercher avant de penser au bonheur
              des autres et/ou à l’intérêt collectif. Cela signifie savoir être égoïste et penser
              parfois à soi avant de penser aux autres.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <TitleNbr number={3} text="CHANGER, C’EST ÊTRE LIBRE" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Bien souvent, nous devenons ce que les autres — nos parents, nos professeurs, la
              société — veulent que nous devenions.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Tout le monde veut nous faire rentrer dans un moule. Je me bats chaque jour contre
              cette fatalité. Personne n’a à être déterminé par ses origines, son histoire ou sa
              condition.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Changer, c’est exercer sa liberté et son libre arbitre! C’est choisir et réaliser ce
              que l’on a choisi de réaliser. Nous pouvons être autre chose que ce que les autres
              veulent que nous soyons. Nous sommes libres de choisir notre destin.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <img
        src={`${imgBookIntro}/rock.png`}
        alt="rock"
        style={{
          width: '45%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
    },
  })
);
export default Page16;
