// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
} from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';

const Page14 = (props: any) => {

    return (
        <Page
            id={14}
        >

            <MarginContainer>
                <TitlePage>
                    LES ÉVOLUTIONS VERTICALES <br />
                    DESCENDANTES
                </TitlePage>
            </MarginContainer>

            {
                context.map((item: any) => (
                    <MarginContainer>

                        <Paragrapth align='justify' colorBlue={true} bold={true}>
                            <h3>
                                {item.title || ""}
                            </h3>
                        </Paragrapth>

                        <Paragrapth align='justify'>
                            {item.paragraph || ""}
                        </Paragrapth>

                    </MarginContainer>
                ))
            }
        </Page>
    );
};
export default Page14;
