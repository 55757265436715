/* eslint-disable react-hooks/exhaustive-deps */
import { Theme, useMediaQuery } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useLanguage } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getCompassTestYearHistories } from '../../services/user.service';
import { AccordionDetails, useStyles } from './style';

const CompassTestHistoryPerYear = () => {
  const [years, setYears] = useState([]);

  const { year, userId }: any = useParams();

  const { language } = useLanguage();

  const token = getFromLS('userToken');
  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  useEffect(() => {
    if (token && userConnected.id) {
      const idUser = userId || userConnected.id;
      getCompassTestYearHistories(token, idUser).then((response) => {
        setYears(response.data.compassTestYearHistories);
      });
    }
  }, []);

  const classes = useStyles();

  const [openHistory, setOpenHistory] = useState<boolean>(false);

  const history = useHistory();

  const handleChange = (currentYear: number) => (e: any) => {
    const isAdmin = userConnected?.company_user_role?.id !== 5;
    if (isAdmin) {
      history.push(`/admin/${userId}/boussole/${currentYear}`, { force: true });
    } else {
      history.push(
        `${language === 'GB' ? `/en/my-compass/${currentYear}` : `/ma-boussole/${currentYear}`}`,
      );
    }
  };

  const handleOpenhHistory = () => {
    setOpenHistory(!openHistory);
  };

  const isMobil = useMediaQuery((theme: Theme) => theme.breakpoints.down(900));

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(900));

  return (
    <MuiAccordion
      expanded={openHistory}
      onChange={handleOpenhHistory}
      className={clsx({
        [classes.rootAccordion]: true,
        [classes.accordionMobileClose]: !openHistory && isMobil,
      })}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        className={classes.timelineTitle}
      >
        <div>
          <DateRangeIcon fontSize="small" style={{ marginRight: 7, marginTop: 2 }} />
        </div>

        {((isMobil && openHistory && !isDesktop) || isDesktop) && <div>Historique</div>}
      </MuiAccordionSummary>

      <AccordionDetails>
        <ul className={classes.ulHistory}>
          {years.map((historyYear: any) => {
            return (
              <li
                className={clsx({
                  [classes.labelPeriod]: true,
                  [classes.isHighlighted]: historyYear === +year,
                })}
              >
                <div onClick={handleChange(historyYear)}>{historyYear}</div>
              </li>
            );
          })}
        </ul>
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default CompassTestHistoryPerYear;
