import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    withBorder: {
      borderBottom: '3px solid #6D6D6D',
    },
    titleContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '125px',
      height: '30px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      color: '#0E1247',
      [theme.breakpoints.down(900)]: {
        width: '84px',
        fontSize: '12px',
      },
    },
    contentPodium: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '14px',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '22px',
      color: '#fff',
      [theme.breakpoints.down(900)]: {
        width: '84px',
        fontSize: '22px',
      },
    },
    first: {
      width: '125px',
      height: '205px',
      background: '#ED6637',
      borderRadius: '20px 20px 0px 0px',
      [theme.breakpoints.down(900)]: {
        width: '84px',
        height: '144px',
      },
    },
    second: {
      width: '125px',
      height: '150px',
      background: '#F7A82E',
      borderRadius: '20px 20px 0px 0px',
      [theme.breakpoints.down(900)]: {
        width: '84px',
        height: '102.75px',
      },
    },
    third: {
      width: '125px',
      height: '120px',
      background: '#FDCE29',
      borderRadius: '20px 20px 0px 0px',
      [theme.breakpoints.down(900)]: {
        height: '80.25px',
        width: '84px',
      },
    },
    textFLoating: {
      fontSize: '11px',
      marginTop: '20px',
    },
  })
);
