import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    img: {
      width: '120px',
      height: '120px',
      [theme.breakpoints.down('sm')]: {
        width: '75px',
        height: '75px',
      },
      borderRadius: 10,
    },
    cardContainer: {
      padding: '10px',
      borderRadius: '10px',
      boxShadow: 'none',
      backgroundColor: 'white',
    },
    fontTitleCard: {
      textDecoration: 'none',
      fontFamily: 'Poppins',
      fontWeight: 700,
      color: '#000A8C',
      cursor: 'pointer',
      fontSize: '18px',
      [theme.breakpoints.down(1440)]: {
        fontSize: '15px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14.5px',
      },
    },
    flexDiplay: {
      float: 'left',
      display: 'flex',
      alignItems: 'center',
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      marginTop: -32,
      elevation4: {
        boxShadow: 'none',
      },
    },
  })
);

export default useStyles;
