/* eslint-disable no-useless-return */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Theme, Typography, useMediaQuery, Button, Hidden, Drawer, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { ApiActions, apiState } from '@orientaction/api-actions';
import { Layout, Modal, SimpleModal, SimpleModalTest } from '@orientaction/components';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrientButton from '../../commons/OrientButton';
import { GB_PREFIX, publicSvgPath } from '../../utils/constants';
import style from './style';
import { useLanguage } from '@orientaction/hooks';
import { useEffect, useState } from 'react';
import { blockRedirection, getFromLS, updateUserInfoInLS, isAdmin } from '@orientaction/utils';
import { userService } from '@orientaction/services';
import { getMe } from '../../services/user.service';
import ChipTime from '@orientaction/commons/ChipTime';
import { Close } from '@material-ui/icons';
import { kebabCase } from 'lodash';


const HomePageUser2 = (props: WithWidth) => {
  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();
  const { language } = useLanguage();

  const redirect = (index: any) => {
    dispatch(ApiActions.setTabLayout(index));
    
    switch (index) {
      case 1:
        history.push('/user/tests');
        break;
      case 2:
        history.push(`${language === GB_PREFIX ? `/en/my-results` : `/mes-resultats`}`);
        break;
      case 3:
        history.push(`${language === GB_PREFIX ? `/en/my-resources` : `/mes-ressources`}`);
        break;
      case 4:
        history.push(
          `${
            language === GB_PREFIX
              ? `/en/my-compass/${new Date().getFullYear()}`
              : `/ma-boussole/${new Date().getFullYear()}`
          }`,
        );
        break;
      default:
        break;
    }
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState<boolean>(false);
  const token = getFromLS('userToken') || '';
  // const userConnected = JSON.parse(getFromLS('userInfos') || '{}');
  const user = JSON.parse(getFromLS('userInfos') || '');
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [testsToDo, setTestsToDo] = useState<any>([]);

  const { push } = useHistory();

  const { tabLayout } = useSelector(apiState);

  const gotTo = async (idTest: any, title: string) => {
    push(`/test/${idTest}/${kebabCase(title)}`);
  };

  useEffect(() => {
    const fetchMe = async () => {
      const dataMe = await getMe(token);
      if (dataMe && dataMe.data) {
        setTestsToDo([...dataMe.data.tests_to_do]);
        if (!user?.secondConnexion && dataMe.data.isMyFirstTest) {
          setOpenDrawer(dataMe.data.tests_to_do && dataMe?.data?.tests_to_do?.length > 0);
          updateUserInfoInLS({ secondConnexion: true });
        }
        console.log({user})
      }
    };
    fetchMe();
  }, []);

  const checkFirstConnexion = async () => {
    const dataMe = await getMe(token as string);
    if (
      !dataMe.data.isMyFirstTest &&
      dataMe.data.beneficiaryType === 'rattached' &&
      !isAdmin(dataMe.data)
    ) {
      await updateFirstConnexion();
    }
  };

  const updateFirstConnexion = async () => {
    try {
      updateUserInfoInLS({ isMyFirstTest: true });
      await userService.modifyUser({
        token,
        isMyFirstTest: true,
        id: user?.id,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    console.log({tabLayout})
    if (tabLayout === 0) {
      checkFirstConnexion();
    }
  }, [tabLayout]);

  return (
    <Layout isaddBackground={true} noPadding={true} isNew3={true} footer={true}>
      <div className={`${classes.paddingContainer} ${classes.paddingLargeHome}`}>
        <Grid container={true}>
          <Hidden smDown={true}>
            <Grid item={true} xs={12} sm={12} md={3} style={{ display: 'flex' }}>
              <img
                className={classes.img}
                src={`${
                  language === GB_PREFIX
                    ? `${publicSvgPath}/en_illustration_accueil.png`
                    : `${publicSvgPath}/illustration_accueil.png`
                }`}
                alt="logo"
              />
            </Grid>
          </Hidden>

          <Grid item={true} xs={12} sm={12} md={6} className={classes.flexCenter}>
            <Typography variant="h2" className={classes.titleHome}>
              Bienvenue <br />
              sur E-Orientaction !
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container={true}
          spacing={6}
          justifyContent="center"
          alignItems="stretch"
          direction={isTab ? 'column-reverse' : 'row'}
        >
          <Grid item={true} xs={12} sm={12} md={5}>
            <Hidden mdUp={true}>
              <img
                className={classes.img}
                src={`${
                  language === GB_PREFIX
                    ? `${publicSvgPath}/en_illustration_accueil.png`
                    : `${publicSvgPath}/illustration_accueil.png`
                }`}
                alt="logo"
                style={{ display: 'block', width: '70%' }}
              />
            </Hidden>
            <Card
              className={`${classes.cardContainer} ${classes.cardPseudo}`}
              style={{ height: '100%' }}
            >
              <div style={{ padding: 20 }}>
                <Typography color="primary" className={classes.fontTextCard}>
                  Benjamin Franklin disait :{' '}
                  <i>
                    {' '}
                    « il existe trois choses extrêmement dures en ce monde, le diamant, l’acier et
                    la connaissance de soi ».{' '}
                  </i>
                </Typography>
                <br />
                <Typography color="primary" className={classes.fontTextCard}>
                  Si j’ai une conviction, après avoir accompagné avec mes équipes{' '}
                  <b>plus de 50.000 personnes</b> dans leur évolution professionnelle et
                  personnelle, c’est que la connaissance de soi est la clef de la réussite et du
                  bonheur.
                </Typography>
                <Typography color="primary" className={classes.fontTextCard}>
                  Dans ce monde imprévisible et complexe, elle est ce qui vous permettra de prendre
                  les bonnes décisions, que cela concerne votre vie professionnelle (choix d’un
                  métier, d’une carrière, d’une orientation) ou votre vie personnelle (famille,
                  couple, ami(e)s, etc.) E-Orientaction a été créé pour vous aider à mieux vous
                  connaître et à réussir dans votre vie.
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  component="h6"
                  color="primary"
                  className={classes.fontTextCard}
                >
                  À vous de jouer,
                </Typography>
                <br />
                <Typography
                  variant="h6"
                  component="h6"
                  color="primary"
                  className={classes.fontTextCard}
                >
                  <b>Dr. Emeric Lebreton</b>
                </Typography>
                <Typography
                  variant="h6"
                  component="h6"
                  color="primary"
                  className={classes.fontTextCard}
                >
                  <i>Créateur d’E-Orientaction</i>
                </Typography>
                {/*
                <Typography
                  variant="h6"
                  component="h6"
                  color="primary"
                  className={classes.fontTextCard}>
                  Vous pouvez également nous contacter ici : {' '}
                  <a href="mailto:info@orientaction-groupe.com">
                    <strong className={classes.link}>info@orientaction-groupe.com</strong>
                  </a>
                </Typography> */}
              </div>
            </Card>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={7} className={classes.marginTopResp}>
            <Grid container={true} style={{ height: '100%' }} spacing={3}>
              <Grid item={true} xs={12} md={6}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.newBtnTest)}
                  onClick={() => redirect(1)}
                >
                  <div className={clsx(classes.imgContainer, classes.btn40Per)}>
                    <img
                      className={classes.newPictoImage}
                      src={`${publicSvgPath}/backgroundBtn/picto_bouton_faire_un_test.svg`}
                      alt="logo"
                    />
                  </div>

                  <span
                    className={clsx(classes.newLabelBtn, classes.marginTopTest)}
                    data-cy="test-mineur"
                  >
                    Faire un test
                  </span>
                </OrientButton>
              </Grid>

              <Grid item={true} xs={12} md={6}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.newBtnResu)}
                  onClick={() => redirect(2)}
                >
                  <div className={clsx(classes.imgContainer, classes.imgResuResp)}>
                    <img
                      className={classes.newPictoImage}
                      src={`${publicSvgPath}/backgroundBtn/picto_bouton_mes_resultats.svg`}
                      alt="logo"
                    />
                  </div>
                  <span className={classes.newLabelBtn} data-cy="test-mineur">
                    Mes résultats
                  </span>
                </OrientButton>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.newBtnRes)}
                  onClick={() => redirect(3)}
                >
                  <div
                    className={clsx(classes.imgContainer, classes.imgContainerRes)}
                    style={{ width: '17%' }}
                  >
                    <img
                      className={classes.newPictoImage}
                      src={`${publicSvgPath}/backgroundBtn/picto_bouton_mes_ressources.svg`}
                      alt="logo"
                    />
                  </div>

                  <span
                    className={clsx(classes.newLabelBtn, classes.marginTopTest)}
                    data-cy="test-mineur"
                  >
                    Mes ressources
                  </span>
                </OrientButton>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.newBtnB)}
                  onClick={() => redirect(4)}
                >
                  <div className={clsx(classes.imgContainer, classes.btn45Per)}>
                    <img
                      className={classes.newPictoImage}
                      src={`${publicSvgPath}/backgroundBtn/picto_bouton_ma_boussole.svg`}
                      alt="logo"
                    />
                  </div>

                  <span className={classes.newLabelBtn} data-cy="test-mineur">
                    Ma boussole
                  </span>
                </OrientButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container={true}
          alignItems="stretch"
          style={{ marginTop: 50 }}
          justifyContent="center"
          spacing={3}
        >
          <Grid item={true} xs={12} sm={12} md={5}>
            <Card className={`${classes.cardContainer} ${classes.backImg} ${classes.backImgCarte}`}>
              <Chip
                label="WEB APP"
                className={classes.tagChip}
                style={{ background: '#242424', color: 'white' }}
              />
              <div style={{ padding: '30px 20px' }}>
                <Typography variant="h3" className={classes.titleCard}>
                  Jeu de cartes {!isMobile ? <br /> : ''}
                  des valeurs
                </Typography>
                <Typography
                  color="primary"
                  className={`${classes.fontTextCard} ${classes.fontTextCardDescription}`}
                >
                  Découvrez vos valeurs professionnelles
                </Typography>
                <img
                  src={`${
                    language === GB_PREFIX
                      ? `${publicSvgPath}/en_carte_bouton_jeu_carte.png`
                      : `${publicSvgPath}/carte_bouton_jeu_carte.png`
                  }`}
                  alt="couverture intro"
                  className={classes.imageSlide}
                />
              </div>
              <Button
                className={classes.btnBlock1}
                endIcon={<img src={`${publicSvgPath}/fleche_clic.svg`} alt="couverture intro" />}
                onClick={() => 
                  blockRedirection({language, onOpen: () => setOpenModal(true), functionToPass: () => {
                    window.open(
                      'https://jeudesvaleurs.orientaction-groupe.com/?_gl=1*1f9cc7c*_ga*MjA3NzM4MjQ3Ny4xNjM4MTk5OTI4*_ga_YH6Q45MREE*MTcwMzI3NjAzOC44MTIuMC4xNzAzMjc2MDM5LjAuMC4w1',
                      '_blank',
                    )
                  }})
                }
              >
                Jouer
              </Button>
            </Card>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={5}>
            <Card
              className={`${classes.cardContainer} ${classes.backImg} ${classes.backImgFormation}`}
            >
              <Chip
                label="Web app"
                className={classes.tagChip}
                style={{ background: '#242424', color: 'white', textTransform: 'uppercase' }}
              />
              <div style={{ padding: '30px 20px' }}>
                <Typography variant="h3" className={classes.titleCard} style={{ color: 'white' }}>
                  Révolution Robot
                </Typography>
                <Typography
                  color="primary"
                  className={`${classes.fontTextCard} ${classes.fontTextCardDescription}`}
                  style={{ color: 'white' }}
                >
                  « Mon job remplacé par un robot ? »
                </Typography>
                <Typography variant="h3" className={classes.fontSecondDesc}>
                  Évaluez les risques d’automatisation {!isMobile ? <br /> : ''}
                  de votre métier !
                </Typography>
                <Grid container={true} className={classes.marginTopImage}>
                  <Grid item={true} sm={6} md={4}>
                    <img
                      src={`${publicSvgPath}/robot_bouton_robot.svg`}
                      alt="couverture intro"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item={true} sm={6} md={4}>
                    <div className={classes.containerBtn}>
                      <img
                        src={`${
                          language === GB_PREFIX
                            ? `${publicSvgPath}/en_bouton_bouton_robot.svg`
                            : `${publicSvgPath}/bouton_bouton_robot.svg`
                        }`}
                        alt="couverture intro"
                        style={{ width: '100%' }}
                      />
                      <span
                        className={classes.btnInsideRobot}
                        onClick={() => {
                          blockRedirection({language, onOpen: () => setOpenModal(true), functionToPass: () => {
                            window.open(
                              'https://revolution-robot.fr',
                              '_blank',
                            )
                          }})
                        }}
                      >
                        Clique
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </div>

              {/* <img
                src={`${publicSvgPath}/fond_bouton_jeu_carte.png`}
                alt="couverture intro"
                className={classes.imageWithBtn}
              /> */}
            </Card>
          </Grid>

          {/**  */}

          <Grid item={true} xs={12} sm={12} md={5}>
            <Card
              className={`${classes.cardContainer} ${classes.backImg} ${classes.backImgCatalogue}`}
              style={{}}
            >
              <div style={{ padding: '30px 20px' }}>
                <Typography
                  variant="h3"
                  className={`${classes.titleCard} ${classes.marginTopTitle}`}
                  style={{ color: 'white' }}
                >
                  Catalogue {!isMobile ? <br /> : ''}
                  de formations
                </Typography>
                <Typography
                  color="primary"
                  className={`${classes.fontTextCard} ${classes.fontTextCardDescription}`}
                  style={{ color: 'white' }}
                >
                  En partenariat avec Galileo Education
                </Typography>
                <Typography variant="h3" className={classes.fontSecondDesc}>
                  Trouvez la formation certifiante {!isMobile ? <br /> : ''}
                  pour réussir votre projet !
                </Typography>
                <Grid
                  container={true}
                  justifyContent="space-between"
                  alignItems={isMobile ? 'flex-start' : 'flex-end'}
                  style={{ marginTop: 15 }}
                  direction={isMobile ? 'column-reverse' : 'row'}
                >
                  <Grid item={true} xs={6} sm={4} md={4} className={classes.floatBtn}>
                    <img
                      src={`${publicSvgPath}/livre_bouton_formation.svg`}
                      alt="couverture intro"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid
                    item={true}
                    xs={9}
                    sm={6}
                    md={6}
                    style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '4%' }}
                  >
                    <Button
                      endIcon={
                        <img src={`${publicSvgPath}/fleche_clic.svg`} alt="couverture intro" />
                      }
                      onClick={() => {
                        blockRedirection({language, onOpen: () => setOpenModal(true), functionToPass: () => {
                          window.open(
                            'https://orientaction.le-catalogue-formations.fr/',
                            '_blank',
                          )
                        }})
                      }}
                      
                      className={classes.floatBtn}
                    >
                      <span
                        style={{
                          textAlign: 'left',
                          color: 'white',
                          textTransform: 'none',
                          fontSize: 15,
                        }}
                      >
                        Choisir <br />
                        une formation
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>

          <Grid item={true} xs={12} sm={12} md={5}>
            <Card
              className={`${classes.cardContainer} ${classes.backImg}`}
              style={{ position: 'relative', background: `#0079DE`, height: '100%' }}
            >
              <Grid
                container={true}
                style={{ height: '100%' }}
                direction={isMobile ? 'column-reverse' : 'row'}
              >
                <Grid
                  item={true}
                  xs={12}
                  sm={7}
                  md={7}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img
                    className={`${classes.newPictoImage} ${classes.imgBoussole}`}
                    src={`${publicSvgPath}/backgroundBtn/picto_bouton_ma_boussole.svg`}
                    alt="logo"
                  />
                </Grid>
                <Grid
                  container={true}
                  item={true}
                  xs={12}
                  sm={5}
                  md={5}
                  direction="column"
                  style={{ height: '100%', flexWrap: 'nowrap' }}
                  justifyContent="space-between"
                >
                  <Grid item={true} sm={12} xs={12} md={12}>
                    <div className={classes.textTitleBoussole}>
                      <Typography
                        variant="h3"
                        className={`${classes.titleCard} ${classes.marginTopTitle}`}
                        style={{ color: 'white', textAlign: 'right' }}
                      >
                        Ma boussole
                      </Typography>
                      <Typography className={`${classes.fontTextCard} ${classes.fontRight}`}>
                        J’accède à la synthèse de résultats de mes tests réalisés
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item={true} className={classes.paddingBtnBoussole} sm={12} xs={12} md={12}>
                    <Button
                      endIcon={
                        <img src={`${publicSvgPath}/fleche_clic.svg`} alt="couverture intro" />
                      }
                      onClick={() => redirect(4)}
                      className={classes.btnBoussole}
                    >
                      <span
                        style={{
                          textAlign: 'left',
                          color: 'white',
                          textTransform: 'none',
                          fontSize: 15,
                        }}
                      >
                        Consulter <br />
                        ma boussole
                      </span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
      <SimpleModal open={openModal} handleClose={() => setOpenModal(false)} title="Information" message='Not yet available in English – work in progress.' />
      <Drawer
          anchor="bottom"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
          <IconButton
            aria-label="delete"
            className={classes.iconBtnImage}
            style={{ background: 'transparent' }}
            onClick={() => setOpenDrawer(false)}>
            <Close style={{ fontSize: 30, color: 'rgb(230, 60, 60)' }} />
          </IconButton>
          <div className={classes.contentDrawer}>
            <Typography variant="h2" color="primary" className={classes.titleTests}>
              Votre consultant(e) vous invite à passer les tests suivants
            </Typography>
            <div className={classes.separator} />
            <Typography variant="caption" className={classes.subTitleTests}>
              Cliquez sur un test pour y accéder
            </Typography>
            <div style={{ marginTop: 40 }}>
              {testsToDo &&
                testsToDo?.length > 0 &&
                testsToDo.map((test: any) => (
                  <div className={classes.testCard} onClick={() => gotTo(test?.id, test?.title)}>
                    <div>{test?.title}</div>
                    <ChipTime time={test.time} />
                  </div>
                ))}
            </div>
          </div>
        </Drawer>
    </Layout>
  );
};

export default withWidth()(HomePageUser2);
