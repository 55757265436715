/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line no-use-before-define
import { IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { FC } from 'react';


interface IAction {
    listAction: string[];
    actionBtn: (data: any, type: string) => (e: any) => void;
    rowOne: any;
    index: number;
}

const Action: FC<IAction> = ({
    index,
    rowOne,
    listAction,
    actionBtn,
}) => {

    return (
        <>
            <div>
                
                {
                    listAction.includes("disable") && (
                        <IconButton
                            onClick={actionBtn(rowOne, "enable")}
                            data-cy={`btn-enable-${index}`}
                        >
                            <RemoveCircleIcon
                                aria-label="enable"
                                style={{
                                    color: rowOne.isActivated ? "red" : "green"
                                }}
                            />
                        </IconButton>
                    )
                }

                {
                    listAction.includes("edit") && (
                        <IconButton
                            data-cy={`btn-edit-${index}`}
                            onClick={actionBtn(rowOne, "edit")}
                        >
                            <EditIcon />
                        </IconButton>
                    )
                }

                {
                    listAction.includes("delete") && (
                        <IconButton
                            onClick={actionBtn(rowOne, "delete")}
                            data-cy={`btn-delete-${index}`}
                        >
                            <DeleteForeverIcon />
                        </IconButton>
                    )
                }
            </div>
        </>

    )
}

export default Action;
