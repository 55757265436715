/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro } from '../../../../utils/constants';

const Page10 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={10}>
      <div>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            component="h1"
            color="primary"
            gutterBottom={true}
            className="opacity07">
            COMMENT VOUS ALLEZ CRÉER VOTRE AVENIR
            <br />
            AVEC LA MÉTHODE ORIENTACTION
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      </div>
      <div>
        <TitleNbr
          number={1}
          text="ADOPTER LES BONNES PRATIQUES DE CELLES ET CEUX QUI RÉUSSISSENT"
          isLight={true}
        />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              L’expérience de plus de 38000 personnes accompagnées par ORIENTACTION nous a permis
              d’identifier les bons et les mauvais comportements, ceux qui favorisent la réussite et
              ceux à éviter, mais aussi les trucs et astuces pour réussir efficacement votre
              évolution professionnelle.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Chaque personne que nous avons accompagnée sur le chemin de
              la réussite est un exemple à suivre. Vous pourrez, j’en suis sûr, vous en inspirer.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Le
              fait que les personnes aient accepté d’être citées dans ces livrets est une façon pour
              elles de partager avec vous leur réussite.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={2} text="TENIR COMPTE DE LA PSYCHOLOGIE DU CHANGEMENT" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Changer, c’est de l’action et des émotions ! Bien gérer ses émotions est un facteur
              essentiel de réussite. S’il existe des freins réels au changement, beaucoup
              d’obstacles sont de nature psychologique. Il s’agit de «croyances limitantes » ou d’un
              manque de confiance en soi. Mieux comprendre la psychologie du changement, c’est mieux
              comprendre les émotions que vous allez éprouver durant tout le processus, et c’est
              aussi apprendre à mieux les gérer. Cela vous permettra d’être plus serein(e) et de
              multiplier vos chances de réussite.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={3} text="ADOPTER LE MODE PROJET POUR AVANCER PAS-À-PAS" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Pour réussir son évolution professionnelle, il existe des étapes à respecter, cinq en
              tout. À chacune de ces étapes, il faut se poser les bonnes questions et mener les
              bonnes actions (recherches, rencontres, etc.).
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Ces cinq étapes sont pour votre consultant(e) une façon de baliser votre chemin.
              Personne ne peut gravir l’Everest en un jour, mais en un mois nombre de volontaires le
              peuvent.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Respecter ces étapes vous permettra d’avancer en toute sécurité, pas-à-pas et
              à votre rythme. N’oubliez jamais que le chemin parcouru est aussi important que le
              point d’arrivée !
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={4} text="BÉNÉFICIER D’UN ACCOMPAGNEMENT SUR MESURE" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Être accompagné(e) et guidé(e) par un(e) professionnel(le) s’appuyant sur une méthode
              éprouvée, avec des outils pertinents et une écoute personnalisée, permet de surmonter
              de nombreux obstacles et d’amorcer le changement dans la sérénité.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              ORIENTACTION vous propose un accompagnement sur mesure, avec un(e) consultant(e) expérimenté(e), qui
              vous écoutera sans vous juger et vous apportera son soutien, ses conseils et son
              énergie tout au long de la démarche. Vous ne serez pas seul(e) pour entreprendre le
              changement.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <img
        src={`${imgBookIntro}/help.png`}
        alt="help"
        style={{
          width: '60%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
      <Grid container={true} spacing={2} justifyContent="center">
        <Grid item={true} xs={12}>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
              fontSize: 10,
            }}>
            Les « croyances limitantes » sont des pensées qui empêchent de réaliser certaines
            actions qui sont pourtant à notre portée. Ces pensées prennent la forme de phrases du
            type : « ce n’est pas possible », « je ne peux pas y arriver », « je ne suis pas assez
            intelligent(e) pour… ». Les « croyances limitantes » sont un frein interne au
            changement.
          </Typography>
        </Grid>
      </Grid>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.7,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page10;
