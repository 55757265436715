import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '35vh',
      position: 'relative',
      left: '8vh',
      [theme.breakpoints.down('sm')]: {
        width: '40%',
        left: 0,
        top: 0,
      },
    },
    bannerContainerOverride: {
      marginBottom: 54,
    },
    bannerTitleContainer: {
      // margin: 'auto',
      marginTop: -138,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        marginTop: -88,
      },
    },
    bannerTitleContainer1: {
      // margin: 'auto',
      marginTop: 50,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        marginTop: 27,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      textAlign: 'center',
      fontSize: 20,
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    bannerStyleOverride: {
      width: '100%',
    },
    bannerTitleContainer2: {
      margin: 'auto',
      marginTop: 51,
    },
    bannerTitle2: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      color: 'white',
      fontSize: 14,
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
      [theme.breakpoints.down(1160)]: {
        fontSize: 10,
      },
    },
    childrenContainerOverride: {
      position: 'absolute',
      top: 36,
    },
    content: {
      width: '80%',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
  }),
);

export default useStyles;
