// eslint-disable-next-line simple-import-sort/imports
import { FC, useEffect, useState } from 'react';
import useStyles from './style';
import { useTheme } from '@material-ui/core/styles';
import { Button, Fab, Theme, useMediaQuery } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';

interface IButtonSomaire {
  href: string;
}

const ButtonSomaire: FC<IButtonSomaire> = ({ href }) => {

  const classes = useStyles();

  const [icon, setIcon] = useState<string>('down');

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const { pathname } = useLocation();

  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleClick = (event: any) => {

    if (pathname.includes('ebook')) {

      const elem: any = document?.getElementById(href);

      const rect: any = elem?.getBoundingClientRect();

      const nowTop = 700;

      const nowBottom = 1000;

      if (rect?.top < 0 && rect.bottom < nowBottom) {
        if (icon !== 'up') {
          setIcon('up');
        }
      }

      if (rect?.top < nowTop && rect.bottom > nowBottom) {
        if (icon !== 'now') {
          setIcon('now');
        }
      }

      if (rect?.top > nowTop) {
        if (icon !== 'down') {
          setIcon('down');
        }
      }

    }

  };

  const iconIt = () => {
    if (icon === 'down') {
      return <KeyboardArrowDownIcon />;
    }

    if (icon === 'now') {
      return <KeyboardArrowLeftIcon />;
    }

    return <KeyboardArrowUpIcon />;
  };

  window.addEventListener('scroll', handleClick, true);

  return (
    <Zoom in={true} timeout={transitionDuration} unmountOnExit={true}>
      <a href={`#${href}`} className={classes.aButton}>
        {isXs ? (
          <Fab className={classes.fixed}>{iconIt()}</Fab>
        ) : (
          <Button
            variant="contained"
            className={classes.fixed}
            endIcon={<div className={classes.fabIcon}>{iconIt()}</div>}>
            <span style={{ color: 'white' }}>Sommaire</span>
          </Button>
        )}
      </a>
    </Zoom>
  );
};

export default ButtonSomaire;
