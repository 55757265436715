import classnames from 'classnames';
import { FC } from 'react';

interface Props {
  title: string;
  className?: any;
}

const H1: FC<Props> = props => {
  const { title, className } = props;
  return <h1 className={classnames(className, 'h1')}>{title}</h1>;
};

export default H1;
