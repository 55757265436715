import moment from 'moment';

const ignore = [
  'upper_hierarchy.data.attributes.lastname',
  'upper_hierarchy.data.attributes.firstname',
];

const deep = (rowOne: any, field: string) => {
  if (!Array.isArray(field)) {
    const splitField = field.split('.');

    let deepData: any = { ...rowOne };

    for (let i = 0; i < splitField.length; i++) {
      deepData = deepData?.[splitField?.[i]];
    }

    if ((deepData === 0 || !deepData) && !ignore.includes(field)) {
      return 0;
    }

    return deepData || '';
  }
};

export const valueFormated = (rowOne: any, field: any, option?: any) => {
  if (Array.isArray(field)) {
    let resultat: any = '';

    for (let i = 0; i < field.length; i++) {
      resultat += deep(rowOne, field[i]);

      resultat += ' ';
    }

    return resultat;
  }

  if (option && option?.type === 'date' && option?.formatedDate) {
    if (option.format) {
      return moment(deep(rowOne, field)).format(option.format);
    }

    const age: any = moment().diff(moment(deep(rowOne, field)).format('YYYY-MM-DD'), 'years');

    return isNaN(age) ? rowOne.age : age;
  }

  if (!Array.isArray(field) && field.split('.').length > 1) {
    return deep(rowOne, field);
  }

  return rowOne?.[field];
};
