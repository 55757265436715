// eslint-disable-next-line import/no-unresolved

import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getMyData = async (token: string, id: number, year: number) => {
  const params = qs.stringify(
    {
      filters: {
        $and: [
          {
            createdAt: { $gte: `${year}-01-01` },
          },
          {
            createdAt: { $lte: `${year}-12-31` },
          },
        ],
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/get-my-history/${id}?year=${year}&${params}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getUserData = async (token: string, userId: number, year: number) => {
  const params = qs.stringify(
    {
      filters: {
        $and: [
          {
            createdAt: { $gte: `${year}-01-01` },
          },
          {
            createdAt: { $lte: `${year}-12-31` },
          },
        ],
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/get-compass-history-by-user/${userId}?year=${year}&${params}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const updateRotate = async (token: string, id: number, rotate: number) => {
  return makeRequest(
    `${url}/api/compass-histories/${id}`,
    'PUT',
    { data: { rotate } },
    getHeaders(token)
  );
};

export const checkIfUserHasCompass = async (token: string) => {
  return makeRequest(
    `${url}/api/users-permissions/verify-if-user-has-compass`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const createUserCompass = async (token: string, data: any) => {
  return makeRequest(`${url}/api/compass-histories`, 'POST', { data }, getHeaders(token));
};
