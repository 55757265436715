/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { adminUserAction } from '@orientaction/api-actions';
import { useAlert } from '@orientaction/hooks';
import { adminUser } from '@orientaction/reducers';
import { adminService, Company } from '@orientaction/services';
import { IAdminUser } from '@orientaction/types';
import { getFromLS } from '@orientaction/utils';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAdminUser = () => {
  const [reassignationErrorPopin, setReassignationErrorPopin] = useState(false);
  const dispatch = useDispatch();

  const alert = useAlert();

  const token = getFromLS('userToken') || '';

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const state: IAdminUser = useSelector(adminUser);

  const setCurrent = (data: any) => {
    dispatch(adminUserAction.setCurrent(data));
  };

  const setActiveSubmit = (data: boolean) => {
    dispatch(adminUserAction.setActiveSubmit(data));
  };

  const setOpenDrawer = (data: boolean) => {
    dispatch(adminUserAction.setOpenDrawer(data));
  };

  const setListAdminUser = (data: any) => {
    dispatch(adminUserAction.setListAdminUser(data));
  };

  const setLoadingList = (data: boolean) => {
    dispatch(adminUserAction.setLoadingList(data));
  };

  const setCompany = (e: any) => {
    if (e.target) {
      const { value } = e.target;

      dispatch(adminUserAction.setCompany(+value));

      setTabs(1);
    }
  };

  const setUserTypeConnected = (e: number) => {
    dispatch(adminUserAction.setUserTypeConnected(e));
  };

  const resetDialog = () => {
    dispatch(
      adminUserAction.setDialog({
        open: false,
        title: ' ',
        paragraph: ' ',
        textBtn: ' ',
        type: ' ',
        id: ' ',
        isActivated: false,
      })
    );
  };

  const setDialog = (data: any) => {
    dispatch(
      adminUserAction.setDialog({
        ...data,
      })
    );
  };

  const setRattachement = (data: boolean) => {
    dispatch(adminUserAction.setRattachement(data));
  };

  const setTabs = (data: number) => {
    dispatch(adminUserAction.setTabs(data));
  };

  const setLoading = (data: boolean) => {
    dispatch(adminUserAction.setLoading(data));
  };

  const setReadOnly = (data: boolean) => {
    dispatch(adminUserAction.setReadOnly(data));
  };

  const setLoadingHierachy = (data: boolean) => {
    dispatch(adminUserAction.setLoadingHierachy(data));
  };

  const setActived = (data: boolean) => {
    dispatch(adminUserAction.setActived(data));
  };

  const setListUphierchy = (data: any[]) => {
    dispatch(adminUserAction.setListUphierchy(data));
  };

  const setCountTabs = (data: any) => {
    dispatch(adminUserAction.setCountTabs(data));
  };

  const setlistAdminPanel = (data: any[]) => {
    dispatch(adminUserAction.setlistAdminPanel(data));
  };

  const setCurrentPagination = (data: any) => {
    dispatch(adminUserAction.setCurrentPagination(data));
  };

  const setTextFilter = (data: any) => {
    dispatch(adminUserAction.setTextFilter(data));
  };

  const getAdminUserList = async (
    id: number,
    company_user_role: number,
    page: number,
    filter: string,
    isActivated: boolean,
    rattachement: boolean
  ) => {
    setLoadingList(true);

    try {
      const { data } = await Company.getCompany({
        token: token as any,
        company: id,
        company_user_role: company_user_role as any,
        page: page as number,
        filter: filter as string,
        isActivated: isActivated as boolean,
        rattachement: rattachement as boolean,
      } as any);

      const newObject = {
        ...state.listAdminUser,
        [+company_user_role]: data.list || [],
      };

      setCountTabs(data.countNbr);

      if (data.company_user_role === company_user_role) {
        setListAdminUser(newObject);
      }
    } catch (err: any) {
      alert.openSimpleAlert();
    } finally {
      setLoadingList(false);
    }
  };

  const getAdminUserListZoneInfluance = async (
    idUserConnected: string,
    tabs: number,
    currentPagination: number,
    textFilter: string,
    isActivated: boolean,
    rattachement: boolean
  ) => {
    setLoadingList(true);

    setListAdminUser({});

    try {
      const { data } = await Company.getZoneInfuance({
        rattachement: rattachement as boolean,
        idUserconnected: idUserConnected,
        company_user_role: tabs,
        page: currentPagination,
        filter: textFilter,
        isActivated: isActivated as any,
        token: token as any,
      });

      setListAdminUser({
        ...state.listAdminUser,
        [tabs]: data.list,
      });
      console.log({influ: data})

      setCountTabs(data.countNbr);
    } catch (err: any) {
      alert.openSimpleAlert();
    } finally {
      setLoadingList(false);
    }
  };

  const onDelete = (data: any) => {
    setDialog({
      open: true,
      title: 'Supprimer le compte',
      paragraph: 'Êtes-vous sûr de vouloir supprimer ce compte ?',
      textBtn: 'Supprimer ce compte',
      type: 'delete',
      id: data.id,
      company_user_role:
        userConnected.role.type !== 'admin' ? data.company_user_role.id : state.tabs,
      isActivated: false,
    });
  };

  const oneCancel = () => {
    setOpenDrawer(false);

    setDialog({
      open: true,
      title: `Annuler la ${state.current.isUpdate ? 'modification' : 'création'} du compte`,
      paragraph: `Êtes-vous sûr de vouloir annuler la ${
        state.current.isUpdate ? 'modification' : 'création'
      } de ce compte ?`,
      textBtn: `Annuler la ${state.current.isUpdate ? 'modification' : 'création'}`,
      type: 'cancel',
      id: '',
      isActivated: false,
    });
  };

  const oneBecomeTOConsultant = (data: any) => {
    setDialog({
      open: true,
      textCancel: 'Non',
      title: `Demande de rattachement d'un consultant .`,
      paragraph: `L'adresse e-mail vous avez renseignée appartient à un utilisateur bénéficiaire non-rattaché. Souhaitez-vous l'ajouter en consultant ?`,
      textBtn: `Oui`,
      type: 'becomeConsulte',
      id: '',
      isActivated: false,
      data: data as any,
    });
  };

  const showRattachUserPopin = (data: any) => {
    setDialog({
      open: true,
      textCancel: 'Non',
      title: `Demande de rattachement d'un bénéficiaire.`,
      paragraph: `L'adresse e-mail que vous avez renseignée appartient à un utilisateur bénéficiaire non-rattaché. Souhaitez-vous le rattacher?`,
      textBtn: `Oui`,
      type: 'rattachBeneficiary',
      id: '',
      isActivated: false,
      data: data as any,
    });
  };

  const oneDisable = (data: any) => {
    setDialog({
      open: true,
      title: `${data.isActivated ? 'Désactiver' : 'Activer'} le compte`,
      paragraph: `Êtes-vous sûr de vouloir ${
        data.isActivated ? 'désactiver' : 'activer'
      } ce compte ?`,
      textBtn: ` ${data.isActivated ? 'Désactiver' : 'Activer'} ce compte`,
      type: 'desable',
      id: data.id,
      isActivated: data.isActivated,
    });
  };

  const oneRessignation = () => {
    setDialog({
      open: true,
      title: ` Réassigner tous les comptes`,
      paragraph: `Êtes-vous sûr de vouloir réassigner tous les comptes administrés de cet utilisateur ?`,
      textBtn: `Oui`,
      type: 'reassigner',
      id: 0,
      isActivated: false,
    });
  };

  const deleteLine = (id: any, position: number) => {
    const listAdmin: any[] = state.listAdminUser[position !== 0 ? position : state.tabs] || [];

    const filter = listAdmin.filter((item: any) => item.id !== id);

    setListAdminUser({
      ...state.listAdminUser,
      [position !== 0 ? position : state.tabs]: filter,
    });
  };

  const updatesLine = (id: any, data: any) => {
    const listAdmin: any[] =
      state.listAdminUser[
        userConnected.role.type !== 'admin' ? data?.company_user_role?.data?.id : state.tabs
      ] || [];

    const filter = listAdmin.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          attributes: { ...data },
        };
      }

      return item;
    });

    setListAdminUser({
      ...state.listAdminUser,
      [userConnected.role.type !== 'admin' ? data?.company_user_role.data?.id : state.tabs]: filter,
    });
  };

  const oneComfirm = async () => {
    if (state.dialog.type === 'reassigner') {
      setLoading(true);

      await adminService
        .reassigned(state.current.listUpadted, token)
        .then((res: any) => {
          setCurrent({});

          resetDialog();
        })
        .catch((err: any) => {
          if (err?.response?.data?.error?.details?.id === 'transfer_failed') {
            setReassignationErrorPopin(true);
          } else {
            alert.openSimpleAlert();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (state.dialog.type === 'desable') {
      setLoading(true);

      await adminService
        .disableAdmin(state.dialog.id, !state.dialog.isActivated, token)
        .then((res: any) => {
          deleteLine(state.dialog.id, state.dialog.company_user_role || 0);
          resetDialog();
        })
        .catch((err: any) => {
          alert.openSimpleAlert();
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (state.dialog.type === 'delete') {
      setLoading(true);

      await adminService
        .deleteAdmin({ id: state.dialog.id, companyUserRole: state.tabs }, token)
        .then(() => {
          deleteLine(state.dialog.id, state.dialog.company_user_role || 0);

          setCountTabs({
            ...state.countTabs,
            [state.dialog.company_user_role || 0]:
              state.countTabs[state.dialog.company_user_role || 0] - 1,
          });

          resetDialog();
        })
        .catch((err: any) => {
          alert.openSimpleAlert();
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (state.dialog.type === 'becomeConsulte') {
      setActiveSubmit(true);
      setCurrent({
        ...state.current,
        firstname: state.dialog?.data?.firstname,
        lastname: state.dialog?.data?.lastname,
        hasMultipleRole: true,
      });
      resetDialog();
    }

    if (state.dialog.type === 'rattachBeneficiary') {
      adminService
        .rattachBeneficiary({ ...state.current, rattachBeneficiary: true }, token)
        .then(response => {
          updateArrayAfterUpdateCreate(response?.data.data);
          resetDialog();
          setCurrent({});
          setOpenDrawer(false);
        })
        .catch(() => {
          alert.openSimpleAlert();
        });
    }

    if (state.dialog.type === 'cancel') {
      setOpenDrawer(false);
      resetDialog();
    }
  };

  const handleCloseModal = () => {
    if (state.dialog.type === 'cancel') {
      setOpenDrawer(true);
    }

    if (state.dialog.type === 'reassigner') {
      setOpenDrawer(true);
    }

    resetDialog();
  };

  const updateArrayAfterUpdateCreate = (data: any) => {
    if (state.current.isUpdate && !state.current?.roleHasChange) {
      updatesLine(data.id, data);
      return;
    }

    const listAdmin: any[] =
      [
        {
          attributes: {
            ...data,
          },
          id: data.id,
        },
        ...state.listAdminUser[state.tabs],
      ] || [];

    setListAdminUser({
      ...state.listAdminUser,
      [state.tabs]: listAdmin,
    });
  };

  const handleChangeHierarchy = (e: any) => {
    const { name, value } = e.target;

    const hierarchy: any = state.current.hierarchy || {};

    const listHierahy: any[] = hierarchy[name] || [];

    listHierahy.push(value);

    hierarchy[name] = listHierahy;

    setCurrent({
      ...state.current,
      hierarchy,
    });
  };

  const handleChangeConsultingUser = (e: any) => {
    const { name, value } = e.target;

    const hierarchy: any = state.current.hierarchy || {};

    hierarchy[name] = value;

    setCurrent({
      ...state.current,
      hierarchy,
    });
  };

  const resetAdminUser = () => {
    setCurrent({
      isView: false,
      isUpdate: false,
      company_user_role: 1,
      company: 1,
    });

    resetDialog();

    setListAdminUser({});

    setlistAdminPanel([]);

    setCurrentPagination(0);

    setTextFilter('');

    setCompany({
      target: {
        value: 1,
      },
    });

    setCountTabs({});
  };

  const closeReassignationErrorPopin = () => {
    setReassignationErrorPopin(false);
    resetDialog();
  };

  return {
    ...state,
    setListAdminUser,
    setActived,
    setCurrent,
    setTextFilter,
    setOpenDrawer,
    setCompany,
    getAdminUserList,
    setUserTypeConnected,
    onDelete,
    oneCancel,
    oneDisable,
    oneComfirm,
    setReadOnly,
    setTabs,
    setCurrentPagination,
    setCountTabs,
    handleCloseModal,
    updateArrayAfterUpdateCreate,
    handleChangeHierarchy,
    handleChangeConsultingUser,
    setlistAdminPanel,
    getAdminUserListZoneInfluance,
    resetAdminUser,
    oneRessignation,
    setListUphierchy,
    setRattachement,
    oneBecomeTOConsultant,
    showRattachUserPopin,
    setActiveSubmit,
    closeReassignationErrorPopin,
    reassignationErrorPopin,
  };
};

export default useAdminUser;
