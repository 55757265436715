import classnames from 'classnames';
import { FC } from 'react';
import useStyle from './style';

interface IProps {
  children: any;
  titlePageOverrideStyle?: any;
  showSpanYellow?: boolean;
}

const TitlePage: FC<IProps> = ({
  children,
  titlePageOverrideStyle = '',
  showSpanYellow = true,
}) => {
  const classe = useStyle();

  return (
    <div>
      <div className={classnames(classe.titlePage, titlePageOverrideStyle)}>{children}</div>
      {showSpanYellow && <div className={classe.spanYellow} />}
    </div>
  );
};

export default TitlePage;
