import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/HighlightOffOutlined';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import QuestionForm from '../Create/QuestionForm';
import style from './style';

const QuestionDrawer = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);
  const classes = style();

  const isEdit = test?.questionToCreate?.type === 'edit';

  const handleClose = () => {
    updateTest({
      questionDrawerOpen: false,
      questionToCreate: undefined,
    });
  };

  const handleClick = () => {
    if (!test?.questionToCreate?.title?.trim().length) {
      updateTest({
        questionToCreate: {
          ...test.questionToCreate,
          errors: {
            ...test?.questionToCreate?.errors,
            title: true,
          },
        },
      });
      return;
    }
    if (!test?.questionToCreate?.responses?.length) {
      updateTest({
        questionToCreate: {
          ...test.questionToCreate,
          errors: {
            ...test?.questionToCreate?.errors,
            response: true,
          },
        },
      });
      return;
    }
    if (isEdit) {
      const index = test.questions.findIndex((q: any) => q.id === test.questionToCreate.id);
      const firstSlice = test.questions.slice(0, index);
      const secondSlice = test.questions.slice(index + 1);
      updateTest({
        questionDrawerOpen: false,
        questions: [...firstSlice, { ...test.questionToCreate }, ...secondSlice],
        questionToCreate: undefined,
      });
    } else {
      // push created question into questions array
      updateTest({
        questionDrawerOpen: false,
        questions: [...test.questions, { ...test.questionToCreate, isNew: true }],
        questionToCreate: undefined,
      });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={test.questionDrawerOpen}
      classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
      <div className={classes.headerContainer}>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
        <div className={classes.titleContainer}>
          <Typography variant="h3" className={classes.title}>
            {`${isEdit ? 'Modifier' : 'Ajouter'} une question`}
          </Typography>
        </div>
      </div>
      <div className={classes.contentChild}>
        <QuestionForm />
      </div>
      <div className={classes.footerWrapper}>
        <Button onClick={handleClick} className={classes.btnPrimary}>
          <span className={classes.textHeader}>Sauvegarder</span>
        </Button>
      </div>
    </Drawer>
  );
};

export default QuestionDrawer;
