/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useOfflineLanguage } from '@orientaction/hooks';
import { emailMatch, isAdmin, publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC, useContext, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserProfilePhotoContext } from '../../../Context/UserProfilePhotoContext';
import { connectUser } from '../../../services/user.service';
import style from './style';

interface IProps {
  signupLink: string;
}

export const getRedirectPathByCurrentPahname = (pathname: string) => {
  let redirectPath;
  switch (pathname) {
    case '/resultat-test-sens-valeurs':
      redirectPath = '/resultat-meaning-value';
      break;
    case '/resultat-test-besoins':
      redirectPath = 'resultat-need';
      break;
    case '/resultat-test-besoins-pro':
      redirectPath = 'resultat-need-pro';
      break;
    case '/resultat-test-preferences':
      redirectPath = 'resultat-preference';
      break;
    case '/resultat-test-harmony':
      redirectPath = '/resultat-harmony';
      break;
    case '/resultat-test-study':
      redirectPath = '/resultat-study';
      break;
    default:
      redirectPath = '/main';
      break;
  }
  return redirectPath;
};

const LoginForm: FC<IProps> = ({ signupLink }) => {
  const classes = style();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const [connectError, setConnectError] = useState<string>('');

  const history = useHistory();
  const { pathname } = useLocation();

  const { language, onLanguageChange } = useOfflineLanguage();

  const { resetPhoto, updateUserProfilePhoto } = useContext<any>(UserProfilePhotoContext);
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<any>({
    mode: 'onChange',
  });

  const onSubmitHandler = async (data: any) => {
    setIsLoading(true);
    try {
      resetPhoto();
      const connectData = await connectUser({ ...data, identifier: data.email });
      if (connectData?.data?.jwt) {
        // eslint-disable-next-line no-new
        new Promise((resolve: any) => {
          localStorage.setItem('userInfos', JSON.stringify(connectData?.data?.user));
          localStorage.setItem('userToken', connectData?.data?.jwt);
          resolve(connectData?.data?.jwt);
        }).then(async () => {
          updateUserProfilePhoto();
          const redirectLink = isAdmin(connectData?.data?.user)
            ? 'admin/users-resultats'
            : getRedirectPathByCurrentPahname(pathname);
          history.push(redirectLink);
        });
        // const role = connectData?.data?.user?.role?.name ?? 'other';
        // when user did one test and he was not connected
      }
    } catch (error) {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        setConnectError(
          "Une erreur s'est produite. Vérifier que vous avez rentré correctement vos identifiants",
        );
      } else {
        setConnectError('Une erreur est survenue');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const goToSignup = () => {
    console.log('go to signup');
  };
  return (
    <>
      {' '}
      <style>
        {`
      .ReactFlagsSelect-module_selectOptions__3LNBJ {
        bottom: 100%;
      }
    `}
      </style>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={classes.form}>
        <Box className={classes.fieldWithLabel}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              pattern: {
                value: emailMatch,
                message: 'Veuillez saisir une adresse e-mail valide',
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="email">
                  <h5 className={classes.labelText}>Email</h5>
                </label>
                <Input
                  placeholder="Tapez votre email"
                  {...field}
                  type="text"
                  name="email"
                  className={classes.input}
                />
                {errors?.email?.message && (
                  <span className={classes.errorField}>{(errors as any).email.message}</span>
                )}
              </>
            )}
          />
        </Box>
        <Box className={classes.fieldWithLabel}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              pattern: {
                value: /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
                message:
                  'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : | - Lettres minuscules (a-z) | - Lettres majuscules (A-Z) | - Chiffres (0-9) | - Caractères spéciaux (ex. !@#)}',
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="password">
                  <h5 className={classes.labelText}>Mot de passe</h5>
                </label>
                <Input
                  placeholder="Tapez votre mot de passe"
                  {...field}
                  type={eyeOn ? 'text' : 'password'}
                  className={clsx(classes.input, classes.inputPassword)}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      {eyeOn ? (
                        <img
                          alt="eye-switch-on"
                          src={`${publicSvgPath}/eyeOn.svg`}
                          className={classes.eyeImg}
                          onClick={() => setEyeOn(false)}
                          onKeyDown={() => setEyeOn(false)}
                        />
                      ) : (
                        <img
                          alt="eye-switch-off"
                          src={`${publicSvgPath}/eyeOff.svg`}
                          className={classes.eyeImg}
                          onClick={() => setEyeOn(true)}
                          onKeyDown={() => setEyeOn(true)}
                        />
                      )}
                    </InputAdornment>
                  }
                />
                <Box className={classes.forgotPasswordLink}>
                  <Link className={classes.forgotPasswordLinkText} to="/forgot-password">
                    Mot de passe oublié
                  </Link>
                </Box>
                {errors?.password?.message && (
                  <span className={classes.passwordErrorField}>
                    {(errors as any).password.message}
                  </span>
                )}
              </>
            )}
          />
        </Box>
        {connectError?.trim()?.length > 0 && <h5 className={classes.errorField}>{connectError}</h5>}
        <Box className={classes.btnConnectContainer}>
          <Button
            type="submit"
            className={classes.btnConnect}
            disabled={isLoading}
            data-cy="login-user"
          >
            {!isLoading ? 'Connexion' : 'Connexion...'}
          </Button>
        </Box>
        <Box className={classes.signupLink}>
          <Link onClick={goToSignup} className={classes.signupLinkText} to={signupLink}>
            Pas encore inscrit·e ? Créez votre compte
          </Link>
        </Box>
        <Box className={classes.signupLink}>
          <ReactFlagsSelect
            countries={['FR', 'GB']}
            showOptionLabel={false}
            showSelectedLabel={false}
            fullWidth={false}
            selected={language}
            onSelect={onLanguageChange}
          />
        </Box>
      </form>
    </>
  );
};

export default LoginForm;
