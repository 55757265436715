/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { bookIntroAction } from '@orientaction/api-actions';
import { appIntro } from '@orientaction/reducers';
import { BookTemplate } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { IBookIntro } from "../../store/types/Book/BookIntro";
import { getFromLS } from '../../utils/utils';

const useBookIntro = () => {

    const dispatch = useDispatch();

    const book: IBookIntro = useSelector(appIntro);

    const token = getFromLS('userToken') || '';

    const setId = (data: number) => {
        dispatch(bookIntroAction.setId(data));
    }
    
    const initValue = (data: any) => {
        dispatch(bookIntroAction.setIndoorWeather(data.indoorWeather || 0));
        dispatch(bookIntroAction.setEngagement(JSON.parse(data.engagement || JSON.stringify(['','','']))));
    }

    const getMyValueBook = async (userId: any) => {
        return BookTemplate.getMyValueBook(token, {
            id: userId,
            query: 'api::intro-ebook.intro-ebook'
        });
    }
    const setEngagement = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            engagement: JSON.stringify(data),
            query: "api::intro-ebook.intro-ebook"
        })
        dispatch(bookIntroAction.setEngagement(data));
    }

    return {
        ...book,
        setId,
        initValue,
        getMyValueBook,
        setEngagement,
    };

};

export default useBookIntro;
