/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import {
    Page,
} from '@orientaction/components';
import { useBook5 } from '@orientaction/hooks_book';
import Array from './Array';
import { listForm } from './context';

const Page13 = (props: any) => {

    const { setThreeNeedAnswerThisProject, threeNeedAnswerThisProject } = useBook5();

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setThreeNeedAnswerThisProject({
            ...threeNeedAnswerThisProject,
            [name]: value
        })

    }

    return (
        // eslint-disable-next-line prettier/prettier
        <Page
            id={13}
            readOnly={false}
        >

            <Array
                handleChange={handleChange}
                defaultValue={threeNeedAnswerThisProject}
                title="QUELS SONT LES 3 BESOINS AUXQUELS RÉPOND CE PROJET ?"
                listForm={listForm}
                readOnly={props.readOnly}
            />

        </Page>

    );
};

export default Page13;
