export const list = {
    'context': {
        'label': "LE CONTEXTE",
        'listParagraph': [
            `Votre employeur vous offre un contexte de travail favorable avec une bonne ambiance, et de bonnes conditions de travail.`,
            `Vos horaires ne dépassent pas 35 heures, ce qui vous laisse du temps pour faire autre chose si vous le désirez.`,
            `Votre employeur vous offre un niveau de sécurité et de rémunération que vous ne retrouverez pas ailleurs.`
        ]
    },
    'you': {
        'label': "VOUS",
        'listParagraph': [
            `Votre désir de changement s’appuie davantage sur un sentiment de lassitude et de manque de sens, que sur un sentiment de mal-être.`,
            `Vous êtes une personne active qui aime utiliser son temps. Vous êtes prêt(e) à travailler plus de 35 heures par semaine.`,
            `Vous avez besoin d’une certaine sécurité financière pour rembourser un crédit ou payer les études de vos enfants par exemple.`,
        ]
    }
}