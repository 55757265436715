import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        both: {
            clear: 'both'
        },
        paragraph: {
            color: '#0E1247',
            fontSize: '13px',
            margin: '0',
            paddingTop: '5vh',
            paddingBottom: '5vh',
            textAlign: 'start'
        },
    })
);
