import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderGradient: {
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      padding: '1.5px',
      borderRadius: '5px',
    },
    fontButton: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      color: '#0E1247',
      textTransform: 'capitalize',
    },
    buttonStyle: {
      background: 'white',
      '&:hover': {
        color: 'white',
      },
      '&:hover span': {
        color: 'white',
      },
    },
    twitterIcon: {
      marginTop: 7,
      marginLeft: 15,
    },
    marginIcon: {
      marginRight: '5px',
    },
    styleInput: {
      border: '1px solid #CDCEDA',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
    linkedinIcon: {
      color: theme.palette.primary.main,
    },
    facebookIcon: {
      color: theme.palette.primary.main,
      borderRadius: '50% !important',
    },
  })
);

export default useStyles;
