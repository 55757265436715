import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import style from '../../../commonStyle';
import localStyle from './style';

const LetterHumanitiesSuggestion = () => {
  const classes = style();
  const localClasses = localStyle();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        🧭 TROIS VOIES D’ORIENTATION PRIVILÉGIÉES :
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Voici trois voies que tu pourrais emprunter pour atteindre tes objectifs en fonction de
          tes résultats scolaires :
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec d&apos;excellents résultats (moyenne ≥15) : classes préparatoires
              lettres (il en existe différentes sortes).
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec de bons résultats (moyenne ≥12) : licence universitaire lettres
              ou sciences humaines, DUT carrières sociales.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec des résultats moyens (moyenne ≥10) : je ne recommande pas de
              s&apos;engager dans cette voie en cas de résultats moyens à moins d&apos;une solide
              motivation.
            </Typography>
          </li>
        </ul>
        <Typography
          className={classNames(classes.suggestionSubtitle, localClasses.customSuggestionSubtitle)}
          component="p">
          Les études de lettres et sciences humaines n&apos;offrent de réels débouchés que pour les
          élèves les plus travailleur(se)s et les plus autonomes.
        </Typography>
        <Typography className={classes.suggestionQuote} component="p">
          « La poésie est une lettre d&apos;amour adressée au monde. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Charlie Chaplin
      </Typography>
    </div>
  );
};

export default LetterHumanitiesSuggestion;
