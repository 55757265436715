// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
    TitleChapitre,
} from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../components/Img';

const Page31 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={31}
        >

            <MarginContainer>
                <TitlePage>
                    VOUS AVEZ DÉCIDÉ DE RÉALISER <br />
                    VOTRE PROJET
                </TitlePage>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Notez dans ce tableau « Tout ce que vous devrez être prêt(e) à faire pour réussir ».
                    Il s’agit de toutes les actions à accomplir pour réaliser votre projet durant sa phase
                    de mise en place, tout ce que vous devrez concrètement accomplir. Notez ensuite
                    pour chacune de ces actions, si vous êtes d’accord ou non pour les réaliser.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Cela vous permettra de voir si vous êtes prêt(e) à mettre en œuvre ce projet.
                    Ce travail constitue une phase importante de votre préparation car il vous permettra
                    de vous projeter dans l’action.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Img
                    src='decideRealise.png'
                    alt='VOUS AVEZ DÉCIDÉ DE RÉALISER'
                    className={classe.img}
                />
            </MarginContainer>

        </Page>
    );
};
export default Page31;
