/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Input from '@material-ui/core/Input';
import { useContext, useEffect, useState } from 'react';
import RichEditor from '../../commons/RichEditor';
import StyledDropzone from '../../commons/StyledDropzone';
import { TestContext } from '../../Context/TestContext';
import style from './style';

const placeholder = () => {
  return (
    <>
      <span style={{ fontSize: 26, margin: 8 }}>+</span>
      <span>Ajouter une photo</span>
    </>
  );
};

const VariableCreationForm = () => {
  const [aName, setAName] = useState('');
  const [bName, setBName] = useState('');
  const [aDescription, setADescription] = useState('');
  const [bDescription, setBDescription] = useState('');
  const [aImage, setAImage] = useState('');
  const [bImage, setBImage] = useState('');

  const { test, updateTest } = useContext<any>(TestContext);

  const cls = style();

  useEffect(() => {
    if (test.variables.length > 0) {
      setAName(test?.variables[0]?.name ?? '');
      setBName(test?.variables[1]?.name ?? '');
      setADescription(test?.variables[0]?.description ?? '');
      setBDescription(test?.variables[1]?.description ?? '');
      setAImage(test?.variables[0]?.image ?? '');
      setBImage(test?.variables[1]?.image ?? '');
    }
  }, [test]);

  const resetState = () => {
    setAName('');
    setBName('');
    setADescription('');
    setBDescription('');
  };

  const handleChange = (variableKey: string) => (e: any) => {
    if (variableKey === 'A') {
      setADescription(e);
    }

    if (variableKey === 'B') {
      setBDescription(e);
    }
  };

  const handleNameChange = (variableKey: string) => (event: any) => {
    if (variableKey === 'A') {
      setAName(event.target.value);
    }
    if (variableKey === 'B') {
      setBName(event.target.value);
    }
  };

  const handleFileUrlChange = (variableKey: string) => (url: string) => {
    if (variableKey === 'A') {
      setAImage(url);
    } else {
      setBImage(url);
    }
  };

  const addVariable = (variableKey: string) => async () => {
    if (variableKey === 'A') {
      await updateTest({
        ...test,
        variables: test.variables.map((variable: any) => {
          if (variable.variableKey === 'A') {
            return {
              id: variable.id ?? '',
              variableKey: 'A',
              name: aName,
              description: aDescription,
              image: aImage,
            };
          }
          return variable;
        }),
      });
    }
    if (variableKey === 'B') {
      await updateTest({
        ...test,
        variables: test.variables.map((variable: any) => {
          if (variable.variableKey === 'B') {
            return {
              id: variable.id ?? '',
              variableKey: 'B',
              name: bName,
              description: bDescription,
              image: bImage,
            };
          }
          return variable;
        }),
      });
    }
  };

  return (
    <div>
      {test.variables.map((variable: any, index: number) => (
        <Card
          key={index}
          variant="outlined"
          style={{ padding: 15, borderRadius: '10px', marginTop: 10 }}>
          <h3 className={cls.h3}>Nom</h3>
          <Input
            value={variable.variableKey === 'A' ? aName : bName}
            onChange={handleNameChange(variable.variableKey)}
            placeholder="Renseignez le nom"
            type="text"
            className={cls.input}
          />
          <h3 className={cls.h3}>Descriptif du résultat</h3>
          <RichEditor
            value={variable.variableKey === 'A' ? aDescription : bDescription}
            handleChange={handleChange(variable.variableKey)}
          />
          {/* <div style={{ paddingTop: 20, paddingBottom: 20 }}>
            <StyledDropzone
              placeholder={placeholder}
              handleFileUrlChange={handleFileUrlChange(variable.variableKey)}
              pathPrefix="variables"
              fileUrl={variable.variableKey === 'A' ? aImage : bImage}
            />
          </div> */}
          <Box className={cls.btnPrimaryContainer}>
            <Button
              className={cls.btnPrimary}
              disabled={variable.variableKey === 'A' ? aName.length === 0 : bName.length === 0}
              onClick={addVariable(variable.variableKey)}>
              {variable.name.length === 0 ? 'Ajouter la variable' : 'Modifier la variable'}
            </Button>
          </Box>
        </Card>
      ))}
    </div>
  );
};

export default VariableCreationForm;
