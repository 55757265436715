/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setLanguageApp =
  (code: string): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'SET_LANGUAGE',
      payload: { message: code },
    });
  };
