import { getFromLS } from '@orientaction/utils';
import { createContext, useState } from 'react';

const getInitialState = () => {
  const user = getFromLS('userInfos') ? JSON.parse(getFromLS('userInfos') as any) : {};
  return user?.photoUri ?? undefined;
};

const initialState = getInitialState();

export const UserProfilePhotoContext = createContext(initialState) as any;

export const UserProfilePhotoProvider = ({ children }: any) => {
  const [userProfilePhoto, setUserProfilePhoto] = useState(initialState);

  const updateUserProfilePhoto = async () => {
    const userPhoto = getInitialState();
    setUserProfilePhoto(userPhoto);
  };

  const resetPhoto = () => {
    setUserProfilePhoto(undefined);
  };

  return (
    <UserProfilePhotoContext.Provider
      value={{ userProfilePhoto, updateUserProfilePhoto, resetPhoto }}>
      {children}
    </UserProfilePhotoContext.Provider>
  );
};
