import TextField from '@material-ui/core/TextField';
import { AdminTestContext } from '@orientaction/Context';
import classNames from 'classnames';
import { forwardRef, useContext } from 'react';
import style from './style';

const TimeInput = forwardRef((_, ref) => {
  const { test, updateTest } = useContext<any>(AdminTestContext);
  const classes = style();

  const handleChange = (e: any) => {
    updateTest({ test: { ...test.test, time: e.target.value } });
  };

  const hasError = test?.errors?.time;

  return (
    <TextField
      id="test-duration"
      variant="outlined"
      className={classNames(classes.textField, hasError && classes.inputError)}
      name="testTime"
      onChange={handleChange}
      inputProps={{
        type: 'number',
        min: 0,
        step: 1,
      }}
      value={test.test.time}
      ref={ref as any}
    />
  );
});

export default TimeInput;
