const inCards = [
  {
    id: 1,
    name: 'Sens® - test des 10 valeurs',
  },
  {
    id: 2,
    name: 'Needs® - test des 7 besoins professionnels et personnels',
  },
  {
    id: 3,
    name: 'Needs® pro - test des 7 besoins professionnels',
  },
  {
    id: 4,
    name: 'Préférences® - test des 32 personnalités',
  },
  {
    id: 6,
    name: "Study® - test d'orientation",
  },

  {
    id: 7,
    name: 'Préférences® Jeunes',
  },
];

const beneficiaryRattachmentPopinTitle = "Demande de rattachement d'un bénéficiaire.";

const beneficiaryRattachmentPopinContent =
  "L'adresse e-mail que vous avez renseignée appartient à un utilisateur bénéficiaire non-rattaché. Souhaitez-vous le rattacher?";

const consultantRattachmentPopinTitle = "Demande de rattachement d'un consultant .";
const consultantRattachmentPopinContent =
  "L'adresse e-mail vous avez renseignée appartient à un utilisateur bénéficiaire non-rattaché. Souhaitez-vous l'ajouter en consultant ?";

export {
  beneficiaryRattachmentPopinContent,
  beneficiaryRattachmentPopinTitle,
  consultantRattachmentPopinContent,
  consultantRattachmentPopinTitle,
  inCards,
};
