/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import {
  useRedirectToRootPage,
  useAlert,
  useCompassMenu,
  usePreferencePersonalityTestResult,
  useTestRights,
  useLanguage,
} from '@orientaction/hooks';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './style';
import Card from '../Components/Card';
import Description from '../Components/Description';
import Score from './Score';
import { dataEmpty } from './data';

const PersonalityCard: FC = () => {
  const { language } = useLanguage();
  // reminder: preference test id is 4
  const { canUserAccessTest } = useTestRights(4);

  const { preferenceTestLastResult } = useCompassMenu();

  const { getMyResult } = usePreferencePersonalityTestResult();

  const [result, setMyResult] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const { goToRootPage } = useRedirectToRootPage();

  const alert = useAlert();

  const getResultat = async (idTest: number) => {
    setLoading(true);

    try {
      const { data } = await getMyResult(+idTest);
      setMyResult(data?.data?.resultat);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (preferenceTestLastResult?.id && typeof preferenceTestLastResult?.id === 'number') {
      getResultat(preferenceTestLastResult?.id);
    }
  }, [preferenceTestLastResult?.id]);

  const classe = useStyles();

  const showNeutralVersion = !result.length;

  return (
    <Card
      to={`${
        language === 'GB' ? `/en/tests/preferences-personality` : `/tests/preference-personality`
      }`}
      disable={showNeutralVersion}
      loading={loading}
      to_resultat={`/resultat-preference/${preferenceTestLastResult?.id}`}
      withAccess={canUserAccessTest}
    >
      <div className={classe.root}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <div className={classe.chartContent}>
              {(result.length ? result : dataEmpty).map((item: any) => (
                <>
                  <Typography
                    className={classe.title}
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Score listScore={item.score} color={item.color} />
                </>
              ))}
            </div>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <Description>
              Voici vos cinq préférences dans votre personnalité profonde. La personnalité profonde
              correspond à votre manière de penser et de se comporter quand vous n’êtes soumis(e) à
              aucune contrainte. L’objectif est de trouver un métier et un environnement
              professionnel qui permettent d’exprimer vos préférences profondes. Bref, qui vous
              permettent d’être vous-même. Cela est aussi vrai dans votre vie personnelle.
            </Description>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default PersonalityCard;
