/* eslint-disable react/no-array-index-key */
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 4, 6),
    textAlign: 'center',
    maxWidth: 698,
    width: '100%',
    borderRadius: '20px',
    border: '2px solid #000A8C',
  },
  icon: {
    display: 'inline-flex',
    width: '90px',
    height: '90px',
    marginBottom: theme.spacing(2),
    color: '#FF0000',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  title2: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    color: '#000A8C',
    marginBottom: theme.spacing(2),
  },
  title5: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#000A8C',
    marginBottom: theme.spacing(2),
  },
  outline: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 600,
    color: '#000A8C',
    textTransform: 'inherit',
    backgroundColor: 'white',
    minWidth: '160px',
    height: '44px',
    border: '1px solid #000A8C',
    padding: '16px 10px 12px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  warningList: {
    backgroundColor: '#FDECEA',
    borderRadius: '10px',
    padding: theme.spacing(2),
    textAlign: 'left',
    marginTop: theme.spacing(2),
    maxHeight: '200px',
    overflowY: 'auto',
  },
  warningItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  warningIcon: {
    marginRight: theme.spacing(1),
    color: '#FFAA00',
  },
}));

const ModalCheckGame = ({
  open,
  handleClose,
  title,
  message,
  onCancel,
  warningsListData,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  message: string;
  onCancel: () => void;
  warningsListData?: string[];
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      disableBackdropClick={true}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <WarningIcon className={classes.icon} />
          <Typography variant="h6" id="transition-modal-title" className={classes.title5}>
            {title}
            {/*   Une erreur est survenue, pour recommencer le jeu, cliquez{' '}
            <span style={{ cursor: 'pointer' }} onClick={onCancel}>
              {' '}
              ici{' '}
    </span> */}
          </Typography>
          {warningsListData && (
            <div className={classes.warningList}>
              {warningsListData &&
                warningsListData.map((warning, index) => (
                  <div key={index} className={classes.warningItem}>
                    <WarningIcon className={classes.warningIcon} />
                    <Typography>{warning}</Typography>
                  </div>
                ))}
            </div>
          )}

          <div className={classes.buttons}>
            <Button onClick={handleClose} className={classes.outline}>
              Ok
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalCheckGame;
