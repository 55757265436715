/* eslint-disable no-octal-escape */
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { publicSvgPath } from '../../utils/constants';

export default makeStyles((theme: Theme) => ({
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 2,
  },
  titleText: {
    fontSize: 17,
    textAlign: 'center',
    textTransform: 'uppercase',
    transform: 'rotate(-90deg)',
    textWrap: 'nowrap',
    fontFamily: 'Poppins',
    fontWeight: 600,
    '@media only screen and (max-width: 600px)': {
      fontSize: 15,
    },
    '@media only screen and (max-width: 361px)': {
      bottom: 15,
    },
  },
  titleContent: {
    fontSize: 16,
    marginTop: 0,
  },
  titlehead: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    height: '100%',
    width: '55px',
  },
  positionCircular2: {
    display: 'block',
    position: 'absolute',
    bottom: -20,
    right: '10%',
    '@media only screen and (max-width: 600px)': {
      bottom: -55,
    },
    '@media only screen and (max-width: 361px)': {
      bottom: -55,
    },
  },
  paddingGridtext: {
    padding: '75px 80px 0px',
    '@media only screen and (max-width: 600px)': {
      padding: '0px 25px 0px',
      textAlign: 'center',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '0px 20px 0px',
      textAlign: 'center',
    },
  },
  paddingGridText2: {
    padding: '60px 85px 50px',
    '@media only screen and (max-width: 600px)': {
      padding: '30px 25px 40px',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '30px 20px 40px',
    },
  },
  textConseil: {
    color: 'white',
    marginTop: 20,
    // fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
  },
  cardBorder: {
    border: '1px solid white',
    borderRadius: '10px',
    padding: '0px 30px 30px',
    marginTop: 75,
    '@media only screen and (max-width: 600px)': {
      padding: '0px 15px 30px',
      marginTop: 40,
    },
    '@media only screen and (max-width: 361px)': {
      padding: '0px 15px 30px',
      marginTop: 40,
    },
  },
  listUl: {
    listStyle: 'none',
    padding: 0,
    paddingLeft: '1em',
    '@media only screen and (max-width: 600px)': {
      marginTop: 0,
      marginBottom: 0,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  listLi: {
    display: 'flex',
    padding: 0,
    '&::before': {
      content: '"\\2022"',
      color: (props: any) => props.color,
      fontWeight: 'bold',
      display: 'inline-block',
      marginRight: 8,
      fontSize: '22px',
      marginLeft: 'calc(-1em + 2px);',
      marginTop: -2,
    },
  },
  widthTitle: {
    fontSize: 22,
    width: '85%',
    lineHeight: 1.5,
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '100%',
    },
  },
  text1: {
    '& b': {
      fontWeight: 'bold',
    },
  },
  marginBottomMobile: {
    '@media only screen and (max-width: 600px)': {
      marginBottom: 30,
    },
    '@media only screen and (max-width: 361px)': {
      marginBottom: 20,
    },
  },
  marginTopMobile: {
    '@media only screen and (max-width: 600px)': {
      marginTop: 20,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 20,
    },
  },
  citation: {
    fontSize: 22,
    textAlign: 'center',
    color: '#242424',
    paddingRight: 100,
    '@media only screen and (max-width: 600px)': {
      paddingRight: 0,
      fontSize: 18,
      '& br': {
        display: 'none',
      },
    },
    '@media only screen and (max-width: 361px)': {
      paddingRight: 0,
      fontSize: 18,
      '& br': {
        display: 'none',
      },
    },
  },
  citationContainer: {
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (max-width: 600px)': {
      marginTop: 60,
      padding: '0px 20px',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 60,
      padding: '0px 20px',
    },
  },
  imgMargin: {
    marginBottom: 25,
    marginTop: 25,
    position: 'relative',
    left: '-10%',
    '@media only screen and (max-width: 600px)': {
      marginTop: 30,
      marginBottom: 0,
      width: '130% !important',
      display: 'flex',
      alignItems: 'flex-end',
      left: '-25%',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 30,
      marginBottom: 0,
      width: '110% !important',
      display: 'flex',
      alignItems: 'flex-end',
      left: '-25%',
    },
  },
  quote: {
    width: '30px',
    height: 'auto',
    display: 'block',
    margin: '0px auto 20px',
    paddingRight: 100,
    '@media only screen and (max-width: 600px)': {
      paddingRight: 0,
    },
    '@media only screen and (max-width: 361px)': {
      paddingRight: 0,
    },
  },
  block1: {
    paddingRight: '40px !important',
    '@media only screen and (max-width: 600px)': {
      paddingRight: '20px !important',
    },
    '@media only screen and (max-width: 361px)': {
      paddingRight: '20px !important',
    },
  },
  block2: {
    paddingLeft: '65px !important',
    '@media only screen and (max-width: 600px)': {
      paddingLeft: '20px !important',
    },
    '@media only screen and (max-width: 361px)': {
      paddingLeft: '20px !important',
    },
  },
}));
