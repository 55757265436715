// eslint-disable-next-line simple-import-sort/imports
import { Page } from '@orientaction/components';
import { Grid } from '@material-ui/core';
import useStyles from '../../../style';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';
import { blockRedirection, publicBook3Path } from '@orientaction/utils';

const Page33a = (props: any) => {
  const classes = useStyles();

  const lang: string = useSelector(appLanguage);

  return (
    <Page id={0} notPadding={true} hidePagination={true}>
      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <Grid container={true} justifyContent="center">
          <Grid item={true} xs={12} md={12}>
            <div className={classes.couverturePage}>
              <img
                src={`${publicBook3Path}/osez_la_vie.jpg`}
                alt="couverture intro"
                className={classes.imgpremierApercu}
              />
              <span
                
                onClick={() =>
                  blockRedirection({
                    language: lang,
                    onOpen: () => console.log(''),
                    functionToPass: () => {
                      window.open(
                        'https://www.orientaction-groupe.com/qui-sommes-nous/accompagnement-vae/',
                        '_blank'
                      );
                    },
                  })
                }
                className={classes.btn033}>
                Clique
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};
export default Page33a;
