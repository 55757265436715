/* eslint-disable react/destructuring-assignment */
import { useBook4 } from '@orientaction/hooks_book';
import { Page, TitlePage } from '@orientaction/components';
import WithPuceSubtitle from '../../components/WithPuceSubtitle';
import YesOrNoTable from '../../components/ArrayYesNo';
import { tableData } from './constant';
import useStyles from './style';
import { imgBook4 } from '@orientaction/utils';

const Page19 = (props: any) => {
  const classes = useStyles();
  const { state, setYourEconomicCapital } = useBook4();

  return (
    <Page id={19} readOnly={props.readOnly}>
      <TitlePage>
        ÉVALUEZ VOTRE CAPITAL ÉCONOMIQUE
        <br />
        MOBILISABLE (PARTIE 2)
      </TitlePage>
      <WithPuceSubtitle puceNumber={2} childrenOverrideStyle={classes.childrenOverrideStyle}>
        VOTRE CAPITAL ÉCONOMIQUE MOBILISABLE
      </WithPuceSubtitle>
      <div style={{marginTop: 20}} >
        <YesOrNoTable list={tableData} current={state} handleChange={setYourEconomicCapital} />
      </div>
      <img
        src={`${imgBook4}/coin.png`}
        alt="bricoleur"
        style={{
          width: '80%',
          marginTop: 50,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      />
    </Page>
  );
};

export default Page19;
