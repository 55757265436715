/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CardTest } from '@orientaction/components';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import useStyle from './style';

interface ISecondEtage {
  listSecondEtape: any[];
}

const SecondEtage: FC<ISecondEtage> = ({ listSecondEtape }) => {
  const classe = useStyle();

  const hasPriority = !!listSecondEtape.filter((item: any) => item.priority === 1).length;

  const verifyList = () => {
    if (hasPriority) {
      if (listSecondEtape.length > 8) {
        return listSecondEtape.slice(9, listSecondEtape.length);
      }

      return [];
    }

    return listSecondEtape;
  };

  return (
    <>
      <Grid container={true} spacing={1} className={classe.marginTop}>
        {verifyList().map((item: any) => (
          <Grid item={true} xl={4} lg={4} md={4} sm={6} xs={12}>
            <CardTest
              status="actif"
              key={item.id}
              id={item.id}
              isArchived={false}
              isDesabled={false}
              title={item.title}
              description={item.description}
              image={item.image}
              time={item.time}
              isFavorite={false}
              category={item.category}
              testId={item.id}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SecondEtage;
