export const list = [
    {
        label1: 'I',
        label2: 'EXEMPLES D’ACCEPTATION DU DIP',
        list: [
            {
                id: 1,
                label: `Emmanuel est d’accord pour quitter son poste de chef de projet
                informatique dans un grand groupe et diminuer sa rémunération de 30%
                pour devenir moniteur d’auto-école.`
            },
            {
                id: 2,
                label: `Angélique est prête à repartir 1 an en formation initiale pour obtenir
                son CAP cuisine et réaliser son rêve d’ouvrir un jour son restaurant.`
            },
            {
                id: 3,
                label: `Frédéric, cadre supérieur habitué à un certain confort de travail , est
                prêt à réaliser lui-même les tâches ménagères dans son entreprise en
                création pour limiter les coûts.`
            },
            {
                id: 4,
                label: `Patrick, 55 ans, ancien directeur des ressources humaines, accepte
                de déposer lui-même 15.000 flyers sur les pare-brise des voitures pour
                faire connaître sa jeune entreprise de service.`
            },
            {
                id: 5,
                label: `Jérôme renonce à Nice et à son doux climat , à proximité de
                sa famille et de ses ami(e)s pour réaliser un an de formation en
                marketing dans le nord de la France.`
            },
            {
                id: 6,
                label: `Christian accepte de dormir du lundi au vendredi à l’hôtel pour avoir
                le job dont il rêve : un poste de consultant en logistique dans un grand cabinet conseil.`
            },
            {
                id: 7,
                label: `Amanda accepte d’être tous les week-ends sur les marchés pour
                vendre ses délicieux macarons et développer son entreprise (www.amandine-macaron.fr).`
            },
            {
                id: 8,
                label: `Katerina, diplômée d’une grande école de gestion, accepte de ne pas
                gagner beaucoup d’argent durant les cinq premières années de sa
                carrière pour vivre de sa passion : la photographie ! (www.sk-photo.fr).`
            }
        ]
    },
    {
        label1: 'II',
        label2: 'EXEMPLES DE RENONCEMENT',
        list: [
            {
                id: 1,
                label: `Francine renonce à quitter un grand groupe d’assurance même si
                elle s’y ennuie terriblement  car elle veut préserver son temps partiel
                et garantir son niveau de rémunération.`
            },
            {
                id: 2,
                label: `Stéphane renonce à changer de métier car il n’accepte pas l’idée
                de devoir repartir en formation pendant un an. `
            },
            {
                id: 3,
                label: `Dominique renonce à quitter son emploi hyper-stressant dans une
                grande entreprise de cosmétiques pour continuer à financer les
                études en grande école de communication de sa fille cadette.`
            },
            {
                id: 4,
                label: `Loïse renonce à une carrière dans le design qui nécessite un an de
                formation à Paris, car elle veut préserver sa vie de couple.`
            },
        ]
    }
]