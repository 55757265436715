import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    grid: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
    },
    content: {
      transition: '500ms',
      width: '389px',
      height: '506px',
      margin: '1.25vh',
      marginBottom: '2.5%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      [theme.breakpoints.up(1640)]: {
        width: '30%',
      },
      [theme.breakpoints.down(1300)]: {
        width: '45%',
      },
      [theme.breakpoints.down(900)]: {
        width: '100%',
        height: 'auto',
        margin: '1vh auto',
      },
    },
    titlePage: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '20px',
      },
    },
    explication: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      textAlign: 'center',
    },
    head: {
      'border-top-left-radius': '12px',
      'border-top-right-radius': '12px',
      'border-bottom-left-radius': '50% 35%',
      'border-bottom-right-radius': '50% 35%',
      height: '89px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      paddingTop: '1vh',
      margin: 0,
      color: '#FFFFFF',
    },
    titleHead: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      [theme.breakpoints.down(800)]: {
        fontSize: '18px',
      },
    },
    score: {
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 300,
    },
    scoreFavorite: {
      fontWeight: 'bold',
    },
    img: {
      height: '200px',
      width: '299px',
      marginTop: '2vh',
      [theme.breakpoints.down(800)]: {
        width: '80%',
        marginLeft: '10%',
      },
    },
    iconButton: {
      backgroundColor: 'rgba(0, 10, 140, 1)',
      color: 'white',
      margin: '1vh',
      width: '48px',
      height: '48px',
      '&:hover': {
        backgroundColor: 'rgba(0, 10, 140, 1)',
      },
    },
    isFavorite: {
      borderRadius: '16px',
      [theme.breakpoints.down(800)]: {
        width: '97.5%',
      },
    },
    isFavoriteHead: {
      'border-top-left-radius': '5px',
      'border-top-right-radius': '5px',
    },
    colorBlack: {
      color: '#0E1247',
    },
    btnContent: {
      width: '92.5%',
      margin: '1.25vh auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    contentScore: {
      marginTop: '2vh',
    },
  }),
);
