// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { Paragrapth, Page, TitleUpCase, ListParagrapth, TitlePage } from '@orientaction/components';
import useStyles from '../../style';

const Page36 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={35}>
      <TitlePage>DESCRIPTION DE VOS VALEURS</TitlePage>

      <TitleUpCase>AUTONOMIE</TitleUpCase>

      <Paragrapth align="start">
        Le principe directeur de votre vie :{' '}
        <Paragrapth align="justify" colorBlue={true}>
          {' '}
          <strong>Une quête de liberté !</strong>
        </Paragrapth>
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        La liberté de penser et d’agir, voilà ce que vous recherchez par-dessus tout. C’est un
        besoin vital. Votre crédo : créer, explorer, inventer. Vous voulez vous affranchir le plus
        possible des contraintes et des obligations.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify" colorBlue={true}>
        {' '}
        <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
      </Paragrapth>

      <ListParagrapth
        list={[
          'Vous lancer dans une activité sous statut d’indépendant(e),',
          'Proposer à votre employeur une(des) journée(s) de télétravail,',
          'Prendre plus de responsabilités, les postes avec plus de responsabilités offrent généralement davantage d’autonomie.',
        ]}
      />
      <br />
      <br />
      <br />
      <TitleUpCase>STIMULATION</TitleUpCase>

      <Paragrapth align="justify">
        Le principe directeur de votre vie :{' '}
        <Paragrapth align="start" colorBlue={true}>
          {' '}
          <strong>Des défis, toujours des défis !</strong>
        </Paragrapth>
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Pour vous, la vie est une aventure ! Ce qui vous motive et vous stimule, ce sont les défis.
        <br />
        <br />
        Vous fuyez la routine, aimez les challenges, ce qui est difficile, nouveau, exigeant. Vous
        aimez prendre des risques. Vous aimez les poussées d’adrénaline.
        <br />
        <br />
        Explorateur(trice) des temps modernes, vous aimez faire de nouvelles rencontres et explorer
        de nouveaux territoires.
        <br />
        <br />
        Dans votre métier, il faut que cela bouge tout le temps ! Vous vous plaisez dans un
        environnement professionnel mouvant, exigeant, et excitant. Après tout, le travail est une
        forme d’aventure moderne !
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify" colorBlue={true}>
        {' '}
        <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
      </Paragrapth>
      <ListParagrapth
        list={[
          'Partir vivre à l’étranger comme expatrié(e) pour mesurer vos talents de baroudeur(euse) pendant une année de césure,',
          'Vous lancer dans une activité complémentaire en plus de votre job actuel (auto-entreprise, immobilier, etc.),',
          'Créer votre entreprise ou votre association, c’est la plus sûre manière d’opter pour une vie hyper-stimulante.',
        ]}
      />
    </Page>
  );
};

export default Page36;
