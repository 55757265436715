/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import {
    Page,
    TitlePage
} from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { listPlanB } from './context';
import { useBook5 } from '@orientaction/hooks_book';
import BorderArray from '../../components/BorderArray';


const Page17 = (props: any) => {

    const { planB, setPlanB } = useBook5();

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setPlanB({
            ...planB,
            [name]: value
        });

    }

    return (
        // eslint-disable-next-line prettier/prettier
        <Page
            id={17}
            readOnly={false}
        >

            <TitlePage>
                VOTRE PLAN B : AU CAS OÙ VOTRE <br />
                PROJET NE POURRAIT PAS ABOUTIR
            </TitlePage>

            <MarginContainer big={true}>

                {
                    listPlanB.map((item: any) =>
                        <MarginContainer big={true}>
                            <BorderArray
                                handleChange={handleChange}
                                name={item.name}
                                defaultValue={planB?.[item.name] || ""}
                                title={item.label}
                                largeTextField={true}
                                readOnly={props.readOnly}
                            />
                        </MarginContainer>
                    )
                }

            </MarginContainer>

        </Page>

    );
};

export default Page17;
