import { MarginContainer } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import { FC } from 'react';

interface IProps {
  lists: any;
  addBackground?: boolean;
}

const ParagraphWithMargin: FC<IProps> = ({ lists, addBackground = false }) => {
  return (
    <>
      {lists.map((item: any) => (
        <MarginContainer key={item.id}>
          <Paragrapth align="justify" addBackground={addBackground}>
            {item.text}
          </Paragrapth>
        </MarginContainer>
      ))}
    </>
  );
};

export default ParagraphWithMargin;
