/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { MarginContainer } from '@orientaction/commons';
import { Page } from '@orientaction/components';
import { publicBookPath, publicSvgPath } from '@orientaction/utils';
import { useStyles } from './style';

const Page02 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={2}>
      <div className={classes.textCenterTop1}>
        <Typography variant="h6" component="h5" style={{ fontSize: 30 }}>
          EMERIC LEBRETON
        </Typography>
        <Typography className={classes.title} style={{ fontSize: 19 }} gutterBottom={true}>
          DOCTEUR EN PSYCHOLOGIE
        </Typography>
      </div>

      <div className="header__right">
        <div className="header__content bg--gradient">
          <div className="header__content-text">
            <h1 className="header__content-title">
              <span className="header__content-wrap">
                <span className="header__content-value">
                  <span className="decoration decoration--blue">
                    <img
                      src="/images/Book1/quote-blue.png"
                      alt="Quote blue"
                      className="quote-blue"
                    />
                  </span>
                  <span className="number">5</span>
                </span>

                <span className="header__content-desc">
                  <span className="title">Étapes pour réussir</span>
                  <span className="subtitle">Votre évolution professionnelle </span>
                </span>

                <span className="decoration decoration--yellow">
                  <img
                    src="/images/Book1/quote-yellow.png"
                    alt="Quote yellow"
                    className="quote-yellow"
                  />
                </span>
              </span>
            </h1>
          </div>
        </div>
      </div>

      <MarginContainer veryBig={true}>
        <div style={{ textAlign: 'left' }}>
          <Typography
            color="textSecondary"
            style={{ marginBottom: '10px', fontWeight: 'lighter', fontSize: '1.125rem' }}
          >
            INTRODUCTION : SE PRÉPARER AU CHANGEMENT
            <CheckIcon
              style={{
                fontSize: '15px',
                border: '1px solid grey',
                borderRadius: '2px',
                marginLeft: '10px',
              }}
            />
          </Typography>
          <Typography
            color="textSecondary"
            style={{ marginBottom: '10px', fontWeight: 'lighter', fontSize: '1.125rem' }}
          >
            <span>ÉTAPE 1 : CONSTRUIRE UN PROJET QUI A DU SENS</span>
            <CheckIcon
              style={{
                fontSize: '15px',
                border: '1px solid grey',
                borderRadius: '2px',
                marginLeft: '10px',
              }}
            />
          </Typography>
          <Typography
            color="textSecondary"
            style={{ marginBottom: '10px', fontWeight: 'lighter', fontSize: '1.125rem' }}
          >
            <span>
              ÉTAPE 2 : MIEUX CONNAÎTRE VOS BESOINS, VOS MOTIVATIONS ET VOTRE PERSONNALITÉ
            </span>
            <CheckIcon
              style={{
                fontSize: '15px',
                border: '1px solid grey',
                borderRadius: '2px',
                marginLeft: '10px',
              }}
            />
          </Typography>
          <Typography
            color="textSecondary"
            style={{ marginBottom: '10px', fontWeight: 'lighter', fontSize: '1.125rem' }}
          >
            <span>ÉTAPE 3 : EXPLORER TOUS LES POSSIBLES</span>
            <CheckIcon
              style={{
                fontSize: '15px',
                border: '1px solid grey',
                borderRadius: '2px',
                marginLeft: '10px',
              }}
            />
          </Typography>
          <Typography
            color="textSecondary"
            style={{ marginBottom: '10px', fontWeight: 'lighter', fontSize: '1.125rem' }}
          >
            <span>ÉTAPE 4 : IDENTIFIER VOS COMPÉTENCES ET ÉVALUER VOTRE POTENTIEL</span>
            <CheckIcon
              style={{
                fontSize: '15px',
                border: '1px solid grey',
                borderRadius: '2px',
                marginLeft: '10px',
              }}
            />
          </Typography>
        </div>
      </MarginContainer>

      <MarginContainer veryBig={true}>
        <div style={{ color: '#2b56a5' }}>
          <Typography
            variant="h1"
            component="h1"
            style={{ marginBottom: '10px', color: '#2b56a5', fontSize: '35px' }}
          >
            <img
              src={`${publicSvgPath}/point.png`}
              alt="Chapitre title"
              className={classes.imgFloat}
            />
            ÉTAPE 5
          </Typography>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            className="opacity07"
            style={{ fontWeight: '100' }}
          >
            <span>VALIDER VOTRE PROJET ET ÉLABORER VOTRE PLAN D’ACTION</span>
          </Typography>
        </div>
        <MarginContainer mormal={true}>
          <div className={classes.trait} />
        </MarginContainer>
      </MarginContainer>

      <div style={{ marginBottom: '100px', marginTop: '100px' }}>
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-justify-content-xs-center">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7">
            <div className="book__footer--image bg--gradient--other mb-5">
              <img
                className="jss150"
                src="/images/Book1/logo_oa_dark_blue_no_text_under.png"
                alt="logo"
              />
            </div>
            <p className="text-center text-blue">LEADER DU BILAN DE COMPÉTENCES</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Page02;
