/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { Company } from '@orientaction/services';
import { useState, FC } from 'react';
import { AliasRoleAdmin } from '@orientaction/components';
import { TextField, Button, Grid } from '@material-ui/core';
import { getFromLS } from '../../../utils/utils';
import { verificationFormulaire } from './verificationForm';
import style from './style';
import { useAlert } from '@orientaction/hooks';
import MajorTestSelection from './MajorTestSelection';


interface ICreateAdmin {
  setOpenDrawer: (data: any) => void;
  cancel: () => void;
  mode: string;
  data: any;
  openDrawer: boolean;
  actionAdmin: (data: any) => void;
}

const CreateAdmin: FC<ICreateAdmin> = ({
  setOpenDrawer,
  cancel,
  mode,
  data,
  openDrawer,
  actionAdmin,
}) => {
  const classes = style();

  const token = getFromLS('userToken') || '';

  const [current, setCurrent] = useState<any>({
    ...data,
  });

  const alert = useAlert();

  const [errorForm, setErrorForm] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setCurrent({
      ...current,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    e.stopPropagation();

    const FormOfVerifie: string[] = ['name', 'address', 'contact', 'phone'];

    const verify = verificationFormulaire(FormOfVerifie, current, setErrorForm);

    if (!verify) {
      return;
    }

    setLoading(true);

    try {
      let response;

      if (mode === 'Create') {
        response = await Company.addCompanies(token, current);
      }

      if (mode === 'Update') {
        response = await Company.updateCompanies(token, current);
      }
      actionAdmin(response?.data.data);

      setOpenDrawer(false);

      setCurrent({});
    } catch (err: any) {
      alert.setOpenAlert(true, 'Une erreur est survenue, merci de réessayer plus tard', 'error');
    } finally {
      setLoading(false);
    }
  };

  const btn = () => {
    if (loading) {
      return 'Chargement ... ';
    }

    if (mode === 'Update') {
      return 'Enregistrer';
    }

    return 'Créer le compte';
  };

  const listForm = [
    {
      label: 'Nom de l’entreprise',
      name: 'name',
    },
    {
      label: 'Coordonnées',
      name: 'address',
    },
    {
      label: 'Contact',
      name: 'contact',
    },
    {
      label: 'Téléphone',
      name: 'phone',
    },
  ];

  const closeIt = () => {
    if (mode === 'View') {
      setOpenDrawer(false);
      return;
    }

    cancel();
  };

  const handleMajorTestChange = (props: any) => {

    setCurrent({
      ...current,
      listMajorTest: {
        ...current.listMajorTest,
        [props.id]: !current.listMajorTest?.[props.id],
      }
    });

  }

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginTop: '10px' }}>
        {listForm.map((item: any) => (
          <div style={{ marginTop: '15px' }}>
            <Typography style={{ marginBottom: '2px', fontSize: '14px', fontWeight: 'bold' }}>
              {item.label || ''}
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              className={classes.formInput}
              onChange={handleChange}
              name={item.name}
              value={current[item.name] || ''}
              error={!!errorForm[item.name]}
              InputProps={{
                readOnly: !!(mode === 'View'),
              }}
            />
          </div>
        ))}

        <AliasRoleAdmin
          handleChangeForm={setCurrent}
          errorForm={errorForm?.companyUserRoleAlias}
          dataValue={current}
          readOnly={!!(mode === 'View')}
        />

        {mode !== 'View' && (
          <Typography
            style={{
              marginBottom: '2px',
              marginTop: '15px',
              fontSize: '14px',
              fontWeight: 'lighter',
              color: 'grey',
            }}>
            *Tous les champs sont obligatoires
          </Typography>
        )}

        <br />

        <MajorTestSelection
          handleMajorTestChange={handleMajorTestChange}
          current={current.listMajorTest}
        />

        <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item={true}>
            <Button className={classes.btnWhite} onClick={closeIt}>
              <span className={classes.textHeader}>{mode === 'View' ? 'Retour' : 'Annuler'}</span>
            </Button>

            {mode !== 'View' && (
              <Button type="submit" className={classes.btnPrimary} disabled={loading}>
                <span className={classes.textHeader}>{btn()}</span>
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default CreateAdmin;
