export const paragraphs = [
  {
    id: '17-1',
    text: ` Nos compétences ont du sens quand elles sont utiles pour autrui. Un savoir, un
    savoir-faire ou un savoir-être n’ont de la valeur que parce qu’ils sont susceptibles
    d’apporter quelque chose à quelqu’un qui a un besoin. Le jour où une compétence devient
    utile, elle acquiert de la valeur.`,
  },
  {
    id: '17-2',
    text: `
    L’inverse est également vrai. Cela signifie qu’il n’est pas possible de dire d’une
    personne qu’elle est compétente en soi. Une personne est compétente parce qu’elle est en
    mesure de répondre de façon satisfaisante au besoin d’une autre personne. Cela signifie
    que la compétence ne peut s’appréhender que dans un contexte donné en relation avec la
    personne qui en a besoin :
        `,
  },
];

export const secondParagraph = [
  {
    id: '17-3',
    text: `
        Toute personne qui cherche à valoriser ou à développer ses compétences doit avant tout se
        préoccuper du besoin des personnes : cela peut être un(e) employeur(se), une entreprise ou
        une administration, ou encore un(e) client(e) lorsqu’il s’agit de créer son entreprise.
        `,
  },
];
