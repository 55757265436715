import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  responseTitle: {
    color: theme.palette.primary.light,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
  },
  variableNameLabel: {
    color: theme.palette.primary.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    paddingLeft: 10,
  },
  variableNameText: {
    color: theme.palette.success.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingLeft: 3,
  },
  overrideDividerClassname: {
    color: '#CDCEDA',
  },
  variableValueText: {
    color: theme.palette.primary.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
  },
}));

export default useStyles;
