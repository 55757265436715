/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import DoubleBanner from '../../../components/DoubleBanner';
import Img from '../../components/Image';
import useStyles from './style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page25 = (props: any) => {
  const classes = useStyles();

  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_merchandising.png' : 'merchandising.png';
  }, [language]);

  return (
    <Page id={25} readOnly={props.readOnly}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid item={true} xs={12} md={12}>
              {/* <Img
                filename="professeur.png"
                alt="professeur"
                style={{ width: '80%', height: 'auto', display: 'block', margin: 'auto' }}
              /> */}
              <DoubleBanner>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                >
                  LE PROFESSEUR QUI APPRENAIT
                </Typography>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer1, classes.bannerTitle)}
                >
                  EN MÊME TEMPS QUE SES ÉTUDIANTS
                </Typography>
              </DoubleBanner>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <Img filename={image} alt="merchandising" className={classes.img} />
            </Grid>

            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Lorsque j’ai créé, en 2008, le cabinet ORIENTACTION, je sortais de dix ans d’études
                à l’université ; je connaissais le monde de l’entreprise seulement par mon père,
                cadre intermédiaire dans une grande compagnie d’assurance et un stage de 6 mois chez
                Renault que j’avais dû écourter à la suite de difficultés relationnelles avec ma
                tutrice.
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Dans le cadre de ma prospection commerciale, je prenais rendez-vous avec des
                dirigeant(e)s et des directeurs(trices) des ressources humaines. Au début, de très
                nombreux termes m’échappaient. Tous mes contrats tombaient à l’eau.
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                En parallèle, j’enseignais le management dans une grande école de commerce. Dans ce
                cours, il s’agissait de transmettre aux étudiant(e)s les concepts de base du
                fonctionnement d’une entreprise. L’année suivante, lorsque je me suis rendu aux
                mêmes rendez-vous, j’ai beaucoup mieux compris le langage de mes
                interlocuteurs(trices).
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                J’avais appris à « parler » le langage de l’entreprise. Il me fallut encore de
                nombreuses années, des dizaines de rencontres et de lectures pour acquérir le
                capital culturel indispensable pour réussir à travailler efficacement avec des
                entreprises.
              </Paragrapth>
            </Grid>
          </Grid>
        </MarginContainer>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          L’outil suivant va vous aider à identifier et à mesurer le capital culturel qui, tout
          comme le capital économique, possède une dimension subjective : il y a les connaissances
          que je possède et celles que je suis prêt(e) à mobiliser pour réussir. Si vous refusez
          d’utiliser certaines connaissances, demandez-vous pourquoi. Si vous trouvez la raison
          légitime et justifiée, alors renoncez. Sinon, soyez pragmatique et dépassez vos freins.
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

export default Page25;
