/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { DrawerRight } from '@orientaction/components';
import { FC } from 'react';
import CompteAdministre from './CompteAdministre';
import { useAdminUser } from '@orientaction/hooks';
import AdminForm from './AdminForm';
import { FORM_MODE } from './constant';
import { UserFormContextProvider } from './Context/UserFormContext';
import { inCards } from './BeneficiaryForm/constant';

interface IForm {
  alias: any;
}

const Form: FC<IForm> = ({ alias }) => {
  const { current, openDrawer, tabs, setOpenDrawer } = useAdminUser();

  const callBackDrawer = () => {
    setOpenDrawer(false);
  };

  const returnMode = () => {
    if (current.isView) {
      return FORM_MODE.readonly;
    }

    if (current.isUpdate) {
      return FORM_MODE.update;
    }

    return FORM_MODE.create;
  };

  const titleDrawer = () => {
    if (current.isView) {
      return 'Consulter un profil adminuistrateur';
    }

    if (current.isUpdate) {
      return 'Modifier le profil';
    }

    if (current.company_user_role === 5) {
      return 'Créer un bénéficiaire';
    }

    if (current.isHierarchy) {
      return 'Utilisateurs rattachés';
    }

    return 'Création d’un compte utilisateur admin';
  };

  const mode = returnMode();

  return (
    <DrawerRight openDrawer={openDrawer} callBack={callBackDrawer} titleDrawer={titleDrawer()}>
      {current.isHierarchy ? (
        <>
          <CompteAdministre alias={alias} />
        </>
      ) : (
        <UserFormContextProvider>
          <AdminForm mode={mode} alias={alias} />
        </UserFormContextProvider>
      )}
    </DrawerRight>
  );
};

export default Form;
