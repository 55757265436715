/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setRorate =
  (rorate: number): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ROTATE',
      payload: rorate,
    });
  };

export const setLoading =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'LOADING',
      payload: bol,
    });
  };

export const setHasCompass =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'HASCOMPASS',
      payload: bol,
    });
  };

export const setMeaningTestLastResult =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'MEANINGTESTLASTRESULT',
      payload: bol,
    });
  };

export const setNeedsTestLastResult =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'NEEDSTESTLASTRESULT',
      payload: bol,
    });
  };

export const setNeedsProTestLastResult =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'NEEDSPROTESTLASTRESULT',
      payload: bol,
    });
  };

export const setPreferenceTestLastResult =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PREFERENCETESTLASTRESULT',
      payload: bol,
    });
  };

export const setHarmonyTestLastResult =
  (bol: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'HARMONYTESTLASTRESULT',
      payload: bol,
    });
  };

export const setMajorTestsAccess =
  (majorTestsAccess: []): AppThunk =>
  dispatch => {
    dispatch({
      type: 'MAJORTESTSACCESS',
      payload: majorTestsAccess,
    });
  };
