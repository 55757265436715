// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
    '@media only screen and (max-width: 600px)': {
      paddingTop:10,
      paddingBottom:10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop:10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom:10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#fff !important',
    background: '#2c57a5',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal'
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal'
  },
  cardBgGrey: {
    background: '#e8eef4'
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important'
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
});

const TableDestin = (props: any) => {
  const classes = useStyles();
  const { head, figure32 } = props;

  return (
    <Card variant="outlined">
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div className={classes.borderTable}>
            {head && (
              <Grid container={true} justifyContent="flex-end">
                <Grid item={true} xs={4} style={{textAlign: 'center',}} className={classes.categorieB}>
                  <Typography component="p" className={classes.h7}>
                    LA FIGURE DU DESTIN
                  </Typography>
                </Grid>
                <Grid item={true} xs={8} style={{textAlign: 'center',}} className={classes.categorieA}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)} >
                    SES ACTIONS ET LE SENS DONNÉ À SA VIE
                  </Typography>
                </Grid>
              </Grid>
            )}
            {figure32.map((figure: any) => (
              <Grid container={true} style={{borderTop: '1px solid black'}} justifyContent="flex-end">
                <Grid item={true} xs={4} style={{position: 'relative', textAlign: 'center', borderRight: '1px solid black'}} >
                  <Typography component="p" className={classes.h6}>
                    {figure.type}
                  </Typography>
                  <img className={classes.img} src={`${imgBook1}/${figure.img}`} alt="logo" />
                  <div className={classes.floatIndex}>
                    <span>{figure.numero}</span>
                  </div>
                </Grid>
                <Grid item={true} xs={8} style={{display: 'flex', alignItems: 'center', textAlign: 'justify', overflow: "hidden", padding: 15}}>
                  <Typography className={classes.paragraphe} style={{fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign:'justify', fontWeight: 'lighter'}} dangerouslySetInnerHTML={figure.description} />
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableDestin;
