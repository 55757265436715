/* eslint-disable no-use-before-define */
import useStyles from './style';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { TextArea } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import clsx from 'clsx';


interface IBorderArray {
    title: string;
    handleChange: (e: any) => void;
    list: any[];
    current: any;
    isText?: boolean;
    readOnly?: boolean;
}

const BorderArray: FC<IBorderArray> = ({
    handleChange,
    current,
    title,
    list,
    isText,
    readOnly
}) => {

    const classes = useStyles();


    const handleChangeArray = (index: any) => (e: any) => {

        const { name, value } = e.target;

        handleChange({
            name, value, index
        });

    }

    const handleChangeChecked = (index: any, checked: boolean, disable: boolean) => (e: any) => {

        if (!disable && !readOnly) {
            handleChange({
                name: 'actived',
                value: checked,
                index
            });
        }

    }

    return (
        <div className={classes.border}>

            <Grid
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.titlePage}
            >

                <Grid item={true} lg={10} md={10} sm={10} xs={12}>
                    <div>
                        {title}
                    </div>
                </Grid>

            </Grid>

            <Grid
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >

                {
                    list.map((item: any) => (

                        <Grid
                            item={true}
                            xs={12}
                            className={classes.contentArray}
                        >

                            <Grid
                                container={true}
                                direction="row"
                            >

                                <Grid item={true} lg={10} md={10} sm={10} xs={12}>

                                    <div className={classes.content}>
                                        {
                                            item?.text ?
                                                (
                                                    <div className={classes.textContent}>
                                                        <Paragrapth align='justify'>
                                                            {item?.text || ""}
                                                        </Paragrapth>
                                                    </div>
                                                ) :
                                                (
                                                    <TextArea
                                                        name='text'
                                                        value={current[item.id]?.text || ""}
                                                        onChange={handleChangeArray(item.id)}
                                                        placeholder={item?.placeholder || ""}
                                                        notBorder={true}
                                                        readOnly={readOnly}
                                                    />
                                                )
                                        }
                                    </div>

                                </Grid>

                                <Grid item={true} lg={1} md={1} sm={1} xs={6}

                                >
                                    <div
                                        onClick={handleChangeChecked(item.id, true, !!item.disable)}
                                        className={clsx({
                                            [classes.notActived]: true,
                                            [classes.actived]: current?.[item.id]?.actived && current?.[item.id]?.actived === true || !!item.value
                                        })}
                                    >
                                        Oui
                                    </div>
                                </Grid>

                                <Grid item={true} lg={1} md={1} sm={1} xs={6}

                                >
                                    <div
                                        onClick={handleChangeChecked(item.id, false, !!item.disable)}
                                        className={clsx({
                                            [classes.notActived]: true,
                                            [classes.actived]: current?.[item.id]?.actived === false
                                        })}
                                    >
                                        Non
                                    </div>
                                </Grid>

                            </Grid>

                        </Grid>
                    ))
                }

            </Grid>

        </div>
    );
};

export default BorderArray;
