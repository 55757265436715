import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useAdminUser, useCompany } from '@orientaction/hooks';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApiActions } from '@orientaction/api-actions';

const useProfileMenu = ({ user }: any) => {

  const { resetCompany } = useCompany();
  const { resetAdminUser } = useAdminUser();
  const dispatch = useDispatch();

  const [open, setOpen] = useState<boolean>(false);

  const history = useHistory();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const goToProfileEdit = () => {
    history.push(`/main/user/${user.id}/edit`);
  };

  const logout = () => {
    dispatch(ApiActions.setTabLayout(0 as any));
    localStorage.clear();
    resetAdminUser();
    resetCompany();
    history.push('/');
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return {
    open,
    setOpen,
    handleToggle,
    handleClose,
    anchorRef,
    goToProfileEdit,
    logout,
  };
};

export default useProfileMenu;
