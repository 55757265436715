import classnames from 'classnames';
import { FC } from 'react';
import style from './style';

interface IProps {
  firstname: string;
  lastname: string;
  overrideClassname?: any;
}

const UserInitials: FC<IProps> = ({ firstname, lastname, overrideClassname }) => {
  const cls = style();
  if (!firstname || !lastname) {
    return null;
  }
  return (
    <div className={classnames(cls.initial, overrideClassname)}>
      <span style={{height: 'auto !important'}}>{`${firstname?.charAt(0).toUpperCase()}${lastname?.charAt(0).toUpperCase()}`}</span>
    </div>
  );
};

export default UserInitials;
