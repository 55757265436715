/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { MarginContainer } from '@orientaction/commons';
import { Page, TitlePage, Title } from '@orientaction/components';
import useStyles from './style';

const Page29 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={29}>

            <Title
                title="CHOIX N°4 :  CRÉER/REPRENDRE UNE ENTREPRISE ?"
                firstTitle="‘‘ Si vous ne courrez pas après ce que vous voulez,
                vous ne l’aurez jamais. Si vous ne demandez pas, la réponse 
                sera toujours non. Si vous ne faites pas un pas en avant
                vous restez toujours au même endroit. ’’"
                firtsecondTitle=" Nora Roberts"
                secondTitle=" ‘‘ Une décision moyenne qu’on transforme rapidement en 
                action donne souvent des meilleurs résultats qu’une décision 
                parfaite qu’on met plusieurs mois à exécuter. ’’"
                secondSousTitle="Lee Lacocca"
            />

            <MarginContainer>
                <Typography className={classes.paragraphe} >
                    La création/reprise d’entreprise constitue l’option majeure d’une évolution
                    professionnelle. Elle est sans doute le tournant le plus important que l’on peut prendre
                    dans une carrière, tant elle va modifier l’environnement personnel et professionnel
                    de celui ou celle qui le choisit. Il faut s’attendre à un changement de paradigme
                    nécessitant une modification profonde de ses habitudes, de ses croyances, de ses
                    réflexes. C’est également une transformation de ses équilibres de vie, de son rapport
                    aux risques, aux autres et à l’argent.
                </Typography>
            </MarginContainer>

            <MarginContainer>
                <Typography className={classes.paragraphe}>
                   
                    La création/reprise d’entreprise peut être perçue de deux façons : soit la personne
                    souhaite devenir indépendante. La création/reprise d’entreprise est alors perçue
                    comme une aventure, un défi, un mode de vie à privilégier. Soit c’est le fait d’une
                    nécessité absolue, lorsque le profil de la personne, pour des raisons d’âge et de
                    compétences le plus souvent, voit son niveau d’attractivité approcher le niveau 0.
                    Dans ce dernier cas, la création/reprise d’entreprise pourra être vécue de façon
                    douloureuse car non désirée.
                </Typography>
            </MarginContainer>

        </Page>
    );
};

export default Page29;
