// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getVariableFilterByTest = async (token: string, titleTest: string) => {
  const searchParams = qs.stringify(
    {
      filters: {
        test: {
          id: {
            $eq: titleTest,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/variables?${searchParams}&populate=*`, 'GET', {}, getHeaders(token));
};