import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        iconeItem: {
            fontSize: 14,
            marginRight: 7,
            marginTop: 5,
            color: '#0E1247'
        },
        textItem: {
            fontSize: 16,
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            color: '#0E1247'
        }
    })
);
