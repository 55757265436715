import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    minHeight: '91vh',
    paddingTop: '15vh',
    zIndex: 999,
    '@media only screen and (max-width: 600px)': {
      paddingTop: '8vh',
    },
  },
  img: {
    width: '60%',
    height: 'auto',
    borderRadius: '10px',
    '@media only screen and (max-width: 600px)': {
      width: '85',
    },
    '@media only screen and (max-width: 391px)': {
      width: '85',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '@media only screen and (max-width: 600px)': {
      width: '90%',
      margin: '0 auto',
    },
  },
  contentMobile: {
    width: '90%',
    margin: '12.5vh auto',
  },
  paddingContent: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noMargin: {
    '& p': {
      margin: 0,
    },
  },
  imageResponsive: {
    width: 250,
    height: 250,
    '@media only screen and (max-width: 600px)': {
      width: 200,
      height: 200,
    },
    '@media only screen and (max-width: 361px)': {
      width: 200,
      height: 200,
    },
  },
  title: {
    fontFamily: 'Poppins',
    '& p': {
      margin: 0,
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '20px',
      marginTop: 10,
      marginBottom: 10,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  text: {
    fontFamily: 'Poppins',
    textAlign: 'left',
    color: '#FFFFFF',
    fontSize: '15px',
    fontWeight: 40,
    marginTop: '0px',
    '@media only screen and (max-width: 600px)': {
      width: '90%',
      fontSize: '12px',
      marginBottom: 5,
    },
    '@media only screen and (max-width: 391px)': {
      width: '95%',
      fontSize: '10px',
    },
    '& p': {
      margin: 0,
      fontFamily: 'Poppins !important',
    },
    '& a': {
      color: '#fff !important',
      fontFamily: 'Poppins !important',
    },
    '& li': {
      color: '#fff !important',
      fontWeight: 400,
      fontFamily: 'Poppins !important',
    },
    '& strong': {
      color: '#fff !important',
      fontWeight: 700,
      fontFamily: 'Poppins !important',
    },
    '& *': {
      fontFamily: 'Poppins !important',
      fontWeight: 400,
      color: '#fff !important',
      background: 'transparent !important',
    },
  },
  marginTopGrid: {
    textAlign: 'center',
    marginTop: 20,
    '@media only screen and (max-width: 600px)': {
      marginTop: 10,
    },
  },
  bodycenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 8,
  },
  button: {
    position: 'absolute',
    top: 100,
    left: 50,
    color: 'white',
    border: '1px solid white',
    paddingLeft: 20,
    paddingRight: 20,
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
    '@media only screen and (max-width: 391px)': {
      display: 'none',
    },
  },
  buttonResponsive: {
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 391px)': {
      width: '100%',
    },
  },
  testStartingImgContainer: {
    zIndex: 1,
  },
}));
