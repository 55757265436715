export const listSomaire = [
    {
        text: "CHOISIR ENTRE LES 5 POSSIBLES",
        number: "7",
        isBold: true
    },
    {
        text: "OPTION N°1 : ÉVOLUER EN INTERNE",
        number: "10",
        isBold: true
    },
    {
        text: "CHOIX N°1 : ÉVOLUER EN INTERNE ?",
        number: "11"
    },
    {
        text: "RISQUES ET OPPORTUNITÉS D’UNE ÉVOLUTION EN INTERNE",
        number: "15"
    },
    {
        text: "OPTION N°2 : CHANGER DE MÉTIER",
        number: "18",
        isBold: true
    },
    {
        text: "CHOIX N°2 : CHANGER DE MÉTIER ?",
        number: "19",
    },
    {
        text: "RISQUES ET OPPORTUNITÉS D’UNE RECONVERSION",
        number: "21",
    },
    {
        text: "OPTION N°3 : CHANGER D’EMPLOYEUR",
        number: "23",
        isBold: true
    },
    {
        text: "CHOIX N°3 : CHANGER D’EMPLOYEUR ?",
        number: "24"
    },
    {
        text: "RISQUES ET OPPORTUNITÉS D’UN CHANGEMENT D’EMPLOYEUR",
        number: "26"
    },
    {
        text: "OPTION N°4 : CRÉER / REPRENDRE UNE ENTREPRISE",
        number: "28",
        isBold: true
    },
    {
        text: "CHOIX N°4 : CRÉER / REPRENDRE UNE ENTREPRISE ?",
        number: "29"
    },
    {
        text: "QUELLE EST LA DIFFÉRENCE ENTRE CRÉATION ET REPRISE D’ENTREPRISE ?",
        number: "32"
    },
    {
        text: "RISQUES ET OPPORTUNITÉS D’UNE CRÉATION / REPRISE D’ENTREPRISE",
        number: "33"
    },
    {
        text: "TEST DU(DE LA) CRÉATEUR(TRICE) / REPRENEUR(SE) D’ENTREPRISE",
        number: "34",
    },
    {
        text: "OPTION N°5 : NE PAS CHANGER !",
        number: "38",
        isBold: true
    },
    {
        text: "CHOIX N°5 : ET SI FINALEMENT VOUS DÉCIDIEZ DE NE PAS CHANGER ?",
        number: "39"
    },
    {
        text: "5 IDÉES POUR INVESTIR D’AUTRES DOMAINES DE VIE",
        number: "41",
    },
]