const INITIAL_STATE: any = {
  list: [],
  listTest: [],
  loadingUpdate: false,
  loading: false,
  modal: {
    type: '',
    open: false,
    title: '',
    textCancel: '',
    textValidate: '',
    noComfirmButton: false,
    id_category: 0,
  },
};

const stateCategoryTest = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'LIST': {
      return {
        ...state,
        list: action.payload,
      };
    }
    case 'LIST_TEST': {
      return {
        ...state,
        listTest: action.payload,
      };
    }
    case 'LOADINGUPDATE': {
      return {
        ...state,
        loadingUpdate: action.payload,
      };
    }
    case 'LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'MODAL': {
      return {
        ...state,
        modal: action.payload,
      };
    }
    case 'UPDATE_CATEGORY_TEST': {
      const updatedList = state.list.map((l: any) => {
        if (l.id === action.payload.oldCategory) {
          const updatedContentList = l?.contentList?.filter((c: any) => {
            return c.id !== action.payload.id;
          });
          return {
            ...l,
            contentList: updatedContentList,
          };
        }
        return l;
      });
      return {
        ...state,
        list: updatedList,
      };
    }
    default:
      return state;
  }
};

export default stateCategoryTest;
