/* eslint-disable jsx-a11y/label-has-associated-control */
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { forwardRef } from 'react';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import BaseSelect from '../BaseSelect';

const options = [
  { value: 1, label: 'Bénéficiaires non-rattachés' },
  { value: 2, label: 'ORIENTACTION uniquement' },
  { value: 4, label: 'Espaces entreprises spécifiques' },
];

const CustomDropdownIndicator = ({ innerRef, innerProps }: any) => (
  <ArrowDropDownIcon innerRef={innerRef} {...innerProps} />
);

const Option = (props: any) => {
  const { label, isSelected } = props;
  return (
    <components.Option {...props}>
      <input type="checkbox" checked={isSelected} onChange={() => null} /> <label>{label}</label>
    </components.Option>
  );
};

const allOption = {
  label: 'Autorisé à tous',
  value: 0,
};

const ValueContainer = ({ children, ...props }: any) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val: any) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = (props: any) => {
  const { data } = props;
  let labelToBeDisplayed = `${data.label}, `;
  if (data.value === allOption.value) {
    labelToBeDisplayed = 'Autorisé à tous';
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();

const PermissionsSelect = forwardRef((props: any, ref) => {
  const { updateTest, test, ...rest } = props;

  const handleChange = (selected: any) => {
    updateTest({ visibilityPermission: selected });
  };
  return (
    <BaseSelect
      {...rest}
      options={options}
      allOption={allOption}
      isMulti={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
        MultiValue,
        ValueContainer,
        animatedComponents,
        CustomDropdownIndicator,
      }}
      onChange={handleChange}
      allowSelectAll={true}
      value={test?.visibilityPermission}
      hasError={test?.errors?.visibilityPermission}
    />
  );
});

export default PermissionsSelect;
