/* eslint-disable no-use-before-define */
import { Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import Img from '../../Img';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page43 = (props: any) => {
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_demander_votre_consultant.png' : 'demander_votre_consultant.jpg';
  }, [language]);

  return (
    <Page id={43}>
      <TitlePage>
        DEMANDEZ À VOTRE CONSULTANT(E)
        <br />
        LE BOOK D’EXPLORATION DES MÉTIERS
      </TitlePage>

      <MarginContainer>
        <Typography component="i" align="center">
          <Paragrapth align="center">
            Vous souhaitez découvrir ou redécouvrir les métiers qu’il est possible <br />
            d’exercer, demandez à votre consultant(e), le book d’exploration des métiers.
          </Paragrapth>
        </Typography>
      </MarginContainer>

      <Img src={image} alt="demander_votre_consultant" style={{ width: '70%' }} />

      <MarginContainer>
        <Typography component="i" align="center">
          <Paragrapth align="center">
            De quoi vous donner encore de nouvelles idées ou de vous rassurer sur le <br />
            fait que vous aurez bien exploré toutes les possibilités
          </Paragrapth>
        </Typography>
      </MarginContainer>
    </Page>
  );
};

export default Page43;
