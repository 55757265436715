import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            width: "90%",
            height: "50px",
            textAlign: 'center',
            border: 'none',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            background: 'none'
        },
        textAreaNotBorder: {
            width: "90%",
            border: 'none'
        },
    })
);