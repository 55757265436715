// eslint-disable-next-line simple-import-sort/imports
import {
    Page,
} from '@orientaction/components';
import { Grid } from '@material-ui/core'
import useStyles from "../../style";
import Img from '../../Img';

const Page37 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={37}
        >
            <Grid
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                    height: '120vh'
                }}
            >
                <Grid item={true} xs={10}>
                    <Img
                        src='new_ide.jpg'
                        alt='new_ide'
                        style={{
                            width: "100%",
                        }}
                    />
                </Grid>

            </Grid>

        </Page>
    );
};
export default Page37;
