/* eslint-disable react/no-array-index-key */
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FC } from 'react';
import RichEditor from '../../commons/RichEditor';
import style from './style';

interface IProps {
  index: number;
  variables: string[];
  responseTitle: string;
  responseVariable: string;
  responseVariableValue: string;
  handleResponseTitleChange: (e: any) => void;
  handleResponseVariableChange: (e: any) => void;
  handleResponseVariableValueChange: (e: any) => void;
}

const ResponseCreationForm: FC<IProps> = ({
  index,
  variables,
  responseTitle,
  responseVariable,
  responseVariableValue,
  handleResponseTitleChange,
  handleResponseVariableChange,
  handleResponseVariableValueChange,
}) => {
  const cls = style();

  const handleChange = (e: any) => {
    handleResponseTitleChange(e);
  };

  const handleSelect = (e: any) => {
    handleResponseVariableChange(e.target.value);
  };

  const handleVariableValueChange = (e: any) => {
    handleResponseVariableValueChange(e.target.value);
  };

  return (
    <Card variant="outlined" style={{ padding: 15, borderRadius: '10px', marginTop: 10 }}>
      <h4 className={cls.h4}>Réponse {index + 1}</h4>
      <RichEditor
        value={responseTitle}
        handleChange={handleChange}
        placeholder="Renseignez le titre de la réponse"
      />
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6}>
          <FormControl className={cls.formControl}>
            <h4 className={cls.h4}>Variable</h4>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={responseVariable}
              onChange={handleSelect}>
              {variables.map((variable: any, i: number) => (
                <MenuItem key={i} value={variable.name}>
                  {variable.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} xs={6} md={6}>
          <h4 className={cls.h4}>Valeur</h4>
          <Input
            value={responseVariableValue}
            onChange={handleVariableValueChange}
            placeholder="Renseignez la valeur"
            type="text"
            className={cls.input}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default ResponseCreationForm;
