/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
} from '@orientaction/components';
import useStyles from './style';
import ArrayYesNo from '../../components/ArrayYesNo';
import { useBook5 } from '@orientaction/hooks_book';
import { MarginContainer } from '@orientaction/commons';
import { list } from './context';

const Page22 = (props: any) => {

    const classe = useStyles();

    const { readyToSuccess, setReadyToSucess } = useBook5();

    const handleChange = (e: any) => {

        const { name, value, index } = e;

        setReadyToSucess({
            ...readyToSuccess,
            [index]: {
                ...readyToSuccess?.[index],
                [name]: value
            }
        });
        
    }

    return (
        // eslint-disable-next-line prettier/prettier
        <Page
            id={21}
            readOnly={props.readOnly}
        >

            <TitlePage>
                CE QUE VOUS DEVEZ< br />
                ÊTRE PRÊT(E) À FAIRE POUR RÉUSSIR
            </TitlePage>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Notez dans ce tableau « tout ce que vous devrez être prêt(e) à faire pour réussir ». Il
                    s’agit de toutes les actions à accomplir pour réaliser votre projet durant sa phase de
                    mise en place, tout ce que vous devrez concrètement accomplir. Notez ensuite pour
                    chacune de ces actions, si vous êtes d’accord ou non pour les réaliser.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Cela vous permettra de voir si vous êtes prêt(e) à mettre en œuvre ce projet.
                    Ce travail constitue une phase importante de votre préparation car elle permettra
                    de vous projeter dans l’action.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <ArrayYesNo
                    handleChange={handleChange}
                    current={readyToSuccess}
                    title="CE QUE VOUS DEVEZ ÊTRE PRÊT(E) À FAIRE POUR RÉUSSIR"
                    list={list}
                />
            </MarginContainer>

        </Page>
    );
};
export default Page22;
