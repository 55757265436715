import { useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OaLabel from '@orientaction/commons/OaLabel';
import { AdminTestContext } from '@orientaction/Context';
import { forwardRef, useContext } from 'react';
import Select from 'react-select';
import CategorySelect from './CategorySelect';
import EnterprisesSelect from './Select/EnterprisesSelect';
import PermissionsSelect from './Select/PermissionsSelect';
import style from './style';
import TestDescription from './TestDescritpion';
import TimeInput from './Time';

const options = [
  { value: 1, label: 'Données stockées' },
  { value: 0, label: 'Données non-stockées' },
];

const CustomDropdownIndicator = ({ innerRef, innerProps }: any) => (
  <ArrowDropDownIcon innerRef={innerRef} {...innerProps} />
);

const customStyles = (error: boolean, matches: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: error ? '#f44336' : '#0E1247',
      height: 43,
      width: matches ? '100%' : '97%',
      boxShadow: 'unset',
      '&:hover': {
        borderColor: error ? '#f44336' : '#0E1247',
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      width: 0,
    }),
  };
};

const VisibilitySection = forwardRef((_, ref: any) => {
  const { timeRef, visibilityRef } = ref;
  const testContext = useContext<any>(AdminTestContext);
  const { test, updateTest } = testContext;
  const classes = style();
  const { companies } = test;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const showEnterpriseSelect =
    !!companies?.length &&
    test?.visibilityPermission &&
    Array.isArray(test.visibilityPermission) &&
    test.visibilityPermission.some((vp: any) => vp.value === 4);

  const showAll =
    !!companies?.length &&
    test?.visibilityPermission &&
    Array.isArray(test.visibilityPermission) &&
    test?.visibilityPermission[0]?.value === 0;

  const companiesTranformed = (companies || []).map((c: any) => ({
    ...c,
    value: c.id,
    label: c.attributes.name,
  }));

  const handleChange = (selected: any) => {
    updateTest({ test: { ...test.test, stockageMode: selected } });
  };

  const styles = customStyles(false, matches);

  return (
    <div>
      <Grid className={classes.marginTop} container={true}>
        <Grid item={true} sm={12} md={6} spacing={2}>
          <OaLabel text="Temps estimé (minutes)" />
          <TimeInput ref={timeRef} />
        </Grid>
      </Grid>
      <OaLabel text="Droits de visibilité" />
      <Grid className={classes.marginTop} container={true}>
        <Grid innerRef={visibilityRef} item={true} sm={12} md={6}>
          <PermissionsSelect test={test} updateTest={updateTest} />
        </Grid>
        {showEnterpriseSelect || showAll ? (
          <Grid item={true} sm={12} md={6}>
            <EnterprisesSelect
              companies={companiesTranformed}
              test={test}
              updateTest={updateTest}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid className={classes.marginTop} container={true}>
        <Grid item={true} sm={12} md={6}>
          <OaLabel>Catégorie par défaut</OaLabel>
          <CategorySelect />
        </Grid>
        <Grid item={true} sm={12} md={6}>
          <OaLabel>Mode de stockage</OaLabel>
          <Select
            styles={styles}
            components={{ DropdownIndicator: CustomDropdownIndicator }}
            placeholder="Sélectionnez"
            className={classes.select}
            options={options}
            value={test?.test?.stockageMode}
            onChange={handleChange}
          />
        </Grid>
        <Grid className={classes.marginTop} container={true}>
          <Grid item={true} xs={true}>
            <OaLabel>Description du test</OaLabel>
            <TestDescription />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});

export default VisibilitySection;
