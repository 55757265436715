import Grid from '@material-ui/core/Grid';
import { useContext } from 'react';
import { TestContext } from '../../Context/TestContext';
import CardFinalText from '../CardFinalText';
import FinalTextCreationForm from '../FinalTextCreationForm';
import FinalTextEmpty from '../FinalTextEmpty';
import style from './style';

const FinalTextSection = () => {
  const { test } = useContext<any>(TestContext);
  const cls = style();
  return (
    <>
      <h1 className={cls.subtitle}>Texte de fin avant présentation des résultats</h1>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6} style={{ marginTop: 10 }}>
          {test.finalText?.title?.length === 0 ? <FinalTextEmpty /> : <CardFinalText />}
        </Grid>
        <Grid item={true} xs={6} md={6}>
          <FinalTextCreationForm />
        </Grid>
      </Grid>
    </>
  );
};

export default FinalTextSection;
