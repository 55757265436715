// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { cloneDeep, findIndex, filter } from 'lodash';
import { useBook2 } from '@orientaction/hooks_book';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding: 20,
    '@media only screen and (max-width: 600px)': {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: 10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardBgGrey: {
    background: '#E6E6E6',
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important',
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
});

const TableAB = (props: any) => {
  const classes = useStyles();
  const { setExpressionAB, expressionAB } = useBook2();
  const { head, figures } = props;
  const [response, setResponse] = useState<any>(expressionAB);

  const handleChange = (e: any, index: number, type: string) => {
    const data = cloneDeep(response);
    const { name, checked } = e.target;
    const found = findIndex(data, function (o: any) {
      return o ? o[name] : false;
    });
    if (found === -1) {
      data[index] = { [name]: checked, type };
    } else {
      data.splice(found, 1);
    }
    setResponse(data);
    setExpressionAB(data);
  };

  return (
    <Card className={classes.borderTable}>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <div className={classes.borderTable}>
            {head && (
              <Grid container={true}>
                <Grid
                  container={true}
                  item={true}
                  xs={6}
                  style={{ textAlign: 'center' }}
                  className={classes.categorieA}>
                  <Grid
                    item={true}
                    xs={10}
                    style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}
                    className={classes.categorieA}>
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                      {head[0].title}
                    </Typography>
                  </Grid>
                  <Grid
                    item={true}
                    xs={2}
                    style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}
                    className={classes.categorieB}>
                    <Typography component="p" className={classes.h7}>
                      {head[0].letter}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container={true}
                  item={true}
                  xs={6}
                  style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}
                  className={classes.categorieB}>
                  <Grid
                    item={true}
                    xs={10}
                    style={{ textAlign: 'center' }}
                    className={classes.categorieA}>
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                      {head[1].title}
                    </Typography>
                  </Grid>
                  <Grid
                    item={true}
                    xs={2}
                    style={{ textAlign: 'center', border: '0.5px solid #2b56a5' }}
                    className={classes.categorieB}>
                    <Typography component="p" className={classes.h7}>
                      {head[1].letter}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {figures.map((figure: any, index: number) => (
              <Grid container={true} style={{ borderTop: '1px solid black' }}>
                <Grid
                  container={true}
                  item={true}
                  xs={6}
                  style={{ textAlign: 'center' }}
                  className={classes.categorieA}>
                  <Grid
                    item={true}
                    xs={10}
                    style={{
                      position: 'relative',
                      textAlign: 'center',
                      border: '0.5px solid #2b56a5',
                    }}>
                    <Typography component="p" className={classes.h6}>
                      {figure.A}
                    </Typography>
                  </Grid>
                  <Grid
                    item={true}
                    xs={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      border: '0.5px solid #2b56a5',
                    }}
                    className={classes.categorieB}>
                    <Checkbox
                      color="primary"
                      name={figure.A}
                      onChange={(e: any) => handleChange(e, index, 'A')}
                      checked={response[index] ? !!response[index][figure.A] : false}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container={true}
                  item={true}
                  xs={6}
                  style={{ textAlign: 'center' }}
                  className={classes.categorieA}>
                  <Grid
                    item={true}
                    xs={10}
                    style={{
                      position: 'relative',
                      textAlign: 'center',
                      border: '0.5px solid #2b56a5',
                    }}>
                    <Typography component="p" className={classes.h6}>
                      {figure.B}
                    </Typography>
                  </Grid>
                  <Grid
                    item={true}
                    xs={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      border: '0.5px solid #2b56a5',
                    }}
                    className={classes.categorieB}>
                    <Checkbox
                      color="primary"
                      name={figure.B}
                      onChange={(e: any) => handleChange(e, index, 'B')}
                      checked={response[index] ? !!response[index][figure.B] : false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
            <Grid container={true} style={{ borderTop: '1px solid black' }}>
              <Grid
                container={true}
                item={true}
                xs={6}
                style={{ textAlign: 'center' }}
                className={classes.categorieA}>
                <Grid
                  item={true}
                  xs={10}
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    border: '0.5px solid #2b56a5',
                  }}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    Total
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '0.5px solid #2b56a5',
                  }}
                  className={classes.categorieB}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    {filter(response, ['type', 'A']).length}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container={true}
                item={true}
                xs={6}
                style={{ textAlign: 'center' }}
                className={classes.categorieA}>
                <Grid
                  item={true}
                  xs={10}
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    border: '0.5px solid #2b56a5',
                  }}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    Total
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  xs={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '0.5px solid #2b56a5',
                  }}
                  className={classes.categorieB}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    {filter(response, ['type', 'B']).length}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableAB;
