import { Collapse, Grid, Typography, Theme, useMediaQuery } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import { useStyles } from './style';
import clsx from 'clsx';

const ExpandableItem = (props: any) => {
  const [open, setOpen] = useState<boolean>(true);
  const classes = useStyles();
  const {
    label,
    index,
    color,
    opacity,
    title,
    description,
    isHarmony,
    titleAd,
    descritionAd,
    imgAd,
  } = props;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  console.log({ label, index });
  return (
    <div className={!isHarmony ? classes.itemRoot : classes.itemRootLastChild}>
      <Grid container={true}>
        <Grid item={true} xs="auto" md="auto">
          <div
            className={classes.number}
            style={{
              background: color,
            }}>
            <span>{index}</span>
          </div>
        </Grid>
        <Grid item={true} xs={true} md={true}>
          <div className={classes.itemExp}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.textBulle}
              style={{ color: opacity ? 'white' : color }}>
              {title}
            </Typography>
            <span
              style={{ color: opacity ? 'white' : color, zIndex: 3, position: 'relative', top: -2 }}
              onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </span>
          </div>
          {!isTab && (
            <Collapse in={open} timeout="auto" unmountOnExit={true}>
              {!isHarmony && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    color: 'white',
                    margin: '10px 0px',
                    lineHeight: 1.5,
                  }}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: description,
                  }}
                />
              )}
              {isHarmony && (
                <Grid container={true} spacing={6}>
                  <Grid item={true} md={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color: 'white',
                        margin: '10px 0px',
                        lineHeight: 1.5,
                      }}
                      className={classes.textDescription}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: description[0],
                      }}
                    />
                    {label === 'Vision' && index === 1 && (
                      <div className={classes.cardBord}>
                        <Typography
                          variant="h2"
                          color="textPrimary"
                          className={classes.titleCard}
                          style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                          {titleAd}
                        </Typography>
                        <img src={imgAd} alt="" className={classes.imgAd} />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{
                            color: 'white',
                            margin: '10px 0px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            // eslint-disable-next-line react/destructuring-assignment
                            __html: descritionAd,
                          }}
                        />
                      </div>
                    )}
                    {label === 'Créativité' && index === 3 && (
                      <div className={classes.cardBord}>
                        <Typography
                          variant="h2"
                          color="textPrimary"
                          className={classes.titleCard}
                          style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                          {titleAd}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{
                            color: 'white',
                            margin: '10px 0px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            // eslint-disable-next-line react/destructuring-assignment
                            __html: descritionAd,
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item={true} md={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color: 'white',
                        margin: '10px 0px',
                        lineHeight: 1.5,
                      }}
                      className={classes.textDescription}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: description[1],
                      }}
                    />
                    {(label === 'Résolution de problèmes' || label === 'Confiance') &&
                      index === 1 && (
                        <div
                          className={clsx(
                            classes.cardBord,
                            label === 'Confiance' || label === 'Audace'
                              ? classes.marginTopNone
                              : null
                          )}>
                          <Typography
                            variant="h2"
                            color="textPrimary"
                            className={classes.titleCard}
                            style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                            {titleAd}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{
                              color: 'white',
                              margin: '10px 0px',
                              lineHeight: 1.5,
                              textAlign: 'center',
                            }}
                            dangerouslySetInnerHTML={{
                              // eslint-disable-next-line react/destructuring-assignment
                              __html: descritionAd,
                            }}
                          />
                        </div>
                      )}
                    {label === 'Audace' && index === 3 && (
                      <div className={clsx(classes.cardBord, classes.marginTopNone)}>
                        <Typography
                          variant="h2"
                          color="textPrimary"
                          className={classes.titleCard}
                          style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                          {titleAd}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{
                            color: 'white',
                            margin: '10px 0px',
                            lineHeight: 1.5,
                            textAlign: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            // eslint-disable-next-line react/destructuring-assignment
                            __html: descritionAd,
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
            </Collapse>
          )}
        </Grid>
      </Grid>
      {isTab && (
        <Collapse in={open} timeout="auto" unmountOnExit={true}>
          {!isHarmony && (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                color: 'white',
                margin: '10px 0px',
                lineHeight: 1.5,
              }}
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line react/destructuring-assignment
                __html: description,
              }}
            />
          )}
          {isHarmony && (
            <Grid container={true}>
              <Grid item={true} xs={12} sm={12} md={6}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    color: 'white',
                    margin: '10px 0px',
                    lineHeight: 1.5,
                  }}
                  className={classes.textDescription}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: description[0],
                  }}
                />
                {label === 'Vision' && index === 1 && (
                  <div className={classes.cardBord}>
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      className={classes.titleCard}
                      style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                      {titleAd}
                    </Typography>
                    <img src={imgAd} alt="" className={classes.imgAd} />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color: 'white',
                        margin: '10px 0px',
                        lineHeight: 1.5,
                        textAlign: 'center',
                      }}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: descritionAd,
                      }}
                    />
                  </div>
                )}
                {label === 'Créativité' && index === 3 && (
                  <div className={classes.cardBord}>
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      className={classes.titleCard}
                      style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                      {titleAd}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color: 'white',
                        margin: '10px 0px',
                        lineHeight: 1.5,
                        textAlign: 'center',
                      }}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: descritionAd,
                      }}
                    />
                  </div>
                )}
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{
                    color: 'white',
                    margin: '10px 0px',
                    lineHeight: 1.5,
                  }}
                  className={classes.textDescription}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: description[1],
                  }}
                />
                {(label === 'Résolution de problèmes' || label === 'Confiance') && index === 1 && (
                  <div className={classes.cardBord}>
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      className={classes.titleCard}
                      style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                      {titleAd}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color: 'white',
                        margin: '10px 0px',
                        lineHeight: 1.5,
                        textAlign: 'center',
                      }}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: descritionAd,
                      }}
                    />
                  </div>
                )}
                {label === 'Audace' && index === 3 && (
                  <div className={classes.cardBord}>
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      className={classes.titleCard}
                      style={{ color: 'white', textTransform: 'none', fontSize: 19 }}>
                      {titleAd}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color: 'white',
                        margin: '10px 0px',
                        lineHeight: 1.5,
                        textAlign: 'center',
                      }}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: descritionAd,
                      }}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default ExpandableItem;
