/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AdminTestContext } from '@orientaction/Context';
import { useCategoryTest } from '@orientaction/hooks';
import clsx from 'clsx';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import style from './style';

const CustomDropdownIndicator = ({ innerRef, innerProps }: any) => (
  <ArrowDropDownIcon innerRef={innerRef} {...innerProps} />
);

const customStyles = (error: boolean, matches: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: error ? '#f44336' : '#0E1247',
      height: 43,
      width: matches ? '100%' : '97%',
      boxShadow: 'unset',
      '&:hover': {
        borderColor: error ? '#f44336' : '#0E1247',
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      width: 0,
    }),
  };
};

const CategorySelect = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);
  const { list, loading, getCategoryTest } = useCategoryTest();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const location = useLocation();

  const classes = style();

  const handleChange = (event: any) => {
    const newObject = {
      ...test,
      category: event.value,
    };

    if (location.pathname.includes('/modifier')) {
      newObject.isUpdatedCategory = true;
    }
    updateTest(newObject);
  };

  const styles = customStyles(false, matches);

  useEffect(() => {
    getCategoryTest({
      isDesabled: false,
      isArchived: false,
    });
  }, []);

  const option = (list || ([] as any)).map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return (
    <Select
      styles={styles}
      components={{ DropdownIndicator: CustomDropdownIndicator }}
      placeholder="Sélectionner"
      options={option}
      defaultValue={{
        value: 1,
        label: 'Sélectionner',
      }}
      value={option.find((item: any) => item.value === test?.category)}
      onChange={handleChange}
      className={clsx({
        [classes.formSelect]: true,
        [classes.inputError]: !!test?.manageError?.category,
      })}
    />
  );
};

export default CategorySelect;
