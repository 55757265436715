// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';
import clsx from 'clsx';

const Page07 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={7}>
      <TitleChapitre nbr={1} />

      <TitlePage>
        MIEUX CONNAÎTRE VOS BESOINS,
        <br />
        VOS MOTIVATIONS ET VOTRE PERSONNALITÉ
      </TitlePage>
      <br />
      <br />
      <Paragrapth align="justify">
        Vous voulez changer de vie, faire les bons choix, surtout ne pas vous tromper. Vous
        souhaitez développer une confiance en vous exceptionnelle. N’attendez plus ! Apprenez à
        mieux vous connaître. Quand vous saurez qui vous êtes vraiment, vous ferez les choix qui
        répondent à vos vrais besoins. Vous saurez quel environnement il vous faut pour vous sentir
        bien, vous épanouir et être heureux(se). Vous serez sûr(e) de vous. Plus rien ne pourra vous
        détourner de votre objectif. Vous serez plus que jamais déterminé(e) à réussir.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        <Paragrapth align="justify" colorBlue={true}>
          <strong style={{ marginRight: '5px' }}>
            La première étape pour mieux vous connaître est d’identifier vos besoins.{' '}
          </strong>
        </Paragrapth>
        Nous avons tous des besoins. Ces besoins sont différents d’une personne à l’autre. Ils
        évoluent à chaque étape de notre vie. Ils sont d’ordre intellectuel, émotionnel ou
        relationnel, et sont aussi forts que des besoins biologiques, tels que la faim, la soif ou
        la sexualité. Ils doivent être satisfaits, si nous voulons être bien dans notre peau.
        Apprendre à mieux connaître vos besoins vous permettra de choisir le bon métier et le bon
        environnement de travail.
      </Paragrapth>
      <br />
      <br />
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook2}/titre11.png`} alt="logo" /> */}
          <SimpleBanner bannerStyleOverride={classes.bannerStyleOverride} isLong={true}>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LUC L&apos;IDÉALISTE QUI VOULAIT ACHETER UN NOUVEAU CANAPÉ
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook2}/illustration_page_11.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Luc est venu de Montpellier pour rejoindre l’équipe d’ORIENTACTION. Actuellement âgé de 26
          ans, il reprend des études de marketing et de communication. Attiré par nos valeurs, Luc
          nous a rejoints dans le cadre d’un contrat d’apprentissage
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Il se révèle rapidement être un excellent collaborateur par sa prise d’initiatives, son
          investissement personnel et son côté passionné. Il travaille si bien que j’envisage de lui
          proposer une association à la fin de son contrat. Je sens en lui une âme d’entrepreneur.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Cependant un jour de mai, son comportement change radicalement, il ne parle plus, prend
          chaque remarque pour une critique et devient négatif. En discutant avec lui, j’apprends
          qu’il a de gros problèmes financiers et que son avenir l’angoisse.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Luc ne s’en rend pas encore compte mais ses besoins ont changé. Ses nouveaux besoins sont
          les suivants : besoin de réalisation de soi, besoin de sécurité, besoin de sens, besoin de
          construire sa vie (meubler son appartement, acheter une nouvelle voiture, faire des
          cadeaux à sa petite amie, etc.).
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          À contrecœur, je me rends compte qu’en aucun cas un statut d’entrepreneur ne peut répondre
          à son besoin.
        </Typography>
      </div>
    </Page>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -80,
      [theme.breakpoints.down(1300)]: {
        marginTop: -89,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 13,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 8,
      },
    },
    bannerStyleOverride: {
      width: '100%',
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'left',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page07;
