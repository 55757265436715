// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useStyles } from './style';
import { valueFormated } from '../formatValue';

interface ITableSimple {
  data: any[];
  header: any[];
  bgColor: string;
}

const TableSimple: FC<ITableSimple> = ({ data, header, bgColor }) => {
  const classes = useStyles();

  return (
    <div>
      {header.map((item: any) => {
        return (
          <div className={classes.coulomContent}>
            <div className={classes.header} style={{ backgroundColor: bgColor }}>
              {item.label}
            </div>

            {data.map((x: any) => {
              const isMaximumScore = x?.attributes?.maximumScoreKeys?.includes(
                item.key.split('attributes.')[1]
              );
              const value = isMaximumScore ? (
                <strong> {valueFormated(x, item.key, item.option)} </strong>
              ) : (
                valueFormated(x, item.key, item.option)
              );
              return <div className={classes.content}>{value}</div>;
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableSimple;
