/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import { publicSvgPath, resultatWithColor } from '@orientaction/utils';
import { useState } from 'react';
import CircularProgressCustom from '../CircularProgressCustom';
import ModalCardHarmony from '../ModalCardHarmony';
import { useStyles } from './style';

const CardHarmony = (props: any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Card className={classes.cardStyle}>
      <div>
        <div className={classes.titlehead} style={{ background: props.harmony.color }}>
          <h3 className={classes.titleText}>{props.harmony.key}</h3>
        </div>
        <img
          src={`${publicSvgPath}/${props.harmony.img}`}
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.positionCircular}>
            <CircularProgressCustom
              value={props.harmony.value}
              color={props.harmony.color}
              size={83}
              fontSize={16}
              fontSizeFloat={10}
            />
          </div>
        </CardContent>
      </div>
      <CardActions className={classes.cardFooter}>
        <Button
          endIcon={<img src={`${publicSvgPath}/bouton_click.svg`} alt="couverture intro" />}
          onClick={() => setIsOpen(true)}
          style={{ textTransform: 'none' }}>
          En savoir plus
        </Button>
      </CardActions>
      <ModalCardHarmony
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        color={props.harmony.color}
        img={`${publicSvgPath}/${props.harmony.img}`}
        label={props.harmony.key}
        colorSecondary={props.harmony.colorSecondary}
        opacity={!!props.harmony.opacity}
        value={props.harmony.value}
        text1={props.harmony.text1}
        listeAccordeon={props.harmony.listeAccordeon}
        listEmploi1={props.harmony.listEmploi1}
        text2={props.harmony.text2}
        title={props.harmony.title}
        citation={props.harmony.citation}
        titleAd={props.harmony.titleAd}
        descriptionAd={props.harmony.descriptionAd}
        imgAd={`${publicSvgPath}/${props.harmony.imgAd}`}
        useGrid={props.harmony.useGrid}
      />
    </Card>
  );
};

CardHarmony.defaultProps = {
  isSoftSkills: false,
};

export default CardHarmony;
