/* eslint-disable react/destructuring-assignment */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr } from '@orientaction/components';
import Title from './Title';
import useStyles from './style';
import { useBook4 } from '@orientaction/hooks_book';
import { list } from "./constant";


const Page35 = (props: any) => {

  const classes = useStyles();

  const { softSkill, setSoftSkill } = useBook4();

  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setSoftSkill({
      ...softSkill,
      myChoise: {
        ...softSkill?.myChoise,
        [name]: {
          ...softSkill?.myChoise?.[name],
          explication: value
        }
      }
    });

  }

  return (
    <Page
      id={35}
      readOnly={false}
    >

      <TitleNbr
        number={3}
        text="Maintenant, illustrez vos « soft skills » avec des expériences
        concrètes : "
      />

      <MarginContainer>

        <Paragrapth align="justify">

          Reliez maintenant chaque « soft skill » à une expérience professionnelle ou personnelle.
          Cette expérience correspond à un moment où vous avez dû faire appel à cette qualité. Par
          exemple : « je suis une personne créative. Chaque jour dans mon travail, je lance de
          nouvelles idées. J’ai notamment lancé l’idée d’une nouvelle organisation plus efficace
          pour le standard téléphonique ». L’objectif est de pouvoir démontrer que vous possédez cette
          « soft skill », qu’elle vous caractérise et que vous saurez faire preuve de cette qualité
          une fois dans l’action.

        </Paragrapth>

      </MarginContainer>

      {

        list.map((item: any) => (
          <>

            <MarginContainer>

              <Title
                content={softSkill?.myChoise?.[item.id]?.label}
                label={item.title}
              />

            </MarginContainer>

            <MarginContainer>
              
              <Paragrapth
                colorBlue={true}
                overrideParagraphStyle={classes.overrideTextStyle}
                align="justify"
              >
                {item.paragraph}
              </Paragrapth>

            </MarginContainer>

            <TextArea
              name={item.id}
              onChange={handleChange}
              value={softSkill?.myChoise?.[item.id]?.explication || ""}
              largeTextField={true}
              readOnly={props.readOnly}
            />

          </>
        ))
      }


    </Page>
  );
};

export default Page35;
