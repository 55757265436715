import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { FC } from 'react';
import style from './style';

interface IProps {
  overrideDividerClassname?: string;
}

const OaDivider: FC<IProps> = ({ overrideDividerClassname }) => {
  const classes = style();
  return <Divider className={classNames(classes.divider, overrideDividerClassname)} />;
};

export default OaDivider;
