/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import DoubleBanner from '../../components/DoubleBanner';

const Page32 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={32}>
      <Title
        title="MIEUX CONNAÎTRE VOS VALEURS"
        firstTitle="‘‘Quelle est la valeur des hommes ? - Ce qu’ils cherchent.’’"
        firtsecondTitle="Proverbe persan"
        secondTitle="‘‘ Qui vole ma bourse vole une chose sans valeur. ’’"
        secondSousTitle="William Shakespeare"
      />
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${publicBookPath}/titre4.png`} alt="logo" /> */}
          <DoubleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              CELUI QUI APPRIT À
            </Typography>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer1, classes.bannerTitle)}
            >
              CONNAÎTRE SES VALEURS
            </Typography>
          </DoubleBanner>
        </div>
        <img className={classes.img} src={`${imgBook1}/mailor.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Stéphane est depuis dix ans maire d’une petite ville. Lorsque je le rencontre, il vient de
          perdre l’élection législative. Il vit cette défaite comme un terrible échec. Il sait qu’il
          doit changer quelque chose !
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Stéphane est perçu par la plupart des gens comme un arriviste, une personne qui ne fait
          les choses que par intérêt, dont on ne sait pas ce qu’elle pense ! C’est vrai qu’il ne
          vous regarde pas toujours dans les yeux et qu’il s’exprime souvent de façon hésitante,
          surtout en public.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Stéphane sait que c’est à cause de cette image faussée qu’il a perdu l’élection. Je
          demande à Stéphane de me raconter son histoire. À partir de souvenirs particulièrement
          forts, nous retrouvons les trois valeurs qui donnent sens à sa vie depuis toujours. Deux
          mois plus tard, un ami assiste à l’un de ses discours.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Je lui demande, sans rien lui dire de ce que nous avons réalisé ensemble, ce qu’il en
          pense. Il me dit que c’est un homme métamorphosé. Sûr de lui, il parle de façon cohérente
          et affirmée, sans retenir ses émotions et avec sincérité. Son discours prend aux tripes !
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Stéphane sait maintenant qui il est, pourquoi il est maire, et pour quelles idées il se
          bat.
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      // margin: 'auto',
      marginTop: -136,
      [theme.breakpoints.down(1300)]: {
        marginTop: -88,
      },
    },
    bannerTitleContainer1: {
      // margin: 'auto',
      marginTop: 50,
      [theme.breakpoints.down(1300)]: {
        marginTop: 27,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page32;
