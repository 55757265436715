import { getFromLS, isAdmin } from '@orientaction/utils';
import { useHistory } from 'react-router-dom';

const useRedirectToRootPage = () => {
  const user = JSON.parse(getFromLS('userInfos') || '');
  let mainLink: string;
  if (user) {
    mainLink = isAdmin(user) ? '/admin/users-resultats' : '/main';
  } else {
    mainLink = '/';
  }
  const { push } = useHistory();
  const goToRootPage = async () => {
    push(mainLink);
  };
  return {
    goToRootPage,
  };
};

export default useRedirectToRootPage;
