import { publicSvgPath } from '@orientaction/utils';
import classNames from 'classnames';
import { FC } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import style from './style';

interface IProps {
  title: string;
  description: string;
  testUrl: string;
}
const ShareIcons: FC<IProps> = ({ title, description, testUrl }) => {
  const classes = style();
  return (
    <>
      <TwitterShareButton
        className={classNames(classes.buttonStyle, classes.twitterIcon)}
        url={testUrl}>
        <img src={`${publicSvgPath}/twitterIcon.svg`} alt="Twitter icon" />
      </TwitterShareButton>
      <FacebookShareButton className={classes.buttonStyle} url={testUrl} quote={title}>
        <img src={`${publicSvgPath}/facebookIcon.svg`} alt="Facebook icon" />
      </FacebookShareButton>
      <LinkedinShareButton className={classes.buttonStyle} url={testUrl}>
        <img src={`${publicSvgPath}/linkedinIcon.svg`} alt="Linkedin icon" />
      </LinkedinShareButton>
    </>
  );
};

export default ShareIcons;
