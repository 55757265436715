// testFilters example
/**
 * { testId: 1, keyWords: [{ key: "name", type: "string", operator: ">" }]}
 */

const INITIAL_STATE: any = {
  results: [],
  loading: false,
  loadingAdmin: false,
  pending: false,
  count: 0,
  tests: [],
  archivedTests: [],
  disabledTests: [],
  test: {},
  progress: 0,
  responses: null,
  testsUser: [],
  oneResponseUser: {},
  companyUserRole: [],
  companies: [],
  company: {},
  tabLayout: 0,
  meaningValueTestResults: [],
  needsTestResults: [],
  needsProTestResults: [],
  preferencePersonalityTestResults: [],
  testFilters: [],
  testSelected: { id: 1, titre: 'Sens/Valeurs', description: 'Sens et Valeurs' },
  userTestFilters: { isActive: true, isRattached: true },
  refetch: false,
  usersTableSearchKeyword: '',
  currentMinorTestResults: [],
  currentMinorTestResultsMetadata: {},
  currentMinorTestResultsColumns: [],
  currentMinorTestResultsPage: 0,
};

const apiReducer = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'LOAD_ADMIN': {
      return { ...state, loadingAdmin: action.payload };
    }
    case 'LOAD_API': {
      return { ...state, loading: true };
    }
    case 'LOADOUT_API': {
      return { ...state, loading: false };
    }
    case 'PENDING_API': {
      return { ...state, pending: true };
    }
    case 'PENDING_END_API': {
      return { ...state, pending: false };
    }
    case 'PUSH_RESULTS':
      return {
        ...state,
        results: action.payload.results,
        loading: false,
        pending: false,
      };
    case 'PUSH_RESULTS_COUNT':
      return {
        ...state,
        count: action.payload.count,
      };
    case 'PUSH_TESTS':
      return {
        ...state,
        tests: action.payload.tests,
      };
    case 'PUSH_ARCHIVED_TESTS':
      return {
        ...state,
        archivedTests: action.payload.archivedTests,
      };
    case 'PUSH_DISABLED_TESTS':
      return {
        ...state,
        disabledTests: action.payload.disabledTests,
      };
    case 'APPEND_TESTS':
      return {
        ...state,
        tests: [...state.tests, action.payload.test],
      };
    case 'PUT_TEST':
      return {
        ...state,
        tests: state.tests.map((test: any) =>
          test.id === action.payload.test.id ? action.payload.test : test
        ),
      };
    case 'SET_TEST':
      return {
        ...state,
        test: action.payload.test,
      };
    case 'SET_PROGRESS':
      return {
        ...state,
        progress: action.payload.progress,
      };
    case 'SET_RESPONSES':
      return {
        ...state,
        responses: action.payload.responses,
      };
    case 'SET_TESTS_USER':
      return {
        ...state,
        testsUser: action.payload.testsUser,
      };
    case 'SET_ONE_RESPONSE_USER':
      return {
        ...state,
        oneResponseUser: action.payload.oneResponseUser,
      };
    case 'SET_COMPANIES':
      return {
        ...state,
        companies: action.payload.companies,
      };
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.payload.company,
      };
    case 'SET_COMPANY_USER_ROLE':
      return {
        ...state,
        companyUserRole: action.payload,
      };
    case 'TABLAYOUT':
      return {
        ...state,
        tabLayout: action.payload,
      };
    case 'REMOVE_DISABLED_TEST':
      return {
        ...state,
        disabledTests: state.disabledTests.filter(
          (disabledTest: any) => disabledTest.id !== action.payload.id
        ),
      };
    case 'REMOVE_ARCHIVED_TEST':
      return {
        ...state,
        archivedTests: state.archivedTests.filter(
          (archivedTest: any) => archivedTest.id !== action.payload.id
        ),
      };
    case 'PUSH_MEANING_VALUE_TEST_RESULTS':
      return {
        ...state,
        meaningValueTestResults: action.payload.meaningValueTestResults,
      };
    case 'PUSH_NEEDS_TEST_RESULTS':
      return {
        ...state,
        needsTestResults: action.payload.needsTestResults,
      };
    case 'PUSH_NEEDS_PRO_TEST_RESULTS':
      return {
        ...state,
        needsProTestResults: action.payload.needsProTestResults,
      };
    case 'PUSH_PREFERENCE_PERSONALITY_TEST_RESULTS':
      return {
        ...state,
        preferencePersonalityTestResults: action.payload.preferencePersonalityTestResults,
      };
    case 'PUSH_TEST_FILTERS':
      return {
        ...state,
        testFilters: [...state.testFilters, action.payload.testFilter],
      };
    case 'REMOVE_TEST_FILTERS':
      return {
        ...state,
        testFilters: state.testFilters.filter(
          (testFilter: any, index: number) => index !== action.payload.testFilter.index
        ),
      };
    case 'SET_TEST_SELECTED':
      return {
        ...state,
        testSelected: action.payload.testSelected,
      };
    case 'UPDATE_USER_ACTIVE_TEST_FILTERS':
      return {
        ...state,
        userTestFilters: {
          isActive: !state.userTestFilters.isActive,
          isRattached: state.userTestFilters.isRattached,
        },
      };
    case 'UPDATE_USER_RATTACHED_TEST_FILTERS':
      return {
        ...state,
        userTestFilters: {
          isActive: state.userTestFilters.isActive,
          isRattached: !state.userTestFilters.isRattached,
        },
      };
    case 'SET_REFETCH':
      return {
        ...state,
        refetch: action.payload.refetch,
      };
    case 'INITIALIZE_TEST_FILTERS':
      return {
        ...state,
        testFilters: [],
      };
    case 'UPDATE_USERS_TABLE_SEARCH_KEYWORD':
      return {
        ...state,
        usersTableSearchKeyword: action.payload.searchKeyword,
      };
    case 'INITIALIZE_TABLE_SEARCH_KEYWORD':
      return {
        ...state,
        usersTableSearchKeyword: '',
      };
    case 'INITIALIZE_USER_STATUS_FILTERS':
      return {
        ...state,
        userTestFilters: {
          isActive: true,
          isRattached: true,
        },
      };
    case 'PUSH_CURRENT_MINOR_TEST_RESULTS':
      return {
        ...state,
        currentMinorTestResults: action.payload.currentMinorTestResults,
      };
    case 'UPDATE_MINOR_TEST_RESULTS_METADATA':
      return {
        ...state,
        currentMinorTestResultsMetadata: action.payload.currentMinorTestResultsMetadata,
      };
    case 'UPDATE_MINOR_TEST_RESULTS_COLUMNS':
      return {
        ...state,
        currentMinorTestResultsColumns: action.payload.currentMinorTestResultsColumns,
      };
    case 'SET_CURRENT_MINOR_TEST_RESULTS_PAGE':
      return {
        ...state,
        currentMinorTestResultsPage: action.payload.currentMinorTestResultsPage,
      };
    default:
      return state;
  }
};
export default apiReducer;
