/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Typography, Grid, IconButton } from '@material-ui/core';
import { FC } from 'react';
import style from './style';
import { useHistory } from 'react-router-dom';
import { useAdminUser } from '@orientaction/hooks';


interface IForm {
    name: string;
    listHierachy: any[]
}

const Form: FC<IForm> = ({
    name, listHierachy
}) => {

    const {
        setTabs,
        company,
        setCurrent,
        setOpenDrawer,
        setListUphierchy,
    } = useAdminUser();

    const classes = style();

    const onClickAction = (data: any) => (e: any) => {

        setListUphierchy([
            {
                ...data.upper_hierarchy?.attributes,
                id: data.upper_hierarchy?.id,
            }
            || null]);

        setCurrent({
            ...data,
            company: company as number,
            company_user_role: data.company_user_role?.id || "",
            upper_hierarchy: data.upper_hierarchy?.id || '',
            isView: !!data.isView,
            isUpdate: true,
        });

        setOpenDrawer(true);

    };

    const history = useHistory();

    return (
        <div className={classes.root}>

            <Typography className={classes.label}>
                {name}
            </Typography>

            <div className={classes.input}>

                <Grid container={true}>

                    {
                        listHierachy.map((item: any) => (
                            <Grid item={true}>
                                <div className={classes.chip} onClick={onClickAction(item)}>
                                    <span>
                                        {`${item.firstname} ${item.lastname}`}
                                    </span>
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>

            </div>

        </div>
    );
};

export default Form;
