/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import { Grid } from '@material-ui/core';
import useStyles from './style';
import { useBook5 } from '@orientaction/hooks_book';

const Page24 = (props: any) => {

    const classes = useStyles();

    const { setFullFillingYourDream, fullFillingYourDream } = useBook5();

    const handleChangeArray = (e: any) => {

        const { name, value } = e.target;

        setFullFillingYourDream({
            ...fullFillingYourDream,
            [name]: value
        });

    }

    return (
        <Page id={24} readOnly={false}>

            <TitlePage>
                LES DIX CHOSES QUE VOUS GAGNEREZ <br />
                EN RÉALISANT VOTRE RÊVE
            </TitlePage>

            <MarginContainer big={true}>
                <Paragrapth align='justify'>
                    Notez dans ce tableau les dix choses que vous gagnerez en allant au bout de
                    votre rêve. Ces choses peuvent être de nature matérielle comme de l’argent,
                    des avantages en nature, plus de temps à consacrer à votre famille ou à vos
                    activités extraprofessionnelles ou personnelles, mais aussi psychologique
                    comme donner plus de sens à vos actions, obtenir plus de reconnaissance,
                    avoir un meilleur statut social ou une meilleure estime de soi.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer big={true}>
                <Paragrapth
                    align='center'
                    colorBlue={true}
                    bold={true}
                >
                    Ce tableau vous permettra de vous rappeler pourquoi
                    vous entreprenez ce projet.
                </Paragrapth>
            </MarginContainer>

            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number, index: number) => (
                <Grid
                    container={true}
                    alignItems="stretch"
                    direction="row"
                >

                    <Grid item={true} xs={3} md={1} className={classes.tableContainer}>
                            <div className={classes.number}
                                style={{
                                    width: 70 - (index * 2),
                                    height: 70 - (index * 2),
                                    borderRadius: 70 - (index * 2)
                                }}
                            >
                                {item}
                            </div>
                    </Grid>

                    <Grid item={true} xs={9} md={11} className={classes.tableContainer}>
                            <TextArea
                                name={`${item}`}
                                value={fullFillingYourDream[item] || ""}
                                onChange={handleChangeArray}
                                notBorder={true}
                                readOnly={props.readOnly}
                            />
                    </Grid>

                </Grid>
            ))}

        </Page>
    );
};

export default Page24;
