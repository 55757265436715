/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import {
  Checkbox,
  Divider,
  Typography,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import { FC } from 'react';
import style from './style';
import { MarginContainer, ContentCenter } from '@orientaction/commons';

interface IServicesSection {
  handleChange: (data: any) => void;
  services: any;
  loading: boolean;
}

const ServicesSection: FC<IServicesSection> = ({ services, handleChange, loading }) => {
  const classes = style();
  if (!services?.length) {
    return null;
  }
  return (
    <>
      <MarginContainer>
        <Typography className={classes.bookChoiceLabel}> Prestations </Typography>
      </MarginContainer>

      {loading ? (
        <>
          <ContentCenter>
            <CircularProgress />
          </ContentCenter>
        </>
      ) : (
        <>
          {services?.map((service: any, index: number) => {
            return (
              <div key={service.id} className={classes.bookChoiceContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={service.isChecked}
                      onChange={handleChange}
                      name={`service_${service.id}`}
                      color="primary"
                    />
                  }
                  label={service?.attributes?.name}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default ServicesSection;
