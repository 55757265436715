/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Table, Layout } from '@orientaction/components';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import useStyles from './style';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ImgNoRattacher from '@orientaction/Asset/Ressources/nonRattacher.png';
import { MarginContainer, BtnPrimary } from '@orientaction/commons';


const NonRattacher = (props: any) => {

    const classes = useStyles();

    const history = useHistory();

    const redirect = () => {
        history.push('/main')
    }

    return (
        <Layout isaddBackground={true} noPadding={true} footer={true}>

            <div className={classes.root}>

                <h2
                    className={classes.title}
                >
                    Mes ressources{' '}
                </h2>

                <div
                    className={classes.bgWhite}
                >

                    <img
                        src={ImgNoRattacher}
                        className={classes.img}
                        alt="Mes ressources"
                    />

                    <MarginContainer>
                        <b
                            className={classes.title1}
                        >
                            Cette page est réservée aux bénéficiaires des prestations d&apos;ORIENTACTION
                        </b>

                        <Typography
                            className={classes.title2}
                            align='center'
                        >
                            (bilan de compétence, bilan d&apos;orientation, et recrutement).
                        </Typography>
                    </MarginContainer>

                    <MarginContainer>
                        <Typography
                            className={classes.title2}
                            align='center'
                        >
                            Si vous souhaitez bénéficier d&apos;une prestation, merci de nous contacter en cliquant sur <a
                                href="https://www.orientaction-groupe.com/contact/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >ce lien.</a>
                        </Typography>
                    </MarginContainer>

                    <BtnPrimary
                        onClick={redirect}
                        disabled={false}
                    >
                        Retour
                    </BtnPrimary>

                </div>

            </div>

        </Layout>
    );
};

export default withWidth()(NonRattacher);
