/* eslint-disable no-use-before-define */
import { Paragrapth, TitleNbr } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import useStyles from './style';
import { FC } from 'react';
import parse from 'react-html-parser';
import { Grid } from '@material-ui/core';
import Img from '../../components/Img';

interface IItem {
  title: string;
  id: number;
  endText: string;
  paragraph: string;
  img: string;
}

const Item: FC<IItem> = ({ title, id, endText, paragraph, img }) => {
  const classes = useStyles();

  return (
    <>
      <MarginContainer mormal={true}>
        <TitleNbr number={id} text={title} />

        <MarginContainer mormal={true}>
          <div className={classes.cardBgGrey}>
            <MarginContainer small={true}>
              <Grid container={true}>
                <Grid item={true} xs={12} md={2}>
                  <Img src={img} alt={title} className={classes.img} />
                </Grid>
                <Grid item={true} xs={12} md={10}>
                  <div className={classes.text}>
                    <MarginContainer small={true}>
                      <Paragrapth align="justify" addBackground={true}>
                        {parse(paragraph)}
                      </Paragrapth>
                    </MarginContainer>
                  </div>
                </Grid>
              </Grid>
            </MarginContainer>
            <div className={classes.yellowBackground}>
              <MarginContainer marginH={true} small={true}>{endText}</MarginContainer>
            </div>
          </div>
        </MarginContainer>
      </MarginContainer>
    </>
  );
};

export default Item;
