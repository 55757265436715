import { Typography } from '@material-ui/core';
import { gameBookTemplateActions, gameVariablesTemplateActions } from '@orientaction/api-actions';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import style from './style';

interface IEditorCitation {
  handleChange?: (infoImg: any) => void;
  defaultValue?: string;
  defaultAutor?: string;
  fieldUpdate?: string;
  fieldUpdateTwo?: string;
  isEbook?: boolean;
  isVariable?: boolean;
}
const EditorCitation: FC<IEditorCitation> = ({
  handleChange,
  defaultValue = '',
  defaultAutor = '',
  fieldUpdate = 'field',
  fieldUpdateTwo = 'field',
  isEbook = false,
  isVariable = false,
}) => {
  const classes = style();
  const [citation, setCitation] = useState<string>(defaultValue);
  const [autor, setAutor] = useState<string>(defaultAutor);
  const textareaRef = useRef(null);
  const dispatch = useDispatch();
  const handleChangeCitation = (e: any) => {
    setCitation(e.target.value);
    if (!isEbook) {
      handleFieldChange(fieldUpdate, e.target.value);
    }
    if (handleChange) {
      handleChange({
        citation: e.target.value,
        autor,
      });
    }
  };

  const handleChangeAutor = (e: any) => {
    setAutor(e.target.value);
    if (!isEbook) {
      handleFieldChange(fieldUpdateTwo, e.target.value);
    }
    if (handleChange) {
      handleChange({
        citation,
        autor: e.target.value,
      });
    }
  };

  const handleFieldChange = (field: string, value: any) => {
    if (isVariable) {
      dispatch(gameVariablesTemplateActions.updateField(field, value));
    } else {
      dispatch(gameBookTemplateActions.updateField(field, value));
    }
  };

  useEffect(() => {
    const textRef: any = textareaRef.current;
    if (textRef) {
      textRef.style.height = 'auto';
      textRef.style.height = `${textRef.scrollHeight}px`;
    }
  }, [citation]);

  return (
    <div>
      {isEbook && (
        <Typography variant="h5" className={classes.title} style={{ margin: '10px 0px' }}>
          Citation
        </Typography>
      )}
      <textarea
        ref={textareaRef}
        style={{
          ...{ minHeight: 135, fontStyle: 'italic' },
          ...(isEbook ? { outline: 'none', border: 'none', borderRadius: 0 } : {}),
        }}
        className={classes.editArea}
        placeholder="Cliquer pour ajouter la citation"
        value={citation}
        onInput={handleChangeCitation}
      />
      <Typography variant="h5" className={classes.title} style={{ margin: '10px 0px' }}>
        Auteur
      </Typography>
      <input
        className={classes.editArea}
        style={{
          ...{ height: 30, fontWeight: 'bold' },
          ...(isEbook ? { outline: 'none', border: 'none', borderRadius: 0 } : {}),
        }}
        type="text"
        placeholder="Cliquer pour ajouter l’auteur"
        value={autor}
        onInput={handleChangeAutor}
      />
    </div>
  );
};

export default EditorCitation;
