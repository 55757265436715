import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getServices = async (token: string) => {
  const params = qs.stringify(
    {
      fields: ['id', 'name'],
      sort: ['id:asc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/services?${params}`, 'GET', {}, getHeaders(token));
};

export const getPopulatedServices = async (token: string) => {
  const params = qs.stringify(
    {
      fields: ['id', 'name'],
      populate: {
        major_tests_templates: {
          fields: ['id', 'name', 'query'],
          sort: ['id:asc'],
        },
        book_templates: {
          fields: ['id', 'name', 'query'],
          sort: ['id:asc'],
        },
      },
      sort: ['id:asc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/services?$${params}`, 'GET', {}, getHeaders(token));
};

export const getUserServices = async (token: string, userId: string | number) => {
  const params = qs.stringify(
    {
      fields: ['id', 'name'],
      filters: {
        allowed_consultants: {
          id: userId,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/services?${params}`, 'GET', {}, getHeaders(token));
};

export const getPopulatedConsultantServices = async (token: string, userId: string | number) => {
  const params = qs.stringify(
    {
      fields: ['id', 'name'],
      filters: {
        allowed_consultants: {
          id: userId,
        },
      },
      populate: {
        major_tests_templates: {
          fields: ['id', 'name', 'query'],
          sort: ['id:asc'],
        },
        book_templates: {
          fields: ['id', 'name', 'query'],
          sort: ['id:asc'],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/services?$${params}`, 'GET', {}, getHeaders(token));
};

export const getUserTestsByServices = async (
  token: string,
  id: number | string,
  userId: number | string
) => {
  return makeRequest(
    `${url}/api/services/get-user-tests/${id}?userId=${userId}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getServicesByUser = async (token: string, userId: string | number) => {
  const params = qs.stringify(
    {
      fields: ['id', 'name'],
      filters: {
        allowed_consultants: {
          id: userId,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/services?$${params}`, 'GET', {}, getHeaders(token));
};

export const getServiceById = async (
  token: string,
  serviceId: string | number,
  fields = ['id', 'name'],
  filters = {},
  populate = {}
) => {
  const params = qs.stringify(
    {
      fields,
      filters,
      populate,
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/services/${serviceId}?${params}`, 'GET', {}, getHeaders(token));
};
