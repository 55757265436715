import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestionContainer: {
      width: '100%',
      backgroundColor: theme.palette.primary.light,
      fontFamily: 'Poppins',
      color: '#fff',
    },
    loveMatterRestitutionContainer: {
      '&.MuiPaper-outlined': {
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
  })
);

export default useStyles;
