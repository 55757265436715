// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
} from '@orientaction/components';
import useStyles from './style';
import Img from '../../components/Img';
import { MarginContainer } from '@orientaction/commons';

const Page18 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={18}
        >
            <TitlePage>
                DÉFI IMPOSSIBLE OU POSSIBLE (DIP)
            </TitlePage>

            <MarginContainer big={true}>

                <Paragrapth align='justify'>
                    Vous avez défini votre projet d’évolution professionnelle. Après avoir validé cet
                    objectif, il convient maintenant de définir le chemin qui va vous mener à cet objectif.
                    Cela vous permettra d’évaluer sa faisabilité, d’identifier les principaux obstacles et
                    les embûches qui pourraient survenir dans sa réalisation, de bien mesurer votre
                    engagement et ses implications dans sa mise en œuvre. Il s’agit de tout ce qu’il
                    faut accomplir pour réussir. N’oubliez pas qu’il est encore temps de renoncer. C’est
                    votre droit le plus strict.
                </Paragrapth>

            </MarginContainer>

            <MarginContainer veryBig={true}>
                <MarginContainer veryBig={true}>
                    <Img
                        src='defiPossibleOuImposible.png'
                        alt='DÉFI IMPOSSIBLE OU POSSIBLE (DIP)'
                        className={classe.img}
                    />
                </MarginContainer>
            </MarginContainer>

        </Page>
    );
};
export default Page18;
