import JSPDF from 'jspdf';
import viege from '@orientaction/Asset/PDF/RELEVÉ-DE-CONNEXION-vierge.png';
import moment from 'moment';

const releveConnexion = (data: any) => {

    const jsPdfPrint: any = new JSPDF();

    const image = new Image();

    image.src = viege;

    jsPdfPrint.setFontSize(11);

    jsPdfPrint.setFont(undefined, 'bold');

    // name 

    jsPdfPrint.text(79, 87.5, data.name);

    jsPdfPrint.text(118, 94, data.upHierachy);

    let startY = 126;

    let startX = 100;

    for (let y = 0; y < data.books.length; y++) {

        const CurrentX = data.books[y];

        for (let x = 0; x < CurrentX.length; x++) {

            jsPdfPrint.setFontSize(9);
            jsPdfPrint.text(startX, startY, CurrentX[x]);

            startX += 23;
        }

        startX = 100;

        startY += 12;

    }

    jsPdfPrint.setFontSize(11);

    jsPdfPrint.text(25, 220.25, data.finish);

    jsPdfPrint.addImage(image, 'PNG', 0, 0, 210, 297);

    jsPdfPrint.save(data.name, { returnPromise: true });

    jsPdfPrint.deletePage(1);

    jsPdfPrint.addPage();

}

export default releveConnexion;