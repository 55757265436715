/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import { Grid, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import Editor from '@orientaction/components/Editor';
import { gameBookValues } from '@orientaction/reducers';
import { labelBlockToShow } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import EditorCitation from '../../EditorCitation';
import EditorEncyclopedie from '../../EditorEncyclopedie';
import EditorImg from '../../EditorImg';
import '../choice.css';
import style from '../style';
import { styleSelect } from '../styleSelect';
import EditorQuill from '@orientaction/components/EditorQuill/index';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span
        style={{
          fontSize: 26,
          margin: 8,
          border: '1px solid #E4E4F2',
          borderRadius: '50%',
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        +
      </span>
      <span style={{ fontSize: 16 }}>Importer une image</span>
    </div>
  );
};
const Model2 = (props: any) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [open, setOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<any>({});
  const dispatch = useDispatch();
  const valuesBooksTemplate = useSelector(gameBookValues);
  const {
    handleActiveStyles,
    handleFocused,
    styleEditor3,
    styleEditor4,
    showLinkPopup3,
    showLinkPopup4,
    onChoiceClick,
    choices,
    choiceTitle,
    typeChoice,
    textEndGame = '',
    displayCondition,
  } = props;
  const [typeBloc, setTypeBloc] = useState<Array<string>>(['normal', 'normal']);
  const isChoice = choices.length > 0 || typeChoice === 'end_game';
  const optionsValue = [
    {
      value: 'normal',
      label: 'Normal',
    },
    {
      value: 'citation',
      label: 'Citation',
    },
    {
      value: 'encyclopedia',
      label: 'Encyclopédie',
    },
  ];
  const handleFieldChange = (field: string, value: any) => {
    dispatch(gameBookTemplateActions.updateField(field, value));
  };
  const handleFileUrlChange = (urlC: string) => {
    setUrl(urlC);
  };
  const handleFileChange = (fileC: any) => {
    // setFile(fileC);
    handleFieldChange('files', fileC);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setUrl('');
  };
  const handleOpen = (event: any) => {
    onChoiceClick(true);
    event.preventDefault();
  };

  const handleChangeSelect = (selected: any, numberBlock: number) => {
    setTypeBloc((prevNumbers) => {
      const newNumbers = [...prevNumbers];
      newNumbers[numberBlock] = selected;
      return newNumbers;
    });
  };

  const allPages = sessionStorage.getItem('gamePages')
    ? JSON.parse(sessionStorage.getItem('gamePages') || '')
    : [];

  function getTitleByIndex(id: number) {
    const page = allPages.find((res: any) => res.id === id);

    if (id === -1) {
      return 'Couverture du jeu'
    }
    if (page) {
      return page.title || 'Page sans titre';
    }

    return 'ID non trouvé';
  }

  return (
    <div className={classes.container} onClick={(e) => e.stopPropagation()} key={valuesBooksTemplate.id}>
      <Grid container={true} style={{ height: '100%' }}>
        <Grid
          item={true}
          sm={6}
          className={classes.blockContentParent}
          style={{ borderRight: '1px solid #E1E1E1' }}
        >
          <div className={classes.blockContent}>
            <div className={classes.blockType}>
              <Typography variant="h5" className={classes.title}>
                {labelBlockToShow[valuesBooksTemplate.contentForType1]}
              </Typography>
              <div className={classes.blockTypeInside}>
                <Typography variant="h5" className={classes.titleType}>
                  Type de bloc :
                </Typography>
                <Select
                  className={classes.select}
                  options={optionsValue}
                  value={optionsValue.find(
                    (option) => option.value === valuesBooksTemplate.contentForType1,
                  )}
                  onChange={(selected) => {
                    if (selected) {
                      handleChangeSelect(selected.value, 0);
                      handleFieldChange('contentForType1', selected.value);
                    }
                  }}
                  styles={styleSelect}
                />
              </div>
            </div>
            <div className={classes.blockText}>
              {valuesBooksTemplate.contentForType1 === 'normal' && (
                <EditorQuill
                  isSmall={true}
                  styleEditor={null}
                  defaultValue={valuesBooksTemplate.p1 || ''}
                  onChange={(content) => {
                    dispatch(gameBookTemplateActions.updateField('p1', content))
                  }}
                />
                // <Editor
                //   isSmall={true}
                //   handleActiveStyles={(data: any) => {
                //     if (handleActiveStyles) handleActiveStyles(data);
                //   }}
                //   handleFocused={(isFocused: boolean) => {
                //     if (handleFocused) handleFocused(isFocused, 3);
                //   }}
                //   styleEditor={styleEditor3}
                //   showLinkPopupE={showLinkPopup3}
                //   field="p1"
                //   defaultValue={valuesBooksTemplate.p1 || ''}
                //   key={valuesBooksTemplate.id}
                // />
              )}
              {valuesBooksTemplate.contentForType1 === 'citation' && (
                <EditorCitation
                  fieldUpdate="citationContent1"
                  fieldUpdateTwo="citationAuthor1"
                  defaultValue={valuesBooksTemplate.citationContent1}
                  defaultAutor={valuesBooksTemplate.citationAuthor1}
                  key={valuesBooksTemplate.id}
                />
              )}
              {valuesBooksTemplate.contentForType1 === 'encyclopedia' && (
                <EditorQuill
                  isSmall={true}
                  styleEditor={null}
                  defaultValue={valuesBooksTemplate.informationEncyclopedia1 || ''}
                  onChange={(content) => {
                    dispatch(gameBookTemplateActions.updateField('informationEncyclopedia1', content));
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.blockContent}>
            <Typography variant="h5" className={classes.title}>
              Illustration
            </Typography>
            <div style={{ marginTop: 15, position: 'relative', maxHeight: 410 }}>
              <EditorImg
                key={valuesBooksTemplate.id}
                keyComponent={valuesBooksTemplate.id}
                width={508}
                height={410}
                handleFile={handleFileChange}
                defaultUrl={
                  valuesBooksTemplate?.illustration?.url || valuesBooksTemplate?.tempImage || null
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item={true} sm={6} className={classes.blockContentParent}>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div className={classes.blockType}>
              <Typography variant="h5" className={classes.title}>
                {labelBlockToShow[valuesBooksTemplate.contentForType2]}
              </Typography>
              <div className={classes.blockTypeInside}>
                <Typography variant="h5" className={classes.titleType}>
                  Type de bloc :
                </Typography>
                <Select
                  className={classes.select}
                  options={optionsValue}
                  value={optionsValue.find(
                    (option) => option.value === valuesBooksTemplate.contentForType2,
                  )}
                  onChange={(selected) => {
                    handleChangeSelect(selected?.value, 1);
                    handleFieldChange('contentForType2', selected?.value);
                  }}
                  styles={styleSelect}
                />
              </div>
            </div>
            <div className={classes.blockText2}>
              {valuesBooksTemplate.contentForType2 === 'normal' && (
                <EditorQuill
                  isSmall={false}
                  styleEditor={null}
                  defaultValue={valuesBooksTemplate.p2 || ''}
                  onChange={(content) => {
                    dispatch(gameBookTemplateActions.updateField('p2', content))
                  }}
                />
                // <Editor
                //   handleActiveStyles={(data: any) => {
                //     if (handleActiveStyles) handleActiveStyles(data);
                //   }}
                //   handleFocused={(isFocused: boolean) => {
                //     if (handleFocused) handleFocused(isFocused, 4);
                //   }}
                //   styleEditor={styleEditor4}
                //   showLinkPopupE={showLinkPopup4}
                //   field="p2"
                //   defaultValue={valuesBooksTemplate.p2 || ''}
                //   isSmall={true}
                //   key={valuesBooksTemplate.id}
                // />
              )}
              {valuesBooksTemplate.contentForType2 === 'citation' && (
                <EditorCitation
                  fieldUpdate="citationContent2"
                  fieldUpdateTwo="citationAuthor2"
                  defaultValue={valuesBooksTemplate.citationContent2}
                  defaultAutor={valuesBooksTemplate.citationAuthor2}
                  key={valuesBooksTemplate.id}
                />
              )}
              {valuesBooksTemplate.contentForType2 === 'encyclopedia' && (
                <EditorQuill
                  isSmall={false}
                  styleEditor={null}
                  defaultValue={valuesBooksTemplate.informationEncyclopedia2 || ''}
                  onChange={(content) => {
                    dispatch(gameBookTemplateActions.updateField('informationEncyclopedia2', content));
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            {typeChoice === 'end_game' ? (
              <Typography variant="h6" className={classes.titleEndGame}>
                CHOIX FIN DU JEU
              </Typography>
            ) : (
              <Typography variant="h5" className={classes.title}>
                Choix
              </Typography>
            )}
            <div
              onClick={handleOpen}
              className="wrapperchoices"
              style={{ marginTop: 10, height: '100%' }}
            >
              <>
                {typeChoice === 'end_game' ? (
                  <h3 className="choicetitleEnd">
                    {choiceTitle || 'Ceci est la fin du jeu. Merci.'}
                  </h3>
                ) : (
                  <h3 className="choicetitle">{choiceTitle || ''}</h3>
                )}
                {typeChoice === 'end_game' && (
                  <>
                    {' '}
                    <div className="wrapper-btn">
                      <button className="custom-button">
                        <span>{textEndGame || 'Ajouter un texte de fin du jeu'}</span>
                        <ArrowForwardIcon className="icon" />
                      </button>
                    </div>{' '}
                  </>
                )}
                {isChoice ? (
                  typeChoice !== 'end_game' &&
                  choices.slice(0, 4).map((choice: any, index: any) => (
                    <div key={choice.id} className="choice-container">
                      <div className="choice-title">
                        {' '}
                        {typeChoice === 'pursue' ? 'Poursuivre' : `Choix ${index + 1}`}{' '}
                      </div>
                      <div
                        className="choice-content"
                        dangerouslySetInnerHTML={{
                          __html: displayCondition === 'horizontal' ? choice?.text : choice?.name,
                        }}
                      />
                      <div className="choice-button">
                        <button>
                          <span className="arrow">{}</span>
                          <span className="intro">
                            {' '}
                            {allPages?.length > 0 &&
                            choice.destination &&
                            typeChoice !== 'redirection'
                              ? getTitleByIndex(choice.destination)
                              : choice?.destinationUrl}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={classes.containerChoice}>
                    <div className={classes.choice}>
                      <span
                        style={{
                          fontSize: 26,
                          margin: 8,
                          border: '1px solid #E4E4F2',
                          borderRadius: '50%',
                          width: 36,
                          height: 36,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                      >
                        +
                      </span>
                      <span style={{ fontSize: 16, cursor: 'pointer' }}>Ajouter des choix</span>
                    </div>
                  </div>
                )}
                {isChoice && (
                  <div className="manage-choices">
                    <button className="manage-choices-button">
                      Gérer les choix
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M7.16667 15.5V10.5H8.83333V12.1667H15.5V13.8333H8.83333V15.5H7.16667ZM0.5 13.8333V12.1667H5.5V13.8333H0.5ZM3.83333 10.5V8.83333H0.5V7.16667H3.83333V5.5H5.5V10.5H3.83333ZM7.16667 8.83333V7.16667H15.5V8.83333H7.16667ZM10.5 5.5V0.5H12.1667V2.16667H15.5V3.83333H12.1667V5.5H10.5ZM0.5 3.83333V2.16667H8.83333V3.83333H0.5Z"
                            fill="#000A8C"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
              </>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Model2;
