/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page25 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={25}>
      <div style={{ marginBottom: '20px' }}>
        <div className={classes.cardBgGrey}>
          <div style={{ marginBottom: '30px' }}>
            {/* <img className={classes.imgTailleur} src={`${imgBookIntro}/titre38.png`} alt="logo" /> */}
            <SimpleBanner>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                LE MONT BLANC
              </Typography>
            </SimpleBanner>
          </div>
          <img className={classes.img} src={`${imgBookIntro}/montblanc.png`} alt="logo" />
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Je me souviens d’être parti un jour avec un guide de haute montagne pour escalader le
            mont Blanc.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Nous étions partis tôt le matin et, après avoir gravi la première partie du chemin
            couvert de pierres, nous sommes arrivés sur un plateau d’où commençait le glacier.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            J’ai regardé devant moi : 1000 m d’une falaise abrupte nous séparait de l’aiguille du
            Goûter, un refuge accroché à la falaise au pied du mont Blanc. Je ne voyais aucun chemin
            pour y parvenir. J’avais l’impression que cette ascension était tout bonnement
            impossible !
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            J’ai pensé m’arrêter là. Le guide m’a alors expliqué qu’il y avait emmené beaucoup
            d’autres personnes avant moi. Ces personnes n’étaient pas plus entraînées, plus
            courageuses ou plus expérimentées, mais elles étaient parvenues au sommet.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Il m’a convaincu de faire encore un peu de chemin et nous avons continué. En escaladant
            le plateau, j’ai découvert une longue arête au centre du précipice que des alpinistes
            (de petites taches de couleur) étaient en train d’escalader.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            C’était un chemin difficile et abrupt, mais il était possible de l’emprunter !!!
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Cette histoire montre qu’il faut faire attention à nos représentations. Certaines
            actions nous paraissent impossibles à réaliser parce qu’on les méconnaît.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Pour savoir si un défi est réalisable ou pas, il faut prendre de l’information. Se faire
            accompagner, se faire guider est souvent indispensable.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Rencontrer des personnes qui ont réussi, qui sont passées par là, qui peuvent nous
            expliquer leur parcours et nous donner une évaluation exacte des difficultés qui nous
            attendent est un moyen de nous rassurer également.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Le DIP doit nous faire réfléchir, mais il ne doit jamais à lui seul nous arrêter.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <TitleNbr number={3} text="ÉCOUTER NOS PROCHES" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Lorsque nous entreprenons de changer, nous pensons spontanément que ce sont les
              personnes qui nous sont les plus proches, celles qui nous apprécient et que nous
              apprécions le plus, et sont donc censées vouloir notre bonheur, qui soutiendront avec
              le plus de force notre démarche.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Nous décidons donc de leur en parler pour avoir leur opinion et oh, surprise, les
              voilà qui doutent, qui nous mettent en garde ou nous accablent de reproches :
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
              }}
            >
              <strong>
                « Ne crois-tu pas que c’est un peu risqué? Tu es sûr(e)? À ta place, je ne ferais
                pas cela ! »
              </strong>
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '7px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Nous découvrons, non sans amertume, que les plus farouches ennemi(e)s de notre
              évolution sont aussi nos meilleurs ami(e)s !
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '7px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Notre conjoint(e), nos parents, nos frères et sœurs, nos enfants, nos collègues, nos
              voisins, notre manager(euse), toutes les personnes qui nous connaissent et que nous
              connaissons depuis de longues années.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '7px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Elles ne veulent pas que nous changions. Elles ne veulent pas que nous devenions
              quelqu’un d’autre que celui ou celle qu’elles ont l’habitude (encore une) de côtoyer.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '7px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Elles nous apprécient tels que nous sommes.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '30%',
      height: 'auto',
      display: 'block',
      marginRight: '20px',
      marginTop: '-10px',
      float: 'left',
      '@media only screen and (max-width: 600px)': {
        width: '90%',
        float: 'none',
        marginTop: '0px',
      },
      '@media only screen and (max-width: 361px)': {
        width: '90%',
        float: 'none',
        marginTop: '0px',
      },
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    textImg: {
      width: '40%',
      height: 'auto',
    },
  }),
);
export default Page25;
