export const list = [
    {
        title: 'VOS ZONES D’INCERTITUDE',
        list: [
            `Acceptation du projet en couple`,
            `Acceptation du projet par la famille et les ami(e)s`,
            `Validation logistique du déménagement (camion, dates, timing)`,
            `Validation du financement de la formation`,
            `Acceptation de l’école et de l’entreprise d’alternance`,
            `Recherche d’un logement sur le nouveau territoire`,
            `Trouver un(e) employeur(se)`
        ]

    },
    {
        title: 'CE QUE VOUS GAGNEREZ EN ALLANT AU BOUT DE VOTRE RÊVE',
        list: [
            `Enrichir le cursus professionnel dans la gestion de projet et la communication`,
            `Obtenir un niveau Bac +3`,
            `Découvrir une nouvelle région`,
            `Développer une nouvelle expérience professionnelle`,
            `Vivre une expérience personnelle`,
            `Enrichir le parcours avec des compétences annexes`,
            `Mettre en place concrètement les compétences antérieures et acquises pendant l’année`,
            `Avoir une maison plus grande pour un loyer moindre`,
            `Développer un réseau de contacts et de partenaires`,
            `Décrocher un emploi stable`
        ]
    }
]