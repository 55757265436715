/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@material-ui/core/Grid';
import { CompassUserContext } from '@orientaction/Context';
import {
  useAlert,
  useCompassMenu,
  useLanguage,
  useNeedsTestResult,
  useRedirectToRootPage,
} from '@orientaction/hooks';
import { useContext, useEffect, useState } from 'react';
import CardButton from '../../Components/Button';
import Card from '../../Components/Card';
import Description from '../../Components/Description';
import { useStyles } from './style';

const NeedsCardProPart = () => {
  const [result, setMyResult] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { language } = useLanguage();

  const alert = useAlert();

  const classe = useStyles();

  const { userInfos } = useContext<any>(CompassUserContext);

  const currentTest = userInfos?.service?.major_tests_templates.find((test: any) => {
    return test.id === 2;
  });

  const shouldBuy = currentTest?.shouldBuy || !userInfos?.rattachement || false;

  const hasAccess = currentTest?.hasRight || currentTest?.hasResult || false;

  const { needsTestLastResult } = useCompassMenu();

  const { getMyResult } = useNeedsTestResult();

  const { goToRootPage } = useRedirectToRootPage();

  const getResultat = async (idTest: number) => {
    setLoading(true);

    try {
      const { data } = await getMyResult(+idTest);
      setMyResult(data?.data?.pro?.favorite);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (needsTestLastResult?.id && typeof needsTestLastResult?.id === 'number') {
      getResultat(needsTestLastResult?.id);
    }
  }, [needsTestLastResult?.id]);

  const showNeutralVersion = !hasAccess || !currentTest?.hasResult;

  const withoutAccessRedirectButton = shouldBuy && (
    <CardButton redirectUri="https://orientaction.kneo.me/shop/view/120C39">
      Acheter le test
    </CardButton>
  );

  const pyramidImageSource = result?.imgPyramid.replace(/\/need\//g, '/need/pyramids/');
  useEffect(() => {
    console.log({result})
  }, [result])

  return (
    <Card
      to={`${language === 'GB' ? `/en/tests/needs` : `/tests/besoins`}`}
      disable={showNeutralVersion}
      loading={loading}
      to_resultat={`/resultat-need/${needsTestLastResult?.id}`}
      withAccess={hasAccess}
      noMarginTop={true}
      withoutAccessRedirectButton={withoutAccessRedirectButton}
    >
      <div className={classe.root}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <div className={classe.contentPiramyd}>
              <img
                src={pyramidImageSource || '/images/need/pyramid_neutre.png'}
                className={classe.imgPiramide}
                alt="Pyramide"
              />
            </div>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <Description>
              Voici votre besoin principal dans votre vie professionnelle. La satisfaction de vos
              besoins est à la source de votre bien-être et de votre motivation. Il est important
              que votre métier, vos conditions de travail et de rémunération, ainsi que vos
              relations professionnelles contribuent pleinement à satisfaire ce besoin.
            </Description>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default NeedsCardProPart;
