/* eslint-disable no-useless-return */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import React from 'react';

interface ISimpleModal {
  title?: string;
  message: string;
  handleClose: () => void;
  handleClick?: () => void;
  textClose?: string;
  textClick?: string;
  open: boolean
}

const SimpleModal: React.FC<ISimpleModal> = ({title, message, handleClose, open, handleClick, textClick, textClose = 'Ok'}) => {  
  
  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {textClose}
          </Button>
          {textClick && 
            <Button onClick={handleClick} color="primary">
              {textClick}
            </Button>
          }
        </DialogActions>
      </Dialog>
  );
};

export default SimpleModal;
