/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../../store';

export const setIndoorWeather = (nbr: number): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'INDOORWEATHER',
            payload: nbr,
        });
    };

export const setId = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'ID',
            payload: data,
        });
    };

export const setExperience = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'EXPERIENCE',
            payload: data,
        });
    };

export const setPlanB = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'PLANB',
            payload: data,
        });
    };

export const setReadyToSucess = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'READYTOSUCCESS',
            payload: data,
        });
    };

export const setWhenProjectStart = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'WHENPROJETSTART',
            payload: data,
        });
    };

export const setIfProjectFinish = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'IFPROJECTFINISH',
            payload: data,
        });
    };

export const setUncertainy = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'UNCERTAINY',
            payload: data,
        });
    };

export const setFullFillingYourDream = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'FULFILLINGYOURDREAM',
            payload: data,
        });
    };

export const setPlanProject = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'PLANPROJECT',
            payload: data,
        });
    };

export const setProjectProffessionalAndAspiration = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'PROJECTPROFFESSIONALANDASPIRATION',
            payload: data,
        });
    };

export const setFourPreferenceOfYourPersonnality = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'FOURPREFERENCEOFYOURPERSONNALITY',
            payload: data,
        });
    };

export const setThreeNeedAnswerThisProject = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'THREENEEDANSWERTHISPROJECT',
            payload: data,
        });
    };

export const setYourPersonnalProjectAndSkill = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'YOURPERSONNALPROJECTANDSKILL',
            payload: data,
        });
    };