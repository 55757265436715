/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { myResultOfTestAction } from '@orientaction/api-actions';
import { appMyResultOfTest } from '@orientaction/reducers';
import { IMyResultOfTest } from '@orientaction/types';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '@orientaction/utils';

const useMyResulatOfTest = () => {
  
  const dispatch = useDispatch();

  const state: IMyResultOfTest = useSelector(appMyResultOfTest);

  const token = getFromLS('userToken') || '';

  const setMyResult = (param: any) => {
    dispatch(myResultOfTestAction.setMyResult(param));
  };

  const setDialog = (param: any) => {
    dispatch(myResultOfTestAction.setDialog(param));
  };

  const setMyElement = (param: any) => {
    dispatch(myResultOfTestAction.setMyElement(param));
  };

  return {
    ...state,
    setMyResult,
    setDialog,
    setMyElement,
  };
};

export default useMyResulatOfTest;
