import Grid from '@material-ui/core/Grid';
import { useContext } from 'react';
import { TestContext } from '../../Context/TestContext';
import { QuestionEmpty } from '..';
import QuestionCardList from '../QuestionCardList';
import QuestionCreationForm from '../QuestionCreationForm';
import style from './style';

const QuestionSection = () => {
  const { test } = useContext<any>(TestContext);
  const cls = style();
  return (
    <>
      <h1 className={cls.subtitle}>Questionnaire</h1>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6} style={{ marginTop: 10 }}>
          {test.questions.length === 0 ? (
            <QuestionEmpty />
          ) : (
            <QuestionCardList questions={test.questions} />
          )}
        </Grid>
        <Grid item={true} xs={6} md={6}>
          <QuestionCreationForm />
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionSection;
