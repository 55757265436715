/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import DoubleBanner from '../../../components/DoubleBanner';
import Img from '../../components/Image';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page21 = (props: any) => {
  const classes = useStyles();

  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_mairie.png' : 'mairie.png';
  }, [language]);

  return (
    <Page id={21} readOnly={props.readOnly}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid item={true} xs={12} md={9}>
              {/* <Img
                filename="reussite.png"
                alt="reussite"
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                  margin: 'auto',
                }}
              /> */}
              <DoubleBanner>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                >
                  CELUI QUI VOULAIT NE DEVOIR
                </Typography>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer1, classes.bannerTitle)}
                >
                  SA RÉUSSITE QU’ À LUI-MÊME
                </Typography>
              </DoubleBanner>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <Img filename={image} alt="mairie" className={classes.img} />
            </Grid>

            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Cyril a 36 ans. Il est en recherche d’emploi. Cette période est très difficile à
                vivre pour ce jeune homme entreprenant qui a toujours réussi à piloter sa carrière
                comme il l’entendait.
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Je rencontre Cyril peu de temps avant qu’il ne soit reçu à un entretien de
                recrutement dans une mairie de sa ville. Le poste l’intéresse énormément. Il est
                très stressé par l’entretien qu’il doit avoir avec le maire et son équipe. Je
                connais très bien Cyril et il se trouve que son oncle est un homme politique
                influent, appartenant au même parti que le maire de la ville en question. Je suggère
                à Cyril de contacter son oncle, afin d’obtenir une lettre de recommandation. Cyril
                refuse catégoriquement et se justifie : il ne veut pas de piston !
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Il trouve ce système injuste et malsain. Je respecte son choix et nous préparons les
                meilleurs arguments pour le poste. Cyril a de réelles compétences et une motivation
                énorme. Il peut l’emporter. L’entretien se déroule à merveille. Cyril est certain de
                l’emporter. Une semaine plus tard, il reçoit un refus. Ils ont choisi une autre
                personne, qui comme par hasard évolue dans les milieux politiques locaux. Cyril
                s’aperçoit de son erreur. Utiliser son réseau à bon escient est aussi une compétence
                !
              </Paragrapth>
            </Grid>
          </Grid>
        </MarginContainer>
      </div>
      <div style={{ marginTop: 20 }}>
        <Paragrapth align="justify">
          L’outil suivant va vous aider à identifier et à mesurer le capital social que vous pouvez
          mobiliser pour réussir votre projet. N’oubliez pas que le capital social, comme le capital
          économique, possède une dimension subjective : il y a les gens que je connais, ceux qui
          sont prêts à me rendre service et ceux à qui je vais oser demander un service.
        </Paragrapth>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          Si vous refusez de solliciter quelqu’un de votre entourage, demandez-vous pourquoi. Si
          vous trouvez la raison légitime et justifiée, alors renoncez. Sinon, soyez pragmatique et
          dépassez vos freins.
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -136,
      [theme.breakpoints.down(1300)]: {
        marginTop: -91,
      },
    },
    bannerTitleContainer1: {
      margin: 'auto',
      marginTop: 51,
      [theme.breakpoints.down(1300)]: {
        marginTop: 24,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      textAlign: 'center',
      color: 'white',
      fontSize: 20,
      [theme.breakpoints.down(1300)]: {
        fontSize: 16,
      },
    },
  }),
);

export default Page21;
