/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr, TitlePage } from '@orientaction/components';
import ParagraphWithMargin from '../../components/ParagraphWithMargin';
import { blockParagraphs, paragraphs } from './constant';
import useStyles from './style';


const Page11 = () => {
  const classes = useStyles();
  return (
    <Page id={11}>
      <>
        <MarginContainer>
          <TitleNbr number={2} text="LES « SOFT SKILLS » (LE TRONC)" />
        </MarginContainer>
        <ParagraphWithMargin lists={paragraphs} />
        <div style={{ border: '2px solid grey', padding: '15px' }}>
          <TitlePage titlePageOverrideStyle={classes.titlePageOverrideStyle}>
            CE QUI DISTINGUE UN(E) JEUNE DIPLÔMÉ(E)
            <br />
            D’UN(E) SÉNIOR(E)
            <br />
          </TitlePage>
          <ParagraphWithMargin lists={blockParagraphs} />
        </div>
      </>
    </Page>
  );
};

export default Page11;
