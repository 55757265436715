import { Grid, Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import useStyles from '../../style';
import { blockRedirection, imgBook1 } from '@orientaction/utils';
import { useLanguage } from '@orientaction/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';
import SimpleModal from '@orientaction/components/SimpleModal';

const Page012 = (props: any) => {
  const classe = useStyles();
  const { language } = useLanguage();
  const lang: string = useSelector(appLanguage);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const coverPUB = useMemo(() => {
    console.log({lang, language});
    return lang === 'GB' ? 'en_PUB.png' : 'PUB.png';
  }, [lang, language]);

  return (
    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} xs={12} md={8}>
          <Card className={classe.couverturePage}>
            <img
              src={`${imgBook1}/${coverPUB}`}
              alt="couverture intro"
              className={classe.imgpremierApercu}
            />
            <span
              
              onClick={() =>
                blockRedirection({
                  language: lang,
                  onOpen: () => setOpenModal(true),
                  functionToPass: () => {
                    window.open(
                      'https://orientaction.kneo.me/shop/view/1438E0?_gl=1*663ado*_ga*MjA3NzM4MjQ3Ny4xNjM4MTk5OTI4*_ga_YH6Q45MREE*MTY5NTM3MDM1OS41NzcuMS4xNjk1MzcxMzUxLjAuMC4w',
                      '_blank'
                    );
                  },
                })
              }
              className={classe.btn012}>
              Clique
            </span>
          </Card>
        </Grid>
      </Grid>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Not yet available in English – work in progress."
      />
    </div>
  );
};

export default Page012;
