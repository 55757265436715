/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { userService } from '@orientaction/services';
import { emailMatch, publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import AuthFormHeader from '../../../Commons/AuthFormHeader';
import locale from '../locale.json';
import { style } from './style';

interface IProps {
  nextStep: (data: any) => void;
  setData: (data: any) => void;
  showAccountConfirm: boolean;
  handleAccountConfirm: (data: any) => void;
  dataStep1: any;
}

const RegisterForm: FC<IProps> = ({
  nextStep,
  setData,
  showAccountConfirm,
  handleAccountConfirm,
  dataStep1,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<string>('');

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<any>({
    mode: 'onChange',
  });

  const newPasswordRef = useRef({});

  newPasswordRef.current = watch('password', '');

  const confirmPasswordRef = useRef({});

  confirmPasswordRef.current = watch('confirmPassword', '');

  const cls = style();

  const onSubmitHandler = async (data: any) => {
    setIsLoading(true);

    try {
      await userService.verifyIfMailExist(data.email);
      setData(data);
      nextStep(1);
    } catch (error) {
      setRegisterError(
        "Cette adresse e-mail est déjà utilisée. Veuillez vous connecter ou changer d'adresse pour créer votre compte",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AuthFormHeader
        title={locale.title}
        subtitle={locale.subtitle}
        questionText={locale.lreadyRegisteredText || ''}
        redirectText={locale.ConnectText}
        linkTo="/"
        showAccountConfirm={showAccountConfirm}
        handleAccountConfirm={handleAccountConfirm}
        noSubtitle={true}
      />

      {!showAccountConfirm && (
        <div className={cls.formContainer}>
          <form onSubmit={handleSubmit(onSubmitHandler)} className={cls.form}>
            <div className={cls.fieldWithLabel}>
              <Controller
                name="lastname"
                control={control}
                defaultValue={dataStep1.lastname}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="lastname">
                      <h5 className={cls.labelText}>{locale.lastname}</h5>
                    </label>
                    <Input
                      placeholder={locale.lastnamePlaceholder}
                      {...field}
                      type="text"
                      className={cls.input}
                      name="lastname"
                    />

                    {errors?.lastname?.message && (
                      <span className={cls.errorField}>{(errors as any).lastname.message}</span>
                    )}
                  </>
                )}
              />
            </div>
            <div className={cls.fieldWithLabel}>
              <Controller
                name="firstname"
                control={control}
                defaultValue={dataStep1.firstname}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="firstname">
                      <h5 className={cls.labelText}>{locale.firstname}</h5>
                    </label>
                    <Input
                      placeholder={locale.firstnamePlaceholder}
                      {...field}
                      type="text"
                      className={cls.input}
                      name="firstname"
                    />
                    {errors?.firstname?.message && (
                      <span className={cls.errorField}>{(errors as any).firstname.message}</span>
                    )}
                  </>
                )}
              />
            </div>
            <div className={cls.fieldWithLabel}>
              <Controller
                name="email"
                control={control}
                defaultValue={dataStep1.email}
                rules={{
                  required: 'Champ obligatoire',
                  pattern: {
                    value: emailMatch,
                    message: 'Veuillez saisir une adresse e-mail valide',
                  },
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="email">
                      <h5 className={cls.labelText}>{locale.email}</h5>
                    </label>
                    <Input
                      placeholder={locale.emailPlaceholder}
                      {...field}
                      type="text"
                      name="email"
                      className={cls.input}
                    />
                    {errors?.email?.message && (
                      <span className={cls.errorField}>{(errors as any).email.message}</span>
                    )}
                  </>
                )}
              />
            </div>
            <div className={cls.fieldWithLabel}>
              <Controller
                name="password"
                control={control}
                defaultValue={dataStep1.password}
                rules={{
                  required: 'Champ obligatoire',
                  pattern: {
                    value: /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
                    message:
                      'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : Lettres minuscules (a-z) | Lettres majuscules (A-Z) | Chiffres (0-9) |Caractères spéciaux (ex. !@#)}',
                  },
                  validate: {
                    value: (value) => {
                      if (
                        confirmPasswordRef &&
                        confirmPasswordRef.current &&
                        confirmPasswordRef.current !== ''
                      ) {
                        if (confirmPasswordRef.current === value) {
                          if (errors?.confirmPassword) {
                            (errors as any).confirmPassword.message = '';
                          }
                        } else if (errors?.confirmPassword) {
                          (errors as any).confirmPassword.message =
                            "Le mot de passe n'est pas identique";
                        } else {
                          (errors as any).confirmPassword = {};
                          (errors as any).confirmPassword.message =
                            "Le mot de passe n'est pas identique";
                        }
                      }
                      return newPasswordRef.current === value;
                    },
                  },
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="newPassword">
                      <h5 className={cls.labelText}>{locale.password}</h5>
                    </label>
                    <Input
                      placeholder={locale.passwordPlaceholder}
                      {...field}
                      type={eyeOn ? 'text' : 'password'}
                      className={clsx(cls.input, cls.inputPassword)}
                      name="password"
                      endAdornment={
                        <InputAdornment position="end">
                          {eyeOn ? (
                            <img
                              alt="eye-switch-on"
                              src={`${publicSvgPath}/eyeOn.svg`}
                              className={cls.eyeImg}
                              onClick={() => setEyeOn(false)}
                              onKeyDown={() => setEyeOn(false)}
                            />
                          ) : (
                            <img
                              alt="eye-switch-off"
                              src={`${publicSvgPath}/eyeOff.svg`}
                              className={cls.eyeImg}
                              onClick={() => setEyeOn(true)}
                              onKeyDown={() => setEyeOn(true)}
                            />
                          )}
                        </InputAdornment>
                      }
                    />
                    {errors?.password?.message && (
                      <span className={cls.passwordErrorField}>
                        {(errors as any).password.message}
                      </span>
                    )}
                  </>
                )}
              />
            </div>
            <div className={cls.fieldWithLabel}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue={dataStep1.confirmPassword}
                rules={{
                  required: 'Champ obligatoire',
                  validate: {
                    value: (value) =>
                      value === newPasswordRef.current || "Le mot de passe n'est pas identique",
                  },
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="confirmPassword">
                      <h5 className={cls.labelText}>{locale.confirmPassword}</h5>
                    </label>
                    <Input
                      placeholder={locale.confirmPasswordPlaceholder}
                      {...field}
                      type={eyeOn ? 'text' : 'password'}
                      className={clsx(cls.input, cls.inputPassword)}
                      name="confirmPassword"
                      endAdornment={
                        <InputAdornment position="end">
                          {eyeOn ? (
                            <img
                              alt="eye-switch-on"
                              src={`${publicSvgPath}/eyeOn.svg`}
                              className={cls.eyeImg}
                              onClick={() => setEyeOn(false)}
                              onKeyDown={() => setEyeOn(false)}
                            />
                          ) : (
                            <img
                              alt="eye-switch-off"
                              src={`${publicSvgPath}/eyeOff.svg`}
                              className={cls.eyeImg}
                              onClick={() => setEyeOn(true)}
                              onKeyDown={() => setEyeOn(true)}
                            />
                          )}
                        </InputAdornment>
                      }
                    />
                    {errors?.confirmPassword?.message && (
                      <span className={cls.passwordErrorField}>
                        {(errors as any).confirmPassword.message}
                      </span>
                    )}
                  </>
                )}
              />
            </div>
            <div className={cls.checkBoxCguContainer}>
              <Controller
                name="cgu"
                control={control}
                defaultValue={dataStep1.cgu}
                rules={{
                  required: "Veuillez accepter les conditions générales d'utilisation",
                }}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={dataStep1.cgu}
                    className={cls.checkBoxCgu}
                    id="cgu"
                    color="primary"
                    data-cy="cgu-cy"
                  />
                )}
              />
              <label htmlFor="cgu">
                <h5 className={cls.cguAcceptText}>
                  {locale.cguAcceptText}{' '}
                  <Link
                    className={cls.cguLinkText}
                    to="../../../../../orientaction-cgu.pdf"
                    target="_blank"
                  >
                    {locale.cguLinkText}
                  </Link>
                </h5>
              </label>
            </div>

            <h5 className={cls.errorField}>{(errors as any).cgu && (errors as any).cgu.message}</h5>

            {registerError?.trim()?.length > 0 && (
              <h5 className={cls.errorField}>{registerError}</h5>
            )}

            <Button
              type="submit"
              className={cls.btnConnect}
              disabled={isLoading}
              data-cy="step-one-register"
            >
              {isLoading ? `Suivant...` : `Suivant`}
            </Button>
          </form>
        </div>
      )}
    </>
  );
};

export default RegisterForm;
