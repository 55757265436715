/* eslint-disable react/destructuring-assignment */
import CompetitorSuggestion from './CompetitorSuggestion';

const Suggestion = () => {
  return (
    <>
      <CompetitorSuggestion />
    </>
  );
};

export default Suggestion;
