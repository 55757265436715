import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getApps, initializeApp } from 'firebase/app';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from 'react-simple-snackbar';
import { UserProfilePhotoProvider } from './Context/UserProfilePhotoContext';
import { Router } from './router';
import myTheme from './themes/myTheme';
import { getFromLS } from './utils';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Editing this file with fast refresh will reinitialize the app on every refresh, let's not do that
if (!getApps().length) {
  initializeApp(firebaseConfig);
}

const { hostname } = new URL(process.env.REACT_APP_API_ENDPOINT!);

console.log({
  apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
  service: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  tracePropagationTargets: [new RegExp(hostname, 'i')], // Set to link traces from frontend to backend requests
  consoleCapture: true, // Capture console logs (default false)
  advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
});
// HyperDX.init({
//   apiKey: process.env.REACT_APP_HYPERDX_API_KEY!,
//   service: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
//   tracePropagationTargets: [new RegExp(hostname, 'i')], // Set to link traces from frontend to backend requests
//   consoleCapture: true, // Capture console logs (default false)
//   advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
// });
// HyperDX.setGlobalAttributes({
//   appId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
// });

function App() {
  const currentUser = JSON.parse(getFromLS('userInfos') || '{}');

  /* if (currentUser) {
    HyperDX.setGlobalAttributes({
      appId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
      userId: currentUser.id,
      userEmail: currentUser.email,
     });
  } */
  return (
    <SnackbarProvider>
      <HelmetProvider>
        <UserProfilePhotoProvider>
          <ThemeProvider theme={myTheme}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </ThemeProvider>
        </UserProfilePhotoProvider>
      </HelmetProvider>
    </SnackbarProvider>
  );
}

export default App;
