/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { categoryTestAction } from '@orientaction/api-actions';
import { appCategoryTest } from '@orientaction/reducers';
import { ICategoryTest, IcontentICategoryTest } from "@orientaction/types";
import { categoryTest } from "@orientaction/services";
import { getFromLS } from '@orientaction/utils';
import { useDispatch, useSelector } from 'react-redux';
import useAlert from './useAlert';
import { useHistory } from 'react-router-dom';

const useCategoryTest = () => {

    const dispatch = useDispatch();

    const data: ICategoryTest = useSelector(appCategoryTest);

    const alert = useAlert();

    const token = getFromLS('userToken') || '';

    const currentUserInfo = JSON.parse(getFromLS('userInfos') || '');

    const history = useHistory();

    const setList = (props: any[]) => {
        dispatch(categoryTestAction.setList(props));
    };

    const setLoadingUpdate = (bool: boolean) => {
        dispatch(categoryTestAction.setLoadingUpdate(bool));
    }

    const setLoading = (bool: boolean) => {
        dispatch(categoryTestAction.setLoading(bool));
    }

    const setModal = (props: any) => {
        dispatch(categoryTestAction.setModal(props));
    }

    const setListTest = (props: any) => {
        dispatch(categoryTestAction.setListTest(props));
    }

    const resetModal = () => {
        dispatch(categoryTestAction.setModal({
            type: "",
            open: false,
            title: "",
            textCancel: "",
            textValidate: "",
            noComfirmButton: false,
        }));
    }

    const getCategoryTest = async (props: any) => {

        setLoading(true);

        try {

            const response = await categoryTest.getCategoryTest(token, props);

            setList(response.data.data);

        } catch (error) {
            alert.openSimpleAlert();
        } finally {
            setLoading(false);
        }
        
    }

    const getManageCategory = async (props: any) => {

        setLoading(true);

        try {

            const response = await categoryTest.getManageCategory(token, props);

            setList(response.data.data);

            setListTest(response.data.listTest);

        } catch (error) {
            alert.openSimpleAlert();
        } finally {
            setLoading(false);
        }
    }

    const getFilterTest = async (keyWord: string) => {

        // setLoading(true);

        // console.log("start");

        // try {
            
        const response = await categoryTest.filterCategoryTest(token, {
            keyWord: keyWord as string,
            companies: currentUserInfo?.company?.id
        });

        return response.data.data;

        //     setList(response.data.data);
        // } catch (error) {
        //     alert.openSimpleAlert();
        // } finally {

        //     console.log("end");
        //     setLoading(false);

        // }
    }

    const updateCategoryTest = async () => {

        setLoadingUpdate(true);

        try {

            const category: any[] = [];

            const dataFormated = data.list;

            for (let i = 0; i < dataFormated.length; i++) {

                category.push({
                    ...dataFormated[i],
                    contentList: data.listTest.filter((item: any) => item.category === dataFormated[i].id)
                })

            }

            const response: any = await categoryTest.updateCategoryTest(category, token);

            history.push('/admin/test');

        } catch (error) {
            alert.openSimpleAlert();
        } finally {
            setLoadingUpdate(false);
        }

    }

    const setUpdateList = (idCategory: number, newArray: any[]) => {

        const findIndex = data.list.findIndex((x: IcontentICategoryTest) => x && x?.id === idCategory);

        const array: any[] = data.list || [];

        array[findIndex] = {
            ...array[findIndex],
            contentList: newArray
        }

        setList(array);

    }

    return {
        ...data,
        setList,
        setLoading,
        setLoadingUpdate,
        getFilterTest,
        updateCategoryTest,
        getCategoryTest,
        setListTest,
        setUpdateList,
        setModal,
        resetModal,
        getManageCategory
    };
};

export default useCategoryTest;
