import { FC, memo, Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import PrivateRoute from '../../router/AdminRoute';
import { Loading } from '../Loading';

type AccessType = 'private' | 'public' | 'authentication';

export interface ProtectedLazyRouteProps extends RouteProps {
  noAccessRedirection?: string;
  isProtected?: boolean;
  isRootOnly?: boolean;
  fallbackComponent?: FC;
  access?: AccessType;
}

const defaultFallback = <Loading />;

const ProtectedLazyRoute: FC<ProtectedLazyRouteProps> = memo(props => {
  const { component, path } = props;
  const FallbackComponent = defaultFallback;
  console.log({path});

  const tags: any = document.getElementsByTagName('meta');
  const titleSignup = 'E-Orientaction – La 1ere plate-forme de tests en ligne française';
  const titleOA = 'ORIENTACTION';
  document.title = path === '/' || path === '/signup' ? titleSignup : titleOA;
  tags.description.content = path === '/' || path === '/signup' ? titleSignup : titleOA;

  return (
    <Suspense fallback={FallbackComponent}>
      <PrivateRoute {...props} component={component as any} />
    </Suspense>
  );
});

export default ProtectedLazyRoute;
