import { CircularProgress, Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Layout } from '@orientaction/components';
import { FC } from 'react';
import useMe from '../../hooks/useMe';
import useResultsClient from '../../hooks/useResultsClient';
import UserResultLayout from '../UserResultLayout/UserResultLayout';
import locale from './locale.json';
import { NoResult } from './NoResult';
import style from './style';
import WithResult from './WithResult';

const UserResult = () => {
  const classes = useStyles();
  const { results, resultToShow, onSelectPeriod, loading } = useResultsClient('email@test.com');
  const hasResult = results.length > 0;
  return (
    <>
      {hasResult && !loading && (
        <Layout isaddBackground={true} noPadding={true}>
          <WithResult
            results={results}
            resultToShow={resultToShow}
            onSelectPeriod={onSelectPeriod}
          />
        </Layout>
      )}
      {!hasResult && !loading && (
        <Layout isaddBackground={true}>
          <NoResult />
        </Layout>
      )}
      {loading && (
        <div className={classes.bodycenter}>
          <CircularProgress color="inherit" />
        </div>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  bodycenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
}));

export default UserResult;
