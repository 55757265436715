const INITIAL_STATE: any = {
    id: 0,
    PossibilityBonus: false,
    yourValue: {},
    indoorWeather: null,
    meanToYou: {},
    projectMakeSense: '',
    successLife: {},
    majoryA: {},
    majoryB: {},
    personalAspiratiom: {},
    fiveJobs: [],
    figureOfDestiny: [
        {
            type: '',
        },
        {
            type: '',
        },
        {
            type: '',
        },
    ],
    objectifSupreme: [],
};

const apiBook1 = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'ID': {
            return { ...state, id: action.payload };
        }
        case 'YOURVALUE': {
            return { ...state, yourValue: action.payload };
        }
        case 'INDOORWEATHER': {
            return { ...state, indoorWeather: action.payload };
        }
        case 'MEANTOYOU': {
            return { ...state, meanToYou: action.payload };
        }
        case 'PROJECTMAKESENSE': {
            return { ...state, projectMakeSense: action.payload };
        }
        case 'SUCCEELIFE': {
            return { ...state, successLife: action.payload };
        }
        case 'MAJORY_A': {
            return { ...state, majoryA: action.payload };
        }
        case 'MAJORY_B': {
            return { ...state, majoryB: action.payload };
        }
        case 'PERSONALASPIRATIONS': {
            return { ...state, personalAspiratiom: action.payload };
        }
        case 'FIVE_JOBS': {
            return { ...state, fiveJobs: action.payload };
        }
        case 'FIGUREOFDESTIN': {
            return { ...state, figureOfDestiny: action.payload };
        }
        case 'OBJECTIF_SUPREME': {
            return { ...state, objectifSupreme: action.payload }
        }
        default:
            return state;
    }
};

export default apiBook1;

