export const dataEmpty = {
  id: 52387,
  bc: 'Bilan',
  problem_solving: 92,
  confidence: 78,
  emotional_intelligence: 72,
  empathy: 63,
  communication: 52,
  time_management: 46,
  stress_management: 42,
  creativity: 38,
  realization_date: '2023-03-19T21:00:00.000Z',
  time_spent: 16,
  opt_in: 'Oui',
  user_email: 'thomas@mayasquad.com',
  score: 830,
  realization_period: 'Matin',
  createdAt: '2023-03-20T07:51:52.234Z',
  updatedAt: '2023-03-20T07:51:52.246Z',
  publishedAt: '2023-03-20T07:51:52.234Z',
  scoreTotal: 830,
};
