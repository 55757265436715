import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktop: {
      width: 701,
      height: '100%',
    },
    mobile: {
      width: 'auto',
      height: '100%',
    },
    content: {
      backgroundColor: 'white',
      overflowX: 'hidden',
    },
    head: {
      float: 'left',
      width: '100%',
      color: 'white',
      'border-top-left-radius': '0',
      'border-top-right-radius': '0',
      'border-bottom-left-radius': '50% 50%',
      'border-bottom-right-radius': '50% 50%',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      height: '94px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: 0,
      padding: 0,
      fontWeight: 'bold',
      fontSize: '26px',
      [theme.breakpoints.down(900)]: {
        position: 'absolute',
        fontSize: '20px',
        zIndex: 200,
      },
    },
    img: {
      width: 400,
      height: 400,
      [theme.breakpoints.down(900)]: {
        width: '70%',
        height: '70%',
      },
    },
    imgContent: {
      marign: '3vh 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    score: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '20px',
      textTransform: 'uppercase',
      color: '#23296F',
      [theme.breakpoints.down(900)]: {
        fontSize: '18px',
      },
    },
    contentDescription: {
      width: '90%',
      margin: '0 auto',
      [theme.breakpoints.down(900)]: {
        marginTop: 130,
      },
    },
    paragraph: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '18px',
      color: '#23296F',
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
      },
    },
    contentFavorite: {
      padding: '16px',
      borderRadius: '8px',
      '& li': {
        margin: '3vh 0',
      },
    },
    handleClose: {
      top: '-10.5%',
      left: '88%',
      color: 'white',
      zIndex: 600,
      [theme.breakpoints.down(900)]: {
        position: 'absolute',
        top: 20,
        left: '5%',
      },
    },
    colorWhite: {
      color: 'white',
    },
    contentLoader: {
      width: 701,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
    colorBlack: {
      color: '#0E1247',
    },
  }),
);
