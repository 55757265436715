import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paragraphe: {
            fontSize: '15px',
            color: 'black',
            '@media only screen and (max-width: 600px)': {
                textAlign: 'left !important',
            },
            '@media only screen and (max-width: 361px)': {
                textAlign: 'left !important',
            },
        },
        testimony: {
            textAlign: 'center',
            fontSize: '17px',
            color: '#2B56A5',
        },
        marginContent: {
            margin: '5vh 0'
        }
    })
);

export default useStyles;