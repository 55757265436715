export const listForm = [
  {
    name: 'DirectionOfEnergy',
    title: 'Orientation de l’énergie',
    title2: 'Préférence relationnelle',
    explication: `Exemple : c’est un métier où l’on est en relation très fréquente avec d’autres
        personnes. Mon trait de personnalité « Interaction » sera un atout.`,
  },
  {
    name: 'WayOfPerceivingTheWorld',
    title: 'Manière de percevoir le monde',
    title2: 'Préférence perceptive',
    explication: `Exemple : c’est un métier opérationnel, fait pour un « homme ou une femme
        d’action ». Mon trait de personnalité « Expérience » me permettra de bien m’adapter à ce type de fonctions.`,
  },
  {
    name: 'WayOfMakingDecisions',
    title: 'Manière de prendre des décisions',
    title2: 'Préférence émotionnelle',
    explication: `Exemple : c’est un métier où il est nécessaire d’écouter et de comprendre
        les autres sans les juger. Mon trait de personnalité « Empathie » sera
        un atout déterminant pour réussir.`,
  },
  {
    name: 'behaviorPreference',
    title: 'Manière de prendre des décisions',
    title2: 'Préférence comportementale',
    explication: `Exemple : ce métier nécessite d’être beaucoup investi(e) en termes de temps de travail et d’énergie. Mon trait de personnalité « Action » me permettra de réussir.`,
  },
  {
    name: 'howToOrganizeYourLife',
    title: 'Manière d’organiser sa vie',
    title2: 'Préférence environnementale',
    explication: `Exemple : ce métier s’exerce dans un environnement professionnel très structuré.
        Mon trait de personnalité « Organisation » fait que ce type d’environnement professionnel est fait pour moi.`,
  },
];
