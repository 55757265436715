// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
    createStyles({
        bookChoiceContainer: {
            backgroundColor: '#EDEEF2',
            color: '#0E1247',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            marginTop: 10,
        },
        bookChoiceLabel: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            marginBottom: 10,
        },
    })
);
