/* eslint-disable no-use-before-define */

import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr } from '@orientaction/components';
import ParagraphWithMargin from '../../components/ParagraphWithMargin';
import { paragraphs, secondParagraph } from './constant';

const Page13 = () => {
  return (
    <Page id={13}>
      <MarginContainer>
        <TitleNbr number={4} text="LA VALEUR AJOUTÉE (LES FRUITS)" />
      </MarginContainer>
      <ParagraphWithMargin lists={paragraphs} />
      <br />
      <Paragrapth align="center" colorBlue={true} bold={true}>
        « celui ou celle qui mange les beaux fruits de nos compétences ».
      </Paragrapth>
      <br />
      <ParagraphWithMargin lists={secondParagraph} />
      <br />
      <Paragrapth align="center" colorBlue={true} bold={true}>
        De quoi a-t-il (elle) besoin ?
      </Paragrapth>
      <Paragrapth align="center" colorBlue={true} bold={true}>
        Que dois-je savoir faire pour répondre correctement à ce besoin ?
      </Paragrapth>
    </Page>
  );
};

export default Page13;
