export const context = [
    {
        id: 1,
        title: 'On dit de moi que je suis :',
        ask: [
            {
                label: 'Dominateur(trice)',
                name: 'X'
            },
            {
                label: 'Sensible',
                name: 'Y'
            },
            {
                label: 'Méthodique',
                name: 'Z'
            }
        ]
    },
    {
        id: 2,
        title: 'Mes loisirs préférés :',
        ask: [
            {
                label: 'Pratiquer un sport de compétition',
                name: 'X'
            },
            {
                label: 'Assister à des concerts',
                name: 'Y'
            },
            {
                label: 'Faire des collections',
                name: 'Z'
            }
        ]
    },
    {
        id: 3,
        title: 'Quand j’entreprends quelque chose, je suis plutôt :',
        ask: [
            {
                label: 'Fonceur(se)',
                name: 'X'
            },
            {
                label: 'Intuitif(ve)',
                name: 'Y'
            },
            {
                label: 'Rigoureux(se)',
                name: 'Z'
            }
        ]
    },
    {
        id: 4,
        title: 'J’aime :',
        ask: [
            {
                label: 'Décider',
                name: 'X'
            },
            {
                label: 'Improviser',
                name: 'Y'
            },
            {
                label: 'Contrôler',
                name: 'Z'
            }
        ]
    },
    {
        id: 5,
        title: 'On dit de moi que je suis :',
        ask: [
            {
                label: 'Imprévisible',
                name: 'X'
            },
            {
                label: 'De confiance',
                name: 'Y'
            },
            {
                label: 'Responsable',
                name: 'Z'
            }
        ]
    },
    {
        id: 6,
        title: 'J’aime le travail :',
        ask: [
            {
                label: 'De management',
                name: 'X'
            },
            {
                label: 'Créatif',
                name: 'Y'
            },
            {
                label: 'D’organisation',
                name: 'Z'
            }
        ]
    },
    {
        id: 7,
        title: 'J’aime les activités :',
        ask: [
            {
                label: 'Commerciales',
                name: 'X'
            },
            {
                label: 'Artistiques',
                name: 'Y'
            },
            {
                label: 'D’organisation',
                name: 'Z'
            }
        ]
    },
    {
        id: 8,
        title: 'Le monde du travail qui me convient le mieux :',
        ask: [
            {
                label: 'Gérer un budget',
                name: 'X'
            },
            {
                label: 'Trouver des idées',
                name: 'Y'
            },
            {
                label: 'Assurer un contrôle de qualité',
                name: 'Z'
            }
        ]
    },
    {
        id: 9,
        title: 'Ce qui m’intéresse le plus :',
        ask: [
            {
                label: 'L’économie',
                name: 'X'
            },
            {
                label: 'Les arts',
                name: 'Y'
            },
            {
                label: 'L’informatique',
                name: 'Z'
            }
        ]
    },
    {
        id: 10,
        title: 'On dit de moi que :',
        ask: [
            {
                label: 'Je sais décider',
                name: 'X'
            },
            {
                label: 'J’ai des idées',
                name: 'Y'
            },
            {
                label: 'Je sais respecter les consignes',
                name: 'Z'
            }
        ]
    },
    {
        id: 11,
        title: 'J’aime :',
        ask: [
            {
                label: 'Gagner',
                name: 'X'
            },
            {
                label: 'Trouver',
                name: 'Y'
            },
            {
                label: 'Assurer',
                name: 'Z'
            }
        ]
    },
    {
        id: 12,
        title: 'Le métier que j’aimerais faire si j’en avais la possibilité :',
        ask: [
            {
                label: 'Directeur(trice)',
                name: 'X'
            },
            {
                label: 'Architecte',
                name: 'Y'
            },
            {
                label: 'Expert-comptable',
                name: 'Z'
            }
        ]
    }
]