export const lists = [
  {
    title: 'Imaginez en quoi ces connaissances pourraient vous être utiles ?',
    name: 'BeSuccessfulInLife',
    number: 1,
  },
  {
    title:
      'Pourraient-elles être utilisées dans le cadre d’une nouvelle activité professionnelle ?',
    name: 'SucceedInLife',
    number: 2,
  },
  {
    title: 'Si oui, laquelle (ou lesquelles) ?',
    name: 'mostImportant',
    number: 3,
  },
];

export const tableData = [
  {
    id: '32-table-head-1',
    firstColumn: 'Domaine 1',
    secondColumn: 'Domaine 2',
    thirdColumn: 'Domaine 3',
    children: [
      {
        id: '32-table-1-1',
        content: '',
      },
    ],
  },
];

export const arrayTextField = [
  {
    md: 4,
    xs: 4,
    title: 'DOMAINE 1',
    name: 'Domaine1',
    children: [
      {
        name: 'A1',
        placeholder: 'Aa'
      }
    ]
  },
  {
    md: 4,
    xs: 4,
    title: 'DOMAINE 2',
    name: 'Domaine2',
    children: [
      {
        name: 'A2',
        placeholder: 'Aa'
      }
    ]
  },
  {
    md: 4,
    xs: 4,
    title: 'DOMAINE 3',
    name: 'Domaine3',
    children: [
      {
        name: 'A3',
        placeholder: 'Aa'
      }
    ]
  }
]