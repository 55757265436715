// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

// used in useResponseUsers
export const postTestUser = async (token: string, data: any) => {
  return makeRequest(`${url}/api/test-users`, 'POST', data, getHeaders(token));
};

export const getTestUsers = async (token: string, idTest: string) => {
  const searchParams = qs.stringify(
    {
      filters: {
        test: {
          id: {
            $eq: idTest,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/test-users?${searchParams}&populate=*`, 'GET', {}, getHeaders(token));
};
