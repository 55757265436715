// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#aaa',
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    bgGrey: {
      background: '#EDEEF2',
    },
    bgWhite: {
      background: 'white',
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    btnWhite: {
      backgroundColor: '#fff#',
      color: '#0E1247',
      marginTop: 0,
      marginRight: 10,
      borderRadius: 3,
      opacity: 1,
      border: '1px solid grey',
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {
        padding: '1vh',
        minHeight: 48,
        height: 48,
        width: 140,
        minWidth: 130,
      },
    },
    textHeader: {
      display: 'none',
      marginLeft: 0,
      '@media(min-width: 600px)': {
        display: 'block',
      },
    },
    btnPrimary: {
      padding: '0 2.5vh',
      minHeight: 30,
      textTransform: 'none',
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
    },
    groupBtnAbsolute: {
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
    modalContent: {},
    modalTitle: {
      color: '#E63C3C',
      marginBottom: 30,
    },
    modalText: {
      fontSize: 14,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
    bouttonRedirect: {
      borderRadius: '5px',
      borderWidth: '4px',
      borderStyle: 'solid',
      borderImage: 'linear-gradient(to top, #000A8C  50% , #E63C3C 0% , #FFDC27 100%) 1',
      width: '100%',
    },
    flexLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    keyword: {
      marginRight: 20,
      width: 200,
      '& .MuiAutocomplete-inputRoot': {
        paddingTop: 4,
        paddingBottom: 5,
      },
      '& .MuiTextField-root': {
        border: '1px solid #CDCEDA',
        borderRadius: 4,
        height: 50,
      },
    },
    btn: {
      color: '#0E1247',
      background: '#FFFFFF',
      border: '1px solid #CDCEDA',
      borderRadius: 4,
      height: 50,
    },
    overrideMargin: {
      width: '94%',
      margin: '0 auto',
    },
  }),
);
