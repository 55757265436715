// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
    ListParagrapth,
    Paragrapth,
    Page,
    Img
} from '@orientaction/components';
import useStyles from "../../style";


const Page05 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={5}
        >


            <div className={classe.titleSteveJobs} style={{marginTop: '15vh'}}>
                <i>
                    « Il y a trois choses extrêmement dures :
                    <br />
                    l’acier, le diamant et se connaître soi-même. »
                </i>
            </div>

            <div style={{
                width: "7%",
                height: 10,
                backgroundColor: "yellow",
                margin: "5vh auto",
                color: "yellow"
            }}>-</div>

            <div style={{
                textAlign: 'center'
            }}>
                <Paragrapth colorBlue={true} align="center">Benjamin Franklin</Paragrapth>
            </div>

            <Img
                src="methodeBC.jpg"
                alt="Steve Jobs"
                style={{
                    width: "100%",
                }}
            />

        </Page>
    );
};
export default Page05;
