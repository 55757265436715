/* eslint-disable no-use-before-define */

import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr, TitlePage } from '@orientaction/components';
import { imgBook4 } from '@orientaction/utils';

const Page10 = () => {
  return (
    <Page id={10}>
      <TitlePage showSpanYellow={false}>LE MODÈLE DE L’ARBRE</TitlePage>
      <br />
      <>
        <MarginContainer>
          <TitleNbr number={1} text="LES 4 CAPITAUX (LES RACINES)" />
        </MarginContainer>
        <MarginContainer>
          <Paragrapth align="justify">
            Tout comme les arbres, les êtres humains ont des racines. Ils grandissent et se
            construisent à partir d’un terreau. La capacité de l’être humain à réussir dans ses
            projets dépend de la qualité et de la richesse de ce terreau. Une partie de cette
            richesse est héritée, l’autre est construite. Car à la différence de l’arbre, l’être
            humain est capable d’amender lui-même son terreau. À travers ses choix, ses expériences
            et ses apprentissages. Le terreau de l’être humain est constitué de 4 capitaux qu’il
            peut mobiliser afin de réussir ses projets.
          </Paragrapth>
        </MarginContainer>
        <br />
        <MarginContainer>
          <Paragrapth align="justify">
            <div style={{display: "flex", alignItems: 'flex-start'}}>
              <img style={{ width: '75px', height: 'auto' }} src={`${imgBook4}/1.png`} alt="logo" />
              <span style={{marginLeft: 20}}>
                <Paragrapth align="justify" bold={true} colorBlue={true}>
                  Économique :
                </Paragrapth>{' '}
                il s’agit des ressources financières (personnelles ou qu’elle peut obtenir d’un tiers)
                que la personne peut et accepte de mobiliser pour réaliser son projet. Une personne
                pourra ainsi investir une somme d’argent pour prendre en charge tout ou partie de sa
                formation dans le cadre d’une reconversion professionnelle par exemple.
              </span>
            </div>
          </Paragrapth>
        </MarginContainer>
        <br />
        <MarginContainer>
          <Paragrapth align="justify">
            <div style={{display: "flex", alignItems: 'flex-start'}}>
              <img style={{ width: '75px', height: 'auto' }} src={`${imgBook4}/2.png`} alt="logo" />
              <span style={{marginLeft: 20}}>
                <Paragrapth align="justify" bold={true} colorBlue={true}>
                Social :
                </Paragrapth>{' '}
                il s’agit des relations, contacts, connaissances directes ou indirectes que la personne
                est en mesure de mobiliser afin de réaliser son projet. Une personne pourra ainsi
                demander à un(e) ami(e) d’être mise en relation avec un(e) potentiel(le) recruteur(se)
                ou encore une lettre de recommandation à son député (sa députée).
              </span>
            </div>
          </Paragrapth>
        </MarginContainer>
        <br />
        <MarginContainer>
          <Paragrapth align="justify">
            <div style={{display: "flex", alignItems: 'flex-start'}}>
              <img style={{ width: '75px', height: 'auto' }} src={`${imgBook4}/3.png`} alt="logo" />
              <span style={{marginLeft: 20}}>
                <Paragrapth align="justify" bold={true} colorBlue={true}>
                  Culturel :
                </Paragrapth>{' '}
                il s’agit des connaissances, explicites et implicites, que la personne peut mobiliser
                pour réaliser son projet. La connaissance d’un domaine professionnel et de ses codes
                peut favoriser le développement de sa carrière dans ce domaine. Pour réussir dans la
                mode, mieux vaut connaître le langage parlé dans l’univers de la mode !
                </span>
            </div>
          </Paragrapth>
        </MarginContainer>
        <br />
        <MarginContainer>
          <Paragrapth align="justify">
            <div style={{display: "flex", alignItems: 'flex-start'}}>
              <img style={{ width: '75px', height: 'auto' }} src={`${imgBook4}/4.png`} alt="logo" />
              <span style={{marginLeft: 20}}>
                <Paragrapth align="justify" bold={true} colorBlue={true}>
                  Symbolique :
                </Paragrapth>{' '}
                il s’agit de notre rayonnement personnel tiré du rayonnement de ce que nous faisons. Par
                exemple, le fait d’avoir travaillé dans une grande entreprise nous donnera une belle
                carte de visite pour créer notre propre entreprise. Avoir exercé dans le domaine médical
                suscitera la curiosité et l’admiration, génératrices de contacts et donc d’opportunités.
              </span>
            </div>
          </Paragrapth>
        </MarginContainer>
        <br />
        <MarginContainer>
          <Paragrapth align="justify">
            Ces 4 capitaux constituent nos racines. Nous puisons en eux la force de réussir. Nous
            nous appuyons sur eux pour nous développer, vaincre les difficultés, relever des défis.
            Dans une phase de changement, ces quatre ressources sont capitales. En effet, elles
            peuvent faire la différence et faciliter un changement ou au contraire le rendre plus
            difficile.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">
            Plus une personne a du capital (économique, social, culturel, symbolique), plus il est
            facile pour elle d’évoluer au niveau professionnel, plus son degré de liberté est élevé.
          </Paragrapth>
        </MarginContainer>
        <MarginContainer>
          <Paragrapth align="justify" bold={true} colorBlue={true}>
            D’où l’intérêt d’adopter une véritable stratégie de capitalisation en suivant cette
            règle jamais démentie : le capital attire le capital.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify" bold={true} colorBlue={true}>
            Plus j’ai d’argent, plus il m’est facile d’en gagner ; plus j’ai de relations, plus il
            m’est facile de développer mes réseaux.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify" bold={true} colorBlue={true}>
            Plus j’ai de connaissances, plus il m’est facile d’apprendre ; plus je brille et je
            rayonne, plus il m’est facile d’accéder à des activités dans lesquelles je brille et je
            rayonne. L’inverse est également vrai.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify" bold={true} colorBlue={true}>
            À contrario, il convient donc de développer et d’entretenir sans cesse ses 4 capitaux.
          </Paragrapth>
        </MarginContainer>
      </>
    </Page>
  );
};

export default Page10;
