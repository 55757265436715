import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: 'auto',
      zIndex: 100,
      backgroundColor: '#fff',
      overflow: 'hidden',
      position: 'relative',
    },
    container: {
      width: '97%',
      margin: '1.5% auto',
      height: '97%',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '2vh'
      }
    },
    noMarginTop: {
      margin: '0% auto',
    },
    inActif: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 100,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    content: {
      width: '100%',
      height: '100%',
      zIndex: 50,
    },
    contentInacif: {
      filter: 'grayscale(0.90)',
    },
    modal: {
      width: '40%',
      height: 'auto',
      padding: '2vh 5vh',
      backgroundColor: '#fff',
      borderRadius: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '70%',
      },
    },
    modalTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      color: '#000000',
      textAlign: 'center',
      width: '80%',
      wordBreak: 'normal',
      marginBottom: '2vh',
      [theme.breakpoints.down('sm')]: {
        width: '95%',
      },
    },
    noMarginBottom: {
      marginBottom: '0px !important',
    },
    btnModal: {
      background: '#000A8C',
      borderRadius: '4px',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      padding: '3vh 4vh',
      textTransform: 'none',
      marginTop: 20,
      marginBottom: 10,
    },
    hidden: {
      display: 'none',
    },
    btnRedirect: {
      padding: '1.5vh 2.5vh',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      background: '#000A8C',
      borderRadius: '60px',
      width: '180px',
      left: '75%',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        left: '33%',
        color: '#fff',
      },
    },
    disable: {
      background: '#989898',
    },
    contentLoader: {
      width: '100%',
      height: '20vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    colorWhite: {
      color: 'white',
    },
    btnContent: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      zIndex: 10,
    },
    arrowForwardIcon: {
      fontSize: 21,
      marginLeft: 10,
    }
  })
);
