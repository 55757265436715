import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Modal } from '@orientaction/components';
import { TestContext } from '@orientaction/Context';
import { useAlert, useTest } from '@orientaction/hooks';
import { FC, useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import style from './style';

interface IProps {
  testTitleRef: any;
  testTimeRef: any;
  testImageRef: any;
  testCategoryRef: any;
  testVisibilityRef: any;
}

const TestSubmitSection: FC<IProps> = ({
  testTitleRef,
  testTimeRef,
  testImageRef,
  testCategoryRef,
  testVisibilityRef,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { test, resetTest, updateTest } = useContext<any>(TestContext);
  const { postTestWithQuestionsAndResponses, updateTestWithQuestionsAndResponses } = useTest();
  const { push } = useHistory();
  const location = useLocation();
  const { id }: any = useParams();
  const cls = style();

  const alert = useAlert();

  const save = async () => {
    updateTest({
      ...test,
      manageError: {
        title: !test?.test?.title || test?.test?.title?.trim()?.length === 0,
        time: !test?.test?.time || test?.test?.time === '',
        image: !test?.test?.image || test?.test?.image?.trim()?.length === 0,
        visibilityPermission:
          !test?.visibilityPermission || test?.visibilityPermission?.length === 0,
        enterpriseSelected:
          !test?.enterpriseSelected ||
          (Array.isArray(test?.enterpriseSelected) && test?.enterpriseSelected?.length === 0),
        category: test.category === '',
      },
    });

    try {
      if (!test?.test?.title || test?.test?.title?.trim()?.length === 0) {
        //  alert.setOpenAlert(true, 'Le champ nom est obligatoire', 'error');
        return testTitleRef?.current?.scrollIntoView();
      }
      if (!test?.test?.time || test?.test?.time === '') {
        //  alert.setOpenAlert(true, 'Le champ temps estimé est obligatoire', 'error');
        return testTimeRef?.current?.scrollIntoView();
      }

      if (!test?.test?.image || test?.test?.image?.trim()?.length === 0) {
        //  alert.setOpenAlert(true, "L'image est obligatoire", 'error');
        return testImageRef?.current?.scrollIntoView();
      }
      if (!test?.visibilityPermission || test?.visibilityPermission?.length === 0) {
        //  alert.setOpenAlert(true, 'Le champ droits de visibilité est obligatoire', 'error');
        return testVisibilityRef?.current?.scrollIntoView();
      }
      if (
        test?.visibilityPermission &&
        Array.isArray(test?.visibilityPermission) &&
        test?.visibilityPermission.some((vp: any) => vp?.value === 0 || vp?.value === 4) &&
        (!test?.enterpriseSelected ||
          (Array.isArray(test?.enterpriseSelected) && test?.enterpriseSelected?.length === 0))
      ) {
        return testVisibilityRef?.current?.scrollIntoView();
      }

      if (test.category === '') {
        //  alert.setOpenAlert(true, 'Le champ catégorie est obligatoire', 'error');
        return testCategoryRef?.current?.scrollIntoView();
      }

      resetTest();

      setLoading(true);

      if (location.pathname.includes('/edit')) {
        await updateTestWithQuestionsAndResponses({ ...test, id, isDraft: false });
      } else {
        await postTestWithQuestionsAndResponses({ ...test, isDraft: false });
      }

      setLoading(false);

      push('/admin/test');
    } catch (error) {
      alert.openSimpleAlert();
    }
  };

  const saveAsDraft = async () => {
    try {
      resetTest();
      setLoading(true);
      if (location.pathname.includes('/edit')) {
        await updateTestWithQuestionsAndResponses({ ...test, id, isDraft: true });
      } else {
        await postTestWithQuestionsAndResponses({ ...test, isDraft: true });
      }
      push('/admin/test');
    } catch (error) {
      console.log('Error on creating test', error);
    } finally {
      setLoading(false);
    }
  };

  const oneComfirm = () => {
    resetTest();
    push('/admin/test');
  };

  const handleCloseModal = () => {
    setModal(!modal);
  };

  return (
    <div className={cls.root}>
      <Box className={cls.btnPrimaryContainer}>
        <Button className={cls.btnDraft} onClick={saveAsDraft}>
          {loading ? '...' : 'Enregistrer en brouillon'}
        </Button>

        <Button data-cy="cancel-test-btn" className={cls.btnCanceled} onClick={handleCloseModal}>
          Annuler
        </Button>

        <Button className={cls.btnPrimary} onClick={save}>
          {loading ? '...' : 'Publier'}
        </Button>
      </Box>

      <Modal
        data-cy="cancel-test-create-modal"
        open={modal}
        onCancel={handleCloseModal}
        onConfirm={oneComfirm}
        textCancel="Non"
        title=""
        textValidate="Oui">
        <h3 className={cls.textH3}>Êtes-vous sur de vouloir annuler les modifications ?</h3>
      </Modal>
    </div>
  );
};

export default TestSubmitSection;
