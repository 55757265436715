/* eslint-disable no-use-before-define */
import { TextArea } from '@orientaction/commons';
import { Grid } from '@material-ui/core';
import useStyles from './style';
import { FC } from 'react';

interface IArray {
    listArray: any[];
    handleChangeArray: (groupBy: string) => (e: any) => void;
    current: any;
    readOnly?: boolean;
}

const Array: FC<IArray> = ({
    listArray,
    current,
    readOnly,
    handleChangeArray
}) => {

    const classes = useStyles();

    return (

        <Grid
            container={true}
            alignItems="stretch"
            direction="row"
        >
            {
                listArray.map((item: any) => (

                    <Grid item={true} md={item.md} xs={12}>

                        <div className={classes.tableContainer}>

                            <div className={classes.head}>
                                {item.title || ""}
                            </div>

                            {
                                item.children.map((children: any) => (
                                    <div className={classes.bodyContent}>
                                        <TextArea
                                            name={children.name || ''}
                                            value={current?.[item.name]?.[children.name] || ""}
                                            onChange={handleChangeArray(item.name)}
                                            notBorder={true}
                                            largeTextField={true}
                                            placeholder={children.placeholder || ""}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                ))
                            }

                        </div>

                    </Grid>

                ))
            }
        </Grid>
    );
};

export default Array;
