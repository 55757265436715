export const context = [
    {
        title: "LES OPPORTUNITÉS D’UNE ÉVOLUTION EN INTERNE",
        id: 1,
        listContext: [
            {
                title: "Conserver ses avantages acquis",
                content: `l’avantage principal d’une évolution interne
                est de conserver ses avantages acquis. En France, le critère d’ancienneté reste
                important. Il permet d’augmenter sa rémunération et parfois d’aménager son temps
                de travail. En quittant votre employeur, il est évident que vous perdrez une grande
                partie de ces avantages appréciables. Pas si facile de revenir à un temps plein
                quand on a obtenu un 80% par exemple…`
            },
            {
                title: "Un choix raisonnable quand le marché de l’emploi est en crise",
                content: `en période de chômage de masse et pour des profils d’attractivité moyenne, changer d’employeur
                a peu de chances de se traduire par une amélioration de votre situation matérielle.
                Cela ne signifie pas que vous serez moins heureux(se), mais juste que sur le plan
                matériel, vous repartirez un échelon en dessous et perdrez en sécurité. C’est
                pourquoi dans un contexte de crise, une évolution interne peut constituer un choix
                raisonnable, à condition que de réelles opportunités existent.`
            },
            {
                title: "Un tremplin pour votre réussite",
                content: `Si vous travaillez dans une organisation en développement ou que la population des managers dans votre organisation est vieillissante, il peut être avisé de privilégier une évolution interne. Vous n’êtes en effet pas certain(e) de retrouver en externe un environnement vous offrant autant d’opportunités. Il faut savoir rester dans un train en marche ! Dans ce cas, il vous faudra peut-être faire preuve d’un peu de patience, mais à moyen et long terme, vous serez à coup sûr récompensé(e).`
            },
        ]
    },
    {
        title: "LES RISQUES D’UNE ÉVOLUTION INTERNE",
        id: 2,
        listContext: [
            {
                title: "Parfois un parcours du combattant",
                content: `évoluer en interne peut parfois s’apparenter à
                un parcours du combattant, surtout si l’évolution souhaitée est un vrai changement
                de métier. Peu d’organisations sont ouvertes à confier à un(e) collaborateur(trice)
                performant(e) sur un poste, un autre poste dans lequel il (elle) sera novice, par
                exemple un(e) directeur(trice) commercial(e) voulant devenir directeur(trice)
                des ressources humaines. Il y a une perte de rentabilité à court terme pour votre
                employeur. Les grandes organisations y sont souvent très réticentes et les petites se
                l’interdisent.`
            },
            {
                title: "Un changement pas toujours satisfaisant",
                content: `beaucoup de personnes qui choisissent
                une évolution interne ne sont pas pleinement satisfaites de leur changement. Elles
                attendaient beaucoup de ce changement. Or très souvent, c’est la culture de
                l’organisation qui pose problème. C’est le cas par exemple des organisations très
                hiérarchisées ou très spécialisées ou basées sur la compétition et la confrontation
                plutôt que sur la coopération. D’un service à l’autre, vous aurez de fortes chances
                de retrouver le même environnement. Le changement n’aura alors servi à rien.`
            },
            {
                title: "Un profil qui perd de son attractivité",
                content: `plus on passe de temps dans la même
                organisation et plus il devient difficile d’en changer. Trois ans, c’est court, cinq ans
                c’est bien, dix ans et plus, vous serez considéré(e) par vos futurs recruteurs comme
                une personne peu mobile intellectuellement. Les recruteurs ont aujourd’hui très peur
                des personnes qui n’ont connu que le même contexte professionnel pendant une
                longue période. En effet, ces personnes ont souvent beaucoup moins de capacité
                à s’adapter à d’autres contextes.`
            },
        ]
    }
]