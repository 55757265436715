import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      margin: '0 auto',
      display: 'block',
      position: 'relative',
      [theme.breakpoints.down(800)]: {
        width: '90%',
      },
    },
    bodycenter: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnHistory: {
      position: 'absolute',
      top: '120px',
      right: 0,
      [theme.breakpoints.down(1440)]: {
        top: '120px',
      },
      [theme.breakpoints.down(800)]: {
        top: '80px',
      },
    },
    resultTitle: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '20px',
      },
    },
    grid: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
    },
    first: {
      width: '72.5%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
    second: {
      width: '25%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
  }),
);
