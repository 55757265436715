/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr } from '@orientaction/components';
import { context } from './context';

const Page42 = (props: any) => {

    return (
        <Page id={42}>

            {
                context.map((item: any) => (
                    <>
                        <TitleNbr number={item.id} text={item.title} />

                        <MarginContainer>
                            {
                                item.listContext.map((item2: any) => (
                                    <>
                                        <MarginContainer>
                                            <Paragrapth align='justify'>
                                                {item2}
                                            </Paragrapth>
                                        </MarginContainer>
                                    </>
                                ))
                            }
                        </MarginContainer>

                    </>
                ))
            }

            <div style={{
                margin: '6vh 0',
            }}>
                <MarginContainer>
                    <Paragrapth align='center' colorBlue={true} bold={true}>
                        À vous de choisir maintenant entre les 5 possibles !
                    </Paragrapth>
                </MarginContainer>
            </div>

        </Page>
    );
};

export default Page42;
