import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import { BurgerMenu, ProfileMenu, UserPhotos } from '@orientaction/commons';
import { Footer, TabLayout } from '@orientaction/components';
import { useAlert, useProfileMenu } from '@orientaction/hooks';
import { userService } from '@orientaction/services';
import { getFromLS, isAdmin, isRootUser, publicLogoPath } from '@orientaction/utils';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { fakeUser } from './constant';
import style from './publicLayoutStyle';

const Layout = ({
  children,
  isaddBackground,
  noPadding,
  classname,
  isNew,
  isNew2,
  showLink = true,
  footer,
}: any) => {
  const history = useHistory();
  const alert = useAlert();
  const cls = style();
  const user: any = fakeUser;
  const { open, anchorRef, handleToggle, handleClose, goToProfileEdit, logout } = useProfileMenu({
    user,
  });

  const switchRole = async () => {
    try {
      const isSecondRoleActivated = user?.company_user_role?.id === 4;
      const token = getFromLS('userToken') || '';
      const { data } = await userService.switchRole(token, !isSecondRoleActivated);
      localStorage.setItem('userInfos', JSON.stringify(data));
      const redirectPath = data?.company_user_role?.id === 5 ? '/main' : '/admin/users-resultats';
      history.push(redirectPath);
    } catch (error) {
      alert.openSimpleAlert();
    }
  };

  return (
    <div className={clsx(cls.layout, classname)}>
      <div
        className={clsx(
          cls.content,
          isaddBackground ? cls.grayBG : null,
          noPadding ? cls.noPadding : null,
          isNew ? cls.imageBG : null,
          isNew2 ? cls.imageBG2 : null
        )}>
        <AppBar position="sticky" color="transparent" elevation={0} className={cls.appBar}>
          <nav className={cls.navbar}>
            <div className={cls.flexContainer}>
              <div className={cls.logoContainer}>
                <picture className={cls.logo}>
                  <source srcSet={`${publicLogoPath}/e-orientaction.png`} />

                  <img
                    src={`${publicLogoPath}/e-orientaction.png`}
                    alt="logo-orientation"
                    className={cls.logoImg}
                  />
                </picture>

                {/* <img className={cls.logo} src={`${publicLogoPath}/logo-orientaction.png`} alt="logo" /> */}
              </div>

              <Hidden only={['xs', 'sm']}>
                <div style={{ marginLeft: 40 }}>
                  {showLink ? (
                    <TabLayout
                      service={user?.service}
                      isRoot={isRootUser(user)}
                      isAdmin={isAdmin(user)}
                    />
                  ) : (
                    <div style={{ padding: 40 }} />
                  )}
                </div>
              </Hidden>
            </div>
            <div className={cls.profilContainer}>
              <Hidden only={['xl', 'lg', 'md']}>
                <BurgerMenu
                  isAdmin={isAdmin(user)}
                  menu={['Résultats des tests', 'Gestion & Administration']}
                  goToProfileEdit={goToProfileEdit}
                  logout={logout}
                  service={user?.service}
                />
              </Hidden>
              <Hidden only={['xs', 'sm']}>
                <UserPhotos
                  anchorRef={anchorRef}
                  user={user}
                  handleToggle={handleToggle}
                  open={open}
                />
                <ProfileMenu
                  open={open}
                  anchorRef={anchorRef}
                  handleToggle={handleToggle}
                  handleClose={handleClose}
                  goToProfileEdit={goToProfileEdit}
                  logout={logout}
                  user={user}
                  switchRole={switchRole}
                />
              </Hidden>
            </div>
          </nav>
        </AppBar>

        <div className={cls.children}>
          {children}
          {footer && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default Layout;
