export const listPlanB = [
    {
        label: 'VOTRE PROJET',
        name: 'yourProject'
    },
    {
        label: 'EN QUOI CE PROJET RÉPOND-IL À VOS ASPIRATIONS ?',
        name: 'yourAspiration'
    },
    {
        label: 'QUELLES RESSOURCES POUVEZ-VOUS MOBILISER POUR RÉUSSIR ?',
        name: 'yourResource'
    }
];
