/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import { ContainerAdmin, MarginContainer } from '@orientaction/commons';
import { Layout } from '@orientaction/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Check } from '../../Asset/svg/check.svg';
import { apiServiceNative } from '../../utils/fetchNative';
import { useStyles } from './style';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { gameBookValues } from '@orientaction/reducers';

interface Item {
  id: number;
  name: string;
  description: string;
}

interface Props {
  data?: Item[];
}

const SelecteTemplate: React.FC<Props> = () => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [dataTemplate, setData] = useState<any>(null);
  const { id, position }: any = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleClick = (itemId: number) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const valuesBooksTemplate = useSelector(gameBookValues);

  const handleClickData = async (e: any) => {
    console.log('valuesBooksTemplate', valuesBooksTemplate);
    if (!selectedItemId) {
      console.log('No selected item ID provided, exiting function.');
      return;
    }

    try {
      const nameRoute = location.pathname;
      const formData = new FormData();
      let routing = `/admin/game/edit-page`;

      const apiRouting = sessionStorage.getItem('id')
        ? `/api/get-id-page?entityId=${sessionStorage.getItem('id')}`
        : '/api/get-id-page';

      let idPagesTemps = sessionStorage.getItem('idPagesTemps') as any;
      const randomId = await apiServiceNative.get(apiRouting);

      if (!idPagesTemps) {
        idPagesTemps = [];
      } else {
        idPagesTemps = JSON.parse(idPagesTemps);
      }

      idPagesTemps.push(randomId);

      sessionStorage.setItem('idPagesTemps', JSON.stringify(idPagesTemps));

      // dispatch(gameBookTemplateActions.updateField('tempImage', null));
      if (nameRoute.trim().includes('/admin/game/add-page')) {
        const pagesData = JSON.parse(sessionStorage.getItem('gamePages') || '[]');
        sessionStorage.setItem('indexNew', position);
        const newItem = {
          template_game: selectedItemId,
          id: randomId,
          p1: null,
          p2: null,
          title: null,
          citationContent1: null,
          citationAuthor1: null,
          citationContent2: null,
          citationAuthor2: null,
          informationEncyclopedia1: null,
          pEncyclopedia1: null,
          informationEncyclopedia2: null,
          pEncyclopedia2: null,
          contentForType1: 'normal',
          contentForType2: 'normal',
          iSmainPath: false,
          typeChoice: 'multiples',
          choiceDisplay: 'horizontal',
          choiceTitle: null,
          textEndGame: null,
          illustration: null,
          choice: [],
        };
        pagesData.splice(position, 0, newItem);
        formData.append('pagesList', JSON.stringify(pagesData));

        dispatch(gameBookTemplateActions.updateField('pages', pagesData));
        routing = `/admin/game/edit-page?novaya=${position}`;
      } else {
        formData.append('pagesList', `[{ "template_game": ${selectedItemId} }]`);
        dispatch(
          gameBookTemplateActions.updateField('pages', [
            {
              id: randomId,
              template_game: selectedItemId,
              p1: null,
              p2: null,
              title: null,
              citationContent1: null,
              citationAuthor1: null,
              citationContent2: null,
              citationAuthor2: null,
              informationEncyclopedia1: null,
              pEncyclopedia1: null,
              informationEncyclopedia2: null,
              pEncyclopedia2: null,
              contentForType1: 'normal',
              contentForType2: 'normal',
              iSmainPath: false,
              typeChoice: 'multiples',
              choiceDisplay: 'horizontal',
              choiceTitle: null,
              textEndGame: null,
              illustration: null,
              choice: [],
            },
          ]),
        );
      }
      history.push(routing);
      // sessionStorage.removeItem('gamePages');
      /* const response = await apiServiceNative.put(`/api/update-game/${id}`, formData, true);
      if (response && response.id) {
        sessionStorage.removeItem('gamePages');
        history.push(routing);
      } */
    } catch (error) {
      console.error("Erreur lors de l'appel API:", error);
    }
    e.preventDefault();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/template-games?populate=*&sort=id:asc`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const dataTemplateApi = await response.json();
        setData(dataTemplateApi?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  if (dataTemplate?.length === 0) {
    return <div>Aucune donnée disponible</div>;
  }

  const goBack = () => {
    const pagesData = JSON.parse(sessionStorage.getItem('gamePages') || '[]');

    if (!sessionStorage.getItem('isEdit')) {
      sessionStorage.setItem('goBack', 'true');
      history.goBack();
    } else {
      const indexNew = sessionStorage.getItem('lastIndex') || '0';
      dispatch(gameBookTemplateActions.updateField('pages', pagesData));
      sessionStorage.setItem('indexNew', indexNew);
      sessionStorage.removeItem('isEdit');
      history.push(`/admin/game/edit-page?novaya=${indexNew}`);
    }
  };

  return (
    <Layout isaddBackground={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <div style={{ position: 'relative' }}>
            <div className={classes.btnFloat} onClick={goBack}>
              <KeyboardArrowLeftIcon />
              <div style={{ marginTop: '3px' }}>Retour</div>
            </div>
            <h1 className={classes.titleMain}>Sélection d'un modèle </h1>
          </div>
          <Grid component="ul" container={true} spacing={2} className={classes.list}>
            {dataTemplate &&
              dataTemplate.map((item: any) => (
                <Grid
                  item={true}
                  xs={6}
                  sm={4}
                  lg={3}
                  component="li"
                  key={item.id}
                  style={{ position: 'relative' }}
                  onClick={() => handleClick(item.id)}
                >
                  <span
                    className={`${classes.containerImg} ${
                      item.id === selectedItemId ? classes.selectedItem : ''
                    }`}
                  >
                    <img
                      src={item?.attributes?.img?.data[0]?.attributes?.url}
                      alt={item?.attributes?.name}
                      width="100%"
                      style={{ display: 'block' }}
                    />
                  </span>
                  <h3 className={classes.titleItem}>{item?.attributes?.name}</h3>
                  {item.id === selectedItemId && (
                    <span className={classes.check}>
                      <Check />
                    </span>
                  )}
                </Grid>
              ))}
          </Grid>
        </MarginContainer>{' '}
        {dataTemplate && (
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedItemId}
            className={classes.btn}
            style={{ textTransform: 'none' }}
            onClick={(e: any) => handleClickData(e)}
          >
            {location.pathname.includes('/admin/game/add-page')
              ? 'Ajouter une page'
              : 'Commencer la création du jeu'}
          </Button>
        )}
      </ContainerAdmin>
    </Layout>
  );
};

export default SelecteTemplate;
