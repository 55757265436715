/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { ApiActions, apiState } from '@orientaction/api-actions';
import { ResultInterface } from '@orientaction/interfaces';
import dateFormat from 'dateformat';
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ResultServices } from '../services';
import { columns } from '../utils/constants';
import { getFromLS, transformResponse } from '../utils/utils';

const useResults = (company = 1) => {
  const token = getFromLS('userToken') || '';
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const resultsInStore: any = useSelector(apiState);
  const [resetTablePage, setResetTaplePage] = useState(false);
  const [activatedUserFilter, setActivatedUserFilter] = useState(true);
  const [companyLinkedUserFilter, setCompanyLinkedUserFilter] = useState(true);
  const [filterList, setfilterList] = useState<ResultInterface.IFilter[]>([]);
  const [exportData, setexportData] = useState<any[]>([]);
  const [resultToRemove, setResultToremove] = useState<ResultInterface.IResultFilter | null>(null);
  const [keyWord, setKeyWord] = useState<any>({
    value: null,
    error: '',
  });
  const [operator, setOperator] = useState<any>({
    value: null,
    error: '',
  });
  const [filterValue, setfilterValue] = useState<any>({
    value: null,
    error: '',
  });
  const [inputAutocomplete, setInputAutocomplete] = useState<string>('');
  const [searchFilter, setsearchFilter] = useState('');
  const [loading, setloading] = useState(false);
  const [currentPage, setcurrentPage] = useState(0);
  const [exportLoading, setexportLoading] = useState(false);
  const [deleteFilter, setdeleteFilter] = useState(false);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setcurrentPage(newPage);
  };

  const changeOperator = (value: string) => {
    setOperator({
      ...operator,
      value,
    });
  };

  const changeKeyword = (value: any) => {
    setKeyWord({
      ...keyWord,
      value,
    });
  };

  const changeFilterValue = (value: string) => {
    setfilterValue({
      ...filterValue,
      value,
    });
  };

  const changeInputAutocomplete = (value: string) => {
    setInputAutocomplete(value);
  };

  const updateRestPage = async () => {
    setResetTaplePage(true);
    setTimeout(() => {
      setResetTaplePage(false);
    }, 1000);
  };

  const addFilter = () => {
    const filters = [...filterList];
    if (keyWord.value && operator.value && filterValue.value) {
      setloading(true);
      filters.push({
        keyWord: keyWord.value,
        operator: operator.value,
        value: filterValue.value,
      });
      setfilterList(filters);
      setKeyWord({
        value: '',
        error: '',
      });
      setOperator({
        value: '',
        error: '',
      });
      setfilterValue({
        value: '',
        error: '',
      });
      changeInputAutocomplete('');
      updateRestPage();
    }
  };

  const handleSearchFilter = (value: string) => {
    setsearchFilter(value);
    if (value?.trim().length < 1) {
      setcurrentPage(0);
      setdeleteFilter(true);
    }
  };

  const removeFilter = (filter: ResultInterface.IFilter) => {
    const filters = filterList.filter((f: ResultInterface.IFilter) => f !== filter);
    setcurrentPage(0);
    setfilterList(filters);
  };

  const onConfirmRemoveResult = () => {
    if (resultToRemove) {
      setloading(true);
      const id: any = resultToRemove?.id || '';
      ResultServices.RemoveResult(token || '', id)
        .then(async (data: any) => {
          const res = resultsInStore.results.filter((i: any) => i.id !== data?.data?.id);
          dispatch(ApiActions.setResults(res || []));
        })
        .catch((err: any) => {
          console.error(err);
          setloading(false);
        })
        .finally(() => {
          setloading(false);
          setResultToremove(null);
        });
    }
  };

  useEffect(() => {
    if ((filterList.length < 1 || searchFilter?.trim().length < 1) && pathname !== '/user/tests') {
      setloading(true);
      const start = !isNaN((currentPage + 1) * 10 - 10) ? (currentPage + 1) * 10 - 10 : 0;
      ResultServices.fetchResults(
        company,
        token,
        start,
        filterList,
        searchFilter,
        false,
        activatedUserFilter,
        companyLinkedUserFilter
      )
        .then(d => {
          const transformedResults = transformResponse(d?.data);
          batch(() => {
            dispatch(ApiActions.setResultsCount(d?.count));
            dispatch(ApiActions.setResults(transformedResults));
          });
          setloading(false);
        })
        .catch(e => {
          dispatch(ApiActions.setResults(e || []));
          setloading(false);
        });
    }
  }, [currentPage, companyLinkedUserFilter, activatedUserFilter]);

  useEffect(() => {
    if (filterList.length > 0 || searchFilter?.trim().length > 0) {
      setloading(true);
      const start = !isNaN((currentPage + 1) * 10 - 10) ? (currentPage + 1) * 10 - 10 : 0;
      ResultServices.fetchResults(
        company,
        token,
        start,
        filterList,
        searchFilter,
        false,
        activatedUserFilter,
        companyLinkedUserFilter
      )
        .then(d => {
          const transformedResults = transformResponse(d?.data);
          batch(() => {
            dispatch(ApiActions.setResultsCount(d?.count));
            dispatch(ApiActions.setResults(transformedResults));
          });
          setloading(false);
        })
        .catch(e => {
          dispatch(ApiActions.setResults(e || []));
          setloading(false);
        });
    }
  }, [filterList, searchFilter]);

  useEffect(() => {
    if (deleteFilter) {
      setloading(true);
      const start = !isNaN((currentPage + 1) * 10 - 10) ? (currentPage + 1) * 10 - 10 : 0;
      ResultServices.fetchResults(
        company,
        token,
        start,
        filterList,
        searchFilter,
        false,
        activatedUserFilter,
        companyLinkedUserFilter
      )
        .then(d => {
          const transformedResults = transformResponse(d?.data);
          batch(() => {
            dispatch(ApiActions.setResultsCount(d?.count));
            dispatch(ApiActions.setResults(transformedResults));
          });
          setloading(false);
          setdeleteFilter(false);
        })
        .catch(e => {
          dispatch(ApiActions.setResults(e || []));
          setloading(false);
          setdeleteFilter(false);
        });
    }
  }, [deleteFilter]);

  const handleExport = async () => {
    setexportLoading(true);
    return ResultServices.fetchResults(
      company,
      token,
      0,
      filterList,
      searchFilter,
      true,
      activatedUserFilter,
      companyLinkedUserFilter,
      true
    )
      .then((res: any) => {
        const dataToExport = [columns.map((data: any) => data.label)];
        const resultsTransformed = transformResponse(res.data);
        // eslint-disable-next-line array-callback-return
        resultsTransformed.map((result: ResultInterface.IResultFilter) => {
          const {
            realization_date,
            opt_in,
            problem_solving,
            confidence,
            collective_sense,
            communication,
            creativity,
            critical_mind,
            curiousity,
            daring,
            emotional_intelligence,
            empathy,
            entrepreneurial_spirit,
            motivation,
            presence,
            realization_period,
            stress_management,
            time_management,
            time_spent,
            vision,
            bc,
            user,
            scoreTotal,
          } = result;
          const row = [
            dateFormat(new Date(realization_date), 'dd/mm/yyyy'),
            opt_in,
            bc,
            user?.firstname || '',
            user?.lastname || '',
            user?.gender || '',
            user?.age || '',
            user?.diploma || '',
            user?.email || '',
            user?.phone || '',
            user?.postal_code || '',
            problem_solving,
            confidence,
            emotional_intelligence,
            empathy,
            communication,
            time_management,
            stress_management,
            creativity,
            entrepreneurial_spirit,
            daring,
            motivation,
            vision,
            presence,
            collective_sense,
            curiousity,
            critical_mind,
            scoreTotal,
            time_spent,
            realization_period,
          ];
          dataToExport.push(row);
        });
        setexportData(dataToExport);
      })
      .catch(() => {
        setexportLoading(false);
      });
  };

  const getResultByUser = async (filter: string) => {
    const resultsData = await ResultServices.getResultByUser(token, filter, company);
    return resultsData.data?.list || [];
  };

  return {
    results: resultsInStore.results,
    keyWord,
    operator,
    filterValue,
    filterList,
    searchFilter,
    addFilter,
    removeFilter,
    changeOperator,
    changeKeyword,
    changeFilterValue,
    inputAutocomplete,
    changeInputAutocomplete,
    loading,
    tableLabel: 'Aucun résultat',
    handleSearchFilter,
    onConfirmRemoveResult,
    resultToRemove,
    setResultToremove,
    resetTablePage,
    count: resultsInStore.count,
    handleChangePage,
    currentPage: currentPage || 0,
    token,
    exportData,
    handleExport,
    exportLoading,
    getResultByUser,
    setfilterValue,
    setOperator,
    setKeyWord,
    setloading,
    activatedUserFilter,
    companyLinkedUserFilter,
    setActivatedUserFilter,
    setCompanyLinkedUserFilter,
  };
};

export default useResults;
