import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import AuthFormHeader from '../../Commons/AuthFormHeader';
import AuthHeader from '../../Commons/AuthHeader';
import locale from '../locale.json';
import { RegisterForm } from '../RegisterForm';

const LeftSide = () => {
  const [showAccountConfirm, setShowAccountConfirm] = useState<boolean>(false);
  const handleAccountConfirm = (show: boolean) => {
    setShowAccountConfirm(show);
  };
  const cls = style();
  return (
    <>
      <AuthHeader />
      <div className={cls.left}>
        <RegisterForm
          handleAccountConfirm={handleAccountConfirm}
          showAccountConfirm={showAccountConfirm}
        />
      </div>
    </>
  );
};
const style = makeStyles((theme: Theme) => ({
  left: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& .header': {
      display: 'none',
      '@media (min-width: 1250px)': {
        display: 'block',
      },
    },
    '@media (min-width: 1250px)': {
      // height: 'calc(92vh - 60px)',
      maxWidth: 600,
      margin: 'auto',
    },
  },
}));
export default LeftSide;
