import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scoreContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    scoreResultContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    scoreResultText: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
      color: theme.palette.primary.dark,
      textAlign: 'center',
    },
    scoreResultDescription: {
      marginTop: 16,
      borderRadius: 16,
      backgroundColor: theme.palette.primary.light,
      padding: 16,
      color: '#fff',
      fontFamily: 'Poppins',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  })
);

export default useStyles;
