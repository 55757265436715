export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * max) + min;
};
export const randomNumberExclusion = (min: number, max: number, exclusions: any) => {
  let randomNum;

  do {
    randomNum = Math.floor(Math.random() * max) + min;
  } while (exclusions.includes(randomNum));

  return randomNum;
};
