import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import clsx from 'clsx';
import { FC } from 'react';
import style from './style';

interface IContent {
  children: any;
  PageTitle?: string;
  title?: string;
  overrideTitleStyle?: any;
  overrideChildrenStyle?: any;
  to: string;
  dotStyleOverride?: any;
  timelineContentOverrideStyle?: any;
  horizontalLineOverrideStyle?: any;
  cardMarginBottomStyle?: any;
}

const Content: FC<IContent> = ({
  children,
  PageTitle,
  title,
  overrideTitleStyle = '',
  overrideChildrenStyle = '',
  dotStyleOverride = '',
  timelineContentOverrideStyle = '',
  horizontalLineOverrideStyle = '',
  cardMarginBottomStyle = '',
  to,
}) => {
  const classes = style();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div id={to}>
      {isMobile ? (
        <>
          <div className={classes.horizontalLine} />

          {PageTitle && <Typography className={classes.pageTitle}>{PageTitle}</Typography>}

          {title && (
            <Typography className={clsx(classes.title, overrideTitleStyle)}>{title}</Typography>
          )}

          <div className={classes.children}>{children}</div>
        </>
      ) : (
        <>
          <TimelineItem
            className={clsx(classes.dot, dotStyleOverride, timelineContentOverrideStyle)}>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent className={classes.content}>
              <div className={clsx(classes.horizontalLine, horizontalLineOverrideStyle)} />

              {PageTitle && <Typography className={classes.pageTitle}>{PageTitle}</Typography>}

              {title && (
                <Typography className={clsx(classes.title, overrideTitleStyle)}>{title}</Typography>
              )}

              <div className={clsx(classes.children, overrideChildrenStyle, cardMarginBottomStyle)}>
                {children}
              </div>
            </TimelineContent>
          </TimelineItem>
        </>
      )}
    </div>
  );
};

export default withWidth()(Content);
