import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useOfflineLanguage } from '@orientaction/hooks';
import { FC } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Link, useLocation } from 'react-router-dom';

interface Iprops {
  showLink?: boolean;
  questionText: string;
  redirectText: string;
  toText: string;
}

const AuthFormHeaderLink: FC<Iprops> = ({
  showLink = true,
  questionText,
  redirectText,
  toText,
}) => {
  const cls = style();
  const location = useLocation() as any;
  const { language, onLanguageChange } = useOfflineLanguage();

  return (
    <div
      className={
        location?.pathname !== '/' ? cls.authFormHeaderRegisterLink : cls.authFormHeaderLink
      }
    >
      <>
        <span className={cls.questionText}>
          {questionText}
          {location?.pathname !== '/' ? <>&nbsp;</> : null}
        </span>
        <Link className={cls.link} to={toText} data-cy="register-page">
          {' '}
          {redirectText}
        </Link>{' '}
        {location?.pathname !== '/' && (
          <ReactFlagsSelect
            countries={['FR', 'GB']}
            showOptionLabel={false}
            showSelectedLabel={false}
            fullWidth={false}
            selected={language}
            onSelect={onLanguageChange}
            className={cls.menuFlags}
          />
        )}
      </>
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  link: {
    color: '#000A8C',
    textDecoration: 'underline',
    font: 'normal normal bold 15px ITC Avant Garde Gothic Std Medium',
  },
  questionText: {
    font: 'normal normal bold 15px ITC Avant Garde Gothic Std Medium',
  },

  authFormHeaderLink: {
    margin: '35px auto 20px auto',
  },

  authFormHeaderRegisterLink: {
    margin: '35px 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  menuFlags: {
    marginLeft: 'auto',
  },
}));
export default AuthFormHeaderLink;
