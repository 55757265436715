/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import { Grid } from '@material-ui/core';
import useStyles from './style';
import { useBook5 } from '@orientaction/hooks_book';

const Page23 = (props: any) => {

    const classes = useStyles();

    const { setUncertainy, uncertainy } = useBook5();

    const handleChangeArray = (index: any) => (e: any) => {

        const { name, value } = e.target;

        setUncertainy({
            ...uncertainy,
            [index]: {
                ...uncertainy[index],
                [name]: value
            }
        });

    }

    return (
        <Page
            id={23}
            readOnly={false}
        >

            <TitlePage>
                LES PRINCIPALES ZONES D’INCERTITUDE
            </TitlePage>

            <MarginContainer big={true}>

                <Paragrapth align='justify'>
                    Notez dans ce tableau « les principales zones d’incertitude » liées à
                    la réalisation de votre projet. Les zones d’incertitude renvoient à ce que
                    vous ne maîtrisez pas et qui peut influer sur la réussite de votre projet.
                    Cela peut concerner la décision d’une autre personne (de votre conjoint(e),
                    de la commission de financement d’une banque, de votre employeur(se),
                    etc.) ou des éléments liés aux contextes social, économique et politique
                    (les prochaines embauches dans un secteur d’activité, la mise en place
                    d’une nouvelle réglementation, etc.).
                </Paragrapth>

            </MarginContainer>

            <Grid
                container={true}
                alignItems="stretch"
                direction="row"
            >

                <Grid item={true} md={6} xs={6}>

                    <div className={classes.tableContainer}>

                        <div className={classes.head}>
                            LES ZONES D’INCERTITUDE
                        </div>

                        {[0, 1, 2, 4, 5, 6].map((item: number) => (
                            <div className={classes.bodyContent}>
                                <TextArea
                                    name="areasUncertainy"
                                    value={uncertainy[item]?.areasUncertainy || ""}
                                    onChange={handleChangeArray(item)}
                                    notBorder={true}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        ))}

                    </div>

                </Grid>

                <Grid item={true} md={6} xs={6}>

                    <div className={classes.tableContainer}>

                        <div className={classes.head}>
                            CE QUE VOUS POUVEZ FAIRE POUR
                            RÉDUIRE CETTE INCERTITUDE
                        </div>

                        {[0, 1, 2, 4, 5, 6].map((item: number) => (
                            <div className={classes.bodyContent}>
                                <TextArea
                                    name="reduceAreasUncertainy"
                                    value={uncertainy[item]?.reduceAreasUncertainy || ""}
                                    onChange={handleChangeArray(item)}
                                    notBorder={true}
                                    readOnly={props.readOnly}
                                />
                            </div>
                        ))}

                    </div>

                </Grid>

            </Grid>
        </Page>
    );
};

export default Page23;
