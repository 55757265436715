import { Typography } from '@material-ui/core';
import style from '../../../commonStyle';

const CompetitorSuggestion = () => {
  const classes = style();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        😍 TA VOIE IDÉALE : CLASSE PRÉPA
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Si tu es une personne de style Compétiteur, je te recommande de faire une classe
          préparatoire aux grandes écoles (CPGE).
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Après une filière générale débouchant sur des résultats brillants au baccalauréat
              (plus de 14 de moyenne), tu intégreras une classe préparatoire aux grandes écoles
              (CPGE), si possible parmi les mieux classées.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Après deux ans (voire trois ans en cas d&apos;échec aux concours), tu intégreras une
              grande école de commerce ou d’ingénieur, la première étape d&apos;une carrière
              professionnelle prometteuse !
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionQuote} component="p">
          « Baisser les bras dans une compétition sous prétexte qu&apos;on ne peut terminer premier
          est incompatible avec l&apos;esprit du sport. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Eric Tabarly
      </Typography>
    </div>
  );
};

export default CompetitorSuggestion;
