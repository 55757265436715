export const qcm = [
  {
    key: '28-1-qcm',
    id: 2,
    title: 'Avez-vous pensé à demander un conseil ou un appui à :',
    responses: [
      {
        content: 'Votre député ou votre maire,',
        label: 'A',
      },
      {
        content:
          'Un(e) représentant(e) de l’État (préfet(e), directeur(trice) de cabinet d’un(e) ministre, etc.),',
        label: 'B',
      },
      {
        content:
          'Le(a) représentant(e) d’une organisation syndicale ou professionnelle (MEDEF, CGPME, CFDT etc.),',
        label: 'C',
      },
      {
        content: 'Le(a) représentant(e) d’une structure associative, publique ou parapublique,',
        label: 'D',
      },
      {
        content: 'Un(e) dirigeant(e) d’entreprise.',
        label: 'E',
      },
    ],
  },
  {
    key: '28-2-qcm',
    id: 3,
    title: 'Quelles actions pourriez-vous réaliser afin de développer votre capital social ?',
    responses: [
      {
        content: 'Prendre rendez-vous avec des professionnels pour une enquête-métier,',
        label: 'A',
      },
      {
        content: 'Participer à des afterworks,',
        label: 'B',
      },
      {
        content: 'Faire un stage dans une entreprise, une association ou une administration,',
        label: 'C',
      },
      {
        content: 'Vous investir dans une association,',
        label: 'D',
      },
      {
        content: 'Vous rendre à un salon professionnel,',
        label: 'E',
      },
      {
        content:
          'Parler de votre projet autour de vous, à vos ami(e)s, collègues ou connaissances.',
        label: 'F',
      },
    ],
  },
];

export const tableInputs = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
];

export const tableThreeInputs = [
  'A1',
  'A2',
  'A3',
];
