export const paragraphe = [
    `Les girafes ont un très long cou qui leur permet d’attraper les branches les plus hautes
    et de dévorer la myriade de petites feuilles appétissantes auxquelles n’a accès
    aucun autre animal. Les girafes sont heureuses dans la savane. Tout simplement
    parce que cet environnement est fait pour elles. Elles y sont parfaitement adaptées.
    Imaginez maintenant que vous transportiez une girafe au pôle Nord.`,
    `Vous l’imaginez debout sur la glace. Que pourrait-elle faire avec ses grandes
    pattes et ses sabots glissants ? Ne sachant pas pêcher, elle n’aurait rien à manger.
    Elle serait condamnée à gratter de ses grandes dents la fine couche de lichen qui
    pousse sur les rochers au printemps, se destinant à un irréparable torticolis.`,
    `L’être humain est semblable à une girafe. Nous aussi, nous avons nos longs cous.
    Ce sont nos talents et nos aptitudes naturelles. Et comme des girafes qui doivent
    vivre dans la savane, nous devons exercer une fonction qui est adaptée à nous et
    dans un environnement qui est adapté à nous.`,
    `Une femme ou un homme de relations (un(e) extraverti(e)) souffrira à mourir
    si elle(il) doit traiter toute la journée des dossiers sur un ordinateur sans pouvoir
    communiquer.`,
    `Une femme ou un homme de dossiers (un(e) introverti(e)) souffrira à mourir si elle(il)
    doit animer toute la journée des réunions…`
]