import { StudyTestService } from '@orientaction/services';
import { getFromLS } from '@orientaction/utils';
import { useLocation } from 'react-router-dom';

const useStudyTestResult = () => {
  const location = useLocation();
  const token = getFromLS('userToken') || '';

  const getMyResult = async (id: number) => {
    const isPublic = location.pathname.startsWith('/mon-resultat-du-test-study');
    if (isPublic) {
      return StudyTestService.getPublicResultStudy(id);
    }
    return StudyTestService.getMyResultStudy(token, id);
  };

  const shareTestPublicly = async (id: number) => {
    return StudyTestService.makeStudyResultPublic(id, token);
  };
  return {
    getMyResult,
    shareTestPublicly,
  };
};

export default useStudyTestResult;
