import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // '&.MuiListItem-gutters': {
    //   paddingLeft: '12px !important',
    //   paddingRight: '12px !important',
    // },
  },
  flex: {
    display: 'flex',
  },
  colorBlue: {
    color: '#0E1247',
    margin: '0'
  }
}));
