/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { MarginContainer } from '@orientaction/commons';
import Explication from './Explication';
import { useStyles } from './style';
import Card from './Card';
import { FC } from 'react';

interface IContent {
  listPrincipaux: any[];
}

const Content: FC<IContent> = ({ listPrincipaux }) => {
  const classe = useStyles();

  return (
    <div className={classe.root}>
      <MarginContainer mormal={true}>
        <h1 className={classe.titlePage}>Découvrez vos 3 valeurs principales</h1>
      </MarginContainer>

      <MarginContainer>
        <div className={classe.grid}>
          {(listPrincipaux || []).map((item: any, index: number) => {
            return <Card item={item} />;
          })}

          <Explication />
        </div>
      </MarginContainer>
    </div>
  );
};

export default Content;
