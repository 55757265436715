/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page } from '@orientaction/components';
import useStyles from './style';

const Page01 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={1}>
      <div className={classes.textCenterTop}>
        <Typography variant="h6" component="h5">
          EMERIC LEBRETON
        </Typography>

        <Typography className={classes.title} color="textSecondary" gutterBottom={true}>
          DOCTEUR EN PSYCHOLOGIE
        </Typography>
      </div>

      <div className="header__right">
        <div className="header__content bg--gradient">
          <div className="header__content-text">
            <h1 className="header__content-title">
              <span className="header__content-wrap">
                <span className="header__content-value">
                  <span className="decoration decoration--blue">
                    <img
                      src="/images/Book1/quote-blue.png"
                      alt="Quote blue"
                      className="quote-blue"
                    />
                  </span>
                  <span className="number">5</span>
                </span>

                <span className="header__content-desc">
                  <span className="title">Étapes pour réussir</span>
                  <span className="subtitle">Votre évolution professionnelle </span>
                </span>

                <span className="decoration decoration--yellow">
                  <img
                    src="/images/Book1/quote-yellow.png"
                    alt="Quote yellow"
                    className="quote-yellow"
                  />
                </span>
              </span>
            </h1>
          </div>
        </div>
      </div>

      <div className={classes.textCenterTop} style={{ marginTop: '20px', marginBottom: '20px' }}>
        <div className={classes.trait00} />
        <br />
        <Typography variant="h1" component="h1" style={{ fontSize: '45px', color: '#2b56a5' }}>
          <strong>étape 3</strong>
        </Typography>
        <br />
        <br />
        <Typography
          variant="h3"
          component="h3"
          style={{ fontSize: '30px', color: '#2b56a5' }}
          gutterBottom={true}
          className="opacity07">
          EXPLORER TOUS LES POSSIBLES
        </Typography>
        <br />
        <div className={classes.trait00} />
      </div>
      <div style={{ marginBottom: '100px', marginTop: '100px' }}>
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-justify-content-xs-center">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7">
            <div className="book__footer--image bg--gradient--other mb-5">
              <img
                className="jss150"
                src="/images/Book1/logo_oa_dark_blue_no_text_under.png"
                alt="logo"
              />
            </div>
            <p className="text-center text-blue">LEADER DU BILAN DE COMPÉTENCES</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Page01;
