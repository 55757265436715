export const defaultData = [
    {
        title: 'VOTRE OBJECTIF PROFESSIONNEL',
        id: 1,
        label: 'Votre objectif professionnel',
        name: 'YourObjectifPersonnel'
    },
    {
        title: 'LES RÉSULTATS QUE VOUS AVEZ OBTENUS',
        id: 2,
        children: [
            {
                label: 'Résultat 1',
                name: 'A1'
            },
            {
                label: 'Résultat 2',
                name: 'A2'
            },
            {
                label: 'Résultat 3',
                name: 'A3'
            }
        ]
    },
    {
        title: 'LES DIFFICULTÉS QUE VOUS AVEZ RENCONTRÉES',
        id: 3,
        children: [
            {
                label: 'Difficulté 1',
                name: 'B1'
            },
            {
                label: 'Difficulté 2',
                name: 'B2'
            },
            {
                label: 'Difficulté 3',
                name: 'B3'
            }
        ]
    },
    {
        title: 'LES QUALITÉS QUE CETTE EXPÉRIENCE VOUS A PERMIS DE DÉVELOPPER',
        id: 4,
        children: [
            {
                label: 'Talent 1',
                name: 'C1'
            },
            {
                label: 'Talent 2',
                name: 'C2'
            },
            {
                label: 'Talent 3',
                name: 'C3'
            }
        ]
    }
]