const INITIAL_STATE: any = {
  listResult: false,
  count: 0,
};

const stateResultOfTest = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'LISTOFRESULT': {
      return {
        ...state,
        listResult: action.payload,
      };
    }
    case 'COUNT': {
      return {
        ...state,
        count: action.payload,
      };
    }
    default:
      return state;
  }
};

export default stateResultOfTest;
