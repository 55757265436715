/* eslint-disable no-use-before-define */

import { getFromLS, isNumeric } from '@orientaction/utils';
import { useHistory } from 'react-router-dom';
import { HomePageUser, HomePageUser2 } from '../../components';

const Results = (props: any) => {
  const history = useHistory();
  const user = JSON.parse(getFromLS('userInfos') || '{}');

  if (user && (isNumeric(user?.company_user_role?.id) || isNumeric(user?.role?.id))) {
    if (user?.company_user_role?.id !== 5) {
      history.push('/admin/users-resultats');
      return null;
    }
  } else {
    history.push('/admin/users-resultats');
    return null;
  }
  return <HomePageUser2 />;
};

export default Results;
