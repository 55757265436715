import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AuthLayoutCustom } from '@orientaction/components';
import Left from './Left/Left';
import Right from './Right/Right';

const Register = () => {
  const cls = style();
  return <AuthLayoutCustom left={<Left />} right={<Right />} />;
};

const style = makeStyles((theme: Theme) => ({
  layout: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'row-reverse',
  },
  left: {
    position: 'relative',
    flex: 1,
  },
  right: {
    position: 'relative',
    flex: 1,
  },
}));

export default Register;
