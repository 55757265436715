import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Book } from '../../../Asset/svg/icon-book.svg';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 2, 8),
    textAlign: 'center',
    maxWidth: 698,
    width: '100%',
    borderRadius: '20px',
  },
  icon: {
    display: 'inline-flex',
    width: '90px',
    height: '90px',
    marginBottom: theme.spacing(6),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title2: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '40px',
    color: '#000A8C',
    fontStyle: 'italic',
    marginBottom: '50px',
  },
  title5: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    color: '#000A8C',
    marginBottom: '20px',
  },
  outline: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 600,
    color: '#000A8C',
    textTransform: 'inherit',
    backgroundColor: 'white',
    minWidth: '160px',
    height: '44px',
    border: '1px solid #000A8C',
    padding: '16px 10px 12px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  contain: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 600,
    color: 'white',
    textTransform: 'inherit',
    backgroundColor: '#000A8C',
    minWidth: '160px',
    height: '44px',
    border: '1px solid #000A8C',
    padding: '16px 10px 12px',
    marginLeft: '10px',
    marginRight: '10px',
    '&:hover': {
      color: '#000A8C',
    },
  },
}));

const ModalEditGame = ({
  open,
  handleClose,
  title,
  message,
  onCancel,
  onConfirm,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Icon className={classes.icon}>
            <Book />
          </Icon>
          <Typography variant="h6" id="transition-modal-title" className={classes.title5}>
            {message}
          </Typography>
          <Typography variant="h5" color="primary" className={classes.title2}>
            {title}
          </Typography>
          <div className={classes.buttons}>
            <Button onClick={onCancel} className={classes.outline}>
              Annuler
            </Button>
            <Button color="primary" onClick={onConfirm} className={classes.contain}>
              {message === "Êtes-vous sûr de vouloir quitter l'édition du jeu ?"
                ? 'Oui '
                : 'Continuer'}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalEditGame;
