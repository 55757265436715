import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px',
      overflow: 'hidden',
    },
    titlehead: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    },
    cardStyle: {
      borderRadius: 10,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    titleText: {
      fontSize: 17,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: 'Poppins',
      fontWeight: 600,
    },
    titleContent: {
      fontSize: 17,
      lineHeight: '25px',
    },
    cardFooter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: '1px solid #80808033',
    },
    cardContent: {
      margin: '47px 0px 10px 0px',
      padding: '0px 25px 0px !important',
      position: 'relative',
    },
    textFLoating: {
      fontSize: '11px',
      marginTop: '20px',
      marginLeft: '3px',
    },
    positionCircular: {
      position: 'absolute',
      top: '-110px',
      right: '30px',
      background: 'white',
      borderRadius: '50%',
    },
    limitText: {
      '@media only screen and (max-width: 600px)': {
        '-webkit-line-clamp': 5,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
      },
    },
    noLimit: {
      '@media only screen and (max-width: 600px)': {
        display: 'block',
      },
    },
  })
);
