/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { Page, TitlePage, Paragrapth } from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';
import { useBook5 } from '@orientaction/hooks_book';
import BorderArray from '../../components/BorderArray';
import { listForm } from './context';

const Page12 = (props: any) => {

  const classes = useStyles();

  const { setProjectProffessionalAndAspiration, projectProffessionalAndAspiration } = useBook5();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setProjectProffessionalAndAspiration({
      ...projectProffessionalAndAspiration,
      [name]: value,
    });
  };

  return (
    <Page id={12} readOnly={false}>
      <TitlePage>
        VOTRE PROJET PROFESSIONNEL <br />
        ET VOS ASPIRATIONS
      </TitlePage>

      <MarginContainer>
        <Paragrapth align="justify">
          Décrivez votre projet d’évolution professionnelle. Prenez soin à chaque fois d’expliquer
          précisément en quoi il répond à vos aspirations sur ces trois dimensions : le sens, les
          besoins et la personnalité. Référez-vous aux résultats des tests que vous avez passés aux
          étapes précédentes, notamment le test de personnalité et le test des valeurs en essayant
          au maximum de reprendre le même vocabulaire.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Cette fiche servira à présenter votre projet d’évolution professionnelle à un(e)
          recruteur(se) ou à un jury de sélection.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer big={true}>
        <BorderArray
          handleChange={handleChange}
          name="yourProject"
          defaultValue={projectProffessionalAndAspiration.yourProject || ''}
          title="VOTRE PROJET"
          paragraph="Exemple : créer une entreprise de service à la personne ou évoluer vers un poste de manager(euse) des ventes."
          largeTextField={true}
          readOnly={props.readOnly}
        />
      </MarginContainer>

      <MarginContainer big={true}>
        <BorderArray
          handleChange={handleChange}
          defaultValue={projectProffessionalAndAspiration || ''}
          listForm={listForm}
          readOnly={props.readOnly}
        />
      </MarginContainer>
    </Page>
  );
};

export default Page12;
