// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { Paragrapth, Page, TitleUpCase, ListParagrapth } from '@orientaction/components';
import useStyles from '../../style';

const Page40 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={39}>
      <div id="google">
        <TitleUpCase>BIENVEILLANCE</TitleUpCase>

        <Paragrapth align="justify">
          Le principe directeur de votre vie :{' '}
          <Paragrapth align="start" colorBlue={true}>
            {' '}
            <strong>Faire le bien autour de vous !</strong>{' '}
          </Paragrapth>
        </Paragrapth>

        <br />
        <br />

        <Paragrapth align="justify">
          Vous êtes animé(e) par le désir d’améliorer le sort et le bien-être des personnes avec
          lesquelles vous vivez.
          <br />
          <br />
          Il peut s’agir de vos proches et/ou de personnes plus éloignées. Vous aspirez à l’harmonie
          sociale. Vous voulez que tout le monde soit bien et heureux. Sur le plan professionnel,
          vous avez besoin d’évoluer dans un environnement et avec des personnes qui partagent cette
          ambition. Indulgence, prévenance, solidarité sont les ingrédients indispensables à votre
          épanouissement personnel.
        </Paragrapth>

        <br />
        <br />

        <Paragrapth align="justify" colorBlue={true}>
          {' '}
          <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
        </Paragrapth>

        <ListParagrapth
          list={[
            'Travailler dans une ONG, une grande association venant en aide aux personnes ou dans les secteurs social et médico-social,',
            'Vous former aux métiers de la santé pour exercer en tant que professionnel(le) de santé indépendant(e) ou salarié(e),',
            'Devenir coach, consultant(e) ou psychologue, des métiers dont la vocation est de venir en aide aux autres en activité principale ou complémentaire.',
          ]}
        />

        <br />
        <br />
        <br />

        <TitleUpCase>UNIVERSALITÉ</TitleUpCase>

        <Paragrapth align="justify">
          Le principe directeur de votre vie :{' '}
          <Paragrapth align="start" colorBlue={true}>
            Changer le monde !
          </Paragrapth>
        </Paragrapth>
        <br />
        <br />
        <Paragrapth align="justify">
          Vous voulez changer le monde. Vous êtes un(e) idéaliste, un(e) utopiste, un(e)
          visionnaire. Votre vision des choses, vous avez envie de la faire partager, d’apporter un
          réel progrès à la société dans laquelle vous vivez.
          <br />
          <br />
          Les valeurs, c’est ce qui compte le plus pour vous avant toutes autres considérations
          matérielles. Vous n’êtes pas vraiment intéressé(e) par l’argent ou le confort, ce qui vous
          intéresse c’est de voir les choses évoluer dans le sens voulu.
          <br />
          <br />
          Sur le plan professionnel, vous avez besoin d’un vrai projet auquel adhérer dans votre
          entreprise ou votre organisation.
        </Paragrapth>
        <br />
        <br />
        <Paragrapth align="justify" colorBlue={true}>
          {' '}
          <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
        </Paragrapth>

        <ListParagrapth
          list={[
            'S’engager comme militant(e) d’un parti politique, bénévole dans une association ou élu(e) d’un syndicat ou d’une organisation professionnelle,',
            'Créer une start-up ou une entreprise qui développe des services, des technologies ou commercialise des produits écoresponsables,',
            'Trouver un emploi dans une ONG ou une association qui défend vos valeurs.',
          ]}    
        />
      </div>
    </Page>
  );
};

export default Page40;
