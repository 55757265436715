import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleRounded } from '@material-ui/icons';
import { FC } from 'react';

interface IProps {
  title: string;
  subtitle?: string;
  additionalText?: string;
}

const AccountConfirm: FC<IProps> = ({ title, subtitle, additionalText }) => {
  const cls = style();
  return (
    <div className={cls.container}>
      <CheckCircleRounded fontSize="large" color="secondary" />
      <h3 className={cls.title} data-cy="confirmation-account">
        {title}
      </h3>
      <h3 className={cls.paragraph}>{subtitle}</h3>
      <h3 className={cls.paragraph}>{additionalText}</h3>
    </div>
  );
};
const style = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '@media (min-width: 1250px)': {
      marginTop: '25%',
    },
  },
  title: {
    margin: 'auto',
    color: '#000A8C',
    textAlign: 'center',
    font: 'normal normal bold 36px/48px ITC Avant Garde Gothic Std Bold',
    letterSpacing: 0,
    opacity: 1,
  },
  paragraph: {
    height: 25,
    font: 'normal normal bold 20px ITC Avant Garde Gothic Std Medium',
    color: '#0E1247',
  },
}));
export default AccountConfirm;
