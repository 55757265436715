import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardBgGrey: {
            width: '100%',
            background: '#e8eef4',
        },
        img: {
            display: 'block',
            width: '62%',
            [theme.breakpoints.down(1200)]: {
                width: '40%',
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center',
                alignItem: 'center'
            },
            [theme.breakpoints.down('sm')]: {
                width: '40%',
            }
        },
        text: {
            width: '90%',
            margin: '3vh auto',
            [theme.breakpoints.down('sm')]: {
                width: '95%',
                margin: '1vh auto',
            },
        },
        title: {
            width: '70%',
            marginBottom: '4vh',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
                left: 0,
            },
        },
        content: {
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            width: '85%',
            margin: '0 auto',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
                display: 'block',
            },
        },
        simpleContent: {
            width: '85%',
            margin: '0 auto',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
            },
        },
        contentIt: {
            display: 'block',
            width: '50%',
            [theme.breakpoints.down(1200)]: {
                width: '100%',
                display: 'block',
            },
        },
        yellowBackground: {
            width: '100%',
            margin: 0,
            textAlign: 'center',
            color: 'black',
            fontStyle: 'italic',
            fontWeight: 'normal',
            backgroundColor: '#fff350',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            fontSize: '15px',
            display: 'inline-block',
            style: 'normal',
        }
    })
);

export default useStyles;