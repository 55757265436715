import { FC } from "react";
import useStyle from './style';
import { publicBookPath } from '../../../utils/constants';
import { Grid } from "@material-ui/core";

interface ITitleChapitre {
    nbr: number;
}

const TitleChapitre: FC<ITitleChapitre> = ({ nbr }) => {

    const classe = useStyle();

    return (
        <Grid
            container={true}
            direction="row"
            alignItems="center"
            className={classe.backGround}
            // style={{
            //     backgroundImage: `url(${publicBookPath}/ChapitreNbr/bgChapitre.png)`
            // }}
        >
            <Grid
                item={true}
            >
                <img
                    src={`${publicBookPath}/ChapitreNbr/Doit.png`}
                    alt='Chapitre title'
                />
            </Grid>

            <Grid
                item={true}
            >
                <div
                    className={classe.textChapitre}
                >
                    CHAPITRE  {nbr}
                </div>
            </Grid>
        </Grid>
    );
};

export default TitleChapitre;
