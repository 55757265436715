import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: '0',
      width: '48%',
      height: '330px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      backgroundColor: '#fff',
      borderRadius: '14px',
      marginBottom: '3%',
      transition: '500',
      [theme.breakpoints.between(900, 1250)]: {
        height: '500px',
      },
      [theme.breakpoints.down(900)]: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        margin: '1vh auto',
        padding: '10px',
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '26px',
      [theme.breakpoints.down(900)]: {
        fontSize: '26px',
      },
    },
    img: {
      borderRadius: '12.75px 0 0 12.75px',
      width: '233px',
      minHeight: '330px',
      transition: '500',
      [theme.breakpoints.between(900, 1250)]: {
        height: '500px',
      },
      [theme.breakpoints.down(900)]: {
        borderRadius: '0',
        width: '277px',
        Height: '390.87px',
      },
    },
    yourPrincipe: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#0E1247',
      lineHeight: '150%',
      [theme.breakpoints.down(900)]: {
        fontSize: '14px',
      },
    },
    contentResult: {
      padding: '2vh',
      [theme.breakpoints.down(900)]: {
        padding: '1vh',
      },
    },
    btn: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      width: '124px',
      height: '48px',
      background: '#000A8C',
      borderRadius: '60px',
      color: '#fff',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      [theme.breakpoints.down(900)]: {
        width: '48px',
        height: '48px',
        borderRadius: '60px',
      },
    },
    iconBtn: {
      width: '20px',
      position: 'relative',
      top: '3px',
    },
  }),
);
