/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-use-before-define */
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '1vh 0',
    float: 'left',
    display: 'flex',
    borderRadius: '50px',
    overflow: 'hidden',
  },
  tabs: {
    textTransform: 'none',
    fontFamily: ['Poppins'].join(','),
    padding: '1vh 2.5vh',
    cursor: 'pointer',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&:focus': {
      color: 'white',
    },
  },
  tabsActive: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: 'white',
    borderRadius: '50px',
  },
  both: {
    clear: 'both',
  },
  content: {
    backgroundColor: 'white',
  },
}));

interface IStyleTab {
  setTabs: (data: any) => void;
  tabs: number;
}

const StyleTab: FC<IStyleTab> = ({ setTabs, tabs }) => {
  const history = useHistory();
  const classes = useStyles();

  const listTabs = [
    {
      id: 0,
      label: 'Tests actifs',
    },
    {
      id: 1,
      label: 'Désactivés',
    },
    {
      id: 2,
      label: 'Archivés',
    },
  ];

  const handleChange = (nbr: number) => (e: any) => {
    setTabs(nbr);
    history.push(`/admin/test?tab=${nbr}`);
  };

  return (
    <>
      <div className={classes.root}>
        {listTabs.map((item: any) => (
          <div
            className={clsx({
              [classes.content]: true,
            })}>
            <div
              className={clsx({
                [classes.tabs]: true,
                [classes.tabsActive]: tabs === item.id,
              })}
              onClick={handleChange(item.id)}>
              {item.label || ''}
            </div>
          </div>
        ))}
      </div>

      <br className={classes.both} />
    </>
  );
};

export default StyleTab;
