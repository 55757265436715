import Grid from '@material-ui/core/Grid';
import { useContext } from 'react';
import { TestContext } from '../../Context/TestContext';
import { EgalityVariableCreationForm } from '..';
import VariableCardItem from '../VariableCardItem';
import VariableEmpty from '../VariableEmpty';
import style from './style';

const text = "En cas d'egalité parfaite";

const EgalitySection = () => {
  const { test } = useContext<any>(TestContext);
  const cls = style();
  return (
    <>
      <h1 className={cls.subtitle}>{text}</h1>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6} style={{ marginTop: 10 }}>
          {test.egalityVariable?.name?.length === 0 ? (
            <VariableEmpty />
          ) : (
            <VariableCardItem variable={test.egalityVariable} />
          )}
        </Grid>
        <Grid item={true} xs={6} md={6}>
          <EgalityVariableCreationForm />
        </Grid>
      </Grid>
    </>
  );
};

export default EgalitySection;
