/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Button, { ButtonProps } from '@material-ui/core/Button';
import { FC } from 'react';
import clsx from 'clsx';
import useStyles from './style';

interface IBtnPrimary {
  props: ButtonProps;
  disabled?: any;
  startIcon?: boolean;
  children: any;
  onClick: (e: any) => void;
  isPrimary?: boolean;
}

const BtnPrimary: FC<any> = ({
  props,
  children,
  startIcon,
  isPrimary,
  disabled = false,
  onClick,
}: IBtnPrimary) => {
  const classe = useStyles();

  return (
    <>
      <Button
        disabled={disabled}
        className={clsx({
          [classe.btnNormal]: true,
          [classe.btnPrimary]: isPrimary,
        })}
        style={{
          color: isPrimary ? '#fff' : '#0E1247',
        }}
        startIcon={startIcon}
        onClick={onClick}
        {...props}>
        {children}
      </Button>
    </>
  );
};

export default BtnPrimary;
