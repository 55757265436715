/* eslint-disable implicit-arrow-linebreak */
import { RootState } from '../store';
import { AppState, AppAction } from '../types/app.d';

import { getData } from './index';

const INITIAL_STATE: AppState = {
  loading: false,
  error: null,
  filter: null,
};

const projectsReducer = (
  state = INITIAL_STATE,
  action: AppAction,
): AppState => {
  switch (action.type) {
    case 'LOADING_START':
      return {
        ...state,
        loading: true,
      };
    case 'LOADING_END':
      return {
        ...state,
        loading: false,
      };
    case 'SET_FILTER':
      return {
        ...state,
        filter: action.filter,
      };
    case 'REMOVE_FILTER':
      return {
        ...state,
        filter: null,
      };
    default:
      return state;
  }
};

// ------------------------------------------------------------------//
// --------------------------- Selectors ----------------------------//
// ------------------------------------------------------------------//
export const getError = (state: RootState, errorIfNotFound = false) =>
  getData(state, 'app.error', errorIfNotFound && 'No error in app');
export const getMessage = (state: RootState, errorIfNotFound = false) =>
  getData(state, 'app.message', errorIfNotFound && 'No message in app');
export const getLoading = (state: RootState, errorIfNotFound = false) =>
  getData(
    state,
    'app.loading',
    errorIfNotFound && 'No action with loader in app',
  );
export const getTitle = (state: RootState, errorIfNotFound = false) =>
  getData(state, 'app.title', errorIfNotFound && 'No title in app');
export const getMessageVariant = (state: RootState, errorIfNotFound = false) =>
  getData(state, 'app.variant', errorIfNotFound && 'No message in app');
export default projectsReducer;
