// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { publicSvgPath } from '../../utils/constants';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal'
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal'
  }
});

const CardVariable = () => {
  const classes = useStyles();

  return (
    <Card variant="outlined" style={{padding: 15, borderRadius: '10px'}}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div>
            <Grid container={true} style={{marginBottom: '10px'}}>
              <Grid item={true} xs={4}>
                <Typography component="p" className={classes.texteTitle}>
                  Nom de la variable
                </Typography>
              </Grid>
              <Grid item={true} xs={8}>
                <Typography component="p" className={classes.h7}>
                  Nom de la variable
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" component="p" className={classes.textCard}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Aliquet risus feugiat in ante metus
              dictum at tempor commodo.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default CardVariable;
