/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core';
import { ApiActions } from '@orientaction/api-actions';
import { MarginContainer } from '@orientaction/commons';
import { Table } from '@orientaction/components';
import { ResponseUser, Variable } from '@orientaction/services';
import dateFormat from 'dateformat';
import { cloneDeep } from 'lodash';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';
import { columnsWithVariableStatique } from '../../utils/constants';
import { getFromLS, isMajorTest } from '../../utils/utils';
import useStyles from './style';

interface IProps {
  testId: any;
  company: any;
}

const MinorTestResults: FC<IProps> = ({ testId, company }) => {
  // local states
  const [loading, setLoading] = useState(false);
  const [loadResponse, setLoadResponse] = useState(false);
  const [resetTablePage, setResetTablePage] = useState(false);

  // constants
  const token = getFromLS('userToken') || '';

  // hooks
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar({
    style: {
      backgroundColor: '#f44336',
    },
  });
  const classes = useStyles();

  // redux states
  const testFilters: any = useSelector((state: any) => state?.api?.testFilters);
  const testSelected: any = useSelector((state: any) => state?.api?.testSelected);
  const userStatusFilters = useSelector((state: any) => state?.api?.userTestFilters);
  const refetch = useSelector((state: any) => state?.api?.refetch);
  const currentMinorTestResults = useSelector((state: any) => state?.api?.currentMinorTestResults);
  const currentMinorTestResultsMetadata = useSelector(
    (state: any) => state?.api?.currentMinorTestResultsMetadata,
  );
  const currentMinorTestResultsColumns = useSelector(
    (state: any) => state?.api?.currentMinorTestResultsColumns,
  );
  const currentMinorTestResultsPage = useSelector(
    (state: any) => state?.api?.currentMinorTestResultsPage,
  );

  const isTestMajor = useMemo(() => isMajorTest(testSelected.id), [testId]);

  /**
   * To be moved in a separate file
   * Render minor test table logic
   *
   */
  // List of response users with filter and pagination
  const renderListResultat = async (page = 1) => {
    const { data } = await ResponseUser.getMinorTestResults(
      token,
      testSelected.id,
      testFilters,
      page,
      !!userStatusFilters.isRattached,
      !!userStatusFilters.isActive,
      company,
    );
    const responseUserMetaData = data;

    const variablesByTest: any = await Variable.getVariableFilterByTest(token, testSelected.id);
    const variablesByTestData: any = variablesByTest?.data?.data;

    const arrayVariableToChange: any = variablesByTestData?.map((variable: any) => {
      return {
        id: variable.id,
        label: variable.attributes.name,
        columnName: variable.attributes.name,
        key: `variable_${variable.id}`,
        type: 'number',
        entity: 'variables',
      };
    });

    const headArrayClone: any = cloneDeep(columnsWithVariableStatique);

    const tableContent: any = (responseUserMetaData.data || []).map((responseUser: any) => {
      const dataGroupByVariableKey: any = {};
      responseUser?.attributes?.responses?.data.forEach((resp: any) => {
        resp.attributes.variable.data.forEach((varData: any) => {
          if (!dataGroupByVariableKey[varData.id]) {
            dataGroupByVariableKey[varData.id] = [];
          }
          dataGroupByVariableKey[varData.id].push(resp);
        });
      });

      const line: any = [
        { value: `${dateFormat(new Date(responseUser.attributes.createdAt), 'dd/mm/yyyy')}` },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.firstname || '',
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.lastname || '',
        },
        { value: responseUser.attributes.users_permissions_users.data[0].attributes.gender || '' },
        { value: responseUser.attributes.users_permissions_users.data[0].attributes.age || '' },
        { value: responseUser.attributes.users_permissions_users.data[0].attributes.diploma || '' },
        { value: responseUser.attributes.users_permissions_users.data[0].attributes.email || '' },
        { value: responseUser.attributes.users_permissions_users.data[0].attributes.phone || '' },
        {
          value:
            responseUser.attributes.users_permissions_users.data[0].attributes.postal_code || '',
        },
      ];

      const responses: any = (variablesByTestData || []).map((variable: any) => {
        const variableResponses: any = dataGroupByVariableKey[variable.id] || [];
        const variableValueSum: any = variableResponses.reduce((acc: any, curr: any) => {
          return acc + curr.attributes.variableValue;
        }, 0);
        return {
          value: variableValueSum > 0 ? variableValueSum : 0,
          label: variable.attributes.name,
        };
      });

      return line.concat(responses);
    });

    return {
      tableContent,
      columnsHandle: headArrayClone.concat(arrayVariableToChange),
      paginationPage: responseUserMetaData.meta.pagination,
    };

    /*   const variablesByTest = await Variable.getVariableFilterByTest(token, testSelected.id);
    const variablesByTestData = variablesByTest?.data?.data;

    console.log('responseUserMetaData', responseUserMetaData);
    console.log('variablesByTestData', variablesByTestData);

    const arrayVariableToChange = variablesByTestData?.map((variable: any) => {
      return {
        id: variable.id,
        label: variable.attributes.name,
        columnName: variable.attributes.name,
        key: `variable_${variable.id}`,
        type: 'number',
        entity: 'variables',
      };
    });

    const headArrayClone = cloneDeep(columnsWithVariableStatique);

    const tableContent = (responseUserMetaData.data || []).map((responseUser: any) => {
      const dataGroupByVariableKey = groupBy(
        responseUser?.attributes?.responses?.data,
        (resp: any) => {
          return resp?.attributes?.variable?.data?.id;
        },
      );
      const line = [
        {
          value: `${dateFormat(new Date(responseUser.attributes.createdAt), 'dd/mm/yyyy')}`,
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.firstname || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.lastname || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.gender || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.age || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.diploma || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.email || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value: responseUser.attributes.users_permissions_users.data[0].attributes.phone || '',
          // onClickAction: () => setResultToremove(result),
        },
        {
          value:
            responseUser.attributes.users_permissions_users.data[0].attributes.postal_code || '',
          // onClickAction: () => setResultToremove(result),
        },
      ];

      const responses = (variablesByTestData || []).map((variable: any) => {
        return {
          value: sumBy(
            dataGroupByVariableKey[variable.id],
            (res: any) => res.attributes.variableValue,
          ),
          label: variable?.attributes?.name,
        };
      });
      return line.concat(responses);
    });
    return {
      tableContent,
      columnsHandle: headArrayClone.concat(arrayVariableToChange),
      paginationPage: responseUserMetaData.meta.pagination,
    };
    */
  };
  const fetchMinorTestByFilter = async (page: number) => {
    setLoading(true);
    renderListResultat(page)
      .then(({ columnsHandle: cH, tableContent: tC, paginationPage: pP }) => {
        dispatch(ApiActions.updateCurrentMinorTestResults(tC));
        dispatch(ApiActions.updateCurrentMinorTestResultsMetadata(pP));
        dispatch(ApiActions.updateCurrentMinorTestResultsColumn(cH));
      })
      .catch(() =>
        openSnackbar(
          'Le filtre que vous avez sélectionné ne fonctionne pas avec les caractères que vous avez entrés.',
        ),
      )
      .finally(() => {
        setLoading(false);
        dispatch(ApiActions.setRefetch(false));
      });
  };

  // Handle change used to render useEffect [currentPageTestCustom] with the allFilter when we call it, line 907
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(ApiActions.setCurrentMinorTestResultsPage(newPage));
    dispatch(ApiActions.setRefetch(true));
  };

  // Effects
  useEffect(() => {
    if (!isTestMajor && refetch) {
      fetchMinorTestByFilter(currentMinorTestResultsPage);
    }
  }, [refetch, testFilters, currentMinorTestResultsPage]);

  return (
    <div>
      <div className={classes.resultTable}>
        {loading ? (
          <div className={classes.bodycenter}>
            <MarginContainer>
              <CircularProgress color="inherit" />
            </MarginContainer>
          </div>
        ) : (
          <Table
            rowsPerPage={10}
            thead={currentMinorTestResultsColumns}
            bodyData={currentMinorTestResults as any}
            load={loadResponse}
            resetPage={resetTablePage}
            count={currentMinorTestResultsMetadata?.total}
            handleChangePage={handleChangePage}
            currentPage={currentMinorTestResultsPage}
            pagination={true}
          />
        )}
      </div>
      {/* <Modal
        open={!!resultToRemove}
        onCancel={() => setResultToremove(null)}
        onConfirm={onConfirmRemoveResult}
        textCancel="Annuler"
        textValidate="Supprimer">
        <div className={classes.modalContent}>
          <div className={classes.deleteIconContainer}>
            <DeleteIcon className={classes.deleteIcon} style={{ color: '#E74242' }} />
          </div>
          <h2 className={classes.modalTitle}>Suppression</h2>
          <div className={classes.modalText}>Êtes-vous sûr de vouloir supprimer ce résultat ?</div>
        </div>
      </Modal> */}
    </div>
  );
};

export default MinorTestResults;
