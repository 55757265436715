/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, ParagrapthListNumber, TitlePage } from '@orientaction/components';
import useStyles from './style';
import { list } from './context';
import Item from './item';
import Img from '../../components/Img';

const Page21 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={20}>

            <TitlePage showSpanYellow={false}>
                J’Y VAIS OU JE N’Y VAIS PAS<br />
                - QUELQUES EXEMPLES -
            </TitlePage>

            {
                list.map((item: any) => <>
                    <MarginContainer>

                        <Item
                            label1={item.label1}
                            label2={item.label2}
                        />

                        {
                            (item.list || []).map((itemList: any) => (
                                <MarginContainer>
                                    <ParagrapthListNumber
                                        textBlue={true}
                                        list={[
                                            {
                                                text: itemList.label,
                                                number: itemList.id
                                            },
                                        ]}
                                    />
                                </MarginContainer>
                            ))
                        }

                    </MarginContainer>

                </>)
            }

            <MarginContainer big={true}>

                <Img
                    src='couple.png'
                    alt=' RELEVEZ LE DIP<br /> (DÉFI IMPOSSIBLE OU POSSIBLE)'
                    className={classes.img}
                />

            </MarginContainer>

        </Page>
    );
};

export default Page21;
