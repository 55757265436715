/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const load = (): AppThunk => dispatch => {
  dispatch({
    type: 'LOAD_API',
  });
};

export const loadOut = (): AppThunk => dispatch => {
  dispatch({
    type: 'LOADOUT_API',
  });
};

export const loadingAdmin =
  (data: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'LOAD_ADMIN',
      payload: data,
    });
  };

export const pending = (): AppThunk => dispatch => {
  dispatch({
    type: 'PENDING_API',
  });
};

export const pendingOut = (): AppThunk => dispatch => {
  dispatch({
    type: 'PENDING_END_API',
  });
};

// RESULTAT SERVICE
export const setResults =
  (results: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_RESULTS',
      payload: { results },
    });
  };

export const setResultsCount =
  (count: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_RESULTS_COUNT',
      payload: { count },
    });
  };

// TEST SERVICE
export const setTests =
  (tests: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_TESTS',
      payload: { tests },
    });
  };

export const setArchivedTests =
  (archivedTests: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_ARCHIVED_TESTS',
      payload: { archivedTests },
    });
  };

export const removeArchivedTest =
  (archivedTest: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'REMOVE_ARCHIVED_TEST',
      payload: archivedTest,
    });
  };

export const setDisabledTests =
  (disabledTests: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_DISABLED_TESTS',
      payload: { disabledTests },
    });
  };

export const removeDisabledTest =
  (disabledTest: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'REMOVE_DISABLED_TEST',
      payload: disabledTest,
    });
  };

export const postTest =
  (test: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'APPEND_TESTS',
      payload: { test },
    });
  };

export const putTest =
  (test: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUT_TEST',
      payload: { test },
    });
  };
export const setTest =
  (test: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_TEST',
      payload: { test },
    });
  };
export const setProgress =
  (progress: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_PROGRESS',
      payload: { progress },
    });
  };
export const setResponses =
  (responses: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_RESPONSES',
      payload: { responses },
    });
  };
export const setTestsUser =
  (testsUser: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_TESTS_USER',
      payload: { testsUser },
    });
  };
export const setoneResponseUser =
  (oneResponseUser: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_ONE_RESPONSE_USER',
      payload: { oneResponseUser },
    });
  };
export const setCompanies =
  (companies: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_COMPANIES',
      payload: { companies },
    });
  };
export const setCompany =
  (company: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_COMPANY',
      payload: { company },
    });
  };

export const setCompanyUserRole =
  (listUserRole: any[]): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_COMPANY_USER_ROLE',
      payload: listUserRole,
    });
  };

export const setTabLayout =
  (listUserRole: any[]): AppThunk =>
  dispatch => {
    dispatch({
      type: 'TABLAYOUT',
      payload: listUserRole,
    });
  };

export const setMeaningValueTestResults =
  (meaningValueTestResults: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_MEANING_VALUE_TEST_RESULTS',
      payload: { meaningValueTestResults },
    });
  };

export const setNeedsTestResults =
  (needsTestResults: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_NEEDS_TEST_RESULTS',
      payload: { needsTestResults },
    });
  };

export const setNeedsProTestResults =
  (needsProTestResults: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_NEEDS_PRO_TEST_RESULTS',
      payload: { needsProTestResults },
    });
  };

export const setPreferencePersonalityTestResults =
  (preferencePersonalityTestResults: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_PREFERENCE_PERSONALITY_TEST_RESULTS',
      payload: { preferencePersonalityTestResults },
    });
  };

export const setTestFilters =
  (testFilter: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_TEST_FILTERS',
      payload: { testFilter },
    });
  };

export const removeTestFilters =
  (testFilter: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'REMOVE_TEST_FILTERS',
      payload: { testFilter },
    });
  };

export const setTestSelected =
  (testSelected: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'INITIALIZE_TEST_FILTERS',
    });
    dispatch({
      type: 'SET_TEST_SELECTED',
      payload: { testSelected },
    });
  };

export const updateUserActiveTestFilters = (): AppThunk => dispatch => {
  dispatch({
    type: 'UPDATE_USER_ACTIVE_TEST_FILTERS',
    payload: {},
  });
};

export const updateUserRattachedTestFilters = (): AppThunk => dispatch => {
  dispatch({
    type: 'UPDATE_USER_RATTACHED_TEST_FILTERS',
    payload: {},
  });
};

export const setRefetch =
  (refetch: any): AppThunk =>
  dispatch =>
    dispatch({
      type: 'SET_REFETCH',
      payload: { refetch },
    });

export const initializeTestFilters = (): AppThunk => dispatch => {
  dispatch({
    type: 'INITIALIZE_TEST_FILTERS',
  });
};

export const updateUsersTableSearchKeyword =
  (searchKeyword: string): AppThunk =>
  dispatch =>
    dispatch({
      type: 'UPDATE_USERS_TABLE_SEARCH_KEYWORD',
      payload: { searchKeyword },
    });

export const initializeTableSearchKeyword = (): AppThunk => dispatch => {
  dispatch({
    type: 'INITIALIZE_TABLE_SEARCH_KEYWORD',
  });
};

export const initializeTableUserStatusFilters = (): AppThunk => dispatch => {
  dispatch({
    type: 'INITIALIZE_USER_STATUS_FILTERS',
  });
};

export const updateCurrentMinorTestResults =
  (currentMinorTestResults: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PUSH_CURRENT_MINOR_TEST_RESULTS',
      payload: { currentMinorTestResults },
    });
  };

export const updateCurrentMinorTestResultsMetadata =
  (currentMinorTestResultsMetadata: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'UPDATE_MINOR_TEST_RESULTS_METADATA',
      payload: { currentMinorTestResultsMetadata },
    });
  };

export const updateCurrentMinorTestResultsColumn =
  (currentMinorTestResultsColumns: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'UPDATE_MINOR_TEST_RESULTS_COLUMNS',
      payload: { currentMinorTestResultsColumns },
    });
  };

export const setCurrentMinorTestResultsPage =
  (currentMinorTestResultsPage: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SET_CURRENT_MINOR_TEST_RESULTS_PAGE',
      payload: { currentMinorTestResultsPage },
    });
  };
