import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const url = config.api_url;

export const updateCategoryTest = async (data: any, token: string) => {
    return makeRequest(`${url}/api/update-category-test`, 'POST', data, getHeaders(token));
};

export const getCategoryTest = async (token: string, data: any) => {
    return makeRequest(`${url}/api/get-category`, 'POST', data, getHeaders(token));
};

export const getManageCategory = async (token: string, data: any) => {
    return makeRequest(`${url}/api/manage-category-test`, 'POST', data, getHeaders(token));
};

export const filterCategoryTest = async (token: string, data: any) => {
    return makeRequest(`${url}/api/filter-category-test`, 'POST', data, getHeaders(token));
};

