/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import start from '@orientaction/Asset/Favorite/start.png';
import { ContentFlex } from '@orientaction/components';
import { publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';

interface ICardTest {
  image?: string;
  description: string;
  title: string;
  time: string;
  isFavorite: boolean;
}

const CardTest: FC<ICardTest> = ({ image, title, description, time, isFavorite }) => {
  const classes = useStyles();

  return (
    <>

      <ContentFlex
        children1={
          <img
            className={classes.img}
            src={image && image !== '' ? image : `${publicSvgPath}/QR.png`}
            //   src={`${publicSvgPath}/QR.png`}
            alt="logo"
          />
        }
        children2={
          <>
            <div className={classes.content}>

              {isFavorite && <img src={start} className={classes.start} alt="Favorite" />}

              <Typography
                variant="h6"
                component="h6"
                color="primary"
                className={classes.fontTitleCard}
              >
                {title}
              </Typography>

              {
                time && (
                  <div
                    className={classes.chipTime}
                  >
                    <div>
                      <AccessTimeIcon className={classes.iconeTime} />
                    </div>
                    <div className={classes.textTime}>
                      {time} min
                    </div>
                  </div>
                )
              }

            </div>
          </>
        }
      />
    </>
  );
};

export default CardTest;
