export const context = [
    {
        title: "LES OPPORTUNITÉS D’UN CHANGEMENT D’EMPLOYEUR",
        id: 1,
        listContext: [
            {
                title: "Retrouver un contexte de travail positif",
                content: `la plupart des personnes qui désirent
                changer d’employeur le font à cause d’une mauvaise ambiance de travail. Cette
                mauvaise ambiance peut avoir différentes causes. <br/>
                En changeant d’employeur, vous pouvez retrouver une ambiance différente, plus
                positive et donc plus propice à votre épanouissement personnel. Parfois, tout n’est
                qu’une question de personnes, d’époque… En changeant ces facteurs simples,
                vous pourrez retrouver le chemin du bonheur et de la performance.`
            },
            {
                title: "Se redonner des perspectives professionnelles",
                content: `quitter une organisation dans
                laquelle on n’évolue plus pour une nouvelle organisation qui offre de meilleures
                perspectives est une manière intelligente de piloter sa carrière professionnelle.
                Lorsqu’un enfant grandit, il doit changer d’habits pour adapter ses habits à sa taille.
                Il en est de même pour un(e) collaborateur(trice) qui progresse. <br/>
                Celui-ci (celle-ci) acquiert de nouvelles compétences. Sa progression le (la) fait
                « grandir professionnellement ». Si son employeur ne grandit pas avec lui (elle), il
                (elle) se sentira à l’étroit.`
            },
            {
                title: "Une meilleure rémunération",
                content: `changer d’employeur peut s’accompagner d’un
                véritable « saut de rémunération ». Si vous quittez une organisation en difficulté
                économique pour une organisation florissante, il y a fort à parier que vous puissiez
                améliorer très significativement votre rémunération. Vous pouvez également passer
                à un statut supérieur comme agent de maîtrise, cadre, cadre supérieur ou cadre
                dirigeant. Il suffit parfois de se faire embaucher dans une organisation (entreprise,
                association, administration) de plus grande taille. `
            },
        ]
    },
    {
        title: "LES RISQUES D’UN CHANGEMENT D’EMPLOYEUR",
        id: 2,
        listContext: [
            {
                title: "Attention à la période d’essai",
                content: `trop peu de salariés(es) le savent, mais s’ils(elles)
                mettent fin à la période d’essai de leur propre initiative, ils(elles) n’auront pas le
                droit aux allocations chômage. Il faut donc être vigilant(e) sur le choix du poste et le projet de collaboration avec le futur employeur. Mieux vaut prendre son temps et
                ne pas agir dans la précipitation pour tenir compte de cette réalité au risque de se
                retrouver sans ressources. Il faudra donc être particulièrement vigilant(e) durant la
                phase de recherche d’emploi et d’intégration chez le nouvel employeur.`
            },
            {
                title: "L’erreur de jugement",
                content: `il peut arriver, si l’employeur n’a pas donné une image fidèle
                du poste ou si le(a) candidat(e) n’a pas exprimé clairement ses aspirations ou ses
                compétences, qu’un recrutement soit un échec. <br/>
                Dans ce cas, le problème est que le profil du (de la) candidat(e) peut s’être détérioré.
                Les recruteurs n’apprécient pas de voir sur un CV des expériences de seulement
                quelques mois. Ils peuvent considérer comme un « caprice » ou comme un « signe
                d’instabilité » une expérience qui déboucherait sur un échec pendant la période
                d’essai.`
            },
            {
                title: "Le syndrome du mercenaire",
                content: `certain(e)s salarié(e)s se comportent comme de
                vrais(es) mercenaires. Ils(elles) changent d’employeur en fonction du plus offrant.
                Ce type de stratégie n’est pas forcément la meilleure car rapidement ce profil va
                être identifié par les recruteurs.<br/>
                Le minimum acceptable semble être de rester au moins trois ans chez le même
employeur tout en activant en parallèle les leviers de la mobilité interne. Parfois, il
peut être préférable de rester chez le même employeur plutôt que de changer pour
prendre de nouvelles responsabilités ou améliorer sa rémunération.`
            },
        ]
    }
]