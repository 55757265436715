// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../components/Image';
import useStyles from './style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page30 = () => {
  const classes = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_tronc.png' : 'tronc.png';
  }, [language]);

  return (
    <Page id={30}>
      <TitleChapitre nbr={3} />

      <TitlePage>
        LES « SOFT SKILLS »
        <br />
        (LE TRONC)
      </TitlePage>
      <MarginContainer>
        <Paragrapth align="justify">
          Les « soft skills » sont le produit d’un lent et long travail de confrontation de
          l’individu avec son environnement. Elles constituent des outils indispensables pour
          s’adapter.
        </Paragrapth>
        <Paragrapth align="justify">
          Les « soft skills » sont le produit des échecs et des réussites.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          De la même façon qu’un arbre révèle dans les nœuds de son tronc les traces des aléas
          climatiques (orages, tempêtes, hivers rigoureux ou étés caniculaires, etc.) qu’il a dû
          affronter, les « soft skills » révèlent les épreuves qu’un individu a dû traverser et les
          défis qu’il a dû relever. Elles sont le produit d’une histoire.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Img filename={image} alt="tronc" className={classes.imgTronc} />
      </MarginContainer>
    </Page>
  );
};

export default Page30;
