import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '80vh',
    border: '1px solid #aaa',
    overflowY: 'hidden',
    overflowX: 'scroll',
    borderRadius: '1vh',
  },
  floatLeft: {
    float: 'left',
    borderLeft: '1px solid #aaa',
    height: '100%',
  },
  coulomContent: {
    minWidth: '25vh',
    height: '100%',
  },
  header: {
    width: '100%',
    height: '72.5px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    color: 'white',
    textTransform: 'uppercase',
  },
  underHeader: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  underHeaderContent: {
    minWidth: '11vh',
    textAlign: 'center',
    fontSize: '12px',
  },
  underContent: {
    width: '49%',
    height: '70vh',
    borderLeft: '1px solid #aaa',
    float: 'left',
  },
  content: {
    width: '100%',
    height: '45.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '15px',
    color: '#0E1247',
  },
});
