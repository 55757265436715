import MinorTestLayout from '@orientaction/commons/Layouts/beneficiary/MinorTestLayout';
import MinorTestTaking from '@orientaction/components/Beneficiary/MinorTest';

const MinorTest = () => {
  return (
    <MinorTestLayout>
      <MinorTestTaking />
    </MinorTestLayout>
  );
};

export default MinorTest;
