/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2, publicBookPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page13 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={13}>
      <div style={{ marginBottom: 80 }}>
        {/* <img className={classes.imgTailleur} src={`${imgBook2}/titre19.png`} alt="logo" /> */}
        <SimpleBanner>
          <Typography
            component="h1"
            className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
          >
            LES 6 BESOINS DE ROBINSON
          </Typography>
        </SimpleBanner>
      </div>
      <Grid container={true} justifyContent="center">
        <Grid xs={5}>
          <img className={classes.img} src={`${imgBook2}/illustration_page_19.png`} alt="logo" />
        </Grid>
      </Grid>
      <div className={classes.cardBgGrey}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Lorsque Robinson se réveilla sur la plage d’une île perdue au milieu de l’océan, la
          première chose qu’il fit fut de rechercher à boire et à manger. Il devait satisfaire ses{' '}
          <strong>besoins physiologiques. </strong> Quand il eut étanché sa soif et apaisé sa faim,
          il construisit une cabane et l’entoura d’un enclos pour se protéger. Il put ainsi
          satisfaire son <strong> besoin de sécurité </strong>.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Quand ce fut fait, il partit à la recherche d’autres êtres humains comme lui. Il devait
          satisfaire son <strong>besoin d’appartenance</strong>. L’île était déserte, alors il
          inventa un personnage imaginaire du nom de Vendredi à qui il racontait ses journées. Comme
          il s’ennuyait, il décida de gravir la montagne qui s’élevait au centre de l’île.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Après trois jours d’une marche éprouvante, il atteignit le sommet. En relevant ce défi, il
          put satisfaire son <strong> besoin d’accomplissement </strong>. Quelques semaines plus
          tard, ayant survécu à toutes les épreuves et à tous les dangers, il prit conscience qu’il
          était devenu quelqu’un de spécial : un survivant. Il put ainsi satisfaire son{' '}
          <strong>besoin de réalisation de soi.</strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Il coupa alors un grand arbre duquel il ôta l’écorce. Il le planta dans le sol et y grava
          le nom de ROBINSON. S’il devait mourir sur cette île, on saurait qu’un jour une personne
          du nom de Robinson y avait échoué et survécu. Il put alors satisfaire son{' '}
          <strong>besoin d’éternité</strong>. Par bonheur, quatre ans après son naufrage, il fut
          sauvé et ramené chez lui.
        </Typography>
      </div>
      <br />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page13;
