/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page29 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={29}>
      <div >
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className="opacity07">
              LES GRANDES DÉCISIONS QUE VOUS
              <br />
              DEVREZ PRENDRE
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      <Grid container={true} spacing={1} justifyContent="center">
        <Grid item={true} xs={12}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
            }}>
            Tout changement commence par une décision. Toute décision a des conséquences. Toute
            personne est responsable des conséquences de ses choix.
          </Typography>
        </Grid>
      </Grid>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={1} text="CHOISIR, C’EST RENONCER" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Choisir une nouvelle orientation professionnelle, c’est renoncer à toutes les autres
              possibilités qui vous sont offertes. Vous pourriez faire mille choses de votre vie,
              mais parmi ces mille choses, vous allez devoir en choisir une seule, car la vie est
              trop courte pour tout faire et tout être.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Seules des personnalités hors du commun peuvent vivre plusieurs vies en une. Choisir, cela signifie
              donc renoncer. Ouvrir une porte, c’est en fermer plusieurs, c’est refuser la tentation
              de certaines carrières pour une autre carrière.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <TitleNbr number={2} text="LE DROIT À L’ÉGOÏSME" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Très souvent, les choix que nous faisons pour notre propre bonheur sont incompatibles
              ou contraires au bonheur des autres ; nos enfants, notre conjoint(e), nos collègues,
              notre manager(euse), etc. La première question à laquelle vous devrez répondre est
              celle-ci : « Qu’est-ce qui compte le plus pour moi, mon bonheur ou le bonheur des
              autres, mon bien-être ou le bien-être des autres? Qu’est-ce qui est le plus important?»
              Voici des questions concrètes posées par des personnes que nous avons accompagnées :
            </Typography>
          </Grid>
        </Grid>
        <div className={clsx(classes.cardBgGrey, classes.bgGreyMargin)}>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Hélène déteste son travail. Mais ce travail lui apporte une très bonne rémunération.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Doit-elle rester dans ce travail qui la fait souffrir, mais qui la rémunère bien,
                pour financer de longues et coûteuses études à ses enfants ? Doit-elle au contraire
                changer de travail, au risque d’avoir un poste moins bien rémunéré et de ne pas
                pouvoir offrir ces études rêvées à ses enfants?
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={clsx(classes.cardBgGrey, classes.bgGreyMargin)}>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Richard souhaite progresser dans sa carrière. Cette progression implique une
                mobilité géographique. Doit-il demander à sa conjointe et à ses enfants de déménager
                pour venir habiter à Paris et assouvir ses ambitions ? Doit-il renoncer au contraire
                à cette ambition et leur permettre de conserver leur qualité de vie à Bordeaux?
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={clsx(classes.cardBgGrey, classes.bgGreyMargin)}>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Céline rêve de créer son entreprise, une marque de vêtements. Elle sait que cette
                création va lui demander un investissement et une énergie considérables. Doit-elle
                reporter une maternité programmée avec son conjoint ? Doit-elle repousser à plus
                tard la réalisation de son rêve de création d’entreprise?
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 10,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 10,
      },
    },
    testimony: {
      fontSize: '17px',
    },
    bgGreyMargin: {
      marginTop: '50px',
    },
  })
);
export default Page29;
