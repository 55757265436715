/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Slide,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import { forwardRef, ReactElement, Ref, useEffect, useRef, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../Asset/svg/icon-close-red.svg';
import DraggableListChoice from './draggable-choice';

const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: 0,
    height: '100vh',
    width: '100%',
    maxWidth: '600px',
    maxHeight: 'calc(100% - 40px)',
    borderRadius: '20px 0 0 20px',
    padding: '40px 32px 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    marginBottom: '30px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E4E4F2',
    '& > div': {
      padding: '0',
    },
    '& h2': {
      fontFamily: 'ITC Avant Garde Gothic Std Demi',
      fontSize: '30px',
      lineHeight: '36px',
      fontWeight: 700,
      color: '#000A8C',
    },
  },
  cross: {
    padding: '0',
  },
  dialogContent: {
    padding: '0 10px 100px 0',
  },
  sectiontitle: {
    paddingBottom: '10px',
    borderBottom: '1px solid #E4E4F2',
    marginBottom: theme.spacing(3),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '#fff',
    padding: '15px 15px 0',
    borderRadius: '5px',
    border: '1px solid #E4E4F2',
    '& label': {
      width: '50%',
      margin: '0 0 15px',
      '& .MuiRadio-colorSecondary': {
        padding: '0',
        marginRight: '10px',
      },
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        lineHeight: '19px',
        fontWeight: 600,
        color: '#000A8C',
      },
      '& svg': {
        width: '18px',
        height: '18px',
        '& path': {
          fill: '#C2C2C2',
        },
      },
      '& .Mui-checked': {
        '& svg': {
          '& path': {
            fill: '#000A8C',
          },
        },
      },
    },
  },
  continueButton: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    left: '0',
    bottom: '0',
    width: 'calc(100% - 64px)',
    padding: '10px 32px 30px',
    background: '#fff',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 600,
    color: '#272727',
    textTransform: 'uppercase',
    margin: '0 0 13px',
  },
  checkbox: {
    margin: '0',
    '& .MuiCheckbox-colorSecondary': {
      marginRight: '10px',
      padding: '0',
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '1.4',
      fontWeight: 600,
      color: '#000A8C',
    },
    '& svg': {
      width: '18px',
      height: '18px',
      '& path': {
        fill: '#000A8C',
      },
    },
  },
  textarea: {
    '& input': {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      color: '#000A8C',
      background: '#F1F1F1',
      padding: '15px',
      borderRadius: '5px',
      border: '1px solid #E4E4F2',
      '&::placeholder': {
        color: '#c9c9c9',
        opacity: '1',
      },
    },
    '& fieldset': {
      display: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#000A8C',
      },
      '&:hover fieldset': {
        borderColor: '#000A8C',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000A8C',
      },
    },
  },
  choices: {
    '& > div': {
      padding: '15px',
      borderRadius: '5px',
      background: '#fbfbfb',
    },
    '& .draglist': {
      padding: '0',
      borderRadius: '0',
      background: 'none',

      '& > div': {
        padding: '0 15px',
        background: '#FBFBFB',
        borderRadius: '5px',
        marginBottom: '20px',
      },
    },
  },
  draggable: {
    background: 'none',
    marginBottom: '30px',
    '& .MuiTextField-root': {
      margin: '0',
    },
    '& .MuiFormControl-root': {
      margin: '0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-formControl': {
      fontSize: '14px',
      lineHeight: '14px',
      color: '#000A8C',
      background: '#F1F1F1',
      padding: '0',
      borderRadius: '5px',
      border: '1px solid #E4E4F2',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'transparent',
        },
      },
      '&.Mui-focused ': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderWidth: '0',
        },
      },
      '& .MuiSelect-iconOutlined': {
        color: '#000A8C',
        right: '5px',
        top: 'calc(50% - 14px)',
      },
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 600,
      padding: '12px 10px',
      '&::placeholder': {
        color: '#A9AAB8',
        opacity: '1',
      },
    },
    '& .MuiIconButton-colorSecondary': {
      padding: 0,
      margin: '0 auto',
      display: 'block',
      '& svg': {
        width: '18px',
        height: '18px',
        '& path': {
          fill: '#000A8C',
        },
      },
    },
    '& .toolbar-choice': {
      '& .MuiInputBase-formControl': {
        '& .MuiSelect-iconOutlined': {
          top: 'calc(50% - 8px)',
        },
      },
    },
  },
  outline: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 600,
    color: '#000A8C',
    textTransform: 'inherit',
    backgroundColor: 'white',
    minWidth: '160px',
    height: '44px',
    border: '1px solid #000A8C',
    padding: '16px 10px 12px',
    marginLeft: '0',
    marginRight: '0',
    transition: 'all 0.5s linear',
    '& .MuiButton-endIcon': {
      marginleft: '8px',
      marginRight: '0',
      marginTop: '-4px',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#000A8C',
    },
  },
  contain: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 600,
    color: 'white',
    textTransform: 'inherit',
    backgroundColor: '#000A8C',
    minWidth: '160px',
    height: '44px',
    border: '1px solid #000A8C',
    padding: '16px 10px 12px',
    marginLeft: 'auto',
    marginRight: '0',
    transition: 'all 0.5s linear',
    '&:hover': {
      color: '#000A8C',
    },
  },
  title: {
    fontWeight: 'bold',
    color: '#0026A7',
  },
  description: {
    color: '#9e9e9e',
  },
  textAreaEndbook: {
    marginTop: theme.spacing(2),
    backgroundColor: '#f9f9f9',
    borderRadius: 4,
    border: '1px solid #e0e0e0',
    '& .MuiOutlinedInput-multiline': {
      padding: '15px',
      background: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-inputMultiline': {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '500',
        color: '#000A8C',
      },
    },
  },
  charCount: {
    textAlign: 'right',
    color: '#9e9e9e',
  },
}));

const Transition = forwardRef(function Transition(
  props: any & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ChoiceModal = ({ open, onClose, pageData, indexPageToEdit, typeGame }: any) => {
  const classes = useStyles();
  const [hasTitle, setHasTitle] = useState(false);
  const [title, setTitle] = useState('');
  const [choice, setChoice] = useState('multiples');
  const [choiceDisplay, setChoiceDisplay] = useState('horizontal');
  const [currentChoice, setCurrentChoice] = useState('');
  const [choices, setChoices] = useState<any>([]);
  const [lastChoices, setLastChoices] = useState<any>([]);
  const [openAlert, setOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const handleClick = () => {
    setOpen(true);
  };
  const choicesContainerRef = useRef<HTMLDivElement>(null);
  const lastChoiceRef = useRef<HTMLDivElement>(null);
  const [text, setText] = useState('');
  const maxChars = 100000000000000000000000000000000000000000000000000;
  const disabledStyle = { color: '#c9c9c9' };
  const checkedStyle = { fill: '#c9c9c9' };
  const disabledCheckedStyle = {
    color: '#c9c9c9',
  };
  const handleTextChange = (event: any) => {
    const newText = event.target.value;
    if (newText.length <= maxChars) {
      setText(newText);
    }
  };

  const gamePagesData = sessionStorage.getItem('gamePages');
  const gamePagesArray: any[] = gamePagesData ? JSON.parse(gamePagesData) : [];

  const isDisabled = (): boolean => {
    return (
      gamePagesArray.some((res: any) => res.typeChoice === 'end_game') &&
      currentChoice !== 'end_game'
    );
  };

  const isChoiceDisplay = () => {
    return pageData?.template_game === 6 || pageData?.template_game === 7;
  };

  useEffect(() => {
    if (open) {
      initializeChoices();
      initializePageData();
    }
  }, [open]);

  const initializeChoices = () => {
    sessionStorage.setItem('page_id', pageData?.id);
    if (pageData?.choice?.length > 0) {
      sessionStorage.setItem('choiceTemp', JSON.stringify(pageData));
    }
    const initialChoices = pageData?.choice || sessionStorage.getItem('choiceTemp');
    initialChoices.forEach((item: any) => {
      if (!item.id) {
        const randomId = `id_${Math.random().toString(36).substr(2, 9)}`;
        item.id = randomId;
      }
    });

    setChoices(initialChoices);
    setCurrentChoice(pageData?.typeChoice);
    setLastChoices(pageData?.choice);
    setChoice(pageData?.typeChoice || 'multiples');
  };

  const initializePageData = () => {
    setTitle(pageData?.choiceTitle);
    setText(pageData?.textEndGame);
    setChoiceDisplay(pageData?.choiceDisplay || 'horizontal');
    setHasTitle(!!pageData.choiceTitle);
  };

  useEffect(() => {
    if (!hasTitle) {
      setTitle('');
    }
  }, [hasTitle]);

  const removeStringIds = (array: any) => {
    return array.map((item: any) => {
      if (typeof item.id === 'string') {
        const { id, ...rest } = item;
        return rest;
      }
      return item;
    });
  };

  const handleClose = (event: any, reason: any): any => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    event.preventDefault();
  };

  const resetStates = () => {
    setHasTitle(false);
    setTitle('');
    setChoice('multiples');
    setChoices([]);
    setOpen(false);
    setData([]);
    setText('');
    sessionStorage.removeItem('choiceTemp');
    sessionStorage.removeItem('choicesData');
  };

  const handleAddChoice = () => {
    const maxChoices =
      (pageData.template_game === 6 && choiceDisplay === 'horizontal') ||
      (choiceDisplay === 'horizontal' && pageData.template_game === 7)
        ? 8
        : 4;

    if (choices.length < maxChoices) {
      const newChoice = {
        id: choices.length,
        text: '',
        destination: null,
        type: 'dur',
        selectedVariable: null,
      };
      setChoices([...choices, newChoice]);
      sessionStorage.setItem('choicesData', JSON.stringify([...choices, newChoice]));
      setTimeout(() => {
        lastChoiceRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    } else {
      handleClick();
    }
  };
  const handleUpdateChoices = (updatedChoicesData: any) => {
    setChoices(updatedChoicesData);
    sessionStorage.setItem('choicesData', JSON.stringify(updatedChoicesData));
    // console.log('updatechoicemodalmain', updatedChoicesData);
  };

  const continuous = () => {
    onClose(
      {
        choice: choices.length > 0 ? updateChoices(removeStringIds(choices)) : [],
        choiceTitle: title,
        typeChoice: choice,
        textEndGame: text,
        choiceDisplay,
      },
      true
    );
    setTimeout(() => {
      resetStates();
    }, 500);
  };

  const isAddChoice = () => {
    return choice === 'multiples' || choice === 'pursue' || choice === 'redirection';
  };

  const updateChoices = (choicesx: any[]): any[] => {
    return choicesx.map((choicex: any) => {
      if (choicex.type === 'dur') {
        const { id, type, ...rest } = choicex;
        return rest;
      }
      return choicex;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetStates();
        onClose();
      }}
      TransitionComponent={Transition as any}
      classes={{ paper: classes.dialog }}
      BackdropProps={{ invisible: true }}>
      <div className={classes.dialogTitle}>
        <DialogTitle>{title || ''}</DialogTitle>
        <IconButton
          className={classes.cross}
          onClick={() => {
            resetStates();
            onClose();
          }}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        {' '}
        {open && (
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={(event, reason) => handleClose(event, reason)}>
            <Alert severity="warning">
              Le nombre maximum de choix (
              {(choiceDisplay === 'horizontal' && pageData.template_game === 6) ||
              (choiceDisplay === 'horizontal' && pageData.template_game === 7)
                ? 8
                : 4}
              ) a été atteint.
            </Alert>
          </Snackbar>
        )}
        <div className={classes.sectiontitle}>
          <p className={classes.subtitle}>Titre</p>
          <FormControlLabel
            control={<Checkbox checked={hasTitle} onChange={e => setHasTitle(e.target.checked)} />}
            label="Présence d'un titre"
            className={classes.checkbox}
          />
          {hasTitle && (
            <TextField
              fullWidth={true}
              variant="outlined"
              margin="normal"
              placeholder="Entrer le titre"
              className={classes.textarea}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          )}
        </div>
        <div className={classes.section}>
          <p className={classes.subtitle}>Choix</p>
          <RadioGroup
            className={classes.radioGroup}
            value={choice}
            onChange={e => {
              setChoice(e.target.value);
              if (e.target.value === 'pursue') {
                setChoices([]);
              }
              if (e.target.value === 'multiples') {
                setChoices(lastChoices);
              }
              setChoiceDisplay('horizontal');
            }}>
            <FormControlLabel value="multiples" control={<Radio />} label="Options multiples" />
            <FormControlLabel
              value="pursue"
              control={<Radio />}
              label='Bouton "poursuivre" uniquement'
            />
            <FormControlLabel value="redirection" control={<Radio />} label="Redirection url" />
            {!isDisabled() && (
              <FormControlLabel
                value="end_game"
                control={<Radio />}
                label={`${typeGame === 'game' ? 'Fin du jeu vers la restitution' : 'Fin du jeu'}`}
              />
            )}
            {isDisabled() && (
              <FormControlLabel
                value="end_game"
                control={
                  <Radio
                    icon={
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="RadioButtonUncheckedIcon"
                        style={isDisabled() ? checkedStyle : {}}>
                        <circle cx="12" cy="12" r="10" />
                      </svg>
                    }
                    checkedIcon={
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="RadioButtonCheckedIcon"
                        style={isDisabled() ? checkedStyle : {}}>
                        <circle cx="12" cy="12" r="10" style={checkedStyle} />
                        <circle cx="12" cy="12" r="5" style={checkedStyle} />
                      </svg>
                    }
                  />
                }
                label={
                  <span style={isDisabled() ? disabledStyle : {}}>
                    {isDisabled() ? 'Fin du jeu (il y a déjà une fin au jeu)' : 'Fin du jeu'}
                  </span>
                }
                disabled={isDisabled()}
              />
            )}
          </RadioGroup>
        </div>
        {isChoiceDisplay() && choice === 'multiples' && (
          <div className={classes.section}>
            <p className={classes.subtitle}>AFFICHAGE</p>
            <RadioGroup
              className={classes.radioGroup}
              value={choiceDisplay}
              onChange={e => {
                setChoiceDisplay(e.target.value);
                setChoices([]);
              }}>
              <FormControlLabel
                value="horizontal"
                control={<Radio />}
                label="Choix classique horizontal"
              />
              <FormControlLabel
                value="carre"
                control={<Radio />}
                label="Choix sous forme de carré"
              />
            </RadioGroup>
          </div>
        )}
        {isAddChoice() && (
          <div className={classes.choices} ref={choicesContainerRef}>
            <DraggableListChoice
              initialItems={choices}
              handleUpdate={handleUpdateChoices}
              className={classes.draggable}
              lastChoiceRef={lastChoiceRef}
              isRedirection={choice === 'redirection'}
              isCarre={choiceDisplay === 'carre'}
              typeChoice={choice}
              typeGame={typeGame}
            />
          </div>
        )}
        {!isAddChoice() && (
          <>
            <Typography variant="body1" className={classes.title}>
              Édition du titre du bouton
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {typeGame === 'game'
                ? 'Le lien de ce bouton lancera la restitution du jeu'
                : 'Le lien de ce bouton renverra vers la liste des jeux'}
            </Typography>
            <TextField
              className={classes.textAreaEndbook}
              multiline={true}
              rows={4}
              variant="outlined"
              value={text}
              onChange={handleTextChange}
              fullWidth={true}
            />

            {/*    <Typography variant="caption" display="block" className={classes.charCount}>
              {text?.length} / {maxChars} caractères max.
        </Typography> */}
          </>
        )}
      </DialogContent>
      <div className={classes.continueButton}>
        {isAddChoice() && (
          <Button
            endIcon={<AddIcon />}
            onClick={handleAddChoice}
            className={classes.outline}
            disabled={choice === 'pursue' && choices.length === 1}>
            Ajouter un choix
          </Button>
        )}
        <Button className={classes.contain} onClick={continuous}>
          Continuer
        </Button>
      </div>
    </Dialog>
  );
};

export default ChoiceModal;
