// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      width: '97%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    inputError: {
      '& div fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  })
);
