const INITIAL_STATE: any = {
    id: 0,
    indoorWeather: null,
    personnelTest: {},
    stylePersonnel: {},
};

const stateBook3 = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'ID': {
            return { ...state, id: action.payload };
        }
        case 'INDOORWEATHER': {
            return { ...state, indoorWeather: action.payload };
        }
        case 'PERSONNELTEST': {
            return { ...state, personnelTest: action.payload };
        }
        case 'STYLEPERSONNEL': {
            return { ...state, stylePersonnel: action.payload };
        }
        default:
            return state;
    }
};

export default stateBook3;

