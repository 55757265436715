export const list = [
    {
        text: 'Exemple : se former avec de jeunes étudiant(e)s pendant une année, renoncer à 50% de sa rémunération pendant deux ans pour créer son entreprise, etc.',
        id: 1,
        disable: true,
        value: true
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
    {
        id: 7
    },
    {
        id: 8
    }
]