/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { FC } from 'react';
import { useStyles } from './style';

interface IContentCenter {
    children: any;
}

const ContentCenter: FC<IContentCenter> = ({
    children
}) => {

    const classes = useStyles();

    return (
        <>
            <div className={classes.bodycenter}>
                {children}
            </div>
        </>
    );
};

export default ContentCenter;
