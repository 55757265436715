import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { publicLogoPath } from '@orientaction/utils';
import classNames from 'classnames';
import { FC } from 'react';
import style from './style';

interface IProps {
  children: any;
  title: string;
  subtitle?: string;
  subSubtitle?: string;
  overrideTitleStyle?: any;
}

const AuthDialog: FC<IProps> = ({
  children,
  title,
  subtitle = '',
  subSubtitle = '',
  overrideTitleStyle = '',
}) => {
  const classes = style();
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={true}
      className={classes.paperRounded}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}>
      <DialogContent className={classes.dialogContentContainer} dividers={false}>
        <div className={classes.logoContainer}>
          <picture className={classes.logo}>
            <source srcSet={`${publicLogoPath}/e-orientaction.png`} />

            <img
              src={`${publicLogoPath}/e-orientaction.png`}
              alt="logo-orientation"
              className={classes.logoImg}
            />
          </picture>
        </div>
        <div className={classNames(classes.dialogTitle, overrideTitleStyle)}>{title}</div>
        {/* <div className={classes.dialogSubtitle}>{subtitle}</div>
        <div className={classes.dialogsubSubtitle}>{subSubtitle}</div> */}
        <div className={classes.formContainer}>{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialog;
