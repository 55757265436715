import { getFromLS } from '@orientaction/utils';

export const isRootUser = (user: any) => {
  if (!user) {
    return false;
  }
  const { role } = user;

  if (role?.type === 'admin') {
    return true;
  }
  return false;
};

export const isAdmin = (user: any) => {
  if (!user) {
    return false;
  }
  const { company_user_role, role } = user;

  if (
    role?.type === 'admin' ||
    company_user_role?.id === 1 ||
    company_user_role?.id === 2 ||
    company_user_role?.id === 3 ||
    company_user_role?.id === 4
  ) {
    return true;
  }

  if (role?.type === 'authenticated') {
    return false;
  }

  if (company_user_role?.id === 5) {
    return false;
  }

  if (!company_user_role) {
    return true;
  }

  return false;
};

export const isAdminVerify = () => {
  const userInfos = JSON.parse(getFromLS('userInfos') || '');

  const { company_user_role, role } = userInfos;

  if (
    role?.type === 'admin' ||
    company_user_role?.id === 1 ||
    company_user_role?.id === 2 ||
    company_user_role?.id === 3 ||
    company_user_role?.id === 4
  ) {
    return true;
  }

  if (role?.type === 'authenticated') {
    return false;
  }

  if (company_user_role?.id === 5) {
    return false;
  }

  if (!company_user_role) {
    return true;
  }

  return false;
};

export const isTopManager = (companyRoleId: string | number) => {
  return [1, 2, 3].includes(+companyRoleId);
};
