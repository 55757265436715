import { useState } from 'react';

function App() {

    const [isDragging, setIsDragging] = useState(false);
    const [rotation, setRotation] = useState(0);

    const onMouseDown = () => {
        setIsDragging(true);
    }

    const onMouseUp = () => {
        setIsDragging(false);
    }

    const handleMouseMove = (event: any) => {

        if (!isDragging) {
            return
        }

        const rect = event.target.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const deltaX = event.clientX - centerX;
        const deltaY = event.clientY - centerY;
        const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

        setRotation(angle);

    };

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

            }}
        >
            <div
                style={{
                    position: 'relative',
                    width: '200px',
                    height: '200px',
                    border: '1px solid black',
                    transform: `rotate(${rotation}deg)`,
                    backgroundColor: isDragging ? 'red' : 'white',
                    cursor: isDragging ? 'grabbing' : 'move',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={handleMouseMove}
            >
                My mouse element
            </div>
        </div>

    );
}

export default App;
