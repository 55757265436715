import FirstView from './FirstView';
import SecondView from './SecondView';
import ThirdView from './ThirdView';

const Perso = ({ myResult, dataFirstView, handleOpen }: any) => {
  return (
    <>
      <FirstView
        img={myResult?.favorite?.imgPyramid || ''}
        description={dataFirstView.description}
      />

      <SecondView listPrincipaux={myResult?.treeMax || []} handleOpenDrawer={handleOpen} />

      <ThirdView />
    </>
  );
};

export default Perso;
