/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setList =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'LIST',
      payload: data,
    });
  };

export const setLoadingUpdate =
  (data: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'LOADINGUPDATE',
      payload: data,
    });
  };

export const setLoading =
  (data: boolean): AppThunk =>
  dispatch => {
    dispatch({
      type: 'LOADING',
      payload: data,
    });
  };

export const setModal =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'MODAL',
      payload: data,
    });
  };

export const setListTest =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'LIST_TEST',
      payload: data,
    });
  };

export const updateCategoryTest =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'UPDATE_CATEGORY_TEST',
      payload: data,
    });
  };
