// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
    TitleChapitre
} from '@orientaction/components';
import useStyles from './style';
import Img from '../../components/Img';
import { MarginContainer } from '@orientaction/commons';

const Page20 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={19}
        >

            <TitleChapitre
                nbr={2}
            />
            
            <TitlePage>
                RELEVEZ LE DIP<br />
                (DÉFI IMPOSSIBLE OU POSSIBLE)
            </TitlePage>

            <MarginContainer big={true}>
                <Img
                    src='france.png'
                    alt=' RELEVEZ LE DIP<br /> (DÉFI IMPOSSIBLE OU POSSIBLE)'
                    className={classe.img}
                />
            </MarginContainer>

            <MarginContainer>

                <Paragrapth align='justify'>

                    <Paragrapth align='justify' colorBlue={true} bold={true} > Le DIP </Paragrapth>, c’est ce qui vous sépare de votre rêve. <Paragrapth align='justify' colorBlue={true} bold={true}>Le DIP</Paragrapth>, c’est la somme de toutes
                    les actions que vous devrez entreprendre, de tous les obstacles que vous devrez
                    dépasser, de tous les sacrifices que vous devrez faire pour atteindre votre objectif
                    suprême : la réalisation de votre projet. <Paragrapth align='justify' colorBlue={true} bold={true} >Le DIP</Paragrapth> est un chemin qui peut être long et
                    difficile. C’est aussi une aventure extraordinairement passionnante. Il n’en revient
                    qu’à vous de décider : vous engager dans ce chemin et ne plus regarder derrière
                    vous, ou renoncer et rester là où vous êtes.

                </Paragrapth>

            </MarginContainer>

            <MarginContainer>

                <Paragrapth align='justify'>
                    Mieux vaut renoncer qu’échouer. Mieux vaut renoncer quand il est encore
                    temps, que de s’arrêter en plein milieu du chemin sans pouvoir avancer et
                    sans possibilité de retour. Voilà pourquoi vous devez prendre conscience
                    du DIP. Informé(e), averti(e), conscient(e), vous serez en mesure de prendre
                    une décision sage et réfléchie. Il n’y aura pas de surprises ! Vous aurez tout
                    prévu ! Vous n’avancerez plus dans l’inconnu, mais sur un chemin balisé.
                    Vous progresserez à votre rythme, par étapes, sûr(e) et certain(e) de réussir.
                </Paragrapth>

            </MarginContainer>

        </Page>
    );
};
export default Page20;
