import classnames from 'classnames';
import { FC, useContext } from 'react';
import { UserProfilePhotoContext } from '../../Context/UserProfilePhotoContext';
import style from './style';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


interface IProps {
  anchorRef: any;
  handleToggle: any;
  overrideClassname?: any;
  user: any;
  open: boolean;
}

const UserPhotos: FC<IProps> = ({ user, anchorRef, handleToggle, overrideClassname = '', open }) => {
  const cls = style();
  const { userProfilePhoto } = useContext<any>(UserProfilePhotoContext);
  return (
    <div
      ref={anchorRef as any}
      className={classnames(cls.profile, overrideClassname)}
      onClick={handleToggle}
      data-cy="menu-user-profile"
    >
      {userProfilePhoto?.length > 0 ? (
        <img className={cls.photo} src={userProfilePhoto ?? ''} alt="user_photo" />
      ) : (
        <div className={cls.initial}>
          <span>{`${user?.firstname?.charAt(0).toUpperCase()}${user?.lastname
            ?.charAt(0)
            .toUpperCase()}`}</span>
        </div>
      )}
      <span className={cls.textHeader}>{`${user.firstname} ${user.lastname}`}</span>
      <IconButton className={cls.btnIcon} style={{ float: 'right' }}>

        {
          open ?
            (
              <>
                <ExpandLessIcon className={cls.styleIcon} />
              </>
            ) :
            (
              <>
                <ExpandMoreIcon className={cls.styleIcon} />
              </>
            )
        }
      </IconButton>
    </div>
  );
};

export default UserPhotos;
