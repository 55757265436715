// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    marginTop: {
      marginTop: 15,
    },
    fullWidth: {
      width: '100%',
    },
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: '#000A8C',
      color: '#fff',
      '@media(min-width: 600px)': {},
    },
    textHeader: {
      display: 'none',
      marginLeft: 0,
      '@media(min-width: 600px)': {
        display: 'block',
      },
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    textField: {
      width: '95%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    subtitle: {
      color: '#000A8C',
      fontSize: 26,
      marginTop: 15,
    },
    formSelect: {
      width: '100%',
      fontFamily: 'Poppins',
    },
    inputError: {
      border: `2px solid red`,
      borderRadius: '4px',
    },
    responseFormTitle: {
      fontFamily: 'Poppins',
      fontSize: 22,
      fontStyle: 'normal',
      fontWeight: 700,
      color: theme.palette.primary.light,
    },
    error: {
      '& div fieldset': {
        border: `2px solid ${theme.palette.error.main}`,
      },
    },
  })
);
