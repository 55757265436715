/* eslint-disable react-hooks/exhaustive-deps */
import { getFromLS, getUserLanguageFromBrowser } from '@orientaction/utils';
import { useEffect, useState } from 'react';

declare const Weglot: any;

const useOfflineLanguage = () => {
  const [language, setLanguage] = useState('FR');

  const onLanguageChange = async (code: string) => {
    setLanguage(code);
    localStorage.setItem('notAuthenticatedLanguage', code);
    Weglot.switchTo(code === 'GB' ? 'en' : 'fr');
  };

  useEffect(() => {
    const lang = getFromLS('notAuthenticatedLanguage');
    if (lang) {
      setLanguage(lang);
      Weglot.switchTo(lang === 'GB' ? 'en' : 'fr');
    } else {
      const browserLanguage = getUserLanguageFromBrowser();
      setLanguage(browserLanguage);
      Weglot.switchTo(browserLanguage === 'GB' ? 'en' : 'fr');
    }
  }, []);

  useEffect(() => {
    if (language === 'GB') {
      Weglot.switchTo('GB');
    } else {
      Weglot.switchTo('FR');
    }
  }, [language]);

  return {
    language,
    onLanguageChange,
  };
};

export default useOfflineLanguage;
