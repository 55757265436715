/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
// eslint-disable-next-line no-use-before-define
import { FC } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { useStyles } from './style';


interface IDialogNotConnected {
  open: boolean;
  handleClose: () => void;
  goToSignupPage: () => void;
}

const DialogNotConnected: FC<IDialogNotConnected> = ({
  open,
  handleClose,
  goToSignupPage
}) => {

  const classe = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          className={classe.iconBoutton}
          onClick={handleClose}>
          <ClearIcon fontSize="small" />
        </IconButton>
        <DialogContent>
          <DialogTitle
            id="alert-dialog-title"
          >
            Vous n&apos;êtes pas connecté
          </DialogTitle>
          <DialogContentText id="alert-dialog-description">
            Pour voir les résultats de votre test, nous vous invitons à vous connecter ou à créer
            un compte Orient Action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goToSignupPage} color="primary" autoFocus={true}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogNotConnected;
