/* eslint-disable react/require-default-props */
import './loading.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import { FC, HTMLAttributes } from 'react';

interface LoaderProps {
  isFullHeight?: boolean;
}

const Loading: FC<LoaderProps & HTMLAttributes<HTMLDivElement>> = props => {
  const { className, isFullHeight, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={classnames('page-loader', isFullHeight && 'full-height', className)}>
      <CircularProgress style={{ color: '#0E1247' }} />
    </div>
  );
};

export default Loading;
