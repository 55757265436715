import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            borderBouttom: 'none',
            border: '1px solid #BCBCBC',
            paddingTop: '0.75vh',
            paddingLeft: '1.5vh',
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '90%',
            '&.MuiInput-underline': {
                borderBouttom: 'none'
            }
        },
        iconButton: {
            color: 'rgba(14, 18, 71, 1)',
            position: 'relative',
            left: 0,
            fontSize: 27,
        },
    })
);
