/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page27 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={29}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
          MIEUX CONNAÎTRE VOS VÉRITABLES <br />
          MOTIVATIONS
        </Typography>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            <strong>
              « La seule façon de faire du bon travail est d’aimer ce que vous <br />
              faites. Si vous n’avez pas encore trouvé, continuez à chercher. »
            </strong>
          </Typography>
          <br />
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}
          >
            Steve Jobs
          </Typography>
        </div>
        <div
          className={clsx(classes.trait, classes.positionCenter)}
          style={{ marginTop: '60px', marginBottom: '60px' }}
        />
        <div style={{ textAlign: 'center' }}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            <strong>
              « Mesure tes forces d’après tes aspirations <br />
              et non tes aspirations d’après tes forces. »
            </strong>
          </Typography>
          <br />
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}
          >
            Adam Mickiewicz
          </Typography>
        </div>
        <div className={classes.cardBgGrey} style={{ marginTop: '40px' }}>
          <div style={{ marginBottom: 80 }}>
            {/* <img className={classes.imgTailleur} src={`${imgBook2}/titre34.png`} alt="logo" /> */}
            <SimpleBanner>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                L&apos;HOMME QUI VOULAIT VIVRE SA VIE
              </Typography>
            </SimpleBanner>
          </div>
          <img className={classes.img} src={`${imgBook2}/illlustration_page_34.png`} alt="logo" />
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Lorsqu’il décide de créer sa société à l’âge de 13 ans, « Bill » comme on le surnomme,
            est un Geek : un adolescent hyper-introverti qui passe des heures sur son ordinateur à
            programmer. Informaticien génial, c’est en revanche un piètre communicant.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Pour vendre ses projets de logiciels, il doit se rendre dans les entreprises pour
            rencontrer ses clients potentiels.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Cette activité est non seulement difficile pour lui, mais elle l’ennuie profondément.
            Prospecter pour prendre des rendez-vous, passer des heures à convaincre des clients
            réticents et exigeants, assurer le service après-vente, ce n’est pas son truc ! Bill,
            plutôt que de persévérer dans cette activité qui ne l’intéresse pas et pour laquelle il
            n’a aucun talent, va alors changer complètement de stratégie. Il va chercher un
            partenaire qui se chargera de la commercialisation, tandis que lui pourra se consacrer
            avec ses amis à ses activités préférées : programmer, concevoir et créer des systèmes
            d’exploitation hypercomplexes en mangeant des chips et en buvant du soda glacé.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Ce partenaire, ce sera IBM. Et c’est ainsi que naîtra Windows, pour ce que certain(e)s
            appelleraient un « caprice d’adolescent. »
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      marginRight: '-85px',
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    imgFurious: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
      },
    },
    imgFurious1: {
      width: '30%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '20px',
      color: '#2b56a5',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page27;
