import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    width: 200,
    height: 200,
    [theme.breakpoints.down('xs')]: {
      marginBottom: '4vh',
    }
  },
  imageContainer: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  input: {
    display: 'none',
  },
  modifyButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#0E1247',
  },
}));
