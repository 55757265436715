/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FC } from 'react';
import { getStudyStyleEnImageByKey } from '../utils';
import { explanationParagraphs } from './constant';
import Score from './Score';
import style from './style';

interface IProps {
  data: any;
  language: string;
}

const Explanation: FC<IProps> = ({ data: { data }, language }) => {
  const classes = style();
  const { key, ...rest } = data?.treeMax[0];
  const studyEnImageSource = getStudyStyleEnImageByKey(data.favorite.key);
  return (
    <Grid justifyContent="center" alignItems="center" container={true}>
      <Grid className={classes.resultImgContainer} item={true} sm={12} md={6}>
        <img
          className={classes.resultImg}
          src={`${language === 'GB' ? studyEnImageSource : data.favorite.img}`}
          alt="Competitor"
        />
      </Grid>
      <Grid className={classes.scoreItemContainer} item={true} sm={12} md={6}>
        <Score score={rest} />
      </Grid>
      <Grid className={classes.explanationTextContainer} item={true} md={12}>
        {explanationParagraphs[key].map((paragraph: string, index: number) => (
          <Typography key={index} className={classes.explanationText} component="p">
            {paragraph}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default Explanation;
