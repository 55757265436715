import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MarginContainer } from '@orientaction/commons';
import { FC, useMemo } from 'react';
import Select from 'react-select';
import style from './style';

interface IProps {
  services: any;
  service: any;
  handleChange: any;
  error: boolean;
}

const CustomDropdownIndicator = ({ innerRef, innerProps }: any) => (
  <ArrowDropDownIcon innerRef={innerRef} {...innerProps} />
);

const customStyles = (error: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: error ? '#f44336' : '#0E1247',
      minHeight: '3rem',
      boxShadow: 'unset',
      '&:hover': {
        borderColor: error ? '#f44336' : '#0E1247',
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      width: 0,
    }),
  };
};

const ServiceSelection: FC<IProps> = ({ services, service, handleChange, error }) => {
  const classes = style();

  const handleSelectChange = (data: any) => {
    handleChange({ target: { name: 'service', value: data } });
  };

  const styles = useMemo(() => customStyles(error), [error]);

  return (
    <>
      <MarginContainer>
        <Typography className={classes.label}> Prestations </Typography>
      </MarginContainer>
      <Select
        styles={styles}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
        placeholder="Sélectionner la prestation"
        className={classes.select}
        options={services}
        value={service}
        onChange={handleSelectChange}
        getOptionLabel={option => option?.attributes?.name || option?.name || ''}
        getOptionValue={option => option.id}
        name="service"
      />
    </>
  );
};

export default ServiceSelection;
