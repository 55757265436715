import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyItems: 'center'
        },
        flex2: {
            width: '98%',
            marign: '1.5vh 1.5vh',
            display: 'flex',
            flexDirection: 'column',
            justifyCcontent: 'flex-start'
        },
        flex1: {
            marign: '1vh 2.5vh'
        },
        flexDraged: {
            width: '100%',
        },
    })
);