export const text = [
    `Changer d’employeur appartient à la catégorie des changements les moins
     risqués. Il conviendra néanmoins d’être vigilant(e) sur le choix du nouvel employeur
     et sur la définition du nouveau poste.`,
    `En effet, une erreur pourrait affecter votre confiance en soi et la confiance dans votre
    profil des futurs recruteurs. Ils pourraient voir en vous « une personne qui ne sait pas
    ce qu’elle veut ».`,
    `Une attention particulière devra donc être accordée à la vérification de l’adéquation
    entre le futur poste et vos aspirations et vos compétences.`
]
