import { useSelector } from 'react-redux';

const useTestRights = (testId: number) => {
  const majorTestsAccess = useSelector((store: any) => store.compass.majorTestsAccess);
  const majorTestsAccessIds = majorTestsAccess.map((mT: any) => +mT.id);
  const canUserAccessTest = majorTestsAccessIds.includes(testId); // major test id
  return {
    canUserAccessTest,
  };
};

export default useTestRights;
