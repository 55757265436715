import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textLock: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '140 %',
      color: '#CDCDCD',
      padding: '9vh 2vh 2vh 2vh',
      border: '1.5px solid',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    logoOrientaction: {
      with: '100%',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        maxWith: '100%',
      },
    },
  })
);

export default useStyles;
