import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { TextArea } from '@orientaction/commons';
import useStyle from './style';
import { FC } from 'react';


interface IProps {
  inputs: any;
  handleChange: (e: any) => void;
  current: any;
  nameGroup: string;
  readOnly?: boolean;
}

const HelpfulPersonTable: FC<IProps> = ({ inputs, current, nameGroup, readOnly, handleChange }) => {

  const classe = useStyle();

  const handleChangeIt = (e: any) => {

    const { name, value } = e.target;

    handleChange({
      ...current,
      [nameGroup]: {
        ...current?.[nameGroup],
        [name]: value
      }
    });

  }

  return (
    <div>
      <Grid container={true}>
        {inputs.map((text: any, i: number) => (
          <Grid item={true} xs={6} md={4} className={classe.border}>
            <TextArea
              onChange={handleChangeIt}
              name={text}
              value={current?.[nameGroup]?.[text] || ""}
              notBorder={true}
              readOnly={readOnly}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HelpfulPersonTable;
