// eslint-disable-next-line simple-import-sort/imports
import { MarginContainer } from '@orientaction/commons';
import { TitlePage, Page, TitleChapitre, Paragrapth } from '@orientaction/components';
import Img from '../../components/Image';
import useStyles from './style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page36 = () => {
  const classes = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_branch.png' : 'branch.png';
  }, [language]);
  return (
    <Page id={36}>
      <TitleChapitre nbr={4} />

      <TitlePage>
        LES 3 SAVOIRS
        <br />
        (LES BRANCHES)
      </TitlePage>
      <MarginContainer>
        <Paragrapth align="justify">
          Les 3 savoirs constituent la partie la plus visible de vos compétences. Chaque jour, dans
          le cadre de votre activité professionnelle ou de votre activité personnelle
          (responsabilité associative ou sociale par exemple), vous mettez en action vos 3 savoirs.
          C’est grâce à eux que vous obtenez des résultats performants dans ce que vous
          entreprenez. L’identification de vos savoirs, savoir-être et savoir-faire est une étape
          essentielle de votre bilan de compétences. Elle permettra de mettre en lumière les
          résultats que vous serez en mesure d’obtenir.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Img filename={image} alt="tronc" className={classes.imgTronc} />
      </MarginContainer>
    </Page>
  );
};

export default Page36;
