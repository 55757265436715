const explanationParagraphs: any = {
  love_trade_economy: [
    `Tu es passionné(e) par le commerce, la gestion et l’économie. Ton sujet : l’entreprise sous toutes ses formes.`,
    `Tu aimes les chiffres. L’argent n’est pas un gros mot pour toi. Tu aimes être en contact avec des informations économiques et tu aimerais exercer un métier dans ce secteur.`,
    `Les matières comme le marketing, la communication commerciale, l’analyse financière, la comptabilité ou encore les statistiques appliquées à l’économie attisent ta curiosité et stimulent ton intelligence.`,
    `Tu pourrais bien embrasser une carrière de commercial, de gestionnaire financier, d'actuaire ou d'économiste.`,
  ],

  love_arts_communication: [
    `Tu es intéressé(e) par l’art, l’information, la communication et la culture. L’argent n’est pas le moteur principal de ton action.`,
    `Ton échelle de valeur se situe ailleurs. Tu aimes tout ce qui fait réfléchir, éveille les consciences, distrait ou donne à apprendre quelque chose.`,
    `Voilà pourquoi faire des études dans ce domaine a du sens pour toi. 🙂`,
    `Les matières comme l’histoire de l’art, la communication visuelle, le journalisme, la conduite de projets culturels attisent ta curiosité et stimulent ton intelligence abstraite et ton sens pratique.`,
  ],

  love_law_political_science: [
    `Tu es attiré(e) par le droit, l’administration et les sciences politiques. Tu aimes la langue française pour l’utiliser dans un objectif précis.`,
    `Contrairement aux Littéraires qui aiment la langue pour sa nature esthétique, tu aimes la langue, mais dans le but de prendre des décisions et d’agir.`,
    `Tout ce qui t'intéresse touche à la règle et à la loi. Des matières comme le droit des affaires, le droit public, ou l'étude du système politique américain attisent ta curiosité et stimulent ton intelligence.`,
    `Tu pourrais bien embrasser une carrière juridique, politique ou administrative.`,
  ],

  love_science_technology: [
    `Tu es passionné(e) par les sciences et la technologie. Enfant, tu étais du style à démonter le vieux grille-pain pour mieux comprendre comment il fonctionne.`,
    `Comprendre comment fonctionnent les éléments qui composent notre univers, qu’ils soient naturels ou créés par les êtres humains, te passionne et excite ton esprit.`,
    `Les matières comme la chimie de l’eau, la physique des matériaux, la robotique ou les langages informatiques attisent ta curiosité et stimulent ton intelligence.`,
    `Tu pourrais bien embrasser une carrière d'ingénieur(e) ou de chercheur(se).`,
  ],

  love_health_care: [
    `Tu es intéressé(e) par les matières qui traitent de la santé.`,
    `Ces matières comprennent un grand nombre de matières scientifiques et notamment toutes les sciences théoriques qui s’intéressent au fonctionnement du corps humain et/ou animal comme l’anatomie et la physiologie ou des pratiques comme la kinésithérapie et la médecine allopathique.`,
    `La santé comprend aussi les matières liées au « bien-être », à l’esthétique et aux soins du corps.`,
    `Les matières associent alors connaissance du fonctionnement biologique et exercices visant à réaliser un soin ou à prodiguer des conseils (esthétique, diététique, etc.).`,
  ],

  love_letter_humanities: [
    `Tu es passionné(e) par les lettres, les langues et les sciences humaines.`,
    `Tu es plutôt attiré(e) par les matières littéraires et tu as de très bons résultats dans ce domaine.`,
    `Tu as une sensibilité et un goût pour analyser et comprendre des données ou des situations complexes.`,
    `Tu es intéressé(e) pour mieux comprendre l’être humain, comment il vit, pense et évolue (sciences humaines), comprendre des œuvres littéraires et le travail des auteur(e)s (lettres) ou comprendre le fonctionnement d’une culture (langues).`,
  ],
};

export { explanationParagraphs };
