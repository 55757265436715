// eslint-disable-next-line simple-import-sort/imports
import Card from '@material-ui/core/Card';
import { Typography, Grid, CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';

const useStyles = makeStyles({
  para: {
    display: 'inline-block',
    fontFamily: '"ITC Avant Garde Gothic Std Regular", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
  },
  titrePara: {
    fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#0E1247',
    style: 'normal',
  },
  reponse: {
    fontFamily: '"ITC Avant Garde Gothic Std Regular", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
  },
  variable: {
    fontFamily: '"ITC Avant Garde Gothic Std Regular", sans-serif',
    fontSize: '11px',
    color: '#0E1247',
    width: '100%',
    border: '1px solid',
    borderRadius: '19px',
    textTransform: 'none',
  },
  iconDelete: {
    position: 'absolute',
    top: '2px',
    right: '2px',
  },
  number: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    borderRadius: '4px',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
  },
  gridFirst: {
    padding: '20px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  borderTransparent: {
    background: 'transparent',
    padding: '1.5px',
    borderRadius: '10px',
  },
  borderGradient: {
    background: 'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    padding: '1.5px',
    borderRadius: '10px',
  }
});

const CardQuestionnaire = () => {
  const classes = useStyles();
  const [target, setTarget] = React.useState(false);

  const handleTarget = () => {
    setTarget(!target);
  };

  return (
    <div className={target ? classes.borderGradient : classes.borderTransparent}>
      <Card style={{position: 'relative'}} className={classes.gridFirst} onClick={handleTarget}>
        {target ? (
          <IconButton aria-label="upload picture" component="span" className={classes.iconDelete} onClick={() => setTarget(false)}>
            <DeleteForeverIcon />
          </IconButton>
        ) : null}
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={1}>
            <div className={classes.number}>1</div>
          </Grid>
          <Grid item={true} xs={11}>
            <Typography className={classes.titrePara}>Titre de la question</Typography>
            <div style={{marginTop: '15px'}}>
              <Grid container={true}>
                <Grid item={true} xs={2}>
                  <p className={classes.reponse}>Reponse 1</p>
                </Grid>
                <Grid item={true} xs={8}>
                  <p className={classes.para}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </Grid>
                <Grid item={true} xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Button size="small" className={classes.variable} onClick={() => setTarget(false)}>Variable A</Button>
                </Grid>
              </Grid>
              <Grid container={true}>
                <Grid item={true} xs={2}>
                  <p className={classes.reponse}>Reponse 2</p>
                </Grid>
                <Grid item={true} xs={8}>
                  <p className={classes.para}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </Grid>
                <Grid item={true} xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Button className={classes.variable} onClick={() => setTarget(false)}>Variable B</Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
export default CardQuestionnaire;
