const mockData = [
  {
    label: 'Présence et proximité',
    value: 46,
    color: '#FDCE29',
  },
  {
    label: 'Écoute et parole',
    value: 6,
    color: '#F7A82E',
  },
  {
    label: 'Aide et adaptation aux contraintes',
    value: 10,
    color: '#ED6637',
  },
  {
    label: 'Cadeaux, petites attentions et récompenses',
    value: 16,
    color: '#C93646',
  },
  {
    label: "Moments d'échange et de partage",
    value: 16,
    color: '#942A59',
  },
  {
    label: 'Confiance et autonomie',
    value: 6,
    color: '#662169',
  },
];

export { mockData };
