// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import {
    Paragrapth,
    Page,
    TitleUpCase,
    ListParagrapth,
    TitlePage
} from '@orientaction/components';
import useStyles from "../../style";

const Page39 = (props: any) => {

    const classe = useStyles();

    return (
        <Page id={38}>

            <TitleUpCase>
                CONFORMITÉ
            </TitleUpCase>

            <Paragrapth align='justify'>
                Le principe directeur de votre vie : <Paragrapth align='start' colorBlue={true}><strong>Sage comme une image !</strong> </Paragrapth>
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Vous êtes une personne modérée. Vous recherchez la modération dans vos actions et vos
                pensées. Cela signifie que vous n’appréciez pas ce qui est radical, extrême ou excessif.
                <br />
                <br />
                Vous vous refusez à adopter des comportements ou des opinions susceptibles de déstabiliser
                ou blesser les autres, ou encore de transgresser les normes. Sur le plan professionnel, vous
                recherchez un métier conventionnel, dans un environnement structuré, stable et constant.
                <br />
                <br />
                Vous évitez tout ce qui est nouveau et disruptif.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify' colorBlue={true}> <strong style={{fontSize: '16px'}}>Y avez-vous pensé ?</strong></Paragrapth>

            <ListParagrapth list={[
                "Travailler dans une banque ou une grande compagnie d’assurance, deux secteurs offrant un environnement de travail stable et structuré,",
                "Travailler dans des secteurs comme l’immobilier, l’agroalimentaire, l’agriculture ou l’industrie traditionnelle, qui offrent également un environnement stable et modéré,",
                "Exercer un métier du droit, de la gestion et/ou de la comptabilité, domaines orientés vers la stabilité et la constance.",
            ]} />
            <br />
            <br />
            <br />
            <TitleUpCase>
                TRADITION
            </TitleUpCase>

            <Paragrapth align='justify'>
                Le principe directeur de votre vie : <Paragrapth align='start' colorBlue={true}> <strong>Sauvegarder les traditions dans un monde qui bouge !</strong> </Paragrapth>
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                La sauvegarde des traditions de votre communauté est ce qui compte le plus à vos yeux.
                Il peut s’agir de coutumes religieuses ou profanes.
                <br />
                <br />
                Vous êtes la courroie de transmission de ces valeurs, le lien entre deux générations. Vous avez
                envie de transmettre des connaissances et surtout des valeurs aux autres.
                <br />
                <br />
                Sur le plan professionnel, vous défendez les traditions, vous êtes l’ambassadeur(rice) des
                valeurs de votre pays ou de votre organisation. Vous défendez la règle par l’éducation ou la
                sanction.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify' colorBlue={true}> <strong style={{fontSize: '16px'}}>Y avez-vous pensé ?</strong></Paragrapth>

            <ListParagrapth list={[
                "Travailler dans l’armée, la police ou la gendarmerie afin de faire respecter la loi,",
                "Intégrer une organisation religieuse ou une association défendant des valeurs traditionnelles,",
                "Vous engager dans les métiers de l’éducation et de l’enseignement.",
            ]} />

        </Page>
    );
};

export default Page39;
