const studyImageFolder = '/images/Study';

export const getStudyStyleEnImageByKey = (key: string) => {
  let imgSrc;
  switch (key) {
    case 'study_competitor':
      imgSrc = `${studyImageFolder}/en_study_competitor.jpg`;
      break;
    case 'study_epicurean':
      imgSrc = `${studyImageFolder}/en_study_epicurean.jpg`;
      break;
    case 'study_freethinker':
      imgSrc = `${studyImageFolder}/en_study_freethinker.jpg`;
      break;
    case 'study_theorist':
      imgSrc = `${studyImageFolder}/en_study_theorist.jpg`;
      break;
    case 'study_realistic':
      imgSrc = `${studyImageFolder}/en_study_realistic.jpg`;
      break;
    case 'study_enterprising':
      imgSrc = `${studyImageFolder}/en_study_enterprising.jpg`;
      break;
    default:
      break;
  }
  return imgSrc;
};
