/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { useBook3 } from "@orientaction/hooks_book";
import { FC } from 'react';
import useStyles from './style';

interface Itotal {
    total: any;
    id: any;
}

const Total: FC<Itotal> = ({ total, id }) => {

    const classes = useStyles();

    const { totalPersonnelTest, totalStylePersonnel } = useBook3();

    return (
        <MarginContainer>
            
            <div className={classes.paddingLeft}>

                {id === 1 && (
                    <div className={classes.content}  >
                        <span className={classes.title}> Votre score est de  :  {totalPersonnelTest(4) + totalPersonnelTest(2) + totalPersonnelTest(8)}</span>
                    </div>
                )}

                {id === 2 && (
                    <>
                        {
                            total.map((item2: any) => (
                                <div className={classes.content}  >
                                    <span className={classes.title}>  {item2.label}  : {totalStylePersonnel(item2.name)}</span>
                                </div>
                            ))
                        }
                    </>
                )}
                
            </div>

        </MarginContainer>
    );
};

export default Total;
