import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperRounded: {
      '& .MuiDialog-paper': {
        '@media (max-width: 600px)': {
          position: 'absolute',
          bottom: 0,
          margin: 0,
          width: '-webkit-fill-available',
        },
      },
      '& .MuiPaper-rounded': {
        borderRadius: 16,
        '@media (max-width: 600px)': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
    },
    backDrop: {
      backdropFilter: 'blur(8px)',
      backgroundColor: 'rgba(0,0,30,0.4)',
      padding: 75,
    },
    dialogContentTitle: {
      color: '#242424',
      textAlign: 'center',
      fontSize: 30,
      fontFamily: 'Poppins',
      fontWeight: 500,
      marginTop: 40,
      marginBottom: 30,
      '@media (max-width: 600px)': {
        fontSize: 18,
      },
    },
    dialogContentContainer: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 60,
      zIndex: 1,
      '@media (max-width: 600px)': {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 10,
      },
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    errorField: {
      color: theme.palette.warning.main,
      font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    },
    input: {
      flex: 1,
      border: `1px solid #E3E8F0`,
      width: '100%',
      marginTop: 5,
      height: 49,
      '&.MuiInput-input': {
        '&::placeholder': {
          color: '#242424',
        },
      },
      '&:before': {
        display: 'none',
      },
      '& input': {
        fontSize: 13,
        padding: 10,
        '&::placeholder': {
          color: '#242424',
        },
      },
      '&.Mui-focused': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    validateButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 20,
      width: '100%',
    },
    dynamicDisabledSelect: {
      border: '1px solid #DBDBDB',
      height: 50,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    placeholderContainer: {
      marginLeft: 20,
    },
    placeholderFirstPart: {
      color: '#707070',
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    placeholderSecondPart: {
      color: '#242424',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    messageTextArea: {
      resize: 'none',
      width: '100%',
      paddingLeft: 14,
      paddingTop: 10,
      border: '1px solid #DBDBDB',
      '&::placeholder': {
        color: '#242424',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
      },
      '&:focus': {
        outline: 'none',
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    interFieldsMarginTop: {
      marginTop: 20,
    },
    cguMainContainer: {
      marginTop: 18,
    },
    checkBoxCgu: {
      '& .MuiSvgIcon-root': {
        fill: '#dbdbdb',
      },
      '&.Mui-checked': {
        color: `${theme.palette.primary.main} !important`,
        '& .MuiSvgIcon-root': {
          fill: 'currentColor',
        },
      },
      marginLeft: -13,
      marginTop: -12,
    },
    cguAcceptText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: 14,
      margin: 0,
    },
    validateButton: {
      width: 262,
      backgroundColor: '#E63C3C',
      borderRadius: 34,
      textTransform: 'none',
      color: '#fff',
      paddingTop: 16,
      paddingBottom: 16,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    validateButtonLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 16,
      marginRight: 10,
    },
    cguContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'start',
    },
    emailContainer: {
      marginTop: 5,
    },
    phoneInputCustomStyle: {
      '&:focus': {
        border: `2px solid ${theme.palette.primary.main} !important`,
        boxShadow: 'none !important',
      },
    },
    textareaContainer: {},
  }),
);

export default useStyles;
