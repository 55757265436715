export const list = {
    'context': {
        'label': "LE CONTEXTE",
        'listParagraph': [
            `Votre employeur vous propose un contexte de travail dégradé par rapport à vos attentes et/ou vos aspirations.`,
            `Les relations avec votre hiérarchie et/ou vos collègues se sont détériorées ces dernières semaines ou ces derniers mois.`,
            `Votre contexte de travail n’a pas évolué
            depuis plusieurs années. Aucune possibilité
            d’évolution n’existe.`,
            `Votre employeur n’a plus confiance en vous et ne vous propose plus aucune évolution professionnelle.`,
            `Votre organisation ne se développe plus ou souffre de difficultés économiques. Cette organisation n’a pas d’avenir.`
        ]
    },
    'you': {
        'label': "VOUS",
        'listParagraph': [
            `Votre insatisfaction (ou votre mal-être)
          concerne plus le contexte de travail que
          votre travail en lui-même.`,
            `Pour vous, l’ambiance du travail est  primordiale.`,
            `Travailler dans une mauvaise ambiance vous rend malheureux(se) et inefficace.`,
            `Vous pensez que vos compétences ne sont
            pas pleinement reconnues ou sont mal
            utilisées par votre employeur actuel.`,
            `Vous n’avez plus confiance dans votre
            employeur qui vous a fait beaucoup de
            promesses non tenues.`,
            `Vous souhaitez évoluer et améliorer à
            terme votre niveau de responsabilité et de
            rémunération.`
        ]
    }
}


