/* eslint-disable react/no-array-index-key */
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const SkeletonComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Skeleton variant="text" width="30%" />
        <div className={classes.actions}>
          <Skeleton variant="rect" width={80} height={30} className={classes.chip} />
          <Skeleton variant="text" width="10%" />
          <IconButton size="small" className={classes.button}>
            <Skeleton variant="circle" width={24} height={24} />
          </IconButton>
          <IconButton size="small" className={classes.button}>
            <Skeleton variant="circle" width={24} height={24} />
          </IconButton>
          <IconButton size="small" className={classes.button}>
            <Skeleton variant="circle" width={24} height={24} />
          </IconButton>
        </div>
      </div>
      <Grid container={true} spacing={1} alignItems="center">
        <Grid item={true} xs={true}>
          <Skeleton variant="text" width="20%" />
        </Grid>
        <Grid item={true} xs={true}>
          <Skeleton variant="rect" width="100%" height={30} />
        </Grid>
      </Grid>
    </div>
  );
};

const SkeletonList = () => {
  return (
    <div>
      {[...Array(4)].map((_, index) => (
        <SkeletonComponent key={index} />
      ))}
    </div>
  );
};

export default SkeletonList;
