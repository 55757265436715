import { Typography } from '@material-ui/core';
import style from '../../../commonStyle';

const ScienceTechnologySuggestion = () => {
  const classes = style();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        🧭 TROIS VOIES D’ORIENTATION PRIVILÉGIÉES :
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Voici trois voies que tu pourrais emprunter pour atteindre tes objectifs en fonction de
          tes résultats scolaires :
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec d&apos;excellents résultats (moyenne ≥15) : classe préparatoire
              Mathspé MP/MP suivie par une grande école d&apos;ingénieur(e).
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec de bons résultats (moyenne ≥12) : école d&apos;ingénieur(e) en
              accès postbac (avec ou sans prépa intégré) ou licence universitaire scientifique.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec des résultats moyens (moyenne ≥10) : BUT de sciences (SGM, Génie
              bio...) ou BTS à dominante scientifique (aéronautique, biotechnologies, etc.).
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionQuote} component="p">
          « La science présente beaucoup de danger, mais il faut lutter contre ces dangers non pas
          par moins de science mais par davantage de science, une science qui puisse aussi créer sa
          propre éthique. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Jean d&apos;Ormesson
      </Typography>
    </div>
  );
};

export default ScienceTechnologySuggestion;
