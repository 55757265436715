/* eslint-disable no-shadow */
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import style from './style';

interface IItemPage {
  id: string;
  position?: number;
  gameData?: any;
}

const ItemPage: FC<IItemPage> = ({ id, position, gameData }) => {
  const classes = style();

  const history = useHistory();

  return (
    <div
      className={classes.container}
      onClick={() => {
        history.push(`/admin/game/add-page/serious/${position}`);
        sessionStorage.setItem('isEdit', 'true');
      }}
    >
      <Typography variant="h4" className={classes.title}>
        +
      </Typography>
    </div>
  );
};

export default ItemPage;
