/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { HistoryMajorTest, PublicLayout } from '@orientaction/components';
import withWidth from '@material-ui/core/withWidth';
import { useStyles } from './style';
import Content from './Content';
import FirstView from './FirstView';
import BtnCanceled from '../BtnCanceled';
import { fakeResult } from './context';

const MeaningValue = () => {
  const classe = useStyles();

  const myResult = fakeResult;
  const id = location.pathname.split('/')[2];

  return (
    <div className={classe.root}>
      <div className={classe.btnHistory}>
        <HistoryMajorTest
          testResults={myResult?.listHistory || []}
          idTest={+id}
          toRedirect="/resultat-meaning-value"
        />
      </div>

      <BtnCanceled id={myResult?.users_permissions_user} />

      <FirstView listSore={myResult?.resultat || []} />

      <Content listPrincipaux={myResult?.resultat || []} />
    </div>
  );
};

export default withWidth()(MeaningValue);
