// put style here
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247'
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    paddingXY: {
      paddingTop: '40px',
      paddingBottom: '30px',
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    bgGrey: {
      background: '#EDEEF2',
    },
    bgWhite: {
      background: 'white',
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    btnWhite: {
      backgroundColor: '#fff#',
      color: '#0E1247',
      marginTop: 0,
      marginRight: 10,
      borderRadius: 3,
      opacity: 1,
      border: '1px solid grey',
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      [theme.breakpoints.down('sm')]: {
        padding: "1vh",
        minHeight: 48,
        height: 48,
        width: 140,
        minWidth: 130,
      },
    },
    textHeader: {
      display: 'none',
      marginLeft: 0,
      '@media(min-width: 600px)': {
        display: 'block',
      },
    },
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      '@media(min-width: 600px)': {
      },
    },
    groupBtnAbsolute: {
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
    modalContent: {},
    modalTitle: {
      color: '#E63C3C',
      marginBottom: 30,
    },
    modalText: {
      fontSize: 14,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
    searchButton: {
      marginLeft: 20,
    },
    btn: {
      color: 'white',
      letterSpacing: 0,
      opacity: 1,
      background: '#0E1247',
      borderRadius: 3,
      textTransform: 'none',
      height: 46,
    },
  })
);
