/* eslint-disable no-use-before-define */
import useStyles from './style';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Paragrapth } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';

interface IYesNo {
    current: any;
}

const YesNo: FC<IYesNo> = ({ current }) => {

    const classes = useStyles();

    return (
        <div className={classes.border}>

            <Grid
                container={true}
                direction="row"
                className={classes.titlePage}
                alignItems="center"
            >

                <Grid
                    item={true}
                    xs={6}
                    className={classes.colorGreen}
                >
                    <div>
                        OUI
                    </div>
                </Grid>

                <Grid item={true} xs={6}>
                    <div>
                        NON
                    </div>
                </Grid>

            </Grid>

            <div className={classes.content}>

                <MarginContainer mormal={true}>
                    <Paragrapth align='justify'>
                        {current || ""}
                    </Paragrapth>
                </MarginContainer>

            </div>

        </div>
    );
};

export default YesNo;

