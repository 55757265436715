/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { useCategoryTest } from '@orientaction/hooks'
import update from 'immutability-helper'
import { FC, useCallback, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { Card } from './Card'
import { ItemTypes } from './ItemTypes'
import Box from './Box'
import { useStyles } from './style';

const style = {
    width: '100%',
}

interface IDragAndDrop {
    id_category: number;
}

const DragAndDrop: FC<IDragAndDrop> = ({ id_category }) => {

    const classe = useStyles();

    const { listTest, setListTest } = useCategoryTest();

    const [isUpdated, setIsUpdated] = useState(false);

    const findCard = (id: string) => {
        const card = listTest.filter((c) => `${c.id}` === id)[0]
        return {
            card,
            index: listTest.indexOf(card),
        }
    }

    const moveCard = useCallback((id: string, atIndex: number, parent: number, isHover: boolean) => {

        const { card, index } = findCard(id);

        const newArray = listTest.map((item: any) => {

            if (item.id === id && item.category !== parent) {
                return {
                    ...item,
                    category: parent,
                }
            }

            return item;

        });

        const historyCategory: any[] = card?.historyCategory || [];

        historyCategory.push({ parent });

        const newArray2 = update(newArray, {
            $splice: [
                [index, 1],
                [atIndex, 0, {
                    ...card,
                    category: parent,
                    historyCategory: historyCategory as any,
                    isHover: isHover as boolean
                }],
            ],
        });

        setListTest(newArray2);

    }, [listTest, setListTest]);

    const comfirmPlace = () => {

        const newList = listTest.map((x: any) => {
            return {
                ...x,
                historyCategory: [],
                isHover: false
            }
        });

        setListTest(newList);

    }

    const comfirmDrop = () => {
        setIsUpdated(true);
    }

    const [, drop] = useDrop(() => ({
        accept: ItemTypes.CARD,
        drop: comfirmDrop,
        canDrag: () => { console.log("error") }
    }));

    useMemo(() => {
        if (isUpdated) {
            comfirmPlace();
            setIsUpdated(false);
        }
    }, [listTest, isUpdated]);

    const updateCategoryEmpty = (id: number, category: number) => {

        const notContent: any[] = listTest
            .filter((item: any) => item.category !== category)
            .filter((item: any) => +item.id !== +id);

        const hasContent = listTest.filter((item: any) => item.category === category);

        const cardNow: any = listTest.find((item: any) => +item.id === +id);

        hasContent.push({
            ...cardNow,
            isHover: false,
            category,
        });

        if (cardNow.category !== category) {

            const newArray = notContent.concat(hasContent);

            const afterFormated = newArray.map((item: any) => { return { ...item, isHover: false } });

            setListTest(afterFormated);

        }

    }

    const listTestByCategory: any[] = listTest.filter((x: any) => x.category === id_category);

    return (
        <div>
            <Box
                name={id_category}
            >

                {
                    listTestByCategory
                        .length ? (
                        <div
                            ref={drop}
                            style={{ ...style }}
                        >
                            {listTestByCategory
                                .map((card: any, index: number) => (
                                    <Card
                                        key={card.id}
                                        id={`${card.id}`}
                                        data={card}
                                        moveCard={moveCard}
                                        findCard={findCard}
                                        category={id_category}
                                        isHover={!!card.isHover}
                                        index={index}
                                        updateCategoryEmpty={updateCategoryEmpty}
                                        comfirmDrop={comfirmDrop}
                                    />
                                ))}

                            <br
                                className={classe.both}
                            />

                        </div>

                    ) : (
                        <p className={classe.paragraph}> Il n’y a encore aucun test dans cette catégorie.</p>
                    )
                }

            </Box>


        </div>
    )

}

export default DragAndDrop;