/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import React, { FC, useEffect } from 'react';
import { useStyles } from './style';
import { TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

interface InInputSearch {
    search: (e: any) => void;
    onChange: (e: any) => void;
    placeholder: string;
    value?: string;
}

const InputSearch: FC<InInputSearch> = ({
    search, onChange, placeholder, value
}) => {

    const classes = useStyles();

    const handleFilterChange = (e: any) => {
        onChange(e);
    }

    const handleKeyUp = (e: any) => {
        onChange(e);
    }

    return (
        <>
            <TextField
                defaultValue={value}
                placeholder={placeholder}
                classes={{
                    root: classes.input,
                }}
                name="input-search"
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <>
                            <SearchIcon
                                onClick={search}
                                className={classes.iconButton}
                            />
                        </>
                    ),
                }}
                onChange={handleFilterChange}
                onKeyDown={handleKeyUp}
            />
        </>

    );
};

export default InputSearch;