import { adminUserAction } from '@orientaction/api-actions';
import { useDispatch, useSelector } from 'react-redux';

const useDrawer = () => {
  const dispatch = useDispatch();
  const isOpenDrawer: boolean = useSelector((state: any) => state.adminUser.openDrawer);
  const openDrawer = (isOpen: boolean) => {
    dispatch(adminUserAction.setOpenDrawer(isOpen));
  };
  return {
    openDrawer,
    isOpenDrawer,
  };
};

export default useDrawer;
