/* eslint-disable react/destructuring-assignment */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr, TitlePage } from '@orientaction/components';
import BlockWithBackground from '../../components/BlockWithBackground';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import { defaultData } from './constant';
import { useBook4 } from '@orientaction/hooks_book';

const Page41 = (props: any) => {
  const classes = useStyles();

  const { setSynthiesIsYouSkill, synthiesIsYouSkill } = useBook4();

  const handleChangeList = (group: any) => (e: any) => {

    const { name, value } = e.target;

    setSynthiesIsYouSkill({
      ...synthiesIsYouSkill,
      [group]: {
        ...synthiesIsYouSkill?.[group],
        [name]: value
      }
    });

  };

  return (
    <Page id={41} readOnly={false}>

      <TitlePage showSpanYellow={false}>
        LA SYNTHÈSE DE VOS COMPÉTENCES
      </TitlePage>
      <MarginContainer>

        {

          defaultData.map((item: any) => (

            <>

              <MarginContainer>

                <TitleNbr number={item.id} text={item.title} />

                <MarginContainer>
                  {
                    item.paragraph && (

                      <Paragrapth
                        align="justify"
                      >
                        {item.paragraph || ""}
                      </Paragrapth>

                    )
                  }
                </MarginContainer>

                <MarginContainer mormal={true}>

                  {
                    item.children && (
                      <>
                        <Grid
                          container={true}
                          spacing={1}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {
                            (item.children.length > 0) && item.children.map((children: any) => (
                              <>

                                <Grid item={true} xs={12} md={2}>

                                  <Paragrapth
                                    colorBlue={true}
                                    align="justify"
                                  >
                                    {children.label || ""}
                                  </Paragrapth>

                                </Grid>

                                <Grid item={true} xs={12} md={10}>

                                  <TextArea
                                    name={children.name}
                                    onChange={handleChangeList(item.id)}
                                    value={synthiesIsYouSkill?.[item.id]?.[children.name] || ""}
                                    litleTextField={true}
                                    readOnly={props.readOnly}
                                  />

                                </Grid>

                              </>
                            ))
                          }
                        </Grid>
                      </>
                    )
                  }

                </MarginContainer>

              </MarginContainer>

            </>

          ))

        }

      </MarginContainer>

      <MarginContainer>
        <BlockWithBackground overridecardBgGreyStyle={classes.overridecardBgGreyStyle}>
          <Paragrapth colorBlue={true} align="center" addBackground={true}>
            Maintenant que vous avez décrit votre expérience par écrit, entraînez-vous à l’oral.
          </Paragrapth>
        </BlockWithBackground>
      </MarginContainer>
    </Page>
  );
};

export default Page41;
