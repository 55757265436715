/* eslint-disable no-use-before-define */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useRef, useState } from 'react';
import ItemAdd from '../Admin/Game/ItemAdd';
import ItemPage from '../Admin/Game/ItemPage';

type Item = {
  id: string;

  content: string;
  title: string;
  url: string;
  isCover?: boolean;
  nbPage?: number;
  isSelected?: boolean;
  handleClick?: () => void;
  typeChoice?: string;
};

interface DraggableListProps {
  initialItems: Item[];
  idGame: string;
  handleIndex?: (index: number) => void;
  handlePageToEdit?: (page: any, i?: number) => void;
  pageSelected?: any;
  handleGamePage?: (item: Item[], index?: any) => void;
}

const DraggableList: React.FC<DraggableListProps> = ({
  initialItems,
  idGame,
  handleIndex,
  handlePageToEdit,
  pageSelected,
  handleGamePage,
}) => {
  const [items, setItems] = useState<Item[]>(initialItems);
  const dragItem = useRef<Item | null>(null);
  const dragOverItem = useRef<Item | null>(null);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    dragItem.current = item;
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    dragOverItem.current = item;
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    const newList = [...items];
    const draggedItemContent = dragItem.current!;
    const dragOverItemContent = dragOverItem.current!;
    const dragItemIndex = items.findIndex(item => item.id === draggedItemContent.id);
    const dragOverItemIndex = items.findIndex(item => item.id === dragOverItemContent.id);

    newList.splice(dragItemIndex, 1);
    newList.splice(dragOverItemIndex, 0, draggedItemContent);

    setItems(newList);
    if (handleGamePage) {
      handleGamePage(newList, dragOverItemIndex + 1);
    }
    dragItem.current = null;
    dragOverItem.current = null;
  };
  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const renderListDrag = () => {
    let interationNull = 0;
    return (
      <div>
        {items?.map((item, index) => {
          let interation = 0;
          if (!item.title) {
            interation = ++interationNull;
          }
          return (
            <div
              key={item.id}
              draggable={true}
              onDragStart={e => handleDragStart(e, item)}
              onDragEnter={e => handleDragEnter(e, item)}
              onDragEnd={handleDragEnd}
              style={{ backgroundColor: '#f0f0f0', cursor: 'move' }}
              onClick={e => e.stopPropagation()}>
              <ItemAdd id={idGame} position={index} />
              <ItemPage
                id={item.id}
                title={item.title ? item.title : `Page sans titre (${interation})`}
                url=""
                isCover={false}
                nbPage={index + 1}
                isSelected={pageSelected?.id === item.id}
                handleClick={() => {
                  if (handlePageToEdit) handlePageToEdit(item, interation);
                  if (handleIndex) handleIndex(index);
                }}
                isLastPage={item?.typeChoice === 'end_game'}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return renderListDrag();
};

export default DraggableList;
