/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line simple-import-sort/imports
import 'swiper/swiper.min.css';
import { Grid, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { LayoutLinearBar } from '@orientaction/layouts';
import clsx from 'clsx';
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import useTest from '../../hooks/useTest';
import useResponseUser from '../../hooks/useResponseUser';
import { useHistory, useParams } from 'react-router-dom';
import { getFromLS } from '../../utils/utils';
import { HelmetProvider } from 'react-helmet-async';
import HtmlReactParser from 'html-react-parser';
import { groupBy, sumBy, map, find } from 'lodash';
import { useStyles } from './style';
import DialogNotConnected from './Dialog';

const qs = require('qs');

const Test_Page = () => {
  const classes = useStyles();

  const [swiper1, setSwiper] = useState<any>();

  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const { getTest, test, setProgress, loadPage, getResponsesPopulate } = useTest();
  const { postResponseUsers } = useResponseUser();
  const [textNext, setTextNext] = useState('Question suivante');
  const [valueArray, setValueArray] = useState(['']);
  const [indexArray, setIndexArray] = useState(0);
  const [dataToPost, setDataToPost] = useState<any>([]);
  const user = JSON.parse(getFromLS('userInfos') || '{}');
  const [open, setOpen] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [protectedTag, setProtectedTag] = useState<any>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dataParams: any = useParams();
  useEffect(() => {
    (async () => {
      const filter = qs.stringify(
        {
          populate: {
            questions: {
              populate: {
                responses: {
                  populate: ['variable'],
                },
              },
            },
          },
        },
        {
          encodeValuesOnly: true, // prettify URL
        }
      );
      const testData = await getTest(dataParams.id, filter);
      setOpenBlock(testData?.attributes?.isDesabled || testData?.attributes?.isArchived);
      removeAttr(testData);
    })();
  }, []);

  // function slide event for getting the current swiper data
  const slideChange = (slide: any) => {
    setProgress(
      parseInt(slide.activeIndex, 10),
      parseInt(test.attributes.questions.data.length, 10)
    );
    setIndexArray(parseInt(slide.activeIndex, 10));
    if (
      parseInt(slide.activeIndex, 10) + 1 ===
      parseInt(test.attributes.questions.data.length, 10)
    ) {
      setTextNext('Voir mes résultats');
    } else {
      setTextNext('Question suivante');
    }
  };

  // init swiper for getting swiper data with initial value
  const initSwiper = (swiper: any) => {
    setProgress(
      parseInt(swiper.activeIndex, 10),
      parseInt(test.attributes ? test.attributes.questions.data.length : 0, 10)
    );
    setSwiper(swiper);
  };

  // function to go to the next swiper if it's not the end one
  const checkNextSwipe = async () => {
    if (
      valueArray[parseInt(swiper1.activeIndex, 10)] !== '' &&
      valueArray[parseInt(swiper1.activeIndex, 10)]
    ) {
      if (
        parseInt(swiper1.activeIndex, 10) + 1 ===
        parseInt(test.attributes.questions.data.length, 10)
      ) {
        // put here the function to post or store user's response
        const postedResponse = await postDataAfterCalculSum();
        // eslint-disable-next-line no-constant-condition
        if (!user.id) {
          setOpen(true);
        } else {
          removeAttr(protectedTag);
          history.push(`/congrats/${postedResponse.id}`);
        }
      } else {
        setQuestionNumber(questionNumber + 1);
        swiper1.slideNext();
      }
    }
  };

  const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newArray: any = [...valueArray];
    const val = (event.target as HTMLInputElement).value;
    newArray[indexArray] = val;
    setValueArray(newArray);
  };
  const removeAttr = (testData: any) => {
    const tags: any = document.getElementsByTagName('meta');
    setProtectedTag({
      attributes: {
        title: document.title,
        image: tags[5].content,
        description: tags.description.content,
      },
    });
    document.title = testData.attributes.title;
    tags.description.content = testData.attributes?.description;
    tags[5].content = testData.attributes?.image;
    tags[4].content = testData.attributes?.image;
  };

  useEffect(() => {
    const dataMapedToPost = {
      users_permissions_users: [`${user.id}`],
      responses: valueArray,
      test: test.id,
    };
    setDataToPost(dataMapedToPost);
  }, [valueArray, indexArray]);

  const handleClose = () => {
    setOpen(!open);
  };
  const redirectLogic = () => {
    removeAttr(protectedTag);
    if (user.id) {
      history.push('/main');
    } else {
      history.push('/');
    }
  };
  const goToSignupPage = () => {
    removeAttr(protectedTag);
    history.push('/signup?from=test');
  };
  const mapDataCalcul = async (array: any) => {
    // const data = array.map(async (responseId: string) => {
    //   const responsePopulate = await getResponsePopulate(responseId);
    //   return responsePopulate;
    // });
    const searchParams = qs.stringify(
      {
        filters: {
          id: {
            $in: array,
          },
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    const data: any = await getResponsesPopulate(searchParams);
    const dataGroupByVariableKey = groupBy(data.data.data, (resp: any) => {
      return resp.attributes.variable.data.attributes.variableKey;
    });
    const dataMapValue = map(dataGroupByVariableKey, (response: any) => {
      const sumVariable = sumBy(response, (resp: any) => resp.attributes.variableValue);
      const dataBeforeRemove = {
        somme: sumVariable,
        key: response[0].attributes.variable.data.attributes.variableKey,
      };
      return dataBeforeRemove;
    });

    return {
      ...dataToPost,
      variableA: find(dataMapValue, ['key', 'A'])?.somme || 0,
      variableB: find(dataMapValue, ['key', 'B'])?.somme || 0,
    };
  };
  const postDataAfterCalculSum = async () => {
    setLoading(true);
    const newDataToPost = await mapDataCalcul(dataToPost.responses);
    const postedResponse = await postResponseUsers(newDataToPost);
    setLoading(false);
    return postedResponse;
  };

  const handlePrevious = () => {
    setQuestionNumber(questionNumber - 1);
    swiper1.slidePrev();
  };

  return (
    <HelmetProvider>
      <LayoutLinearBar isGradient={true} isNew2={false}>
        {loadPage || loading ? (
          <div className={classes.bodycenter}>
            <CircularProgress style={{ color: 'white' }} />
          </div>
        ) : !openBlock ? (
          <div className={classes.paddingContent}>
            <Grid container={true}>
              <Grid
                item={true}
                xs={12}
                style={{ textAlign: 'center', color: '#FFFFFF', fontSize: '15px' }}>
                <h1 className={classes.title} style={{ fontFamily: 'Poppins' }}>
                  {' '}
                  <strong>Question {questionNumber}</strong>
                </h1>
              </Grid>
              <Grid
                className={classes.flexCenter}
                item={true}
                sm={3}
                style={{ textAlign: 'center' }}
              />
              <Grid item={true} xs={12} sm={6}>
                <Swiper
                  onSlideChange={slideChange}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSwiper={initSwiper}
                  allowTouchMove={false}>
                  {(test.attributes ? test.attributes.questions.data : []).map(
                    (question: any, id: number) => {
                      return (
                        <SwiperSlide key={id}>
                          <h1
                            className={classes.questionText}
                            dangerouslySetInnerHTML={{ __html: question.attributes.title }}
                          />
                          <FormControl component="fieldset" className={classes.FormControl}>
                            <Grid item={true} xs={12}>
                              <RadioGroup
                                aria-label="question"
                                value={valueArray[indexArray]}
                                onChange={changeValue}>
                                {(question ? question?.attributes?.responses?.data : []).map(
                                  (response: any, index: number) => {
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        value={response.id}
                                        className={classes.FormControlLabel}
                                        classes={{ label: classes.label }}
                                        checked={
                                          parseInt(valueArray[indexArray], 10) ===
                                          parseInt(response.id, 10)
                                        }
                                        control={
                                          <Radio
                                            color="default"
                                            checkedIcon={
                                              <span
                                                className={clsx(classes.icon, classes.checkedIcon)}
                                              />
                                            }
                                            icon={<span className={classes.icon} />}
                                          />
                                        }
                                        label={HtmlReactParser(response.attributes.title)}
                                      />
                                    );
                                  }
                                )}
                              </RadioGroup>
                            </Grid>
                          </FormControl>
                        </SwiperSlide>
                      );
                    }
                  )}
                </Swiper>
                <Grid
                  container={true}
                  direction="row"
                  justifyContent="center"
                  alignItems="baseline"
                  style={{ marginTop: '15px', marginBottom: '25px' }}
                  spacing={1}
                  xs={12}>
                  <Grid item={true} xs={6} sm={4} style={{ width: '100%', textAlign: 'left' }}>
                    {questionNumber !== 1 && (
                      <Button
                        startIcon={<ArrowBack />}
                        type="submit"
                        variant="outlined"
                        className={classes.button}
                        onClick={handlePrevious}>
                        Question précédente
                      </Button>
                    )}
                  </Grid>
                  <Grid item={true} xs={6} sm={4} style={{ width: '100%' }}>
                    <Button
                      endIcon={<ArrowForward />}
                      type="submit"
                      variant="outlined"
                      className={classes.button}
                      onClick={checkNextSwipe}>
                      {textNext}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                className={classes.flexCenter}
                item={true}
                sm={3}
                style={{ textAlign: 'center' }}
              />
            </Grid>
          </div>
        ) : (
          <div className={classes.flexCenterMessage}>
            <Grid container={true} direction="column" justifyContent="center" alignItems="center">
              <Grid item={true} xs={12}>
                <h3 className={classes.title} style={{ color: 'white' }}>
                  Nous sommes désolés mais ce test n’est plus disponible
                </h3>
              </Grid>
              <Grid item={true} xs={12}>
                <Button className={classes.btnPrimary} onClick={redirectLogic}>
                  <span className={classes.textHeader}>Aller vers Orient Action</span>
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        <DialogNotConnected open={open} handleClose={handleClose} goToSignupPage={goToSignupPage} />
      </LayoutLinearBar>
    </HelmetProvider>
  );
};
export default Test_Page;
