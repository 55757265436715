const INITIAL_STATE: any = {
  youCan: false,
  ref: null,
};

const stateStopRender = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'YOUCAN': {
      return {
        ...state,
        youCan: action.payload,
      };
    }
    case 'REF': {
      return {
        ...state,
        ref: action.payload,
      };
    }
    default:
      return state;
  }
};

export default stateStopRender;
