import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        head: {
            padding: "1vh 0",
            textAlign: 'center',
            borderBottom: '1px solid',
            background: '#e8eef4',
            fontSize: '2.75vh'
        },
        tableContainer: {
            border: '1px solid'
        },
        body: {
            padding: '1.5vh 2vh',
            minHeight: '50vh'
        },
        marginContent: {
            margin: '6vh 0',
            textAlign: 'center',
            fontSize: '2vh'
        }
    })
);

export default useStyles;

