/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page } from '@orientaction/components';
import { useState } from 'react';

const Page28 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={30}>
      <div style={{ marginBottom: '20px' }}>
        <div>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            La motivation, c’est l’énergie, l’envie, le désir : c’est le moteur de l’action. Quand vous 
            vous levez le matin pour aller au travail, c’est la motivation qui vous donne envie 
            d’être présent(e) et de donner le meilleur de vous-même. Quand vous rencontrez 
            des difficultés, que vous devez surmonter des échecs, c’est la motivation qui vous 
            donne la force et l’énergie nécessaires pour dépasser les obstacles.  
          </Typography>
          <Typography className={classes.paragraphe2} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'center', color: "#2b56a5", fontWeight: 'bold', fontStyle: 'italic' }}>
            <strong>
              Il est impossible de réussir et d’être heureux(se) dans son travail <br />
              sans être motivé(e). Alors pour choisir le bon job, découvrez vos motivations.
            </strong> 
          </Typography>
        </div>
        <div style={{ marginTop: '30px', marginBottom: '20px' }}>
          <Grid container={true} spacing={2} justifyContent="center">
            <Grid item={true} xs={8}>
              <img className={classes.img} src={`${imgBook2}/illustration_page_35.jpg`} alt="logo" />
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe2: {
      color: '#000A8C',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '15px',
      display: 'flex',
      width: 'calc(100% - 20px)',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#000A8C',
      fontStyle: 'bold',
      textAlign: 'left',
    },
  })
);
export default Page28;
