import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardDragDrop: {
            width: '30%',
            margin: '1vh',
            float: 'left',
            [theme.breakpoints.down(1130)]: {
                width: '45%',
            },
            [theme.breakpoints.down('xs')]: {
                float: 'none',
                width: '100%',
            }
        },
        root: {
            borderRadius: '10px',
            overflow: 'hidden',
            height: '144px',
            minHeight: '144px',
            maxHeight: '144px',
        },
        cardContainer: {
            borderLeft: '0.75vh solid #EDEEF2',
            width: '100%',
            padding: '5px 2.5px',
            borderRadius: '10px',
            boxShadow: 'none',
            backgroundColor: '#EDEEF2',
        },
        favorite: {
            boxSizing: 'border-box',
            border: '4px',
            borderLeftStyle: 'solid',
            borderImage: 'linear-gradient(to bottom, rgba(255, 220, 39, 1), rgba(230, 60, 60, 1),rgba(0, 10, 140, 1)) 1',
            gap: '16px',
            isolation: 'isolate',
        },
        iconDragDrop: {
            color: '#9799B1',
            cursor: 'move'
        },
        content: {
            padding: '1vh',
            borderRadius: '2vh',
        }
    })
);
