import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { publicSvgPath } from '../../utils/constants';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '100vh',
    overflow: 'auto',
  },
  cardContainer: {
    padding: '20px',
    borderRadius: '10px',
    boxShadow: 'none',
    backgroundColor: 'white',
    height: 'auto',
  },
  img: {
    width: '100%',
    height: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '103%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '103%',
    },
  },
  imgBan: {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  fontTitleCard: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '140%',
    color: '#0E1247',
  },
  fontTextCard: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#0E1247',
  },
  paddingContainer: {
    padding: 25,
    '@media only screen and (max-width: 600px)': {
      padding: 20,
    },
    '@media only screen and (max-width: 361px)': {
      padding: 20,
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  btnTest: {
    width: '100%',
    height: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 10,
    '@media only screen and (max-width: 600px)': {
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: 20,
      paddingRight: 20,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: '12px',
      paddingBottom: '12px',
      paddingLeft: 20,
      paddingRight: 20,
    },
    '& .MuiButton-label': {
      '@media only screen and (max-width: 600px)': {
        display: 'block !important',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'block !important',
      },
    },
  },
  iconBtn: {
    padding: 10,
    background: 'white',
    borderRadius: '50%',
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media only screen and (max-width: 600px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '10px',
    },
    '@media only screen and (max-width: 361px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '10px',
    },
  },
  link: {
    textDecoration: 'underline',
    color: '#000A8C',
  },
  paddingDesktop: {
    paddingTop: 15,
    paddingBottom: 15,
    '@media only screen and (max-width: 600px)': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  gridRow: {
    '@media only screen and (max-width: 600px)': {
      flexDirection: 'row !important',
    },
    '@media only screen and (max-width: 361px)': {
      flexDirection: 'row !important',
    },
  },
  labelBtn: {
    marginLeft: 20,
    color: '#000A8C',
    '@media only screen and (max-width: 600px)': {
      marginLeft: 0,
    },
    '@media only screen and (max-width: 361px)': {
      marginLeft: 0,
    },
  },
  yellowBtn: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/yellow.png)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/yellow_mobile.png)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/yellow_mobile.png)`,
    },
  },
  blueBtn: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/blue.png)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/blue_mobile.png)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/blue_mobile.png)`,
    },
  },
  redBtn: {
    backgroundImage: `url(${publicSvgPath}/backgroundBtn/red.png)`,
    '@media only screen and (max-width: 600px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/red_mobile.png)`,
    },
    '@media only screen and (max-width: 361px)': {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/red_mobile.png)`,
    },
  },
}));
