// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useStyles } from './style';
import { valueFormated } from '../formatValue';

interface ITableDouble {
  data: any[];
  header: any[];
  bgColor: string;
}

const TableDouble: FC<ITableDouble> = ({ data, header, bgColor }) => {
  const classes = useStyles();

  return (
    <div>
      {(header || []).map((item: any) => {
        return (
          <div className={classes.coulomContent}>
            <div className={classes.header} style={{ backgroundColor: bgColor }}>
              <div>{item.label}</div>

              <div className={classes.underHeader}>
                {(item.attr || []).map((x: any) => {
                  return <div className={classes.underHeaderContent}>{x.label}</div>;
                })}
              </div>
            </div>

            {(item.attr || []).map((attr: any) => {
              return (
                <div className={classes.underContent}>
                  {(data || []).map((y: any) => {
                    const isMaximumScore = y?.attributes?.maximumScoreKeys?.includes(
                      attr?.key?.split('attributes.')[1]
                    );
                    const value = isMaximumScore ? (
                      <strong> {valueFormated(y, attr.key, attr.option)} </strong>
                    ) : (
                      valueFormated(y, attr.key, attr.option)
                    );
                    return <div className={classes.content}>{value}</div>;
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TableDouble;
