/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { ArrowBackIos } from '@material-ui/icons';
import Timeline from '@material-ui/lab/Timeline';
import { HistoryMajorTest } from '@orientaction/components';
import { useAlert, useStudyTestResult } from '@orientaction/hooks';
import { publicIconPath } from '@orientaction/utils';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoveMatter from './LoveMatter';
import StudyStyle from './StudyStyle';
import style from './style';
import Content from './Timeline/Content';

interface IProps {
  isPublic?: boolean;
}

const pageTitle = "Tes résultats - Test d'orientation postbac Study®";

const StudyTestResult: FC<IProps> = ({ isPublic = false }) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<any>(undefined);
  const [linkCopied, setLinkCopied] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);

  const alert = useAlert();

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const classes = style();
  const { getMyResult, shareTestPublicly } = useStudyTestResult();

  const getResultat = async (idTest: number) => {
    setLoading(true);

    (window as any).scrollTo(0, 0);

    try {
      setLoading(true);
      const { data } = await getMyResult(+idTest);
      setResult(data.data);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        // return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    history.back();
  };

  const shareTest = async () => {
    try {
      setShareLoading(true);
      await shareTestPublicly(+id);
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_API_FRONT_URL}/mon-resultat-du-test-study/${id}`
      );
      setLinkCopied(true);
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setShareLoading(false);
    }
  };

  useEffect(() => {
    getResultat(+id);
  }, [id]);

  const studyStyleContainerTitle = `Ton Study® Style est <b>${result?.studyStyle?.favorite?.label}</b>`;
  const loveMatterContainerTitle = `Ta love matière ❤️ est <b>${result?.loveMatter?.favorite?.label}</b>`;

  return (
    <>
      {loading ? (
        <div className={classes.bodycenter}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div className={classes.root}>
          {!isPublic && (
            <Button
              onClick={goBack}
              startIcon={<ArrowBackIos />}
              variant="outlined"
              className={classes.button}>
              Retour
            </Button>
          )}
          {!isPublic && (
            <div className={classes.btnHistory}>
              <HistoryMajorTest
                testResults={result.listHistory || []}
                idTest={+id}
                toRedirect="/resultat-study"
              />
            </div>
          )}
          <Typography className={classes.studyRestitutionPageTitle} component="p">
            {pageTitle}
          </Typography>
          <Timeline className={classes.timelineAlignLeft}>
            <Content title={studyStyleContainerTitle} to="">
              <div className={classes.marginBottom}>
                <StudyStyle data={result.studyStyle} />
              </div>
            </Content>
            <Content
              overrideChildrenStyle={classes.overrideLoveMatterStyle}
              title={loveMatterContainerTitle}
              to="">
              <LoveMatter data={result.loveMatter} />
            </Content>
          </Timeline>
          {!isPublic && (
            <div className={classes.studyRestitutionShareButtonContainer}>
              <Button
                disabled={shareLoading}
                endIcon={
                  linkCopied ? undefined : !shareLoading ? (
                    <img src={`${publicIconPath}/ContentCopy.svg`} alt="Copy icon" />
                  ) : (
                    <CircularProgress color="inherit" size={16} />
                  )
                }
                variant="outlined"
                className={classes.studyRestitutionShareButton}
                onClick={shareTest}>
                {!linkCopied ? 'Partager vos résultats' : 'Lien de partage copié !'}
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default withWidth()(StudyTestResult);
