/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import { MarginContainer } from '@orientaction/commons';
import { Page } from '@orientaction/components';
import { publicSvgPath } from '@orientaction/utils';
import { list1, list2 } from './context';
import useStyles from './style';

const Page02 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={2}>
      <div className={classes.textCenterTop}>
        <Typography variant="h6" component="h5">
          EMERIC LEBRETON
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom={true}>
          DOCTEUR EN PSYCHOLOGIE
        </Typography>
      </div>
      <div className="header__right">
        <div className="header__content bg--gradient">
          <div className="header__content-text">
            <h1 className="header__content-title">
              <span className="header__content-wrap">
                <span className="header__content-value">
                  <span className="decoration decoration--blue">
                    <img
                      src="/images/Book1/quote-blue.png"
                      alt="Quote blue"
                      className="quote-blue"
                    />
                  </span>
                  <span className="number">5</span>
                </span>

                <span className="header__content-desc">
                  <span className="title">Étapes pour réussir</span>
                  <span className="subtitle">Votre évolution professionnelle </span>
                </span>

                <span className="decoration decoration--yellow">
                  <img
                    src="/images/Book1/quote-yellow.png"
                    alt="Quote yellow"
                    className="quote-yellow"
                  />
                </span>
              </span>
            </h1>
          </div>
        </div>
      </div>

      <MarginContainer big={true}>
        <div>
          {list1.map((item: any) => (
            <Grid container={true} direction="row" justifyContent="space-around">
              <Grid item={true} xs={11}>
                <Typography
                  color="textSecondary"
                  style={{
                    fontWeight: 'lighter',
                    fontSize: '1.125rem',
                  }}
                >
                  {item.label1} : {item.label2}
                </Typography>
              </Grid>

              <Grid item={true} xs={1}>
                <CheckIcon
                  style={{
                    fontSize: '15px',
                    border: '1px solid grey',
                    borderRadius: '2px',
                    color: 'grey',
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </div>
      </MarginContainer>

      <MarginContainer big={true}>
        <div style={{ marginTop: '75px', marginBottom: '20px' }}>
          <div>
            <Typography
              variant="h1"
              component="h1"
              style={{ marginBottom: '10px', color: '#2b56a5', fontSize: '35px' }}
            >
              <img
                src={`${publicSvgPath}/point.png`}
                alt="Chapitre title"
                className={classes.imgFloat}
              />
              ÉTAPE 3 :
            </Typography>
            <Typography
              variant="h1"
              component="h1"
              color="primary"
              gutterBottom={true}
              className="opacity07"
              style={{ color: '#2B56A5' }}
            >
              EXPLORER TOUS LES POSSIBLES
            </Typography>
          </div>

          <div className={classes.trait} />

          <div style={{ marginTop: '50px' }}>
            {list2.map((item: any) => (
              <Typography
                variant="h2"
                component="h2"
                color="primary"
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  marginBottom: '10px',
                  textAlign: 'justify',
                }}
              >
                <strong className={classes.fontWeightBold}>{item.label1}</strong> : {item.label2}
              </Typography>
            ))}
          </div>
        </div>
      </MarginContainer>

      <div style={{ marginBottom: '100px', marginTop: '100px' }}>
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-justify-content-xs-center">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7">
            <div className="book__footer--image bg--gradient--other mb-5">
              <img
                className="jss150"
                src="/images/Book1/logo_oa_dark_blue_no_text_under.png"
                alt="logo"
              />
            </div>
            <p className="text-center text-blue">LEADER DU BILAN DE COMPÉTENCES</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Page02;
