import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import style from './style';

const ApplicationBlock = () => {
  const classes = style();
  return (
    <Grid className={classes.applicationBlockContainer} container={true} spacing={2}>
      <Grid
        className={classes.applicationBlockContainerFirstItem}
        item={true}
        md={6}
        lg={6}
        sm={12}
        xs={12}
      >
        <Grid direction="column" className={classes.content} container={true} xs={12}>
          <Typography variant="h6" color="primary" className={`${classes.title}`}>
            APPLICATIONS CONCRÈTES
          </Typography>

          <Typography variant="h6" color="primary" className={`${classes.title}`}>
            DANS VOTRE VIE PROFESSIONNELLE
          </Typography>

          <Typography
            variant="body2"
            color="primary"
            className={clsx(classes.description, classes.paragraphMarginTop)}
            dangerouslySetInnerHTML={{
              __html: ` Si vous ne vous sentez pas pleinement satisfait(e) dans votre vie professionnelle, c’est
            sans doute parce que certains de vos besoins ne sont pas satisfaits. <br/>Dans le domaine
            professionnel, cela peut être d’avoir une rémunération que vous jugez insuffisante,
            d’avoir des relations de mauvaise qualité avec vos collègues ou encore de ne pas avoir
            un poste suffisamment stimulant par rapport à vos attentes.`,
            }}
          />

          <Typography
            variant="body2"
            color="primary"
            className={clsx(classes.description, classes.paragraphMarginTop)}
          >
            Tous ces éléments risquent d’entraîner de la démotivation. Au contraire, si vos besoins
            sont satisfaits, alors vous vous sentirez pleinement motivé(e) par votre travail. Voilà
            pourquoi il faut être à l’écoute de vos besoins et être capable de les expliciter à
            votre manager. Cela lui permettra de tenter d’y répondre. Vous vous sentirez compris(e)
            et vous aurez le sentiment de progresser…
          </Typography>
        </Grid>
      </Grid>
      <Grid
        className={classes.applicationBlockContainerSecondItem}
        item={true}
        md={6}
        lg={6}
        sm={12}
        xs={12}
      >
        <Grid direction="column" className={classes.content} container={true} xs={12}>
          <Typography variant="h6" color="primary" className={`${classes.title}`}>
            APPLICATIONS CONCRÈTES
          </Typography>

          <Typography variant="h6" color="primary" className={`${classes.title}`}>
            DANS VOTRE VIE PERSONNELLE{' '}
          </Typography>

          <Typography
            variant="body2"
            color="primary"
            className={clsx(classes.description, classes.paragraphMarginTop)}
          >
            Si vous ne vous sentez pas pleinement épanoui(e) dans votre vie personnelle, c’est sans
            doute parce que certains de vos besoins demeurent insatisfaits. Lorsque l’un de nos
            besoins demeure insatisfait, nous nous sentons frustrés. Cette frustration se manifeste
            par une certaine contrariété, des plaintes, voire de la colère qui peut être à la source
            de tensions, voire de véritables disputes avec vos proches. Au contraire, si vos besoins
            sont satisfaits, alors vos relations s’en trouveront renforcées. Voilà pourquoi il faut
            être à l’écoute de ses besoins et être capable de les expliciter à ses proches. Cela
            leur permettra de mieux y répondre et vous vous sentirez beaucoup mieux et plus
            épanoui(e) dans vos relations interpersonnelles. Satisfaire ses besoins est l’une des
            clefs du bonheur.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApplicationBlock;
