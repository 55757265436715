/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { kebabCase } from 'lodash';

declare const window: any;
// eslint-disable-next-line no-use-before-define

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderGradient: {
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      padding: '1.5px',
      borderRadius: '5px',
    },
    fontButton: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      color: '#0E1247',
      textTransform: 'capitalize',
    },
    buttonStyle: {
      background: 'white',
      width: '100%',
      paddingLeft: '50px',
      paddingRight: '50px',
      '&:hover': {
        color: 'white',
      },
      '&:hover span': {
        color: 'white',
      },
    },
    marginIcon: {
      marginRight: '5px',
    },
    styleInput: {
      border: '1px solid #CDCEDA',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
  })
);
const Modal = (props: any) => {
  const classes = useStyles();

  const handleClose = () => {
    props.callBack(false);
  };

  const testUrl = `${process.env.REACT_APP_SHARE_ENDPOINT}/api/test/${props.testId}/${kebabCase(
    props.title
  )}`;

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          style={{ position: 'absolute', top: '5px', right: '5px' }}
          onClick={handleClose}>
          <ClearIcon fontSize="small" />
        </IconButton>
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '24px' }}>
          Partager
        </DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={4}>
              <div className={classes.borderGradient}>
                <FacebookShareButton
                  url={testUrl}
                  quote={props.title}
                  hashtag="#test"
                  style={{ width: '100%' }}>
                  <Button color="primary" className={classes.buttonStyle}>
                    <FacebookIcon className={classes.marginIcon} />
                    <span className={classes.fontButton}>Facebook</span>
                  </Button>
                </FacebookShareButton>
              </div>
            </Grid>
            <Grid item={true} xs={4}>
              <div className={classes.borderGradient}>
                <LinkedinShareButton url={testUrl} title={props.title} style={{ width: '100%' }}>
                  <Button color="primary" className={classes.buttonStyle}>
                    <LinkedInIcon className={classes.marginIcon} />
                    <span className={classes.fontButton}>Linkedin</span>
                  </Button>
                </LinkedinShareButton>
              </div>
            </Grid>
            <Grid item={true} xs={4}>
              <div className={classes.borderGradient}>
                <TwitterShareButton url={testUrl} title={props.title} style={{ width: '100%' }}>
                  <Button color="primary" className={classes.buttonStyle}>
                    <TwitterIcon className={classes.marginIcon} />
                    <span className={classes.fontButton}>Twitter</span>
                  </Button>
                </TwitterShareButton>
              </div>
            </Grid>
          </Grid>
          <div style={{ paddingTop: '20px', paddingBottom: '10px' }}>
            <FormControl
              className={classes.styleInput}
              variant="outlined"
              style={{ width: '100%' }}>
              <OutlinedInput
                id="outlined-adornment-password"
                value={props.link}
                readOnly={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => {
                        navigator.clipboard.writeText(props.link);
                      }}>
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Modal;
