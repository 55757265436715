/* eslint-disable jsx-a11y/label-has-associated-control */
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import { UserInitials } from '@orientaction/commons';
import { useUpload } from '@orientaction/hooks';
import { FC, useContext } from 'react';
import { UserProfilePhotoContext } from '../../Context/UserProfilePhotoContext';
import style from './style';

interface IProps {
  user: any;
  currentUser: any;
  setUser: any;
  editable?: boolean;
  loading: boolean;
}

const UserProfilePhoto: FC<IProps> = ({
  user,
  currentUser,
  setUser,
  editable = false,
  loading,
}) => {
  const cls = style();
  const { userProfilePhoto, updateUserProfilePhoto } = useContext<any>(UserProfilePhotoContext);
  const { uploadToStore } = useUpload({ user, setUser, updateUserProfilePhoto });

  const handleModify = (e: any) => {
    const acceptedFiles = e.target.files;
    uploadToStore(acceptedFiles, `profiles/${user.id}`);
  };

  const userPhotoUri = user?.id === currentUser?.id ? userProfilePhoto : user?.photoUri;

  return (
    <div className={cls.container}>

      {loading ? (
        <>
          <Skeleton variant="circle" className={cls.imageContainer} />
        </>
      ) : (
        <>

          {userPhotoUri?.length > 0 ? (
            <img src={userPhotoUri} className={cls.imageContainer} alt="profile" />
          ) : (
            <UserInitials
              lastname={user ? user?.lastname : ''}
              firstname={user ? user?.firstname : ''}
              overrideClassname={cls.imageContainer}
            />
          )}

          {editable && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                accept="image/*"
                className={cls.input}
                id="contained-button-file"
                multiple={false}
                type="file"
                onChange={handleModify}
              />
              <label htmlFor="contained-button-file">
                <Button className={cls.modifyButton} startIcon={<EditIcon />} component="span">
                  Modifier
                </Button>
              </label>
            </div>
          )}

        </>
      )}
    </div>
  );
};

export default UserProfilePhoto;
