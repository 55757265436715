import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonCenter: {
    display: 'block',
    margin: 'auto',
  },
  text: {
    margin: 'auto',
    color: '#2B56A5',
    textAlign: 'center',
    font: 'normal normal bold 29px/48px ITC Avant Garde Gothic Std Medium',
    letterSpacing: 0,
    opacity: 1,
    fontSize: 30,
    '@media only screen and (max-width: 600px)': {
      fontSize: 22,
    },
    '@media only screen and (max-width: 361px)': {
      fontSize: 20,
    },
  },
  fixed: {
    backgroundColor: "#2B56A5",
    borderRadius: "50px",
    display: 'block',
    margin: 'auto',
    marginTop: '30px',
  },
}));
