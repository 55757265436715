import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  subtitle: {
    color: '#000A8C',
    fontSize: 26,
    marginTop: 15,
  },
  visibilityPermission: {
    width: '100%',
  },
  select: {
    fontFamily: 'Poppins',
  },
  inputError: {
    border: `2px solid red`,
    borderRadius: '4px'
  },
  textError: {
    color: 'red'
  },
  noBorder: {
    border: 'none !important',
    padding: 0,
  }
}));
