/* eslint-disable react/no-array-index-key */
import { Button, Typography } from '@material-ui/core';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import NoResponse from './NoResponse';
import ResponseItem from './ResponseItem';
import style from './style';

const ResponsesSection = ({ responses }: any) => {
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const handleClick = () => {
    updateTest({
      responseDrawerOpen: true,
      questionToCreate: {
        ...test.questionToCreate,
      },
    });
  };

  return (
    <>
      <div>
        <Typography className={classes.responsetitle} component="h3">
          RÉPONSES
        </Typography>
      </div>

      {responses?.length ? (
        <div className={classes.responsesContainer}>
          {responses
            .sort((a: any, b: any) => a.order - b.order)
            .map((response: any, index: number) => (
              <div key={index} className={classes.itemContainer}>
                <ResponseItem index={index} response={response} />
              </div>
            ))}
        </div>
      ) : (
        <NoResponse />
      )}

      <div className={classes.buttonContainer}>
        <Button variant="outlined" className={classes.button} onClick={handleClick}>
          Ajouter une réponse
        </Button>
      </div>
    </>
  );
};

export default ResponsesSection;
