export const fakeResult = {
  users_permissions_user: 42369,
  resultat: [
    {
      score: 105,
      key: 'universalism',
      label: 'Universalisme',
      img: '/images/Sens/universalism.png',
      description: [
        'Le principe directeur de votre vie : changer le monde !',
        'Vous voulez changer le monde. Vous êtes un(e) idéaliste, un(e) utopiste, un(e) visionnaire.',
      ],
      color: '#ED6637',
    },
    {
      score: 102,
      key: 'autonomy',
      label: 'Autonomie',
      img: '/images/Sens/autonomy.png',
      description: [
        'Le principe directeur de votre vie : une quête de liberté !',
        'La liberté de penser et d’agir, voilà ce que vous recherchez par-dessus tout. C’est un besoin vital.',
      ],
      color: '#F7A82E',
    },
    {
      score: 101,
      key: 'stimulation',
      label: 'Stimulation',
      img: '/images/Sens/stimulation.png',
      description: [
        'Le principe directeur de votre vie : des défis, toujours des défis !',
        'Pour vous, la vie est une aventure ! Ce qui vous motive et vous stimule, ce sont les défis.',
      ],
      color: '#FDCE29',
    },
  ],
  listHistory: [
    {
      id: 453,
      createdAt: '2023-04-27T04:12:10.368Z',
    },
    {
      id: 307,
      createdAt: '2023-03-08T07:06:16.425Z',
    },
    {
      id: 20,
      createdAt: '2023-03-03T06:23:08.686Z',
    },
    {
      id: 19,
      createdAt: '2023-03-02T13:35:00.179Z',
    },
    {
      id: 1,
      createdAt: '2023-02-08T03:40:04.374Z',
    },
  ],
};
