import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    titlePage: {
        fontSize: 20,
        fontWeight: 600,
        color: '#2B56A5',
        textAlign: "center",
        borderBottom: '1px solid',
        backgroundColor: '#e8eef4',
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    paddingTitle: {
        padding: '1.5vh 0',
    },
    border: {
        border: '1px solid',
    },
    content: {
        width: '100%',
        margin: '0 auto',
        minHeight: '8vh',
    },
    textContent: {
        width: '90%',
        margin: '0 auto',
        minHeight: '8vh',
        display: 'flex',
        alignItems: "center",
    },
    notActived: {
        width: '100%',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        alignContent: 'center',
        borderLeft: '0.05px solid',
        [theme.breakpoints.down('xs')]: {
            borderTop: '0.05px solid',
            padding: '1vh 0'
        },
    },
    actived: {
        backgroundColor: '#e8eef4',
        //    color: 'white'
    },
    contentArray: {
        borderBottom: '1px solid',
        [theme.breakpoints.down('sm')]: {
            overflow: 'none',
        },
    }
}));
