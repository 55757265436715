import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
    },
    testimony: {
      textAlign: 'center',
      fontSize: '17px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      },
    },
    testimony2: {
      textAlign: 'center',
      color: 'black',
      fontSize: '14px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    cardBgGrey1: {
      textAlign: 'center',
      border: '1px solid #2b56a5',
      fontStyle: 'normal',
      padding: 5,
      background: '#e8eef4',
      '@media only screen and (max-width: 600px)': {
        padding: 5,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 5,
      },
    },
    card1: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #2b56a5',
      fontStyle: 'normal',
      justifyContent: 'center',
      background: 'white',
      '@media only screen and (max-width: 600px)': {
        padding: 5,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 5,
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    content: {
      width: '95%',
      margin: '1vh auto',
      [theme.breakpoints.down('xs')]: {
        width: '85%',
        margin: '1vh auto',
      },
    },
    paragraph: {
      color: 'black',
      fontSize: 14,
      textAlign: 'start',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
    textPositionPerso: {
      position: 'absolute',
      color: '#2B56A5',
      top: '20%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    textPositionNeed: {
      position: 'absolute',
      color: '#2B56A5',
      top: '62%',
      left: '25%',
      transform: 'translateX(-50%)',
    },
    textPositionSens: {
      position: 'absolute',
      color: '#2B56A5',
      top: '62%',
      left: '75%',
      transform: 'translateX(-50%)',
    },
    textStyleInside: {
      fontSize: '1.6rem',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.7rem',
      },
    },
  })
);
