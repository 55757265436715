/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, Title } from '@orientaction/components';
import { imgBook4 } from '@orientaction/utils';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import useStyles from './style';

const Page27 = () => {
  const classes = useStyles();

  return (
    <Page id={27}>
      <Title
        title="LE CAPITAL SYMBOLIQUE"
        firstTitle="‘‘ La bonne réputation est un collier de perles. ’’"
        firtsecondTitle="Proverbe arménien"
        secondTitle="‘‘Les deux choses les plus importantes n’apparaissent pas
        au bilan de l’entreprise : sa réputation et ses hommes. ’’"
        secondSousTitle="Henry Ford"
      />

      <MarginContainer>
        <Paragrapth align="justify">
          Le capital symbolique est sans doute, de tous les capitaux, le plus difficile à
          quantifier. Il correspond à votre rayonnement personnel. Travailler dans une grande
          entreprise plutôt que dans une TPE/PME, avoir obtenu un diplôme dans une grande université
          parisienne comme la Sorbonne plutôt que dans une université de province, exercer un métier
          dans l’aéronautique plutôt que dans la mécanique vous confèrera forcément un surplus de
          capital symbolique, voire un certain prestige, suscitant la curiosité et l’intérêt autour
          de vous.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Ce capital peut être d’une aide précieuse au moment de rechercher un nouvel emploi ou de
          créer/reprendre une entreprise. Il apporte un bonus de crédibilité. Il donne une image
          favorable de vous. Dit autrement, la réputation (le capital symbolique) d’une école, d’une
          entreprise, d’une association, d’une administration ou d’une personne que vous connaissez
          vous précède. Si vous décidez de la mettre en avant, alors vous bénéficierez
          automatiquement de cette bonne réputation et de cette image de vous-même.
        </Paragrapth>
      </MarginContainer>
      <div className={classes.cardBgGrey} style={{ marginTop: '40px', marginBottom: '30px' }}>
        <div style={{ marginBottom: '10px' }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook4}/epaulette.png`} alt="logo" /> */}
          <SimpleBanner
            bannerContainerOverride={classes.bannerContainerOverride}
            bannerStyleOverride={classes.bannerStyleOverride}
            childrenContainerOverride={classes.childrenContainerOverride}
          >
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              L’EFFET D’ÉPAULETTE
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook4}/army.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le capital symbolique peut être comparé aux galons que l’on trouve sur les épaulettes des
          officiers.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          En fonction de vos expériences professionnelles et/ou personnelles, vous ajoutez des
          galons qui augmentent d’autant votre rayonnement auprès de vos interlocuteurs(trices).
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Tout le monde a des galons sur ses « épaulettes… ». À vous de choisir ceux que vous mettez
          en avant lorsque vous vous présentez.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          D’où l’intérêt de réfléchir à la manière dont vous pourriez vous présenter sous un jour
          favorable à vos interlocuteurs(trices).
        </Typography>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          Est-ce que le capital symbolique s’obtient uniquement en préparant un diplôme dans une
          école prestigieuse, en travaillant dans une grande entreprise, une grande association, une
          grande administration ou en ayant des parents illustres ?
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">Absolument pas !</Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Les exemples donnés au départ ne le sont que pour illustrer la force du capital
          symbolique. En réalité, le capital symbolique est partout. Nom d’école, d’entreprise,
          d’association, d’administration, nom des personnes appartenant à votre cercle
          professionnel, amical ou familial, mais aussi nom des expériences personnelles que vous
          avez pu faire dans votre vie comme des voyages, des exploits sportifs petits ou grands,
          des loisirs…{' '}
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

export default Page27;
