export const listForm = [
    {
        name: 'capitalEconomy',
        title: 'Capital économique (cf. argent, patrimoine) :',
    },
    {
        name: 'capitalSocial',
        title: 'Capital social (cf. relations, réseaux) :',
    },
    {
        name: 'capitalCulturel',
        title: 'Capital culturel (cf. vos connaissances) :',
    },
    {
        name: 'capitalSymbole',
        title: 'Capital symbolique (cf. réputation de votre école, de votre entreprise) :',
    },
]