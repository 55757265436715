import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Explanation from './Explanation';
import style from './style';
import Suggestion from './Suggestion';

const LoveMatter = (props: any) => {
  const classes = style();

  return (
    <Paper className={classes.loveMatterRestitutionContainer} variant="outlined">
      <Grid container={true}>
        <Grid item={true} md={12}>
          <Explanation data={props} />
        </Grid>
        <Grid className={classes.suggestionContainer} item={true} md={12}>
          <Suggestion {...props} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoveMatter;
