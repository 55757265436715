/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, Paragrapth, TitlePage } from '@orientaction/components';
import { useMemo, useState } from 'react';
import useStyles from '../../style';
import { Link } from 'react-router-dom';
import { useLanguage } from '@orientaction/hooks';

const Page15 = (props: any) => {
  const classes = usesStyles();
  const classe = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_NEEDSPRO.png' : 'NEEDSPRO.png';
  }, [language]);
  return (
    <Page id={15}>
      <div style={{ marginBottom: '40px', marginTop: '20px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Chacune de ces grandes figures d’autorité peut être reliée à un besoin présent dans la
          pyramide des besoins. Dit autrement, chacune de ces figures est plus qu’une autre capable
          de répondre à un besoin particulier. Déterminez quel est votre besoin premier et avec quel
          type de chef(fe) vous aimeriez travailler au quotidien.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Demandez ensuite à votre consultant(e){' '}
          <Paragrapth align="justify" colorBlue={true}>
            <strong>ORIENTACTION </strong>
          </Paragrapth>{' '}
          de vous présenter les différentes figures du chef. Et surtout à l’avenir, soyez
          attentif(ve) au type de manager(euse) avec qui vous serez amené(e) à travailler.
        </Typography>
      </div>
      <TitlePage>
        PASSEZ MAINTENANT LE TEST <br />
        DES 7 BESOINS PROFESSIONNELS NEEDS PRO®
      </TitlePage>
      <div style={{ marginBottom: '20px' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={11}>
            <div className={classe.cardImageBtn}>
              <img
                src={`${imgBook2}/${image}`}
                alt="couverture intro"
                className={classe.imageBtn}
              />
              <Link
                to={language === 'GB' ? '/en/tests/needs-pro' : '/tests/needs-pro'}
                target="_blank"
                rel="noopener noreferrer"
                className={classe.btnInside}
                style={{ bottom: '12%' }}>
                Clique
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const usesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page15;
