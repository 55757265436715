/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, TitleRomain } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';
import TextArea from '@orientaction/commons/TextArea';

const Page23a2 = (props: any) => {
  const classes = useStyles();
  const {
    setResponseCondition5,
    setResponseCondition6,
    setResponseCondition7,
    responseCondition5,
    responseCondition6,
    responseCondition7,
  } = useBook2();
  const [responseCondition5_, setResponseCondition5_] = useState<any>(responseCondition5);
  const [responseCondition6_, setResponseCondition6_] = useState<any>(responseCondition6);
  const [responseCondition7_, setResponseCondition7_] = useState<any>(responseCondition7);
  const question1 = [
    {
      name: '',
      text: 'Transport en commun (train, tramway, bus)',
    },
    {
      name: '',
      text: 'Véhicule personnel (auto ou moto)',
    },
    {
      name: '',
      text: 'Micromobilité (vélo, trottinette, monocycle, etc.)',
    },
    {
      name: '',
      text: 'À pied',
    },
  ];
  const question2 = [
    {
      name: '',
      text: 'Voiture de fonction ou de société',
    },
    {
      name: '',
      text: 'Ordinateur portable',
    },
    {
      name: '',
      text: 'Téléphone portable',
    },
    {
      name: '',
      text: 'Autres (précisez) :',
    },
  ];
  const question3 = [
    {
      name: '',
      text: 'Vous êtes une personne plutôt ouverte à faire des heures supplémentaires quand cela est nécessaire.',
    },
    {
      name: '',
      text: 'Ce n’est pas possible pour vous. Vous ne préférez pas.',
    },
  ];

  const handleChange = (e: any, index: number) => {
    const { name, checked } = e.target;
    if (index === 1) {
      setResponseCondition5_({ [name]: checked });
      setResponseCondition5({ [name]: checked });
    } else if (index === 2) {
      setResponseCondition6_({ ...responseCondition6_, [name]: checked });
      setResponseCondition6({ ...responseCondition6_, [name]: checked });
    } else if (index === 3) {
      setResponseCondition7_({ [name]: checked });
      setResponseCondition7({ [name]: checked });
    }
  };
  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    if (value !== '') {
      setResponseCondition6_({ ...responseCondition6_, [name]: value });
      setResponseCondition6({ ...responseCondition6_, [name]: value });
    }
  };

  const formatageText = (text: any) => {
    if (text === true || text === false) {
      return '';
    }
    return text;
  };

  return (
    <Page id={25} readOnly={props.readOnly}>
      <div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="Comment vous déplacez-vous généralement pour vous rendre au travail ?"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question1.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 1)}
                            checked={!!responseCondition5_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            text="En complément de votre rémunération, trouvez-vous indispensable de bénéficier des
            avantages suivants (cochez les cases correspondantes) :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question2.map((elem: any) => {
                    return (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              name={elem.text}
                              onChange={(e: any) => handleChange(e, 2)}
                              checked={!!responseCondition6_[elem.text]}
                            />
                          }
                          label={<div style={{ color: 'black' }}>{elem.text}</div>}
                        />
                        {elem.text === 'Autres (précisez) :' &&
                          !!responseCondition6_[elem.text] && (
                            <TextArea
                              name={elem.text}
                              value={formatageText(responseCondition6_[elem.text])}
                              onChange={handleChangeText}
                            />
                          )}
                      </div>
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr text="Au niveau des heures supplémentaires :" isSmall={true} />
          <div style={{ marginTop: '10px' }}>
            <div>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question3.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 3)}
                            checked={!!responseCondition7_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page23a2;
