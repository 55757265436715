import clsx from 'clsx';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Scrollbar from '../../commons/Scrollbar';
import { publicSvgPath } from '../../utils/constants';
import { getFromLS } from '../../utils/utils';
import style from './style';
import Grid from '@material-ui/core/Grid';

const LayoutTestSoftSkills = ({ children, isGradient, noPadding }: any) => {
  const history = useHistory();
  const cls = style();

  return (
    <div className={cls.layout}>
      <div
        className={clsx(
          cls.content,
          isGradient ? cls.gradientBG : null,
          noPadding ? cls.noPadding : null
        )}>
        <Scrollbar>{children}</Scrollbar>
      </div>
    </div>
  );
};

export default LayoutTestSoftSkills;
