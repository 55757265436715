export const context = [
    {
        title: "CHOISISSEZ VOTRE ATTITUDE",
        id: 1,
        listContext: [
            `En choisissant une attitude positive, en vous concentrant sur chaque point positif
            de votre vie, vous pouvez changer votre façon de voir les choses. Certes, votre travail
            est ennuyeux, difficile ou stressant, mais en même temps il comporte des aspects
            positifs. Peut-être vous permet-il d’avoir des temps de loisirs, peut-être est-il bien
            rémunéré (relativement), peut-être avez-vous des collègues sympathiques avec qui
            vous vous entendez bien ? Il y a forcément du positif. Il vous faut maintenant vous
            concentrer sur ces aspects.` ,
        ]
    },
    {
        title: "MULTIPLIEZ LES SOURCES D’ÉPANOUISSEMENT EN DEHORS DU TRAVAIL",
        id: 2,
        listContext: [
            `Il n’y a pas que le travail dans la vie ! Ces besoins de réalisation, de plaisir, d’ambition
            peuvent aussi se réaliser dans d’autres domaines de vie. Vous avez envie d’avoir
            plus de responsabilités, mais sans pour autant devenir manager(euse), vous pouvez
            prendre par exemple des responsabilités dans un club sportif ou une association
            culturelle. Vous ne voyez pas de sens dans votre métier, il ne correspond pas à vos
            valeurs, peut-être qu’un projet personnel, une passion réalisée sur votre temps libre
            peuvent redonner du sens à votre vie. Investissez d’autres domaines de vie !` ,
        ]
    },
    {
        title: "CHANGEZ VOTRE RELATION AUX AUTRES",
        id: 3,
        listContext: [
            `Par expérience, je sais que beaucoup de personnes souhaitent changer de métier,
            d’entreprise ou de vie, non pas à cause du métier, de l’entreprise ou de leur vie
            en eux-mêmes, mais plutôt à cause d’une mauvaise ambiance ou de mauvaises
            relations avec les collègues ou la hiérarchie. La qualité de ces relations peut
            changer. Communiquer plus efficacement, faire de ses ennemi(e)s des ami(e)s,
            cela s’apprend. Si vous êtes dans ce cas, il vous faut entreprendre un travail pour
            développer votre aptitude à la communication interpersonnelle.` ,
        ]
    },
    {
        title: "ILLUMINEZ LA VIE DE VOS PROCHES",
        id: 4,
        listContext: [
            `Vous n’êtes pas satisfait(e) de votre vie. Concentrez-vous sur la vie des autres et
            essayez de faire leur bonheur. Vous verrez à quel point cela vous rendra positif(ve),
            de quelle manière cela vous aidera à être vous-même heureux(se). En donnant aux
            autres, en leur offrant une écoute, un soutien, de l’aide, vous trouverez toujours de
            l’aide en retour.` ,
        ]
    },
]