/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { getFromLS } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { createBookCustom } from '../services/Book.service';
import { getMyBook, getUserBooks, updateBook } from '../services/BookTemplate.service';
import useAlert from './useAlert';

const useEbook = ({ mode, user }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [books, setBooks] = useState<any>([]);

  const alert = useAlert();

  useEffect(() => {
    if (books?.length === 0) {
      getEbooks();
    }
  }, []);

  const token = getFromLS('userToken') || '';

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const getEbooks = async () => {
    setLoading(true);
    try {
      if (mode === 'Create') {
        const response = await getUserBooks(token, { userId: user.id });
        setBooks(response?.data?.books);
      } else if (typeof +user.id !== 'number') {
        const response = await getUserBooks(token, { userId: user.id });
        setBooks(response?.data?.books);
      }
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const getMyBookRequest = async (filter: string, page: number, id?: string) => {
    try {
      const myBook = await getMyBook(token, id || userConnected.id, filter, page);
      return myBook;
    } catch (error) {
      localStorage.clear();
    }
  };

  const createEbookCustom = async (data: any) => {
    const response = await createBookCustom(token, data);
    return response;
  };

  const updateEbookCustom = async (data: any) => {
    const response = await updateBook(token, data);
    return response;
  };

  return {
    loading,
    getEbooks,
    getMyBookRequest,
    setBooks,
    books,
    createEbookCustom,
    updateEbookCustom,
  };
};

export default useEbook;
