import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import style from '../../../commonStyle';
import localStyle from './style';

const EnterprisingSuggestion = () => {
  const classes = style();
  const localClasses = localStyle();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        😍 TA VOIE IDÉALE : BUT
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Après une terminale générale ou technologique, tu intégreras un BUT.
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Le BUT est l’environnement idéal pour toi car on y étudie différentes matières (droit,
              sciences, marketing, communication, etc.).
            </Typography>
          </li>
        </ul>
        <Typography
          className={classNames(classes.suggestionSubtitle, localClasses.customSuggestionSubtitle)}
          component="p">
          Les enseignements sont théoriques et de très bon niveau puisque dispensés par des
          professeur(e)s d’Université, mais aussi pratiques avec de très nombreux projets
          individuels ou collectifs à réaliser, complétés par des stages en entreprise et
          l’intervention de professionnel(le)s. Le BUT est fait pour les hommes et les femmes
          daction qui ont de l&apos;ambition !
        </Typography>
        <Typography className={classes.suggestionQuote} component="p">
          « L’échec est seulement l’opportunité de recommencer d’une façon plus intelligente. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Henry Ford
      </Typography>
    </div>
  );
};

export default EnterprisingSuggestion;
