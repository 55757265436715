/* eslint-disable react/destructuring-assignment */
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import { useBook4 } from '@orientaction/hooks_book';
import HelpfulPersonTable from '../../components/HelpfulPersonTable';
import TitleNbr from '../../components/TitleNbr';
import WithPuceSubtitle from '../../components/WithPuceSubtitle';
import { qcm, tableInputs, tableThreeInputs } from './constant';
import useStyles from './styles';

const Page22 = (props: any) => {

  const classes = useStyles();

  const { assessYourSocialCapitalMobilizable, setAssessYourSocialCapitalMobilizable } = useBook4();

  const handleChange = (key: string) => (e: any) => {

    const { name, checked } = e.target;

    setAssessYourSocialCapitalMobilizable({
      ...assessYourSocialCapitalMobilizable,
      [key]: {
        ...assessYourSocialCapitalMobilizable?.[key],
        [name]: checked
      }
    });

  };

  return (
    <Page
      id={22}
      readOnly={false}
    >

      <TitlePage>
        ÉVALUEZ VOTRE CAPITAL SOCIAL <br />
        MOBILISABLE
      </TitlePage>

      <TitleNbr
        isSmall={true}
        textOverrideStyle={classes.textOverrideStyle}
        puceContentOverrideStyle={classes.puceContentOverrideStyle}
        textContainerOverrideStyle={classes.textContainerOverrideStyle}
        number={1}
        text="Listez ici neuf personnes que vous connaissez directement ou indirectement et qui
          pourraient vous aider dans la réussite de votre projet."
      />

      <MarginContainer>
        <HelpfulPersonTable
          inputs={tableInputs}
          handleChange={setAssessYourSocialCapitalMobilizable}
          current={assessYourSocialCapitalMobilizable}
          nameGroup='nenNameWhoHelpYouAchieveYourProjet'
          readOnly={props.readOnly}
        />
      </MarginContainer>

      <TitleNbr
        isSmall={true}
        textOverrideStyle={classes.textOverrideStyle}
        puceContentOverrideStyle={classes.puceContentOverrideStyle}
        textContainerOverrideStyle={classes.textContainerOverrideStyle}
        number={2}
        text="Listez ici trois personnes influentes que vous connaissez directement ou
        indirectement et qui pourraient vous aider dans la réussite de votre projet."
      />

      <MarginContainer>
        <HelpfulPersonTable
          inputs={tableThreeInputs}
          handleChange={setAssessYourSocialCapitalMobilizable}
          current={assessYourSocialCapitalMobilizable}
          nameGroup='treeNameInfluentialWhoHelpYouAchieveYourProjet'
          readOnly={props.readOnly}
        />
      </MarginContainer>


      {qcm.map((item: any) => (
        <MarginContainer>
          {item.id === 2 ? (
            <WithPuceSubtitle
              puceNumber={1}
              showPuce={false}
              isSmall={true}
              childrenOverrideStyle={classes.childrenOverrideStyle}
              textOverrideStyle={classes.textOverrideStyle}>
              {item.title}
            </WithPuceSubtitle>
          ) : (
            <TitleNbr
              isSmall={true}
              textContainerOverrideStyle={classes.textContainerOverrideStyle}
              textOverrideStyle={classes.textOverrideStyle}
              puceContentOverrideStyle={classes.puceContentOverrideStyle}
              number={item.id}
              text={item.title || ''}
            />
          )}

          <div className={classes.contentAsk}>
            {item.responses.map((response: any, index: number) => (
              <>
                <Grid container={true} direction="row" justifyContent="center" alignItems="center">
                  <Grid item={true} xs={2} md={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          defaultChecked={!!assessYourSocialCapitalMobilizable?.[item.key]?.[response.label]}
                          name={response.label}
                          onChange={handleChange(item.key || "")}
                          color="primary"
                          disabled={props.readOnly}
                        />
                      }
                      label=""
                    />
                  </Grid>

                  <Grid item={true} xs={10} md={11}>
                    <div
                      style={{
                        cursor: 'pointer',
                      }}>
                      <Paragrapth align="justify" colorBlue={true}>
                        {response.content || ''}
                      </Paragrapth>
                    </div>
                  </Grid>
                </Grid>
              </>
            ))}
          </div>
        </MarginContainer>
      ))}
    </Page>
  );
};

export default Page22;
