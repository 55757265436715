export const fakeResult = {
  studyStyle: {
    treeMax: [
      {
        score: 720,
        key: 'study_competitor',
        path: '/images/Study/study_competitor.jpg',
        label: 'Compétiteur(trice)',
      },
    ],
    favorite: {
      label: 'Compétiteur(trice)',
      key: 'study_competitor',
      img: '/images/Study/study_competitor.jpg',
    },
  },
  loveMatter: {
    treeMax: [
      {
        score: 620,
        key: 'love_science_technology',
        path: '/images/Study/love_science_technology.jpg',
        label: 'Sciences & technologie',
      },
    ],
    favorite: {
      label: 'Sciences & technologie',
      key: 'love_science_technology',
      img: '/images/Study/love_science_technology.jpg',
    },
  },
  users_permissions_user: 15304,
  listHistory: [
    {
      id: 1,
      createdAt: '2023-08-23T17:16:33.720Z',
    },
  ],
};
