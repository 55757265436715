import { FC } from 'react';
import style from './style';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  UserProfilePhoto,
} from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import { useUser } from '@orientaction/hooks';


const UserProfileHeader: FC = () => {

  const cls = style();

  const { user, setUser, loading }: any = useUser();

  const currentUser = JSON.parse(getFromLS('userInfos') || '');

  return (

    <Grid
      container={true}
      className={cls.header}
    >

      <Grid
        item={true}
        xs={12}
        sm={12}
        md={3}
        lg={3}
      >
        <div
          className={cls.userPhoto}
        >

          <UserProfilePhoto
            setUser={setUser}
            user={user || {}}
            currentUser={currentUser}
            editable={true}
            loading={loading}
          />

        </div>

      </Grid>

      <Grid
        item={true}
        xs={12}
        sm={12}
        md={9}
        lg={9}
      >

        <div className={cls.headerContainer}>
          {
            loading ? (
              <>
                <Skeleton variant="rect" height={60} width='100%' />
              </>
            ) : (
              <>
                {user ? user.firstname : ''} {user ? user.lastname : ''}
              </>
            )
          }
        </div>

      </Grid>

    </Grid>

  );
};

export default UserProfileHeader;
