/* eslint-disable react-hooks/exhaustive-deps */
import IconButton from '@material-ui/core/IconButton';
import DragIcon from '@material-ui/icons/DragIndicatorOutlined';
import OaAccordion from '@orientaction/commons/OaAccordion';
import { AdminTestContext } from '@orientaction/Context';
import type { Identifier, XYCoord } from 'dnd-core';
import type { FC } from 'react';
import { useCallback, useContext, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import CardContent from './CardContent';
import { ItemTypes } from './ItemTypes';
import useStyles from './style';

const style = {
  padding: '0.5rem 1rem',
  marginTop: '.5rem',
  marginBottom: '.5rem',
  cursor: 'move',
  width: '100%',
};

export interface CardProps {
  id: any;
  title: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Card: FC<CardProps> = ({ id, title, index, moveCard }) => {
  const classes = useStyles();
  const { updateTest, test } = useContext<any>(AdminTestContext);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const accordionDetailsComponent = () => () => {
    return <CardContent responses={test?.questions[index]?.responses} />;
  };

  const handlePopinConfirm = () => {
    const questionToDelete = test?.questions[index];
    const { isNew } = questionToDelete;
    updateTest({
      questions: test.questions.filter((q: any, i: number) => i !== index),
      toDeleteQuestionsIds: isNew
        ? test.toDeleteQuestionsIds
        : [...test.toDeleteQuestionsIds, questionToDelete.id],
      questionDeletionConfirmPopin: {
        open: false,
        title: '',
        handlePopinConfirm: undefined,
        handlePopinClose: undefined,
        data: undefined,
      },
    });
  };

  const handlePopinClose = () => {
    updateTest({
      questionDeletionConfirmPopin: {
        open: false,
        title: '',
        content: '',
        handlePopinConfirm: undefined,
        handlePopinClose: undefined,
      },
    });
  };

  const handleDelete = () => (e: any) => {
    e.stopPropagation();
    // confirm delete
    // open confirm popin
    updateTest({
      questionDeletionConfirmPopin: {
        open: true,
        title: 'Confirmation de suppression',
        content: 'Êtes-vous sûr de vouloir supprimer cette question ?',
        handlePopinConfirm,
        handlePopinClose,
      },
    });
  };

  const handleEdit = (idx: number) => (e: any) => {
    e.stopPropagation();
    // find the question to edit
    const questionToEdit = test.questions[idx];
    updateTest({
      questionDrawerOpen: true,
      questionToCreate: { ...questionToEdit, type: 'edit' },
    });
  };

  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <div className={classes.flexContainer}>
        <IconButton className={classes.grab}>
          <DragIcon fontSize="large" />
        </IconButton>
        <OaAccordion
          detailsText=""
          index={index}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          summaryText={title}
          accordionDetailsComponent={accordionDetailsComponent()}
        />
      </div>
    </div>
  );
};
