import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '2vh',
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '498px',
      margin: '0 auto',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 0',
        lineHeight: '150%',
        fontSize: '16px',
        width: '95%',
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600
    },
    contentLoader: {
      width: '100%',
      height: '20vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    chartContent: {
      width: '92.5%',
      [theme.breakpoints.down(800)]: {
        width: '90%',
      }
    },
  }),
);
