import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    padding: '20px 20px 20px 30px',
    borderRadius: 5,
    marginBottom: 10,
  },
  gridCenterVerticaly: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'none',
  },
  btnBorder: {
    borderLeft: '1px solid #E4E4F2',
    borderRight: '1px solid #E4E4F2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    fontSize: 10,
    height: 30,
    color: 'white',
    cursor: 'default',
    padding: '8px 15px 8px 15px',
    boxShadow: 'none',
    '& .MuiButton-label': {
      fontFamily: 'Poppins',
    },
  },
  btnPublish: {
    background: '#4C8612',
  },
  btnDraft: {
    background: '#BFC0D5',
  },
  pageNumber: {
    fontSize: 14,
    color: '#E63C3C',
    fontFamily: 'Poppins',
    marginLeft: 20,
  },
  containerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  containerSelect: {
    borderRight: '1px solid #E4E4F2',
    paddingRight: 30,
    width: '73%',
  },
  text: {
    fontFamily: 'Open Sans',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginTop: 5,
    color: '#4C8612',
  },
}));
