import { Grid } from "@material-ui/core";
import { FC } from "react";
import useStyle from '../style';
import Typography from '@material-ui/core/Typography';

interface ITitle {
    number: string;
    text: string;
}

const Title: FC<ITitle> = ({ number, text }) => {

    const classe = useStyle();

    return (
        <Typography variant="h3" component="h3" style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'left', fontWeight: 'lighter' }}>
            <span className={classe.numberRomain}>{number || 0}</span>
            <strong className={classe.h2} style={{fontSize: '20px'}}>{text || ""}</strong>
        </Typography>
    );
};

export default Title;
