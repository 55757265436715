/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useState } from 'react';
import { Layout, HistoryMajorTest } from '@orientaction/components';
import { useLocation } from 'react-router-dom';
import {
  useAlert,
  useMyResulatOfTest,
  useNeedsTestResult,
  useRedirectToRootPage,
} from '@orientaction/hooks';
import withWidth from '@material-ui/core/withWidth';
import { dataFirstViewPro } from './Pro/context';
import { dataFirstViewPerso } from './Perso/context';
import { useStyles } from './style';
import { CircularProgress } from '@material-ui/core';
import DrawerView from './Drawer';
import Pro from './Pro';
import Perso from './Perso';
import BtnCanceled from '../BtnCanceled';

const ResultatNeeds = () => {
  const classe = useStyles();

  const [loading, setLoading] = useState(true);

  const { dialog, myResult, setDialog, setMyResult } = useMyResulatOfTest();

  const { getMyResult } = useNeedsTestResult();

  const { goToRootPage } = useRedirectToRootPage();

  const alert = useAlert();

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const getResultat = async (idTest: number) => {
    setLoading(true);

    (window as any).scrollTo(0, 0);

    try {
      const { data } = await getMyResult(+idTest);
      setMyResult(data.data);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResultat(+id);
  }, [id]);

  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleOpen = (prop: any) => (e: any) => {
    setDialog({
      ...dialog,
      open: true,
      data: {
        idTest: id,
        keyWord: prop.key,
        color: prop.color,
        label: prop.label,
      },
    });
  };

  return (
    <Layout isaddBackground={true} noPadding={true} isNew={true} footer={true}>
      {loading ? (
        <div className={classe.contentLoader}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classe.root}>
          <div className={classe.btnHistory}>
            <HistoryMajorTest
              testResults={myResult?.listHistory || []}
              idTest={+id}
              toRedirect="/resultat-need"
            />
          </div>

          <BtnCanceled id={myResult?.users_permissions_user} isBack={true} />

          <Pro myResult={myResult?.pro} dataFirstView={dataFirstViewPro} handleOpen={handleOpen} />
          <Perso
            myResult={myResult?.perso}
            dataFirstView={dataFirstViewPerso}
            handleOpen={handleOpen}
          />
        </div>
      )}

      <DrawerView open={dialog.open} handleClose={handleClose} />
    </Layout>
  );
};

export default withWidth()(ResultatNeeds);
