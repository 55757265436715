export const context = [
    {
        title: "LES SIX QUESTIONS À SE POSER POUR ANTICIPER VOTRE FUTUR–À DEUX ANS",
        id: 1,
        listContext: [
            {
                titleLeft: "Question 1",
                content: `qu’est-ce qui ne va pas changer, qu’est-ce qui est vrai aujourd’hui
                et qui dans tous les cas de figure sera toujours vrai demain ?`
            },
            {
                titleLeft: "Question 2",
                content: `qu’est-ce qui aura changé à coup sûr pour nous, inéluctablement,
                dans tous les cas de figure, quels sont les changements qui sont déjà inscrits
                dans le marbre ?`
            },
            {
                titleLeft: "Question 3",
                content: `quels sont les futurs dont on ne veut pas, qu’est-ce qui ne sera
                pas, qu’est-ce qui ne doit pas être pour nous dans deux ans ?`
            },
            {
                titleLeft: "Question 4",
                content: `qu’est-ce qui a déjà commencé à changer, quelles sont les
                tendances qui sont à l’œuvre, qu’on commence à sentir ?`
            },
            {
                titleLeft: "Question 5",
                content: `dans les deux années qui viennent, quelles sont nos principales
                incertitudes, c’est-à-dire les principaux risques encourus, mais aussi les
                principales opportunités susceptibles de se présenter ?`
            },
              {
                titleLeft: "Question 6",
                content: `au cours des deux ans qui viennent, quelles seront mes marges de
                manœuvre à l’endroit où je suis ?`
            },
        ]
    },
    {
        title: "LES TROIS RÉFLEXES À AVOIR POUR ANTICIPER VOTRE AVENIR",
        id: 2,
        listContext: [
            {
                title: "LE RÉFLEXE DE LA CURIOSITÉ",
                content: `Il faut être en permanence à l’écoute de son environnement pour s’adapter, et le
                cas échéant prendre les décisions qui s’imposent. La curiosité est l’attitude n°1 de
                la réussite. En étant curieux(se), on acquiert les connaissances qui nous permettent
                d’anticiper les évolutions de notre environnement.`
            },
            {
                title: "LE PESSIMISME DU(DE LA) CONQUÉRANT(E)",
                content: `Toujours se dire « qu’est-ce que je fais si ça tourne mal ? ». Il faut avoir un plan B, si
                cela ne marche pas, une solution de repli. L’échec fait partie des possibles, c’est une
                variable à prendre en considération. Il faut donc en cas d’échec avoir élaboré un
                plan de secours pour réagir rapidement.`
            },
            {
                title: "LE SENS DU PARI",
                content: `Quand on est dans un environnement stable, c’est facile de peser sa décision, de la
                préparer, de la mûrir, de faire des études… En temps de turbulences, jamais vous ne
                pourrez bâtir une décision et être sûr(e) de son résultat. La meilleure méthode, c’est
                de prendre une décision et de tout faire pour que cela soit la bonne.`
            },
        ]
    },
]