export const list = {
    'context': {
        'label': "LE CONTEXTE",
        'listParagraph': [
            `Votre métier est voué à disparaître à court
        ou moyen terme à la suite d’évolutions
        technologiques ou réglementaires.`,
            `Votre employeur finance et accompagne
        les départs volontaires dans le cadre d’une
        réduction des effectifs.`,
            `Vous avez 5 ans d’activités professionnelles
        en CDI dans le secteur privé, ce qui vous donne
        droit au CPF de transition professionnelle.`,
            `D’autres secteurs d’activité recrutent
        et peinent à trouver des candidat(e)s
        sérieux(ses) et professionnels(les).`
        ]
    },
    'you': {
        'label': "VOUS",
        'listParagraph': [
            `Vous préférez anticiper le changement
        que le subir. Vous êtes dans l’anticipation,
        proactif(ve) par rapport à votre avenir.`,
            `Vous êtes prêt(e) à réapprendre un nouveau
        métier, à y consacrer du temps et de l’énergie
        pour réussir.`,
            `Vous êtes prêt(e) à consentir à une diminution
        de votre niveau de vie pendant le temps de
        votre reconversion.`,
            `Vous avez toujours rêvé d’exercer un métier,
        mais n’avez jamais fait le pas pour concrétiser
        votre rêve.`
        ]
    }
}
