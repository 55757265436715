import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  puce: {
    color: 'white',
    background: '#fff350',
    minWidth: '30px',
    borderRadius: '30px',
    height: '30px',
    border: '3px solid #fff350',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  h3: {
    fontSize: '20px',
    color: '#2B56A5',
    fontWeight: 'bold',
  },
  content: {
    width: '100%',
    margin: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  small: {
    fontSize: '15px !important',
    color: '#2B56A5',
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  childrenStyle: {
    fontFamily: 'ITC Avant Garde Gothic Std Medium',
    fontWeight: 'lighter',
    textAlign: 'start',
    margin: '0 1vh',
  },
}));
