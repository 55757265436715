// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import { CircularProgress, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useAdminUser, useAlert, useCompany } from '@orientaction/hooks';
import { getFromLS, valueAliasRole } from '@orientaction/utils';
import { ChangeEvent, FC, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import useStyle from './style';

let typeTimeout: any;

interface ISelectHieachy {
  value: string;
  alias: any;
  errorForm: any;
  title: string;
  name: string;
  readOnly: boolean;
  handleChange: (e: any) => void;
  company_user_role: number;
  company: number;
  equal: boolean;
  direction?: any;
}

const AutoCompleHierachy: FC<ISelectHieachy> = ({
  value,
  alias,
  errorForm,
  readOnly,
  title,
  name,
  handleChange,
  company,
  company_user_role,
  equal,
  direction,
}) => {
  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const { getUpHierachy } = useCompany();
  const { setListUphierchy, listUpHierachy } = useAdminUser();
  const alert = useAlert();
  const classe = useStyle();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyWord, setkeyWord] = useState('');

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    clearTimeout(typeTimeout);

    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      callApi(keyWord);
    }
  };

  const handleChangeTextField = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setkeyWord(e.target.value);
    typeTimeout = setTimeout(function () {
      callApi(e.target.value);
    }, 1000);
  };

  const selectHierachy = (event: any, selected: any | any[], reason: string) => {
    handleChange({
      target: {
        name: name as string,
        value: selected ? selected.id : (null as any),
      },
    });
  };

  const callApi = (word: string) => {
    setLoading(true);

    setListUphierchy([]);

    const api: any = {
      company: company as number,
      filter: word as string,
      company_user_role: company_user_role as number,
      equal: equal as boolean,
      // direction: direction as any
    };

    if (userConnected.role.type === 'admin') {
      api.direction = direction;
    } else {
      api.zoneInfluance = {
        name: userConnected.company_user_role.id,
        value: userConnected.id,
      };
    }

    getUpHierachy({
      ...api,
    })
      .then((res: any) => {
        setListUphierchy(res.data.list);
      })
      .catch((err: any) => {
        setListUphierchy([]);
        alert.openSimpleAlert();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const filter = (data: any[]) => {
    if (data.length > 0) {
      return data
        .map((option: any, index: number) => {
          const nameCurrent = option.company_user_role?.name;
          const aliasCurrent = alias?.attributes?.companyUserRoleAlias;
          const valueAliasRoleCurrent = nameCurrent
            ? valueAliasRole(nameCurrent, aliasCurrent)
            : '';

          return {
            ...option,
            index: index as number,
            hierachy: valueAliasRoleCurrent,
          };
        })
        .sort((a: any, b: any) => a.company_user_role.id - b.company_user_role.id);
    }

    return [];
  };

  return (
    <div style={{ marginTop: '15px' }}>
      <Typography className={classe.label}>{title}</Typography>

      <Autocomplete
        id="asynchronous-demo"
        fullWidth={true}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        value={(listUpHierachy || []).find((item: any) => item.id === value) || ''}
        getOptionLabel={option => `${option.firstname || ''} ${option.lastname || ''}`}
        options={filter(listUpHierachy || [])}
        groupBy={(option: any) => `${option.hierachy || ''}`}
        loading={loading}
        disabled={readOnly}
        renderOption={(props: any, option: any) => (
          <div data-cy={`list-up-hierachy-${props.index}`}>
            {props.firstname} {props.lastname}
          </div>
        )}
        onChange={selectHierachy}
        loadingText={<>Chargement ...</>}
        noOptionsText={<>Aucun resulat</>}
        renderInput={params => (
          <TextField
            {...params}
            id="outlined-basic"
            variant="outlined"
            error={!!errorForm[name]}
            onChange={handleChangeTextField}
            placeholder="Rechercher un nom ou un prénom"
            className={classe.formInput}
            onKeyDown={onKeyDown}
            name="formGetUpHierachy"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AutoCompleHierachy;
