export const context = [
    {
        title: 'Un désir d’évolution nouveau',
        paragraph: `Alors qu’il y a quelques années encore, il était inimaginable de demander à changer
        de poste pour un poste subalterne, ce type de demande se multiplie aujourd’hui
        dans les organisations (entreprise, association, administration). Certains(es) cadres
        n’hésitent plus à demander à revenir à des postes à moindre responsabilité. On est
        passé d’une époque où il fallait « Réussir DANS la vie » à une époque où beaucoup
        préfèrent « Réussir LEUR vie ». C’est un changement de culture et de paradigme.`
    },
    {
        title: 'Une recherche d’équilibre',
        paragraph: `Un certain nombre de personnes renoncent à une ascension sociale pour maintenir
        un équilibre entre leur vie personnelle et leur vie professionnelle. Elles sont à la
        recherche d’une qualité de vie. Elles aspirent à une vie moins stressante, respectueuse
        de leur rythme de vie et de leur santé. Ces personnes désirent consacrer du temps
        à l’éducation de leurs enfants, avoir des loisirs personnels, en famille ou entre amis.
        Très souvent, elles ont développé des passions en dehors du travail comme une
        activité artistique ou un engagement associatif.`
    },
    {
        title: 'Faire des concessions',
        paragraph: `Si ce type d’évolution est possible sur le plan juridique en renégociant son
        contrat de travail, dans les faits, ce type d’évolution fait l’objet de réticences de
        la part des employeurs. La crainte des DRH et des dirigeant(e)s est de voir des
        collaborateurs(trices) leur demander un poste à moindre responsabilité tout
        en conservant leurs avantages salariaux. Pour convaincre, la bonne attitude est
        à l’opposé. Il faut accepter de renégocier son contrat de travail et de faire des
        concessions. On ne peut conserver ses avantages et réduire ses responsabilités. `
    },
    {
        title: ' Convaincre ses proches',
        paragraph: `Parfois, ce sont ses proches qu’il faut convaincre. Il arrive en effet que le(a) conjoint(e)
        n’accepte pas facilement ce type d’évolution. Si personnellement, on est prêt(e) à
        réduire son niveau de vie ou à changer son organisation, ce n’est pas forcément
        le cas des autres membres de la famille. Pourtant, ce type d’évolution devrait se
        multiplier dans les années à venir et devenir un choix tout à fait courant dans la
        vie professionnelle d’une personne. La vie professionnelle n’est plus linéaire, mais
        mouvante et faite de différents temps avec chacun ses priorités.`
    },
]