/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line no-use-before-define
import { listTabs } from '@orientaction/Context';
import { useAdminUser } from '@orientaction/hooks';
import { getFromLS, valueAliasRole } from '@orientaction/utils';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AntTab from './antTab';
import { a11yProps, AntTabs, useStyles } from './style';

interface IAdminTab {
  callBack: (data: any) => void;
  countTabs: any;
  alias: any;
  viewDisable: boolean;
}

const AdminTab: FC<any> = (props: any) => {
  const { callBack, countTabs, alias, viewDisable } = props as IAdminTab;

  const history = useHistory();

  const { listAdminPanel, setlistAdminPanel, tabs } = useAdminUser();

  const classes = useStyles();

  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    event.stopPropagation();

    callBack(newValue);

    history.push(`/admin/users-resultats?tab=${newValue}`);
  };

  useEffect(() => {
    const newList = [];

    const loopStart = user?.company_user_role?.id || 0;

    const isUserConsultant = user?.company_user_role?.id === 4;

    for (let i = loopStart; i < listTabs.length; i++) {
      // remove TESTS column if connected user is consultant
      // i think that it should be refactored later

      // newList.push({
      //   ...listTabs[i],
      //   colomTable: [
      //     {
      //       type: listTabs[i]?.colomTable[0]?.type,
      //       header: isUserConsultant
      //         ? (listTabs[i]?.colomTable[0]?.header || []).filter(
      //             (elt: any) => elt?.key !== 'viewTest'
      //           )
      //         : listTabs[i]?.colomTable[0]?.header,
      //     },
      //     {
      //       type: listTabs[i]?.colomTable[1]?.type,
      //       header: listTabs[i]?.colomTable[1]?.header,
      //     },
      //   ],
      // });

      newList.push(listTabs[i]);
    }

    if (user.role.type === 'admin') {
      newList.push({
        label: 'Résultats des tests',
        userTypeConnected: 6,
        viewNbr: false,
      });
    }

    setlistAdminPanel(newList);
  }, [user?.role?.id]);

  const valueNbr = (position: number) => {
    return countTabs && countTabs?.[position] ? <span>{countTabs?.[position] || 0}</span> : '';
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs variant="scrollable" value={tabs} onChange={handleChange} aria-label="ant example">
          {listAdminPanel.map((item: any, index: number) => {
            if (!item.viewNbr && !item.userTypeConnected) {
              return (
                <AntTab
                  key={index}
                  data-cy="tabs-6"
                  label={<div>{item.label || ''}</div>}
                  value={item.userTypeConnected}
                  {...a11yProps(item.userTypeConnected)}
                />
              );
            }

            return (
              <AntTab
                key={index}
                value={item.userTypeConnected}
                data-cy={`tabs-${item.userTypeConnected}`}
                label={
                  <div>
                    {valueAliasRole(item.label, alias?.attributes?.companyUserRoleAlias) || ''}{' '}
                    {valueNbr(item.userTypeConnected)}
                  </div>
                }
                {...a11yProps(item.userTypeConnected)}
              />
            );
          })}
        </AntTabs>
      </div>
    </div>
  );
};

export default AdminTab;
