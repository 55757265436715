import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 50,
      marginTop: 0,
    },
    title: {
      color: '#000A8C',
      fontSize: 36,
      margin: 0,
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      '& input': {
        padding: '14px 14px 14px 0 !important',
        width: 260,
      },
    },
    input: {
      '& input': {
        padding: '14px 14px 14px 14px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E3E8F0',
        borderRadius: 3,
      },
    },
    searchContainer: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    searchInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    btn: {
      letterSpacing: 0,
      opacity: 1,
      border: '1px solid',
      borderImage: 'linear-gradient(to top, #000A8C  50% , #E63C3C 0% , #FFDC27 100%) 1',
      borderRadius: 4,
      textTransform: 'none',
      height: 46,
      backgroundColor: '#FFFFFF',
      color: '#0E1247',
    },
    searchButton: {
      marginLeft: 20,
    },
    resultFilter: {
      display: 'flex',
      marginBottom: 15,
    },
    keyword: {
      marginRight: 20,
      width: 320,
      '& .MuiAutocomplete-inputRoot': {
        paddingTop: 4,
        paddingBottom: 5,
      },
    },
    filterValue: {
      marginRight: 20,
      width: 320,
    },
    filters: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 5,
    },
    chip: {
      margin: '10px',
      '&.MuiChip-root': {
        margin: [4, 0],
        color: '#000a8c',
        background: '#000A8C26 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'baseline',
        '& .MuiSvgIcon-root': {
          color: '#000a8c !important',
        },
        '& .MuiSvgIcon-root:hover': {
          color: '#000A8C26 !important',
        },
      },
    },
    resultTable: {},
    deleteIconContainer: {
      background: '#e63c3c26 0% 0% no-repeat padding-box',
      borderRadius: '10px',
      width: 50,
      height: 50,
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    deleteIcon: {},
    modalContent: {},
    modalTitle: {
      color: '#E63C3C',
      marginBottom: 30,
    },
    modalText: {
      fontSize: 15,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
    bodycenter: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    flexLine: {
      display: 'flex',
      alignItems: 'center',
    },
    dividerBlock: {
      width: '30px',
      height: '10px',
      backgroundColor: '#000A8C',
      marginLeft: '10px',
      marginRight: '10px',
    },
  })
);

export default useStyles;
