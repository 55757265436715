export const list1 = [
    {
        text: 'Rentrer en formation avec des élèves plus jeunes',
        active: true,
        id: 1
    },
    {
        active: true,
        text: 'Apprendre et me soumettre à la discipline d’une école',
        id: 2
    },
    {
        active: true,
        text: 'Rechercher un(e) employeur(se) pour mon contrat de professionnalisation',
        id: 3
    },
    {
        active: true,
        text: 'Rechercher un nouvel emploi à l’issue de la formation',
        id: 4
    },
    {
        active: true,
        text: 'Accepter de gagner seulement 900 euros net / mois pendant un an',
        id: 5
    },
    {
        active: true,
        text: 'Prendre le risque de quitter un CDI',
        id: 6
    },
    {
        active: false,
        text: 'Déménager dans le nord de la France',
        id: 7
    },
];


export const list2 = [
    {
        active: true,
        text: 'Le climat sympa et la beauté des paysages – j’habite à Grasse',
        id: 1
    },
    {
        active: true,
        text: 'La proximité de ma famille et de mes ami(e)s',
        id: 2
    },
    {
        active: true,
        text: 'La sécurité d’un CDI',
        id: 3
    },
    {
        active: true,
        text: 'Du temps de loisirs – entre travail et école, j’aurai peu de temps pour moi',
        id: 4
    },
    {
        active: true,
        text: 'Mes vacances : je n’aurai sans doute pas les moyens de partir',
        id: 5
    },
]