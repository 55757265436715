import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktop: {
      width: 701,
      height: '100%',
    },
    mobile: {
      width: 'auto',
      height: '100%',
    },
    content: {
      backgroundColor: 'white',
      overflowX: 'hidden',
    },
    head: {
      width: '100%',
      color: 'white',
      'border-top-left-radius': '0',
      'border-top-right-radius': '0',
      'border-bottom-left-radius': '50% 50%',
      'border-bottom-right-radius': '50% 50%',
      paddingTop: '1.5vh',
      height: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down(900)]: {
        position: 'absolute',
        justifyContent: 'flex-start',
        zIndex: 200,
        height: '95px',
        paddingTop: '1.5vh',
      },
    },
    img: {
      width: '552.5px',
      height: '390px',
      [theme.breakpoints.down(900)]: {
        width: '70%',
        height: '70%',
      },
    },
    imgContent: {
      marign: '3vh 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentDescription: {
      width: '90%',
      margin: '0 auto',
      [theme.breakpoints.down(900)]: {
        marginTop: 130,
      },
    },
    paragraph: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '18px',
      color: '#23296F',
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
      },
    },
    titleHead: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      [theme.breakpoints.down(800)]: {
        fontSize: '18px',
      },
    },
    contentFavorite: {
      borderRadius: '8px',
      '& li': {
        margin: '1vh 0',
        color: '#23296F',
      },
      [theme.breakpoints.down(900)]: {
        paddingLeft: '15px',
      },
    },
    score: {
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 300,
    },
    handleClose: {
      top: '-12%',
      left: '88%',
      color: 'white',
      zIndex: 600,
      [theme.breakpoints.down(900)]: {
        position: 'absolute',
        top: 5,
        left: '5%',
      },
    },
    colorWhite: {
      color: 'white',
    },
    contentLoader: {
      width: 701,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
  }),
);
