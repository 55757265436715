/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { ContainerAdmin, InputSearch, MarginContainer } from '@orientaction/commons';
import { Layout } from '@orientaction/components';
import { useCategoryTest } from '@orientaction/hooks';
import { getFromLS, isUserRoot } from '@orientaction/utils';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useTest from '../../hooks/useTest';
import { useStyles } from './style';
import StyleTab from './StyleTab';
import TabPannelAdminTest from './TabPannel';

const AdminTest = (props: any) => {
  const { push } = useHistory();

  const { search } = useLocation();

  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const [searchFilter, setSearchFilter] = React.useState('');

  const { getTests, getArchivedTests, getDisabledTests, loadingAdmin } = useTest();

  const { getCategoryTest, loading } = useCategoryTest();

  useEffect(() => {
    if (value === 0) {
      getCategoryTest({
        isDesabled: false,
        isArchived: false,
        keyWord: searchFilter,
      });
    }

    if (value === 1) {
      getDisabledTests();
    }

    if (value === 2) {
      getArchivedTests();
    }
  }, [value, searchFilter]);

  useEffect(() => {
    const currentTab = new URLSearchParams(search).get('tab');
    if (currentTab) {
      setValue(+currentTab);
    }
  }, [search]);

  const goToCreateTest = () => {
    push('/admin/test/creer');
  };

  const gotoManageCategory = () => {
    push('/admin/gestion-categorie');
  };

  const handleFilterChange = (e: any) => {
    setSearchFilter(e.target.value);
  };

  const searchTest = () => {
    getTests(searchFilter);
  };

  const user = JSON.parse(getFromLS('userInfos') || '');

  if (!isUserRoot(user)) {
    return null;
  }

  return (
    <Layout isaddBackground={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid item={true}>
              <Typography variant="h2" style={{ marginBottom: '20px' }}>
                Administration des tests{' '}
              </Typography>
            </Grid>

            <Grid item={true}>
              <Grid container={true} direction="row" justifyContent="center" alignItems="center">
                <Grid item={true}>
                  <InputSearch
                    placeholder="Chercher un test"
                    onChange={handleFilterChange}
                    search={searchTest}
                  />
                </Grid>

                <Grid item={true}>
                  <Button
                    data-cy="category-management-button"
                    className={classes.btnEdit}
                    onClick={gotoManageCategory}
                    startIcon={<EditIcon />}>
                    Gestion des catégories
                  </Button>
                </Grid>

                <Grid item={true}>
                  <Button
                    startIcon={<Add />}
                    className={classes.btnPrimary}
                    onClick={goToCreateTest}
                    data-cy="create-test">
                    Créer un test
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <StyleTab setTabs={setValue} tabs={value} />

          <TabPannelAdminTest tabs={value} loading={loading || loadingAdmin} />
        </MarginContainer>
      </ContainerAdmin>
    </Layout>
  );
};

export default AdminTest;
