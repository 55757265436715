export const paragraphs = [
  {
    id: '16-1',
    text: `
            À partir de notre personnalité, nous construisons un ensemble de savoirs, de savoir-faire
            et de savoir-être. Ces savoirs, savoir-faire et savoir-être sont liés à l’action. Ils sont
            utilisés ensemble simultanément ou successivement. Ainsi, pour convaincre un(e) client(e),
            un(e) technico-commercial(e) mobilise des savoirs (informations sur le produit, sur son
            (sa) client(e), sur les techniques de vente), des savoir-faire (questionner, écouter,
            argumenter) et des savoir-être (être ponctuel(le) aux rendez-vous, avoir une tenue
            adaptée, valoriser son (sa) client(e), etc.).
          `,
  },
];
