const INITIAL_STATE: any = {
  id: 0,
  indoorWeather: null,
  DoYouownYourMainResidence: false,
  haveYouFinishedRepayingYourLoan: false,
  DoYouHaveFinancialInvestments: false,
  DoYouOwnRealEstate: false,
  DoYouHaveSpecialCapitalToMakeYourProjectAsuccess: false,
  SubscribeToUnemploymentInsurance: false,
  AreYouCurrentlyOnFixedTermContractOrOnAMissionContract: false,
  AreYouOver50old: false,
  DoYouWorkInaGroupOrAnAdministrationOfMoreThan1000Employees: false,
  IYsourCurrentOrganizationLookingToDownsize: false,
  DoesYourSpouseHaveAstableProfessionalSituation: false,
  IsYourSpousesRemunerationSufficientToPayHouseholdExpenses: false,
  DoYouOrWillYouBeResponsibleForTheHigherEducationOfYourChildren: false,
  DoYouOrWillYoubeInChargeOfYourParentsRetirementHome: false,
  AreRelativesReadyToFinanciallySupportYourProject: false,

  AreYouReadyToSellYourPrincipalResidence: false,
  AreYouReadyToMobilizeFinancialInvestments: false,
  AreYouReadyToMobilizeRealEstateInvestments: false,
  HaveYouEverSetAsideMoneyForTheSuccessOfYourProject: false,
  AreYouReadyToTakeAPayCut: false,
  AreYouReadyToMakeAConventionalBreakup: false,
  AreYouReadyToNegotiateYourDeparture: false,
  IfYouAreACoupleIsYourSpouseReadyToTakeOnHouseholdExpenses: false,

  assessYourSocialCapitalMobilizable: {},
  accessYourCulturalCApitalMobilizable: {},

  diplomeExperience: {},
  softSkill: {},
  yourBestProfessionalExperience: {},
  yourDiplome: {},
  synthiesIsYouSkill: {},
  valueAdd: ''
};

const stateBook4 = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'ID': {
      return { ...state, id: action.payload };
    }
    case 'INDOORWEATHER': {
      return { ...state, indoorWeather: action.payload };
    }
    case 'YOURECONOMICCAPITAL': {
      return { ...state, [action.payload.name]: action.payload.value };
    }
    case 'ASSESSYOURSOCIALCAPITALMOBILIZABLE': {
      return { ...state, assessYourSocialCapitalMobilizable: action.payload };
    }
    case 'ASSESSYOURCULTURALCAPITALMOBILIZABLE': {
      return { ...state, accessYourCulturalCApitalMobilizable: action.payload };
    }
    case 'DIPLOMEEXPERINCE': {
      return { ...state, diplomeExperience: action.payload };
    }
    case 'SOFTSKILL': {
      return { ...state, softSkill: action.payload };
    }
    case 'YOURBESTPROFESSIONALEXPERIENCE': {
      return { ...state, yourBestProfessionalExperience: action.payload };
    }
    case 'YOURDIPLOME': {
      return { ...state, yourDiplome: action.payload };
    }
    case 'SYNTHIESISYOUSKILL': {
      return { ...state, synthiesIsYouSkill: action.payload };
    }
    case 'VALUEADD': {
      return { ...state, valueAdd: action.payload };
    }
    case 'ALL': {
      return { ...action.payload };
    }
    default:
      return state;
  }
};

export default stateBook4;
