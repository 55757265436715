import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    backGround: {
        margin: '2vh 0',
        marginBottom: '6vh',
        height: '40px',
        width: '300px',
        clipPath: 'polygon(0% 0%, 90% 0%, 100% 100%, 100% 100%, 0% 100%)',
        background: '#2B56A5',
        padding: '0.5vh 2.5vh',
        [theme.breakpoints.down('sm')]: {
            width: '400px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        }
    },
    textChapitre: {
        fontSize: "2.5vh",
        color: 'white',
    }
}));
