import { FC } from 'react';
import style from './style';

interface IProps {
  image: string;
}

const ResultImage: FC<IProps> = ({ image }) => {
  const classes = style();
  return <img className={classes.testImg} src={image} alt="Sample" />;
};

export default ResultImage;
