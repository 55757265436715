/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { book3Action } from '@orientaction/api-actions';
import { appBook3 } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IBook3 } from "../../store/types/Book/Book3";
import { BookTemplate } from '@orientaction/services';
import { getFromLS } from '../../utils/utils';

const useBook3 = () => {

    const dispatch = useDispatch();

    const book: IBook3 = useSelector(appBook3);

    const token = getFromLS('userToken') || '';

    const updateIndoorWeather = async (nbr: number) => {
        console.log({book, nbr})
        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            indoorWeather: nbr,
            query: "api::third-ebook.third-ebook"
        });
        dispatch(book3Action.setIndoorWeather(nbr));
    }

    const setPersonnelTest = async (data: any) => {
        dispatch(book3Action.setPersonnelTest(data));
        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            personnelTest: data,
            query: "api::third-ebook.third-ebook"
        });
    }

    const setStylePersonnel = async (data: any) => {
        dispatch(book3Action.setStylePersonnel(data));
        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            stylePersonnel: data,
            query: "api::third-ebook.third-ebook"
        });
    }

    const setId = (data: number) => {
        dispatch(book3Action.setId(data));
    }

    const initValue = (data: any) => {
        dispatch(book3Action.setIndoorWeather(data.indoorWeather || 0));
        dispatch(book3Action.setPersonnelTest(data.personnelTest || {}));
        dispatch(book3Action.setStylePersonnel(data.stylePersonnel || {}));
    }

    const totalPersonnelTest = (label: number) => {

        const list = Object.keys(book.personnelTest);

        let total = 0;

        for (let i = 0; i < list.length; i++) {

            if ((book.personnelTest[list[i]] || []).includes(label)) {
                total += +label;
            }

        }

        return total;

    }

    const getMyValueBook = async (userId: any) => {
        return BookTemplate.getMyValueBook(token, {
            id: userId,
            query: 'api::third-ebook.third-ebook'
        });
    }

    const totalStylePersonnel = (label: string) => {

        const list = Object.keys(book.stylePersonnel);


        let total = 0;

        for (let i = 0; i < list.length; i++) {
            if ((book.stylePersonnel[list[i]] || []).includes(label)) {
                total += 1;
            }
        }

        return total;

    }

    return {
        ...book,
        setId,
        updateIndoorWeather,
        setPersonnelTest,
        setStylePersonnel,
        initValue,
        totalPersonnelTest,
        totalStylePersonnel,
        getMyValueBook
    };

};

export default useBook3;
