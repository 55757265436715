// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetMetaData = (props: any) => {
  const { quotePr, titlePr, imagePr, descriptionPr, hashtagPr, link, children } = props;
  const [childrenMap, setChildrenMap] = React.useState();
  const currentUrl = link;
  const quote = quotePr !== undefined ? quotePr : '';
  const title = titlePr !== undefined ? titlePr : 'Orientaction test';
  const image =
    imagePr !== undefined
      ? imagePr
      : 'https://www.w3schools.com/css/paris.jpg';
  const description = descriptionPr !== undefined ? descriptionPr : 'Test description';
  const hashtag = hashtagPr !== undefined ? hashtagPr : '#Test';

  // const Head = Helmet.renderStatic();
  // const regexp = / data-react-helmet="true"/g;
  // const attr = Head.htmlAttributes.toString()
  // const style = Head.style.toString().replace(regexp, '');
  // const meta = Head.meta.toString().replace(regexp, '');
  // const script = Head.script.toString().replace(regexp, '');
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:description" content={description} />
    </Helmet>
  );
};

export default HelmetMetaData;
