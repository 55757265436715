import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import OaLabel from '@orientaction/commons/OaLabel';
import RichEditor from '@orientaction/commons/RichEditor';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import style from './style';

const FinalTextSection = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const handleChange = (value: string) => {
    updateTest({
      finalText: {
        ...test.finalText,
        description: value,
      },
    });
  };

  const handleTitleChange = (e: any) => {
    updateTest({
      finalText: {
        ...test.finalText,
        title: e.target.value,
      },
    });
  };

  return (
    <div style={{ marginTop: 25 }}>
      <Typography className={classes.sectionTitle} component="h1">
        Texte de fin avant présentation des résultats
      </Typography>
      <div className={classes.marginTop}>
        <OaLabel>Titre</OaLabel>
        <TextField
          id="outlined-basic"
          variant="outlined"
          className={classes.formInput}
          name="Name"
          value={test.finalText.title}
          onChange={handleTitleChange}
          placeholder="Renseignez le titre"
        />
      </div>
      <div className={classes.marginTop}>
        <OaLabel>Description du résultat</OaLabel>
        <RichEditor
          value={test.finalText.description}
          handleChange={handleChange}
          placeholder="Renseignez la description du texte"
          whiteBg={true}
          isMultiline={true}
        />
      </div>
    </div>
  );
};

export default FinalTextSection;
