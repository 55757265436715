export const paragraphs = [
  {
    id: '15-1',
    text: `Toute personne hérite à sa naissance des 4 capitaux (économique, social, culturel et
              symbolique) de ses parents. Elle les utilise pour grandir et construire sa vie, puis
              développe à son tour ses propres capitaux qu’elle transmettra à ses enfants (si elle en
              a). Parallèlement à cela, en faisant des expériences, des rencontres, en atteignant des
              résultats, en surmontant des difficultés, en relevant des défis, elle développe et
              construit sa personnalité. La personnalité constitue le second étage de la compétence.`,
  },
  {
    id: '15-2',
    text: `
              C’est le socle de la personne, semblable au tronc de l’arbre. La personnalité peut se
              définir comme un ensemble de traits de caractère stables dans le temps, mais la
              personnalité est aussi et surtout un ensemble de capacités et de talents. On dira qu’une
              personne est méticuleuse et ordonnée ; d’une autre, qu’elle a un tempérament de
              leadeuse; d’une autre encore, qu’elle est curieuse et ouverte d’esprit. Toutes ces
              qualités constituent l’empreinte ADN de cette personne, « sa signature sociale ». Cette
              « signature sociale » est ce qui la différencie des autres et en fait un être unique,
              reconnu et apprécié pour ce qu’il est.
      `,
  },
  {
    id: '15-3',
    text: `
              Mais la personnalité est aussi un ensemble de capacités. La personne dont on dira
              qu’elle est méticuleuse et ordonnée possède en fait une aptitude particulière à classer
              et à hiérarchiser les choses en fonction de leurs caractéristiques.   
            `,
  },
  {
    id: '15-4',
    text: `
      Elle est aussi capable de percevoir plus que les autres personnes de son entourage les
      détails. Elle agit en général en suivant une méthode et toujours de manière rigoureuse.
      Ce trait de personnalité est ce qui distingue cette personne, mais c’est aussi et
      surtout un talent. Et ce talent est recherché par les employeurs(ses).
      `,
  },
  {
    id: '15-5',
    text: `
      Ce que l’on appelle « soft skills » (qui s’acquièrent par l’expérience) par opposition
      aux « hard skills » (qui s’acquièrent par la formation) correspond justement à ces
      traits de personnalité qui rendent les gens différents les uns des autres et fondent
      leurs talents. Ces traits de personnalité (ces talents) se construisent à partir des
      expériences de vie, professionnelles, mais aussi personnelles. Au-delà des compétences
      techniques, ce sont ces capacités qui sont les plus recherchées par les employeurs(ses)
      car il faut beaucoup de temps pour les acquérir et les développer.  
      `,
  },
  {
    id: '15-6',
    text: `
      Les équipes d’ORIENTACTION ont identifié pour vous 16 « soft skills » particulièrement
      prisées dans le domaine professionnel. Vous avez forcément développé au cours de votre
      vie une ou plusieurs de ces « 16 soft skills ». Vous allez vous appuyer sur ces « soft
      skills » pour réussir votre projet d’évolution professionnelle. Et parallèlement à cela,
      vous continuerez à les développer dans le futur grâce à une posture proactive de
      développement professionnel et personnel. Plus vous développerez vos « soft skills » et
      plus vous serez heureux(se) et performant(e).
      `,
  },
];

export const blockParagraphs = [
  {
    id: '15-7',
    text: `
            Savoir poser des questions, prendre des initiatives tout en faisant valider ses
            intuitions, contrôler son travail, demander un feedback à ses collègues, ne pas se
            mettre en colère ou en état de stress au premier conflit qui survient avec un(e)
            client(e) et/ou un(e) collaborateur(trice)… Autant de compétences qui ne s’acquièrent
            pas sur les bancs de la fac, mais bien par l’expérience professionnelle.
        `,
  },
  {
    id: '15-8',
    text: `
        Ces habitudes de travail sont aussi celles qui font qu’un(e) collaborateur(trice) sera
        efficace et performant(e). Et il faut parfois plusieurs années pour acquérir ces
        réflexes.
        `,
  },
];
