/* eslint-disable no-use-before-define */
import { Typography } from '@material-ui/core';
import { Page, Paragrapth, TitlePage, TitleNbr } from '@orientaction/components';
import { publicBook5Path } from '@orientaction/utils';
import { MarginContainer } from '@orientaction/commons';
import useStyles from './style';
import { list } from './context';

const Page27 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={27}>

            <TitlePage>
                DOSSIER : ANTICIPER VOTRE FUTUR
            </TitlePage>

            <MarginContainer small={true}>

                <img className={classes.img} src={`${publicBook5Path}/anticipeLeFuture.png`} alt="logo" />

                <Typography
                    className={classes.paragraphe}
                    style={{
                        fontFamily: 'ITC Avant Garde Gothic Std Medium',
                        marginTop: '10px',
                        textAlign: 'justify',
                        fontWeight: 'lighter'
                    }}
                >
                    Aujourd’hui, nous vivons une période d’incertitude sans précédent. Dans notre vie personnelle ou professionnelle, les incertitudes sont multiples dans un monde de plus en plus complexe. Un CDI ne nous garantit plus la sécurité de l’emploi, l’ancienneté ne nous confère plus une place privilégiée dans une organisation, nos compétences peuvent devenir obsolètes du jour au lendemain, une organisation solide peut disparaître en quelques mois avec  la survenue d’une innovation technologique majeure ou d’un changement de réglementation.
                </Typography>

                <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
                    Lorsque l’on souhaite changer de métier, d’employeur(se), de vie, on doit prendre
                    des décisions. Et ces décisions se heurtent nécessairement au doute et à la peur
                    de l’avenir.
                    <br /><br />
                </Typography>

            </MarginContainer>

            <MarginContainer big={true}>
                <MarginContainer mormal={true}>

                    {list.map((item: any) =>

                        <MarginContainer big={true}>

                            <TitleNbr
                                number={item.id}
                                text={item.label || ""}
                                notBold={true}
                                colorBlack={true}
                                isLight={true}
                                moreSpace={true}
                            />

                        </MarginContainer>

                    )}

                </MarginContainer>
            </MarginContainer>

            <MarginContainer small={true}>

                <Paragrapth align='justify'>
                    Une chose est certaine, on ne peut pas prédire l’avenir avec certitude. La seule
                    chose que l’on puisse faire pour anticiper son avenir, c’est de se poser les
                    bonnes questions et d’adopter les bons réflexes.
                </Paragrapth>

            </MarginContainer>

        </Page>
    );
};

export default Page27
