import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  drawerContent: {
    width: '50%',
    borderTop: '2px solid #23296F',
    borderRadius: 16,
    overflowX: 'hidden',
  },
  closeBtn: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: '0.001rem',
    top: '0.001rem',
    color: '#fff',
  },
  contentChild: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowY: 'auto',
    minHeight: '100vh',
  },
  title: {
    lineHeight: '30px',
    color: '#fff',
    textTransform: 'none',
    marginBottom: 20,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '2px solid #23296F',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  titleContainer: {
    marginTop: 25,
  },
  footerWrapper: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 0 20px 0',
    marginRight: 50,
    borderTop: '1px solid var(--neutres-gris-label, #9799B1)',
    background: '#EDEEF2',
  },
  btnPrimary: {
    textTransform: 'none',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#000A8C',
    color: '#fff',
    marginRight: 20,
    '@media(min-width: 600px)': {},
  },
  textHeader: {
    display: 'none',
    marginLeft: 0,
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
}));

export default useStyles;
