// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page } from '@orientaction/components';
import useStyles from './style';
import { Grid, Typography } from '@material-ui/core';
import Img from '../../Img';
import SimpleBanner from '../../../components/SimpleBanner';

import { MarginContainer } from '@orientaction/commons';
import clsx from 'clsx';

const Page09 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={9}>
      <MarginContainer>
        <Paragrapth align="justify">
          Choisir parmi les 5 possibles est sans doute la décision la plus difficile à prendre.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Réfléchir sur soi est excitant, sympathique et confortable. Imaginer son avenir, rêver est
          une activité extrêmement agréable. Concrétiser son rêve est beaucoup plus difficile : peur
          de se tromper, peur d’échouer, peur de réussir aussi. L’expérience nous a montré que c’est
          l’instant crucial du bilan de compétences. Le seul et dernier conseil que je peux vous
          donner :
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify" colorBlue={true} bold={true}>
          <strong>
            choisissez vite, agissez vite, prenez un chemin et avancez sans vous retourner.
          </strong>
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <div className={classes.cardBgGrey}>
          <Grid container={true} direction="row" justifyContent="center" alignItems="center">
            <Grid item={true} xs={9} md={7}>
              {/* <Img
                                src='rate_vie.jpg'
                                alt='rate_vie'
                                style={{
                                    width: '100%'
                                }}
                            /> */}
              <SimpleBanner bannerContainerOverride={classes.bannerContainerOverride}>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                >
                  J&apos; AI RATÉ MA VIE
                </Typography>
              </SimpleBanner>
            </Grid>
          </Grid>
          <Grid container={true} direction="row" justifyContent="center" alignItems="center">
            <Grid item={true} xs={12} sm={12} md={5}>
              <Img
                src="rate_ma_vie.jpg"
                alt="Chaque homme doit inventer son chemin."
                className={classes.img}
              />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={7}>
              <Typography className={classes.paragraphe}>
                Imaginez que vous avez 65 ans. Vous venez de partir à la retraite. Quand vous
                regardez derrière vous, vous vous dites : j’ai raté ma vie ! Certes vous avez eu de
                beaux enfants, qui ont réussi et sont bien insérés dans la vie, certes vous avez su
                préserver votre vie de couple, votre conjoint(e) est là, près de vous, la bague au
                doigt.
              </Typography>
              <Typography className={classes.paragraphe}>
                Il (Elle) est à la retraite lui (elle) aussi. Vous avez voyagé. Vous êtes
                propriétaire de votre maison. Vos comptes en banque sont bien remplis. Tout de
                l’extérieur donne l’image d’une vie heureuse et réussie. Mais vous dans tout cela ?
                Vous rêviez de travailler à l’étranger. Vous auriez voulu avoir un autre métier,
                faire d’autres rencontres, habiter dans une autre maison, dans une autre ville. Ce
                ne sont pas vos rêves que vous avez réalisés, seulement ceux des autres ! Les désirs
                de votre conjoint(e), de vos enfants, de votre employeur… Et maintenant il est trop
                tard !
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.paragraphe}>
            Vous voudriez tout plaquer, tout recommencer. Mais vous ne vous en sentez plus la force,
            pris(e) au piège et épuisé(e) par toutes ces années de renoncement. Vous n’avez plus
            qu’à profiter modestement des quelques années qui vous restent.
          </Typography>
        </div>

        <MarginContainer big={true}>
          <Typography className={classes.paragraphe}>
            Vous ne voulez pas vivre cela un jour. Vous considérez que ce n’est pas digne de vous.
            Vous êtes venu(e) avec un désir : le désir de changer ! Car vous savez que vous en avez
            besoin. Vous en avez besoin, tout simplement, pour être plus heureux(se). C’est pour
            cette raison que vous devez aller au bout de votre démarche. Vous en êtes convaincu(e).
            À vous maintenant de trouver l’énergie, la force, le courage d’aller au terme de ce
            processus, de réussir ce changement auquel vous aspirez avec tant de volonté et d’envie.
          </Typography>
        </MarginContainer>
      </MarginContainer>
    </Page>
  );
};

export default Page09;
