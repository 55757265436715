/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ChipIsDone, ChipTime } from '@orientaction/commons';
import { useResults } from '@orientaction/hooks';
import { getFromLS, publicSvgPath } from '@orientaction/utils';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './style';

interface ICardMajorTest {
  image?: string;
  route: string;
  time: string;
  title: string;
  isActive?: boolean;
  testId: string | number;
}

const getInactiveImageByTest = (id: string | number) => {
  let imageUri;
  switch (id) {
    case 1:
      imageUri = `${publicSvgPath}/meaning_value_grey.svg`;
      break;
    case 2:
      imageUri = `${publicSvgPath}/needs_grey.svg`;
      break;
    case 3:
      imageUri = `${publicSvgPath}/needs_pro_grey.svg`;
      break;
    case 4:
      imageUri = `${publicSvgPath}/preference_grey.svg`;
      break;
    case 5:
      imageUri = `${publicSvgPath}/harmony_grey.svg`;
      break;
    case 6:
      imageUri = `${publicSvgPath}/study_grey.svg`;
      break;

    default:
      imageUri = `${publicSvgPath}/QR.png`;
      break;
  }
  return imageUri;
};

const getBuyLinkByTest = (id: string | number) => {
  let link;
  switch (id) {
    case 1:
      link = 'https://orientaction.kneo.me/shop/view/D0A37F';
      break;
    case 2:
      link = 'https://orientaction.kneo.me/shop/view/120C39';
      break;
    case 3:
      link = 'https://orientaction.kneo.me/shop/view/5726D7';
      break;
    case 4:
      link = 'https://orientaction.kneo.me/shop/view/752F26';
      break;
    case 5:
      link = 'https://orientaction.kneo.me/shop/view/1755C3';
      break;
    case 6:
      link = 'https://orientaction.kneo.me/shop/view/7EC7E8';
      break;
    case 7:
      link = 'https://orientaction.kneo.me/shop/view/K0976011';
      break;
    default:
      link = '';
      break;
  }
  return link;
};

const ActiveCard: FC<ICardMajorTest> = ({
  testId = '',
  image,
  route,
  time,
  title,
  isActive = true,
}) => {
  const [lock, setLock] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const classes = useStyles();

  const { getResultByUser } = useResults();

  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const [pastille, showPastille] = useState(false);

  const fecthData = async () => {
    try {
      const resultData = await getResultByUser(user.id);
      showPastille(resultData?.length > 0);
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    fecthData();
  }, []);

  const imageUri = isActive ? image : getInactiveImageByTest(testId);
  const nodeRef = useRef(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const testBuyLink = getBuyLinkByTest(testId);
    window.open(testBuyLink, '_blank');
    return true;
  };

  const handleLeave = () => {
    setAnchorEl(null);
    setLock(true);
  };

  const handleEnter = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setLock(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Link
          to={route}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: 'none',
          }}
        >
          <Card className={classes.cardContainer}>
            <Grid container={true} spacing={1}>
              <Grid item={true}>
                <img
                  className={classes.img}
                  src={imageUri !== '' ? imageUri : `${publicSvgPath}/QR.png`}
                  alt="logo"
                />
              </Grid>

              <Grid item={true} xs={true} style={{ position: 'relative' }}>
                <Typography color="primary" className={classes.fontTitleCard}>
                  <b>{title}</b>
                </Typography>

                <Grid
                  container={true}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <div className={classes.flexDiplay}>
                      <div>
                        <ChipTime time={time} />
                      </div>
                      <div>{pastille && <ChipIsDone />}</div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default ActiveCard;
