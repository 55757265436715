import Grid from '@material-ui/core/Grid';
import { Paragrapth } from '@orientaction/components';
import { FC } from 'react';
import useStyles from './style';

interface IProps {
    content: string;
    label: string;
}

const Title: FC<IProps> = ({
    content,
    label,
}) => {

    const classe = useStyles();

    return (
        <Grid
            container={true}
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
        >
            <Grid item={true} className={classe.overrideParagraphStyle}>
                <Paragrapth
                    colorBlue={true}
                    align="justify"
                    bold={true}
                >
                    {label} :
                </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} sm={9}>
                <Paragrapth
                    bold={true}
                    colorBlue={true}
                    align="justify"
                >
                    {content}
                </Paragrapth>
            </Grid>
        </Grid >
    );
};

export default Title;
