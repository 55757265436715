/* eslint-disable no-use-before-define */
import { Page, TitleNbr, Paragrapth, TitlePage } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';
import Img from '../../Img';

const Page33 = (props: any) => {

    return (
        <Page id={33}>

            <div style={{ textAlign: 'center' }}>

                <TitlePage>
                    RISQUES ET OPPORTUNITÉS D’UNE<br />
                    CRÉATION/REPRISE D’ENTREPRISE
                </TitlePage>

                <Img
                    src='alert.jpg'
                    alt='alert'
                    style={{
                        width: '40%'
                    }}
                />

                {
                    context.map((item: any) => (
                        <MarginContainer big={true}>

                            <MarginContainer>
                                <TitleNbr number={item.id} text={item.title} />
                            </MarginContainer>

                            {
                                item.listContext.map((itemList: any) => (
                                    <MarginContainer>

                                        <Paragrapth align='start'>

                                            <Paragrapth align='start' bold={true} colorBlue={true}>
                                                {itemList.title} :
                                            </Paragrapth> {itemList.content}
                                        </Paragrapth>

                                    </MarginContainer>
                                ))
                            }

                        </MarginContainer>
                    ))
                }

            </div>

        </Page>
    );
};

export default Page33;
