import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    controls: {
      marginTop: '10px',
    },
    img: {
      width: '120px',
      height: '120px',
      [theme.breakpoints.down('sm')]: {
        width: '75px',
        height: '75px',
      },
      borderRadius: 10,
    },
    inactiveImage: {
      border: '2px solid #FFF',
    },
    fontTitleCard: {
      textDecoration: 'none',
      fontFamily: 'Poppins',
      fontWeight: 700,
      color: '#000A8C',
      cursor: 'pointer',
      fontSize: '18px',
      [theme.breakpoints.down(1440)]: {
        fontSize: '15px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14.5px',
      },
    },
    fontTitleInactiveCard: {
      textDecoration: 'none',
      fontFamily: 'Poppins',
      fontWeight: 700,
      color: '#606060',
      cursor: 'pointer',
      fontSize: '18px',
      [theme.breakpoints.down(1440)]: {
        fontSize: '15px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14.5px',
      },
    },
    fontTitleCardFavorite: {
      fontSize: '23px',
      fontWeight: 'bold',
      [theme.breakpoints.down(1440)]: {
        fontSize: '22px',
      },
    },
    fontTextCard: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
      color: '#0E1247',
      marginTop: '5px',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&>p': {
        marginTop: 5,
        marginBottom: 5,
      },
    },
    btnIcon: {
      border: '1px solid black',
      borderRadius: '10px !important',
      width: 32,
      height: 32,
      marginRight: '8px',
      padding: '2px',
    },
    styleIcon: {
      color: '#0E1247',
      width: '20px',
      height: '20px',
    },
    fontChecked: {
      fontSize: '13px',
      color: 'white',
      background: 'linear-gradient(180deg, #FFDC27 0%, #E63C3C 48.96%, #000A8C 100%)',
      borderRadius: '50px',
    },
    chipsWhite: {
      fontSize: '11px',
      borderColor: 'white',
      height: 'auto !important',
      paddingTop: '5px',
      color: 'white',
      paddingBottom: '5px',
      paddingLeft: '6px',
      paddingRight: '6px',
      '&> span': {
        paddingRight: '8px !important',
      },
      backgroundColor: 'white',
    },
    cardContainer: {
      padding: '10px',
      borderRadius: '10px',
      boxShadow: 'none',
      backgroundColor: 'white',
    },
    chipTime: {
      backgroundColor: 'white',
      color: 'grey',
      height: 'auto',
      '&> svg': {
        marginLeft: '0px !important',
        marginRight: '0px !important',
      },
      '&> span': {
        paddingRight: '5px !important',
      },
    },
    chipTime2: {
      backgroundColor: 'white',
      color: 'grey',
      height: 'auto',
      '&> span': {
        padding: '0px !important',
      },
    },
    flexDiplay: {
      float: 'left',
      display: 'flex',
      alignItems: 'center',
    },
    flexDisplayFavorite: {
      bottom: 6,
    },
    contentImgFavorite: {
      position: 'relative',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&::after': {
        content: '',
        display: 'block',
        paddingBottom: '100%',
        border: '1px solid red',
      },
    },
    imgFavorite: {
      width: '100%',
      paddingBottom: '73%',
      backgroundSize: 'cover',
      borderRadius: 15,
      '@media only screen and (max-width: 1500px)': {
        width: '100%',
        paddingBottom: '100%',
      },
    },
    start: {
      width: 120,
      height: 120,
      position: 'relative',
      left: 65,
    },
    bouttom: {
      padding: '5vh',
    },
    isFavorite: {
      //   borderLeftStyle: 'solid',
      minHeight: 'calc(146px * 4)',
      maxHeight: 'calc(146px * 4)',
      //  borderImage: 'linear-gradient(to bottom, rgba(255, 220, 39, 1), rgba(230, 60, 60, 1),rgba(0, 10, 140, 1)) 1',
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      marginTop: -24,
      background: 'transparent',
    },
    slideContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    inactive: {
      borderRadius: 10,
      border: '3px solid  #9799B1',
      background: '#EDEEF2',
    },
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: '#23296F',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#23296F',
        borderRadius: '4px',
        color: 'white',
        textTransform: 'none',
      },
      '&:focus': {
        background: '#23296F',
        borderRadius: '4px',
        color: 'white',
        textTransform: 'none',
      },
    },
    textHeader: {
      display: 'block',
      marginLeft: 0,
    },
    padlock: {
      position: 'absolute',
      bottom: -7,
      right: -7,
    },
    lock: {
      position: 'absolute',
      bottom: 0,
      right: 2,
      width: 25,
      height: 25,
      backgroundColor: '#23296F',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: 3,
      transition: 'all .8s',
      cursor: 'pointer',
      '&::after': {
        position: 'absolute',
        content: '""',
        width: 8,
        height: 8,
        borderRadius: '50%',
        borderTop: '5px solid #23296F',
        borderBottom: '5px solid #23296F',
        borderRight: '5px solid #23296F',
        borderLeft: '5px solid #23296F',
        top: -10,
        left: 3,
        transform: 'all .8s !important',
      },
    },
    unlock: {
      '&::after': {
        borderTop: '5px solid #23296F',
        borderBottom: '5px solid #23296F',
        borderRight: '5px solid transparent',
        borderLeft: '5px solid #23296F',
      },
    },
    buyButtonIcon: {
      marginLeft: 5,
    },
  })
);
