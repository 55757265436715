import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      display: 'block',
      width: '80%',
      [theme.breakpoints.down(1200)]: {
        width: '40%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        width: '30%',
      },
    },
    title: {
      width: '70%',
      marginBottom: '4vh',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        left: 0,
      },
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    bannerStyleOverride: {
      width: '100%',
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
      width: '85%',
      margin: '0 auto',
      [theme.breakpoints.down(1200)]: {
        width: '100%',
        display: 'block',
      },
    },
    simpleContent: {
      width: '85%',
      margin: '0 auto',
      [theme.breakpoints.down(1200)]: {
        width: '100%',
      },
    },
    contentIt: {
      display: 'block',
      width: '50%',
      [theme.breakpoints.down(1200)]: {
        width: '100%',
        display: 'block',
      },
    },
  }),
);

export default useStyles;
