import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    width: '95%',
  },
  btnPrimary: {
    minHeight: 48,
    width: 190,
    textTransform: 'none',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    marginTop: 5,
  },
  btnDraft: {
    minHeight: 48,
    width: 190,
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#000A8C',
    marginTop: 5,
    marginRight: 5,
    borderRadius: 3,
    opacity: 1,
    border: 'solid #000A8C',
  },
  textH3: {
    color: '#000A8C',
  },
  btnCanceled: {
    minHeight: 48,
    width: 190,
    textTransform: 'none',
    color: 'black',
    backgroundColor: 'white',
    marginTop: 5,
    marginRight: 5,
    borderRadius: 3,
    opacity: 1,
    border: 'solid black',
  },
  btnPrimaryContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
