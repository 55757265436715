import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paddingContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 50,
    marginTop: -33,
  },
  bodycenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 8,
  },
  restitutionPageTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
  },
  button: {
    marginLeft: '3vw',
    color: '#fff',
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.primary.dark,
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 500,
    margin: '32px 0px 32px 106px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 12,
    },
  },
  btnHistory: {
    position: 'relative',
    top: -50,
    right: 25,
    alignSelf: 'end',
  },
}));
