import { AdminTestContext } from '@orientaction/Context';
import { forwardRef, useContext } from 'react';
import style from './style';
import TestMainImage from './TestMainImage';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span style={{ fontSize: 26, margin: 8 }}>+</span>
      <span>Importer une image</span>
    </div>
  );
};

const LeftImage = forwardRef((_, ref) => {
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const handleFileUrlChange = (url: string) => {
    updateTest({
      test: {
        ...test.test,
        image: url,
      },
    });
  };

  const handleDeleteFile = () => {
    updateTest({
      test: {
        ...test.test,
        image: '',
      },
    });
  };

  return (
    <>
      <TestMainImage
        fileUrl={test.test.image}
        handleFileUrlChange={handleFileUrlChange}
        placeholder={placeholder}
        pathPrefix="tests"
        sectionTop={true}
        error={test?.errors?.image}
        ref={ref as any}
      />
      <div onClick={handleDeleteFile} className={classes.deleteFileTextContainer}>
        Supprimer
      </div>
    </>
  );
});

export default LeftImage;
