import { useState, useLayoutEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useStyles } from './style';
import { Button, useMediaQuery, Theme, CircularProgress } from '@material-ui/core';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFView = (props: any) => {
  const { pdf } = props;
  const [numPages, setNumPages] = useState<number>();
  const [containerRef, setContainerRef] = useState<any>(null);
  const ref = useRef<any>(null);

  const onDocumentLoadSuccess = (data: any): void => {
    setNumPages(data.numPages);
  };
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useLayoutEffect(() => {
    setContainerRef(ref?.current?.offsetWidth);
    console.log({ width: ref?.current?.offsetWidth });
  }, [containerRef]);
  const classe = useStyles();

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <Document
        file={{ url: pdf }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={
          <div className={classe.contentLoader}>
            <CircularProgress />
          </div>
        }>
        {Array.from(new Array(numPages), (el, index) => (
          <Page width={containerRef} key={`page_${index + 1}`} pageNumber={index + 1} loading="" />
        ))}
      </Document>
    </div>
  );
};

export default PDFView;
