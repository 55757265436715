/* eslint-disable import/first */
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { RadioButtonLetter } from '@orientaction/components';
import { useState } from 'react'
import { cloneDeep } from 'lodash';
import { useBook2 } from "@orientaction/hooks_book";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '20px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
    '@media only screen and (max-width: 600px)': {
      paddingTop:10,
      paddingBottom:10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop:10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom:10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal'
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal'
  },
  cardBgGrey: {
    background: '#E6E6E6'
  },
  borderTable: {
    borderRadius: '1px solid #2b56a5',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important'
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back1.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    fontSize: 18,
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
});

const TablePersonality = (props: any) => {
  const classes = useStyles();
  const { toggleA_B } = props;
  const { setTestPersonality, testPersonality } = useBook2();
  const [dataAB, setDataAB] = useState<any>(testPersonality);
  const handleChangeNumber = (data: any) => {
    const dataToAdd = cloneDeep(dataAB);
    dataToAdd[data.index] = data.value;
    setDataAB(dataToAdd);
    setTestPersonality(dataToAdd);
  };
  return (
    <div className={classes.borderTable}>
      {toggleA_B.map((elem: any, index: number) => {
        return (
          <Grid container={true}>
            <Grid item={true} xs={12} sm={4} className={classes.categorieB} style={{position: 'relative',display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #2b56a5',}}>
              <Typography variant="h2" component="h2" className={clsx(classes.h7)} >
                {elem.title}
              </Typography>
              <div className={classes.floatIndex}>
                <span>{elem.index}</span>
              </div>
            </Grid>
            <Grid item={true} xs={12} sm={8}>
              <Grid container={true}>
                <Grid item={true} xs={12} style={{textAlign: 'center', border: '1px solid #2b56a5',}} className={classes.categorieA}>
                  <RadioButtonLetter
                    radios={elem.toggle}
                    onChange={handleChangeNumber}
                    name="operator1"
                    index={index}
                    defaultPropsValue={dataAB && dataAB[index] ? dataAB[index] : ''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </div>
  );
};
export default TablePersonality;
