import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardBgGrey: {
            padding: 20,
            margin: '2vh 0',
            background: '#e8eef4',
            fontStyle: 'italic',
            '@media only screen and (max-width: 600px)': {
                padding: 20,
            },
            '@media only screen and (max-width: 361px)': {
                padding: 15,
            },
        },
      
        tableContainer: {
            border: '1px solid'
        },
        body: {
            padding: '1.5vh 2vh',
            minHeight: '60vh'
        },
        marginContent: {
            margin: '6vh 0'
        }
    })
);

export default useStyles;