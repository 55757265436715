// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../Img';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page23 = (props: any) => {
  const classe = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_changerEmployeur.png' : 'changerEmployeur.jpg';
  }, [language]);

  return (
    <Page id={23}>
      <TitleChapitre nbr={4} />

      <MarginContainer>
        <TitlePage>
          OPTION N°3 : <br />
          CHANGER D’EMPLOYEUR
        </TitlePage>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Vous ressentez une grande insatisfaction par rapport à votre travail ou un mal-être.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Cette insatisfaction ne concerne pas votre métier en tant que tel, mais plutôt
          l’environnement dans lequel vous l’exercez. Relations avec vos collègues et/ou votre
          hiérarchie, rémunération, niveau de responsabilité, temps de travail, temps de transport,
          etc. autant de sources d’insatisfaction ou de mal-être qui pourraient disparaître en
          changeant d’employeur…
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Changez d’employeur et retrouvez du plaisir au travail !
        </Paragrapth>
      </MarginContainer>

      <MarginContainer big={true}>
        <Img src={image} alt="CHANGER D’EMPLOYEUR" className={classe.img} />
      </MarginContainer>
    </Page>
  );
};
export default Page23;
