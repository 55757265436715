/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { Paragrapth, TitlePage, Page, Img } from '@orientaction/components';
import useStyles from '../commons/Style';
import useStyles2 from '../../../style';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useBook4 } from '@orientaction/hooks_book';
import { imgBook1, publicBookPath } from '@orientaction/utils';

const Page04 = (props: any) => {
  const classe = useStyles();
  const classes = useStyles2();

  const { updateIndoorWeather, state } = useBook4();

  const list = [
    {
      title: 'LA PRESTATION CORRESPOND PARFAITEMENT À VOS ATTENTES',
      img: `meteo1.png`,
      id: 1,
    },
    {
      title: 'LA PRESTATION CORRESPOND PRESQUE TOTALEMENT À VOS ATTENTES, MAIS…',
      img: `meteo2.png`,
      id: 2,
    },
    {
      title: 'LA PRESTATION NE CORRESPOND PAS TOUT À FAIT À VOS ATTENTES',
      img: `meteo3.png`,
      id: 3,
    },
    {
      title: 'LA PRESTATION NE CORRESPOND PAS DU TOUT À VOS ATTENTES',
      img: `meteo4.png`,
      id: 4,
    },
  ];

  const handleChange = (e: any) => {
    const { name } = e.target;

    const convert: number = name || '';

    updateIndoorWeather(+convert);
  };

  const click = (nbr: number) => (e: any) => {
    updateIndoorWeather(nbr);
  };

  return (
    // eslint-disable-next-line prettier/prettier
        <Page
            id={4}
            readOnly={props.readOnly}
        >
      <TitlePage>VOTRE MÉTÉO INTÉRIEURE</TitlePage>

      <div className={classe.container}>
        <div>
          <Paragrapth align="justify">
            Avant chaque entretien, nous vous proposons de donner votre ressenti sur le déroulement
            de votre bilan de compétences. Cela vous permettra d’avoir une prestation personnalisée
            et répondant parfaitement à vos attentes. Ce support ludique permettra d’affiner
            l’accompagnement réalisé par votre consultant(e).
          </Paragrapth>
        </div>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Paragrapth colorBlue={true} align="center" bold={true}>
            Cochez la case de l’image qui correspond le mieux à votre ressenti.
          </Paragrapth>
        </div>
      </div>
      <div
        style={{
          width: '80%',
          margin: '2vh auto',
        }}>
        <Grid container={true} direction="row" spacing={5}>
          {list.map((item: any) => (
            <Grid
              item={true}
              xs={12}
              sm={6}
              style={{
                cursor: 'pointer',
                position: 'relative',
              }}
              onClick={click(item.id)}>
              <div
                style={{
                  cursor: 'pointer',
                  position: 'relative',
                }}>
                <div className={classes.textPositionInside}>
                  <Typography className={classes.textStyleInside}>{item.title}</Typography>
                </div>
                <img
                  src={`${publicBookPath}/${item.img}`}
                  alt="logo"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!(state.indoorWeather === item.id)}
                    onChange={handleChange}
                    name={item.id}
                    color="primary"
                  />
                }
                label=""
                style={{ position: 'absolute', bottom: '10%', right: '5%' }}
              />
            </Grid>
          ))}
        </Grid>
      </div>

      <Paragrapth align="center">
        Vous n’osez pas en parler avec votre consultant(e) ou vous souhaitez proposer une
        amélioration
        <br />
        au créateur de la méthode ? Contactez-nous :{' '}
        <strong>qualite@orientaction-groupe.com</strong>
      </Paragrapth>
    </Page>
  );
};
export default Page04;
