/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { figure32_page1, figure32_page2, figure32_page3, figure32_page4, figure32_page5 } from '@orientaction/utils';
import { TableAspiration, TableMetiers, Page } from '@orientaction/components';
import concat from 'lodash/concat';
import chunck from 'lodash/chunk';
import { useBook1 } from "@orientaction/hooks_book";

const Page29 = (props: any) => {

  const { book, updateFive_Jobs } = useBook1();

  const allFiguresProtected = concat(figure32_page1, figure32_page2, figure32_page3, figure32_page4, figure32_page5)

  return (
    <Page id={29} readOnly={props.readOnly}>
      <TableAspiration figure32={chunck(allFiguresProtected, 12)[2]} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TableMetiers
          handleChange={updateFive_Jobs}
          defaultValue={book.fiveJobs}
          figure32={allFiguresProtected}
        />
      </div>
    </Page>
  );
  
};

export default Page29;
