/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook1 } from '@orientaction/utils';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Page } from '@orientaction/components';
import { useBook1 } from "@orientaction/hooks_book";


const Page17 = (props: any) => {

  const classes = useStyles();

  const { book, updateMajory_B } = useBook1();

  const handleChange = (event: any) => {
    const { name, checked } = event.target;
    updateMajory_B({ [name]: checked });
  };

  return (
    <Page id={17} readOnly={props.readOnly}>
      <div style={{ marginTop: '50px' }}>
        <Typography variant="h3" component="h3" style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'left', fontWeight: 'lighter' }}>
          <span className={classes.number}>2</span>
          <span className={classes.h3}>VOUS AVEZ UNE MAJORITÉ DE</span>
          <span className={clsx(classes.letter, classes.categorieB)}>B</span>
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Ce qui compte le plus pour vous aujourd’hui (et qui comptera le plus demain) est de réussir <span className={classes.boldText}> VOTRE </span> vie.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous avez décidé de fixer vous-même les critères de votre réussite. Vous êtes attentif(ve) à ce que vous ressentez et à ce que ressentent vos proches.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous privilégiez votre santé et votre bien-être.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          La réussite sociale n’est pas ou n’est plus une priorité pour vous.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous cherchez à vous réaliser dans votre travail, que ce soit à travers vos valeurs, la
          qualité de vos rencontres ou des relations que vous entretenez avec vos collègues,
          vos client(e)s ou votre hiérarchie.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous avez à cœur de trouver un juste équilibre entre votre temps de travail et vos autres domaines de vie :
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          La famille, le couple, vos ami(e)s et vos activités personnelles.
        </Typography>
        <Typography className={classes.boldText} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '30px', textAlign: 'justify', fontStyle: 'italic' }}>
          Pour aller plus loin, identifiez ce qui est le vrai moteur de votre ambition :
        </Typography>
      </div>
      <div style={{ marginTop: '50px', }}>
        <div className={classes.cardBgGrey} >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={!!book.majoryB.gilad} color="primary" onChange={handleChange} name="gilad" />}
                label={
                  <div style={{color: 'black'}}>
                    <span className={classes.boldText}>L’équilibre : </span> entre vos différents domaines de vie (famille, couple, vie amicale et associative, activités personnelles).
                  </div>
                }
                style={{ paddingTop: 7, paddingBottom: 7 }}
              />
              <FormControlLabel
                control={<Checkbox checked={!!book.majoryB.jason} color="primary" onChange={handleChange} name="jason" />}
                label={
                  <div style={{color: 'black'}}>
                    <span className={classes.boldText}>L’harmonie : </span> entre vous et les personnes de votre entourage. Ce qui compte le plus, c’est d’avoir des relations de qualité avec les autres.
                  </div>
                }
                style={{ paddingTop: 7, paddingBottom: 7 }}
              />
              <FormControlLabel
                control={<Checkbox checked={!!book.majoryB.antoine} color="primary" onChange={handleChange} name="antoine" />}
                label={
                  <div style={{color: 'black'}}>
                    <span className={classes.boldText}>Le bien-être : </span> avoir une activité en adéquation avec votre biorythme, vos envies et vos désirs. Prendre du plaisir dans ce que vous faites.
                  </div>
                }
                style={{ paddingTop: 7, paddingBottom: 7 }}
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
      <div style={{ marginBottom: '20' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={4}>
            <img className={classes.img} src={`${imgBook1}/dog.png`} alt="logo" />
          </Grid>
        </Grid>
      </div>
    </Page>

  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
      marginTop: '-15px'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    letter: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10%',
      padding: '7px',
    },
    categorieB: {
      color: '#2c57a5 !important',
      background: '#ebebeb',
    },
    categorieA: {
      color: '#2c57a5',
      background: '#fff',
      border: '1px solid #2c57a5'
    },
    h3: {
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#000A8C',
      fontWeight: 'bold',
    },
    boldText: {
      color: '#000A8C',
      fontWeight: 300,
    },
  })
);
export default Page17;
