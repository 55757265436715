/* eslint-disable react/no-array-index-key */
import { Theme } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleRounded } from '@material-ui/icons';
import { FC } from 'react';

type Props = {
  title: string;
  subtitle: string;
  items: string[];
};

const VideoText: FC<Props> = ({ title, subtitle, items }) => {
  const cls = style();
  return (
    <div className={cls.root}>
      <div className={cls.preLiTextContainer}>
        <span className={cls.title}>{title}</span>
        <span className={cls.paragraph}>{subtitle}</span>
      </div>
      <List className={cls.listItemContainer}>
        {items.map((item: string, index: number) => (
          <ListItem key={index} className={cls.listItem}>
            <ListItemIcon>
              <CheckCircleRounded fontSize="small" color="secondary" />
            </ListItemIcon>
            <ListItemText className={cls.liText} primary={item} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};
const style = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '100vh',
    overflow: 'auto',
    padding: 10,
  },
  title: {
    color: '#FFF',
    font: 'normal normal bold 20px ITC Avant Garde Gothic Std Bold',
    letterSpacing: 0,
    marginBottom: 10,
    textAlign: 'center',
  },
  paragraph: {
    height: 25,
    font: 'normal normal bold 15px ITC Avant Garde Gothic Std Medium',
    color: '#FFF',
    textAlign: 'center',
  },
  liText: {
    color: '#FFF',
    '& .MuiTypography-body1': {
      font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium !important',
    },
  },
  preLiTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 18,
  },
  listItemContainer: {
    margin: 'auto',
  },
  listItem: {
    paddingLeft: 0,
    '& .MuiListItemIcon-root': {
      minWidth: '30px',
    },
  },
}));
export default VideoText;
