import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -82,
      [theme.breakpoints.down(1300)]: {
        marginTop: -89,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 16,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 11,
      },
    },
    bannerStyleOverride: {
      width: '100%',
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'normal',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '60%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '70%',
      },
    },
    imgTitle: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
  }),
);

export default useStyles;
