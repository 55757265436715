// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
} from '@orientaction/components';
import useStyles from './style';
import Img from '../../components/Img';
import { MarginContainer } from '@orientaction/commons';

const Page11 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={11}
        >
            <TitlePage>
                UN PROJET QUI REND HEUREUX(SE) <br />
                ET PERFORMANT(E)
            </TitlePage>

            <MarginContainer>

                <Paragrapth align='justify'>
                    Le temps est venu maintenant de choisir votre projet : un projet qui vous rendra
                    heureux(se) et performant(e). Heureux(se), parce qu’il répondra à vos aspirations.
                    Pas à toutes vos aspirations sans doute, mais aux plus essentielles et à celles
                    auxquelles vous entendez répondre dans votre travail. Performant(e), parce qu’être
                    performant(e), c’est avoir de la valeur.
                </Paragrapth>

            </MarginContainer>

            <MarginContainer mormal={true}>

                <Paragrapth align='justify'>
                    Les employeurs(ses) recrutent des personnes qui ont de la valeur. Il faudra donc
                    choisir un projet qui répond à vos aspirations, mais qui vous permette aussi d’exploiter
                    vos compétences et vos talents naturels.
                </Paragrapth>

            </MarginContainer>

            <MarginContainer big={true}>
                <Img
                    src='projectToMakeHappy.png'
                    alt='UN PROJET QUI REND HEUREUX(SE) ET PERFORMANT(E)'
                    className={classe.img}
                />
            </MarginContainer>

        </Page>
    );
};
export default Page11;
