// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247',
      },
      // '&>div': {
      //   paddingTop: 12,
      //   paddingBottom: 12,
      // },
    },
    listSubheader: {
      fontWeight: 'bold',
      color: '#000A8C',
      textTransform: 'none',
    },
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: '#0E1247',
      },
      // '& div input': {
      //   paddingTop: 10,
      //   paddingBottom: 10,
      // },
    },
    label: {
      color: '#0E1247',
      fontSize: '13px',
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      letterSpacing: 0
    }
  })
);
