import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '0',
            display: 'flex',
            alignItems: 'center',
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
            borderBouttom: 'none',
            border: '1px solid #BCBCBC',
            paddingTop: '0.75vh',
            paddingLeft: '1.5vh',
            backgroundColor: 'white',
            borderRadius: '4px',
            width: '90%',
            '&.MuiInput-underline': {
                borderBouttom: 'none'
            }
        },
        iconButton: {
            color: 'rgba(14, 18, 71, 1)',
            position: 'relative',
            left: 0,
            fontSize: 35,
        },
        divider: {
            height: 28,
            margin: 4,
        },
        btnPrimary: {
            padding: '0 2.5vh',
            minHeight: 30,
            textTransform: 'none',
            background: 'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
            color: '#fff',
        },
        btnEdit: {
            padding: '0 2.5vh',
            minHeight: 30,
            textTransform: 'none',
            color: '#0E1247',
            margin: '0 1vh',
            border: '1px solid #BCBCBC',
        },
        bodycenter: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
        },
        cardContent: {
            marginRight: '-3%',
            [theme.breakpoints.down(600)]: {
                marginRight: '0%',
            }
        },
        inlineContent: {
            width: '30%',
            marginRight: '3%',
            marginBottom: '1.25%',
            float: 'left',
            [theme.breakpoints.down(1000)]: {
                width: '48%',
                margin: '1vh 0.5vh',
            },
            [theme.breakpoints.down(600)]: {
                float: 'none',
                width: '100%',
                margin: '1vh 0',
            }
        },
        both: {
            clear: 'both'
        }
    })
);