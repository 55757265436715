import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    backgroundColor: '#fff',
    padding: 15,
    width: '100%',
  },
  accordionResponseSectionTitleContainer: {
    marginTop: 10,
    marginBottom: 5,
  },
  accordionResponseSectionTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
  },
  overrideDividerClassname: {
    color: '#CDCEDA',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default useStyles;
