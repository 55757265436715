/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { ApiActions } from '@orientaction/api-actions';
import { apiState } from '@orientaction/reducers';
import { TestUser } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '../utils/utils';

// interface dataApi {
//   // 
// }

const useTestUser = () => {
  const dispatch = useDispatch();
  // const data: dataApi = useSelector(apiState);
  const token = getFromLS('userToken') || '';

  const getTestUsers = async (idTest: string) => {
    const testUsers = await TestUser.getTestUsers(token, idTest);
    return testUsers.data?.data;
  };

  return {
    getTestUsers,
  };
};

export default useTestUser;
