// put style here
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '75vh',
    },
    formContainer: {
      width: '100%',
      '@media (min-width: 1250px)': {
        maxWidth: 410,
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fieldWithLabel: {
      marginTop: 20,
      width: '100%',
    },
    errorField: {
      color: theme.palette.warning.main,
      font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    },
    input: {
      flex: 1,
      border: `1px solid #E3E8F0`,
      width: '100%',
      marginTop: 5,
      height: 48,
      '&:before': {
        display: 'none',
      },
      '& input': {
        fontSize: 13,
        padding: 10,
      },
    },
    inputPassword: {
      paddingRight: 5,
    },
    labelText: {
      margin: 'auto',
      font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
    },
    btnConnectContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 20,
      width: '100%',
    },
    btnConnect: {
      width: '100%',
      background:
        'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      borderRadius: 3,
      opacity: 1,
      textTransform: 'none',
      color: '#fff',
    },
    forgotPasswordLink: {
      marginTop: 20,
    },
    forgotPasswordLinkText: {
      color: '#000A8C',
      textDecoration: 'underline',
      font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
    },
    passwordErrorField: {
      color: theme.palette.warning.main,
      font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
      minHeight: 20,
      maxWidth: 330,
    },
    eyeImg: {
      cursor: 'pointer',
      zIndex: 1,
    },
    inputWitAdornment: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    h3: {
      fontSize: '20px',
      color: '#000A8C',
      fontWeight: 'bold',
    },
    boldText: {
      color: '#000A8C',
      fontWeight: 800,
      fontSize: '14px',
    },
    card: {
      boxShadow: '-1px 5px 15px -1px rgba(140,135,140,0.2)',
      padding: 40,
      borderRadius: '10px',
    },
    signupLink: {
      marginTop: 20,
      cursor: 'pointer',
    },
  })
);
