/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page26 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={26}>
      <div style={{ marginBottom: '20px' }}>
        <div className={classes.cardBgGrey}>
          <div style={{ marginBottom: '30px' }}>
            {/* <img className={classes.imgTailleur} src={`${imgBookIntro}/titre40.png`} alt="logo" /> */}
            <SimpleBanner>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                LE GUÉRISSEUR
              </Typography>
            </SimpleBanner>
          </div>
          <img className={classes.img} src={`${imgBookIntro}/guerisseur.png`} alt="logo" />
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Je me souviens d’un jeune homme qui exerçait la fonction de commercial export dans la
            mode. Il avait décidé à trente-deux ans de se reconvertir. Il voulait devenir
            kinésiologue : une médecine parallèle assez peu reconnue en France.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Sa compagne, juriste de profession, avec laquelle il avait eu un enfant, s’opposa
            violemment à cette reconversion. C’était impensable pour elle que l’homme avec qui elle
            avait décidé de faire sa vie soit kinésiologue, une profession qu’elle jugeait « en
            marge ».
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Cela ne correspondait pas à son idéal. Elle ne voulait pas être la femme d’un
            kinésiologue, elle ne voulait pas que son fils ait pour père un kinésiologue. Dans son
            système de référence, cette profession n’avait aucune valeur. Elle aurait eu honte de
            dire à ses collègues ou à ses amis qu’elle était en couple avec un kinésiologue.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Elle en devenait rouge rien que d’y penser. Cette reconversion remettait en cause son
            couple et cela la déstabilisait profondément.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Mon client persista, car il savait que c’était sa voie, celle qui répondrait à ses
            besoins les plus essentiels. Elle finit par se résigner. Il devint kinésiologue malgré
            elle.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Ce que nous montre cette histoire, c’est à quel point le changement est susceptible de
            modifier la nature de nos relations aux autres : nos proches ont l’habitude de nous voir
            d’une certaine manière.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Ils sont habitués à une certaine image. Le fait de nous voir changer les inquiète. Cela
            leur demande un effort d’adaptation car il faut qu’ils se créent une nouvelle image de
            nous-mêmes, avec laquelle ils ne sont pas encore très à l’aise. Ils vont donc très
            souvent tout faire pour nous décourager.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Parce qu’ils tiennent tout simplement à préserver leurs propres habitudes.
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '30%',
      height: 'auto',
      display: 'block',
      marginRight: '20px',
      marginTop: '-10px',
      float: 'left',
      '@media only screen and (max-width: 600px)': {
        width: '90%',
        float: 'none',
        marginTop: '0px',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
      },
      '@media only screen and (max-width: 361px)': {
        width: '90%',
        float: 'none',
        marginTop: '0px',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
      },
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    textImg: {
      width: '40%',
      height: 'auto',
    },
  }),
);
export default Page26;
