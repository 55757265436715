export const BUSINESSCREATION = [
    `Tout construire de zéro, de l’identité visuelle
     de l’entreprise à sa gamme de produits
     et/ou de services, en passant par son
     organisation matérielle et administrative.`,
    `Investissement souvent plus faible au départ
     car pas de frais d’achat d’une structure
     déjà existante. Un euro peut suffire à créer
     son entreprise.`,
    `Charges fixes faibles au démarrage,
     adaptables et flexibles selon le volume de
     chiffre d’affaires permettant un démarrage
     de l’activité en douceur.`,
    `Ne nécessite pas de compétences en gestion
     d’entreprise (commerciales, administratives ou
     managériales) fortes dès le démarrage. Il faut
     juste avoir l’envie d’apprendre.`,
    `Base clients à construire entièrement. Il faut
     faire ses preuves et faire sa place dans un
     environnement économique concurrentiel, ce
     qui demande une énergie considérable.`,
    `Risque d’échec faible car peu de charges
     favorisant l’apprentissage et l’adaptation,
     notamment pour une création dans un secteur
     non maîtrisé.`
];

export const TAKEBACKTHEENTERPRISE = [
    `S’appuyer sur une offre de services
   existants, avec un nom qui a acquis une
   certaine notoriété. Les process sont en
   place et fonctionnent déjà.`,
    `Investissement souvent très important
   avec mobilisation du patrimoine du (de la)
   repreneur(se) et prêt bancaire pour acheter
   l’entreprise avec caution personnelle.`,
    `Charges fixes élevées nécessitant que
   l’entreprise fonctionne bien dès le
   démarrage. Il faut assurer la continuité de
   l’activité. Les erreurs de gestion se paient
   cash.`,
    `Nécessite des compétences en gestion
   d’entreprise (commerciales, administratives et
   managériales) fortes dès le démarrage. L’envie
   ne suffit pas. Il faut être compétent(e).`,
    `Base clients existante permettant d’avoir déjà
   un chiffre d’affaires conséquent. Possibilité de
   s’appuyer sur une expérience et une réputation.`,
    `Risque d’échec fort si le domaine d’activité n’est
   pas maîtrisé et si les compétences en gestion
   d’entreprise ne sont pas suffisamment assises.`
]