import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        titlePage: {
            padding: '1.5vh 0',
            fontSize: 20,
            fontWeight: 600,
            color: '#2B56A5',
            textAlign: "center",
            border: '1px solid',
            backgroundColor: '#e8eef4',
            [theme.breakpoints.down('sm')]: {
                fontSize: 15,
            },
        },
        tableContainer: {
            border: '1px solid',
            overflow: 'hidden'
        },
        bodyContent: {
            border: '1px solid',
            overflow: 'hidden',
            borderBottom: '0.05px solid',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        number: {
            background: '#f1665f',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            fontSize: '2.5vh',
            color: 'white'
        }
    })
);

export default useStyles;