/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import { LayoutSimple } from '@orientaction/layouts';
import style from './style';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { useState, useRef, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import locale from './local.json';
import { publicSvgPath } from '../../utils/constants';
import { updateCompanyUserPassword } from '../../services/Company.service';
import useQueryString from '../../hooks/useQueryString';
import { useHistory } from 'react-router-dom';
import { useAlert, useOfflineLanguage, useUser } from '@orientaction/hooks';
import { isAdmin } from '@orientaction/utils';
import { UserProfilePhotoContext } from '../../Context/UserProfilePhotoContext';
import ReactFlagsSelect from 'react-flags-select';

const content = `Vous avez déjà finalisé votre création de compte en définissant un mot de passe. 
Nous vous invitons à vous connecter à votre compte ou à cliquer sur l'option "Mot de passe oublié"`;

const AdminAddPasword = (props: any) => {
  const cls = style();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const [connectError, setConnectError] = useState<string>('');
  const queryString = useQueryString();
  const history = useHistory();
  const { resetPhoto, updateUserProfilePhoto } = useContext<any>(UserProfilePhotoContext);
  const { language, onLanguageChange } = useOfflineLanguage();

  const alert = useAlert();
  const { checkUser, loading, isUserExist } = useUser();

  useEffect(() => {
    (async () => {
      const confirmationToken = queryString.get('q');
      checkUser(confirmationToken);
    })();
  }, []);

  const goToLogin = () => {
    history.push('/');
  };

  const onSubmitHandler = async (data: any) => {
    setIsLoading(true);

    try {
      resetPhoto();
      const confirmationToken = queryString.get('q');
      const result = await updateCompanyUserPassword({ ...data, confirmationToken });
      if (result?.data?.jwt) {
        new Promise((resolve: any) => {
          localStorage.clear();
          localStorage.setItem('userInfos', JSON.stringify(result?.data?.user));
          localStorage.setItem('userToken', result?.data?.jwt);
          resolve(result?.data);
        }).then((res: any) => {
          updateUserProfilePhoto();
          if (!isAdmin(res.user)) {
            history.push('/main');
          } else {
            history.push('/admin/users-resultats');
          }
        });
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        setConnectError(
          "Une erreur s'est produite. Vérifier que vous avez rentré correctement vos identifiants"
        );
      } else {
        alert.openSimpleAlert();
      }
    } finally {
      setIsLoading(false);
    }
  };
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm<any>({
    mode: 'onChange',
  });
  const newPasswordRef = useRef({});
  newPasswordRef.current = watch('password', '');
  const confirmPasswordRef = useRef({});
  confirmPasswordRef.current = watch('confirmPassword', '');

  return (
    <LayoutSimple isWhite={true} noPadding={true}>
      <div className={cls.root}>
        {!loading && isUserExist === true && (
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={8} sm={5} md={4} className={cls.card}>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                }}>
                <strong className={cls.h3}>Finalisation de création de compte</strong>
              </Typography>
              <Typography
                className={cls.boldText}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontStyle: 'italic',
                  marginTop: '30px',
                  marginBottom: '20px',
                }}>
                Pour finaliser la création de votre compte, <br />
                merci de définir un mot de passe
              </Typography>
              <form onSubmit={handleSubmit(onSubmitHandler)} className={cls.form}>
                <Box className={cls.fieldWithLabel}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Champ obligatoire',
                      pattern: {
                        value:
                          /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
                        message:
                          'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : Lettres minuscules (a-z) | Lettres majuscules (A-Z) | Chiffres (0-9) |Caractères spéciaux (ex. !@#)}',
                      },
                      validate: {
                        value: value => {
                          if (
                            confirmPasswordRef &&
                            confirmPasswordRef.current &&
                            confirmPasswordRef.current !== ''
                          ) {
                            if (confirmPasswordRef.current === value) {
                              if ((errors as any)?.confirmPassword) {
                                (errors as any).confirmPassword.message = '';
                              }
                            } else if ((errors as any)?.confirmPassword) {
                              (errors as any).confirmPassword.message =
                                "Le mot de passe n'est pas identique";
                            } else {
                              (errors as any).confirmPassword = {};
                              (errors as any).confirmPassword.message =
                                "Le mot de passe n'est pas identique";
                            }
                          }
                          return newPasswordRef.current === value;
                        },
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <label htmlFor="newPassword">
                          <h5 className={cls.labelText}>{locale.password}</h5>
                        </label>
                        <Input
                          placeholder={locale.passwordPlaceholder}
                          {...field}
                          type={eyeOn ? 'text' : 'password'}
                          className={clsx(cls.input, cls.inputPassword)}
                          endAdornment={
                            <InputAdornment position="end">
                              {eyeOn ? (
                                <img
                                  alt="eye-switch-on"
                                  src={`${publicSvgPath}/eyeOn.svg`}
                                  className={cls.eyeImg}
                                  onClick={() => setEyeOn(false)}
                                  onKeyDown={() => setEyeOn(false)}
                                />
                              ) : (
                                <img
                                  alt="eye-switch-off"
                                  src={`${publicSvgPath}/eyeOff.svg`}
                                  className={cls.eyeImg}
                                  onClick={() => setEyeOn(true)}
                                  onKeyDown={() => setEyeOn(true)}
                                />
                              )}
                            </InputAdornment>
                          }
                        />
                        {errors?.password?.message && (
                          <span className={cls.passwordErrorField}>
                            {(errors as any).password.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box className={cls.fieldWithLabel}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Champ obligatoire',
                      validate: {
                        value: value =>
                          value === newPasswordRef.current || "Le mot de passe n'est pas identique",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <label htmlFor="confirmPassword">
                          <h5 className={cls.labelText}>{locale.confirmPassword}</h5>
                        </label>
                        <Input
                          placeholder={locale.confirmPasswordPlaceholder}
                          {...field}
                          type={eyeOn ? 'text' : 'password'}
                          className={clsx(cls.input, cls.inputPassword)}
                          endAdornment={
                            <InputAdornment position="end">
                              {eyeOn ? (
                                <img
                                  alt="eye-switch-on"
                                  src={`${publicSvgPath}/eyeOn.svg`}
                                  className={cls.eyeImg}
                                  onClick={() => setEyeOn(false)}
                                  onKeyDown={() => setEyeOn(false)}
                                />
                              ) : (
                                <img
                                  alt="eye-switch-off"
                                  src={`${publicSvgPath}/eyeOff.svg`}
                                  className={cls.eyeImg}
                                  onClick={() => setEyeOn(true)}
                                  onKeyDown={() => setEyeOn(true)}
                                />
                              )}
                            </InputAdornment>
                          }
                        />
                        {errors?.confirmPassword?.message && (
                          <span className={cls.passwordErrorField}>
                            {(errors as any).confirmPassword.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Box>
                {connectError?.trim()?.length > 0 && (
                  <h5 className={cls.errorField}>{connectError}</h5>
                )}
                <Box className={cls.btnConnectContainer}>
                  <Button type="submit" className={cls.btnConnect} disabled={isLoading}>
                    {!isLoading ? locale.connectText : `${locale.connectText}...`}
                  </Button>
                </Box>
                <Box className={cls.signupLink}>
                  <ReactFlagsSelect
                    countries={['FR', 'GB']}
                    showOptionLabel={false}
                    showSelectedLabel={false}
                    fullWidth={false}
                    selected={language}
                    onSelect={onLanguageChange}
                  />
                </Box>
              </form>
            </Grid>
          </Grid>
        )}

        {!loading && isUserExist === false && (
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={8} sm={5} md={4} className={cls.card}>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontWeight: 'lighter',
                }}>
                <strong className={cls.h3}>Votre compte est déjà finalisé</strong>
              </Typography>
              <Typography
                className={cls.boldText}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'center',
                  fontStyle: 'italic',
                  marginTop: '30px',
                  marginBottom: '20px',
                }}>
                {content}
              </Typography>
              <Box className={cls.btnConnectContainer}>
                <Button type="button" onClick={goToLogin} className={cls.btnConnect}>
                  Connectez-vous
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </div>
    </LayoutSimple>
  );
};

export default AdminAddPasword;
