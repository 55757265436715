// put style here
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: 'white',
      textTransform: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      marginTop: '7vh',
      position: 'relative',
      left: '-8px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '4vh',
      },
    },
  }),
);
