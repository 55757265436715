import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        head: {
            padding: "1vh 0",
            textAlign: 'center',
            borderBottom: '1px solid',
            background: '#e8eef4',
            fontSize: '2.75vh',
            [theme.breakpoints.between(1200, 1300)]: {
                height: '5vh',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
            },
        },
        tableContainer: {
            border: '1px solid'
        },
        bodyContent: {
            padding: "2vh 2vh 0 2vh",
            borderBottom: '0.05px solid',
            height: '15vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.between(1200, 1300)]: {
                height: '17vh',
            },
            [theme.breakpoints.between(1050, 1200)]: {
                height: '22vh',
            },
            [theme.breakpoints.between(950, 1050)]: {
                height: '30vh',
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: '12vh',
            }
        },
    })
);

export default useStyles;