import { adminUserAction } from '@orientaction/api-actions';
import {
  useAdminUser,
  useAlert,
  useCompany,
  useDrawer,
  useRandomPassword,
} from '@orientaction/hooks';
import { adminService } from '@orientaction/services';
import { canIUpdate, getFromLS } from '@orientaction/utils';
import { omit } from 'lodash';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  consultantRattachmentPopinContent,
  consultantRattachmentPopinTitle,
} from '../BeneficiaryForm/constant';
import { FORM_MODE, formBaseInputs, subordinatesIds } from '../constant';
import { UserFormContext } from '../Context/UserFormContext';
import { validateForm } from '../utils';
import useServices from './useServices';

interface IPopin {
  open: boolean;
  title: string;
  content: string;
}

const useManagerForm = (mode: any, companyUserRoleId: number) => {
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [popin, setPopin] = useState<IPopin>({ open: false, title: '', content: '' });

  const { updateFormUser, formUser } = useContext<any>(UserFormContext);
  const { randomPassword, isRandomPasswordCopied, generateRandomPassword, copyRandomPassword } =
    useRandomPassword();

  const alert = useAlert();

  const dispatch = useDispatch();
  const user: any = useSelector((state: any) => state.adminUser.current);

  const token = getFromLS('userToken') || '';

  const canUpdateHierarchy = !canIUpdate(!!(mode === FORM_MODE.update), user.company_user_role);

  const { loading: servicesLoading } = useServices(token, user.id, mode);

  const { openDrawer } = useDrawer();

  const { updateArrayAfterUpdateCreate, setListUphierchy, listUpHierachy, tabs } = useAdminUser();

  const { getUpHierachy } = useCompany();

  const setDialog = (data: any) => {
    dispatch(
      adminUserAction.setDialog({
        ...data,
      }),
    );
  };

  const updateUserState = (data: any) => {
    dispatch(adminUserAction.setCurrent(data));
  };

  const setTabs = (data: number) => {
    dispatch(adminUserAction.setTabs(data));
  };

  const confirmCancel = () => {
    openDrawer(false);

    setDialog({
      open: true,
      title: `Annuler la ${user.isUpdate ? 'modification' : 'création'} du compte`,
      paragraph: `Êtes-vous sûr de vouloir annuler la ${
        user.isUpdate ? 'modification' : 'création'
      } de ce compte ?`,
      textBtn: `Annuler la ${user.isUpdate ? 'modification' : 'création'}`,
      type: 'cancel',
      id: '',
      isActivated: false,
    });
  };

  const confirmRattachment = (data: any) => {
    setDialog({
      open: true,
      textCancel: 'Non',
      title: `Demande de rattachement d'un consultant .`,
      paragraph: `L'adresse e-mail vous avez renseignée appartient à un utilisateur bénéficiaire non-rattaché. Souhaitez-vous l'ajouter en consultant ?`,
      textBtn: `Oui`,
      type: 'becomeConsulte',
      id: '',
      isActivated: false,
      data: data as any,
    });
  };

  const closeDrawer = () => {
    if (mode === FORM_MODE.readonly) {
      openDrawer(false);
      return;
    }
    confirmCancel();
  };

  const handlePopinClose = async () => {
    setPopin(() => ({ title: '', open: false, content: '' }));
  };

  const handlePopinConfirm = async () => {
    // admin confirm double-role change
    const dummyEvent = {
      preventDefault: () => {
        return true;
      },
      stopPropagation: () => {
        return true;
      },
      target: {
        value: { hasMultipleRole: true },
      },
    };
    handleSubmit(dummyEvent);
  };

  const handleOpenConsultanTransormPopin = async () => {
    setPopin(() => ({
      title: consultantRattachmentPopinTitle,
      content: consultantRattachmentPopinContent,
      open: true,
    }));
  };

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;

    if (name.startsWith('service')) {
      const serviceId = +name.split('_')[1];
      const updatedServices = formUser.services.map((service: any) => {
        if (serviceId === service.id) {
          return {
            ...service,
            isChecked: checked,
          };
        }
        return service;
      });
      updateFormUser({ services: updatedServices });
      return;
    }
    const value_Formated = name === 'dayOfBirth' ? new Date(value) : value;
    updateFormUser({
      [name]: value_Formated,
    });
  };

  const handleRoleChange = async (e: any) => {
    const { name, value } = e.target;
    setTabs(value);
    updateFormUser({
      [name]: value,
      roleHasChange: true,
    });
  };

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    const fieldsLabels = formBaseInputs;
    if (subordinatesIds.includes(companyUserRoleId)) {
      fieldsLabels.push('upper_hierarchy');
    }

    const { isValid, formError } = validateForm(fieldsLabels, formUser);

    setErrors(formError);
    if (!isValid) {
      return;
    }
    const omittedUser = omit(formUser, [
      'blocked',
      'compassFirstActivationDate',
      'confirmationToken',
      'confirmed',
      'isActivated',
      'role',
      'isCompassActivated',
      'isTemporaryPassword',
      'password',
      'provider',
      'updatedAt',
      'old_id',
      'hasMultipleRole',
      'hasNeedsProResult',
      'hasNeedsResult',
      'isView',
      'createdAt',
      'description',
      'isUpdate',
      'username',
      'photoUri',
      'rattachement',
      'resetPasswordToken',
    ]);
    let response;
    if (mode === FORM_MODE.create) {
      setLoading(true);
      try {
        response = await adminService.register(
          {
            ...omittedUser,
            ...e?.target?.value, // additional value to persist
          },
          token,
        );
        updateUserState({ ...user, formUser });
      } catch (error) {
        alert.openSimpleAlert();
      } finally {
        setLoading(false);
      }
    }

    if (mode === FORM_MODE.update) {
      setLoading(true);
      try {
        response = await adminService.update(
          {
            ...omittedUser,
            ...e?.target?.value, // additional value to persist
          },
          token,
        );
      } catch (error) {
        if (
          error?.response?.data?.error?.details[0]?.messages[0]?.id ===
          'Auth.form.error.email.taken'
        ) {
          alert.setOpenAlert(
            true,
            'Cette adresse e-mail existe déjà, merci d’en utiliser une autre',
            'error',
          );
          return;
        }
        alert.openSimpleAlert();
      } finally {
        setLoading(false);
      }
    }
    if (response?.data.data === 'E-mail already exist') {
      alert.setOpenAlert(
        true,
        'Cette adresse e-mail existe déjà, merci d’en utiliser une autre',
        'error',
      );
      return;
    }

    if (response?.data.code === 'beneficiary-to-consultant') {
      handleOpenConsultanTransormPopin();
      return;
    }
    updateArrayAfterUpdateCreate(response?.data.data);
    openDrawer(false);
    updateFormUser({});
  };
  return {
    handleRoleChange,
    handleChange,
    handleSubmit,
    confirmCancel,
    confirmRattachment,
    updateUserState,
    setTabs,
    closeDrawer,
    user: formUser,
    loading,
    errors,
    servicesLoading,
    getManagers: getUpHierachy,
    setManager: setListUphierchy,
    manager: listUpHierachy,
    currentUser: user,
    canUpdateHierarchy,
    handlePopinConfirm,
    popin,
    handlePopinClose,
    tabs,
    isRandomPasswordCopied,
    generateRandomPassword,
    copyRandomPassword,
  };
};

export default useManagerForm;
