/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Box, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FC, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { registerUser, resetPassword } from '../../../../services/user.service';
import { emailMatch, publicSvgPath } from '../../../../utils/constants';
import locale from './locale.json';

const ResetPasswordForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registerError, setRegisterError] = useState<string>('');
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const cls = style();
  const history = useHistory();
  const { search } = useLocation();
  const onSubmitHandler = async (data: any) => {
    try {
      setIsLoading(true);
      const payload: any = {};
      const code = new URLSearchParams(search).get('code');
      payload.password = data.password;
      payload.passwordConfirmation = data.password;
      payload.code = code;
      const response = await resetPassword(payload);
      if (response?.data?.jwt) {
        localStorage.clear();
        setIsLoading(false);
        history.push('/');
      } else {
        setIsLoading(false);
        setRegisterError('Une erreur est survenue');
      }
    } catch (error: any) {
      setRegisterError('Une erreur est survenue');
      setIsLoading(false);
    }
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<any>({
    mode: 'onChange',
  });
  const newPasswordRef = useRef({});
  newPasswordRef.current = watch('password', '');
  const confirmPasswordRef = useRef({});
  confirmPasswordRef.current = watch('confirmPassword', '');
  return (
    <div className={cls.formContainer}>
      <form onSubmit={handleSubmit(onSubmitHandler)} className={cls.form}>
        <div className={cls.fieldWithLabel}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              pattern: {
                value:
                  /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
                message:
                  'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : | - Lettres minuscules (a-z) | - Lettres majuscules (A-Z) | - Chiffres (0-9) | - Caractères spéciaux (ex. !@#)}',
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="password">
                  <h5 className={cls.labelText}>{locale.password}</h5>
                </label>
                <Input
                  placeholder={locale.passwordPlaceholder}
                  {...field}
                  type={eyeOn ? 'text' : 'password'}
                  className={clsx(cls.input, cls.inputPassword)}
                  endAdornment={
                    <InputAdornment position="end">
                      {eyeOn ? (
                        <img
                          alt="eye-switch-on"
                          src={`${publicSvgPath}/eyeOn.svg`}
                          className={cls.eyeImg}
                          onClick={() => setEyeOn(false)}
                          onKeyDown={() => setEyeOn(false)}
                        />
                      ) : (
                        <img
                          alt="eye-switch-off"
                          src={`${publicSvgPath}/eyeOff.svg`}
                          className={cls.eyeImg}
                          onClick={() => setEyeOn(true)}
                          onKeyDown={() => setEyeOn(true)}
                        />
                      )}
                    </InputAdornment>
                  }
                />

                {errors?.password?.message && (
                  <span className={cls.passwordErrorField}>{(errors as any).password.message}</span>
                )}
              </>
            )}
          />
        </div>
        <div className={cls.fieldWithLabel}>
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              validate: {
                value: value =>
                  value === newPasswordRef.current || "Le mot de passe n'est pas identique",
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="confirmPassword">
                  <h5 className={cls.labelText}>{locale.confirmPassword}</h5>
                </label>
                <Input
                  placeholder={locale.confirmPasswordPlaceholder}
                  {...field}
                  type={eyeOn ? 'text' : 'password'}
                  className={clsx(cls.input, cls.inputPassword)}
                  endAdornment={
                    <InputAdornment position="end">
                      {eyeOn ? (
                        <img
                          alt="eye-switch-on"
                          src={`${publicSvgPath}/eyeOn.svg`}
                          className={cls.eyeImg}
                          onClick={() => setEyeOn(false)}
                          onKeyDown={() => setEyeOn(false)}
                        />
                      ) : (
                        <img
                          alt="eye-switch-off"
                          src={`${publicSvgPath}/eyeOff.svg`}
                          className={cls.eyeImg}
                          onClick={() => setEyeOn(true)}
                          onKeyDown={() => setEyeOn(true)}
                        />
                      )}
                    </InputAdornment>
                  }
                />
                {errors?.confirmPassword?.message && (
                  <span className={cls.passwordErrorField}>{(errors as any).confirmPassword.message}</span>
                )}
              </>
            )}
          />
        </div>
        {registerError?.trim()?.length > 0 && <h5 className={cls.errorField}>{registerError}</h5>}
        <Button type="submit" className={cls.btnConnect}>
          {isLoading ? 'Envoyer...' : 'Envoyer'}
        </Button>
      </form>
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
    '@media (min-width: 1250px)': {
      maxWidth: 410,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldWithLabel: {
    marginTop: 20,
    width: '100%',
  },
  errorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginTop: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  inputPassword: {
    paddingRight: 5,
  },
  labelText: {
    margin: 'auto',
    font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
  },
  btnConnectContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    width: '100%',
  },
  btnConnect: {
    width: '100%',
    background:
      'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#fff',
    marginTop: 20,
  },
  forgotPasswordLink: {
    color: '#000A8Cc !important',
    textDecoration: 'underline',
    font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
    marginTop: 20,
  },
  passwordErrorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    minHeight: 20,
    maxWidth: 330,
  },
  eyeImg: {
    cursor: 'pointer',
    zIndex: 1,
  },
  inputWitAdornment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkBoxCguContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  checkBoxCgu: {
    '&.Mui-checked': {
      color: `${theme.palette.primary.main} !important`,
    },
    marginLeft: -13,
  },
  cguLinkText: {
    font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
    color: theme.palette.primary.main,
  },
  cguAcceptText: {
    font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
  },
}));

export default ResetPasswordForm;
