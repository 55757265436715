import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    titlePage: {
        fontSize: 26,
        fontWeight: 600,
        color: '#2B56A5',
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            fontSize: 30,
        },
    },
    spanYellow: {
        width: "7%",
        height: 10,
        backgroundColor: "yellow",
        margin: "5vh auto",
    },
}));
