/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import clsx from 'clsx';
// eslint-disable-next-line no-use-before-define
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomTableProps, Field } from './interface';
import Row from './Row';
import useStyles from './style';
import TablePagination from './TablePagination';
import Thead from './Thead';

const TableAdmin: FC<CustomTableProps> = ({
  to,
  count,
  ebook1,
  loading,
  bodyData,
  colomTable,
  currentPage,
  onDelete,
  oneDisable,
  onClickAction,
  handleChangePage,
  currentUser,
}) => {
  const history = useHistory();

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const classes = useStyles();

  return (
    <div className={classes.customtable}>
      {loading ? (
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid item={true}>
            <div className={classes.bodycenter}>
              <CircularProgress color="primary" />
            </div>
          </Grid>
        </Grid>
      ) : (
        <>
          {bodyData?.length > 0 ? (
            <>
              <TableContainer className={clsx(classes.tableContainer, '')}>
                <Table className={classes.table} aria-label="simple table">
                  <Thead>
                    {colomTable.map((column: Field) => (
                      <TableCell key={Math.random()}>{column?.label || ""}</TableCell>
                    ))}

                    {to && currentUser?.company_user_role?.id !== 4 && <TableCell>Tests</TableCell>}

                    {ebook1 && <TableCell>Ressources</TableCell>}

                    <TableCell>Actions</TableCell>
                  </Thead>

                  <TableBody style={{ position: 'relative' }}>
                    {bodyData.map((rowOne: any) => (
                      <Row
                        rowOne={rowOne}
                        colomTable={colomTable}
                        onClickAction={onClickAction}
                        onDelete={onDelete}
                        oneDisable={oneDisable}
                        to={to}
                        ebook1={ebook1}
                        currentUser={currentUser}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {rowsPerPage < count && (
                <div className={classes.tableFooter}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    labelDisplayedRows={() => ''}
                    colSpan={10}
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onChangePage={handleChangePage}
                    onPageChange={handleChangePage}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  textAlign: 'center',
                  margin: '10vh 0',
                }}>
                Aucun resultat
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TableAdmin;
