/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../../store';

export const setIndoorWeather =
  (nbr: number): AppThunk =>
    dispatch => {
      dispatch({
        type: 'INDOORWEATHER',
        payload: nbr,
      });
    };

export const setId =
  (data: any): AppThunk =>
    dispatch => {
      dispatch({
        type: 'ID',
        payload: data,
      });
    };


export const setBooleanField =
  (data: any): AppThunk =>
    dispatch => {
      dispatch({
        type: data?.type?.toUpperCase() ?? '',
        payload: data?.value,
      });
    };

export const setYourEconomicCapital = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'YOURECONOMICCAPITAL',
      payload: {
        name: data.name,
        value: data.value,
      },
    });
  };

export const setAssessYourSocialCapitalMobilizable = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ASSESSYOURSOCIALCAPITALMOBILIZABLE',
      payload: data,
    });
  };

export const setAccessYourCulturalCApitalMobilizable = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ASSESSYOURCULTURALCAPITALMOBILIZABLE',
      payload: data,
    });
  };

export const setDiplomeExperience = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'DIPLOMEEXPERINCE',
      payload: data,
    });
  };

export const setSoftSkill = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SOFTSKILL',
      payload: data,
    });
  };

export const setYourBestProfessionalExperience = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'YOURBESTPROFESSIONALEXPERIENCE',
      payload: data,
    });
  };

export const setYourDiplome = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'YOURDIPLOME',
      payload: data,
    });
  };

export const setSynthiesIsYouSkill = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SYNTHIESISYOUSKILL',
      payload: data,
    });
  };

export const setvalueAdd = (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'VALUEADD',
      payload: data,
    });
  };

export const setAll = (data: any): AppThunk =>
  dispatch => {
    console.log({dataAll: data})
    dispatch({
      type: 'ALL',
      payload: data,
    });
  };