import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scoreItemContainer: {
      alignSelf: 'center',
    },
    explanationTextContainer: {
      padding: '24px 48px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    explanationText: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 500,
      wordBreak: 'break-word',
      color: theme.palette.primary.dark,
      lineHeight: 2,
    },
    resultImgContainer: {
      [theme.breakpoints.down('sm')]: {
        flexBasis: 'unset',
      },
    },
    resultImg: {
      maxWidth: '100%',
      overflow: 'hidden',
      objectFit: 'cover',
      marginTop: 25,
      marginLeft: 25,
      [theme.breakpoints.down('sm')]: {
        marginTop: 25,
        marginBottom: 25,
      },
    },
  })
);

export default useStyles;
