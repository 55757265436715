import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenterTop: {
      textAlign: 'center',
      color: '#2b56a5',
      fontFamily: 'ITC Avant Garde Gothic Std bold',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: 22,
    },
    cardBgGrey: {
      padding: 20,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  })
);

export default useStyles;
