// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page } from '@orientaction/components';
import { imgBook4 } from '@orientaction/utils';
import useStyles from '../commons/Style';

const Page05 = () => {
  const classe = useStyles();

  return (
    <Page id={5}>
      <div className={classe.titleSteveJobs}>
        <i>
          « Pour avoir du talent,
          <br />
          il faut être convaincu qu’on en possède. »
        </i>
      </div>

      <div
        style={{
          width: '7%',
          height: 10,
          backgroundColor: 'yellow',
          margin: '5vh auto',
          color: 'yellow',
        }}>
        -
      </div>

      <div
        style={{
          textAlign: 'center',
        }}>
        <Paragrapth colorBlue={true} align="center">
          Gustave Flaubert
        </Paragrapth>
      </div>

      <img
        src={`${imgBook4}/bricoleur.jpg`}
        alt="bricoleur"
        style={{
          width: '80%',
          marginTop: 50,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      />
    </Page>
  );
};
export default Page05;
