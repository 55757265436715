import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "95%",
            margin: '1vh auto',
            minHeight: '95vh',
            padding: '2vh',
            [theme.breakpoints.down('xs')]: {
                padding: '2vh 1vh',
            }
        },
        title: {
            fontWeight: 'bold',
            fontSize: '3.5vh',
            margin: 0
        },
        title1: {
            color: '#0E1247',
            fontWeight: 'bold',
            fontSize: '2vh'
        },
        title2: {
            color: '#0E1247'
        },
        bgWhite: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            flexDirection: 'column',
            borderRadius: '10px',
            minHeight: '70vh',
            margin: '2vh 0',
            padding: '5vh 2vh',
            [theme.breakpoints.down('xs')]: {
                padding: '2vh 2vh',
            }
        },
        img: {
            width: 250,
            [theme.breakpoints.down('xs')]: {
                width: 125,
            }
        }
    })
);

export default useStyles;