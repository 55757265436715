// eslint-disable-next-line simple-import-sort/imports
import {
    Page,
} from '@orientaction/components';
import useStyles from './style';
import ArrayBorder from '../../components/ArrayBorder';
import { useBook5 } from '@orientaction/hooks_book';
import { MarginContainer } from '@orientaction/commons';
import { list } from './context';
import YesNo from './YesNo';


const Page26 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={26}
        >

            {
                list.map((item: any) => (
                    <MarginContainer mormal={true}>
                        <ArrayBorder
                            title={item.title || ""}
                            list={item.list || []}
                        />
                    </MarginContainer>
                ))
            }

            <MarginContainer mormal={true}>
                <YesNo
                    current='Après avoir réalisé ce travail d’analyse, Jérôme en vient à la conclusion qu’il est prêt à concrétiser son projet. Maintenant, la mise en œuvre commence.'
                />
            </MarginContainer>

        </Page>
    );
};
export default Page26;
