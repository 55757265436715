import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const style = makeStyles((theme: Theme) =>
  createStyles({
    backDrop: {
      backdropFilter: 'blur(8px)',
      backgroundColor: 'rgba(0,0,30,0.4)',
      padding: 75,
    },
    logoContainer: {
      cursor: 'pointer',
      maxWidth: 200,
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 160,
        margin: 'auto',
      },
    },
    logo: {
      width: '100%',
      height: 'auto',
    },
    logoImg: {
      width: '100%',
      height: 'auto',
    },
    dialogTitle: {
      color: '#23296F',
      textAlign: 'center',
      fontSize: '1rem',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      marginTop: 25,
    },
    formContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      '@media (min-width: 1250px)': {
        maxWidth: 410,
      },
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80%',
    },
    fieldWithLabel: {
      marginTop: 20,
      width: '100%',
    },
    errorField: {
      color: theme.palette.warning.main,
      font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    },
    input: {
      flex: 1,
      border: `1px solid #E3E8F0`,
      width: '100%',
      marginTop: 5,
      height: 48,
      '&:before': {
        display: 'none',
      },
      '& input': {
        fontSize: 13,
        padding: 10,
      },
    },
    inputPassword: {
      paddingRight: 5,
    },
    labelText: {
      margin: 'auto',
      font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
      color: '#000A8C',
    },
    btnConnectContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 20,
      width: '100%',
    },
    btnConnect: {
      width: '80%',
      backgroundColor: '#000A8C',
      borderRadius: 3,
      opacity: 1,
      textTransform: 'none',
      color: '#fff',
    },
    forgotPasswordLink: {
      marginTop: 5,
      textAlign: 'end',
    },
    forgotPasswordLinkText: {
      color: '#000A8C',
      textDecoration: 'underline',
      font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
    },
    passwordErrorField: {
      color: theme.palette.warning.main,
      font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
      minHeight: 20,
      maxWidth: 330,
    },
    eyeImg: {
      cursor: 'pointer',
      zIndex: 1,
    },
    inputWitAdornment: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    firstStepTitleStyle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    secondStepTitleStyle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
    },
  })
);

export default style;
