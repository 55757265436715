/* eslint-disable no-await-in-loop */
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AdminTestContext } from '@orientaction/Context';
import OaPopin from '@orientaction/commons/Popin';
import { Modal } from '@orientaction/components';
import { useTest } from '@orientaction/hooks';
import { omit } from 'lodash';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import style from './style';

const StickyFooter = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [openErrorPopin, setOpenErrorPopin] = useState<boolean>(false);
  const [saveText, setSaveText] = useState('Publier');
  const [draftText, setDraftText] = useState('Enregistrer en brouillon');

  const { push } = useHistory();

  const { test, updateTest, resetTest, refetchTest, fetchLoading } = useContext<any>(
    AdminTestContext,
  );

  const { postTestWithQuestionsAndResponses, updateTestWithQuestionsAndResponses } = useTest();

  const classes = style();

  const save = async () => {
    if (test.isEdit) {
      updateTest({
        ...test,
        errors: {
          title: !test?.test?.title?.trim()?.length,
          time: !test?.test?.time,
          image: !test?.test?.image?.trim()?.length,
          visibilityPermission: !test?.visibilityPermission?.length,
          enterpriseSelected:
            !test?.enterpriseSelected ||
            (Array.isArray(test?.enterpriseSelected) && test?.enterpriseSelected?.length === 0),
          category: test.category === '',
          variables: !test?.variables?.length,
          questions: !test.questions?.length,
        },
      });
      const omittedTest = omit(test, [
        'refs',
        'errors',
        'questionDeletionConfirmPopin',
        'variableDeletionConfirmPopin',
      ]);
      if (!test?.test?.title?.trim().length) {
        return test?.refs?.testTitleRef?.current?.scrollIntoView();
      }

      if (!test?.test?.image?.trim()?.length) {
        return test?.refs?.testImageRef?.current?.scrollIntoView();
      }

      if (!test?.test?.time) {
        return test?.refs?.testTimeRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }
      setLoading(true);

      let retry = 0;

      while (retry < 5) {
        try {
          await updateTestWithQuestionsAndResponses({
            ...omittedTest,
            id: test?.test?.id,
            isDraft: false,
          });
          retry = 6;
          // push('/admin/test');
          setSaveText('Publié !');
          setTimeout(() => {
            setSaveText('Publier');
          }, 2000);
          refetchTest();
        } catch (error) {
          if (error?.response?.data?.error?.details?.retry) {
            retry++;
          }
          retry = 5;
        }
      }
      if (retry === 5) {
        setOpenErrorPopin(true);
      }
      setLoading(false);
    } else {
      updateTest({
        ...test,
        errors: {
          title: !test?.test?.title?.trim()?.length,
          time: !test?.test?.time,
          image: !test?.test?.image?.trim()?.length,
          visibilityPermission: !test?.visibilityPermission?.length,
          enterpriseSelected:
            !test?.enterpriseSelected ||
            (Array.isArray(test?.enterpriseSelected) && test?.enterpriseSelected?.length === 0),
          category: test.category === '',
          variables: !test?.variables?.length,
          questions: !test.questions?.length,
        },
      });
      if (!test?.test?.title?.trim().length) {
        return test?.refs?.testTitleRef?.current?.scrollIntoView();
      }

      if (!test?.test?.image?.trim()?.length) {
        return test?.refs?.testImageRef?.current?.scrollIntoView();
      }

      if (!test?.test?.time) {
        return test?.refs?.testTimeRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }
      const omittedTest = omit(test, [
        'refs',
        'errors',
        'questionDeletionConfirmPopin',
        'variableDeletionConfirmPopin',
      ]);

      setLoading(true);

      let retry = 0;
      while (retry < 5) {
        try {
          const response = await postTestWithQuestionsAndResponses({
            ...omittedTest,
            isDraft: false,
          });
          retry = 6;
          setSaveText('Publié !');
          setTimeout(() => {
            setSaveText('Publier');
          }, 2000);
          setTimeout(() => {
            if (response.data.data.id) {
              push(`/admin/test/${response.data.data.id}/modifier`);
            }
          }, 3000);
        } catch (error) {
          if (error?.response?.data?.error?.details?.retry) {
            retry++;
          }
          retry = 5;
        }
      }
      if (retry === 5) {
        setOpenErrorPopin(true);
      }
      setLoading(false);
    }
  };

  const saveAsDraft = async () => {
    if (test.isEdit) {
      updateTest({
        ...test,
        errors: {
          title: !test?.test?.title?.trim()?.length,
          time: !test?.test?.time,
          image: !test?.test?.image?.trim()?.length,
          visibilityPermission: !test?.visibilityPermission?.length,
          enterpriseSelected:
            !test?.enterpriseSelected ||
            (Array.isArray(test?.enterpriseSelected) && test?.enterpriseSelected?.length === 0),
          category: test.category === '',
          variables: !test?.variables?.length,
          questions: !test.questions?.length,
        },
      });
      if (!test?.test?.title?.trim().length) {
        return test?.refs?.testTitleRef?.current?.scrollIntoView();
      }

      if (!test?.test?.image?.trim()?.length) {
        return test?.refs?.testImageRef?.current?.scrollIntoView();
      }

      if (!test?.test?.time) {
        return test?.refs?.testTimeRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }
      const omittedTest = omit(test, [
        'refs',
        'errors',
        'questionDeletionConfirmPopin',
        'variableDeletionConfirmPopin',
      ]);

      setLoading(true);
      let retry = 0;

      while (retry < 5) {
        try {
          await updateTestWithQuestionsAndResponses({
            ...omittedTest,
            id: test?.test?.id,
            isDraft: true,
          });
          retry = 6;
          // push('/admin/test');
          setDraftText('Enregistré !');
          setTimeout(() => {
            setDraftText('Enregistrer en brouillon');
          }, 2000);
          refetchTest();
        } catch (error) {
          if (error?.response?.data?.error?.details?.retry) {
            retry++;
          }
          console.log('Error on creating test', error);
          retry = 5;
        }
      }

      if (retry === 5) {
        // show popin
        setOpenErrorPopin(true);
      }
      setLoading(false);
    } else {
      updateTest({
        ...test,
        errors: {
          title: !test?.test?.title?.trim()?.length,
          time: !test?.test?.time,
          image: !test?.test?.image?.trim()?.length,
          visibilityPermission: !test?.visibilityPermission?.length,
          enterpriseSelected:
            !test?.enterpriseSelected ||
            (Array.isArray(test?.enterpriseSelected) && test?.enterpriseSelected?.length === 0),
          category: test.category === '',
          variables: !test?.variables?.length,
          questions: !test.questions?.length,
        },
      });
      if (!test?.test?.title?.trim().length) {
        return test?.refs?.testTitleRef?.current?.scrollIntoView();
      }

      if (!test?.test?.image?.trim()?.length) {
        return test?.refs?.testImageRef?.current?.scrollIntoView();
      }

      if (!test?.test?.time) {
        return test?.refs?.testTimeRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }

      if (!test?.visibilityPermission?.length) {
        return test?.refs?.testVisibilityRef?.current?.scrollIntoView();
      }

      const omittedTest = omit(test, [
        'refs',
        'errors',
        'questionDeletionConfirmPopin',
        'variableDeletionConfirmPopin',
      ]);

      setLoading(true);

      let retry = 0;

      while (retry < 5) {
        try {
          const response = await postTestWithQuestionsAndResponses({
            ...omittedTest,
            isDraft: true,
          });
          retry = 6;
          setDraftText('Enregistré !');
          setTimeout(() => {
            setDraftText('Enregistrer en brouillon');
          }, 2000);
          if (response.data.data.id) {
            push(`/admin/test/${response.data.data.id}/modifier`);
          }
        } catch (error) {
          if (error?.response?.data?.error?.details?.retry) {
            retry++;
          }
          retry = 5;
        }
      }

      if (retry === 5) {
        // show popin
        setOpenErrorPopin(true);
      }
      setLoading(false);
    }
  };

  const confirmCancel = () => {
    resetTest();
    push('/admin/test');
  };

  const handleCloseModal = () => {
    setModal(!modal);
  };

  const handleConfirm = () => {
    // ok
    setOpenErrorPopin(false);
  };

  return (
    <div className={classes.marginTop}>
      <Button variant="outlined" className={classes.button} onClick={handleCloseModal}>
        Annuler
      </Button>
      <Button
        disabled={loading || fetchLoading}
        id="draft-submit"
        onClick={saveAsDraft}
        className={classes.btnPrimary}
      >
        <span className={classes.btnText}> {loading ? '...' : draftText}</span>
      </Button>
      <Button
        disabled={loading || fetchLoading}
        onClick={save}
        id="response-submit"
        className={classes.btnPrimary}
      >
        <span className={classes.btnText}>{loading ? '...' : saveText}</span>
      </Button>
      <Modal
        data-cy="cancel-test-create-modal"
        open={modal}
        onCancel={handleCloseModal}
        onConfirm={confirmCancel}
        textCancel="Non"
        title=""
        textValidate="Oui"
      >
        <h3 className={classes.textH3}>Êtes-vous sur de vouloir annuler les modifications ?</h3>
      </Modal>
      <OaPopin
        open={openErrorPopin}
        withCancel={false}
        title=""
        confirmText="OK"
        handleConfirm={handleConfirm}
        handleClose={handleConfirm}
      >
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            Une erreur de sauvegarde est survenue, merci de réessayer dans quelques secondes.
          </Typography>
        </div>
      </OaPopin>
    </div>
  );
};

export default StickyFooter;
