
export const retry = async <T>(
  fn: any,
  { retries, retryIntervalMs , args}: { retries: number; retryIntervalMs: number, args: any }
): Promise<T> => {
  try {
    return await fn(args)
  } catch (error) {
    console.log("--------------> error in retry", error)
    if (retries <= 0) {
      console.error('Retry end')
      alert("Une erreur s'est produite lors de l'envoi des données, veuillez réessayer d'interagir avec les champs que vous avez remplis dans quelques instants")
      throw error
    }
    await sleep(retryIntervalMs)
    return retry(fn, { retries: retries - 1, retryIntervalMs, args })
  }
}

const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms))