/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useRef } from 'react';
import { useStyles } from './style';
import Simple from './Simple';
import Double from './Double';
import Normal from './Normal';
import Action from './Action';
import { ContentCenter } from '@orientaction/commons';
import { CircularProgress, Typography } from '@material-ui/core';
import TablePaginationActions, { ITablePaginationActionsProps } from './Pagination';
import clsx from 'clsx';

interface ITableContent {
  btnClick?: (field: string, elem: any) => void;
  oneDisable?: (elem: any) => void;
  onDelete?: (elem: any) => void;
  oneEdit?: (elem: any) => void;
  bgColor: string;
  loading: boolean;
  array: any[];
  coulom: any[];
  pagination?: ITablePaginationActionsProps;
}

const TableContent: FC<ITableContent> = ({
  btnClick,
  oneDisable,
  onDelete,
  oneEdit,
  bgColor,
  loading,
  array,
  coulom,
  pagination,
}) => {
  const refContainer = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  const whenClickBtn = (field: string, elem: any) => (e: any) => {
    e.preventDefault();

    if (btnClick) {
      btnClick(elem, field);
    }
  };

  const whenActionBtn = (elem: any, key: string) => (e: any) => {
    e.preventDefault();

    if (key === 'delete' && onDelete) {
      onDelete(elem);
    }

    if (key === 'enable' && oneDisable) {
      oneDisable(elem);
    }

    if (key === 'edit' && oneEdit) {
      oneEdit(elem);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <ContentCenter>
            <CircularProgress style={{ color: bgColor }} />
          </ContentCenter>
        </>
      ) : (
        <>
          {!array?.length ? (
            <ContentCenter>
              <Typography>Aucun resultat</Typography>
            </ContentCenter>
          ) : (
            <>
              <div className={classes.root} ref={refContainer}>
                {(coulom || []).map((item: any, index: number) => {
                  if (item.type === 'double') {
                    return (
                      <div key={index} className={classes.floatLeft}>
                        <Double data={array} header={item.header} bgColor={bgColor} />
                      </div>
                    );
                  }

                  if (item.type === 'single') {
                    return (
                      <div key={index} className={classes.floatLeft}>
                        <Simple data={array} header={item.header} bgColor={bgColor} />
                      </div>
                    );
                  }

                  if (item.type === 'action') {
                    return (
                      <div
                        key={index}
                        className={clsx({
                          [classes.floatLeftNotBorder]: !item.withBorder,
                          [classes.floatLeft]: item.withBorder,
                        })}>
                        <Action
                          data={array}
                          header={item.header}
                          bgColor={bgColor}
                          actionBtn={whenActionBtn}
                        />
                      </div>
                    );
                  }

                  return (
                    <div key={index} className={classes.floatLeft}>
                      <Normal
                        data={array}
                        header={item.header}
                        width="100%"
                        bgColor={bgColor}
                        btnClick={whenClickBtn}
                        actionBtn={whenActionBtn}
                      />
                    </div>
                  );
                })}
              </div>
              {pagination && (
                <TablePaginationActions
                  count={pagination.count}
                  page={pagination.page}
                  rowsPerPage={pagination.rowsPerPage}
                  onChangePage={pagination.onChangePage}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TableContent;
