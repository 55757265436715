import { FC } from 'react';
import '../../../index.css';
import './couve.css';
import CouveIntro from './couveIntro';
import CouveStepFive from './couveStepFive';
import CouveStepFor from './couveStepFor';
import CouveStepOne from './couveStepOne';
import CouveStepThree from './couveStepThree';
import CouveStepTwo from './couveStepTwo';
import useStyles from './style';

interface IPageCouverture {
  src: string;
}

const PageCouverture: FC<IPageCouverture> = ({ src }) => {
  const classe = useStyles();
  const renderingCouverture = () => {
    switch (src) {
      case 'couverture1.jpg':
        return <CouveStepOne />;

      case 'couverture2.jpg':
        return <CouveStepTwo />;

      case 'couverture3.jpg':
        return <CouveStepThree />;

      case 'couverture4.jpg':
        return <CouveStepFor />;

      case 'couverture5.jpg':
        return <CouveStepFive />;

      default:
        return <CouveIntro />;
    }
  };

  return (
    <div id={`page-${0}`} className={classe.page}>
      {renderingCouverture()}
    </div>
  );
};

export default PageCouverture;
