import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btn: {
            '&.MuiButton-root': {
                border: '1px solid rgb(188, 188, 188)',
                height: '35px',
                minWidth: '35px'
            }
        },
        menuItem: {
            margin: '0px !important',
            overflow: 'visible',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 30,
            paddingBottom: 30
        },
        contentBtn: {
            backgroundColor: 'white',
            color: 'black',
            width: '90%',
            margin: '4vh auto',
            display: 'flex',
            justifyContent: 'center',
            padding: '1vh'
        },
        grow: {
            background: '#EDEEF2',
            height: '100vh',
        },
        menuList: {
            overflow: 'visible',
            paddingTop: '15px !important',
        },
        typography: {
            color: '#0E1247'
        }
    })
);