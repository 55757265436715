/* eslint-disable import/no-unresolved */
import { Grid, Typography } from '@material-ui/core';
import { publicImgMajorTest } from '@orientaction/utils';
import { useStyles } from './style';

const NeedsProHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} md={6} className={classes.displayFlexAlignCenter}>
          <Typography variant="h2" className={classes.titleHeader}>
            Needs® Pro, <br />
            test des 7 besoins
            <br />
            professionnels
          </Typography>
        </Grid>
        <Grid item={true} md={3} className={classes.imageCenterMobile}>
          <img
            src={`${publicImgMajorTest}/illustration_banniere_needs_pro.png`}
            alt="couverture intro"
            className={classes.imageBtn}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NeedsProHeader;
