/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro, publicBookPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page15 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={15}>
      <div className={classes.cardBgGrey}>
        {/* <div style={{ marginBottom: '50px' }}>
          <img className={classes.imgTailleur} src={`${imgBookIntro}/titre25.png`} alt="logo" />
        </div> */}
        <div style={{ marginBottom: '50px' }}>
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              MARIANNE VA MOURIR
            </Typography>
          </SimpleBanner>
        </div>
        <Grid container={true} justifyContent="center">
          <Grid xs={5}>
            <img className={classes.img} src={`${imgBookIntro}/marianne.png`} alt="logo" />
          </Grid>
        </Grid>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Marianne a 49 ans. Elle travaille depuis vingt-cinq ans dans un grand groupe de
          cosmétiques.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Après plusieurs mois de travail intense sans pause (Marianne commence chaque jour son
          travail à 8 heures le matin et finit rarement avant 22 heures), elle demande à sa
          responsable un week-end prolongé.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Elle veut partir quelques jours dans le sud de la France. Elle sait qu’elle a besoin de
          repos, elle sent qu’elle a absolument besoin de repos, de penser à autre chose qu’à ce
          travail qu’elle ne supporte plus ! Sa responsable refuse.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Les jours suivants, Marianne se sent de plus en plus mal. Elle n’a plus aucune force. Elle
          tremble. Ses collègues s’inquiètent. Elle est tellement pâle. Son cœur palpite dans sa
          poitrine, tout tourne autour d’elle, la lumière vacille ; elle est en réunion quand un
          jour elle s’écroule, victime d’un malaise.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Arrêtée en urgence par le médecin du travail, elle reste deux semaines chez elle.
          Plusieurs semaines après, Marianne me raconte cette histoire.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          À 49 ans, elle vient de prendre conscience qu’elle n’a qu’une seule vie, que cette vie ne
          tient qu’à un fil, que ce travail qui l’accable depuis des mois a failli la tuer.
        </Typography>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'normal',
              fontStyle: 'italic',
              fontSize: 15,
            }}
          >
            <strong>Il faut vraiment qu’elle change, cette fois.</strong>
          </Typography>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'normal',
              fontStyle: 'italic',
              fontSize: 15,
            }}
          >
            <strong>Cela faisait dix ans qu’elle y pensait.</strong>
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      // position: 'absolute',
      // top: 15,
      // [theme.breakpoints.up('md')]: {
      //   top: 27,
      // },
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      '@media only screen and (max-width: 600px)': {
        width: '80%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '90%',
      },
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page15;
