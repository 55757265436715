import { FC } from 'react';
import { publicBookPath } from '../../../utils/constants';
import { Grid } from '@material-ui/core';

interface Iimg {
  src: string;
  alt: string;
  style?: any;
  className?: any;
}

const Img: FC<Iimg> = ({ src, alt, style, className }) => {
  return (
    <>
      <Grid container={true} direction="row" justifyContent="center" alignItems="center">
        <img src={`${publicBookPath}/${src}`} alt={alt} className={className} style={style} />
      </Grid>
    </>
  );
};

export default Img;
