/* eslint-disable react/no-array-index-key */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FC, useState } from 'react';
import VariableCard from '../VariableCard';
import VariableDetailsModal from '../VariableDetailsModal';
import styles from './style';

interface IProps {
  variables: any[];
}

const TopVariablesBlock: FC<IProps> = ({ variables }) => {
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [variableToOpen, setVariableToOpen] = useState<any>(null);

  const classes = styles();

  const handleDetailModalOpen = (variableIndex: any) => {
    setIsDetailModalOpen((open: boolean) => !open);
    setVariableToOpen(variables[variableIndex]);
  };

  const handleDetailModalClose = () => {
    setIsDetailModalOpen(false);
    setVariableToOpen(null);
  };

  return (
    <>
      <Typography variant="h2" color="textPrimary" className={classes.titleText}>
        DÉCOUVREZ VOS 3 BESOINS PRINCIPAUX
      </Typography>
      <Grid container={true} style={{ marginTop: 50, marginBottom: 50 }} spacing={3}>
        {variables.map((variable: any, index: number) => (
          <Grid key={index} item={true} xs={12} md={4}>
            <VariableCard index={index} variable={variable} handleOpen={handleDetailModalOpen} />
          </Grid>
        ))}
      </Grid>
      <VariableDetailsModal
        opacity={true}
        open={isDetailModalOpen}
        onCancel={handleDetailModalClose}
        colorSecondary="#80BDEF"
        variable={variableToOpen}
      />
    </>
  );
};

export default TopVariablesBlock;
