import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getNeedsProTestResults = async (token: string) => {
  const searchParams = qs.stringify(
    {
      populate: ['users_permissions_user'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/needs-pro-test-results?${searchParams}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getMyResultNeedPro = async (token: string, id: number) => {
  return makeRequest(`${url}/api/get-result-need-pro-test/${id}`, 'GET', {}, getHeaders(token));
};

export const getMyResumeElement = async (token: string, data: any) => {
  return makeRequest(`${url}/api/get-my-resume-result-need`, 'POST', data, getHeaders(token));
};
