import { Button, CircularProgress, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import style from './style';

interface IContent {
  children: any;
  disable?: boolean;
  to: string;
  loading: boolean;
  to_resultat: string;
  btnRedirectOverride?: any;
  withAccess?: boolean;
  noMarginTop?: boolean;
}

const Content: FC<IContent> = ({
  children,
  disable,
  to,
  loading,
  to_resultat,
  btnRedirectOverride,
  withAccess = true,
  noMarginTop = false,
}) => {
  const classes = style();

  const { push, location } = useHistory();

  const redirect = (e: any) => {
    e.preventDefault();
    return window.open(`${to}`, '_blank');
  };

  const redirect_resultat = () => {
    push(`${to_resultat}?toBack=${location.pathname}`);
  };

  return (
    <>
      {loading ? (
        <div className={classes.contentLoader}>
          <CircularProgress className={classes.colorWhite} />
        </div>
      ) : (
        <div className={classes.root}>
          <div
            className={clsx({
              [classes.hidden]: !disable,
              [classes.inActif]: disable,
            })}>
            <div className={classes.modal}>
              <Typography className={clsx(classes.modalTitle, classes.noMarginBottom)}>
                {withAccess
                  ? "Ce test n'a pas encore été passé."
                  : "Ce test n'a pas encore été attribué."}
              </Typography>
            </div>
          </div>

          <div
            className={clsx({
              [classes.container]: true,
              //   [classes.noMarginTop]: noMarginTop,
            })}>
            {/* <div className={classes.btnContent}>
              <div
                className={clsx({
                  [classes.btnRedirect]: true,
                  [classes.disable]: disable,
                  [btnRedirectOverride]: !!btnRedirectOverride,
                })}
                onClick={redirect_resultat}>
                <Typography>Revoir vos résultats</Typography>
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </div>
            </div> */}
            <div
              className={clsx({
                [classes.content]: true,
                [classes.contentInacif]: disable,
              })}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withWidth()(Content);
