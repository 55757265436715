import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            borderRadius: '2vh',
            border: '1px solid #BCBCBC',
            padding: '1vh',
            marginTop: '1vh',
        },
        iconDragDrop: {
            color: '#9799B1',
            cursor: 'move'
        },
        content: {
            padding: '1vh',
            borderRadius: '2vh',
        }
    })
);