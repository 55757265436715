import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            width: '70%',
            marginBottom: '4vh',
            [theme.breakpoints.down('sm')]: {
                width: '90%',
                left: 0,
            },
        },
        paragraphe: {
            fontSize: '15px',
            color: 'black',
            width: '55%',
            display: 'block',
            float: 'right',
            '@media only screen and (max-width: 600px)': {
                textAlign: 'left !important',
            },
            '@media only screen and (max-width: 361px)': {
                textAlign: 'left !important',
                float: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                float: 'none',
                width: '100%',
            },
        },
        img: {
            width: '35%',
            height: 'auto',
            display: 'block',
            margin: 'auto',
            float: 'left',
            transform: 'translateX(30px)',
            '@media only screen and (max-width: 600px)': {
                transform: 'translateX(0px)'
            },
            '@media only screen and (max-width: 361px)': {
                transform: 'translateX(0px)',
                float: 'none',
            },
            [theme.breakpoints.down('sm')]: {
                float: 'none',
                width: '50%',
            },
        },
    })
);

export default useStyles;