// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getTests = async (
  token: string,
  visibilityPermissions: any = undefined,
  filter?: string,
  filterBoolean?: boolean,
) => {
  if (filter) {
    const searchParams = qs.stringify(
      {
        filters: {
          $or: [
            {
              title: { $containsi: filter },
            },
            {
              description: { $containsi: filter },
            },
          ],
        },
      },
      {
        encodeValuesOnly: true,
      },
    );
    return makeRequest(
      `${url}/api/tests?populate=*&${searchParams}${
        visibilityPermissions ? `&${visibilityPermissions}` : ''
      }&pagination[start]=0&pagination[limit]=10000&sort[1]=createdAt%3Adesc`,
      'GET',
      {},
      getHeaders(token),
    );
  }
  if (filterBoolean) {
    const searchParams = qs.stringify(
      {
        filters: {
          $and: [
            {
              isArchived: false,
            },
            {
              isDesabled: false,
            },
          ],
        },
      },
      {
        encodeValuesOnly: true,
      },
    );
    return makeRequest(
      `${url}/api/tests?populate=*&${searchParams}${
        visibilityPermissions ? `&${visibilityPermissions}` : ''
      }&pagination[start]=0&pagination[limit]=10000&sort[1]=createdAt%3Adesc`,
      'GET',
      {},
      getHeaders(token),
    );
  }
  return makeRequest(
    `${url}/api/tests?populate=*${
      visibilityPermissions ? `&${visibilityPermissions}` : ''
    }&pagination[start]=0&pagination[limit]=10000&sort[1]=createdAt%3Adesc`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getArchivedTests = async (token: string) => {
  return makeRequest(
    `${url}/api/tests?filters[isArchived][$eq]=true&populate=*&pagination[start]=0&pagination[limit]=10000&sort[1]=priority%3Aasc`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getMyTestByCompany = async (token: string, data: any) => {
  return makeRequest(`${url}/api/get-my-test-by-company`, 'POST', { ...data }, getHeaders(token));
};

export const getDisabledTests = async (token: string) => {
  return makeRequest(
    `${url}/api/tests?filters[isDesabled][$eq]=true&populate=*&pagination[start]=0&pagination[limit]=10000&sort[1]=priority%3Aasc`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const postTest = async (token: string, test: any) => {
  return makeRequest(`${url}/api/tests`, 'POST', test, getHeaders(token));
};

export const postTestWithQuestionsAndResponses = async (token: string, test: any) => {
  return makeRequest(`${url}/api/test-questions-responses`, 'POST', test, getHeaders(token));
};

export const putTest = async (token: string, id: string, dataToChange: any) => {
  return makeRequest(`${url}/api/tests/${id}`, 'PUT', dataToChange, getHeaders(token));
};

export const desableTest = async (token: string, id: string, dataToChange: any) => {
  return makeRequest(`${url}/api/desable-test/${id}`, 'PUT', dataToChange, getHeaders(token));
};

export const getTest = async (token: string, id: string, filter: string) => {
  const params = filter?.length > 0 ? filter : 'populate=*';
  return makeRequest(`${url}/api/tests/${id}?${params}`, 'GET', {}, getHeaders(token));
};
export const getTestWithoutPopulate = async (token: string, id: string) => {
  return makeRequest(`${url}/api/tests/${id}`, 'GET', {}, getHeaders(token));
};
export const getResponsesQuestion = async (token: string, id: string) => {
  return makeRequest(`${url}/api/questions/${id}?populate=*`, 'GET', {}, getHeaders(token));
};
export const getResponsePopulate = async (token: string, id: string) => {
  return makeRequest(`${url}/api/responses/${id}?populate=*`, 'GET', {}, getHeaders(token));
};

export const getResponsesPopulate = async (token: string, filter: any) => {
  return makeRequest(`${url}/api/responses?populate=*&${filter}`, 'GET', {}, getHeaders(token));
};

export const getTestPopulated = async (token: string, id: string) => {
  return makeRequest(`${url}/api/test-questions-responses/${id}`, 'GET', {}, getHeaders(token));
};

export const updateTestWithQuestionsAndResponses = async (token: string, test: any) => {
  return makeRequest(`${url}/api/test-questions-responses`, 'PUT', test, getHeaders(token));
};
export const getTestResultText = async (token: string, idTest: string) => {
  return makeRequest(
    `${url}/api/test-result-texts?filters[test][id][$eq]=${idTest}`,
    'GET',
    {},
    getHeaders(token),
  );
};
