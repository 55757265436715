// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
    TitleChapitre,
} from '@orientaction/components';

const Page07 = (props: any) => {
 
    return (
        <Page
            id={7}
        >

            <TitleChapitre nbr={1} />

            <TitlePage>
                CHOISIR ENTRE LES « 5 POSSIBLES »
            </TitlePage>
            <h2
                style={{
                    textAlign: 'center'
                }}
            >
                5 CHEMINS S’OFFRENT À VOUS : 5 POSSIBLES !
            </h2>
            <br />
            <Paragrapth align='justify'>
                Chacun de ces chemins mène à une destination différente et vous propose un
                parcours lui aussi différent. Le choix de ce chemin est crucial, car votre vie sera très
                différente selon ce choix.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Au début, cela se verra peu, mais 10, 20 ou 30 ans plus tard, rien ne sera plus pareil :
                les rencontres que vous aurez faites, les expériences que vous aurez vécues, les
                succès et les échecs que vous aurez eus, les difficultés que vous aurez eues à affronter...
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Un bateau qui change de cap ne parvient pas dans le même port. Pour prendre
                cette décision cruciale, vous disposez maintenant de solides connaissances
                sur vous-même. Vous savez quelles valeurs vous animent, quels sont vos besoins
                fondamentaux et quelle est votre personnalité profonde. Vous avez également
                compris des principes importants sur le bonheur et la réussite professionnelle (et
                personnelle). Vous ne subissez pas les évènements extérieurs. C’est vous qui décidez,
                vous qui déterminez dans quelle direction vous souhaitez aller. Vous êtes libre et
                seul(e) maître(sse) de vos choix.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Vous avez notamment appris une chose très importante : on ne peut prédire les
                conséquences de ses choix. La seule chose que l’on puisse faire, c’est de prendre
                une décision et de tout faire ensuite pour que ce soit la bonne. C’est la seule stratégie
                possible. La meilleure décision n’est donc pas toujours une décision rationnelle.
                C’est votre décision, prise avec vos tripes, avec la ferme intention de vous engager
                et d’atteindre l’objectif que vous vous êtes fixé(e).
            </Paragrapth>
            <br />
            <br />
            <br />
            <br />
            <Paragrapth align='center' colorBlue={true}>
                <strong>Réussir le changement, c’est s’engager puis passer à l’action.</strong>
            </Paragrapth>
            <br />
            <br />
        </Page>
    );
};
export default Page07;
