export const proModalStaticBlockElement: any = {
  security_pro: {
    img: 'security_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous avez besoin de travailler pour une organisation, qui, par sa nature (service public, association, etc.), saura vous apporter la sécurité de l’emploi. Vous avez besoin de bénéficier d’un statut très protecteur au sein de cette organisation (statut de fonctionnaire, CDI, etc.). Enfin, vous avez besoin de savoir que cette organisation est solide financièrement et qu’elle ne risque pas de disparaître du jour au lendemain. Vous aspirez aussi à bénéficier d’une certaine sécurité pour vous-même et votre famille à travers différents avantages comme une bonne protection sociale, l’intéressement et/ou la participation, des actions qui vous aideront à faire face aux coups durs et/ou à mettre de l’argent de côté.',
    },
    environmentToPrivilege: {
      type: 'list',
      content: [
        'Les grandes entreprises publiques ou privées ;',
        'Les grandes administrations ;',
        'Les environnements professionnels dont la culture est de prendre soin de ses collaborateurs(trices) et de ne pas les licencier.',
      ],
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les start-up ou plus généralement les entreprises en très fort développement qui n’aspirent qu’à conquérir de nouveaux marchés, dont la stratégie varie et où les risques de fin de contrat sont importants.',
    },
  },
  accomplishment_pro: {
    img: 'accomplishment_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous aspirez à vous accomplir. Aussi vous recherchez des environnements professionnels qui vous mettent au défi et vous font « sortir de votre zone de confort ». Vous avez besoin d’être stimulé(e) par votre manager(euse) et/ou par l’organisation pour laquelle vous travaillez. Vous aspirez à évoluer dans des environnements toujours plus complexes, qui vous permettent d’acquérir de nouvelles connaissances et/ou de nouvelles compétences, afin de prendre de nouvelles responsabilités. C’est un moteur pour vous et une source de satisfaction que de sentir que vous êtes constamment en train de vous développer et de découvrir de nouvelles facettes de vous-même et de la vie.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les start-up, les entreprises dirigées par un(e) leader(euse) visionnaire qui a de fortes ambitions, certaines filiales de grands groupes en très fort développement, des postes d’expatrié(e)s.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les administrations ou les grandes entreprises dans lesquelles cela ronronne. Les entreprises en général qui sont dans un développement lent et/ou n’ont pas de réelles perspectives.',
    },
  },
  self_realization_pro: {
    img: 'self_realization_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous avez besoin d’évoluer dans un environnement qui favorise la réalisation de soi, c’est-à-dire qui vous offre l’occasion d’exprimer tout votre potentiel et d’exercer un métier qui a du sens. Ce type d’environnement n’est pas toujours offert par une organisation dirigée par un tiers, aussi il est recommandé de créer sa propre activité pour pouvoir se réaliser pleinement. Vous pouvez néanmoins trouver une organisation dont la mission vous parle, qui porte suffisamment haut les valeurs que vous partagez pour pouvoir les embrasser pleinement. Certaines organisations, qui vous laisseront être réellement vous-même, sont également intéressantes.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Créer votre propre entreprise vous offrira un maximum de liberté pour exercer le métier qui a vraiment un sens pour vous ; les organisations à mission.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les entreprises trop orientées business sauf si vous êtes vous-même très orienté(e) business. Les entreprises avec un cadre trop rigide, qui vous empêchent d’être vous-même.',
    },
  },
  physiological_pro: {
    img: 'physiological_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Vous avez besoin que votre entourage professionnel réponde à vos besoins physiologiques. La relation que vous cherchez à développer avec vos collègues est une relation qui s’inspire de la relation dans une fratrie. La relation que vous entretenez avec votre manager(euse) peut s’apparenter à la relation que vous auriez avec un père et/ou une mère, idéal(e) bien sûr. Vous avez besoin de sentir que votre entourage professionnel vous aime, qu’il puisse vous pardonner vos erreurs de la même manière qu’un(e) père/mère et/ou un(e) frère/sœur le feraient. Vous avez /Vous éprouvez/ un fort sentiment d’appartenance à l’égard de votre entreprise que vous pouvez voir comme une deuxième famille et entretenez de vraies relations affectives avec celles et ceux qui y travaillent',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les TPE et PME familiales, les petites administrations comme les mairies et/ou les petites collectivités locales. Vous pourriez aussi être tenté(e) de créer une entreprise, mais de préférence en famille.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les grandes entreprises, les start-up et plus généralement les entreprises en très forte croissance qui n’aspirent qu’à conquérir de nouveaux marchés et à se développer rapidement.',
    },
  },
  horizontal_belonging_pro: {
    img: 'appartenance_h_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous aspirez à développer de nombreuses relations dans votre travail. Vous êtes une personne très sociable et vous vous nourrissez des relations avec les autres. Discuter, échanger, contribuer à la prise de décision, travailler en équipe, sont autant de sources de satisfaction. Vous appréciez particulièrement les temps collectifs. Vous avez également besoin que l’organisation pour laquelle vous travaillez ait une marque forte et facilement identifiable, de façon à pouvoir porter ses couleurs. Vous avez un fort besoin d’appartenance à l’égard de votre organisation. Vous êtes prêt(e) à promouvoir et défendre ses valeurs, ses produits/services ou missions et son image.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les organisations dans lesquelles le sentiment d’appartenance et la fierté sont forts, où l’on peut interagir avec de nombreuses personnes et travailler en équipe.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Travailler en free-lance seul(e) ne peut pas vous convenir, pas plus que d’avoir un métier trop solitaire qui vous priverait de la richesse des échanges du travail en équipe.',
    },
  },
  vertical_belonging_pro: {
    img: 'appartenance_v_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous avez besoin de travailler pour des organisations qui sont connues et reconnues et qui possèdent un certain prestige, une certaine aura et une certaine notoriété. Vous privilégiez des secteurs comme le luxe, l’industrie de pointe, le conseil, etc. Vous aspirez à travailler pour une organisation qui est leadeuse dans son domaine, dans laquelle beaucoup de personnes rêveraient de travailler. Pour vous, intégrer ce type d’organisation est la preuve que vous faites partie d’un petit groupe de privilégié(e)s, supérieur(e)s par leurs savoirs, leurs savoir-faire et leurs savoir-être. Une fois intégré(e) dans ce type d’organisation, vous aspirez à en gravir les échelons pour faire partie des décideurs(ses).',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les grandes entreprises, certaines PME qui sont reconnues pour leur niveau d’excellence dans un domaine spécifique d’activité et qui sont perçues comme prestigieuses.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les petites entreprises et/ou les PME dont la marque n’est pas connue du grand public ou qui n’occupent pas une position de leadeuse dans leur domaine.',
    },
  },
  spirituality_pro: {
    img: 'spirituality_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, votre besoin de spiritualité se traduit par l’aspiration à travailler pour une organisation qui intègre dans ses objectifs, ses méthodes, ses produits/services ou sa mission, une dimension spirituelle. Vous aspirez à évoluer avec des collègues et/ou un(e) manager(euse) avec lequel (laquelle) il est possible de discuter de ce type de sujet, qui sont suffisamment ouvert(e)s et tolérant(e)s à ce type de croyances et/ou de pratiques. Dans l’idéal, vous aimeriez travailler avec des personnes qui partagent vos croyances religieuses ou parareligieuses. Cette dimension spirituelle touche également à la question de vos valeurs, d’une forme de transcendance à laquelle n’échappe pas le travail.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les organisations qui ont une vocation religieuse ; des métiers qui sont en lien avec des pratiques religieuses ou parareligieuses ; des métiers qui sont en lien avec la question de la mort.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les environnements professionnels dont les produits/services et/ou les pratiques seraient contraires à vos croyances et/ou qui ne les tolèreraient pas.',
    },
  },
  eternity_pro: {
    img: 'eternity_details.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine professionnel, vous aspirez à travailler pour une organisation dont l’objet vous permettra de laisser une trace dans l’histoire de l’humanité. Une organisation capable de changer le monde, de marquer l’histoire grâce à une innovation majeure dans le domaine de la santé, du transport et/ou de la technologie, etc. serait une organisation idéale pour vous. Vous n’avez pas envie juste de travailler. Vous voulez que votre travail ait une dimension supérieure, qui s’inscrive dans le temps long. Vous avez envie que votre travail marque votre époque et les époques futures. Vous serez d’autant plus engagé(e) si une organisation vous offre cette opportunité.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les organisations qui ont pour projet de changer le monde, de transformer les pratiques et/ou les mentalités ; des métiers comme écrivain(e), réalisateur(trice), artiste qui permettent de laisser une trace.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les organisations qui ne sont pas animées par le projet ambitieux de réaliser de grandes choses, qui servent juste aux êtres humains à vivre au quotidien, dont la mission est trop « terre à terre ».',
    },
  },
};

export const persoModalStaticBlockElement: any = {
  security_perso: {
    img: 'security_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous avez besoin de développer des relations avec des personnes capables de contribuer à votre sentiment de sécurité. Cette contribution peut passer par différentes voies comme le fait de contribuer à votre sécurité financière, affective et/ou physique. Concrètement, vous avez besoin que votre conjoint(e), que les membres de votre famille et/ou vos ami(e)s, contribuent à vous sécuriser financièrement, affectivement et/ou physiquement. Vous avez donc besoin de sentir que les personnes avec qui vous vivez pourront vous venir en aide si vous en avez besoin, que vous pouvez compter sur elles, car elles vous ont déjà démontré par le passé cet esprit de solidarité et d’entraide.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Des personnes qui ont une situation professionnelle stable, qui gagnent bien leur vie ; des personnes fortes physiquement et/ou sur le plan de leur caractère, etc.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes qui ont une situation professionnelle instable, des revenus irréguliers ; les personnes instables affectivement ; les personnes faibles physiquement ou sur le plan de leur caractère.',
    },
  },
  accomplishment_perso: {
    img: 'accomplishment_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous avez besoin de développer des relations avec des personnes qui aiment relever des défis et qui ont des ambitions. Ces ambitions peuvent se traduire par la recherche d’exploits sportifs, artistiques, intellectuels, culturels, etc. Cela peut aussi passer par des voyages. Ce qui compte pour vous c’est que votre conjoint(e) et/ou vos ami(e)s aient des projets et que vous puissiez avoir des projets en commun. Vous appréciez les relations humaines à condition que celles-ci soient orientées vers un but. Passer du temps ensemble pour passer du temps ensemble ne vous stimule pas suffisamment. Aussi, dans votre couple, en famille et/ou avec vos ami(e)s, vous avez besoin d’avoir constamment des projets et/ou des objectifs à atteindre.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Des personnes qui ont des projets, qui bougent en permanence, qui sont capables de se lancer et/ou de vous lancer de nouveaux défis sportifs, culturels, intellectuels et/ou artistiques.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes qui n’ont pas ou trop peu de projets, qui se contentent de la vie qu’elles ont, qui ne veulent pas « sortir de leur zone de confort », qui ne vous stimulent pas.',
    },
  },
  self_realization_perso: {
    img: 'self_realization_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous avez besoin de vous réaliser et que votre entourage vous aide à y parvenir. Se réaliser, c’est pouvoir exercer un métier et mener une existence qui correspond à ses valeurs, à ce qui compte vraiment pour soi, au-delà de ce que peuvent penser les autres. Vous avez donc besoin de sentir que votre entourage familial, conjugal et amical vous soutient dans vos initiatives, qu’au lieu d’être critique et/ou de vous juger, il vous encourage à réaliser vos rêves et à vous épanouir pleinement. Vous attendez ce soutien et cet enthousiasme dans votre vie, car cela vous aidera à oser. C’est vraiment un besoin pour vous dans vos relations que de ne pas être jugé(e), mais au contraire soutenu(e) dans votre quête de soi.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Des personnes qui sont sensibles au développement personnel, qui ont réellement envie que vous soyez heureux(se) et qui vont vous aider à concrétiser vos aspirations.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes qui pensent que la vie est faite pour se sacrifier pour les autres, qu’il ne faut pas rêver et/ou chercher à réaliser ses rêves ; les personnes qui voient la réalisation de soi comme impossible.',
    },
  },
  physiological_perso: {
    img: 'physiological_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Vous avez besoin que votre entourage personnel prenne soin de vous et réponde à vos besoins physiologiques : manger, boire, rire, recevoir de l’affection, avoir des relations intimes, etc. Vous avez besoin de relations qui satisfont des plaisirs simples, « du corps », qui se calquent peu ou prou sur un type de relation que vous jugez idéal : la relation familiale. Vous aspirez à être aimé(e) quoi que vous fassiez, quoi que vous disiez. Pour satisfaire ce besoin, vous devez développer des relations avec des personnes aimantes et affectueuses. Vous aspirez à passer du temps avec vos proches à travers des plaisirs simples comme partager un repas, boire un verre, rire ou danser ou simplement faire une promenade.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Des personnes qui ont un grand attachement à la famille, qui mettent au centre de leur vie des plaisirs simples comme rire , jouer,  partager un repas , etc.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes qui sont prêtes à sacrifier leur vie de famille et/ou leurs relations avec les autres pour atteindre des objectifs ambitieux ; les personnes trop exigeantes envers les autres, etc.',
    },
  },
  horizontal_belonging_perso: {
    img: 'appartenance_h_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous avez besoin de développer des relations avec de très nombreuses personnes. Faire partie d’une équipe sportive, d’une association culturelle, avoir un groupe d’ami(e)s que vous voyez régulièrement, une famille soudée, sont des sources de satisfaction importante dans votre vie. Vous avez besoin plus généralement de vous sentir appartenir à un groupe, d’y avoir votre place et d’être reconnu(e) comme un(e) membre à part entière. Cela crée beaucoup de frustration chez vous si vous n’êtes pas invité(e) à un évènement qui rassemble les autres membres de votre « tribu ». Vous êtes attaché(e) aux autres personnes et avez besoin de sentir qu’elles sont attachées à vous.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Des personnes très sociables, qui aiment faire de nouvelles rencontres et qui sont investies et/ou appartiennent elles-mêmes au même groupe que vous.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes trop individualistes qui visent à réaliser uniquement des projets personnels ; les personnes solitaires qui aiment vivre éloignées des autres êtres humains.',
    },
  },
  vertical_belonging_perso: {
    img: 'appartenance_v_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous avez besoin de développer des relations avec des personnes qui possèdent un certain statut social, une certaine aura et/ou une certaine notoriété. Vous n’aimez pas côtoyer des personnes moyennes, car vous visez l’excellence dans vos relations. Cela peut passer par le niveau de diplômes, de fortune, de culture ou encore de beauté physique, etc. Vous avez besoin d’être entouré(e) de personnes brillantes dont l’éclat peut rejaillir sur vous d’une manière ou d’une autre. Vous êtes donc très exigeant(e) sur vos relations et les choisissez scrupuleusement. Vous abhorrez la médiocrité. Vous aspirez à évoluer avec des personnes supérieures aux autres.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Des personnes occupant un emploi prestigieux, qui gagnent beaucoup d’argent et/ou sont fortunées de naissance, des personnes issues de la noblesse et/ou de la bourgeoisie.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes qui viennent de milieux populaires, qui habitent dans des petites villes, qui exercent des métiers moyens ou peu valorisés socialement, qui n’ont pas fait d’études ou réalisé de choses notables.',
    },
  },
  spirituality_perso: {
    img: 'spirituality_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous avez besoin de vivre en contact avec des forces, des entités ou des principes invisibles et supérieurs. Vous avez besoin de donner un sens à ce que vous faites ou vivez. Cela se traduit chez vous par certaines croyances ou certaines pratiques religieuses ou parareligieuses. Vous avez besoin de pratiquer certains rituels, de vous ouvrir à certaines croyances qualifiées d’ « ésotériques». C’est pour vous une source de satisfaction que de sentir que votre entourage partage les mêmes croyances ou vous laisse exprimer vos convictions et vos intuitions sans vous juger. Vous avez besoin que votre entourage ait une certaine ouverture et une certaine sensibilité sur ces sujets-là.',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les personnes qui partagent vos croyances et/ou pratiquent la même religion. Les personnes suffisamment ouvertes d’esprit pour en parler.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes trop matérialistes, qui ne parviennent pas à comprendre pourquoi certaines personnes croient en des choses invisibles et invérifiables.',
    },
  },
  eternity_perso: {
    img: 'eternity_details_perso.jpg',
    toBeHappy: {
      type: 'paragraph',
      content:
        'Dans le domaine personnel, vous aspirez à laisser une trace au-delà de votre propre vie. Que cela soit à travers vos enfants, le fait de bâtir une maison, d’écrire un livre, etc. vous aspirez à laisser quelque chose de concret aux générations futures. Vous souhaitez que votre conjoint(e), que les membres de votre famille et/ou vos ami(e)s contribuent à cela. Vous avez besoin qu’ils partagent et/ou soutiennent cette aspiration. Vous avez besoin que vos enfants se souviennent de vous et contribuent à entretenir votre souvenir, qu’ils s’investissent activement pour faire fructifier ce que vous leur laisserez en héritage (connaissances, patrimoine immobilier ou financier, œuvres scientifiques, artistiques ou culturelles, etc.).',
    },
    environmentToPrivilege: {
      type: 'paragraph',
      content:
        'Les personnes qui ont envie de faire quelque chose avec vous qui laissera une trace qui ira bien au-delà de votre existence sur terre.',
    },
    environmentToAvoid: {
      type: 'paragraph',
      content:
        'Les personnes trop terre à terre, centrées sur le moment présent, qui ne voient pas plus loin que le bout de leur nez ; les personnes incapables de se projeter dans un projet qui les dépasse.',
    },
  },
};
