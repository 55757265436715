/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import clsx from 'clsx';
import { cloneDeep, difference } from 'lodash';
import { useState } from 'react';
import { imgBook1, imgBook2 } from '../../../../utils/constants';

const Page40 = (props: any) => {
  const classes = useStyles();
  const {
    setRolePrefere,
    setRolePossible,
    setRoleAEviter,
    rolePrefere,
    rolePossible,
    roleAEviter,
  } = useBook2();
  const textSelectedProtected = [
    'LE CONCEPTEUR (LA CONCEPTRICE)',
    'LE STRATÈGE (LA STRATÈGE)',
    'L’EXPERT (L’EXPERTE)',
    'L’ORGANISATEUR (L’ORGANISATRICE)',
    'LE PROPULSEUR (LA PROPULSEUSE)',
    'LE PEAUFINEUR (LA PEAUFINEUSE)',
    'LE COORDINATEUR (LA COORDINATRICE)',
    'LE PROMOTEUR (LA PROMOTRICE)',
    'LE SOUTIEN',
  ];
  const [textSelected1, setTextSelected1] = useState<any>(textSelectedProtected);
  const [textSelected2, setTextSelected2] = useState<any>(textSelectedProtected);
  const [textSelected3, setTextSelected3] = useState<any>(textSelectedProtected);

  const [textSelected4, setTextSelected4] = useState<any>(textSelectedProtected);
  const [textSelected5, setTextSelected5] = useState<any>(textSelectedProtected);
  const [textSelected6, setTextSelected6] = useState<any>(textSelectedProtected);

  const [textSelected7, setTextSelected7] = useState<any>(textSelectedProtected);
  const [textSelected8, setTextSelected8] = useState<any>(textSelectedProtected);
  const [textSelected9, setTextSelected9] = useState<any>(textSelectedProtected);
  const [select1, setSelect1] = useState<any>(rolePrefere);
  const [select2, setSelect2] = useState<any>(rolePossible);
  const [select3, setSelect3] = useState<any>(roleAEviter);

  const removeFromIndex = (array: any, index: number) => {
    return array.map((elem: any, i: number) => {
      let el = elem;
      if (index === i) {
        el = '';
      }
      return el;
    });
  };
  const verifyDataInArray1 = (data: any, index: number) => {
    if (index === 0) {
      setTextSelected2(difference(textSelectedProtected, removeFromIndex(data, 1)));
      setTextSelected3(difference(textSelectedProtected, removeFromIndex(data, 2)));
    } else if (index === 1) {
      setTextSelected1(difference(textSelectedProtected, removeFromIndex(data, 0)));
      setTextSelected3(difference(textSelectedProtected, removeFromIndex(data, 2)));
    } else if (index === 2) {
      setTextSelected1(difference(textSelectedProtected, removeFromIndex(data, 0)));
      setTextSelected2(difference(textSelectedProtected, removeFromIndex(data, 1)));
    }
  };
  const verifyDataInArray2 = (data: any, index: number) => {
    if (index === 0) {
      setTextSelected5(difference(textSelectedProtected, removeFromIndex(data, 1)));
      setTextSelected6(difference(textSelectedProtected, removeFromIndex(data, 2)));
    } else if (index === 1) {
      setTextSelected4(difference(textSelectedProtected, removeFromIndex(data, 0)));
      setTextSelected6(difference(textSelectedProtected, removeFromIndex(data, 2)));
    } else if (index === 2) {
      setTextSelected4(difference(textSelectedProtected, removeFromIndex(data, 0)));
      setTextSelected5(difference(textSelectedProtected, removeFromIndex(data, 1)));
    }
  };
  const verifyDataInArray3 = (data: any, index: number) => {
    if (index === 0) {
      setTextSelected8(difference(textSelectedProtected, removeFromIndex(data, 1)));
      setTextSelected9(difference(textSelectedProtected, removeFromIndex(data, 2)));
    } else if (index === 1) {
      setTextSelected7(difference(textSelectedProtected, removeFromIndex(data, 0)));
      setTextSelected9(difference(textSelectedProtected, removeFromIndex(data, 2)));
    } else if (index === 2) {
      setTextSelected7(difference(textSelectedProtected, removeFromIndex(data, 0)));
      setTextSelected8(difference(textSelectedProtected, removeFromIndex(data, 1)));
    }
  };

  const handleChangeSelect1 = (e: any, index: number) => {
    const data = cloneDeep(select1);
    data[index] = e.target.value;
    verifyDataInArray1(data, index);
    setSelect1(data);
    setRolePrefere(data);
  };
  const handleChangeSelect2 = (e: any, index: number) => {
    const data = cloneDeep(select2);
    data[index] = e.target.value;
    verifyDataInArray2(data, index);
    setSelect2(data);
    setRolePossible(data);
  };
  const handleChangeSelect3 = (e: any, index: number) => {
    const data = cloneDeep(select3);
    data[index] = e.target.value;
    verifyDataInArray3(data, index);
    setSelect3(data);
    setRoleAEviter(data);
  };

  return (
    <Page id={41} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            CONSIGNES
          </Typography>
        </div>
        <div
          className={clsx(classes.trait, classes.positionCenter)}
          style={{ marginTop: '50px', marginBottom: '50px' }}
        />
        <div>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Notez dans ce tableau vos trois rôles préférés, vos trois rôles possibles et vos trois
            rôles à éviter. Essayez pour chacun de ces rôles de vous remémorer des situations dans
            lesquelles vous avez occupé ces dits rôles et éprouvé du plaisir (mes trois rôles
            préférés), éprouvé du plaisir ou des émotions neutres (mes trois rôles possibles) et des
            sentiments négatifs ou un malaise (mes trois rôles à éviter).
          </Typography>
          <br />
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            Vous pourrez échanger avec votre consultant(e) sur ces situations afin de préciser vos
            aspirations et mieux choisir ainsi votre place à l’avenir dans un groupe.
          </Typography>
        </div>
        <div style={{ marginTop: '50px', marginBottom: '20px' }}>
          <Grid container={true} spacing={2} justifyContent="center">
            <Grid item={true} xs={6}>
              <img
                className={classes.img}
                src={`${imgBook2}/illustration_page_55.png`}
                alt="logo"
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: '50px', marginBottom: '20px' }}>
          <Grid container={true}>
            <Grid item={true} xs={4}>
              <div>
                <Grid container={true}>
                  <Grid
                    item={true}
                    xs={12}
                    style={{ textAlign: 'center' }}
                    className={classes.categorieB}>
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                      MES 3 RÔLES PRÉFÉRÉS
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select1[0]}
                        onChange={(e: any) => handleChangeSelect1(e, 0)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected1?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select1[1]}
                        onChange={(e: any) => handleChangeSelect1(e, 1)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected2?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select1[2]}
                        onChange={(e: any) => handleChangeSelect1(e, 2)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected3?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item={true} xs={4}>
              <div>
                <Grid container={true}>
                  <Grid
                    item={true}
                    xs={12}
                    style={{ textAlign: 'center' }}
                    className={classes.categorieB}>
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                      MES 3 RÔLES POSSIBLES
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select2[0]}
                        onChange={(e: any) => handleChangeSelect2(e, 0)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected4?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select2[1]}
                        onChange={(e: any) => handleChangeSelect2(e, 1)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected5?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select2[2]}
                        onChange={(e: any) => handleChangeSelect2(e, 2)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected6?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item={true} xs={4}>
              <div>
                <Grid container={true}>
                  <Grid
                    item={true}
                    xs={12}
                    style={{ textAlign: 'center' }}
                    className={classes.categorieB}>
                    <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                      MES 3 RÔLES À ÉVITER
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select3[0]}
                        onChange={(e: any) => handleChangeSelect3(e, 0)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected7?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select3[1]}
                        onChange={(e: any) => handleChangeSelect3(e, 1)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected8?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} className={classes.categorieA}>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={select3[2]}
                        onChange={(e: any) => handleChangeSelect3(e, 2)}
                        placeholder="Entreprise"
                        style={{ border: '0.5px solid #2b56a5' }}
                        displayEmpty={true}>
                        <MenuItem value="" disabled={true}>
                          Cliquer ici
                        </MenuItem>
                        {textSelected9?.map((text: any) => {
                          return (
                            <MenuItem value={text} key={text}>
                              {text}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6',
    },
    mediaCard: {
      width: '100%',
      height: 'auto',
    },
    h7: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      display: 'inline-block',
      style: 'normal',
      alignItems: 'center',
      padding: 20,
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      color: '#0E1247',
      textAlign: 'center',
      display: 'block',
      style: 'normal',
      alignItems: 'center',
      padding: 20,
      paddingTop: 10,
      paddingBottom: 10,
      '@media only screen and (max-width: 600px)': {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 3,
        paddingRight: 3,
        fontSize: 12,
        fontWeight: 800,
      },
      '@media only screen and (max-width: 361px)': {
        paddingTop: 10,
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom: 10,
        fontSize: 12,
        fontWeight: 800,
      },
    },
    categorieB: {
      color: '#2c57a5 !important',
      background: '#eaeff9',
      fontSize: '25px',
      border: '1px solid #0E1247',
    },
    categorieA: {
      color: '#2c57a5',
      background: '#fff',
      fontSize: '25px',
      border: '1px solid #0E1247',
      position: 'relative',
      height: 'auto',
    },
    texteTitle: {
      font: 'Poppins',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19.6px',
      color: '#9799B1',
      display: 'inline-block',
      style: 'normal',
    },
    textCard: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19.6px',
      color: '#0E1247',
      style: 'normal',
    },
    borderTable: {
      border: '1px solid #0E1247',
      borderRadius: 'none',
    },
    textArea: {
      width: '98%',
      height: '100px',
      border: 'none',
      fontSize: '15px',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      display: 'block',
    },
    floatIndex: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      background: `url(${imgBook1}/back.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '50px',
      height: '50px',
      '& > span': {
        color: 'white',
        position: 'absolute',
        bottom: '5px',
        left: '5px',
      },
    },
  })
);
export default Page40;
