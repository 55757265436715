import Typography from '@material-ui/core/Typography';
import Diagram from './Diagram';
import style from './style';

const Score = (props: any) => {
  const { score } = props;
  const classes = style();
  const percentage = Math.round((score?.score * 100) / 1200) || 0;
  const graphData = [percentage, 100 - percentage];

  return (
    <div className={classes.scoreContainer}>
      <div>
        <Diagram graphData={graphData} />
      </div>
      <div className={classes.scoreResultContainer}>
        <div>
          <Typography className={classes.scoreResultText} component="h5">
            Ton profil correspond à {percentage}% à
          </Typography>
        </div>
        <div>
          <Typography className={classes.scoreResultText} component="h5">
            {`${score?.label}`}.
          </Typography>
        </div>
      </div>
      <div className={classes.scoreResultDescription}>Ton score : {`${score?.score}`} / 1200</div>{' '}
    </div>
  );
};

export default Score;
