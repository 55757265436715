import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  accordionSummary: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  circle: {
    position: 'relative',
    width: '3vw',
    height: '3vw',
    borderRadius: '50%',
    background: '#000A8C',
  },
  number: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: 12,
  },
  summaryText: {
    width: '70%',
  },
  summaryTextH6: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#0E1247',
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    padding: 5,
  },
  accordionDetails: {
    color: theme.palette.primary.dark,
  },
  variableNameLabel: {
    color: theme.palette.primary.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    paddingLeft: 10,
  },
  variableNameText: {
    color: theme.palette.success.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingLeft: 3,
  },
}));

export default useStyles;
