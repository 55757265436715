/* eslint-disable react-hooks/exhaustive-deps */
import { useAdminUser } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserServices } from '../../../../services/services.service';
import { isTopManager } from '../../../../utils/isAdmin';
import { FORM_MODE, inCardService, oaServices } from '../constant';
import { omit } from 'lodash';

const returnMode = (state: any) => {
  if (state.isView) {
    return FORM_MODE.readonly;
  }

  if (state.isUpdate) {
    return FORM_MODE.update;
  }

  return FORM_MODE.create;
};

export const UserFormContext = createContext({ mode: FORM_MODE.create }) as any;

export const UserFormContextProvider = ({ children }: any) => {
  const [formUser, setFormUser] = useState<any>({});
  const { current } = useAdminUser();

  const managers = useSelector((state: any) => state?.adminUser?.listUpHierachy);

  const token = getFromLS('userToken') || '';

  const updateFormUser = useCallback(async (data: any) => {
    setFormUser((state: any) => ({ ...state, ...data }));
  }, []);

  const setInitialContextValue = async () => {
    const mode = returnMode(current);
    if (current.company_user_role === 5) {
      // User to create is a beneficiary
      if (mode === FORM_MODE.update) {
        if (+current?.upper_hierarchy) {
          // set user service
          let services;
          const currentManager = managers.find((m: any) => +m.id === +current.upper_hierarchy);
          const isUserTopManager = isTopManager(currentManager?.company_user_role?.id);
          if (isUserTopManager) {
            // any top managers has rights on all services
            services = oaServices;
          } else {
            // get services in which consultant has rights
            const {
              data: { data },
            } = await getUserServices(token, current.upper_hierarchy);
            services = data;
          }
          updateFormUser({
            ...current,
            language: current?.language?.id === 2 ? 2 : 1,
            mode,
            services: services.concat(inCardService),
          });
        } else {
          // user is probably a non rattached beneficiary
          updateFormUser({
            ...current,
            language: 1,
            mode,
          });
        }
      } else if (mode === FORM_MODE.create) {
        updateFormUser({
          ...current,
          language: 1,
          mode,
          services: oaServices.concat(inCardService),
        });
      }
    }
    if (current.company_user_role !== 5) {
      updateFormUser({
        ...omit(current, ['service']),
        language: current?.language?.id === 2 ? 2 : 1,
        mode,
      });
    }
  };

  useEffect(() => {
    // init context
    setInitialContextValue();
  }, [current.mode]);

  return (
    <UserFormContext.Provider value={{ formUser, updateFormUser, setFormUser }}>
      {children}
    </UserFormContext.Provider>
  );
};
