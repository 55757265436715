/* eslint-disable no-use-before-define */
import useStyles from './style';
import { FC } from 'react';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { TextArea } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import clsx from 'clsx';


interface IArrayYesNoDisable {
    title: string;
    list: any[];
}

const ArrayYesNoDisable: FC<IArrayYesNoDisable> = ({
    title,
    list,
}) => {

    const classes = useStyles();

    const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <div className={classes.border}>

            <Grid
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.titlePage}
            >

                <Grid item={true} lg={10} md={10} sm={10} xs={12}>
                    <div className={classes.paddingTitle}>
                        {title}
                    </div>
                </Grid>

                {
                    !isXs && (
                        <>
                            <Grid item={true} lg={1} md={1} sm={1} xs={6}
                                className={clsx({
                                    [classes.notActived]: true,
                                })}
                            >
                                <div className={classes.paddingTitle}>
                                    OUI
                                </div>
                            </Grid>

                            <Grid item={true} lg={1} md={1} sm={1} xs={6}
                                className={clsx({
                                    [classes.notActived]: true,
                                })}
                            >
                                <div className={classes.paddingTitle}>
                                    NON
                                </div>
                            </Grid>
                        </>
                    )
                }

            </Grid>

            <Grid
                container={true}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >

                {
                    list.map((item: any) => (

                        <Grid
                            item={true}
                            xs={12}
                            className={classes.contentArray}
                        >

                            <Grid
                                container={true}
                            >

                                <Grid item={true} lg={10} md={10} sm={10} xs={12}>

                                    <div className={classes.textContent}>
                                        <Paragrapth align='justify'>
                                            {item?.text || ""}
                                        </Paragrapth>
                                    </div>

                                </Grid>

                                <Grid item={true} lg={1} md={1} sm={1} xs={6}
                                    className={clsx({
                                        [classes.notActived]: true,
                                    })}
                                >
                                    <div>
                                        {
                                            (item.active === true) && (
                                                <CheckIcon style={{ color: '#5fc194' }} />
                                            )
                                        }
                                    </div>
                                </Grid>

                                <Grid item={true} lg={1} md={1} sm={1} xs={6}
                                    className={clsx({
                                        [classes.notActived]: true,
                                    })}
                                >
                                    <div>
                                        {
                                            (item.active === false) && (
                                                <CloseIcon style={{ color: '#f1665f' }} />
                                            )
                                        }
                                    </div>
                                </Grid>

                            </Grid>

                        </Grid>
                    ))
                }

            </Grid>

        </div>
    );
};

export default ArrayYesNoDisable;
