/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { AlertActions } from '@orientaction/api-actions';
import { appAlert } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';

type TypeSeverity = 'success' | 'info' | 'warning' | 'error';

interface dataApi {
  open: boolean;
  message: string;
  severity: TypeSeverity;
}

const useAlert = () => {
  const dispatch = useDispatch();

  const data: dataApi = useSelector(appAlert);

  const setOpenAlert = (open: boolean, message: string, severity: TypeSeverity) => {
    dispatch(AlertActions.setMessage(message));
    dispatch(AlertActions.setOpen(open));
    dispatch(AlertActions.setSeverity(severity));
  };

  const setCloseAlert = () => {
    dispatch(AlertActions.setOpen(false));
    dispatch(AlertActions.setMessage(''));
    dispatch(AlertActions.setSeverity('error'));
  };

  const openSimpleAlert = (message = 'Une erreur est survenue, merci de réessayer plus tard') => {
    dispatch(AlertActions.setOpen(true));
    dispatch(AlertActions.setMessage(message));
    dispatch(AlertActions.setSeverity('error'));
  };

  return {
    ...data,
    setOpenAlert,
    setCloseAlert,
    openSimpleAlert,
  };
};

export default useAlert;
