import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '10px',
            overflow: 'hidden'
        },
        controls: {
            marginTop: '12px',
        },
        img: {
            width: '90px',
            height: '90px',
            borderRadius: '10px',
        },
        contentImgFavorite: {
            position: 'relative',
            width: '100%',
            "&::after": {
                content: "",
                display: 'block',
                paddingBottom: '100%',
                border: '1px solid red'
            }
        },
        imgFavorite: {
            width: '100%',
            paddingBottom: '70%',
            backgroundSize: 'cover',
            borderRadius: 30,
        },
        fontTitleCard: {
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '140%',
            color: '#000A8C',
            cursor: 'pointer',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        fontTextCard: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '140%',
            color: '#0E1247',
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&>p': {
                marginTop: 5,
                marginBottom: 5,
            },
        },
        btnIcon: {
            border: '1px solid grey',
            borderRadius: '5px !important',
            width: 32,
            height: 32,
            marginRight: '8px',
            padding: '2px',
        },
        styleIcon: {
            color: '#0E1247',
            width: '20px',
            height: '20px',
        },
        cardContainer: {
            padding: '10px',
            borderRadius: '10px',
            boxShadow: 'none',
            backgroundColor: 'white',
            minHeight: '120px',
            border: '0.5vh solid white',
            overflow: 'hidden'
        },
        chipTime: {
            backgroundColor: 'white',
            color: 'grey',
            height: 'auto',
            '&> svg': {
                marginLeft: '0px !important',
                marginRight: '0px !important',
            },
            "&> span": {
                paddingRight: "5px !important",
            },
        },
        chipTime2: {
            backgroundColor: 'white',
            color: 'grey',
            height: 'auto',
            '&> span': {
                padding: '0px !important',
            },
        },
        marginChip: {
            marginTop: 0,
        },
        positionAbsolute: {
            position: 'absolute',
            bottom: 14,
            width: '68%',
        },
        bouttom: {
            padding: '5vh'
        },
        start: {
            position: 'relative',
            float: 'right',
            right: -40,
            width: 90,
            height: 90
        }
    })
);