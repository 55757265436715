/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import VariableCardItem from '../VariableCardItem';

interface IProps {
  variables: any[];
}

const VariableCardList: FC<IProps> = ({ variables }) => {
  return (
    <div>
      {variables.map((variable: any, index: number) => {
        if (variable.name) {
          return <VariableCardItem key={index} variable={variable} />;
        }
      })}
    </div>
  );
};

export default VariableCardList;
