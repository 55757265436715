/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../../store';

export const setIndoorWeather = (nbr: number): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'INDOORWEATHER',
            payload: nbr,
        });
    };

export const setPersonnelTest = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'PERSONNELTEST',
            payload: data,
        });
    };

export const setStylePersonnel = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'STYLEPERSONNEL',
            payload: data,
        });
    };

export const setId = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'ID',
            payload: data,
        });
    };



