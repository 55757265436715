/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../components/Image';
import useStyles from './style';

const Page28 = () => {
  const classes = useStyles();

  return (
    <Page id={28}>
      <MarginContainer>
        <Paragrapth align="justify">
          Tout ce que vous vivez ou faites contribue à constituer votre capital symbolique et donc
          votre rayonnement personnel.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Pour avoir du capital symbolique, il n’est pas nécessaire d’avoir un diplôme issu d’une
          grande école, il suffit d’avoir un diplôme d’une école qui a une bonne réputation là où
          vous vivez ou d’avoir travaillé dans une entreprise, une association ou une administration
          connues des gens qui vivent là où vous vivez.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Il suffit d’être bénévole dans une association reconnue pour son utilité publique, de
          vivre dans un quartier, un village, une ville ou une région dont les habitants ont une
          bonne image ou encore où il fait bon vivre. Encore une fois, le capital symbolique est
          partout.
        </Paragrapth>
      </MarginContainer>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          <Grid container={true} justifyContent="center" alignItems="center">
            <Grid item={true} xs={12} md={6}>
              {/* <Img filename="afpi.png" alt="afpi" style={{ width: '100%' }} /> */}
              <SimpleBanner
                bannerContainerOverride={classes.bannerContainerOverride}
                bannerStyleOverride={classes.bannerStyleOverride}
              >
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                >
                  A.F.P.I
                </Typography>
              </SimpleBanner>
            </Grid>

            <Grid item={true} xs={12} md={12}>
              <Img filename="machine.png" alt="machine" className={classes.img} />
            </Grid>

            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Il y a peu de chances que vous connaissiez ce sigle. Il désigne un centre de
                formation sous forme associative installé au Mans qui œuvre pour l’industrie. Cette
                école forme du CAP ou Bac +2. Cette école n’est pas prestigieuse. Elle n’entend pas
                rivaliser avec Polytechnique ou l’université de Paris Sorbonne. Elle est connue
                seulement par un nombre très restreint de personnes.
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Pourtant si vous voulez travailler dans l’industrie au Mans, le fait de dire que
                vous avez étudié dans cette école vous ouvrira des portes parce que cette école est
                connue des ouvriers(ères) et des techniciens(nes) qui travaillent dans l’industrie.
                Un grand nombre d’entre eux (elles) ont suivi une formation courte ou longue dans
                cette école. Cela les rassurera de savoir que vous avez étudié dans cette école
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                L’A.F.P.I possède un rayonnement local qui confère à celles et ceux qui ont suivi
                une formation courte ou longue un capital symbolique qu’ils (elles) pourront
                réutiliser s’ils (si elles) recherchent un emploi dans l’industrie ou décident de
                créer/reprendre leur entreprise dans ce secteur.
              </Paragrapth>
            </Grid>
            <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
              <Paragrapth align="justify" addBackground={true}>
                Leurs futur(e)s employeurs(ses) et/ou client(e)s auront un a priori positif à leur
                sujet.
              </Paragrapth>
            </Grid>
          </Grid>
        </MarginContainer>
      </div>
      <MarginContainer>
        <Paragrapth align="justify">
          L’outil suivant va vous aider à identifier et à mesurer le capital symbolique que vous
          pouvez mobiliser pour réussir votre projet professionnel. N’oubliez pas que le capital
          symbolique, tout comme les capitaux économique, culturel ou social, possède une dimension
          subjective : combien de personnes n’osent pas par modestie mettre en avant les références,
          les gens, les projets ou les organisations pour lesquels elles ont travaillé. Toute
          personne est une marque liée à d’autres marques.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">Osez briller un peu !</Paragrapth>
      </MarginContainer>
    </Page>
  );
};

export default Page28;
