/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FC } from 'react';
import { useStyles } from './style';

interface ICardTime {
  time?: string;
}

const CardTime: FC<ICardTime> = ({ time }) => {
  const classes = useStyles();

  return (
    <>
      {time && (
        <div className={classes.chipTime}>
          <div>
            <AccessTimeIcon className={classes.iconeTime} />
          </div>
          <div className={classes.textTime} data-cy="time-card-test">
            {time} min
          </div>
        </div>
      )}
    </>
  );
};

export default CardTime;
