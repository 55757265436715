/* eslint-disable no-use-before-define */
import useStyles from './style';
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import { FC } from 'react';
import parse from 'html-react-parser';

interface IArray {
  title: string;
  handleChange: (e: any) => void;
  defaultValue: string;
  listForm?: any[];
  readOnly?: boolean;
}

const Array: FC<IArray> = ({ handleChange, defaultValue, title, listForm, readOnly }) => {
  const classes = useStyles();

  return (
    <div className={classes.border}>
      <div className={classes.titlePage}>{parse(title)}</div>

      {listForm && (
        <MarginContainer>
          <div className={classes.content}>
            <i>
              <Paragrapth align="start" bold={true}>
                Référez-vous ici aux résultats du test de personnalité (cf. étape 2)
              </Paragrapth>
            </i>
            {listForm.map((item: any, index: number) => (
              <>
                <MarginContainer>
                  <MarginContainer>
                    <Paragrapth align="start" colorBlue={true} bold={true}>
                      {`${index + 1}. ${parse(item.title2)}`}
                    </Paragrapth>
                  </MarginContainer>

                  <MarginContainer>
                    <i>
                      <Paragrapth align="start">{parse(item.explication)}</Paragrapth>
                    </i>
                  </MarginContainer>

                  <MarginContainer>
                    <TextArea
                      name={item.name}
                      value={defaultValue?.[item.name] || ''}
                      onChange={handleChange}
                      readOnly={readOnly}
                    />
                  </MarginContainer>
                </MarginContainer>
              </>
            ))}
          </div>
        </MarginContainer>
      )}
    </div>
  );
};

export default Array;
