/* eslint-disable no-use-before-define */
import { Grid } from '@material-ui/core';
import useStyles from './style';
import { FC } from 'react';
import parse from 'html-react-parser';

interface InterfaceItem {
    label1: string;
    label2: string;
}

const Item: FC<InterfaceItem> = ({ label1, label2 }) => {

    const classes = useStyles();

    return (
        <div className={classes.cardBgGrey}>

            <Grid
                container={true}
                direction='row'
                justifyContent="center"
                alignItems="center"
            >

                <Grid item={true} xs={3} md={1}>
                    <div className={classes.cardBlue}>
                        {label1}
                    </div>
                </Grid>

                <Grid item={true} xs={9} md={11}>
                    <h3 className={classes.label2}>
                        {parse(label2)}
                    </h3>
                </Grid>

            </Grid>

        </div>
    );
};

export default Item;
