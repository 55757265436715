import { createStyles, makeStyles } from '@material-ui/styles';

export default makeStyles(() =>
  createStyles({
    cardButton: {
      background: '#000A8C',
      borderRadius: '4px',
      color: 'white',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      padding: '3vh 4vh',
      textTransform: 'none',
      marginTop: 20,
      marginBottom: 10,
      '&:hover': {
        background: '#000A8C',
        borderRadius: '4px',
        color: 'white',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        padding: '3vh 4vh',
        textTransform: 'none',
        marginTop: 20,
        marginBottom: 10,
      },
      '&:focus': {
        background: '#000A8C',
        borderRadius: '4px',
        color: 'white',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        padding: '3vh 4vh',
        textTransform: 'none',
        marginTop: 20,
        marginBottom: 10,
      },
    },
  })
);
