import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    bannerContainerOverride: {
      width: 350,
      [theme.breakpoints.down(1300)]: {
        width: 250,
      },
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -92,
      [theme.breakpoints.down(1300)]: {
        marginTop: -93,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    bannerStyleOverride: {
      // width: '100%',
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '40%',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        top: '0',
        width: '35%',
      },
    },
  }),
);

export default useStyles;
