/* eslint-disable react-hooks/exhaustive-deps */
import {
  getEnterpriseSelected,
  getVisibilityPermission,
} from '@orientaction/components/Admin/Test/utils';
import { Company, TestService } from '@orientaction/services';
import { getFromLS } from '@orientaction/utils';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const initialState = {
  test: {
    id: '',
    title: '',
    description: '',
    image: '',
    stockageMode: { value: 1, label: 'Données stockées' },
    visibilityPermission: null,
    enterpriseSelected: null,
    isAllEnterpriseSelected: false,
    time: undefined,
    diagram: {
      id: 1,
      type: 'Diagramme en secteur - simple',
    },
  },
  variables: [],
  questions: [],
  toDeleteQuestionsIds: [],
  toDeleteVariablesIds: [],
  toDeleteResponsesIds: [],
  finalText: { id: '', title: '', description: '' },
  isDraft: false,
  company: 1,
  category: '',
  manageError: {},
  companies: [],
  variableDrawerOpen: false,
  questionDrawerOpen: false,
  responseDrawerOpen: false,
  variableToCreate: undefined,
  variableToEdit: undefined,
  questionToEdit: undefined,
  questionToCreate: undefined,
  responseToEdit: undefined,
  variableDeletionConfirmPopin: {
    open: false,
    title: '',
    content: '',
    handlePopinConfirm: undefined,
    handlePopinClose: undefined,
    data: undefined,
  },
  questionDeletionConfirmPopin: {
    open: false,
    title: '',
    content: '',
    handlePopinConfirm: undefined,
    handlePopinClose: undefined,
    data: undefined,
  },
  refs: {},
  errors: {},
  loading: true,
};

export const AdminTestContext = createContext(initialState) as any;

export const CreateTestContextProvider = ({ children }: any) => {
  const [test, setAdminTest] = useState<any>(initialState);
  const [refetch, setRefetch] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);

  const { id }: any = useParams();
  const location = useLocation();

  const testTitleRef: any = useRef(null);
  const testTimeRef: any = useRef(null);
  const testImageRef: any = useRef(null);
  const testVisibilityRef: any = useRef(null);
  const testCategoryRef: any = useRef(null);
  const testVariablesRef: any = useRef(null);
  const testQuestionsRef: any = useRef(null);

  const token = getFromLS('userToken') || '';

  const updateTest = async (value: any) => {
    setAdminTest((state: any) => {
      return { ...state, ...value };
    });
  };

  const resetTest = () => {
    setAdminTest(initialState);
  };

  const refetchTest = () => {
    setRefetch(true);
  };

  const fetchCurrentTest = useCallback(async () => {
    try {
      setFetchLoading(true);
      const { data } = await TestService.getTestPopulated(token, id);
      if (data?.test?.id) {
        const toEditTest: any = {};
        toEditTest.test = {};
        toEditTest.test.id = data?.test?.id;
        toEditTest.test.title = data?.test?.title || '';

        toEditTest.test.description = data.test?.description || '';

        toEditTest.test.image = data.test?.image || '';

        toEditTest.test.time = data.test?.time || '';

        const visibilityPermission = getVisibilityPermission(data.test?.visibilityPermission);

        const stockageMode = !data.test?.saveTestResult
          ? { value: 0, label: 'Données non-stockées' }
          : { value: 1, label: 'Données stockées' };
        const isAllEnterpriseSelected = data.test?.isAllEnterpriseSelected ?? false;
        const enterpriseSelected = getEnterpriseSelected(
          data.test?.companies,
          data.test?.isAllEnterpriseSelected
        );

        toEditTest.test.stockageMode = stockageMode;

        toEditTest.test.isAllEnterpriseSelected = isAllEnterpriseSelected;

        toEditTest.test.diagram = {
          id: data?.test?.diagram?.id,
          type: data?.test?.diagram?.type,
        };

        // default company is orient action with id = 1
        const company = data.test?.company?.id ? 1 : 0;
        toEditTest.company = company;

        const category = data.test?.category_test?.id || 0;
        toEditTest.category = category;

        const finalText: any = {};

        finalText.id = data.test?.finalText?.id ?? '';
        finalText.title = data.test?.finalText?.title ?? '';
        finalText.description = data.test?.finalText?.description ?? '';
        toEditTest.finalText = finalText;

        toEditTest.questions = data?.test?.questions || [];
        toEditTest.variables = data?.test?.variables || [];

        updateTest({
          ...toEditTest,
          visibilityPermission,
          stockageMode,
          enterpriseSelected,
          isAllEnterpriseSelected,
          isEdit: true,
          loading: false,
          pageTitle: 'Modifier le test',
        });
      }
    } catch (error) {
      console.log('Error ', error);
    } finally {
      setRefetch(false);
      setFetchLoading(false);
    }
  }, [fetchLoading]);

  const fetchCompanies = useCallback(async () => {
    const companiesData = await Company.getCompanies(token);
    updateTest({ companies: companiesData?.data?.data?.filter((c: any) => c.id !== 1) });
  }, [token]);

  useEffect(() => {
    fetchCompanies();
    window.scroll(0, 0);
  }, [fetchCompanies]);

  useEffect(() => {
    const refs = {
      testTitleRef,
      testImageRef,
      testTimeRef,
      testVisibilityRef,
      testCategoryRef,
      testVariablesRef,
      testQuestionsRef,
    };

    if (location.pathname.includes('/modifier')) {
      fetchCurrentTest();
    } else {
      setAdminTest((state: any) => ({
        ...state,
        isEdit: false,
        refs,
        loading: false,
        pageTitle: 'Créer un test',
      }));
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/modifier') && refetch) {
      fetchCurrentTest();
    }
  }, [refetch]);

  console.log('CONTEXT_VALUE', test);

  return (
    <AdminTestContext.Provider value={{ test, updateTest, resetTest, refetchTest, fetchLoading }}>
      {children}
    </AdminTestContext.Provider>
  );
};
