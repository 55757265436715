/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { HistoryMajorTest } from '@orientaction/components';
import { useLocation } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { useStyles } from './style';
import Content from './Content';
import BtnCanceled from '../BtnCanceled';
import { fakeResult } from './context';

const ResultatPreference = () => {
  const classe = useStyles();
  const myResult = fakeResult;

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  return (
    <div className={classe.root}>
      <div className={classe.btnHistory}>
        <HistoryMajorTest
          testResults={myResult?.listHistory || []}
          idTest={+id}
          toRedirect="/resultat-preference"
        />
      </div>

      <BtnCanceled id={myResult?.users_permissions_user} />

      <Content listPrincipaux={myResult?.resultat || []} />
    </div>
  );
};

export default withWidth()(ResultatPreference);
