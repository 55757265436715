import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgpremierApercu: {
      height: '100%',
      width: '100%',
    },
    couverturePage: {
      maxWidth: '1240px',
      height: 'auto',
    },
  })
);

export default useStyles;
