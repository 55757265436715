/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Theme, Typography, useMediaQuery } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import Add from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { ApiActions } from '@orientaction/api-actions';
import { Layout } from '@orientaction/components';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrientButton from '../../commons/OrientButton';
import { EN_PREFIX, GB_PREFIX, publicSvgPath } from '../../utils/constants';
import { getFromLS } from '../../utils/utils';
import style from './style';
import { useLanguage } from '@orientaction/hooks';

const HomePageUser = (props: WithWidth) => {
  const classes = style();
  const history = useHistory();
  const dispatch = useDispatch();
  const { language } = useLanguage();

  const currentUserInfo = JSON.parse(getFromLS('userInfos') || '');

  const redirect = (index: any) => {
    dispatch(ApiActions.setTabLayout(index));
    switch (index) {
      case 1:
        history.push('/user/tests');
        break;
      case 2:
        history.push(`${language === GB_PREFIX ? '/en/my-results' : '/mes-resultats'}`);
        break;
      case 3:
        history.push(`${language === GB_PREFIX ? '/en/my-resources' : '/mes-ressources'}`);
        break;
      case 4:
        history.push(
          `${
            language === GB_PREFIX
              ? `/en/my-compass/${new Date().getFullYear()}`
              : `/ma-boussole/${new Date().getFullYear()}`
          }`,
        );
        break;
      default:
        break;
    }
  };
  const { width } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Layout isaddBackground={true} noPadding={true} isNew3={true} footer={true}>
      <div className={classes.paddingContainer}>
        <Typography
          variant="h2"
          style={{ marginTop: 30, marginBottom: 20, textTransform: 'capitalize', color: 'white' }}
        >
          Accueil{' '}
        </Typography>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={12} md={9}>
            <Card className={classes.cardContainer}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} sm={12} md={4}>
                  <img className={classes.img} src={`${publicSvgPath}/welcome.png`} alt="logo" />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={8} className={classes.flexCenter}>
                  <div>
                    <Typography
                      variant="h6"
                      component="h6"
                      color="primary"
                      className={classes.fontTitleCard}
                    >
                      Bienvenue sur E-Orientaction ?
                    </Typography>
                    <br />
                    <Typography color="primary" className={classes.fontTextCard}>
                      Benjamin Franklin disait :{' '}
                      <i>
                        {' '}
                        « il existe trois choses extrêmement dures en ce monde, le diamant, l’acier
                        et la connaissance de soi ».{' '}
                      </i>
                      Si j’ai une conviction, après avoir accompagné avec mes équipes plus de 50.000
                      personnes dans leur évolution professionnelle et personnelle, c’est que la
                      connaissance de soi est la clef de la réussite et du bonheur. Dans ce monde
                      imprévisible et complexe, elle est ce qui vous permettra de prendre les bonnes
                      décisions, que cela concerne votre vie professionnelle (choix d’un métier,
                      d’une carrière, d’une orientation) ou votre vie personnelle (famille, couple,
                      ami(e)s, etc.) E-Orientaction a été créé pour vous aider à mieux vous
                      connaître et à réussir dans votre vie.
                    </Typography>
                    <br />
                    <Typography
                      variant="h6"
                      component="h6"
                      color="primary"
                      className={classes.fontTextCard}
                    >
                      À vous de jouer,
                    </Typography>
                    <br />
                    <Typography
                      variant="h6"
                      component="h6"
                      color="primary"
                      className={classes.fontTextCard}
                    >
                      Dr. Emeric Lebreton
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h6"
                      color="primary"
                      className={classes.fontTextCard}
                    >
                      <i>Créateur d’E-Orientaction</i>
                    </Typography>
                    {/*
                    <Typography
                      variant="h6"
                      component="h6"
                      color="primary"
                      className={classes.fontTextCard}>
                      Vous pouvez également nous contacter ici : {' '}
                      <a href="mailto:info@orientaction-groupe.com">
                        <strong className={classes.link}>info@orientaction-groupe.com</strong>
                      </a>
                    </Typography> */}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={3}>
            <Grid
              container={true}
              direction="column"
              style={{ height: '100%' }}
              justifyContent="space-between"
              className={classes.gridRow}
              spacing={width === 'xs' ? 2 : 0}
            >
              <Grid item={true} xs={4} md={true}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.yellowBtn)}
                  onClick={() => redirect(1)}
                >
                  <span className={classes.iconBtn} style={{ color: '#000A8C' }}>
                    <Add fontSize="small" />
                  </span>
                  <span className={classes.labelBtn} data-cy="test-mineur">
                    Faire un test
                  </span>
                </OrientButton>
              </Grid>
              <Grid item={true} xs={4} md={true} className={classes.paddingDesktop}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.blueBtn)}
                  onClick={() => redirect(2)}
                >
                  <span className={classes.iconBtn} style={{ color: '#000A8C' }}>
                    <CheckCircleIcon fontSize="small" />
                  </span>
                  <span className={classes.labelBtn} style={{ color: 'white' }}>
                    Mes résultats
                  </span>
                </OrientButton>
              </Grid>
              <Grid item={true} xs={4} md={true}>
                <OrientButton
                  className={clsx(classes.btnTest, classes.redBtn)}
                  onClick={() => redirect(3)}
                >
                  <span className={classes.iconBtn} style={{ color: '#000A8C' }}>
                    <FormatListBulletedIcon fontSize="small" />
                  </span>
                  <span className={classes.labelBtn} style={{ color: 'white' }}>
                    Mes ressources
                  </span>
                </OrientButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {currentUserInfo?.company?.id === 1 && (
          <Grid container={true} spacing={2} style={{ marginTop: 10 }}>
            {isMobile && (
              <Grid item={true} md={3} xs={12}>
                <Card
                  className={classes.cardContainer}
                  style={{ padding: 0, background: 'transparent' }}
                  onClick={() => redirect(4)}
                >
                  <img
                    className={classes.imgBan}
                    src={`${publicSvgPath}/banCompass.png`}
                    alt="logo"
                  />
                </Card>
              </Grid>
            )}

            <Grid item={true} md={3} xs={12}>
              <Card
                className={classes.cardContainer}
                style={{ padding: 0, background: 'transparent' }}
                onClick={() =>
                  window.open(
                    'https://www.orientaction-groupe.com/qui-sommes-nous/bilan-de-competences/',
                    '_blank',
                  )
                }
              >
                <img className={classes.imgBan} src={`${publicSvgPath}/ban1.png`} alt="logo" />
              </Card>
            </Grid>

            <Grid item={true} md={3} xs={12}>
              <Card
                className={classes.cardContainer}
                style={{ padding: 0, background: 'transparent' }}
                onClick={() =>
                  window.open(
                    'https://www.orientaction-groupe.com/qui-sommes-nous/accompagnement-creation-rerprise-entreprise-cpf/',
                    '_blank',
                  )
                }
              >
                <img className={classes.imgBan} src={`${publicSvgPath}/ban2.png`} alt="logo" />
              </Card>
            </Grid>

            <Grid item={true} md={3} xs={12}>
              <Card
                className={classes.cardContainer}
                style={{ padding: 0, background: 'transparent' }}
                onClick={() =>
                  window.open(
                    'https://www.orientaction-groupe.com/qui-sommes-nous/contactez-un-coach/',
                    '_blank',
                  )
                }
              >
                <img className={classes.imgBan} src={`${publicSvgPath}/ban3.png`} alt="logo" />
              </Card>
            </Grid>

            {currentUserInfo?.service?.id !== 2 && (
              <Grid item={true} md={3} xs={12}>
                <Card
                  className={classes.cardContainer}
                  style={{ padding: 0, background: 'transparent' }}
                  onClick={() => redirect(4)}
                >
                  <img
                    className={classes.imgBan}
                    src={`${publicSvgPath}/banCompass.png`}
                    alt="logo"
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </Layout>
  );
};

export default withWidth()(HomePageUser);
