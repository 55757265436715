import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '1px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E4E4F2',
    cursor: 'pointer',
  },

  textFooter: {
    color: '#B0B5FF',
    fontFamily: "Open Sans",
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
  },
  btnFull: {
    borderRadius: 5,
    color: '#000A8C',
    width: '100%',
    textTransform: 'none',
  },
  btnOutFilled: {
    borderRadius: 5,
    color: 'white',
    width: '100%',
    border: '1px solid white',
    textTransform: 'none',
  },
  title: {
    color: '#000A8C',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'none',
    lineHeight: 1,
    marginBottom: 12,
    marginTop: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      marginTop: 12,
    },
  },
  titleStatus: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: '14px',
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: '14px',
    margin: '10px 0px',
    textTransform: 'none',
  },
  containerCover: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 238,
    minHeight: 317,
    height: 'auto',
    background: '#FFF',
    borderRadius: 5,
    borderLeft: '5px solid #757788',
    padding: '5px 5px',
  },
  containerCover2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFF',
    borderRadius: 5,
    borderLeft: '5px solid #757788',
    padding: '141% 0px 0px',
    position: 'relative',
    marginTop: 20,
  },
  content2: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerElement: {
    height: 212,
    borderRadius: 5,
    marginTop: 9,
    overflow: 'hidden',
    marginBottom: 10,
  },
  containerElement2: {
    height: '85%',
    width: '85%',
    borderRadius: 5,
    overflow: 'hidden',
    marginBottom: 12,
  },
  separator: {
    width: 55,
    height: 1,
    background: '#B0B5FF',
    opacity: 0.4,
    margin: '5px 0px',
  },
  spanText: {
    color: '#FFF',
    textAlign: 'right',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '14px',
  }
}));
