/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  checkIfUserIsOnTemporaryPasswordService,
  checkUserExist,
  getUserById,
  modifyUser,
} from '../services/user.service';
import { getFromLS } from '../utils/utils';
import useAlert from './useAlert';

const useUser = () => {
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [isUserExist, setUserExist] = useState(true);
  const [isUserOnTemporaryPassword, setIsUserOnTemporaryPassword] = useState(true);

  const token = getFromLS('userToken') || '';

  const { push } = useHistory();
  const { id }: any = useParams();
  const alert = useAlert();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    setLoading(true);
    getUserById({ token, userId: id })
      .then((res: any) => {
        setUser(res?.data ?? {});
        return 'ok';
      })
      .then((data) => {
        setLoading(false);
      });
  };

  const updateUserById = async (data: any) => {
    setLoadingUpdate(true);
    const currentUser = JSON.parse(getFromLS('userInfos') || '{}');
    const payload = {
      id: user?.id,
      firstname: data?.firstname,
      lastname: data?.lastname,
      dayOfBirth: data?.dayOfBirth,
      description: data?.description,
      email: data?.email,
      postal_code: data?.post_code,
      phone: data?.phone,
      language: data.language,
      photoUri: user?.photoUri,
      token,
    } as any;

    if (currentUser?.company_user_role?.id === 5) {
      payload.company_user_role = 5;
      payload.company = currentUser?.company?.id || null;
    }
    try {
      const response = await modifyUser(payload);
      const userUpdated = { ...currentUser, ...response?.data };
      localStorage.setItem('userInfos', JSON.stringify(userUpdated));
      alert.setOpenAlert(true, 'Modifications sauvegardées avec succès', 'success');
      return response;
    } catch (err: any) {
      if (err?.response?.data?.error?.message === 'Email already taken') {
        alert.openSimpleAlert("L'adresse mail renseignée est déjà prise.");
      } else {
        alert.openSimpleAlert();
      }
    } finally {
      setLoadingUpdate(false);
    }
  };

  const checkUser = async (qs: any) => {
    setLoading(true);
    const { data } = await checkUserExist(qs);
    if (data.isUserExist) {
      setUserExist(true);
    } else {
      setUserExist(false);
    }
    setLoading(false);
  };

  const checkIfUserIsOnTemporaryPassword = async () => {
    try {
      setPageLoading(true);
      const { data } = await checkIfUserIsOnTemporaryPasswordService(token);
      if (data?.success) {
        setIsUserOnTemporaryPassword(true);
      }
    } catch (error) {
      alert.openSimpleAlert('Cette page ne vous est pas accessible.');
      return push('/');
    } finally {
      setPageLoading(false);
    }
  };

  return {
    token,
    pageLoading,
    loading,
    setUser,
    getUser,
    user,
    updateUserById,
    loadingUpdate,
    checkUser,
    isUserExist,
    checkIfUserIsOnTemporaryPassword,
    isUserOnTemporaryPassword,
  };
};

export default useUser;
