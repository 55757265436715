import { useMediaQuery, useTheme } from '@material-ui/core';
import { uploadImageAsync } from '@orientaction/utils';
import classnames from 'classnames';
import clsx from 'clsx';
import { FC, forwardRef, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './style';

const baseStyle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: '100%',
  height: '50px',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const getBaseStyle = (
  match: boolean,
  useForGame: boolean,
  fullSize: boolean,
  useForModel: boolean,
) => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: fullSize ? 0 : '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: useForModel ? '#f1f1f1' : '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: fullSize ? '100%' : useForGame ? 260 : match ? 125 : 150,
    height: fullSize ? 398 : useForGame ? 364 : match ? 125 : 150,
    cursor: 'pointer',
  };
};

const getImageStyle = (match: boolean) => {
  return {
    display: 'block',
    width: '100%',
    height: '100%',
  };
};

interface IProps {
  fileUrl?: string;
  pathPrefix?: string;
  placeholder?: any;
  handleFileUrlChange: (url: any) => void;
  overrideClassname?: any;
  sectionTop?: boolean;
  error?: boolean;
  overrideThumbsContainer?: string;
  overrideThumb?: string;
  overrideThumbInner?: string;
  handleFileChange?: (file: any) => void;
  position?: any;
  keyComponent?: any;
}

const TestMainImage: FC<IProps & any> = forwardRef(
  (
    {
      placeholder = 'Ajouter une image à la une',
      fileUrl = '',
      handleFileUrlChange,
      pathPrefix = 'tests',
      overrideClassname = '',
      sectionTop = false,
      error = false,
      overrideThumbsContainer = '',
      overrideThumb = '',
      overrideThumbInner = '',
      useForGame = false,
      handleFileChange,
      fullSize = false,
      isFullHeight = false,
      useForModel = false,
      position,
      keyComponent,
    }: any,
    ref,
  ) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept: 'image/*',
      onDrop: (acceptedFiles) => {
        const uri = URL.createObjectURL(acceptedFiles[0]);
        if (useForGame) {
          handleFileUrlChange(uri);
          handleFileChange(acceptedFiles[0]);
        } else {
          uploadImageAsync(uri, pathPrefix)
            .then((res) => {
              handleFileUrlChange(res);
            })
            .catch((err: any) => console.log('error on upload', err));
        }
      },
    });

    const cls = styles();

    const style: any = useMemo(
      () => ({
        ...(sectionTop ? getBaseStyle(matches, useForGame, fullSize, useForModel) : baseStyle2),
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(isFullHeight ? { height: '100%' } : {}),
      }),
      [
        sectionTop,
        matches,
        isFocused,
        isDragAccept,
        isDragReject,
        useForGame,
        fullSize,
        isFullHeight,
        useForModel,
      ],
    );

    const imageStyle = getImageStyle(matches);
    const generateKey = () => Math.random().toString(36).substr(2, 9);

    return (
      <div
        ref={ref as any}
        className={classnames(cls.dropzoneContainer, overrideClassname)}
        style={{
          ...(isFullHeight ? { height: '100%' } : {}),
          ...(useForModel ? { width: '100%' } : {}),
        }}
      >
        {fileUrl.length > 0 ? (
          <div
            style={{ ...(useForModel ? { width: '100%', height: '100%' } : {}) }}
            className={classnames(cls.thumbsContainer, overrideThumbsContainer)}
            key={`editor2-${Math.random()}`}
          >
            <div
              className={classnames(cls.thumb, overrideThumb)}
              style={
                fullSize
                  ? { width: '100%', height: isFullHeight ? '100%' : 408 }
                  : useForGame
                  ? { width: 304, height: 408 }
                  : {}
              }
            >
              <div
                style={{
                  ...(useForModel
                    ? {
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                      }
                    : {}),
                  ...(useForGame ? { width: '100%', height: '100%' } : {}),
                }}
                className={classnames(cls.thumbInner, overrideThumbInner)}
              >
                <img
                  style={{
                    ...(useForModel || useForGame ? { objectFit: 'contain' } : {}),
                    ...imageStyle,
                    ...(useForModel
                      ? {
                          position: 'absolute',
                          width: `${(position && position.scale ? position.scale : 1) * 100}%`,
                          height: `${(position && position.scale ? position.scale : 1) * 100}%`,
                          top: `${position && position.position ? position.position : 0}%`,
                        }
                      : {}),
                  }}
                  src={fileUrl}
                  alt="thumbs"
                  key={keyComponent || generateKey()}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              ...(fullSize ? { width: '100%' } : {}),
              ...(isFullHeight ? { height: '100%' } : {}),
            }}
            className={clsx({
              [cls.inputError]: error,
            })}
          >
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />

              <div>{placeholder()}</div>
            </div>
          </div>
        )}
      </div>
    );
  },
);
export default TestMainImage;
