/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook1 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleChapitre, Page } from '@orientaction/components';
import { useState } from 'react';

const Page19 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={19}>
      <TitleChapitre nbr={3} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" color="primary" gutterBottom={true} className="opacity07">
            QUEL EST VOTRE DESTIN <br />
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} />
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'justify', fontWeight: 'lighter' }}>
            L’une des clefs du bonheur est de se sentir utile aux autres. L’être humain est un animal social.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Il donne du sens à sa vie en étant utile. Il existe de nombreuses façons d’être utile aux autres : diriger, représenter, assister, vendre, mettre en relation, défendre, etc.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            En fonction de la place que l’on occupe dans la société, on est utile aux autres d’une certaine façon.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Grâce au test des 32 figures du destin, vous allez pouvoir mieux identifier quelle est la place que vous souhaitez occuper dans la société et déterminer ainsi de quelle manière vous souhaitez être utile aux autres.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={8}>
            <img className={classes.img} src={`${imgBook1}/pensif.png`} alt="logo" />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    }
  })
);
export default Page19;
