export const context = [
  {
    title: 'OUTPLACEMENT (RECLASSEMENT)',
    id: 1,
    paragraph: `Vous souhaitez changer d’employeur(se). Vous pouvez demander
        à votre employeur(se), dans le cadre d’une rupture conventionnelle
        ou d’un licenciement à bénéficier d’un accompagnement à la
        recherche d’emploi appelé « outplacement ». Appuyez-vous sur un
        cabinet spécialisé pour rebondir en saisissant une nouvelle opportunité
        professionnelle. Bénéficiez de nos réseaux et de notre expertise.`,
    endText: `Mode de financement : prestation financée intégralement par votre employeur(se).`,
    img: 'accompagnement.png',
    en_img: 'en_accompagnement.png',
  },
  {
    title: 'ACCOMPAGNEMENT CRÉATION/REPRISE D’ENTREPRISE',
    id: 2,
    paragraph: `Créer ou reprendre une entreprise est une véritable aventure humaine !
        Chez ORIENTACTION, presque tous(toutes) les consultant(e)s sont eux-mêmes (elles-mêmes) des créateurs(trices) d’entreprise. Ne voudriez-
        vous pas bénéficier de leur expérience pour gagner du temps et réussir
        votre projet de création/reprise d’entreprise ? <br /><br />
        Votre projet n’en aura que plus de chances d’aboutir et de réussir !`,
    endText: `Mode de financement : prestation financée dans le cadre de votre CPF ou via votre employeur(se).`,
    img: 'creation.png',
    en_img: 'en_creation.png',
  },
  {
    title: 'ACCOMPAGNEMENT À LA V.A.E',
    id: 3,
    paragraph: `Et si vous transformiez votre expérience en un diplôme reconnu ?
        Ce diplôme vous permettra d’être reconnu(e) pour vos compétences,
        d’évoluer plus vite et donc de prétendre à une rémunération plus élevée. <br/><br/>
        ORIENTACTION vous propose d’être accompagné(e) dans ce projet
        de V.A.E, une étape essentielle pour obtenir un diplôme sans avoir à
        retourner sur les bancs de l’école.`,
    endText: `Mode de financement : prestation financée dans le cadre de votre CPF, via votre employeur(se) ou via la plateforme France VAE.`,
    img: 'experience.png',
    en_img: 'en_experience.png',
  },
];
