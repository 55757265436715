import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    margin: '2vh 0',
    padding: '2vh',
    background: '#e8eef4',
  },
  span: {
    width: 40,
    height: 40,
    background: '#FF4F33',
    fontWeight: 'bold',
    color: 'white',
    fontSize: '2.5vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    width: '100%',
  },
  container: {
    margin: '3.5vh 0',
  },
}));
