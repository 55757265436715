/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleChapitre, Page } from '@orientaction/components';
import { useState } from 'react';

const Page26 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={28}>
      <TitleChapitre nbr={3} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            QUELLES SONT VOS VÉRITABLES <br />
            MOTIVATIONS ? 
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <div >
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'justify', fontWeight: 'lighter' }}>
            La motivation, c’est l’énergie, l’envie, le désir : c’est le moteur de l’action. Quand 
            vous vous levez le matin pour aller au travail, c’est la motivation qui vous donne 
            envie d’être présent(e) et de donner le meilleur de vous-même.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Quand vous rencontrez des difficultés, que vous devez surmonter des échecs, c’est 
            la motivation qui vous donne la force et l’énergie nécessaires pour dépasser les 
            obstacles. Il est impossible de réussir et d’être heureux(se) dans son travail sans être 
            motivé(e). Alors pour choisir le bon job, découvrez vos motivations. 
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={8}>
            <img className={classes.img} src={`${imgBook2}/illustration_page_32.png`} alt="logo" />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    }
  })
);
export default Page26;
