import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    titlePage: {
        padding: '1.5vh 0',
        fontSize: 20,
        fontWeight: 600,
        color: '#2B56A5',
        textAlign: "center",
        border: '1px solid',
        backgroundColor: '#e8eef4',
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    spanYellow: {
        width: "7%",
        height: 10,
        backgroundColor: "yellow",
        margin: "5vh auto",
        color: "yellow"
    },
    textArea: {
        width: '100%',
        height: '10vh',
        border: 'none',
        borderBottom: '1px dashed #aaa'
    },
    strong: {
        color: 'black'
    }
}));
