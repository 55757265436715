import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            position: 'fixed',
            bottom: 0,
            backgroundColor: '#EDEEF2',
            zIndex: 5,
            paddingTop: 5,
            paddingBottom: 5
        },
    })
);