// eslint-disable-next-line simple-import-sort/imports
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { FC } from 'react';
import useStyles from './style';
import BodyScore from './Body';
import ArrayScroreHead from './head';

interface IProps {
  body: any[];
  onChange: (data: any) => void;
  current: any;
}

const ArrayScore: FC<IProps> = ({ body, current, onChange }) => {
  const classes = useStyles();

  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  return (
    <div>
      <Grid
        container={true}
      >
        <Grid
          item={true}
          xs={12}
          md={body.length > 8 ? 6 : 12}
        >
          <ArrayScroreHead />
          {
            body.slice(0, 8).map((item: any) => (
              <BodyScore onChange={onChange} name={item.name} value={current?.[item.name]} label={item.label} />
            ))
          }
        </Grid>

        {body.length > 0 && (

          <Grid
            item={true}
            xs={12}
            md={body.length > 8 ? 6 : 12}
          >

            {
              !isXS && (
                <ArrayScroreHead />
              )
            }

            {
              body.slice(8, 16).map((item: any) => (
                <BodyScore onChange={onChange} name={item.name} value={current?.[item.name]} label={item.label} />
              ))
            }
          </Grid>)}

      </Grid>
      
    </div>
  );
};

export default ArrayScore;
