/* eslint-disable no-use-before-define */
import { Page, TitleNbr, Paragrapth, TitlePage } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';

const Page33 = (props: any) => {

    return (
        <Page id={33}>

            <TitlePage>
                6 CONSEILS POUR CONDUIRE <br />
                VOTRE PROJET
            </TitlePage>

            <div style={{ textAlign: 'center' }}>

                {
                    context.map((item: any) => (

                        <MarginContainer mormal={true}>

                            <MarginContainer>
                                <TitleNbr number={item.id} text={item.title} />
                            </MarginContainer>

                            <MarginContainer mormal={true}>

                                <div style={{ textAlign: 'start' }}>

                                    <Paragrapth align='start'>
                                        {item.paragraph || ""}
                                    </Paragrapth>

                                </div>

                            </MarginContainer>

                        </MarginContainer>
                        
                    ))
                }

            </div>

        </Page>
    );
};

export default Page33;
