export const getEbookEnRoute = (frPath: string) => {
  let enPath = '';
  switch (frPath) {
    case 'ebook-intro':
      enPath = 'ebook-intro';
      break;
    case 'ebook-etape-1':
      enPath = 'ebook-step-1';
      break;
    case 'ebook-etape-2':
      enPath = 'ebook-step-2';
      break;
    case 'ebook-etape-3':
      enPath = 'ebook-step-3';
      break;
    case 'ebook-etape-4':
      enPath = 'ebook-step-4';
      break;
    case 'ebook-etape-5':
      enPath = 'ebook-step-5';
      break;
    default:
      enPath = '';
  }
  return `${enPath}`;
};
