export const context = [
    `À 55 ans, Pascal a eu une longue carrière dans les ressources humaines. Il a
    occupé successivement les postes d’assistant RH, de responsable RH avant
    d’atteindre le poste de DRH qu’il occupera pendant 10 ans.`,
    `Il a beaucoup aimé ce métier, exercé dans un contexte de confiance avec le
    propriétaire de l’entreprise.`,
    `Mais celui-ci, arrivé à l’âge de la retraite, a décidé de vendre sa PME à un grand
    groupe. Pascal a rapidement compris que son poste était sur la sellette.
    Ses missions avaient changé. Il n’avait plus les coudées franches.`,
    `Au terme d’une première année de cohabitation difficile avec le nouveau
    directeur général, il a finalement décidé de négocier son départ de
    l’entreprise. Il est parti avec un gros chèque mais sans véritable projet d’avenir.
    Et là, la galère a commencé.`,
    `Pascal croyait qu’avec son expérience il retrouverait rapidement un poste.
    Malheureusement, après plus de 500 candidatures envoyées, il n’a eu aucune
    touche. Ses droits au chômage arrivaient à leur terme. Son horizon professionnel
    s’assombrissait peu à peu.`,
    `Sa seule chance de survie : créer ou reprendre une entreprise.`
]