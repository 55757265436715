/* eslint-disable no-use-before-define */
import useStyles from './style';
import { FC } from 'react';
import { Paragrapth } from '@orientaction/components';


interface IBorderArray {
    title: string;
    list: any[];
}

const BorderArray: FC<IBorderArray> = ({
    title,
    list,
}) => {

    const classes = useStyles();

    return (
        <div className={classes.border}>

            <div className={classes.titlePage}>
                {title}
            </div>

            {
                list.map((item: any) => (
                    <div
                        className={classes.contentArray}
                    >

                        <div className={classes.content}>
                            <Paragrapth align='justify'>
                                {item || ""}
                            </Paragrapth>
                        </div>

                    </div>
                ))
            }

        </div>
    );
};

export default BorderArray;
