/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */

import { useResponseUser, useResults } from '@orientaction/hooks';
import React, { FC } from 'react';
import TableLayout from '../../components/TableLayout';
import Results from './Results';
/**
 * Legacy code to be removed later
 */

const ResultsContent: FC = () => {
  return <TableLayout isaddBackground={true} />;
};

export default ResultsContent;
