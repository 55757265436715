/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';

interface IContentFlex {
    children1: any;
    children2: any;
    isDragging?: boolean;
}


const ContentFlex: FC<IContentFlex> = ({ children1, children2, isDragging }) => {

    const classe = useStyles();

    return (

        <div className={classe.root}>

            <div
                className={clsx({
                    [classe.flex1]: true,
                    [classe.flexDraged]: isDragging,
                })}
            >
                {children1}
            </div>

            <div className={classe.flex2}>
                {children2}
            </div>

        </div>
    );
};

export default ContentFlex;
