import { Theme, useMediaQuery } from '@material-ui/core';
import classnames from 'classnames';
import { FC } from 'react';
import useStyle from '../style';

interface IParagrapth {
  children: any;
  addBackground?: boolean;
  colorBlue?: boolean;
  colorRed?: boolean;
  colorGrey?: boolean;
  bold?: boolean;
  align: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
  overrideParagraphStyle?: any;
}

const Paragrapth: FC<IParagrapth> = ({
  children,
  addBackground,
  colorBlue,
  colorRed,
  colorGrey,
  align,
  bold,
  overrideParagraphStyle = undefined,
}) => {
  const classe = useStyle();

  const color = () => {
    if (colorBlue) {
      return '#2B56A5';
    }

    if (colorRed) {
      return 'red';
    }

    if (colorGrey) {
      return 'gray';
    }

    return 'black';
  };

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div
      className={
        overrideParagraphStyle
          ? classnames(overrideParagraphStyle, classe.paragrapth)
          : classe.paragrapth
      }
      style={{
        background: addBackground ? '#e8eef4' : '#fff',
        color: color(),
        padding: colorBlue ? '0' : '00px',
        textAlign: isSm ? 'start' : align,
        margin: align === 'center' ? '0 auto' : '0',
        display: align === 'center' ? 'block' : 'inline-block',
      }}>
      {bold ? <strong>{children}</strong> : <>{children}</>}
    </div>
  );
};

export default Paragrapth;
