/* eslint-disable react-hooks/exhaustive-deps */
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { HistoryMajorTest, Layout } from '@orientaction/components';
import {
  useAlert,
  useMyResulatOfTest,
  useNeedsProTestResult,
  useRedirectToRootPage,
} from '@orientaction/hooks';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BtnCanceled from '../BtnCanceled';
import ContactForm from '../ContactForm';
import CardGoFar from './CardGoFar';
import NeedsProHeader from './NeedsProHeader';
import PyramidBlock from './PyramidBlock';
import { useStyles } from './style';
import TopVariablesBlock from './TopVariablesBlock';

const NeedsProResult = () => {
  const { myResult, setMyResult } = useMyResulatOfTest();

  const { getMyResult } = useNeedsProTestResult();

  const { goToRootPage } = useRedirectToRootPage();

  const [loading, setLoading] = useState(true);
  const [openContactForm, setOpenContactForm] = useState(false);

  const classes = useStyles();

  const alert = useAlert();

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const getResultat = async (idTest: number) => {
    setLoading(true);

    (window as any).scrollTo(0, 0);

    try {
      const { data } = await getMyResult(+idTest);

      setMyResult(data.data);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const openContactModal = () => {
    setOpenContactForm((prev: boolean) => !prev);
  };

  useEffect(() => {
    getResultat(+id);
  }, [id]);

  return (
    <Layout isaddBackground={true} noPadding={true} isClassic={true} footer={true}>
      <>
        <NeedsProHeader />
        {loading ? (
          <div className={classes.contentLoader}>
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.root}>
            <div className={classes.btnHistory}>
              <HistoryMajorTest
                testResults={myResult?.listHistory || []}
                idTest={+id}
                toRedirect="/resultat-need-pro"
              />
            </div>

            <BtnCanceled id={myResult?.users_permissions_user} isBack={true} />

            <PyramidBlock
              img={myResult?.favorite?.imgPyramid || ''}
              favoriteVariable={myResult?.favorite}
            />
            <TopVariablesBlock variables={myResult?.treeMax || []} />
            <Grid
              container={true}
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 50 }}
            >
              <Grid item={true} md={10}>
                <CardGoFar handleContactClick={openContactModal} />
              </Grid>
            </Grid>
          </div>
        )}
        <ContactForm
          test={{ id: 3, label: 'Needs® pro, test des besoins professionnels' }}
          open={openContactForm}
          handleClose={openContactModal}
        />
      </>
    </Layout>
  );
};

export default NeedsProResult;
