// eslint-disable-next-line no-use-before-define
import 'swiper/swiper.min.css';
import { Grid } from '@material-ui/core';
import { LayoutTestSoftSkills } from '@orientaction/layouts';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { publicSvgPath } from '../../utils/constants';
import { useStyles } from './style';

const TestStudy = () => {
  const classes = useStyles();

  useEffect(() => {
    (window as any).chatboxSettings = {
      appKey: 'e9f7a4c2-2e1e-4f8d-bf68-a2d64a096819',
      websiteId: 'aerosYellAcaulX0lW79r2',
      preventMessengerPreload: true,
      language: 'fr',
      ref: 'restart',
    };
    (function (d, s, id) {
      let js: any = d.getElementsByTagName(s)[0];
      const fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://cbassets.botnation.ai/js/widget.js';
      js.async = true;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'chatbox-jssdk');
  }, []);

  return (
    <HelmetProvider>
      <LayoutTestSoftSkills isGradient={true}>
        <div className={classes.paddingContent}>
          <Grid container={true} style={{ height: '100%' }}>
            <Grid
              className={classes.flexCenterStart}
              item={true}
              xs={4}
              style={{ textAlign: 'center', position: 'relative', height: '100%' }}>
              <img
                className={classes.absoluteTop}
                src={`${publicSvgPath}/logoDesktop.svg`}
                alt="logo"
              />
              <img className={classes.img} src={`${publicSvgPath}/agence.png`} alt="logo" />
            </Grid>
            <Grid item={true} xs={4} />
            {/* <script src="/script/scriptbot.js" type="text/javascript" />
              <Helmet>
                <script src="/script/scriptbot.js" type="text/javascript" />
              </Helmet> */}
            <Grid
              className={classes.flexCenterEnd}
              item={true}
              xs={4}
              style={{ textAlign: 'center' }}>
              <img className={classes.img} src={`${publicSvgPath}/entretien.png`} alt="logo" />
            </Grid>
          </Grid>
        </div>
      </LayoutTestSoftSkills>
    </HelmetProvider>
  );
};
export default TestStudy;
