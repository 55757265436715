import { useAdminUser, useQueryString } from '@orientaction/hooks';
import { FC } from 'react';
import BeneficiaryForm from './BeneficiaryForm';
import ManagerForm from './ManagerForm';

interface IProps {
  mode: string;
  alias: any;
}

const AdminForm: FC<IProps> = ({ mode, alias }) => {
  const { tabs: fromReduxTab } = useAdminUser();
  const isBeneficiaryTab = fromReduxTab === 5;
  return isBeneficiaryTab ? (
    <BeneficiaryForm mode={mode} alias={alias} companyUserRoleId={fromReduxTab} />
  ) : (
    <ManagerForm mode={mode} alias={alias} companyUserRoleId={fromReduxTab} />
  );
};

export default AdminForm;
