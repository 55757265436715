/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import { Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import React from 'react';
import CircularProgressCustom from '../CircularProgressCustom';
import ExpandableItem from '../ExpandableItem';
import style from './style';
import clsx from 'clsx';
import { publicSvgPath } from '@orientaction/utils';

interface IModalPref {
  open: boolean;
  onCancel: () => void;
  label: string;
  value: number;
  color: string;
  img: string;
  colorSecondary: string;
  opacity: boolean;
  text1: string;
  text2: string;
  list1: any;
  list2: any;
  youAre: string;
  title: string;
  listText: boolean;
}

const ModalCardPref: React.FC<IModalPref> = ({
  open,
  onCancel,
  label,
  value,
  color,
  img,
  colorSecondary,
  opacity,
  text1,
  text2,
  list1,
  list2,
  youAre,
  title,
  listText,
}) => {
  const classes = style({ color: !opacity ? colorSecondary : 'white' });

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={isTab ? 'xl' : 'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent
        style={{ position: 'relative', padding: 0, maxWidth: '100%', overflowX: 'hidden' }}>
        <IconButton aria-label="delete" className={classes.closeBtn} onClick={onCancel}>
          <Close />
        </IconButton>
        <Grid container={true}>
          <Grid item={true} style={{ zIndex: 2 }}>
            <div className={classes.titlehead} style={{ background: color }}>
              <h3 className={classes.titleText}>{label}</h3>
            </div>
          </Grid>
          <Grid item={true} xs={true} sm={true} md={5} className={classes.containerImg}>
            <img
              src={img}
              alt=""
              style={{ width: '100%', height: 'auto' }}
              className={classes.imgMargin}
            />
          </Grid>
          <Grid item={true} sm={12} xs={12} md={true} className={classes.citationContainer}>
            <div style={{ width: '100%' }}>
              <div className={classes.positionCircular2}>
                <CircularProgressCustom
                  value={value}
                  color={color}
                  size={!isTab ? 155 : 140}
                  fontSize={!isTab ? 23 : 20}
                  fontSizeFloat={!isTab ? 15 : 12}
                  isPercent={true}
                />
              </div>
              <Typography variant="body2" color="primary" className={classes.citation}>
                <div style={{ textTransform: 'uppercase', fontWeight: 700, fontSize: 22 }}>
                  {title}
                </div>
                Vous êtes <b style={{ fontWeight: 500 }}>{youAre}</b>
              </Typography>
            </div>
          </Grid>
          <Grid />
        </Grid>
        <div className={classes.paddingGridText2} style={{ background: 'white' }}>
          <Grid container={true}>
            <Grid item={true} container={true} md={12} spacing={5} justifyContent="space-between">
              <Grid item={true} md={6} className={classes.block1}>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleContent}
                  style={{ textTransform: 'none', fontSize: 22, marginBottom: 20 }}>
                  Votre style général
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text1}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: text1,
                  }}
                />
              </Grid>
              <Grid item={true} md={6} className={classes.block2}>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleContent}
                  style={{ textTransform: 'none', fontSize: 22, marginBottom: 20 }}>
                  Vos relations avec les autres
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.text1}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: text2,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.paddingGridText2} style={{ background: color }}>
          <Grid container={true}>
            <Grid item={true} container={true} md={12} spacing={5} justifyContent="space-between">
              <Grid item={true} md={6} className={classes.block1}>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleContent}
                  style={{ color: 'white', textTransform: 'none', fontSize: 22, marginBottom: 20 }}>
                  Ce qui vous stimule
                </Typography>
                {!!listText && (
                  <Typography
                    variant="body2"
                    component="p"
                    className={clsx(classes.text1, classes.marginTopMobile)}
                    style={{ color: 'white' }}
                    dangerouslySetInnerHTML={{
                      // eslint-disable-next-line react/destructuring-assignment
                      __html: list1,
                    }}
                  />
                )}
                {!listText && (
                  <ul className={clsx(classes.listUl, classes.marginTopMobile)}>
                    {list1?.map((elem: any) => (
                      <li className={classes.listLi}>
                        <Typography
                          variant="body2"
                          component="p"
                          dangerouslySetInnerHTML={{
                            // eslint-disable-next-line react/destructuring-assignment
                            __html: elem,
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                )}
              </Grid>
              <Grid item={true} md={6} className={classes.block2}>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleContent}
                  style={{ color: 'white', textTransform: 'none', fontSize: 22, marginBottom: 20 }}>
                  Ce qui vous épuise
                </Typography>
                <ul className={clsx(classes.listUl, classes.marginTopMobile)}>
                  {list2?.map((elem: any) => (
                    <li className={classes.listLi}>
                      <Typography
                        variant="body2"
                        component="p"
                        dangerouslySetInnerHTML={{
                          // eslint-disable-next-line react/destructuring-assignment
                          __html: elem,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ModalCardPref;
