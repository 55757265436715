/* eslint-disable no-use-before-define */
import { Page, TitleNbr, Paragrapth } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';

const Page28 = (props: any) => {

    return (
        <Page id={28}>

            <div style={{ textAlign: 'center' }}>

                {
                    context.map((item: any) => (
                        <MarginContainer big={true}>

                            <MarginContainer>
                                <TitleNbr number={item.id} text={item.title} />
                            </MarginContainer>

                            {
                                item.listContext.map((itemList: any) => (
                                    <MarginContainer>

                                        <div style={{ textAlign: 'start' }}>

                                            {
                                                itemList.title && (
                                                    <MarginContainer>

                                                        <Paragrapth align='start' bold={true} colorBlue={true}>
                                                            {itemList.title}
                                                        </Paragrapth>
                                                    </MarginContainer>
                                                )
                                            }

                                            <Paragrapth align='start'>

                                                {
                                                    itemList.titleLeft && (
                                                        <Paragrapth align='start' bold={true} colorBlue={true}>
                                                            {itemList.titleLeft} : {' '}
                                                        </Paragrapth>
                                                    )
                                                }

                                                {' '} {itemList.content}

                                            </Paragrapth>

                                        </div>

                                    </MarginContainer>
                                ))
                            }

                        </MarginContainer>
                    ))
                }

            </div>

        </Page>
    );
};

export default Page28;
