/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page } from '@orientaction/components';
import { publicBook5Path } from '@orientaction/utils';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../components/Img';
import { useStyles } from './style';

const Page08 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={8}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          {/* <img className={classes.imgTailleur} src={`${publicBook5Path}/titre12.png`} alt="imgTailleur" /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LE CHOIX DE LAURÈNE
            </Typography>
          </SimpleBanner>
        </MarginContainer>

        <MarginContainer big={true}>
          <Img className={classes.img} src="illustration_page_12.png" alt="logo" />
        </MarginContainer>

        <Typography className={classes.paragraphe}>
          Laurène est expatriée en Tunisie où elle exerce le métier de professeure des écoles dans
          une école française. Laurène a entrepris un bilan de compétences car elle pense revenir en
          France. À l’issue de son bilan de compétences, Laurène a trois pistes.
        </Typography>
        <Typography className={classes.paragraphe}>
          La première est de travailler dans le domaine de l’éducation à la paix en tant que
          formatrice. Ce métier est celui qui a le plus de sens pour elle et qui correspond le plus
          à sa personnalité anticonformiste. Si elle choisit cette voie, elle perd tous ses
          avantages (salaire, sécurité et temps de travail) de l’Éducation nationale.
        </Typography>
        <Typography className={classes.paragraphe}>
          La deuxième est de chercher un poste dans une grande collectivité territoriale comme
          chargée de mission à l’international. Ce poste a du sens pour elle, il répond à ses
          besoins de sécurité. Mais il ne correspond pas à sa personnalité anticonformiste, qui la
          rend difficilement adaptable dans une grande organisation bureaucratique.
        </Typography>
        <Typography className={classes.paragraphe}>
          La troisième piste est de rester en Tunisie et d’avoir un troisième enfant. Ce choix a un
          sens personnel fort, elle reste dans un métier qui répond à ses besoins, mais ne
          correspond pas à sa personnalité. Que va choisir Laurène ?
        </Typography>
      </div>
    </Page>
  );
};

export default Page08;
