/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Card, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';

interface IProps {
  handleContactClick: () => void;
}

const CardGoFar: FC<IProps> = ({ handleContactClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardBord}>
      <Typography variant="h2" color="textPrimary" className={classes.titleContent0}>
        Pour aller plus loin
      </Typography>
      <Grid container={true} spacing={2} className={classes.paddingCard}>
        <Grid item={true} md={6}>
          <Card className={classes.cardRed}>
            <div className={classes.cardTopRed} />
            <div className={classes.paddingCard2}>
              <img
                src={`${publicSvgPath}/picto_conversation.svg`}
                alt=""
                className={clsx(classes.imgCard)}
              />
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ fontSize: 19 }}
              >
                CONTACTEZ NOTRE SERVICE <br />
                DE FORMATION
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.description}
                style={{ marginTop: 10 }}
              >
                Pour une offre de formation personnalisée
              </Typography>
              <Button
                onClick={handleContactClick}
                className={classes.btnCard}
                style={{ background: 'white', color: 'black' }}
              >
                Nous contacter
              </Button>
            </div>
          </Card>
        </Grid>
        {/* <Grid item={true} md={6}></Grid> */}
      </Grid>
    </div>
  );
};

export default CardGoFar;
