/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { ArrowBackIos } from '@material-ui/icons';
import Timeline from '@material-ui/lab/Timeline';
import { HistoryMajorTest } from '@orientaction/components';
import { publicIconPath } from '@orientaction/utils';
import { FC } from 'react';
import { fakeResult } from './context';
import LoveMatter from './LoveMatter';
import StudyStyle from './StudyStyle';
import style from './style';
import Content from './Timeline/Content';

interface IProps {
  isPublic?: boolean;
}

const pageTitle = "Tes résultats - Test d'orientation postbac Study®";

const StudyTestResult: FC<IProps> = ({ isPublic = false }) => {
  const classes = style();
  const result = fakeResult;

  const studyStyleContainerTitle = `<p>Ton Study® Style est <b>${result?.studyStyle?.favorite?.label}</b></p>`;
  const loveMatterContainerTitle = `<p>Ta love matière ❤️ est <b>${result?.loveMatter?.favorite?.label}</b></p>`;

  return (
    <>
      <div className={classes.root}>
        {!isPublic && (
          <Button startIcon={<ArrowBackIos />} variant="outlined" className={classes.button}>
            Retour
          </Button>
        )}
        {!isPublic && (
          <div className={classes.btnHistory}>
            <HistoryMajorTest
              testResults={result.listHistory || []}
              idTest={1}
              toRedirect="/resultat-study"
            />
          </div>
        )}
        <Typography className={classes.studyRestitutionPageTitle} component="p">
          {pageTitle}
        </Typography>
        <Timeline className={classes.timelineAlignLeft}>
          <Content title={studyStyleContainerTitle} to="">
            <div className={classes.marginTop}>
              <StudyStyle data={result.studyStyle} />
            </div>
          </Content>
          <Content title={loveMatterContainerTitle} to="">
            <div className={classes.marginTop}>
              <LoveMatter data={result.loveMatter} />
            </div>
          </Content>
        </Timeline>
        {!isPublic && (
          <div className={classes.studyRestitutionShareButtonContainer}>
            <Button
              disabled={false}
              endIcon={<img src={`${publicIconPath}/ContentCopy.svg`} alt="Copy icon" />}
              variant="outlined"
              className={classes.studyRestitutionShareButton}>
              Partager vos résultats
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default withWidth()(StudyTestResult);
