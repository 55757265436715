import Button, { ButtonProps } from '@material-ui/core/Button';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import useStyles from './style';

interface OrientButtonProps {
  children: ReactNode;
}

const OrientButton: FC<OrientButtonProps & ButtonProps> = props => {
  const classes = useStyles();
  const { children, className, ...otherProps } = props;
  return (
    <Button {...otherProps} className={classNames(classes.button, className)}>
      {children}
    </Button>
  );
};

export default OrientButton;
