/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { CardTestSimple } from '@orientaction/components';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import useTest from '../../hooks/useTest';
import { LayoutResultat } from '@orientaction/layouts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btnPrimary: {
      minHeight: 38,
      height: 38,
      width: 38,
      minWidth: 38,
      textTransform: 'none',
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      '@media(min-width: 1250px)': {
        minHeight: 48,
        height: 48,
        width: 146,
        minWidth: 146,
      },
    },
    paddingY: {
      paddingTop: 50,
      paddingBottom: 50,
    },
    flexCenterY: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);
const TestPageUser = (props: any) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  // const { getTests, tests } = useTest();
  // useEffect(() => {
  //   getTests();
  // }, []);

  return (
    <LayoutResultat isNew={true}>
      <div className={classes.paddingY}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={6} className={classes.flexCenterY}>
            <Typography variant="h2">Tests</Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <div style={{ display: 'flex', float: 'right' }}>
              <Paper component="form" className={classes.root} style={{ marginRight: '20px' }}>
                <InputBase className={classes.input} placeholder="Chercher un test" />
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </Grid>
        </Grid>
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={3}>
              <CardTestSimple
                status="actif"
                title="Titre de test"
                description="Ceci est une description de GG"
                isDone={true}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </LayoutResultat>
  );
};

export default TestPageUser;
