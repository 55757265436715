/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, ParagrapthListNumber, ArrayYouContext } from '@orientaction/components';
import { paragraph, list } from './context';
import useStyles from './style';

const Page12 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={12}>

            <div className={classes.marginContent}>
                <MarginContainer>
                    <MarginContainer>
                        {
                            paragraph.map((item: any) =>
                                <MarginContainer>
                                    <Paragrapth align='justify'>
                                        {item || ''}
                                    </Paragrapth>
                                </MarginContainer>
                            )
                        }
                    </MarginContainer>
                </MarginContainer>
            </div>

            <MarginContainer big={true}>
                <ParagrapthListNumber
                    withTwoPoint={false}
                    list={[
                        {
                            text: `Une organisation en fort développement,`,
                            number: 1
                        },
                        {
                            text: `Une organisation dans laquelle le management vieillit,`,
                            number: 2
                        },
                        {
                            text: `Des événements majeurs (restructuration, rachat,fusion) entraînant un
                        changement partiel ou total des managers(euses) en place.`,
                            number: 3
                        },
                    ]}
                />
            </MarginContainer>

            <MarginContainer big={true}>

                <MarginContainer>
                    <Paragrapth
                        align='center'
                        bold={true}
                        colorBlue={true}
                    >
                        Dans quels cas choisir une évolution interne verticale :
                    </Paragrapth>
                </MarginContainer>

                <ArrayYouContext objectList={list} />

            </MarginContainer>


        </Page>
    );
};

export default Page12;
