/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Img, Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2, publicBookPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page09 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={9}>
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook2}/titre14.png`} alt="logo" /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LA PETITE FILLE QUI AIMAIT JOUER AUX LEGO
            </Typography>
          </SimpleBanner>
        </div>
        <Grid container={true} justifyContent="center">
          <Grid xs={5}>
            <img className={classes.img} src={`${imgBook2}/illustration_page_14.png`} alt="logo" />
          </Grid>
        </Grid>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Quand elle était enfant, Lisa adorait jouer aux Lego. Vous savez ces briques de
          différentes couleurs qu’on assemble pour construire une maison, un château fort ou une
          ferme... Elle pouvait passer des heures, toute seule, à assembler ces briques, imaginant
          sans cesse de nouvelles constructions, faisant et défaisant ses ouvrages avec attention et
          minutie sans jamais se lasser.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          En grandissant, Lisa a arrêté de jouer aux Lego. Après de brillantes études dans une
          grande école d’ingénieur, elle est devenue architecte de systèmes d’information. Tous les
          jours, elle conçoit des systèmes complexes pour traiter l’information dans les
          entreprises. Elle est passionnée par ce métier, dans lequel elle assemble des « briques ».
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Mais récemment, Lisa a été promue directrice de projet. Elle a dû laisser de côté la
          technique, les « briques ». Son salaire est de 30% supérieur, elle va pouvoir offrir à ses
          enfants le voyage au Canada dont ils rêvent, ses parents sont fiers d’elle. Lisa devrait
          être heureuse, alors pourquoi éprouve-t-elle ce sentiment de vide ? Tout simplement, parce
          qu’elle ne fait plus ce qu’elle aime : concevoir et assembler.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Elle est coupée de son talent naturel, de sa personnalité profonde.
        </Typography>
      </div>
      <br />
      <br />
      <Paragrapth align="justify">
        Le travail que nous vous proposons ici est de partir à la découverte de votre personnalité
        profonde. Il s’agit d’identifier les attitudes et les comportements qui sont naturels chez
        vous. Cette recherche vous permettra d’être de nouveau en accord avec vous-même, d’être plus
        efficace et performant(e) également. Car en réalité, ces comportements constituent vos
        talents.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Ce que j’aime faire, ce que je fais naturellement, je le fais mieux que toute autre chose.
        Le bonheur et la réussite sont les deux faces d’une même pièce de monnaie. Ce travail n’est
        ni facile, ni évident. Il demande un effort d’introspection et de consultation, un travail
        d’écoute de soi-même et des autres.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Rappelez-vous seulement que le plus important est de commencer ce travail, de conserver la
        volonté et le réflexe de le poursuivre au-delà.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        C’est l’objectif des exercices que nous proposons dans ce livret. L’objectif du consultant
        (de la consultante) qui vous accompagne est de vous rendre le(a) plus autonome possible dans
        ce travail de connaissance de soi.
      </Paragrapth>
      <br />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -80,
      [theme.breakpoints.down(1300)]: {
        marginTop: -87,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 18,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 11,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
    },
    imgTailleur: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    colorPrimaryBook: {
      color: '#2b56a5',
    },
    titleSteveJobs: {
      fontSize: 25,
      color: '#2B56A5',
      textAlign: 'center',
      fontWeight: 300,
      width: '90%',
      margin: '0 auto',
      lineHeight: '7vh',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '4vh',
      },
    },
  }),
);
export default Page09;
