/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './style';


const Explication: FC = () => {

    const classe = useStyles();

    return (

        <div className={classe.content}>

            <div
                className={classe.contentText}
            >

                <Typography
                    className={classe.title}
                    component='h2'
                >
                    APPLICATIONS DANS VOTRE VIE PRO ET PERSO
                </Typography>

                <Typography
                    component='p'
                    className={classe.description}
                >
                    Dans votre vie professionnelle, vous devriez exercer un métier cohérent avec vos valeurs et travailler dans une organisation qui vous permet de les exprimer à travers vos actions. Dans votre vie personnelle, vous devriez développer des relations avec des personnes qui partagent vos valeurs. Développer des relations avec des personnes qui ont la même vision de la vie que vous favorise la bonne entente et l’harmonie.
                </Typography>

            </div>

        </div>

    );
};

export default Explication; 