import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
    height: 100,
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 12,
    color: '#9799B1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
