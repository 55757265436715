import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    page: {
        paddingTop: '75px',
        paddingBottom: '75px',
        paddingLeft: '100px',
        paddingRight: '100px',
        maxWidth: '1240px',
        minHeight: '147vh',
        display: 'block',
        flex: 'none',
        color: '#2B56A5',
        order: 0,
        flexGrow: 0,
        [theme.breakpoints.down('sm')]: {
            paddingTop: '75px',
            paddingBottom: '75px',
            paddingLeft: '75px',
            paddingRight: '75px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: "auto",
            minHeight: "auto",
            maxHeight: "none"
        },
    },
    pageNoTruncate: {
        paddingTop: '75px',
        paddingBottom: '75px',
        paddingLeft: '100px',
        paddingRight: '100px',
        maxWidth: '1240px',
        display: 'block',
        flex: 'none',
        color: '#2B56A5',
        order: 0,
        flexGrow: 0,
        height: "auto",
        minHeight: "auto",
        maxHeight: "none",
        [theme.breakpoints.down('sm')]: {
            paddingTop: '75px',
            paddingBottom: '75px',
            paddingLeft: '75px',
            paddingRight: '75px',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            height: "auto",
            minHeight: "auto",
            maxHeight: "none"
        },
    },
    readOnly: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 10,
        background: 'transparent'
    },
    simpleContent: {
        margin: '0',
        '@media only screen and (max-width: 600px)': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
        '@media only screen and (max-width: 361px)': {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        },
    },
    center: {
        display: 'flex',
        maxWidth: '1240px',
        minHeight: '147vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nbrPage: {
        position: 'relative',
        bottom: 75,
        fontFamily: 'calibri',
        fontSize: '1.5em',
        fontWeight: 300,
        margin: 0,
        padding: 0,
        right: 30,
        float: 'right',
        [theme.breakpoints.down('sm')]: {
            bottom: 45,
            right: 20,
        },
        [theme.breakpoints.down('xs')]: {
            right: 20,
            bottom: 35
        },
    }
}));
