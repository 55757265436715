import { getFromLS } from '@orientaction/utils';
import { validate } from 'email-validator';

export const getButtonTextByState = (status: string) => {
  if (status === 'loading') {
    return 'Chargement ... ';
  }

  if (status === 'Updated') {
    return 'Enregistrer';
  }
  return 'Créer le compte';
};

export const canIUpdate = (isUpdate: boolean, currentCompanyUserRole?: number) => {
  const userInfos = JSON.parse(getFromLS('userInfos') || '');

  const { company_user_role, role } = userInfos;

  if (role?.type === 'admin') {
    return true;
  }

  if (role?.type === 'authenticated') {
    if (!isUpdate) {
      return true;
    }

    if (company_user_role?.id === 1) {
      return true;
    }

    if (currentCompanyUserRole) {
      const condition = company_user_role?.id + 1 < currentCompanyUserRole && isUpdate;

      if (condition) {
        return true;
      }
    }

    return false;
  }

  return false;
};

export const validateForm = (inputs: string[], user: any) => {
  const formError: { [key: string]: boolean | string } = {};

  inputs.forEach((type: string) => {
    if (type === 'email' && !validate(user[type])) {
      formError[type] = !validate(user[type]);
    } else if ((type !== 'email' && !user[type]) || user[type] === ' ') {
      formError[type] = true;
    }
  });

  if (Object.keys(formError).length === 0) {
    return { isValid: true, formError };
  }

  return { isValid: false, formError };
};
