// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    formInput: {
      width: '100%',
      '& div fieldset': {
        borderColor: theme.palette.primary.dark,
      },
      '& div input': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    error: {
      '& div fieldset': {
        borderColor: `${theme.palette.error.main} !important`,
      },
    },
    marginTop: {
      marginTop: 15,
    },
    fullWidth: {
      width: '100%',
    },
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '@media(min-width: 600px)': {},
    },
    textHeader: {
      display: 'none',
      marginLeft: 0,
      '@media(min-width: 600px)': {
        display: 'block',
      },
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    overrideThumb: {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      width: 'auto',
      height: 100,
      maxWidth: 100,
    },
    variableFormTitle: {
      fontFamily: 'Poppins',
      fontSize: 22,
      fontStyle: 'normal',
      fontWeight: 700,
      color: theme.palette.primary.light,
    },
    replaceTextContainer: {
      marginLeft: '3.7rem',
      marginTop: 7,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 5,
      cursor: 'pointer',
    },
  })
);
