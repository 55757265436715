import { ArcElement, Chart as ChartJS } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement);

const Diagram = (props: any) => {
  const { graphData } = props;
  const data = {
    datasets: [
      {
        data: graphData,
        backgroundColor: ['#FDCE29', '#E6E6E6'],
        borderColor: ['#FDCE29', '#E6E6E6'],
      },
    ],
  };

  const options = {
    cutout: '80%',
    legend: {
      display: false,
    },
    tooltip: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart: any) {
      const { ctx } = chart;
      ctx.save();
      ctx.font = 'bolder 30px sans-serif';
      ctx.fillStyle = '#FDCE29';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(
        `${graphData[0]}%`,
        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      );
    },
  };

  return <Doughnut width={160} height={160} data={data} options={options} plugins={[textCenter]} />;
};

export default Diagram;
