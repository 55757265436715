import { Button, Dialog, DialogContent, Grid, IconButton, Slider, Theme, useMediaQuery } from '@material-ui/core';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import html2canvas from 'html2canvas';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as Close } from '../../../../Asset/svg/close.svg';
import { ReactComponent as ZoomIn } from '../../../../Asset/svg/zoom_in.svg';
import { ReactComponent as ZoomOut } from '../../../../Asset/svg/zoom_out.svg';
import styles from './style';

interface IProps {
  fileUrl?: string;
  open: boolean;
  handleClose?: () => void;
  width?: any;
  height?: any;
}

const ModalZoomImg: FC<IProps> = ({
  fileUrl,
  open,
  handleClose,
  width = '100%',
  height = 'auto',
}) => {
  const classes = styles();
  const [scale, setScale] = useState<number>(1);
  const [position, setPosition] = useState<any>({ x: 0, y: 0 });
  const imageRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const handleChange = (event: any, newValue: number | number[]) => {
    console.log({ newValue });
    setScale(newValue as number);
  };
  const handleZoomIn = () => {
    const scaleIn = scale + 0.1;
    if (scaleIn > 2) {
      setScale(2);
    } else {
      setScale(scaleIn);
    }
  };
  const handleZoomOut = () => {
    const scaleOut = scale - 0.1;
    if (scaleOut < 1) {
      setScale(1);
    } else {
      setScale(scaleOut);
    }
  };
  // useEffect(() => {
  //   const image = imageRef?.current;
  //   const container = containerRef?.current;
  //   let isDragging = false;

  //   console.log({ image, container });

  //   const handleMouseDown = (e: any) => {
  //     isDragging = true;
  //   };

  //   const handleMouseMove = (e: any) => {
  //     console.log('mandeha ve ?');
  //     if (!isDragging) {
  //       return;
  //     }

  //     setPosition((prevPos: any) => {
  //       console.log('tafiditra')

  //       const containerBounds = containerRef.current.getBoundingClientRect();
  //       const imageBounds = imageRef.current.getBoundingClientRect();
  //       console.log({prevPos, e})
  //       const movementX = isXs ? e?.touches[0]?.clientX : e.movementX;
  //       const movementY = isXs ? e?.touches[0]?.clientX : e.movementX;
  //       const newX = prevPos.x + movementX;
  //       const newY = prevPos.y + movementY;

  //       const maxTranslateX = (imageBounds.width - containerBounds.width) / 2;
  //       const maxTranslateY = (imageBounds.height - containerBounds.height) / 2;

  //       const maxX = 0;
  //       const maxY = 0;
  //       console.log({newX, newY, maxX, maxY, maxTranslateX, maxTranslateY})
  //       console.log({containerBounds, imageBounds})
        

  //       return {
  //         x: Math.max(Math.min(newX, maxTranslateX), -maxTranslateX),
  //         y: Math.max(Math.min(newY, maxTranslateY), -maxTranslateY),
  //       };
  //     });
  //   };

  //   const handleMouseUp = () => {
  //     isDragging = false;
  //   };
  //   if (!isXs) {
  //     image?.addEventListener('mousedown', handleMouseDown);
  //     image?.addEventListener('mousemove', handleMouseMove);
  //     image?.addEventListener('mouseup', handleMouseUp);
  //   } else {
  //     image?.addEventListener('touchstart', handleMouseDown);
  //     image?.addEventListener('touchmove', handleMouseMove);
  //     image?.addEventListener('touchend', handleMouseUp);
  //   }

  //   return () => {
  //     if (!isXs) {
  //       image?.removeEventListener('mousedown', handleMouseDown);
  //       image?.removeEventListener('mousemove', handleMouseMove);
  //       image?.removeEventListener('mouseup', handleMouseUp);
  //     } else {
  //       image?.removeEventListener('touchstart', handleMouseDown);
  //       image?.removeEventListener('touchmove', handleMouseMove);
  //       image?.removeEventListener('touchend', handleMouseUp);
  //     }
  //   };
  // }, [imageRef, scale, isXs]);

  // const handleWheel = (e: any) => {
  //   e.preventDefault();
  //   setScale((prevScale) => Math.min(Math.max(prevScale + e.deltaY * -0.01, 1), 3));
  // };

  useEffect(() => {
    if (containerRef?.current && imageRef?.current) {
      const img = imageRef?.current;
      const container = containerRef?.current;
      setPosition((prevPos: any) => {
        const newX = prevPos.x;
        const newY = prevPos.y;
        const maxX = 0 - (container.offsetWidth - img.offsetWidth * scale) / 2;
        const maxY = 0 - (container.offsetHeight - img.offsetHeight * scale) / 2;
        const minX = (container.offsetWidth - img.offsetWidth * scale) / 2;
        const minY = (container.offsetHeight - img.offsetHeight * scale) / 2;

        return {
          x: Math.min(maxX, Math.max(minX, newX)),
          y: Math.min(maxY, Math.max(minY, newY))
        };
      });
    }
  }, [scale]);

  const resetAndClose = () => {
    if (handleClose) {
      handleClose()
    }
    setScale(1);
    setPosition({ x: 0, y: 0 });
  }

  const [lastTouch, setLastTouch] = useState({ x: 0, y: 0 });
  const [lastDistance, setLastDistance] = useState(null);

  useEffect(() => {
    const img = imageRef?.current;
    const container = containerRef?.current;

    const handleTouchStart = (e: any) => {
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        setLastTouch({ x: touch.clientX, y: touch.clientY });
      } else if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY);
        setLastDistance(distance as any);
      }
    };

    const handleTouchMove = (e: any) => {
      e.stopPropagation();
      if (e.touches.length === 1) {
        const touch = e.touches[0];
        const touchX = touch.clientX;
        const touchY = touch.clientY;

        const movementX = touchX - lastTouch.x;
        const movementY = touchY - lastTouch.y;

        setLastTouch({ x: touchX, y: touchY });

        setPosition((prevPosition: any) => {
          const newX = prevPosition.x + movementX;
          const newY = prevPosition.y + movementY;

          // Calcul des limites
          const maxX = 0 - (container.offsetWidth - img.offsetWidth * scale) / 2;
          const maxY = 0 - (container.offsetHeight - img.offsetHeight * scale) / 2;
          const minX = (container.offsetWidth - img.offsetWidth * scale) / 2;
          const minY = (container.offsetHeight - img.offsetHeight * scale) / 2;

          return {
            x: Math.min(maxX, Math.max(minX, newX)),
            y: Math.min(maxY, Math.max(minY, newY))
          };
        });
      } else if (e.touches.length === 2) {
        const touch1 = e.touches[0];
        const touch2 = e.touches[1];
        const distance = Math.hypot(touch2.clientX - touch1.clientX, touch2.clientY - touch1.clientY);
        const scaleChange = distance / (lastDistance || 1);
        setLastDistance(distance as any);

        setScale((prevScale) => Math.max(1, prevScale * scaleChange));
      }
    };

    if (img) {
      img.addEventListener('touchstart', handleTouchStart);
      img.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (img) {
        img.removeEventListener('touchstart', handleTouchStart);
        img.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [lastTouch, lastDistance, scale]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.containerPaper}>
      <DialogContent className={classes.flexContent}>
        <IconButton
          style={{ background: '#E63C3C', zIndex: 2 }}
          className={classes.btnClose}
          onClick={resetAndClose}>
          <Close className={classes.icon} />
        </IconButton>
        <div style={{ position: 'relative' }}>
          <Grid container={true} spacing={2} justifyContent="center" style={{ marginBottom: 10 }}>
            <Grid item={true}>
              <IconButton
                color="secondary"
                style={{ background: '#000A8C' }}
                onClick={handleZoomOut}>
                <ZoomOut className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item={true} xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Slider
                value={scale}
                min={1}
                step={0.0001}
                max={2}
                onChange={handleChange}
                aria-labelledby="non-linear-slider"
              />
            </Grid>
            <Grid item={true}>
              <IconButton
                color="secondary"
                aria-label="delete"
                style={{ background: '#000A8C' }}
                onClick={handleZoomIn}>
                <ZoomIn className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
          <div
            ref={containerRef}
            style={{
              width,
              height,
              borderRadius: 5,
              background: '#F1F1F1',
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <img
              ref={imageRef}
              style={{
                width: `100%`,
                height: `auto`,
                scale: `${scale}`,
                translate: `${position.x}px ${position.y}px`,
                willChange: 'transform',
                touchAction: 'none',
              }}
              src={fileUrl}
              alt=""
              draggable={false}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ModalZoomImg;
