/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page14 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={14}>
      <div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className="opacity07">
              POURQUOI VOUS AVEZ RAISON DE
              <br />
              VOULOIR CHANGER ?
            </Typography>
          </div>
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20,
              }}>
              <strong>‘‘ Changer de lit guérit la fièvre. ’’</strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter',fontSize: 20 }}>
              Peter Drucker
            </Typography>
          </div>
          <div
            className={clsx(classes.positionCenter)}
            style={{ marginTop: '40px', marginBottom: '40px' }}
          />
          <div style={{ textAlign: 'center' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20,
              }}>
                <strong>‘‘ Pour s’améliorer, il faut changer. Donc, pour être parfait, il faut avoir changé
                souvent. ’’</strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter',fontSize: 20 }}>
              Winston Churchill
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      <div >
        <TitleNbr number={1} text="NOUS N’AVONS QU’UNE VIE" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Et elle est courte ! Pourtant, quand nous observons les gens autour de nous, nous avons
              l’impression du contraire.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              La plupart des gens agissent comme s’ils étaient immortels.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Ils font des choix qui vont à l’encontre de leur santé, de leur bien-être, de leurs
              intérêts et de leur bonheur.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Prendre conscience que nous n’avons qu’une vie et qu’elle est courte, c’est prendre
              conscience qu’il faut en profiter au maximum.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Il faut jouir de la vie. Il faut essayer d’être le(la) plus heureux(se) possible,
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              le plus de temps possible dans ce temps limité.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
    },
  })
);
export default Page14;
