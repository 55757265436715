import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    number: {
      borderRadius: '50%',
      width: 30,
      height: 30,
      position: 'relative',
      '&::after': {
        content: '" "',
        clipPath: 'polygon(0% 50.15%, 100% 0%, 100% 100%)',
        backgroundColor: 'inherit',
        transform: 'translate(23%, 67%)',
        width: 15,
        height: 15,
        position: 'absolute',
        zIndex: 1,
      },
      '& span': {
        height: 'auto',
        marginTop: 4,
        color: 'white',
        zIndex: 2
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textBulle: {
      fontSize: 17,
      fontWeight: 600,
    },
    itemExp: {
      margin: '28px 5px 0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    itemRoot: {
      borderBottom: '1px solid white',
      paddingBottom: 10,
      marginBottom: 20,
    },
    itemRootLastChild: {
      borderBottom: '1px solid white',
      paddingBottom: 10,
      marginBottom: 20,
      '&:last-child': {
        borderBottom: 'none !important',
      },
    },
    marginXMobile: {
      margin: '10px 5px',
      '@media only screen and (max-width: 600px)': {
        margin: '10px 0px',
      },
      '@media only screen and (max-width: 361px)': {
        margin: '10px 0px',
      },
    },
    cardBord: {
      borderRadius: 10,
      border: '1px solid white',
      padding: 30,
      margin: '30px 0px',
      '@media only screen and (max-width: 600px)': {
        padding: '20px 15px',
      },
      '@media only screen and (max-width: 361px)': {
        padding: '20px 15px',
      },
    },
    titleCard: {
      textAlign: 'center',
      fontSize: 18,
    },
    textDescription: {
      '& ul': {
        paddingInlineStart: 10,
      },
    },
    imgAd: {
      width: '95%',
      height: 'auto',
      margin: '20px auto 20px',
      display: 'block',
    },
    marginTopNone: {
      marginTop: '0px !important',
    },
  })
);
