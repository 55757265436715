/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { FC } from "react";
import useStyles from "./style";

interface ITitle {
  title: string;
  firstTitle: string;
  firtsecondTitle: string;
  secondTitle: string;
  secondSousTitle: string;
}

const Title: FC<ITitle> = ({
  title,
  firstTitle,
  firtsecondTitle,
  secondTitle,
  secondSousTitle
}) => {

  const classes = useStyles();
  return (
    <div style={{ textAlign: 'center' }}>

      <Typography
        variant="h1"
        component="h1"
        color="primary"
        gutterBottom={true}
//        className="opacity07"
        className={classes.title}

//        style={{ }}
      >
        {title}
      </Typography>

      <div style={{ textAlign: 'center', marginTop: '50px', }}>
        <Typography
          color="primary"
          className={classes.label1}
        >
          <strong>{firstTitle}</strong>
        </Typography>
        <Typography
          color="primary"
          className={classes.label2}
        >
          {firtsecondTitle}
        </Typography>
      </div>

      <div
        className={clsx(classes.trait, classes.positionCenter)}
        style={{ marginTop: "20px", marginBottom: "25px" }}
      />

      <div style={{ textAlign: 'center' }}>
        <Typography
          color="primary"
          className={classes.label1}
        >
          <strong>
            {secondTitle}
          </strong>
        </Typography>
        <Typography
          color="primary"
          className={classes.label2}
        >
          {secondSousTitle}
        </Typography>
      </div>

      <div className={clsx(classes.positionCenter)} style={{ marginTop: "40px", marginBottom: "40px" }} />

    </div>
  );
};


export default Title;
