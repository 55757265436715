// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import useStyles from './style';
import Img from '../../Img';
import { MarginContainer } from '@orientaction/commons';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page10 = (props: any) => {
  const classe = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_evoulerEnInterne.png' : 'evoulerEnInterne.jpg';
  }, [language]);

  return (
    <Page id={10}>
      <TitleChapitre nbr={2} />

      <MarginContainer>
        <TitlePage>OPTION N°1 : ÉVOLUER EN INTERNE</TitlePage>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Parmi les 5 options qui s’offrent à vous, l’évolution interne est celle qui vient le plus
          vite à l’esprit. Elle est ce qu’on appelle « un changement dans la continuité ». Qui a dit
          qu’il fallait tout changer ? Une évolution en interne consiste à changer de métier
          (tâches, missions, objectifs), de service (collègues, manager, ambiance) ou les deux à la
          fois. Parfois on aime ce que l’on fait, mais on n’aime pas ceux avec qui on le fait ! Ce
          changement n’entraînant pas de modification majeure du contrat de travail, il est souvent
          perçu comme le moins risqué.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <div className={classe.cardBgGrey}>
          <Img src={image} alt="Chaque homme doit inventer son chemin." className={classe.img} />
        </div>
      </MarginContainer>
    </Page>
  );
};
export default Page10;
