/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { FC, useEffect, useState } from 'react';
import { valueAliasRole, getFromLS } from '@orientaction/utils';
import style from './style';
import { useAdminUser, useCompany, useAlert } from '@orientaction/hooks';
import Form from './Form';
import { Button } from '@material-ui/core';
import { Loading, MarginContainer, SelectHieachy } from '@orientaction/commons';
import { listForm } from '@orientaction/Context';

interface IHierachy {
  alias: any;
  isPossiblity: boolean;
}

const Hierachy: FC<IHierachy> = ({ alias, isPossiblity }) => {

  const { current, setCurrent, tabs } = useAdminUser();

  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');

  const classes = style();

  const alert = useAlert();

  const [roleAdmin, setRoleAdmin] = useState(current.company_user_role || '');

  const [loading, setLoading] = useState(false);

  const [openReassignation, setOpenReassignation] = useState(false);

  const { companyUserRole, getPopulateHierachy } = useCompany();

  const [listFormHierachy, setListFormHierachy] = useState<any>([]);

  const getHierchyhasPopulate = async (id: number) => {

    setLoading(true);

    try {

      const hierarchy = await getPopulateHierachy(id);

      setCurrent({
        ...current,
        hierarchy: hierarchy as any,
      });

    } catch {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    getHierchyhasPopulate(current.id);
  }, [current.id]);

  useEffect(() => {

    const newList = [];

    for (let i = (tabs - 1); i < listForm.length; i++) {
      newList.push({
        ...listForm[i],
      });
    }

    setListFormHierachy(newList);

  }, [tabs]);


  const handleReassignation = (e: any) => {
    setOpenReassignation(true);
  };

  const handleClose = () => {
    setOpenReassignation(false);
  }

  const hamdleChangeHierrachySeleted = (e: any) => {

    const { value } = e.target;

    if (value) {

      setCurrent({
        ...current,
        listUpadted: {
          id_current_assigned: value,
          id_for_assigned: current.id
        } as any
      });

    }

  }

  const cancelUpdateHierachy = () => {

    setCurrent({
      ...current,
      listUpadted: [] as any
    });

    handleClose();

  }



  return (
    <div>

      {loading ? (
        <Loading />
      ) : (
        <>

          {listFormHierachy.map((item: any) => {
            return (
              <div style={{ margin: "2vh 0" }}>
                <Form
                  name={valueAliasRole(item.name, alias.attributes.companyUserRoleAlias)}
                  listHierachy={current.hierarchy?.[item.name] || []}
                />
              </div>
            );
          })}

          {
            isPossiblity && (
              <Button className={classes.btnReasigner} onClick={handleReassignation}>
                Réassigner les comptes
              </Button>
            )
          }

          {openReassignation && (
            
            <MarginContainer>

              <div className={classes.addBackground}>

                <Typography className={classes.label}>
                  Voulez-vous réassigner tous les comptes à un autre administrateur ? (Facultatif)
                </Typography>

                <div style={{ marginTop: '15px', width: '100%' }}>

                  <SelectHieachy
                    value={current?.listUpadted?.id_current_assigned || ''}
                    alias={alias}
                    errorForm={{}}
                    readOnly={false}
                    title="Utilisateur consultant"
                    name="upper_hierarchy"
                    handleChange={hamdleChangeHierrachySeleted}
                    company_user_role={current.company_user_role}
                    company={current.company}
                    equal={true}
                    direction={current.Direction}
                  />

                </div>

                <div
                  onClick={cancelUpdateHierachy}
                  className={classes.cancelBtn}
                >
                  Annuler
                </div>

              </div>

            </MarginContainer>
          )}
        </>
      )}
    </div>
  );
};

export default Hierachy;
