import { makeStyles, Theme } from '@material-ui/core/styles';

export const style = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '95%',
    margin: '0 auto',
    alignSelf: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20%',
  },
  fieldWithLabel: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  phoneField: {
    marginTop: 20,
    width: '100%',
  },
  errorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginTop: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  inputPassword: {
    paddingRight: 5,
  },
  labelText: {
    margin: 'auto',
    font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
  },
  btnConnectContainer: {
    width: '100%',
  },
  btnConnect: {
    width: '100%',
    background:
      'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#fff',
  },
  previousBtn: {
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #000A8C',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#000A8C',
  },
  forgotPasswordLink: {
    color: '#000A8Cc !important',
    textDecoration: 'underline',
    font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
    marginTop: 20,
  },
  passwordErrorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    minHeight: 20,
    maxWidth: 330,
  },
  eyeImg: {
    cursor: 'pointer',
    zIndex: 1,
  },
  inputWitAdornment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  h2Tittle: {
    color: '#000A8C',
    textAlign: 'center',
    font: 'normal normal bold 16px/28px ITC Avant Garde Gothic Std Bold',
    fontSize: '2vh',
  },
}));
