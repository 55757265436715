import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      display: 'flex',
      alignItems: 'center',
      margin: '1vh auto',
      borderRadius: '0.5vh',
      overflow: 'hidden',
    },
    score: {
      display: 'flex',
      height: '22px',
      alignItems: 'center',
      paddingLeft: '0.75vh',
      paddingRight: '0.75vh',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '14px',
    },
    textContent: {
      width: '50%',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontSize: '14px',
    },
  }),
);
