/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Page, Title } from '@orientaction/components';
import Img from '../../components/Img';
import { useStyles } from './style';
import { publicBook5Path } from '@orientaction/utils';

const Page09 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={9}>
      <div>
        {/* <Img className={classes.img} src="illustration_page_13.png" alt="illustration_page_13" /> */}
        <Grid container={true} justifyContent="center">
          <Grid xs={12}>
            <div style={{ position: 'relative' }}>
              <div className={classes.textPositionNeed}>
                <Typography className={classes.textStyleInside}>Besoins</Typography>
              </div>
              <div className={classes.textPositionPerso}>
                <Typography className={classes.textStyleInside}>Personnalité</Typography>
              </div>
              <div className={classes.textPositionSens}>
                <Typography className={classes.textStyleInside}>Sens</Typography>
              </div>
              <img
                className={classes.img}
                src={`${publicBook5Path}/illustration_page_13.png`}
                alt="logo"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container={true} justifyContent="center">
          <Grid item={true} xs={4} className={classes.cardBgGrey1}>
            <Typography variant="h6" component="h6" className={classes.testimony}>
              HEUREUX (SE)
            </Typography>
          </Grid>
          <Grid item={true} xs={4} className={classes.cardBgGrey1}>
            <Typography variant="h6" component="h6" className={classes.testimony}>
              ET
            </Typography>
          </Grid>
          <Grid item={true} xs={4} className={classes.cardBgGrey1}>
            <Typography variant="h6" component="h6" className={classes.testimony}>
              PERFORMANT(E)
            </Typography>
          </Grid>
        </Grid>
        <Grid container={true} justifyContent="center">
          <Grid item={true} xs={4} className={classes.card1}>
            <div className={classes.content}>
              <Typography component="p" className={classes.paragraph}>
                Je cherche l’activité qui donne du sens à ma vie. Je trouve du sens à ma vie en me
                rendant utile aux autres, en répondant à leurs besoins.
              </Typography>
            </div>
          </Grid>
          <Grid item={true} xs={4} className={classes.card1}>
            <Typography component="p" className={classes.testimony2}>
              LE SENS DE VOTRE VIE
            </Typography>
          </Grid>
          <Grid item={true} xs={4} className={classes.card1}>
            <div className={classes.content}>
              <Typography component="p" className={classes.paragraph}>
                Les autres reconnaissent que je suis utile quand je réponds à leurs besoins. La
                compétence, c’est la capacité à répondre aux besoins d’autrui.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container={true} justifyContent="center">
          <Grid item={true} xs={4} className={classes.card1}>
            <div className={classes.content}>
              <Typography component="p" className={classes.paragraph}>
                Je cherche l’entreprise et la fonction les plus à même de répondre à mes besoins en
                fonction de mes priorités personnelles et professionnelles.
              </Typography>
            </div>
          </Grid>
          <Grid item={true} xs={4} className={classes.card1}>
            <Typography component="p" className={classes.testimony2}>
              VOS BESOINS
            </Typography>
          </Grid>
          <Grid item={true} xs={4} className={classes.card1}>
            <div className={classes.content}>
              <Typography component="p" className={classes.paragraph}>
                En échange de la réponse à mes besoins, mon employeur(se) m’attribue une fonction et
                attend un certain résultat. Ce résultat, je le produis grâce à mes compétences.
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container={true} justifyContent="center">
          <Grid item={true} xs={4} className={classes.card1}>
            <div className={classes.content}>
              <Typography component="p" className={classes.paragraph}>
                Pour me sentir bien, je dois exercer une activité qui correspond à ma personnalité.
                Je travaille naturellement et sans contraintes.
              </Typography>
            </div>
          </Grid>
          <Grid item={true} xs={4} className={classes.card1}>
            <Typography component="p" className={classes.testimony2}>
              VOTRE PERSONNALITÉ
            </Typography>
          </Grid>
          <Grid item={true} xs={4} className={classes.card1}>
            <div className={classes.content}>
              <Typography component="p" className={classes.paragraph}>
                Pour être performant(e), ma fonction doit solliciter mes qualités personnelles
                naturelles. C’est dans ce qu’on aime faire que se situe notre potentiel.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default Page09;
