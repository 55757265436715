import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    display: 'block',
    backgroundColor: '#0E1247',
    padding: 20,
  },
  buttonCenter: {
    display: 'block',
    margin: 'auto',
  },
  text: {
    margin: 'auto',
    color: '#2B56A5',
    textAlign: 'center',
    font: 'normal normal bold 29px/48px ITC Avant Garde Gothic Std Medium',
    letterSpacing: 0,
    opacity: 1,
    fontSize: 30,
    '@media only screen and (max-width: 600px)': {
      fontSize: 22,
    },
    '@media only screen and (max-width: 361px)': {
      fontSize: 20,
    },
  },
  fixed: {
    backgroundColor: '#2B56A5',
    borderRadius: '50px',
    display: 'block',
    margin: 'auto',
    marginTop: '30px',
  },
  imageLogo: {
    width: '30%',
    height: 'auto',
  },
  imageEurope: {
    width: '15%',
    height: 'auto',
  },
  btnContacter: {
    marginTop: 20,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
    paddingRight: 20,
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: 'white',
    borderRadius: '5px',
    fontSize: '12px',
  },
  textHeader: {
    marginBottom: 20,
    textTransform: 'none',
    color: 'white',
    fontSize: '15px',
  },
  trait: {
    width: '100%',
    height: '0.5px',
    backgroundColor: 'white',
    marginTop: 20,
    marginBottom: 10,
  },
  textFooter: {
    color: 'white',
    fontSize: '12px',
  },
  textLink: {
    fontSize: '12px',
    color: 'white',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontWeight: 300,
  },
  spanText: {
    display: 'block',
    marginBottom: '5px',
  },
  textAlignRight: {
    textAlign: 'right',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left',
    },
  },
  footerContainer: {
    marginTop: 23,
  },
}));
