const INITIAL_STATE: any = {
    id: 0,
    indoorWeather: null,
    engagement: [],
};

const stateBookIntro = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'ID': {
            return { ...state, id: action.payload };
        }
        case 'INDOORWEATHER': {
            return { ...state, indoorWeather: action.payload };
        }
        case 'ENGAGEMENT': {
            return { ...state, engagement: action.payload };
        }
        default:
            return state;
    }
};

export default stateBookIntro;

