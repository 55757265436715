import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import { appLanguage } from '@orientaction/reducers';
import { useSelector } from 'react-redux';

import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';

interface IProps {
  result: any;
}

const PyramidBlock: FC<IProps> = ({ result }) => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const lang: string = useSelector(appLanguage);

  const getImg = (key: string): string => {
    switch (key) {
      case 'physiological_pro':
        return '/images/need/en_physiologique.png';

      case 'security_pro':
        return '/images/need/en_security.png';

      case 'horizontal_belonging_pro':
        return '/images/need/en_appartenance.png';

      case 'vertical_belonging_pro':
        return '/images/need/en_appartenance_V.png';

      case 'accomplishment_pro':
        return '/images/need/en_Accomplissement.png';

      case 'self_realization_pro':
        return '/images/need/en_realisation_soi.png';

      case 'spirituality_pro':
        return '/images/need/en_spiritualite.png';

      case 'eternity_pro':
        return '/images/need/en_eternity.png';

      case 'vertical_belonging_perso':
        return '/images/need/eN_Appartenance_V.png';

      case 'physiological_perso':
        return '/images/need/Physiologique.png';

      case 'security_perso':
        return '/images/need/en_security.png';

      case 'horizontal_belonging_perso':
        return '/images/need/en_appartenance.png';

      case 'accomplishment_perso':
        return '/images/need/en_Accomplissement.png';

      case 'self_realization_perso':
        return '/images/need/en_realisation_soi.png';

      case 'spirituality_perso':
        return '/images/need/en_spiritualite.png';

      case 'eternity_perso':
        return '/images/need/en_eternity.png';

      default:
        return '';
    }
  };

  return (
    <Grid className={classes.needPyramidBlockContainer} container={true}>
      <Grid item={true} md={6} lg={6} xs={12}>
        <Grid className={classes.contentNoPadding} container={true}>
          <Grid item={true} md={5} sm={12}>
            <div className={classes.padding25}>
              {!isSmallScreen && (
                <Typography variant="h4" color="primary" className={`${classes.title}`}>
                  PYRAMIDE DES BESOINS 2
                </Typography>
              )}

              <Typography
                variant="body2"
                color="primary"
                className={clsx(classes.description1, classes.paragraphMarginTop)}
              >
                Voici votre besoin principal dans votre vie professionnelle
              </Typography>

              <Typography
                variant="h4"
                color="primary"
                className={classes.favoriteLabel}
                style={{ color: result?.pro?.favorite?.color }}
              >
                {result?.pro?.favorite?.label}
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={clsx(classes.description2, classes.paragraphMarginTop)}
              >
                Voici votre besoin principal dans votre vie professionnelle. La satisfaction de vos
                besoins est à la source de votre bien-être et de votre motivation. Il est important
                que votre métier, vos conditions de travail et de rémunération, ainsi que vos
                relations professionnelles contribuent pleinement à satisfaire ce besoin.
              </Typography>
            </div>
          </Grid>

          <Grid className={classes.pyramidContainer} item={true} md={7} sm={12}>
            <img
              src={
                lang !== 'FR'
                  ? getImg(result?.pro?.favorite?.key)
                  : result?.pro?.favorite?.imgPyramid
              }
              className={classes.imgPyramid}
              alt="Pyramide"
            />
          </Grid>
          {isSmallScreen && (
            <Typography
              variant="h4"
              color="primary"
              className={clsx(classes.title, classes.smallScreenTitle)}
            >
              PYRAMIDE DES BESOINS
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item={true} md={6} lg={6} xs={12}>
        <Grid
          className={clsx(classes.contentNoPadding, classes.contentNoPaddingNotReversed)}
          container={true}
        >
          <Grid className={classes.pyramidContainer} item={true} md={7} sm={12}>
            <img
              src={
                lang !== 'FR'
                  ? getImg(result?.perso?.favorite?.key)
                  : result?.perso?.favorite?.imgPyramid
              }
              className={classes.imgPyramid}
              alt="Pyramide"
            />
          </Grid>
          <Grid item={true} md={5} sm={12}>
            <div className={classes.padding25}>
              <Typography
                variant="body2"
                color="primary"
                className={clsx(
                  classes.description1,
                  classes.paragraphMarginTop,
                  classes.marginTop25,
                  classes.alignTextRight,
                )}
              >
                Voici votre besoin principal dans votre vie personnelle
              </Typography>

              <Typography
                variant="h4"
                color="primary"
                className={clsx(classes.favoriteLabel, classes.alignTextRight)}
                style={{ color: result?.perso?.favorite?.color }}
              >
                {result?.perso?.favorite?.label}
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={clsx(
                  classes.description2,
                  classes.paragraphMarginTop,
                  classes.alignTextRight,
                )}
              >
                Voici votre besoin principal dans votre vie personnelle. La satisfaction de vos
                besoins est à la source de votre bien-être et de votre motivation. Il est important
                que votre situation personnelle, vos conditions de vie, ainsi que vos relations
                conjugales, amicales et familiales contribuent pleinement à satisfaire ce besoin.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PyramidBlock;
