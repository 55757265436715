import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overrideParagraphStyle: {
      fontSize: 12,
      marginTop: "10px !important",
    },
    h7: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      display: 'inline-block',
      style: 'normal',
      alignItems: 'center',
      padding: 20,
      color: '#2c57a5',
    },
    titlePageOverrideStyle: {
      fontSize: 24,
    },
  })
);

export default useStyles;
