export const styleSelect = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderWidth: '0px',
    backgroundColor: 'transparent',
    minHeight: '20px',
    height: '20px',
    borderColor: 'transparent',
    boxShadow: 'none',
    '& :hover': {
      borderColor: 'transparent',
    },
  }),
  dropdownIndicator: (styles: any, state: any) => ({ ...styles, padding: '0px 8px' }),
  indicatorSeparator: (styles: any, state: any) => ({ ...styles, display: 'none' }),
  input: (styles: any, state: any) => ({
    ...styles,
    margin: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
  }),
  valueContainer: (styles: any, state: any) => ({ ...styles, padding: '0px 8px' }),
};
