/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { Typography, Grid } from '@material-ui/core';
import { Page, TitlePage, ArrayAskAnswer } from '@orientaction/components';
import useStyles from '../../style';
import { useBook1 } from '@orientaction/hooks_book';
import { debounce } from 'lodash';

let typeTimeout: any;

const Page41 = (props: any) => {
  const classe = useStyles();

  const { book, updateProjectMakeSense, getMyValue } = useBook1();
  const updateValue = debounce(event => {
    handleChangeGlobalState(event);
  }, 1000);
  const list_value = getMyValue();

  const onKeyDown = (event: any) => {
    if (props.readOnly) return;
    updateValue(event);
  };

  const handleChange = (e: any) => {
    if (props.readOnly) return;
    updateValue(e);
  };

  const handleChangeGlobalState = (e: any) => {
    if (props.readOnly) return;
    const { value } = e.target;

    updateProjectMakeSense(value);
  };

  return (
    <Page id={40} readOnly={false}>
      <TitlePage>SYNTHÈSE DE VOTRE TRAVAIL DE RÉFLEXION</TitlePage>

      <ArrayAskAnswer
        readOnly={props.readOnly}
        list={[
          {
            title: 'VOTRE OBJECTIF SUPRÊME',
            listAsq: [
              {
                label: 'Votre objectif suprême pour dans dix ans',
                name: 'name',
                value: book.objectifSupreme[0] || '',
                isTextArea: true,
              },
              {
                label: 'Votre objectif suprême pour dans cinq ans',
                name: 'name',
                value: book.objectifSupreme[1] || '',
                isTextArea: true,
              },
              {
                label: 'Votre objectif suprême pour dans trois ans',
                name: 'name',
                value: book.objectifSupreme[2] || '',
                isTextArea: true,
              },
            ],
          },
          {
            title: 'VOS FIGURES DU DESTIN',
            listAsq: [
              {
                label: 'Votre figure n°1',
                name: 'name',
                value: book?.figureOfDestiny ? book.figureOfDestiny[0]?.type || '' : '',
              },
              {
                label: 'Votre figure n°2',
                name: 'name',
                value: book?.figureOfDestiny ? book.figureOfDestiny[1]?.type || '' : '',
              },
              {
                label: 'Votre figure n°3',
                name: 'name',
                value: book?.figureOfDestiny ? book.figureOfDestiny[2]?.type || '' : '',
              },
            ],
          },
          // {
          //     title: "VOS VALEURS",
          //     listAsq: [
          //         {
          //             label: "Votre valeur n°1",
          //             name: "name",
          //             value: list_value[0]?.name || ""
          //         },
          //         {
          //             label: "Votre valeur n°2",
          //             name: "name",
          //             value: list_value[1]?.name || ""
          //         },
          //         {
          //             label: "Votre valeur n°3",
          //             name: "name",
          //             value: list_value[2]?.name || ""
          //         }
          //     ]
          // }
        ]}
      />

      <div className={classe.formContent}>
        <div className={classe.backGroundGrey}>
          <Typography align="center">
            Résumez les caractéristiques principales d’un projet qui aurait du sens
            <br />
            pour vous aujourd’hui :
          </Typography>
        </div>

        <textarea
          onChange={handleChange}
          onKeyDown={onKeyDown}
          onBlur={handleChangeGlobalState}
          defaultValue={book.projectMakeSense || ''}
          readOnly={props.readOnly}
          placeholder="Aa"
          style={{
            width: '100%',
            height: '10vh',
            marginTop: '15px',
            border: '2px solid #0E1247',
            fontSize: '15px',
            padding: 5,
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            position: 'relative',
            zIndex: 150,
          }}
        />
      </div>
    </Page>
  );
};

export default Page41;
