export const context = [
    {
        title: "NE PERDEZ JAMAIS VOTRE OBJECTIF DE VUE",
        id: 1,
        paragraph: `Vous avez un rêve, vous avez décidé de le réaliser, n’abandonnez jamais. Échouer,
        c’est abandonner avant d’avoir réussi.Vous devez visualiser en permanence votre
        objectif. La détermination est le facteur n°1 de la réussite.`
    },
    {
        title: "ACCEPTEZ LE DOUTE",
        id: 2,
        paragraph: `Avoir des doutes est un phénomène psychologique normal lors d’un changement.
        Acceptez-le et faites-en un allié. Le doute est sain car il vous pousse à réfléchir et à
        agir. Si vous êtes paralysé(e) par la peur, parlez-en à votre consultant(e).`
    },
    {
        title: "ADOPTEZ LA STRATÉGIE DES PETITS PAS",
        id: 3,
        paragraph: `On ne grimpe pas une montagne en un jour. Pour atteindre un objectif, surtout s’il
        est ambitieux, des étapes sont nécessaires, du temps aussi. Avancez à votre rythme
        en prenant soin de valider chacune des étapes.`
    },
    {
        title: "L’ACTION COMME SOLUTION",
        id: 4,
        paragraph: `Quand on n’avance plus, quand on a peur, quand on doute, la seule solution est
        d’agir. Arrêtez de réfléchir et agissez. L’action est une source inouïe d’opportunités.
        Laissez-vous surprendre par les autres et par vous-même. `
    },
    {
        title: "DEMANDEZ DE L’AIDE EN CAS DE DIFFICULTÉS",
        id: 5,
        paragraph: `Si vous vous trouvez en difficulté, demandez de l’aide. Il ne faut jamais essayer de
        dépasser une difficulté insurmontable seul(e). Un conseil, un coup de pouce, une
        mise en relation, n’hésitez jamais à demander de l’aide.`
    },
    {
        title: "GARDEZ LE RYTHME",
        id: 6,
        paragraph: `Pour que vos actions portent leurs fruits et pour ne pas diluer votre énergie, il faut agir
        en rythme et le conserver. Il faut donc avancer par actions successives. Comment
        savoir si vous avancez en rythme ? Il faut pouvoir constater des avancées régulières.`
    },
];