import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'left',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    overrideParagrapthListStyle: {
      background: 'transparent !important',
    },
    imgTronc: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      boxShadow: "-2px 0px 15px 2px rgba(100,95,95,0.3)",
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
  })
);

export default useStyles;
