import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardContainer: {
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    borderRadius: '10px',
    width: '100%',
    boxSizing: 'border-box',
  },
  titleContainer: {
    height: '10vh',
  },
  iconButton: {
    padding: 5,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  number: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: 12,
    fontFamily: 'Poppins',
    fontWeight: 700,
  },
  responseTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.primary.light,
  },
  responseVariableLabel: {
    display: 'inline-block',
    color: theme.palette.primary.dark,
    fontWeight: 700,
  },
  responseVariableName: {
    display: 'inline-block',
    marginLeft: 10,
    color: theme.palette.secondary.light,
    fontSize: 14,
    fontWeight: 700,
  },
  itemContainer: {
    marginTop: '1rem',
  },
}));

export default useStyles;
