/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, Paragrapth ,TitlePage} from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';

const Page15 = (props: any) => {


    return (
        <Page id={15}>

            <div style={{ textAlign: 'center' }}>

                <TitlePage>
                    RISQUES ET OPPORTUNITÉS <br />
                    D’UNE ÉVOLUTION EN INTERNE
                </TitlePage>

                {
                    context.map((item: any) => (
                        <>
                            <MarginContainer>
                                <TitleNbr number={item.id} text={item.title} />
                            </MarginContainer>

                            {
                                item.listContext.map((itemList: any) => (
                                    <MarginContainer>

                                        <Paragrapth align='start'>

                                            <Paragrapth align='start' bold={true} colorBlue={true}>
                                                {itemList.title} :
                                            </Paragrapth> {itemList.content}
                                        </Paragrapth>

                                    </MarginContainer>
                                ))
                            }

                        </>
                    ))
                }

            </div>

        </Page>
    );
};

export default Page15;
