/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, ArrayYouContext } from '@orientaction/components';
import { list, paragraph } from './context';

const Page31 = (props: any) => {

    return (
        <Page id={31}>

            <MarginContainer>
                {
                    paragraph.map((item: any) =>
                        <MarginContainer>
                            <Paragrapth align='justify'>
                                {item || ''}
                            </Paragrapth>
                        </MarginContainer>
                    )
                }
            </MarginContainer>

            <MarginContainer>

                <MarginContainer>
                    <Paragrapth
                        align='center'
                        bold={true}
                        colorBlue={true}
                    >
                        QUAND ET COMMENT SAVOIR S’IL FAUT <br />
                        CRÉER/REPRENDRE UNE ENTREPRISE :
                    </Paragrapth>
                </MarginContainer>

                <MarginContainer>
                    <ArrayYouContext objectList={list} />
                </MarginContainer>

            </MarginContainer>

        </Page>
    );
};

export default Page31;
