// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { Paragrapth, TitlePage, Page } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../components/Image';
import useStyles from './style';

const Page23 = () => {
  const classes = useStyles();
  return (
    <Page id={23}>
      <TitlePage showSpanYellow={false} titlePageOverrideStyle={classes.titlePageOverrideStyle}>
        LA THÉORIE DES SIX POIGNÉES DE MAIN
      </TitlePage>
      <MarginContainer>
        <Grid item={true} xs={12} md={12}>
          <Img filename="persons.png" alt="persons" className={classes.img} />
        </Grid>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="center">
          Représentation graphique et artistique de la théorie des six poignées de main (Dannie
          Walker)
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <div className={classes.cardBgGrey}>
          <MarginContainer>
            <Paragrapth align="justify" addBackground={true}>
              Cette théorie, élaborée en 1929 par Frigyes Karinthy, explique que toute personne sur
              la planète peut être reliée à n’importe quelle autre, au travers d’un enchaînement
              d’au plus six poignées de main.
            </Paragrapth>
          </MarginContainer>
          <MarginContainer>
            <Paragrapth align="justify" addBackground={true}>
              Cela veut dire qu’il y a au maximum cinq personnes qui vous séparent de Joe Biden, le
              président des États-Unis, de Bernard Arnaud, le Président fondateur de LVMH ou de Lady
              Gaga.
            </Paragrapth>
          </MarginContainer>
          <MarginContainer>
            <Paragrapth align="justify" addBackground={true}>
              Cette théorie nous montre simplement que ce n’est pas parce nous pensons « que nous
              n’avons pas de réseau » (de capital social) que nous n’en avons pas effectivement. Il
              n’en revient en réalité qu’à nous de le développer. Contacter une personne, même
              importante, est souvent beaucoup plus simple qu’il n’y paraît. C’est juste que bien
              souvent nous n’osons pas ! Osez !
            </Paragrapth>
          </MarginContainer>
        </div>
      </MarginContainer>
    </Page>
  );
};

export default Page23;
