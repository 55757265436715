// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { publicSvgPath } from '../../utils/constants';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#ebebeb',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal'
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal'
  },
  cardBgGrey: {
    background: '#e8eef4'
  }
});

const CardNumber = (props: any) => {
  const classes = useStyles();
  const {categorie, number} = props;

  return (
    <Card variant="outlined" style={{borderRadius: '10px'}}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div>
            <Grid container={true} style={{marginBottom: '10px'}} justifyContent="flex-end">
              <Grid item={true} xs={9} style={{textAlign: 'center',}} className={classes.cardBgGrey}>
                <Typography component="p" className={classes.h7}>
                  Nombre de réponses
                </Typography>
              </Grid>
              <Grid item={true} xs={3} style={{textAlign: 'center',}} className={clsx(categorie === 'A' ? classes.categorieA : classes.categorieB)}>
                <Typography variant="h2" component="h2" className={clsx(classes.h7, categorie === 'A' ? classes.categorieA : classes.categorieB)} >
                  {categorie}
                </Typography>
              </Grid>
            </Grid>
            <div style={{width: '100%', textAlign: 'center'}}>
              <Typography variant="body2" component="p" className={classes.h7}>
                {number}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default CardNumber;
