import { standartBeneficaire, standartCouloms, standartDirection } from '@orientaction/utils';

export const listForm = [
  {
    label: 'Manager n+3',
    name: 'manager_3',
    userTypeConnected: 2,
  },
  {
    label: 'Manager n+2',
    name: 'manager_2',
    userTypeConnected: 3,
  },
  {
    label: 'Manager n+1',
    name: 'manager_1',
    userTypeConnected: 4,
  },
  {
    label: 'Bénéficiaires',
    name: 'Bénéficiaires',
    userTypeConnected: 5,
  },
];

export const nameHierachy = (tabs: any) => {
  if (tabs === 1) {
    return 'Direction';
  }

  if (tabs === 2) {
    return 'manager_3';
  }

  if (tabs === 3) {
    return 'manager_2';
  }

  if (tabs === 4) {
    return 'manager_1';
  }

  return 'Bénéficiaires';
};

export const listTabs: any = [
  {
    label: 'Direction',
    userTypeConnected: 1,
    viewNbr: true,
    ebook1: false,
    to: false,
    colomTable: [...standartDirection],
  },
  {
    label: 'manager_3',
    userTypeConnected: 2,
    viewNbr: true,
    ebook1: false,
    to: false,
    colomTable: [
      ...standartCouloms,
      {
        label: 'Utilisateurs rattachés',
        key: 'subordinates',
        type: 'company_user_role',
      },
    ],
  },
  {
    label: 'manager_2',
    userTypeConnected: 3,
    viewNbr: true,
    ebook1: false,
    to: false,
    colomTable: [
      ...standartCouloms,
      {
        label: 'Admin. de rattachement',
        key: 'subordinates',
        type: 'company_user_role',
      },
    ],
  },
  {
    label: 'manager_1',
    userTypeConnected: 4,
    viewNbr: true,
    ebook1: false,
    to: false,
    colomTable: [
      ...standartCouloms,
      {
        label: 'Utilisateurs rattachés',
        key: 'subordinates',
        type: 'company_user_role',
      },
    ],
  },
  {
    label: 'Bénéficiaires',
    userTypeConnected: 5,
    viewNbr: true,
    ebook1: true,
    to: true,
    colomTable: [...standartBeneficaire],
  },
];
