import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const url = config.api_url;

export const registerUser = async (data: any) => {
  return makeRequest(`${url}/api/Register`, 'POST', data);
};

export const connectUser = async (data: any) => {
  return makeRequest(`${url}/api/auth/local?populate=*`, 'POST', data);
};

export const getMe = async (token: string, year = undefined) => {
  const yearParams = year ? `&year=${year}` : '';
  return makeRequest(`${url}/api/users/me?populate=*${yearParams}`, 'GET', {}, getHeaders(token));
};

export const forgotPassword = async (data: any) => {
  return makeRequest(`${url}/api/auth/forgot-password`, 'POST', data);
};

export const resetPassword = async (data: any) => {
  return makeRequest(`${url}/api/auth/reset-password`, 'POST', data);
};

export const updateUser = async (data: any) => {
  return makeRequest(`${url}/api/auth/register`, 'PUT', data);
};

export const getUserById = async (data: any) => {
  return makeRequest(
    `${url}/api/users/${data.userId}?populate=*`,
    'GET',
    {},
    getHeaders(data.token),
  );
};

export const modifyUser = async (data: any) => {
  return makeRequest(`${url}/api/users/${data.id}`, 'PUT', data, getHeaders(data.token));
};

export const checkUserExist = async (qs: any) => {
  return makeRequest(`${url}/api/company-check-user?q=${qs}`, 'GET');
};

export const verifyIfMailExist = async (email: any) => {
  return makeRequest(`${url}/api/verify-if-mail-exist`, 'POST', { email });
};

export const sendMailForMore = async (id: any, token: any) => {
  return makeRequest(`${url}/api/user-stripe/more-test`, 'POST', { id }, getHeaders(token));
};

export const switchRole = async (token: string, isSecondRoleActivated: boolean) => {
  return makeRequest(
    `${url}/api/users-permissions/user-role-switch`,
    'PUT',
    { isSecondRoleActivated },
    getHeaders(token),
  );
};

export const createUserTemporaryPassword = async (
  token: string,
  companyId: number,
  generatedPassword: string,
) => {
  return makeRequest(
    `${url}/api/create-temporary-password`,
    'POST',
    { companyId, generatedPassword },
    getHeaders(token),
  );
};

export const checkIfUserIsOnTemporaryPasswordService = async (token: string) => {
  return makeRequest(
    `${url}/api/users-permissions/is-user-on-temporary-password`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const updateUserPasswordService = async (token: string, { password }: any) => {
  return makeRequest(
    `${url}/api/users-permissions/update-user-password`,
    'POST',
    { password },
    getHeaders(token),
  );
};

export const checkIfUserHasCompass = async (token: string) => {
  return makeRequest(
    `${url}/api/users-permissions/verify-if-user-has-compass`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getCompassStatusByUserId = async (token: string, id: number) => {
  return makeRequest(
    `${url}/api/users-permissions/get-compass-status/${id}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getCompassTestYearHistories = async (token: string, userId: number) => {
  return makeRequest(
    `${url}/api/users-permissions/get-compass-test-history-years?userId=${userId}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const postContactForm = async (token: string, data: any) => {
  return makeRequest(
    `${url}/api/users-permissions/post-contact-form`,
    'POST',
    data,
    getHeaders(token),
  );
};

export const getUserLanguage = async (data: any) => {
  return makeRequest(
    `${url}/api/users-permissions/get-language/${data.userId}`,
    'GET',
    {},
    getHeaders(data.token),
  );
};
