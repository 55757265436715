// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
    TitleChapitre,
} from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons'
import Img from '../../Img';

const Page18 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={18}
        >

            <TitleChapitre nbr={3} />

            <TitlePage>
                OPTION N°2 : CHANGER DE MÉTIER
            </TitlePage>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Se reconvertir, changer de métier pour peut-être changer de vie… Vous êtes-vous
                    trompé(e) d’orientation dès votre plus jeune âge, parce que vous n’avez pas
                    fait attention, parce que vos parents ou vos professeurs ont choisi pour vous ?
                    Avez-vous changé au point de vouloir faire quelque chose de totalement différent ?
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Vous avez besoin d’un renouveau, d’un grand chambardement ou bien vous en
                    voyez la nécessité en raison d’un contexte économique morose et déprimant.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Peut-être la reconversion est-elle faite pour vous ?
                </Paragrapth>
            </MarginContainer>

            <Img
                src='change_de_metier.jpg'
                alt='OPTION N°2 : CHANGER DE MÉTIER'
                className={classe.img}
            />

        </Page>
    );
};
export default Page18;
