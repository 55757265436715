import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import style from '../../../commonStyle';
import localStyle from './style';

const FreethinkerSuggestion = () => {
  const classes = style();
  const localClasses = localStyle();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        😍 TA VOIE IDÉALE : INSTITUT D’ÉTUDES POLITIQUES (I.E.P)
      </Typography>
      <div className={classes.suggestionContent}>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Après une filière générale débouchant sur de bons résultats au baccalauréat, tu
              intégreras un Institut d’Etudes Politiques (IEP) où tu prépareras une licence en
              sciences politiques.
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionSubtitle} component="p">
          Ces trois années d’études te permettront de te doter d’outils intellectuels pour penser et
          analyser la société dans laquelle tu vis.
        </Typography>
        <Typography
          className={classNames(classes.suggestionSubtitle, localClasses.localCustomParagraph)}
          component="p">
          Les échanges avec les autres élèves, partageant les mêmes centres d’intérêts, te
          procureront une grande satisfaction intellectuelle, ainsi que les échanges avec tes
          professeur(e)s, des personnalités aux parcours personnels et professionnels riches.
        </Typography>
        <Typography className={classes.suggestionQuote} component="p">
          « Qu’est-ce qu’un penseur ? Un homme qui se pose encore des questions quand les autres ne
          s’en posent plus. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        André Frossard
      </Typography>
    </div>
  );
};

export default FreethinkerSuggestion;
