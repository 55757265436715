import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '18px',
      },
    },
    titleHead: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      [theme.breakpoints.down(800)]: {
        fontSize: '18px',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(800)]: {
        flexDirection: 'column',
      },
    },
    content: {
      width: '30.5%',
      backgroundColor: '#fff',
      borderRadius: '14px',
      [theme.breakpoints.down(800)]: {
        width: '100%',
        margin: '1vh 0',
      },
    },
    head: {
      'border-top-left-radius': '12px',
      'border-top-right-radius': '12px',
      'border-bottom-left-radius': '50% 35%',
      'border-bottom-right-radius': '50% 35%',
      height: '89px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: '1vh',
      margin: 0,
      color: '#FFFFFF',
    },
    score: {
      fontFamily: 'Poppins',
      fontSize: '20px',
      fontWeight: 300,
    },
    scoreFavorite: {
      fontWeight: 'bold',
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '21px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '90%',
    },
    img: {
      marginTop: '1.5vh',
      width: '80%',
      marginLeft: '10%',
    },
    iconButton: {
      backgroundColor: 'rgba(0, 10, 140, 1)',
      color: 'white',
      margin: '1vh',
      width: '48px',
      height: '48px',
      '&:hover': {
        backgroundColor: 'rgba(0, 10, 140, 1)',
      },
    },
    isFavorite: {
      border: '5px solid',
      borderRadius: '16px',
      [theme.breakpoints.down(800)]: {
        width: '97.5%',
      },
    },
    isFavoriteHead: {
      'border-top-left-radius': '5px',
      'border-top-right-radius': '5px',
    },
    colorBlack: {
      color: '#0E1247',
    },
  }),
);
