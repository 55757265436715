/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, ArrayYouContext } from '@orientaction/components';
import { list } from './context';

const Page25 = (props: any) => {

    return (
        <Page id={25}>

            <MarginContainer>

                <MarginContainer mormal={true}>
                    <Paragrapth
                        align='center'
                        bold={true}
                        colorBlue={true}
                    >
                       QUAND ET COMMENT SAVOIR S’IL FAUT CHANGER D’EMPLOYEUR :
                    </Paragrapth>
                </MarginContainer>

                <MarginContainer mormal={true}>
                    <ArrayYouContext objectList={list} />
                </MarginContainer>

            </MarginContainer>

        </Page>
    );
};

export default Page25;
