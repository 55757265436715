import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            maxWidth: '110px',
            height: '110px',
            borderRadius: '10px',
            [theme.breakpoints.down(1530)]: {
                maxWidth: '70px',
                height: '70px',
            },
            [theme.breakpoints.down(1130)]: {
                maxWidth: '60px',
                height: '60px',
            },
        },
        fontTitleCard: {
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '15px',
            color: '#000A8C',
            cursor: 'pointer',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        fontTextCard: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '140%',
            color: '#0E1247',
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&>p': {
                marginTop: 5,
                marginBottom: 5,
            },
        },
        cardContainer: {
            borderLeft: '0.75vh solid #EDEEF2',
            width: '100%',
            padding: '10px 5px',
            borderRadius: '10px',
            boxShadow: 'none',
            backgroundColor: '#EDEEF2',
        },

        content: {
            width: '95%',
            margin: '0 auto'
        },
        chipTime: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        iconeTime: {
            fontSize: 20,
            marginRight: 7,
            marginTop: 5
        },
        textTime: {
            fontSize: 14,
            fontFamily: 'Poppins',
            fontWeight: 300
        },
        start: {
            float: 'right',
            position: 'relative',
            right: -55,
            width: 100,
            height: 100,
        }
    })
);
