import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '48%',
      height: '330px',
      backgroundColor: '#23296F',
      borderRadius: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '500',
      [theme.breakpoints.between(900, 1250)]: {
        minHeight: '500px',
      },
      [theme.breakpoints.down(900)]: {
        width: '100%',
        height: 'auto',
        margin: '1vh auto',
        padding: '24px',
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      color: '#fff',
      [theme.breakpoints.down(900)]: {
        fontSize: '14px',
      },
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#fff',
      lineHeight: '180%',
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
      },
    },
    contentText: {
      width: '90%',
      [theme.breakpoints.down(900)]: {
        width: '97%',
      },
    },
  }),
);
