import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      width: '100%',
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btnPrimary: {
      minHeight: 38,
      height: 38,
      width: 38,
      minWidth: 38,
      textTransform: 'none',
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      '@media(min-width: 1250px)': {
        minHeight: 48,
        height: 48,
        width: 146,
        minWidth: 146,
      },
    },
    paddingY: {
      padding: 50,
      [theme.breakpoints.down('sm')]: {
        padding: 20,
      },
    },
    flexCenterY: {
      display: 'flex',
      alignItems: 'center',
    },
    bodycenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
    },
    colorWhite: {
      color: 'white',
    },
    makeBold: {
        color: 'red',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
  }),
);
