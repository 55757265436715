/* eslint-disable no-use-before-define */
import { figure32_page1, figure32_page2, figure32_page3, figure32_page4, figure32_page5 } from '@orientaction/utils';
import { TableAspiration, Page } from '@orientaction/components';
import concat from 'lodash/concat';
import chunck from 'lodash/chunk';

const Page28 = (props: any) => {
  const allFiguresProtected = concat(figure32_page1, figure32_page2, figure32_page3, figure32_page4, figure32_page5)
  return (
    <Page id={28}>
      <TableAspiration figure32={chunck(allFiguresProtected, 12)[1]} />
    </Page>
  );
};

export default Page28;