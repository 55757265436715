import { CreateTestContextProvider } from '@orientaction/Context';
import EditTestContent from './EditTest';

const EditTest = () => {
  return (
    <CreateTestContextProvider>
      <EditTestContent />
    </CreateTestContextProvider>
  );
};

export default EditTest;
