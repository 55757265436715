import { useAlert } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import { postContactForm } from '../../../services/user.service';

const useContactForm = (testId: number) => {
  const token = getFromLS('userToken') || '';
  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const alert = useAlert();

  const postUserContactInfo = async (data: any) => {
    try {
      if (token.length) {
        await postContactForm(token, { ...data, testId });
        alert.setOpenAlert(true, 'Votre demande a bien été envoyé', 'info');
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { postUserContactInfo, user };
};

export default useContactForm;
