import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import { appLanguage } from '@orientaction/reducers';
import { publicSvgPath } from '@orientaction/utils';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import styles from './style';

interface IProps {
  index: number;
  variable: any;
  handleOpen: (variable: any) => void;
}

const VariableCard: FC<IProps> = ({ index, variable, handleOpen }) => {
  const classes = styles();
  const lang: string = useSelector(appLanguage);
  const handleOpenModal = () => {
    handleOpen(index);
  };

  const getImg = (key: string): string => {
    switch (key) {
      case 'accomplishment_pro':
        return '/images/need/en_carte_accomplissement.png';

      case 'vertical_belonging_pro':
        return '/images/need/en_carte_appartenance_verticale.png';

      case 'vertical_belonging_perso':
        return '/images/need/en_perso_carte_needs_app_vert.png';

      case 'horizontal_belonging_pro':
        return '/images/need/en_carte_appartenance_horizon.png';

      case 'accomplishment_perso':
        return '/images/need/en_perso_carte_needs_accompli.png';

      case 'spirituality_pro':
        return '/images/need/en_carte_spiritualite.png';

      case 'eternity_pro':
        return '/images/need/en_carte_eternite.png';

      case 'eternity_perso':
        return '/images/need/en_perso_page_eternite.png';

      case 'physiological_perso':
        return '/images/need/en_perso_carte_needs_physio.png';

      case 'physiological_pro':
        return '/images/need/en_carte_physiologie.png';

      case 'security_pro':
        return '/images/need/en_carte_securite.png';

      case 'security_perso':
        return '/images/need/en_perso_carte_needs_securite.png';

      case 'spirituality_perso':
        return '/images/need/en_perso_page_spirit.png';

      case 'self_realization_pro':
        return '/images/need/en_carte_real_soi.png';

      case 'self_realization_perso':
        return '/images/need/en_perso_carte_needs_real_soi.png';

      default:
        return '/images/need/en_perso_carte_needs_app_horizon.png';
    }
  };

  const score = (variable.score * 100) / 125;

  return (
    <Card className={classes.cardStyle}>
      <div className={classes.cardContentRelativeContainer}>
        <div className={classes.titlehead} style={{ background: variable.color }}>
          <h3 className={classes.titleText}>{variable.label}</h3>
        </div>
        <img
          src={lang !== 'FR' ? getImg(variable.key) : variable.path}
          alt="Top variable"
          style={{ width: '100%', height: 'auto' }}
        />
        <div className={classes.circleContainer}>
          <CircularProgressCustom
            value={variable.score}
            color={variable.color}
            size={78}
            fontSize={18}
            fontSizeFloat={12}
            totalValue={125}
            calculatedValue={score}
          />
        </div>
      </div>
      <CardActions className={classes.cardFooter}>
        <Button
          endIcon={<img src={`${publicSvgPath}/bouton_click.svg`} alt="couverture intro" />}
          onClick={handleOpenModal}
          style={{ textTransform: 'none' }}
        >
          En savoir plus
        </Button>
      </CardActions>
    </Card>
  );
};

export default VariableCard;
