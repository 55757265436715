import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Add from '@material-ui/icons/Add';
import { BurgerMenu, ProfileMenu, UserPhotos } from '@orientaction/commons';
import { useAlert, useProfileMenu } from '@orientaction/hooks';
import { userService } from '@orientaction/services';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { publicSvgPath } from '../../utils/constants';
import { getFromLS } from '../../utils/utils';
import style from './style';

const TableLayout = ({ children, isaddBackground, noPadding }: any) => {
  const history = useHistory();
  const alert = useAlert();
  const cls = style();
  const user = JSON.parse(getFromLS('userInfos') || '');
  const { open, anchorRef, handleToggle, handleClose, goToProfileEdit, logout } = useProfileMenu({
    user,
  });
  const mainLink = user?.role?.id === 3 ? '/results' : '/main';

  const switchRole = async () => {
    try {
      const isSecondRoleActivated = user?.company_user_role?.id === 4;
      const token = getFromLS('userToken') || '';
      const { data } = await userService.switchRole(token, !isSecondRoleActivated);
      localStorage.setItem('userInfos', JSON.stringify(data));
      history.push('/');
    } catch (error) {
      alert.openSimpleAlert();
    }
  };

  return (
    <div className={cls.layout}>
      <div
        className={clsx(
          cls.content,
          isaddBackground ? cls.grayBG : null,
          noPadding ? cls.noPadding : null
        )}>
        <AppBar position="sticky" color="transparent" elevation={0} className={cls.appBar}>
          <nav className={cls.navbar}>
            <div
              className={cls.logoContainer}
              onClick={() => (user ? history.push(mainLink) : history.push('/'))}>
              <picture className={cls.logo}>
                <source media="(min-width:650px)" srcSet={`${publicSvgPath}/logoDesktop.svg`} />
                <img src={`${publicSvgPath}/logoDesktop.svg`} alt="logo-orientation" />
              </picture>
              {/* <img className={cls.logo} src={`${publicSvgPath}/logo-orientaction.png`} alt="logo" /> */}
            </div>
            <div className={cls.profilContainer}>
              <UserPhotos
                anchorRef={anchorRef}
                user={user}
                handleToggle={handleToggle}
                open={open}
              />

              <ProfileMenu
                open={open}
                anchorRef={anchorRef}
                handleToggle={handleToggle}
                handleClose={handleClose}
                goToProfileEdit={goToProfileEdit}
                logout={logout}
                user={user}
                switchRole={switchRole}
              />
            </div>
          </nav>
        </AppBar>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default TableLayout;
