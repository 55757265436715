const INITIAL_STATE: any = {
    id: 0,
    indoorWeather: null,
    experience: {},
    planB: {},
    readyToSuccess: {},
    whenProjectStart: {},
    ifProjectFinish: {},
    uncertainy: {},
    fullFillingYourDream: {},
    planProject: {},
    projectProffessionalAndAspiration: {},
    fourPreferenceOfYourPersonnality: {},
    threeNeedAnswerThisProject: {}
};

const stateBook5 = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'ID': {
            return { ...state, id: action.payload };
        }
        case 'INDOORWEATHER': {
            return { ...state, indoorWeather: action.payload };
        }
        case 'EXPERIENCE': {
            return { ...state, experience: action.payload };
        }
        case 'PLANB': {
            return { ...state, planB: action.payload };
        }
        case 'READYTOSUCCESS': {
            return { ...state, readyToSuccess: action.payload };
        }
        case 'WHENPROJETSTART': {
            return { ...state, whenProjectStart: action.payload };
        }
        case 'IFPROJECTFINISH': {
            return { ...state, ifProjectFinish: action.payload };
        }
        case 'UNCERTAINY': {
            return { ...state, uncertainy: action.payload };
        }
        case 'FULFILLINGYOURDREAM': {
            return { ...state, fullFillingYourDream: action.payload };
        }
        case 'PLANPROJECT': {
            return { ...state, planProject: action.payload };
        }
        case 'PROJECTPROFFESSIONALANDASPIRATION': {
            return { ...state, projectProffessionalAndAspiration: action.payload };
        }
        case 'THREENEEDANSWERTHISPROJECT': {
            return { ...state, threeNeedAnswerThisProject: action.payload };
        }
        case 'FOURPREFERENCEOFYOURPERSONNALITY': {
            return { ...state, fourPreferenceOfYourPersonnality: action.payload };
        }
        case 'YOURPERSONNALPROJECTANDSKILL': {
            return { ...state, yourPersonnalProjectAndSkill: action.payload };
        }
        default:
            return state;
    }
};

export default stateBook5;
