import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bodyContent: {
            minHeight: '20vh',
        },
        cardBgGrey: {
            padding: 10,
            background: '#e8eef4',
        },
        cardBlue: {
            width: 45,
            height: 45,
            background: '#2B56A5',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '2.5vh'
        },
        label2: {
            color: '#2B56A5',
            margin: 0,
            padding: 0,
            fontSize: '2vh'
        },
        contentAsk: {
            width: '90%',
            margin: '1vh auto'
        },
        desactived: {
            margin: 1,
            width: 25,
            height: 25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        actived: {
            margin: 1,
            width: 25,
            height: 25,
            fontWeight: 'bold',
            background: '#2B56A5',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
        }
    })
);

export default useStyles;