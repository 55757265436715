/* eslint-disable react/destructuring-assignment */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr, TitlePage } from '@orientaction/components';
import { useBook4 } from '@orientaction/hooks_book';
import { lists, arrayTextField } from './constant';
import Array from '../../components/ArrayForm';

const Page26 = (props: any) => {

  const { accessYourCulturalCApitalMobilizable, setAccessYourCulturalCApitalMobilizable } = useBook4();

  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setAccessYourCulturalCApitalMobilizable({
      ...accessYourCulturalCApitalMobilizable,
      [name]: value
    });

  };

  const handleChangeArrayState = (groupBy: string) => (e: any) => {

    const { name, value } = e.target;

    setAccessYourCulturalCApitalMobilizable({
      ...accessYourCulturalCApitalMobilizable,
      array: {
        ...accessYourCulturalCApitalMobilizable?.array,
        [groupBy]: {
          ...accessYourCulturalCApitalMobilizable?.array?.[groupBy],
          [name]: value
        }
      }
    });

  };

  return (
    <Page id={26} readOnly={false}>

      <TitlePage>
        ÉVALUEZ VOTRE CAPITAL CULTUREL
        <br />
        MOBILISABLE
      </TitlePage>

      <MarginContainer>
        <Paragrapth align="justify">
          Listez ici les trois domaines dans lesquels vous possédez une connaissance supérieure à la
          moyenne. Il peut s’agir de domaines professionnels comme le droit, les assurances, la
          gestion d’entreprise, la communication, la physique des fluides, les statistiques, la
          pédagogie, les soins médicaux, etc. ou personnels comme le bricolage, le jardinage, la
          littérature, le tricot, l’équitation, la course à pied, les voyages, la cuisine etc.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Vous devez juste avoir dans ces domaines des connaissances supérieures à la moyenne des
          autres personnes, ce qui fait de vous un(e) amateur(trice) éclairé(e).
        </Paragrapth>
      </MarginContainer>

      <Array
        readOnly={props.readOnly}
        listArray={arrayTextField}
        handleChangeArray={handleChangeArrayState}
        current={accessYourCulturalCApitalMobilizable?.array}
      />

      <MarginContainer>

        {lists.map((item: any) => (

          <>
          
            <Paragrapth align="left" colorBlue={true}>
              <span style={{ fontSize: 15 }}>{item.title}</span>
            </Paragrapth>

            <MarginContainer>
              <TextArea
                onChange={handleChange}
                name={item.name}
                value={accessYourCulturalCApitalMobilizable?.[item.name] || ""}
                largeTextField={true}
                readOnly={props.readOnly}
              />
            </MarginContainer>

          </>

        ))}

      </MarginContainer>

    </Page>
  );
};

export default Page26;
