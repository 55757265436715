import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  floatLeft: {
    float: 'left',
    borderLeft: '1px solid #aaa',
    height: '100%',
  },
  coulomContent: {
    width: '100%',
  },
  header: {
    minWidth: '10vh',
    padding: '0 1.5vh',
    height: '72.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '15px',
    paddingLeft: '2vh',
    color: 'white',
    textTransform: 'uppercase',
  },
  content: {
    width: '100%',
    height: '45.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '15px',
    color: '#0E1247',
  },
  button: {
    padding: '12px 20px',
    border: '1px solid #CDCEDA',
    borderRadius: '4px',
  },
  link: {
    color: 'rgba(14, 18, 71, 1)',
    textDecoration: 'underline',
  },
});
