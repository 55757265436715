// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: 15,
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '80%',
    },
    textInputContainer: {
      width: '100%',
    },
    customTextField: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      width: '100%',
      '& .MuiOutlinedInput-root': {
        border: '1px solid #ccc',
        '&:hover': {
          border: '1px solid #ccc',
        },
      },
    },
    questionFormTitle: {
      fontFamily: 'Poppins',
      fontSize: 22,
      fontStyle: 'normal',
      fontWeight: 700,
      color: theme.palette.primary.light,
    },
    error: {
      '& .MuiOutlinedInput-root': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },
    responseError: {
      color: `${theme.palette.error.main}`,
    },
  })
);
