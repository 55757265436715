import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  btnPrimary: {
    minHeight: 48,
    width: 190,
    textTransform: 'none',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    marginTop: 5,
    marginLeft: 500,
  },
 
  divider: {
    marginTop: 75,
  },
  content: {
    paddingBottom: '100px'
  }
}));
