/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, TitleChapitre } from '@orientaction/components';
import clsx from 'clsx';

const Page07 = (props: any) => {
  
  const classes = useStyles();
  return (
    <Page id={7}>
    <TitleChapitre nbr={1} />
      <div style={{ marginTop: '50px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            className="opacity07">
            VALIDEZ VOTRE PROJET (PLAN A) <br />
            ET ÉLABOREZ VOTRE PLAN D’ACTION
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      </div>
      <div>
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
                Vous arrivez maintenant au terme de votre démarche. Le résultat : le choix d’un 
                projet d’évolution professionnelle et la mise en œuvre d’actions concrètes visant à 
                le réaliser. Le temps de la réflexion se termine. C’est maintenant le temps de l’action ! 
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                marginTop: 10,
                fontWeight: 'lighter',
              }}>
                Vous rentrez dans le concret ! Évidemment, c’est un peu plus effrayant mais plus 
                excitant aussi. 
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                marginTop: 10,
                fontWeight: 'lighter',
              }}>
                Avant de décider, nous allons rappeler quelques principes de base afin de vous 
                aider à bien choisir votre projet ; un « dernier tour de table », avant de prendre votre 
                décision finale. 
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '30px' }}>
        <TitleNbr
          number={1}
          text="UN PROJET QUI RÉPOND À VOS ASPIRATIONS…"
          isLight={true}
        />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
                Vous devez choisir une activité professionnelle qui vous rendra heureux(se). 
                Heureux(se) parce qu’elle a du sens, parce qu’elle répond à vos besoins les plus 
                essentiels et parce qu’elle correspond à votre personnalité profonde. Aux étapes 1 
                et 2 de votre démarche, vous avez pu analyser précisément ces différents points.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
                Vous avez identifié vos valeurs, priorisé vos besoins et mis au jour votre personnalité. 
                Attention, un projet, sauf à de rares exceptions, ne pourra jamais dans l’immédiat 
                répondre à toutes vos aspirations. 
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '30px' }}>
        <TitleNbr
          number={2}
          text="…ET QUI VOUS PERMET D’EXPLOITER VOS TALENTS"
          isLight={true}
        />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
                Un bon projet, c’est un projet qui s’appuie sur « l’arbre de vos compétences ».
                C’est un projet qui vous permet de faire ce que vous aimez, qui répond à vos 
                aspirations et qui mobilise vos qualités et vos talents naturels. C’est un projet qui 
                vous rendra heureux(se) et performant(e).
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
                Car on ne peut pas être réellement heureux(se) dans un métier dans lequel on ne 
                réussit pas. C’est en réussissant, en apportant une vraie valeur ajoutée, que l’on 
                peut être utile et se sentir utile aux autres.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.7,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page07;
