/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setListMajorTest = (data: any[]): AppThunk => dispatch => {
    dispatch({
        type: 'LISTMAJORTEST',
        payload: data,
    });
};

export const setLoading = (data: boolean): AppThunk => dispatch => {
    dispatch({
        type: 'LOADING',
        payload: data,
    });
};


