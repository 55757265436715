import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import OaDivider from '@orientaction/commons/OaDivider';
import { Fragment } from 'react';
import Item from './Item';
import style from './style';

const CardContent = ({ responses }: any) => {
  const classes = style();
  return (
    <Card className={classes.cardContainer} variant="outlined">
      <Grid container={true} spacing={2}>
        <Grid
          className={classes.accordionResponseSectionTitleContainer}
          item={true}
          xl={12}
          sm={12}
          md={12}>
          <Typography className={classes.accordionResponseSectionTitle} component="h1">
            Réponses
          </Typography>
        </Grid>
        {responses.map((response: any) => (
          <Fragment key={response.id}>
            <OaDivider overrideDividerClassname={classes.overrideDividerClassname} />
            <Item key={response.id} response={response} />
          </Fragment>
        ))}
      </Grid>
    </Card>
  );
};

export default CardContent;
