import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    backgroundColor: '#EDEEF2',
    height: 50,
    color: '#0E1247',
    width: '75%',
  },
}));
