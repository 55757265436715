/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable simple-import-sort/imports */
/* eslint-disable no-use-before-define */
import { FormControl, Grid, IconButton, MenuItem, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import TextEditorChoice from '@orientaction/components/EditorChoice/indext';
import React, { useCallback, useEffect, useState } from 'react';
import './index.css';

type ChoiceItemProps = {
  id: number;
  text: string;
  destination: string;
  destinationUrl: string;
  name: string;
  handleChange: (id: number, field: string, value: string) => void;
  handleRemove: (id: number) => void;
  listPages: any;
  defaultValue?: any;
  index: any;
  isRedirection?: any;
  isCarre?: any;
  typeChoice?: any;
  canDrag: (data: any) => void;
  typeGame?: string;
  selectedVariable?: string;
};

const ChoiceItem: React.FC<ChoiceItemProps> = React.memo(
  ({
    id,
    text,
    destination,
    destinationUrl,
    name,
    handleChange,
    handleRemove,
    listPages,
    defaultValue = '',
    index,
    isRedirection,
    isCarre,
    typeChoice,
    canDrag,
    typeGame,
    selectedVariable,
  }) => {
    const [contentx, setContent] = useState<any>(defaultValue || '');
    const [dataList, setDataList] = useState<any[]>([]);
    const [variables, setVariables] = useState<any[]>([]);

    useEffect(() => {
      handleChange(id, 'text', contentx);
    }, [contentx]);

    useEffect(() => {
      const storedData = sessionStorage.getItem('gamePages');
      const pageIdString = sessionStorage.getItem('page_id');
      const gameVariables = sessionStorage.getItem('gameVariables');
      const pageId = pageIdString ? parseInt(pageIdString, 10) : 0;
      console.log('pageId', pageId);
      if (storedData) {
        const gamePages = JSON.parse(storedData).map((page: any, indexx: any) => ({
          ...page,
          title: page.title || `Page ${indexx + 1}`,
        }));

        setDataList(gamePages.filter((page: any) => page.id !== pageId));
        setVariables(JSON.parse(gameVariables || ''));
      }
    }, []);

    const truncate = useCallback((str: string, maxLength: number) => {
      return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
    }, []);

    return (
      <div>
        <Grid container={true} spacing={3} alignItems="center">
          <Grid
            item={true}
            sm={4}
            onMouseEnter={() => canDrag(true)}
            onMouseLeave={() => canDrag(false)}>
            <p className="draginput">
              <DragIndicatorIcon style={{ cursor: 'move' }} />
              {!isCarre ? (
                <span>{typeChoice !== 'pursue' ? `Choix ${index + 1}` : 'Poursuivre'} </span>
              ) : (
                <>
                  <div className={`inputContainer ${name?.length > 0 ? 'filled' : ''}`}>
                    <input
                      type="text"
                      className="input"
                      value={name || ''}
                      placeholder="Titre"
                      maxLength={4}
                      onChange={e => {
                        handleChange(id, 'name', e.target.value as string);
                      }}
                    />
                  </div>
                </>
              )}
            </p>
          </Grid>
          <Grid item={true} sm={7}>
            <FormControl fullWidth={true} variant="outlined" margin="normal">
              {!isRedirection ? (
                <>
                  <Select
                    value={destination || ''}
                    onChange={e => {
                      handleChange(id, 'destination', e.target.value as string);
                    }}
                    displayEmpty={true}>
                    <MenuItem value="" disabled={true}>
                      Sélectionner une destination
                    </MenuItem>
                    <MenuItem value={-1}>Couverture du jeu</MenuItem>
                    {dataList?.map((page: any, idx: number) => (
                      <MenuItem
                        key={page?.id}
                        value={page?.id}
                        style={{
                          color: destination === page?.id ? '#000A8C' : 'inherit',
                        }}>
                        {page?.title ? truncate(page?.title, 20) : `Page ${idx + 1}`}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              ) : (
                <FormControl fullWidth={true} variant="outlined" margin="normal">
                  <TextField
                    value={destinationUrl}
                    onChange={e => handleChange(id, 'destinationUrl', e.target.value as string)}
                    placeholder="Sélectionner une destination"
                    variant="outlined"
                  />
                </FormControl>
              )}
            </FormControl>
            {typeGame === 'game' && (
              <FormControl fullWidth={true} variant="outlined" margin="normal">
                <Select
                  style={{ marginTop: 30 }}
                  value={selectedVariable || ''}
                  onChange={e => {
                    handleChange(id, 'selectedVariable', e.target.value as string);
                  }}
                  displayEmpty={true}>
                  <MenuItem value="">Aucune variable</MenuItem>
                  {variables?.map((variable: any, idx: number) => (
                    <MenuItem
                      key={variable?.id}
                      value={variable?.id}
                      style={{
                        color: selectedVariable === variable?.id ? '#000A8C' : 'inherit',
                      }}>
                      {variable?.name ? truncate(variable?.name, 20) : `Variable ${idx + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item={true} sm={1}>
            <IconButton color="secondary" onClick={() => handleRemove(id)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
          {!isCarre && (
            <Grid item={true} sm={12} onMouseEnter={() => canDrag(false)}>
              <TextField
                id="outlined-multiline-static"
                label="Multiline"
                multiline={true}
                value={contentx}
                variant="outlined"
                style={{ display: 'none' }}
                onChange={(event: any) => {
                  handleChange(id, 'text', event.target.value);
                  setContent(event.target.value);
                }}
              />

              <TextEditorChoice
                isSmall={true}
                styleEditor={null}
                defaultValue={defaultValue}
                onChange={content => {
                  handleChange(id, 'text', content);
                  setContent(content);
                }}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.text === nextProps.text &&
      prevProps.destination === nextProps.destination &&
      prevProps.destinationUrl === nextProps.destinationUrl &&
      prevProps.name === nextProps.name &&
      prevProps.listPages === nextProps.listPages &&
      prevProps.defaultValue === nextProps.defaultValue &&
      prevProps.index === nextProps.index &&
      prevProps.isRedirection === nextProps.isRedirection &&
      prevProps.isCarre === nextProps.isCarre &&
      prevProps.typeChoice === nextProps.typeChoice &&
      prevProps.selectedVariable === nextProps.selectedVariable
    );
  }
);

export default ChoiceItem;
