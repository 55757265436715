// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../components/Image';
import useStyles from './style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page15 = () => {
  const classes = useStyles();

  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_roots.png' : 'roots.png';
  }, [language]);

  return (
    <Page id={15}>
      <TitleChapitre nbr={1} />

      <TitlePage>
        LES 4 CAPITAUX
        <br />
        (LES RACINES)
      </TitlePage>
      <br />
      <br />
      <Paragrapth align="justify">
        Pour réussir dans la vie et atteindre nos objectifs, il existe 4 ressources essentielles :
        le capital économique, le capital culturel, le capital social et le capital symbolique.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Ces 4 capitaux, nous en héritons une partie de nos parents et de notre famille, le reste
        nous l’accumulons tout au long de notre vie, au travers de nos expériences, de nos
        rencontres, de nos succès et de nos échecs.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Ces 4 capitaux, c’est notre capital chance. Il revient à nous de l’utiliser de façon
        intelligente et pertinente pour réussir notre projet d’évolution professionnelle.
      </Paragrapth>
      <MarginContainer>
        <Img filename={image} alt="roots" className={classes.img} />
      </MarginContainer>
    </Page>
  );
};

export default Page15;
