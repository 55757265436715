import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  dropzoneContainer: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  inputError: {
    border: `2px solid red`,
  },
}));
