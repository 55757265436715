/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { LayoutResultat } from '@orientaction/layouts';
import { ResultDetail, Timeline, UserResult } from '@orientaction/components';
import useResultsClient from '../../hooks/useResultsClient';
import { UserResultRadarGraph } from '../../components/UserResult/UserResultRadarGraph';
import { CircularProgress } from '@material-ui/core';
import localText from '../../components/UserResult/WithResult/locale.json';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import clsx from 'clsx';
import useResponseUser from '../../hooks/useResponseUser';
import useEquilityVariable from '../../hooks/useEqualityVariable';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getFromLS } from '../../utils/utils';
import dateFormat from 'dateformat';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';
import { backgroundChart, publicSvgPath } from '../../utils/constants';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import trim from 'lodash/trim';
import Button from '@material-ui/core/Button';
import truncate from 'lodash/truncate';
import maxBy from 'lodash/maxBy';
import Hidden from '@material-ui/core/Hidden';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { useDispatch, useSelector } from 'react-redux';
import { ApiActions } from '@orientaction/api-actions';
import { NoResult } from '../../components/UserResult/NoResult';

Chart.register(ChartDataLabels);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    btnPrimary: {
      paddingLeft: 20,
      paddingRight: 20,
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      '@media(min-width: 1250px)': {
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 146,
      },
      '@media only screen and (max-width: 600px)': {
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 5,
      },
      '@media only screen and (max-width: 361px)': {
        marginTop: 0,
      },
      display: 'block',
      marginTop: 30,
      width: '100%',
      textTransform: 'none',
    },
    paddingY: {
      paddingTop: 30,
      paddingBottom: 30,
      paddingLeft: 20,
      '@media only screen and (max-width: 600px)': {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 5,
      },
    },
    flexCenterY: {
      display: 'flex',
      alignItems: 'center',
    },
    bodycenter: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    CardWhite: {
      width: 'auto',
      height: 'auto',
      borderRadius: '20px',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      paddingBottom: 40,
      background: 'white',
    },
    marginBottomTypo: {
      marginBottom: 40,
      fontSize: 18,
    },
    paddingX: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 50,
    },
    rootGradient: {
      backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern2.png) !important`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      '@media only screen and (max-width: 600px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern_mobile.png)  !important`,
        backgroundPositionY: 0,
      },
      '@media only screen and (max-width: 361px)': {
        backgroundImage: `url(${publicSvgPath}/backgroundBtn/pattern_mobile.png)  !important`,
        backgroundPositionY: 0,
      },
    },
    rootTransaprent: {
      background: 'transparent',
    },
    textHeader: {
      display: 'block',
      '@media only screen and (max-width: 600px)': {
        fontSize: 10,
      },
    },
    paddingContent: {
      background: `linear-gradient(0deg, rgba(0, 10, 140, 0.1), rgba(0, 10, 140, 0.1)), #FFFFFF;`,
      '@media only screen and (max-width: 600px)': {
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
      },
      '@media only screen and (max-width: 361px)': {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    text: {
      width: '100%',
      fontFamily: 'Poppins',
      textAlign: 'left',
      color: '#FFFFFF',
      fontSize: '15px',
      marginTop: '0px',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
        marginBottom: 5,
        fontSize: '13px',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
        marginBottom: 5,
        fontSize: '10px',
      },
    },
    title: {
      textAlign: 'center',
      color: '#FFFFFF',
      fontFamily: 'Poppins',
      '@media only screen and (max-width: 600px)': {
        fontSize: '20px',
        marginTop: 10,
        marginBottom: 5,
      },
      '@media only screen and (max-width: 361px)': {
        fontSize: '17px',
        marginTop: 0,
        marginBottom: 0,
      },
    },
    img: {
      width: 275,
      height: 275,
      borderRadius: '10px',
      '@media only screen and (max-width: 600px)': {
        width: 125,
        height: 125,
      },
      '@media only screen and (max-width: 361px)': {
        width: 100,
        height: 100,
      },
    },
    textH1: {
      '@media only screen and (max-width: 600px)': {
        fontSize: 25,
      },
    },
    noPaddingMobile: {
      '@media only screen and (max-width: 600px)': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    textH3: {
      width: '100%',
      color: 'white',
      fontFamily: 'Poppins',
      marginBottom: 30,
      textAlign: 'left',
      fontSize: '20px',
      textTransform: 'none',
      '@media only screen and (max-width: 600px)': {
        fontSize: '15px',
        marginBottom: 10,
        marginTop: 45,
      },
    },
    pie: {
      width: 300,
      height: 300,
      '@media only screen and (max-width: 600px)': {
        width: 250,
        height: 250,
      },
    },
    textH2: {
      width: '100%',
      color: 'white',
      fontFamily: 'Poppins',
      marginBottom: 5,
      textAlign: 'center',
      fontSize: '28px',
      textTransform: 'none',
      '@media only screen and (max-width: 600px)': {
        fontSize: '24px',
        marginBottom: 5,
        marginTop: 40,
      },
    },
    flexData: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media only screen and (max-width: 600px)': {
        justifyContent: 'flex-start',
      },
    },
  })
);

const AdminUserHarmonyResult = (props: WithWidth) => {
  const classes = useStyles();
  const { results, resultToShow, onSelectPeriod, loading } = useResultsClient('email@test.com');
  const hasResult = results.length > 0;
  const [isSoftSkills, setIsSoftSkills] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataMax, setDataMax] = useState<any>();
  const { width } = props;

  const searchIndexToBreak = (words: any, maxValue: number) => {
    let i = 0;
    let sommeWords = 0;
    for (i; sommeWords < maxValue; i++) {
      sommeWords += words[i]?.length;
    }
    return i;
  };
  const formaterLabels = (value: any, ctx: any) => {
    const words = data[ctx.dataIndex].label.split(' ');
    const max = 28;
    if (width === 'xs' && data[ctx.dataIndex].label.length > max && dataMax.value < 100) {
      const index = searchIndexToBreak(words, max) - 1;
      if (words.slice(index).length === 0) {
        return words.slice(0, index).join(' ');
      }
      return [words.slice(0, index).join(' '), words.slice(index).join(' ')];
    }
    return data[ctx.dataIndex].label;
  };

  return (
    <LayoutResultat isaddBackground={true} showLogo={width === 'xs'} noPadding={true}>
      <div className={classes.noPaddingMobile}>
        <Grid container={true}>
          <Grid item={true} xs={6}>
            <Hidden only={['sm', 'lg', 'xl']}>
              <Timeline testResults={results} onChange={onSelectPeriod} />
            </Hidden>
          </Grid>
        </Grid>
        <Grid
          container={true}
          className={clsx(isSoftSkills ? classes.rootTransaprent : classes.rootGradient)}>
          <Grid item={true}>
            <Hidden xsDown={true}>
              <Timeline testResults={results} onChange={onSelectPeriod} />
            </Hidden>
          </Grid>
          {loading && (
            <div className={classes.bodycenter}>
              <CircularProgress color="inherit" />
            </div>
          )}
          {!hasResult && !loading && (
            <Grid item={true} md={true} style={{ padding: 35 }} className={classes.paddingContent}>
              <NoResult />
            </Grid>
          )}
          {hasResult && !loading && (
            <Grid item={true} md={true} style={{ padding: 35 }} className={classes.paddingContent}>
              <Typography variant="h2" className={classes.marginBottomTypo}>
                Vos résultats - Test Harmony 16 soft skills{' '}
              </Typography>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={7}>
                  <div className={classes.CardWhite}>
                    <UserResultRadarGraph resultToShow={resultToShow} />
                  </div>
                </Grid>
                <Grid item={true} xs={5}>
                  <div className={classes.paddingX}>
                    <ResultDetail
                      imageSrc={localText.problem_solving.imageSrc}
                      title={localText.problem_solving.title}
                      color={localText.problem_solving.color}
                      note={resultToShow?.attributes.problem_solving || 0}
                      description={localText.problem_solving.description}
                      decisiveProfessions={localText.problem_solving.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.confidence.imageSrc}
                      title={localText.confidence.title}
                      color={localText.confidence.color}
                      note={resultToShow?.attributes.confidence || 0}
                      description={localText.confidence.description}
                      decisiveProfessions={localText.confidence.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.emotional_intelligence.imageSrc}
                      title={localText.emotional_intelligence.title}
                      color={localText.emotional_intelligence.color}
                      note={resultToShow?.attributes.emotional_intelligence || 0}
                      description={localText.emotional_intelligence.description}
                      decisiveProfessions={localText.emotional_intelligence.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.empathy.imageSrc}
                      title={localText.empathy.title}
                      color={localText.empathy.color}
                      note={resultToShow?.attributes.empathy || 0}
                      description={localText.empathy.description}
                      decisiveProfessions={localText.empathy.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.communication.imageSrc}
                      title={localText.communication.title}
                      color={localText.communication.color}
                      note={resultToShow?.attributes.communication || 0}
                      description={localText.communication.description}
                      decisiveProfessions={localText.communication.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.time_management.imageSrc}
                      title={localText.time_management.title}
                      color={localText.time_management.color}
                      note={resultToShow?.attributes.time_management || 0}
                      description={localText.time_management.description}
                      decisiveProfessions={localText.time_management.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.stress_management.imageSrc}
                      title={localText.stress_management.title}
                      color={localText.stress_management.color}
                      note={resultToShow?.attributes.stress_management || 0}
                      description={localText.stress_management.description}
                      decisiveProfessions={localText.stress_management.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.creativity.imageSrc}
                      title={localText.creativity.title}
                      color={localText.creativity.color}
                      note={resultToShow?.attributes.creativity || 0}
                      description={localText.creativity.description}
                      decisiveProfessions={localText.creativity.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.entrepreneurial_spirit.imageSrc}
                      title={localText.entrepreneurial_spirit.title}
                      color={localText.entrepreneurial_spirit.color}
                      note={resultToShow?.attributes.entrepreneurial_spirit || 0}
                      description={localText.entrepreneurial_spirit.description}
                      decisiveProfessions={localText.entrepreneurial_spirit.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.daring.imageSrc}
                      title={localText.daring.title}
                      color={localText.daring.color}
                      note={resultToShow?.attributes.daring || 0}
                      description={localText.daring.description}
                      decisiveProfessions={localText.daring.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.motivation.imageSrc}
                      title={localText.motivation.title}
                      color={localText.motivation.color}
                      note={resultToShow?.attributes.motivation || 0}
                      description={localText.motivation.description}
                      decisiveProfessions={localText.motivation.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.vision.imageSrc}
                      title={localText.vision.title}
                      color={localText.vision.color}
                      note={resultToShow?.attributes.vision || 0}
                      description={localText.vision.description}
                      decisiveProfessions={localText.vision.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.presence.imageSrc}
                      title={localText.presence.title}
                      color={localText.presence.color}
                      note={resultToShow?.attributes.presence || 0}
                      description={localText.presence.description}
                      decisiveProfessions={localText.presence.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.collective_sense.imageSrc}
                      title={localText.collective_sense.title}
                      color={localText.collective_sense.color}
                      note={resultToShow?.attributes.collective_sense || 0}
                      description={localText.collective_sense.description}
                      decisiveProfessions={localText.collective_sense.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.curiousity.imageSrc}
                      title={localText.curiousity.title}
                      color={localText.curiousity.color}
                      note={resultToShow?.attributes.curiousity || 0}
                      description={localText.curiousity.description}
                      decisiveProfessions={localText.curiousity.decisiveProfessions}
                    />
                    <ResultDetail
                      imageSrc={localText.critical_mind.imageSrc}
                      title={localText.critical_mind.title}
                      color={localText.critical_mind.color}
                      note={resultToShow?.attributes.critical_mind || 0}
                      description={localText.critical_mind.description}
                      decisiveProfessions={localText.critical_mind.decisiveProfessions}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </LayoutResultat>
  );
};

export default withWidth()(AdminUserHarmonyResult);
