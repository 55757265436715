/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
export const studyTableHeaderBgColor = '#662169';

export const studyStandardColumns = [
  {
    type: 'normal',
    header: [
      {
        label: 'Date',
        key: 'attributes.createdAt',
        option: {
          type: 'date',
          formatedDate: true,
          format: 'DD/MM/YYYY',
        },
      },
      {
        label: 'Prénom',
        key: 'attributes.users_permissions_user.firstname',
      },
      {
        label: 'Nom',
        key: 'attributes.users_permissions_user.lastname',
      },
      {
        label: 'Sexe',
        key: 'attributes.users_permissions_user.gender',
      },
      {
        label: 'Âge',
        key: 'attributes.users_permissions_user.dayOfBirth',
        option: {
          type: 'date',
          formatedDate: true,
        },
      },
      {
        label: 'Diplôme',
        key: 'attributes.users_permissions_user.diploma',
      },
      {
        label: 'CP',
        key: 'attributes.users_permissions_user.postal_code',
      },
      {
        label: 'Email',
        key: 'attributes.users_permissions_user.email',
      },
      {
        label: 'téléphone',
        key: 'attributes.users_permissions_user.phone',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Compétiteur',
        key: 'attributes.study_competitor',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Épicurien',
        key: 'attributes.study_epicurean',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Libre-penseur',
        key: 'attributes.study_freethinker',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Théoricien',
        key: 'attributes.study_theorist',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Réaliste',
        key: 'attributes.study_realistic',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Entreprenant',
        key: 'attributes.study_enterprising',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Com./Éco.',
        key: 'attributes.love_trade_economy',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Art/Com.',
        key: 'attributes.love_arts_communication',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Droit/Sc. pol.',
        key: 'attributes.love_law_political_science',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Science/Tech',
        key: 'attributes.love_science_technology',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Santé/Soins',
        key: 'attributes.love_health_care',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Lettre/Huma.',
        key: 'attributes.love_letter_humanities',
      },
    ],
  },
  {
    type: 'action',
    withBorder: true,
    header: [
      {
        label: 'Actions',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['delete'],
        },
      },
    ],
  },
];
