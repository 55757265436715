// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import { MarginContainer } from '@orientaction/commons';
import {
  TitlePage,
  Page,
  TitleChapitre,
  Paragrapth,
  Title,
  ListParagrapth,
} from '@orientaction/components';
import Img from '../../components/Image';
import useStyles from './style';
import SimpleBanner from '../../../components/SimpleBanner';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const Page37 = () => {
  const classes = useStyles();
  return (
    <Page id={37}>
      <TitlePage showSpanYellow={false}>
        QUELS SONT VOS 3 SAVOIRS
        <br />
        (LES BRANCHES) ?
      </TitlePage>
      <Title
        title=""
        firstTitle="‘‘ La confiance en soi ne remplace pas la compétence. ’’"
        firtsecondTitle="Olivier Lockert"
        secondTitle="‘‘Peu de choses sont impossibles à qui est assidu
        et compétent... Les grandes œuvres jaillissent non de la force
        mais de la persévérance.  ’’"
        secondSousTitle="Samuel Johnson"
      />
      <MarginContainer>
        <Paragrapth align="justify">
          Les diplômes sont un élément essentiel dans le positionnement professionnel d’une
          personne. Ils lui confèrent une crédibilité autant qu’un niveau de connaissances et de
          savoir-faire. Ils positionnent la personne sur une échelle de valeurs :
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <ListParagrapth
          overrideParagrapthListStyle={classes.overrideParagrapthListStyle}
          componentType="p"
          list={[
            `On attribue à une personne un certain niveau de connaissances et de compétences
            dans une discipline donnée.`,
            `On attribue à une personne un certain statut. Le diplôme permet de prétendre à un
            certain niveau de responsabilité.`,
          ]}
        />
      </MarginContainer>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          <Paragrapth align="center" colorBlue={true} bold={true} addBackground={true}>
            La cote d’un diplôme pourra ouvrir de nombreuses portes
          </Paragrapth>
          <Paragrapth align="center" colorBlue={true} bold={true} addBackground={true}>
            ou au contraire en fermer.
          </Paragrapth>
        </MarginContainer>
      </div>
      <MarginContainer>
        <div className={classes.cardBgGrey} style={{ fontStyle: 'italic' }}>
          <MarginContainer>
            <Grid container={true} justifyContent="center" alignItems="center">
              <Grid item={true} xs={12} md={12}>
                {/* <Img
                  filename="comptable.png"
                  alt="comptable"
                  style={{
                    width: '80%',
                    height: 'auto',
                    display: 'block',
                    margin: 'auto',
                  }}
                /> */}
                <SimpleBanner>
                  <Typography
                    component="h1"
                    className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                  >
                    LE COMPTABLE PRISONNIER
                  </Typography>
                </SimpleBanner>
              </Grid>

              <Grid item={true} xs={12} md={12}>
                <Img filename="computer.png" alt="computer" className={classes.img} />
              </Grid>

              <Grid item={true} xs={12} md={12}>
                <MarginContainer>
                  <Paragrapth align="justify" addBackground={true}>
                    Julien a eu un parcours scolaire tumultueux. Il a fait 6 ans de lycée avant
                    d’obtenir son bac. C’est un garçon brillant, intelligent, mais qui refuse de
                    travailler et de grandir. Après avoir obtenu son bac, il est rentré en BTS
                    comptabilité dans une école offrant des enseignements professionnels très
                    pratiques. Son diplôme en poche, il a été embauché par l’entreprise qui l’avait
                    accueilli durant son apprentissage. Grâce au soutien du directeur de
                    l’entreprise, son mentor, Julien monte les échelons. Il devient collaborateur
                    comptable puis chef de groupe, un poste rarement occupé par une personne n’ayant
                    pas le titre d’expert-comptable dans un cabinet, puis enfin collaborateur
                    associé.
                  </Paragrapth>
                </MarginContainer>
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <MarginContainer>
                  <Paragrapth align="justify" addBackground={true}>
                    Julien est fier de sa réussite qu’il doit à ses efforts et à son engagement. Le
                    problème de Julien est que son entreprise est en train, sans qu’il ne s’en rende
                    compte, de se transformer en prison. Que fera Julien quand son directeur partira
                    à la retraite ? Ce qui va effectivement lui arriver dans les deux ans qui
                    viennent. Il ne pourra pas trouver d’entreprise facilement, car son niveau
                    scolaire sera en décalage avec son travail réel.
                  </Paragrapth>
                </MarginContainer>
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <MarginContainer>
                  <Paragrapth align="justify" addBackground={true}>
                    Sur le marché du travail, il sera en compétition avec des personnes diplômées.
                    Son profil est attractif, mais uniquement à l’intérieur de son entreprise
                    actuelle. En dehors, le profil de Julien n’est pas attractif.
                  </Paragrapth>
                </MarginContainer>
              </Grid>
            </Grid>
          </MarginContainer>
        </div>
        <MarginContainer>
          <Paragrapth align="justify">
            L’outil qui va suivre va vous permettre d’identifier vos savoirs reconnus et validés par
            des diplômes, des qualifications ou des certificats de compétences. Il vous permettra
            surtout de mettre en avant les points de différences et d’équivalences de ces savoirs
            avec les autres savoirs.
          </Paragrapth>
        </MarginContainer>
        <MarginContainer>
          <Paragrapth align="justify">
            Chaque diplôme, chaque parcours de formation, est différent des autres. Savoir expliquer
            ses différences est un moyen sûr de vous faire remarquer lors d’un entretien de
            recrutement. Cela montre que vous avez choisi une formation en fonction d’un objectif
            précis.
          </Paragrapth>
        </MarginContainer>
      </MarginContainer>
    </Page>
  );
};

export default Page37;
