/* eslint-disable react/destructuring-assignment */
import ArtsCommunicationSuggestion from './ArtsCommunicationSuggestion';
import HealthCareSuggestion from './HealthCareSuggestion';
import LawPoliticalScienceSuggestion from './LawPoliticalScienceSuggestion';
import LetterHumanitiesSuggestion from './LetterHumanitiesSuggestion';
import ScienceTechnologySuggestion from './ScienceTechnologySuggestion';
import TradeEconomySuggestion from './TradeEconomySuggestion';

const Suggestion = (props: any) => {
  const key = props?.data?.favorite?.key;
  return (
    <>
      {key === 'love_science_technology' && <ScienceTechnologySuggestion />}
      {key === 'love_trade_economy' && <TradeEconomySuggestion />}
      {key === 'love_law_political_science' && <LawPoliticalScienceSuggestion />}
      {key === 'love_arts_communication' && <ArtsCommunicationSuggestion />}
      {key === 'love_health_care' && <HealthCareSuggestion />}
      {key === 'love_letter_humanities' && <LetterHumanitiesSuggestion />}
    </>
  );
};

export default Suggestion;
