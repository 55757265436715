/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleChapitre, Page } from '@orientaction/components';
import { useState } from 'react';

const Page11 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={11}>
      <TitleChapitre nbr={2} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className={classes.colorPrimaryBook}>
            QUELS SONT VOS BESOINS ? <br />
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <div>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'justify', fontWeight: 'lighter' }}>
            Généralement, quand je demande à une personne qui a envie de changer de vie 
            quels sont ses besoins, elle marque tout de suite un temps d’arrêt.
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Elle sait qu’elle a besoin de quelque chose, de quelque chose de différent.
            Sinon pourquoi serait-elle assise devant moi dans ce bureau ?
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Mais elle ne sait pas de quoi précisément. Les exercices qui vont suivre vont vous 
            aider à préciser vos besoins, d’abord en explorant vos besoins matériels, ensuite 
            vos besoins relationnels. Puis en regardant dans quels domaines de vie ces besoins 
            pourront être le mieux satisfaits.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={8}>
            <img className={classes.img} src={`${imgBook2}/illustration_page_17.png`} alt="logo" />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    },
    colorPrimaryBook: {
      color: "#2b56a5"
    }
  })
);
export default Page11;
