import { Typography } from '@material-ui/core';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import style from './style';

const Title = () => {
  const {
    test: { pageTitle },
  } = useContext<any>(AdminTestContext);
  const classes = style();

  return (
    <Typography className={classes.createTestPageTitle} component="h1" align="left">
      {pageTitle}
    </Typography>
  );
};

export default Title;
