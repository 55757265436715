/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import { isBefore } from 'date-fns';
import { jwtDecode, JwtPayload } from 'jwt-decode';

const majorTestIds = [1, 2, 3, 4, 5, 6, 7];

const isNumericColumn = (columnName: string) => {
  return (
    resultBackendNumericColumn.includes(columnName) || userBackendNumericColumn.includes(columnName)
  );
};

const tranformOperator = (operator: string, columnName: string) => {
  let op;
  switch (operator) {
    case '=':
      op = isNumericColumn(columnName) ? 'eq' : 'contains';
      break;
    case '<':
      op = 'lt';
      break;
    case '>':
      op = 'gt';
      break;
    case '≠':
      op = isNumericColumn(columnName) ? 'ne' : 'notContainsi';
      break;
    default:
      break;
  }
  return op;
};

const usersColumn = [
  'firstname',
  'lastname',
  'gender',
  'age',
  'diploma',
  'email',
  'phone',
  'postal_code',
];

const columnToTransform = ['gender', 'opt_in', 'bc', 'realization_date', 'diploma'];

const userBackendStringColumnWithFilterType = [
  'firstname',
  'lastname',
  'gender',
  'diploma',
  'email',
  'phone',
  'postal_code',
];

const userBackendStringColumn = [
  'firstname',
  'lastname',
  'gender',
  'diploma',
  'email',
  'phone',
  'postal_code',
];

const resultBackendStringColumnWithFilterType = ['realization_period'];

const resultBackendNumericColumnWitFilterType = [
  'critical_mind',
  'curiousity',
  'collective_sense',
  'presence',
  'vision',
  'motivation',
  'daring',
  'entrepreneurial_spirit',
  'creativity',
  'stress_management',
  'time_management',
  'communication',
  'empathy',
  'emotional_intelligence',
  'confidence',
  'problem_solving',
  'bc',
];

const resultBackendNumericColumn = [
  'critical_mind',
  'curiousity',
  'collective_sense',
  'presence',
  'vision',
  'motivation',
  'daring',
  'entrepreneurial_spirit',
  'creativity',
  'stress_management',
  'time_management',
  'communication',
  'empathy_eq',
  'emotional_intelligence',
  'confidence',
  'problem_solving',
  'score',
  'bc',
  'realization_date',
  'opt_in',
  'time_spent',
];

const userBackendNumericColumnWitFilterType = ['age'];

const userBackendNumericColumn = ['age'];

export const getHeaders = (authToken?: any, isFormData?: boolean) => {
  const headers: any = {
    'Content-Type': !isFormData ? 'application/json' : 'multipart/form-data',
  };
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  return headers;
};

export const makeRequest = async (url: string, method: string, body?: any, headers?: any) => {
  const options: any = {
    method,
    url,
  };

  if (body) {
    options.data = body;
  }

  if (headers) {
    options.headers = headers;
  }
  console.log({options});

  try {
    return await axios(options);
  } catch (error: any) {
    if (error.message.includes('401')) {
      window.location.replace('/');
    }

    throw error;
  }
};

export const writetoLS = async (key: string, data: string) => {
  await window.localStorage.setItem(key, data);
};

export const getFromLS = (key: string) => window.localStorage.getItem(key);

export const removeLS = async (data: any) => {
  Object.keys(data).forEach(async (key: string) => {
    window.localStorage.removeItem(key);
  });
};

export const updateUserInfoInLS = (infos: any) => {
  const lsInfos: any = window.localStorage.getItem('userInfos');
  const currentUserInfos = JSON.parse(lsInfos);
  const infosUpdated = JSON.stringify({ ...currentUserInfos, ...infos });
  window.localStorage.setItem('userInfos', infosUpdated);
};

export const isNumeric = (value: any) => /^\d+$/.test(value);

export const getUserOs = () => {
  if (navigator.appVersion.indexOf('Win') !== -1) {
    return 'windows';
  }
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return 'macos';
  }
  if (navigator.appVersion.indexOf('X11') !== -1) {
    return 'unix';
  }
  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return 'linux';
  }
  return 'unknown';
};

export const transformColumnValue = (columnName: any, value: any) => {
  if (columnName === 'gender') {
    let newValue = 'autre';
    if (value.toLowerCase().startsWith('hom')) {
      newValue = 'homme';
      return newValue;
    }
    if (value.toLowerCase().startsWith('fem')) {
      newValue = 'femme';
      return newValue;
    }
    return newValue;
  }
  if (columnName === 'bc') {
    let newValue = 4;
    if (value.toLowerCase().startsWith('ou')) {
      newValue = 1;
      return newValue;
    }
    if (value.toLowerCase().startsWith('no')) {
      newValue = 2;
      return newValue;
    }
    if (value.toLowerCase().startsWith('bilan')) {
      newValue = 3;
      return newValue;
    }
    return newValue;
  }
  if (columnName === 'diploma') {
    let newValue = value.replace(/^\D+/g, '');
    if (value.toLowerCase().startsWith('bac')) {
      const years = value.replace(/^\D+/g, '');
      return isNumeric(years) ? years : 0;
    }
    if (isNumeric(value)) {
      newValue = value.replace(/^\D+/g, '');
      return newValue;
    }
    return newValue;
  }
  if (columnName === 'opt_in') {
    let newValue = true;
    if (value.toLowerCase().startsWith('ou')) {
      newValue = true;
      return newValue;
    }
    if (value.toLowerCase().startsWith('no')) {
      newValue = false;
      return newValue;
    }
    return newValue;
  }
  if (columnName === 'realization_date') {
    const reversedValue = value.split('/').reverse().join('/');
    const date = new Date(reversedValue);
    date.setDate(date.getDate() + 1);
    date.setUTCHours(0, 0, 0, 0);
    const isoDate = date.toISOString();
    return isoDate;
  }

  if (columnName === 'realization_period') {
    let newValue;
    if (value.toLowerCase().startsWith('mat')) {
      newValue = 1;
      return newValue;
    }
    if (value.toLowerCase().startsWith('mid')) {
      newValue = 2;
      return newValue;
    }
    if (value.toLowerCase().startsWith('apr')) {
      newValue = 3;
      return newValue;
    }
    if (value.toLowerCase().startsWith('soi')) {
      newValue = 4;
      return newValue;
    }
    return 5;
  }
  return 1;
};

export const makeFilters = (filters: any) => {
  if (!filters) {
    return '';
  }
  let i = 0;
  let params = '';
  for (const filter of filters) {
    const prepend = '&';
    if (i === 0) {
      const column = usersColumn.includes(filter.keyWord.columnName)
        ? `filters[user][${filter.keyWord.columnName}]`
        : `filters[${filter.keyWord.columnName}]`;
      const value = columnToTransform.includes(filter.keyWord.columnName)
        ? transformColumnValue(filter.keyWord.columnName, filter.value)
        : filter.value;
      params = `${prepend}${column}[$${tranformOperator(
        filter.operator,
        filter.keyWord.columnName
      )}]=${value}`;
      i += 1;
      // eslint-disable-next-line no-continue
      continue;
    }
    const column = usersColumn.includes(filter.keyWord.columnName)
      ? `filters[user][${filter.keyWord.columnName}]`
      : `filters[${filter.keyWord.columnName}]`;
    const value = columnToTransform.includes(filter.keyWord.columnName)
      ? transformColumnValue(filter.keyWord.columnName, filter.value)
      : filter.value;
    params += `${prepend}${column}[$${tranformOperator(
      filter.operator,
      filter.keyWord.columnName
    )}]=${value}`;
    i += 1;
  }
  return params;
};

export const makeGlobalFilter = (filter: any) => {
  const userBackendStringCol: any = userBackendStringColumnWithFilterType.map((column: string) => {
    return { user: { [`${column}`]: { $contains: filter } } };
  });

  const userBackendNumericCol: any = userBackendNumericColumnWitFilterType.map((column: string) => {
    return { user: { [`${column}`]: { $eq: filter } } };
  });

  const resultBackendStringCol: any = resultBackendStringColumnWithFilterType.map(
    (column: string) => {
      return { [`${column}`]: { $contains: filter } };
    }
  );

  const resultBackendNumericCol: any = resultBackendNumericColumnWitFilterType.map(
    (column: string) => {
      return { [`${column}`]: { $eq: filter } };
    }
  );

  let columns;

  if (isNumeric(filter)) {
    columns = [
      ...userBackendStringCol,
      ...userBackendNumericCol,
      ...resultBackendStringCol,
      ...resultBackendNumericCol,
    ];
  } else {
    columns = [...userBackendStringCol, ...resultBackendStringCol];
  }
  return columns;
};

const titleCase = (string: string) => {
  if (typeof string === 'string' && string?.trim()?.length > 0) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }
  return string;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const transformResponse = (data: any) => {
  return data.map((d: any) => {
    const { attributes: r, id } = d;
    const scoreTotal =
      r.problem_solving +
      r.confidence +
      r.collective_sense +
      r.communication +
      r.creativity +
      r.critical_mind +
      r.curiousity +
      r.daring +
      r.emotional_intelligence +
      r.empathy +
      r.entrepreneurial_spirit +
      r.motivation +
      r.presence +
      r.stress_management +
      r.time_management +
      r.vision;
    return {
      id,
      ...r,
      opt_in: r.opt_in ? 'Oui' : 'Non',
      bc: getBC(r.bc),
      scoreTotal,
      user: {
        ...r.user.data.attributes,
        firstname: titleCase(r?.user?.data?.attributes?.firstname || ''),
        lastname: r?.user?.data?.attributes?.lastname?.toUpperCase(),
      },
    };
  });
};

export const formatData = (data: any, indexRole: number, rattachement: boolean | null) => {
  if (data && data[indexRole]) {
    // if (indexRole === 5) {
    //   return (data[indexRole] || [])
    //     ?.filter((user: any) => {
    //       if (rattachement) {
    //         return user?.attributes?.rattachement === true;
    //       }

    //       return !user?.attributes?.rattachement;
    //     })
    //     .map((user: any) => {
    //       return {
    //         ...user?.attributes,
    //         id: user?.id,
    //         subordinates: user?.attributes?.subordinates?.data,
    //       };
    //     });
    // }

    if ((data[indexRole] || []).length) {
      return (data[indexRole] || []).map((user: any) => {
        return {
          ...user?.attributes,
          id: user?.id,
          subordinates: user?.attributes?.subordinates?.data,
        };
      });
    }
  }

  return [];
};

export const isUserRoot = (user: any) => {
  return user?.role?.id === 3;
};

export const isUserConsultant = (userId: any) => {
  return userId === 4;
};

export const convertDatestringToIsodate = (datestring: string) => {
  if (typeof datestring !== 'string') {
    return '';
  }
  const reversedValue = datestring.split('/').reverse().join('/');
  const date = new Date(reversedValue);
  date.setDate(date.getDate() + 1);
  date.setUTCHours(0, 0, 0, 0);
  const isoDate = date.toISOString();
  return isoDate;
};

export const isMajorTest = (testId: any) => {
  return majorTestIds.includes(testId);
};

export const getPeriodeRealisation = (periode: string) => {
  let resp = 'N/A';
  switch (periode) {
    case 'matin':
      resp = 'Matin';
      break;
    case 'midi':
      resp = 'Midi';
      break;
    case 'après-midi':
      resp = 'Après-midi';
      break;
    case 'soir':
      resp = 'Soir';
      break;
    default:
      break;
  }
  return resp;
};

export const getBC = (bc: number) => {
  let resp = 'N/A';
  switch (bc) {
    case 1:
      resp = 'Oui';
      break;
    case 2:
      resp = 'Non';
      break;
    case 3:
      resp = 'Bilan';
      break;
    default:
      break;
  }
  return resp;
};

export const transformHarmonyResult = (r: any) => {
  if (!r) {
    return {};
  }
  const scoreTotal =
    r.problem_solving +
    r.confidence +
    r.collective_sense +
    r.communication +
    r.creativity +
    r.critical_mind +
    r.curiousity +
    r.daring +
    r.emotional_intelligence +
    r.empathy +
    r.entrepreneurial_spirit +
    r.motivation +
    r.presence +
    r.stress_management +
    r.time_management +
    r.vision;

  return {
    ...r,
    opt_in: r.opt_in ? 'Oui' : 'Non',
    bc: getBC(r.bc),
    scoreTotal,
    realization_period: getPeriodeRealisation(r.realization_period as any),
  };
};

export const flattenStrapiArrays = (array: any[]) => {
  return array.map(item => {
    return { ...item.attributes, id: item.id };
  });
};

export const getHdxServiceName = (url: string | undefined) => {
  if (!url) {
    return;
  }
  if (url.includes('staging')) {
    return 'Orientaction staging frontend';
  }

  if (url.includes('preprod')) {
    return 'Orientaction preprod frontend';
  }

  return 'Orientaction prod frontend';
};

export const getUserLanguageById = (id: any) => {
  if (id === 2) {
    return 'GB';
  }
  return 'FR';
};

export const getUserLanguageFromBrowser = () => {
  const language = navigator.language.toLocaleLowerCase().includes('fr') ? 'FR' : 'GB';
  return language;
};
export const blockRedirection = ({ language, onOpen, functionToPass }: any) => {
  if (language === 'GB') {
    onOpen();
  } else {
    functionToPass();
  }
};
export const isComingDate = (date: Date) => {
  const dateNow = new Date();
  return dateNow.getTime() < date.getTime();
};
export enum StatusItemGame {
  draft = 'draft',
  published = 'published',
};

export const fetchData = async (url: string, method: string, body?: any, headers?: any) => {
  try {
    console.log({
      method,
      headers,
      body,
    });
    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    console.log({response})
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("An error occurred while fetching data: ", error);
  }
};

export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('userToken') ? getFromLS('userToken') : '';
  if (!token) {
    return false;
  }

  const decoded = jwtDecode<JwtPayload>(token);

  console.log({ decoded, dateN: new Date((decoded.exp as any) * 1000) });

  if (!decoded.exp) {
    // error decoding
    return false;
  }
  const expiration = new Date(decoded.exp * 1000);
  if (isBefore(expiration, new Date())) {
    // expired = clear
    localStorage.clear();
    return false;
  }
  return true;
};
