import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { disabledTests } from './constant';
import DisabledCardItem from './DisableCardItem';
import useTests from './useTests';

export interface ITest {
  id: string | number;
  title: string;
  time: string;
  image: string;
}

const DisableTestCard = () => {
  const tests = disabledTests;
  // const {tests} = useTests();
  return (
    <>
      {(tests || []).map((test: ITest) => (
        <DisabledCardItem key={test.id} content={test} />
      ))}
    </>
  );
};

export default DisableTestCard;
