import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles(() => ({
  content: {
    padding: '24px 20vw 100px 20vw',
  },
  footerWrapper: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0 10px 0',
    marginRight: 50,
    borderTop: '1px solid var(--neutres-gris-label, #9799B1)',
    background: '#EDEEF2',
  },
}));

export default style;
