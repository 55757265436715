// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import { ListParagrapth, Paragrapth, Page } from '@orientaction/components';
import useStyles from '../../style';
import { imgBookIntro } from '../../../../utils/constants';

const Page04 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={4} center={true}>
      <div
        style={{
          margin: 'auto',
        }}>
        <div style={{lineHeight: 1,}} className={classe.titleSteveJobs}>
          <i style={{fontWeight: 100, fontSize: 20}}>« Le futur a été créé pour être changé. »</i>
        </div>

        <div
          style={{
            width: '7%',
            height: 10,
            backgroundColor: 'yellow',
            margin: '5vh auto',
            color: 'yellow',
          }}>
          -
        </div>

        <div
          className={classe.noAdjustLeft}
          style={{
            textAlign: 'center',
          }}>
          <Paragrapth colorBlue={true} align="center">
            <div className={classe.noAdjustLeft} style={{fontSize: 20}}>Paulo Coelho</div>
          </Paragrapth>
        </div>

        <img
          src={`${imgBookIntro}/coelho.png`}
          alt="coelho"
          style={{
            width: '70%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '60px'
          }}
        />
      </div>
    </Page>
  );
};
export default Page04;
