/* eslint-disable react/destructuring-assignment */
import CompetitorSuggestion from './CompetitorSuggestion';
import EnterprisingSuggestion from './EnterprisingSuggestion';
import EpicureanSuggestion from './EpicureanSuggesttion';
import FreethinkerSuggestion from './FreethinkerSuggestion';
import RealisticSuggestion from './RealisticSuggestion';
import TheoristSuggestion from './TheoristSuggestion';

const Suggestion = (props: any) => {
  const key = props?.data?.favorite?.key;

  return (
    <>
      {key === 'study_competitor' && <CompetitorSuggestion />}
      {key === 'study_epicurean' && <EpicureanSuggestion />}
      {key === 'study_freethinker' && <FreethinkerSuggestion />}
      {key === 'study_theorist' && <TheoristSuggestion />}
      {key === 'study_realistic' && <RealisticSuggestion />}
      {key === 'study_enterprising' && <EnterprisingSuggestion />}
    </>
  );
};

export default Suggestion;
