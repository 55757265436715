const INITIAL_STATE: any = {
  myResult: null,
  dialog: {
    open: false,
    title: '',
  },
  myElement: null,
};

const stateMyResultOfTest = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'MYRESULT': {
      return {
        ...state,
        myResult: action.payload,
      };
    }
    case 'DIALOG': {
      return {
        ...state,
        dialog: action.payload,
      };
    }
    case 'MYELEMENT': {
      return {
        ...state,
        myElement: action.payload,
      };
    }
    default:
      return state;
  }
};

export default stateMyResultOfTest;
