import { Grid } from "@material-ui/core";
import { FC } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyle from './style';
import clsx from 'clsx';


interface IPage {
    children: any;
    notPadding?: boolean;
    id?: number;
    readOnly?: boolean;
    pageNoTruncate?: boolean;
    center?: boolean;
    hidePagination?: boolean;
}

const Page: FC<IPage> = ({
    children,
    notPadding = false,
    id,
    readOnly,
    pageNoTruncate,
    center,
    hidePagination = false
}) => {

    const classe = useStyle();

    return (
        <div id={`page-${id}`}>

            <Grid
                container={true}
                //   spacing={notPadding ? 0 : 2}
                justifyContent="center"
            >

                <Grid
                    item={true}
                    xs={12}
                    md={8}
                >

                    <Card
                        className={clsx({
                            [classe.pageNoTruncate]: pageNoTruncate,
                            [classe.center]: center,
                            [classe.page]: true,
                        })}
                        style={{padding: notPadding ? '0px' : ''}}
                    >

                        <CardContent
                            className={classe.simpleContent}
                            style={{ position: 'relative', padding: notPadding ? '0px' : ''}}
                        >

                            {readOnly ? <div className={classe.readOnly} /> : null}

                            {children}

                        </CardContent>

                    </Card>

                    <h2 className={classe.nbrPage} style={{opacity: hidePagination ? 0 : 1}}>
                        {id}
                    </h2>

                </Grid>

            </Grid>



        </div>

    );

};

export default Page;
