import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customtable: {
      'overflow': 'auto',
      position: 'relative',
    },
    tableContainer: {
      width: 'auto',
      '& td.disabled, & td.disabled a': {
        color: '#B3BAC5 !important',
      },
      flex: '1',
    },
    table: {
      minWidth: 650,
    },
    tableLink: {
      color: '#030F85',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    tableFooter: {
      padding: '20px 0',
    },
    tableCell: {
      '&.MuiTableCell-root': {
        fontSize: 15,
        letterSpacing: 0,
        color: '#0E1247',
        padding: 21,
        [theme.breakpoints.down('sm')]: {
          padding: 10,
        },
      },
    },
    tableCellEmpty: {
      '&.MuiTableCell-root': {
        width: "100%",
      },
    },
    bodycenter: {
      width: "50px",
      height: "50px",
    },
    noResultText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    BodyMissingTextContainer: {
      textAlign: 'center',
      padding: '15px',
    },
    TableRow: {
      cursor: 'pointer',
      background: '#fff',
    },
    relative: {
      position: 'relative',
    },
    formControlSelect: {
      width: '100%',
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247'
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    formControl: {
      width: "100%"
    },
    modalContent: {},
    modalTitle: {
      color: '#E63C3C',
      marginBottom: 30,
    },
    modalText: {
      fontSize: 15,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
    },
    cancel: {
      color: '#E63C3C',
      display: 'inline-block',
      margin: "0 5",
      cursor: "pointer"
    },
    success: {
      color: '#82CA96',
      display: 'inline-block',
      margin: "0 5",
      cursor: "pointer"
    },
    button: {
      padding: "12px 20px",
      border: "1px solid #CDCEDA",
      borderRadius: "4px",
    },
    name: {
      color: '#0E1247',
      textDecoration: 'underline'
    },
    btnAction: {
      display: 'inline-block',
      margin: '0 1vh'
    }
  }))

export default useStyles