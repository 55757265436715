/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page11 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={11}>
      <Title
        title="IDENTIFIEZ VOTRE OBJECTIF SUPRÊME"
        firstTitle="‘‘ Nous gagnons notre vie avec ce que nous recevons, mais nous lui donnons un sens
            avec ce que nous donnons..’’"
        firtsecondTitle="Winston Churchill"
        secondTitle="‘‘ Celui qui possède un pourquoi qui le fait vivre peut supporter tous les comment. ’’"
        secondSousTitle="Friedrich Nietzsche"
      />
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${publicBookPath}/titre2.png`} alt="logo" /> */}
          <SimpleBanner isLong={true}>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              PRENDRE SA PART DU LION
            </Typography>
          </SimpleBanner>
        </div>
        <Grid container={true} justifyContent="center">
          <Grid xs={5}>
            <img className={classes.img} src={`${imgBook1}/mountain.png`} alt="logo" />
          </Grid>
        </Grid>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          J’avais vingt-cinq ans quand j’ai gravi le mont Kilimandjaro. J’ai toujours aimé les défis
          et l’idée de monter à près de 6000 m d’altitude m’excitait. Je partis pour la Tanzanie
          avec deux de mes amis et nous entamâmes, avec nos guides et nos sherpas, une rude
          ascension qui allait durer cinq jours.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Ce fut une aventure aussi extraordinaire qu’épouvantable. Il faisait un froid glacial. Le
          manque d’oxygène nous occasionnait de terribles insomnies. La nourriture était infecte et
          l’ambiance avec nos guides détestable.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Chaque jour, nous marchions pendant de longues heures. Au quatrième jour, après une
          difficile montée de 1000 m dans le sable, à la lampe torche, le cœur et la tête prêts à
          éclater à cause de l’altitude, nous atteignîmes le sommet.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le soleil brillait dans un ciel bleu azur, illuminant les terres d’Afrique où nous avions
          vu quelques jours plus tôt des lions, des éléphants et des troupeaux de zèbres. Durant une
          seconde, j’éprouvai alors un plaisir infini : un sentiment de victoire !
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le mal de tête me reprit et commença alors une interminable descente. Cette ascension fut
          l’aventure la plus éprouvante, mais aussi la plus extraordinaire de ma vie.
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page11;
