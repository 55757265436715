/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useMemo, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import {
  Page,
  KnowYouBetter,
  TitlePage,
  Modal,
  Paragrapth
} from '@orientaction/components';
import {
  listvalueArray,
} from '@orientaction/Context';
import { useBook1 } from "@orientaction/hooks_book";
import useStyles from "../../style";
import { useHistory, Link } from 'react-router-dom';
import { imgBook1 } from '@orientaction/utils';
import { useLanguage } from '@orientaction/hooks';


const Page34 = (props: any) => {

  const classe = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const { book, updateYourValue } = useBook1();

  const history = useHistory();

  const handleChangeValue = (data: any) => {
    updateYourValue(data);
  }

  useEffect(() => {
    if (book.yourValue?.nbrClique === 40 && !book.yourValue?.allReadyAsk) {
      setOpenModal(true);
    }
  }, [setOpenModal, book.yourValue?.nbrClique, book.yourValue?.allReadyAsk]);

  const handleClose = () => {

    setOpenModal(false);

  }

  const onConfirm = (e: any) => {

    e.preventDefault();

    e.stopPropagation();

    updateYourValue({
      ...book.yourValue,
      PossibilityBonus: true,
      allReadyAsk: true,
    });

    const scroll: any = (document as any).getElementById("page-34")?.offsetTop || 0;

    (document as any).getElementById("ScrollSmooth").scrollTo({
      top: scroll,
      behavior: 'smooth'
    });

    setOpenModal(false);

  };

  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_visuel.png' : 'visuel.png';
  }, [language]);

  return (
    <Page id={34} readOnly={props.readOnly}>

      <TitlePage>
        PASSEZ MAINTENANT LE TEST <br />
        DES 10 VALEURS SENS®
      </TitlePage>
      <Grid container={true} spacing={2} justifyContent="center">
        <Grid item={true} xs={12}>
          <div className={classe.cardImageBtn}>
            <img src={`${imgBook1}/${image}`} alt="couverture intro" className={classe.imageBtn} />
            <Link
              to="/tests/sens-valeurs"
              target="_blank"
              rel="noopener noreferrer"
              className={classe.btnInside}>
              Clique
            </Link>
          </div>
        </Grid>
      </Grid>
      {/* <Paragrapth align='justify'>
        Chaque valeur représentée par sa première lettre comporte <Paragrapth align='start' colorRed={true}> 4 principes directeurs.</Paragrapth>
      </Paragrapth> */}
      {/* <br />
      <br /> */}
      {/* <Paragrapth align='justify'>
        Notez <Paragrapth align='start' colorBlue={true}> <b>0</b> </Paragrapth> les principes qui ne dirigent absolument pas votre vie.
      </Paragrapth> <br />
      <Paragrapth align='justify'>
        Notez <Paragrapth align='start' colorBlue={true}> <b>5</b> </Paragrapth> ceux qui dirigent entièrement votre vie.
      </Paragrapth> <br />
      <Paragrapth align='justify'>
        Notez de <Paragrapth align='start' colorBlue={true}> <b>1</b> </Paragrapth>  à <Paragrapth align='start' colorBlue={true}> <b>4</b> </Paragrapth>  les principes qui dirigent plus ou moins votre vie.
      </Paragrapth> */}
      {/* <br />
      <br />
      {
        listvalueArray.map(item =>
          <>
            <KnowYouBetter
              handleChange={handleChangeValue}
              current={book.yourValue}
              title={item.name}
              listTest={item.listTest}
            />
          </>
        )
      }
      <Modal
        open={openModal}
        onCancel={handleClose}
        title="Vous avez terminé de remplir <br/> tous les principes directeurs"
      >
        <div className={classe.modalContent}>
          <Paragrapth align='center' colorBlue={true}>
            Vous avez la possibilité de mettre un bonus de <Paragrapth
              align='start'
              colorBlue={true}
              bold={true}
            >15 points</Paragrapth> sur l’uns d’entres eux qui a le plus d’importance à vos yeux.
            Veuillez sélectionner la proposition que vous voulez svp.
          </Paragrapth>

          <div className={classe.containerBtn}>
            <Grid
              container={true}
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >

              <Grid item={true}>
                <Button
                  onClick={onConfirm}
                  style={{ textTransform: 'none' }}
                  className={classe.validate}
                >
                  C&#39;est parti !
                </Button>
              </Grid>

            </Grid>

          </div>

        </div>

      </Modal> */}

    </Page>
  );
};

export default Page34;
