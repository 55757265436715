import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '65vh',
    border: '1px solid #aaa',
    overflowY: 'hidden',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    borderRadius: '1vh',
    background: '#fff',
  },
  floatLeft: {
    display: 'inline-block',
    borderLeft: '1px solid #aaa',
    height: '100%',
    verticalAlign: 'bottom',
    overflow: 'hidden',
  },
  floatLeftNotBorder: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'bottom',
    overflow: 'hidden',
  },
});
