/* eslint-disable no-use-before-define */
import { figure32_page4 } from '../../../../utils/constants';
import { TableDestin, Page } from '@orientaction/components';

const Page25 = (props: any) => {
  return (
    <Page id={25}>
      <TableDestin head={false} figure32={figure32_page4} />
    </Page>

  );
};

export default Page25;


