import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paginationFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    marginBottom: 75,
    textAlign: 'right',
    '& button': {
      fontSize: 18,
      minWidth: 42,
      padding: 0,
      marginLeft: 5,
      fontWeight: 'bold',
      color: '#000A8C',
      '&-page': {
        padding: 0,
      },
      '&.active': {
        background: '#00098c8a',
      },
      '&:hover': {
        color: '#bcbcbc;',
      },
      '&:disabled': {
        display: 'none',
      },
    },
  },
}));
