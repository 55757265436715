/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import { Grid, Typography } from '@material-ui/core';
import { gameVariablesTemplateActions } from '@orientaction/api-actions';
import { gameBookValues, gameVariables, gameVariableValues } from '@orientaction/reducers';
import { labelBlockToShow } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import EditorCitation from '../../EditorCitation';
import EditorImg from '../../EditorImg';
import style from '../style';
import { styleSelect } from '../styleSelect';
import EditorQuill from '@orientaction/components/EditorQuill/index';

const ModelVariable6 = (props: any) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [position, setPosition] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const valuesBooksTemplate = useSelector(gameBookValues);
  const allVariables = useSelector(gameVariables);
  const valuesVariables = useSelector(gameVariableValues);

  const {
    onChoiceClick,
    indexVariable
  } = props;
  const [typeBloc, setTypeBloc] = useState<Array<string>>(['normal', 'normal']);
  const [restitution, setRestitution] = useState<any>({});
  const optionsValue = [
    {
      value: 'normal',
      label: 'Normal',
    },
    {
      value: 'citation',
      label: 'Citation',
    },
    {
      value: 'encyclopedia',
      label: 'Encyclopédie',
    },
  ];

  const handleFieldChange = (field: string, value: any) => {
    dispatch(gameVariablesTemplateActions.updateField(field, value));
  };

  const handleOpen = (event: any) => {
    onChoiceClick(true);
    event.preventDefault();
  };

  const handleFileUrlChange = (urlC: string) => {
    setUrl(urlC);
  };
  const handleFileChange = (fileC: any) => {
    // handleFieldChange('files', fileC);
    //  setFile(fileC);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setUrl('');
  };
  const handleChangeSelect = (selected: any, numberBlock: number) => {
    setTypeBloc((prevTypes) => {
      const newTypes = [...prevTypes];
      newTypes[numberBlock] = selected;
      return newTypes;
    });
  };

  useEffect(() => {
    if (allVariables) {
      console.log({valuesVariables, all: allVariables[indexVariable]})
      dispatch(gameVariablesTemplateActions.setAllValues(allVariables[indexVariable]));
    } 
  }, [indexVariable, allVariables])


  return (
    <div
      className={classes.container}
      onClick={(e) => e.stopPropagation()}
      key={indexVariable}
    >
      <Grid container={true} style={{ height: '100%' }}>
        <Grid
          item={true}
          sm={6}
          className={classes.blockContentParent}
          style={{ borderRight: '1px solid #E1E1E1' }}
        >
          <div 
            className={classes.blockContent} 
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography variant="h5" className={classes.title}>
              Illustration
            </Typography>
            <div style={{ marginTop: 15, height: '100%', position: 'relative', maxHeight: 622 }}>
              <EditorImg
                width={508}
                height={622}
                isFullHeight={true}
                handleFile={handleFileChange}
                defaultUrl={
                  valuesVariables?.illustration?.url || valuesVariables?.tempImage || null
                }
                isVariable={true}
                key={indexVariable}
              />
            </div>
          </div>
        </Grid>
        <Grid item={true} sm={6} className={classes.blockContentParent}>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Typography variant="h5" className={classes.title}>
              Choix
            </Typography>
            <div
              className="wrapperchoices"
              style={{ fontFamily: 'Poppins', fontSize: 12, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, height: '100%', backgroundColor: 'rgb(241, 241, 241)' }}
            >
              Pas de personnalisation disponible <br />
              (l'utisateur a un bouton qui lui permet de retourner à sa liste de jeux)
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModelVariable6;
