/* eslint-disable no-shadow */
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { ReactComponent as Indicator } from '../../../../Asset/svg/indicator.svg';
import { ReactComponent as Stories } from '../../../../Asset/svg/stories.svg';
import { ReactComponent as AutoStories } from '../../../../Asset/svg/auto_stories.svg';
import style from './style';

interface IItemPage {
  id: string;
  title: string;
  url: string;
  isCover?: boolean;
  nbPage?: number;
  isSelected?: boolean;
  handleClick?: () => void;
  onMouseEnter?: () => void;
  isLastPage?: boolean;
}

const ItemPage: FC<IItemPage> = ({
  id,
  title,
  url = 'https://storage.googleapis.com/orient-action.appspot.com/xx_85ae6992d2.png?updated_at=2024-05-27T18:25:35.428Z',
  isCover = true,
  nbPage,
  isSelected,
  handleClick,
  onMouseEnter,
  isLastPage = false,
}) => {
  const classes = style();
  return (
    <div
      onClick={handleClick}
      className={`${classes.container} ${
        !isCover && isSelected
          ? isLastPage
            ? classes.borderSelectedLastPage
            : classes.borderSelected
          : classes.borderTransparent
      } `}
      style={!isCover ? { padding: '7px 5px' } : {}}
    >
      {isCover ? (
        <img className={classes.img} src={url} alt="page covurture" />
      ) : (
        <div className={classes.container2}>
          <Indicator style={{ width: 10, height: 10, marginRight: 7 }} />
          <div className={classes.container3} style={isLastPage ? { background: 'white' } : {}}>
            {isLastPage ? (
              <AutoStories style={{ width: 13, height: 13 }} />
            ) : (
              <Stories style={{ width: 13, height: 13 }} />
            )}
            <Typography
              variant="h4"
              className={classes.title2}
              style={isLastPage ? { color: '#E63C3C' } : {}}>
              {`P${nbPage}`}
            </Typography>
          </div>
        </div>
      )}
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

export default ItemPage;
