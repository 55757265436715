import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  noResultContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResultTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
  },
  noResultImageContainer: {
    top: '50%',
    left: '50%',
    position: 'absolute',
    width: '110%',
    maxWidth: 500,
    transform: 'translate(-50%, -50%)',
    '& img': {
      width: '100%',
      height: 'auto',
    },
    '@media(min-width: 1250px)': {
      top: '50%',
      left: '27%',
      maxWidth: 675,
      transform: 'translate(-50%, -50%)',
    },
  },
  noResultText: {
    margin: 'auto',
    color: '#000A8C',
    textAlign: 'center',
    font: 'normal normal bold 29px/48px ITC Avant Garde Gothic Std Medium',
    letterSpacing: 0,
    opacity: 1,
  },
}));
