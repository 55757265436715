import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { FC } from 'react';
import style from './style';
import Skeleton from '@material-ui/lab/Skeleton';
import { MarginContainer } from '@orientaction/commons';


interface IProps {
  user: any;
  loading: boolean;
}

const UserProfileInfos: FC<IProps> = ({ user, loading }) => {
  const cls = style();
  return (
    <div className={cls.infoContainer}>

      {
        loading ?
          (
            <>
              {
                [0, 1, 2, 3, 4, 5].map((item: any) =>
                  <MarginContainer>
                    <Skeleton variant="rect" height={20} width='60%' />
                  </MarginContainer>
                )
              }
            </>
          ) : (
            <>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Prénom
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.firstname : ""}
                </Grid>
              </Grid>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Nom
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.lastname : ""}
                </Grid>
              </Grid>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Description
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.description : ""}
                </Grid>
              </Grid>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Anniversaire
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.dayOfBirth ? moment(user?.dayOfBirth).format('DD/MM/YYYY') : '' : ''}
                </Grid>
              </Grid>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Email
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.email : ""}
                </Grid>
              </Grid>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Code postal
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.postal_code : ""}
                </Grid>
              </Grid>
              <Grid className={cls.itemContainer} container={true} spacing={10}>
                <Grid item={true} md={3} className={cls.itemLabelFont}>
                  Téléphone
                </Grid>
                <Grid item={true} md={9} className={cls.itemInfoFont}>
                  {user ? user?.phone : ""}
                </Grid>
              </Grid>
            </>
          )
      }

    </div>
  );
};

export default UserProfileInfos;
