/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import start from '@orientaction/Asset/Favorite/start.png';
import { ChipTime } from '@orientaction/commons';
import { useTest } from '@orientaction/hooks';
import { publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { kebabCase } from 'lodash';
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from './Modal';
import { useStyles } from './style';

const CardTest = (props: any) => {
  const classes = useStyles();
  const link = `${process.env.REACT_APP_SHARE_ENDPOINT}/api/test/${props.id}/${kebabCase(
    props.title
  )}`;
  const [checkedC, setStateC] = React.useState(!props.isDesabled);
  const [opacity, setOpacity] = React.useState(1);
  const [filter, setFilter] = React.useState('grayscale(0%)');
  const [openModal, setOpenModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { push } = useHistory();
  const { desableTest, deepDuplicateTest } = useTest();

  const handleChange = (event: any) => {
    disableCard(!event.target.checked);
    setStateC(event.target.checked);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const duplicateCard = () => {
    setLoading(true);
    deepDuplicateTest(props.id).then(() => setLoading(false));
  };

  const archiveCard = () => {
    const { id, isArchived } = props;
    const data = {
      isArchived: !isArchived,
      isDesabled: false,
      category_test: props.category, // default one
      currentCategory: props.category,
    };
    desableTest(id, { ...data });
  };

  const disableCard = (handleChecked: boolean) => {
    const data = {
      isDesabled: handleChecked,
      isArchived: false,
      category_test: props.category, // default one
      currentCategory: props.category,
    };
    const { id } = props;
    desableTest(id, { ...data });
  };

  React.useEffect(() => {
    if (props.isArchived) {
      setOpacity(0.7);
      setFilter('grayscale(100%)');
    }
  }, [props.isArchived]);

  const modifyTest = (testId: any) => () => {
    push(`/admin/test/${testId}/modifier`);
  };

  return (
    <>
      <div className={classes.root}>
        <Card
          className={clsx({
            [classes.cardContainer]: true,
          })}
          style={{ opacity, filter }}>
          <Grid container={true} spacing={3}>
            {props.isFavorite ? (
              <Grid item={true} xs={12}>
                <div className={classes.contentImgFavorite}>
                  <div
                    className={classes.imgFavorite}
                    style={{
                      backgroundImage: `url(${
                        props.image && props.image !== '' ? props.image : `${publicSvgPath}/QR.png`
                      })`,
                    }}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item={true}>
                <img
                  className={classes.img}
                  src={props.image && props.image !== '' ? props.image : `${publicSvgPath}/QR.png`}
                  alt="logo"
                />
              </Grid>
            )}

            <Grid item={true} xs={true}>
              {!!props.isFavorite && <img src={start} className={classes.start} alt="Favorite" />}

              <Typography
                variant="h6"
                component="h6"
                color="primary"
                className={classes.fontTitleCard}>
                {' '}
                {props.title}
              </Typography>
              <div className={classes.positionAbsolute}>
                <div className={classes.marginChip}>
                  <ChipTime time={props.time} />
                </div>
                <div className={classes.controls}>
                  <IconButton className={classes.btnIcon} onClick={modifyTest(props.id)}>
                    <EditIcon className={classes.styleIcon} />
                  </IconButton>
                  <IconButton className={classes.btnIcon}>
                    {loading ? (
                      <CircularProgress color="primary" size={14} />
                    ) : (
                      <FileCopyIcon
                        data-cy="duplicate-test"
                        className={classes.styleIcon}
                        onClick={duplicateCard}
                      />
                    )}
                  </IconButton>
                  <IconButton className={classes.btnIcon} onClick={handleOpenModal}>
                    <ShareIcon className={classes.styleIcon} />
                  </IconButton>
                  <IconButton className={classes.btnIcon}>
                    <ArchiveIcon
                      data-cy="archive-test"
                      className={classes.styleIcon}
                      onClick={archiveCard}
                    />
                  </IconButton>
                  <div style={{ float: 'right' }}>
                    <Switch
                      checked={checkedC}
                      onChange={handleChange}
                      name="checkedA"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      color="primary"
                    />
                  </div>
                </div>
              </div>

              {props.isFavorite && <div className={classes.bouttom} />}
            </Grid>
          </Grid>
          <Modal
            open={openModal}
            title={props.title}
            testId={props.testId}
            link={link}
            callBack={(event: any) => setOpenModal(event)}
          />
        </Card>
      </div>
    </>
  );
};

export default CardTest;
