/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook1, publicSvgPath } from '../../../../utils/constants';
import clsx from 'clsx';
import { Page } from '@orientaction/components';
import { useState } from 'react';
import Hidden from '@material-ui/core/Hidden';

const Page12 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={12}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" color="primary" gutterBottom={true} className={classes.colorPrimaryBook}>
          MIEUX CONNAÎTRE VOS BESOINS <br />
        </Typography>
        <div className={classes.colorPrimaryBook} style={{ textAlign: 'center', marginTop: '50px', }}>
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'bold', fontStyle: 'italic' }}>
            <strong>« Les vrais besoins n’ont jamais d’excès. »</strong>
          </Typography>
          <br />
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}>
            Jean-Jacques Rousseau
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: "60px", marginBottom: "60px" }} />
        <div className={classes.colorPrimaryBook} style={{ textAlign: 'center' }}>
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'bold', fontStyle: 'italic' }}>
            <strong>
              « Qui mieux que vous sait vos besoins ? <br />
              Apprendre à se connaître est le premier des soins... »
            </strong>
          </Typography>
          <br />
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}>
            Jean de La Fontaine
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Nous avons tous des besoins. De la même façon qu’une plante a besoin d’une 
          certaine quantité de lumière, d’eau et de sels minéraux pour s’épanouir, tout être 
          humain a besoin de certains éléments pour vivre.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Ces éléments sont de nature matérielle comme un logement, de l’argent pour 
          payer ses factures, ou mettre de l’essence dans sa voiture. Mais l’être humain 
          est un animal social. Nombre de ses besoins sont liés à sa nature, comme le fait 
          d’appartenir à un groupe, d’y avoir une place reconnue, d’être apprécié par ses 
          pairs. L’être humain a de très nombreux besoins immatériels. 
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Bien connaître ses besoins permet d’identifier les activités qui seront les plus à 
          même d’y répondre. Savoir par exemple précisément combien nous souhaitons 
          gagner chaque mois, nous permettra d’identifier les métiers qui auront le plus de 
          chances de nous apporter cette rémunération et d’écarter les activités qui seraient 
          insatisfaisantes. 
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Il faut savoir que les besoins sont à la base de la motivation. Nombre de nos 
          motivations sont en fait liées au désir de satisfaire nos besoins. Voilà pourquoi ce 
          travail d’identification et de classement de nos besoins, est essentiel. 
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Les exercices qui vont suivre vont vous permettre de mieux identifier vos besoins et 
          de les hiérarchiser par ordre de priorité. Les questions vous permettront de préciser 
          au mieux vos besoins. Les échanges avec votre consultant(e) vous permettront de 
          valider les choix réalisés de façon individuelle. En effet, il n’est pas toujours facile de 
          bien connaître soi-même ses besoins. Par contre, il est très facile de confondre des 
          besoins, notamment des besoins d’ordre matériel, avec des besoins d’ordre social 
          ou psychologique. Parlez-en avec votre consultant(e). 
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '60%',
      height: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
        marginTop: '0px !important',
      },
    },
    imgFurious: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
      },
    },
    imgFurious1: {
      width: '30%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none'
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 20,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    colorPrimaryBook : {
      color: "#2b56a5"
    }
  })
);
export default Page12;
