/* eslint-disable no-use-before-define */
import { FC, useState } from 'react';
import { useStyles } from './style';
import clsx from 'clsx';

let typeTimeout: any;

interface ITextArea {
    onChange: (e: any) => void;
    name: string;
    value: any;
    placeholder?: string;
    notBorder?: boolean;
    type?: string;
}

const TextField: FC<ITextArea> = ({
    onChange,
    name,
    value,
    placeholder,
    notBorder,
    type = 'text'
}) => {

    const classes = useStyles();
    const [valueDefault, setDefaultValue] = useState(value)
    const MAX_SCORE = 100;

    const onKeyDown = (event: any) => {

        clearTimeout(typeTimeout);

        handleChangeGlobalState(event)

    };

    const handleChange = (e: any) => {
        typeTimeout = setTimeout(function () {
            handleChangeGlobalState(e)
        }, 1000);
    };

    const handleChangeGlobalState = (e: any) => {
        if (type === 'number') {
            let valueMax = e.target.value
            if (parseInt(valueMax, 10) > MAX_SCORE) {
                valueMax = MAX_SCORE
            }
            onChange({
                target: {
                    name: name as any,
                    value: valueMax
                } as any
            });
            setDefaultValue(valueMax)
        } else {
            onChange({
                target: {
                    name: name as any,
                    value: e.target.value
                } as any
            });
            setDefaultValue(e.target.value)
        }
        

    };

    return (
        <input
            type={type}
            max={100}
            min={0}
            name={name || ""}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onBlur={handleChangeGlobalState}
            defaultValue={valueDefault}
            placeholder={placeholder || "0123"}
            className={clsx({
                [classes.textArea]: true,
                [classes.textAreaNotBorder]: notBorder,
            })}
        />
    );
};

export default TextField;
