import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: any) => ({
  resultDetail: {
    background: '#fff',
    cursor: 'pointed',
  },
  resultDetailHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '2vh',
  },
  resultDetailHeaderImage: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resultDetailHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
  },
  note: {
    marginTop: 15,
  },
  resultDetailContent: {
    padding: '2vh',
  },
  definition: {
    marginTop: '1em',
  },
  description: {
    fontSize: '14px'
  },
  subtitle: {
    fontSize: 15,
  },
  decisiveProfessions: {},
  hundred: {
    fontSize: 12,
    verticalAlign: 'top',
  },
  mainNote: {
    fontFamily: 'ITC Avant Garde Gothic Std Bold',
    fontSize: 15,
  },
  decisiveProfessionsList: {
    columnCount: 2,
    listStyle: 'none',
    padding: 0,
    '& li': {
      paddingLeft: 10,
    },
  },
  dot: {
    verticalAlign: 'super',
    fontSize: 25,
    marginLeft: '-10px',
  },
}));
