import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    desktop: {
      width: 701,
      height: '100%',
    },
    mobile: {
      width: 'auto',
      height: '100%',
    },
    content: {
      overflowX: 'hidden',
      [theme.breakpoints.down(900)]: {
        borderRadius: '0px',
      },
    },
    drawer: {
      '& .MuiDrawer-paperAnchorRight': {
        borderRadius: '18px 0px 0px 18px',
        overflowX: 'hidden',
      },
    },
    head: {
      width: '100%',
      height: '142px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      color: '#ED6637',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down(900)]: {
        position: 'absolute',
        justifyContent: 'center',
        zIndex: 200,
        height: '68px',
        borderBottom: '1px solid #9799B1',
        backgroundColor: '#fff',
      },
    },
    contentDrawer: {
      marginTop: '10px',
      [theme.breakpoints.down(900)]: {
        marginTop: '95px',
      },
    },
    img: {
      width: '280px',
      height: '400px',
    },
    imgContent: {
      margin: '3vh 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentDescription: {
      width: '90%',
      margin: '0 auto',
      [theme.breakpoints.down(900)]: {
        marginTop: 20,
      },
    },
    paragraph: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '19px',
      color: '#23296F',
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
      },
    },
    paragraphPrincipe: {
      color: '#23296F',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      maxWidth: '621px',
      margin: '0 auto',
      fontSize: '20px',
      '& b': {
        fontWeight: 600,
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
      },
    },
    titleHead: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '50px',
      [theme.breakpoints.down(800)]: {
        fontSize: '20px',
      },
    },
    contentFavorite: {
      borderRadius: '8px',
      '& li': {
        margin: '1vh 0',
        color: '#23296F',
      },
      [theme.breakpoints.down(900)]: {
        paddingLeft: '15px',
      },
    },
    score: {
      fontFamily: 'Poppins',
      fontSize: '28px',
      fontWeight: 600,
      [theme.breakpoints.down(900)]: {
        fontSize: '18px',
      },
    },
    handleClose: {
      color: '#ED6637',
      position: 'absolute',
      left: '90%',
      zIndex: 600,
      top: 6,
      [theme.breakpoints.down(900)]: {
        top: 10,
        left: '2%',
      },
    },
    colorOrange: {
      color: '#ED6637',
    },
    contentLoader: {
      width: 701,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
  }),
);
