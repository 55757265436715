/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, imgBookIntro, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page24 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={24}>
      <div style={{ marginBottom: '20px' }}>
        <div className={classes.cardBgGrey}>
          <div style={{ marginBottom: '30px' }}>
            {/* <img className={classes.imgTailleur} src={`${imgBookIntro}/titre37.png`} alt="logo" /> */}
            <SimpleBanner>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                YES MAN !
              </Typography>
            </SimpleBanner>
          </div>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Dans ce film, Carl Allen (joué par Jim Carrey) est au point mort, jusqu’au jour où
                il s’inscrit à un programme de développement personnel basé sur une idée simple :
                dire oui à tout!
              </Typography>
            </Grid>
            {/* <Grid item={true} xs={12}>
            <img className={classes.textImg} src={`${imgBook1}/equilibre.png`} alt="logo" />
          </Grid> */}
          </Grid>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Carl découvre alors avec éblouissement le pouvoir magique du «OUI », il voit sa vie
            professionnelle et sa vie amoureuse bouleversées du jour au lendemain :
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            une promotion inattendue, une nouvelle petite amie… Ce que raconte ce film de façon
            humoristique, nous l’avons tous (toutes) connu un jour ou l’autre dans nos vies.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Il y a des moments où, pris(es) dans nos habitudes, nous refusons presque
            systématiquement toute nouvelle opportunité. Chaque jour, nous suivons un scénario écrit
            d’avance. Nous réalisons les mêmes gestes, côtoyons les mêmes personnes, allons dans les
            mêmes endroits.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Nos habitudes nous empêchent de voir les très nombreuses opportunités qui pourraient se
            faire jour si, tout à coup, nous nous mettions à réaliser des gestes différents, à
            discuter avec de nouvelles personnes, à nous rendre dans de nouveaux endroits.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Pour déjouer le piège de nos habitudes, le film YES MAN est un conte moderne qui nous
            invite simplement à dire « OUI ».
          </Typography>
        </div>
        <div style={{ marginTop: '50px', marginBottom: '20px' }}>
          <TitleNbr number={3} text="CRAINDRE LE « DIP » (DÉFI IMPOSSIBLE OU POSSIBLE)" />
          <Grid container={true} spacing={1} justifyContent="center">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Imaginez que vous êtes au pied d’une montagne.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Vous regardez le sommet. Vous voyez un chemin, une épaisse forêt puis une falaise
                abrupte, puis encore un grand glacier plein de crevasses.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Vous regardez votre objectif. Vous hésitez : dois-je y aller ou non? Vous voulez
                atteindre le sommet de cette montagne. La vue y est, paraît-il, fantastique.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Vous aimeriez atteindre ce sommet, mais êtes-vous prêt(e) à emprunter ce chemin, à
                grimper pendant des heures dans la chaleur puis le froid, à affronter le vertige, la
                neige, le vent, à prendre tous ces risques? Cette image illustre bien un principe
                essentiel du changement.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Il y a le changement et il y a le chemin qui vous mène au changement.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Il y a l’objectif final et il y a le processus. Ce chemin, c’est ce qu’on appelle le
                DIP (Défi Impossible ou Possible). Le DIP c’est la somme de tous les efforts,
                l’énergie, le temps, les relations, l’argent, les connaissances nécessaires pour
                réaliser votre projet.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Pour savoir si l’on souhaite vraiment changer, il faut répondre à cette question :
                suis-je prêt(e) à emprunter ce chemin ?
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    textImg: {
      width: '40%',
      height: 'auto',
    },
  }),
);
export default Page24;
