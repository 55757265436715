// eslint-disable-next-line import/no-unresolved
import {
  getHeaders,
  getStrapiFilter,
  getUserStatusStrapiFilter,
  makeRequest,
} from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;
const exportUrl = config.export_url;

export const getListMajorTest = async (token: string) => {
  return makeRequest(`${url}/api/get-list-major-test`, 'GET', {}, getHeaders(token));
};

export const getMyListMajorTest = async (CompanyId: string, token: string, searchKey = '') => {
  const searchParams = qs.stringify(
    {
      filters: {
        $and: [{ name: { $containsi: searchKey } }],
      },
    },
    {
      encodeValuesOnly: true,
    },
  );
  return makeRequest(
    `${url}/api/get-my-major-test/${CompanyId}?${searchParams}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getUserAllTestLatestResult = async (token: string, userId: number) => {
  return makeRequest(
    `${url}/api/get-user-all-test-latest-result?userId=${userId}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getMyTestByCompany = async (token: string, filter: any) => {
  return makeRequest(`${url}/api/get-my-test-by-company`, 'POST', { ...filter }, getHeaders(token));
};

export const getMajorTestResults = async (
  companyId: any,
  additionalUrl: string,
  token: string,
  filters: any,
  userStatusFilters: any,
  page: any = 'none',
  isExport = false,
) => {
  const isHarmony = additionalUrl === 'results';
  const populate = isHarmony
    ? { populate: { user: { populate: ['company_user_role'] } } }
    : { populate: { users_permissions_user: { populate: ['company_user_role'] } } };
  const filtersTransformed = filters.map((filter: any) => getStrapiFilter(filter, isHarmony));
  const userStatusFiltersTransformed = getUserStatusStrapiFilter(userStatusFilters, isHarmony);
  const allFilters =
    additionalUrl === 'meaning-value-test-results'
      ? [
          ...filtersTransformed.concat(userStatusFiltersTransformed),
          { fromScript: { $null: true } },
        ]
      : filtersTransformed.concat(userStatusFiltersTransformed);
  const pagination = page === 'none' ? {} : { page, pageSize: 10 };

  const searchParams = qs.stringify(
    {
      filters: {
        $and: allFilters,
      },
      ...populate,
      pagination,
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );

  const apiUrl = isExport ? exportUrl : url;

  return makeRequest(
    `${apiUrl}/api/${additionalUrl}?${searchParams}&company=${companyId}&isExport=${isExport}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getUserMajorTests = async (token: string, userId = 0, serviceId = 0) => {
  return makeRequest(
    `${url}/api/get-user-major-tests?userId=${userId}&serviceId=${serviceId}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getMajorTestLastResult = async (token: string, testId: string | number) => {
  return makeRequest(
    `${url}/api/get-user-last-test-result/${testId}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getNonRattachedBeneficiaryTests = async (token: string) => {
  return makeRequest(`${url}/get-non-rattached-beneficiary-tests`, 'GET', {}, getHeaders(token));
};
