export const valueAliasRole = (aliasField: any, companyUserRoleAlias: any) => {

    const listOther = [
        "Direction",
        "Bénéficiaire",
        "Résultats des tests",
    ];

    const listManager = [
        "Manager n+3",
        "Manager n+2",
        "Manager n+1",
        "Bénéficiaires"
    ];

    if (listManager.includes(aliasField)) {

        const separe = aliasField.split('+')[1];
        
        const key = `manager_${separe}`;

        return companyUserRoleAlias?.[key] || aliasField;

    }

    if (listOther.includes(aliasField)) {
        return aliasField;
    }

    if (companyUserRoleAlias?.[aliasField]) {
        return companyUserRoleAlias[aliasField]
    }

    const getNbr = aliasField.split('_')[1];

    return `Manager N+${getNbr}`;

}