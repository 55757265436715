import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import classNames from 'classnames';
import clsx from 'clsx';
import { FC, ReactNode, useState } from 'react';
import { publicIconDetailResultPath } from '../utils/constants';

type Props = {
  imageSrc: string;
  title: string;
  color: string;
  note: number;
  description: string;
  decisiveProfessions: string[];
  className?: string;
  firstElement?: boolean;
  lastElement?: boolean;
};

const Thead: FC<Props> = ({
  imageSrc,
  title,
  color,
  note,
  description,
  decisiveProfessions,
  firstElement = false,
  lastElement = false,
  className = '',
}) => {
  const classes = useStyles();
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div
      className={clsx(classes.resultDetail, className)}
      style={{
        borderTop: '0.02rem solid #CDCEDA',
        borderBottom: '0.02rem solid #CDCEDA',
        borderTopRightRadius: firstElement ? '8px' : '0px',
        borderTopLeftRadius: firstElement ? '8px' : '0px',
        borderBottomRightRadius: lastElement ? '8px' : '0px',
        borderBottomLeftRadius: lastElement ? '8px' : '0px',
      }}>
      <div
        className={classes.resultDetailHeader}
        onClick={() => setIsExpand((prevstate: boolean) => !prevstate)}>
        <div className={classes.resultDetailHeaderImage}>
          {imageSrc && (
            <img
              src={`${publicIconDetailResultPath}/${imageSrc}`}
              alt="Icon"
              className={classes.img}
            />
          )}
        </div>
        <div className={classes.resultDetailHeaderContent}>
          <div className={classes.textheader}>
            <div className={classes.titleContainer}>
              <h2 className={classes.title}>{title}</h2>
            </div>
            <div
              className={classes.note}
              style={{
                color,
              }}>
              <span className={classes.mainNote}>{note}</span>
              <span className={classes.hundred}>/100</span>
            </div>
          </div>
          <div className={classes.arrow}>{isExpand ? <ExpandLess /> : <ExpandMore />}</div>
        </div>
      </div>
      {isExpand && (
        <div className={classes.resultDetailContent}>
          <div className={classes.definition}>
            <h4
              className={classes.subtitle}
              style={{
                color,
              }}>
              Définition
            </h4>
            <p>{description}</p>
          </div>
          <div className={classes.decisiveProfessions}>
            <h4
              className={classes.subtitle}
              style={{
                color,
              }}>
              Fonction et/ou métiers dans lesquels elle est déterminante
            </h4>
            <ul className={classes.decisiveProfessionsList}>
              {decisiveProfessions.map((decisiveProfession: string) => (
                <li>
                  <span
                    className={classes.dot}
                    style={{
                      color,
                    }}>
                    .
                  </span>{' '}
                  {decisiveProfession}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  img: {},
  resultDetail: {
    background: '#fff',
    paddingTop: 15,
    paddingBottom: 15,
    '& h2, h3, h4, h5': {
      margin: 0,
      color: '#0E1247',
    },
    '& p, ul > li': {
      fontSize: 14,
      lineHeight: '1.5em',
      color: '#0E1247',
    },
  },
  resultDetailHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  resultDetailHeaderImage: {},
  resultDetailHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  textheader: {},
  titleContainer: {},
  title: {
    fontSize: 18,
  },
  note: {
    marginTop: 15,
  },
  arrow: {},
  resultDetailContent: {},
  definition: {
    marginTop: '1em',
  },
  subtitle: {
    fontSize: 15,
  },
  decisiveProfessions: {},
  hundred: {
    fontSize: 12,
    verticalAlign: 'top',
  },
  mainNote: {
    fontFamily: 'ITC Avant Garde Gothic Std Bold',
    fontSize: 15,
  },
  decisiveProfessionsList: {
    columnCount: 2,
    listStyle: 'none',
    padding: 0,
    '& li': {
      paddingLeft: 10,
    },
  },
  dot: {
    verticalAlign: 'super',
    fontSize: 25,
    marginLeft: '-10px',
  },
}));

export default Thead;
