// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
    ListParagrapth,
    Paragrapth,
    Page,
    Img
} from '@orientaction/components';
import useStyles from "../../style";


const Page10 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={10}
            center={true}
        >
            <div className={classe.flexPosition}>
                <div>
                    <div className={classe.titleSteveJobs}>
                        <i className={classe.colorPrimaryBook} style={{lineHeight: "15px"}}>
                            <span style={{fontSize: '20px', fontWeight: 'bold'}}>Vous pouvez maintenant commencer et…</span>
                            <br />
                            <strong style={{fontSize: '25px', fontWeight: 'bold'}}>Oser être qui vous êtes vraiment !</strong>
                        </i>
                    </div>

                    <Img
                        src="illustration_page_15.jpg"
                        alt="Steve Jobs"
                        style={{
                            width: "80%",
                        }}
                    />
                </div>
            </div>
            

        </Page>
    );
};
export default Page10;
