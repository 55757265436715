// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    alignItemsCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btnText: {
      display: 'none',
      marginLeft: 0,
      fontFamily: 'ITC Avant Garde Gothic Std bold',
      color: '#0E1247',
      '@media(min-width: 600px)': {
        display: 'block',
      },
    },
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: 'white',
      border: '3px solid #0E1247',
      borderRadius: 10,
      '@media(min-width: 600px)': {},
    },
    btnIcon: {
      border: '3px solid #0E1247',
      borderRadius: 10,
      width: 48,
      height: 48,
      marginLeft: 20,
      padding: '2px',
    },
    styleIcon: {
      color: '#0E1247',
      width: '20px',
      height: '20px',
    },
    italicText: {
      fontFamily: 'ITC Avant Garde Gothic Std bold',
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: 12,
      marginTop: 7,
    },
  })
);
