export const tableData = [
  {
    id: '24-table-head-1',
    firstColumn: `Les choix que vous êtes prêt(e) à faire, si nécessaire,
    pour réaliser votre projet`,
    secondColumn: 'Oui',
    thirdColumn: 'Non',
    children: [
      {
        id: '24-table-1-1',
        name: 'D1',
        content: 'Êtes-vous prêt(e) à vendre votre résidence principale ?',
      },
      {
        id: '24-table-1-2',
        content: 'Êtes-vous prêt(e) à mobiliser des placements financiers ?',
        name: 'D2',
      },
      {
        id: '24-table-1-3',
        content: 'Êtes-vous prêt(e) à mobiliser des placements immobiliers ?',
        name: 'D3',
      },
      {
        id: '24-table-1-4',
        content: 'Avez-vous déjà placé en réserve de l’argent pour la réussite de votre projet ?',
        name: 'D4',
      },
      {
        id: '24-table-1-5',
        content: 'Êtes-vous prêt(e) à accepter une baisse de votre rémunération ?',
        name: 'D5',
      },
      {
        id: '24-table-1-6',
        content: 'Êtes-vous prêt(e) à réaliser une rupture conventionnelle ?',
        name: 'D6',
      },
      {
        id: '24-table-1-7',
        content: 'Êtes-vous prêt(e) à négocier votre départ ?',
        name: 'D7',
      },
      {
        id: '24-table-1-8',
        content: `Si vous êtes en couple, votre conjoint(e) est-il (elle) prêt(e) à assumer les charges
        du foyer ?`,
        name: 'D8',
      },
    ],
  },
];
