/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import 'react-quill/dist/quill.snow.css';
import Divider from '@material-ui/core/Divider';
import { ContainerAdmin } from '@orientaction/commons';
import {
  EgalitySection,
  FinalTextSection,
  Layout,
  QuestionSection,
  TestSection,
  TestSubmitSection,
  TestVisibilityPermissionSection,
  VariableSection,
} from '@orientaction/components';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TestContext, TestProvider } from '../../Context/TestContext';
import useTest from '../../hooks/useTest';
import Footer from './Footer';
import Category from './Form/Category';
import style from './style';

const AdminCreateTest = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { resetTest } = useContext<any>(TestContext);

  const location = useLocation();

  const { id }: any = useParams();

  const {
    getTestPopulated,
    testTitleRef,
    testTimeRef,
    testImageRef,
    testCategoryRef,
    testVisibilityRef,
  } = useTest();

  useEffect(() => {
    if (location.pathname.includes('/edit')) {
      setLoading(true);
      getTestPopulated(id).then(() => {
        setLoading(false);
      });
    }
    return () => {
      resetTest();
    };
  }, []);

  const cls: any = style();

  if (loading && location.pathname.includes('/edit')) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        }}>
        Chargement en cours...
      </div>
    );
  }

  return (
    <TestProvider>
      <Layout isaddBackground={true}>
        <ContainerAdmin>
          <div className={cls.content}>
            <TestSection
              testTitleRef={testTitleRef}
              testTimeRef={testTimeRef}
              testImageRef={testImageRef}
            />

            <Divider className={cls.divider} />

            <TestVisibilityPermissionSection testVisibilityRef={testVisibilityRef} />

            <Divider className={cls.divider} />

            <Category testCategoryRef={testCategoryRef} />

            <Divider className={cls.divider} />

            <VariableSection />

            <Divider className={cls.divider} />

            <EgalitySection />

            <Divider className={cls.divider} />

            <QuestionSection />

            <Divider className={cls.divider} />

            <FinalTextSection />
          </div>
        </ContainerAdmin>

        <Footer>
          <TestSubmitSection
            testTitleRef={testTitleRef}
            testTimeRef={testTimeRef}
            testImageRef={testImageRef}
            testCategoryRef={testCategoryRef}
            testVisibilityRef={testVisibilityRef}
          />
        </Footer>
      </Layout>
    </TestProvider>
  );
};

export default AdminCreateTest;
