export const context = [
    {
        id: 1,
        title: 'Lequel de ces adjectifs emploie-t-on lorsqu’on parle de vous ?',
        ask: [
            {
                label: 'Organisé(e)',
                name: 'A',
                point: 4
            },
            {
                label: 'Entreprenant(e)',
                name: 'B',
                point: 8
            },
            {
                label: 'Bon(ne) exécutant(e)',
                name: 'C',
                point: 2
            }
        ]
    },
    {
        id: 2,
        title: 'Lorsqu’il faut prendre un risque professionnel (nouveau travail, mutation), votre attitude est :',
        ask: [
            {
                label: 'Foncer',
                name: 'A',
                point: 8
            },
            {
                label: 'Choisir la prudence',
                name: 'B',
                point: 2
            },
            {
                label: 'Calculer le risque',
                name: 'C',
                point: 4
            }
        ]
    },
    {
        id: 3,
        title: 'Votre attitude vis-à-vis de la hiérarchie :',
        ask: [
            {
                label: 'Vous avez besoin de consignes claires pour être efficace',
                name: 'A',
                point: 2
            },
            {
                label: 'Vous acceptez la hiérarchie, mais vous savez être autonome',
                name: 'B',
                point: 4
            },
            {
                label: 'Vous n’aimez pas qu’on vous dirige',
                name: 'C',
                point: 8
            }
        ]
    },
    {
        id: 4,
        title: 'Lorsqu’il faut prendre une décision importante :',
        ask: [
            {
                label: 'Vous préférez décider seul(e)',
                name: 'A',
                point: 8
            },
            {
                label: 'Vous préférez décider de manière collégiale, avec d’autres',
                name: 'B',
                point: 4
            },
            {
                label: 'Vous préférez laisser la décision au (à la) patron(ne)',
                name: 'C',
                point: 2
            }
        ]
    },
    {
        id: 5,
        title: 'Vis-à-vis des difficultés :',
        ask: [
            {
                label: 'Vous avez besoin de trouver du soutien autour de vous',
                name: 'A',
                point: 4

            },
            {
                label: 'Vous vous découragez',
                name: 'B',
                point: 2

            },
            {
                label: 'Vous assumez',
                name: 'C',
                point: 8

            }
        ]
    },
    {
        id: 6,
        title: 'Votre moteur :',
        ask: [
            {
                label: 'Grimper les échelons hiérarchiques',
                name: 'A',
                point: 2
            },
            {
                label: 'La volonté de réussir',
                name: 'B',
                point: 8
            },
            {
                label: 'Être apprécié(e) pour la qualité de votre travail',
                name: 'C',
                point: 4
            }
        ]
    },
    {
        id: 7,
        title: 'La gestion de votre temps :',
        ask: [
            {
                label: 'Vous gérez et organisez votre temps',
                name: 'A',
                point: 4
            },
            {
                label: 'Vous avez besoin de vous caler sur des horaires fixes pour être efficace',
                name: 'B',
                point: 2
            },
            {
                label: 'Quand vous entreprenez quelque chose, vous ne regardez pas le temps',
                name: 'C',
                point: 8
            }
        ]
    },
    {
        id: 8,
        title: 'La phase où vous êtes le(a) plus performant(e) :',
        ask: [
            {
                label: 'Le développement d’affaires',
                name: 'A',
                point: 8
            },
            {
                label: 'La production',
                name: 'B',
                point: 4
            },
            {
                label: 'L’organisation',
                name: 'C',
                point: 2
            }
        ]
    },
    {
        id: 9,
        title: 'Face aux problèmes, quelle est la principale qualité que vous mettez en œuvre ? :',
        ask: [
            {
                label: 'La débrouillardise',
                name: 'A',
                point: 4
            },
            {
                label: 'La volonté',
                name: 'B',
                point: 8
            },
            {
                label: 'La rigueur',
                name: 'C',
                point: 2
            }
        ]
    },
    {
        id: 10,
        title: 'Pour vous, la principale qualité pour réussir est :',
        ask: [
            {
                label: 'Le sens de la progression hiérarchique',
                name: 'A',
                point: 2
            },
            {
                label: 'Le sens relationnel',
                name: 'B',
                point: 4
            },
            {
                label: 'Le sens des affaires',
                name: 'C',
                point: 8
            }
        ]
    },
    {
        id: 11,
        title: 'Dans les relations professionnelles :',
        ask: [
            {
                label: 'Vous avez du culot',
                name: 'A',
                point: 8
            },
            {
                label: 'Vous savez faire valoir votre point de vue sur des choses importantes',
                name: 'B',
                point: 4
            },
            {
                label: 'Vous vous attachez avant tout à bien faire le travail qu’on vous demande',
                name: 'C',
                point: 2
            }
        ]
    },
    {
        id: 12,
        title: 'Votre attitude vis-à-vis de l’entreprise :',
        ask: [
            {
                label: 'La structure de l’entreprise permet de travailler efficacement',
                name: 'A',
                point: 2
            },
            {
                label: 'Dans une entreprise, on est exploité(e) et limité(e) dans ses possibilités',
                name: 'B',
                point: 8
            },
            {
                label: 'Quand on est son (sa) propre patron(ne), on peut s’inspirer du fonctionnement des entreprises existantes',
                name: 'C',
                point: 4
            }
        ]
    }
]