/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import withWidth from '@material-ui/core/withWidth';
import MyResources from './MyResources';
import { CompassUserProvider } from '@orientaction/Context';


const MesRessources = () => {

  return (
        <CompassUserProvider>
            <MyResources/>
        </CompassUserProvider>
  );
};

export default withWidth()(MesRessources);
