/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { FC, useContext, useEffect, useState } from 'react';
import { H1 } from '../../commons/H1';
import RichEditor from '../../commons/RichEditor';
import { TestContext } from '../../Context/TestContext';
import ResponseCreationForm from '../ResponseCreationForm';
import style from './style';

interface IProps {
  test: any;
  updateTest: any;
}

const initialState = {
  id: '',
  title: '',
  isSelected: false,
  aResponse: {
    id: '',
    title: '',
    variable: '',
    variableValue: '',
    variableKey: '',
  },
  bResponse: {
    id: '',
    title: '',
    variable: '',
    variableValue: '',
    variableKey: '',
  },
};

const QuestionCreationForm = () => {
  const [question, setQuestion] = useState(initialState);
  const { test, updateTest } = useContext<any>(TestContext);
  const cls = style();

  useEffect(() => {
    const selectedQuestion = test.questions?.filter((q: any) => q.isSelected === true);
    if (test?.questions?.length > 0 && selectedQuestion.length > 0) {
      setQuestion({
        id: selectedQuestion[0].id ?? '',
        title: selectedQuestion[0].title ?? '',
        isSelected: false,
        aResponse: {
          id: selectedQuestion[0]?.aResponse?.id ?? '',
          title: selectedQuestion[0]?.aResponse?.title ?? '',
          variable: selectedQuestion[0]?.aResponse?.variable ?? '',
          variableValue: selectedQuestion[0]?.aResponse?.variableValue ?? '',
          variableKey: 'A',
        },
        bResponse: {
          id: selectedQuestion[0]?.bResponse?.id ?? '',
          title: selectedQuestion[0]?.bResponse?.title ?? '',
          variable: selectedQuestion[0]?.bResponse?.variable ?? '',
          variableValue: selectedQuestion[0]?.bResponse?.variableValue ?? '',
          variableKey: 'B',
        },
      });
    } else {
      setQuestion(initialState);
    }
  }, [test]);

  const resetState = () => {
    setQuestion(initialState);
  };

  const handleChange = (value: string) => {
    setQuestion({
      ...(test.questions?.filter((q: any) => q.isSelected === true)[0] ?? initialState),
      title: value,
    });
  };

  const handleResponseTitleChange = (variableKey: string) => (value: string) => {
    if (variableKey === 'A') {
      setQuestion({ ...question, aResponse: { ...question.aResponse, title: value } });
    } else {
      setQuestion({ ...question, bResponse: { ...question.bResponse, title: value } });
    }
  };

  const handleResponseVariableChange = (variableKey: string) => (value: any) => {
    if (variableKey === 'A') {
      setQuestion({
        ...question,
        aResponse: {
          ...question.aResponse,
          variableKey: test.variables.filter((v: any) => v.name === value)[0].variableKey,
          variable: value,
        },
      });
    } else {
      setQuestion({
        ...question,
        bResponse: {
          ...question.bResponse,
          variableKey: test.variables.filter((v: any) => v.name === value)[0].variableKey,
          variable: value,
        },
      });
    }
  };

  const handleResponseVariableValueChange = (variableKey: string) => (value: any) => {
    if (variableKey === 'A') {
      setQuestion({ ...question, aResponse: { ...question.aResponse, variableValue: value } });
    } else {
      setQuestion({ ...question, bResponse: { ...question.bResponse, variableValue: value } });
    }
  };

  const isActivated = () => {
    return (
      question.title.length === 0 ||
      question.aResponse.title.length === 0 ||
      question.bResponse.title.length === 0 ||
      question.aResponse.variable.length === 0 ||
      question.bResponse.variable.length === 0 ||
      question.aResponse.variableValue.length === 0 ||
      question.bResponse.variableValue.length === 0
    );
  };

  const addQuestion = async () => {
    const selectedQuestion = test.questions?.filter((q: any) => q.isSelected === true);
    if (selectedQuestion.length === 0 && !isActivated()) {
      // create
      await updateTest({
        ...test,
        questions: [...test.questions, question],
      });
      resetState();
    } else if (!isActivated()) {
      // update
      await updateTest({
        ...test,
        questions: test.questions.map((q: any) => {
          if (q.isSelected) {
            return question;
          }
          return q;
        }),
      });
      resetState();
    }
  };

  const selectedQuestion = test.questions?.filter((q: any) => q.isSelected === true);
  return (
    <Card variant="outlined" style={{ padding: 15, borderRadius: '10px', marginTop: 10 }}>
      <h3 className={cls.h3}>Titre de la question</h3>
      <RichEditor
        value={question.title}
        handleChange={handleChange}
        placeholder="Renseignez le titre de la question"
      />

      {['A', 'B'].map((variable: any, index: number) => {
        return (
          <ResponseCreationForm
            handleResponseVariableValueChange={handleResponseVariableValueChange(variable)}
            handleResponseVariableChange={handleResponseVariableChange(variable)}
            handleResponseTitleChange={handleResponseTitleChange(variable)}
            key={index}
            index={index}
            variables={test.variables}
            responseTitle={variable === 'A' ? question.aResponse.title : question.bResponse.title}
            responseVariable={
              variable === 'A' ? question.aResponse.variable : question.bResponse.variable
            }
            responseVariableValue={
              variable === 'A' ? question.aResponse.variableValue : question.bResponse.variableValue
            }
          />
        );
      })}
      <Box className={cls.btnPrimaryContainer}>
        <Button className={cls.btnPrimary} onClick={addQuestion}>
          {selectedQuestion.length > 0 ? 'Modifier la question' : 'Ajouter la question'}
        </Button>
      </Box>
    </Card>
  );
};

export default QuestionCreationForm;
