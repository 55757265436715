/* eslint-disable no-use-before-define */

import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import { Layout, Modal, RadioGrp, Table, TableTag } from '@orientaction/components';
import clsx from 'clsx';
import React, { FC, ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getFromLS, removeLS } from '../../utils/utils';

const Profile = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const user = JSON.parse(getFromLS('userInfos') || '');

  const onLogout = () => {
    window.localStorage.removeItem('userInfos');
    window.localStorage.removeItem('userToken');
    history.push('/');
  };

  return (
    <Layout isaddBackground={true}>
      <div className={classes.header}>
        <h1 className={classes.title}>Votre profil</h1>
      </div>
      <div className={classes.content}>
        <div className={classes.initial}>
          <span>{`${user?.firstname?.charAt(0).toUpperCase()}${user?.lastname
            ?.charAt(0)
            .toUpperCase()}`}</span>
        </div>
        <div className={classes.name}>
          <h2>{`${user.firstname} ${user.lastname}`}</h2>
        </div>
        <div className={classes.about}>
          <EmailIcon style={{ color: '#E74242' }} />
          <h4>Email</h4>
          <span>{user.email}</span>
        </div>
        <Button className={classes.btnPrimary} onClick={onLogout}>
          Déconnexion
        </Button>
      </div>
    </Layout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 50,
    },
    title: {
      color: '#000A8C',
      fontSize: 36,
      margin: 0,
    },
    content: {
      background: '#fff',
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '115px 10px',
    },
    initial: {
      width: 100,
      height: 100,
      background: '#000A8C 0% 0% no-repeat padding-box',
      borderRadius: '100%',
      color: '#fff',
      fontSize: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        height: '51%',
      },
    },
    btnPrimary: {
      minHeight: 48,
      width: 190,
      textTransform: 'none',
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
    },
    about: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      color: '#0E1247',
      fontSize: 15,
      marginBottom: 25,
      '& h4': {
        marginTop: 10,
      },
      '& span': {
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
      },
    },
    name: {
      color: '#0E1247',
      marginBottom: 25,
    },
  })
);
export default Profile;
