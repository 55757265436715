/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { appLanguage } from '@orientaction/reducers';
import { publicSvgPath, resultatWithColor } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgressCustom from '../CircularProgressCustom';
import ModalCardSens from '../ModalCardSens';
import { useStyles } from './style';

const CardSens = (props: any) => {
  const lang: string = useSelector(appLanguage);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [lang]);

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getImg = (key: string): string => {
    switch (key) {
      case 'compliance':
        return 'en_compliance.png';

      case 'universalism':
        return 'en_UNIVERSALISME_carte.png';

      case 'hedonism':
        return 'en_HEDONISME_carte.png';

      case 'achievement':
        return 'en_REUSSITE_carte.png';

      case 'stimulation':
        return 'en_STIMULATION_carte.png';

      case 'tradition':
        return 'en_TRADITION_carte.png';

      case 'kindness':
        return 'en_BIENVEILLANCE_carte.png';

      case 'security':
        return 'en_SECURITE_carte.png';

      case 'power':
        return 'en_POUVOIR_carte.png';

      case 'autonomy':
        return 'en_AUTONOMIE_carte.png';
      default:
        return '';
    }
  };

  return (
    <Card className={classes.cardStyle}>
      <div>
        <div
          className={classes.titlehead}
          style={{ background: resultatWithColor[props.sens.key].color }}
        >
          <h3 className={classes.titleText}>{props.sens.label} </h3>
        </div>
        {lang && (
          <img
            src={`${publicSvgPath}/${
              lang !== 'FR' ? getImg(props.sens.key) : resultatWithColor[props.sens.key].img
            }`}
            alt=""
            style={{ width: '100%', height: 'auto' }}
          />
        )}
        <CardContent className={classes.cardContent}>
          <div className={classes.positionCircular}>
            <CircularProgressCustom
              value={props.sens.score}
              color={resultatWithColor[props.sens.key].color}
              size={98}
              fontSize={18}
              fontSizeFloat={12}
            />
          </div>
          <Typography variant="h2" color="textPrimary" className={classes.titleContent}>
            {props.sens.description[0]}
          </Typography>
          <br />
          <Typography variant="body2" color="textSecondary" component="p">
            {props.sens.description[1]}
          </Typography>
        </CardContent>
      </div>
      <CardActions className={classes.cardFooter}>
        <Button
          endIcon={<img src={`${publicSvgPath}/bouton_click.svg`} alt="couverture intro" />}
          onClick={() => setIsOpen(true)}
          style={{ textTransform: 'none' }}
        >
          En savoir plus
        </Button>
      </CardActions>
      <ModalCardSens
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        color={resultatWithColor[props.sens.key].color}
        img={`${publicSvgPath}/${
          lang !== 'FR' ? getImg(props.sens.key) : resultatWithColor[props.sens.key].img
        }`}
        sens={props.sens}
        colorSecondary={resultatWithColor[props.sens.key].colorSecondary}
        opacity={!!resultatWithColor[props.sens.key].opacity}
        longText={resultatWithColor[props.sens.key].longText}
        text1={resultatWithColor[props.sens.key].text1}
        listeAccordeon={resultatWithColor[props.sens.key].listeAccordeon}
        listEmploi1={resultatWithColor[props.sens.key].listEmploi1}
        listEmploi2={resultatWithColor[props.sens.key].listEmploi2}
        text2={resultatWithColor[props.sens.key].text2}
        text3={resultatWithColor[props.sens.key].text3}
        text4={resultatWithColor[props.sens.key].text4}
      />
    </Card>
  );
};

CardSens.defaultProps = {
  isSoftSkills: false,
};

export default CardSens;
