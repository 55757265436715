import classnames from 'classnames';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImageAsync } from '../../utils/file';
import styles from './style';

const baseStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: 200,
  height: 133,
  cursor: 'pointer',
};
const baseStyle2 = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: 200,
  height: 133,
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const img = {
  display: 'block',
  width: 200,
  height: 133,
};

interface IProps {
  fileUrl?: string;
  pathPrefix?: string;
  placeholder?: any;
  handleFileUrlChange: (url: string) => void;
  overrideClassname?: any;
  sectionTop?: boolean;
  error?: boolean;
  overrideThumbsContainer?: string;
  overrideThumb?: string;
  overrideThumbInner?: string;
  overrideBaseStyle?: any;
  overrideBaseStyle2?: any;
}

const StyledDropzone: FC<IProps> = ({
  placeholder = 'Ajouter une image à la une',
  fileUrl = '',
  handleFileUrlChange,
  pathPrefix = 'tests',
  overrideClassname = '',
  sectionTop = false,
  error = false,
  overrideThumbsContainer = '',
  overrideThumb = '',
  overrideThumbInner = '',
  overrideBaseStyle = '',
  overrideBaseStyle2 = '',
}) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const uri = URL.createObjectURL(acceptedFiles[0]);
      uploadImageAsync(uri, pathPrefix)
        .then(res => {
          handleFileUrlChange(res);
        })
        .catch((err: any) => console.log('error on upload', err));
    },
  });

  const cls = styles();

  const style: any = useMemo(
    () => ({
      ...(sectionTop
        ? { ...baseStyle, ...overrideBaseStyle }
        : { ...baseStyle2, ...overrideBaseStyle2 }),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [sectionTop, overrideBaseStyle, overrideBaseStyle2, isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className={classnames(cls.dropzoneContainer, overrideClassname)}>
      {fileUrl.length > 0 ? (
        <div className={classnames(cls.thumbsContainer, overrideThumbsContainer)}>
          <div className={classnames(cls.thumb, overrideThumb)}>
            <div className={classnames(cls.thumbInner, overrideThumbInner)}>
              <img src={fileUrl} style={img} alt="thumbs" />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={clsx({
            [cls.inputError]: error,
          })}>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />

            <div>{placeholder()}</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default StyledDropzone;
