/* eslint-disable no-use-before-define */
import { Page, TitleNbr, Paragrapth, TitlePage } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';
import parse from 'html-react-parser';

const Page26 = (props: any) => {

    return (
        <Page id={26}>

            <div style={{ textAlign: 'center' }}>

                <TitlePage>
                    RISQUES ET OPPORTUNITÉS D’UN <br />
                    CHANGEMENT D’EMPLOYEUR
                </TitlePage>

                {
                    context.map((item: any) => (
                        <>
                            <MarginContainer>
                                <TitleNbr number={item.id} text={item.title} />
                            </MarginContainer>

                            {
                                item.listContext.map((itemList: any) => (
                                    <MarginContainer>

                                        <Paragrapth align='start'>

                                            <Paragrapth align='start' bold={true} colorBlue={true}>
                                                {itemList.title} :
                                            </Paragrapth> {parse(itemList.content)}
                                        </Paragrapth>

                                    </MarginContainer>
                                ))
                            }

                        </>
                    ))
                }

            </div>

        </Page>
    );
};

export default Page26;
