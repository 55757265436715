import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imgpremierApercu: {
            height: "100%",
            width: "100%",
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            borderRadius: '4px'
        },
        page: {
            marginTop: '25px',
            marginBottom: '25px',
        },
        content: {
            display: 'block',
            flex: 'none',
            color: '#2B56A5',
            order: 0,
            flexGrow: 0,
        }
    })
);

export default useStyles;