import { Button, CircularProgress, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import style from './style';

interface IContent {
  children: any;
  disable?: boolean;
  to: string;
  loading: boolean;
  to_resultat: string;
  btnRedirectOverride?: any;
  withAccess?: boolean;
  noMarginTop?: boolean;
  accessRedirectButton?: ReactNode;
  isNonRattachedBeneficiary?: boolean;
  withoutAccessRedirectButton?: ReactNode;
  testId?: number | string;
}

const Content: FC<IContent> = ({
  children,
  disable,
  to,
  loading,
  to_resultat,
  btnRedirectOverride,
  withAccess = true,
  noMarginTop = false,
  accessRedirectButton = undefined,
  withoutAccessRedirectButton = undefined,
  isNonRattachedBeneficiary,
  testId = undefined,
}) => {
  const classes = style();

  const { push, location } = useHistory();

  const redirect = (e: any) => {
    e.preventDefault();
    return window.open(`${to}`, '_blank');
  };

  const redirect_resultat = () => {
    push(`${to_resultat}?toBack=${location.pathname}`);
  };

  const getWithAccessButton = () => {
    return accessRedirectButton ? (
      { accessRedirectButton }
    ) : (
      <Button className={classes.btnModal} onClick={redirect}>
        Passer le test
      </Button>
    );
  };

  const getWithoutAccessButton = () => withoutAccessRedirectButton || <div />;

  return (
    <>
      {loading ? (
        <div className={classes.contentLoader}>
          <CircularProgress className={classes.colorWhite} />
        </div>
      ) : (
        <div className={classes.root}>
          <div
            className={clsx({
              [classes.hidden]: !disable,
              [classes.inActif]: disable,
            })}>
            <div className={classes.modal}>
              <Typography className={clsx(classes.modalTitle, classes.noMarginBottom)}>
                {withAccess
                  ? 'Vous n’avez pas encore passé le test, vous ne pouvez pas consulter les résultats'
                  : 'Vous n’avez pas encore accès à ce test.'}
              </Typography>

              {withAccess ? getWithAccessButton() : getWithoutAccessButton()}
            </div>
          </div>

          <div
            className={clsx({
              [classes.container]: true,
              //   [classes.noMarginTop]: noMarginTop,
            })}>
            <div className={classes.btnContent}>
              <div
                className={clsx({
                  [classes.btnRedirect]: true,
                  [classes.disable]: disable,
                  [btnRedirectOverride]: !!btnRedirectOverride,
                })}
                onClick={redirect_resultat}>
                <Typography className={classes.viewResultButton}>Revoir vos résultats</Typography>
                <ArrowForwardIcon className={classes.arrowForwardIcon} />
              </div>
            </div>
            <div
              className={clsx({
                [classes.content]: true,
                [classes.contentInacif]: disable,
              })}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withWidth()(Content);
