/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title, TitleNbr } from '@orientaction/components';
import { useState } from 'react';
import { imgBookIntro, publicBookPath, publicSvgPath } from '../../../../utils/constants';

const Page08 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={8}>
      <div style={{ marginBottom: '20px' }}>
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Ces phrases, nous les avons entendues des milliers de fois dans le secret d’un premier
              entretien, par des milliers de personnes venues chercher un conseil pour faire évoluer
              leur vie professionnelle. Toutes ces personnes avaient des raisons très différentes de
              vouloir changer : mal-être, ennui, lassitude, ras-lebol, besoin de nouveauté, de
              gravir un échelon, d’exprimer son potentiel, de se réaliser, etc.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Mais elles avaient toutes un point commun : elles avaient un même espoir, un même
              désir : le désir de changer!
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Tel est en effet le paradoxe du changement : on désire changer, parfois au plus
              profond de soi-même, mais il n’est pas si facile d’oser le faire! Voilà pourquoi
              certaines personnes qui ne se sentent pas bien dans un métier ou chez leur employeur y
              passeront pourtant toute leur vie. Le changement est quelquefois vital, souvent
              nécessaire, mais rarement simple à réaliser.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Les obstacles sont nombreux. Ils sont aussi bien en nous qu’en dehors de nous. C’est
              la raison pour laquelle nous avons créé cette méthode unique d’accompagnement au
              changement.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={2} text="CHANGER, OUI, MAIS POUR QUELQUE CHOSE DE MIEUX!" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Très souvent, les personnes qui viennent consulter ne prennent pas rendez-vous
              immédiatement. Nombre d’entre elles attendent des mois, voire des années avant de se
              décider à prendre conseil auprès d’un(e) professionnel(le). Elles essaient de changer
              seules.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Le temps passe, leur envie reste. Plusieurs années après, elles se réveillent au même
              endroit.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              D’autres se précipitent et, dans leur précipitation, elles font les mauvais choix :
              elles privilégient un secteur difficile, un métier peu porteur, une organisation
              (entreprise, association, administration) dont l’ambiance ne leur correspond pas. Pour
              vaincre l’immobilisme et/ou éviter de faire les mauvais choix, il est indispensable de
              se faire conseiller et accompagner par un(e) professionnel(le).
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Ce(tte) dernier(ère) vous permettra de trouver votre voie et de sécuriser votre
              transition professionnelle. Mieux vaut adopter les bonnes stratégies et les bonnes
              attitudes lorsque l’on entreprend une évolution professionnelle.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              ORIENTACTION met à votre disposition un(e) consultant(e) qui vous écoute, vous comprend, vous conseille
              et vous accompagne sur le chemin du changement.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '20px' }}>
        <TitleNbr number={3} text="EN RESSORTIR GRANDI(E) PERSONNELLEMENT" isLight={true} />
        <div>
          <img className={classes.showMobile} src={`${imgBookIntro}/gone.png`} alt="gone" />
          <div>
            <img className={classes.img} src={`${imgBookIntro}/gone.png`} alt="gone" />
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                À 20 ans, Cécilia a quitté l’Italie pour venir travailler à Paris. Cécilia avait un
                rêve : devenir cheffe.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Elle aimait faire la cuisine depuis qu’elle était enfant.
                Elle a donc quitté sa ville natale, ses parents et son petit ami pour s’installer à
                Paris, la capitale de la gastronomie.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Elle est entrée dans une école de cuisine et a commencé à étudier en apprentissage.
                Elle étudiait 2 jours à l’école et travaillait 3 jours dans un grand restaurant.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Ce furent des années difficiles, car la cuisine est un domaine difficile et exigeant.
                Elle travaillait jusqu’à 16 heures par jour dans un milieu très masculin et machiste.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Finalement, à l’issue de ses deux premières années d’études, elle s’est aperçue que
                cuisinière n’était pas un métier fait pour elle.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Elle aimait cuisiner pour ses parents, ses amis, son petit ami, mais en faire son métier, non !
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Elle n’aimait pas
                vraiment ça et ce métier était incompatible avec la vie de famille à laquelle elle
                aspirait. Malheureusement il était trop tard. Elle était engagée. Il fallait bien
                payer le loyer à la fin du mois.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '12px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
              C’est seulement cinq ans plus tard que je l’ai
                rencontrée la première fois pour entamer un bilan de compétences.
              </Typography>
            </Grid>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      lineHeight: 1.6,
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    textImg: {
      width: '40%',
      height: 'auto',
    },
    img: {
      width: '30%',
      height: 'auto',
      display: 'block',
      marginLeft: '20px',
      marginTop: '-40px',
      float: 'right',
      '@media only screen and (max-width: 600px)': {
        display:"none"
      },
      '@media only screen and (max-width: 361px)': {
        display:"none"
      },
    },
    showMobile: {
      display: 'none',
      '@media only screen and (max-width: 600px)': {
        display:"block",
        margin: 'auto',
        marginBottom: '30px',
        width: '75%'
      },
      '@media only screen and (max-width: 361px)': {
        display:"block",
        margin: 'auto',
        marginBottom: '20px',
        width: '80%'
      },
    }
  })
);
export default Page08;
