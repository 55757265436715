// eslint-disable-next-line no-use-before-define
import { FC, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from "./style";
import {
  DialogTitle,
  DialogContent,
} from "./Dialog";
import parse from 'react-html-parser'

interface ModalProps extends DialogProps {
  title?: string;
  textValidate?: string;
  noComfirmButton?: boolean;
  noCancelButton?: boolean;
  textCancel?: string;
  onCancel: () => void;
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
  onConfirm?: () => void;
}

const ExtendedModal: FC<ModalProps> = ({
  title,
  className = '',
  textCancel = 'Annuler',
  textValidate = 'Supprimer',
  onCancel,
  open,
  children,
  isLoading = false,
  noCancelButton = false,
  noComfirmButton = false,
  onConfirm,
  ...modalProps
}) => {

  const classes = useStyles();

  const saveText = isLoading ? `${textValidate}...` : textValidate;

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...modalProps}
      className={clsx(classes.modal, className)}
    >

      <DialogTitle id="customized-dialog-title" onClose={onCancel} >
        <h4 className={classes.title}>
          {title ? parse(title) : ""}
        </h4>
      </DialogTitle>

      {children && <DialogContent>{children}</DialogContent>}

      {onConfirm && (

        <div className={classes.containerBtn}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >

            {!noCancelButton && (
              <Grid item={true}>
                <Button onClick={onCancel} variant="outlined" style={{ textTransform: 'none' }}>
                  {textCancel}
                </Button>
              </Grid>
            )}

            {
              !noComfirmButton && (
                <Grid item={true}>
                  <Button
                    onClick={onConfirm}
                    style={{ textTransform: 'none' }}
                    className={classes.validate}
                    disabled={isLoading}>
                    {saveText}
                  </Button>
                </Grid>
              )
            }

          </Grid>

        </div>

      )}

    </Dialog>
  );
};


export default ExtendedModal;
