import { FC } from 'react';
import style from './style';
import clsx from 'clsx';


interface IMarginContainer {
  mormal?: boolean;
  big?: boolean;
  small?: boolean;
  veryBig?: boolean;
  realyVeryBig?: boolean;
  marginH?: boolean
}

const MarginContainer: FC<IMarginContainer> = ({ mormal, big, small, veryBig, children, realyVeryBig, marginH = false }) => {

  const cls = style();

  return (
    <div
      className={clsx({
        [cls.little]: true,
        [cls.small]: small,
        [cls.mormal]: mormal,
        [cls.big]: big,
        [cls.veryBig]: veryBig,
        [cls.realyVeryBig]: realyVeryBig,
        [cls.marginH]: marginH,
      })}
    >
      {children}
    </div>
  );
};

export default MarginContainer;
