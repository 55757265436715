import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '20px',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      padding: '2vh',
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '90%',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 0',
        lineHeight: '150%',
        fontSize: '16px',
        width: '95%',
      },
    },
    imgPiramide: {
      width: '100%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
  }),
);
