/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import { FC } from 'react';
import {
    Typography,
  } from '@material-ui/core';
import { useStyles } from './style';


interface IItemBtn {
    text: string;
    icon: any;
}

const ItemBtn: FC<IItemBtn> = ({ text, icon }) => {

    const classes = useStyles();

    return (
        <>

            <div
                className={classes.chipItem}
            >

                <div className={classes.iconeItem}>
                    {icon}
                </div>

                <div className={classes.textItem}>

                    <Typography variant="inherit" noWrap={true}>
                        {text}
                    </Typography>

                </div>

            </div>

        </>
    );
};

export default ItemBtn;
