import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        imgFurious: {
            width: '80%',
            height: 'auto',
            display: 'block',
            margin: 'auto',
            '@media only screen and (max-width: 600px)': {
                width: '50%',
                marginTop: '0px !important',
            },
            '@media only screen and (max-width: 361px)': {
                width: '50%',
                marginTop: '0px !important',
            },
        },
        imgFurious1: {
            width: '30%',
            height: 'auto',
            display: 'block',
            margin: 'auto',
            '@media only screen and (max-width: 600px)': {
                width: '50%',
                marginTop: '0px !important',
                display: 'none'
            },
            '@media only screen and (max-width: 361px)': {
                width: '50%',
                marginTop: '0px !important',
                display: 'none',
            },
        },
        trait: {
            backgroundColor: 'yellow',
            width: '75px',
            height: '5px',
            marginTop: '50px',
            marginBottom: '50px'
        },
        paragraphe: {
            fontSize: '15px',
            color: 'black',
            '@media only screen and (max-width: 600px)': {
                textAlign: 'left !important',
            },
            '@media only screen and (max-width: 361px)': {
                textAlign: 'left !important',
            },
        },
        testimony: {
            fontSize: '17px',
        },
        positionCenter: {
            display: 'block',
            margin: 'auto'
        },
        cardBgGrey: {
            padding: 20,
            margin: '2vh 0',
            background: '#e8eef4',
            fontStyle: 'italic',
            '@media only screen and (max-width: 600px)': {
                padding: 20,
            },
            '@media only screen and (max-width: 361px)': {
                padding: 15,
            },
        },
        img: {
            width: '60%',
        }
    })
);

export default useStyles;