import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Close from '@material-ui/icons/Close';
import CircularProgressCustom from '@orientaction/components/CircularProgressCustom';
import { appLanguage } from '@orientaction/reducers';
import { publicImagePath } from '@orientaction/utils';
import clsx from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { modalStaticBlockElement } from './constant';
import style from './style';

interface IProps {
  open: boolean;
  variable: any;
  onCancel: () => void;
  opacity: boolean;
  colorSecondary: string;
}

const VariableDetailsModal: FC<IProps> = ({
  open,
  variable,
  opacity,
  colorSecondary,
  onCancel,
}) => {
  const classes = style({ color: opacity ? 'white' : colorSecondary });
  const lang: string = useSelector(appLanguage);
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const getImg = (key: string): string => {
    switch (key) {
      case 'accomplishment_pro':
        return '/images/need/en_page_detail_accomplissement.png';

      case 'vertical_belonging_pro':
        return '/images/need/en_page_detail_appartenance_verticale.png';

      case 'horizontal_belonging_pro':
        return '/images/need/en_page_detail_appartenance_horizontale.png';

      case 'accomplishment_perso':
        return '/images/need/en_perso_carte_needs_accompli.png';

      case 'vertical_belonging_perso':
        return '/images/need/en_perso_page_app_verticale_2.png';

      case 'spirituality_pro':
        return '/images/need/en_page_detail_spiritualite.png';

      case 'eternity_pro':
        return '/images/need/en_page_detail_eternite.png';

      case 'physiological_pro':
        return '/images/need/en_page_detail_physiologie.png';

      case 'eternity_perso':
        return '/images/need/en_perso_carte_needs_eternite.png';

      case 'physiological_perso':
        return '/images/need/en_perso_page_physio.png';

      case 'security_pro':
        return '/images/need/en_page_detail_securite.png';

      case 'security_perso':
        return '/images/need/en_perso_carte_needs_securite.png';

      case 'spirituality_perso':
        return '/images/need/en_perso_carte_needs_spirit.png';

      case 'self_realization_pro':
        return '/images/need/en_page_detail_realisation_soi.png';

      case 'self_realization_perso':
        return '/images/need/en_perso_page_realisation_soi.png';

      default:
        return '/images/need/en_perso_page_app_horizontale.png';
    }
  };

  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (!variable) return null;

  const staticElement = modalStaticBlockElement[variable.key];
  const labels = variable.label.split(' ');
  const firstLabel = labels[0];
  const secondLabel = labels[1];
  const thirdLabel = labels[2];

  const score = (variable.score * 100) / 125;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={isTab ? 'xl' : 'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogContainer}
    >
      <DialogContent style={{ position: 'relative', padding: 0 }}>
        <IconButton aria-label="delete" className={classes.closeBtn} onClick={onCancel}>
          <Close />
        </IconButton>
        <Grid container={true}>
          <Grid item={true}>
            <div className={classes.titlehead} style={{ background: variable.color }}>
              <div className={classes.titleTextContainer}>
                <span className={classes.titleText}>{firstLabel}</span>
                {secondLabel && <span className={classes.titleText}>{secondLabel}</span>}
                {thirdLabel && <span className={classes.titleText}>{thirdLabel}</span>}
              </div>
            </div>
          </Grid>
          <Grid item={true} xs={true} sm={true} md={5}>
            <img
              src={
                lang !== 'FR'
                  ? getImg(variable.key)
                  : `${publicImagePath}/need/${staticElement.img}`
              }
              alt=""
              style={{ width: '100%' }}
              className={classes.imgHeight}
            />
          </Grid>
          <Grid item={true} sm={12} xs={12} md={true}>
            <div className={classes.paddingGridtext}>
              <div className={classes.positionCircular2}>
                <CircularProgressCustom
                  value={variable.score}
                  color={variable.color}
                  size={130}
                  fontSize={23}
                  fontSizeFloat={15}
                  totalValue={125}
                  calculatedValue={score}
                />
              </div>
              <Typography
                variant="h2"
                color="textPrimary"
                className={clsx(classes.titleContent, classes.widthTitle)}
              >
                Votre besoin pour être heureux(se)
              </Typography>
              <br />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={clsx(classes.body2TextOverride, classes.marginBottomMobile)}
                dangerouslySetInnerHTML={{ __html: staticElement.toBeHappy.content }}
              />
            </div>
          </Grid>
          <Grid />
        </Grid>
        <div className={classes.paddingGridText2} style={{ background: variable.color }}>
          <Grid container={true} spacing={4}>
            <Grid item={true} md={6}>
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ color: 'white', textTransform: 'none', fontSize: 21 }}
              >
                Les environnements professionnels à privilégier :
              </Typography>
              <br />
              {staticElement.environmentToPrivilege.type === 'paragraph' ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ color: 'white' }}
                  className={clsx(classes.body2TextOverride, classes.text1)}
                >
                  {staticElement.environmentToPrivilege.content}
                </Typography>
              ) : (
                <ul className={clsx(classes.listUl, classes.marginTopMobile)}>
                  {staticElement.environmentToPrivilege.content?.map((elem: any) => (
                    <li className={classes.listLi}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ color: 'white' }}
                        className={classes.body2TextOverride}
                      >
                        {elem}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </Grid>
            <Grid item={true} md={6}>
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ color: 'white', textTransform: 'none', fontSize: 21 }}
              >
                Les environnements professionnels à éviter :
              </Typography>
              <br />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ color: 'white' }}
                className={clsx(classes.body2TextOverride, classes.text1)}
              >
                {staticElement.environmentToAvoid.content}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VariableDetailsModal;
