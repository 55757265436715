/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, TitleRomain } from '@orientaction/components';
import { useBook2 } from '@orientaction/hooks_book';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page20 = (props: any) => {
  const classes = useStyles();
  const {
    setResponse7,
    setResponse8,
    setResponse9,
    setResponse10,
    setResponse11,
    response7,
    response8,
    response9,
    response10,
    response11,
  } = useBook2();
  const [response7_, setResponse7_] = useState<any>(response7);
  const [response8_, setResponse8_] = useState<any>(response8);
  const [response9_, setResponse9_] = useState<any>(response9);
  const [response10_, setResponse10_] = useState<any>(response10);
  const [response11_, setResponse11_] = useState<any>(response11);
  const question7 = [
    {
      name: '',
      text: 'Toujours avoir de l’argent sur votre compte en banque ',
    },
    {
      name: '',
      text: 'Être propriétaire de votre maison',
    },
    {
      name: '',
      text: 'Être fonctionnaire',
    },
    {
      name: '',
      text: 'Avoir des compétences recherchées sur votre marché',
    },
    {
      name: '',
      text: 'Travailler dans une grande organisation (entreprise, association, administration)',
    },
  ];
  const question8 = [
    {
      name: '',
      text: 'CDI',
    },
    {
      name: '',
      text: 'CDD ou contractuel(le)',
    },
    {
      name: '',
      text: 'Intérimaire',
    },
    {
      name: '',
      text: 'Fonctionnaire',
    },
    {
      name: '',
      text: 'Entrepreneur(se)',
    },
  ];
  const question9 = [
    {
      name: '',
      text: 'Oui',
    },
    {
      name: '',
      text: 'Non',
    },
  ];
  const question10 = [
    {
      name: '',
      text: 'Vous appartenez à une équipe',
    },
    {
      name: '',
      text: 'Vos collègues sont aussi des ami(e)s',
    },
    {
      name: '',
      text: 'Vous travaillez dans une organisation connue et reconnue',
    },
    {
      name: '',
      text: 'Vous travaillez dans une organisation prestigieuse',
    },
    {
      name: '',
      text: 'Le besoin d’appartenance n’est pas un besoin fort chez vous',
    },
  ];
  const question11 = [
    {
      name: '',
      text: 'Être considéré(e) comme un(e) expert(e) dans votre domaine',
    },
    {
      name: '',
      text: 'Être populaire',
    },
    {
      name: '',
      text: 'Bénéficier d’une certaine notoriété',
    },
    {
      name: '',
      text: 'Vous sentir utile',
    },
    {
      name: '',
      text: 'Avoir un salaire important',
    },
    {
      name: '',
      text: 'Travailler dans une grande organisation',
    },
    {
      name: '',
      text: 'Avoir des promotions régulièrement',
    },
    {
      name: '',
      text: 'Avoir un poste élevé dans la hiérarchie',
    },
    {
      name: '',
      text: 'Recevoir régulièrement des compliments de la part de votre entourage professionnel',
    },
  ];
  const handleChange = (e: any, index: number) => {
    const { name, checked } = e.target;
    if (index === 7) {
      setResponse7_({ [name]: checked });
      setResponse7({ [name]: checked });
    } else if (index === 8) {
      setResponse8_({ [name]: checked });
      setResponse8({ [name]: checked });
    } else if (index === 9) {
      setResponse9_({ [name]: checked });
      setResponse9({ [name]: checked });
    } else if (index === 10) {
      setResponse10_({ [name]: checked });
      setResponse10({ [name]: checked });
    } else if (index === 11) {
      setResponse11_({ [name]: checked });
      setResponse11({ [name]: checked });
    }
  };
  return (
    <Page id={20} readOnly={props.readOnly}>
      <div style={{ border: '2px solid grey', padding: '15px' }}>
        <div className={classes.cardBgGrey}>
          <TitleRomain number="II" text="VOS BESOINS DE SÉCURITÉ" />
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={7}
            text="Quel est pour vous le moyen le plus sûr pour vous sentir en sécurité ? "
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question7.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 7)}
                            checked={!!response7_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={8}
            text="De quel contexte de travail avez-vous besoin pour vous sentir en sécurité ?"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question8.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 8)}
                            checked={!!response8_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={9}
            text="Dans l’optique du changement souhaité, êtes-vous prêt(e) à prendre certains risques ?"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question9.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 9)}
                            checked={!!response9_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className={classes.cardBgGrey}>
          <TitleRomain number="III" text="VOS BESOINS D’APPARTENANCE" />
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={10}
            text="Votre besoin d’appartenance est satisfait si..."
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question10.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 10)}
                            checked={!!response10_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px' }}>
          <TitleNbr
            number={11}
            text="Pour vous sentir pleinement reconnu(e), vous avez besoin de :"
            isSmall={true}
          />
          <div style={{ marginTop: '10px' }}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)' }}>
              <FormControl component="fieldset">
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {question11.map((elem: any) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            name={elem.text}
                            onChange={(e: any) => handleChange(e, 11)}
                            checked={!!response11_[elem.text]}
                          />
                        }
                        label={<div style={{ color: 'black' }}>{elem.text}</div>}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page20;
