// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const url = config.api_url;

export const createBook = async (token: string, data: any) => {
  return makeRequest(`${url}/api/books`, 'POST', { data }, getHeaders(token));
};

export const createBookCustom = async (token: string, data: any) => {
  return makeRequest(`${url}/api/book-create`, 'POST', { data }, getHeaders(token));
};

export const getBooks = async (token: string) => {
  return makeRequest(`${url}/api/books`, 'GET', {}, getHeaders(token));
};

