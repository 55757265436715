import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    textStyleInside: {
      fontSize: '2rem',
      textAlign: 'center',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.4rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.4rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    textPositionYes: {
      position: 'absolute',
      color: '#2B56A5',
      top: '9.5%',
      left: '18.5%',
      transform: 'translate(-50%, -25%)',
    },
    textPositionNo: {
      position: 'absolute',
      color: '#2B56A5',
      top: '9.5%',
      left: '82%',
      transform: 'translate(-50%, -25%)',
    },
  })
);

export default useStyles;
