/* eslint-disable no-use-before-define */
import { figure32_page2 } from '../../../../utils/constants';
import { TableDestin, Page } from '@orientaction/components';

const Page23 = (props: any) => {
  return (
    <Page id={23}>
      <TableDestin head={false} figure32={figure32_page2} />
    </Page>

  );
};

export default Page23;
