import { Typography } from '@material-ui/core';
import { FC } from 'react';
import useStyle from './style';

interface IProps {
  puceNumber: number;
  isSmall?: boolean;
  isItalic?: boolean;
  puceOverrideStyle?: any;
  contentOverrideStyle?: any;
  childrenOverrideStyle?: any;
  textOverrideStyle?: any;
  showPuce?: boolean;
}

const WithPuceSubtitle: FC<IProps> = ({
  puceNumber,
  children,
  isSmall = false,
  isItalic = false,
  puceOverrideStyle = undefined,
  contentOverrideStyle = undefined,
  childrenOverrideStyle = undefined,
  textOverrideStyle = undefined,
  showPuce = true,
}) => {
  const classes = useStyle();

  return (
    <div className={contentOverrideStyle || classes.content}>
      {showPuce && (
        <span className={puceOverrideStyle || classes.puce}>
          <strong>{puceNumber}</strong>
        </span>
      )}
      <Typography className={childrenOverrideStyle || classes.childrenStyle}>
        <strong className={isSmall ? classes.small : classes.h3}>
          <span className={isItalic ? classes.italic : textOverrideStyle}>{children}</span>
        </strong>
      </Typography>
    </div>
  );
};

export default WithPuceSubtitle;
