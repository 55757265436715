import { FC } from 'react';
import style from './style';

interface IProps {
  children: any;
}

const CircledElement: FC<IProps> = ({ children, ...rest }) => {
  const classes = style();
  return (
    <div {...rest} className={classes.circle}>
      {children}
    </div>
  );
};

export default CircledElement;
