/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable global-require */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

declare const window: any;
// eslint-disable-next-line no-use-before-define

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderGradient: {
      background:
        'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
      padding: '1.5px',
      borderRadius: '5px',
    },
    fontButton: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      color: '#0E1247',
      textTransform: 'capitalize',
    },
    buttonStyle: {
      background: 'white',
      width: '100%',
      paddingLeft: '50px',
      paddingRight: '50px',
      '&:hover': {
        color: 'white',
      },
      '&:hover span': {
        color: 'white',
      },
    },
    marginIcon: {
      marginRight: '5px',
    },
    styleInput: {
      border: '1px solid #CDCEDA',
      boxSizing: 'border-box',
      borderRadius: '4px',
    },
    trait: {
      width: '75px',
      height: '25px'
    },
    dialogue: {
      '& .MuiDialog-paper': {
        width: '40%',
        '@media only screen and (max-width: 600px)': {
          width: '80%',
        },
        '@media only screen and (max-width: 361px)': {
          width: '100%',
        },
      }
    },
    itemList: {
      fontSize: 15,
      color: '#2b56a5'
    },
  })
);
const ColorSelection = (props: any) => {
  const classes = useStyles()

  // useEffect(() => {
  //   shareInstagramPost();
  // }, [facebookUserAccessToken]);

  const handleClose = () => {
    props.callBack(false);
  };
  const chooseColor = (color: string) => {
    props.callBack(false);
    props.colorChoosed(color);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogue}
        maxWidth="xl">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          style={{ position: 'absolute', top: '5px', right: '5px' }}
          onClick={handleClose}>
          <ClearIcon fontSize="small" />
        </IconButton>
        <DialogTitle id="alert-dialog-title" style={{ fontSize: '24px', textAlign: 'center' }}>
          Choisissez une couleur
        </DialogTitle>
        <DialogContent>
          <Grid container={true}>
            <Grid item={true} xs={12} sm={6}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button={true} onClick={() => chooseColor('#2e96d3')}>
                  <ListItemText primary="Professionnel : " className={classes.itemList} />
                  <span className={classes.trait} style={{background: '#2e96d3', marginLeft:'1px'}} />
                </ListItem>
                <ListItem button={true} onClick={() => chooseColor('#e2e318')}>
                  <ListItemText primary="Familial : " className={classes.itemList} />
                  <span className={classes.trait} style={{background: '#e2e318'}} />
                </ListItem>
                <ListItem button={true} onClick={() => chooseColor('#11af4b')}>
                  <ListItemText primary="Couple : " className={classes.itemList} />
                  <span className={classes.trait} style={{background: '#11af4b'}} />
                </ListItem>
              </List>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button={true} onClick={() => chooseColor('#f2655b')}>
                  <ListItemText primary="Social : " className={classes.itemList} />
                  <span className={classes.trait} style={{background: '#f2655b'}} />
                </ListItem>
                <ListItem button={true} onClick={() => chooseColor('#ed1748')}>
                  <ListItemText primary="Intime : " className={classes.itemList} />
                  <span className={classes.trait} style={{background: '#ed1748'}} />
                </ListItem>
                <ListItem button={true} onClick={() => chooseColor('#939598')}>
                  <ListItemText primary="Sommeil : " className={classes.itemList} />
                  <span className={classes.trait} style={{background: '#939598'}} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ColorSelection;
