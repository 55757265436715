// eslint-disable-next-line simple-import-sort/imports
import { Page, Somaire } from '@orientaction/components';

const Page06 = (props: any) => {
  return (
    <Page id={6}>
      <Somaire
        list={[
          {
            text: 'MIEUX CONNAÎTRE VOS BESOINS, VOS MOTIVATIONS ET VOTRE PERSONNALITÉ',
            number: '7',
            isBold: true,
          },
          {
            text: 'QUELS SONT VOS BESOINS ?',
            number: '11',
            isBold: true,
          },
          {
            text: 'MIEUX CONNAÎTRE VOS BESOINS',
            number: '12',
          },
          {
            text: 'LES 6 BESOINS DE ROBINSON',
            number: '13',
          },
          {
            text: 'LA PYRAMIDE DE MASLOW ET LES 6 FIGURES DU CHEF',
            number: '14',
          },
          {
            text: 'CHOISISSEZ MAINTENANT L’ORGANISATION CAPABLE DE RÉPONDRE À VOS BESOINS !',
            number: '18',
          },
          {
            text: 'QUINZE QUESTIONS POUR PRÉCISER VOS BESOINS',
            number: '19',
          },
          {
            text: 'LE CERCLE DES ÉQUILIBRES',
            number: '23',
          },
          {
            text: 'VOS BESOINS ET VOS DOMAINES DE VIE',
            number: '26',
          },
          {
            text: 'QUELLES SONT VOS VÉRITABLES MOTIVATIONS ?',
            number: '28',
            isBold: true,
          },
          {
            text: 'VOTRE MOTIVATION EST-ELLE PLUTÔT INSTRUMENTALE OU PLUTÔT FINALE ?',
            number: '31',
          },
          {
            text: 'LES ACTIVITÉS QUI VOUS RENDENT HEUREUX(SE) ET PERFORMANT(E)',
            number: '32',
          },
          {
            text: 'QUELLE EST VOTRE VÉRITABLE PERSONNALITÉ ?',
            number: '33',
            isBold: true,
          },
          {
            text: 'MIEUX CONNAÎTRE VOTRE VÉRITABLE PERSONNALITÉ',
            number: '34',
          },
          {
            text: 'TESTEZ VOTRE PERSONNALITÉ MAINTENANT !',
            number: '35',
          },
          {
            text: 'VOS QUALITÉS PERSONNELLES',
            number: '36',
          },
          {
            text: 'VOS AXES DE PROGRESSION',
            number: '37',
          },
          {
            text: 'DÉCOUVRIR VOTRE PERSONNALITÉ EN GROUPE',
            number: '38',
          },
          {
            text: 'TEST : TEAM PERSO',
            number: '40',
          },
        ]}
      />
    </Page>
  );
};

export default Page06;
