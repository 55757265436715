/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro, publicBookPath, publicSvgPath } from '../../../../utils/constants';

const Page27 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={27}>
      <div>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            className="opacity07">
            TROIS TECHNIQUES POUR
            <br />
            CONVAINCRE SES PROCHES
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <TitleNbr number={1} text="POUR CHANGER VOUS-MÊME, COMMENCEZ PAR CHANGER LES AUTRES" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Nos proches sont soit les meilleurs soutiens, soit les pires obstacles au changement.
              Il faut donc adopter les bonnes attitudes de façon à bénéficier au maximum du soutien
              des personnes positives et minimiser l’impact des personnes toxiques.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <TitleNbr number={2} text="EN PARLER DE FAÇON CLAIRE ET AFFIRMÉE" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              La première étape est d’en parler. En parler, oui, mais sans trop en dire… du style :
              « J’ai envie de changer, je ne sais pas encore ce que je vais faire exactement, mais
              ce sera quelque chose de bien, c’est certain. »
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Cette première approche permettra de préparer votre entourage à l’idée du changement,
              de détecter celles et ceux qui sont « pour » et celles et ceux qui sont « contre ». La
              chose à ne surtout pas faire est de ne rien dire et d’arriver avec un projet tout
              prêt. Ce type de comportement suscitera à coup sûr le rejet de vos proches, provoquera
              des conflits et des disputes.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <TitleNbr number={3} text="IMPLIQUER ET MOBILISER AUTOUR DE SOI " />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              La meilleure façon de rendre nos proches ouverts au changement est de les impliquer
              dans le processus : j’ai envie de changer, je sais que c’est important pour toi que je
              sois heureux(se), que nous soyons heureux, est-ce que tu veux bien m’aider?
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              En engageant nos proches à nos côtés, nous nous assurons de leur soutien. Nous les
              préparons à cette idée.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Changer est une aventure humaine dont l’objectif est le bonheur. Qui n’aurait pas
              envie de participer à ce genre d’aventure? Et puis, comme on ne réussit jamais tout(e)
              seul(e)…
            </Typography>
          </Grid>
        </Grid>
      </div>
      <br />
      <div>
        <TitleNbr number={4} text=" ÉVITER LES PERSONNALITÉS TOXIQUES, CHOISIR TROIS SPONSORS" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Parmi vos proches, vous allez vite découvrir des personnalités toxiques.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Ce sont des personnes qui ne veulent pas que vous changiez. Pour elles, tout va bien.
              Elles ne voient aucune raison que cela change! Elles n’ont surtout pas envie de revoir
              leurs habitudes. Mieux vaut ne pas parler de son projet à ce type de personnes.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              D’autres au contraire se montreront particulièrement enthousiastes. Parmi elles, vous
              devrez choisir trois sponsors, c’est-à-dire trois personnes à qui vous pourrez parler
              régulièrement de votre projet, qui pourront vous conseiller et vous soutenir.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <img
        src={`${imgBookIntro}/toxic.png`}
        alt="patissier"
        style={{
          width: '80%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 50
        }}
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '0px',
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    textImg: {
      width: '40%',
      height: 'auto',
    },
  })
);
export default Page27;
