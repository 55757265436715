/* eslint-disable no-use-before-define */

import { Select } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Timeline from '@material-ui/lab/Timeline';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import { ResultInterface } from '@orientaction/interfaces';
import clsx from 'clsx';
import dateFormat from 'dateformat';
import React, { FC, ReactNode, useState } from 'react';

type TimelineProps = {
  testResults: ResultInterface.IResultFilter[];
  onChange: (value: number) => void;
};

const WithResult: FC<TimelineProps> = ({ testResults, onChange }) => {
  const classes = useStyles();
  const [indexPeriod, setindexPeriod] = useState(0);

  const handleChange = (index: number) => {
    setindexPeriod(index);
    onChange(index);
  };
  return (
    <div className={classes.timeline}>
      <div className={classes.timelineDesk}>
        <div className={classes.timelineTitle}>
          <DateRangeIcon fontSize="small" style={{ marginRight: 7 }} />
          Vos tests précédents
        </div>
        <Timeline>
          {testResults.map((test: ResultInterface.IResultFilter, index: number) => {
            return (
              <TimelineItem key={Math.random()}>
                <TimelineSeparator>
                  <TimelineDot className={classes.customDot} />
                  {index !== testResults.length - 1 && (
                    <TimelineConnector className={classes.customConector} />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <span
                    className={clsx(
                      classes.labelPeriod,
                      index === indexPeriod ? classes.isHighlighted : null
                    )}
                    onClick={(e: any) => handleChange(index)}>
                    {test?.attributes?.createdAt
                      ? dateFormat(new Date(test?.attributes?.createdAt), 'dd/mm/yyyy, HH:MM')
                      : '-'}
                    {/* haha */}
                  </span>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </div>
      <div className={classes.timelineMob}>
        <Select
          native={true}
          inputProps={{ className: classes.inputSelect }}
          value={indexPeriod}
          variant="outlined"
          onChange={(e: any) => handleChange(parseInt(e.target.value, 10))}>
          <option key={1} disabled={true}>
            Sélectionnez un test précédent
          </option>
          {testResults.map((test: ResultInterface.IResultFilter, index: number) => {
            return (
              <option key={Math.random()} value={index}>
                {test?.attributes?.createdAt
                  ? dateFormat(new Date(test?.attributes?.createdAt), 'dd/mm/yyyy')
                  : test.createdAt
                  ? dateFormat(new Date(test.createdAt), 'dd/mm/yyyy')
                  : '-'}
                {/* haha */}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeline: {
      '@media(min-width: 1250px)': {
        height: 'calc(100vh - 90px)',
        position: 'sticky',
        top: 0,
        left: 0,
        overflow: 'auto',
        width: 286,
        padding: 31,
        background:
          'transparent linear-gradient(180deg, #000A8C 0%, #000763 100%) 0% 0% no-repeat padding-box',
        color: '#fff',
        '& .MuiTimeline-root': {
          padding: 0,
          justifyContent: 'center',
          alignItems: 'flex-start',
        },
        '& .MuiTimelineItem-missingOppositeContent:before': {
          padding: 0,
          flex: 'none',
        },
      },
    },
    timelineDesk: {
      display: 'none',
      '@media(min-width: 1250px)': {
        display: 'block',
      },
    },
    timelineMob: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
      '@media(min-width: 1250px)': {
        display: 'none',
      },
      '& .MuiOutlinedInput-root': {
        border: '1px solid #E3E8F0',
        width: '100%',
        maxWidth: 327,
        margin: 'auto',
      },
    },
    timelineTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    customDot: {
      backgroundColor: '#fff',
      padding: 2,
      marginTop: 12,
    },
    customConector: {
      width: 3,
      backgroundColor: 'transparent',
      backgroundSize: '3px 10px',
      backgroundImage: 'radial-gradient(rgb(255 255 255) 0%, rgb(255 255 255) 16%, #ff141400 42%)',
    },
    labelPeriod: {
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      fontSize: 15,
      width: '100%',
      padding: '10px',
      cursor: 'pointer',
      borderRadius: 3,
      '&:hover': {
        background: '#333B9F',
      },
    },
    isHighlighted: {
      background: '#333B9F',
    },
    inputSelect: {
      '& + .MuiSelect-iconOutlined': {
        color: 'inherit',
      },
    },
  })
);
export default WithResult;
