/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import {
    Page,
} from '@orientaction/components';
import useStyles from './style';
import { MarginContainer, TextArea } from '@orientaction/commons';
import { listExp } from './context';
import { useBook5 } from '@orientaction/hooks_book';
import BorderArray from '../../components/BorderArray';

const Page16 = (props: any) => {
    const classes = useStyles();

    const { experience, setExperience } = useBook5();

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setExperience({
            ...experience,
            [name]: value
        })

    }

    return (
        // eslint-disable-next-line prettier/prettier
        <Page
            id={16}
            readOnly={false}
        >

            <div
                className={classes.titlePage}
            >
                QUELLES SONT LES TROIS EXPÉRIENCES <br />
                QUI PEUVENT VOUS AIDER À RÉUSSIR ?
            </div>

            <MarginContainer big={true}>

                {
                    listExp.map((item: any) =>
                        <MarginContainer>

                            <MarginContainer>
                                <strong className={classes.strong}>
                                    <i>
                                        {item.label}
                                    </i>
                                </strong>
                            </MarginContainer>

                            <TextArea
                                name={item.name}
                                value={experience?.[item.name] || ""}
                                onChange={handleChange}
                                readOnly={props.readOnly}
                            />

                        </MarginContainer>
                    )
                }

            </MarginContainer>

            <BorderArray
                readOnly={props.readOnly}
                handleChange={handleChange}
                name='addValue'
                largeTextField={true}
                defaultValue={experience?.addValue || ""}
                title="QUELLE EST LA VALEUR AJOUTÉE DE VOTRE PROFIL ?"
                paragraph='En quoi votre profil peut intéresser un(e) futur(e) recruteur(se) ? <br/>
                 « Qu’est-ce qui fait que vous êtes un très joli fruit qu’on a envie de manger ? »'
            />

        </Page>

    );
};

export default Page16;
