import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeft: '1px solid'
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  borderTable: {
    border: '1px solid'
  },
});

export default useStyles;
