/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';


interface IPodium {
    first: any;
    second: any;
    third: any;
}

const Podium: FC<IPodium> = ({
    first, second, third
}) => {

    const classe = useStyles();

    return (

        <>
            <div
                className={clsx({
                    [classe.withBorder]: true,
                    [classe.root]: true
                })}
            >
                <Grid
                    container={true}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    <Grid item={true}>
                        <div
                            className={clsx({
                                [classe.second]: true,
                                [classe.contentPodium]: true,
                            })}
                        >
                            {second?.score || 14}
                        </div>
                    </Grid>
                    <Grid item={true}>
                        <div
                            className={clsx({
                                [classe.first]: true,
                                [classe.contentPodium]: true,
                            })}
                        >
                            {first?.score || 18}
                        </div>
                    </Grid>
                    <Grid item={true}>
                        <div
                            className={clsx({
                                [classe.third]: true,
                                [classe.contentPodium]: true,
                            })}
                        >
                            {third?.score || 6}
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div
                className={clsx({
                    [classe.root]: true
                })}
            >
                <Grid
                    container={true}
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-end"
                >
                    <Grid item={true}>
                        <div
                            className={clsx({
                                [classe.titleContent]: true,
                            })}
                        >
                            {second?.label || ''}
                        </div>
                    </Grid>
                    <Grid item={true}>
                        <div
                            className={clsx({
                                [classe.titleContent]: true,
                            })}
                        >
                            <b>
                                {first?.label || ''}
                            </b>
                        </div>
                    </Grid>
                    <Grid item={true}>
                        <div
                            className={clsx({
                                [classe.titleContent]: true,
                            })}
                        >
                            {third?.label || ''}
                        </div>
                    </Grid>
                </Grid>
            </div>

        </>

    );
};

export default Podium;
