/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setYouCan =
  (data: boolean): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'YOUCAN',
      payload: data,
    });
  };

export const setRef =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'REF',
      payload: data,
    });
  };
