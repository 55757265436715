import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    dot: {
      '& .MuiTimelineDot-defaultGrey': {
        color: '#FFFFFF',
        backgroundColor: '#FFFFFF',
      },
      '& .MuiTimelineDot-root': {
        padding: 8,
        marginBottom: 0,
        marginTop: 0,
      },
      width: 'calc(96vw - 20px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    content: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '3rem',
      color: '#FFFFFF',
      marginTop: -46,
      marginLeft: 33,
      wordBreak: 'break-all',
      [theme.breakpoints.down('sm')]: {
        marginTop: -15,
        fontSize: '1.8rem',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: -9,
        fontSize: '1rem',
      },
    },
    horizontalLine: {
      position: 'absolute',
      top: '0.6rem',
      left: 40,
      borderBottom: '2px solid #fff',
      width: 40,
    },
    children: {
      marginTop: 20,
      marginBottom: '3vh',
      borderRadius: '16px',
      overflow: 'hidden',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '30px',
      color: '#fff',
      textTransform: 'uppercase',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        margin: '2vh 0',
        width: '90%',
      },
    },
    pageTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '48px',
    },
  })
);
