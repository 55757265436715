import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    imageBtn: {
      width: '110%',
      height: 'auto',
      display: 'block',
      marginTop: 45,
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        width: '90%',
      },
    },
    displayFlexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    titleHeader: {
      fontSize: 45,
      color: 'white',
      textTransform: 'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: 35,
        paddingTop: 40,
        textAlign: 'center',
      },
    },
    header: {
      background:
        'transparent linear-gradient(106deg, #000A8C 0%, #E63C3C 47%, #FFDC37 100%) 0% 0% no-repeat padding-box',
    },
  }),
);
