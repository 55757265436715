/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../ItemTypes';
import CardTest from '../CardTest';
import { useStyles } from './style';
import { ContentFlex } from '@orientaction/components';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useCategoryTest } from '@orientaction/hooks';
import clsx from 'clsx';

export interface CardProps {
  index: number;
  id: string;
  category: number;
  data: any;
  moveCard: (id: string, to: number, parent: number, isHover: boolean) => void;
  isHover: boolean;
  findCard: any;
  updateCategoryEmpty: (id: number, category: number) => void;
  comfirmDrop: () => void;
}

interface Item {
  id: string;
  originalIndex: number;
}

export const Card: FC<CardProps> = ({
  id,
  data,
  index,
  category,
  isHover,
  findCard,
  moveCard,
  updateCategoryEmpty,
  comfirmDrop,
}) => {
  const classe = useStyles();

  const originalIndex = findCard(id).index;

  const { listTest } = useCategoryTest();

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex, category },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item: any, monitor) => {
        const dropResult = monitor.getDropResult<any>();

        const findCardId = listTest.find((x: any) => x.id === +id);

        const historyCategory: any[] = findCardId?.historyCategory || [];

        if (
          listTest.filter((x: any) => x.category === dropResult?.id_category && x.isHover)
            .length === 0 &&
          category !== dropResult?.id_category &&
          historyCategory.filter((x: any) => x.parent === dropResult?.id_category).length === 0
        ) {
          updateCategoryEmpty(item.id, dropResult?.id_category);
          return;
        }

        if (listTest.filter((x: any) => x.category === category && x.isHover).length === 1) {
          comfirmDrop();
        }
      },
    }),
    [id, originalIndex, moveCard, listTest]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover: ({ id: draggedId }: Item) => {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex, category, true);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isHover || isDragging ? 0 : 1;

  return (
    <div ref={drop} style={{ opacity }} className={classe.cardDragDrop}>
      <div className={classe.root}>
        <div ref={dragPreview}>
          <div
            className={clsx({
              [classe.cardContainer]: true,
              [classe.favorite]: index === 0,
            })}>
            <ContentFlex
              children1={
                <div ref={drag} className={classe.content}>
                  <DragIndicatorIcon className={classe.iconDragDrop} />
                </div>
              }
              children2={
                <div className={classe.content}>
                  <CardTest
                    image={data.image}
                    title={data.title}
                    description={data.description}
                    time={data.time}
                    isFavorite={index === 0}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
