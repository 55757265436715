/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

type Props = {
  name: string;
  radios: RadioValue[];
  onChange: (value: any) => void;
  className?: string;
  withDivider?: boolean;
  index: number;
  defaultPropsValue: string;
};

interface RadioValue {
  col2: string;
  col3: string;
  disabled?: boolean;
}

const MyRadio = withStyles({
  root: {
    display: 'none',
    '& + span': {
      height: '100%',
      border: '1px solid #E3E8F0',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      letterSpacing: 0,
      color: '#2c57a5',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& + span:hover': {
      border: '1px solid #2c57a5',
    },
    margin: 0,
  },
  checked: {
    '& + span': {
      color: '#fff',
      background: '#2c57a5',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);
const MyRadio2 = withStyles({
  root: {
    display: 'none',
    '& + span': {
      height: '100%',
      border: '1px solid #E3E8F0',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      letterSpacing: 0,
      color: '#2c57a5',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& + span:hover': {
      border: '1px solid #2c57a5',
    },
    margin: 0,
  },
})((props: RadioProps) => <Radio color="default" {...props} />);
const MyRadio3 = withStyles({
  root: {
    display: 'none',
    '& + span': {
      height: '100%',
      border: '1px solid #E3E8F0',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      letterSpacing: 0,
      color: '#2c57a5 !important',
      background: '#eaeff9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    '& + span:hover': {
      border: '1px solid #2c57a5',
    },
    margin: 0,
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const RadioButtonFigure: FC<Props> = ({
  className = '',
  name,
  radios = [],
  onChange,
  withDivider = false,
  index,
  defaultPropsValue,
}) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue((event.target as HTMLInputElement).value);
    const dataValue = {
      value: (event.target as HTMLInputElement).value,
      index,
    };
    onChange(dataValue);
  };
  useEffect(() => {
    setDefaultValue(defaultPropsValue);
  }, [defaultPropsValue]);

  return (
    <RadioGroup
      className={clsx(classes.radioGrp, className)}
      aria-label={name}
      name={name}
      value={defaultValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}>
      {radios.map((r, i) => (
        <div className={classes.radioItemContainer} key={Math.random()}>
          {withDivider && i !== 0 && <Divider orientation="vertical" className={classes.divider} />}
          <Grid container={true} style={{height: '80px', color: '#2b56a5'}}>
            <Grid item={true} xs={1} style={{textAlign: 'center',border: '0.5px solid #2b56a5'}} className={classes.categorieB}>
              <FormControlLabel
                key={i}
                className={classes.controlLabel}
                style={{
                  color: '#2c57a5 !important',
                  background: '#eaeff9',
                  fontSize: '25px',
                }}
                value={r.col3}
                control={<MyRadio />}
                label={i + 1}
              />
            </Grid>
            <Grid item={true} xs={5} style={{position: 'relative', textAlign: 'center', border: '0.5px solid #2b56a5', display: 'flex', alignItems: 'center', justifyContent: 'center',}} >
              <FormControlLabel
                key={r.col2}
                className={classes.controlLabel}
                value={r.col3}
                control={<MyRadio />}
                label={r.col2}
              />
            </Grid>
            <Grid item={true} xs={6} style={{textAlign: 'center', height: '100%', border: '0.5px solid #2b56a5',}}>
              <FormControlLabel
                key={r.col3}
                className={classes.controlLabel}
                value={r.col3}
                control={<MyRadio />}
                label={r.col3}
              />
            </Grid>
          </Grid>
        </div>
      ))}
    </RadioGroup>
  );
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGrp: {
      width: '100%',
      borderRadius: 4,
    },
    controlLabel: {
      margin: '0 !important',
      display: 'block',
      width: '100%',
      height: '100%',
    },
    radioItemContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #0E1247',
    },
    divider: {
      color: 'black',
      '&.MuiDivider-vertical': {
        height: '50%',
      },
    },
    h7: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '16px',
      textAlign: 'center',
      display: 'inline-block',
      style: 'normal',
      alignItems: 'center',
      justifyContent: 'center',
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      color: '#2b56a5',
      textAlign: 'center',
      display: 'inline-block',
      style: 'normal',
      alignItems: 'center',
      padding:20,
      '@media only screen and (max-width: 600px)': {
        paddingTop:10,
        paddingBottom:10,
        paddingLeft: 3,
        paddingRight: 3,
        fontSize: 12,
        fontWeight: 800,
      },
      '@media only screen and (max-width: 361px)': {
        paddingTop:10,
        paddingLeft: 3,
        paddingRight: 3,
        paddingBottom:10,
        fontSize: 12,
        fontWeight: 800,
      },
    },
    categorieB: {
      color: '#2c57a5 !important',
      background: '#eaeff9',
      fontSize: '25px',
    },
    categorieA: {
      color: '#2c57a5',
      background: '#fff',
      fontSize: '25px',
    },
  })
);

export default RadioButtonFigure;
