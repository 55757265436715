import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipDone: {
            margin: '0.25vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'rgba(0, 0, 0, 0.87)',
            border: '1px solid #CDCEDA',
            paddingLeft: '1vh',
            paddingRight: '1vh',
            borderRadius: 20
        },
        iconeDone: {
            fontSize: 20,
            marginRight: 7,
            marginTop: 5,
            color: 'white',
            background: 'linear-gradient(180deg, #FFDC27 0%, #E63C3C 48.96%, #000A8C 100%)',
            borderRadius: '50px',
            [theme.breakpoints.down('xs')]: {
                fontSize: 17,
            }
        },
        textDone: {
            fontSize: 16,
            fontFamily: 'Poppins',
            fontWeight: 300,
            [theme.breakpoints.down('xs')]: {
                fontSize: 13,
            }
        },

    })
);
