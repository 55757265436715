const getNextId = (arr: any, isEdit = false) => {
  if (arr.length === 0) {
    return 1;
  }

  const ids = arr.map((obj: any) => (isEdit ? obj.order : obj.id));

  const maxId = Math.max(...ids);

  return maxId + 1;
};

const getVisibilityPermission = (value: number) => {
  switch (value) {
    case 0:
      return [
        { value: 0, label: 'Autorisé à tous' },
        { value: 1, label: 'Bénéficiaires non-rattachés' },
        { value: 2, label: 'ORIENTACTION uniquement' },
        { value: 4, label: 'Espaces entreprises spécifiques' },
      ];

    case 1:
      return [{ value: 1, label: 'Bénéficiaires non-rattachés' }];

    case 2:
      return [{ value: 2, label: 'ORIENTACTION uniquement' }];

    case 3:
      return [
        { value: 1, label: 'Bénéficiaires non-rattachés' },
        { value: 2, label: 'ORIENTACTION uniquement' },
      ];

    case 4:
      return [{ value: 4, label: 'Espaces entreprises spécifiques' }];

    case 5:
      return [
        { value: 1, label: 'Bénéficiaires non-rattachés' },
        { value: 4, label: 'Espaces entreprises spécifiques' },
      ];

    case 6:
      return [
        { value: 2, label: 'ORIENTACTION uniquement' },
        { value: 4, label: 'Espaces entreprises spécifiques' },
      ];

    default:
      return null;
  }
};

const getEnterpriseSelected = (companies: any, isAllEnterpriseSelected = false) => {
  const allCompanies = companies
    ?.filter((c: any) => c.id !== 1)
    ?.map((e: any) => {
      return { ...e, value: e.id, label: e.name };
    });
  if (isAllEnterpriseSelected) {
    return [{ value: 0, label: 'Tous' }, ...allCompanies];
  }
  return allCompanies;
};

export { getEnterpriseSelected, getNextId, getVisibilityPermission };
