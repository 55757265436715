/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line no-use-before-define
import CheckIcon from '@material-ui/icons/Check';
import { FC } from 'react';
import { useStyles } from './style';


const IsDone: FC = () => {

    const classes = useStyles();

    return (
        <>
            <div
                className={classes.chipDone}
            >
                <div>
                    <CheckIcon className={classes.iconeDone} />
                </div>
                <div className={classes.textDone}>
                    Test fait
                </div>
            </div>

        </>
    );
};

export default IsDone;
