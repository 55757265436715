/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page18 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={18}>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={4} text="CHANGER EST UNE AVENTURE EXTRAORDINAIRE" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Apprendre de nouvelles choses, rencontrer de nouvelles personnes, découvrir de
              nouveaux territoires… Y a-t-il plus excitant et plus stimulant que cela? C’est une
              aventure extraordinaire!!!
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Il est temps de quitter votre zone de confort, dans votre façon de penser comme de
              vous comporter, pour explorer, confronter, découvrir.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Rompre avec les habitudes, remettre en cause l’ordre existant, c’est mettre du piment
              dans votre vie!
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Le changement, c’est la vie, c’est la découverte et la nouveauté, c’est une expérience
              géniale !
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <TitleNbr number={5} text="CHANGER EST ABSOLUMENT NÉCESSAIRE POUR S’ADAPTER" isLight={true} />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Non seulement le changement est indispensable, car il nous permet d’évoluer vers le
              métier, la fonction et l’environnement professionnel susceptibles de nous rendre plus
              heureux(ses) et plus performant(es), mais c’est aussi une nécessité.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Mondialisation, digitalisation, ubérisation, robotisation… Le marché du travail est
              traversé par des évolutions constantes auxquelles les salarié(e)s (mais aussi les
              indépendant(e)s) doivent s’adapter.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Car c’est en s’adaptant que l’on reste employable et attractif(ve) sur le marché du
              travail.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              La méthode ORIENTACTION a cette spécificité et cette ambition de vouloir réconcilier
              le bonheur et la performance au travail.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Notre objectif est d’aider les actifs à trouver le métier, la fonction et
              l’environnement professionnel qui favorisent leur bien-être et leur épanouissement
              personnel, tout en leur permettant de développer leurs compétences et l’attractivité
              de leur profil auprès des employeurs.
            </Typography>
          </Grid>
        </Grid>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std bold',
              fontWeight: 'bold',
              fontSize: 15,
            }}>
            Sécuriser son parcours professionnel, être performant(e) dans son travail
          </Typography>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std bold',
              fontWeight: 'bold',
              fontSize: 15,
            }}>
            et en même temps s’éclater, telle pourrait être notre devise !
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
    },
  })
);
export default Page18;
