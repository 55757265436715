import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      color: 'black',
      fontSize: 22,
      [theme.breakpoints.down('lg')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    smallScreenTitle: {
      alignSelf: 'flex-start',
      marginTop: 25,
      marginLeft: 25,
    },
    contentNoPadding: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
      },
    },
    padding25: {
      padding: 25,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    content2: {
      backgroundColor: '#FFFFFF',
      borderRadius: 16,
      padding: 25,
      height: '100%',
      marginBottom: 20,
    },
    description1: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      color: '#242424',
      // [theme.breakpoints.down('md')]: {
      //   fontSize: 13,
      // },
      // [theme.breakpoints.down('sm')]: {
      //   fontSize: 16,
      // },
    },
    description2: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 14,
      color: '#242424',
      // [theme.breakpoints.down('md')]: {
      //   fontSize: 13,
      // },
      // [theme.breakpoints.down('sm')]: {
      //   fontSize: 16,
      // },
    },
    pyramidContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        marginTop: 25,
      },
    },
    imgPyramid: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '130%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '112%',
      },
    },
    paragraphMarginTop: {
      marginTop: 25,
    },
    mobileNoParagraphMarginTop: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    favoriteLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      [theme.breakpoints.down('lg')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      marginTop: 25,
    },
    needProPyramidBlockContainer: {
      width: '100%',
      marginTop: 40,
      [theme.breakpoints.up('lg')]: {
        maxHeight: '100vh',
      },
      [theme.breakpoints.down('xs')]: {
        margin: 'auto',
      },
    },
    pyramidItem: {
      paddingRight: 16,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    applicationItem: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
  }),
);
