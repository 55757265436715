export const context = [
    {
        title: "LES OPPORTUNITÉS D’UNE CRÉATION/REPRISE D’ENTREPRISE",
        id: 1,
        listContext: [
            {
                title: "Un projet très personnel",
                content: `alors qu’un poste de salarié(e) est standardisé, une
                création/reprise d’entreprise permet d’avoir un métier dans lequel nous pouvons
                exprimer qui nous sommes. Une entreprise porte et exprime les valeurs de son (sa)
                dirigeant(e).`
            },
            {
                title: "Créer/reprendre une entreprise",
                content: `est une façon d’exprimer sa vision des choses de
                façon très concrète. À travers les produits et les services proposés par l’entreprise,
                sa culture managériale, son organisation s’expriment la personnalité et les valeurs
                du (de la) dirigeant(e) fondateur(trice).`
            },
            {
                title: "Une aventure humaine",
                content: `la création/reprise d’entreprise est une aventure humaine.
                Elle implique une confrontation avec des difficultés, des réussites, des échecs, des
                rencontres. Elle pousse le(a) dirigeant(e) fondateur(trice) dans ses retranchements,
                l’oblige sans cesse à reconsidérer sa vision du monde, à changer d’habitudes et de réflexes. En trois ans de direction d’entreprise, vous aurez appris autant de choses
                qu’en vingt ans en tant que salarié(e). Car vous ne devrez pas connaître un seul
                poste, mais tous les postes.`
            },
            {
                title: "Une rémunération exceptionnelle",
                content: `une création/reprise d’entreprise est génératrice
                de valeur. Si vous réussissez, votre niveau de rémunération dépassera largement ce
                que vous gagnez en tant que salarié(e). Vous toucherez directement les fruits de
                votre travail sous deux formes. `
            },
            {
                title: "La première, sous forme de rémunération, la deuxième sous forme de capital",
                content: `lorsque vous vendrez votre entreprise. Une carrière de salarié(e), sauf à de très rares
                exceptions, ne peut permettre une accumulation de capital aussi rapide.`
            },
        ]
    },
    {
        title: "LES RISQUES D’UNE CRÉATION/REPRISE D’ENTREPRISE",
        id: 2,
        listContext: [
            {
                title: "Des débuts difficiles",
                content: `ne vous faites pas d’illusions, les débuts seront difficiles ! Peu
                d’entrepreneurs(ses) connaissent un succès fulgurant immédiat. Comme dans tout
                métier, il faut de l’expérience, des connaissances, des réseaux. Tout cela met du
                temps à se construire. Au bout de trois ans, votre société commencera à se structurer,
                au bout de cinq ans, si elle existe encore, à se renforcer et au bout de sept ans elle
                atteindra une maturité et une solidité suffisantes pour résister à la plupart des aléas.
                Il faudra donc être persévérant(e).`
            },
            {
                title: " Une vie stressante",
                content: `quand vous êtes salarié(e), le soir quand vous fermez la porte
                de votre bureau et que vous rentrez chez vous, vous changez de préoccupations.
                Certes il vous arrive de rapporter quelques soucis professionnels à la maison,
                mais cela est sans commune mesure avec ce qui se passe quand on est chef(fe)
                d’entreprise. Les responsabilités sont énormes, les problématiques nombreuses et
                complexes. Le simple fait d’avoir des bureaux et de les entretenir demande une
                attention et une énergie considérables, alors attention au stress !`
            },
            {
                title: "Rien n’est acquis",
                content: `si vous faites le choix de cette carrière, vous devez accepter le
                fait que dans votre vie, rien ne sera plus acquis. Le monde change et il change
                la vie ! En période de crise, il faut sans cesse s’adapter, créer, innover. C’est une
                course sans fin, un ballet épuisant. Le risque est évidemment de trop se reposer
                sur ses lauriers, de relâcher son attention. Un(e) bon(ne) chef(fe) d’entreprise est
                une personne préoccupée. Cela signifie, travailler beaucoup et souvent, parfois
                pendant le week-end ou les vacances.`
            },
        ]
    },
]