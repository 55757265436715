/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
export const colorMeanValue = 'rgba(247, 168, 46, 1)';

export const standartCoulomrMeanValue = [
  {
    type: 'normal',
    header: [
      {
        label: 'Date',
        key: 'attributes.createdAt',
        option: {
          type: 'date',
          formatedDate: true,
          format: 'DD/MM/YYYY',
        },
      },
      {
        label: 'Prénom',
        key: 'attributes.users_permissions_user.firstname',
      },
      {
        label: 'Nom',
        key: 'attributes.users_permissions_user.lastname',
      },
      {
        label: 'Sexe',
        key: 'attributes.users_permissions_user.gender',
      },
      {
        label: 'Âge',
        key: 'attributes.users_permissions_user.dayOfBirth',
        option: {
          type: 'date',
          formatedDate: true,
        },
      },
      {
        label: 'Diplôme',
        key: 'attributes.users_permissions_user.diploma',
      },
      {
        label: 'CP',
        key: 'attributes.users_permissions_user.postal_code',
      },
      {
        label: 'Email',
        key: 'attributes.users_permissions_user.email',
      },
      {
        label: 'téléphone',
        key: 'attributes.users_permissions_user.phone',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Autonomie',
        key: 'attributes.autonomy',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Stimulation',
        key: 'attributes.stimulation',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Hédonisme',
        key: 'attributes.hedonism',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Réussite',
        key: 'attributes.achievement',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Pouvoir',
        key: 'attributes.power',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Sécurité',
        key: 'attributes.security',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Tradition',
        key: 'attributes.tradition',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Conformité',
        key: 'attributes.compliance',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Bienveillance',
        key: 'attributes.kindness',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Universalisme',
        key: 'attributes.universalism',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'OPT-IN',
        key: 'attributes.time_spent',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'BC',
        key: 'attributes.realization_period',
      },
    ],
  },
  {
    type: 'action',
    withBorder: true,
    header: [
      {
        label: 'Actions',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['delete'],
        },
      },
    ],
  },
];
