import { makeStyles } from '@material-ui/core/styles';
import { FC, ReactNode } from 'react';
import { publicIconPath } from '../utils/constants';

type Props = {
  imageSrc: string;
  label?: number;
  color: string;
  background: string;
};

const Thead: FC<Props> = ({ imageSrc, label = 0, color, background }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.tagTable}
      style={{
        background,
      }}>
      <div className={classes.tagTableImage}>
        <img src={`${publicIconPath}/${imageSrc}`} alt="Tag table" className={classes.img} />
      </div>
      <div className={classes.tagTableLabel}>
        <span style={{ color }}>{label}</span>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => ({
  tagTable: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: 3,
    paddingRight: 5,
  },
  tagTableImage: {
    width: 31,
    height: 31,
    overflow: 'hidden',
  },
  tagTableLabel: {},
  img: {
    width: 39,
  }
}));

export default Thead;
