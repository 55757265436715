export const list = [
  {
    id: 1,
    title: '« Soft skill » n°1',
    paragraph: "MON EXPÉRIENCE PROFESSIONNELLE ET/OU PERSONNELLE",
  },
  {
    id: 2,
    title: '« Soft skill » n°2',
    paragraph: "MON EXPÉRIENCE PROFESSIONNELLE ET/OU PERSONNELLE",
  },
  {
    id: 3,
    title: '« Soft skill » n°3',
    paragraph: "MON EXPÉRIENCE PROFESSIONNELLE ET/OU PERSONNELLE",
  },
  {
    id: 4,
    title: '« Soft skill » n°4',
    paragraph: "MON EXPÉRIENCE PROFESSIONNELLE ET/OU PERSONNELLE",
  },
  {
    id: 5,
    title: '« Soft skill » n°5',
    paragraph: "MON EXPÉRIENCE PROFESSIONNELLE ET/OU PERSONNELLE",
  },
]