/* eslint-disable no-use-before-define */
import { figure32_page3 } from '../../../../utils/constants';
import { TableDestin, Page } from '@orientaction/components';

const Page24 = (props: any) => {

  return (
    <Page id={24}>
      <TableDestin head={false} figure32={figure32_page3} />
    </Page>
  );
};

export default Page24;

