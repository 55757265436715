/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { bookAction } from '@orientaction/api-actions';
import { listvalueArray } from '@orientaction/Context';
import { appBook1 } from '@orientaction/reducers';
import { BookTemplate } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { IBook1 } from "../../store/types/Book/Book1";
import { getFromLS } from '../../utils/utils';

const useBook1 = () => {

    const dispatch = useDispatch();

    const book: IBook1 = useSelector(appBook1);

    const token = getFromLS('userToken') || '';

    const updateYourValue = async (data: any) => {
        dispatch(bookAction.setYourValue(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            yourValue: data,
            query: "api::book.book"
        })
    }

    const updateIndoorWeather = async (nbr: number) => {
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            indoorWeather: nbr,
            query: "api::book.book"
        })
        dispatch(bookAction.setIndoorWeather(nbr));
    }

    const updateMeanToYou = async (data: any) => {
        dispatch(bookAction.setMeanToYou(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            meanToYou: data,
            query: "api::book.book"
        })
    }

    const updateProjectMakeSense = async (data: string) => {
        dispatch(bookAction.setProjectMakeSense(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            projectMakeSense: data,
            query: "api::book.book"
        })
    }

    const updateSuccessLife = async (data: any[]) => {
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            successLife: data,
            query: "api::book.book"
        })
        dispatch(bookAction.setSuccessLife(data));
    }

    const updateMajory_A = async (data: any) => {
        dispatch(bookAction.setMajory_A(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            majoryA: data,
            query: "api::book.book"
        })
    }

    const updateMajory_B = async (data: any) => {
        dispatch(bookAction.setMajory_B(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            majoryB: data,
            query: "api::book.book"
        })
    }

    const updatePersonal_aspiratiom = async (data: any) => {
        dispatch(bookAction.setPersonal_aspiratiom(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            personalAspiration: data,
            query: "api::book.book"
        })
    }

    const updateFive_Jobs = async (data: any) => {
        dispatch(bookAction.setFive_Jobs(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            fiveJobs: JSON.stringify(data),
            query: "api::book.book"
        })
    }

    const updateFigureOfDestiny = async (data: any) => {
        dispatch(bookAction.setFigureOfDestiny(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            figureOfDestiny: JSON.stringify(data),
            query: "api::book.book"
        })
    }

    const updateObjectifSupreme = async (data: any) => {
        dispatch(bookAction.setObjectifSupreme(data));
        await BookTemplate.updateBook(token, {
            id: book.id || "",
            objectifSupreme: JSON.stringify(data),
            query: "api::book.book"
        })
    }

    const setId = (data: any) => {
        dispatch(bookAction.setId(data));
    }

    const totalYourValue = (key: string) => {

        const currentObject = book.yourValue[key]

        const listKey = currentObject ? Object.keys(currentObject) : [];

        let total = 0;

        for (let i = 0; i < listKey.length; i++) {

            const { value } = currentObject[listKey[i]]

            total += value;

        }

        return total;

    }

    const getMyValue = () => {

        const listTotalWithName = [];

        for (let i = 0; i < listvalueArray.length; i++) {

            const current = { ...listvalueArray[i] }

            listTotalWithName.push({
                name: current.title,
                total: +totalYourValue(current.name)
            });
        }

        const arraySorted = listTotalWithName
            .filter((item: any) => item.total !== 0)
            .sort((a: any, b: any) => b.total - a.total);

        return arraySorted;

    }

    const getMyValueBook = async (userId: any) => {
        return BookTemplate.getMyValueBook(token, {
            id: userId,
            query: 'api::book.book'
        });
    }

    const initValue = (data: any) => {
        dispatch(bookAction.setSuccessLife(((data.successLife) || book.successLife)));
        dispatch(bookAction.setIndoorWeather(data.indoorWeather || book.indoorWeather));
        dispatch(bookAction.setMeanToYou(data.meanToYou || book.meanToYou));
        dispatch(bookAction.setPersonal_aspiratiom(data.personalAspiration || {}));
        dispatch(bookAction.setFigureOfDestiny(JSON.parse(data.figureOfDestiny || JSON.stringify([]))));
        dispatch(bookAction.setFive_Jobs(JSON.parse(data.fiveJobs || JSON.stringify(['', '', '', '', '']))));
        dispatch(bookAction.setYourValue(data.yourValue || book.yourValue));
        dispatch(bookAction.setProjectMakeSense(data.projectMakeSense));
        dispatch(bookAction.setObjectifSupreme(JSON.parse(data.objectifSupreme || JSON.stringify([]))));
        dispatch(bookAction.setMajory_A(data.majoryA || book.majoryA));
        dispatch(bookAction.setMajory_B(data.majoryB || book.majoryB));
    }

    return {
        book,
        getMyValueBook,
        updateProjectMakeSense,
        totalYourValue,
        updateMajory_A,
        updateMajory_B,
        updateYourValue,
        updateMeanToYou,
        getMyValue,
        updateFive_Jobs,
        updateSuccessLife,
        updateIndoorWeather,
        updateFigureOfDestiny,
        updatePersonal_aspiratiom,
        updateObjectifSupreme,
        initValue,
        setId
    };

};

export default useBook1;
