import { CreateTestContextProvider } from '@orientaction/Context';
import CreateTestContent from './CreateTest';

const CreateTest = () => {
  return (
    <CreateTestContextProvider>
      <CreateTestContent />
    </CreateTestContextProvider>
  );
};

export default CreateTest;
