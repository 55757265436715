export const fakeResult = {
  users_permissions_user: 42369,
  resultat: [
    {
      title: 'Préférence sociale',
      youAre: 'une personne de relation',
      key: 'interaction_social',
      label: 'Interaction',
      img: '/images/Preference/Interaction.jpg',
      color: '#692960',
      score: [
        {
          pourcentage: 63.63,
          key: 'interaction_social',
          label: 'Interaction',
          isMax: true,
        },
        {
          pourcentage: 36.37,
          key: 'reflexion_social',
          label: 'Réflexion',
        },
      ],
      category: 'social',
    },
    {
      title: 'Préférence perceptive',
      youAre: 'une personne d’imagination',
      label: 'Imagination',
      key: 'imagination_perceptual',
      img: '/images/Preference/Imagination.jpg',
      color: '#933754',
      score: [
        {
          pourcentage: 49.995,
          key: 'experience_perceptual',
          label: 'Expérience',
        },
        {
          pourcentage: 50.005,
          key: 'imagination_perceptual',
          label: 'Imagination',
          isMax: true,
        },
      ],
      category: 'perceptual',
    },
    {
      title: 'Préférence émotionnelle',
      youAre: 'une personne rationnelle',
      label: 'Rationalité',
      key: 'rationality_emotional',
      img: '/images/Preference/Decision.jpg',
      color: '#BA534B',
      score: [
        {
          pourcentage: 63.63,
          key: 'rationality_emotional',
          label: 'Rationalité',
          isMax: true,
        },
        {
          pourcentage: 36.37,
          key: 'empathy_emotional',
          label: 'Empathie',
        },
      ],
      category: 'emotional',
    },
    {
      title: 'Préférence comportementale',
      youAre: "une personne d'observation",
      label: 'Observation',
      key: 'observation_behavioral',
      img: '/images/Preference/Observation.jpg',
      color: '#E87E59',
      score: [
        {
          pourcentage: 40,
          key: 'action_behavioral',
          label: 'Action',
        },
        {
          pourcentage: 60,
          key: 'observation_behavioral',
          label: 'Observation',
          isMax: true,
        },
      ],
      category: 'behavioral',
    },
    {
      title: 'Préférence environnementale ',
      youAre: 'une personne d’organisation',
      key: 'organization_environmental',
      img: '/images/Preference/Organisation.jpg',
      label: 'Organisation',
      color: '#E7A850',
      score: [
        {
          pourcentage: 54.54,
          key: 'organization_environmental',
          label: 'Organisation',
          isMax: true,
        },
        {
          pourcentage: 45.46,
          key: 'adaptation_environmental',
          label: 'Adaptation',
        },
      ],
      category: 'environmental',
    },
  ],
  listHistory: [
    {
      id: 8,
      createdAt: '2023-03-03T11:51:17.244Z',
    },
    {
      id: 7,
      createdAt: '2023-03-03T09:18:10.170Z',
    },
    {
      id: 943,
      createdAt: '2023-03-02T21:00:00.000Z',
    },
    {
      id: 945,
      createdAt: '2023-03-02T21:00:00.000Z',
    },
    {
      id: 4,
      createdAt: '2023-02-24T06:38:39.862Z',
    },
  ],
};
