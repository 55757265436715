import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    padding: '1px',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px dashed #E4E4F2',
    cursor: 'pointer',
  },
  title: {
    fontFamily: 'ITC Avant Garde Gothic Std Demi',
    fontSize: '20px',
    fontWeight: 600,
    textTransform: 'none',
    textAlign: 'center',
  },
}));
