import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC, useEffect, useRef } from 'react';

type MyProps = {
  left: any;
  right: any;
};

const AuthLayout: FC<MyProps> = ({ left, right }) => {
  const cls = style();
  const refLeft = useRef<any>();

  const scrollToBottom = () => {
    refLeft.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(scrollToBottom, [left, right]);
  return (
    <div className={cls.layout}>
      <div className={cls.left} ref={refLeft}>
        {left}
      </div>
      <div className={cls.right}>{right}</div>
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  layout: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'column-reverse',
    '@media(min-width: 1250px)': {
      flexDirection: 'row',
      height: 'calc(100vh)',
    },
  },
  left: {
    position: 'relative',
    flex: 1,
    overflow: 'auto',
    '& .header': {
      display: 'block',
      '@media(max-width: 1249px)': {
        display: 'none',
        margin: 'auto',
        position: 'inherit',
        height: 56,
        width: '100%',
        top: 0,
        left: 0,
      },
    },
  },
  right: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
    '& .header': {
      display: 'none',
      '@media(max-width: 1249px)': {
        margin: 20,
        display: 'flex',
        height: 56,
      },
    },
    '@media(min-width: 1250px)': {
      maxWidth: '50%',
      height: '100vh',
    },
  },
}));

export default AuthLayout;
