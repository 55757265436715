import { compassAction } from '@orientaction/api-actions';
import { appCompass } from '@orientaction/reducers';
import { compassService } from '@orientaction/services';
import { ICompass } from '@orientaction/types';
import { getFromLS, transformHarmonyResult, updateUserInfoInLS } from '@orientaction/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAlert from './useAlert';

const useCompassMenu = () => {
  const dispatch = useDispatch();

  const data: ICompass = useSelector(appCompass);
  const { userId, year }: any = useParams();

  const alert = useAlert();

  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const token = getFromLS('userToken') || '';

  const setRorate = (rotate: any) => {
    dispatch(compassAction.setRorate(rotate));
  };

  const setHasCompass = (bool: boolean) => {
    dispatch(compassAction.setHasCompass(bool));
  };

  const setMajorTestsAccess = (tests: []) => {
    dispatch(compassAction.setMajorTestsAccess(tests));
  };

  const setLoading = (bol: boolean) => {
    dispatch(compassAction.setLoading(bol));
  };

  const setHarmonyTestLastResult = (bol: any) => {
    dispatch(compassAction.setHarmonyTestLastResult(bol));
  };

  const setMeaningTestLastResult = (bol: any) => {
    dispatch(compassAction.setMeaningTestLastResult(bol));
  };

  const setNeedsProTestLastResult = (bol: any) => {
    dispatch(compassAction.setNeedsProTestLastResult(bol));
  };

  const setNeedsTestLastResult = (bol: any) => {
    dispatch(compassAction.setNeedsTestLastResult(bol));
  };

  const setPreferenceTestLastResult = (bol: any) => {
    dispatch(compassAction.setPreferenceTestLastResult(bol));
  };

  const doUserHasCompass = async () => {
    try {
      setLoading(true);
      await compassService.checkIfUserHasCompass(token);
      setHasCompass(true);
      // update user info in local storage
      updateUserInfoInLS({ isCompassActivated: true, rattachement: true });
    } catch (error) {
      if (error?.response?.data?.error?.status === 404) {
        setHasCompass(false);
        updateUserInfoInLS({ isCompassActivated: false });
      } else {
        // alert.openSimpleAlert();
      }
    } finally {
      setLoading(false);
    }
  };

  const updateRotate = async (id: number, rotate: number) => {
    if (!id) {
      // compass does not exist yet so create it
      const response = await compassService.createUserCompass(token, {
        rotate,
        users_permissions_user: user.id,
      });
      return compassService.updateRotate(token, response.data.data.id, rotate);
    }
    return compassService.updateRotate(token, id, rotate);
  };

  const getMyData = async () => {
    setLoading(true);

    try {
      const idUser = userId || user.id;
      const response = await compassService.getMyData(token, idUser, year);

      setRorate({
        id: response.data.data?.rotate?.id,
        deg: response.data.data?.rotate?.rotate || 0,
      });

      setHarmonyTestLastResult(transformHarmonyResult(response.data.data?.harmonyTestLastResult));

      setMeaningTestLastResult(response.data.data?.meaningTestLastResult);

      setNeedsProTestLastResult(response.data.data?.needsProTestLastResult);

      setNeedsTestLastResult(response.data.data?.needsTestLastResult);

      setPreferenceTestLastResult(response.data.data?.preferenceTestLastResult);

      // push tests in which user has access
      setMajorTestsAccess(response.data.data?.majorTestsAccess);
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const getUserData = async () => {
    setLoading(true);
    try {
      const idUser = userId || user.id;
      const response = await compassService.getUserData(token, idUser, year);

      setRorate({
        id: response.data.data?.rotate?.id,
        deg: response.data.data?.rotate?.rotate || 0,
      });

      setHarmonyTestLastResult(transformHarmonyResult(response.data.data?.harmonyTestLastResult));

      setMeaningTestLastResult(response.data.data?.meaningTestLastResult);

      setNeedsProTestLastResult(response.data.data?.needsProTestLastResult);

      setNeedsTestLastResult(response.data.data?.needsTestLastResult);

      setPreferenceTestLastResult(response.data.data?.preferenceTestLastResult);

      // push tests in which user has access
      setMajorTestsAccess(response.data.data?.majorTestsAccess);
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  return {
    ...data,
    user,
    useCompassMenu,
    doUserHasCompass,
    getMyData,
    getUserData,
    updateRotate,
    setRorate,
    token,
  };
};

export default useCompassMenu;
