/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow, no-unused-vars */
import { getHeaders, makeRequest } from '@orientaction/utils';
import dateFormat from 'dateformat';
import { makeFilters, makeGlobalFilter } from '../utils/utils';
import config from './config';

const qs = require('qs');

export const url = config.api_url;
export const { export_url } = config;

export const RemoveResult = async (token: string, id: string) => {
  try {
    const res = await makeRequest(`${url}/api/results/${id}`, 'DELETE', {}, getHeaders(token));
    return res.data;
  } catch (err: any) {
    console.error(err);
  }
};

export const GetResults = async (
  token: any,
  start: any = 0,
  filters: any = [],
  globalFilter: any = '',
  all = false,
  isActivated = true,
  isCompanyLinked = true,
  isExport = false
) => {
  try {
    let globalQs = '';
    const limit = all ? '-1' : 10;
    if (globalFilter?.trim()?.length > 0) {
      const filters = makeGlobalFilter(globalFilter);
      globalQs = qs.stringify(
        { filters: { $or: filters } },
        {
          encodeValuesOnly: true,
        }
      );
    }
    const filter = makeFilters(filters);
    const startAt = isNaN(start) ? '' : `pagination[start]=${start}&`;
    const filterGlobal = globalQs.length > 0 ? `&${globalQs}` : '';
    const isActivatedFilter = `&filters[user][isActivated][$eq]=${isActivated}`;
    const isCompanyLinkedFilter = `&filters[user][rattachement][$eq]=${isCompanyLinked}`;
    const res: any = await makeRequest(
      `${
        isExport ? export_url : url
      }/api/results?populate=*&${startAt}pagination[limit]=${limit}${filter}${isActivatedFilter}${isCompanyLinkedFilter}${filterGlobal}&sort[1]=createdAt%3Adesc&isExport=${isExport}`,
      'GET',
      {},
      getHeaders(token)
    );
    // const count = await makeRequest(
    //   `${url}/api/results/count?${startAt}_limit=${limit}${filter}${filterGlobal}&_sort=createdAt:desc`,
    //   'GET',
    //   {},
    //   getHeaders(token)
    // );

    return { data: res.data.data, count: res?.data?.meta?.pagination?.total };
  } catch (err: any) {
    console.error(err);
  }
};

/**
 * REFACTOR LATER: duplicate GetResults to avoid regression
 *
 */
export const fetchResults = async (
  companyId: any,
  token: any,
  start: any = 0,
  filters: any = [],
  globalFilter: any = '',
  all = false,
  isActivated = true,
  isCompanyLinked = true,
  isExport = false
) => {
  try {
    let globalQs = '';
    const limit = all ? '-1' : 10;
    if (globalFilter?.trim()?.length > 0) {
      const filters = makeGlobalFilter(globalFilter);
      globalQs = qs.stringify(
        { filters: { $or: filters } },
        {
          encodeValuesOnly: true,
        }
      );
    }
    const filter = makeFilters(filters);
    const startAt = isNaN(start) ? '' : `pagination[start]=${start}&`;
    const filterGlobal = globalQs.length > 0 ? `&${globalQs}` : '';
    const isActivatedFilter = `&filters[user][isActivated][$eq]=${isActivated}`;
    const isCompanyLinkedFilter = `&filters[user][rattachement][$eq]=${isCompanyLinked}`;
    const res: any = await makeRequest(
      `${
        isExport ? export_url : url
      }/api/results?populate=*&${startAt}pagination[limit]=${limit}${filter}${isActivatedFilter}${isCompanyLinkedFilter}${filterGlobal}&sort[1]=createdAt%3Adesc&company=${companyId}&isExport=${isExport}`,
      'GET',
      {},
      getHeaders(token)
    );
    return { data: res.data.data, count: res?.data?.meta?.pagination?.total };
  } catch (err: any) {
    console.error(err);
  }
};
export const getResultByTest = async (token: string, testId: number, withHistory = 0) => {
  const searchParams = qs.stringify(
    {
      populate: {
        user: {
          populate: ['company_user_role'],
        },
      },
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/results/${testId}?${searchParams}&withHistory=${withHistory}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getResultByUser = async (token: string, userId: any, company = 1) => {
  const companyId = !isNaN(+company) ? +company : 1;
  const searchParams = qs.stringify(
    {
      filters: {
        user: {
          id: {
            $eq: userId,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/results?${searchParams}&company=${companyId}&populate=*`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getCurrentUserHarmonyResultByYear = async (token: string, year: number) => {
  const params = qs.stringify(
    {
      filters: {
        $and: [
          {
            user: {
              id: {
                $eq: 27198,
              },
            },
          },
          {
            createdAt: { $gte: `${year}-01-01` },
          },
          {
            createdAt: { $lte: `${year}-12-31` },
          },
        ],
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/results/get-user-harmony-result-by-year?year=${year}&${params}`,
    'GET',
    {},
    getHeaders(token)
  );
};
