/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Typography,
} from '@material-ui/core';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Add from '@material-ui/icons/Add';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import BuildIcon from '@material-ui/icons/Build';
import ClearIcon from '@material-ui/icons/Clear';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import InputIcon from '@material-ui/icons/Input';
import SortIcon from '@material-ui/icons/Sort';
import { useCompassMenu, useEbook, useLanguage } from '@orientaction/hooks';
import { GB_PREFIX } from '@orientaction/utils';
import { filter } from 'lodash';
import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Item from './Item';
import { useStyles } from './style';

interface IBurgerMenu {
  isAdmin: boolean;
  menu: string[];
  goToProfileEdit: any;
  logout: any;
  service: any;
}

const BurgerMenu: FC<IBurgerMenu> = ({ isAdmin, goToProfileEdit, logout, service }) => {
  const [open, setOpen] = useState(false);

  const { language } = useLanguage();

  const classe = useStyles();
  const history = useHistory();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const { getMyBookRequest } = useEbook({ mode: 'none', user: 'none' });
  const { loading } = useCompassMenu();

  const [numberBadge, setNumberBadge] = useState(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const redirect = (to: string) => (e: any) => {
    history.push(to);
    setOpen(false);
  };

  const basicMenu = [
    {
      label: 'Administration des tests',
      to: '/admin/test',
      icon: <BuildIcon />,
      isAdmin: true,
      badge: false,
    },
    {
      label: 'Gestion & Administration',
      to: '/admin/users-resultats',
      icon: <AccountBalanceIcon />,
      isAdmin: true,
      badge: false,
    },
    {
      label: 'Accueil',
      to: '/main',
      icon: <Add />,
      isAdmin: false,
      badge: false,
    },
    {
      label: 'Faire un test',
      to: '/user/tests',
      icon: <Add />,
      isAdmin: false,
      badge: false,
    },

    {
      label: 'Mes résultats',
      to: `${language === GB_PREFIX ? `/en/my-results` : `/mes-resultats`}`,
      icon: <EqualizerIcon />,
      isAdmin: false,
      badge: false,
    },
    {
      label: 'Mes ressources',
      to: `${language === GB_PREFIX ? `/en/my-resources` : `/mes-ressources`}`,
      icon: <AllInboxIcon />,
      isAdmin: false,
      badge: true,
    },
  ];

  const burgerMenu =
    service?.id === 2
      ? basicMenu
      : [
          ...basicMenu,
          {
            label: 'Ma boussole',
            to: `${
              language === 'GB'
                ? `/en/my-compass/${new Date().getFullYear()}`
                : `/ma-boussole/${new Date().getFullYear()}`
            }`,
            icon: <AllInboxIcon />,
            isAdmin: false,
            badge: false,
          },
        ];

  return (
    <>
      {!loading && (
        <Button ref={anchorRef} onClick={handleToggle} className={classe.btn}>
          {open ? (
            <>
              <ClearIcon />
            </>
          ) : (
            <>
              <SortIcon />
            </>
          )}
        </Button>
      )}
      {loading ? (
        <CircularProgress color="inherit" size={16} />
      ) : (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition={true}
          disablePortal={true}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                transform: 'translate3d(0px, 41px, 0px) !important',
              }}
            >
              <div className={classe.grow}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" className={classe.menuList}>
                    {burgerMenu.map((item: any) => {
                      if (!isAdmin) {
                        if (item.isAdmin) {
                          return null;
                        }

                        return (
                          <>
                            <MenuItem onClick={redirect(item.to)} className={classe.menuItem}>
                              <Badge badgeContent={item.badge ? numberBadge : 0} color="primary">
                                <Typography
                                  variant="inherit"
                                  noWrap={true}
                                  className={classe.typography}
                                >
                                  {item.label || ''}
                                </Typography>
                              </Badge>
                            </MenuItem>
                          </>
                        );
                      }

                      return item.isAdmin ? (
                        <div onClick={redirect(item.to)}>
                          <Item icon={item.icon} text={item.label} />
                        </div>
                      ) : null;
                    })}

                    <div onClick={goToProfileEdit} className={classe.contentBtn}>
                      <Item icon={<AccountCircleOutlinedIcon />} text="Mon profil" />
                    </div>

                    <div className={classe.contentBtn} onClick={logout}>
                      <Item icon={<InputIcon />} text="Déconnexion" />
                    </div>
                  </MenuList>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default BurgerMenu;
