import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  profile: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 !important',
    cursor: 'pointer',
    '& > span': {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  initial: {
    width: 38,
    height: 38,
    background: '#000A8C 0% 0% no-repeat padding-box',
    borderRadius: '100%',
    color: '#fff',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      height: '51%',
    },
  },
  textHeader: {
    display: 'none',
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  photo: {
    borderRadius: '50%',
    width: 38,
    height: 38,
    objectFit: 'cover',
  },
  btnIcon: {
    width: 20,
    height: 20,
    padding: '2px',
  },
  styleIcon: {
    color: '#0E1247',
    width: '20px',
    height: '20px',
  },
}));
