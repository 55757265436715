import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        head: {
            padding: "1vh 0",
            textAlign: 'center',
            borderBottom: '1px solid',
            background: '#e8eef4',
            fontSize: '2vh',
            height: '5vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.between(1200, 1300)]: {
                height: '8vh',
            },
        },
        tableContainer: {
            border: '1px solid',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        bodyContent: {
            borderBottom: '0.05px solid',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        textArea: {
            width: '100%',
            height: '14.5vh',
            maxHeight: '14.5vh',
            border: 'none'
        },
        number: {
            background: '#f1665f',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            fontSize: '2.5vh',
            color: 'white'
        }
    })
);

export default useStyles;