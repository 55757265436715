export const paragraphs = [
  {
    id: '13-1',
    text: `
    Le résultat de l’expression du potentiel de la personne est ce qui intéresse le plus la
    société, car c’est ce qui fait qu’une personne est utile. Celle qui construit un meuble,
    développe un programme informatique, fait biper un code-barre lors d’un passage en caisse,
    est à sa façon utile à la société. Elle exploite son potentiel et le transforme en un
    service qui va servir aux autres et donner du sens à son action. Une action n’a de sens que
    si elle est utile à autrui. À moins de vouloir produire quelque chose uniquement pour soi.`,
  },
  {
    id: '13-2',
    text: `
    L’analyse de ces phénomènes nous amène à comparer la personne et ses précieuses compétences
    à un arbre : enracinée dans un terreau, se développant, poussant, s’étendant à mesure
    qu’elle grandit, et produisant de précieux fruits, qui peuvent être consommés, et appréciés
    par d’autres personnes. Cette comparaison avec l’arbre vous permettra de mieux identifier
    vos potentiels et vos compétences à chacune des étapes du travail.`,
  },
  {
    id: '13-3',
    text: `
    C’est ce que nous vous proposons de commencer maintenant grâce à l’étape 4 de la méthode ORIENTACTION.`,
  },
];
