/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page22 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={22}>
      <div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className="opacity07">
              LES QUATRE OBSTACLES
              <br />
              AU CHANGEMENT
            </Typography>
          </div>
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20
              }}>
              <strong>
                ‘‘ Pourquoi, après avoir tout fait pendant dix ans pour que son mari perde ses
                vieilles habitudes, une femme se plaint-elle qu’il ne soit plus l’homme qu’elle a
                épousé ? ’’
              </strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter',fontSize: 20 }}>
              Barbara Streisand
            </Typography>
          </div>
          <div
            className={clsx(classes.positionCenter)}
            style={{ marginTop: '40px', marginBottom: '40px' }}
          />
          <div style={{ textAlign: 'center' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20
              }}>
              <strong>
                ‘‘ Que la force me soit donnée de supporter ce qui ne peut être changé et le courage
                de changer ce qui peut l’être, mais aussi la sagesse de distinguer l’un de l’autre.
                ’’
              </strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter',fontSize: 20 }}>
              Marc Aurèle
            </Typography>
          </div>
          
        </div>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      <div >
        <TitleNbr number={1} text="NE PAS SAVOIR EXACTEMENT CE QUE L’ON VEUT" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Chaque jour, des millions de personnes vont travailler. Elles se lèvent tôt,
              affrontent les transports, font preuve d’une intelligence extraordinaire pour
              s’occuper de leur famille et gérer les tâches quotidiennes.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Une fois arrivées au travail, elles passent des heures à chercher des solutions à des
              problèmes insolubles, à mettre sur pied des stratégies pour corriger des erreurs ou
              résoudre des conflits. Chaque seconde, il faut se dépasser pour atteindre de nouveaux
              objectifs toujours plus ambitieux. Chaque journée est un incroyable défi. Pour relever
              ce défi, il faut une énergie considérable.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              La condition indispensable pour trouver cette énergie est que ce travail et cette vie
              aient un sens. C’est dans le sens donné à l’action que nous trouvons l’énergie de
              l’action. Les personnes qui ont conscience de ce qu’elles font et de pourquoi elles le
              font sont mues par une volonté inébranlable.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Elles sont concentrées sur leur objectif et chaque fois qu’elles l’atteignent, cette volonté se renforce. Seules ces personnes réussissent dans la durée.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Changer fait partie des activités les plus difficiles qui existent dans notre société.
              Cela demande de l’énergie, des efforts, du courage et de la volonté.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              C’est pourquoi on ne peut changer si on n’a pas un objectif précis.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Pour réussir à changer, il faut que ce changement ait du sens. Il faut que ce
              changement soit décliné en un véritable projet avec son objectif final, mais aussi ses
              étapes les plus importantes et un calendrier.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Celles ou ceux qui ne savent pas ce qu’ils(elles) veulent ne peuvent pas changer. Ou
              alors ils(elles) se mettent véritablement en danger.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
    },
  })
);
export default Page22;
