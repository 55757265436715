import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      background: 'white',
      borderRadius: '50%',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#1a90ff',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
    textContainer: {
      width: '100%',
      textAlign: 'center',
    },
    textValue: {
      fontSize: 18,
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
    },
    textFLoating: {
      fontSize: '12px',
      position: 'relative',
      top: '7px',
    },
  })
);
