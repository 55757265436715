/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
export const colorPreferencePersonality = 'rgba(237, 102, 55, 1)';

export const standartCoulomsPreferencePersonality = [
  {
    type: 'normal',
    header: [
      {
        label: 'Date',
        key: 'attributes.createdAt',
        option: {
          type: 'date',
          formatedDate: true,
          format: 'DD/MM/YYYY',
        },
      },
      {
        label: 'Prénom',
        key: 'attributes.users_permissions_user.firstname',
      },
      {
        label: 'Nom',
        key: 'attributes.users_permissions_user.lastname',
      },
      {
        label: 'Sexe',
        key: 'attributes.users_permissions_user.gender',
      },
      {
        label: 'Âge',
        key: 'attributes.users_permissions_user.dayOfBirth',
        option: {
          type: 'date',
          formatedDate: true,
        },
      },
      {
        label: 'Diplôme',
        key: 'attributes.users_permissions_user.diploma',
      },
      {
        label: 'CP',
        key: 'attributes.users_permissions_user.postal_code',
      },
      {
        label: 'Email',
        key: 'attributes.users_permissions_user.email',
      },
      {
        label: 'téléphone',
        key: 'attributes.users_permissions_user.phone',
      },
    ],
  },
  {
    type: 'double',
    header: [
      {
        label: 'sociale',
        attr: [
          {
            label: 'Intéraction',
            key: 'attributes.interaction_social',
          },
          {
            label: 'Réflexion',
            key: 'attributes.reflexion_social',
          },
        ],
      },
    ],
  },
  {
    type: 'double',
    header: [
      {
        label: 'perceptive',
        attr: [
          {
            label: 'Expérience',
            key: 'attributes.experience_perceptual',
          },
          {
            label: 'Imagination',
            key: 'attributes.imagination_perceptual',
          },
        ],
      },
    ],
  },
  {
    type: 'double',
    header: [
      {
        label: 'émotionnelle',
        attr: [
          {
            label: 'Rationalité',
            key: 'attributes.rationality_emotional',
          },
          {
            label: 'Empathie',
            key: 'attributes.empathy_emotional',
          },
        ],
      },
    ],
  },
  {
    type: 'double',
    header: [
      {
        label: 'comportementale',
        attr: [
          {
            label: 'Action',
            key: 'attributes.action_behavioral',
          },
          {
            label: 'Observation',
            key: 'attributes.observation_behavioral',
          },
        ],
      },
    ],
  },
  {
    type: 'double',
    header: [
      {
        label: 'environnementale',
        attr: [
          {
            label: 'Organisation',
            key: 'attributes.organization_environmental',
          },
          {
            label: 'Adaptation',
            key: 'attributes.adaptation_environmental',
          },
        ],
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'OPT-IN',
        key: 'attributes.time_spent',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'BC',
        key: 'attributes.realization_period',
      },
    ],
  },
  {
    type: 'action',
    withBorder: true,
    header: [
      {
        label: 'Actions',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['delete'],
        },
      },
    ],
  },
];
