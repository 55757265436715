import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    grid: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
    },
    titlePage: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '18px',
      },
    },
  }),
);
