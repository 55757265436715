/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { ResultInterface } from '@orientaction/interfaces';
import { ResultServices } from '@orientaction/services';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { getMe } from '../services/user.service';
import { getBC, getFromLS, getPeriodeRealisation } from '../utils/utils';
import useAlert from './useAlert';
import useRedirectToRootPage from './useRedirectToRootPage';

const useResultsClient = (emailUser: string, testId = '0') => {
  const history = useHistory();
  const token = getFromLS('userToken');
  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');
  const [company_user_role, setCompany_user_role] = useState<number>(0);
  const [results, setResults] = useState<ResultInterface.IResultFilter[]>([]);
  const [resultToShow, setResultToShow] = useState<ResultInterface.IResultFilter | null>(null);
  const [loading, setLoading] = useState(true);

  const { goToRootPage } = useRedirectToRootPage();
  const alert = useAlert();

  const onSelectPeriod = (index: number) => {
    setResultToShow({ ...results[index] });
  };

  const getResults = useCallback(async idTest => {
    if (token) {
      const isManager = userConnected?.company_user_role?.id !== 5;
      if (!isManager) {
        getMe(token)
          .then((response: any) => {
            const data = response.data?.results || [];

            setCompany_user_role(response?.data.company_user_role.id);

            const resp = data.map((r: ResultInterface.IResult) => {
              const scoreTotal =
                r.problem_solving +
                r.confidence +
                r.collective_sense +
                r.communication +
                r.creativity +
                r.critical_mind +
                r.curiousity +
                r.daring +
                r.emotional_intelligence +
                r.empathy +
                r.entrepreneurial_spirit +
                r.motivation +
                r.presence +
                r.stress_management +
                r.time_management +
                r.vision;
              return {
                ...r,
                opt_in: r.opt_in ? 'Oui' : 'Non',
                bc: getBC(r.bc),
                scoreTotal,
                realization_period: getPeriodeRealisation(r.realization_period as any),
              };
            });
            setResults(resp);
            const resToShow = resp.filter((t: any) => t.id == +idTest);
            setResultToShow(resToShow.length > 0 ? resToShow[0] : null);
          })
          .catch((err: any) => {
            console.error(err);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        ResultServices.getResultByTest(token, idTest, 1)
          .then((resultsData: any) => {
            setCompany_user_role(resultsData?.data?.data?.attributes?.user?.data?.id);

            const resp = resultsData?.data?.history.map((r: ResultInterface.IResult) => {
              const scoreTotal =
                r.problem_solving +
                r.confidence +
                r.collective_sense +
                r.communication +
                r.creativity +
                r.critical_mind +
                r.curiousity +
                r.daring +
                r.emotional_intelligence +
                r.empathy +
                r.entrepreneurial_spirit +
                r.motivation +
                r.presence +
                r.stress_management +
                r.time_management +
                r.vision;

              return {
                ...r,
                opt_in: r.opt_in ? 'Oui' : 'Non',
                bc: getBC(r.bc),
                scoreTotal,
                realization_period: getPeriodeRealisation(r.realization_period as any),
              };
            });

            setResults(resp);
            const selectedTest = resp.filter((res: any) => res.id === +idTest);
            setResultToShow(selectedTest[0]);
          })
          .catch((err: any) => {
            if (
              err?.response?.data?.error?.name === 'PolicyError' &&
              err?.response?.data?.error?.status === 403
            ) {
              alert.openSimpleAlert('Cette page ne vous est pas accessible.');
              goToRootPage();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getResults(testId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getResults, emailUser, testId]);

  return {
    results,
    loading,
    resultToShow,
    company_user_role,
    onSelectPeriod,
    getResults,
  };
};

export default useResultsClient;
