export const reConstructArray = (array: any[]) => {

    const lastArray = array;

    const newArray: any[] = [];

    for (let i = 0; i < lastArray.length; i++) {

        const current = {
            ...lastArray[i],
            priority: i + 1
        };

        if (current.id) {
            newArray.push(current);
        }

    }

    return newArray;

}
