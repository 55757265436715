import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  testImg: {
    width: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
  },
}));

export default useStyles;
