/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';

const Page30 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={30}>
      <div style={{ marginBottom: '20px' }}>
        <TitleNbr number={3} text="ACCEPTER LES CONSÉQUENCES" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Tout changement professionnel se répercute sur l’ensemble des autres domaines de vie
              (couple, famille, vie sociale et amicale, passions personnelles). Quand on change,
              c’est tout notre entourage qui change. Ce changement peut provoquer des crises dans
              différents domaines de vie et ce, avant qu’un nouvel équilibre ne s’instaure. Plus le
              changement professionnel est important (identité, statut, rémunération, etc.), plus il
              sera susceptible de se traduire par une modification profonde de vos relations avec
              vos proches.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Voici trois situations caractéristiques vécues par des personnes que nous avons
              accompagnées :
            </Typography>
          </Grid>
        </Grid>
        <div className={clsx(classes.cardBgGrey, classes.bgGreyMargin)}>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Estelle a changé de métier dans le cadre d’une reconversion. Son conjoint était contre cette reconversion. Quelques mois plus tard, ils se sont séparés. Depuis ce changement de vie professionnelle, ils n’étaient plus sur la même longueur d’onde. Estelle a retrouvé un nouveau compagnon plus en phase avec sa vie actuelle.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={clsx(classes.cardBgGrey, classes.bgGreyMargin)}>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                David a décidé d’assouvir ses ambitions en créant une entreprise. Son entreprise
                s’est très vite développée et il s’est enrichi rapidement. Plusieurs membres de sa
                famille et plusieurs de ses amis jaloux se sont mis à le critiquer et à le juger. Il
                a perdu leur amitié et a dû se créer de nouvelles relations.
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={clsx(classes.cardBgGrey, classes.bgGreyMargin)}>
          <Grid container={true} justifyContent="space-between">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Pour réaliser son rêve, Sabine a décidé de faire le tour du monde. Ce voyage a
                ébranlé son couple mais il s’est finalement trouvé renforcé, le jour où son
                compagnon Stéphane a décidé de prendre une année sabbatique pour la suivre. Ils ont
                passé une année géniale à l’étranger avant de retrouver leur vie d’avant.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 10,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 10,
      },
    },
    testimony: {
      fontSize: '17px',
    },
    bgGreyMargin: {
      marginTop: '50px',
    },
  })
);
export default Page30;
