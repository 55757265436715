/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, TablePersonality } from '@orientaction/components';
import { useMemo, useState } from 'react';
import useStyles from "../../style";
import { Link } from 'react-router-dom';
import { useLanguage } from '@orientaction/hooks';

const Page33 = (props: any) => {
  const classes = usesStyles();
  const classe = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_PREF.png' : 'PREF.png';
  }, [language]);
  // const toggleA_B = [
  //   {
  //     index: 1,
  //     title: 'EN GÉNÉRAL, VOS MEILLEURES IDÉES JAILLISSENT :',
  //     toggle: [
  //       {
  //         label: 'Lors d’un échange ou d’une discussion.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Quand vous réfléchissez seul(e).',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 2,
  //     title: 'QUAND VOUS PRÉPAREZ UNE RÉUNION :',
  //     toggle: [
  //       {
  //         label: 'Vous prévoyez minutieusement  l’ordre du jour.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous êtes ouvert(e) et prêt(e) à toutes les éventualités.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 3,
  //     title: 'QUAND UNE PERSONNE DE VOTRE ENTOURAGE A UN COMPORTEMENT QUI VOUS DÉPLAÎT :',
  //     toggle: [
  //       {
  //         label: 'Vous mettez sur la table les problèmes que vous rencontrez avec elle. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous évitez soigneusement de lui dire des choses qui pourraient la froisser.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 4,
  //     title: 'LE CONTEXTE QUI VOUS CONVIENT LE MIEUX EST PLUTÔT :',
  //     toggle: [
  //       {
  //         label: 'Très structuré, avec des règles claires et explicites. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Les échanges sont occasionnels et prévus à l’avance. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 5,
  //     title: 'VOUS APPRÉCIEZ UN(E) MANAGER(EUSE) AVEC LEQUEL(LAQUELLE) :',
  //     toggle: [
  //       {
  //         label: 'Il est possible de discuter et de le(a) rencontrer plusieurs fois par jour.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Les échanges sont occasionnels et prévus à l’avance.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 6,
  //     title: 'VOUS PRÉFÉREZ UNE RÉUNION D’ÉQUIPE QUI SOIT L’OCCASION :',
  //     toggle: [
  //       {
  //         label: 'De concrétiser des idées.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'De rechercher des idées.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 7,
  //     title: 'EN GÉNÉRAL, VOUS PRÉFÉREZ UN CONTEXTE RELATIONNEL OÙ :',
  //     toggle: [
  //       {
  //         label: 'Les désaccords peuvent s’exprimer librement lors de discussions franches. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'L’harmonie est valorisée au point d’éviter soigneusement tout conflit. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 8, 
  //     title: 'VOUS PRÉFÉREZ VOUS LANCER DANS DES PROJETS :',
  //     toggle: [
  //       {
  //         label: 'Préparés et planifiés soigneusement à l’avance.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Partir à l’aventure en vous adaptant aux circonstances. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 9,
  //     title: 'À L’HEURE DU DÉJEUNER, VOUS PRÉFÉREZ :',
  //     toggle: [
  //       {
  //         label: 'Rejoindre un groupe de collègues. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Rester seul(e) ou en tête-à-tête avec un(e) proche.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 10,
  //     title: 'LORSQU’ON VOUS CONFIE UNE TÂCHE PARTICULIÈREMENT DIFFICILE :',
  //     toggle: [
  //       {
  //         label: 'Vous réunissez le maximum de données avant de commencer. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous plongez tout de suite dans l’action en comptant sur votre capacité à faire face.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 11,
  //     title: 'DANS VOTRE PERSONNALITÉ, VOUS PRÉFÉREZ QU’ON SOULIGNE :',
  //     toggle: [
  //       {
  //         label: 'Vos capacités intellectuelles et rationnelles. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Votre style chaleureux et convivial.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 12,
  //     title: 'VOUS AIMEZ :',
  //     toggle: [
  //       {
  //         label: 'Prévoir l’organisation de votre travail quotidien. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Laisser place à l’improvisation en vous adaptant en fonction des urgences. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 13,
  //     title: 'EN RÈGLE GÉNÉRALE, VOUS AIMEZ QUE LA PORTE DE VOTRE BUREAU SOIT :',
  //     toggle: [
  //       {
  //         label: 'Ouverte. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Fermée.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 14,
  //     title: 'QUAND UNE NOUVELLE IDÉE VOUS VIENT À L’ESPRIT :',
  //     toggle: [
  //       {
  //         label: 'Vous évaluez le pour et le contre avant de vous laisser emporter par la joie. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous êtes excité(e) et vous voulez immédiatement en parler ou la mettre en œuvre. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 15,
  //     title: 'DANS UNE RÉUNION, VOUS PRÉFÉREZ :',
  //     toggle: [
  //       {
  //         label: 'Une discussion détaillée des faits. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Une discussion sur les valeurs et les intentions sous-jacentes. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 16,
  //     title: 'VOUS AIMEZ AVOIR :',
  //     toggle: [
  //       {
  //         label: 'Des résultats tangibles très fréquents.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Des résultats à longue échéance. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 17,
  //     title: 'GÉNÉRALEMENT, VOUS ÊTES PLUS À L’AISE :',
  //     toggle: [
  //       {
  //         label: 'Quand vous avez une série de petites tâches. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Quand on vous confie une mission longue où vous pouvez concentrer vos efforts.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 18,
  //     title: 'QUAND VOUS DEVEZ RÉDIGER UN RAPPORT :',
  //     toggle: [
  //       {
  //         label: 'Vous pensez d’abord aux données que vous voulez relater. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous pensez d’abord au plan d’ensemble.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 19,
  //     title: 'UN(E) COLLÈGUE VIENT DE TERMINER UN EXPOSÉ :',
  //     toggle: [
  //       {
  //         label: 'Vous vous engagez dans une discussion franche et critique. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous choisissez avec prudence vos commentaires. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 20,
  //     title: 'DANS VOTRE BUREAU :',
  //     toggle: [
  //       {
  //         label: 'Pas un papier ne traîne, tout est soigneusement rangé. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'On trouve un fouillis sympathique de papiers et de dossiers. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 21,
  //     title: 'QUAND VOUS DEVEZ ORGANISER UNE RÉUNION :',
  //     toggle: [
  //       {
  //         label: 'Vous êtes ravi(e) si vous devez également l’animer. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous vous arrangez pour que quelqu’un d’autre prenne en charge l’animation. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 22,
  //     title: 'POUR PRÉPARER UN PLAN STRATÉGIQUE :',
  //     toggle: [
  //       {
  //         label: 'Vous vous attachez à ce qu’il faudrait concrètement réaliser à court terme. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous développez les grands axes des orientations futures. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 23,
  //     title: 'POUR PRENDRE UNE DÉCISION IMPORTANTE :',
  //     toggle: [
  //       {
  //         label: 'Vous vous appuyez uniquement sur des faits et des chiffres.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous tenez compte des opinions et des sentiments de votre entourage. ',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 24,
  //     title: 'QUAND VOTRE PATRON(NE) VOUS CONFIE UNE NOUVELLE MISSION :',
  //     toggle: [
  //       {
  //         label: 'Vous aimez qu’il(elle) définisse les objectifs et les résultats qu’il(elle) attend de vous. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous aimez qu’il(elle) vous laisse carte blanche.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 25,
  //     title: 'VOUS PRÉFÉREZ TRAVAILLER :',
  //     toggle: [
  //       {
  //         label: 'Au sein d’une équipe nombreuse.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Avec deux ou trois personnes que vous connaissez bien.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 26,
  //     title: 'DANS UNE RÉUNION, VOUS ÊTES PLUTÔT DE CELLES (OU CEUX) QUI :',
  //     toggle: [
  //       {
  //         label: 'Écoutent en étant attentifs(ves) aux moindres détails. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Anticipent sur ce qui va être dit, quitte à interrompre souvent le(a) conférencier(ère).',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 27,
  //     title: 'SI QUELQU’UN REMET EN  CAUSE UNE DE VOS DÉCISIONS OU ACTIONS :',
  //     toggle: [
  //       {
  //         label: 'Vous campez fermement sur vos positions.',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous cherchez à éviter l’inconfort et le conflit.',
  //         value: 'B',
  //       },
  //     ],
  //   },
  //   {
  //     index: 28,
  //     title: 'APRÈS AVOIR FAIT UN CHOIX :',
  //     toggle: [
  //       {
  //         label: 'Vous êtes content(e) de l’avoir fait. ',
  //         value: 'A',
  //       },
  //       {
  //         label: 'Vous vous ménagez des portes de sortie.',
  //         value: 'B',
  //       },
  //     ],
  //   }
  // ];
  return (
    <Page id={35} pageNoTruncate={true} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            PASSEZ MAINTENANT LE TEST DES 32 PERSONNALITÉS PRÉFÉRENCES®
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={12}>
            <div className={classe.cardImageBtn}>
              <img
                src={`${imgBook2}/${image}`}
                alt="couverture intro"
                className={classe.imageBtn}
              />
              <Link
                to="/tests/preference-personality"
                target="_blank"
                rel="noopener noreferrer"
                className={classe.btnInside}
                style={{bottom: '12%'}}
                >
                Clique
              </Link>
            </div>

          </Grid>
        </Grid>
      </div>

      {/* <div style={{ marginBottom: '20px' }}>
        <TitleNbr
          number={1}
          text="PRÉSENTATION DU QUESTIONNAIRE"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Avec ce premier questionnaire nous partons à la découverte de votre personnalité 
          véritable. Il vous faut répondre aux questions qui vont suivre de la façon la plus 
          authentique possible. Essayez simplement de retrouver votre comportement 
          naturel face aux situations exposées, celui que vous préférez adopter entre tous, 
          celui qui est pour vous le plus spontané et le plus confortable. Ce sont vos réponses 
          spontanées qui mettront à jour votre personnalité véritable.
        </Typography>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr
          number={2}
          text="LES CONSIGNES À SUIVRE"
        />
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Pour chaque question entourez la lettre a ou b, (une seule réponse par question 
          impérativement). Choisissez la réponse qui correspond chez vous au comportement 
          le plus naturel, le plus spontané, le plus facile, celui qui vous demande le moins 
          d’efforts. Soyez vous-même !
        </Typography>
      </div>
      <div style={{ marginTop: '20px' }}>
        <TablePersonality toggleA_B={toggleA_B} />
      </div> */}
    </Page>
  );
};

const usesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    paragraphe2: {
      color: '#000A8C',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    }
  })
);
export default Page33;
