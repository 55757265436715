/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { UserProfileForm, UserProfileHeader } from '@orientaction/components';
import { useUser } from '@orientaction/hooks';
import { LayoutResultat } from '@orientaction/layouts';
import style from './style';

const UserProfileEdit = () => {
  const { user, updateUserById, loading, loadingUpdate }: any = useUser();

  const cls = style();

  return (
    <LayoutResultat isWhite={true} showLogo={false} noPadding={true}>
      <>
        <UserProfileHeader />

        <Grid container={true} direction="row" className={cls.root}>
          <Grid item={true} xs={12} sm={12} md={3} lg={3} />

          <Grid item={true} xs={12} sm={12} md={9} lg={9}>
            <UserProfileForm
              user={user || {}}
              updateUser={updateUserById}
              loading={loadingUpdate}
              loadingGetUser={loading}
            />
          </Grid>
        </Grid>
      </>
    </LayoutResultat>
  );
};

export default UserProfileEdit;
