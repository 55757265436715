/* eslint-disable no-use-before-define */
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { imgBook1, imgBook4 } from '@orientaction/utils';
import useStyles from './style';

const Page00 = () => {
  const classes = useStyles();
  return (
    <div id={`page-${3}`} style={{ marginBottom: '10px', marginTop: '10px' }}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} xs={12} md={8}>
          <Card className={classes.couverturePage} style={{position: "relative"}}>
            <p className="position-absolute-cover title-aside-side no-margin-content">ÉTAPE 4</p>
            <div className="position-title-cover">
              <div className="header__content bg--gradient">
                <div className="header__content-text no-padding-content">
                  <h1 className="header__content-title no-margin-content">
                    <span className="header__content-wrap">
                      <span className="header__content-value">
                        <span className="decoration decoration--blue">
                          <img
                            src="/images/Book1/quote-blue.png"
                            alt="Quote blue"
                            className="quote-blue"
                          />
                        </span>
                        <span className="number">5</span>
                      </span>

                      <span className="header__content-desc">
                        <span className="title">Étapes pour réussir</span>
                        <span className="subtitle">Votre évolution professionnelle </span>
                      </span>

                      <span className="decoration decoration--yellow">
                        <img
                          src="/images/Book1/quote-yellow.png"
                          alt="Quote yellow"
                          className="quote-yellow"
                        />
                      </span>
                    </span>
                  </h1>
                </div>
              </div>
            </div>
            <p className="footer-title no-margin-content">LEADER DU BILAN DE COMPÉTENCES</p>
            <div className="right-column position-absolute-cover no-padding-content main-title-step-four">
              <div className="right-column__content no-margin-content no-padding-content">
                <h2 className="main-title-h2">ÉTAPE 4</h2>
                <p className="no-padding-content text-font-line-height">IDENTIFIER VOS </p>
                <p className="no-padding-content text-font-line-height">COMPÉTENCES</p>
                <p className="no-padding-content text-font-line-height">ET ÉVALUER</p>
                <p>VOTRE POTENTIEL</p>
              </div>
            </div>
            <img
              src={`${imgBook1}/etape04_no_text.jpg`}
              alt="couverture intro"
              className={classes.imgpremierApercu}
            />
            {/* <img src={`${imgBook4}/cover.jpg`} alt="cover" className={classes.imgpremierApercu} /> */}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Page00;
