interface IlistTest {
    title: string;
    field: string;
}


interface IlistvalueArray {
    name: string;
    title: string;
    listTest: IlistTest[]
}

export const listvalueArray: IlistvalueArray[] = [
    {
        name: "A",
        title: "Autonomie",
        listTest: [
            {
                title: "Pouvoir créer, inventer, innover",
                field: "A1"
            },
            {
                title: "Choisir mes propres buts et mes propres méthodes",
                field: "A2"
            },
            {
                title: "Me sentir libre dans tout ce que je fais",
                field: "A3"
            },
            {
                title: "Vivre de façon indépendante",
                field: "A4"
            }
        ]
    },
    {
        name: "S",
        title: "Stimulation",
        listTest: [
            {
                title: "Avoir une vie diversifiée et variée",
                field: "S1"
            },
            {
                title: "Vivre des aventures dans des endroits variés",
                field: "S2"
            },
            {
                title: "Avoir une vie passionnante et excitante",
                field: "S3"
            },
            {
                title: "Connaître différentes expériences",
                field: "S4"
            }
        ]
    },
    {
        name: "H",
        title: "Hédonisme",
        listTest: [
            {
                title: "Me faire plaisir dans ce que je fais",
                field: "H1"
            },
            {
                title: "Partager des moments de plaisir avec celles et ceux que j’aime",
                field: "H2"
            },
            {
                title: "Vivre le moment présent et profiter au maximum",
                field: "H3"
            },
            {
                title: "Avoir une vie agréable et rechercher le bien-être",
                field: "H4"
            }
        ]
    },
    {
        name: "R",
        title: "Réussite",
        listTest: [
            {
                title: "Réaliser mes ambitions et atteindre mes objectifs",
                field: "R1"
            },
            {
                title: "Avoir de l’influence, rayonner",
                field: "R2"
            },
            {
                title: "Avoir du succès dans ce que j’entreprends",
                field: "R3"
            },
            {
                title: "Connaître la célébrité ou la gloire",
                field: "R4"
            }
        ]
    },
    {
        name: "P",
        title: "Pouvoir",
        listTest: [
            {
                title: "Avoir de l’influence sur les évènements",
                field: "P1"
            },
            {
                title: "Avoir de l’influence sur les autres",
                field: "P2"
            },
            {
                title: "Gagner beaucoup d’argent et faire fortune",
                field: "P3"
            },
            {
                title: "Côtoyer des personnes qui ont du pouvoir",
                field: "P4"
            }
        ]
    },
    {
        name: "S2",
        title: "Sécurité",
        listTest: [
            {
                title: "Avoir une existence stable",
                field: "S21"
            },
            {
                title: "Bénéficier de la sécurité de l’emploi",
                field: "S22"
            },
            {
                title: "Vivre en forme et en bonne santé",
                field: "S23"
            },
            {
                title: "Être prudent(e) et éviter les risques inconsidérés",
                field: "S24"
            }
        ]
    },
    {
        name: "C",
        title: "Conformité",
        listTest: [
            {
                title: "Mener une vie honnête, juste et droite",
                field: "C1"
            },
            {
                title: "Me montrer modéré(e) dans mes comportements",
                field: "C2"
            },
            {
                title: "Avoir une bonne hygiène de vie au quotidien",
                field: "C3"
            },
            {
                title: "Ne jamais me comporter avec excès",
                field: "C4"
            }
        ]
    },
    {
        name: "T",
        title: "Tradition",
        listTest: [
            {
                title: "Avoir une vie qui respecte les principes moraux",
                field: "T1"
            },
            {
                title: "Me montrer toujours modeste et humble",
                field: "T2"
            },
            {
                title: "Avoir une vie qui soit en accord avec mes croyances",
                field: "T3"
            },
            {
                title: "Vivre une vie régie par la tradition",
                field: "T4"
            }
        ]
    },
    {
        name: "B",
        title: "Bienveillance",
        listTest: [
            {
                title: "Me comporter avec le sens des responsabilités",
                field: "B1"
            },
            {
                title: "Me montrer loyal(e) et bienveillant(e) envers autrui",
                field: "B2"
            },
            {
                title: "Aider les personnes les plus fragiles",
                field: "B3"
            },
            {
                title: "Me soucier du sort des autres",
                field: "B4"
            }
        ]
    },
    {
        name: "U",
        title: "Universalité",
        listTest: [
            {
                title: "Me battre pour changer le monde",
                field: "U1"
            },
            {
                title: "Toujours me montrer ouvert(e) aux autres",
                field: "U2"
            },
            {
                title: "Lutter pour un monde plus harmonieux",
                field: "U3"
            },
            {
                title: "Protéger les êtres humains, les animaux et la nature",
                field: "U4"
            }
        ]
    },
]
