// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page } from '@orientaction/components';
import useStyles from './style';
import ArrayYesNoDisable from '../../components/ArrayYesNoDisable';
import { useBook5 } from '@orientaction/hooks_book';
import { MarginContainer } from '@orientaction/commons';
import { list1, list2 } from './context';
import Img from '../../components/Img';
import SimpleBanner from '../../../components/SimpleBanner';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const Page25 = (props: any) => {
  const classes = useStyles();

  const { readyToSuccess, setReadyToSucess } = useBook5();

  return (
    <Page id={25}>
      <TitlePage showSpanYellow={false}>EXEMPLE DE JÉRÔME – TABLEAU DE SYNTHÈSE</TitlePage>

      <MarginContainer big={true}>
        {/* <Img src='rappelHistoire.png' alt=' EXEMPLE DE JÉRÔME – TABLEAU DE SYNTHÈSE' className={classe.img} /> */}
        <SimpleBanner>
          <Typography
            component="h1"
            className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
          >
            RAPPEL DE L’HISTOIRE DE JÉRÔME
          </Typography>
        </SimpleBanner>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Jérôme habite à Grasse. Il travaille dans un BIOCOP en tant que vendeur.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Les opportunités professionnelles intéressantes étant limitées dans le sud de la France,
          il a pour projet d’entreprendre une formation afin d’obtenir un Bac +3 en Marketing &
          Communication. Ce projet implique pour lui de déménager dans le nord de la France. Ce
          choix est donc crucial car il aura à s’éloigner de sa famille.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Par ailleurs, reprendre des études n’est pas si évident à vingt-six ans alors qu’il a déjà
          conquis son indépendance. Voici le travail réalisé par Jérôme.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer big={true}>
        <ArrayYesNoDisable
          title="CE QUE VOUS DEVEZ ÊTRE PRÊT(E) À FAIRE POUR RÉUSSIR"
          list={list1}
        />
      </MarginContainer>

      <MarginContainer big={true}>
        <ArrayYesNoDisable title="CE À QUOI VOUS ALLEZ DEVOIR RENONCER" list={list2} />
      </MarginContainer>
    </Page>
  );
};
export default Page25;
