/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Card, Grid, Theme, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { blockRedirection, publicSvgPath, resultatWithColor } from '@orientaction/utils';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useStyles } from './style';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';
import SimpleModal from '@orientaction/components/SimpleModal';

const CardGoFar = (props: any) => {
  const { handleContactClick } = props;

  const classes = useStyles();
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [openModal, setOpenModal] = useState<boolean>(false);
  const lang: string = useSelector(appLanguage);

  return (
    <div className={classes.cardBord}>
      <Typography variant="h2" color="textPrimary" className={classes.titleContent0}>
        Pour aller plus loin
      </Typography>
      <Grid container={true} spacing={isTab ? 0 : 2} className={classes.paddingCard}>
        <Grid item={true} xs={12} sm={12} md={6} className={classes.paddingCard1}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.titleContent}
            style={{ fontSize: 19 }}
          >
            ACHETER LE LIVRE
          </Typography>
          <img src={`${publicSvgPath}/illustration_livre.png`} alt="" className={classes.imgCard} />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.description}
          >
            Découvrez le livre " la méthode 16 soft skills ". <br />
            Il permet de développer vos soft skills dans <br />
            les domaines pro et perso.
          </Typography>
          <Button
            onClick={() =>
              blockRedirection({
                language: lang,
                onOpen: () => setOpenModal(true),
                functionToPass: () => {
                  window.open('https://orientaction.kneo.me/shop/view/868273', '_blank');
                },
              })
            }
            className={classes.btnCard}
            style={{ background: '#E63C3C', color: 'white' }}
          >
            Acheter le livre
          </Button>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6}>
          <Card className={classes.cardRed}>
            <div className={classes.cardTopRed} />
            <div className={classes.paddingCard2}>
              <img
                src={`${publicSvgPath}/picto_conversation.svg`}
                alt=""
                className={clsx(classes.imgCard, classes.imgCardMobile)}
                style={{ width: '15%', marginTop: 60 }}
              />
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ fontSize: 19 }}
              >
                CONTACTEZ NOTRE SERVICE <br />
                DE FORMATION
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.description}
                style={{ marginTop: 10 }}
              >
                Pour une offre de formation personnalisée
              </Typography>
              <Button
                onClick={handleContactClick}
                className={classes.btnCard}
                style={{ background: 'white', color: 'black' }}
              >
                Nous contacter
              </Button>
            </div>
          </Card>
        </Grid>
        {/* <Grid item={true} md={6}></Grid> */}
      </Grid>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Not yet available in English – work in progress."
      />
    </div>
  );
};

CardGoFar.defaultProps = {
  isSoftSkills: false,
};

export default CardGoFar;
