import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { userService } from '@orientaction/services';
import { isAdmin } from '@orientaction/utils';
import { FC, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useHistory, useLocation } from 'react-router-dom';
import { getRedirectPathByCurrentPahname } from '../../Login';
import style from './style';

interface IProps {
  handleAccountConfirm: any;
  showAccountConfirm: boolean;
  dataStep1: any;
  handlePrevious: any;
}

const SecondStep: FC<IProps> = ({
  handlePrevious,
  dataStep1,
  handleAccountConfirm,
  showAccountConfirm,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>({});
  const [registerError, setRegisterError] = useState<any>({});

  const { search, pathname } = useLocation();
  const history = useHistory();

  const classes = style();

  const verifyFormulaire = () => {
    const errorForm: any = { ...registerError };

    if (!current.phone || current.phone.length <= 2) {
      errorForm.phone = 'Champ obligatoire';
    } else {
      delete errorForm.phone;
    }

    if (!current.postal_code) {
      errorForm.postal_code = 'Champ obligatoire';
    } else {
      delete errorForm.postal_code;
    }

    setRegisterError(errorForm);

    if (Object.keys(errorForm).length) {
      return true;
    }

    return false;
  };

  const handlePhoneNumberChange = (value: any) => {
    setCurrent({
      ...current,
      phone: value,
    });
  };

  const handleChange = (data: any) => {
    const { name, value } = data.target;

    setCurrent({
      ...current,
      [name]: value,
    });
  };

  const goToPrevious = () => {
    handlePrevious({ data: dataStep1 });
  };

  const onSubmitHandler = async (data: any) => {
    data.preventDefault();

    data.stopPropagation();

    if (verifyFormulaire()) {
      return;
    }

    setIsLoading(true);
    try {
      const from = new URLSearchParams(search).get('from');
      const secondStepData = { ...current };
      // Persist + part
      secondStepData.phone = `+${secondStepData.phone}`;
      // check if french phone number
      if (current?.phone?.startsWith(330)) {
        // Remove zero if second number part start with it
        const secondPart = current?.phone?.substring(3);
        secondStepData.phone = `+33${secondPart}`;
      }
      const registerData = await userService.registerUser({
        ...dataStep1,
        ...secondStepData,
        fromTest: from === 'test',
        company: 1,
        username: dataStep1.email,
        confirmed: true,
      });

      if (registerData?.data?.jwt) {
        new Promise((resolve: any) => {
          localStorage.setItem('userInfos', JSON.stringify(registerData?.data?.user));
          localStorage.setItem('userToken', registerData?.data?.jwt);
          resolve(registerData?.data?.jwt);
        }).then(async token => {
          // eslint-disable-next-line no-unused-expressions
          const redirectLink = isAdmin(registerData?.data?.user)
            ? 'admin/users-resultats'
            : getRedirectPathByCurrentPahname(pathname);
          history.push(redirectLink);
        });
      } else if (registerData?.data?.user?.confirmed === false) {
        handleAccountConfirm(true);
      }
    } catch (error) {
      setRegisterError('Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={onSubmitHandler} className={classes.form}>
      <div className={classes.phoneField}>
        <h5 className={classes.labelText}>Numéro de téléphone</h5>

        <PhoneInput
          inputProps={{
            name: 'phone',
          }}
          specialLabel=""
          country="fr"
          value={current.phone || ''}
          onChange={handlePhoneNumberChange}
          masks={{
            fr: '..........',
          }}
          preferredCountries={['fr', 'be', 'ch', 'ca']}
          preserveOrder={['preferredCountries']}
          inputStyle={{
            width: '100%',
          }}
        />

        {registerError?.phone && <span className={classes.errorField}>{registerError.phone}</span>}
      </div>

      <div className={classes.fieldWithLabel}>
        <h5 className={classes.labelText}>Code postal</h5>

        <Input
          placeholder="Entrez votre code postal"
          name="postal_code"
          onChange={handleChange}
          type="text"
          className={classes.input}
        />

        {registerError?.postal_code && (
          <span className={classes.errorField}>{registerError.postal_code}</span>
        )}
      </div>

      <Grid className={classes.btnConnectContainer} container={true} spacing={1}>
        <Grid item={true} xs={12} md={12}>
          <Button
            type="submit"
            className={classes.btnConnect}
            disabled={isLoading}
            data-cy="save-user">
            {isLoading ? 'Terminer l’inscription...' : 'Terminer l’inscription'}
          </Button>
        </Grid>
      </Grid>
      <Box className={classes.previousLinkContainer}>
        <div onClick={goToPrevious} className={classes.previousLinkText}>
          Précédent
        </div>
      </Box>
    </form>
  );
};

export default SecondStep;
