export const dataEmpty = [
    {
        "title": "Préférence sociale",
        "youAre": "une personne de relation",
        "key": "interaction_social",
        "label": "Interaction",
        "img": "/images/Preference/Interaction.jpg",
        "color": "#692960",
        "score": [
            {
                "pourcentage": 40,
                "key": "interaction_social",
                "label": "Interaction",
            },
            {
                "pourcentage": 60,
                "key": "reflexion_social",
                "label": "Réflexion",
                "isMax": true
            }
        ],
        "category": "social"
    },
    {
        "title": "Préférence perceptive",
        "youAre": "une personne d’expérience",
        "label": "Expérience",
        "key": "experience_perceptual",
        "img": "/images/Preference/Experience.jpg",
        "color": "#933754",
        "score": [
            {
                "pourcentage": 42,
                "key": "experience_perceptual",
                "label": "Expérience",
            },
            {
                "pourcentage": 58,
                "key": "imagination_perceptual",
                "label": "Imagination",
                "isMax": true
            }
        ],
        "category": "perceptual"
    },
    {
        "title": "Préférence émotionnelle",
        "youAre": "une personne rationnelle",
        "label": "Rationalité",
        "key": "rationality_emotional",
        "img": "/images/Preference/Decision.jpg",
        "color": "#BA534B",
        "score": [
            {
                "pourcentage": 40,
                "key": "rationality_emotional",
                "label": "Rationalité",
            },
            {
                "pourcentage": 60,
                "key": "empathy_emotional",
                "label": "Empathie",
                "isMax": true
            }
        ],
        "category": "emotional"
    },
    {
        "title": "Préférence comportementale",
        "label": "Action",
        "youAre": "une personne d’action",
        "key": "action_behavioral",
        "img": "/images/Preference/Action.jpg",
        "color": "#E87E59",
        "score": [
            {
                "pourcentage": 72,
                "key": "action_behavioral",
                "label": "Action",
                "isMax": true
            },
            {
                "pourcentage": 28,
                "key": "observation_behavioral",
                "label": "Observation"
            }
        ],
        "category": "behavioral"
    },
    {
        "title": "Préférence environnementale ",
        "youAre": "une personne d’adaptation",
        "key": "adaptation_environmental",
        "label": "Adaptation",
        "img": "/images/Preference/Adaptation.jpg",
        "color": "#E7A850",
        "score": [
            {
                "pourcentage": 40,
                "key": "organization_environmental",
                "label": "Organisation"
            },
            {
                "pourcentage": 60,
                "key": "adaptation_environmental",
                "label": "Adaptation",
                "isMax": true
            }
        ],
        "category": "environmental"
    }
]