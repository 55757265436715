/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import DateRangeIcon from '@material-ui/icons/DateRange';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import clsx from 'clsx';
import dateFormat from 'dateformat';
import { FC, useState } from 'react';
import { useStyles, AccordionDetails } from './style';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

type TimelineProps = {
  testResults: any[];
  idTest: number;
  toRedirect: string;
  idUser?: string;
  //   onChangeDate: (value: number) => void;
};

const MinorTestHistory: FC<TimelineProps> = ({ testResults, idTest, toRedirect, idUser }) => {
  const classes = useStyles();

  const [openHistory, setOpenHistory] = useState<boolean>(false);

  const history = useHistory();

  const handleChange = (id: number) => () => {
    history.push(!idUser ? `${toRedirect}/${id}` : `${toRedirect}/${id}/${idUser}`);
  };

  const handleOpenhHistory = () => {
    setOpenHistory(!openHistory);
  };

  const isMobil = useMediaQuery((theme: Theme) => theme.breakpoints.down(900));

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up(900));

  return (
    <div>
      <MuiAccordion
        expanded={openHistory}
        onChange={handleOpenhHistory}
        className={clsx({
          [classes.rootAccordion]: true,
          [classes.accordionMobileClose]: !openHistory && isMobil,
          [classes.zIndexMaximum]: openHistory,
        })}>
        <MuiAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          className={classes.timelineTitle}>
          <div>
            <DateRangeIcon fontSize="small" style={{ marginRight: 7, marginTop: 2 }} />
          </div>

          {((isMobil && openHistory && !isDesktop) || isDesktop) && <div>Historique</div>}
        </MuiAccordionSummary>

        <AccordionDetails>
          <ul className={classes.ulHistory}>
            {testResults
              .sort(
                (a: any, b: any) =>
                  new Date(b?.attributes?.createdAt as any).valueOf() -
                  new Date(a?.attributes?.createdAt as any).valueOf()
              )
              .map((test: any) => {
                return (
                  <li
                    key={test.id}
                    className={clsx({
                      [classes.labelPeriod]: true,
                      [classes.isHighlighted]: test.id === idTest,
                    })}>
                    <div onClick={handleChange(test.id)}>
                      {test?.attributes?.createdAt
                        ? dateFormat(new Date(test?.attributes?.createdAt), 'dd/mm/yyyy, HH:MM')
                        : '-'}
                    </div>
                  </li>
                );
              })}
          </ul>
        </AccordionDetails>
      </MuiAccordion>
    </div>
  );
};

export default MinorTestHistory;
