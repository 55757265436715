// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { Paragrapth, Page, TitleUpCase, ListParagrapth, TitlePage } from '@orientaction/components';
import useStyles from '../../style';

const Page38 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={37}>
      <TitleUpCase>POUVOIR</TitleUpCase>

      <Paragrapth align="justify">
        Le principe directeur de votre vie :{' '}
        <Paragrapth align="start" colorBlue={true}>
          {' '}
          <strong>Quand je serai grand(e), je serai Président(e) !</strong>{' '}
        </Paragrapth>
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Vous êtes animé(e) par une quête de pouvoir et d’influence.
        <br />
        <br />
        Que cela soit par l’argent, vos réseaux, votre statut ou votre intelligence, vous cherchez à
        développer votre pouvoir sur le monde et les gens qui vous entourent. Sur le plan
        professionnel, vous tirez votre puissance de votre statut, qui doit être élevé dans la
        hiérarchie, de votre capacité à influencer et à convaincre.
        <br />
        <br />
        Cela nécessite chez vous de développer des compétences politiques pour pouvoir gravir au
        plus vite les échelons et atteindre des sommets.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify" colorBlue={true}>
        {' '}
        <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
      </Paragrapth>

      <ListParagrapth
        list={[
          'Compléter votre formation par une année supplémentaire (Master, Mastère ou MBA) dans une grande école ou à l’université pour booster votre carrière,',
          'Vous engager dans un programme de coaching pour développer vos savoir-être et booster votre carrière,',
          'Vous engager dans une carrière politique ou prendre des responsabilités dans une association en parallèle de votre activité professionnelle.',
        ]}
      />
      <br />
      <br />
      <br />
      <TitleUpCase>SÉCURITÉ</TitleUpCase>

      <Paragrapth align="justify">
        Le principe directeur de votre vie :{' '}
        <Paragrapth align="start" colorBlue={true}>
          {' '}
          <strong>Prudence est mère de sûreté !</strong>{' '}
        </Paragrapth>
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        La recherche de sécurité est ce qui guide votre vie, que cela soit dans vos relations aux
        autres, dans votre métier ou dans la gestion de votre vie personnelle.
        <br />
        <br />
        Vous aimez la stabilité, l’incertitude vous effraie et prendre des risques n’est pas dans
        votre tempérament.
        <br />
        <br />
        Sur le plan professionnel, cela se traduit par la nécessité de travailler dans de grandes
        organisations vous offrant la possibilité de développer votre potentiel sur le long terme
        tout en vous assurant une sécurité de l’emploi.
        <br />
        <br />
        Vous appréciez les employeurs aux reins solides.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify" colorBlue={true}>
        {' '}
        <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
      </Paragrapth>

      <ListParagrapth
        list={[
          'Passer un concours de la fonction publique afin de bénéficier du statut de fonctionnaire et donc d’un contrat de travail très protecteur,',
          'Trouver un emploi dans une grande entreprise ou une grande association, qui offre plus de stabilité et de sécurité que les TPE/PME,',
          'Évoluer vers un secteur d’activité plus stable et plus sécurisé (l’énergie, les banques et les assurances).',
        ]}
      />
    </Page>
  );
};

export default Page38;
