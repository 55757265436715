import { FC } from 'react';
import useStyle from './style';
import { Typography, Grid } from '@material-ui/core';
import clsx from 'clsx';

interface ITitleNbr {
  number?: number;
  text: string;
  isSmall?: boolean;
  isItalic?: boolean;
  isLight?: boolean;
  notBold?: boolean;
  colorBlack?: boolean;
  moreSpace?: boolean;
}

const TitleNbr: FC<ITitleNbr> = ({
  number,
  text,
  isSmall,
  isItalic,
  isLight,
  notBold,
  colorBlack,
  moreSpace,
}) => {
  const classe = useStyle();

  return (
    <div className={classe.content}>
      {number && (
        <span className={classe.number1}>
          <strong>{number || 0}</strong>
        </span>
      )}
      <Typography
        className={clsx({
          [classe.notBold]: notBold,
          [classe.small]: isSmall,
          [classe.italic]: isItalic,
          [classe.colorBlack]: colorBlack,
          [classe.moreSpace]: moreSpace,
          [classe.center]: text.length < 200,
          [classe.h3]: true,
          [classe.noMarginLeft]: !number,
        })}>
        <span>{isLight ? text || '' : <strong>{text || ''}</strong>}</span>
      </Typography>
    </div>
  );
};

export default TitleNbr;
