import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const style = makeStyles((theme: Theme) =>
  createStyles({
    contentLoader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
  })
);

export default style;
