export const listForm = [
    {
        name: 'ThisProjectMakesSense',
        title: 'Ce projet a du sens pour vous parce qu’il : '
    },
    {
        name: 'thisProjectWillAllowYouToExpress',
        title: 'Notez ici les valeurs que ce projet vous permettra d’exprimer (cf. étape 1) :'
    },
    {
        name: 'figureOfFateThisProject',
        title: 'Notez ici à quelle figure du destin ce projet correspond (cf. étape 1) :'
    }
]