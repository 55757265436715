/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';

const Page28 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={28}>
      <div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className="opacity07">
              LES QUATRE FAÇONS DE CHANGER
              <br />
              (OU DE NE PAS CHANGER)
            </Typography>
          </div>
          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20,
              }}>
              <strong>‘‘ Le seul mauvais choix est l’absence de choix ’’</strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter', fontSize: 20 }}>
              Amélie Nothomb
            </Typography>
          </div>
          <div
            className={clsx(classes.positionCenter)}
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          <div style={{ textAlign: 'center' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20,
              }}>
              <strong>‘‘ Soyez le changement que vous voulez voir dans le monde. ’’</strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter', fontSize: 20 }}>
              Gandhi
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      <Grid container={true} spacing={1} justifyContent="center">
        <Grid item={true} xs={12}>
          <Typography
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
            }}>
            Face à une situation professionnelle déplaisante, un emploi peu épanouissant ou peu
            enthousiasmant, il existe quatre réactions possibles :
          </Typography>
        </Grid>
      </Grid>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={1} text="NE PAS CHANGER" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Le président Roosevelt disait : « La plupart des gens préfèrent ne pas changer, du
              moment que leurs souffrances restent supportables. » Cela est peut-être votre cas.
              Vous êtes dans une situation inconfortable et déplaisante, certes, mais, à bien y
              réfléchir, le changement vous effraie et vous demanderait trop d’efforts
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Vous pouvez alors choisir, c’est votre droit, de rester dans votre situation et de
              vous en accommoder. Ce chemin est celui de la résignation et de l’acceptation. Ce
              n’est pas la voie que je préfère, mais elle est honorable.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <TitleNbr number={2} text="CHANGER LE MONDE OU LES GENS QUI VOUS ENTOURENT" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Vous pouvez décider de ne pas changer, mais plutôt de changer le monde ou les gens qui
              vous entourent. L’ambiance au travail, la manière dont s’effectuent vos tâches et dont
              se déroulent vos missions.
              Faites-le.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Les habitudes de vos collègues vous déplaisent, vous pouvez choisir de les faire
              changer ou tout au moins essayer de le faire… C’est un chemin difficile, car
              l’expérience montre que les gens ne changent pas facilement. C’est le chemin de
              l’engagement politique. Si vous êtes convaincu(e) de pouvoir faire bouger les choses,
              faites-le.
            </Typography>
          </Grid>
        </Grid>
        <br />
        <TitleNbr number={3} text="VOUS CHANGER VOUS-MÊME" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Il n’existe pas de réalité objective. C’est notre façon de regarder les choses qui en
              détermine la nature. Peut-être votre situation n’est-elle pas aussi négative que vous
              le pensez, peut-être votre insatisfaction est-elle due à votre façon de regarder votre
              vie.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Vous voyez toujours la bouteille à moitié vide, là où d’autres la voient à moitié
              pleine ? Vous pouvez choisir de transformer votre façon de voir les choses, modifier
              vos comportements en conséquence et obtenir l’amélioration souhaitée.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              C’est le chemin du développement personnel, du travail sur soi.
            </Typography>
          </Grid>
        </Grid>
        <br />

        <TitleNbr number={4} text="CHANGER DE VIE" />

        <Grid container={true} spacing={1} justifyContent="center">

          <Grid item={true} xs={12}>

            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Il est pour vous hors de question de continuer à vivre de cette façon.
            </Typography>

            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Les combats épiques, vous préférez les laisser aux autres. Vous avez beau
              chercher à voir le positif, vous ne le trouvez pas, car il n’existe pas !!!
            </Typography>

            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Choisissez de tout changer : quittez cette organisation (entreprise,
              association, administration) dont le mode de fonctionnement vous oppresse,
              ces collègues qui vous ennuient, ce travail qui vous épuise. Pour respirer,
              vous sentir libre, partir à l’aventure, vivre tout simplement.
            </Typography>

          </Grid>

        </Grid>

      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
              }}>
              <strong>Allons-y, alors !</strong>
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
              }}>
              <strong>Les consultant(e)s ORIENTACTION sont là pour vous accompagner.</strong>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
      textAlign: 'center',
    },
  })
);
export default Page28;
