// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page, TitleUpCase, ListParagrapth } from '@orientaction/components';
import useStyles from '../../style';

const Page37 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={36}>
      <TitleUpCase>HÉDONISME</TitleUpCase>

      <Paragrapth align="justify">
        Le principe directeur de votre vie :{' '}
        <Paragrapth align="start" colorBlue={true}>
          <strong>Carpe diem !</strong>
        </Paragrapth>
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Vous êtes un(e) hédoniste. Vous recherchez le plaisir avant tout. Ce plaisir, vous le tirez
        de vos sens mais aussi de votre intellect. Vous entretenez une relation sensuelle à votre
        environnement. Vous avez besoin d’évoluer dans un environnement qui flatte par sa beauté ou
        par les sensations qu’il procure. Sur le plan professionnel, cela se traduit par la
        recherche du beau, de l’excellence et de l’harmonie.
        <br />
        <br />
        Vous avez besoin d’évoluer dans un milieu où l’activité, le produit, votre environnement
        relationnel et matériel sont une source continue de plaisir.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify" colorBlue={true}>
        {' '}
        <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
      </Paragrapth>

      <ListParagrapth
        list={[
          'Travailler dans le domaine du bien-être, de l’art ou du luxe,',
          'Créer une entreprise en choisissant un modèle de développement qui vous permette de vous faire plaisir (livre à lire : La semaine de 4h),',
          'Travailler pour un employeur qui propose un temps de travail réduit, des RTT et de nombreux jours de congés.',
        ]}
      />
      <br />
      <br />
      <br />
      <TitleUpCase>RÉUSSITE</TitleUpCase>

      <Paragrapth align="justify">
        Le principe directeur de votre vie :{' '}
        <Paragrapth align="start" colorBlue={true}>
          {' '}
          <strong>Être le(a) meilleur(e), seulement le(a) meilleur(e) !</strong>
        </Paragrapth>
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Fortune, gloire et beauté, vous voulez être la star de votre domaine et réussir{' '}
        <Paragrapth align="start" colorBlue={true}>
          {' '}
          <strong>DANS</strong>{' '}
        </Paragrapth>{' '}
        la vie !
        <br />
        <br />
        Vous êtes en quête de reconnaissance sociale. Vous voulez rayonner par votre charisme et
        votre aura. Sur le plan professionnel, vous avez besoin d’être reconnu(e) et écouté(e) par
        vos pairs, mais aussi par vos client(e)s et, en général, par toutes les personnes qui
        travaillent avec vous. Vous êtes prêt(e) à faire de nombreux efforts et sacrifices pour y
        arriver.
        <br />
        <br />
        Le travail ne vous fait pas peur, reste juste à trouver le domaine dans lequel vous pouvez
        devenir le(a) meilleur(e).
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify" colorBlue={true}>
        {' '}
        <strong style={{ fontSize: '16px' }}>Y avez-vous pensé ?</strong>
      </Paragrapth>

      <ListParagrapth
        list={[
          'Vous engager dans un programme de coaching pour développer vos savoir-être et booster votre carrière,',
          'Vous inscrire à un MBA (Master of Business Administration) dans une grande école afin de muscler votre profil,',
          'Passer des certifications, apprendre des langues étrangères, développer vos compétences.',
        ]}
      />
    </Page>
  );
};

export default Page37;
