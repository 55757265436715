/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Page, TableFigureChef, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, imgBook2 } from '../../../../utils/constants';

const lists = [
  'Vous aurez plus confiance en vous;',
  'Vous serez plus affirmé(e) dans vos choix;',
  'Vous serez capable de mettre en valeur vos talents;',
  'Vous serez capable de montrer ce que vous valez vraiment;',
  'Vous serez en capacité de vous exprimer de façon affirmée :',
  'Vos objectifs seront clairs;',
  'Vous serez sûr(e) de vous, plus déterminé(e) que jamais à réussir votre vie.',
];

const Page09 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={9}>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Travailler sur soi, apprendre sur soi, faire des choix, définir des priorités, apprendre à
          affirmer ses choix, savoir les expliquer clairement, donner l’image d’une personne qui
          sait ce qu’elle veut…
        </Typography>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Autant de bénéfices supplémentaires que vous tirerez de l’accompagnement réalisé avec la
          méthode ORIENTACTION.
        </Typography>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Notre prestation consiste non seulement à vous accompagner dans la définition d’un nouveau
          projet d’évolution professionnelle, mais aussi à vous développer personnellement.
        </Typography>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          Après avoir travaillé avec la méthode ORIENTACTION, vous ne serez plus la même personne :
        </Typography>
      </div>
      <div className={classes.ParagrapthList}>
        {lists.map((item: string, i: number) => (
          <div>
            <Grid container={true} justifyContent="flex-start" alignItems="center">
              <Grid item={true} xs={12}>
                <Typography
                  className={classes.text}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowRightIcon style={{color: 'black'}} />
                  <span style={{ marginLeft: '10px' }}>{item || ''}</span>
                </Typography>
              </Grid>
            </Grid>
            {i === 4 && (
              <Grid item={true} xs={12}>
                <Typography
                  className={classes.text}
                  style={{ display: 'flex', alignItems: 'center', marginLeft: "calc(1.8rem + 28px)" }}>
                  - ce que vous voulez... et ce que vous ne voulez pas, ou plus.
                </Typography>
                <Typography
                  className={classes.text}
                  style={{ display: 'flex', alignItems: 'center', marginLeft: "calc(1.8rem + 28px)" }}>
                  - ce que vous aimez faire... et ce que vous savez faire.
                </Typography>
              </Grid>
            )}
          </div>
        ))}
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      lineHeight: 1.7,
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    textWithImageContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
    },
    ParagrapthList: {
      padding: 10,
      marginTop: '10px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    text: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      lineHeight: '16px',
      color: '#2B56A5',
      display: 'inline-block',
      style: 'normal',
      padding: 10,
      [theme.breakpoints.down('sm')]: {
        margin: '0 2vh',
      },
    },
    colorRed: {
      color: 'red',
    },
  })
);
export default Page09;
