// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    btnPrimary: {
      textTransform: 'none',
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.primary.main,
      marginRight: 15,
      color: '#fff',
      width: '15vw',
    },
    textHeader: {
      display: 'none',
      marginLeft: 0,
      '@media(min-width: 600px)': {
        display: 'block',
        fontSize: '0.5rem',
      },
    },
    marginTop: {
      // marginTop: 15,
    },
    button: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      paddingLeft: 20,
      paddingRight: 20,
      textTransform: 'none',
      marginRight: 15,
      width: '12vw',
      '@media only screen and (max-width: 600px)': {
        display: 'none',
      },
      '@media only screen and (max-width: 391px)': {
        display: 'none',
      },
    },
    btnText: {
      fontSize: '0.8rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.7rem',
      },
    },
    textH3: {
      color: theme.palette.primary.main,
    },
    modalContent: {
      fontSize: 18,
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    modalText: {
      fontSize: 14,
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
  })
);
