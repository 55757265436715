import 'react-perfect-scrollbar/dist/css/styles.css';
import { FC } from 'react';
import ScrollBar, { ScrollBarProps } from 'react-perfect-scrollbar';

const Scrollbar: FC<ScrollBarProps> = props => {
  const { children } = props;

  return <ScrollBar {...props}>{children}</ScrollBar>;
};
export default Scrollbar;
