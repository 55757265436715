import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  testVariableImage: {
    width: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
  },
}));
