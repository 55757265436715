/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { figure32_page1, figure32_page2, figure32_page3, figure32_page4, figure32_page5 } from '@orientaction/utils';
import clsx from 'clsx';
import { TableAspiration, Page } from '@orientaction/components';
import concat from 'lodash/concat';
import chunck from 'lodash/chunk';

const Page27 = (props: any) => {
  const classes = useStyles();
  const allFiguresProtected = concat(figure32_page1, figure32_page2, figure32_page3, figure32_page4, figure32_page5)
  return (
    <Page id={27}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" color="primary" gutterBottom={true} className="opacity07">
          EXEMPLES DE MÉTIERS CORRESPONDANT <br /> À CHAQUE FIGURE DU DESTIN <br />
        </Typography>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} />
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TableAspiration figure32={chunck(allFiguresProtected, 12)[0]} />
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
  })
);

export default Page27;
