import { EN_PREFIX } from '@orientaction/utils';

export const getMajorTestEngUrlByTestId = (id: number | string) => {
  let url = '';
  switch (id) {
    case 1:
      url = `/${EN_PREFIX}/tests/meaning-values`;
      break;
    case 2:
      url = `/${EN_PREFIX}/tests/needs`;
      break;
    case 3:
      url = `/${EN_PREFIX}/tests/needs-pro`;
      break;
    case 4:
      url = `/${EN_PREFIX}/tests/preferences-personality`;
      break;
    case 5:
      url = `/${EN_PREFIX}/tests/harmony`;
      break;
    case 6:
      url = `/${EN_PREFIX}/tests/study`;
      break;
    default:
      break;
  }
  return url;
};
