import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 14,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    textCenterTop1: {
      textAlign: 'center',
      color: '#2b56a5',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '14px',
      color: '#2B56A5',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    fontWeightBold: {
      fontWeight: 'bold',
    },
    imgEmericLebreton: {
      width: '100%',
      height: 'auto',
    },
    before: {
      "& ::before": {
        content: "♥"
      }
    },
    imgFloat: {
      width: '45px',
      height: 'auto',
      float: 'left',
      marginLeft: '-60px',
      marginTop: '5px',
      '@media only screen and (max-width: 600px)': {
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'none',
      },
    }
  })
);

export default useStyles;
