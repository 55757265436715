import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getMeaningValueTestResults = async (token: string) => {
  const searchParams = qs.stringify(
    {
      populate: ['users_permissions_user'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/meaning-value-test-results?${searchParams}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getMyResultMeaningValue = async (token: string, id: number) => {
  return makeRequest(
    `${url}/api/get-result-meaning-value-test/${id}`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getMyResumeElement = async (token: string, data: any) => {
  return makeRequest(
    `${url}/api/get-my-resume-result-meaning-value`,
    'POST',
    data,
    getHeaders(token)
  );
};

export const getUserLastTestResult = async (token: string, data: any) => {
  return makeRequest(
    `${url}/api/meaning-value-test-results/get-last-result`,
    'GET',
    {},
    getHeaders(token)
  );
};
