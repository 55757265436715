export const fakeResults = [
  {
    id: 49517,
    bc: 'Bilan',
    problem_solving: 80,
    confidence: 60,
    emotional_intelligence: 70,
    empathy: 20,
    communication: 60,
    time_management: 80,
    stress_management: 90,
    creativity: 50,
    entrepreneurial_spirit: 60,
    daring: 40,
    motivation: 50,
    vision: 60,
    presence: 50,
    collective_sense: 60,
    curiousity: 10,
    critical_mind: 40,
    realization_date: '2023-03-06T21:00:00.000Z',
    time_spent: 12,
    opt_in: 'Oui',
    user_email: 'testing.mayasquad@yopmail.com',
    score: 880,
    realization_period: 'Midi',
    createdAt: '2023-03-07T11:42:05.094Z',
    updatedAt: '2023-03-07T11:42:05.121Z',
    publishedAt: '2023-03-07T11:42:05.094Z',
    scoreTotal: 880,
  },
  {
    id: 49216,
    bc: 'Bilan',
    problem_solving: 10,
    confidence: 15,
    emotional_intelligence: 20,
    empathy: 25,
    communication: 30,
    time_management: 35,
    stress_management: 40,
    creativity: 45,
    entrepreneurial_spirit: 50,
    daring: 55,
    motivation: 60,
    vision: 65,
    presence: 70,
    collective_sense: 75,
    curiousity: 80,
    critical_mind: 85,
    realization_date: '2023-03-01T21:00:00.000Z',
    time_spent: 500,
    opt_in: 'Oui',
    user_email: 'testing.mayasquad@yopmail.com',
    score: 980,
    realization_period: 'Soir',
    createdAt: '2023-03-01T20:12:34.056Z',
    updatedAt: '2023-03-06T17:29:03.825Z',
    publishedAt: '2023-03-01T20:12:34.056Z',
    scoreTotal: 760,
  },
];

export const fakeResultToShow = [
  {
    id: 49517,
    bc: 'Bilan',
    problem_solving: 80,
    confidence: 60,
    emotional_intelligence: 70,
    empathy: 20,
    communication: 60,
    time_management: 80,
    stress_management: 90,
    creativity: 50,
    entrepreneurial_spirit: 60,
    daring: 40,
    motivation: 50,
    vision: 60,
    presence: 50,
    collective_sense: 60,
    curiousity: 10,
    critical_mind: 40,
    realization_date: '2023-03-06T21:00:00.000Z',
    time_spent: 12,
    opt_in: 'Oui',
    user_email: 'testing.mayasquad@yopmail.com',
    score: 880,
    realization_period: 'Midi',
    createdAt: '2023-03-07T11:42:05.094Z',
    updatedAt: '2023-03-07T11:42:05.121Z',
    publishedAt: '2023-03-07T11:42:05.094Z',
    scoreTotal: 880,
  },
];
