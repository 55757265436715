/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { Grid } from '@material-ui/core';
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import { useBook5 } from '@orientaction/hooks_book';
import DatePiker from './DatePiker';
import useStyles from './style';

const Page32 = (props: any) => {
  const classes = useStyles();

  const { setPlanProject, planProject } = useBook5();

  const handleChangeArray = (index: number) => (e: any) => {
    const { name, value } = e.target;

    setPlanProject({
      ...planProject,
      [index]: {
        ...planProject[index],
        [name]: value,
      },
    });
  };

  return (
    <Page id={32} readOnly={false}>
      <TitlePage>PLANIFIEZ VOTRE PROJET</TitlePage>

      <MarginContainer big={true}>
        <Paragrapth align="justify">
          Notez dans ce tableau les dix actions prioritaires que vous jugez utiles de réaliser afin
          de concrétiser votre projet. Notez ensuite l’échéance, c’est-à-dire la date limite à
          laquelle ces actions doivent être réalisées. Gardez à l’esprit que l’une des clefs du
          succès de la conduite de projet est de garder le rythme. Évitez donc aussi bien de vous
          fixer des délais intenables, que de vous offrir une marge de liberté trop importante, qui
          risquerait de diluer votre action. La gestion de projet est un défi qui doit vous
          mobiliser pleinement.
        </Paragrapth>
      </MarginContainer>

      <Grid container={true} alignItems="stretch" direction="row">
        <Grid item={true} xs={3} md={2} className={classes.titlePage}>
          N°
        </Grid>

        <Grid item={true} xs={5} md={5} className={classes.titlePage}>
          ACTIONS À RÉALISER
        </Grid>

        <Grid item={true} xs={4} md={5} className={classes.titlePage}>
          ÉCHÉANCES
        </Grid>
      </Grid>

      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item: number, index: number) => (
        <Grid container={true} alignItems="stretch" direction="row">
          <Grid item={true} xs={3} md={2} className={classes.bodyContent}>
            <div
              className={classes.number}
              style={{
                width: 70 - index * 2,
                height: 70 - index * 2,
                borderRadius: 70 - index * 2,
              }}>
              {item}
            </div>
          </Grid>

          <Grid item={true} xs={5} md={5} className={classes.tableContainer}>
            <TextArea
              name="actionToDo"
              value={planProject[item]?.actionToDo || ''}
              notBorder={true}
              onChange={handleChangeArray(item)}
              readOnly={props.readOnly}
            />
          </Grid>

          <Grid item={true} xs={4} md={5} className={classes.bodyContent}>
            <DatePiker
              name="echeance"
              value={planProject[item]?.echeance || ''}
              onChange={handleChangeArray(item)}
              readOnly={props.readOnly}
            />
          </Grid>
        </Grid>
      ))}
    </Page>
  );
};

export default Page32;
