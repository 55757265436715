/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { CardGoFar, CardSens, HistoryMajorTest, Layout } from '@orientaction/components';
import {
  useAlert,
  useMeaningValueTestResult,
  useMyResulatOfTest,
  useRedirectToRootPage,
} from '@orientaction/hooks';
import { take } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BtnCanceled from '../BtnCanceled';
import ContactForm from '../ContactForm';
import DrawerView from './Drawer';
import FirstView from './FirstView';
import HeaderMeaning from './HeaderMeaning';
import { useStyles } from './style';

const ResultatMeanning = () => {
  const classe = useStyles();

  const [loading, setLoading] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false);

  const { dialog, myResult, setDialog, setMyResult } = useMyResulatOfTest();

  const { goToRootPage } = useRedirectToRootPage();

  const { getMyResult } = useMeaningValueTestResult();

  const alert = useAlert();

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const getResultat = async (idTest: number) => {
    setMyResult([]);

    setLoading(true);

    (window as any).scrollTo(0, 0);

    try {
      const { data } = await getMyResult(+idTest);
      console.log({ dataMeaning: data.data });
      setMyResult(data.data);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResultat(+id);
  }, [id]);

  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleOpen = (prop: any) => (e: any) => {
    setDialog({
      ...dialog,
      open: true,
      data: {
        keyWord: prop.key,
        color: prop.color,
        title: prop.youAre,
        nbr: prop.score,
      },
    });
  };

  const openContactModal = () => {
    setOpenContactForm((prev: boolean) => !prev);
  };

  return (
    <Layout isaddBackground={true} noPadding={true} isClassic={true} footer={true}>
      <HeaderMeaning />
      {loading ? (
        <div className={classe.contentLoader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classe.root}>
            <div className={classe.btnHistory}>
              <HistoryMajorTest
                testResults={myResult?.listHistory || []}
                idTest={+id}
                toRedirect="/resultat-meaning-value"
              />
            </div>

            <BtnCanceled id={myResult?.users_permissions_user} isBack={true} />

            <FirstView listSore={myResult?.resultat || []} />

            <Typography variant="h2" color="textPrimary" className={classe.titleText}>
              DÉCOUVREZ VOS 3 VALEURS PRINCIPALES
            </Typography>
            {console.log('myResult', myResult)}
            <Grid container={true} style={{ marginTop: 50, marginBottom: 50 }} spacing={3}>
              {take(myResult?.resultat, 3)?.map((res: any, i: number) => (
                <Grid key={i} item={true} xs={12} md={4}>
                  <CardSens sens={res} />
                </Grid>
              ))}
            </Grid>
            <Grid
              container={true}
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 50 }}
            >
              <Grid item={true} md={10}>
                <CardGoFar handleContactClick={openContactModal} />
              </Grid>
            </Grid>

            {/* <Content listPrincipaux={myResult?.resultat || []} handleOpenDrawer={handleOpen} /> */}
          </div>
          <ContactForm
            handleClose={openContactModal}
            test={{ id: 1, label: 'Sens®, test des valeurs' }}
            open={openContactForm}
          />
        </>
      )}

      <DrawerView open={dialog.open} handleClose={handleClose} />
    </Layout>
  );
};

export default withWidth()(ResultatMeanning);
