/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UserResultRadarGraph } from '@orientaction/components';
import Explication from '../Explication';
import { useStyles } from './style';

Chart.register(ChartDataLabels);

interface IresultToShow {
  resultToShow: any;
}

const FirstView: FC<IresultToShow> = ({ resultToShow }) => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <div className={classes.card}>
        {resultToShow && <UserResultRadarGraph resultToShow={resultToShow} />}
      </div>

      <Explication view={!isMobile} />
    </>
  );
};

export default FirstView;
