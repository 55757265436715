/* eslint-disable react-hooks/exhaustive-deps */
import Typography from '@material-ui/core/Typography';
import OaPopin from '@orientaction/commons/Popin';
import { AdminTestContext } from '@orientaction/Context';
import update from 'immutability-helper';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Card } from '../Card';
import useStyle from './style';

const style = {
  width: '100%',
};

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export const Container: FC = () => {
  const { updateTest, test } = useContext<any>(AdminTestContext);
  const [cards, setCards] = useState<any>();

  const classes = useStyle();

  const renderCard = useCallback(
    (card: any, index: number) => {
      return (
        <Card key={card.id} index={index} id={card.id} title={card.title} moveCard={moveCard} />
      );
    },
    [test?.questions, test?.questionDeletionConfirmPopin]
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards((prevCards: any) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as any],
          ],
        })
      );
    },
    [test?.questions]
  );

  useEffect(() => {
    if (cards?.length) {
      updateTest({ questions: cards });
    }
  }, [cards]);

  useEffect(() => {
    setCards(test?.questions);
  }, [test?.questions]);

  return (
    <>
      <div style={style}>{(cards || []).map((card: any, i: any) => renderCard(card, i))}</div>
      <OaPopin
        open={test?.questionDeletionConfirmPopin?.open}
        title={test?.questionDeletionConfirmPopin?.title}
        handleConfirm={test?.questionDeletionConfirmPopin?.handlePopinConfirm}
        handleClose={test?.questionDeletionConfirmPopin?.handlePopinClose}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {test?.questionDeletionConfirmPopin?.content}
          </Typography>
        </div>
      </OaPopin>
    </>
  );
};
