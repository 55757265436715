import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '18.5px',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      padding: '2vh',
      paddingTop: '10vh',
      paddingBottom: '10vh',
      [theme.breakpoints.down(800)]: {
        paddingTop: '2vh',
        paddingBottom: '2vh',
      }
    },
    whenEqual: {
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#0E1247',
      marginTop: '3vh'
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '498px',
      margin: '0 auto',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 0',
        lineHeight: '150%',
        fontSize: '16px',
        width: '95%',
      },
    },
    imgPiramide: {
      width: '100%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
    podium: {
      width: '70%',
      margin: '0 auto',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
  }),
);
