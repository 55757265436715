import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        VeryBiglogo: {
            width: '50%',
            height: 'auto',
            display: 'block',
            margin: 'auto'
        },
        paddingAuto: {
            paddingTop: "75px"
        },
        container: {
            width: "95%",
            margin: "0 auto"
        },
        containerBtn: {
            margin: "2vh"
        },
        validate: {
            color: '#fff',
            backgroundColor: '#2B56A5'
        },
        modalTitle: {
            color: '#E63C3C',
            marginBottom: 30,
        },
        modalText: {
            fontSize: 14,
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
        },
        modalContent: {
            width: "80%",
            margin: "0 auto"
        },
        backGroundGrey: {
            width: "100%",
            background: '#e8eef4',
            color: "#2B56A5",
            paddding: "3vh"
        },
        fixed: {
            position: 'fixed',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            backgroundColor: "#2B56A5",
            color: 'white',
        },
        page: {
            maxHeight: "1754px",
            maxWidth: '1240px',
            minHeight: '147vh',
            // margin: "1vh 0",
            // [theme.breakpoints.down('sm')]: {
            //     height: "85vh",
            // },
        },
        imgpremierApercu: {
            height: "100%",
            width: "100%",
        },
        colorBlue: {
            color: '#2B56A5',
        },
        trait: {
            backgroundColor: 'yellow',
            width: '75px',
            height: '5px',
            marginTop: '50px',
            marginBottom: '50px'
        },
        img: {
            width: "100%"
        },
        title: {
            fontSize: 28,
        },
        title2: {
            fontSize: 20,
            color: "#2B56A5",
            fontWeight: 300,
        },
        paragraphe: {
            fontSize: '14px'
        },
        logo: {
            width: '50%',
            height: 'auto',
            display: 'block',
            margin: 'auto'
        },
        imgWeigth: {
            width: "25vh",
            height: "25vh",
            [theme.breakpoints.down('sm')]: {
                width: "18vh",
                height: "18vh",
            },
        },
        textTitle: {
            color: "white",
            textAlign: 'center',
            width: "100%",
            height: "auto",
            borderRadius: "4px 4px 0 0",
            backgroundColor: "#2B56A5",
            paddingTop: "1.5vh",
            margin: "3vh 0 0 0",
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                height: "11vh",
                fontSize: '1.5vh'
            },
        },
        borderWeither: {
            borderRadius: "0 0 4px 4px",
            border: "1px solid #2B56A5",
            width: "100%",
            height: "240px",
            display: "block",
            marginTop: "1vh",
            [theme.breakpoints.down('sm')]: {
                height: "170px",

            },
        },
        checkBox: {
            position: "relative",
            margin: "-6vh 2vh",
            [theme.breakpoints.down('sm')]: {
                margin: "-3.5vh 3vh",
            },
        },
        titleSteveJobs: {
            fontSize: 25,
            color: '#2B56A5',
            textAlign: "center",
            fontWeight: 300,
            width: "90%",
            margin: "0 auto",
            lineHeight: "7vh",
            [theme.breakpoints.down('sm')]: {
                lineHeight: "4vh",
            },
        },
        aButton: {
            textDecoration: "none",
            color: "white"
        },
        formContent: {
            margin: '2vh auto',
            width: '100%'
        },
        couverturePage: {
            maxWidth: '1265px',
            height: 'auto',
        },
        readOnly: {
            scrollBehavior: 'smooth',
            "& input": {
                "-webkit-user-select": 'none',
                "-moz-user-select": 'none',
                "-ms-user-select": 'none',
                "user-select": 'none',
            }
        },
        title00: {
            fontSize: 19,
        },
        textCenterTop: {
            textAlign: 'center',
            color: '#2b56a5',
        },
        trait00: {
            backgroundColor: 'yellow',
            width: '75px',
            height: '5px',
            display: 'block',
            margin: 'auto',
            marginTop: '50px',
            marginBottom: '50px',
        },
        textLock: {
            fontFamily: 'Poppins',
            fontStyle: "normal",
            fontSize: "16px",
            lineHeight: "140 %",
            color: "#CDCDCD",
            padding: "9vh 2vh 2vh 2vh",
            border: "1.5px solid",
            [theme.breakpoints.down('sm')]: {
                fontSize: "12px",
            },
        },
        noAdjustLeft: {
            '@media only screen and (max-width: 600px)': {
                textAlign: 'center !important',
            },
            '@media only screen and (max-width: 361px)': {
                textAlign: 'center !important',
            },
        },
        marginFooter: {
            marginTop: '10px',
            marginBottom: '100px',
        }
    })
);

export default useStyles;