const INITIAL_STATE: any = {
  user: null,
  loading: false,
  isLoggedIn: false,
  token: '',
};

const authReducer = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'LOAD': {
      return { ...state, loading: true };
    }
    case 'LOADOUT': {
      return { ...state, loading: false };
    }
    case 'LOGIN':
      return {
        ...state,
        ...action.payload,
        isLoggedIn: true,
        loading: false,
      };
    case 'LOGOUT':
      return {
        ...state,
        ...INITIAL_STATE,
        loading: false,
      };
    case 'TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'USERINFOS':
      return {
        ...state,
        user: {
          ...state.user,
          userInfo: action.payload.userInfoUpdate,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
