import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    titlePage: {
        padding: '1.5vh 0',
        fontSize: 20,
        fontWeight: 600,
        color: '#2B56A5',
        textAlign: "left",
        borderBottom: '1px solid',
        backgroundColor: '#e8eef4',
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
    },
    border: {
        border: '1px solid',
        overflow: 'hidden',
    },
    content: {
        width: '100%',
        margin: '0 auto',
        minHeight: '8vh',
    },
    textContent: {
        width: '90%',
        margin: '0 auto',
        minHeight: '8vh',
        display: 'flex',
        alignItems: "center",
    },
    notActived: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        alignContent: 'center',
        fontFamily: 'ITC Avant Garde Gothic Std Medium',
        fontSize: '15px',
        color: '#2B56A5',
        style: 'normal',
        lineHeight: 1.5,
        borderLeft: '0.05px solid',
        [theme.breakpoints.down('xs')]: {
            borderTop: '0.05px solid',
            height: '5vh',
        }
    },
    actived: {
        backgroundColor: '#e8eef4',
        //    color: 'white'
    },
    contentArray: {
        borderBottom: '1px solid',
        [theme.breakpoints.down('sm')]: {
            overflow: 'none',
        },
    }
}));
