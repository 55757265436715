/* eslint-disable no-restricted-syntax */
import { adminUserAction } from '@orientaction/api-actions';
import {
  useAdminUser,
  useAlert,
  useCompany,
  useDrawer,
  useRandomPassword,
} from '@orientaction/hooks';
import { adminService } from '@orientaction/services';
import { canIUpdate, getFromLS } from '@orientaction/utils';
import { omit } from 'lodash';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { beneficiaryFields, FORM_MODE, formBaseInputs, subordinatesIds } from '../constant';
import { UserFormContext } from '../Context/UserFormContext';
import { validateForm } from '../utils';
import {
  beneficiaryRattachmentPopinContent,
  beneficiaryRattachmentPopinTitle,
  consultantRattachmentPopinContent,
  consultantRattachmentPopinTitle,
} from './constant';
import useBeneficiaryServices from './useBeneficiaryServices';

interface IPopin {
  open: boolean;
  title: string;
  content: string;
  type: string;
  data?: any;
}

const popinInitialState = {
  open: false,
  title: '',
  content: '',
  type: 'default',
};

const useBeneficiaryForm = (mode: string, companyUserRoleId: number) => {
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [popin, setPopin] = useState<IPopin>(popinInitialState);

  const alert = useAlert();

  const dispatch = useDispatch();

  const userContext = useContext<any>(UserFormContext);
  const managers = useSelector((state: any) => state?.adminUser?.listUpHierachy);

  const { updateFormUser, formUser: user } = userContext;

  const token = getFromLS('userToken') || '';
  const currentUser = JSON.parse(getFromLS('userInfos') || '{}');

  useBeneficiaryServices(token, userContext, managers);

  const { openDrawer } = useDrawer();

  const { randomPassword, isRandomPasswordCopied, generateRandomPassword, copyRandomPassword } =
    useRandomPassword();

  const {
    updateArrayAfterUpdateCreate,
    setCountTabs,
    countTabs,
    tabs,
    setListUphierchy,
    listUpHierachy,
  } = useAdminUser();

  const { getUpHierachy } = useCompany();

  const hasTests = !!user?.tests?.length;
  const hasBooks = !!user?.books?.length;

  const canUpdateHierarchy = !canIUpdate(!!(mode === FORM_MODE.update), user.company_user_role);

  const setDialog = (data: any) => {
    dispatch(
      adminUserAction.setDialog({
        ...data,
      }),
    );
  };

  const updateUserState = (data: any) => {
    dispatch(adminUserAction.setCurrent(data));
  };

  const setTabs = (data: number) => {
    dispatch(adminUserAction.setTabs(data));
  };

  const confirmCancel = () => {
    openDrawer(false);

    setDialog({
      open: true,
      title: `Annuler la ${user.isUpdate ? 'modification' : 'création'} du compte`,
      paragraph: `Êtes-vous sûr de vouloir annuler la ${
        user.isUpdate ? 'modification' : 'création'
      } de ce compte ?`,
      textBtn: `Annuler la ${user.isUpdate ? 'modification' : 'création'}`,
      type: 'cancel',
      id: '',
      isActivated: false,
    });
  };

  const closeDrawer = () => {
    if (mode === 'View') {
      openDrawer(false);
      return;
    }
    confirmCancel();
  };

  const handleOpenRattachmentPopin = () => {
    setPopin(() => ({
      title: beneficiaryRattachmentPopinTitle,
      content: beneficiaryRattachmentPopinContent,
      open: true,
      type: 'default',
    }));
  };

  const handleOpenUncheckNeedsProPopin = (data: any) => {
    setPopin(() => ({
      title: 'Confirmation',
      content:
        'Ce bénéficiaire a déjà passé un test Needs Pro®, êtes-vous sûr de vouloir lui en proposer un nouveau ?',
      open: true,
      data,
      type: 'uncheck-needs-pro',
    }));
  };

  const handleOpenUncheckNeedsPopin = (data: any) => {
    setPopin(() => ({
      title: 'Confirmation',
      content:
        'Ce bénéficiaire a déjà passé un test Needs®, êtes-vous sûr de vouloir lui en proposer un nouveau ?',
      open: true,
      data,
      type: 'uncheck-needs',
    }));
  };

  const handleUncheckNeedsPopinConfirm = async () => {
    const { name, checked } = popin?.data;
    const testId = +name.split('_')[1];
    // For coaching service, user cannot choose needs and needs pro at the same time
    const newTests = [];
    for (const test of user.tests) {
      if (testId === test.id) {
        newTests.push({ ...test, isChecked: checked });
      } else {
        newTests.push(test);
      }
    }

    if (testId === 3 && checked) {
      // if needs is checked, uncheck needs pro
      newTests[1] = { ...newTests[1], isChecked: false };
    }
    updateFormUser({ tests: newTests });
    setPopin(popinInitialState);
  };

  const handleUncheckNeedsProPopinConfirm = async () => {
    const { name, checked } = popin?.data;
    const testId = +name.split('_')[1];
    // For coaching service, user cannot choose needs and needs pro at the same time
    const newTests = [];
    for (const test of user.tests) {
      if (testId === test.id) {
        newTests.push({ ...test, isChecked: checked });
      } else {
        newTests.push(test);
      }
    }
    if (testId === 2 && checked) {
      // if needs pro is checked, uncheck needs
      newTests[2] = { ...newTests[2], isChecked: false };
    }

    updateFormUser({ tests: newTests });
    setPopin(popinInitialState);
  };

  const handlePopinConfirm = async () => {
    // admin confirm rattachment
    setLoading(true);
    try {
      const response = await adminService.rattachBeneficiary(
        { ...user, rattachBeneficiary: true },
        token,
      );
      updateArrayAfterUpdateCreate(response?.data.data);
      openDrawer(false);
    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConsultanTransormPopin = async () => {
    setPopin(() => ({
      title: consultantRattachmentPopinTitle,
      content: consultantRattachmentPopinContent,
      open: true,
      type: 'default',
    }));
  };

  const handlePopinClose = () => {
    setPopin(() => ({ title: '', content: '', open: false, type: 'default' }));
  };

  const phoneNumberChange = (value: any) => {
    updateFormUser({ phone: value });
  };

  const languageChange = (value: string) => {
    updateFormUser({ language: value === 'GB' ? 2 : 1 });
  };

  const getPopinConfirmFunction = (type: string) => {
    let func;
    switch (type) {
      case 'uncheck-needs-pro':
        func = handleUncheckNeedsProPopinConfirm;
        break;

      case 'uncheck-needs':
        func = handleUncheckNeedsPopinConfirm;
        break;

      default:
        func = handlePopinConfirm;
        break;
    }
    return func;
  };

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;
    if (name.startsWith('test')) {
      const testId = +name.split('_')[1];
      if (testId === 2 && checked && user.hasNeedsProResult) {
        // show uncheck need-pro confirm
        handleOpenUncheckNeedsProPopin({ name, checked: true });
        return;
      }
      if (testId === 3 && checked && user.hasNeedsResult) {
        // show uncheck need confirm
        handleOpenUncheckNeedsPopin({ name, checked: true });
      }
      if (user?.service?.id === 3) {
        // For coaching service, user cannot choose needs and needs pro at the same time
        const newTests = [];
        for (const test of user.tests) {
          if (testId === test.id) {
            newTests.push({ ...test, isChecked: checked });
          } else {
            newTests.push(test);
          }
        }
        if (testId === 2 && checked) {
          // if needs pro is checked, uncheck needs
          newTests[2] = { ...newTests[2], isChecked: false };
        }

        if (testId === 3 && checked) {
          // if needs is checked, uncheck needs pro
          newTests[1] = { ...newTests[1], isChecked: false };
        }
        updateFormUser({ tests: newTests });
      } else {
        const updatedTests = user.tests.map((test: any) => {
          if (test.id === testId) {
            return { ...test, isChecked: checked };
          }
          return test;
        });
        updateFormUser({ tests: updatedTests });
      }
      return;
    }

    if (name.startsWith('book')) {
      const bookId = +name.split('_')[1];
      const newBooks = user.books.map((b: any) => {
        if (b.id === bookId) {
          return { ...b, isChecked: checked };
        }
        return b;
      });
      updateFormUser({ books: newBooks });
      return;
    }

    if (name.startsWith('upper_hierarchy')) {
      // reset service select on manager change
      updateFormUser({
        [name]: value,
        service: null,
      });
      return;
    }
    const valueFormatted = name === 'dayOfBirth' ? new Date(value) : value;
    updateFormUser({
      [name]: valueFormatted,
    });
  };

  const handleRoleChange = async (e: any) => {
    const { name, value } = e.target;
    setTabs(value);
    updateUserState({
      ...user,
      [name]: value,
      roleHasChange: true,
    });
  };

  const handleSubmit = async (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    // Persist + part
    user.phone = `+${user.phone}`;
    // check if french phone number
    if (user?.phone?.startsWith(330 as any)) {
      // Remove zero if second number part start with it
      const secondPart = user?.phone?.substring(3);
      user.phone = `+33${secondPart}`;
    }

    let fieldsLabels = formBaseInputs;
    // add specific beneficiary fields for validation
    fieldsLabels = fieldsLabels.concat(beneficiaryFields);
    if (subordinatesIds.includes(companyUserRoleId)) {
      fieldsLabels.push('upper_hierarchy');
    }

    const { isValid, formError } = validateForm(fieldsLabels, user);
    if (!isValid) {
      return setErrors(formError);
    }
    let response;
    const omittedUser = omit(user, [
      'blocked',
      'compassFirstActivationDate',
      'confirmationToken',
      'confirmed',
      'isActivated',
      'role',
      'isCompassActivated',
      'isTemporaryPassword',
      'password',
      'provider',
      'updatedAt',
      'old_id',
      'hasMultipleRole',
      'hasNeedsProResult',
      'hasNeedsResult',
      'isView',
      'createdAt',
      'description',
      'isUpdate',
      'username',
      'photoUri',
      'rattachement',
      'resetPasswordToken',
    ]);
    if (mode === FORM_MODE.create) {
      setLoading(true);
      try {
        response = await adminService.register(
          {
            ...omittedUser,
          },
          token,
        );
      } catch (error) {
        alert.openSimpleAlert();
      } finally {
        setLoading(false);
      }
    } else if (mode === FORM_MODE.update) {
      setLoading(true);
      try {
        response = await adminService.update(
          {
            ...omittedUser,
          },
          token,
        );
      } catch (error) {
        if (
          error?.response?.data?.error?.details[0]?.messages[0]?.id ===
          'Auth.form.error.email.taken'
        ) {
          alert.setOpenAlert(
            true,
            'Cette adresse e-mail existe déjà, merci d’en utiliser une autre',
            'error',
          );
          return;
        }
        alert.openSimpleAlert();
      } finally {
        setLoading(false);
      }
    }
    if (response?.data.data === 'E-mail already exist') {
      return alert.setOpenAlert(
        true,
        'Cette adresse e-mail existe déjà, merci d’en utiliser une autre',
        'error',
      );
    }
    if (response?.data?.code === 'transform-beneficiary') {
      // return showRattachUserPopin(response?.data.user);
      handleOpenRattachmentPopin();
      return;
    }

    if (response?.data.code === 'beneficiary-to-consultant') {
      // return oneBecomeTOConsultant(response?.data.user);
      handleOpenConsultanTransormPopin();
      return;
    }

    updateArrayAfterUpdateCreate(response?.data.data);

    openDrawer(false);

    if (mode === FORM_MODE.create) {
      setCountTabs({
        ...countTabs,
        [user.company_user_role]: countTabs[user.company_user_role] + 1,
      });
    }
    userContext.updateFormUser({});
  };

  return {
    errors,
    handleChange,
    handleRoleChange,
    handleSubmit,
    user,
    phoneNumberChange,
    randomPassword,
    isRandomPasswordCopied,
    generateRandomPassword,
    copyRandomPassword,
    hasBooks,
    hasTests,
    loading,
    closeDrawer,
    tabs,
    getManagers: getUpHierachy,
    manager: listUpHierachy,
    setManager: setListUphierchy,
    currentUser,
    canUpdateHierarchy,
    handlePopinConfirm: getPopinConfirmFunction(popin?.type),
    popin,
    handlePopinClose,
    languageChange,
  };
};

export default useBeneficiaryForm;
