/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import useStyles from './style';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Theme } from '@material-ui/core';
import { isAdminVerify, getFromLS } from '@orientaction/utils';

interface IBtnCanceled {
  id: number;
  isBack?: boolean;
}

const BtnCanceled: FC<IBtnCanceled> = ({ id, isBack = false }) => {
  const classe = useStyles();

  const { push, location, goBack } = useHistory();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const cancelRedirect = () => {
    if (location.search !== '') {
      push(location.search.replace('?toBack=', ''));
      return;
    }

    const to = isAdminVerify() ? `/admin/resultat-beneficiaire/${id}` : '/mes-resultats';
    if (isBack) {
      goBack();
    } else {
      push(to);
    }
  };

  return (
    <div className={classe.btn} onClick={cancelRedirect}>
      <div>
        <KeyboardArrowLeftIcon />
      </div>

      <div>Retour</div>
    </div>
  );
};

export default BtnCanceled;
