/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import withWidth from '@material-ui/core/withWidth';
import { CompassUserProvider } from '@orientaction/Context';
import Restitution from './Restitution';

const GlobalRestitution = () => {
  return (
    <CompassUserProvider>
      <Restitution />
    </CompassUserProvider>
  );
};

export default withWidth()(GlobalRestitution);
