/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
export const colorNeedPro = 'rgba(148, 42, 89, 1)';

export const standartCoulomsNeedPro = [
  {
    type: 'normal',
    header: [
      {
        label: 'Date',
        key: 'attributes.createdAt',
        option: {
          type: 'date',
          formatedDate: true,
          format: 'DD/MM/YYYY',
        },
      },
      {
        label: 'Prénom',
        key: 'attributes.users_permissions_user.firstname',
      },
      {
        label: 'Nom',
        key: 'attributes.users_permissions_user.lastname',
      },
      {
        label: 'Sexe',
        key: 'attributes.users_permissions_user.gender',
      },
      {
        label: 'Âge',
        key: 'attributes.users_permissions_user.dayOfBirth',
        option: {
          type: 'date',
          formatedDate: true,
        },
      },
      {
        label: 'Diplôme',
        key: 'attributes.users_permissions_user.diploma',
      },
      {
        label: 'CP',
        key: 'attributes.users_permissions_user.postal_code',
      },
      {
        label: 'Email',
        key: 'attributes.users_permissions_user.email',
      },
      {
        label: 'téléphone',
        key: 'attributes.users_permissions_user.phone',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'physiologie',
        key: 'attributes.physiological_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'Sécurité',
        key: 'attributes.security_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'appartenance H',
        key: 'attributes.horizontal_belonging_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'appartenance V',
        key: 'attributes.vertical_belonging_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'accompli.',
        key: 'attributes.accomplishment_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'réal. de soi',
        key: 'attributes.self_realization_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'spiritualité',
        key: 'attributes.spirituality_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'éternité',
        key: 'attributes.eternity_pro',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'OPT-IN',
        key: 'attributes.time_spent',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'BC',
        key: 'attributes.realization_period',
      },
    ],
  },
  {
    type: 'action',
    withBorder: true,
    header: [
      {
        label: 'Actions',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['delete'],
        },
      },
    ],
  },
];
