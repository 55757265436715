/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from '@orientaction/components';
import { useBook2 } from "@orientaction/hooks_book";
import clsx from 'clsx';
import { cloneDeep, difference,findIndex } from 'lodash';
import React, { useEffect,useState } from 'react';
import { imgBook2, publicSvgPath } from '../../../../utils/constants';

const Page35 = (props: any) => {
  const classes = useStyles();
  const { setQuality, setPersonalQuality, personalQuality, quality } = useBook2()
  const [dataDefault, setDataDefault] = useState('');
  const column1 = [
    {
      name: '',
      text: 'Adaptable/souple'
    },
    {
      name: '',
      text: 'Assertif(ve)',
    },
    {
      name: '',
      text: 'Ambitieux(se)',
    },
    {
      name: '',
      text: 'Animateur(ice)',
    },
    {
      name: '',
      text: 'Autonome',
    },
    {
      name: '',
      text: 'Bon sens',
    },
    {
      name: '',
      text: 'Assidu(e)',
    },
    {
      name: '',
      text: 'Calme',
    },
    {
      name: '',
      text: 'Consciencieux(se)',
    },
    {
      name: '',
      text: 'Coopératif(ve)',
    },
    {
      name: '',
      text: 'Courageux(se)',
    },
    {
      name: '',
      text: 'Créatif(ve)',
    },
    {
      name: '',
      text: 'Débrouillard(e)',
    },
    {
      name: '',
      text: 'Dévoué(e)',
    },
    {
      name: '',
      text: 'Fiable',
    },
    {
      name: '',
      text: 'Direct(e)',
    },
    {
      name: '',
      text: 'Discret(e)',
    },
    {
      name: '',
      text: 'Donne suite',
    },
    {
      name: '',
      text: 'Dynamique',
    },
    {
      name: '',
      text: 'Économe',
    },
    {
      name: '',
      text: 'À l’écoute',
    },
    {
      name: '',
      text: 'Efficace',
    },
    {
      name: '',
      text: 'Enthousiaste',
    },
  ];
  const column2 = [
    {
      name: '',
      text: 'Équilibré(e)/stable'
    },
    {
      name: '',
      text: 'S’exprime bien',
    },
    {
      name: '',
      text: 'Franc(che)',
    },
    {
      name: '',
      text: 'Gai(e)',
    },
    {
      name: '',
      text: 'Honnête',
    },
    {
      name: '',
      text: 'Imaginatif(ve)',
    },
    {
      name: '',
      text: 'Innovateur(trice)',
    },
    {
      name: '',
      text: 'Intelligent(e)',
    },
    {
      name: '',
      text: 'Loyal(e)',
    },
    {
      name: '',
      text: 'Méthodique/organisé(e)',
    },
    {
      name: '',
      text: 'Observateur(trice)',
    },
    {
      name: '',
      text: 'Ordonné(e)',
    },
    {
      name: '',
      text: 'Ouvert (e)aux autres',
    },
    {
      name: '',
      text: 'Patient(e)',
    },
    {
      name: '',
      text: 'Persévérant(e)/tenace',
    },
    {
      name: '',
      text: 'Plein(e) de confiance',
    },
    {
      name: '',
      text: 'Positif(ve)',
    },
    {
      name: '',
      text: 'Précis(e)',
    },
    {
      name: '',
      text: 'Prend l’initiative',
    },
    {
      name: '',
      text: 'Professionnel(le)',
    },
  ];
  const column3 = [
    {
      name: '',
      text: 'Prudent(e)'
    },
    {
      name: '',
      text: 'Psychologue',
    },
    {
      name: '',
      text: 'Réceptif(ve)',
    },
    {
      name: '',
      text: 'Réfléchi(e)',
    },
    {
      name: '',
      text: 'Résistant(e)',
    },
    {
      name: '',
      text: 'Responsable',
    },
    {
      name: '',
      text: 'Sens de l’urgence',
    },
    {
      name: '',
      text: 'Réaliste',
    },
    {
      name: '',
      text: 'Sens des chiffres',
    },
    {
      name: '',
      text: 'Sérieux(se)',
    },
    {
      name: '',
      text: 'Sociable',
    },
    {
      name: '',
      text: 'Soigné(e)',
    },
    {
      name: '',
      text: 'Esprit d’équipe',
    },
    {
      name: '',
      text: 'Ouvert (e)aux autres',
    },
    {
      name: '',
      text: 'Travailleur(se)',
    },
  ];
  const [dataConcat, setDataConcat] = useState<any>(personalQuality);
  const [textSelected1, setTextSelected1] = useState<any>([]);
  const [textSelected2, setTextSelected2] = useState<any>([]);
  const [textSelected3, setTextSelected3] = useState<any>([]);
  const [select, setSelect] = useState<any>(quality);

  const removeFromIndex = (array: any, index: number) => {
    return array.map((elem: any, i: number) => {
      let el = elem;
      if (index === i) {
        el = '';
      }
      return el;
    });
  };
  const verifyDataInArray = (data: any, index: number) => {
    if (index === 0) {
      setTextSelected2(difference(dataConcat, removeFromIndex(data, 1)));
      setTextSelected3(difference(dataConcat, removeFromIndex(data, 2)));
    } else if (index === 1) {
      setTextSelected1(difference(dataConcat, removeFromIndex(data, 0)));
      setTextSelected3(difference(dataConcat, removeFromIndex(data, 2)));
    } else if (index === 2) {
      setTextSelected1(difference(dataConcat, removeFromIndex(data, 0)));
      setTextSelected2(difference(dataConcat, removeFromIndex(data, 1)));
    }
  }
  useEffect(() => {
    const data = cloneDeep(select);
    setTextSelected1(difference(dataConcat, removeFromIndex(data, 0)));
    setTextSelected2(difference(dataConcat, removeFromIndex(data, 1)));
    setTextSelected3(difference(dataConcat, removeFromIndex(data, 2)));
  }, [dataConcat]);

  const handleChangeSelect = (e: any, index: number) => {
    const data = cloneDeep(select);
    data[index] = e.target.value;
    verifyDataInArray(data, index);
    setSelect(data);
    setQuality(data)
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const found = findIndex(dataConcat, function(o) { return o === (event.target as HTMLInputElement).name})
    let data = cloneDeep(dataConcat)
    if (found === -1) {
      data = [...data, (event.target as HTMLInputElement).name];
    } else {
      data.splice(found, 1);
    }
    setDataConcat(data);
    setPersonalQuality(data)
  };

  return (
    <Page id={36} readOnly={props.readOnly}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
          VOS QUALITÉS PERSONNELLES
        </Typography>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: "40px", marginBottom: "45px" }} />
        <div style={{ textAlign: 'center' }}>
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', }}>
            Cochez les qualificatifs qui correspondent le mieux à votre personnalité.
          </Typography>
        </div>
        <div className={classes.cardBgGrey} style={{marginTop: '40px'}}>
          <Grid container={true} spacing={3}>
            <Grid item={true} sm={6} md={6} xs={12} lg={4} className={clsx(classes.fieldset, classes.responsive6)}>
              <FormControl component="fieldset" style={{display: "block !important"}}>
                <FormGroup style={{display: 'flex', flexDirection: 'column'}}>
                  {column1.map((elem: any) => {
                    return (<FormControlLabel
                      control={<Checkbox color="primary" name={elem.text} onChange={handleChange} checked={dataConcat.indexOf(elem.text) === -1 ? false : true} style={{padding: 3}} />}
                      label={
                        <div>
                          {elem.text}
                        </div>
                      }
                      labelPlacement="start"
                      style={{justifyContent: "space-between"}}
                      color="primary"
                    />)
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} md={6} xs={12} lg={4} className={clsx(classes.fieldset, classes.responsive6)}>
              <FormControl component="fieldset">
                <FormGroup style={{display: 'flex', flexDirection: 'column'}}>
                  {column2.map((elem: any) => {
                    return (<FormControlLabel
                      control={<Checkbox color="primary" name={elem.text} onChange={handleChange} checked={dataConcat.indexOf(elem.text) === -1 ? false : true} style={{padding: 3}} />}
                      label={
                        <div>
                          {elem.text}
                        </div>
                      }
                      labelPlacement="start"
                      style={{justifyContent: "space-between"}}
                      color="primary"
                    />)
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item={true} sm={6} md={6} xs={12} lg={4} className={clsx(classes.fieldset, classes.responsive6)}>
              <FormControl component="fieldset">
                <FormGroup style={{display: 'flex', flexDirection: 'column'}}>
                  {column3.map((elem: any) => {
                    return (<FormControlLabel
                      control={<Checkbox color="primary" name={elem.text} onChange={handleChange} checked={dataConcat.indexOf(elem.text) === -1 ? false : true} style={{padding: 3}} />}
                      label={
                        <div>
                          {elem.text}
                        </div>
                      }
                      labelPlacement="start"
                      style={{justifyContent: "space-between"}}
                      color="primary"
                    />)
                  })}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div style={{marginTop: 50, textAlign: 'center' }}>
          <Typography className={classes.testimony} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', }}>
            Reportez ci-dessous les trois qualités personnelles qui vous caractérisent le mieux :
          </Typography>
        </div>
        <div style={{ marginTop: 40 }}>
          <Grid container={true} >
            <Grid item={true} xs={12} sm={2} className={classes.h6}>
              <Typography>
                Qualité n°1 :
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={10}>
              <FormControl style={{ width: '100%' }}>
                <Select
                  value={select[0]}
                  onChange={(e: any) => handleChangeSelect(e, 0)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  displayEmpty={true}
                >
                  <MenuItem value="" disabled={true}>
                    Cliquer ici
                  </MenuItem>
                  {textSelected1?.map((col: any) => {
                    return (
                      <MenuItem value={col} key={col}>
                        {col}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container={true} style={{ marginTop: 20 }} >
            <Grid item={true} xs={12} sm={2} className={classes.h6}>
              <Typography>
                Qualité n°2 :
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={10}>
              <FormControl style={{ width: '100%' }}>
                <Select
                  value={select[1]}
                  onChange={(e: any) => handleChangeSelect(e, 1)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  displayEmpty={true}
                >
                  <MenuItem value="" disabled={true}>
                    Cliquer ici
                  </MenuItem>
                  {textSelected2?.map((col: any) => {
                    return (
                      <MenuItem value={col} key={col}>
                        {col}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container={true} style={{ marginTop: 20 }} >
            <Grid item={true} xs={12} sm={2} className={classes.h6}>
              <Typography>
                Qualité n°3 :
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={10}>
              <FormControl style={{ width: '100%' }}>
                <Select
                  value={select[2]}
                  onChange={(e: any) => handleChangeSelect(e, 2)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  displayEmpty={true}
                >
                  <MenuItem value="" disabled={true}>
                    Cliquer ici
                  </MenuItem>
                  {textSelected3?.map((col: any) => {
                    return (
                      <MenuItem value={col} key={col}>
                        {col}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      marginRight: '-85px',
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    imgFurious: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
      },
    },
    imgFurious1: {
      width: '30%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none'
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
      color: 'black'
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 5,
      paddingRight:15,
      fontSize: 12,
      background: '#e8eef4',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    fieldset: {
      "& fieldset" : {
        display: "block !important"
      },
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      color: '#0E1247',
      style: 'normal',
      textAlign:'left',
      alignItems: 'center',
      display: 'flex'
    },
    responsive6: {
      '@media only screen and (min-width: 960px) and (max-width: 1450px)': {
        maxWidth: "50% !important",
        flexBasis: "50% !important",
      },
    }
  })
);
export default Page35;
