// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
    ListParagrapth,
    Paragrapth,
    Page,
    Img
} from '@orientaction/components';
import useStyles from "../../style";


const Page05 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={5}
        >


            <div className={classe.titleSteveJobs}>
                <i>
                    « Ayez le courage de suivre votre cœur et votre intuition.
                    <br />
                    L’un et l’autre savent ce que vous voulez réellement devenir.
                    <br />
                    Le reste est secondaire. »
                </i>
            </div>

            <div style={{
                width: "7%",
                height: 10,
                backgroundColor: "yellow",
                margin: "5vh auto",
                color: "yellow"
            }}>-</div>

            <div style={{
                textAlign: 'center'
            }}>
                <Paragrapth colorBlue={true} align="center">Steve Jobs</Paragrapth>
            </div>

            <Img
                src="5.png"
                alt="Steve Jobs"
                style={{
                    width: "60%",
                }}
            />

        </Page>
    );
};
export default Page05;
