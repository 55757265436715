/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, Title } from '@orientaction/components';
import useStyles from './style';
import { useBook4 } from '@orientaction/hooks_book';

const Page45 = (props: any) => {

  const classes = useStyles();

  const { setvalueAdd, valueAdd } = useBook4();

  const handleChange = (e: any) => {
    const { value } = e.target;
    setvalueAdd(value);
  }

  return (
    <Page id={45} readOnly={false}>

      <Title
        title="QUELLE EST VOTRE VALEUR AJOUTÉE ?"
        firstTitle="‘‘Le fruit le plus agréable et le plus utile au monde est la reconnaissance.’’"
        firtsecondTitle="Ménandre"
        secondTitle="‘‘Je souhaite que chaque être humain soit libre de se développer et de s'épanouir.’’"
        secondSousTitle="Simone de Beauvoir"
      />

      <MarginContainer>
        <Paragrapth align="justify">
          Dit autrement, qu’est-ce que vous pouvez apporter (grâce à vos ressources, vos
          « soft skills » et vos compétences) d’unique et de différent à un(e) employeur(se)
          et/ou à un(e) client(e) ?
        </Paragrapth>
      </MarginContainer>

      <MarginContainer mormal={true}>
        <Paragrapth align="center" colorBlue={true}>
          INSCRIVEZ ICI EN UNE PHRASE CE QUI CONSTITUE VOTRE VALEUR AJOUTÉE :
        </Paragrapth>
      </MarginContainer>

      <div style={{ border: '2px solid grey', padding: '15px', marginTop: 40 }}>
        <Paragrapth align="center" colorBlue={true}>
          <span style={{ fontSize: 17 }}>
            VOTRE VALEUR AJOUTÉE (LES FRUITS DE VOS COMPÉTENCES) :
          </span>
        </Paragrapth>

        <MarginContainer>
          <TextArea
            name='valueAdd'
            onChange={handleChange}
            value={valueAdd || ""}
            largeTextField={true}
            readOnly={props.readOnly}
          />
        </MarginContainer>

      </div>

    </Page>
  );
};

export default Page45;
