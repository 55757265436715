/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, IconButton, Typography, useMediaQuery, Theme } from '@material-ui/core';
import { ContentCenter, MarginContainer } from '@orientaction/commons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Explication from './Explication';
import { useStyles } from './style';
import Score from './Score';
import { FC } from 'react';
import clsx from 'clsx';

interface IContent {
  listPrincipaux: any[];
}

const Content: FC<IContent> = ({ listPrincipaux }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const classe = useStyles();

  return (
    <div className={classe.root}>
      <MarginContainer big={true}>
        <h1 className={classe.titlePage}>
          Découvrez ici vos 5 préférences dans votre personnalité
        </h1>
      </MarginContainer>

      <MarginContainer>
        <div className={classe.grid}>
          {!isMobile && (
            <>
              <Explication />
            </>
          )}

          {(listPrincipaux || []).map((item: any, index: number) => {
            return (
              <div
                className={clsx({
                  [classe.content]: true,
                })}
                style={{
                  borderColor: item?.color,
                }}>
                <div>
                  <div
                    className={clsx({
                      [classe.head]: true,
                    })}
                    style={{
                      backgroundColor: item?.color,
                    }}>
                    <Typography
                      className={clsx({
                        [classe.score]: true,
                        [classe.scoreFavorite]: false,
                      })}>
                      {index + 1}
                    </Typography>

                    <Typography className={classe.titleHead}>{item?.title || ''}</Typography>
                  </div>

                  <ContentCenter>
                    <img src={item.img} className={classe.img} alt="Pyramide" />
                  </ContentCenter>

                  <div className={classe.contentScore}>
                    <Typography className={classe.explication}>
                      Vous êtes{' '}
                      <i>
                        <b>{item?.youAre || ''}</b>{' '}
                      </i>
                    </Typography>

                    <Score listScore={item?.score || []} />

                    <div className={classe.btnContent}>
                      <IconButton className={classe.iconButton}>
                        <ArrowForwardIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {isMobile && (
            <>
              <Explication />
            </>
          )}
        </div>
      </MarginContainer>
    </div>
  );
};

export default Content;
