// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
    TitleChapitre,
} from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../Img';

const Page38 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={38}
        >

            <TitleChapitre nbr={6} />

            <MarginContainer>
                <TitlePage>
                    OPTION N°5 : NE PAS CHANGER
                </TitlePage>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Ne pas changer alors qu’on désire changer, quelle idée saugrenue ! Certes, mais
                    on peut aussi changer sans changer. Conserver son travail, sa situation, sa place
                    acquise oui, mais accepter sa routine et son désintérêt, son mal-être, non ; changer
                    la routine et le désintérêt, c’est possible, pour se consacrer à autre chose.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Profiter de ce qu’on a en changeant son regard sur ce que l’on a et utiliser le temps
                    précieux qu’il nous reste pour s’éclater. Bref, aller trouver du plaisir ailleurs, en attendant
                    des jours meilleurs, plus propices au changement, c’est aussi une vraie solution.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Img
                    src='ilPleut.jpg'
                    alt='ilPleut'
                    className={classe.img}
                />
            </MarginContainer>

        </Page>
    );
};
export default Page38;
