const INITIAL_STATE: any = {
  variables: [],
  values: {
    contentForType1: 'normal',
    contentForType2: 'normal',
    citationAuthor1: '',
    citationAuthor2: '',
    citationContent1: '',
    citationContent2: '',
    p1: '',
    p2: '',
    informationEncyclopedia1: '',
    informationEncyclopedia2: '',
  },
};

const gameVariableBookTemplateReducer = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        values: { ...state.values, [action.payload.field]: action.payload.value },
      };

    case 'SET_ALL_VALUES':
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };

    case 'RESET_VALUES':
      return INITIAL_STATE;

    case 'SET_ALL_VARIABLES':
      return {
        ...state,
        variables: action.payload,
      };
    case 'RESET_VALUES_MODEL':
      return {
        variables: state.variables,
        values: INITIAL_STATE.values,
      };
    case 'SET_ALL_VALUES_MODEL':
      console.log({
        variables: state.variables,
        values: {
          ...INITIAL_STATE.values,
          ...action.payload,
        },
        payload: action.payload,
      });
      return {
        variables: state.variables,
        values: {
          ...INITIAL_STATE.values,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default gameVariableBookTemplateReducer;
