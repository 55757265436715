/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
export const colorSoftSkill = '#C93646';

export const standartCoulomrSoftSkill = [
  {
    type: 'normal',
    header: [
      {
        label: 'Date',
        key: 'attributes.createdAt',
        option: {
          type: 'date',
          formatedDate: true,
          format: 'DD/MM/YYYY',
        },
      },
      {
        label: 'Prénom',
        key: 'attributes.user.firstname',
      },
      {
        label: 'Nom',
        key: 'attributes.user.lastname',
      },
      {
        label: 'Sexe',
        key: 'attributes.user.gender',
      },
      {
        label: 'Âge',
        key: 'attributes.user.dayOfBirth',
        option: {
          type: 'date',
          formatedDate: true,
        },
      },
      {
        label: 'Diplôme',
        key: 'attributes.user.diploma',
      },
      {
        label: 'CP',
        key: 'attributes.user.postal_code',
      },
      {
        label: 'Email',
        key: 'attributes.user.email',
      },
      {
        label: 'téléphone',
        key: 'attributes.user.phone',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'reso. PB',
        key: 'attributes.problem_solving',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'confiance',
        key: 'attributes.confidence',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'intel. EMo',
        key: 'attributes.emotional_intelligence',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'empathie',
        key: 'attributes.empathy',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'communication',
        key: 'attributes.communication',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'gestion temps',
        key: 'attributes.time_management',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'gestion stress',
        key: 'attributes.stress_management',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'créativité',
        key: 'attributes.creativity',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'esprit entre.',
        key: 'attributes.entrepreneurial_spirit',
      },
    ],
  },
  { type: 'single', header: [{ label: 'audace', key: 'attributes.daring' }] },
  {
    type: 'single',
    header: [
      {
        label: 'motivation',
        key: 'attributes.motivation',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'vision',
        key: 'attributes.vision',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'présence',
        key: 'attributes.presence',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'sens collectif',
        key: 'attributes.collective_sense',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'curiosité',
        key: 'attributes.curiousity',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'esprit critique',
        key: 'attributes.critical_mind',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'score total',
        key: 'attributes.score',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'temps passé',
        key: 'attributes.time_spent',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'période de réal.',
        key: 'attributes.realization_period',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'OPT-IN',
        key: 'attributes.opt_in',
      },
    ],
  },
  {
    type: 'single',
    header: [
      {
        label: 'BC',
        key: 'attributes.bc',
      },
    ],
  },
  {
    type: 'action',
    withBorder: true,
    header: [
      {
        label: 'Actions',
        key: 'attributes.name3',
        option: {
          type: 'Action',
          listAction: ['delete'],
        },
      },
    ],
  },
];
