/* eslint-disable no-use-before-define */
import { Page, TitleNbr, Paragrapth, TitlePage } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';

const Page30 = (props: any) => {

    return (
        <Page id={30}>

            <MarginContainer veryBig={true}>
                <TitlePage showSpanYellow={false}>
                    LES BONNES ATTITUDES <br />
                    SI VOUS DÉCIDEZ DE RENONCER
                </TitlePage>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='start'>
                    Après avoir examiné toutes les variables, vous avez pris la décision de renoncer.
                    Changer aujourd’hui est un défi « impossible » pour vous. C’est votre décision et elle
                    est légitime car elle se fait en conscience de tous les paramètres.
                </Paragrapth>
            </MarginContainer>

            <div style={{ textAlign: 'center' }}>

                {
                    context.map((item: any) => (
                        <MarginContainer>

                            <MarginContainer>
                                <TitleNbr number={item.id} text={item.title} />
                            </MarginContainer>

                            {
                                item.listContext.map((itemList: any) => (
                                    <MarginContainer>

                                        <div style={{ textAlign: 'start' }}>

                                            <Paragrapth align='start'>

                                                {itemList}

                                            </Paragrapth>

                                        </div>

                                    </MarginContainer>
                                ))
                            }

                        </MarginContainer>
                    ))
                }

            </div>

        </Page>
    );
};

export default Page30;
