/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useAlert } from '@orientaction/hooks';
import { getFromLS, getUserLanguageById } from '@orientaction/utils';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import style from './style';

const UserProfileFormContent: FC<any> = ({ user, updateUser, loading }) => {
  const [phone, setPhone] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState('FR');

  const userInfos = JSON.parse(getFromLS('userInfos') || '');

  const mainLink = userInfos.username === 'Admin' ? '/results' : '/main';

  const cls = style();

  const history = useHistory();

  const { setOpenAlert } = useAlert();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<any>({
    mode: 'onChange',
  });

  const onSelect = (code: string): void => {
    setSelectedLanguage(code);
  };

  const goBack = () => {
    history.push(`${mainLink}`);
  };

  const onSubmitHandler = async (data: any) => {
    try {
      let phoneParsed = phone;
      // Persist + part
      // check if french phone number
      if (phoneParsed?.startsWith(330 as any)) {
        // Remove zero if second number part start with it
        const secondPart = phoneParsed?.substring(3);
        phoneParsed = `+33${secondPart}`;
      }
      await updateUser({ ...data, language: selectedLanguage === 'GB' ? 2 : 1, phone });
      // setOpenAlert(true, 'Modifications sauvegardées avec succès', 'success');
    } catch (error) {
      // setOpenAlert(true, 'Un erreur est survenue', 'error');
    }
  };

  useEffect(() => {
    if (user.id) {
      const userLanguage = getUserLanguageById(user?.language?.id);
      setSelectedLanguage(userLanguage);
    }
  }, []);

  if (!user.email) {
    return <div />;
  }

  const phoneNumberChange = (value: any) => {
    setPhone(value);
  };

  return (
    <form className={cls.form} onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container={true} spacing={1}>
        <Grid item={true} md={12} lg={12} sm={12} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} lg={6} md={6} sm={12} xs={12}>
              <Controller
                name="firstname"
                control={control}
                defaultValue={user?.firstname}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => {
                  return (
                    <label htmlFor="firstname">
                      <h5 className={cls.labelText}>
                        {' '}
                        <strong>Prénom</strong>
                      </h5>
                      <TextField
                        placeholder="Prénom"
                        {...field}
                        type="text"
                        className={cls.input}
                        variant="outlined"
                      />
                      {errors?.firstname?.message && (
                        <span className={cls.errorField}>{(errors as any).firstname.message}</span>
                      )}
                    </label>
                  );
                }}
              />
            </Grid>

            <Grid item={true} lg={6} md={6} sm={12} xs={12}>
              <Controller
                name="lastname"
                control={control}
                defaultValue={user?.lastname}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <label htmlFor="lastname">
                    <h5 className={cls.labelText}>
                      <strong>Nom</strong>
                    </h5>
                    <TextField
                      placeholder="Nom"
                      {...field}
                      type="text"
                      className={cls.input}
                      variant="outlined"
                    />
                    {errors?.lastname?.message && (
                      <span className={cls.errorField}>{(errors as any).lastname.message}</span>
                    )}
                  </label>
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} lg={12} md={12} xs={12}>
          <Controller
            name="description"
            control={control}
            defaultValue={user?.description}
            rules={{
              required: 'Champ obligatoire',
            }}
            render={({ field }) => (
              <>
                <label htmlFor="description">
                  <h5 className={cls.labelText}>
                    <strong>Description</strong>
                  </h5>
                </label>

                {/* <textarea
                  placeholder="Description"
                  {...field}
                  className={cls.textArea}
                /> */}

                <TextField
                  placeholder="Description"
                  {...field}
                  type="text"
                  className={cls.input}
                  variant="outlined"
                  multiline={true}
                  rows={4}
                />

                {errors?.description?.message && (
                  <span className={cls.errorField}>{(errors as any).description.message}</span>
                )}
              </>
            )}
          />
        </Grid>

        <Grid item={true} md={12} lg={12} xs={12}>
          <Controller
            name="dayOfBirth"
            control={control}
            defaultValue={user.dayOfBirth ? moment(user.dayOfBirth).format('YYYY-MM-DD') : ''}
            rules={{
              required: 'Champ obligatoire',
            }}
            render={({ field }) => (
              <>
                <label htmlFor="dayOfBirth">
                  <h5 className={cls.labelText}>
                    <strong>Anniversaire</strong>
                  </h5>
                </label>
                <TextField
                  placeholder="dayOfBirth"
                  {...field}
                  type="date"
                  className={cls.input}
                  variant="outlined"
                />
                {errors?.dayOfBirth?.message && (
                  <span className={cls.errorField}>{(errors as any).dayOfBirth.message}</span>
                )}
              </>
            )}
          />
        </Grid>

        <Grid md={12} lg={12} sm={12} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} md={6} lg={6} sm={12} xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue={user?.email}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="email">
                      <h5 className={cls.labelText}>
                        <strong>Email</strong>
                      </h5>
                    </label>
                    <TextField
                      placeholder="Email"
                      {...field}
                      type="text"
                      className={cls.input}
                      variant="outlined"
                    />
                    {errors?.email?.message && (
                      <span className={cls.errorField}>{(errors as any).email.message}</span>
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item={true} md={6} lg={6} sm={12} xs={12}>
              <Controller
                name="post_code"
                control={control}
                defaultValue={user?.postal_code}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <>
                    <label htmlFor="post_code">
                      <h5 className={cls.labelText}>
                        <strong>Code postal</strong>
                      </h5>
                    </label>
                    <TextField
                      placeholder="Code postal"
                      {...field}
                      type="text"
                      className={cls.input}
                      variant="outlined"
                    />
                    {errors?.post_code?.message && (
                      <span className={cls.errorField}>{(errors as any).post_code.message}</span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} md={12} lg={12} sm={12} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} md={6} lg={6} sm={12} xs={12}>
              <Controller
                name="phone"
                control={control}
                defaultValue={user?.phone}
                render={({ field }) => (
                  <>
                    <label htmlFor="phone">
                      <h5 className={cls.labelText}>
                        {' '}
                        <strong>Téléphone (facultatif)</strong>
                      </h5>
                    </label>
                    {/* <TextField
                  placeholder="Téléphone"
                  {...field}
                  type="text"
                  className={cls.input}
                  variant="outlined"
                /> */}
                    <PhoneInput
                      specialLabel=""
                      country="fr"
                      value={phone?.length > 0 ? phone : user?.phone}
                      onChange={phoneNumberChange}
                      masks={{
                        fr: '..........',
                      }}
                      preferredCountries={['fr', 'be', 'ch', 'ca']}
                      preserveOrder={['preferredCountries']}
                      inputStyle={{
                        width: '100%',
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item={true} md={6} lg={6} sm={12} xs={12}>
              <Controller
                name="language"
                control={control}
                defaultValue="FR"
                render={({ field }) => (
                  <>
                    <label htmlFor="phone">
                      <h5 className={cls.labelText}>
                        {' '}
                        <strong>Langue</strong>
                      </h5>
                    </label>
                    <ReactFlagsSelect
                      countries={['FR', 'GB']}
                      showOptionLabel={false}
                      showSelectedLabel={false}
                      fullWidth={false}
                      selected={selectedLanguage}
                      onSelect={onSelect}
                      selectButtonClassName={cls.selectLanguageButton}
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} md={12} lg={12} xs={12} className={cls.contentBtn}>
          <div className={cls.divContentBtn}>
            <Button className={cls.btnWhite} onClick={goBack}>
              Annuler
            </Button>

            <Button type="submit" className={cls.btnPrimary}>
              {' '}
              {loading ? 'Enregistrement...' : 'Enregistrer'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default UserProfileFormContent;
