/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, TableFigureChef } from '@orientaction/components';
import { useState } from 'react';

const Page16 = (props: any) => {
  const classes = useStyles();
  const data = [
    {
      col2: "Physiologique",
      col3: "Patriarche (Matriarche)",
    },
    {
      col2: "Sécurité",
      col3: "Seigneur (Seigneuresse)",
    },
    {
      col2: "Appartenance",
      col3: "Roi (Reine)",
    },
    {
      col2: "Accomplissement",
      col3: "Général (Générale)",
    },
    {
      col2: "Réalisation de soi",
      col3: "Maître (Maîtresse)",
    },
    {
      col2: "Éternité",
      col3: "Empereur (Impératrice)",
    },
  ];
  return (
    <Page id={16} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium',marginBottom: '50px', textAlign: 'center', fontWeight: 'lighter', fontSize: "18px", color: "#2b56a5" }}>
            Veuillez sélectionner une des 6 figures du chef associée chacune à un besoin  
          </Typography>
        </div>
        <TableFigureChef head={true} figures={data} />
        <div style={{ marginTop: '30px' }}>
          <TitleNbr
            number={1}
            text="LE PATRIARCHE (LA MATRIARCHE)"
          />
          <div>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
              Le patriarche (la matriarche) répond à des besoins d’autorité, d’affection et 
              de protection sur le modèle d’un père (ou d’une mère) de famille. Dans une 
              organisation (entreprise, association, administration) ou dans un service dirigé par 
              ce type de chef(fe), les relations sont basées sur la proximité et la simplicité.Il y a 
              de l’exigence, mais toujours compensée par l’indulgence. On retrouve ce modèle 
              plutôt dans les TPE/PME, les petites associations ou les collectivités locales à taille 
              humaine telles que les mairies. 
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <TitleNbr
            number={2}
            text="LE SEIGNEUR (LA SEIGNEURESSE)"
          />
          <div>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
              Il (elle) répond avant tout à un besoin de sécurité. L’individu échange contre 
              la sécurité de l’emploi sa force de travail. Dans une organisation (entreprise, 
              association, administration) ou dans un service dirigé par ce type de chef(fe), il 
              existe une grande distance entre les employé(e)s et la hiérarchie.  
            </Typography>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
              Le bien-être des employé(e)s est rarement pris en compte, car ils (elles) ne sont 
              perçu(e)s qu’en tant que force de travail. On retrouve ce modèle plutôt dans 
              certaines grandes administrations ou dans des petites industries implantées dans 
              des zones rurales.   
            </Typography>
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <TitleNbr
            number={3}
            text="LE ROI (LA REINE)"
          />
          <div>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
                Le roi (la reine) répond à des besoins d’appartenance et de prestige. Dans une 
                organisation (entreprise, association, administration) ou dans un service dirigé 
                par ce type de chef(fe), les relations sont très politiques. L’avancement repose sur 
                la capacité à communiquer, à courtiser ou à faire des alliances. On retrouve ce 
                modèle plutôt dans les grandes entreprises, les grandes banques et les grandes 
                compagnies d’assurance, ainsi que dans le secteur de la santé où le diplôme a 
                une grande importance. Le diplôme agit alors comme un titre de noblesse.  
            </Typography>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    }
  })
);
export default Page16;
