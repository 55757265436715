/* eslint-disable simple-import-sort/imports */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { book2Action } from '@orientaction/api-actions';
import { appBook2 } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IBook2 } from "../../store/types/Book/Book2";
import { BookTemplate } from '@orientaction/services';
import { getFromLS } from '../../utils/utils';

const useBook2 = () => {

    const dispatch = useDispatch();

    const book: IBook2 = useSelector(appBook2);

    const token = getFromLS('userToken') || '';

    const updateIndoorWeather = async (nbr: number) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            indoorWeather: nbr,
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setIndoorWeather(nbr));
    }

    const setAttendToYoursNeeds = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            ToAttendToYourNeeds: data,
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setToAttendToYoursNeeds(data));
    }

    const setSpecifiedYourNeed = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            toSpecifiedYourNeed: data,
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setToSpecifiedYourNeed(data));
    }

    const initValue = (data: any) => {
        dispatch(book2Action.setIndoorWeather(data.indoorWeather || 0));
        dispatch(book2Action.setToAttendToYoursNeeds(data.ToAttendToYourNeeds || {}));
        dispatch(book2Action.setToSpecifiedYourNeed(data.toSpecifiedYourNeed || {}));
        dispatch(book2Action.setFigure6(data.figure6 || ""));
        dispatch(book2Action.setAdjectifs(JSON.parse(data.adjectifs) || []));
        dispatch(book2Action.setSelectAdjectif(JSON.parse(data.selectAdjectif) || ['', '', '']));
        dispatch(book2Action.setResponse1(data.response1 || {}));
        dispatch(book2Action.setResponse2(data.response2 || {}));
        dispatch(book2Action.setResponse3(data.response3 || {}));
        dispatch(book2Action.setResponse4(data.response4 || {}));
        dispatch(book2Action.setResponse5(data.response5 || {}));
        dispatch(book2Action.setResponse6(data.response6 || {}));
        dispatch(book2Action.setResponse7(data.response7 || {}));
        dispatch(book2Action.setResponse8(data.response8 || {}));
        dispatch(book2Action.setResponse9(data.response9 || {}));
        dispatch(book2Action.setResponse10(data.response10 || {}));
        dispatch(book2Action.setResponse11(data.response11 || {}));
        dispatch(book2Action.setResponse12(JSON.parse(data.response12) || ['', '', '']));
        dispatch(book2Action.setResponse13(JSON.parse(data.response13) || ['', '', '']));
        dispatch(book2Action.setResponse14(data.response14 || ''));
        dispatch(book2Action.setResponse15(data.response15 || ''));
        dispatch(book2Action.setDataColor1(JSON.parse(data.dataColor1) || ['white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white']));
        dispatch(book2Action.setDataColor11(JSON.parse(data.dataColor11) || ['white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white']));
        dispatch(book2Action.setDataColor2(JSON.parse(data.dataColor2) || ['white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white']));
        dispatch(book2Action.setDataColor21(JSON.parse(data.dataColor21) || ['white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white','white', 'white', 'white', 'white', 'white', 'white']));
        dispatch(book2Action.setExpressionAB(JSON.parse(data.expressionAB) || []));
        dispatch(book2Action.setTestPersonality(JSON.parse(data.testPersonality) || []));
        dispatch(book2Action.setPersonalQuality(JSON.parse(data.personalQuality) || []));
        dispatch(book2Action.setQuality(JSON.parse(data.quality) || ['', '', '']));
        dispatch(book2Action.setProgressionAxe(JSON.parse(data.progressionAxe) || []));
        dispatch(book2Action.setProgression(JSON.parse(data.progression) || ['', '', '']));
        dispatch(book2Action.setProgressionText(JSON.parse(data.progressionText) || ['', '', '']));
        dispatch(book2Action.setRolePrefere(JSON.parse(data.rolePrefere) || ['', '', '']));
        dispatch(book2Action.setRolePossible(JSON.parse(data.rolePossible) || ['', '', '']));
        dispatch(book2Action.setRoleAEviter(JSON.parse(data.roleAEviter) || ['', '', '']));
        dispatch(book2Action.setVerbes(JSON.parse(data.verbes) || []));
        dispatch(book2Action.setResponseCondition1(data.responseCondition1 || {}));
        dispatch(book2Action.setResponseCondition2(data.responseCondition2 || {}));
        dispatch(book2Action.setResponseCondition3(data.responseCondition3 || {}));
        dispatch(book2Action.setResponseCondition4(data.responseCondition4 || {}));
        dispatch(book2Action.setResponseCondition5(data.responseCondition5 || {}));
        dispatch(book2Action.setResponseCondition6(data.responseCondition6 || {}));
        dispatch(book2Action.setResponseCondition7(data.responseCondition7 || {}));
    }

    const setId = (data: number) => {
        dispatch(book2Action.setId(data));
    }

    const getMyValueBook = async (userId: any) => {
        return BookTemplate.getMyValueBook(token, {
            id: userId,
            query: 'api::second-ebook.second-ebook'
        });
    }
    const setTestPersonality = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            testPersonality: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setTestPersonality(data));
    }
    const setFigure6 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            figure6: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setAdjectifs = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            adjectifs: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setSelectAdjectif = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            selectAdjectif: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse1 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response1: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse2 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response2: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse3 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response3: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse4 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response4: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse5 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response5: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse6 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response6: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse7 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response7: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse8 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response8: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse9 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response9: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse10 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response10: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse11 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response11: data,
            query: "api::second-ebook.second-ebook"
        })
    }
    const setResponse12 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response12: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setResponse12(data));
    }
    const setResponse13 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response13: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setResponse13(data));
    }
    const setResponse14 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response14: data,
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setResponse14(data));
    }
    const setResponse15 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            response15: data,
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setResponse15(data));
    }
    const setDataColor1 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            dataColor1: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setDataColor1(data));
    }
    const setDataColor11 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            dataColor11: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setDataColor11(data));
    }
    const setDataColor2 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            dataColor2: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setDataColor2(data));
    }
    const setDataColor21 = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            dataColor21: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setDataColor21(data));
    }
    const setExpressionAB = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            expressionAB: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setPersonalQuality = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            personalQuality: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setQuality = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            quality: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setProgressionAxe = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            progressionAxe: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setProgression = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            progression: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setProgressionText = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            progressionText: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
        dispatch(book2Action.setProgressionText(data));
    }
    const setRolePrefere = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            rolePrefere: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setRolePossible = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            rolePossible: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setRoleAEviter = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            roleAEviter: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }
    const setVerbes = async (data: any) => {
        await BookTemplate.updateBook(token, {
            id: book.id,
            verbes: JSON.stringify(data),
            query: "api::second-ebook.second-ebook"
        })
    }

    // New variables

    const setResponseCondition1 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition1: data,
          query: "api::second-ebook.second-ebook"
      })
  }
  const setResponseCondition2 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition2: data,
          query: "api::second-ebook.second-ebook"
      })
  }
  const setResponseCondition3 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition3: data,
          query: "api::second-ebook.second-ebook"
      })
  }
  const setResponseCondition4 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition4: data,
          query: "api::second-ebook.second-ebook"
      })
  }
  const setResponseCondition5 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition5: data,
          query: "api::second-ebook.second-ebook"
      })
  }
  const setResponseCondition6 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition6: data,
          query: "api::second-ebook.second-ebook"
      })
  }
  const setResponseCondition7 = async (data: any) => {
      await BookTemplate.updateBook(token, {
          id: book.id,
          responseCondition7: data,
          query: "api::second-ebook.second-ebook"
      })
  }

    return {
        ...book,
        updateIndoorWeather,
        setAttendToYoursNeeds,
        setSpecifiedYourNeed,
        initValue,
        setId,
        getMyValueBook,
        setTestPersonality,
        setFigure6,
        setAdjectifs,
        setSelectAdjectif,
        setResponse1,
        setResponse2,
        setResponse3,
        setResponse4,
        setResponse5,
        setResponse6,
        setResponse7,
        setResponse8,
        setResponse9,
        setResponse10,
        setResponse11,
        setResponse12,
        setResponse13,
        setResponse14,
        setResponse15,
        setDataColor1,
        setDataColor11,
        setDataColor2,
        setDataColor21,
        setExpressionAB,
        setPersonalQuality,
        setQuality,
        setProgressionAxe,
        setProgression,
        setProgressionText,
        setRolePrefere,
        setRolePossible,
        setRoleAEviter,
        setVerbes,
        setResponseCondition1,
        setResponseCondition2,
        setResponseCondition3,
        setResponseCondition4,
        setResponseCondition5,
        setResponseCondition6,
        setResponseCondition7
    };

};

export default useBook2;
