// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';
import clsx from 'clsx';

const Page08 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={8}>
      <br />
      <Paragrapth align="justify">
        <Paragrapth align="justify" colorBlue={true}>
          <strong style={{ marginRight: '5px' }}>
            La deuxième étape pour bien se connaître est d’identifier ses motivations.
          </strong>
        </Paragrapth>
        Qu’est-ce qui me fait avancer dans la vie ? Qu’est-ce qui me donne envie de me lever le
        matin, de me rendre au travail motivé(e) et heureux(se) ?
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Comprendre ce qui nous anime, identifier les caractéristiques que doit posséder notre
        environnement matériel, symbolique et social pour nous stimuler, est essentiel pour cibler
        les bons métiers et les bonnes organisations (entreprise, association, administration), qui
        nous permettront de réussir et de nous épanouir.
      </Paragrapth>
      <br />
      <br />
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook2}/titre12.png`} alt="logo" /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              DES PLANTES VERTES ET DES ÊTRES HUMAINS
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook2}/illustration_page_12.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Nous avons dans nos bureaux douze plantes vertes. Elles sont toutes différentes, et ont
          changé plusieurs fois de place depuis la création d’ORIENTACTION. Nous avons remarqué deux
          choses essentielles à leur sujet. D’abord, elles sont sensibles aux caractéristiques de la
          pièce dans laquelle elles poussent. L‘ensoleillement, la température, l’humidité, chaque
          bureau constitue un milieu particulier, favorable à certaines plantes et délétère pour
          d’autres.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Ensuite, les plantes sont sensibles à l’ambiance. S’il y a une bonne ambiance dans la
          pièce où se situe la plante, elle sera en bonne santé. L’inverse est également vrai. Les
          êtres humains sont comme des plantes vertes. Ils ne sont pas aptes au bonheur ou à la
          réussite intrinsèquement. On ne peut pas dire d’un individu : il est compétent ! Il est
          performant ! Il est heureux ! Non, cela dépend toujours de l’environnement dans lequel il
          évolue.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Certes, certaines personnes ont une capacité à s’adapter plus grande que d’autres mais, en
          général, pour qu’une personne soit heureuse et réussisse, il faut que son environnement
          lui permette d’exprimer sa personnalité et ses talents naturels. Si votre environnement
          favorise cela, alors vous serez heureux(se) et vous réussirez.
        </Typography>
      </div>
      <br />
      <br />
      <Paragrapth align="justify">
        <Paragrapth align="justify" colorBlue={true}>
          <strong style={{ marginRight: '5px' }}>La troisième et dernière étape, </strong>
        </Paragrapth>
        sans doute la plus importante pour bien se connaître est d’identifier sa personnalité. Notre
        personnalité est composée de multiples facettes. Pour une partie, elle relève de la
        génétique. Pour une autre partie, de notre éducation. Pour le reste, de nos choix. Notre
        personnalité évolue avec l’âge, les évènements et les rencontres. Elle se construit à
        travers les épreuves de la vie. Concrètement, elle correspond à nos attitudes, à nos façons
        de penser et de nous comporter, à une manière particulière de fonctionner, qui nous est
        propre.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Imaginez que vous gardiez dans une grande armoire de votre maison, bien rangés et
        disponibles, un grand nombre de masques. Dès que vous souhaiteriez sortir de votre maison,
        vous mettriez l’un de ces masques pour dissimuler votre vrai visage. Quand vous rentreriez
        chez vous, vous ôteriez ce masque et le rangeriez consciencieusement à sa place. Personne ne
        saurait jamais qui vous êtes vraiment, à l’image de ces super-héros qui dissimulent
        toujours, pour des raisons de sécurité, leur véritable identité.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Cette métaphore illustre bien le fonctionnement de notre personnalité. D’un côté des
        éléments périphériques et superficiels que nous montrons aux autres - nos masques – et de
        l’autre des éléments centraux et profonds que nous dévoilons très rarement, sauf peut-être à
        des intimes – notre vrai visage - .
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        D’un côté, l’image que nous voulons donner, de l’autre qui nous sommes réellement. Nous
        jouons ainsi de multiples rôles dans notre vie de tous les jours. Nous allons maintenant
        découvrir pourquoi.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Si nous cachons ainsi notre vraie personnalité, c’est souvent parce que nous jugeons, à tort
        ou à raison, certains des aspects de notre personnalité inacceptables. Nous pensons que si
        nous sommes vraiment nous-mêmes, les autres cesseront de nous aimer, de nous considérer ou
        de nous respecter. Nous percevons certains de nos comportements, pourtant parfaitement
        naturels, comme des défauts qu’il convient à tout prix de dissimuler. Nous adoptons d’autres
        comportements.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Ce mode de fonctionnement peut nous conduire à certaines difficultés. En effet, il arrive
        qu’à force de porter des masques, de vouloir donner une certaine image de nous-mêmes, nous
        nous trouvions coupés de notre vraie personnalité. Cette coupure se traduit par un sentiment
        de vide. Nous avons l’impression de faire des choses qui ne nous correspondent pas. Nous
        éprouvons une insatisfaction constante. Nous ne prenons plus de plaisir. Nous perdons
        confiance, jusqu’à ne plus savoir qui nous sommes vraiment.
      </Paragrapth>
    </Page>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -81,
      [theme.breakpoints.down(1300)]: {
        marginTop: -88,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 18,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 11,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '35%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'left',
    },
    imgTailleur: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page08;
