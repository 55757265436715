/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { ApiActions, categoryTestAction } from '@orientaction/api-actions';
import { apiState } from '@orientaction/reducers';
import { TestService } from '@orientaction/services';
import { omit } from 'lodash';
import { useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TestContext } from '../Context/TestContext';
import { getCategoryTest } from '../services/categoryTest.service';
import { getFromLS } from '../utils/utils';

const qs = require('qs');

interface dataApi {
  tests: any[];
  getTests: () => Promise<void>;
  getArchivedTests: () => Promise<void>;
  getDisabledTests: () => Promise<void>;
  postTest: (test: any) => Promise<void>;
  putTest: (id: string, test: any) => Promise<void>;
  getTest: (id: string) => Promise<void>;
  setProgress: (index: number, length: number) => Promise<void>;
  test: any;
  archivedTests: any;
  disabledTests: any;
  progress: number;
  testPresentation: any;
  loadingAdmin: boolean;
}

const useTest: any = () => {
  const dispatch = useDispatch();

  const data: dataApi = useSelector(apiState);
  const { test: contextTest, updateTest } = useContext<any>(TestContext);

  const token = getFromLS('userToken') || '';
  const [loadPage, setLoadPage] = useState(true);

  const testTitleRef: any = useRef(null);
  const testTimeRef: any = useRef(null);
  const testImageRef: any = useRef(null);
  const testVisibilityRef: any = useRef(null);
  const testCategoryRef: any = useRef(null);

  const loadingAdmin = (bool: boolean) => {
    dispatch(ApiActions.loadingAdmin(bool));
  };

  const getMyTestByCompany = async (props: any) => {
    return TestService.getMyTestByCompany(token, props);
  };

  const getTests = async (
    filter?: string,
    filterBoolean?: boolean,
    visibilityPermissions?: boolean
  ) => {
    // Get user info to check the right permissions
    const permissionsParams = qs.stringify(
      {
        filters: {
          $or: [
            {
              visibilityPermission: { $eq: 0 },
            },
            {
              visibilityPermission: { $eq: 1 },
            },
            {
              visibilityPermission: { $eq: 3 },
            },
          ],
        },
      },
      {
        encodeValuesOnly: true,
      }
    );
    return TestService.getTests(token, permissionsParams, filter, filterBoolean)
      .then(d => {
        dispatch(ApiActions.setTests(d?.data.data));
        return d?.data.data;
      })
      .catch(e => {
        if (e.message.indexOf('401') >= 0) {
          // logout();
        }
        dispatch(ApiActions.setTests(e || []));
      });
  };

  const getArchivedTests = async () => {
    loadingAdmin(true);
    try {
      const resp: any = await TestService.getArchivedTests(token);
      dispatch(ApiActions.setArchivedTests(resp?.data?.data));
      return resp?.data?.data;
    } catch (err: any) {
      if (err.message.indexOf('401') >= 0) {
        // logout();
      }
      dispatch(ApiActions.setTests(err || []));
    } finally {
      loadingAdmin(false);
    }
  };

  const getDisabledTests = async () => {
    loadingAdmin(true);
    try {
      const resp: any = await TestService.getDisabledTests(token);
      dispatch(ApiActions.setDisabledTests(resp?.data?.data));
      return resp?.data?.data;
    } catch (err: any) {
      if (err.message.indexOf('401') >= 0) {
        // logout();
      }
      dispatch(ApiActions.setDisabledTests(err || []));
    } finally {
      loadingAdmin(false);
    }
  };

  const postTest = async (test: any) => {
    TestService.postTest(token, test)
      .then(d => {
        dispatch(ApiActions.postTest(d?.data.data));
      })
      .catch(e => {
        if (e.message.indexOf('401') >= 0) {
          // logout();
        }
      });
  };

  const postTestWithQuestionsAndResponses = async (test: any) => {
    console.log('TEST...', test);
    return TestService.postTestWithQuestionsAndResponses(token, test);
  };

  const putTest = async (id: string, test: any) => {
    TestService.putTest(token, id, test)
      .then(d => {
        const responseData = { ...d.data.data };
        responseData.oldCategory = test?.currentCategory;

        if (test?.isDesabled || test?.isArchived) {
          dispatch(categoryTestAction.updateCategoryTest(responseData));
        }
        if (test?.isDesabled === false) {
          dispatch(ApiActions.removeDisabledTest(responseData));
        }
        if (test?.isArchived === false) {
          dispatch(ApiActions.removeArchivedTest(responseData));
        }
      })
      .catch(e => {
        if (e.message.indexOf('401') >= 0) {
          // logout();
        }
      });
  };

  const desableTest = async (id: string, test: any) => {
    TestService.desableTest(token, id, test)
      .then(d => {
        const responseData = { ...d.data.data };
        responseData.oldCategory = test?.currentCategory;

        if (test?.isDesabled || test?.isArchived) {
          dispatch(categoryTestAction.updateCategoryTest(responseData));
        }
        if (test?.isDesabled === false) {
          dispatch(ApiActions.removeDisabledTest(responseData));
        }
        if (test?.isArchived === false) {
          dispatch(ApiActions.removeArchivedTest(responseData));
        }
      })
      .catch(e => {
        if (e.message.indexOf('401') >= 0) {
          // logout();
        }
      });
  };

  const getTest = async (id: string, filter = '') => {
    setLoadPage(true);
    return TestService.getTest(token, id, filter)
      .then(async (d: any) => {
        dispatch(ApiActions.setTest(d?.data.data));
        setLoadPage(false);
        return d?.data.data;
      })
      .catch(e => {
        if (e.message.indexOf('401') >= 0) {
          // logout();
        }
      });
  };

  const getVisibilityPermission = (value: number) => {
    switch (value) {
      case 0:
        return [
          { value: 0, label: 'Autorisé à tous' },
          { value: 1, label: 'Bénéficiaires non-rattachés' },
          { value: 2, label: 'ORIENTACTION uniquement' },
          { value: 4, label: 'Espaces entreprises spécifiques' },
        ];

      case 1:
        return [{ value: 1, label: 'Bénéficiaires non-rattachés' }];

      case 2:
        return [{ value: 2, label: 'ORIENTACTION uniquement' }];

      case 3:
        return [
          { value: 1, label: 'Bénéficiaires non-rattachés' },
          { value: 2, label: 'ORIENTACTION uniquement' },
        ];

      case 4:
        return [{ value: 4, label: 'Espaces entreprises spécifiques' }];

      case 5:
        return [
          { value: 1, label: 'Bénéficiaires non-rattachés' },
          { value: 4, label: 'Espaces entreprises spécifiques' },
        ];

      case 6:
        return [
          { value: 2, label: 'ORIENTACTION uniquement' },
          { value: 4, label: 'Espaces entreprises spécifiques' },
        ];

      default:
        return null;
    }
  };

  const getEnterpriseSelected = (companies: any, isAllEnterpriseSelected = false) => {
    const allCompanies = companies
      ?.filter((c: any) => c.id !== 1)
      ?.map((e: any) => {
        return { ...e, value: e.id, label: e.name };
      });
    if (isAllEnterpriseSelected) {
      return [{ value: 0, label: 'Tous' }, ...allCompanies];
    }
    return allCompanies;
  };

  const getTestPopulated = async (id: string) => {
    return TestService.getTestPopulated(token, id).then(async (d: any) => {
      const { data: dataResp }: any = d;

      const test: any = {};

      test.title = dataResp.test?.title ?? '';

      test.description = dataResp.test?.description ?? '';

      test.image = dataResp.test?.image ?? '';

      test.time = dataResp.test?.time ?? '';

      const visibilityPermission = getVisibilityPermission(dataResp.test?.visibilityPermission);

      const stockageMode = !dataResp.test?.saveTestResult
        ? { value: 0, label: 'Données non-stockées' }
        : { value: 1, label: 'Données stockées' };
      const isAllEnterpriseSelected = dataResp.test?.isAllEnterpriseSelected ?? false;
      const enterpriseSelected = getEnterpriseSelected(
        dataResp.test?.companies,
        dataResp.test?.isAllEnterpriseSelected
      );

      // default company is orient action with id = 1
      const company = dataResp.test?.company?.id ? 1 : 0;

      const category = dataResp.test?.category_test?.id || 0;

      const finalText: any = {};

      finalText.id = dataResp.test?.finalText?.id ?? '';
      finalText.title = dataResp.test?.finalText?.title ?? '';
      finalText.description = dataResp.test?.finalText?.description ?? '';

      const egalityVariable: any = {};
      egalityVariable.id = dataResp.test?.egalityVariable?.id ?? '';
      egalityVariable.name = dataResp.test?.egalityVariable?.name ?? '';
      egalityVariable.description = dataResp.test?.egalityVariable?.description ?? '';
      egalityVariable.image = dataResp.test?.egalityVariable?.image ?? '';

      const isDraft = dataResp.test.isDesabled;

      const questions: any = [];
      await Promise.all(
        dataResp.test.questions.map((q: any) => {
          const question: any = {};
          question.aResponse = {};
          question.bResponse = {};
          question.id = q.id;
          question.title = q.title;
          question.aResponse.id = q?.responses[0]?.id ?? '';
          question.aResponse.title = q?.responses[0]?.title ?? '';
          question.aResponse.variable = q?.responses[0]?.variable?.name ?? '';
          question.aResponse.variableValue = q?.responses[0]?.variableValue ?? '';
          // TO DO: change relation based on ID
          question.aResponse.variableKey =
            dataResp.test.variables.filter((v: any) => v.id === q?.responses[0]?.variable?.id)[0]
              ?.variableKey ?? '';
          question.bResponse.id = q?.responses[1]?.id ?? '';
          question.bResponse.title = q?.responses[1]?.title ?? '';
          question.bResponse.variable = q?.responses[1]?.variable?.name ?? '';
          question.bResponse.variableValue = q?.responses[1]?.variableValue ?? '';
          // TO DO: change relation based on ID
          question.bResponse.variableKey =
            dataResp.test.variables.filter((v: any) => v.id === q?.responses[1]?.variable?.id)[0]
              ?.variableKey ?? '';
          questions.push(question);
          return q;
        })
      );
      const variables: any = [];
      await Promise.all(
        dataResp.test.variables.map((v: any) => {
          const testVariable: any = {};
          testVariable.variableKey = v.variableKey;
          testVariable.id = v.id;
          testVariable.name = v.name;
          testVariable.description = v.description;
          testVariable.image = v.image;
          variables.push(testVariable);
          return v;
        })
      );
      updateTest({
        test,
        visibilityPermission,
        stockageMode,
        enterpriseSelected,
        isAllEnterpriseSelected,
        variables,
        egalityVariable,
        questions,
        finalText,
        isDraft,
        toDeleteQuestions: [],
        company,
        category,
      });
    });
  };
  // To refactor
  const deepDuplicateTest = async (id: string) => {
    return TestService.getTestPopulated(token, id).then(async (d: any) => {
      const { data: dataResp }: any = d;
      const { test } = dataResp;
      const testToDuplicate: any = {};
      testToDuplicate.test = {};
      testToDuplicate.test.title = test?.title;
      testToDuplicate.test.description = test?.description;
      testToDuplicate.test.image = test?.image;
      testToDuplicate.test.time = test?.time;
      const visibilityPermission = getVisibilityPermission(test?.visibilityPermission);

      const stockageMode = !test?.saveTestResult
        ? { value: 0, label: 'Données non-stockées' }
        : { value: 1, label: 'Données stockées' };
      const isAllEnterpriseSelected = test?.isAllEnterpriseSelected ?? false;
      const enterpriseSelected = getEnterpriseSelected(
        test?.companies,
        test?.isAllEnterpriseSelected
      );

      testToDuplicate.test.diagram = {
        id: test?.diagram?.id,
        type: test?.diagram?.type,
      };

      // default company is orient action with id = 1
      const company = test?.company?.id ? 1 : 0;
      testToDuplicate.company = company;

      const category = test?.category_test?.id || 0;
      testToDuplicate.category = category;

      const finalText: any = {};

      finalText.id = test?.finalText?.id ?? '';
      finalText.title = test?.finalText?.title ?? '';
      finalText.description = test?.finalText?.description ?? '';
      testToDuplicate.finalText = finalText;

      const questions = test?.questions || [];
      const variables = test?.variables || [];

      const isDraft = test.isDesabled;

      const omittedTest = omit(testToDuplicate, [
        'refs',
        'errors',
        'questionDeletionConfirmPopin',
        'variableDeletionConfirmPopin',
      ]);

      await postTestWithQuestionsAndResponses({
        ...omittedTest,
        visibilityPermission,
        stockageMode,
        enterpriseSelected,
        isAllEnterpriseSelected,
        isDraft,
        questions,
        variables,
      });

      const responseData = await getCategoryTest(token, {
        isDesabled: false,
        isArchived: false,
      });
      // update store
      dispatch(categoryTestAction.setList(responseData?.data?.data));
    });
  };

  const updateTestWithQuestionsAndResponses = async (test: any) => {
    return TestService.updateTestWithQuestionsAndResponses(token, test);
  };

  const setProgress = (index: number, length: number) => {
    // dispatch(ApiActions.setProgress(data));
    const conversionPourcentage = ((index + 1) * 100) / length;
    dispatch(ApiActions.setProgress(conversionPourcentage));
  };

  const resetProgress = (pour?: number) => {
    dispatch(ApiActions.setProgress(pour || 0));
  };

  const getTestResultText = async (idTest: string) => {
    const textResponse = await TestService.getTestResultText(token, idTest);
    return textResponse.data.data[0];
  };

  const getResponsePopulate = async (idResponse: string) => {
    const responseMaped = await TestService.getResponsePopulate(token, idResponse);
    return responseMaped.data.data;
  };

  const getResponsesPopulate = async (filter: string) => {
    return TestService.getResponsesPopulate(token, filter);
  };

  const getTestPresentation = async (id: string) => {
    resetProgress();
    setLoadPage(true);
    const d = await TestService.getTestWithoutPopulate(token, id);
    dispatch(ApiActions.setTest(d?.data.data));
    setLoadPage(false);
  };

  return {
    ...data,
    getTests,
    getArchivedTests,
    getDisabledTests,
    getTestPopulated,
    postTest,
    postTestWithQuestionsAndResponses,
    putTest,
    updateTestWithQuestionsAndResponses,
    deepDuplicateTest,
    getTest,
    setProgress,
    getTestResultText,
    getResponsePopulate,
    getResponsesPopulate,
    resetProgress,
    loadPage,
    getTestPresentation,
    getMyTestByCompany,
    testTitleRef,
    testTimeRef,
    testImageRef,
    testVisibilityRef,
    testCategoryRef,
    desableTest,
  };
};

export default useTest;
