import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  gradientBG: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 20%, #000A8C 100%) 0% 0% padding-box',
  },
  gradientGrey: {
    background: 'linear-gradient(0deg, rgba(0, 10, 140, 0.1), rgba(0, 10, 140, 0.1)), #FFFFFF',
  },
  bgWhite: {
    background: 'white',
  },
  noPadding: {
    '&>div': {
      '@media(min-width: 1250px)': {
        padding: '0px !important',
      },
      '@media only screen and (max-width: 600px)': {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
      },
    },
  },
  layout: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    // maxWidth: 'calc(100% - 205px)',
    height: '100%',
    overflow: 'hidden',
    '&>div': {
      height: 'calc(100vh - 76px)',
      padding: theme.spacing(0, 3),
      color: theme.palette.primary.main,
      overflow: 'auto',
      '@media(min-width: 1750px)': {
        padding: '56px 120px 28px 80px',
      },
    },
  },
  appBar: {
    paddingRight: theme.spacing(3),
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: '1px solid #E3E8F0',
    '&>button': {
      marginRight: theme.spacing(-2),
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0),
    },
  },
  navbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
    marginTop: 10,
    marginBottom: 10,
    '& a': {
      marginRight: theme.spacing(4.75),
    },
    '@media(min-width: 1250px)': {
      height: 56,
    },
    [theme.breakpoints.down('sm')]: {
      height: 76,
      width: '98.5%',
      margin: '20 auto',
    },
  },
  logoImg: {
    width: '100%',
    height: 'auto',
  },
  navbarWithoutLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 76,
    '& a': {
      marginRight: theme.spacing(4.75),
    },
    '@media(min-width: 1250px)': {
      height: 76,
    },
  },
  logoContainer: {
    cursor: 'pointer',
    maxWidth: 239,
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 160,
      marginTop: 5,
      marginLeft: 10,
    },
  },
  logo: {
    width: '100%',
    height: 'auto',
  },
  profilContainer: {
    display: 'flex',
    alignItems: 'center',
    '& div, hr': {
      marginLeft: 10,
      '@media(min-width: 1250px)': {
        marginLeft: 30,
      },
    },
  },
  btnPrimary: {
    minHeight: 38,
    height: 38,
    width: 38,
    minWidth: 38,
    textTransform: 'none',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    '@media(min-width: 600px)': {
      minHeight: 48,
      height: 48,
      width: 146,
      minWidth: 146,
    },
  },
  initial: {
    width: 38,
    height: 38,
    background: '#000A8C 0% 0% no-repeat padding-box',
    borderRadius: '100%',
    color: '#fff',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      height: '51%',
    },
  },
  divider: {
    display: 'none',
    '@media(min-width: 1250px)': {
      display: 'block',
      height: '20px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
  textHeader: {
    display: 'none',
    marginLeft: 0,
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 !important',
    cursor: 'pointer',
    '& > span': {
      marginLeft: 10,
      '@media(min-width: 1250px)': {
        marginLeft: 10,
      },
    },
  },
  buttonBack: {
    background: '#000a8c47',
    color: '#000A8C',
    height: '100%',
    width: '135px',
    borderRadius: '0px !important',
    '&: hover': {
      background: '#000a8c47',
      color: '#000A8C',
    },
    '@media only screen and (max-width: 600px)': {
      opacity: 0,
    },
  },
  btnWhite: {
    backgroundColor: '#fff#',
    color: '#0E1247',
    marginTop: 0,
    marginRight: 5,
    borderRadius: 3,
    opacity: 1,
    border: 'solid grey',
    textTransform: 'none',
    minHeight: 48,
    minWidth: 146,
    [theme.breakpoints.down('sm')]: {
      padding: '1vh',
      minHeight: 48,
      height: 48,
      width: 140,
      minWidth: 130,
    },
  },
}));
