/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { gameBookTemplateActions, gameVariablesTemplateActions } from '@orientaction/api-actions';
import { ContainerAdmin, MarginContainer } from '@orientaction/commons';
import { Layout } from '@orientaction/components';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Check } from '../../../Asset/svg/check.svg';
import { apiServiceNative } from '../../../utils/fetchNative';
import { useStyles } from './style';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { gameBookValues, gameVariables, gameVariableValues } from '@orientaction/reducers';

interface Item {
  id: number;
  name: string;
  description: string;
}

interface Props {
  data?: Item[];
}

const SelecteTemplate: React.FC<Props> = () => {
  const history = useHistory();
  const classes = useStyles();
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [dataTemplate, setData] = useState<any>(null);
  const { id, edit }: any = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleClick = (itemId: number) => {
    setSelectedItemId(itemId === selectedItemId ? null : itemId);
  };
  const valuesBooksTemplate = useSelector(gameBookValues);
  const allVariables = useSelector(gameVariables);
  const valuesVariables = useSelector(gameVariableValues);

  const handleClickData = async (e: any) => {
    if (!selectedItemId) {
      console.log('No selected item ID provided, exiting function.');
      return;
    }
    dispatch(gameVariablesTemplateActions.updateField('template_game', selectedItemId));
    confirmCancel();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/template-games?populate=*&sort=id:asc`,
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const dataTemplateApi = await response.json();
        setData(dataTemplateApi?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  if (dataTemplate?.length === 0) {
    return <div>Aucune donnée disponible</div>;
  }

  const goBack = () => {
    history.push('/admin/game/create-variables');
  };

  useEffect(() => {
    console.log({allVariables, valuesVariables});
  }, [allVariables, valuesVariables]);

  const confirmCancel = () => {
    if (!edit) {
      history.push(`/admin/game/edit-variable/${id}`);
    } else {
      history.push(`/admin/game/edit-variable/${id}/edit`);
    }
  };

  return (
    <Layout isaddBackground={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <div style={{ position: 'relative' }}>
            <div className={classes.btnFloat} onClick={goBack}>
              <KeyboardArrowLeftIcon />
              <div style={{ marginTop: '3px' }}>Retour</div>
            </div>
            <h1 className={classes.titleMain}>Sélection d'un modèle de restitution</h1>
          </div>
          <Grid component="ul" container={true} spacing={2} className={classes.list}>
            {dataTemplate &&
              dataTemplate.map((item: any) => (
                <Grid
                  item={true}
                  xs={6}
                  sm={4}
                  lg={3}
                  component="li"
                  key={item.id}
                  style={{ position: 'relative' }}
                  onClick={() => handleClick(item.id)}
                >
                  <span
                    className={`${classes.containerImg} ${
                      item.id === selectedItemId ? classes.selectedItem : ''
                    }`}
                  >
                    <img
                      src={item?.attributes?.img?.data[0]?.attributes?.url}
                      alt={item?.attributes?.name}
                      width="100%"
                      style={{ display: 'block' }}
                    />
                  </span>
                  <h3 className={classes.titleItem}>{item?.attributes?.name}</h3>
                  {item.id === selectedItemId && (
                    <span className={classes.check}>
                      <Check />
                    </span>
                  )}
                </Grid>
              ))}
          </Grid>
        </MarginContainer>{' '}
        {dataTemplate && (
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedItemId}
            className={classes.btn}
            style={{ textTransform: 'none' }}
            onClick={(e: any) => handleClickData(e)}
          >
            Valider
          </Button>
        )}
      </ContainerAdmin>
    </Layout>
  );
};

export default SelecteTemplate;
