/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, ParagrapthListNumber, TitlePage, TitleNbr } from '@orientaction/components';
import { context } from './context';

const Page41 = (props: any) => {

    return (
        <Page id={41}>
            <TitlePage>
                5 IDÉES POUR INVESTIR <br />
                D’AUTRES DOMAINES DE VIE
            </TitlePage>

            {
                context.map((item: any) => (
                    <>
                        <TitleNbr number={item.id} text={item.title} />

                        <MarginContainer>
                            <Paragrapth align='justify'>
                                {item.text}
                            </Paragrapth>
                        </MarginContainer>

                        <MarginContainer>
                            {
                                item.listContext.map((item2: any) => (
                                    <>
                                        <MarginContainer>
                                            <ParagrapthListNumber
                                                withTwoPoint={false}
                                                list={[
                                                    {
                                                        label: item2.label,
                                                        text: item2.text,
                                                        number: item2.id
                                                    }
                                                ]}
                                            />
                                        </MarginContainer>
                                    </>
                                ))
                            }
                        </MarginContainer>

                    </>
                ))
            }

        </Page>
    );
};

export default Page41;
