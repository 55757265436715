/* eslint-disable no-use-before-define */
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';

let typeTimeout: any;

interface ITextArea {
  onChange: (e: any) => void;
  name: string;
  value: any;
  readOnly?: boolean;
}

const TextArea: FC<ITextArea> = ({ onChange, name, value, readOnly }) => {
  const classes = useStyles();

  const handleChange = (e: any) => {
    typeTimeout = setTimeout(function () {
      onChange({
        target: {
          name: name as any,
          value: e.target.value,
        } as any,
      });
    }, 2000);
  };

  const handleChangeGlobalState = (e: any) => {
    if (readOnly) return;
    onChange({
      target: {
        name: name as any,
        value: e.target.value,
      } as any,
    });
  };

  return (
    <TextField
      name={name || ''}
      type="date"
      onChange={handleChange}
      onBlur={handleChangeGlobalState}
      defaultValue={value}
      className={clsx({
        [classes.textArea]: true,
        [classes.empty]: value === '',
      })}
      InputProps={{
        readOnly: readOnly as boolean,
      }}
    />
  );
};

export default TextArea;
