// eslint-disable-next-line simple-import-sort/imports
import { Page, Somaire } from '@orientaction/components';
import { listSomaire } from "./context";

const Page06 = (props: any) => {

    return (
        <Page
            id={6}
        >
            <Somaire list={listSomaire} />
        </Page>
    );
};
export default Page06;
