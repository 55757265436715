import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  labelContainer: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#0E1247',
  },
  error: {
    color: 'red',
  },
}));
