// eslint-disable-next-line simple-import-sort/imports
import { Page } from '@orientaction/components';
import { Grid } from '@material-ui/core';
import useStyles from '../../../style';
import Img from '../../Img';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';
import { blockRedirection, publicBook3Path } from '@orientaction/utils';

const Page17 = (props: any) => {
  const classes = useStyles();

  const lang: string = useSelector(appLanguage);

  return (
    <Page id={17} notPadding={!(lang === 'GB')}>
      {lang === 'GB' ? (
        <Grid
          container={true}
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            height: '120vh',
          }}>
          <Img
            src="pasContent.jpg"
            alt="pasContent"
            style={{
              width: '100%',
            }}
          />
        </Grid>
      ) : (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} md={12}>
              <div className={classes.couverturePage}>
                <img
                  src={`${publicBook3Path}/ame_manager.jpg`}
                  alt="couverture intro"
                  className={classes.imgpremierApercu}
                />
                <span
                  onClick={() =>
                    blockRedirection({
                      language: lang,
                      onOpen: () => console.log(''),
                      functionToPass: () => {
                        window.open(
                          'https://decouvrez-nos-tests.e-orientaction.com/api/test/10/avez-vous-lame-dun-manager',
                          '_blank'
                        );
                      },
                    })
                  }
                  className={classes.btn017}>
                  Clique
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Page>
  );
};
export default Page17;
