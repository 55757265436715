/* eslint-disable no-restricted-syntax */
import 'react-quill/dist/quill.snow.css';
import './index.css';
import classNames from 'classnames';
import { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import style from './style';

const RichEditor = forwardRef((props: any, quillRef) => {
  const {
    handleChange,
    value,
    placeholder = 'Renseignez le titre',
    whiteBg = false,
    isMultiline = false,
    hasError = false,
  } = props;
  const classes = style();
  return (
    <ReactQuill
      ref={quillRef as any}
      className={classNames(
        classes.quill,
        { [classes.quillWhiteBackground]: whiteBg },
        { [classes.quillMultiline]: isMultiline },
        {
          [classes.error]: hasError,
        }
      )}
      theme="snow"
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      modules={{
        clipboard: {
          matchVisual: false,
        },
      }}
      style={{ fontFamily: 'Poppins !important' }}
    />
  );
});

export default RichEditor;
