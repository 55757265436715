import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% padding-box',
    height: '100vh',
    position: 'relative',
  },
  simplePieContainer: {
    zIndex: 99,
    marginTop: 50,
  },
  btnPrimary: {
    paddingLeft: 20,
    paddingRight: 20,
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    '@media(min-width: 1250px)': {
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      minWidth: 146,
    },
    '@media only screen and (max-width: 600px)': {
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 5,
    },
    display: 'block',
    marginTop: 30,
    width: '100%',
    textTransform: 'none',
  },
  textHeader: {
    display: 'block',
    '@media only screen and (max-width: 600px)': {
      fontSize: 10,
    },
  },
  paddingContent: {
    // minHeight: 'calc(100vh - 128px)',
    paddingTop: 50,
    paddingBottom: 50,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    width: '100%',
    fontFamily: 'Poppins',
    textAlign: 'left',
    color: '#FFFFFF',
    fontSize: '15px',
    marginTop: '0px',
    '@media only screen and (max-width: 600px)': {
      width: '90%',
      marginBottom: 5,
      fontSize: '14px',
    },
    '@media only screen and (max-width: 361px)': {
      width: '90%',
      marginBottom: 5,
      fontSize: '12px',
    },
    '& *': {
      color: '#fff !important',
    },
  },
  title: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontFamily: 'Poppins',
    '@media only screen and (max-width: 600px)': {
      fontSize: '20px',
      marginTop: 10,
      marginBottom: 5,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  img: {
    borderRadius: '10px',
    width: 275,
    height: 275,
    '@media only screen and (max-width: 600px)': {
      width: 125,
      height: 125,
    },
    '@media only screen and (max-width: 361px)': {
      width: 100,
      height: 100,
    },
  },
  textH3: {
    width: '100%',
    color: 'white',
    fontFamily: 'Poppins',
    marginBottom: 30,
    textAlign: 'left',
    fontSize: '20px',
    textTransform: 'none',
    '@media only screen and (max-width: 600px)': {
      fontSize: '15px',
      marginBottom: 10,
      marginTop: 45,
    },
  },
  pie: {
    width: 300,
    height: 300,
    '@media only screen and (max-width: 600px)': {
      width: 250,
      height: 250,
    },
  },
  textH2: {
    width: '100%',
    color: 'white',
    fontFamily: 'Poppins',
    marginBottom: 5,
    textAlign: 'center',
    fontSize: '28px',
    textTransform: 'none',
    '@media only screen and (max-width: 600px)': {
      fontSize: '24px',
      marginBottom: 5,
      marginTop: 40,
    },
  },
  bodycenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 8,
  },
  flexData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media only screen and (max-width: 600px)': {
      justifyContent: 'flex-start',
      marginLeft: 25,
    },
  },
  restitutionPageTitle: {
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#fff',
    textTransform: 'uppercase',
  },
  shareLabelText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#fff',
  },
  studyRestitutionShareButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  studyRestitutionShareButton: {
    color: theme.palette.primary.main,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#fff',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 600,
    width: 'fit-content',
    height: 30,
    marginTop: -6,
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
      border: `1px solid #fff`,
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: '#fff',
      border: `1px solid #fff`,
    },
  },
  shareIconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  socialMediaIconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  resultDetailsContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 25,
      marginRight: 25,
    },
  },
}));

export default useStyles;
