/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './style';


const Explication: FC = () => {

    const classe = useStyles();

    return (

        <div className={classe.contentPreference}>

            <div style={{ maxWidth: '341px' }}>

                <Typography
                    className={classe.title}
                    component='h2'
                >
                    VOS 5 PRÉFÉRENCES
                </Typography>

                <Typography
                    component='p'
                    className={classe.description}
                >
                    Voici vos cinq préférences dans votre personnalité profonde. La personnalité profonde correspond à votre manière de penser et de se comporter quand vous n’êtes soumis(e) à aucune contrainte. L’objectif est de trouver un métier et un environnement professionnel qui permettent d’exprimer vos préférences profondes. Bref, qui vous permettent d’être vous-même. Cela est aussi vrai dans votre vie personnelle.
                </Typography>

            </div>

        </div>

    );
};

export default Explication; 