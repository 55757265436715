import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      color: 'black',
      fontSize: 22,
      [theme.breakpoints.down('lg')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    smallScreenTitle: {
      alignSelf: 'flex-start',
      marginTop: 25,
      marginLeft: 25,
    },
    contentNoPadding: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'nowrap',
      },
    },
    contentNoPaddingNotReversed: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    padding25: {
      padding: '25px',
    },
    content2: {
      padding: 25,
      height: '100%',
    },
    description1: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      color: '#242424',
      [theme.breakpoints.down('md')]: {
        fontSize: 13,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    description2: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 13,
      color: '#242424',
      [theme.breakpoints.down('md')]: {
        fontSize: 13,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 13,
      },
    },
    imgPyramid: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '140%',
      },
    },
    pyramidContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paragraphMarginTop: {
      marginTop: 25,
      [theme.breakpoints.down('lg')]: {
        marginTop: 9,
      },
    },
    favoriteLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      [theme.breakpoints.down('lg')]: {
        fontSize: 13,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 13,
      },
      marginTop: 13,
    },
    needPyramidBlockContainer: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      marginTop: 40,
      borderRadius: 16,
      margin: 'auto',
      [theme.breakpoints.up('lg')]: {
        maxHeight: '100vh',
      },
    },
    alignTextRight: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    marginTop25: {
      marginTop: 36,
    },
  }),
);
