/* eslint-disable no-use-before-define */
import { Page, Paragrapth, TitleNbr, ArrayYouContext } from '@orientaction/components';
import { paragraph, list } from './context';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';

const Page13 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={13}>

            <MarginContainer>
                <TitleNbr number={2} text='LES ÉVOLUTIONS HORIZONTALES' />
            </MarginContainer>

            {
                paragraph.map((item: any) =>
                    <MarginContainer>
                        <Paragrapth align='justify'>
                            {item || ''}
                        </Paragrapth>
                    </MarginContainer>
                )
            }

            <MarginContainer>

                <MarginContainer>
                    <Paragrapth
                        align='center'
                        bold={true}
                        colorBlue={true}
                    >
                        Dans quels cas choisir une évolution interne horizontale :
                    </Paragrapth>
                </MarginContainer>

                <ArrayYouContext objectList={list} />

            </MarginContainer>


        </Page >
    );

};

export default Page13;
