import { createContext, useState } from 'react';

const initialState = {
  test: {
    id: '',
    title: '',
    description: '',
    image: '',
    stockageMode: { value: 1, label: 'Données stockées' },
    visibilityPermission: null,
    enterpriseSelected: null,
    isAllEnterpriseSelected: false,
    time: '',
  },
  variables: [
    { id: '', variableKey: 'A', name: '', description: '', image: '' },
    { id: '', variableKey: 'B', name: '', description: '', image: '' },
  ],
  egalityVariable: { id: '', name: '', description: '', image: '' },
  questions: [],
  toDeleteQuestions: [],
  finalText: { id: '', title: '', description: '' },
  isDraft: false,
  company: 1,
  category: '',
  manageError: {},
};

// const state = {
//   test: { title: 'mlay', description: 'mlay ty', image: '' },
//   variables: [
//     { key: 'A', name: 'Var X', description: 'Desc var X', image: '' },
//     { key: 'B', name: 'Var Y', description: 'Desc var Y', image: '' },
//   ],
//   egalityVariable: { name: 'Var Z', description: 'Desc var Z', image: '' },
//   questions: [
//     {
//       title: 'Why?',
//       aResponse: { title: 'Because of this', variable: 'Var X', variableValue: 75 },
//       bResponse: { title: 'Because of that', variable: 'Var Y', variableValue: 90 },
//     },
//   ],
//   finalText: { title: 'Mlay final text', description: 'desc mlay final text' },
//   isDraft: false,
// };

export const TestContext = createContext(initialState) as any;

export const TestProvider = ({ children }: any) => {
  const [test, setTest] = useState(initialState);

  const updateTest = async (value: any) => {
    setTest(value);
  };

  const resetTest = () => {
    setTest(test);
  };

  return (
    <TestContext.Provider value={{ test, updateTest, resetTest }}>{children}</TestContext.Provider>
  );
};
