import style from './style';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

const FooterEbook = (props: any) => {
  const cls = style();
  const { ebookName, toBack } = props;
  const history = useHistory();

  return (
    <div className={cls.container}>
      <div>
        <div className={cls.text}>
          <span>
            Félicitations,
            <br />
            vous venez de terminer {ebookName} !
          </span>
        </div>
        <Button variant="contained" size='large' className={cls.fixed} onClick={() => history.push(toBack)}>
          <span style={{ color: "white" }}>Retour à mes ressources</span>
        </Button>
      </div>
    </div>
  );
};

export default FooterEbook;
