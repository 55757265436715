import { FC } from 'react';

interface IProps {
  description: string;
}

const Details: FC<IProps> = ({ description }) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: description,
      }}
    />
  );
};

export default Details;
