import { createStyles } from '@material-ui/core/styles';

const style = (theme: any) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#0E1247',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#0E1247',
    },
    colorStandar: {
      color: '#0E1247',
    },
  });

export default style;
