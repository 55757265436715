// eslint-disable-next-line no-use-before-define
import 'swiper/swiper.min.css';
import { Grid } from '@material-ui/core';
import { LayoutTestSoftSkills } from '@orientaction/layouts';
import { getFromLS } from '@orientaction/utils';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { publicSvgPath } from '../../utils/constants';
import { useStyles } from './style';
import { useLanguage } from '@orientaction/hooks';

const TestSoftSkills = () => {
  const history = useHistory();
  const classes = useStyles();
  const token = getFromLS('userToken') || '';
  const userConnected = JSON.parse(getFromLS('userInfos') || '{}');
  const canViewTest = token?.length > 0 && userConnected?.id;
  if (!canViewTest) {
    history.push('/');
  }
  const { language } = useLanguage();

  useEffect(() => {
    if (canViewTest) {
      (window as any).chatboxSettings = {
        appKey: 'e9f7a4c2-2e1e-4f8d-bf68-a2d64a096819',
        websiteId: language === 'GB' ? 'M9tCa2leZefflu02B' : 'WNKgbi9d3eck8dthie6lUke',
        preventMessengerPreload: true,
        language: 'fr',
        autoStart: true,
        ref: 'restart',
        // Component behaviour
        // autoStart: true | false // Open and start automatically the conversation (default : false)
        // User datas
        // userId: 'UNIQUE USER ID',
        // firstName: 'USER FIRSTNAME',
        // lastName: 'USER LASTNAME',
        // gender: 'M | F',
        // You can specify a target sequence here :
        // ref: '<sequence id> OR "restart" to restart the welcome sequence',
      };

      // eslint-disable-next-line func-names
      (function (d, s, id) {
        // eslint-disable-next-line one-var
        let js: any = d.getElementsByTagName(s)[0];
        const fjs: any = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }

        js = d.createElement(s);
        js.id = id;
        js.src = 'https://cbassets.botnation.ai/js/widget.js';
        js.async = true;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'chatbox-jssdk');
    }
  }, [canViewTest, language]);

  return (
    <HelmetProvider>
      <LayoutTestSoftSkills isGradient={true}>
        <div className={classes.paddingContent}>
          <Grid container={true} style={{ height: '100%' }}>
            <Grid
              className={classes.flexCenterStart}
              item={true}
              xs={4}
              style={{ textAlign: 'center', position: 'relative', height: '100%' }}>
              <img
                className={classes.absoluteTop}
                src={`${publicSvgPath}/logoDesktop.svg`}
                alt="logo"
              />
              <img className={classes.img} src={`${publicSvgPath}/agence.png`} alt="logo" />
            </Grid>

            <Grid item={true} xs={4} />
            {/* <script src="/script/scriptbot.js" type="text/javascript" />
              <Helmet>
                <script src="/script/scriptbot.js" type="text/javascript" />
              </Helmet> */}

            <Grid
              className={classes.flexCenterEnd}
              item={true}
              xs={4}
              style={{ textAlign: 'center' }}>
              <img className={classes.img} src={`${publicSvgPath}/entretien.png`} alt="logo" />
            </Grid>
          </Grid>
        </div>
      </LayoutTestSoftSkills>
    </HelmetProvider>
  );
};
export default TestSoftSkills;
