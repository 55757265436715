/* eslint-disable no-use-before-define */
import useStyles from './style';
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import { FC } from 'react';
import parse from 'html-react-parser';

interface IBorderArray {
    title?: string;
    paragraph?: string;
    handleChange: (e: any) => void;
    name?: string;
    defaultValue: string;
    listForm?: any[];
    largeTextField?: boolean;
    readOnly?: boolean;
}

const BorderArray: FC<IBorderArray> = ({
    handleChange,
    name,
    defaultValue,
    title,
    paragraph,
    listForm,
    largeTextField,
    readOnly
}) => {
    const classes = useStyles();

    return (
        <div className={classes.border}>

            {
                title && (
                    <div
                        className={classes.titlePage}
                    >
                        {title}
                    </div>
                )
            }


            {
                paragraph && (
                    <MarginContainer>
                        <Paragrapth align='center'>
                            {parse(paragraph)}
                        </Paragrapth>
                    </MarginContainer>
                )
            }

            {
                name && (
                    <MarginContainer>
                        <div
                            className={classes.content}
                        >
                            <TextArea
                                name={name}
                                value={defaultValue || ""}
                                onChange={handleChange}
                                largeTextField={largeTextField}
                                readOnly={readOnly}
                            />
                        </div>

                    </MarginContainer>
                )
            }

            {
                listForm && (

                    <MarginContainer>

                        <div className={classes.content}>

                            {
                                listForm.map((item: any) => (
                                    <>

                                        <MarginContainer>

                                            <MarginContainer>
                                                <Paragrapth align='start'>
                                                    {parse(item.title)}
                                                </Paragrapth>
                                            </MarginContainer>

                                            <TextArea
                                                name={item.name}
                                                value={defaultValue?.[item.name] || ""}
                                                onChange={handleChange}
                                                readOnly={readOnly}
                                            />

                                        </MarginContainer>

                                    </>
                                ))
                            }

                        </div>

                    </MarginContainer>

                )
            }

        </div >
    );
};

export default BorderArray;
