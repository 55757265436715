/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
export const standartCouloms = [
  {
    type: 'normal',
    header: [
      {
        label: 'Nom',
        key: 'name',
        width: '15vw',
      },
      {
        label: 'Coordonnées',
        key: 'address',
        width: '15vw',
      },
      {
        label: 'contact',
        key: 'contact',
        width: '15vw',
      },
      {
        label: 'Téléphone',
        key: 'phone',
        width: '15vw',
      },
      {
        label: 'Date de création',
        key: 'createdAt',
        width: '15vw',
      },
    ],
  },
  {
    type: 'action',
    header: [
      {
        label: 'Actions',
        width: '15vw',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['disable', 'edit', 'delete'],
        },
      },
    ],
  },
];
