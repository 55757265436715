// eslint-disable-next-line simple-import-sort/imports
import Grid from '@material-ui/core/Grid';
import { MarginContainer } from '@orientaction/commons';
import {
  TitlePage,
  Page,
  TitleChapitre,
  Paragrapth,
  Title,
  ListParagrapth,
} from '@orientaction/components';
import BlockWithBackground from '../../components/BlockWithBackground';
import Img from '../../components/Image';
import useStyles from './style';

const Page43 = () => {
  const classes = useStyles();
  return (
    <Page id={43}>
      <BlockWithBackground>
        <Paragrapth align="justify" addBackground={true}>
          Pour synthétiser l’ensemble de vos compétences, le CV reste aujourd’hui encore le
          meilleur outil de communication.
        </Paragrapth>
        <MarginContainer>
          <Paragrapth align="justify" addBackground={true}>
            Que vous souhaitiez évoluer en interne, changer de métier, changer d’employeur(se) ou
            créer/reprendre une entreprise, mettre à jour votre CV vous permettra de valoriser vos
            compétences et votre parcours professionnel.
          </Paragrapth>
        </MarginContainer>
        <Paragrapth align="justify" addBackground={true}>
          Votre consultant(e) ORIENTACTION peut vous accompagner dans cette démarche en vous
          conseillant sur les éléments à y faire figurer, en vous fournissant un modèle ou même en
          vous assistant concrètement dans la création de votre CV si vous ne maîtrisez pas
          parfaitement les logiciels de traitement de texte.
        </Paragrapth>
      </BlockWithBackground>
      <MarginContainer>
        <Img filename="poignet.png" alt="poignet" className={classes.imgTronc} />
      </MarginContainer>
    </Page>
  );
};

export default Page43;
