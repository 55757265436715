/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import { Button, Card, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { appLanguage } from '@orientaction/reducers';
import { blockRedirection, publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useStyles } from './style';
import { useState } from 'react';
import SimpleModal from '../SimpleModal';

const CardGoFar = (props: any) => {
  const { handleContactClick, isSoftSkills } = props;
  const classes = useStyles();
  const lang: string = useSelector(appLanguage);
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className={classes.cardBord}>
      <Typography variant="h2" color="textPrimary" className={classes.titleContent0}>
        Pour aller plus loin
      </Typography>
      <Grid container={true} spacing={2} className={classes.paddingCard}>
        <Grid item={true} md={6} className={classes.paddingCard1}>
          <Typography variant="h2" color="textPrimary" className={classes.titleContent}>
            Acheter le jeu de cartes des valeurs
          </Typography>
          <img
            src={`${publicSvgPath}/${lang === 'FR' ? 'jeu_cartes.png' : 'en_jeu-cartes.png'}`}
            alt=""
            className={classes.imgCard}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.description}>
            Découvrez le jeu de cartes des valeurs. <br />
            Il permet d’identifier ses valeurs profondes ou celles <br />
            de ses proches.
          </Typography>
          <Button
            onClick={() =>
              blockRedirection({
                language: lang,
                onOpen: () => setOpenModal(true),
                functionToPass: () => {
                  window.open('https://orientaction.kneo.me/shop/view/15FB21', '_blank');
                },
              })
            }
            className={classes.btnCard}
            style={{ background: '#E63C3C', color: 'white', padding: '8.5px 20px 10px' }}>
            Acheter le jeu
          </Button>
        </Grid>
        <Grid item={true} md={6}>
          <Card className={classes.cardRed}>
            <div className={classes.cardTopRed} />
            <div className={classes.paddingCard2}>
              <img
                src={`${publicSvgPath}/picto_conversation.svg`}
                alt=""
                className={clsx(classes.imgCard, classes.imgCardMobile)}
                style={{ width: '15%', marginTop: 60 }}
              />
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ fontSize: 19 }}>
                CONTACTEZ NOTRE SERVICE <br />
                DE FORMATION
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.description}
                style={{ marginTop: 10 }}>
                Pour organiser un atelier ou une formation <br />
                sur les valeurs dans votre entreprise
              </Typography>
              <Button
                onClick={handleContactClick}
                className={classes.btnCard}
                style={{ background: 'white', color: 'black' }}>
                Nous contacter
              </Button>
            </div>
          </Card>
        </Grid>
        {/* <Grid item={true} md={6}></Grid> */}
      </Grid>
      <SimpleModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Information"
        message="Not yet available in English – work in progress."
      />
    </div>
  );
};

CardGoFar.defaultProps = {
  isSoftSkills: false,
};

export default CardGoFar;
