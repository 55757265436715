/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, Title } from '@orientaction/components';

const Page24 = () => {
  return (
    <Page id={24}>
      <Title
        title="LE CAPITAL CULTUREL"
        firstTitle="‘‘ Enseigner, c’est apprendre deux fois. ’’"
        firtsecondTitle="Joseph Joubert"
        secondTitle="‘‘Celui qui ouvre une école ferme une prison. ’’"
        secondSousTitle="Victor Hugo"
      />

      <MarginContainer>
        <Paragrapth align="justify">
          Le capital culturel correspond à nos connaissances et à notre aptitude à les mobiliser.
          Cela va de la connaissance d’une matière scientifique à un savoir-faire technique très
          spécialisé, jusqu’à l’aptitude à discuter, négocier, partager avec des personnes issues de
          cultures nationales, organisationnelles ou professionnelles très différentes.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Une très grande partie de notre capital culturel nous est transmise par l’école, une autre
          partie par les rencontres que nous faisons. Le reste s’acquiert au travers des expériences
          que nous réalisons.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Par exemple, le fait d’avoir voyagé dans plusieurs pays étrangers, d’avoir connu plusieurs
          milieux professionnels spécifiques (industrie, informatique, communication etc.), d’avoir
          étudié des matières rares ou spécifiques dans le cadre de votre formation initiale ou
          continue constitue des éléments importants de votre capital culturel.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Ces éléments sont susceptibles à un moment donné de vous servir et d’être utilisés de
          façon stratégique pour réussir votre évolution professionnelle. Il est donc important de
          bien les connaître.
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

export default Page24;
