/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, ArrayYouContext } from '@orientaction/components';
import { list } from './context';
import useStyles from './style';

const Page40 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={40}>

            <MarginContainer>
                <MarginContainer>
                    <Paragrapth
                        align='center'
                        bold={true}
                        colorBlue={true}
                    >
                        QUAND ET COMMENT SAVOIR S’IL NE FAUT PAS CHANGER :
                    </Paragrapth>
                </MarginContainer>

                <MarginContainer>
                    <ArrayYouContext objectList={list} />
                </MarginContainer>
            </MarginContainer>

        </Page>
    );
};

export default Page40;
