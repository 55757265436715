import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginBottom: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  btnPrimaryContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 5,
  },
  btnPrimary: {
    minHeight: 48,
    width: 190,
    textTransform: 'none',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
  },
  h3: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 900,
    color: '#0E1247',
    marginLeft: 10,
  },
}));
