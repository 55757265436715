import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '85%',
      margin: '0 auto',
      [theme.breakpoints.down(500)]: {
        width: '95%',
      },
    },
    contentLoader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
    btnHistory: {
      position: 'fixed',
      top: '13vh',
      right: 25,
      zIndex: 20,
      [theme.breakpoints.down(800)]: {
        top: 'auto !important',
        bottom: '20px',
        position: 'fixed',
      },
    },
  }),
);
