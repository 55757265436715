/* eslint-disable react-hooks/exhaustive-deps */
import withWidth from '@material-ui/core/withWidth';
import { Layout } from '@orientaction/components';
import { PublicStudyLayout } from '@orientaction/components/Layout';
import { useLocation } from 'react-router-dom';
import StudyTestResult from './StudyTestResult';

const ResultatStudy = () => {
  const location = useLocation();

  const isPublic = location.pathname.startsWith('/mon-resultat-du-test-study');

  return (
    <>
      {isPublic ? (
        <PublicStudyLayout isaddBackground={true} noPadding={true} isNew={true} footer={true}>
          <StudyTestResult isPublic={isPublic} />
        </PublicStudyLayout>
      ) : (
        <Layout isaddBackground={true} noPadding={true} isNew={true} footer={true}>
          <StudyTestResult isPublic={isPublic} />
        </Layout>
      )}
    </>
  );
};

export default withWidth()(ResultatStudy);
