/* eslint-disable import/no-unresolved */
import { Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import { publicImgMajorTest } from '@orientaction/utils';
import { useStyles } from './style';

const HeaderHarmony = () => {
  const classe = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div className={classe.header}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} md={6} className={classe.displayFlexAlignCenter}>
          <Typography variant="h2" className={classe.titleHeader}>
            Harmony®, <br />
            test des {isTab ? <br /> : ''} 16 soft skills
          </Typography>
        </Grid>
        <Grid item={true} md={3} className={classe.imageCenterMobile}>
          <img
            src={`${publicImgMajorTest}/banniere_harmony.png`}
            alt="couverture intro"
            className={classe.imageBtn}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HeaderHarmony;
