import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  dropzoneContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'start',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: 12,
    color: '#0E1247',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  inputError: {
    border: `2px solid red`,
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 200,
    height: 133,
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
}));
