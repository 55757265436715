/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { book5Action } from '@orientaction/api-actions';
import { appBook5 } from '@orientaction/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IBook5 } from "../../store/types/Book/Book5";
import { BookTemplate } from '@orientaction/services';
import { getFromLS } from '@orientaction/utils';

const useBook5 = () => {

    const dispatch = useDispatch();

    const book: IBook5 = useSelector(appBook5);

    const token = getFromLS('userToken') || '';

    const updateIndoorWeather = async (nbr: number) => {
        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            indoorWeather: nbr,
            query: "api::fifth-ebook.fifth-ebook"
        });
        dispatch(book5Action.setIndoorWeather(nbr));
    }

    const setExperience = async (data: any) => {

        dispatch(book5Action.setExperience(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            experience: data,
            query: "api::fifth-ebook.fifth-ebook"
        });

    }

    const setReadyToSucess = async (data: any) => {
        dispatch(book5Action.setReadyToSucess(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            readyToSuccess: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setPlanB = async (data: any) => {
        dispatch(book5Action.setPlanB(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            planB: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setWhenProjectStart = async (data: any) => {
        dispatch(book5Action.setWhenProjectStart(data));
        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            whenProjectStart: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setIfProjectFinish = async (data: any) => {
        dispatch(book5Action.setIfProjectFinish(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            ifProjectFinish: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setUncertainy = async (data: any) => {
        dispatch(book5Action.setUncertainy(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            uncertainy: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setFullFillingYourDream = async (data: any) => {
        dispatch(book5Action.setFullFillingYourDream(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            fullFillingYourDream: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setId = (data: number) => {
        dispatch(book5Action.setId(data));
    }

    const setPlanProject = async (data: any) => {
        dispatch(book5Action.setPlanProject(data));
        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            planProject: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setProjectProffessionalAndAspiration = async (data: any) => {
        dispatch(book5Action.setProjectProffessionalAndAspiration(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            projectProffessionalAndAspiration: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setFourPreferenceOfYourPersonnality = async (data: any) => {
        dispatch(book5Action.setFourPreferenceOfYourPersonnality(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            fourPreferenceOfYourPersonnality: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setThreeNeedAnswerThisProject = async (data: any) => {
        dispatch(book5Action.setThreeNeedAnswerThisProject(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            threeNeedAnswerThisProject: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const setYourPersonnalProjectAndSkill = async (data: any) => {
        dispatch(book5Action.setYourPersonnalProjectAndSkill(data));

        const bookUpdated = await BookTemplate.updateBook(token, {
            id: book.id,
            yourPersonnalProjectAndSkill: data,
            query: "api::fifth-ebook.fifth-ebook"
        });
    }

    const getMyValueBook = async (userId: any) => {
        return BookTemplate.getMyValueBook(token, {
            id: userId,
            query: 'api::fifth-ebook.fifth-ebook'
        });
    }

    const initValue = (data: any) => {
        dispatch(book5Action.setIndoorWeather(data.indoorWeather || 0))
        dispatch(book5Action.setExperience(data.experience || {}))
        dispatch(book5Action.setPlanB(data.planB || {}))
        dispatch(book5Action.setReadyToSucess(data.readyToSuccess || {}))
        dispatch(book5Action.setWhenProjectStart(data.whenProjectStart || {}))
        dispatch(book5Action.setIfProjectFinish(data.ifProjectFinish || {}))
        dispatch(book5Action.setUncertainy(data.uncertainy || {}))
        dispatch(book5Action.setFullFillingYourDream(data.fullFillingYourDream || {}))
        dispatch(book5Action.setPlanProject(data.planProject || {}))
        dispatch(book5Action.setProjectProffessionalAndAspiration(data.projectProffessionalAndAspiration || {}))
        dispatch(book5Action.setFourPreferenceOfYourPersonnality(data.fourPreferenceOfYourPersonnality || {}))
        dispatch(book5Action.setThreeNeedAnswerThisProject(data.threeNeedAnswerThisProject || {}))
    }

    return {
        ...book,
        setId,
        setPlanB,
        initValue,
        setExperience,
        setUncertainy,
        setPlanProject,
        getMyValueBook,
        setReadyToSucess,
        setIfProjectFinish,
        updateIndoorWeather,
        setWhenProjectStart,
        setFullFillingYourDream,
        setThreeNeedAnswerThisProject,
        setYourPersonnalProjectAndSkill,
        setFourPreferenceOfYourPersonnality,
        setProjectProffessionalAndAspiration
    };

};

export default useBook5;
