import { PublicLayout } from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useQueryString from '../../hooks/useQueryString';
import AuthDialog from './Dialog';
import Harmony from './Harmony';
import LoginForm, { getRedirectPathByCurrentPahname } from './Login';
import MeaningValue from './MeaningValue';
import NeedsPro from './NeedsPro';
import Preference from './Preference';
import SignupContainer from './Signup';
import Study from './Study';

const getFakeTestBackground = (params: string) => {
  let background;
  switch (params) {
    case '/resultat-test-besoins-pro':
      background = <NeedsPro />;
      break;
    case '/resultat-test-besoins':
      background = <NeedsPro />;
      break;
    case '/resultat-test-sens-valeurs':
      background = <MeaningValue />;
      break;
    case '/resultat-test-preferences':
      background = <Preference />;
      break;
    case '/resultat-test-harmony':
      background = <Harmony />;
      break;
    case '/resultat-test-study':
      background = <Study />;
      break;

    default:
      background = <NeedsPro />;
      break;
  }
  return background;
};

const CustomAuth = () => {
  const [showAccountConfirm, setShowAccountConfirm] = useState<boolean>(false);
  const queryString = useQueryString();
  const isSignupPage = queryString.get('q') === 'signup';
  const { pathname } = useLocation();
  const history = useHistory();
  const background = getFakeTestBackground(pathname);

  const token = getFromLS('userToken') as string;
  if (token?.trim().length > 0) {
    const redirectPath = getRedirectPathByCurrentPahname(pathname);
    history.push(redirectPath);
  }

  const handleAccountConfirm = (show: boolean) => {
    setShowAccountConfirm(show);
  };
  return (
    <PublicLayout isaddBackground={true} noPadding={true} isNew={true} footer={true}>
      {background}
      {isSignupPage ? (
        <SignupContainer
          handleAccountConfirm={handleAccountConfirm}
          showAccountConfirm={showAccountConfirm}
        />
      ) : (
        <AuthDialog title="Connectez-vous pour accéder à vos résultats">
          <LoginForm signupLink={`${pathname}?q=signup&s=1`} />
        </AuthDialog>
      )}
    </PublicLayout>
  );
};

export default CustomAuth;
