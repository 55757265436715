import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  number1: {
    color: 'white',
    background: '#fff350',
    minWidth: '30px',
    borderRadius: '30px',
    height: '30px',
    border: '3px solid #fff350',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  h3: {
    color: '#2B56A5',
    fontSize: 18,
    fontWeight: 600,
    margin: '0 0.75vh',
  },
  noMarginLeft: {
    marginLeft: '0px !important',
  },
  notBold: {
    fontFamily: 'ITC Avant Garde Gothic Std Medium',
    color: '#2B56A5',
    fontSize: 15,
    fontWeight: 100,
    margin: '0 0.75vh',
  },
  content: {
    width: '100%',
    margin: '0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
  },
  small: {
    fontSize: '15px !important',
  },
  italic: {
    fontStyle: 'italic',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBlack: {
    color: 'black',
  },
  moreSpace: {
    margin: '0 1.5vh',
  },
}));
