/* eslint-disable no-use-before-define */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Layout, ResultDetail, Timeline } from '@orientaction/components';
import { ResultInterface } from '@orientaction/interfaces';
import clsx from 'clsx';
import React, { FC, ReactNode, useState } from 'react';
import { publicIconPath } from '../../../utils/constants';
import { UserResultRadarGraph } from '../UserResultRadarGraph';
import localText from './locale.json';

type WithResultProps = {
  results: ResultInterface.IResultFilter[];
  resultToShow: ResultInterface.IResultFilter | null;
  onSelectPeriod: (value: number) => void;
};

const WithResult: FC<WithResultProps> = ({ results, resultToShow, onSelectPeriod }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainResult}>
      <h1 className={classes.titlemob}>Vos résultats - test Harmony 16 soft skills</h1>
      <Timeline testResults={results} onChange={onSelectPeriod} />
      <div className={classes.container}>
        <h1 className={classes.title}>Vos résultats - test Harmony 16 soft skills</h1>

        <div className={classes.content}>
          <div className={classes.graphe}>
            <div className={classes.grapheDetail}>
              <UserResultRadarGraph resultToShow={resultToShow} />
            </div>
            <div className={classes.legends}>
              <span className={classes.lineLegend} /> Moyenne
            </div>
          </div>
          <div className={classes.details}>
            <ResultDetail
              imageSrc={localText.problem_solving.imageSrc}
              title={localText.problem_solving.title}
              color={localText.problem_solving.color}
              note={resultToShow?.problem_solving || 0}
              description={localText.problem_solving.description}
              decisiveProfessions={localText.problem_solving.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.confidence.imageSrc}
              title={localText.confidence.title}
              color={localText.confidence.color}
              note={resultToShow?.confidence || 0}
              description={localText.confidence.description}
              decisiveProfessions={localText.confidence.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.emotional_intelligence.imageSrc}
              title={localText.emotional_intelligence.title}
              color={localText.emotional_intelligence.color}
              note={resultToShow?.emotional_intelligence || 0}
              description={localText.emotional_intelligence.description}
              decisiveProfessions={localText.emotional_intelligence.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.empathy.imageSrc}
              title={localText.empathy.title}
              color={localText.empathy.color}
              note={resultToShow?.empathy || 0}
              description={localText.empathy.description}
              decisiveProfessions={localText.empathy.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.communication.imageSrc}
              title={localText.communication.title}
              color={localText.communication.color}
              note={resultToShow?.communication || 0}
              description={localText.communication.description}
              decisiveProfessions={localText.communication.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.time_management.imageSrc}
              title={localText.time_management.title}
              color={localText.time_management.color}
              note={resultToShow?.time_management || 0}
              description={localText.time_management.description}
              decisiveProfessions={localText.time_management.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.stress_management.imageSrc}
              title={localText.stress_management.title}
              color={localText.stress_management.color}
              note={resultToShow?.stress_management || 0}
              description={localText.stress_management.description}
              decisiveProfessions={localText.stress_management.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.creativity.imageSrc}
              title={localText.creativity.title}
              color={localText.creativity.color}
              note={resultToShow?.creativity || 0}
              description={localText.creativity.description}
              decisiveProfessions={localText.creativity.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.entrepreneurial_spirit.imageSrc}
              title={localText.entrepreneurial_spirit.title}
              color={localText.entrepreneurial_spirit.color}
              note={resultToShow?.entrepreneurial_spirit || 0}
              description={localText.entrepreneurial_spirit.description}
              decisiveProfessions={localText.entrepreneurial_spirit.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.daring.imageSrc}
              title={localText.daring.title}
              color={localText.daring.color}
              note={resultToShow?.daring || 0}
              description={localText.daring.description}
              decisiveProfessions={localText.daring.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.motivation.imageSrc}
              title={localText.motivation.title}
              color={localText.motivation.color}
              note={resultToShow?.motivation || 0}
              description={localText.motivation.description}
              decisiveProfessions={localText.motivation.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.vision.imageSrc}
              title={localText.vision.title}
              color={localText.vision.color}
              note={resultToShow?.vision || 0}
              description={localText.vision.description}
              decisiveProfessions={localText.vision.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.presence.imageSrc}
              title={localText.presence.title}
              color={localText.presence.color}
              note={resultToShow?.presence || 0}
              description={localText.presence.description}
              decisiveProfessions={localText.presence.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.collective_sense.imageSrc}
              title={localText.collective_sense.title}
              color={localText.collective_sense.color}
              note={resultToShow?.collective_sense || 0}
              description={localText.collective_sense.description}
              decisiveProfessions={localText.collective_sense.decisiveProfessions}
            />
            <ResultDetail
              imageSrc={localText.curiousity.imageSrc}
              title={localText.curiousity.title}
              color={localText.curiousity.color}
              note={resultToShow?.curiousity || 0}
              description={localText.curiousity.description}
              decisiveProfessions={localText.curiousity.decisiveProfessions}
            />
            <ResultDetail
              className={classes.criticalMindCustomStyle}
              imageSrc={localText.critical_mind.imageSrc}
              title={localText.critical_mind.title}
              color={localText.critical_mind.color}
              note={resultToShow?.critical_mind || 0}
              description={localText.critical_mind.description}
              decisiveProfessions={localText.critical_mind.decisiveProfessions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainResult: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      // height: 'calc(100vh - 90px)',
      '@media(min-width: 1250px)': {
        flexDirection: 'row',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      '@media(min-width: 1250px)': {
        flexDirection: 'row',
        marginTop: 20,
      }
    },
    titlemob: {
      fontSize: 23,
      color: '#000A8C',
      margin: 0,
      marginBottom: 12,
      '@media(min-width: 1250px)': {
        display: 'none',
      },
    },
    title: {
      display: 'none',
      color: '#000A8C',
      margin: 0,
      '@media(min-width: 1250px)': {
        fontSize: 36,
        display: 'block',
      },
    },
    container: {
      width: '100%',
      '@media(min-width: 1250px)': {
        padding: '56px 30px 30px 30px',
      },
    },
    graphe: {
      height: 'fit-content',
      marginTop: 5,
      padding: 10,
      flex: 0.7,
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: 20,
      '@media(min-width: 1250px)': {
        marginTop: 0,
      },
    },
    grapheDetail: {
      margin: 'auto',
      '@media(min-width: 1250px)': {
        padding: '10px 30px 30px 30px',
        overflow: 'auto'
      },
      maxWidth: '70%'
    },
    legends: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media(min-width: 1250px)': {
        justifyContent: 'flex-start',
      },
    },
    lineLegend: {
      width: 32,
      display: 'block',
      background: '#000A8C',
      marginRight: 8,
      border: '2px solid #000A8C',
      borderRadius: 10,
    },
    details: {
      marginTop: 20,
      marginBottom: 20,
      flex: 0.3,
      height: '80vh',
      '@media(min-width: 1250px)': {
        marginBottom: 0,
        marginTop: 0,
        paddingLeft: 15,
        overflow: 'auto'
      },
      overflow: 'auto'
    },
    criticalMindCustomStyle: {
      marginBottom: 75
    }
  })
);
export default WithResult;
