// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    Page,
} from '@orientaction/components';
import useStyles from "../../style";
import Img from '../../Img';

const Page05 = (props: any) => {

    const classe = useStyles();

    return (
        <Page
            id={5}
        >

            <div className={classe.titleSteveJobs}>
                <i>
                    « Chaque homme doit inventer son chemin. »
                </i>
            </div>

            <div style={{
                width: "7%",
                height: 10,
                backgroundColor: "yellow",
                margin: "5vh auto",
                color: "yellow"
            }}>-</div>

            <div  >
                <Paragrapth colorBlue={true} align="center">Jean-Paul Sartre</Paragrapth>
            </div>

            <Img
                src='Chaque_homme_doit_inventer_son_chemin.jpg'
                alt='Chaque homme doit inventer son chemin.'
                style={{
                    width: "85%",
                }}
            />

        </Page>
    );
};
export default Page05;
