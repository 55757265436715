/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { useStyles } from './style';

interface IDescription {
    children: any;
}

const Description: FC<IDescription> = ({ children }) => {

    const classe = useStyles();

    return (
        <Typography
            className={classe.description}
        >
            {children}
        </Typography>
    );
};

export default Description;
