/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitleNbr, Page } from '@orientaction/components';
import useStyles from '../../style';
import { useBook1 } from '@orientaction/hooks_book';
import { debounce } from 'lodash';

let typeTimeout: any;

const Page14 = (props: any) => {
  const classe = useStyles();

  const { book, updateMeanToYou } = useBook1();

  const listFormualaire = [
    {
      title: 'Que signifie pour vous « Réussir DANS la vie » ?',
      name: 'BeSuccessfulInLife',
      number: 1,
    },
    {
      title: 'Que signifie pour vous « Réussir SA vie » ?',
      name: 'SucceedInLife',
      number: 2,
    },
    {
      title: 'En toute honnêteté, quel est le plus important des deux pour vous aujourd’hui ?',
      name: 'mostImportant',
      number: 3,
    },
  ];
  const updateValue = debounce(event => {
    handleChangeGlobalState(event);
  }, 1000);

  const onKeyDown = (event: any) => {
    clearTimeout(typeTimeout);
    updateValue(event)
  };

  const handleChange = (e: any) => {
    if (props.readOnly) return;
    updateValue(e)
  };

  const handleChangeGlobalState = (e: any) => {
    if (props.readOnly) return;
    const { name, value } = e.target;

    updateMeanToYou({
      ...book.meanToYou,
      [name]: value,
    });
  };

  return (
    <Page id={14} readOnly={false}>
      <div>
        <Paragrapth align="justify">
          Réussir{' '}
          <Paragrapth align="justify" colorBlue={true} bold={true}>
            DANS
          </Paragrapth>{' '}
          la vie et réussir{' '}
          <Paragrapth align="justify" colorBlue={true} bold={true}>
            SA
          </Paragrapth>{' '}
          vie sont deux chemins qui permettent d’accéder à une forme de plénitude. Ce sont deux
          façons de prendre du plaisir et de réussir. Personne n’a à juger vos choix. Il vous
          revient de choisir le chemin qui vous correspond le mieux, de trouver le juste équilibre
          entre ces deux façons de réussir.
        </Paragrapth>

        <br />
        <br />

        <Paragrapth align="justify">
          En prenant conscience de ce qui est le moteur de vos actions au quotidien, en définissant
          clairement votre objectif suprême, vous ferez les meilleurs choix possibles, c’est-à-dire
          ceux qui vous correspondent vraiment.
        </Paragrapth>

        <br />
        <br />

        <Paragrapth align="justify">
          Le test qui suit va vous aider à évaluer votre propre positionnement. Ses résultats seront
          le point de départ d’une réflexion plus large et plus approfondie, que vous pouvez mener
          avec l’appui de votre consultant(e){' '}
          <Paragrapth align="justify" colorBlue={true} bold={true}>
            ORIENTACTION.
          </Paragrapth>
        </Paragrapth>

        <br />
        <br />

        <Paragrapth align="justify">
          En prenant conscience de ce qui est le moteur de vos actions au quotidien, en définissant
          clairement votre objectif suprême, vous ferez les meilleurs choix possibles, c’est-à-dire
          ceux qui vous correspondent vraiment.
        </Paragrapth>

        <br />
        <br />

        <Paragrapth align="justify">
          Avant de commencer ce test, prenez le temps de répondre à ces trois questions :
        </Paragrapth>

        <br />
        <br />

        {listFormualaire.map((item: any) => (
          <>
            <TitleNbr number={item.number} text={item.title} />

            <textarea
              name={item.name}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              onBlur={handleChangeGlobalState}
              defaultValue={book.meanToYou[item.name] || ''}
              placeholder="Aa"
              readOnly={props.readOnly}
              style={{
                width: '100%',
                height: '10vh',
                marginTop: '15px',
                border: '2px solid #0E1247',
                fontSize: '15px',
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
              }}
            />
          </>
        ))}
      </div>
    </Page>
  );
};
export default Page14;
