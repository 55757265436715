// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useState, FC, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useBook1 } from '@orientaction/hooks_book';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 800,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    textTransform: 'uppercase',
    alignItems: 'center',
    padding: 20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    display: 'flex',
    style: 'normal',
    alignItems: 'flex-end',
  },
  categorieB: {
    color: '#fff !important',
    background: '#2c57a5',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardBgGrey: {
    background: '#e8eef4',
  },
  borderTable: {
    borderRadius: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important',
  },
  floatIndex: {
    position: 'absolute',
    top: 0,
    right: 0,
    background: `url(${imgBook1}/back2.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '45px',
    height: '45px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      top: '5px',
      right: '5px',
    },
  },
  headerTitle: {
    background: 'white',
    color: '#000A8C',
  },
});

interface ITableMetiers {
  handleChange: (data: any) => void;
  defaultValue: any[];
  figure32: any[];
}

const TableMetiers: FC<ITableMetiers> = ({ handleChange, defaultValue, figure32 }) => {
  const classes = useStyles();
  const [defaultPropsValue, setDefaultPropsValue] = useState<any>(['', '', '', '', '']);
  const { book } = useBook1();

  const handleChangeFigure = (e: any, index: number) => {
    const copiedFigure = cloneDeep(defaultPropsValue);

    copiedFigure[index] = e.target.value;
    setDefaultPropsValue(copiedFigure);

    handleChange(copiedFigure);
  };

  useEffect(() => {
    setDefaultPropsValue(defaultValue);
  }, [defaultValue]);

  return (
    <div style={{ border: '1px solid grey', borderRadius: '10px', overflow: 'hidden' }}>
      <Grid container={true} style={{ borderBottom: '1px solid grey' }}>
        <Grid
          item={true}
          xs={12}
          style={{ position: 'relative', textAlign: 'center' }}
          className={classes.cardBgGrey}>
          <Typography component="p" className={classes.h7}>
            Notez ici les 5 métiers qui ont le plus retenu votre attention et votre intérêt :
          </Typography>
        </Grid>
      </Grid>
      <div style={{ padding: 20 }}>
        <Grid container={true} style={{ marginTop: 10 }}>
          <Grid item={true} xs={12} sm={2} className={classes.h6}>
            <Typography>Métier N° 1 :</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={10}>
            <FormControl style={{ width: '100%' }}>
              <Select
                defaultValue={defaultPropsValue[0]}
                id="grouped-select"
                value={defaultPropsValue[0]}
                onChange={(e: any) => handleChangeFigure(e, 0)}>
                {figure32.map((elem: any) => {
                  return [
                    <ListSubheader className={classes.headerTitle}>{elem.type}</ListSubheader>,
                    elem.metiers.map((metier: string) => (
                      <MenuItem value={metier} key={metier}>
                        {metier}
                      </MenuItem>
                    )),
                  ];
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginTop: 10 }}>
          <Grid item={true} xs={12} sm={2} className={classes.h6}>
            <Typography>Métier N° 2 :</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={10}>
            <FormControl style={{ width: '100%' }}>
              <Select
                defaultValue={defaultPropsValue[1]}
                id="grouped-select"
                value={defaultPropsValue[1]}
                onChange={(e: any) => handleChangeFigure(e, 1)}>
                {figure32.map((elem: any) => {
                  return [
                    <ListSubheader className={classes.headerTitle}>{elem.type}</ListSubheader>,
                    elem.metiers.map((metier: string) => (
                      <MenuItem value={metier} key={metier}>
                        {metier}
                      </MenuItem>
                    )),
                  ];
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginTop: 10 }}>
          <Grid item={true} xs={12} sm={2} className={classes.h6}>
            <Typography>Métier N° 3 :</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={10}>
            <FormControl style={{ width: '100%' }}>
              <Select
                defaultValue={defaultPropsValue[2]}
                id="grouped-select"
                value={defaultPropsValue[2]}
                onChange={(e: any) => handleChangeFigure(e, 2)}>
                {figure32.map((elem: any) => {
                  return [
                    <ListSubheader className={classes.headerTitle}>{elem.type}</ListSubheader>,
                    elem.metiers.map((metier: string) => (
                      <MenuItem value={metier} key={metier}>
                        {metier}
                      </MenuItem>
                    )),
                  ];
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginTop: 10 }}>
          <Grid item={true} xs={12} sm={2} className={classes.h6}>
            <Typography>Métier N° 4 :</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={10}>
            <FormControl style={{ width: '100%' }}>
              <Select
                defaultValue={defaultPropsValue[3]}
                id="grouped-select"
                value={defaultPropsValue[3]}
                onChange={(e: any) => handleChangeFigure(e, 3)}>
                {figure32.map((elem: any) => {
                  return [
                    <ListSubheader className={classes.headerTitle}>{elem.type}</ListSubheader>,
                    elem.metiers.map((metier: string) => (
                      <MenuItem value={metier} key={metier}>
                        {metier}
                      </MenuItem>
                    )),
                  ];
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container={true} style={{ marginTop: 10 }}>
          <Grid item={true} xs={12} sm={2} className={classes.h6}>
            <Typography>Métier N° 5 :</Typography>
          </Grid>
          <Grid item={true} xs={12} sm={10}>
            <FormControl style={{ width: '100%' }}>
              <Select
                defaultValue={defaultPropsValue[4]}
                id="grouped-select"
                value={defaultPropsValue[4]}
                onChange={(e: any) => handleChangeFigure(e, 4)}>
                {figure32.map((elem: any) => {
                  return [
                    <ListSubheader className={classes.headerTitle}>{elem.type}</ListSubheader>,
                    elem.metiers.map((metier: string) => (
                      <MenuItem value={metier} key={metier}>
                        {metier}
                      </MenuItem>
                    )),
                  ];
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default TableMetiers;
