import { TestContext } from '@orientaction/Context';
import { useContext } from 'react';
import Select from 'react-select';
import style from './style';

const options = [
  { value: 1, label: 'Données stockées' },
  { value: 0, label: 'Données non-stockées' },
];

const TestStockageSection = () => {
  const { test, updateTest } = useContext<any>(TestContext);
  const cls = style();
  const handleChange = (selected: any) => {
    updateTest({ ...test, stockageMode: selected });
  };
  return (
    <>
      <h1 className={cls.subtitle}>Mode de stockage</h1>
      <Select
        placeholder="Sélectionnez"
        className={cls.select}
        options={options}
        defaultValue={test?.stockageMode || { value: 1, label: 'Données stockées' }}
        value={test?.stockageMode}
        onChange={handleChange}
      />
    </>
  );
};

export default TestStockageSection;
