/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-return-await */
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useWindowSize } from '@react-hook/window-size';
import { Bar } from 'react-chartjs-2';
import locale from './locale.json';
import { useLanguage } from '@orientaction/hooks';
import { useEffect, useMemo, useState } from 'react';

const getMaxBarThicknessByScreenWidth = (width: number) => {
  if (width > 2000) {
    return 24;
  }
  if (width > 1400) {
    return 22;
  }
  if (width > 1200) {
    return 20;
  }
  if (width > 900) {
    return 18;
  }
  if (width > 600) {
    return 16;
  }
  if (width > 300) {
    return 12;
  }
  return 8;
};

const getAspectRatioByScreen = (width: number, height: number) => {
  const ratio = +(width / height).toFixed(1);
  console.log({ratio})
  return ratio;
};

const UserResultRadarGraph = ({
  resultToShow,
  isEmpty = false,
  fromCompass = false,
  canUserAccessHarmony = true,
}: any) => {
  const isMobile = useMediaQuery('(max-width:900px)');
  const isMobileXs = useMediaQuery('(max-width:600px)');
  const [translateData, setTranslateData] = useState<any>([])
  const [width, height] = useWindowSize();
  const additionnalTickConf = isMobile
    ? { mirror: true, fontSize: 12, labelOffset: isMobileXs ? -12 : -16 }
    : {};

  const { translateWord, language } = useLanguage();

  const dataObj = useMemo(() => {
    return [
      {
        key: locale.problemSolving,
        value: resultToShow?.attributes?.problem_solving || resultToShow?.problem_solving,
        average: 53,
      },
      {
        key: locale.confidence,
        value: resultToShow?.attributes?.confidence || resultToShow?.confidence,
        average: 61,
      },
      {
        key: locale.emotionalIntelligence,
        value:
          resultToShow?.attributes?.emotional_intelligence || resultToShow?.emotional_intelligence,
        average: 63,
      },
      { key: locale.empathy, value: resultToShow?.attributes?.empathy || resultToShow?.empathy, average: 75, },
      {
        key: locale.communication,
        value: resultToShow?.attributes?.communication || resultToShow?.communication,
        average: 68,
      },
      {
        key: locale.timeManagement,
        value: resultToShow?.attributes?.time_management || resultToShow?.time_management,
        average: 61,
      },
      {
        key: locale.stressManagement,
        value: resultToShow?.attributes?.stress_management || resultToShow?.stress_management,
        average: 61,
      },
      {
        key: locale.creativity,
        value: resultToShow?.attributes?.creativity || resultToShow?.creativity,
        average: 51,
      },
      {
        key: locale.entrepreneurialSpirit,
        value:
          resultToShow?.attributes?.entrepreneurial_spirit || resultToShow?.entrepreneurial_spirit,
        average: 52,
      },
      { key: locale.daring, value: resultToShow?.attributes?.daring || resultToShow?.daring, average: 55 },
      {
        key: locale.motivation,
        value: resultToShow?.attributes?.motivation || resultToShow?.motivation,
        average: 72,
      },
      { key: locale.vision, value: resultToShow?.attributes?.vision || resultToShow?.vision, average: 61, },
      { key: locale.presence, value: resultToShow?.attributes?.presence || resultToShow?.presence, average: 56, },
      {
        key: locale.collectiveSense,
        value: resultToShow?.attributes?.collective_sense || resultToShow?.collective_sense,
        average: 60,
      },
      {
        key: locale.curiousity,
        value: resultToShow?.attributes?.curiousity || resultToShow?.curiousity,
        average: 78,
      },
      {
        key: locale.criticalMind,
        value: resultToShow?.attributes?.critical_mind || resultToShow?.critical_mind,
        average: 68,
      },
    ].sort((a, b) => b.value - a.value);
  }, [resultToShow]);

  useEffect(() => {
    (async () => {
      const dataTrans = dataObj.map(async (elem) => {
        return {
          key: (await translateWord(elem.key))[0],
          value: elem.value,
          average: elem.average,
        };
      });
      setTranslateData(await Promise.all(dataTrans));
    })();
  }, [language, dataObj]);

  useEffect(() => {
    // console.log({translateData})
  }, [translateData]);

  const data = useMemo(() => {
    return {
      labels: fromCompass ? translateData?.map((obj: any) => obj.key).slice(0, 8) : translateData?.map((obj: any) => obj.key),
      datasets: [
        {
          order: 1,
          data: fromCompass
            ? translateData.map((obj: any) => obj.value).slice(0, 8)
            : translateData.map((obj: any) => obj.value),
          backgroundColor:
            isEmpty && fromCompass
              ? [
                  '#000000',
                  '#353935',
                  '#808080',
                  '#A9A9A9',
                  '#000000',
                  '#353935',
                  '#808080',
                  '#A9A9A9',
                ]
              : fromCompass
              ? [
                  '#FDCE29',
                  '#F7A82E',
                  '#ED6637',
                  '#C93646',
                  '#942A59',
                  '#662169',
                  '#3E1877',
                  '#0E0E87',
                ]
              : [
                  '#FDCE29',
                  '#F7A82E',
                  '#ED6637',
                  '#C93646',
                  '#942A59',
                  '#662169',
                  '#3E1877',
                  '#0E0E87',
                  '#FDCE29',
                  '#F7A82E',
                  '#ED6637',
                  '#C93646',
                  '#942A59',
                  '#662169',
                  '#3E1877',
                  '#0E0E87',
                ],
        },
        {
          type: 'line',
          label: 'Moyenne',
          data: translateData?.map((obj: any) => obj.average),
          order: 0,
          borderColor: isEmpty ? '#000000' : '#000A8C',
        },
      ],
    };
  }, [translateData, isEmpty]);

  const options = {
    indexAxis: 'y',
    barPercentage: isMobile ? 0.6 : 0.8,
    aspectRatio: getAspectRatioByScreen(width, height),
    maxBarThickness: getMaxBarThicknessByScreenWidth(width),
    maintainAspectRatio: !isMobile,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        display: false,
        text: 'Chart.js Horizontal Bar Chart',
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        max: 100,
        min: 0,
        position: 'top',
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          stepSize: isMobile ? 10 : 5,
        },
      },
      y: {
        max: 200,
        grid: {
          display: false,
        },
        ticks: {
          color: '#0E1247',
          ...additionnalTickConf,
        },
      },
    },

  };

  // bar chart

  return (
    <>
      <Bar id="canvas-data-bar" data={data as any} options={options as any} redraw={true} />
    </>
  );
};

export default UserResultRadarGraph;
