import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import style from '../../../commonStyle';
import localStyle from './style';

const ArtsCommunicationSuggestion = () => {
  const classes = style();
  const localClasses = localStyle();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        🧭 TROIS VOIES D’ORIENTATION PRIVILÉGIÉES :
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Voici trois voies que tu pourrais emprunter pour atteindre tes objectifs en fonction de
          tes résultats scolaires :
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec d&apos;excellents résultats (moyenne ≥15) : classes préparatoires
              art et design ou MANAA. Certains BTS très cotés comme Olivier de Serre.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec de bons résultats (moyenne ≥12) : BUT Info/com ou Techniques de
              commercialisation, licence universitaire aux Beaux-arts ou en histoire de l&apos;art.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec des résultats moyens (moyenne ≥10) : BTS design, communication,
              DN MADE, etc.
            </Typography>
          </li>
        </ul>
        <Typography
          className={classNames(classes.suggestionSubtitle, localClasses.customSuggestionSubtitle)}
          component="p">
          Quelle que soit la filière, il est recommandé de réaliser ses études à Paris ou dans une
          grande métropole où sont concentrés les emplois de la communication.
        </Typography>
        <Typography className={classes.suggestionQuote} component="p">
          « L’art n’est pas un mensonge. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Gustave Flaubert
      </Typography>
    </div>
  );
};

export default ArtsCommunicationSuggestion;
