// eslint-disable-next-line simple-import-sort/imports
import { Page, Somaire } from '@orientaction/components';

const Page05 = (props: any) => {

    return (
        <Page
            id={5}
        >
            <Somaire
                list={[
                  {
                    text: 'CHAPITRE 1 - LA MÉTHODE ORIENTACTION',
                    number: '6',
                    isBold: true,
                  },
                  {
                    text: 'POURQUOI NOUS AVONS CRÉÉ CETTE MÉTHODE ET CE QUI VA VOUS ARRIVER EN L’APPLIQUANT',
                    number: '7',
                  },
                  {
                    text: 'COMMENT VOUS ALLEZ CRÉER VOTRE AVENIR AVEC LA MÉTHODE ORIENTACTION',
                    number: '10',
                  },
                  {
                    text: 'LES TROIS ENGAGEMENTS QUE VOUS PRENEZ VIS-À-VIS DE VOUS-MÊME ET DE VOTRE CONSULTANT(E)',
                    number: '12',
                  },
                  {
                    text: 'CHAPITRE 2 - VOUS AVEZ RAISON DE VOULOIR CHANGER !',
                    number: '13',
                    isBold: true,
                  },
                  {
                    text: 'POURQUOI VOUS AVEZ RAISON DE VOULOIR CHANGER',
                    number: '14',
                  },
                  {
                    text: 'CHANGER, C’EST BON POUR LA SANTÉ !',
                    number: '19',
                  },
                  {
                    text: 'CHAPITRE 3 - LES OBSTACLES À SURMONTER ET LES DÉCISIONS À PRENDRE',
                    number: '21',
                    isBold: true,
                  },
                  {
                    text: 'LES QUATRE OBSTACLES AU CHANGEMENT',
                    number: '22',
                  },
                  {
                    text: 'TROIS TECHNIQUES POUR CONVAINCRE SES PROCHES',
                    number: '27',
                  },
                  {
                    text: 'LES QUATRE FAÇONS DE CHANGER (OU DE NE PAS CHANGER)',
                    number: '28',
                  },
                  {
                    text: 'LES GRANDES DÉCISIONS QUE VOUS DEVEZ PRENDRE',
                    number: '29',
                  },
                  {
                    text: 'LE TEST DU LAPIN : POUR COMPRENDRE EN S’AMUSANT SON RAPPORT AU CHANGEMENT',
                    number: '31',
                    isBold: true,
                  },
                ]}
            />
        </Page>
    );
};

export default Page05;
