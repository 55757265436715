import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    position: 'relative',
    width: '3vw',
    height: '3vw',
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
}));

export default useStyles;
