/* eslint-disable react/destructuring-assignment */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr, TitlePage } from '@orientaction/components';
import BlockWithBackground from '../../components/BlockWithBackground';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { defaultData, defaultCheked } from './constant';
import { useBook4 } from '@orientaction/hooks_book';


const Page40 = (props: any) => {

  const { setYourDiplome, yourDiplome } = useBook4();

  const classes = useStyles();

  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setYourDiplome({
      ...yourDiplome,
      [name]: value
    });

  };

  const handleCheked = (e: any) => {

    const { name, checked } = e.target;

    setYourDiplome({
      ...yourDiplome,
      recognized: {
        [name]: checked
      },
    });

  };

  const handleChangeList = (group: any) => (e: any) => {

    const { name, value } = e.target;

    setYourDiplome({
      ...yourDiplome,
      [group]: {
        ...yourDiplome?.[group],
        [name]: value
      }
    });

  };

  return (
    <Page id={40} readOnly={false}>

      <TitlePage showSpanYellow={false}>
        RETOUR SUR VOTRE PRINCIPAL DIPLÔME
      </TitlePage>

      <Grid
        container={true}
        style={{ marginTop: 20 }}
        spacing={1}
        direction="row"
        alignItems="center"
      >

        <Grid item={true} >
          <Paragrapth
            colorBlue={true}
            align="justify"
          >
            Intitulé :
          </Paragrapth>
        </Grid>

        <Grid item={true} xs={12} sm={9}>
          <TextArea
            name="init"
            onChange={handleChange}
            value={yourDiplome?.init || ""}
            veryLitleTextField={true}
            readOnly={props.readOnly}
          />
        </Grid>
      </Grid>

      <Grid
        container={true}
        style={{ marginTop: 20 }}
        spacing={1}
        direction="row"
        alignItems="center"
      >

        <Grid item={true} xs={12}  >
          <Paragrapth
            colorBlue={true}
            align="justify"
          >
            Éléments de contexte :
          </Paragrapth>
        </Grid>

        <Grid item={true} xs={12}  >
          <TextArea
            name="elementContext"
            onChange={handleChange}
            value={yourDiplome?.elementContext || ""}
            litleTextField={true}
            readOnly={props.readOnly}

          />
        </Grid>

      </Grid>

      <Grid container={true} spacing={2}>

        <Grid item={true} style={{ display: 'flex', alignItems: "center" }}>
          <Paragrapth
            colorBlue={true}
            overrideParagraphStyle={classes.overrideTextStyle}
            align="left">
            Cote de votre diplôme :
          </Paragrapth>
        </Grid>

        <Grid item={true}>

          {
            defaultCheked.map((item: any) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!yourDiplome?.recognized?.[item.name]}
                    name={item.name}
                    onChange={handleCheked}
                    color="primary"
                    disabled={props.readOnly}
                  />
                }
                label={item.title}
              />
            ))
          }

        </Grid>

      </Grid>

      <MarginContainer>

        {

          defaultData.map((item: any) => (

            <>

              <MarginContainer>

                <TitleNbr number={item.id} text={item.title} />

                <MarginContainer mormal={true}>

                  {

                    item.children && (
                      <>
                        <Grid
                          container={true}
                          spacing={1}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >

                          {
                            (item.children.length > 0) && item.children.map((children: any) => (
                              <>

                                <Grid item={true} xs={12} md={1}>

                                  <Paragrapth
                                    colorBlue={true}
                                    align="center"
                                    bold={true}
                                  >
                                    {children.label || ""} :
                                  </Paragrapth>

                                </Grid>

                                <Grid item={true} xs={12} md={11}>
                                  <TextArea
                                    name={children.name}
                                    onChange={handleChangeList(item.id)}
                                    value={yourDiplome?.[item.id]?.[children.name] || ""}
                                    litleTextField={true}
                                    readOnly={props.readOnly}
                                  />
                                </Grid>

                              </>
                            ))
                          }

                        </Grid>

                      </>

                    )

                  }

                </MarginContainer>

              </MarginContainer>

            </>

          ))

        }

      </MarginContainer>

      <MarginContainer>
        <BlockWithBackground overridecardBgGreyStyle={classes.overridecardBgGreyStyle}>
          <Paragrapth colorBlue={true} align="center" addBackground={true}>
            Maintenant que vous avez décrit votre expérience par écrit, entraînez-vous à l’oral.
          </Paragrapth>
        </BlockWithBackground>
      </MarginContainer>
    </Page>
  );
};

export default Page40;
