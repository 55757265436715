// eslint-disable-next-line simple-import-sort/imports
import { MarginContainer } from '@orientaction/commons';
import { TitlePage, Page, TitleChapitre, Paragrapth } from '@orientaction/components';
import Img from '../../components/Image';
import useStyles from './style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page44 = () => {
  const classes = useStyles();
  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_plus-value.png' : 'plus-value.png';
  }, [language]);
  return (
    <Page id={44}>
      <TitleChapitre nbr={5} />
      <TitlePage>
        VOTRE VALEUR AJOUTÉE
        <br />
        (LE FRUIT)
      </TitlePage>
      <MarginContainer>
        <Paragrapth align="justify">
          Grâce à vos ressources (les 4 capitaux), votre personnalité (où résident vos « soft skills
          ») et vos compétences (savoir-être, savoir-faire et savoirs), vous avez la capacité
          d’apporter, de créer, de faire quelque chose de particulier, différent de ce que peuvent
          faire les autres. La combinaison de l’ensemble de ces trois éléments crée quelque chose
          d’unique. Cette chose unique correspond à votre valeur ajoutée personnelle. Vous devez
          être capable de formuler sous la forme d’une phrase ce que vous êtes capable d’apporter
          d’unique et de différent.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">À vous de jouer.</Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Img filename={image} alt="plus-value" className={classes.imgTronc} />
      </MarginContainer>
    </Page>
  );
};

export default Page44;
