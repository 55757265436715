import Grid from '@material-ui/core/Grid';
import { useContext } from 'react';
import { TestContext } from '../../Context/TestContext';
import VariableCardList from '../VariableCardList';
import VariableCreationForm from '../VariableCreationForm';
import VariableEmpty from '../VariableEmpty';
import style from './style';

const VariableSection = () => {
  const cls = style();
  const { test, updateTest } = useContext<any>(TestContext);
  return (
    <>
      <h1 className={cls.subtitle}>Variables</h1>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6} style={{ marginTop: 10 }}>
          {test.variables.filter((v: any) => v.name.length !== 0).length === 0 ? (
            <VariableEmpty />
          ) : (
            <VariableCardList variables={test.variables} />
          )}
        </Grid>
        <Grid item={true} xs={6} md={6}>
          <VariableCreationForm />
        </Grid>
      </Grid>
    </>
  );
};

export default VariableSection;
