/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { MarginContainer } from '@orientaction/commons';
import clsx from 'clsx';
// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';
import { useStyles } from './style';
import TablePagination from './TablePagination';
import Thead from './Thead';

type CustomTableProps = {
  rowsPerPage: number;
  thead: Array<any>;
  bodyData: Array<any>;
  className?: string;
  load?: boolean;
  bodyText?: string;
  isRowRelative?: boolean;
  resetPage?: boolean;
  count?: any;
  handleChangePage: any;
  currentPage: any;
  // eslint-disable-next-line react/no-unused-prop-types
  pagination?: boolean;
  isHover?: boolean;
  isCustomer?: boolean;
};

const CustomPaginationActionsTable: FC<CustomTableProps> = ({
  rowsPerPage,
  thead,
  bodyData,
  className = '',
  load = false,
  bodyText,
  isRowRelative = false,
  resetPage = false,
  count,
  handleChangePage,
  currentPage,
  pagination,
  isHover = true,
  isCustomer = false,
}) => {
  const classes = useStyles();
  const refContainer = React.useRef<HTMLDivElement>(null);

  const dataLength = count;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataLength - currentPage * rowsPerPage);

  interface Column {
    id: string;
    label: string;
    minWidth?: number;
    columnStyle?: any;
    rowStyle?: any;
    onClickAction?: any;
  }

  interface Data {
    value: string;
    customCellClass?: string;
    onClickAction?: any;
    isBold?: boolean;
  }

  React.useEffect(() => {
    // if (refContainer?.current?.parentElement) {
    //   refContainer.current.parentElement.scrollIntoView({ behavior: 'auto', block: 'start' });
    // }

    let pageCount = dataLength / rowsPerPage;
    if (!Number.isInteger(pageCount)) {
      // eslint-disable-next-line
      pageCount = Math.floor(pageCount + 1);
    }
    if (currentPage > pageCount - 1 && pageCount > 0) handleChangePage(pageCount - 1);
  }, [dataLength, currentPage, rowsPerPage]);

  React.useEffect(() => {
    if (resetPage) {
      handleChangePage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPage]);

  const HeaderTable = thead;

  return (
    <div className={classes.customtable}>
      <>
        {dataLength > 0 ? (
          <>
            <TableContainer className={clsx(classes.tableContainer, className)} ref={refContainer}>
              <Table className={classes.table} aria-label="simple table">
                <Thead>
                  {HeaderTable.map((column: Column) => (
                    <TableCell
                      key={Math.random()}
                      classes={{
                        head: clsx({
                          [classes.isCustomer]: isCustomer,
                        }),
                      }}>
                      {column.label}
                    </TableCell>
                  ))}
                </Thead>

                <TableBody style={{ position: 'relative' }}>
                  {load && (
                    <div className={classes.bodycenter}>
                      <CircularProgress color="inherit" />
                    </div>
                  )}
                  {(rowsPerPage > 0 ? bodyData : bodyData).map((rowOne: Array<Data>) => {
                    const row = rowOne;

                    return (
                      <TableRow
                        className={clsx(
                          classes.TableRow,
                          isRowRelative ? classes.relative : null,
                          !isHover ? classes.cursorDefault : null
                        )}
                        key={Math.random()}
                        hover={isHover}>
                        {HeaderTable.map((column: Column, index) => {
                          if (!row[index]) return null;

                          const { value, customCellClass, onClickAction, isBold } = row[index];

                          return (
                            <TableCell
                              key={Math.random()}
                              scope="row"
                              onClick={onClickAction || undefined}
                              className={clsx(classes.tableCell, customCellClass || '')}
                              style={{
                                ...((() => (column.rowStyle ? column.rowStyle : {}))() as any),
                              }}>
                              {isBold ? (
                                <b
                                  style={{
                                    color: 'black',
                                    fontWeight: 600,
                                    fontFamily: 'Poppins',
                                  }}>
                                  {value}
                                </b>
                              ) : (
                                <>{value}</>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}

                  {!load && !dataLength && bodyText && (
                    <TableRow style={{ height: 64 * 2 }}>
                      <TableCell colSpan={6} className={clsx(classes.tableCellEmpty)} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {pagination && rowsPerPage < dataLength && (
              <div className={classes.tableFooter}>
                <TablePagination
                  rowsPerPageOptions={[]}
                  labelDisplayedRows={() => ''}
                  colSpan={10}
                  count={dataLength}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onChangePage={handleChangePage}
                  onPageChange={handleChangePage}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={classes.noResultText}>{bodyText || 'Aucun resultat'}</div>
          </>
        )}
      </>
    </div>
  );
};

export default CustomPaginationActionsTable;
