// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { publicSvgPath } from '../../utils/constants';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { ItemAspiration } from '@orientaction/components'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding:20,
  },
  categorieB: {
    color: '#fff !important',
    background: '#2c57a5',
    fontSize: '25px',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal'
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal'
  },
  cardBgGrey: {
    background: '#e8eef4'
  },
  borderTable: {
    borderRadius: '10px',
    border: '1px solid black',
  },
  textArea: {
    width: '100%',
    height: 'auto',
    border: '0px !important',
  }
});

const TableAspiration = (props: any) => {
  const classes = useStyles();
  const {figure32} = props;

  return (
    <Card variant="outlined" className={classes.borderTable}>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            {figure32.map((elem: any) => (
              <Grid item={true} xs={12} sm={6}  md={4} style={{border: '1px solid black',borderTop: 'none'}}>
                <ItemAspiration aspiration={elem} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableAspiration;
