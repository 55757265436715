import 'moment/locale/fr'; // without this line it didn't work
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { ContentCenter, MarginContainer } from '@orientaction/commons';
import releveConnextion from '@orientaction/PDF/releveConnection';
import { publicSvgPath } from '@orientaction/utils';
import clsx from 'clsx';
import moment from 'moment';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import style from './style';

moment.locale('fr');

interface IProps {
  handleChange: (data: any) => void;
  books: any[];
  current: any;
  loading: boolean;
}

const EbookSection: FC<IProps> = ({ handleChange, books, current, loading }) => {
  const classes = style();

  const [exportLoading, setExportLoading] = useState(false);

  const exportDataHandle = async () => {
    const listSuiviDesTemps: any[] = books
      .sort((a: any, b: any) => a.id - b.id)
      .map((item: any) => {
        return [
          item.attributes.DateView ? moment(item.attributes.DateView).format('DD/MM/YYYY') : '',
          item.attributes.DateView ? moment(item.attributes.DateView).format('HH:mm') : '',
          item.attributes.DateView
            ? moment(item.attributes.DateView)
                .add(item.attributes.HourRead, 'hours')
                .format('HH:mm')
            : '',
        ];
      });

    const data = {
      upHierachy: current.name_upper_hierarchy,
      name: `${current?.firstname} ${current?.lastname}`,
      books: listSuiviDesTemps,
      finish: books[5].attributes?.DateView
        ? moment(books[5].attributes?.DateView).format('dddd DD MMM YYYY')
        : '',
    };

    releveConnextion(data);
  };

  return (
    <div>
      <div>
        {books?.length > 0 && (
          <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item={true}>
              <Typography className={classes.bookChoiceLabel}>Les E-books</Typography>
            </Grid>

            <Grid item={true}>
              <Button
                variant="contained"
                className={clsx(classes.searchButton, classes.btn)}
                disabled={exportLoading}
                onClick={exportDataHandle}
                data-cy="export-suivi">
                <img
                  src={`${publicSvgPath}/download.svg`}
                  alt="search icon"
                  style={{ marginRight: 10 }}
                />
                <span>{exportLoading ? 'Exporter...' : 'Exporter'}</span>
              </Button>
            </Grid>
          </Grid>
        )}

        {loading ? (
          <>
            <ContentCenter>
              <CircularProgress />
            </ContentCenter>
          </>
        ) : (
          books
            ?.sort((a: any, b: any) => a.id - b.id)
            .map((book: any, index: number) => (
              <div key={book.id} className={classes.bookChoiceContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={book?.isChecked || false}
                      onChange={handleChange}
                      name={`book_${book.id}`}
                      color="primary"
                    />
                  }
                  label={book?.attributes?.name}
                />
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default EbookSection;
