import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textArea: {
            width: "40%",
            borderBottom: 'none',
            cursor: 'pointer',
            "& .MuiInput-underline:before": {
                borderBottom: 'none'
            },
            "& .MuiInput-underline:after": {
                borderBottom: 'none'
            },
            "& .MuiInput-underline:hover:before": {
                borderBottom: "2px solid #aaa"
            },
            "& .MuiInputBase-input": {
                width: "100%",
                color: "black",
            },
            [theme.breakpoints.down('sm')]: {
                width: "80%",
            },
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                "& .MuiInputBase-input": {
                    fontSize: 11
                },
            }
        },
        empty: {
            "& .MuiInputBase-input": {
                color: "#BCBCBC",
                textTransform: "uppercase"
            }
        }
    })
);