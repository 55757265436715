import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentPreference: {
      transition: '500ms',
      width: '389px',
      height: '506px',
      margin: '1.25vh',
      backgroundColor: '#fff',
      borderRadius: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up(1640)]: {
        width: '30%',
      },
      [theme.breakpoints.down(1300)]: {
        width: '45%',
      },
      [theme.breakpoints.down(800)]: {
        width: '100%',
        height: 'auto',
        margin: '1vh auto',
        padding: '24px',
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      color: '#0E1247',
      [theme.breakpoints.down(800)]: {
        fontSize: '14px',
      },
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '90%',
      [theme.breakpoints.down(800)]: {
        fontSize: '16px',
      },
    },
  }),
);
