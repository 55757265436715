/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    TitlePage,
    Page,
} from '@orientaction/components';
import useStyles from './style';
import ArrayYesNo from '../../components/ArrayYesNo';
import { useBook5 } from '@orientaction/hooks_book';
import { MarginContainer } from '@orientaction/commons';
import { list1, list2 } from './context';

const Page23 = (props: any) => {

    const classe = useStyles();

    const { ifProjectFinish, whenProjectStart, setIfProjectFinish, setWhenProjectStart } = useBook5();

    const handleChangeProjectFinish = (e: any) => {

        const { name, value, index } = e;

        setIfProjectFinish({
            ...ifProjectFinish,
            [index]: {
                ...ifProjectFinish?.[index],
                [name]: value
            }
        });

    }

    const handleChangeProjectStart = (e: any) => {

        const { name, value, index } = e;

        setWhenProjectStart({
            ...whenProjectStart,
            [index]: {
                ...whenProjectStart?.[index],
                [name]: value
            }
        });

    }

    return (
        // eslint-disable-next-line prettier/prettier
        <Page id={22} readOnly={false}>

            <TitlePage>
                CE À QUOI VOUS ALLEZ DEVOIR RENONCER <br />
                POUR RÉALISER VOTRE PROJET
            </TitlePage>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Choisir c’est renoncer. Cela signifie que si vous gagnez quelque chose en réalisant
                    votre projet, vous renoncez aussi à d’autres bénéfices ou avantages. Cela peut
                    être des bénéfices ou des avantages matériels ou psychologiques. Notez dans ce
                    tableau « ce à quoi il va vous falloir renoncer pour réaliser votre projet » à deux
                    moments clefs :
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    <Paragrapth align='start' bold={true} colorBlue={true}> (1) </Paragrapth> pendant la mise en œuvre de votre projet et  <Paragrapth align='start' bold={true} colorBlue={true}> (2) </Paragrapth> une fois que votre projet sera réalisé. Notez ensuite si vous êtes d’accord ou non pour y renoncer.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <ArrayYesNo
                    readOnly={props.readOnly}
                    handleChange={handleChangeProjectStart}
                    current={whenProjectStart}
                    title="PENDANT LA MISE EN ŒUVRE DE VOTRE PROJET"
                    list={list1}
                />
            </MarginContainer>

            <MarginContainer>
                <ArrayYesNo
                    handleChange={handleChangeProjectFinish}
                    current={ifProjectFinish}
                    title="UNE FOIS VOTRE PROJET RÉALISÉ"
                    list={list2}
                    readOnly={props.readOnly}
                />
            </MarginContainer>

        </Page>
    );
};
export default Page23;
