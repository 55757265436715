import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: 'white',
    border: '1px solid white',
    '@media only screen and (max-width: 600px)': {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '10px',
    },
    '@media only screen and (max-width: 391px)': {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '8px',
    },
  },
  label: {
    color: '#000A8C',
    fontSize: '15px',
    '@media only screen and (max-width: 600px)': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 391px)': {
      fontSize: '10px',
    },
    '&>p': {
      '@media only screen and (max-width: 600px)': {
        margin: 0,
      },
    },
  },
  FormControlLabel: {
    backgroundColor: 'white',
    marginBottom: 10,
    borderRadius: 10,
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: 'Poppins',
    '@media only screen and (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  FormControl: {
    margin: 0,
    display: 'block',
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  img: {
    width: '60%',
    height: 'auto',
    borderRadius: '10px',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddingContent: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media only screen and (max-width: 600px)': {
      paddingLeft: 20,
      paddingRight: 20,
    },
    '@media only screen and (max-width: 361px)': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  noMargin: {
    '& p': {
      margin: 0,
    },
  },
  imageResponsive: {
    width: 320,
    height: 320,
    '@media only screen and (max-width: 600px)': {
      width: 125,
      height: 125,
    },
    '@media only screen and (max-width: 361px)': {
      width: 100,
      height: 100,
    },
  },
  title: {
    fontFamily: 'Poppins',
    '& p': {
      margin: 0,
    },
    '@media only screen and (max-width: 600px)': {
      fontSize: '20px',
      marginTop: 10,
      marginBottom: 10,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  text: {
    width: '60%',
    fontFamily: 'Poppins',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '15px',
    fontWeight: 40,
    marginTop: '0px',
    '@media only screen and (max-width: 600px)': {
      width: '90%',
      fontSize: '12px',
      marginBottom: 5,
    },
    '@media only screen and (max-width: 391px)': {
      width: '95%',
      fontSize: '10px',
    },
    '& p': {
      margin: 0,
    },
  },
  questionText: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    '@media only screen and (max-width: 600px)': {
      fontSize: '13px',
    },
    '@media only screen and (max-width: 361px)': {
      fontSize: '10px',
    },
  },
  marginTopGrid: {
    textAlign: 'center',
    marginTop: 20,
    '@media only screen and (max-width: 600px)': {
      marginTop: 10,
    },
  },
  btnPrimary: {
    paddingLeft: 20,
    paddingRight: 20,
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  textHeader: {
    display: 'block',
  },
  flexCenterMessage: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  bodycenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 8,
  },
}));
