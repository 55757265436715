import { Grid, Typography } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import classnames from 'classnames';
import { FC } from 'react';
import useStyle from '../style';

interface IListParagrapth {
  list: string[];
  overrideParagrapthListStyle?: any;
  componentType?: any;
}

const ListParagrapth: FC<IListParagrapth> = ({
  list,
  overrideParagrapthListStyle = '',
  componentType = 'i',
}) => {
  const classe = useStyle();

  return (
    <div
      className={classnames(classe.ParagrapthList, overrideParagrapthListStyle)}
      style={{
        background: '#e8eef4',
      }}>
      {list.map((item: string) => (
        <div>
          <Grid container={true} justifyContent="flex-start" alignItems="center">
            <Grid item={true} xs={12}>
              <Typography
                component={componentType}
                className={classe.text}
                style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowRightIcon className={classe.colorRed} />
                <span style={{ marginLeft: '10px' }}>{item || ''}</span>
              </Typography>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default ListParagrapth;
