/* eslint-disable no-use-before-define */

import { Page, ParagrapthListNumber, TitlePage } from '@orientaction/components';
import Img from '../../components/Image';
import useStyles from './style';

const lists = [
  {
    label: ` Les racines`,
    text: `Elles désignent nos 4 capitaux : le capital économique, le capital social, le
    capital culturel et enfin le capital symbolique. Les racines sont ainsi le socle sur lequel
    nous prenons appui pour réussir.`,
    number: 1,
  },
  {
    label: ` Le tronc`,
    text: `Il correspond à notre personnalité forgée dans la confrontation avec
    l’environnement. La personnalité est aussi un ensemble de capacités appelées
    « soft skills ».`,
    number: 2,
  },
  {
    label: ` Les branches`,
    text: `Il s’agit de nos savoirs, savoir-faire et savoir-être, nos compétences
    visibles, quotidiennes, en application.`,
    number: 3,
  },
  {
    label: ` Les fruits `,
    text: `C’est ce que produit notre compétence. Ce qui intéresse le(a) client(e) de
    notre future entreprise ou notre futur(e) employeur(se) qui voudra « manger les beaux
    fruits de nos compétences ».`,
    number: 4,
  },
];

const Page14 = () => {
  const classes = useStyles();
  return (
    <Page id={14}>
      <TitlePage showSpanYellow={false}>LE FRUIT DE VOS COMPÉTENCES</TitlePage>
      <Img filename="fruits.png" alt="fruits" className={classes.img} />
      <ParagrapthListNumber
        addParagraphBackground={false}
        overrideRootStyle={classes.overrideRootStyle}
        list={lists}
        overrideSpanStyle={classes.overrideSpanStyle}
      />
    </Page>
  );
};

export default Page14;
