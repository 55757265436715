import { getFromLS, uploadImageAsync } from '@orientaction/utils';
import { useState } from 'react';

const useUpload = ({ user, setUser, updateUserProfilePhoto }: any) => {
  const [photoUri, setUri] = useState<string>('');

  const uploadToStore = (files: any, path = 'tests') => {
    const url = URL.createObjectURL(files[0]);
    uploadImageAsync(url, path).then(res => {
      setUri(res);
      setUser({ ...user, photoUri: res });
      const userInfos = JSON.parse(getFromLS('userInfos') ?? '');
      const userInfoUpdated = { ...userInfos, photoUri: res };
      localStorage.setItem('userInfos', JSON.stringify(userInfoUpdated));
      updateUserProfilePhoto();
    });
  };

  return {
    photoUri,
    setUri,
    uploadToStore,
  };
};

export default useUpload;
