import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleText: {
      fontSize: 20,
      color: 'white',
      textAlign: 'center',
      marginTop: 50,
    },
  }),
);

export default useStyles;
