import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  rootSkeleton: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'coulom',
    },
  },
  root: {
    width: '95%',
    margin: '0 auto',
  },
  form: {
    width: '100%',
    marginTop: 40,
  },
  fieldWithLabel: {
    marginTop: 20,
    width: '100%',
  },
  errorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    fontFamily: 'ITC Avant Garde Gothic Std Medium',
    fontSize: 13,
    borderRadius: 5,
  },
  textArea: {
    width: '100%',
    marginTop: 5,
    fontFamily: 'ITC Avant Garde Gothic Std Medium',
    fontSize: 13,
    borderRadius: 5,
    height: '100px',
    border: `1px solid #E3E8F0`,
  },
  inputPassword: {
    paddingRight: 5,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  labelText: {
    font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
    color: '#0E1247',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 3,
    marginTop: 3,
  },
  btnConnectContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    width: '100%',
  },
  btnConnect: {
    width: '100%',
    background:
      'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#fff',
  },
  inputWitAdornment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  btnPrimary: {
    textTransform: 'none',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '1vh',
      minHeight: 48,
      width: '50%',
      color: '#fff',
    },
  },
  btnWhite: {
    backgroundColor: '#fff#',
    color: '#0E1247',
    marginTop: 0,
    marginRight: 10,
    borderRadius: 3,
    opacity: 1,
    border: '1px solid grey',
    textTransform: 'none',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      padding: '1vh',
      minHeight: 48,
      width: '50%',
      color: '#0E1247',
    },
  },
  contentBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  divContentBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  selectLanguageButton: {
    height: 56,
    backgroundColor: '#fff',
  },
}));
