/* eslint-disable no-continue */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Checkbox, Typography, CircularProgress, FormControlLabel } from '@material-ui/core';
import { FC } from 'react';
import style from './style';
import { MarginContainer, ContentCenter } from '@orientaction/commons';
import classNames from 'classnames';

interface IMajorTestSelection {
  handleChange: (data: any) => void;
  tests: any;
  loading: boolean;
  service: any;
}

const MajorTestSelection: FC<IMajorTestSelection> = ({ tests, handleChange, loading, service }) => {
  const classes = style();
  if (!tests?.length) {
    return null;
  }

  const isCoaching = service.id === 3;

  return (
    <>
      <MarginContainer>
        <Typography className={classes.bookChoiceLabel}> Les tests </Typography>
      </MarginContainer>

      {loading ? (
        <>
          <ContentCenter>
            <CircularProgress />
          </ContentCenter>
        </>
      ) : (
        tests.map((test: any) => {
          const isNeed = test.id === 2;
          const isNeedPro = test.id === 3;
          const needAndHasNeedProChecked = isCoaching && isNeed && tests[2].isChecked;
          const needProAndHasNeedChecked = isCoaching && isNeedPro && tests[1].isChecked;
          return (
            <div
              key={test.id}
              className={classNames(
                classes.bookChoiceContainer,
                isCoaching && isNeed && classes.needsStyle,
                isCoaching && isNeedPro && classes.needProStyle
              )}>
              <div
                className={classNames(classes.selectItemContainer, {
                  [classes.whiteBackground]: needAndHasNeedProChecked || needProAndHasNeedChecked,
                })}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={test.isChecked || false}
                      onChange={handleChange}
                      name={`test_${test.id}`}
                      color="primary"
                    />
                  }
                  label={test?.attributes?.name}
                />
              </div>
            </div>
          );
        })
      )}
    </>
  );
};

export default MajorTestSelection;
