import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    childrenOverrideStyle: {
      fontFamily: 'ITC Avant Garde Gothic Std bold',
      fontWeight: 'bold',
      fontStyle: 'normal',
      textAlign: 'start',
      marginTop: 5,
      marginLeft: 5,
    },
  })
);

export default useStyles;
