import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardBgGrey: {
            padding: 5,
            background: '#e8eef4',
        },
        title: {
            fontSize: '20px',
        },
        content: {
            width: '90%',
            margin: '1vh auto'
        },
        paddingLeft: {
            margin: '0 0 3vh 0',
        }
    })
);

export default useStyles;