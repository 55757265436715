import { Grid } from "@material-ui/core";
import { FC } from "react";
import { publicSvgPath } from '../../../utils/constants';


interface ILogo {
    marginTop: string;
    marginBottom: string;
    widthLogo: string;
    heightLogo: string;
}

const Logo: FC<ILogo> = ({ marginTop, marginBottom, widthLogo, heightLogo }) => {

    const styleContent: any = {
        marginTop: marginTop as string,
        marginBottom: marginBottom as string,
        width: "100%"
    }

    const styleLogo: any = {
        width: widthLogo as string,
        height: heightLogo as string,
    }

    return (
        <div style={styleContent}>
            <div
                style={{
                    ...styleLogo,
                    margin: "0 auto"
                }}
            >
                <img
                    src={`${publicSvgPath}/logoDesktop.svg`}
                    alt="logo"
                    style={{
                        width: "100%"
                    }}
                />
            </div>
        </div >
    );

};

export default Logo;
