import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    aButton: {
      textDecoration: 'none',
      color: 'white',
    },
    fixed: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      backgroundColor: '#2B56A5',
      color: 'white',
      marginRight: 3,
      marginBottom: 3,
    },
    fabIcon: {
      backgroundColor: 'white',
      color: '#2B56A5',
      width: 35,
      height: 35,
      borderRadius: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default useStyles;
