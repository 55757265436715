/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import withWidth from '@material-ui/core/withWidth';
import Timeline from '@material-ui/lab/Timeline';
import { CompassTestHistoryPerYear, Layout } from '@orientaction/components';
import CompassCard from './CompassCard';
import PersonalityCard from './PersonalityCard';
import style from './style';
import TalentsCard from './TalentsCard';
import ValueCard from './ValueCard';
import NeedsProCard from './NeedsProCard';
import Content from './Components/Content';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useCompassMenu } from '@orientaction/hooks';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const GlobalRestitution = () => {
  const { year, userId }: any = useParams();
  const history = useHistory();
  const classes = style();

  const { getUserData, loading, harmonyTestLastResult } = useCompassMenu();

  useEffect(() => {
    if (year && typeof +year === 'number') {
      getUserData();
    }
  }, [year]);

  const goBack = () => {
    console.log('back');
    history.push(`/admin/resultat-beneficiaire/${userId}`);
  };

  return (
    <Layout isaddBackground={true} noPadding={true} isNew={true} footer={false}>
      {loading ? (
        <div className={classes.contentLoader}>
          <CircularProgress className={classes.colorWhite} />
        </div>
      ) : (
        <div>
          <div className={classes.marginTop}>
            <Button
              data-cy="button-back"
              className={classes.buttonBack}
              startIcon={<ArrowBackIcon />}
              onClick={goBack}
            >
              Retour
            </Button>
          </div>
          <div className={classes.btnHistory}>
            <CompassTestHistoryPerYear />
          </div>

          <Timeline className={classes.timelineAlignLeft}>
            <Content
              title="Votre boussole Orientaction"
              overrideTitleStyle={classes.overrideTitleStyle}
              overrideChildrenStyle={classes.overrideChildrenStyle}
              dotStyleOverride={classes.dotStyleOverride}
              horizontalLineOverrideStyle={classes.horizontalLineOverrideStyle}
              to=""
            >
              <CompassCard />
            </Content>

            <Content
              title="Vos valeurs"
              to="meaning-value"
              overrideTitleStyle={classes.overrideSubtitleStyle}
              timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
              cardMarginBottomStyle={classes.cardMarginBottomStyle}
            >
              <ValueCard />
            </Content>

            <Content
              title="Vos besoins professionnels"
              to="besoins-pro"
              overrideTitleStyle={classes.overrideSubtitleStyle}
              timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
              cardMarginBottomStyle={classes.cardMarginBottomStyle}
            >
              <NeedsProCard />
            </Content>

            <Content
              title="Votre personnalité"
              to="preference-personality"
              overrideTitleStyle={classes.overrideSubtitleStyle}
              timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
              cardMarginBottomStyle={classes.cardMarginBottomStyle}
            >
              <PersonalityCard />
            </Content>

            <Content
              title="Vos talents (Soft skills)"
              to="soft-skills"
              overrideTitleStyle={classes.overrideSubtitleStyle}
              timelineContentOverrideStyle={classes.timelineContentOverrideStyle}
              cardMarginBottomStyle={classes.lastCardMarginBottomStyle}
            >
              <TalentsCard result={harmonyTestLastResult} loading={loading} />
            </Content>
          </Timeline>
        </div>
      )}
    </Layout>
  );
};

export default withWidth()(GlobalRestitution);
