import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  img: {
    width: '70%',
    height: 'auto',
  },
  flexCenterEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexCenterStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  paddingContent: {
    paddingTop: 50,
    paddingBottom: 100,
    height: '100%',
  },
  absoluteTop: {
    position: 'absolute',
    top: 20,
    left: 20,
    color: 'white',
  },
}));
