/* eslint-disable no-use-before-define */
// eslint-disable-next-line no-use-before-define
import Button from '@material-ui/core/Button';
import { useStyles } from './style';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import React, { FC } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { MarginContainer } from '@orientaction/commons';


export interface ITablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onChangePage: (event: any, newPage: number) => void;
};


const TablePaginationActions: FC<ITablePaginationActionsProps> = props => {
    const classes = useStyles();
    const { count, page, rowsPerPage, onChangePage } = props;
    const interval = 2;
    const pageFirstShowed = 5;
    let pageCount = count / rowsPerPage;

    if (!Number.isInteger(pageCount)) {
        // eslint-disable-next-line
        pageCount = Math.floor(pageCount + 1);
    }

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onChangePage(event, page + 1);
    };

    const handlePageButtonClick = (event: React.MouseEvent<HTMLButtonElement>, newpage: number) => {
        onChangePage(event, newpage);
    };

    const getArrayPagination = () => {
        const pageModified = page || 0;
        let begin = pageModified <= interval ? 0 : pageModified - interval;
        let end = pageModified + interval >= pageCount ? pageCount : pageModified + interval + 1;
        if (pageModified <= interval) end = pageFirstShowed;
        if (pageModified + interval >= pageCount && pageCount >= pageFirstShowed)
            begin = pageCount - pageFirstShowed;
        return {
            begin,
            end,
        };
    };

    const arrayPagination = getArrayPagination();

    const paginationList = Array.from({ length: pageCount }, (v, k) => k).slice(
        arrayPagination.begin,
        arrayPagination.end
    );

    return (

        <>

            {rowsPerPage < count && (

                <MarginContainer>

                    <div className={classes.paginationFooter}>
                        <Button
                            onClick={event => handlePageButtonClick(event, 0)}
                            disabled={page === 0}
                            aria-label="first page">
                            <FirstPage />
                        </Button>

                        <Button onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                            <ArrowBackIosIcon style={{ fontSize: 16 }} />
                        </Button>

                        {paginationList.map((e, i) => (
                            <Button
                                key={Math.random()}
                                className={`button-page ${page === e ? 'active' : ''}`}
                                onClick={event => handlePageButtonClick(event, e)}>
                                {e + 1}
                            </Button>
                        ))}

                        <Button
                            onClick={handleNextButtonClick}
                            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                            aria-label="next page">
                            <ArrowForwardIosIcon style={{ fontSize: 16 }} />
                        </Button>

                        <Button
                            onClick={event => handlePageButtonClick(event, pageCount - 1)}
                            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                            aria-label="last page">
                            <LastPage />
                        </Button>

                    </div>

                </MarginContainer>

            )}

        </>

    );
};



export default TablePaginationActions;