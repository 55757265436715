import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestionContainer: {
      margin: '16px 48px',
      [theme.breakpoints.down('sm')]: {
        margin: '24px 16px',
      },
    },
    suggestionContent: {
      margin: '16px 0px',
    },
    suggestionList: {
      listStyle: 'none',
      margin: '48px 0px 32px',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '&:before': {
          content: '"•"',
          color: '#fff',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1em',
          marginTop: '0.3em',
        },
      },
    },
    title: {
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 700,
      wordBreak: 'break-word',
    },
    suggestionSubtitle: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      wordBreak: 'break-word',
    },
    suggestionQuote: {
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 600,
      wordBreak: 'break-word',
    },
    suggestionQuoteAuthor: {
      fontFamily: 'Poppins',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      wordBreak: 'break-word',
    },
  })
);

export default useStyles;
