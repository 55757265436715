/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import 'react-phone-input-2/lib/material.css';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { useOfflineLanguage, useResponseUser } from '@orientaction/hooks';
import { userService } from '@orientaction/services';
import { FC, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PhoneInput from 'react-phone-input-2';
import { useHistory, useLocation } from 'react-router-dom';
import AuthFormHeader from '../../../Commons/AuthFormHeader';
import locale from '../locale.json';
import { style } from './style';

interface IProps {
  handleAccountConfirm: any;
  showAccountConfirm: boolean;
  dataStep1: any;
  handlePrevious: any;
}

const RegisterForm: FC<IProps> = ({
  handleAccountConfirm,
  dataStep1,
  showAccountConfirm,
  handlePrevious,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [current, setCurrent] = useState<any>({});

  const [registerError, setRegisterError] = useState<any>({});

  const { search } = useLocation();

  const { postResponseUserConnected, responses } = useResponseUser();
  const { language } = useOfflineLanguage();
  const history = useHistory();

  const cls = style();

  const verifyFormulaire = () => {
    const errorForm: any = { ...registerError };

    if (!current.phone || current.phone.length <= 2) {
      errorForm.phone = 'Champ obligatoire';
    } else {
      delete errorForm.phone;
    }

    if (!current.postal_code) {
      errorForm.postal_code = 'Champ obligatoire';
    } else {
      delete errorForm.postal_code;
    }

    setRegisterError(errorForm);

    if (Object.keys(errorForm).length) {
      return true;
    }

    return false;
  };

  const onSubmitHandler = async (data: any) => {
    data.preventDefault();

    data.stopPropagation();

    if (verifyFormulaire()) {
      return;
    }

    setIsLoading(true);
    try {
      const from = new URLSearchParams(search).get('from');
      const secondStepData = { ...current };
      // Persist + part
      secondStepData.phone = `+${secondStepData.phone}`;
      // check if french phone number
      if (current?.phone?.startsWith(330)) {
        // Remove zero if second number part start with it
        const secondPart = current?.phone?.substring(3);
        secondStepData.phone = `+33${secondPart}`;
      }
      const registerData = await userService.registerUser({
        ...dataStep1,
        ...secondStepData,
        language: language === 'GB' ? 2 : 1,
        fromTest: from === 'test',
        company: 1,
        username: dataStep1.email,
      });

      if (registerData?.data?.jwt) {
        new Promise((resolve: any) => {
          localStorage.setItem('userInfos', JSON.stringify(registerData?.data?.user));
          localStorage.setItem('userToken', registerData?.data?.jwt);
          resolve(registerData?.data?.jwt);
        }).then(async (token) => {
          if (responses) {
            const dataToPost = {
              ...responses,
              users_permissions_users: [registerData?.data?.user.id],
            };
            const datareturn = await postResponseUserConnected(dataToPost, token);
            history.push(`/congrats/${datareturn.id}`);
          } else {
            // eslint-disable-next-line no-unused-expressions
            registerData?.data?.user?.role?.id === 3
              ? history.push('/results')
              : history.push('/main');
          }
        });
      } else if (registerData?.data?.user?.confirmed === false) {
        handleAccountConfirm(true);
      }
    } catch (error) {
      setRegisterError('Une erreur est survenue');
    } finally {
      setIsLoading(false);
    }
  };

  const phoneNumberChange = (value: any) => {
    setCurrent({
      ...current,
      phone: value,
    });
  };

  const handleChange = (data: any) => {
    const { name, value } = data.target;

    setCurrent({
      ...current,
      [name]: value,
    });
  };

  const goToPreviousStep = (e: any) => {
    handlePrevious({ data: dataStep1 });
  };

  return (
    <>
      {showAccountConfirm && (
        <AuthFormHeader
          title={locale.title}
          subtitle={locale.subtitle}
          questionText={locale.lreadyRegisteredText || ''}
          redirectText={locale.ConnectText}
          linkTo="/"
          showAccountConfirm={showAccountConfirm}
          handleAccountConfirm={handleAccountConfirm}
          noSubtitle={true}
        />
      )}
      {!showAccountConfirm && (
        <div className={cls.formContainer}>
          <div className={cls.h2Tittle}>
            {ReactHtmlParser(
              `Pour finaliser votre inscription, merci de nous indiquer votre numéro de téléphone et votre code postal qui ne seront utilisés que par les équipes ORIENTACTION.`,
            )}
          </div>

          <form onSubmit={onSubmitHandler} className={cls.form}>
            <div className={cls.phoneField}>
              <h5 className={cls.labelText}>N° de téléphone</h5>

              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                specialLabel=""
                country="fr"
                value={current.phone || ''}
                onChange={phoneNumberChange}
                masks={{
                  fr: '..........',
                }}
                preferredCountries={['fr', 'be', 'ch', 'ca']}
                preserveOrder={['preferredCountries']}
                inputStyle={{
                  width: '100%',
                }}
              />

              {registerError?.phone && (
                <span className={cls.errorField}>{registerError.phone}</span>
              )}
            </div>

            <div className={cls.fieldWithLabel}>
              <h5 className={cls.labelText}>Code postal</h5>

              <Input
                placeholder="Entrez votre code postal"
                name="postal_code"
                onChange={handleChange}
                type="text"
                className={cls.input}
              />

              {registerError?.postal_code && (
                <span className={cls.errorField}>{registerError.postal_code}</span>
              )}
            </div>

            <Grid container={true} spacing={1}>
              <Grid item={true} xs={6}>
                <Button className={cls.previousBtn} onClick={goToPreviousStep} disabled={isLoading}>
                  Précédent
                </Button>
              </Grid>

              <Grid item={true} xs={6}>
                <Button
                  type="submit"
                  className={cls.btnConnect}
                  disabled={isLoading}
                  data-cy="save-user"
                >
                  {isLoading ? `${locale.registrationText}...` : locale.registrationText}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </>
  );
};

export default RegisterForm;
