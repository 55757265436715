import { FC, useState } from 'react';
import AuthDialog from '../Dialog';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import style from './style';

const firstStepTitle =
  'Créez gratuitement un compte pour accéder à vos résultats et mieux vous connaître !';
const secondStepTitle =
  'Pour finaliser votre inscription, merci de nous indiquer votre numéro de téléphone et votre code postal qui ne seront utilisés que par les équipes ORIENTACTION.';

interface IProps {
  handleAccountConfirm: any;
  showAccountConfirm: boolean;
}

const SignupContainer: FC<IProps> = ({ handleAccountConfirm, showAccountConfirm }) => {
  const [step, setStep] = useState(0);
  const [current, setCurrent] = useState<any>({});

  const classes = style();

  const handlePrevious = ({ data }: any) => {
    setStep(0);
    setCurrent(data);
  };

  const title = step === 0 ? firstStepTitle : secondStepTitle;
  const overrideTitleStyle =
    step === 0 ? classes.firstStepTitleStyle : classes.secondStepTitleStyle;

  return (
    <AuthDialog title={title} overrideTitleStyle={overrideTitleStyle}>
      {step === 0 ? (
        <FirstStep
          nextStep={setStep}
          setData={setCurrent}
          showAccountConfirm={showAccountConfirm}
          handleAccountConfirm={handleAccountConfirm}
          dataStep1={current}
        />
      ) : (
        <SecondStep
          handlePrevious={handlePrevious}
          dataStep1={current}
          handleAccountConfirm={handleAccountConfirm}
          showAccountConfirm={showAccountConfirm}
        />
      )}
    </AuthDialog>
  );
};

export default SignupContainer;
