/* eslint-disable react-hooks/exhaustive-deps */
import { servicesService } from '@orientaction/services';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FORM_MODE, oaServices } from '../constant';
import { UserFormContext } from '../Context/UserFormContext';

const useServices = (token: string, userId: number, mode: string) => {
  const [loading, setLoading] = useState(false);

  const { updateFormUser, formUser } = useContext<any>(UserFormContext);

  const getServices = useCallback(async () => {
    setLoading(true);
    try {
      const serviceData = oaServices;
      if (mode === FORM_MODE.create) {
        updateFormUser({ ...formUser, services: serviceData });
      } else {
        // fetch services in which user has rights
        const {
          data: { data: userServiceData },
        } = await servicesService.getUserServices(token, userId);
        if (userServiceData?.length > 0) {
          // user has one or more services
          const servicesWithRights = serviceData?.map((service: any) => {
            if (userServiceData?.find((s: any) => +s.id === +service.id)) {
              return {
                ...service,
                isChecked: true,
              };
            }
            return {
              ...service,
              isChecked: false,
            };
          });
          updateFormUser({ ...formUser, services: servicesWithRights });
        } else {
          updateFormUser({ ...formUser, services: serviceData });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [mode, token, userId]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  return {
    loading,
  };
};

export default useServices;
