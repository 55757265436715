/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, imgBookIntro, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page23 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={23}>
      <div style={{ marginBottom: '20px' }}>
        <div className={classes.cardBgGrey} style={{ marginTop: '30px' }}>
          <div style={{ marginBottom: '50px' }}>
            {/* <img className={classes.imgTailleur} src={`${imgBookIntro}/titre35.png`} alt="logo" /> */}
            <SimpleBanner>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                PERDU AU MILIEU DE L&rsquo;OCÉAN
              </Typography>
            </SimpleBanner>
          </div>
          <div>
            <img
              className={classes.img}
              style={{ marginLeft: 20 }}
              src={`${imgBookIntro}/marin.png`}
              alt="marin"
            />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Un marin était parti pour un long voyage en mer. Son bateau fut pris dans une tempête
              et une vague l’emporta. Il se retrouva seul au milieu de l’océan. Il faisait nuit. Il
              avait froid. Accroché à un morceau de bastingage qui flottait, balloté par la houle,
              il se posa cette question :
              <br />« Dois-je continuer à nager, à résister à la fatigue et au froid ou dois-je me
              résigner à mon sort et mourir? »
            </Typography>

            {/* <Grid item={true} xs={12}>
            <img className={classes.textImg} src={`${imgBook1}/equilibre.png`} alt="logo" />
          </Grid> */}
          </div>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Alors qu’il s’interrogeait ainsi, le soleil se leva et illumina l’océan. Il regarda tout
            autour de lui, il n’y avait que de l’eau. C’est alors qu’il aperçut au loin un point
            sombre. Il se frotta les yeux, croyant rêver. Mais non, il y avait bien une île.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Elle se trouvait à plusieurs dizaines de kilomètres, à une distance infranchissable pour
            un homme, mais elle existait bel et bien. Le marin s’agrippa à sa planche et se mit à
            nager en direction de l’île. Il avançait très lentement.
          </Typography>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '10px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}
          >
            Le jour, le soleil lui brûlait le visage, la nuit il était tétanisé de froid. Il avait
            mal partout, mais la douleur lui importait peu maintenant, car il avait un objectif. Son
            action avait un sens. Quelques jours plus tard, les indigènes recueillirent un homme
            épuisé sur une plage de leur île.
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <TitleNbr number={2} text="SE LAISSER ALLER AU TRAIN-TRAIN QUOTIDIEN" />
        <Grid container={true} spacing={1} justifyContent="center">
          <div>
            <img
              className={classes.imgFloat}
              src={`${imgBookIntro}/bureaucrate.png`}
              alt="bureaucrate"
            />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Suivre ses habitudes, c’est comme être en pilotage automatique. Quand vous êtes en
              pilotage automatique, vous n’avez pas besoin de réfléchir à ce que vous faites.
            </Typography>
            <br />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Vous suivez un scénario écrit d’avance, sans même avoir à y penser, comme si un petit
              programme à l’intérieur de votre inconscient prenait des décisions à votre place et
              dirigeait vos comportements. C’est pratique, confortable et très utile, car cela vous
              permet de gagner du temps et de vous épargner des efforts coûteux et inutiles.
            </Typography>
            <br />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Les habitudes ont les inconvénients de leurs avantages. Elles nous permettent de
              réaliser tout un tas de choses sans y penser de façon efficace, mais en même temps
              elles nous contraignent. Le jour où nous voulons changer, nous sommes comme un train
              prisonnier de ses rails, qui fait toujours le même trajet aux mêmes horaires.
            </Typography>
            <br />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Abandonner une habitude nécessite un effort extraordinaire. Cela nous place dans une
              situation que nous ne maîtrisons pas en totalité. Cela nous fait peur. Il faut tout
              réapprendre, retrouver des repères, reconstruire de nouvelles habitudes.
            </Typography>
            <br />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              Les habitudes nous empêchent de découvrir des choses nouvelles, de rencontrer des
              personnes nouvelles, de nous offrir des opportunités nouvelles. Les chanceux(ses) le
              savent, la chance déteste la routine et les habitudes ! Cela est valable sur le plan
              personnel comme sur le plan professionnel.
            </Typography>
            <br />
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}
            >
              La meilleure décision à prendre quand on veut évoluer sur le plan personnel et
              professionnel est déjà de rompre avec ses habitudes. Pour évoluer professionnellement,
              il vous faut renoncer à vos habitudes.
            </Typography>
          </div>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    childrenContainerOverride: {
      marginTop: -42,
    },
    bannerStyleOverride: {
      width: 400,
      height: 125,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
      '@media only screen and (max-width: 600px)': {
        width: '90%',
        float: 'none',
        marginTop: '0px',
      },
      '@media only screen and (max-width: 361px)': {
        width: '90%',
        float: 'none',
        marginTop: '0px',
      },
    },
    imgTailleur: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '105%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '105%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    textImg: {
      width: '40%',
      height: 'auto',
    },
    textWithImageContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    imgFloat: {
      width: '40%',
      height: 'auto',
      display: 'block',
      marginLeft: '20px',
      marginTop: '-10px',
      float: 'right',
      '@media only screen and (max-width: 600px)': {
        width: '40%',
        marginTop: '10px',
      },
      '@media only screen and (max-width: 361px)': {
        width: '35%',
        marginTop: '10px',
      },
    },
  }),
);
export default Page23;
