/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../../store';

export const setYourValue = (data: string): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'YOURVALUE',
            payload: { ...data as any }
        });
    };


export const setIndoorWeather = (nbr: number): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'INDOORWEATHER',
            payload: nbr,
        });
    };


export const setMeanToYou = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'MEANTOYOU',
            payload: data,
        });
    };


export const setProjectMakeSense = (data: string): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'PROJECTMAKESENSE',
            payload: data,
        });
    };


export const setSuccessLife = (data: any[]): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'SUCCEELIFE',
            payload: data,
        });
    };

export const setMajory_A = (data: any[]): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'MAJORY_A',
            payload: data,
        });
    };

export const setMajory_B = (data: any[]): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'MAJORY_B',
            payload: data,
        });
    };

export const setPersonal_aspiratiom = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'PERSONALASPIRATIONS',
            payload: data,
        });
    };

export const setFive_Jobs = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'FIVE_JOBS',
            payload: data,
        });
    };

export const setFigureOfDestiny = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'FIGUREOFDESTIN',
            payload: data,
        });
    };
export const setObjectifSupreme = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'OBJECTIF_SUPREME',
            payload: data,
        });
    };

export const setId = (data: any): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'ID',
            payload: data,
        });
    };

