import { ApiActions } from '@orientaction/api-actions';
import { NeedsTestResultService } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '../utils/utils';

const useNeedsTestResult = () => {
  const dispatch = useDispatch();

  const needsTestResultsState: any = useSelector((state: any) => state?.api?.needsTestResults);

  const token = getFromLS('userToken') || '';

  const getNeedsTestResults = async () => {
    try {
      const needsTestResults = await NeedsTestResultService.getNeedsTestResults(token);
      dispatch(ApiActions.setNeedsTestResults(needsTestResults?.data?.data));
    } catch (error) {
      console.log('error on getting needsTestResults ', error);
    }
  };

  const getMyResult = async (id: number) => {
    return NeedsTestResultService.getMyResultNeed(token, id);
  };

  const getNeedsTestResultDetails = async (data: any) => {
    return NeedsTestResultService.getNeedsTestResultDetails(token, data);
  };

  return {
    needsTestResults: needsTestResultsState,
    needsTestResultsState,
    getNeedsTestResults,
    getMyResult,
    getNeedsTestResultDetails,
  };
};

export default useNeedsTestResult;
