import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: '10px',
      overflow: 'hidden',
    },
    cardBord: {
      borderRadius: 10,
      border: '1px solid white',
    },
    titleContent: {
      fontSize: 17,
      lineHeight: '25px',
      color: 'white',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    titleContent0: {
      fontSize: 20,
      lineHeight: '25px',
      color: 'white',
      width: 'fit-content',
      textAlign: 'center',
      padding: '20px 40px',
      position: 'relative',
      transform: 'translateY(-50%)',
      background: '#0E1444',
      margin: 'auto',
      textTransform: 'none',
    },
    paddingCard: {
      padding: '0px 60px 50px 60px',
      '@media only screen and (max-width: 600px)': {
        padding: '0px 20px 25px 20px',
      },
      '@media only screen and (max-width: 361px)': {
        padding: '0px 20px 25px 20px',
      },
    },
    imgCard: {
      width: '32%',
      height: 'auto',
      margin: 'auto',
      display: 'block',
      marginTop: 20,
      marginBottom: 20,
      '@media only screen and (max-width: 600px)': {
        width: '65%',
        marginTop: 20,
      },
      '@media only screen and (max-width: 361px)': {
        width: '65%',
      },
    },
    imgCardMobile: {
      '@media only screen and (max-width: 600px)': {
        width: '25% !important',
        marginTop: '40px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '25% !important',
        marginTop: '40px !important',
      },
    },
    description: {
      textAlign: 'center',
      color: 'white',
    },
    btnCard: {
      borderRadius: 50,
      display: 'block',
      margin: '20px auto',
      textTransform: 'none',
      padding: '9px 20px 10px',
      marginBottom: 0,
      '@media only screen and (max-width: 600px)': {
        marginBottom: 20,
      },
      '@media only screen and (max-width: 361px)': {
        marginBottom: 20,
      },
    },
    paddingCard1: {
      padding: '0px 75px',
    },
    cardRed: {
      background: '#E63C3C',
      borderRadius: 10,
      height: '100%',
    },
    cardTopRed: {
      width: '100%',
      height: '15px',
      background: '#AA2C2C',
    },
    paddingCard2: {
      padding: '0px 20px 20px 20px',
    }
  })
);
