/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { gameBookTemplateActions } from '@orientaction/api-actions';
import ContainerAdmin from '@orientaction/commons/ContainerAdmin';
import MarginContainer from '@orientaction/commons/MarginContainer';
import { Layout } from '@orientaction/components';
import TestMainImage from '@orientaction/components/Admin/Test/Create/TestMainInfo/LeftImage/TestMainImage';
import { gameBookValues, gameVariables } from '@orientaction/reducers';
import { getFromLS } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import style from '../style';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span
        style={{
          fontSize: 26,
          margin: 8,
          border: '1px solid #E4E4F2',
          borderRadius: '50%',
          width: 36,
          height: 36,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        +
      </span>
      <span style={{ fontSize: 16 }}>Importer une image</span>
      <span style={{ color: 'black', marginTop: 10 }}>Résolution idéale : 260 x 364 px</span>
    </div>
  );
};

const CreateGame = (props: any) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const token = getFromLS('userToken') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleFileUrlChange = (urlC: string) => {
    setUrl(urlC);
    dispatch(gameBookTemplateActions.updateField('couvTemp', urlC));
  };

  const handleFileChange = (fileC: any) => {
    setFile(fileC);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setUrl('');
    dispatch(gameBookTemplateActions.updateField('couvTemp', ''));
  };

  const handleFieldChange = (field: string, value: any) => {
    dispatch(gameBookTemplateActions.updateField(field, value));
  };
  const valuesBooksTemplate = useSelector(gameBookValues);
  const valuesVariables = useSelector(gameVariables);

  const postGame = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('file.img', file as any);
    dispatch(gameBookTemplateActions.updateField('file.img', file));
    history.push(`/admin/game/selected/main-info
    `);
    /* try {
      const response = await apiServiceNative.post('/api/create-game', formData, true);

      if (response) {
        setLoading(false);
        setFile(null);
        setUrl('');
        setTitle('');

      }
    } catch (error) {
      console.error('Erreur lors de la création du jeu :', error);
      setLoading(false);
    } */
  };
  const optionsValue: any = [
    {
      value: 'classic',
      label: 'Classique',
    },
    {
      value: 'game',
      label: 'De jeu',
    },
  ];
  const [selectTypeGame, setSelectTypeGame] = useState<any>(optionsValue[0]);

  useEffect(() => {
    if (valuesBooksTemplate.typeGame === 'game') {
      console.log({valuesBooksTemplate})
      setSelectTypeGame(optionsValue[1]);
    }
  }, [valuesBooksTemplate]);

  return (
    <Layout isaddBackground={true} showOverFlow={true}>
      <ContainerAdmin>
        <MarginContainer big={true}>
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item={true} sm={12}>
              <Typography variant="h2" style={{ textTransform: 'none', textAlign: 'center' }}>
                Création d’un jeu
              </Typography>
            </Grid>
          </Grid>
          <span className={classes.separator} />
          <Grid
            container={true}
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item={true} sm={5} style={{ display: 'flex', justifyContent: 'center' }}>
              <div>
                <div className={classes.blockSelectType}>
                  <h3 className={classes.label}>Type de jeu</h3>
                  <div className={classes.blockBtnSelect}>
                    <Select
                      className={classes.select}
                      options={optionsValue}
                      value={selectTypeGame}
                      onChange={selected => {
                        setSelectTypeGame(selected);
                        handleFieldChange('typeGame', selected?.value);
                      }}
                    />
                    {selectTypeGame.value === 'game' && (
                      <Button variant="contained" color="primary" className={classes.btnPrimary} onClick={() => history.push('/admin/game/create-variables')}>
                        Éditer les variables
                      </Button>
                    )}
                  </div>
                </div>
                <div className={classes.card}>
                  <h3 className={classes.label}>Titre du jeu</h3>
                  <TextField
                    id="create-test-title"
                    variant="outlined"
                    className={classes.formInput}
                    name="test-name"
                    value={valuesBooksTemplate?.titlGame || title}
                    onChange={e => {
                      handleFieldChange('titlGame', e.target.value);
                      handleFieldChange('title', e.target.value);
                    }}
                    placeholder="Indiquer ici le titre du jeu"
                  />

                  <h3 className={classes.label} style={{ marginTop: '25px' }}>
                    Image de la couverture
                  </h3>
                  <div style={{ position: 'relative' }}>
                    {!!file && (
                      <span className={classes.closeBtn} onClick={handleDeleteFile}>
                        x
                      </span>
                    )}
                    <TestMainImage
                      fileUrl={valuesBooksTemplate?.couvTemp}
                      handleFileUrlChange={handleFileUrlChange}
                      handleFileChange={handleFileChange}
                      placeholder={placeholder}
                      pathPrefix="tests"
                      sectionTop={true}
                      useForGame={true}
                    />
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !valuesBooksTemplate?.couvTemp || !valuesBooksTemplate?.titlGame || loading
                  }
                  className={classes.btnPrimary}
                  style={{ width: '100%', margin: '20px 0px' }}
                  onClick={postGame}>
                  Commencer la création du jeu
                </Button>
              </div>
            </Grid>
          </Grid>
        </MarginContainer>
      </ContainerAdmin>
    </Layout>
  );
};

export default CreateGame;
