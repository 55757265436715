/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Title } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, publicBookPath, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page13 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={13}>
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${publicBookPath}/titre3.png`} alt="logo" /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LE MARCHAND DEVENU SAGE
            </Typography>
          </SimpleBanner>
        </div>
        <Grid container={true} justifyContent="center">
          <Grid xs={5}>
            <img className={classes.img} src={`${imgBook1}/equilibre.png`} alt="logo" />
          </Grid>
        </Grid>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          À vingt-cinq ans, Christian Fabre quitte la France pour l’Inde avec femme et enfants. Il
          commence par travailler comme employé dans une société de cuir à Madras. C’est un homme
          d’affaires redoutable qui a des ambitions. Il veut faire fortune.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Vingt ans plus tard, il dirige la plus grande société de « sourcing » de toute l’Inde du
          Sud et fournit en tissus et vêtements les plus grandes multinationales du monde. Il donne
          du travail à plus de 60 000 personnes réparties dans vingt-trois usines. Il a réussi DANS
          la vie.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          C’est alors que s’opère en lui un profond changement. Il se rend compte que son style de
          vie est néfaste pour lui-même et pour les autres. Il décide alors de changer radicalement,
          se forme et devient Swami, c’est-à-dire un sage dans la religion hindouiste. Il abandonne
          ses vêtements à l’occidentale, renonce à son salaire et à l’ensemble des privilèges liés à
          sa fonction.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Pour finir, il abandonne la propriété de son entreprise mais, sans renoncer à ses
          fonctions de PDG, il continue de la diriger.
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    childrenContainerOverride: {
      marginTop: -42,
    },
    bannerStyleOverride: {
      width: 400,
      height: 125,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '90%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page13;
