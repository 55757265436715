import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      margin: '0 auto',
      [theme.breakpoints.up(1697)]: {
        width: '85%',
      },
      [theme.breakpoints.down(500)]: {
        width: '95%',
      },
    },
    contentLoader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
    btnHistory: {
      position: 'fixed',
      top: '13vh',
      right: 25,
      zIndex: 20,
      [theme.breakpoints.down(800)]: {
        top: 'auto !important',
        bottom: '20px',
        position: 'fixed',
      },
    },
    titleText: {
      fontSize: 20,
      color: 'white',
      textAlign: 'center',
      marginTop: 60,
    },
    closeBtn: {
      position: 'fixed',
      top: 10,
      right: 25,
      zIndex: 7,
      color: 'white !important',
      [theme.breakpoints.down(800)]: {
        top: -7,
      },
    },
    printBtn: {
      position: 'fixed',
      top: 10,
      right: 80,
      zIndex: 7,
      color: 'white !important',
      [theme.breakpoints.down(800)]: {
        top: -7,
      },
    },
    maxWidthPaper: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '1360px !important',
        width: '75%',
        [theme.breakpoints.down(800)]: {
          margin: '0px !important',
          width: '95%',
        },
      },
    },
  })
);
