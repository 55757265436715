/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Checkbox, Divider, Typography, CircularProgress } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useMajorTest } from '@orientaction/hooks';
import style from './style';
import { MarginContainer, ContentCenter } from '@orientaction/commons';

interface IMajorTestSelection {
  handleMajorTestChange: (data: any) => void;
  current: any;
}

const MajorTestSelection: FC<IMajorTestSelection> = ({ handleMajorTestChange, current }) => {
  const { listMajorTest, loading, getListMajorTest } = useMajorTest();

  const classes = style();

  const handleChange = (id: any) => (e: any) => {
    handleMajorTestChange({ id, e });
  };

  useEffect(() => {
    getListMajorTest();
  }, []);

  return (
    <div>
      <Divider style={{ marginTop: 20, marginBottom: 30 }} />

      <MarginContainer>
        <Typography className={classes.bookChoiceLabel}> Tests majeurs autorisés </Typography>

        {loading ? (
          <>
            <ContentCenter>
              <CircularProgress />
            </ContentCenter>
          </>
        ) : (
          <>
            {listMajorTest?.map((majorTest: any) => (
              <div key={majorTest.id} className={classes.bookChoiceContainer}>
                <Checkbox
                  checked={!!current?.[majorTest.id]}
                  color="primary"
                  onChange={handleChange(majorTest.id)}
                />{' '}
                <span>{majorTest?.name ?? ''}</span>
              </div>
            ))}
          </>
        )}
      </MarginContainer>
    </div>
  );
};

export default MajorTestSelection;
