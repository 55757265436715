import classNames from 'classnames';
import { FC } from 'react';
import style from './style';

interface IProps {
  text?: string;
  hasError?: boolean;
}

const OaLabel: FC<IProps> = ({ text = '', hasError, children }) => {
  const classes = style();
  return (
    <h3 className={classNames(classes.labelContainer, hasError && classes.error)}>
      {children || text}
    </h3>
  );
};

export default OaLabel;
