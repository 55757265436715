import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    button: {},
    flexContainer: {},
    subtitle: {
      color: '#000A8C',
      fontSize: 26,
      marginTop: 15,
    },
    stockageMode: {
      '&>svg': {
        color: '#0E1247',
      },
      '&>fieldset': {
        borderColor: '#0E1247',
      },
      '&>div': {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    select: {
      fontFamily: 'Poppins',
    },
    marginTop: {
      marginTop: 15,
    },
  })
);

export default useStyles;
