/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */

import { AppThunk } from '../store';

export const updateField =
  (field: string, value: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: { field, value },
    });
  };

export const resetValues = (): AppThunk => (dispatch) => {
  dispatch({
    type: 'RESET_VALUES',
  });
};

export const setAllValues =
  (payload: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'SET_ALL_VALUES',
      payload,
    });
  };
