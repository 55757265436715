/* eslint-disable no-return-await */
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import uuid from 'uuid';

export const uploadImageAsync = async (uri: string, prefix: string) => {
  const blob: any = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.onerror = e => {
      reject(new TypeError('Network request failed'));
    };
    xhr.responseType = 'blob';
    xhr.open('GET', uri, true);
    xhr.send(null);
  });
  const fileRef = ref(getStorage(), `${prefix}/${uuid.v4()}`);
  await uploadBytes(fileRef, blob);

  // We're done with the blob, close and release it
  // blob.close();

  return await getDownloadURL(fileRef);
};
