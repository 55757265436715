// eslint-disable-next-line simple-import-sort/imports
import { Page, Somaire } from '@orientaction/components';

const Page6 = (props: any) => {

    return (
        <Page
            id={6}
        >

            <Somaire
                list={[
                    {
                        text: "POURQUOI DONNER DU SENS À VOTRE VIE PROFESSIONNELLE ?",
                        number: "7",
                        isBold: true
                    },
                    {
                        text: "QUEL EST L’OBJECTIF SUPRÊME DE VOTRE VIE ?",
                        number: "10",
                        isBold: true
                    },
                    {
                        text: "IDENTIFIEZ VOTRE OBJECTIF SUPRÊME",
                        number: "11"
                    },
                    {
                        text: "TEST : RÉUSSIR DANS LA VIE OU RÉUSSIR SA VIE ?",
                        number: "15"
                    },
                    {
                        text: "CONCRÈTEMENT, QUELLES SONT VOS ASPIRATIONS PERSONNELLES ET PROFESSIONNELLES ?",
                        number: "18"
                    },
                    {
                        text: "QUEL EST VOTRE DESTIN ?",
                        number: "19",
                        isBold: true
                    },
                    {
                        text: "CHOISISSEZ VOTRE DESTIN",
                        number: "20",
                    },
                    {
                        text: "LES 32 FIGURES DU DESTIN",
                        number: "22"
                    },
                    {
                        text: "EXEMPLES DE MÉTIERS CORRESPONDANT À CHAQUE FIGURE DU DESTIN",
                        number: "27"
                    },
                    {
                        text: "QUELLES SONT VOS VALEURS ?",
                        number: "31",
                        isBold: true
                    },
                    {
                        text: "MIEUX CONNAÎTRE VOS VALEURS",
                        number: "32"
                    },
                    {
                        text: "TEST : MIEUX VOUS CONNAÎTRE",
                        number: "34"
                    },
                    {
                        text: "DESCRIPTION DE VOS VALEURS",
                        number: "35"
                    },
                    {
                        text: "SYNTHÈSE DE VOTRE TRAVAIL DE RÉFLEXION",
                        number: "40",
                        isBold: true
                    },
                ]}
            />
        </Page>
    );
};

export default Page6;
