import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from './style';

const CircularProgressCustom = (props: any) => {
  const { value, color, size, fontSize, fontSizeFloat, isPercent, totalValue, calculatedValue } =
    props;
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      style={{ background: 'white', borderRadius: '50%' }}
    >
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={size}
        thickness={3}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink={true}
        className={classes.top}
        size={size}
        thickness={3}
        value={calculatedValue || value}
        style={{ color }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
      >
        <Typography
          className={classes.textContainer}
          variant="caption"
          component="div"
          color="textSecondary"
        >
          <span className={classes.textValue} style={{ color, fontSize }}>
            {value}
            {isPercent && '%'}
          </span>
          {!isPercent && (
            <span className={classes.textFLoating} style={{ color, fontSize: fontSizeFloat }}>
              /{totalValue || 100}
            </span>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressCustom;
