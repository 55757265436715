/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useCompassMenu } from '@orientaction/hooks';
import { FC, useState } from 'react';
import aiguille from './aiguille.png';
import clsx from 'clsx';
import BtnLink from '../BtnRedirect';


interface IAnimation {
  isActive: boolean;
}

const Animation: FC<IAnimation> = ({
  isActive
}) => {

  const [isDragging, setIsDragging] = useState(false);

  const { setRorate, rotate } = useCompassMenu();

  const onMouseDown = () => {

    if (!isActive) {
      return
    }

    setIsDragging(true);

  }

  const onMouseUp = () => {

    if (!isActive) {
      return
    }

    setIsDragging(false);

  }

  const handleMouseMove = (event: any) => {

    if (!isActive) {
      return
    }

    if (!isDragging) {
      return
    }

    const rect = event.target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const deltaX = event.clientX - centerX;
    const deltaY = event.clientY - centerY;
    const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    setRorate({
      ...rotate,
      deg: angle
    });

  };

  const handleToucheMove = (event: any) => {

    if (!isActive) {
      return
    }

    document.body.style.overflow = 'hidden';

    const rect = event.target.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const deltaX = event.changedTouches[0].clientX - centerX;
    const deltaY = event.changedTouches[0].clientY - centerY;
    const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    setRorate({
      ...rotate,
      deg: angle
    });

  }

  const handleToucheEnd = () => {
    document.body.style.overflow = 'scroll';
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: 'relative',
        width: '80%',
        paddingBottom: '80%',
        backgroundImage: 'url(/images/Background/Boussolle.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.down(800)]: {
          width: '100%',
        }
      },
      animatedItem: {
        animation: `$myEffect 2500ms infinite`,
      },
      "@keyframes myEffect": {
        "0%": {
          transform: `rotate(${rotate.deg + 2}deg)`
        },
        "50%": {
          transform: `rotate(${rotate.deg - 2}deg)`
        },
        "100%": {
          transform: `rotate(${rotate.deg + 2}deg)`
        }
      },
      nord: {
        position: 'absolute',
        width: '50%'
      }
    }),
  );

  const classe = useStyles();

  return (
    <BtnLink isActive={isActive}>

      <div className={classe.root}>
        <div
          className={clsx({
            [classe.animatedItem]: !isActive
          })}
          draggable={false}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: `rotate(${rotate.deg}deg)`,
            cursor: isActive ? (isDragging) ? 'grabbing' : 'move' : 'default',
          }}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={handleMouseMove}
          onTouchMove={handleToucheMove}
          onTouchEnd={handleToucheEnd}
        >
          <div
            draggable={false}
            style={{
              width: '80%',
              paddingBottom: '80%',
              backgroundImage: `url(${aiguille})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          />
        </div>

      </div>

    </BtnLink>
  );
};

export default Animation;
