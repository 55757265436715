import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const url = config.api_url;
export const getMyResultStudy = async (token: string, id: number) => {
  return makeRequest(`${url}/api/get-result-study-test/${id}`, 'GET', {}, getHeaders(token));
};

export const getPublicResultStudy = async (id: number) => {
  return makeRequest(`${url}/api/get-public-result-study-test/${id}`, 'GET', {});
};

export const makeStudyResultPublic = async (id: any, token: string) => {
  return makeRequest(
    `${url}/api/study-test-results/${id}`,
    'PUT',
    { data: { isPublic: true } },
    getHeaders(token)
  );
};
