import { MarginContainer } from '@orientaction/commons';
import clsx from 'clsx';
import { FC } from 'react';
import useStyles from './style';

interface IProps {
  children: any;
  overridecardBgGreyStyle?: any;
}

const BlockWithBackground: FC<IProps> = ({ children, overridecardBgGreyStyle = undefined }) => {
  const classes = useStyles();
  return (
    <div
      className={
        overridecardBgGreyStyle
          ? clsx(overridecardBgGreyStyle, classes.cardBgGrey)
          : classes.cardBgGrey
      }>
      {children}
    </div>
  );
};

export default BlockWithBackground;
