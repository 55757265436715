import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    root: {
        margin: '0',
        paddingTop: '2vh',
        paddingBottom: '2vh',
        paddingLeft: '1vh',
        paddingRight: '1vh',
        background: '#e8eef4'
    },
    span: {
        color: 'white',
        background: '#f1665f',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },
    text: {
        width: '100%'
    },
    container: {
        margin: '3.5vh 0',
    },
    margin: {
        marginBottom: '1vh'
    }
}));
