/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { useStyles } from './style';
import { useAlert, useMeaningValueTestResult, useMyResulatOfTest } from '@orientaction/hooks';
import { useMediaQuery, Theme, Typography, IconButton, CircularProgress } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


interface IDrawerView {
    open: boolean;
    handleClose: () => void;
}

const DrawerView: FC<IDrawerView> = ({
    open, handleClose
}) => {

    const { getMyResumeElement } = useMeaningValueTestResult();

    const [loading, setLoading] = useState(false);

    const { setMyElement, myElement, dialog } = useMyResulatOfTest();

    const alert = useAlert();

    const fetchData = async () => {
        setLoading(true);

        try {
            const reponse = await getMyResumeElement({ ...dialog.data });
            setMyElement(reponse.data.data);
        } catch (err: any) {
            alert.setOpenAlert(
                true,
                'Impossible de recuperer ses elements .',
                'error'
            );
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [open])

    const classes = useStyles();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={open}
            onClose={handleClose}
            className={classes.drawer}
        >

            <div
                className={clsx({
                    [classes.content]: true,
                    [classes.desktop]: !isMobile,
                    [classes.mobile]: isMobile
                })}
            >

                <div
                    className={classes.handleClose}
                >
                    <IconButton
                        onClick={handleClose}
                    >

                        {
                            isMobile ? (
                                <ArrowBackIcon />
                            ) : (
                                <HighlightOffIcon
                                    className={classes.colorOrange}
                                />
                            )
                        }

                    </IconButton>
                </div>

                <div
                    className={classes.head}
                >

                    <Typography
                        className={clsx({
                            [classes.titleHead]: true,
                        })}
                    >
                        {dialog?.data?.title || ""}
                    </Typography>

                    <Typography
                        className={clsx({
                            [classes.score]: true,
                        })}
                    >
                        {dialog?.data?.nbr || 0}
                    </Typography>

                </div>

                {
                    loading ? (
                        <div
                            className={classes.contentLoader}
                        >
                            <CircularProgress style={{ color: dialog?.data?.color }} />
                        </div>
                    ) : (

                        <div
                            className={classes.contentDrawer}
                        >

                            <Typography
                                component='p'
                                align='center'
                                className={clsx({
                                    [classes.paragraphPrincipe]: true
                                })}
                            >
                                <b> Le principe directeur de votre vie : </b>
                                <i>
                                    {myElement?.yourPrincipe || ""}
                                </i>
                            </Typography>

                            {
                                !isMobile && (
                                    <div
                                        className={classes.imgContent}
                                    >

                                        <img
                                            src={myElement?.imgPath}
                                            className={classes.img}
                                            alt="Apercu"
                                        />

                                    </div>
                                )
                            }

                            <MarginContainer>

                                <div className={classes.contentDescription}>

                                    <Typography
                                        component='p'
                                        className={classes.paragraph}
                                    >
                                        {myElement?.explication || ""}
                                    </Typography>

                                </div>

                            </MarginContainer>

                            {
                                isMobile && (
                                    <div
                                        className={classes.imgContent}
                                    >
                                        <img
                                            src={myElement?.imgPath}
                                            className={classes.img}
                                            alt="Apercu"
                                        />
                                    </div>
                                )
                            }

                        </div>

                    )}

            </div>

        </Drawer >
    );
}


export default DrawerView;