/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page } from '@orientaction/components';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../components/Img';
import { paragraphe } from './context';
import { useStyles } from './style';

const Page10 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={10}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          {/* <Img
            className={classes.imgTailleur}
            src="titre14.png"
            alt="logo"
          /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LE PROJET D’ UNE GIRAFE
            </Typography>
          </SimpleBanner>
        </MarginContainer>

        <MarginContainer>
          <Img className={classes.img} src="illustration_page_14.png" alt="logo" />
        </MarginContainer>

        {paragraphe.map((item: string) => (
          <MarginContainer>
            <Typography className={classes.paragraphe}>{item || ''}</Typography>
          </MarginContainer>
        ))}
      </div>
    </Page>
  );
};
export default Page10;
