import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        head: {
            padding: "1vh 0",
            textAlign: 'center',
            borderBottom: '1px solid',
            background: '#e8eef4',
            fontSize: '1.5vh',
            height: '5vh',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.between(1200, 1300)]: {
                height: '8vh',
            },
        },
        tableContainer: {
            border: '1px solid',
            overflow: 'hidden'
        },
        bodyContent: {
            borderBottom: '0.05px solid',
            overflow: 'hidden',
            [theme.breakpoints.between(1200, 1300)]: {
                height: '17vh',
            },
            [theme.breakpoints.between(1050, 1200)]: {
                height: '22vh',
            },
            [theme.breakpoints.between(950, 1050)]: {
                height: '30vh',
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: '12vh',
            }
        },
        textArea: {
            width: '100%',
            height: '15vh',
        },
    })
);

export default useStyles;