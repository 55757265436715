/* eslint-disable no-octal-escape */
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  dialogContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: 16,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  titleText: {
    fontSize: 17,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginRight: 7,
  },
  titleContent: {
    fontSize: 20,
    lineHeight: '25px',
    marginTop: 30,
    textTransform: 'none',
  },
  titlehead: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    height: '100%',
    maxWidth: '55px',
    overflow: 'hidden',
  },
  positionCircular2: {
    display: 'block',
    '@media only screen and (max-width: 600px)': {
      marginTop: '-30%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: '-30%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  paddingGridtext: {
    padding: '75px 80px 0px',
    paddingBottom: 25,
    '@media only screen and (max-width: 600px)': {
      padding: '0px 25px 0px',
      textAlign: 'left',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '0px 20px 0px',
      textAlign: 'left',
    },
  },
  paddingGridText2: {
    padding: '25px 85px 50px',
    '@media only screen and (max-width: 600px)': {
      padding: '10px 25px 40px',
    },
    '@media only screen and (max-width: 361px)': {
      padding: '10px 20px 40px',
    },
  },
  textConseil: {
    color: 'white',
    marginTop: 20,
    // fontFamily: '"ITC Avant Garde Gothic Std Bold", sans-serif',
  },
  cardBorder: {
    border: '1px solid white',
    borderRadius: '10px',
    padding: '0px 30px 30px',
    marginTop: 75,
    '@media only screen and (max-width: 600px)': {
      padding: '0px 15px 30px',
      marginTop: 40,
    },
    '@media only screen and (max-width: 361px)': {
      padding: '0px 15px 30px',
      marginTop: 40,
    },
  },
  listUl: {
    listStyle: 'none',
    padding: 0,
    paddingLeft: '1em',
    '@media only screen and (max-width: 600px)': {
      marginTop: 0,
      marginBottom: 0,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  listLi: {
    display: 'flex',
    padding: 0,
    '&::before': {
      content: '"\\2022"',
      color: (props: any) => props.color,
      fontWeight: 'bold',
      display: 'inline-block',
      marginRight: 8,
      fontSize: '22px',
      marginLeft: 'calc(-1em + 2px);',
      marginTop: -2,
    },
  },
  widthTitle: {
    fontSize: 22,
    width: '85%',
    lineHeight: 1.5,
    '@media only screen and (max-width: 600px)': {
      width: '100%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '100%',
    },
  },
  text1: {
    '& b': {
      fontWeight: 'bold',
    },
  },
  marginBottomMobile: {
    '@media only screen and (max-width: 600px)': {
      marginBottom: 30,
    },
    '@media only screen and (max-width: 361px)': {
      marginBottom: 20,
    },
  },
  marginTopMobile: {
    '@media only screen and (max-width: 600px)': {
      marginTop: 20,
    },
    '@media only screen and (max-width: 361px)': {
      marginTop: 20,
    },
  },
  titleTextContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    transform: 'rotate(-90deg)',
  },
  body2TextOverride: {
    fontSize: '0.975rem',
  },
  imgHeight: {
    height: '100%',
    '@media only screen and (max-width: 1301px)': {
      height: 'auto',
    },
    '@media only screen and (max-width: 600px)': {
      height: '100%',
    },
    '@media only screen and (max-width: 361px)': {
      height: '100%',
    },
  },
}));
