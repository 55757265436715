/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, MenuItem } from '@material-ui/core';
import { TestContext } from '@orientaction/Context';
import { useCategoryTest } from '@orientaction/hooks';
import { useLocation } from 'react-router-dom';
import { FC, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import style from './style';
import Select from 'react-select';
import { TestStockageSection } from '@orientaction/components';
import clsx from 'clsx';

interface IProps {
  testCategoryRef: any;
}

const Category: FC<IProps> = ({ testCategoryRef }) => {
  const { test, updateTest } = useContext<any>(TestContext);

  const { list, loading, getCategoryTest } = useCategoryTest();

  const location = useLocation();

  const cls = style();

  const handleChange = (event: any) => {
    const newObject = {
      ...test,
      category: event.value,
    };

    if (location.pathname.includes('/edit')) {
      newObject.isUpdatedCategory = true;
    }

    updateTest(newObject);
  };

  useEffect(() => {
    getCategoryTest({
      isDesabled: false,
      isArchived: false,
    });
  }, []);

  const option = (list || ([] as any)).map((item: any) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  return (
    <Grid container={true} spacing={2}>
      <Grid innerRef={testCategoryRef} item={true} xs={3} md={3}>
        <h1
          className={clsx({
            [cls.subtitle]: true,
            [cls.textError]: !!test?.manageError?.category
          })}
        >Catégorie par défaut</h1>

        {loading ? (
          <CircularProgress />
        ) : (
          <Select
            placeholder="Sélectionnez"
            options={option}
            defaultValue={{
              value: 1,
              label: 'Sélectionnez',
            }}
            value={option.find((item: any) => item.value === test?.category)}
            onChange={handleChange}
            className={clsx({
              [cls.formSelect]: true,
              [cls.inputError]: !!test?.manageError?.category
            })}
          />
        )}
      </Grid>

      <Grid item={true} xs={3} md={3}>
        <TestStockageSection />
      </Grid>
    </Grid>
  );
};

export default Category;
