/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TitleNbr, Page, TitleRomain } from '@orientaction/components';
import { useBook2 } from "@orientaction/hooks_book";
import { cloneDeep, debounce } from 'lodash'

let typeTimeout: any;
const Page21 = (props: any) => {
  const classes = useStyles();
  const { setResponse12, setResponse13, setResponse14, response12, response13, response14 } = useBook2();
  const updateValue = debounce((event, index, question) => {
    if (question === 12) {
      handleChangeGlobalState(event, index);
    } else if (question === 13) {
      handleChangeGlobalState1(event, index);
    } else if (question === 14) {
      handleChangeGlobalState2(event);
    }
  }, 1000);
  const onKeyDown = (event: any, index: number, question: number) => {
    if (props.readOnly) return;
    updateValue(event, index, question)
  };

  const handleChange = (e: any, index: number, question: number) => {
    if (props.readOnly) return;
    updateValue(e, index, question)
  };

  const handleChangeGlobalState = (e: any, index: number) => {
    if (props.readOnly) return;
    const { value } = e.target;
    const data = cloneDeep(response12);
    data[index] = value;
    setResponse12(data);
  };
  const handleChangeGlobalState1 = (e: any, index: number) => {
    if (props.readOnly) return;
    const { value } = e.target;
    const data = cloneDeep(response13);
    data[index] = value;
    setResponse13(data);
  };
  const handleChangeGlobalState2 = (e: any) => {
    if (props.readOnly) return;
    const { value } = e.target;
    setResponse14(value);
  };

  return (
    <Page id={21} readOnly={false}>

      <div style={{border: "2px solid grey", padding: "15px"}}>
        <div className={classes.cardBgGrey}>
          <TitleRomain
            number="IV"
            text="VOS BESOINS D’ACCOMPLISSEMENT"
          />
        </div>
        <div style={{ marginTop: '40px'}}>
          <TitleNbr number={12} text="Citez 3 défis que vous avez déjà relevés dans votre vie professionnelle et/ou personnelle et qui vous ont rendu(e) particulièrement fier(ère) ?" isSmall={true} />
          <div style={{ marginTop: '10px'}}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)', paddingRight: 5, paddingBottom: 5, paddingTop: 5}}>
              <Typography>
                Défi n° 1 :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChange(e, 0, 12)}
                onKeyDown={(e: any) => onKeyDown(e, 0, 12)}
                onBlur={(e: any) => handleChangeGlobalState(e, 0)}
                defaultValue={response12[0]}
                placeholder="Aa"
                readOnly={props.readOnly}
                style={{
                    width: "99%",
                    height: "50px",
                    fontSize: '15px',
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                }}
                className={classes.borderText}
              />
            </div>
            <div style={{ paddingLeft: 'calc(30px + 1vh)', paddingRight: 5, paddingBottom: 5, paddingTop: 5}}>
              <Typography>
                Défi n° 2 :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChange(e, 1, 12)}
                onKeyDown={(e: any) => onKeyDown(e, 1, 12)}
                onBlur={(e: any) => handleChangeGlobalState(e, 1)}
                defaultValue={response12[1]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                    width: "99%",
                    height: "50px",
                    fontSize: '15px',
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                }}
                className={classes.borderText}
              />
            </div>
            <div style={{ paddingLeft: 'calc(30px + 1vh)', paddingRight: 5, paddingBottom: 5, paddingTop: 5}}>
              <Typography>
                Défi n° 3 :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChange(e, 2, 12)}
                onKeyDown={(e: any) => onKeyDown(e, 2, 12)}
                onBlur={(e: any) => handleChangeGlobalState(e, 2)}
                defaultValue={response12[2]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                    width: "99%",
                    height: "50px",
                    fontSize: '15px',
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                }}
                className={classes.borderText}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '40px'}}>
          <TitleNbr number={13} text="Quels sont maintenant les trois défis que vous aimeriez relever dans les 3 prochaines années ? Il peut s’agir d’un défi personnel ou professionnel, quelque chose qui vous rendrait fier(ère) si vous parveniez à l’accomplir. " isSmall={true} />
          <div style={{ marginTop: '10px'}}>
            <div style={{ paddingLeft: 'calc(30px + 1vh)', paddingRight: 5, paddingBottom: 5, paddingTop: 5}}>
              <Typography>
                Défi n° 1 :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChange(e, 0, 13)}
                onKeyDown={(e: any) => onKeyDown(e, 0, 13)}
                onBlur={(e: any) => handleChangeGlobalState1(e, 0)}
                defaultValue={response13[0]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                    width: "99%",
                    height: "50px",
                    fontSize: '15px',
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                }}
                className={classes.borderText}
              />
            </div>
            <div style={{ paddingLeft: 'calc(30px + 1vh)', paddingRight: 5, paddingBottom: 5, paddingTop: 5}}>
              <Typography>
                Défi n° 2 :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChange(e, 1, 13)}
                onKeyDown={(e: any) => onKeyDown(e, 1, 13)}
                onBlur={(e: any) => handleChangeGlobalState1(e, 1)}
                defaultValue={response13[1]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                    width: "99%",
                    height: "50px",
                    fontSize: '15px',
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                }}
                className={classes.borderText}
              />
            </div>
            <div style={{ paddingLeft: 'calc(30px + 1vh)', paddingRight: 5, paddingBottom: 5, paddingTop: 5}}>
              <Typography>
                Défi n° 3 :
              </Typography>
              <textarea
                name="name"
                onChange={(e: any) => handleChange(e, 2, 13)}
                onKeyDown={(e: any) => onKeyDown(e, 2, 13)}
                onBlur={(e: any) => handleChangeGlobalState1(e, 2)}
                defaultValue={response13[2]}
                readOnly={props.readOnly}
                placeholder="Aa"
                style={{
                    width: "99%",
                    height: "50px",
                    fontSize: '15px',
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                }}
                className={classes.borderText}
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className={classes.cardBgGrey}>
          <TitleRomain
            number="V"
            text="VOS BESOINS DE RÉALISATION DE SOI"
          />
        </div>
        <Typography style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', fontWeight: 'lighter' }}>
          <span className={classes.number}>14</span>
          <span className={classes.h3}>« Je suis devenu(e) un bon conseiller ou une bonne conseillère commerciale » <br />
          « Je suis devenu(e) un(e) professionnel(le) respecté(e) » <br />
          « Je suis devenu(e) un homme ou une femme affirmé(e)… »</span>
        </Typography>
        <div style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
          <Typography color="primary" style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'bold', fontStyle: 'italic' }}>
            Un être humain se réalise lorsqu’il a le sentiment d’être devenu ce qu’il voulait devenir. <br />
            Et vous, qu’aimeriez-vous devenir dans trois, cinq ou dix ans ? <br />
            Qu’aimeriez-vous pouvoir dire de vous ? <br />
          </Typography>
        </div>
        
        <div className={classes.cardBgGrey}>

          <Typography>
            Je voudrais devenir :
          </Typography>

          <textarea
            name="name"
            onChange={(e: any) => handleChange(e, 0, 14)}
            onKeyDown={(e: any) => onKeyDown(e, 0, 14)}
            onBlur={(e: any) => handleChangeGlobalState2(e)}
            defaultValue={response14}
            readOnly={props.readOnly}
            placeholder="Aa"
            style={{
                width: "99%",
                height: "100px",
                fontSize: '15px',
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
            }}
            className={classes.borderText}
          />

        </div>

      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '15px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#2b56a5',
      textAlign: 'center'
    },
    letter: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10%',
      padding: '7px',
    },
    borderText: {
      border: "2px solid #2b56a5",
      padding: 5,

    },
  })
);
export default Page21;
