/* eslint-disable no-use-before-define */
import { FC, useEffect, useState } from 'react';
import { Paragrapth } from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';

interface IArrayYouContext {
    objectList: any;
}

const ArrayYouContext: FC<IArrayYouContext> = ({ objectList }) => {


    const classes = useStyles();

    const title = Object.keys(objectList);

    return (
        <div
            style={{
                display: 'table-row',
            }}
        >

            {
                title.map((currentObject: any, index: number) =>
                    <div
                        className={classes.tableContainer}
                    >

                        <div className={classes.head}>
                            {objectList[currentObject].label || ""}
                        </div>

                        <div
                            className={classes.body}
                        >

                            {(objectList[currentObject].listParagraph || []).map((item: any) =>
                                <MarginContainer>
                                    <Paragrapth align='justify'>
                                        {item || ''}
                                    </Paragrapth>
                                </MarginContainer>
                            ) || ""}

                        </div>

                    </div>
                )
            }

        </div>
    );

};

export default ArrayYouContext;
