/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { ApiActions, } from '@orientaction/api-actions';
import { apiState } from '@orientaction/reducers';
import { Company } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '@orientaction/utils';

interface dataApi {
  companies: any[];
  companyUserRole: any[];
  company: any;
}

const useCompany = () => {

  const dispatch = useDispatch();

  const data: dataApi = useSelector(apiState);

  const token = getFromLS('userToken') || '';

  const getCompanies = async () => {
    const companies = await Company.getCompanies(token);
    dispatch(ApiActions.setCompanies(companies.data?.data));
    return companies.data?.data;
  };

  const getCompany = async (idCompany: number) => {

    const company = await Company.getCompany({
      token: token as any,
      company: idCompany,
      company_user_role: 1,
      page: 0,
      filter: ''
    } as any)

    dispatch(ApiActions.setCompany(company.data?.data));
    return company.data?.data;
  };

  const filterAdmin = (
    idCompany: number,
    company_user_role: number,
    filter: string
  ) => {

    return Company.getCompany({
      token: token as any,
      company: idCompany as number,
      company_user_role: company_user_role as number,
      page: 0,
      filter: filter as string
    } as any)

  };

  const getCompanyUserRole = async () => {
    const company = await Company.getCompanyUserRole(token);
    dispatch(ApiActions.setCompanyUserRole(company.data?.data));
    return company.data?.data;
  };

  const getPopulateHierachy = async (id: number) => {
    const hierachy = await Company.getPopulateHierachy(token, id);
    return hierachy.data?.data
  }

  const getUpHierachy = async (param: any) => {
    const response = await Company.getUpHierachy(token, param);
    return response;
  }

  const resetCompany = () => {
    dispatch(ApiActions.setCompanies([]));
    dispatch(ApiActions.setCompanyUserRole([]));
  }

  return {
    ...data,
    getCompany,
    filterAdmin,
    getCompanies,
    resetCompany,
    getUpHierachy,
    getCompanyUserRole,
    getPopulateHierachy,
  };

};

export default useCompany;
