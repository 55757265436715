/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, TableAB } from '@orientaction/components';
import { useState } from 'react';

const Page29 = (props: any) => {
  const classes = useStyles();
  const data = [
    {
      A: "Pratiquer un sport pour le plaisir",
      B: "Gagner une compétition sportive",
    },
    {
      A: "Négocier un contrat",
      B: "Signer un contrat",
    },
    {
      A: "Avoir un travail que l’on aime",
      B: "Avoir une forte rémunération",
    },
    {
      A: "Cuisiner un gâteau",
      B: "Avoir réussi à cuisiner un gâteau",
    },
    {
      A: "Passer du temps avec des ami(e)s",
      B: "Relever des défis avec des ami(e)s",
    },
    {
      A: "Échanger avec une personne",
      B: "Convaincre une personne",
    },
    {
      A: "Jouer au tennis",
      B: "Gagner un match de tennis",
    },
    {
      A: "Partir en voyage",
      B: "Voyager pour relever un défi",
    },
    {
      A: "Dessiner et peindre",
      B: "Réaliser une œuvre artistique",
    },
    {
      A: "Se promener en montagne",
      B: "Monter au sommet d’une montagne",
    },
  ];
  const header = [
    {
      title: 'EXPRESSIONS 1',
      letter: 'A',
    },
    {
      title: 'EXPRESSIONS 2',
      letter: 'B',
    },
  ];

  return (
    <Page id={31} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            VOTRE MOTIVATION EST-ELLE PLUTÔT <br />
            INSTRUMENTALE OU FINALE?
          </Typography>
        </div>
        <div>
          <Grid container={true} spacing={2} justifyContent="center">
            <Grid item={true} xs={8}>
              <img className={classes.img} src={`${imgBook2}/course.jpg`} alt="logo" />
            </Grid>
          </Grid>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Parmi ces couples d’expressions, choisissez à chaque fois celle qui vous correspond 
            le mieux. <strong style={{color: '#2b56a5'}}>Cochez l’expression que vous voulez</strong> dans la colonne A ou dans la colonne B en fonction de 
            votre préférence. 
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <TableAB head={header} figures={data} />
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Vous l’aurez sans doute deviné, les expressions de gauche font référence à une 
            motivation instrumentale – le plaisir de réaliser - tandis que les expressions de droite 
            font référence à une motivation finale – le plaisir d’atteindre un objectif. Les deux 
            motivations coexistent chez tous les êtres humains. Néanmoins, on remarque une 
            préférence des personnes pour l’une ou l’autre des motivations. Cela a un impact 
            important dans le choix de votre activité professionnelle, l’idéal étant de trouver un 
            métier qui puisse satisfaire ces deux motivations. 
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe2: {
      color: '#000A8C',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '25px',
      display: 'flex',
      width: 'calc(100% - 20px)',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#000A8C',
      fontStyle: 'bold',
      textAlign: 'left',
    },
  })
);
export default Page29;
