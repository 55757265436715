/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import { publicSvgPath } from '@orientaction/utils';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './style';

const ThirdView: FC = () => {
  const classe = useStyles();

  return (
    <div className={classe.root}>

      <div className={classe.container}>

        <div className={classe.content}>

          <div className={classe.contentElement}>

            <Typography
              className={classe.title}
              component="h2"
            >
              APPLICATIONS CONCRÈTES DANS VOTRE VIE PROFESSIONNELLE
            </Typography>

            <Typography
              component="p"
              className={classe.paragraph}
            >
              
              Si vous ne vous sentez pas pleinement satisfait(e) dans votre vie professionnelle,
              c’est sans doute parce que certains de vos besoins ne sont pas satisfaits. Dans le
              domaine professionnel, cela peut être d’avoir une rémunération que vous jugez
              insuffisante, d’avoir des relations de mauvaise qualité avec vos collègues ou encore
              de ne pas avoir un poste suffisamment stimulant par rapport à vos attentes. Tous ces
              éléments risquent d’entraîner de la démotivation. Au contraire, si vos besoins sont
              satisfaits, alors vous vous sentirez pleinement motivé(e) par votre travail. Voilà
              pourquoi il faut être à l’écoute de vos besoins et être capable de les expliciter à
              votre manager. Cela lui permettra de tenter d’y répondre. Vous vous sentirez
              compris(e) et vous aurez le sentiment de progresser...

            </Typography>

          </div>
        </div>

        <Link
          className={classe.cardContainer}
          to="/tests/preference-personality"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={classe.imgBan} src={`${publicSvgPath}/bani4.png`} alt="logo" />
        </Link>

      </div>

    </div>
  );
};

export default ThirdView;
