import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  marginTop: {
    marginTop: 5,
  },
  flexContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: 10,
  },
  btnPrimary: {
    textTransform: 'none',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#000A8C',
    color: '#fff',
    '@media(min-width: 600px)': {},
  },
  textHeader: {
    display: 'none',
    marginLeft: 0,
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
  },
}));
