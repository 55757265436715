/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro } from '../../../../utils/constants';
import { Controller, useForm } from 'react-hook-form';
import dateFormat from 'dateformat';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { cloneDeep } from 'lodash';
import { useBookIntro } from "@orientaction/hooks_book";

let typeTimeout: any;

const Page12 = (props: any) => {

  const classes = useStyles();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<any>({
    mode: 'onChange',
  });

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { engagement, setEngagement } = useBookIntro();

  const onKeyDown = (event: any, index: number) => {
    clearTimeout(typeTimeout);
    handleChangeGlobalState(event, index);
  };

  const handleChange = (e: any, index: number) => {
    typeTimeout = setTimeout(function () {
      handleChangeGlobalState(e, index);
    }, 1000);
  };

  const handleChangeGlobalState = (e: any, index: number) => {

    const valueProtected: any[] = cloneDeep(engagement);

    valueProtected[index] = e.target.value;

    if (valueProtected[0] !== '' && valueProtected.length === 3) {
      valueProtected.push(dateFormat(new Date(), 'dd/mm/yyyy'));
    }

    setEngagement(valueProtected);
  }

  return (
    <Page
      id={12}
      readOnly={props.readOnly}
    >
      <div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className="opacity07"
            >
              LES TROIS ENGAGEMENTS QUE VOUS PRENEZ
              <br />
              VIS-À-VIS DE VOUS-MÊME ET DE VOTRE
              <br />
              CONSULTANT(E)
            </Typography>
          </div>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Pour réussir votre évolution professionnelle, vous devez d’ores et déjà prendre des
              engagements. Ces engagements sont la clef de votre réussite.
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: '50px', marginBottom: '20px' }}>

        <TitleNbr number={1} text="FAITES DE VOTRE BILAN DE COMPÉTENCES UNE PRIORITÉ" />

        <Grid container={true} spacing={1} justifyContent="center">

          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Nous avons tous mille choses à faire : le travail, la famille, le couple, les amis et
              mille autres choses encore… 24 h n’y suffisent pas. Néanmoins, si vous voulez réussir
              votre évolution professionnelle, il vous faudra prendre le temps nécessaire.
            </Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Vous devez prendre du temps pour réfléchir sur vous-même, prendre du temps pour
              effectuer des recherches, pour faire des rencontres, pour travailler sur vous.
            </Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Bénéficier de la méthode ORIENTACTION est une chance, mais c’est aussi très exigeant.
              Cela nécessite un engagement fort de votre part. Telle sera la condition de votre
              réussite.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={2} text="SOYEZ DANS L’ACTION !" />
        <Grid container={true} spacing={2} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Cela découle du premier engagement. Le temps consacré à votre bilan de compétences ne
              se limite pas aux temps de réflexion et de questionnement. Cela vous demande de
              réaliser des actions très concrètes et très précises. Contacter un(e)
              professionnel(le) par téléphone pour une enquête-métier, effectuer une recherche sur
              un secteur professionnel, visiter une organisation (entreprise, association,
              administration), démarrer une étude de marché en vue de la création de votre
              entreprise… autant de démarches à réaliser sans attendre. Agissez et vous réussirez !
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <TitleNbr number={3} text="AVANCEZ EN RYTHME" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '20px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Pour que votre accompagnement soit réussi, il vous faut avancer en rythme. Évitez au
              maximum de reporter les rendez-vous.
              <br />
              Durant votre bilan de compétences, c’est votre bilan qui est prioritaire sur tous les
              autres engagements. Il est la clef pour vous construire un avenir qui vous rendra
              épanoui(e) et heureux(se).
              <br />
              Votre bonheur doit toujours passer avant tout le reste. Apprenez au moins un temps à
              être égoïste, prenez du temps pour vous, prenez du temps pour entreprendre votre bilan
              de compétences.
            </Typography>
          </Grid>
        </Grid>
        <img
          src={`${imgBookIntro}/rocket.png`}
          alt="coelho"
          style={{
            width: '40%',
            display: 'block',
            margin: 'auto',
            marginTop: '10vh',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            marginTop: 100,
            border: '1px solid grey',
            padding: "35px 30px",
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}>
          <div>
            Je m’engage à tenir les trois engagements présentés ci-avant.{' '}
          </div>
          <div style={{ marginTop: 20, }}>
            Date : Le <span style={{ color: "black" }}>{engagement[3] || ""}</span>
          </div>
          <div style={{ marginTop: 20 }}>  Écrivez votre prénom, votre nom et la mention “Je m’engage” </div>
          <form>
            <Grid container={true} spacing={4} justifyContent="space-between">
              <Grid item={true} md={6} xs={12}>
                <h5 className={classes.labelText}> <strong>{"Je m'engage"}</strong></h5>
                <input
                  placeholder="Aa"
                  defaultValue={engagement[0]}
                  onChange={(e: any) => handleChange(e, 0)}
                  onKeyDown={(e: any) => onKeyDown(e, 0)}
                  onBlur={(e: any) => handleChangeGlobalState(e, 0)}
                  type="text"
                  className={classes.input}
                />
              </Grid>
            </Grid>
            <Grid container={true} spacing={isSmall ? 0 : 4} justifyContent="space-between">
              <Grid item={true} md={6} xs={12}>

                <h5 className={classes.labelText}> <strong>Prénom</strong></h5>
                <input
                  placeholder="Aa"
                  defaultValue={engagement[1]}
                  onChange={(e: any) => handleChange(e, 1)}
                  onKeyDown={(e: any) => onKeyDown(e, 1)}
                  onBlur={(e: any) => handleChangeGlobalState(e, 1)}
                  type="text"
                  className={classes.input}
                />
              </Grid>
              <Grid item={true} md={6} xs={12}>
                <h5 className={classes.labelText}> <strong>Nom</strong></h5>
                <input
                  placeholder="Aa"
                  defaultValue={engagement[2]}
                  onChange={(e: any) => handleChange(e, 2)}
                  onKeyDown={(e: any) => onKeyDown(e, 2)}
                  onBlur={(e: any) => handleChangeGlobalState(e, 2)}
                  type="text"
                  className={classes.input}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      lineHeight: 1.6,
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    labelText: {
      font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
      color: '#2b56a5',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 14,
      marginBottom: 3,
    },
    input: {
      flex: 1,
      width: 'calc(100% - 23px)',
      border: `1px solid #E3E8F0`,
      marginTop: 5,
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      fontSize: 13,
      borderRadius: 5,
    },
  })
);
export default Page12;
