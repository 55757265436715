import Grid from '@material-ui/core/Grid';
import OaLabel from '@orientaction/commons/OaLabel';
import { forwardRef } from 'react';
import LeftImage from './LeftImage';
import TitlePart from './TitlePart';

const TestMainInfo = forwardRef((_, ref) => {
  const { imageRef, titleRef }: any = ref;
  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} sm={12} md={4} lg={4}>
        <OaLabel>Image du test</OaLabel>
        <LeftImage ref={imageRef} />
      </Grid>
      <Grid item={true} sm={12} md={8} lg={8}>
        <TitlePart ref={titleRef} />
      </Grid>
    </Grid>
  );
});

export default TestMainInfo;
