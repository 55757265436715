export const verificationFormulaire = (FormOfVerifie: string[], verify: any, setFormErreur: (data: any) => void) => {

    const FormError: { [key: string]: boolean | string } = {};

    FormOfVerifie.forEach((event: string) => {
        if (!verify[event] || verify[event] === " ") {
            FormError[event] = true;
        }
    });

    setFormErreur(FormError);

    if (Object.keys(FormError).length === 0) {
        return true;
    }

    return false;

};