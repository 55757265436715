/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { RadioGrpLabel, CardNumber, Page } from '@orientaction/components';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import { useBook1 } from "@orientaction/hooks_book";


const Page15 = (props: any) => {
  const classes = useStyles();
  const { book, updateSuccessLife } = useBook1();
  const toggleA_B = [
    [
      {
        label: 'Prendre toujours plus de responsabilités.',
        value: 'A',
      },
      {
        label: 'Avoir un métier qui respecte votre biorythme.',
        value: 'B',
      },
    ],
    [
      {
        label: 'Devenir leader(euse) dans votre domaine.',
        value: 'A',
      },
      {
        label: 'Être utile aux autres.',
        value: 'B',
      },
    ],
    [
      {
        label: 'Obtenir une distinction, un prix ou une médaille.',
        value: 'A',
      },
      {
        label: 'Avoir le sentiment de bien faire votre travail.',
        value: 'B',
      },
    ],
    [
      {
        label: 'Devenir célèbre, bénéficier d’une certaine notoriété.',
        value: 'A',
      },
      {
        label: 'Travailler dans une bonne ambiance avec des personnes sympathiques.',
        value: 'B',
      },
    ],
    [
      {
        label: 'Pouvoir afficher votre réussite sociale à travers des objets : maison, bijoux, voiture.',
        value: 'A',
      },
      {
        label: 'Prendre du plaisir dans ce que vous faites – ne rien vous imposer.',
        value: 'B',
      },
    ],
    [
      {
        label: 'Fréquenter des personnes influentes.',
        value: 'A',
      },
      {
        label: 'Avoir une vie équilibrée entre vos différents domaines de vie (métier, famille, couple etc.).',
        value: 'B',
      },
    ],
    [
      {
        label: 'Monter dans la hiérarchie de votre organisation (entreprise, association, administration).',
        value: 'A',
      },
      {
        label: 'Avoir un métier qui vous passionne même si vous ne gagnez pas forcément beaucoup d’argent.',
        value: 'B',
      },
    ],
    [
      {
        label: 'Atteindre des sommets, aller au bout de vos ambitions.',
        value: 'A',
      },
      {
        label: 'Avoir du temps pour vos loisirs et votre épanouissement personnel.',
        value: 'B',
      },
    ],
  ];

  const handleChangeNumber = (data: any) => {

    const arrayCloned = cloneDeep(book.successLife);

    arrayCloned[data.index] = data.value;

    updateSuccessLife(arrayCloned);

  };

  return (
    <Page id={15} readOnly={props.readOnly}>

      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" component="h1" style={{ marginBottom: '10px' }}>
          TEST :
        </Typography>
        <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
          RÉUSSIR DANS LA VIE OU RÉUSSIR SA VIE ?
        </Typography>
      </div>

      <div className={clsx(classes.trait, classes.positionCenter)} />

      <div style={{ marginTop: '50px', marginBottom: '60px' }}>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Dans chaque couple d’items ci-après, sélectionnez celui des deux qui vous correspond le plus aujourd’hui, tout en essayant au maximum de vous projeter dans l’avenir. Soyez authentique et sincère.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Ce qui compte le plus pour vous aujourd’hui (et ce qui comptera le plus demain), c’est :
        </Typography>
      </div>

      <div className={classes.cardBgGrey}>
        {
          toggleA_B.map((elem: any, index: number) => (
            <div style={{ marginBottom: '20px' }}>
              <RadioGrpLabel
                radios={elem}
                onChange={handleChangeNumber}
                name="operator1"
                index={index}
                defaultPropsValue={book?.successLife[index]}
              />
            </div>
          ))
        }
      </div>

      <Grid container={true} spacing={2} style={{marginTop: '50px'}}>
        <Grid item={true} xs={12} sm={6}>
          <CardNumber categorie="A" number={filter(book.successLife, elem => elem === 'A').length} />
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <CardNumber categorie="B" number={filter(book.successLife, elem => elem === 'B').length} />
        </Grid>
      </Grid>

    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    }
  })
);
export default Page15;