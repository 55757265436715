/* eslint-disable react/jsx-no-undef */
// eslint-disable-next-line simple-import-sort/imports, no-use-before-define
import { Page } from '@orientaction/components';
import ParagraphWithMargin from '../../components/ParagraphWithMargin';
import { paragraphs } from './constant';

const Page09 = () => {
  return (
    <Page id={9}>
      <ParagraphWithMargin lists={paragraphs} />
    </Page>
  );
};

export default Page09;
