import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  span: {
    color: 'white',
    background: '#f1665f',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  text: {
    width: '100%'
  },
  container: {
    margin: '3.5vh 0',
  },
  margin: {
    marginBottom: '1vh'
  },
  formControl: {
    width: '100%'
  }
}));
