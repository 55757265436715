/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import { Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import React from 'react';
import CircularProgressCustom from '../CircularProgressCustom';
import ExpandableItem from '../ExpandableItem';
import style from './style';
import clsx from 'clsx';
import { publicSvgPath } from '@orientaction/utils';

interface IModalHarmony {
  open: boolean;
  onCancel: () => void;
  label: string;
  value: number;
  color: string;
  img: string;
  colorSecondary: string;
  opacity: boolean;
  citation: string;
  title: string;
  listeAccordeon: any;
  listEmploi1: any;
  text1: string;
  text2: any;
  titleAd?: string;
  descriptionAd?: string;
  imgAd?: string;
  useGrid?: boolean;
}

const ModalCardHarmony: React.FC<IModalHarmony> = ({
  open,
  onCancel,
  label,
  value,
  color,
  img,
  colorSecondary,
  opacity,
  citation,
  title,
  text1,
  listeAccordeon,
  listEmploi1,
  text2,
  titleAd,
  descriptionAd,
  imgAd,
  useGrid,
}) => {
  const classes = style({ color });

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={isTab ? 'xl' : 'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent
        style={{ position: 'relative', padding: 0, maxWidth: '100%', overflowX: 'hidden' }}>
        <IconButton aria-label="delete" className={classes.closeBtn} onClick={onCancel}>
          <Close />
        </IconButton>
        <Grid container={true}>
          <Grid item={true} style={{ zIndex: 2 }}>
            <div className={classes.titlehead} style={{ background: color }}>
              <h3 className={classes.titleText}>{label}</h3>
            </div>
          </Grid>
          <Grid item={true} xs={true} sm={true} md={5} style={{ position: 'relative', zIndex: 1 }}>
            <img
              src={img}
              alt=""
              style={{ width: '100%', height: 'auto' }}
              className={classes.imgMargin}
            />
            <div className={classes.positionCircular2}>
              <CircularProgressCustom
                value={value}
                color={color}
                size={!isTab ? 170 : 120}
                fontSize={!isTab ? 27 : 20}
                fontSizeFloat={!isTab ? 19 : 12}
              />
            </div>
          </Grid>
          <Grid item={true} sm={12} xs={12} md={true} className={classes.citationContainer}>
            <div style={{ width: '100%' }}>
              <img src={`${publicSvgPath}/quote.png`} alt="" className={classes.quote} />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.citation}
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line react/destructuring-assignment
                  __html: citation,
                }}
              />
            </div>
          </Grid>
          <Grid />
        </Grid>
        <div className={classes.paddingGridText2} style={{ background: 'white' }}>
          <Grid container={true}>
            <Grid item={true} sm={12} md={12} style={{ paddingBottom: 0 }}>
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ textTransform: 'none', fontSize: 22, marginBottom: 20 }}>
                Définition de la soft skill
              </Typography>
            </Grid>
            <Grid item={true} container={true} md={12} spacing={5} justifyContent="space-between">
              <Grid item={true} md={6} className={classes.block1}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.text1}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: text1,
                  }}
                />
              </Grid>
              <Grid item={true} md={6} className={classes.block2}>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleContent}
                  style={{
                    textTransform: 'none',
                    lineHeight: 1.5,
                    marginBottom: 25,
                    fontSize: 15,
                  }}>
                  Fonctions et/ou métiers dans lesquels elle est déterminante
                </Typography>
                <ul className={clsx(classes.listUl, classes.marginTopMobile)}>
                  {listEmploi1?.map((elem: any) => (
                    <li className={classes.listLi}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        dangerouslySetInnerHTML={{
                          // eslint-disable-next-line react/destructuring-assignment
                          __html: elem,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container={true} style={{ marginTop: 40 }} spacing={3}>
            <Grid item={true} md={12}>
              {!isTab &&
                listeAccordeon?.map((elem: any, index: any) => {
                  if ((index + 1) % 2 !== 0) {
                    return (
                      <ExpandableItem
                        index={index + 1}
                        title={elem.title}
                        description={elem.description}
                        color={colorSecondary}
                        opacity={opacity}
                      />
                    );
                  }
                })}
            </Grid>
          </Grid> */}
        </div>
        <div className={classes.paddingGridText2} style={{ background: color }}>
          <Grid container={true}>
            <Grid item={true} md={12}>
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ color: 'white', textTransform: 'none', fontSize: 22 }}>
                {title}
              </Typography>
              <br />
              {useGrid && (
                <Grid container={true} spacing={5}>
                  <Grid item={true} md={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ color: 'white' }}
                      className={classes.text1}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: text2[0],
                      }}
                    />
                  </Grid>
                  <Grid item={true} md={6}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ color: 'white' }}
                      className={classes.text1}
                      dangerouslySetInnerHTML={{
                        // eslint-disable-next-line react/destructuring-assignment
                        __html: text2[1],
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {!useGrid && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  style={{ color: 'white' }}
                  className={classes.text1}
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react/destructuring-assignment
                    __html: text2,
                  }}
                />
              )}
            </Grid>
            <Grid item={true} md={12} style={{ marginTop: 25 }}>
              {listeAccordeon?.map((elem: any, index: any) => (
                <ExpandableItem
                  index={index + 1}
                  title={elem.title}
                  description={elem.description}
                  color={colorSecondary}
                  opacity={opacity}
                  isHarmony={true}
                  label={label}
                  titleAd={titleAd}
                  descritionAd={descriptionAd}
                  imgAd={imgAd}
                />
              ))}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
export default ModalCardHarmony;
