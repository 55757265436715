// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import { imgBook1 } from '../../utils/constants';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useState } from 'react';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
    padding: 20,
  },
  h6: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'block',
    style: 'normal',
    alignItems: 'center',
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '@media only screen and (max-width: 600px)': {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 3,
      paddingRight: 3,
      fontSize: 12,
      fontWeight: 800,
    },
    '@media only screen and (max-width: 361px)': {
      paddingTop: 10,
      paddingLeft: 3,
      paddingRight: 3,
      paddingBottom: 10,
      fontSize: 12,
      fontWeight: 800,
    },
  },
  categorieB: {
    color: '#2c57a5 !important',
    background: '#eaeff9',
    fontSize: '25px',
    border: '1px solid #0E1247',
  },
  categorieA: {
    color: '#2c57a5',
    background: '#fff',
    fontSize: '25px',
    border: '1px solid #0E1247',
    position: 'relative',
    height: 'auto',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
  cardBgGrey: {
    background: '#E6E6E6',
  },
  borderTable: {
    border: '1px solid #0E1247',
    borderRadius: 'none',
  },
  textArea: {
    width: '98%',
    height: '100px',
    border: 'none',
    fontSize: '15px',
    fontFamily: 'ITC Avant Garde Gothic Std Medium',
    display: 'block',
  },
  img: {
    width: '40%',
    height: 'auto',
    display: 'block',
    margin: 'auto',
    '@media only screen and (max-width: 600px)': {
      width: '75%',
    },
    '@media only screen and (max-width: 361px)': {
      width: '80%',
    },
  },
  paragraphe: {
    fontSize: '14px',
    color: '#2c57a5',
    '@media only screen and (max-width: 600px)': {
      textAlign: 'left !important',
    },
    '@media only screen and (max-width: 361px)': {
      textAlign: 'left !important',
    },
  },
  floatIndex: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: `url(${imgBook1}/back.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '50px',
    height: '50px',
    '& > span': {
      color: 'white',
      position: 'absolute',
      bottom: '5px',
      left: '5px',
    },
  },
});

const TableConsigne = (props: any) => {
  const classes = useStyles();
  const { consigne, textSelected, onChange } = props;
  const [select, setSelect] = useState<any>(['', '', '']);
  const handleChangeSelect = (e: any, index: number) => {
    const data = cloneDeep(select);
    data[index] = e.target.value;
    setSelect(data);
    onChange(e.target.value);
  };
  return (
    <div>
      <Grid container={true}>
        <Grid item={true} xs={12} style={{ textAlign: 'center' }} className={classes.categorieB}>
          <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
            {consigne.title}
          </Typography>
        </Grid>
        <Grid item={true} xs={12} className={classes.categorieA}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={select[0]}
              onChange={(e: any) => handleChangeSelect(e, 0)}
              placeholder="Entreprise"
              style={{ border: '0.5px solid #2b56a5' }}
              displayEmpty={true}>
              <MenuItem value="">Cliquer ici</MenuItem>
              {textSelected?.map((text: any) => {
                return (
                  <MenuItem value={text} key={text}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12} className={classes.categorieA}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={select[1]}
              onChange={(e: any) => handleChangeSelect(e, 1)}
              placeholder="Entreprise"
              style={{ border: '0.5px solid #2b56a5' }}
              displayEmpty={true}>
              <MenuItem value="">Cliquer ici</MenuItem>
              {textSelected?.map((text: any) => {
                return (
                  <MenuItem value={text} key={text}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12} className={classes.categorieA}>
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={select[2]}
              onChange={(e: any) => handleChangeSelect(e, 2)}
              placeholder="Entreprise"
              style={{ border: '0.5px solid #2b56a5' }}
              displayEmpty={true}>
              <MenuItem value="">Cliquer ici</MenuItem>
              {textSelected?.map((text: any) => {
                return (
                  <MenuItem value={text} key={text}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
export default TableConsigne;
