import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        description: {
            overflow: 'hidden',
            width: '95%',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            color: '#0E1247',
            lineHeight: '180%',
            wordBreak: 'normal',
            [theme.breakpoints.down(800)]: {
                margin: '2vh 0',
                lineHeight: '150%',
                fontSize: '16px',
                width: '90%',
            },
        },
    }),
);
