/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import OaLabel from '@orientaction/commons/OaLabel';
import { AdminTestContext } from '@orientaction/Context';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useState } from 'react';
import ResponsesSection from '../ResponsesSection';
import style from './style';

const QuestionForm = () => {
  const [title, setTitle] = useState();
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const initializeEditState = useCallback(() => {
    const { questionToEdit } = test;
    setTitle(questionToEdit.title);
  }, []);

  const initializeState = useCallback(() => {
    const { questionToCreate } = test;
    setTitle(questionToCreate.title);
  }, []);

  const handleChange = (e: any) => {
    setTitle(e.target.value);
    updateTest({ questionToCreate: { ...test.questionToCreate, title: e.target.value } });
  };

  useEffect(() => {
    if (test.questionToEdit) {
      initializeEditState();
    }
    if (test.questionToCreate) {
      initializeState();
    }
  }, [initializeEditState, test.questionToEdit]);

  const showError =
    !test?.questionToCreate?.responses?.length && test?.questionToCreate?.errors?.response;

  const questionIndex = test?.questions.findIndex((q: any) => q.id === test?.questionToCreate?.id);
  const questionNumber = questionIndex === -1 ? test?.questions.length + 1 : questionIndex + 1;
  return (
    <>
      <form className={classes.flexContainer}>
        <Typography className={classes.questionFormTitle} component="h3">
          Question N°{questionNumber}
        </Typography>
        <div className={classNames(classes.marginTop, classes.textInputContainer)}>
          <OaLabel>Titre de la question</OaLabel>
          <TextField
            multiline={true}
            id="question-form-title"
            variant="outlined"
            minRows={5}
            maxRows={5}
            fullWidth={true}
            value={title}
            className={classNames(
              classes.customTextField,
              test?.questionToCreate?.errors?.title && classes.error
            )}
            onChange={handleChange}
          />
        </div>
      </form>
      <ResponsesSection responses={test?.questionToCreate?.responses} />
      {showError && (
        <Typography component="h5" className={showError ? classes.responseError : ''}>
          La réponse est obligatoire*
        </Typography>
      )}
    </>
  );
};

export default QuestionForm;
