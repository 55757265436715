// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page } from '@orientaction/components';
import useStyles from './style';
import ParagraphWithMargin from '../../components/ParagraphWithMargin';
import { lastParagraphs, paragraphs, remainingParagraphs } from './constant';
import BlockWithBackground from '../../components/BlockWithBackground';
import ParagraphWithImage from '../../components/ParagraphWithImage';
import SimpleBanner from '../../../components/SimpleBanner';

import Typography from '@material-ui/core/Typography';
import { imgBook4 } from '@orientaction/utils';
import clsx from 'clsx';

const Page08 = () => {
  const classes = useStyles();

  return (
    <Page id={8}>
      <ParagraphWithMargin lists={paragraphs} />
      <div className={classes.cardBgGrey} style={{ marginTop: '40px' }}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook4}/twins.png`} alt="logo" /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LE DESTIN DES JUMEAUX
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook4}/brothers.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Dans ma famille, j’ai deux cousins germains qui sont jumeaux. Ils sont nés le même jour à
          la même heure dans la même famille. Aujourd’hui, à trente-deux ans, l’un est
          kinésithérapeute-ostéopathe, épanoui dans sa vie de couple, propriétaire d’une maison et
          de son cabinet médical. Le second vient de reprendre ses études à l’université. Il a
          cinq fois le concours pour devenir professeur de sport, sans succès.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Après avoir travaillé à l’étranger, fait des tas de petits boulots, voyagé, il reprend des
          études pour repasser une sixième fois le concours, s’obstinant comme un automate dans une
          voie « bouchée » par la réduction des recrutements alors que son frère a choisi un emploi
          bien rémunéré et porteur.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Qu’est-ce qui distingue ces deux frères ? D’abord une certaine vision de leur avenir et
          ensuite une aptitude à mobiliser leurs ressources pour réussir. Je ne porte aucun jugement
          de valeur sur leur situation respective, mais objectivement, l’un a une situation bien
          plus enviable que le second et cette différence m’interroge tant ils sont semblables sur
          les plans génétique, économique et culturel.
        </Typography>
      </div>
      <ParagraphWithMargin lists={lastParagraphs} />
    </Page>
  );
};

export default Page08;
