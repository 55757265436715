/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { Switch } from '@orientaction/commons';
import { FC, useState } from 'react';
import { useStyles } from './style';
import Animation from './Animation';
import { useCompassMenu, useAlert } from '@orientaction/hooks';
import { useParams } from 'react-router-dom';

const CompassCard: FC = () => {
  const classe = useStyles();

  const { updateRotate, rotate } = useCompassMenu();

  const [isActive, setIsActive] = useState(false);

  const [loading, setLoading] = useState(false);

  const { year: paramYear }: any = useParams();
  const currentYear = new Date().getFullYear();

  const disableCompass = currentYear !== +paramYear;

  const alert = useAlert();

  const updateMyrotat = async () => {
    setLoading(true);

    try {
      await updateRotate(rotate.id, rotate.deg);
    } catch (err: any) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSwitch = (e: any) => {
    const { checked } = e.target;

    if (!checked) {
      updateMyrotat();
    }

    setIsActive(checked);
  };

  const isMobil = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div className={classe.root}>
      <div className={classe.content}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <div className={classe.center}>
              <Animation isActive={isActive} />
            </div>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <div className={classe.elementContent}>
              <div>
                <Typography className={classe.title} component="h2">
                  Prenez la bonne direction pour être heureux(se) et performant(e)
                </Typography>

                <p className={classe.description}>
                  Pour être heureux(se) et performant(e), vous devez choisir un environnement
                  professionnel cohérent avec vos valeurs, qui répond à vos besoins, qui vous permet
                  d’exprimer les préférences profondes de votre personnalité et de mobiliser vos
                  talents (soft skills). L’aiguille indique l’élément que vous privilégiez pour
                  votre projet.
                </p>
              </div>

              <div>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <div className={classe.contentFlex}>
                    {/* <Switch
                      checked={isActive}
                      disabled={disableCompass}
                      onChange={handleChangeSwitch}
                    />
                    <Typography className={classe.switch}>Bouger l&rsquo;aiguille</Typography> */}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CompassCard;
