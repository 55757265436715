import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getPreferencePersonalityTestResults = async (token: string) => {
  const searchParams = qs.stringify(
    {
      populate: ['users_permissions_user'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    },
  );
  return makeRequest(
    `${url}/api/preference-personality-results?${searchParams}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getMyResultPrefrerencePersonality = async (token: string, id: number) => {
  return makeRequest(
    `${url}/api/get-result-preference-personality-test/${id}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getMyResultPrefrerenceJeune = async (token: string, id: number) => {
  return makeRequest(
    `${url}/api/get-result-preference-jeune-test/${id}`,
    'GET',
    {},
    getHeaders(token),
  );
};

export const getMyResumeElement = async (token: string, data: any) => {
  return makeRequest(
    `${url}/api/get-my-resume-preference-personality-value`,
    'POST',
    data,
    getHeaders(token),
  );
};
