/* eslint-disable react/no-array-index-key */
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import { getNextId } from '../../utils';
import Board from '../WithDragAndDrop';
import NoQuestion from './NoQuestion';
import style from './style';

const QuestionsSection = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const handleClick = () => {
    const id = getNextId(test.questions);
    updateTest({
      questionDrawerOpen: true,
      questionToCreate: {
        id,
        title: '',
        index: test?.questions?.length,
        responses: [],
        type: 'create',
        isNew: true,
      },
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: 35, marginBottom: 15 }}>
        <Typography className={classes.sectionTitle} component="h1">
          Questionnaire
        </Typography>
      </div>
      {test?.questions?.length ? <Board /> : <NoQuestion hasError={test?.errors?.questions} />}
      <div className={classes.flexContainer}>
        <Button onClick={handleClick} className={classes.btnPrimary}>
          <span className={classes.textHeader}>Ajouter une question</span>
        </Button>
      </div>
    </div>
  );
};

export default QuestionsSection;
