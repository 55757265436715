/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setListOfResult =
  (data: any[]): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'LISTOFRESULT',
      payload: data,
    });
  };

export const setCount =
  (data: number): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'COUNT',
      payload: data,
    });
  };
 