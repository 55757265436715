/* eslint-disable simple-import-sort/imports */

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { FC } from 'react';

interface ISnackBar {
    open: boolean;
    handleClose: () => void;
    severity?: 'success' | 'info' | 'warning' | 'error';
    message?: string;
}

const Alert: FC<ISnackBar> = ({
    open,
    handleClose,
    severity,
    message
}) => {

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
        >
            <MuiAlert
                elevation={6}
                onClose={handleClose}
                variant="filled"
                severity={severity}
            >
                {message || ""}
            </MuiAlert>
        </Snackbar>
    );
};

export default Alert;
