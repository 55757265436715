/* eslint-disable react/no-array-index-key */
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Input from '@material-ui/core/Input';
import { useContext, useEffect, useState } from 'react';
import RichEditor from '../../commons/RichEditor';
import StyledDropzone from '../../commons/StyledDropzone';
import { TestContext } from '../../Context/TestContext';
import style from './style';

const FinalTextCreationForm = () => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const { test, updateTest } = useContext<any>(TestContext);

  const cls = style();

  useEffect(() => {
    if (test.finalText) {
      setTitle(test.finalText.title);
      setDescription(test.finalText.description);
    }
  }, [test]);

  const handleNameChange = (e: any) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const addText = () => {
    updateTest({
      ...test,
      finalText: { title, description, id: test.finalText?.id ?? '' },
    });
  };

  return (
    <Card variant="outlined" style={{ padding: 15, borderRadius: '10px', marginTop: 10 }}>
      <h3 className={cls.h3}>Nom</h3>
      <Input
        value={title}
        onChange={handleNameChange}
        placeholder="Renseignez le nom"
        type="text"
        className={cls.input}
      />
      <h3 className={cls.h3}>Descriptif du résultat</h3>
      <RichEditor value={description} handleChange={handleDescriptionChange} />
      <Box className={cls.btnPrimaryContainer}>
        <Button className={cls.btnPrimary} disabled={title.length === 0} onClick={addText}>
          {test.finalText.title.length > 0 ? 'Modifier le texte' : 'Ajouter le texte'}
        </Button>
      </Box>
    </Card>
  );
};

export default FinalTextCreationForm;
