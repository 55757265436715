import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timelineTitle: {
      height: '72px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px',
      fontWeight: 700,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      [theme.breakpoints.down(800)]: {
        height: '45px',
      },
    },
    customDot: {
      backgroundColor: '#fff',
      padding: 2,
      marginTop: 12,
    },
    customConector: {
      width: 3,
      backgroundColor: 'transparent',
      backgroundSize: '3px 10px',
      backgroundImage: 'radial-gradient(rgb(255 255 255) 0%, rgb(255 255 255) 16%, #ff141400 42%)',
    },
    labelPeriod: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 400,
      padding: '8px 4px',
      margin: 0,
      cursor: 'pointer',
      borderRadius: 3,
      '&:hover': {
        background: '#333B9F',
      },
    },
    whenMobil: {
      position: 'relative',
      left: 0,
      border: '1px solid red',
    },
    isHighlighted: {
      background: '#333B9F',
    },
    rootAccordion: {
      position: 'relative',
      zIndex: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '196px',
      background:
        'transparent linear-gradient(180deg, #000A8C 0%, #000763 100%) 0% 0% no-repeat padding-box',
      color: '#fff',
      transition: '600ms',
      [theme.breakpoints.down(800)]: {
        paddingLeft: '1vh',
      },
    },
    accordionMobileClose: {
      width: 50,
    },
    ulHistory: {
      margin: '0 1.25vh',
      padding: 0,
    },
  })
);

export const AccordionDetails = withStyles(theme => ({
  root: {
    paddingBottom: '2vh',
  },
}))(MuiAccordionDetails);
