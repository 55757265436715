/* eslint-disable no-use-before-define */
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

type Props = {
  name: string;
  radios: RadioValue[];
  onChange: (value: any) => void;
  className?: string;
  withDivider?: boolean;
  index: number;
  defaultPropsValue: string;
};

interface RadioValue {
  label: string;
  value: string;
  disabled?: boolean;
}

const MyRadio = withStyles({
  root: {
    display: 'none',
    '& + span': {
      padding: '10px 12px',
      border: '1px solid #E3E8F0',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      letterSpacing: 0,
      borderRadius: 3,
      color: '#2c57a5',
      background: '#fff',
      width: '20px',
      textAlign: 'center'
    },
    '& + span:hover': {
      border: '1px solid #2b56a5',
    },
    margin: 0,
  },
  checked: {
    '& + span': {
      color: '#fff',
      background: '#2b56a5',
      borderRadius: '50%',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const RadioButtonLetter: FC<Props> = ({
  className = '',
  name,
  radios = [],
  onChange,
  withDivider = false,
  index,
  defaultPropsValue,
}) => {
  const classes = useStyles();
  const [defaultValue, setDefaultValue] = useState(defaultPropsValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue((event.target as HTMLInputElement).value);
    const dataValue = {
      value: (event.target as HTMLInputElement).value,
      index,
    };
    onChange(dataValue);
  };

  return (
    <RadioGroup
      className={clsx(classes.radioGrp, className)}
      aria-label={name}
      name={name}
      value={defaultValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}>
      {radios.map((r, i) => (
        <div className={classes.radioItemContainer} key={Math.random()}>
          {withDivider && i !== 0 && <Divider orientation="vertical" className={classes.divider} />}
          <Grid container={true} style={{height: '80px'}}>
            <Grid item={true} xs={10} style={{maxHeight: '100%', paddingLeft: 10, paddingRight: 10, display: 'flex', alignItems: 'center'}}>
              <span style={{fontSize: '15px', display:'block', margin: 'auto', color: 'black'}}>{r.label}</span>
            </Grid>
            <Grid item={true} xs={2} style={{textAlign: 'center', maxHeight: '100%', borderLeft: '1px solid #0E1247', display: 'flex', alignItems: 'center'}}>
              <Grid container={true}>
                <Grid item={true} xs={12}>
                  <FormControlLabel
                    key={r.value}
                    className={classes.controlLabel}
                    value={r.value}
                    control={<MyRadio />}
                    label={r.value}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ))}
    </RadioGroup>
  );
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGrp: {
      width: '100%',
      borderRadius: 4,
    },
    controlLabel: {
      margin: '0 !important',
    },
    radioItemContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #0E1247',
    },
    divider: {
      color: 'black',
      '&.MuiDivider-vertical': {
        height: '50%',
      },
    },
  })
);

export default RadioButtonLetter;
