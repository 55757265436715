// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../components/Img';
import { Grid, Typography } from '@material-ui/core';
import { publicBook5Path } from '@orientaction/utils';

const Page29 = (props: any) => {
  const classe = useStyles();

  return (
    <Page id={29}>
      <TitleChapitre nbr={3} />

      <MarginContainer>
        <TitlePage>FAITES LE GRAND CHOIX</TitlePage>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="center" colorBlue={true} bold={true}>
          Le moment est venu de prendre votre décision.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Vous êtes conscient(e) des opportunités et des risques éventuels de votre projet, vous
          connaissez les actions que vous devrez mettre en place, ce à quoi vous devrez renoncer,
          les zones d’incertitude que vous devrez accepter. Fermez les yeux, prenez une profonde
          inspiration et faites votre choix.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer big={true}>
        {/* <Img
          src="doBigChoise.png"
          alt="Le moment est venu de prendre votre décision."
          className={classe.img}
        /> */}
        <Grid container={true} justifyContent="center">
          <Grid xs={8}>
            <div style={{ position: 'relative' }}>
              <div className={classe.textPositionYes}>
                <Typography className={classe.textStyleInside}>OUI</Typography>
              </div>
              <div className={classe.textPositionNo}>
                <Typography className={classe.textStyleInside} color="error">
                  NON
                </Typography>
              </div>
              <img className={classe.img} src={`${publicBook5Path}/doBigChoise.png`} alt="logo" />
            </div>
          </Grid>
        </Grid>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          Si vous faites le choix de renoncer, reportez-vous à la page suivante, sinon rendez-vous
          au chapitre consacré à la mise en œuvre de votre projet.
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};
export default Page29;
