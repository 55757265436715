// eslint-disable-next-line simple-import-sort/imports
import { Page, TitlePage, Img } from '@orientaction/components';
import useStyles from '../../style';
import { blockRedirection, imgBook1 } from '@orientaction/utils';
import { useSelector } from 'react-redux';
import { appLanguage } from '@orientaction/reducers';
import { Card, Grid } from '@material-ui/core';

const Page42 = (props: any) => {
  const classes = useStyles();
  const lang: string = useSelector(appLanguage);

  return (
    <Page id={41} notPadding={!(lang === 'GB')}>
      {lang === 'GB' ? (
        <div>
          <TitlePage>
            DEMANDEZ À VOTRE CONSULTANT(E)
            <br />
            DE VOUS FAIRE DÉCOUVRIR LES CARTES DES VALEURS
          </TitlePage>

          <Img src="58.jpg" alt="CONSULTANT" style={{ width: '80%' }} />
        </div>
      ) : (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12} md={12}>
              <div className={classes.couverturePage}>
                <img
                  src={`${imgBook1}/carteweb.jpg`}
                  alt="couverture intro"
                  className={classes.imgpremierApercu}
                />
                <span
                  onClick={() =>
                    blockRedirection({
                      language: lang,
                      onOpen: () => console.log(''),
                      functionToPass: () => {
                        window.open(' https://amzn.eu/d/4vvyXKh', '_blank');
                      },
                    })
                  }
                  className={classes.btn042}>
                  Clique
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Page>
  );
};

export default Page42;
