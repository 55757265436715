/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import Grid from '@material-ui/core/Grid';
import { UserProfileHeader, UserProfileInfos, UserProfilePhoto } from '@orientaction/components';
import { useUser } from '@orientaction/hooks';
import { LayoutSimple } from '@orientaction/layouts';
import { getFromLS } from '@orientaction/utils';
import style from './style';

const UserProfile = () => {
  const { user, setUser, loading }: any = useUser();
  const currentUser = JSON.parse(getFromLS('userInfos') || '');
  const cls = style();
  return (
    <LayoutSimple isaddBackground={true} showLogo={false} noPadding={true}>
      <UserProfileHeader />
      <Grid container={true} spacing={2} className={cls.root}>
        <Grid item={true} sm={3} md={3} lg={3}>
          {/* <UserProfilePhoto
            currentUser={currentUser}
            user={user}
            setUser={setUser}
            loading={loading}
          /> */}
        </Grid>
        <Grid item={true} sm={9} md={9} lg={9}>
          <UserProfileInfos user={user} loading={loading} />
        </Grid>
      </Grid>
    </LayoutSimple>
  );
};

export default UserProfile;
