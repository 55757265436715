/* eslint-disable react-hooks/exhaustive-deps */
import { book4Action } from '@orientaction/api-actions';
import { appBook4 } from '@orientaction/reducers';
import { BookTemplate } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { IBook4 } from '../../store/types/Book/Book4';
import { getFromLS } from '../../utils/utils';


const useBook4 = () => {

  const dispatch = useDispatch();

  const book4: IBook4 = useSelector(appBook4);

  const token = getFromLS('userToken') || '';

  const updateIndoorWeather = async (nbr: number) => {
    console.log({book4, nbr})
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      indoorWeather: nbr,
      query: "api::fourth-ebook.fourth-ebook"
    });
    dispatch(book4Action.setIndoorWeather(nbr));
  }

  const getMyValueBook = async (userId: any) => {
    return BookTemplate.getMyValueBook(token, {
      id: userId,
      query: 'api::fourth-ebook.fourth-ebook',
    });
  };

  const setId = (data: number) => {
    dispatch(book4Action.setId(data));
  };

  const setYourEconomicCapital = async (data: any) => {
    dispatch(book4Action.setYourEconomicCapital(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      [data.name]: data.value,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setAssessYourSocialCapitalMobilizable = async (data: any) => {
    dispatch(book4Action.setAssessYourSocialCapitalMobilizable(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      assessYourSocialCapitalMobilizable: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setAccessYourCulturalCApitalMobilizable = async (data: any) => {
    dispatch(book4Action.setAccessYourCulturalCApitalMobilizable(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      accessYourCulturalCApitalMobilizable: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setDiplomeExperience = async (data: any) => {
    dispatch(book4Action.setDiplomeExperience(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      diplomeExperience: data,
      query: "api::fourth-ebook.fourth-ebook"
    });

  }

  const setSoftSkill = async (data: any) => {
    dispatch(book4Action.setSoftSkill(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      softSkill: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setYourDiplome = async (data: any) => {
    dispatch(book4Action.setYourDiplome(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      yourDiplome: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setYourBestProfessionalExperience = async (data: any) => {
    dispatch(book4Action.setYourBestProfessionalExperience(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      yourBestProfessionalExperience: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setSynthiesIsYouSkill = async (data: any) => {
    dispatch(book4Action.setSynthiesIsYouSkill(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      synthiesIsYouSkill: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setvalueAdd = async (data: any) => {
    dispatch(book4Action.setvalueAdd(data));
    const bookUpdated = await BookTemplate.updateBook(token, {
      id: book4.id,
      valueAdd: data,
      query: "api::fourth-ebook.fourth-ebook"
    });
  }

  const setAll = (data: any) => {
    dispatch(book4Action.setAll(data));
  }

  return {
    ...book4,
    state: book4,
    setId,
    setAll,
    setvalueAdd,
    setSoftSkill,
    getMyValueBook,
    setYourDiplome,
    updateIndoorWeather,
    setDiplomeExperience,
    setSynthiesIsYouSkill,
    setYourEconomicCapital,
    setYourBestProfessionalExperience,
    setAssessYourSocialCapitalMobilizable,
    setAccessYourCulturalCApitalMobilizable,
  };

};

export default useBook4;