/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { FC } from 'react';
import Podium from './Podium';
import { useStyles } from './style';

interface IFirstView {
  listSore: any[];
}

const FirstView: FC<IFirstView> = ({ listSore }) => {
  const classe = useStyles();
  console.log({ listSore });

  return (
    <div className={classe.root}>
      <Grid container={true} spacing={2} className={classe.marginFistViewTop}>
        <Grid item={true} md={7} lg={7} xs={12}>
          <div className={classe.contentNoPadding}>
            <div className={classe.padding25}>
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}
              >
                SYNTHÈSE DE VOS RÉSULTATS
              </Typography>
              <div className={classe.podium}>
                <Podium first={listSore[0]} second={listSore[1]} third={listSore[2]} />
              </div>

              <Typography className={classe.whenEqual}>
                *en cas d&#39;égalité de scores l&#39;une des valeurs est mise en premier
                automatiquement
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid
          item={true}
          container={true}
          md={5}
          lg={5}
          xs={12}
          direction="column"
          style={{ height: '100%' }}
        >
          <Grid item={true} xs={12} style={{ paddingBottom: 16 }}>
            <div className={classe.content2}>
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}
              >
                DÉCOUVREZ VOS VALEURS
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={`${classe.description2}`}
                style={{ marginTop: 25 }}
              >
                Voici vos valeurs directrices. <br />
                Vos valeurs directrices guident vos pensées et vos comportements. Elles sont à la
                source du sens que vous donnez à vos actions. Agir conformément à vos valeurs vous
                permettra de développer votre motivation et votre bien-être. Vous vous sentirez
                aligné(e) entre ce que vous êtes et ce que vous faites.
              </Typography>
            </div>
          </Grid>
          <Grid item={true} xs={12}>
            <div className={classe.content2}>
              <Typography
                variant="h4"
                color="primary"
                className={`${classe.title}`}
                style={{ color: 'black', fontSize: 23 }}
              >
                APPLICATION DANS VOTRE VIE PRO ET PERSO
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={`${classe.description2}`}
                style={{ marginTop: 25 }}
              >
                Dans votre vie professionnelle, vous devriez exercer un métier cohérent avec vos
                valeurs et travailler dans une organisation qui vous permet de les exprimer à
                travers vos actions. Dans votre vie personnelle, vous devriez développer des
                relations avec des personnes qui partagent vos valeurs. Développer des relations
                avec des personnes qui ont la même vision de la vie que vous favorise la bonne
                entente et l’harmonie.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* <div className={classe.content}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={7} lg={6} xs={12}>
            <div className={classe.podium}>
              <Podium first={listSore[0]} second={listSore[1]} third={listSore[2]} />
            </div>

            <Typography className={classe.whenEqual}>
              *en cas d&#39;égalité de scores l&#39;une des valeurs est mise en premier
              automatiquement
            </Typography>
          </Grid>

          <Grid md={5} lg={6} xs={12}>
            <Typography className={classe.description}>
              Voici vos valeurs directrices. Vos valeurs directrices guident vos pensées et vos
              comportements. Elles sont à la source du sens que vous donnez à vos actions. Agir
              conformément à vos valeurs vous permettra de développer votre motivation et votre
              bien-être. Vous vous sentirez aligné(e) entre ce que vous êtes et ce que vous faites.
            </Typography>
          </Grid>
        </Grid>
      </div> */}
    </div>
  );
};

export default FirstView;
