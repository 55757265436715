export const context = [
    {
        title: 'DEVENIR BÉNÉVOLE DANS UNE ASSOCIATION',
        id: 3,
        listContext: [
            `Les associations recrutent en permanence des bénévoles. Devenir bénévole,
                c’est une manière de donner du sens à sa vie en se rendant utile aux autres.
                Il existe des milliers d’associations avec chacune une raison d’être différente :
                associations sportives, associations à vocation sociale, associations d’éducation, de
                formation, d’organisation de voyages, clubs littéraires ou autour des arts, à vous de
                trouver l’association qui vous convient. Cet engagement associatif vous permettra
                de redonner du sens et du peps à votre vie.`
        ]
    },
    {
        title: 'DEVENIR ÉLU(E) OU MILITANT(E) DANS UN PARTI POLITIQUE',
        id: 4,
        listContext: [
            `Vous habitez dans une grande ville, vous pourriez adhérer à un parti politique. Cela
            vous donnera l’occasion de rencontrer d’autres personnes, de travailler sur des
            projets, de participer à une aventure collective. Et pourquoi pas, si vous vous prenez
            au jeu, prendre un jour des fonctions électives.`,
            `Si vous habitez dans une petite commune, présentez votre candidature à la mairie
            ou devenez conseiller(ère) municipal(e). Vous découvrirez de nouveaux projets,
            développerez vos réseaux et acquerrez de nouvelles compétences tout en vous
            rendant utile.
            `
        ]
    },
    {
        title: 'VOUS LANCER DANS UN PROJET PERSONNEL',
        id: 5,
        listContext: [
            `Écrire un livre, réaliser un film ou une sculpture, partir faire un voyage à l’autre bout
            du monde, rénover sa maison, fabriquer des meubles, etc. autant d’activités pour
            lesquelles vous pouvez vous passionner et qui peuvent satisfaire vos besoins de sens,
            de création, d’inventivité, de liberté. Certaines de ces activités peuvent devenir un
            jour une activité professionnelle à part entière. En attendant, elles vous apporteront
            de la satisfaction et vous aideront à relativiser votre situation dans votre travail actuel.`,
            `Comme quoi, on peut changer, sans changer.`
        ]
    }
]