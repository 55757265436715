export const listSomaire = [
    {
        text: `VALIDEZ VOTRE PROJET (PLAN A) ET ÉLABOREZ VOTRE PLAN D’ACTION`,
        number: '7',
        isBold: true,
    },
    {
        text: 'VOTRE PROJET PROFESSIONNEL ET VOS ASPIRATIONS',
        number: '12',
        isBold: false,
    },
    {
        text: 'VOTRE PROJET PROFESSIONNEL ET VOS COMPÉTENCES',
        number: '15',
        isBold: false,
    },
    {
        text: 'VOTRE PLAN B : AU CAS OÙ VOTRE PROJET NE POURRAIT PAS ABOUTIR',
        number: '17',
    },
    {
        text: 'RELEVEZ LE DIP (DÉFI IMPOSSIBLE OU POSSIBLE)',
        number: '19',
        isBold: true,
    },
    {
        text: 'CE QUE VOUS DEVEZ ÊTRE PRÊT(E) À FAIRE POUR RÉUSSIR',
        number: '21',
    },
    {
        text: 'CE À QUOI VOUS ALLEZ DEVOIR RENONCER POUR RÉALISER VOTRE PROJET',
        number: '22',
    },
    {
        text: 'LES PRINCIPALES ZONES D’INCERTITUDE',
        number: '23',
        isBold: true,
    },
    {
        text: 'LES DIX CHOSES QUE VOUS GAGNEREZ EN RÉALISANT VOTRE RÊVE',
        number: '24',
    },
    {
        text: 'DOSSIER : ANTICIPER VOTRE FUTUR',
        number: '27',
    },
    {
        text: 'FAITES LE GRAND CHOIX',
        number: '29',
        isBold: true,
    },
    {
        text: 'LES BONNES ATTITUDES SI VOUS DÉCIDEZ DE RENONCER',
        number: '30',
    },
    {
        text: 'VOUS AVEZ DÉCIDÉ DE RÉALISER VOTRE PROJET',
        number: '31',
    },
    {
        text: 'PLANIFIEZ VOTRE PROJET',
        number: '32',
    },
    {
        text: '6 CONSEILS POUR CONDUIRE VOTRE PROJET',
        number: '33',
    },
    {
        text: 'POUR ALLER PLUS LOIN',
        number: '34',
        isBold: true,
    },
]