import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  containerPaper: {
    '& .MuiDialog-paper': {
      padding: '10px',
      '@media only screen and (max-width: 600px)': {
        padding: '0px !important',
      },
    },
  },
}));
