/* eslint-disable prefer-const */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ApiActions } from '@orientaction/api-actions';
import { backgroundChart, publicIconPath, isAdmin, getFromLS } from '@orientaction/utils';
import { kebabCase } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import round from 'lodash/round';
import sumBy from 'lodash/sumBy';
import { FC, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ShareIcons from './ShareIcons';
import style from './style';

interface IProps {
  userResponse: any;
}

const colors = ['#23296F', '#fff'];

const textColors = ['#fff', '#000A8C'];

const SimplePie: FC<IProps> = ({ userResponse }) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const classes = style();

  const dispatch = useDispatch();
  const history = useHistory();

  let allVariables: any[] = [];
  userResponse?.attributes?.responses?.data.forEach((response: any) => {
    response?.attributes.variable.data.forEach((variable: any) => {
      allVariables.push({
        ...variable,
        variableValue: response.attributes.variableValue, // Attribuer variableValue à chaque variable
      });
    });
  });
  const variableGroup = groupBy(allVariables, (variable: any) => variable.id);

  /* const variableGroup = groupBy(userResponse?.attributes.responses.data, (response: any) => {
    return response.attributes.variable.data.id;
  }); */

  /* const dataMapValue = map(variableGroup, (response: any) => {
    const sumVariable = sumBy(response, (variable: any) => variable.attributes.variableValue);
    const background = cloneDeep(backgroundChart);
    const idStyle = Math.floor(Math.random() * background.length);
    const dataBeforeRemove = {
      value: sumVariable,
      label: response[0].attributes.variable.data.attributes.name,
      image: response[0].attributes.variable.data.attributes.image,
      description: response[0].attributes.variable.data.attributes.description,
      bg: background[idStyle]?.bg,
      bd: background[idStyle]?.bd,
    };

    return dataBeforeRemove;
  }); */

  const dataMapValue = map(variableGroup, (variables: any[]) => {
    const sumVariable = sumBy(variables, (variable: any) => variable.variableValue);
    const firstVariable = variables[0];
    const background = cloneDeep(backgroundChart);
    const idStyle = Math.floor(Math.random() * background.length);

    return {
      value: sumVariable,
      label: firstVariable.attributes.name,
      title: firstVariable.attributes.title,
      image: firstVariable.attributes.image,
      description: firstVariable.attributes.description,
      bg: background[idStyle]?.bg,
      bd: background[idStyle]?.bd,
    };
  });

  // const totalValue = sumBy(dataMapValue, (value: any) => value.value) || 0;
  const totalValue = sumBy(dataMapValue, (item: any) => item.value) || 0;

  const dataMapValuePourcentage = dataMapValue
    .sort((a: any, b: any) => b.value - a.value)
    .map((item: any, index: number) => {
      const valuePercent = (item.value * 100) / totalValue;
      return {
        ...item,
        value: round(valuePercent, 2),
        color: colors[index % colors.length],
        bg: colors[index],
        txtColor: textColors[index],
      };
    });

  /*  const dataMapValuePourcentage = map(
    dataMapValue.sort((a: any, b: any) => b.value - a.value),
    (value: any, index: number) => {
      const valuePercent = (value.value * 100) / totalValue;
      const valueLabel = value.label;
      return {
        value: round(valuePercent),
        label: valueLabel,
        description: value?.description,
        image: value?.image,
        color: colors[index],
        bg: colors[index],
        txtColor: textColors[index],
      };
    }
  );
*/
  const maximumResponse = dataMapValuePourcentage[0];

  const processedData = {
    datasets: [
      {
        labels: dataMapValuePourcentage.map((i: any) => i.label),
        data: dataMapValuePourcentage.map((i: any) => i.value),
        backgroundColor: dataMapValuePourcentage.map((i: any) => i.bg),
        borderColor: dataMapValuePourcentage.map((i: any) => i.bd),
        borderWidth: 1,
        datalabels: {
          labels: {
            name: {
              align: 'end' as const,
              anchor: 'end' as const,
              color: 'white',
              font: {
                size: 50,
                family: 'Poppins',
              },
              display: false,
              clamp: false,
              clip: false,
            },
          },
        },
      },
    ],
    // labels: data.map((i: any) => i.label),
  };

  const copyTestLink = () => {
    navigator.clipboard.writeText(testUrl);
    setLinkCopied(true);
  };

  const redirect = () => {
    dispatch(ApiActions.setTabLayout(1 as any));
    history.push('/user/tests');
  };

  const test = userResponse?.attributes?.test?.data;
  const testUrl = `${process.env.REACT_APP_SHARE_ENDPOINT}/api/test/${test.id}/${kebabCase(
    test.attributes.title
  )}`;
  const user = JSON.parse(getFromLS('userInfos') || '');

  return (
    <Grid className={classes.simplePieContainer} container={true} justifyContent="center">
      <Grid xs={12} md={5}>
        <Typography variant="h2" className={classes.textH2}>
          Résultats du test
        </Typography>

        <div>
          <Pie
            className={classes.pie}
            data={processedData}
            options={{
              plugins: {
                tooltip: {
                  enabled: false,
                },
                datalabels: {
                  display: true,
                  color: dataMapValuePourcentage.map((i: any) => i.txtColor),
                  labels: {
                    title: {
                      font: {
                        weight: 'bold',
                        size: 25,
                      },
                      align: 'center',
                      padding: 0,
                    },
                  },
                  formatter(value: any) {
                    return `${value}%`;
                  },
                },
              },
              layout: {
                padding: {
                  top: 10,
                  bottom: 10,
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>

        <div className={classes.flexData}>
          <span>
            {dataMapValuePourcentage?.map((elem: any) => {
              return (
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 15 }}>
                  <span>
                    <div
                      style={{
                        height: 20,
                        width: 20,
                        backgroundColor: elem.bg,
                        borderRadius: '50%',
                      }}
                    />
                  </span>
                  <span style={{ color: 'white', marginLeft: 10, width: '90%' }}>{elem.label}</span>
                </div>
              );
            })}
          </span>
        </div>
      </Grid>
      <Grid className={classes.resultDetailsContainer} item={true} xs={12} md={6}>
        <Typography variant="h3" className={classes.textH3}>
          {dataMapValuePourcentage.length && maximumResponse.label}
        </Typography>
        <p
          className={classes.text}
          dangerouslySetInnerHTML={{
            __html: maximumResponse ? maximumResponse.description : 'En attente...',
          }}
        />
        {!isAdmin(user) && (
          <>
            <Grid alignItems="center" justifyContent="center" container={true}>
              <Grid item={true}>
                <Typography className={classes.shareLabelText}>Partager ce test</Typography>
              </Grid>
              <Grid item={true}>
                <div className={classes.shareIconsContainer}>
                  <div className={classes.socialMediaIconsContainer}>
                    <ShareIcons
                      title={test?.attributes?.title}
                      testUrl={testUrl}
                      description={test?.attributes?.description}
                    />
                    <Button
                      endIcon={
                        linkCopied ? undefined : (
                          <img src={`${publicIconPath}/ContentCopy.svg`} alt="Copy icon" />
                        )
                      }
                      variant="outlined"
                      className={classes.studyRestitutionShareButton}
                      onClick={copyTestLink}>
                      {linkCopied ? 'Lien copié !' : 'Copier le lien'}
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item={true} sm={12}>
                <Typography
                  className={classes.shareLabelText}
                  style={{ textAlign: 'center', fontSize: '12px', marginTop: 5 }}>
                  Les utilisateurs ne verront pas vos résultats, mais pourront passer le test.
                </Typography>
              </Grid>
            </Grid>
            <Button className={classes.btnPrimary} onClick={redirect}>
              <span className={classes.textHeader}>Faire un nouveau test</span>
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SimplePie;
