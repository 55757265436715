import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { FC } from 'react';
import { DialogContent, DialogTitle } from '../Dialog';
import style from './style';

interface IProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children?: any;
  footerBtnContainerOverrideStyle?: string;
  loading?: boolean;
  cancelText?: string;
  confirmText?: string;
  handleConfirm: () => void;
  modalOverrideStyle?: string;
  withConfirm?: boolean;
  withCancel?: boolean;
}

const OaPopin: FC<IProps> = ({
  open,
  handleClose,
  title = '',
  children,
  footerBtnContainerOverrideStyle,
  loading = false,
  cancelText = 'Non',
  confirmText = 'Oui',
  handleConfirm,
  modalOverrideStyle = '',
  withConfirm = true,
  withCancel = true,
}) => {
  const classes = style();
  return (
    <Dialog
      className={classNames(classes.modal, modalOverrideStyle)}
      open={open}
      aria-labelledby="oa-common-popin">
      <DialogTitle id="oa--title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <div className={classNames(classes.footerBtnContainer, footerBtnContainerOverrideStyle)}>
        <Grid
          container={true}
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center">
          {withCancel && (
            <Grid item={true}>
              <Button
                onClick={handleClose}
                variant="outlined"
                style={{ textTransform: 'none' }}
                disabled={loading}>
                {cancelText}
              </Button>
            </Grid>
          )}
          {withConfirm && (
            <Grid item={true}>
              <Button
                id="confirmButton"
                onClick={handleConfirm}
                style={{ textTransform: 'none' }}
                className={classes.validate}
                disabled={loading}
                data-cy="btn-on-confirm-modal">
                {confirmText}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Dialog>
  );
};

export default OaPopin;
