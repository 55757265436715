/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr, Paragrapth, Title } from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';

const Page11 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={11}>

            <Title
                title="CHOIX N°1 : ÉVOLUER EN INTERNE ?"
                firstTitle="‘‘ Plus faibles sont les risques, meilleure est l’entreprise. ’’"
                firtsecondTitle="Sophocle"
                secondTitle="‘‘ L’entreprise ne peut exiger la loyauté de ses salariés : elle doit la mériter. ’’"
                secondSousTitle="Charles Handy"
            />

            <MarginContainer
                big={true}
            >
                <Typography
                    className={classes.paragraphe}
                    style={{
                        fontFamily: 'ITC Avant Garde Gothic Std Medium',
                        marginTop: '10px',
                        textAlign: 'justify',
                        fontWeight: 'lighter'
                    }}
                >
                    Cette option est sans doute la plus confortable. Vous conservez la sécurité de l’emploi
                    ainsi que les avantages acquis au cours de votre carrière chez le même employeur.
                    Une évolution en interne permet de capitaliser sur l’expérience et la connaissance
                    de votre organisation (sa culture, son histoire, ses codes, etc.) et l’utiliser comme
                    un tremplin pour évoluer vers un nouveau poste, davantage en adéquation avec
                    vos attentes. C’est un pari sur l’avenir, sur l’avenir de votre organisation et sur vos
                    chances de réussir en son sein.
                </Typography>
                <Typography
                    className={classes.paragraphe}
                    style={{
                        fontFamily: 'ITC Avant Garde Gothic Std Medium',
                        marginTop: '20px',
                        textAlign: 'justify',
                        fontWeight: 'lighter'
                    }}
                >
                    Mais attention, une évolution interne n’est pas toujours la solution idéale. Rien ne
                    serait pire que de choisir cette option seulement pour la sécurité de l’emploi au
                    détriment de votre épanouissement personnel et de se retrouver dans trois ou cinq
                    ans encore au même poste, bloqué(e), ayant perdu au passage toute énergie
                    pour changer et réussir la vie professionnelle à laquelle vous aspiriez. Une évolution
                    interne dépend en effet du bon vouloir de votre employeur. C’est donc parfois un
                    pari risqué.
                </Typography>
            </MarginContainer>

            <TitleNbr
                number={1}
                text="LES ÉVOLUTIONS VERTICALES"
            />

            <div className={classes.cardBgGrey}>

                <div className={classes.content}>

                    <MarginContainer>
                        <Paragrapth colorBlue={true} align='center' addBackground={true}>
                            « Plus on approche du sommet et moins il y a de place pour s’asseoir »
                        </Paragrapth>
                    </MarginContainer>

                    <MarginContainer>
                        <Paragrapth align='justify' addBackground={true}>
                            Un matin, vous êtes parti(e) gravir une montagne. Les conditions étaient réunies : une forme excellente, un temps sublime. Sur la route, vous n’avez rencontré personne. Normal,
                            vous aviez choisi de partir à l’aube. Vous vous êtes garé(e) sur un grand parking. Quelques
                            randonneurs étaient déjà là.
                        </Paragrapth>
                    </MarginContainer>

                    <MarginContainer>
                        <Paragrapth align='justify' addBackground={true}>
                            Sans vous soucier d’eux, vous avez démarré votre ascension. Rapidement, vous vous êtes
                            aperçu(e) que certains randonneurs étaient partis avant le lever du soleil à la lampe
                            torche. Vous ne pensiez vraiment pas que cette montagne intéresserait autant de monde.
                            Vous avez poursuivi en marchant d’un bon pas.
                        </Paragrapth>
                    </MarginContainer>

                    <MarginContainer>
                        <Paragrapth align='justify' addBackground={true}>
                            Mais plus vous avanciez et plus il y avait de randonneurs sur le chemin qui allait en
                            rétrécissant. Pour gravir cette montagne, on pouvait partir de différents endroits, les
                            sentiers se rejoignant tous par la suite au même endroit. Vous avez péniblement dépassé
                            trois groupes de randonneurs plus âgés qui peinaient dans la pente et provoquaient
                            des embouteillages. Vous armant de courage, vous avez gravi la montagne jusqu’au
                            sommet. Arrivé(e) là-haut, il y avait tant de monde qu’il était impossible de s’asseoir.
                        </Paragrapth>
                    </MarginContainer>

                    <MarginContainer>
                        <Paragrapth align='justify' addBackground={true}>
                            Il fallait même se bagarrer pour prendre une photo. Quelle journée...
                        </Paragrapth>
                    </MarginContainer>

                </div>

            </div>

        </Page>
    );
};

export default Page11;
