export const paragraph = [
    `Quand on a atteint un certain niveau de compétences et de rémunération,
    que son profil est très contextualisé (carrière linéaire dans une même
    organisation durant de longues années), le profil perd de son attractivité.
    Parfois ce niveau d’attractivité est tel que la seule chance de survie professionnelle
    est la création/reprise d’entreprise.`,
    `Le facteur clef dans ces situations est le temps. Plus vous attendez et moins vous
     avez de chances de rebondir. En effet, vos ressources financières s’amenuisant,
     vous risquez de ne plus avoir les moyens de créer votre boîte.`,
]

export const list = {
    'context': {
        'label': "LE CONTEXTE",
        'listParagraph': [
            `Le marché de l’emploi est morose dans
    votre secteur. Vous n’avez pas le profil
    idéal (trop compétent(e), jugé(e) trop
    âgé(e) etc.)`,
            `Les seuls postes accessibles semblent être
    des postes moins qualifiés et précaires.`,
            `Les employeurs recrutent des profils
    comme le vôtre, mais à des niveaux de
    rémunération très inférieurs à ce que vous
    gagniez.`,
            `Les offres d’emploi pouvant répondre à
        vos aspirations se situent dans des zones
        géographiques très éloignées de votre
        domicile.`
        ]
    },
    'you': {
        'label': "VOUS",
        'listParagraph': [
            `Vous avez cherché de façon active un emploi
        pendant une durée supérieure à douze mois
        sans trouver d’opportunités satisfaisantes.`,
            `Vous avez envie de vous investir dans un
        projet de long terme qui mobilise vos
        compétences.`,
            `Vous avez envie de bien gagner votre vie ou
        de revenir au moins à ce que vous gagniez
        avant, en tant que salarié(e).`,
            `Vous n’êtes pas mobile. Vous souhaitez
        continuer à vivre dans la zone géographique
        où vous habitez actuellement.`
        ]
    }
}