import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: '8px',
      width: '100%',
      overflow: 'hidden',
    },
  }),
);
