/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-use-before-define */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleChapitre } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBookIntro } from '../../../../utils/constants';

const Page21 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={21}>
      <TitleChapitre nbr={3} />
      <div style={{ marginTop: '10px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            className="opacity07">
            LES OBSTACLES À SURMONTER ET LES
            <br />
            DÉCISIONS À PRENDRE
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <div>
          <Grid container={true} spacing={1} justifyContent="center">
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}>
                Changer est rarement un processus simple, nous l’avons déjà souligné à plusieurs
                reprises. Les obstacles sont nombreux et rarement là où nous les attendons le plus.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                  marginTop: '15px',
                }}>
                Nos proches, supposés vouloir notre bonheur, se montrent souvent réticents. Nous
                découvrons que derrière notre envie de changer se cache parfois une pulsion presque
                aussi forte qui nous pousse à l’immobilisme et à la procrastination.
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                  marginTop: '7px',
                }}>
                Changer est une course d’obstacles qui demande du courage, de la méthode, une
                stratégie et une bonne dose de soutien.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <img
        src={`${imgBookIntro}/montagne.png`}
        alt="patissier"
        style={{
          width: '40%',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: 50
        }}
      />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1000px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      lineHeight: 1.6,
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
  })
);
export default Page21;
