/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import {
    Page,
    TitlePage,
    Paragrapth
} from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { useBook5 } from '@orientaction/hooks_book';
import Array from './Array';
import { listForm } from './context';

const Page15 = (props: any) => {

    const { setFourPreferenceOfYourPersonnality, fourPreferenceOfYourPersonnality } = useBook5();

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setFourPreferenceOfYourPersonnality({
            ...fourPreferenceOfYourPersonnality,
            [name]: value
        })

    }

    return (
        // eslint-disable-next-line prettier/prettier
        <Page
            id={15}
            readOnly={false}
        >

            <TitlePage>
                VOTRE PROJET PROFESSIONNEL<br />
                ET VOS COMPÉTENCES
            </TitlePage>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Décrivez votre projet d’évolution professionnelle en lien avec vos compétences.
                    Prenez soin à chaque fois d’expliquer précisément en quoi il répond à vos
                    compétences sur ces 4 dimensions : les 4 capitaux, les « soft skills », les expériences
                    et la valeur ajoutée. Référez-vous à l’étape 4 consacrée à l’identification de vos
                    compétences en essayant au maximum de reprendre le même vocabulaire.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Cette fiche servira à présenter votre projet d’évolution professionnelle à un(e)
                    recruteur(se) ou à un jury de sélection.
                </Paragrapth>
            </MarginContainer>

            <MarginContainer big={true}>
                <Array
                    handleChange={handleChange}
                    defaultValue={fourPreferenceOfYourPersonnality}
                    title="QUELS CAPITAUX POUVEZ-VOUS MOBILISER <br/>POUR RÉUSSIR CE PROJET ?"
                    readOnly={props.readOnly}
                    listForm={listForm}
                />
            </MarginContainer>

        </Page>

    );
};

export default Page15;
