/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page20 = (props: any) => {
  const classes = useStyles();
  const [dataDefault, setDataDefault] = useState('');
  return (
    <Page id={20}>
      <div style={{ textAlign: 'center' }}>
        <Typography
          variant="h1"
          component="h1"
          color="primary"
          gutterBottom={true}
          className="opacity07"
        >
          CHOISISSEZ VOTRE DESTIN <br />
        </Typography>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            <strong>‘‘ Le destin mêle les cartes et nous jouons. ’’</strong>
          </Typography>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}
          >
            Arthur Schopenhauer
          </Typography>
        </div>
        <div
          className={clsx(classes.positionCenter)}
          style={{ marginTop: '40px', marginBottom: '40px' }}
        />
        <div style={{ textAlign: 'center' }}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              fontWeight: 'bold',
              fontStyle: 'italic',
            }}
          >
            <strong>‘‘ Un destin n’est jamais une punition. ’’</strong>
          </Typography>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter' }}
          >
            Albert Camus
          </Typography>
        </div>
        <div
          className={clsx(classes.trait, classes.positionCenter)}
          style={{ marginTop: '75px', marginBottom: '75px' }}
        />
      </div>
      <div style={{ marginTop: '50px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '50px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Notre temps d’activité représente, si l’on enlève les temps de sommeil, 70% de notre temps
          de vie. L’activité professionnelle est le pivot de l’existence et, à travers notre métier,
          nous contribuons à transformer le monde et sommes utiles aux autres.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Se sentir utile aux autres est un facteur déterminant pour être heureux(euse) et donner du
          sens à sa vie. Les personnes qui se sentent utiles sont en général plus épanouies et plus
          heureuses que les autres.
        </Typography>
      </div>
      <div style={{ marginTop: '50px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            color="primary"
            className={classes.testimony}
            style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium' }}
          >
            <strong>
              « Si je réalise des actions utiles pour les autres, <br /> alors je trouve un sens à
              ce que je fais ».
            </strong>
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '80px' }}>
        <div className={classes.cardBgGrey}>
          <Grid container={true}>
            <Grid xs={12} sm={12}>
              {/* <img className={classes.img} src={`${publicSvgPath}/artisans.png`} alt="logo" /> */}
              <SimpleBanner bannerContainerOverride={classes.bannerContainerOverride}>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                >
                  L&rsquo;ARTISAN DEVENU OUVRIER
                </Typography>
              </SimpleBanner>
              <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <img
                  className={classes.imgFurious}
                  src={`${imgBook1}/furious.png`}
                  alt="logo"
                  style={{ marginTop: '-70px' }}
                />
              </Hidden>
              <Hidden xsDown={true}>
                <img
                  className={classes.imgFurious1}
                  src={`${imgBook1}/furious.png`}
                  alt="logo"
                  style={{ marginTop: '-70px', float: 'right' }}
                />
              </Hidden>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Notre temps d’activité représente, si l’on enlève les temps de sommeil, 70% de notre
                temps de vie. L’activité professionnelle est le pivot de l’existence et, à travers
                notre métier, nous contribuons à transformer le monde et sommes utiles aux autres.
              </Typography>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '10px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Les technologies ont évolué, les méthodes de travail et les organisations aussi.
                Avant, Antoine avait des relations directes avec les autres services. On lui
                adressait une demande sur un problème technique et il passait des heures, parfois
                des jours à travailler pour le résoudre. Quand il y parvenait, il avait le sentiment
                d’avoir été utile aux autres.
              </Typography>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '20px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Souvent − parce qu’il réussissait − on lui téléphonait ou on lui adressait un email
                pour le féliciter. Tout est plus complexe à présent. Il ne peut plus résoudre un
                problème seul. C’est un travail d’équipe et ce travail est décomposé en une
                multitude de petites tâches, qui sont confiées chacune à un service différent.
              </Typography>
              <Typography
                className={classes.paragraphe}
                style={{
                  fontFamily: 'ITC Avant Garde Gothic Std Medium',
                  marginTop: '20px',
                  textAlign: 'justify',
                  fontWeight: 'lighter',
                }}
              >
                Les problèmes techniques sont si complexes et si longs à résoudre que les personnes
                qui formulent des demandes sont rarement satisfaites. Antoine éprouve un sentiment
                de malaise. Il a l’impression de ne plus servir à rien.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '50px' }}>
        <Typography
          color="primary"
          className={classes.testimony}
          style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'center' }}
        >
          <strong>IL EXISTE 32 GRANDES FIGURES DU DESTIN.</strong>
        </Typography>
      </div>
      <div style={{ marginBottom: '50px' }}>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Chacune de ces figures représente une famille d’activités qui se différencie des autres
          familles par son degré d’utilité sociale. Ce sont des activités, des métiers qui existent
          depuis la nuit des temps.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Connaître la(les) figure(s) du destin qui vous ressemble(nt) le plus va vous aider à
          choisir l’activité, le métier qui aura le plus de sens pour vous.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Cela vous permettra également d’entamer une réflexion sur la place que vous souhaitez
          occuper dans la société, la place qui vous apporte de la reconnaissance et donne du sens à
          votre vie.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le test qui va suivre a été conçu par{' '}
          <strong style={{ color: '#3f51b5' }}>ORIENTACTION</strong> afin de vous permettre
          d’identifier la place que vous souhaitez occuper dans la société et de quelle manière vous
          pouvez être utile aux autres.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Attention, pensez toujours que l’utilité est un sentiment, une perception.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Certaines personnes, exerçant la même activité, peuvent se sentir utiles ou inutiles.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le sens que l’on donne à son travail dépend beaucoup de la façon dont on perçoit la
          situation.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          À ce propos, je vous invite à lire, à la page suivante, l’histoire inspirante des trois
          tailleurs de pierres.
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerContainerOverride: {
      alignItems: 'flex-start',
      margin: 'unset',
    },
    bannerStyleOverride: {
      margin: 'unset',
      marginLeft: 3,
      width: 300,
      height: 125,
    },
    childrenContainerOverride: {
      marginTop: -76,
    },
    bannerTitleContainer: {
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '60%',
      height: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
        marginTop: '0px !important',
      },
    },
    imgFurious: {
      width: '80%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
      },
    },
    imgFurious1: {
      width: '30%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        width: '50%',
        marginTop: '0px !important',
        display: 'none',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 20,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page20;
