import { FC } from 'react';
import { DndProvider } from 'react-dnd';
import withScrolling from "react-dnd-scrolling";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useStyles } from './style';

const ScrollingComponent = withScrolling("div");

interface IProvider {
    children: any;
}

const DragAndDrop: FC<IProvider> = ({ children }) => {

    const classe = useStyles();

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <ScrollingComponent
                    className={classe.root}
                >
                    {children}
                </ScrollingComponent>
            </DndProvider>
        </>
    );
};

export default DragAndDrop;
