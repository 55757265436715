import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        trait: {
            backgroundColor: 'yellow',
            width: '75px',
            height: '5px',
            marginTop: '50px',
            marginBottom: '50px'
        },
        testimony: {
            fontSize: '17px',
            color: "#2B56A5",
        },
        label1: {
            fontSize: '20px',
            color: '#2B56A5',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            fontStyle: 'italic',
            [theme.breakpoints.down('sm')]: {
                fontSize: '15px',
            }
        },
        label2: {
            fontWeight: 'lighter',
            fontSize: '18px',
            color: '#2B56A5',
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            margin: '2vh 0',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            }
        },
        positionCenter: {
            display: 'block',
            margin: 'auto'
        },
        divider: {
            width: '90%',
            height: '1px',
            borderTop: '1px dashed grey',
        },
        title: {
            color: '#2B56A5',
            // marginTop: '50px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.5vh'
            }
        }
    })
);

export default useStyles;