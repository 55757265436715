/* eslint-disable simple-import-sort/imports */
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleMain: {
      textAlign: 'center',
      fontSize: '1.875rem',
      lineHeight: '2.25rem',
      borderBottom: 'solid 1px #e4e4f2',
      paddingBottom: '1rem',
      marginBottom: '3.5rem',
    },
    titleItem: {
      textAlign: 'center',
      fontSize: '.875rem',
      marginTop: '.375rem',
    },
    list: {
      justifyContent: 'center',
      paddingLeft: '0',
      listStyle: 'none',
    },
    selectedItem: {
      border: '1px solid #000A8C',
    },
    containerImg: {
      position: 'relative',
      display: 'block',
      height: 'auto',
    },
    check: {
      position: 'absolute',
      bottom: '14%',
      right: '0%',
      background: '#000A8C',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
      borderRadius: '50%',
      border: '2px solid white',
    },
    btn: {
      display: 'block',
      margin: 'auto',
      marginBottom: '60px',
      background: '#000A8C',
    },
    btnFloat: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
  })
);
