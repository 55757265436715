import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FC } from 'react';
import style from './randomPasswordSectionStyle';

interface IProps {
  password: string;
  isCopied: boolean;
  generatePassword: () => void;
  copyPassword: () => void;
}

const RandomPasswordSection: FC<IProps> = ({
  password,
  isCopied,
  generatePassword,
  copyPassword,
}) => {
  const classes = style();

  return (
    <div className={classes.container}>
      <div className={classes.alignItemsCenter}>
        <Button
          type="button"
          className={classes.btnPrimary}
          onClick={generatePassword}
          disabled={!!password?.length}>
          <span className={classes.btnText}>Générer un mot de passe aléatoire</span>
        </Button>
        <div
          className={classes.italicText}
          style={{ visibility: password?.length ? undefined : 'hidden' }}>
          Mot de passe généré !
        </div>
      </div>
      {!!password?.length && (
        <div className={classes.alignItemsCenter}>
          <IconButton className={classes.btnIcon} onClick={copyPassword}>
            <FileCopyIcon className={classes.styleIcon} fontSize="large" />
          </IconButton>
          <div
            className={classes.italicText}
            style={{ visibility: isCopied ? undefined : 'hidden', marginLeft: 20 }}>
            Copié !
          </div>
        </div>
      )}
    </div>
  );
};

export default RandomPasswordSection;
