import { Typography } from '@material-ui/core';
import style from '../../../commonStyle';

const LawPoliticalScienceSuggestion = () => {
  const classes = style();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        🧭 TROIS VOIES D’ORIENTATION PRIVILÉGIÉES :
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Voici trois voies que tu pourrais emprunter pour atteindre tes objectifs en fonction de
          tes résultats scolaires :
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec d&apos;excellents résultats (moyenne ≥15) : IEP (Instituts
              d&apos;Étude Politique) en visant le Graal Sciences Po Paris.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec de bons résultats (moyenne ≥12) : licence universitaire en UFR de
              droit.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec des résultats moyens (moyenne ≥10) : DUT juridiques (Carrières
              juridiques...) ou BTS notariat.
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionQuote} component="p">
          « Si vous avez la force, il nous reste le droit. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Victor Hugo
      </Typography>
    </div>
  );
};

export default LawPoliticalScienceSuggestion;
