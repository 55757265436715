/* eslint-disable no-use-before-define */
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import DoubleBanner from '../../../components/DoubleBanner';
import Img from '../../Img';
import useStyles from './style';

const Page22 = (props: any) => {
  const classes = useStyles();

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Page id={22}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          <Grid container={true} direction="row" justifyContent="center" alignItems="flex-end">
            <Grid item={true} xs={12} md={8}>
              <MarginContainer>
                <MarginContainer>
                  {/* <Img
                                        src='parcoursCombatant.jpg'
                                        alt='parcoursCombatant'
                                        style={{
                                            width: "100%"
                                        }}
                                    /> */}
                  {/* <div style={{ marginBottom: 80 }}> */}
                  <DoubleBanner bannerContainerOverride={classes.bannerContainerOverride}>
                    <Typography
                      component="h1"
                      className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                    >
                      LE PARCOURS DU COMBATTANT
                    </Typography>
                    <Typography
                      component="h1"
                      className={clsx(classes.bannerTitleContainer1, classes.bannerTitle)}
                    >
                      DE MATHILDE
                    </Typography>
                  </DoubleBanner>
                  {/* </div> */}
                </MarginContainer>

                {smDown && (
                  <MarginContainer>
                    <Img
                      src="parcourDeCombatante.jpg"
                      alt="parcourDeCombatante"
                      className={classes.img}
                    />
                  </MarginContainer>
                )}

                <MarginContainer>
                  <Paragrapth align="justify" addBackground={true}>
                    Mathilde est déléguée médicale et son métier va disparaître dans un à deux ans,
                    à la suite d’une évolution de la règlementation sur la promotion des
                    médicaments. Après son bilan de compétences, elle décide d’entreprendre une
                    reconversion et de devenir directrice d’établissement de santé. Mathilde a
                    toujours été ambitieuse et elle prend ce changement comme une opportunité. Elle
                    commence par faire valider son expérience de déléguée médicale en B.T.S grâce à
                    une Validation des Acquis de l’Expérience (V.A.E). Reçue, elle s’inscrit alors à
                    une prépa d’un an.
                  </Paragrapth>
                </MarginContainer>
              </MarginContainer>
            </Grid>

            {!smDown && (
              <Grid item={true} xs={12} md={4}>
                <Img
                  src="parcourDeCombatante.jpg"
                  alt="parcourDeCombatante"
                  className={classes.img}
                />
              </Grid>
            )}
          </Grid>

          <MarginContainer>
            <Paragrapth align="justify" addBackground={true}>
              En effet, pour préparer le CAFDES, diplôme qui lui permettra d’exercer en tant que
              directrice d’un établissement de santé, elle doit d’abord être sélectionnée par une
              école. Le concours d’entrée est particulièrement difficile. Elle réalise sa prépa,
              passe le concours d’entrée et est admise dans une école de formation. Elle suit alors
              des cours pendant deux ans avant de passer le concours auquel elle est reçue. Commence
              pour elle le parcours classique et difficile de recrutement pour une directrice de
              haut niveau. Il lui aura fallu quatre ans pour atteindre son objectif et finalement
              être embauchée en tant que directrice d’une maison de retraite de quarante
              pensionnaires.
            </Paragrapth>
          </MarginContainer>
        </MarginContainer>
      </div>
    </Page>
  );
};

export default Page22;
