/* eslint-disable no-use-before-define */
import useStyles from './style';
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import { FC } from 'react';
import parse from 'html-react-parser';

interface IArray {
    title?: string;
    handleChange: (e: any) => void;
    defaultValue: string;
    listForm?: any[];
    readOnly?: boolean;
}

const Array: FC<IArray> = ({
    handleChange, defaultValue, title, listForm, readOnly
}) => {
    const classes = useStyles();

    return (
        <div className={classes.border}>

            <div
                className={classes.titlePage}
            >
                {title}
            </div>

            {
                listForm && (

                    <MarginContainer>

                        <div
                            className={classes.content}
                        >

                            {
                                listForm.map((item: any) => (
                                    <>

                                        <MarginContainer>

                                            <MarginContainer>
                                                <Paragrapth align='start'>
                                                    {parse(item.title)}
                                                </Paragrapth>
                                            </MarginContainer>

                                            <TextArea
                                                name={item.name}
                                                value={defaultValue?.[item.name] || ""}
                                                onChange={handleChange}
                                                largeTextField={true}
                                                readOnly={readOnly}
                                            />

                                        </MarginContainer>

                                    </>
                                ))
                            }

                        </div>

                    </MarginContainer>

                )
            }

        </div >
    );
};

export default Array;
