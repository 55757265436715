const explanationParagraphs: any = {
  study_competitor: [
    `Les personnes de style Compétiteur(trice) ont généralement d’excellents résultats scolaires. Douées de facilités à l’école, elles savent transformer leurs capacités en performance scolaire.
      En classe, elles occupent souvent les premiers rangs et aiment étudier les matières les plus ardues (Allemand, Latin, etc.) afin de côtoyer les élèves et les professeur(e)s les plus brillant(e)s.
      Appliquées, sérieuses, travailleuses, elles accordent en général une grande valeur à leurs notes. Elles ont un goût accru pour la compétition et recherchent l’excellence.`,
  ],

  study_epicurean: [
    `Les personnes de style Épicurien(ne) ont en général des résultats scolaires moyens. Douées de facilités à l’école, elles cherchent à faire le moins d’effort possible.
      Au travail scolaire, elles privilégient leur vie sociale et leurs loisirs. Si elles étudient des matières difficiles (Allemand, Latin, etc.), c’est plus pour plaire à leurs parents ou à leurs professeurs que par envie.
      Elles considèrent les résultats scolaires comme un moyen, jamais comme une fin. Ce qui les intéresse dans les diplômes, ce sont d'abord les avantages qu’ils procurent.`,
  ],

  study_freethinker: [
    `Les personnes de style Libre penseur(se) ont souvent de bons résultats scolaires.
      Douées de facilités, elles s'appuient sur leurs capacités de réflexion et leur maîtrise de la langue pour réussir.
      Ces personnes ne cherchent pas forcément à être de bon(ne)s élèves. Leurs résultats sont la conséquence de leur curiosité et de leur appétence pour les débats d'idées.
      Elles ont un rapport privilégié à la connaissance et à celles et ceux qui sont en mesure de la leur transmettre. Voilà pourquoi elles entretiennent souvent une relation de qualité avec les professeur(e)s.`,
  ],

  study_theorist: [
    `Les personnes de style Théoricien(ne) ont souvent des résultats scolaires contrastés ; excellant dans certaines matières, se révélant médiocre dans d’autres.
      Quand elles sont intéressées, ces personnes aiment apprendre. Cela leur permet de comprendre le monde.
      Ces personnes n’aiment pas travailler les matières qui ne les intéressent pas. Elles choisissent leurs matières de prédilection en fonction de leurs goûts.
      Elles n’ont pas l’ambition d'exceller en tout. Au contraire, elles préfèrent être des spécialistes. Elles préfèrent en général la théorie à la pratique.`,
  ],

  study_realistic: [
    `Les personnes de style réaliste ont souvent des résultats scolaires moyens, moins par manque de capacités que par manque d’intérêt pour l’école.
      Pour elles, l’école et ses enseignements sont trop abstraits, trop théoriques avec un manque d’applications pratiques.
      Elles apprennent plus par l’action que par la réflexion et le « bachotage ». Pour être motivées, elles ont besoin de concret et de se sentir utiles.
      C’est pourquoi, très souvent, elles n'obtiennent de bons résultats scolaires que dans les matières où les enseignements sont concrets et reliés au monde professionnel.`,
  ],

  study_enterprising: [
    `Les personnes qui ont un style Entreprenant(e) ont des résultats scolaires corrects sans forcément exceller.`,
    `Si elles n’excellent pas à l’école traditionnelle c'est surtout parce ce milieu ne leur permet pas d’exprimer leurs véritables talents et de leur révéler leur vrai potentiel.`,
    `Elles ont très souvent des tempéraments de développeur(se). Elles excellent dans l’action, l’organisation et les relations humaines.`,
    `Elles sont douées dans les relations, humaines, sont enthousiastes et spontanées. Ce sont avant tout des hommes et des femmes d'action.`,
  ],
};

export { explanationParagraphs };
