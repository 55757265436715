/* eslint-disable no-use-before-define */

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TitleNbr, Page } from '@orientaction/components';
import { useState } from 'react';
 

const Page17 = (props: any) => {
  const classes = useStyles();
  const data = [
    {
      col2: "Physiologique",
      col3: "Patriarche (Matriarche)",
    },
    {
      col2: "Sécurité",
      col3: "Seigneur (Seigneuresse)",
    },
    {
      col2: "Appartenance",
      col3: "Roi (Reine)",
    },
    {
      col2: "Accomplissement",
      col3: "Général (Générale)",
    },
    {
      col2: "Réalisation de soi",
      col3: "Maître (Maîtresse)",
    },
    {
      col2: "Éternité",
      col3: "Empereur (Impératrice)",
    },
  ];
  return (
    <Page id={17}>
      <div style={{ marginBottom: '20px' }}>
        <div>
          <TitleNbr
            number={4}
            text="LE GÉNÉRAL (LA GÉNÉRALE)"
          />
          <div>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
                Le général (la générale) répond à des besoins de conquête et de défis. Dans une 
                organisation (entreprise, association, administration) ou dans un service dirigé 
                par ce type de chef(fe), les relations sont orientées vers l’action et le résultat. 
                L’avancement se fait au mérite. On retrouve ce modèle dans les entreprises en 
                forte croissance, les start-up, les entreprises qui se développent à l’international, les 
                associations qui créent des projets innovants ou dans les collectivités qui cherchent 
                à être à la pointe sur certains sujets (technologie, écologie, etc.).  
            </Typography>
          </div>
        </div>

        <div style={{ marginTop: '30px' }}>

          <TitleNbr
            number={5}
            text="LE MAÎTRE (MAÎTRESSE)"
          />

          <div>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
                Le maître (la maîtresse)  répond à des besoins de réalisation de soi. Le maître (la maîtresse)  détient un savoir qui 
                est indispensable pour acquérir un certain statut. Dans une organisation (entreprise, 
                association, administration) ou dans un service dirigé par ce type de chef(fe), les 
                relations sont orientées vers la transmission de certaines connaissances ou certains 
                savoir-faire techniques. L’avancement se fait par l’apprentissage. On retrouve ce 
                modèle dans les entreprises artisanales (relation entre le maître (la maîtresse)  et l’apprenti(e)) ou 
                dans les grandes universités.  
            </Typography>
          </div>
          
        </div>
        <div style={{ marginTop: '30px' }}>
          <TitleNbr
            number={6}
            text="L’EMPEREUR (L’IMPÉRATRICE)"
          />
          <div>
            <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
                L’empereur (l’impératrice) est « un(e) général(e) » qui a réussi ! Il (Elle) répond à 
                des besoins de conquête, d’accomplissement, mais aussi de prestige. Dans une 
                organisation (entreprise, association, administration) ou dans un service dirigé par 
                ce type de chef(fe), prime la recherche de l’excellence et d’une certaine forme 
                d’élitisme. L’avancement se fait à la fois au mérite et sur la base du diplôme. On 
                retrouve ce modèle plutôt dans les grandes entreprises après qu’elles ont conquis 
                une place dominante sur leur marché.  
            </Typography>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    }
  })
);
export default Page17;
