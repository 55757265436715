import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerImageStyle: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    imageInFlexStyle: {
      width: '40%',
      height: '100%',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'left',
    },
    bannerContainerOverride: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      margin: 'unset',
      width: 350,
      [theme.breakpoints.down(1300)]: {
        width: 200,
      },
    },
    bannerStyleOverride: {
      // marginLeft: 10,
      // width: '100%',
    },
    bannerTitleContainer: {
      margin: 'auto',
      // marginTop: -89,
      // marginLeft: 85,
      // [theme.breakpoints.down(1300)]: {
      //   marginTop: -95,
      //   marginLeft: 42,
      // },
      marginTop: -93,
      [theme.breakpoints.down(1300)]: {
        marginTop: -96,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      textAlign: 'center',
      fontSize: 20,
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    paragraphWithImageContainerStyle: {
      flexDirection: 'row',
    },
    blockContainerStyle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    img: {
      marginLeft: '40px',
      marginRight: '20px',
      width: 'calc(40% - 60px)',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-40px',
      float: 'right',
      '@media only screen and (max-width: 600px)': {
        float: 'none',
        marginTop: '0px',
        width: '80%',
        display: 'block',
        margin: 'auto',
      },
      '@media only screen and (max-width: 361px)': {
        float: 'none',
        marginTop: '0px',
        width: '80%',
        display: 'block',
        margin: 'auto',
      },
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    showMobile: {
      display: 'none',
      '@media only screen and (max-width: 600px)': {
        display: 'block',
        margin: 'auto',
        marginBottom: '30px',
        width: '75%',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'block',
        margin: 'auto',
        marginBottom: '20px',
        width: '80%',
      },
    },
  }),
);

export default useStyles;
