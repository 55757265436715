/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { Page } from '@orientaction/components';
import { useBook5 } from '@orientaction/hooks_book';
import Array from './Array';
import { listForm } from './context';

const Page14 = (props: any) => {
  const { setFourPreferenceOfYourPersonnality, fourPreferenceOfYourPersonnality } = useBook5();

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFourPreferenceOfYourPersonnality({
      ...fourPreferenceOfYourPersonnality,
      [name]: value,
    });
  };

  return (
    // eslint-disable-next-line prettier/prettier
        <Page
            id={14}
            readOnly={false}
        >
      <Array
        handleChange={handleChange}
        defaultValue={fourPreferenceOfYourPersonnality}
        title={`EN QUOI CORRESPOND-T-IL AUX 5 PRÉFÉRENCES <br/>
                    DE VOTRE PERSONNALITÉ ?`}
        listForm={listForm}
        readOnly={props.readOnly}
      />
    </Page>
  );
};

export default Page14;
