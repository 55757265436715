import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  categorySelect: {
    width: '100%',
    fontFamily: 'Poppins',
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#0E1247',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  inputError: {
    '& div fieldset': {
      borderColor: theme.palette.error.main,
    },
  },
  marginTop: {
    marginTop: 15,
  },
  fullWidth: {
    width: '100%',
  },
}));
