import { Typography } from '@material-ui/core';
import style from '../../../commonStyle';

const TradeEconomySuggestion = () => {
  const classes = style();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        🧭 TROIS VOIES D’ORIENTATION PRIVILÉGIÉES :
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Voici trois voies que tu pourrais emprunter pour atteindre tes objectifs en fonction de
          tes résultats scolaires :
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec d&apos;excellents résultats (moyenne ≥15) : classe préparatoire
              ECE suivie par une grande école de commerce.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec de bons résultats (moyenne ≥12) : licence d&apos;économie à
              l&apos;université ou BUT GEA/Techniques de commercialisation.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Pour les élèves avec des résultats moyens (moyenne ≥10) : école de commerce postbac
              sans concours d&apos;entrée ou BTS NDRC/MCO.
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionQuote} component="p">
          « Mieux vaut gagner dans le commerce de paille que perdre dans celui de l&apos;or. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Proverbe serbe
      </Typography>
    </div>
  );
};

export default TradeEconomySuggestion;
