import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    imgFloat: {
      width: '45px',
      height: 'auto',
      float: 'left',
      marginLeft: '-60px',
      marginTop: '5px',
      '@media only screen and (max-width: 600px)': {
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'none',
      },
    },
    imgEmericLebreton: {
      width: '100%',
    },
    textCenterTop: {
      textAlign: 'center',
    },
    textCenterTop1: {
      textAlign: 'center',
      color: '#2b56a5',
    },
    title: {
      fontSize: 14,
    },
  })
);
