/* eslint-disable react-hooks/exhaustive-deps */
import { userService } from '@orientaction/services';
import { getFromLS } from '@orientaction/utils';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const initialState = {};

export const CompassUserContext = createContext(initialState) as any;

export const CompassUserProvider = ({ children }: any) => {
  const [loading, setLoading] = useState(false);
  const [userInfos, setUserInfos] = useState<any>();

  const { year }: any = useParams();

  const token = getFromLS('userToken') || '';
  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const getMe = useCallback(async () => {
    try {
      setLoading(true);
      const { data: userData } = await userService.getMe(token, year);
      localStorage.setItem(
        'userInfos',
        JSON.stringify({
          ...user,
          upper_hierarchy: userData.upper_hierarchy,
          rattachement: userData.rattachement,
          company_user_role: userData.company_user_role,
        }),
      );
      setUserInfos(userData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [token, user]);

  useEffect(() => {
    if (!userInfos) {
      getMe();
    }
  }, []);

  return (
    <CompassUserContext.Provider value={{ userInfos, loading }}>
      {children}
    </CompassUserContext.Provider>
  );
};

export default CompassUserProvider;
