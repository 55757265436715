// put style here
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        input: {
            boxSizing: 'border-box',
            width: "100%",
            height: 'auto',
            minHeight: '45px',
            background: '#FFFFFF',
            border: '1px solid #CDCEDA',
            borderRadius: '4px'
        },
        chip: {
            height: '32px',
            background: 'rgba(0, 10, 140, 0.15)',
            borderRadius: '4px',
            margin: "3px",
            width: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: "3px 4px",
            cursor: 'pointer'
        },
        delete: {
            cursor: 'pointer',
            margin: "0 0.25vh"
        },
        btnReasigner: {
            margin: "5px 0",
            borderRadius: "5px",
            borderWidth: "2px",
            borderStyle: "solid",
            borderImage: "linear-gradient(to top, #000A8C  50% , #E63C3C 0% , #FFDC27 100%) 1",
            width: '100%'
        },
        addBackground: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '16px',
            background: '#EDEEF2',
            borderRadius: '4px',
            flex: 'none',
        },
        formControlSelect: {
            width: '100%',
            '&>svg': {
                color: '#0E1247',
            },
            '&>fieldset': {
                borderColor: '#0E1247'
            },
            '&>div': {
                paddingTop: 12,
                paddingBottom: 12,
            },
        },
        cancelBtn: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            alignItems: 'center',
            textAlign: 'center',
            textDecorationLine: 'underline',
            color: ' #000A8C',
            flex: 'none',
            order: 3,
            alignSelf: 'stretch',
            cursor: 'pointer',
            flexGrow: 0
        },
        label: {
            color: '#0E1247',
            fontSize: '13px',
            fontFamily: 'ITC Avant Garde Gothic Std Bold',
            letterSpacing: 0
        },
        labelTitle: {
            color: '#0E1247',
            fontSize: '15px',
            fontFamily: 'ITC Avant Garde Gothic Std Bold',
            letterSpacing: 0
        },
    })
);
