import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { FC, forwardRef, useMemo } from 'react';
import Select from 'react-select';
import style from './style';

const customStyles = (error: boolean, matches: boolean) => {
  return {
    control: (provided: any) => ({
      ...provided,
      borderColor: error ? '#f44336' : '#0E1247',
      minHeight: '43px !important',
      boxShadow: 'unset',
      width: matches ? '100%' : '97%',
      '&:hover': {
        borderColor: error ? '#f44336' : '#0E1247',
      },
    }),
  };
};

interface IBaseSelect {
  allowSelectAll: any;
  allOption: any;
  options: any;
  onChange: any;
  isMulti: boolean;
  closeMenuOnSelect: boolean;
  hideSelectedOptions: boolean;
  components: any;
  value: any;
  defaultValue: boolean;
  hasError: boolean;
}

const BaseSelect: FC<IBaseSelect & any> = forwardRef(
  (
    {
      allowSelectAll,
      allOption,
      options,
      onChange,
      isMulti,
      closeMenuOnSelect,
      hideSelectedOptions,
      components,
      value,
      defaultValue,
      hasError = false,
    },
    ref,
    ...rest
  ) => {
    const cls = style();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = useMemo(() => customStyles(hasError, matches), [hasError, matches]);

    if (allowSelectAll) {
      const allOptions = [allOption, ...options];
      return (
        <Select
          {...rest}
          styles={styles}
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          components={components}
          value={value}
          className={clsx({
            [cls.select]: true,
          })}
          options={allOptions}
          placeholder="Sélectionner"
          onChange={(selected: any, event: any) => {
            if (selected !== null && selected.length > 0) {
              if (selected[selected.length - 1].value === allOption.value) {
                return onChange(allOptions);
              }

              let result = [];
              if (selected.length === options.length) {
                if (selected.includes(allOption)) {
                  result = selected.filter((option: any) => option.value !== allOption.value);
                } else if (event.action === 'select-option') {
                  result = allOptions;
                }
                return onChange(result);
              }
            }
            return onChange(selected);
          }}
        />
      );
    }

    return (
      <Select
        {...rest}
        styles={styles}
        components={components}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        className={clsx({
          [cls.select]: true,
        })}
        value={value}
        defaultValue={defaultValue}
      />
    );
  }
);

export default BaseSelect;
