const INITIAL_STATE: any = {
    message: "",
    open: false,
    severity: "error",
};

const apiAlert = (state = INITIAL_STATE, action: any): any => {
    switch (action.type) {
        case 'MESSAGE': {
            return { ...state, message: action.payload.message };
        }
        case 'OPEN': {
            return { ...state, open: action.payload.open };
        }
        case 'SERVEITY': {
            return { ...state, severity: action.payload.severity };
        }
        default:
            return state;
    }
};

export default apiAlert;
