import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  initial: {
    width: "100%",
    height: "100%",
    background: '#000A8C 0% 0% no-repeat padding-box',
    borderRadius: '100%',
    color: '#fff',
    fontSize: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span': {
      height: 'auto',
    },
  },
}));
