export const tableData = [
  {
    id: '36-table-head-1',
    firstColumn: `VOTRE DIPLÔME ET/OU
    VOTRE FORMATION`,
    secondColumn: `SA RÉPUTATION, OÙ
    ET AUPRÈS DE QUI ?`,
    thirdColumn: `EN QUOI CELA PEUT-IL
    VOUS ÊTRE UTILE ?`,
    children: [
      {
        id: '36-table-1-1',
        firstInput: '',
        secondInput: '',
        thirdInput: '',
      },
      {
        id: '36-table-1-2',
        firstInput: '',
        secondInput: '',
        thirdInput: '',
      },
      {
        id: '36-table-1-3',
        firstInput: '',
        secondInput: '',
        thirdInput: '',
      },
    ],
  },
];

export const employmentTableData = [
  {
    id: '36-table-head-2',
    firstColumn: `LE NOM DE VOTRE
    EMPLOYEUR(SE)`,
    secondColumn: `SA RÉPUTATION, OÙ
    ET AUPRÈS DE QUI ?`,
    thirdColumn: `EN QUOI CELA PEUT-IL
    VOUS ÊTRE UTILE ?`,
    children: [
      {
        id: '36-table-2-1',
        firstInput: '',
        secondInput: '',
        thirdInput: '',
      },
      {
        id: '36-table-2-2',
        firstInput: '',
        secondInput: '',
        thirdInput: '',
      },
      {
        id: '36-table-3-3',
        firstInput: '',
        secondInput: '',
        thirdInput: '',
      },
    ],
  },
];


export const arrayTextField1 = [
  {
    md: 4,
    xs: 4,
    title: 'VOTRE DIPLÔME ET/OU VOTRE FORMATION',
    name: 'diplomeAndTrainning',
    children: [
      {
        name: 'A1',
        placeholder: 'Aa'
      },
      {
        name: 'A2',
        placeholder: 'Aa'
      },
      {
        name: 'A3',
        placeholder: 'Aa'
      }
    ]
  },
  {
    md: 4,
    xs: 4,
    title: 'SA RÉPUTATION, OÙ ET AUPRÈS DE QUI ?',
    name: 'reputation',
    children: [
      {
        name: 'B1',
        placeholder: 'Aa'
      },
      {
        name: 'B2',
        placeholder: 'Aa'
      },
      {
        name: 'B3',
        placeholder: 'Aa'
      }
    ]
  },
  {
    md: 4,
    xs: 4,
    title: 'EN QUOI CELA PEUT-IL VOUS ÊTRE UTILE ?',
    name: 'usefull',
    children: [
      {
        name: 'C1',
        placeholder: 'Aa'
      },
      {
        name: 'C2',
        placeholder: 'Aa'
      },
      {
        name: 'C3',
        placeholder: 'Aa'
      }
    ]
  }
];



export const arrayTextField2 = [
  {
    md: 4,
    xs: 4,
    title: 'LE NOM DE VOTRE EMPLOYEUR(SE)',
    name: 'nameManager',
    children: [
      {
        name: 'A1',
        placeholder: 'Aa'
      },
      {
        name: 'A2',
        placeholder: 'Aa'
      },
      {
        name: 'A3',
        placeholder: 'Aa'
      }
    ]
  },
  {
    md: 4,
    xs: 4,
    title: 'SA RÉPUTATION, OÙ ET AUPRÈS DE QUI ?',
    name: 'reputation',
    children: [
      {
        name: 'B1',
        placeholder: 'Aa'
      },
      {
        name: 'B2',
        placeholder: 'Aa'
      },
      {
        name: 'B3',
        placeholder: 'Aa'
      }
    ]
  },
  {
    md: 4,
    xs: 4,
    title: 'EN QUOI CELA PEUT-IL VOUS ÊTRE UTILE ?',
    name: 'usefull',
    children: [
      {
        name: 'C1',
        placeholder: 'Aa'
      },
      {
        name: 'C2',
        placeholder: 'Aa'
      },
      {
        name: 'C3',
        placeholder: 'Aa'
      }
    ]
  }
]