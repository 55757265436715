import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    background: '#FAFAFA',
    borderRadius: 5,
    width: '100%',
    height: '100%',
    minHeight: '692px',
  },
  editArea: {
    width: 'calc(100% - 20px)',
    fontWeight: 'normal',
    padding: '10px',
    fontFamily: 'Open Sans',
    color: 'black',
    border: '1px solid #E4E4F2',
    background: '#F1F1F1',
    borderRadius: 5,
    resize: 'none',
    fontSize: 18,
  },
  title: {
    color: '#272727',
    fontFamily: "Open Sans",
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));
