import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginBottom: 5,
    height: 48,
    backgroundColor: '#fff',
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  btnPrimary: {
    minHeight: 48,
    width: 190,
    textTransform: 'none',
    background:
      'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    color: '#fff',
    marginTop: 5,
    marginLeft: 500,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 50,
    marginTop: 50,
  },
  title: {
    color: '#000A8C',
    fontSize: 36,
    margin: 0,
  },
  subtitle: {
    color: '#000A8C',
    fontSize: 26,
    margin: 0,
  },
  overrideHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  h3: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 900,
    color: '#0E1247',
    marginLeft: 10,
  },
  dropzoneOverride: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },
  input6: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '50%',
    marginBottom: 5,
    height: 48,
    backgroundColor: '#fff',
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  inputError: {
    border: `2px solid red`,
  },
  textError: {
    color: theme.palette.error.main,
  },
}));
