import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
     
        paragraphe: {
            fontSize: '15px',
            fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter',
            color: 'black',
            '@media only screen and (max-width: 600px)': {
                textAlign: 'left !important',
            },
            '@media only screen and (max-width: 361px)': {
                textAlign: 'left !important',
            },
        },
     

    })
);

export default useStyles;