import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      marginLeft: '20px',
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      float: 'right',
      '@media only screen and (max-width: 600px)': {
        float: 'none',
        width: '90%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
      },
      '@media only screen and (max-width: 361px)': {
        width: '90%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px',
      },
    },
    imgTailleur: {
      width: '70%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);

export default useStyles;
