/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import style from './style';
import { useAlert, useTest } from '@orientaction/hooks';
import { ContentCenter } from '@orientaction/commons';
import { useDispatch } from 'react-redux';
import { ApiActions } from '@orientaction/api-actions';

interface IAutoComplete {
  setTypeTest: (data: any) => void;
  setObjectValue: (data: any) => void;
  typeObjectValue: any;
  idCompany: number;
  IfTabs: boolean;
}

const AutoComplete: FC<IAutoComplete> = ({
  setTypeTest,
  setObjectValue,
  typeObjectValue,
  idCompany,
  IfTabs,
}) => {
  const [loading, setLoading] = useState(false);

  const { setOpenAlert } = useAlert();

  const [typeTest, setType] = useState<any>([]);

  const { getMyTestByCompany } = useTest();

  const dispatch = useDispatch();

  const classes = style();

  // changeTypeTest gets Object value from Autocomplete, only for test custom
  const changeTypeTest = (event: any, value: any) => {
    setObjectValue(value);
    setTypeTest(value.id);
    dispatch(ApiActions.setTestSelected(value));
    dispatch(ApiActions.setRefetch(true));
  };

  // Get all tests for the first filter we use to filter response-user

  const getAllTest = async () => {
    setLoading(true);
    try {
      const { data } = await getMyTestByCompany({
        companies: idCompany,
      });

      setType(data.list);
      setTypeTest(data.list[0].id);
      setObjectValue(data.list[0]);
    } catch (error) {
      setOpenAlert(
        true,
        `Une erreur sur la récupération des données des catégories des tests`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * Effects start here
   */
  useEffect(() => {
    if (IfTabs) {
      getAllTest();
    }
  }, [idCompany, IfTabs]);
  /**
   * end of effects
   */

  return (
    <>
      {loading ? (
        <>
          <ContentCenter>
            <CircularProgress />
          </ContentCenter>
        </>
      ) : (
        <Autocomplete
          options={typeTest}
          getOptionLabel={(option: any) => option.titre}
          getOptionSelected={(option, value) => option.id === value.id}
          noOptionsText="Pas de mots clé disponible"
          onChange={changeTypeTest}
          className={clsx(classes.keyword)}
          renderOption={(props, option) => {
            return (
              <span>{props.isMajorTest ? <strong> {props.titre} </strong> : props.titre}</span>
            );
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder="Saisissez un mot clef ..."
              className={clsx(classes.input)}
              variant="outlined"
            />
          )}
          value={typeObjectValue}
        />
      )}
    </>
  );
};

export default AutoComplete;
