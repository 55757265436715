import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  container: {
    background: '#FAFAFA',
    borderRadius: 5,
    width: '100%',
    height: '100%',
    minHeight: '692px',
  },
  containerPaper: {
    '& .MuiDialog-paper': {
      padding: '10px',
      maxWidth: 'none',
      borderRadius: 20,
      width: '880px',
      '@media only screen and (max-width: 600px)': {
        padding: '0px !important',
      },
    },
  },
  btn: {
    display: 'block',
    margin: 'auto',
    marginBottom: 10,
    background: '#000A8C',
    marginTop: 20,
  },
  flexContent: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  icon: {
    width: 14,
    height: 14,
  },
  btnClose: {
    background: '#E63C3C',
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));
