/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import VisibilityIcon from '@material-ui/icons/Visibility';

export const standartCouloms = [
  {
    type: 'normal',
    header: [
      {
        label: 'Prénom',
        key: 'firstname',
        width: '12vw',
        option: {
          type: 'link',
        },
      },
      {
        label: 'Nom',
        key: 'lastname',
        width: '12vw',
        option: {
          type: 'link',
        },
      },
      {
        label: 'Email',
        key: 'email',
        width: '15vw',
      },
      {
        label: 'Code postal',
        key: 'postal_code',
        width: '12vw',
      },
      {
        label: 'Admin. de rattachement',
        width: '15vw',
        key: [
          'upper_hierarchy.data.attributes.lastname',
          'upper_hierarchy.data.attributes.firstname',
        ],
      },
      {
        label: 'Utilisateurs rattachés',
        key: 'subordinates',
        width: '15vw',
        option: {
          type: 'button',
          labelText: 'consulter',
        },
      },
    ],
  },
  {
    type: 'action',
    header: [
      {
        label: 'Actions',
        width: '16vw',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['disable', 'edit', 'delete'],
        },
      },
    ],
  },
];

export const standartDirection = [
  {
    type: 'normal',
    header: [
      {
        label: 'Prénom',
        key: 'firstname',
        width: '15vw',
        option: {
          type: 'link',
        },
      },
      {
        label: 'Nom',
        key: 'lastname',
        width: '15vw',
        option: {
          type: 'link',
        },
      },
      {
        label: 'Email',
        width: '20vw',
        key: 'email',
      },
      {
        label: 'Code postal',
        key: 'postal_code',
        width: '15vw',
      },
      {
        label: 'Utilisateurs rattachés',
        key: 'subordinates',
        width: '15vw',
        option: {
          type: 'button',
          labelText: 'consulter',
        },
      },
    ],
  },
  {
    type: 'action',
    header: [
      {
        label: 'Actions',
        width: '15vw',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['disable', 'edit', 'delete'],
        },
      },
    ],
  },
];

export const standartBeneficaire = [
  {
    type: 'normal',
    header: [
      {
        label: 'Prénom',
        key: 'firstname',
        option: {
          type: 'link',
        },
      },
      {
        label: 'Nom',
        key: 'lastname',
        option: {
          type: 'link',
        },
      },
      {
        label: 'Sexe',
        key: 'gender',
      },
      {
        label: 'Âge',
        key: 'dayOfBirth',
        option: {
          type: 'date',
          formatedDate: true,
        },
      },
      {
        label: 'Diplôme',
        key: 'diploma',
      },
      {
        label: 'Email',
        key: 'email',
      },
      {
        label: 'Code postal',
        key: 'postal_code',
      },
      {
        label: 'consultant',
        key: [
          'upper_hierarchy.data.attributes.lastname',
          'upper_hierarchy.data.attributes.firstname',
        ],
      },
      {
        label: 'TESTS',
        key: 'viewTest',
        option: {
          type: 'button',
          icon: (<VisibilityIcon />) as any,
        },
      },
      {
        label: 'RESSOURCES',
        key: 'ressource',
        option: {
          type: 'button',
          icon: (<VisibilityIcon />) as any,
        },
      },
    ],
  },
  {
    type: 'action',
    header: [
      {
        label: 'Actions',
        key: 'name3',
        option: {
          type: 'Action',
          listAction: ['disable', 'edit', 'delete'],
        },
      },
    ],
  },
];
