/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useState } from 'react';
import style from './style';
import { Button, Grid } from '@material-ui/core';
import { useAdminUser, useAlert } from '@orientaction/hooks';
import { getFromLS, canIUpdate } from '@orientaction/utils';
import Hierachy from './Hierachy/Hierachy';

interface ICompteAdministre {
    alias: any;
}

const CompteAdministre: FC<ICompteAdministre> = ({
    alias,
}) => {
    const classes = style();

    const alert = useAlert();

    const {
        current,
        setOpenDrawer,
        oneRessignation
    } = useAdminUser();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (e: any) => {

        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        oneRessignation();
        setOpenDrawer(false);

    };

    const handleClose = () => {
        setOpenDrawer(false);
    };

    const btn = () => {

        if (loading) {
            return 'Chargement ... ';
        }

        return 'Enregistrer';

    };

    return (
        <div>

            <Hierachy
                alias={alias}
                isPossiblity={canIUpdate(true, current.company_user_role)}
            />

            <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">

                <Grid item={true}>

                    <Button className={classes.btnWhite} onClick={handleClose}>
                        <span className={classes.textHeader}>
                            {
                                canIUpdate(true, current.company_user_role) ? 'Fermer' : 'Annuler'
                            }
                        </span>
                    </Button>
                    {
                        canIUpdate(true, current?.company_user_role) &&
                        current?.listUpadted?.id_current_assigned &&
                        current?.listUpadted?.id_for_assigned
                        && (
                            <Button type="submit" className={classes.btnPrimary} disabled={loading} onClick={handleSubmit}>
                                <span className={classes.textHeader}>{btn()}</span>
                            </Button>
                        )
                    }
                </Grid>

            </Grid>

        </div>
    );

};

export default CompteAdministre;
