import Card from '@material-ui/core/Card';
import classNames from 'classnames';
import { FC } from 'react';
import style from './style';

interface IProps {
  placeholder: string;
  overrideStyle?: string;
}

const EmptyPlaceholder: FC<IProps> = ({ placeholder, overrideStyle }) => {
  const cls = style();

  return (
    <Card
      variant="outlined"
      className={classNames(cls.root, overrideStyle)}
      style={{ padding: 15, borderRadius: '10px' }}>
      {placeholder}
    </Card>
  );
};

export default EmptyPlaceholder;
