/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page } from '@orientaction/components';
import { useState } from 'react';

const Page23 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={23}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            LE CERCLE DES ÉQUILIBRES
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px',  }} />
        <div >
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', textAlign: 'center', fontWeight: 'lighter', }}>
            La vie est constituée de 5 grands domaines :
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'center', fontWeight: 'bold', color:'#2b56a5' }}>
            <span className={classes.paragraphe2}>
              <strong>Professionnel</strong>, <strong>Familial</strong>, <strong>Conjugal</strong>, <strong>Social</strong> <span style={{color: 'black'}}>et</span> <strong>Intime</strong>.
            </span>
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', fontWeight: 'lighter' }}>
            <span className={classes.number}>1</span>
            <span className={classes.h3}>
              <span>
                <strong>Professionnel</strong> :
                <span className={classes.paragraphe} style={{fontSize: "15px", textAlign: 'left'}}>
                  Il correspond au temps consacré à votre activité professionnelle y 
                  compris votre temps de transport et les repas pris sur votre lieu de travail.
                </span>
              </span>
            </span>
          </Typography>
          <Typography style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', fontWeight: 'lighter' }}>
            <span className={classes.number}>2</span>
            <span className={classes.h3}>
              <span>
                <strong>Familial</strong> :
                <span className={classes.paragraphe} style={{fontSize: "15px", textAlign: 'left', paddingLeft:"5px"}}>
                  Il correspond au temps passé avec vos enfants, vos parents, votre famille.
                </span>
              </span>
            </span>
          </Typography>
          <Typography style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', fontWeight: 'lighter' }}>
            <span className={classes.number}>3</span>
            <span className={classes.h3}>
              <span>
                <strong>Conjugal</strong> : 
                <span className={classes.paragraphe} style={{fontSize: "15px", textAlign: 'left', paddingLeft:"5px"}}>
                  Il correspond au temps passé avec votre conjoint(e), centré sur le couple
                  et uniquement sur le couple.
                </span>
              </span>
            </span>
          </Typography>
          <Typography style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', fontWeight: 'lighter' }}>
            <span className={classes.number}>4</span>
            <span className={classes.h3}>
              <span>
                <strong>Social</strong> : 
                <span className={classes.paragraphe} style={{fontSize: "15px", textAlign: 'left', paddingLeft:"5px"}}>
                  Il correspond au temps consacré aux activités associatives ou amicales.
                </span>
              </span>
            </span>
          </Typography>
          <Typography style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', fontWeight: 'lighter' }}>
            <span className={classes.number}>5</span>
            <span className={classes.h3}>
              <span>
                <strong>Intime</strong> : 
                <span className={classes.paragraphe} style={{fontSize: "15px", textAlign: 'left', paddingLeft:"5px"}}>
                  Il correspond au temps consacré à vos passions personnelles.
                </span>
              </span>
            </span>
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Nous partageons notre temps de vie entre ces 5 domaines. Avant de choisir votre 
            orientation professionnelle, il est intéressant de mesurer le temps que vous souhaitez 
            consacrer à chaque domaine de vie. Cela vous permettra de choisir un métier et 
            un environnement professionnel vous permettant d’avoir un bon équilibre de vie.  
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Pour savoir de quel domaine relève une activité, il faut identifier la finalité de 
            cette activité. Par exemple, si vous allez faire un jogging pour vous détendre et 
            vous retrouver avec vous-même, cette activité relève du domaine de l’intime. En 
            revanche, si vous allez faire un jogging avec votre conjoint(e) parce que vous 
            aimez faire du sport ensemble, cela relève du domaine du couple. 
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Au-delà du temps consacré à chaque domaine de vie, il faut également réfléchir 
            à la qualité de chacun de ces temps. Si vous passez une heure avec vos enfants 
            à jouer avec eux, c’est un temps de grande qualité relationnelle. Si en revanche, 
            vous passez une heure avec eux, alors qu’ils regardent un film et que vous êtes en 
            train de surfer sur le web, ce temps est d’une moindre qualité relationnelle. 
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            Ainsi, on peut consacrer moins de temps en quantité pour un domaine de vie mais 
            avec une plus grande qualité.
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe2: {
      color: '#2b56a5',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '15px',
      display: 'flex',
      width: 'calc(100% - 20px)',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#2b56a5',
      fontStyle: 'bold',
      textAlign: 'left',
    },
  })
);
export default Page23;
