/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { FC } from 'react';
import { useStyles } from './style';
import Podium from './Podium';

interface IFirstView {
    listSore: any[]
}

const FirstView: FC<IFirstView> = ({
    listSore
}) => {

    const classe = useStyles();

    return (
        <div className={classe.root}>

            <MarginContainer
                mormal={true}
            >
                <h1 className={classe.title}>
                    Vos résultats - Test de valeurs
                </h1>
            </MarginContainer>

            <div
                className={classe.content}
            >
                <Grid
                    container={true}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >

                    <Grid md={7} lg={6} xs={12}>
                        <div
                            className={classe.podium}
                        >
                            <Podium
                                first={listSore[0]}
                                second={listSore[1]}
                                third={listSore[2]}
                            />
                        </div>

                        <Typography
                            className={classe.whenEqual}
                        >
                            *en cas d&#39;égalité de scores l&#39;une des valeurs est mise en premier automatiquement
                        </Typography>

                    </Grid>

                    <Grid md={5} lg={6} xs={12}>
                        <Typography
                            className={classe.description}
                        >
                            Voici vos valeurs directrices. Vos valeurs directrices guident vos pensées et vos comportements. Elles sont à la source du sens que vous donnez à vos actions. Agir conformément à vos valeurs vous permettra de développer votre motivation et votre bien-être. Vous vous sentirez aligné(e) entre ce que vous êtes et ce que vous faites.
                        </Typography>
                    </Grid>

                </Grid>
            </div>

        </div>
    );
};

export default FirstView;
