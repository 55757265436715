/* eslint-disable react-hooks/exhaustive-deps */
import { BookTemplate, MajorTest, servicesService } from '@orientaction/services';
import { useCallback, useEffect, useState } from 'react';
import { getUserServices } from '../../../../services/services.service';
import { isTopManager } from '../../../../utils/isAdmin';
import { FORM_MODE, inCardService, oaServices } from '../constant';

const useBeneficiaryServices = (token: string, formStore: any, managers: any) => {
  const [fetchTests, setFetchTests] = useState(false);
  const [fetchBooks, setFetchBooks] = useState(false);
  const [loadingTests, setLoadingTests] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);

  const getServices = useCallback(async () => {
    try {
      setLoadingServices(true);
      if (
        formStore?.formUser?.upper_hierarchy &&
        typeof formStore?.formUser?.upper_hierarchy === 'number'
      ) {
        let services;
        const currentManager = managers.find(
          (m: any) => +m.id === +formStore?.formUser?.upper_hierarchy
        );
        const isUserTopManager = isTopManager(currentManager?.company_user_role?.id);
        if (isUserTopManager) {
          // any top managers has rights on all services
          services = oaServices;
        } else {
          // get services in which consultant has rights
          const {
            data: { data },
          } = await getUserServices(token, formStore?.formUser?.upper_hierarchy);
          services = data;
        }
        formStore.updateFormUser({ services: (services || []).concat(inCardService) });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServices(false);
    }
  }, [token, formStore?.formUser?.upper_hierarchy]);

  const getBeneficiaryTests = useCallback(async () => {
    if (formStore.formUser?.service?.id) {
      const toFetchFields = ['id', 'name'];
      const populateTest = {
        major_tests_templates: {
          fields: ['id', 'name', 'query'],
          sort: ['id:asc'],
        },
      };
      setLoadingTests(true);
      try {
        // Fetch tests
        if (formStore.formUser.mode === FORM_MODE.update) {
          const {
            data: {
              data: { userMajorTests },
            },
          } = await MajorTest.getUserMajorTests(
            token,
            formStore?.formUser?.id,
            formStore?.formUser?.service?.id
          );

          if (formStore.formUser?.service?.id === 1) {
            // fetch books for Bilan de compétences service
            const {
              data: { books },
            } = await BookTemplate.getUserBooks(token, {
              userId: formStore?.formUser?.id,
            });
            const transformedBooks = books.map((book: any) => {
              return { ...book, isChecked: book.attributes.isChecked };
            });
            formStore.updateFormUser({
              services: formStore?.formUser?.services || [],
              tests: userMajorTests,
              books: transformedBooks,
            });
          } else {
            formStore.updateFormUser({
              services: formStore?.formUser?.services || [],
              tests: userMajorTests,
              books: [],
            });
          }
        } else {
          const {
            data: {
              data: {
                attributes: {
                  major_tests_templates: { data },
                },
              },
            },
          } = await servicesService.getServiceById(
            token,
            formStore?.formUser?.service?.id,
            toFetchFields,
            {},
            populateTest
          );
          if (formStore.formUser?.service?.id === 1) {
            // fetch books for Bilan de compétences service
            const {
              data: { books },
            } = await BookTemplate.getUserBooks(token, {
              userId: formStore?.formUser?.id,
            });
            formStore.updateFormUser({
              services: formStore?.formUser?.services || [],
              tests: data,
              books,
            });
          } else {
            formStore.updateFormUser({
              services: formStore?.formUser?.services || [],
              tests: data,
              books: [],
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTests(false);
      }
    }
  }, [formStore?.formUser?.service?.id, token]);

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    getBeneficiaryTests();
  }, [getBeneficiaryTests, fetchTests, formStore?.formUser?.service?.id]);

  useEffect(() => {
    getServices();
  }, [token, formStore?.formUser?.upper_hierarchy]);

  return {
    refetchTests: fetchTests,
    refetchBooks: fetchBooks,
    loadingTests,
    loadingServices,
    setFetchBooks,
    setFetchTests,
  };
};

export default useBeneficiaryServices;
