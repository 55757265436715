/* eslint-disable react-hooks/exhaustive-deps */
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout } from '@orientaction/components';
import { getFromLS } from '@orientaction/utils';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getMajorTestLastResult } from '../../services/MajorTest.service';
import style from './style';

const getTestIdByPathname = (pathname: string) => {
  let testId;
  switch (pathname) {
    case '/resultat-meaning-value':
      testId = 1;
      break;

    case '/resultat-need':
      testId = 2;
      break;

    case '/resultat-need-pro':
      testId = 3;
      break;

    case '/resultat-preference':
      testId = 4;
      break;

    case '/resultat-harmony':
      testId = 5;
      break;
    case '/resultat-study':
      testId = 6;
      break;

    default:
      testId = 5;
      break;
  }
  return testId;
};

const RedirectToLastResult = () => {
  const location = useLocation();
  const history = useHistory();

  const classes = style();
  const token = getFromLS('userToken') || '';

  const getLastResult = async () => {
    try {
      const { pathname } = location;
      const testId = getTestIdByPathname(pathname);
      const response = await getMajorTestLastResult(token, testId);
      const responseId = response?.data?.data?.id;
      if (responseId) {
        history.push(`${pathname}/${responseId}`);
      } else {
        history.push('/main');
      }
    } catch (error) {
      history.push('/main');
    }
  };
  useEffect(() => {
    getLastResult();
  }, []);

  return (
    <Layout isaddBackground={true} noPadding={true} isNew={true} footer={true}>
      <div className={classes.contentLoader}>
        <CircularProgress />
      </div>
    </Layout>
  );
};

export default RedirectToLastResult;
