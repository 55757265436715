/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import { useBook4 } from '@orientaction/hooks_book';
import WithPuceSubtitle from '../../components/WithPuceSubtitle';
import YesOrNoTable from '../../components/ArrayYesNo';
import { tableData } from './constant';
import useStyles from './style';

const Page18 = (props: any) => {
  const classes = useStyles();

  const { state, setYourEconomicCapital } = useBook4();

  return (
    // eslint-disable-next-line react/destructuring-assignment
    <Page id={18} readOnly={props.readOnly}>
      <TitlePage>
        ÉVALUEZ VOTRE CAPITAL ÉCONOMIQUE
        <br />
        MOBILISABLE (PARTIE 1)
      </TitlePage>
      <MarginContainer>
        <Paragrapth align="justify">
          Répondez aux différentes questions et calculez votre score global pour chaque dimension.
          Ce tableau servira de base à un échange avec votre consultant(e). Si vous ne souhaitez pas communiquer certaines informations, ne répondez pas aux questions.
        </Paragrapth>
      </MarginContainer>
      <WithPuceSubtitle puceNumber={1} childrenOverrideStyle={classes.childrenOverrideStyle}>
        VOTRE CAPITAL ÉCONOMIQUE
      </WithPuceSubtitle>
      <div style={{marginTop: 20}} >
        <YesOrNoTable list={tableData} current={state} handleChange={setYourEconomicCapital} />
      </div>
    </Page>
  );
};

export default Page18;
