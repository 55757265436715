// eslint-disable-next-line simple-import-sort/imports
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import { FC } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { useStyles } from './style';

interface IlistQuestionReponse {
  label: string;
  name: string;
}
interface ITableQuestionReponse {
  handleChange: (data: any) => void;
  defaultValue: any;
  listquestion: IlistQuestionReponse[];
  keyDown: (data: any) => void;
  handleChangeGlobalState: (data: any) => void;
  readonly?: boolean;
}

const TableQuestionReponse: FC<ITableQuestionReponse> = ({
  defaultValue,
  listquestion,
  readonly,
  keyDown,
  handleChange,
  handleChangeGlobalState,
}) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" style={{ borderRadius: '10px' }}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div className={classes.borderTable}>
            <Grid container={true} justifyContent="flex-end">
              <Grid
                item={true}
                xs={4}
                style={{ textAlign: 'center' }}
                className={classes.cardBgGrey}>
                <Hidden xsDown={true}>
                  <Typography component="p" className={classes.h7}>
                    Questions
                  </Typography>
                </Hidden>
              </Grid>
              <Grid item={true} xs={8} style={{ textAlign: 'center' }}>
                <Hidden xsDown={true}>
                  <Typography variant="h2" component="h2" className={clsx(classes.h7)}>
                    Réponses
                  </Typography>
                </Hidden>
              </Grid>
            </Grid>

            {listquestion.map((item: IlistQuestionReponse) => (
              <>
                <Grid
                  container={true}
                  style={{ borderTop: '1px solid black' }}
                  justifyContent="flex-end">
                  <Grid
                    item={true}
                    md={4}
                    xs={12}
                    className={classes.borderNone}
                    style={{ textAlign: 'center' }}>
                    <Typography component="p" className={classes.h6}>
                      {item.label || ''}
                    </Typography>
                  </Grid>
                  <Grid
                    item={true}
                    md={8}
                    xs={12}
                    style={{ textAlign: 'center', overflow: 'hidden' }}>
                    <TextField
                      id="outlined-multiline-static"
                      multiline={true}
                      rows={2}
                      onChange={handleChange}
                      onKeyDown={keyDown}
                      onBlur={handleChangeGlobalState}
                      defaultValue={defaultValue ? defaultValue[item.name || ''] || '' : ''}
                      name={item.name || ''}
                      variant="outlined"
                      InputProps={{
                        readOnly: readonly,
                      }}
                      className={classes.textArea}
                      placeholder="Aa"
                    />
                  </Grid>
                </Grid>
              </>
            ))}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default TableQuestionReponse;
