/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { LayoutSimple } from '@orientaction/layouts';
import style from './style';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import clsx from 'clsx';
import { useState, useRef, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import locale from './local.json';
import { publicSvgPath } from '../../utils/constants';
import { useHistory } from 'react-router-dom';
import { useAlert, useOfflineLanguage, useUser } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import { UserProfilePhotoContext } from '../../Context/UserProfilePhotoContext';
import { updateUserPasswordService } from '../../services/user.service';
import ReactFlagsSelect from 'react-flags-select';

const BeneficiaryModifyPassword = () => {
  const cls = style();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eyeOn, setEyeOn] = useState<boolean>(false);
  const [connectError, setConnectError] = useState<string>('');
  const history = useHistory();
  const { resetPhoto, updateUserProfilePhoto } = useContext<any>(UserProfilePhotoContext);
  const { language, onLanguageChange } = useOfflineLanguage();

  const alert = useAlert();
  const { token, pageLoading, isUserOnTemporaryPassword, checkIfUserIsOnTemporaryPassword } =
    useUser();

  useEffect(() => {
    (async () => {
      checkIfUserIsOnTemporaryPassword();
    })();
  }, []);

  const onSubmitHandler = async (data: any) => {
    setIsLoading(true);

    try {
      resetPhoto();
      const result = await updateUserPasswordService(token, data);
      if (result?.data?.success) {
        new Promise((resolve: any) => {
          const currentUserInfos = JSON.parse(getFromLS('userInfos') || '{}');
          localStorage.setItem(
            'userInfos',
            JSON.stringify({
              ...currentUserInfos,
              isTemporaryPassword: false,
            })
          );
          resolve(result?.data);
        }).then(() => {
          updateUserProfilePhoto();
          history.push('/main');
        });
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.name === 'ValidationError') {
        setConnectError(
          "Une erreur s'est produite. Vérifier que vous avez rentré correctement vos identifiants"
        );
      } else {
        alert.openSimpleAlert();
      }
    } finally {
      setIsLoading(false);
    }
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<any>({
    mode: 'onChange',
  });
  const newPasswordRef = useRef({});
  newPasswordRef.current = watch('password', '');
  const confirmPasswordRef = useRef({});
  confirmPasswordRef.current = watch('confirmPassword', '');

  return (
    <LayoutSimple isWhite={true} noPadding={true}>
      <div className={cls.root}>
        {pageLoading ? (
          <CircularProgress color="primary" size={32} />
        ) : (
          isUserOnTemporaryPassword === true && (
            <Grid container={true} justifyContent="center">
              <Grid item={true} xs={8} sm={5} md={4} className={cls.card}>
                <Typography
                  variant="h3"
                  component="h3"
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    textAlign: 'center',
                    fontWeight: 'lighter',
                  }}>
                  <strong className={cls.h3}>Finalisation de création de compte</strong>
                </Typography>
                <Typography
                  className={cls.boldText}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    textAlign: 'center',
                    fontStyle: 'italic',
                    marginTop: '30px',
                    marginBottom: '20px',
                  }}>
                  Pour finaliser la création de votre compte, <br />
                  merci de définir un mot de passe
                </Typography>
                <form onSubmit={handleSubmit(onSubmitHandler)} className={cls.form}>
                  <Box className={cls.fieldWithLabel}>
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Champ obligatoire',
                        pattern: {
                          value:
                            /^(((?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[A-Z])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z]))|((?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]))).{8,}$/,
                          message:
                            'Votre mot de passe doit contenir au moins 8 caractères et au moins 3 des éléments suivants : Lettres minuscules (a-z) | Lettres majuscules (A-Z) | Chiffres (0-9) |Caractères spéciaux (ex. !@#)}',
                        },
                        validate: {
                          value: value => {
                            if (
                              confirmPasswordRef &&
                              confirmPasswordRef.current &&
                              confirmPasswordRef.current !== ''
                            ) {
                              if (confirmPasswordRef.current === value) {
                                if ((errors as any)?.confirmPassword) {
                                  (errors as any).confirmPassword.message = '';
                                }
                              } else if ((errors as any)?.confirmPassword) {
                                (errors as any).confirmPassword.message =
                                  "Le mot de passe n'est pas identique";
                              } else {
                                (errors as any).confirmPassword = {};
                                (errors as any).confirmPassword.message =
                                  "Le mot de passe n'est pas identique";
                              }
                            }
                            return newPasswordRef.current === value;
                          },
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <label htmlFor="newPassword">
                            <h5 className={cls.labelText}>{locale.password}</h5>
                          </label>
                          <Input
                            placeholder={locale.passwordPlaceholder}
                            {...field}
                            type={eyeOn ? 'text' : 'password'}
                            className={clsx(cls.input, cls.inputPassword)}
                            endAdornment={
                              <InputAdornment position="end">
                                {eyeOn ? (
                                  <img
                                    alt="eye-switch-on"
                                    src={`${publicSvgPath}/eyeOn.svg`}
                                    className={cls.eyeImg}
                                    onClick={() => setEyeOn(false)}
                                    onKeyDown={() => setEyeOn(false)}
                                  />
                                ) : (
                                  <img
                                    alt="eye-switch-off"
                                    src={`${publicSvgPath}/eyeOff.svg`}
                                    className={cls.eyeImg}
                                    onClick={() => setEyeOn(true)}
                                    onKeyDown={() => setEyeOn(true)}
                                  />
                                )}
                              </InputAdornment>
                            }
                          />
                          {errors?.password?.message && (
                            <span className={cls.passwordErrorField}>
                              {(errors as any).password.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Box>
                  <Box className={cls.fieldWithLabel}>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: 'Champ obligatoire',
                        validate: {
                          value: value =>
                            value === newPasswordRef.current ||
                            "Le mot de passe n'est pas identique",
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <label htmlFor="confirmPassword">
                            <h5 className={cls.labelText}>{locale.confirmPassword}</h5>
                          </label>
                          <Input
                            placeholder={locale.confirmPasswordPlaceholder}
                            {...field}
                            type={eyeOn ? 'text' : 'password'}
                            className={clsx(cls.input, cls.inputPassword)}
                            endAdornment={
                              <InputAdornment position="end">
                                {eyeOn ? (
                                  <img
                                    alt="eye-switch-on"
                                    src={`${publicSvgPath}/eyeOn.svg`}
                                    className={cls.eyeImg}
                                    onClick={() => setEyeOn(false)}
                                    onKeyDown={() => setEyeOn(false)}
                                  />
                                ) : (
                                  <img
                                    alt="eye-switch-off"
                                    src={`${publicSvgPath}/eyeOff.svg`}
                                    className={cls.eyeImg}
                                    onClick={() => setEyeOn(true)}
                                    onKeyDown={() => setEyeOn(true)}
                                  />
                                )}
                              </InputAdornment>
                            }
                          />
                          {errors?.confirmPassword?.message && (
                            <span className={cls.passwordErrorField}>
                              {(errors as any).confirmPassword.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </Box>
                  {connectError?.trim()?.length > 0 && (
                    <h5 className={cls.errorField}>{connectError}</h5>
                  )}
                  <Box className={cls.btnConnectContainer}>
                    <Button type="submit" className={cls.btnConnect} disabled={isLoading}>
                      {!isLoading ? locale.connectText : `${locale.connectText}...`}
                    </Button>
                  </Box>
                  <Box className={cls.signupLink}>
                    <ReactFlagsSelect
                      countries={['FR', 'GB']}
                      showOptionLabel={false}
                      showSelectedLabel={false}
                      fullWidth={false}
                      selected={language}
                      onSelect={onLanguageChange}
                    />
                  </Box>
                </form>
              </Grid>
            </Grid>
          )
        )}
      </div>
    </LayoutSimple>
  );
};

export default BeneficiaryModifyPassword;
