/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
  useNeedsProTestResult,
  useRedirectToRootPage,
  useAlert,
  useCompassMenu,
  useLanguage,
} from '@orientaction/hooks';
import { FC, useContext, useEffect, useState } from 'react';
import { useStyles } from './style';
import Card from '../Components/Card';
import Description from '../Components/Description';
import CardButton from '../Components/Button';
import { CompassUserContext } from '@orientaction/Context';

/**
 * This card should be shown while user is:
 *  - A rattached beneficiary who has needs-pro test result
 *  - A rattached beneficiary who has right to see the test
 *  - A rattached beneficiary with no more right on test but has result
 */

const NeedsProCard: FC = () => {
  const [result, setMyResult] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const { userInfos } = useContext<any>(CompassUserContext);
  const { language } = useLanguage();

  const currentTest = userInfos?.service?.major_tests_templates.find((test: any) => {
    return test.id === 3;
  });

  const shouldBuy = currentTest?.shouldBuy || !userInfos?.rattachement || false;

  const hasAccess = currentTest?.hasRight || currentTest?.hasResult || false;

  // TO REFACTOR: Actually we fetch last test in order to have ID for redirection purpose,
  // so heavy in my opinion
  const { needsProTestLastResult } = useCompassMenu();
  const { getMyResult } = useNeedsProTestResult();

  const { goToRootPage } = useRedirectToRootPage();

  const alert = useAlert();

  const getResultat = async (idTest: number) => {
    setLoading(true);

    try {
      const { data } = await getMyResult(+idTest);
      setMyResult(data.data.favorite);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (needsProTestLastResult?.id && typeof needsProTestLastResult?.id === 'number') {
      getResultat(needsProTestLastResult?.id);
    }
  }, [needsProTestLastResult?.id]);

  const classe = useStyles();

  const showNeutralVersion = !hasAccess || !currentTest?.hasResult;

  const withoutAccessRedirectButton = shouldBuy && (
    <CardButton redirectUri="https://orientaction.kneo.me/shop/view/5726D7">
      Acheter le test
    </CardButton>
  );

  const pyramidImageSource = result?.imgPyramid.replace(
    /\/need\//g,
    language === 'GB' ? '/need/pyramids/en/' : '/need/pyramids/'
  );
  useEffect(() => {
    console.log({result, })
  }, [result])

  return (
    <Card
      to={`${language === 'GB' ? `/en/tests/needs-pro` : `/tests/besoins-pro`}`}
      disable={showNeutralVersion}
      loading={loading}
      to_resultat={`/resultat-need-pro/${needsProTestLastResult?.id}`}
      withAccess={hasAccess}
      noMarginTop={true}
      withoutAccessRedirectButton={withoutAccessRedirectButton}
    >
      <div className={classe.root}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <div className={classe.contentPiramyd}>
              <img
                src={pyramidImageSource || '/images/need/pyramid_neutre.png'}
                className={classe.imgPiramide}
                alt="Pyramide"
              />
            </div>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <Description>
              Voici votre besoin principal dans votre vie professionnelle. La satisfaction de vos
              besoins est à la source de votre bien-être et de votre motivation. Il est important
              que votre métier, vos conditions de travail et de rémunération, ainsi que vos
              relations professionnelles contribuent pleinement à satisfaire ce besoin.
            </Description>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default NeedsProCard;
