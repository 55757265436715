import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#fff',
    color: '#9799B1',
    width: '100%',
    marginTop: 10,
    boxSizing: 'border-box',
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
  },
}));
