import RichEditor from '@orientaction/commons/RichEditor';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';

const TestDescription = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);
  const handleChange = (value: string) => {
    updateTest({
      test: {
        ...test.test,
        description: value,
      },
    });
  };
  return (
    <RichEditor
      value={test.test.description}
      handleChange={handleChange}
      placeholder="Renseignez la description du test"
      whiteBg={true}
      isMultiline={true}
    />
  );
};

export default TestDescription;
