import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { publicIconDetailResultPath } from '@orientaction/utils';
import { FC, useState } from 'react';
import { useStyles } from './style';

interface IDetails {
  imageSrc: string;
  title: string;
  color: string;
  note: number;
  description: string;
  decisiveProfessions: string[];
}

const Details: FC<IDetails> = ({
  imageSrc,
  title,
  color,
  note,
  description,
  decisiveProfessions,
}) => {
  const classes = useStyles();

  const [isExpand, setIsExpand] = useState(false);

  const handdleOpen = () => {
    setIsExpand(!isExpand);
  };

  return (
    <div className={classes.resultDetail}>
      <div className={classes.resultDetailHeader} onClick={handdleOpen}>
        <div className={classes.resultDetailHeaderImage}>
          {imageSrc && <img src={`${publicIconDetailResultPath}/${imageSrc}`} alt="Icon" />}
        </div>

        <div className={classes.resultDetailHeaderContent}>
          <div>
            <div>
              <h2 className={classes.title}>{title}</h2>
            </div>

            <div
              className={classes.note}
              style={{
                color,
              }}>
              <span className={classes.hundred}>{note}</span>
              <span className={classes.hundred}>/100</span>
            </div>
          </div>

          <div>{isExpand ? <ExpandLess /> : <ExpandMore />}</div>
        </div>
      </div>

      {isExpand && (
        <div className={classes.resultDetailContent}>
          <div className={classes.definition}>
            <h4
              className={classes.subtitle}
              style={{
                color,
              }}>
              Définition
            </h4>
            <p className={classes.description}>{description}</p>
          </div>

          <div className={classes.decisiveProfessions}>
            <h4
              className={classes.subtitle}
              style={{
                color,
              }}>
              Fonction et/ou métiers dans lesquels elle est déterminante
            </h4>
            <ul className={classes.decisiveProfessionsList}>
              {decisiveProfessions.map((decisiveProfession: string) => (
                <li>
                  <span
                    className={classes.dot}
                    style={{
                      color,
                    }}>
                    .
                  </span>{' '}
                  {decisiveProfession}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
