import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import style from '../../../commonStyle';
import localStyle from './style';

const RealisticSuggestion = () => {
  const classes = style();
  const localClasses = localStyle();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        😍 TA VOIE IDÉALE : BTS
      </Typography>
      <div className={classes.suggestionContent}>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Après une filière technologique ou professionnelle débouchant sur un baccalauréat, tu
              intégreras un BTS, éventuellement en alternance.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Après deux années, voire trois années, si tu désires compléter ton BAC+2 par une
              licence professionnelle (en IUT ou dans une Université), tu rentreras sur le marché du
              travail.
            </Typography>
          </li>
        </ul>
        <Typography
          className={classNames(classes.suggestionSubtitle, localClasses.localSuggestionSubtitle)}
          component="p">
          Ce premier emploi te permettra de prendre ton indépendance vis-à-vis de tes parents et de
          construire ta vie. Ta carrière dépendra ensuite des opportunités que ton employeur
          t&apos;offrira et de tes ambitions. C&apos;est au travail, que tu découvriras tes
          véritables talents et on véritable potentiel.
        </Typography>
        <Typography className={classes.suggestionQuote} component="p">
          « Le pessimiste se plaint du vent, l&apos;optimiste espère qu&apos;il va changer, le
          réaliste ajuste ses voiles. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        William Arthur Ward
      </Typography>
    </div>
  );
};

export default RealisticSuggestion;
