/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './style';

interface IExplication {
    view: boolean;
}

const Explication: FC<IExplication> = ({ view }) => {

    const classes = useStyles();

    return (
        <>
            {
                view && (
                    <>
                        <div className={classes.card}>
                            <Typography className={classes.resultDetailsTitle} component="h2">
                                DÉCOUVREZ VOS 16 <i> SOFT SKILLS </i>
                            </Typography>
                            <Typography component="p" className={classes.resultDetailsParagraph}>
                                Les soft skills ou compétences douces sont des compétences que l’on acquiert par l’expérience, par opposition aux hard skills qui s’acquièrent dans le cadre d’une formation ou de ses études. Les soft skills sont aujourd’hui la clef de la réussite professionnelle. Les développer vous aidera à devenir un(e) collaborateur (trice) reconnu(e) et apprécié(e) dans son organisation. Dans votre sphère de vie personnelle, cela fera de vous une personne accomplie et respectée pour ses qualités humaines. Le fait de les tester est une première étape avant de progresser. À vous de jouer !
                            </Typography>
                        </div>
                    </>
                )
            }
        </>

    );
};

export default Explication;
