import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { publicSvgPath } from '../../../../utils/constants';
import AuthFormHeader from '../../Commons/AuthFormHeader';
import AuthHeader from '../../Commons/AuthHeader';
import locale from '../locale.json';
import { LoginForm } from '../LoginForm';

const LeftSide = () => {
  const cls = style();
  return (
    <>
      <AuthHeader />
      <div className={cls.left}>
        <AuthFormHeader
          title={locale.loginFormHeaderTitle}
          subtitle={locale.loginFormHeaderSubtitle}
          questionText={locale.loginQuestionText}
          redirectText={locale.loginRedirectText}
          linkTo="/signup"
        />
        <LoginForm />
      </div>
    </>
  );
};
const style = makeStyles((theme: Theme) => ({
  left: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
    '& .header': {
      display: 'none',
      '@media (min-width: 1250px)': {
        display: 'block',
      },
    },
    '@media (min-width: 1250px)': {
      height: 'calc(92vh - 70px)',
      maxWidth: 600,
      margin: 'auto',
    },
  },
}));
export default LeftSide;
