import { CardContent, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import HtmlReactParser from 'html-react-parser';
import { FC } from 'react';
import { publicSvgPath } from '../../utils/constants';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  mediaCard: {
    width: '100%',
    height: 'auto',
  },
  h7: {
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    color: '#0E1247',
    textAlign: 'center',
    display: 'inline-block',
    style: 'normal',
    alignItems: 'center',
  },
  texteTitle: {
    font: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#9799B1',
    display: 'inline-block',
    style: 'normal',
  },
  textCard: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.6px',
    color: '#0E1247',
    style: 'normal',
  },
});

interface IProps {
  variable: any;
}

const VariableCardItem: FC<IProps> = ({ variable }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" style={{ padding: 15, borderRadius: '10px' }}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div>
            <Grid container={true} spacing={3} style={{ marginBottom: '10px' }}>
              <Grid item={true}>
                <Typography component="p" className={classes.texteTitle}>
                  Nom de la variable
                </Typography>
              </Grid>
              <Grid item={true}>
                <Typography component="p" className={classes.h7}>
                  {variable.name}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body2" component="div" className={classes.textCard}>
              {HtmlReactParser(variable.description)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default VariableCardItem;
