import { ReactNode } from 'react';
import { styles } from "./style";
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children?: ReactNode;
    onClose: () => void;
}


const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography={true} className={classes.root} {...other}>
            {children && <Typography variant="h6" className={classes.colorStandar} style={{ margin: "0 2vh" }} >{children}</Typography>}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon className={classes.colorStandar} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: 10,
        borderBottom: 'none',
    },
    dialog: {
        minWidth: 660,
        maxWidth: 'none',
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: '35px 20% 60px 20%',
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            width: 158,
        },
    },
}))(MuiDialogActions);


export {
    DialogActions,
    DialogContent,
    DialogTitle,
}