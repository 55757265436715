/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleChapitre, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';

const Page06 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={6}>
      <TitleChapitre nbr={1} />
      <div style={{ marginTop: '50px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            className="opacity07"
            style={{ fontSize: "1.88rem" }}>
            LA MÉTHODE <br />
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className={classes.responsiveTitle}
              style={{ fontSize: "2.8rem" }}>
              ORIENTACTION
            </Typography>
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
        <div>
          <span>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '50px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              ORIENTACTION a accompagné plus de 38000 personnes depuis sa création. Cette expérience
              est le socle de notre expertise dans le conseil en évolution professionnelle. Elle
              nous a permis de développer la meilleure méthode d’accompagnement qui existe :
              <Paragrapth align='justify' colorBlue={true}><strong style={{ marginLeft: 5 }}> La méthode ORIENTACTION.</strong> </Paragrapth>.
            </Typography>
          </span>
          <Typography
            className={classes.paragraphe}
            style={{
              fontFamily: 'ITC Avant Garde Gothic Std Medium',
              marginTop: '20px',
              textAlign: 'justify',
              fontWeight: 'lighter',
            }}>
            C’est cette méthode que vous avez choisie pour réussir votre évolution professionnelle.
            Grâce à elle, vous adoptez les bonnes attitudes, définissez les bonnes stratégies,
            faites les bons choix.
          </Typography>
          <div style={{ textAlign: 'center', marginTop: 25 }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
              }}>
              <strong>Sa finalité est votre réussite : </strong>
            </Typography>
            <Typography
              color="primary"
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
              }}>
              <strong>exercer un métier pérenne qui vous rendra heureux(se). </strong>
            </Typography>
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1000px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      lineHeight: 1.7,
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
      color: "#2b56a5"
    },
    responsiveTitle: {
      '@media only screen and (max-width: 600px)': {
        fontSize: '1.88rem !important',
      },
      '@media only screen and (max-width: 361px)': {
        fontSize: '1.88rem !important',
      },
    }
  })
);
export default Page06;
