/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ChoiceItem from './choice-item';

type Item = {
  id: number;
  text: string;
  destination: string;
  destinationUrl: string;
  name: string;
  selectedVariable?: string;
};

interface DraggableListProps {
  initialItems: Item[];
  handleUpdate: (items: Item[]) => void;
  className: string;
  listPages?: any;
  lastChoiceRef: any;
  isRedirection?: boolean;
  isCarre?: boolean;
  typeChoice?: string;
  typeGame?: string;
}

const DraggableListChoice: React.FC<DraggableListProps> = React.memo(
  ({
    initialItems,
    handleUpdate,
    className,
    listPages,
    lastChoiceRef,
    isRedirection,
    isCarre,
    typeChoice,
    typeGame,
  }) => {
    const [items, setItems] = useState<Item[]>(initialItems);
    const dragItem = useRef<Item | null>(null);
    const dragOverItem = useRef<Item | null>(null);
    const [isDrag, setDrag] = useState<boolean>(true);

    // Update local state whenever initialItems changes
    useEffect(() => {
      setItems(initialItems);
    }, [initialItems]);

    const handleDragStart = useCallback(
      (e: React.DragEvent<HTMLDivElement>, item: Item) => {
        if (!isDrag) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        dragItem.current = item;
      },
      [isDrag],
    );

    const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>, item: Item) => {
      dragOverItem.current = item;
      e.preventDefault();
      e.stopPropagation();
    }, []);

    const handleDragEnd = useCallback(() => {
      if (!dragItem.current || !dragOverItem.current) return;

      const dragItemIndex = items.findIndex((item) => item.id === dragItem.current!.id);
      const dragOverItemIndex = items.findIndex((item) => item.id === dragOverItem.current!.id);

      if (dragItemIndex !== dragOverItemIndex) {
        const newList = [...items];
        newList.splice(dragItemIndex, 1);
        newList.splice(dragOverItemIndex, 0, dragItem.current!);

        handleUpdate(newList);
      }

      dragItem.current = null;
      dragOverItem.current = null;
    }, [items, handleUpdate]);

    const handleChoiceChange = (id: number, field: string, value: string) => {
      const storedData = sessionStorage.getItem('choicesData');
      const data = storedData ? JSON.parse(storedData) : items;

      const newChoices = data.map((item: any) =>
        item.id === id ? { ...item, [field]: value } : item,
      );

      //  console.log('Updated choices:', newChoices);
      console.log({newChoices, id, field, value})
      handleUpdate(newChoices);
    };

    const handleRemoveChoice = (id: number) => {
      const newChoices = items.filter((item) => item.id !== id);

      handleUpdate(newChoices);
    };

    // console.log('fuita', items);
    return (
      <div className={isCarre ? 'draglist' : ''}>
        {items &&
          items.length > 0 &&
          items.map((item, nidex) => (
            <div
              key={item.id}
              draggable={true}
              onDragStart={(e) => {
                handleDragStart(e, item);
              }}
              onDragEnter={(e) => {
                handleDragEnter(e, item);
                e.preventDefault();
                e.stopPropagation();
              }}
              onDragEnd={(e) => {
                handleDragEnd();
                e.preventDefault();
                e.stopPropagation();
              }}
              className={className}
              ref={nidex === items.length - 1 ? lastChoiceRef : null}
            >
              <MemoizedChoiceItem
                id={item.id}
                text={item.text}
                index={nidex}
                destination={item.destination}
                destinationUrl={item.destinationUrl}
                name={item.name}
                handleChange={handleChoiceChange}
                handleRemove={handleRemoveChoice}
                listPages={listPages}
                defaultValue={item.text}
                isRedirection={isRedirection}
                isCarre={isCarre}
                typeChoice={typeChoice}
                canDrag={(data: any) => {
                  setDrag(data);
                }}
                typeGame={typeGame}
                selectedVariable={item.selectedVariable}
              />
            </div>
          ))}
      </div>
    );
  },
);

const MemoizedChoiceItem = React.memo(ChoiceItem);

export default DraggableListChoice;
