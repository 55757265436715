import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  overrideParagraphStyle: {
    fontFamily: 'ITC Avant Garde Gothic Std bold',
    fontStyle: 'italic',
  },
  overrideTextStyle: {
    fontSize: 14,
  },
  overridecardBgGreyStyle: {
    padding: 2,
    fontStyle: 'normal',
  },
}));
