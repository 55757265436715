/* eslint-disable import/no-unresolved */
import { Grid, Typography } from '@material-ui/core';
import { publicImgMajorTest } from '@orientaction/utils';
import { useStyles } from './style';

const NeedsHeader = () => {
  const classe = useStyles();

  return (
    <div className={classe.header}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} md={6} className={classe.displayFlexAlignCenter}>
          <Typography variant="h2" className={classe.titleHeader}>
            Needs®, <br />
            test des 7 besoins
            <br />
            professionnels et personnels
          </Typography>
        </Grid>
        <Grid item={true} md={3}>
          <img
            src={`${publicImgMajorTest}/illustration_banniere_needs.png`}
            alt="couverture intro"
            className={classe.imageBtn}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NeedsHeader;
