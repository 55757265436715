/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import {
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import SearchIcon from '@material-ui/icons/Search';
import { MarginContainer } from '@orientaction/commons';
import { Layout, Table } from '@orientaction/components';
import { useAlert, useMajorTest, useMyResulatOfTest } from '@orientaction/hooks';
import { columnsMesResultats, columnsMesResultatsMobile } from '@orientaction/utils';
import dateFormat from 'dateformat';
import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './style';

const ListMyResultats = () => {
  const classes = useStyles();

  const history = useHistory();

  const [testDone, setResponseUser]: any = useState([]);

  const [load, setLoading] = useState(true);

  const [searchData, setSearchData] = useState('');

  const [testProtected, setTestProtected]: any = useState([]);

  const { getUserAllTestLatestResult } = useMajorTest();

  const { setDialog, setMyResult } = useMyResulatOfTest();

  const alert = useAlert();

  const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const getResultat = async () => {
    setLoading(true);

    setDialog({});

    setMyResult({});

    try {
      const allTests = await getUserAllTestLatestResult();

      const testsMaped = allTests.customTestResults.map((test: any) => {
        if (isXS) {
          return [
            {
              value: test?.test?.title,
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() => history.push(`/mes-resultats/${test.id}`)}
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(new Date(test.createdAt), 'dd/mm/yyyy, HH:MM')}`,
            },
          ];
        }

        return [
          {
            value: `${dateFormat(new Date(test.createdAt), 'dd/mm/yyyy, HH:MM')}`,
          },
          {
            value: test?.test?.title,
          },
          {
            value: (
              <Button variant="outlined" onClick={() => history.push(`/mes-resultats/${test.id}`)}>
                Consulter
              </Button>
            ),
          },
        ];
      });

      const majorTests = [];

      if (isXS) {
        if (allTests.meaningTestLastResult) {
          majorTests.push([
            {
              isBold: true,
              value: 'Sens® - test des 10 valeurs',
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-meaning-value/${allTests.meaningTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.meaningTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }

        if (allTests.needsTestLastResult) {
          majorTests.push([
            {
              isBold: true,

              value: 'Needs® - test des 7 besoins professionnels et personnels',
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() => history.push(`/resultat-need/${allTests.needsTestLastResult.id}`)}
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.needsTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }

        if (allTests.needsProTestLastResult) {
          majorTests.push([
            {
              isBold: true,

              value: 'Needs® pro - test des 7 besoins professionnels',
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-need-pro/${allTests.needsProTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.needsProTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }

        if (allTests.preferenceTestLastResult) {
          majorTests.push([
            {
              value: 'Préférences® - test des 32 personnalités',
              isBold: true,
            },

            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-preference/${allTests.preferenceTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.preferenceTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }

        if (allTests.harmonyTestLastResult) {
          majorTests.push([
            {
              value: 'Harmony® - test des 16 soft skills',
              isBold: true,
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-harmony/${allTests.harmonyTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.harmonyTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }

        // if (allTests.meaningTestLastResult) {
        //   majorTests.push([
        //     {
        //       value: 'Sens® - test des 10 valeurs',
        //       isBold: true,
        //     },
        //     {
        //       value: (
        //         <Button
        //           variant="outlined"
        //           onClick={() =>
        //             history.push(`/resultat-meaning-value/${allTests.meaningTestLastResult.id}`)
        //           }>
        //           Consulter
        //         </Button>
        //       ),
        //     },
        //     {
        //       value: `${dateFormat(
        //         new Date(allTests.meaningTestLastResult.createdAt),
        //         'dd/mm/yyyy, HH:MM'
        //       )}`,
        //     },
        //   ]);
        // }
        if (allTests.studyTestLastResult) {
          majorTests.push([
            {
              value: "Study® - test d'orientation",
              isBold: true,
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() => history.push(`/resultat-study/${allTests.studyTestLastResult.id}`)}
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.studyTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }
        if (allTests.preferenceJeuneLastResult) {
          majorTests.push([
            {
              value: 'Préférences® Jeunes',
              isBold: true,
            },

            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/res-pref-j/${allTests.preferenceJeuneLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
            {
              value: `${dateFormat(
                new Date(allTests.preferenceJeuneLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
          ]);
        }
      } else {
        // to be fixed
        if (allTests.meaningTestLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.meaningTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              isBold: true,

              value: 'Sens® - test des 10 valeurs',
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-meaning-value/${allTests.meaningTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }
        if (allTests.needsTestLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.needsTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              value: 'Needs® - test des 7 besoins professionnels et personnels',
              isBold: true,
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() => history.push(`/resultat-need/${allTests.needsTestLastResult.id}`)}
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }
        if (allTests.needsProTestLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.needsProTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              isBold: true,

              value: 'Needs® pro - test des 7 besoins professionnels',
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-need-pro/${allTests.needsProTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }
        if (allTests.preferenceTestLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.preferenceTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              value: 'Préférences® - test des 32 personnalités',
              isBold: true,
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-preference/${allTests.preferenceTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }

        if (allTests.harmonyTestLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.harmonyTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              isBold: true,

              value: 'Harmony® - test des 16 soft skills',
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/resultat-harmony/${allTests.harmonyTestLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }
        if (allTests.studyTestLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.studyTestLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              isBold: true,

              value: "Study® - test d'orientation",
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() => history.push(`/resultat-study/${allTests.studyTestLastResult.id}`)}
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }
        if (allTests.preferenceJeuneLastResult) {
          majorTests.push([
            {
              value: `${dateFormat(
                new Date(allTests.preferenceJeuneLastResult.createdAt),
                'dd/mm/yyyy, HH:MM',
              )}`,
            },
            {
              value: 'Préférences® Jeunes',
              isBold: true,
            },
            {
              value: (
                <Button
                  variant="outlined"
                  onClick={() =>
                    history.push(`/res-pref-j/${allTests.preferenceJeuneLastResult.id}`)
                  }
                >
                  Consulter
                </Button>
              ),
            },
          ]);
        }
      }

      const allMyTests = majorTests.concat(testsMaped);

      setTestProtected(allMyTests);
      setResponseUser(allMyTests);
    } catch (err) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e: any) => {
    setSearchData(e.target.value);
    const filterData = testProtected.filter((row: any) => {
      return some(row, (elem: any) => {
        return includes(lowerCase(elem.value), lowerCase(e.target.value));
      });
    });
    setResponseUser(filterData);
    if (e.target.value === '') {
      setResponseUser(testProtected);
    }
  };

  useEffect(() => {
    getResultat();
  }, [isXS]);

  return (
    <Layout isaddBackground={true} noPadding={true} isNew={true} footer={true}>
      <div className={classes.paddingY}>
        <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
          <Grid item={true} xs={12} md={3}>
            <Typography variant="h2" className={classes.colorWhite}>
              Mes résultats{' '}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md={3}>
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Rechercher"
                value={searchData}
                onChange={handleSearch}
              />
              <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>

        {load ? (
          <div className={classes.bodycenter}>
            <CircularProgress color="inherit" className={classes.colorWhite} />
          </div>
        ) : (
          <MarginContainer>
            <Table
              rowsPerPage={10}
              isCustomer={true}
              thead={isXS ? columnsMesResultatsMobile : columnsMesResultats}
              bodyData={testDone}
              count={testDone.length}
              handleChangePage={undefined}
              currentPage={undefined}
              isHover={false}
            />
          </MarginContainer>
        )}
      </div>
    </Layout>
  );
};

export default withWidth()(ListMyResultats);
