import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      width: '100%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-20px',
      float: 'left',
    },
    overrideParagraphStyle: {
      fontStyle: 'italic',
    },
    overrideTextStyle: {
      fontSize: 14,
    },
  })
);

export default useStyles;
