import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useLanguage } from '@orientaction/hooks';
import { FC } from 'react';
import Explanation from './Explanation';
import style from './style';
import Suggestion from './Suggestion';

const StudyStyle = (props: any) => {
  const { language } = useLanguage();
  const classes = style();

  return (
    <Paper className={classes.studyStyleRestitutionContainer} variant="outlined">
      <Grid container={true}>
        <Grid item={true} xs={12} sm={12} md={12}>
          <Explanation language={language} data={props} />
        </Grid>
        <Grid className={classes.suggestionContainer} item={true} xs={12} sm={12} md={12}>
          <Suggestion {...props} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StudyStyle;
