import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  select: {
    fontFamily: 'Poppins',
  },
  label: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#0E1247',
    fontSize: '16px',
    marginBottom: 10,
  },
}));
