/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useRef } from 'react';
import { useStyles } from './style';
import { DragAndDrop, ContentFlex } from '@orientaction/components';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { TextField, Grid, Typography, Button, IconButton } from '@material-ui/core';
import { useCategoryTest } from '@orientaction/hooks';
import { IcontentICategoryTest } from '@orientaction/types';

interface ICard {
  id: number;
  required: boolean;
  name: string;
}

const Card: FC<ICard> = ({ id, name, required }) => {
  const hiddenFileInput = useRef<any>({});

  const classe = useStyles();

  const { list, listTest, setList, setModal } = useCategoryTest();

  const handleChange = (id_category: number) => (event: any) => {
    const { value } = event.target;

    const findIndex = list.findIndex((x: IcontentICategoryTest) => x && x?.id === id_category);

    list[findIndex] = {
      ...list[findIndex],
      name: value,
    };

    setList(list);
  };

  const handleDelete = (id_category: number) => (event: any) => {
    const object = {
      id_category: id_category as number,
      type: 'delete_category',
      open: true,
      title: 'Êtes-vous sûr de vouloir supprimer cette catégorie ',
      textCancel: 'Non',
      textValidate: 'Oui',
      noComfirmButton: false,
    };

    const contentListCurrent = listTest.filter((x: any) => x.category === id_category);

    if (contentListCurrent.length) {
      object.title = 'Cette catégorie contient encore des tests et ne peut pas être supprimée';
      object.noComfirmButton = true;
      object.textCancel = 'Ok';
    }

    setModal(object);
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    hiddenFileInput.current.focus();
  };

  return (
    <div data-cy="empty-category-block">
      <Typography className={classe.label}>Titre catégorie</Typography>

      <IconButton className={classe.iconDelete} onClick={handleDelete(id)}>
        <DeleteForeverIcon />
      </IconButton>

      {/* <div onMouseDown={handleDrag}> */}

      <TextField
        variant="outlined"
        className={classe.formInput}
        onChange={handleChange(id)}
        value={name || ''}
        placeholder="Renseignez le titre de la catégorie"
        ref={hiddenFileInput}
      />

      {/* </div> */}

      {required && <Typography className={classe.require}>Champ obligatoire</Typography>}

      <DragAndDrop key={name} id_category={id} />

      {!listTest.filter((x: any) => x.category === id).length && (
        <>
          <Grid
            container={true}
            alignItems="center"
            justifyContent="center"
            className={classe.delete}
            onClick={handleDelete(id)}>
            <Grid item={true}>
              <DeleteForeverIcon />
            </Grid>
            <Grid item={true}>
              <Typography className={classe.textDelete}>Supprimer</Typography>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default Card;
