/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import { resultOfTestAction } from '@orientaction/api-actions';
import { apiState, appResultOfTest } from '@orientaction/reducers';
import { resultOfTestService } from '@orientaction/services';
import { IResultOfTest } from '@orientaction/types';
import { getFromLS } from '@orientaction/utils';
import { useDispatch, useSelector } from 'react-redux';

const useResulatOfTest = (companyId: any) => {
  const dispatch = useDispatch();

  const state: IResultOfTest = useSelector(appResultOfTest);
  const testFiltersState: any = useSelector((store: any) => store.api.testFilters);
  const userFiltersState: any = useSelector((store: any) => store.api.userTestFilters);
  const testSelectedState: any = useSelector((store: any) => store.api.testSelected);

  const token = getFromLS('userToken') || '';

  const setListOfResult = (bool: any[]) => {
    dispatch(resultOfTestAction.setListOfResult(bool));
  };

  const setCount = (nbr: number) => {
    dispatch(resultOfTestAction.setCount(nbr));
  };

  const getResultTest = async (props: any) => {
    const isHarmony = testSelectedState.id === 5;
    const { data } = await resultOfTestService.getResultOfTest(
      companyId,
      token,
      props,
      isHarmony,
      testFiltersState,
      userFiltersState
    );
    setListOfResult(data.list);
    setCount(data.count);
  };

  const deleteResultOfTest = async (props: any) => {
    const { data } = await resultOfTestService.deleteResultOfTest(token, props);
  };

  return {
    ...state,
    setListOfResult,
    getResultTest,
    setCount,
    deleteResultOfTest,
  };
};

export default useResulatOfTest;
