/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { publicSvgPath } from '@orientaction/utils';
import { FC } from 'react';
import { useStyles } from './style';

const ThirdView: FC = () => {
  const classe = useStyles();

  const redirect = (e: any) => {
    window.open(
      'https://www.orientaction-groupe.com/qui-sommes-nous/bilan-de-competences/',
      '_blank'
    );
  };

  return (
    <div className={classe.root}>
      <div className={classe.container}>
        <div className={classe.content}>
          <div className={classe.contentElement}>
            <Typography className={classe.title} component="h2">
              APPLICATIONS CONCRÈTES DANS VOTRE VIE PERSONNELLE
            </Typography>

            <Typography component="p" className={classe.paragraph}>
              Si vous ne vous sentez pas pleinement épanoui(e) dans votre vie personnelle, c’est
              sans doute parce que certains de vos besoins demeurent insatisfaits. Lorsque l’un de
              nos besoins demeure insatisfait, nous nous sentons frustrés. Cette frustration se
              manifeste par une certaine contrariété, des plaintes, voire de la colère qui peut être
              à la source de tensions, voire de véritables disputes avec vos proches. Au contraire,
              si vos besoins sont satisfaits, alors vos relations s’en trouveront renforcées. Voilà
              pourquoi il faut être à l’écoute de ses besoins et être capable de les expliciter à
              ses proches. Cela leur permettra de mieux y répondre et vous vous sentirez beaucoup
              mieux et plus épanoui(e) dans vos relations interpersonnelles. Satisfaire ses besoins
              est l’une des clefs du bonheur.
            </Typography>
          </div>
        </div>

        <div className={classe.cardContainer} onClick={redirect}>
          <img className={classe.imgBan} src={`${publicSvgPath}/ban1.png`} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default ThirdView;
