import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { RichEditor, StyledDropzone } from '@orientaction/commons';
import { TestContext } from '@orientaction/Context';
import clsx from 'clsx';
import { FC, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import style from './style';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span style={{ fontSize: 26, margin: 8 }}>+</span>
      <span>Ajouter une image</span>
      <span>à la une</span>
    </div>
  );
};

interface IProps {
  testTitleRef: any;
  testTimeRef: any;
  testImageRef: any;
}

const TestSection: FC<IProps> = ({ testTitleRef, testTimeRef, testImageRef }) => {
  const { pathname } = useLocation();
  const { test, updateTest } = useContext<any>(TestContext);
  const cls = style();

  const handleChange = (value: string) => {
    updateTest({
      ...test,
      test: {
        description: value,
        title: test.test.title,
        image: test.test.image,
        id: test.test.id,
        time: test.test.time,
      },
    });
  };

  const handleTestNameChange = (e: any) => {
    updateTest({
      ...test,
      test: {
        title: e.target.value,
        description: test.test.description,
        image: test.test.image,
        id: test.test.id,
        time: test.test.time,
      },
    });
  };

  const handleFileUrlChange = (url: string) => {
    updateTest({
      ...test,
      test: {
        image: url,
        title: test.test.title,
        description: test.test.description,
        id: test.test.id,
        time: test.test.time,
      },
    });
  };
  const handleTestTimeChange = (e: any) => {
    updateTest({
      ...test,
      test: {
        title: test.test.title,
        description: test.test.description,
        image: test.test.image,
        id: test.test.id,
        time: e.target.value,
      },
    });
  };
  return (
    <>
      <div className={cls.header}>
        <h1 className={cls.title} data-cy="create-test-mineur">
          {pathname === '/admin/test/create-test' ? 'Créer un test' : 'Modifier un test'}
        </h1>
      </div>
      <h1 className={cls.subtitle}>Informations</h1>
      <div style={{ marginTop: '20px' }}>
        <h3
          className={clsx({
            [cls.h3]: true,
            [cls.textError]: !!test?.manageError?.title,
          })}
          style={{ marginLeft: '0px', marginBottom: '5px' }}>
          <strong>Nom </strong>
        </h3>
      </div>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={6} md={6}>
          <div ref={testTitleRef}>
            <Input
              value={test.test.title}
              placeholder="Renseignez le nom"
              name="name-test"
              type="text"
              className={clsx({
                [cls.input]: true,
                [cls.inputError]: !!test?.manageError?.title,
              })}
              onChange={handleTestNameChange}
            />
          </div>
          <div ref={testTimeRef}>
            <h3
              className={clsx({
                [cls.h3]: true,
                [cls.textError]: !!test?.manageError?.time,
              })}
              style={{ marginLeft: '0px', marginBottom: '5px' }}>
              <strong>Temps estimé (minutes)</strong>
            </h3>

            <Input
              value={test.test.time}
              placeholder="Ex : 3"
              type="number"
              name="test-time"
              onChange={handleTestTimeChange}
              className={clsx({
                [cls.input6]: true,
                [cls.inputError]: !!test?.manageError?.time,
              })}
            />
          </div>
          <div>
            <h3 style={{ marginLeft: '0px', marginBottom: '5px' }}>
              <strong>Description du test</strong>
            </h3>

            <RichEditor
              value={test.test.description}
              handleChange={handleChange}
              placeholder="Renseignez la description du test"
            />
          </div>
        </Grid>
        <Grid innerRef={testImageRef} item={true} xs={6} md={6}>
          <StyledDropzone
            fileUrl={test.test.image}
            handleFileUrlChange={handleFileUrlChange}
            placeholder={placeholder}
            pathPrefix="tests"
            sectionTop={true}
            error={!!test?.manageError?.image}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TestSection;
