import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardBtn: {
            width: '90%',
            position: 'relative',
            backgroundImage: 'url(/images/Background/bgBousole.svg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            paddingBottom: '76.5%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                paddingBottom: '85%',
                marginBottom: '2.5vh',
                marginTop: '2.5vh'
            }
        },
        active: {
            zIndex: 100,
        },
        link: {
            position: 'absolute',
            wordBreak: 'normal',
            textTransform: 'uppercase',
            textDecoration: 'none',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 15.4452,
            color: '#0E1247',
            transition: '100ms',
            [theme.breakpoints.down(1440)]: {
                fontSize: 13,
            },
            [theme.breakpoints.down(1350)]: {
                fontSize: 12,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: 8.5,
            }
        },
        meaning: {
            width: '100px',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            wordBreak: 'normal',
        },
        preference: {
            width: '110px',
            left: 0,
            right: 0,
            bottom: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
        },
        soft: {
            width: '100px',
            height: '15px',
            top: 0,
            bottom: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
            textAlign: 'start',
            [theme.breakpoints.down('sm')]: {
                height: '12.5px',
            }
        },
        besoins: {
            width: '100px',
            height: '15px',
            right: 0,
            top: 0,
            bottom: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
            textAlign: 'end',
            [theme.breakpoints.down('sm')]: {
                height: '12.5px',
            }
        },
        compass: {
            width: '100%',
            height: "100%",
            display: 'flex',
            alignItems: "center",
            justifyContent: 'center',
            position: 'absolute',
        }
    }),
);
