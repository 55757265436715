import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: '#EDEEF2',
            margin: 0,
            padding: 0,
            paddingTop: '2vh',
            width: '100%',
            minHeight: '100%'
        },
        content: {
            position: 'relative',
            width: '95%',
            margin: '0 auto',
            paddingBottom: '30px'
        },
        cancel: {
            border: '1px solid #BCBCBC',
            color: '#0E1247',
            padding: "1.5vh",
            margin: '0 1vh'
        },
        footer: {
            margin: 5,
            marginRight: 20
        },
        titleModal: {
            width: '80%',
            margin: '0 auto'
        },
        loadingFullWidth: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80vh'
        },
        title: {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '26px',
            lineHeight: '140 %',
            display: 'flex',
            alignItems: 'center',
            color: '#000A8C'
        },
        rootDragAndDrop: {
            margin: '3.5vh 0'
        }
    })
);