// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import Typography from '@material-ui/core/Typography';
import { imgBook4 } from '@orientaction/utils';
import useStyles from './style';
import SimpleBanner from '../../../components/SimpleBanner';
import clsx from 'clsx';

const Page07 = () => {
  const classes = useStyles();

  return (
    <Page id={7}>
      <TitleChapitre nbr={1} />
      <TitlePage>
        IDENTIFIER VOS COMPÉTENCES
        <br />
        ET ÉVALUER VOTRE POTENTIEL
      </TitlePage>
      <br />
      <Paragrapth align="justify">
        Lorsque l’on regarde autour de nous, on voit des personnes qui ont de la chance et d’autres
        qui n’en ont pas. Certaines personnes collectionnent les succès dans leur vie, tandis que
        d’autres collectionnent les échecs, les malchances. Certaines semblent naturellement plus
        favorisées que d’autres par le destin. Spontanément, on se dit que c’est une question de
        chance : soit on a de la chance, soit on n’en a pas. Très souvent, faute d’explications plus
        rationnelles, on invoque la bonne étoile pour expliquer ces différences de destin.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Or, si on regarde bien, on s’aperçoit que les personnes qui ont de la chance possèdent
        certaines caractéristiques, développent certaines attitudes et certains comportements
        différents de celles qui jouent de malchance. La vie ne ressemble pas tout à fait à une
        série de coups de dés. Certes, le hasard joue un rôle, mais on s’aperçoit que très souvent
        le hasard est en fait provoqué. L’évènement heureux est souvent le fruit d’un long processus
        de maturation dans lequel l’individu joue un rôle déterminant.
      </Paragrapth>
      <br />
      <br />
      <div className={classes.cardBgGrey} style={{ marginTop: '10px' }}>
        <div style={{ marginBottom: '10px' }}>
          {/* <img className={classes.imgTailleur} src={`${imgBook4}/copain.png`} alt="logo" /> */}
          <SimpleBanner
            bannerContainerOverride={classes.bannerContainerOverride}
            bannerStyleOverride={classes.bannerStyleOverride}
          >
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              COPAINS D&apos; AVANT
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook4}/chemins.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          J’ai suivi une scolarité normale parmi beaucoup de jeunes gens dont certains furent de
          très bons(nes) ami(e)s. Au lycée, nous ne voyions pas de différences entre nous. Nous
          avions la même intelligence, le même niveau d’études, les mêmes distractions. Nous
          partagions le même objectif d’avoir notre bac.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Plusieurs années plus tard, lorsque je regarde ce que mes ami(e)s sont devenu(e)s sur le
          site « Mes copains d’avant », je m’aperçois à quel point leurs destins sont différents.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Certain(e)s exercent des métiers précaires et mal rémunérés sans véritables perspectives,
          d’autres ont fait de très belles carrières et ont une vie bien remplie.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Certain(e)s ont voyagé partout dans le monde, rencontré des centaines de personnes
          intéressantes, vécu des tas d’expériences, développé des passions, tandis que d’autres
          semblent être resté(e)s immobiles sur le bord de la route. Au départ, toutes ces personnes
          avaient les mêmes chances de réussir. Elles avaient le même niveau socio-économique, le
          même niveau d’études, des personnalités proches, la même chance initiale, et pourtant
          elles ont pris des chemins différents.
        </Typography>
      </div>
    </Page>
  );
};

export default Page07;
