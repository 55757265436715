import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    applicationBlockContainer: {
      marginTop: 40,
      [theme.breakpoints.up('lg')]: {
        maxHeight: '100vh',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: 16,
      padding: 25,
      height: '100%',
      marginBottom: 20,
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      color: 'black',
      fontSize: 22,
      [theme.breakpoints.down('lg')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 16,
      },
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 14,
      color: '#242424',
    },
    paragraphMarginTop: {
      marginTop: 25,
    },
    applicationBlockContainerFirstItem: {},
    applicationBlockContainerSecondItem: {},
  }),
);

export default useStyles;
