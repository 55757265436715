/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { getMe } from '../services/user.service';
import { getFromLS } from '../utils/utils';

const useMe = () => {
  const [loading, setloading] = useState<boolean>(false);
  const [me, setMe] = useState<any>();
  const history = useHistory();
  useEffect(() => {
    setloading(true);
    const token = getFromLS('userToken');
    if (token) {
      getMe(token)
        .then((result: any) => {
          setMe(result?.data);
          console.log({result: result?.data})
          return 'ok'
        })
        .then((ok: string) => {
          setloading(false);
        })
        .catch(() => history.push('/'));
    } else {
      // history.push('/');
    }
  }, []);
  return {
    me,
    loading,
  };
};

export default useMe;
