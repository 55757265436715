/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import clsx from 'clsx';
import { FC } from 'react';
import { useStyles } from './style';

interface IBtnLink {
    children: any;
    isActive: boolean;
}

const BtnLink: FC<IBtnLink> = ({
    children, isActive
}) => {

    const classe = useStyles();

    return (
        <div
            className={classe.cardBtn}
        >

            <a
                href='#meaning-value'
                className={clsx({
                    [classe.meaning]: true,
                    [classe.link]: true,
                    [classe.active]: !isActive
                })}
            >
                VALEURS
            </a>

            <a
                href='#preference-personality'
                className={clsx({
                    [classe.preference]: true,
                    [classe.link]: true,
                    [classe.active]: !isActive
                })}
            >
                personnalité
            </a>

            <a href='#soft-skills'
                className={clsx({
                    [classe.soft]: true,
                    [classe.link]: true,
                    [classe.active]: !isActive
                })}
            >
                TALENTS
            </a>

            <a href='#besoins-pro'
                className={clsx({
                    [classe.besoins]: true,
                    [classe.link]: true,
                    [classe.active]: !isActive
                })}
            >
                besoins
            </a>

            <div className={classe.compass}>
                {children}
            </div>

        </div >
    );
};

export default BtnLink;
