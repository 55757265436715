import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardBgGrey: {
            padding: 40,
            background: '#e8eef4',
            fontStyle: 'italic',
            '@media only screen and (max-width: 600px)': {
                padding: 20,
            },
            '@media only screen and (max-width: 361px)': {
                padding: 15,
            },
        },
        img: {
            width: '90%',
        }
    }))

export default useStyles; 