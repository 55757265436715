/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { majorTestAction } from '@orientaction/api-actions';
import { appMajorTest } from '@orientaction/reducers';
import { MajorTest } from '@orientaction/services';
import { IMajorTest } from '@orientaction/types';
import { getFromLS } from '@orientaction/utils';
import { useDispatch, useSelector } from 'react-redux';
import useAlert from './useAlert';

const useMajorTest = () => {
  const dispatch = useDispatch();

  const data: IMajorTest = useSelector(appMajorTest);

  const token = getFromLS('userToken') || '';

  const userInfos: any = JSON.parse(getFromLS('userInfos') || '');

  const alert = useAlert();

  const setLoading = (bool: boolean) => {
    dispatch(majorTestAction.setLoading(bool));
  };

  const setListMajorTest = (list: any[]) => {
    dispatch(majorTestAction.setListMajorTest(list));
  };

  const getListMajorTest = async () => {
    setLoading(true);

    setListMajorTest([]);

    try {
      const companies = await MajorTest.getListMajorTest(token);

      setListMajorTest(companies.data?.list);
    } catch (err: any) {
      console.log('err', err);

      setListMajorTest([]);

      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const getMyMajorTest = async (searchKey = '') => {
    setLoading(true);

    setListMajorTest([]);

    try {
      const companies = await MajorTest.getMyListMajorTest(
        userInfos?.company?.id,
        token,
        searchKey
      );
      setListMajorTest(companies.data?.list);
    } catch (err: any) {
      console.log('err', err);

      setListMajorTest([]);

      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const getLabelMajorTest = async (company: string) => {
    const companies = await MajorTest.getMyListMajorTest(company, token);

    return companies.data?.list;
  };

  const getUserAllTestLatestResult = async (userId = +userInfos?.id) => {
    const response: any = await MajorTest.getUserAllTestLatestResult(token, +userId);
    return response.data.data;
  };

  return {
    ...data,
    getListMajorTest,
    getMyMajorTest,
    getLabelMajorTest,
    getUserAllTestLatestResult,
    setLoading,
  };
};

export default useMajorTest;
