export const context = [
    {
        title: "LES OPPORTUNITÉS D’UNE RECONVERSION",
        id: 1,
        listContext: [
            {
                title: "Faire le métier dont on rêve",
                content: `on fait rarement le métier dont on rêve à la sortie de
                ses études. Se reconvertir peut permettre de réaliser ce rêve. Après une période de
                formation, la reconversion permet de trouver un métier réellement épanouissant.
                Aujourd’hui, certains métiers de la création comme le design, décrits il y a 10 ans
                comme des voies sans issue, offrent de magnifiques carrières. L’important est de
                viser le plein épanouissement à travers une activité qui procure un vrai plaisir au
                quotidien. Si tel est votre cas, la reconversion s’impose…`
            },
            {
                title: "Se diriger vers un secteur plus porteur",
                content: `certains secteurs d’activité sont voués à
                disparaître, tandis que d’autres sont voués à se développer. Se reconvertir dans un
                secteur très porteur, c’est se donner toutes les chances de réussir dans l’avenir.
                Cette décision s’impose dans de nombreux cas, surtout pour les personnes peu ou
                pas qualifiées. Il faut savoir quitter son secteur d’activité pour évoluer pendant qu’il
                en est encore temps. Mieux vaut se reconvertir au bon moment plutôt que de se
                retrouver dans une impasse.`
            },
            {
                title: "Réduire les risques qui pèsent sur sa santé",
                content: `certains métiers sollicitent beaucoup
                le corps et l’esprit, à tel point qu’une reconversion au terme de plusieurs années
                d’exercice professionnel peut s’avérer salutaire pour préserver sa santé.
                Stress, mal de dos, exposition à des matières dangereuses, la liste est longue. Dans
                tous les cas, en limitant l’exposition dans le temps, on limite les risques pour sa
                santé. La reconversion est une solution intelligente pour préserver son capital santé,
                indispensable pour continuer à vivre heureux(se).`
            },
        ]
    },
    {
        title: "LES RISQUES D’UNE RECONVERSION",
        id: 2,
        listContext: [
            {
                title: "Un parcours de longue haleine",
                content: `changer de métier n’est pas si évident que cela
                puisse paraître. Les temps de formation qui seront nécessaires pour assurer la
                reconversion sont relativement importants. Par ailleurs, se former, réapprendre, est
                très exigeant sur les plans physique et intellectuel. Pour toutes ces raisons, certaines
                personnes peuvent se décourager en cours de parcours. Il convient donc de bien
                anticiper l’engagement qui sera nécessaire pour réussir sa reconversion avant de
                l’entreprendre.`
            },
            {
                title: "Tout recommencer à zéro",
                content: `lorsque l’on a acquis une certaine expérience dans un
                domaine, on appartient à la catégorie des « établi(e)s ». Les gens nous font confiance
                car ils savent ce que nous valons. Lorsque l’on entreprend une reconversion, on
                recommence tout à zéro. Il va falloir refaire ses preuves. On appartient à la catégorie
                des « outsiders ». Les gens ne nous font pas confiance spontanément. Ils attendent
                de voir ce que nous valons vraiment. Avant de redevenir un(e) « établi(e) » il faut
                parfois compter plusieurs années.`
            },
            {
                title: "Se tromper",
                content: `certaines personnes qui ne préparent pas suffisamment leur projet
                peuvent se tromper et choisir une profession qu’elles ont idéalisée. Une fois formées
                et prêtes à exercer leur nouveau métier, elles s’aperçoivent douloureusement
                qu’elles se sont trompées.Finalement ce métier qu’elles pensaient fait pour elles
                ne l’était pas. Une reconversion doit donc être étudiée de très près avant d’être
                engagée. Il est nécessaire de rencontrer des professionnel(le)s et de réaliser des
                stages de découverte pour être sûr(e) de son fait avant de s’engager.`
            },
        ]
    }
]