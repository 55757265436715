export const dataFirstView = {
  description:
    'Voici votre besoin principal dans votre vie professionnelle. La satisfaction de vos besoins est à la source de votre bien-être et de votre motivation. Il est important que votre métier, vos conditions de travail et de rémunération, ainsi que vos relations professionnelles contribuent pleinement à satisfaire ce besoin.',
};

export const fakeResult = {
  treeMax: [
    {
      score: 20,
      key: 'accomplishment_pro',
      path: '/images/need/accomplishment.jpg',
      color: '#C93646',
      label: 'Accomplissement',
    },
    {
      score: 19,
      key: 'self_realization_pro',
      path: '/images/need/self_realization.jpg',
      color: '#ED6637',
      label: 'Réalisation de soi',
    },
    {
      score: 15,
      key: 'security_pro',
      path: '/images/need/security.jpg',
      color: '#662169',
      label: 'Sécurité',
    },
  ],
  favorite: {
    label: 'Accomplissement',
    key: 'accomplishment_pro',
    img: '/images/need/accomplishment.jpg',
    imgPyramid: '/images/need/pyramid_accomplishment.jpg',
    color: '#C93646',
  },
  users_permissions_user: 42369,
  listHistory: [
    {
      id: 17,
      createdAt: '2023-03-03T08:44:33.506Z',
    },
    {
      id: 16,
      createdAt: '2023-03-03T08:43:06.520Z',
    },
    {
      id: 1,
      createdAt: '2023-02-08T03:39:48.224Z',
    },
  ],
};
