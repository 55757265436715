import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '0 auto',
      [theme.breakpoints.down(500)]: {
        width: '95%',
      },
    },
    studyRestitutionPageTitle: {
      fontFamily: 'Poppins',
      fontSize: 36,
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#fff',
      marginLeft: 112,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 16,
      },
    },
    timelineAlignLeft: {
      marginTop: 50,
      '& .MuiTimelineItem-missingOppositeContent:before': {
        flex: 0,
        content: '',
      },
      [theme.breakpoints.down(900)]: {
        marginTop: 10,
      },
    },
    marginTop: {
      marginTop: 25,
    },
    button: {
      color: '#fff',
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.primary.dark,
      textTransform: 'none',
      fontFamily: 'Poppins',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 500,
      margin: '32px 0px 32px 106px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 12,
      },
    },
    btnHistory: {
      position: 'absolute',
      top: '3%',
      right: 0,
      [theme.breakpoints.down(1440)]: {
        top: '2%',
      },
      [theme.breakpoints.down(800)]: {
        top: '2%',
      },
    },
    bodycenter: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    studyRestitutionShareButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 50,
    },
    studyRestitutionShareButton: {
      color: theme.palette.primary.main,
      borderRadius: 4,
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: '#fff',
      textTransform: 'none',
      fontFamily: 'Poppins',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 600,
      width: '25%',
      [theme.breakpoints.down('md')]: {
        width: '45%',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
        border: `1px solid #fff`,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
        border: `1px solid #fff`,
      },
    },
  })
);

export default useStyles;
