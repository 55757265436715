/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-use-before-define */
import { Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import React from 'react';
import CircularProgressCustom from '../CircularProgressCustom';
import ExpandableItem from '../ExpandableItem';
import style from './style';
import clsx from 'clsx';

interface IModalCardSens {
  open: boolean;
  onCancel: () => void;
  color: string;
  img: string;
  sens: any;
  colorSecondary: string;
  opacity: boolean;
  longText: string;
  text1: string;
  listeAccordeon: any;
  listEmploi1: any;
  listEmploi2: any;
  text2: string;
  text3: string;
  text4: string;
}

const ModalCardSens: React.FC<IModalCardSens> = ({
  open,
  onCancel,
  color,
  img,
  sens,
  colorSecondary,
  opacity,
  longText,
  text1,
  listeAccordeon,
  listEmploi1,
  listEmploi2,
  text2,
  text3,
  text4,
}) => {
  const classes = style({ color: opacity ? 'white' : colorSecondary });

  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={isTab ? 'xl' : 'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent style={{ position: 'relative', padding: 0 }}>
        <IconButton aria-label="delete" className={classes.closeBtn} onClick={onCancel}>
          <Close />
        </IconButton>
        <Grid container={true}>
          <Grid item={true}>
            <div className={classes.titlehead} style={{ background: color }}>
              <h3 className={classes.titleText}>{sens.label}</h3>
            </div>
          </Grid>
          <Grid item={true} xs={true} sm={true} md={5}>
            <img className={classes.imgHeight} src={img} alt="" style={{ width: '100%' }} />
          </Grid>
          <Grid item={true} sm={12} xs={12} md={true}>
            <div className={classes.paddingGridtext}>
              <div className={classes.positionCircular2}>
                <CircularProgressCustom
                  value={sens.score}
                  color={color}
                  size={130}
                  fontSize={23}
                  fontSizeFloat={15}
                />
              </div>
              <Typography
                variant="h2"
                color="textPrimary"
                className={clsx(classes.titleContent, classes.widthTitle)}>
                {sens.description[0]}
              </Typography>
              <br />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.marginBottomMobile}
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line react/destructuring-assignment
                  __html: isTab ? sens.description[1] : longText,
                }}
              />
            </div>
          </Grid>
          <Grid />
        </Grid>
        <div className={classes.paddingGridText2} style={{ background: color }}>
          <Grid container={true}>
            <Grid item={true} md={12}>
              <Typography
                variant="h2"
                color="textPrimary"
                className={classes.titleContent}
                style={{ color: 'white', textTransform: 'none', fontSize: 21 }}>
                Les conseils de carrière d’Orientaction
              </Typography>
              <br />
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                style={{ color: 'white' }}
                className={classes.text1}
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line react/destructuring-assignment
                  __html: text1,
                }}
              />
            </Grid>
          </Grid>
          <Grid container={true} style={{ marginTop: 40 }} spacing={3}>
            <Grid item={true} md={6}>
              {!isTab &&
                listeAccordeon?.map((elem: any, index: any) => {
                  if ((index + 1) % 2 !== 0) {
                    return (
                      <ExpandableItem
                        key={index}
                        index={index + 1}
                        title={elem.title}
                        description={elem.description}
                        color={colorSecondary}
                        opacity={opacity}
                      />
                    );
                  }
                })}
              {isTab &&
                listeAccordeon?.map((elem: any, index: any) => {
                  return (
                    <ExpandableItem
                      key={index}
                      index={index + 1}
                      title={elem.title}
                      description={elem.description}
                      color={colorSecondary}
                      opacity={opacity}
                    />
                  );
                })}
            </Grid>
            {!isTab && (
              <Grid item={true} md={6}>
                {listeAccordeon?.map((elem: any, index: number) => {
                  if ((index + 1) % 2 === 0) {
                    return (
                      <ExpandableItem
                        key={index}
                        index={index + 1}
                        title={elem.title}
                        description={elem.description}
                        color={colorSecondary}
                        opacity={opacity}
                      />
                    );
                  }
                })}
              </Grid>
            )}
          </Grid>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.textConseil}>
            <b>{text2}</b>
          </Typography>
          <div className={classes.cardBorder}>
            <Typography
              variant="h2"
              color="textPrimary"
              className={classes.titleContent}
              style={{ color: 'white', textTransform: 'none', fontSize: 21 }}>
              Liste des métiers en lien avec cette valeur
            </Typography>
            <br />
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ color: 'white' }}
              className={classes.text1}
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line react/destructuring-assignment
                __html: text3,
              }}
            />
            <Grid
              container={true}
              justifyContent="space-between"
              spacing={isTab ? 0 : 4}
              style={{ marginTop: 3 }}>
              <Grid key={1} item={true} md={6}>
                <ul className={clsx(classes.listUl, classes.marginTopMobile)}>
                  {listEmploi1?.map((elem: any, index: number) => (
                    <li className={classes.listLi}>
                      <Typography
                        key={index}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ color: 'white' }}>
                        {elem}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid key={2} item={true} md={6}>
                <ul className={classes.listUl}>
                  {listEmploi2?.map((elem: any, index: number) => (
                    <li className={classes.listLi}>
                      <Typography
                        key={index}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ color: 'white' }}>
                        {elem}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                color: 'white',
                marginTop: 20,
              }}>
              {text4}
            </Typography>
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Agree
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
export default ModalCardSens;
