/* eslint-disable no-use-before-define */
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../components/Image';
import useStyles from './style';

const Page17 = () => {
  const classes = useStyles();
  return (
    <Page id={17}>
      <div className={classes.cardBgGrey}>
        <Grid container={true} justifyContent="center" alignItems="center">
          <Grid item={true} xs={12} md={12}>
            {/* <Img
              filename="queen.png"
              alt="queen"
              className={classes.imgTitle}
            /> */}
            <SimpleBanner isLong={true}>
              <Typography
                component="h1"
                className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
              >
                LA REINE QUI CROYAIT ÊTRE CENDRILLON
              </Typography>
            </SimpleBanner>
          </Grid>

          <Grid item={true} xs={12} md={12}>
            <Img filename="christiane.png" alt="Lisa" className={classes.img} />
          </Grid>

          <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
            <Paragrapth align="justify" addBackground={true}>
              Christiane est cadre supérieur dans un grand groupe de l’industrie pharmaceutique.
              Elle vit en région parisienne où elle a acheté une maison de 150 m2 dans un quartier
              résidentiel d’une banlieue cotée. Sa maison vaut près de 950 000 euros.
            </Paragrapth>
          </Grid>
          <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
            <Paragrapth align="justify" addBackground={true}>
              Elle possède par ailleurs un appartement au bord de la mer et plusieurs livrets
              d’épargne bien remplis. Au total, son capital économique s’élève à près de 1 350 000
              euros. Dans son entreprise, un(e) cadre de son niveau, avec son ancienneté, peut
              espérer négocier son départ contre une prime équivalente à vingt-quatre mois de
              salaire.
            </Paragrapth>
          </Grid>
          <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
            <Paragrapth align="justify" addBackground={true}>
              Ayant plus de cinquante ans, en cas de rupture conventionnelle, elle aura le droit à
              trois ans d’indemnisation chômage par le Pôle emploi, ce qui, compte tenu de son
              salaire actuel, lui assurera une rémunération tout à fait confortable. Christiane
              appartient aux 1% des Français(es) les plus aisé(e)s.
            </Paragrapth>
          </Grid>
          <Grid item={true} xs={12} md={12} style={{ marginTop: 10 }}>
            <Paragrapth align="justify" addBackground={true}>
              Pourtant, elle se sent en insécurité financière. Malgré une santé très fragilisée par
              son travail – Christiane a été hospitalisée pour un burn-out sévère il y a plusieurs
              semaines - elle refuse de quitter son entreprise et de mobiliser la moindre once de
              son capital économique pour rebondir. Elle préfère évoluer en interne.
            </Paragrapth>
          </Grid>
        </Grid>
      </div>
      <br />
      <Paragrapth align="justify">
        L’outil qui suit va vous permettre d’évaluer votre capital économique réel, ainsi que votre
        capital économique mobilisable. Gardez à l’esprit que votre capital économique mobilisable
        dépend avant tout des choix que vous faites. Il est de nature très subjective. Comme je
        l’expliquais précédemment, certaines personnes vendront leur résidence principale pour
        créer/reprendre une entreprise, tandis que d’autres refuseront d’investir un centime dans
        leur projet.
      </Paragrapth>
      <br />
      <br />
      <Paragrapth align="justify">
        Chacun fait ses choix en fonction de ses priorités et de ses objectifs de vie,
        professionnels et personnels.
      </Paragrapth>
    </Page>
  );
};

export default Page17;
