import clsx from 'clsx';
import { FC } from 'react';
import style from './style';

interface IContainerAdmin {
  children: any;
  rootStyleOverride?: any;
}

const ContainerAdmin: FC<IContainerAdmin> = ({ children, rootStyleOverride = undefined }) => {
  const cls = style();

  return <div className={rootStyleOverride || cls.container}>{children}</div>;
};

export default ContainerAdmin;
