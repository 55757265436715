/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, ParagrapthListNumber, Title, Paragrapth } from '@orientaction/components';
import useStyles from './style';
import { text } from './context';


const Page19 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={19}>

            <Title
                title="CHOIX N°2 : CHANGER DE MÉTIER ?"
                firstTitle="‘‘ Plus faibles sont les risques, meilleure est l’entreprise. ’’"
                firtsecondTitle="Sophocle"
                secondTitle=" ‘‘ La croissance et le changement peuvent être douloureux,
                mais rien n’est plus pénible que de rester coincé quelque
                part où tu ne veux plus être. ’’"
                secondSousTitle="Mandy Hale"
            />

            <div className={classes.marginContent}>
                <MarginContainer>
                    <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
                        Entreprendre une reconversion professionnelle signifie changer à la fois de métier
                        et d’environnement professionnel. C’est un changement important dans la vie
                        professionnelle d’un individu, une rupture dans sa trajectoire à la suite d’une prise
                        de conscience : soit d’un mauvais choix d’orientation (vous exercez un métier qui
                        ne vous satisfait pas), soit d’une impasse professionnelle (le métier que vous exercez
                        est voué à disparaître). Dans les deux cas, la reconversion vise à trouver un métier
                        épanouissant et porteur.
                    </Typography>
                </MarginContainer>
            </div>

            <div className={classes.marginContent}>
                <MarginContainer>
                    <Typography color="primary" className={classes.testimony}>
                        ENTREPRENDRE UNE RECONVERSION PROFESSIONNELLE <br />
                        SE FAIT GÉNÉRALEMENT EN 3 TEMPS :
                    </Typography>
                </MarginContainer>
            </div>

            <MarginContainer>
                <ParagrapthListNumber
                    list={[
                        {
                            text: `Vous préparez votre projet de reconversion, en définissant votre projet professionnel, en validant la formation, quasiment obligatoire, en recherchant et en mobilisant des modes de financement.`,
                            number: 1
                        },
                        {
                            text: `C’est la période de formation pouvant durer de quelques mois à plusieurs années, à temps plein ou suivie en parallèle de son activité professionnelle principale. C’est la période durant laquelle la personne redevient « étudiante ».`,
                            number: 2
                        },
                        {
                            text: `Vient le moment où vous accédez à votre nouvelle activité professionnelle.
                            C’est une période de recherche d’emploi, puis d’intégration dans le nouvel
                            environnement professionnel.`,
                            number: 3
                        }
                    ]}
                />
            </MarginContainer>

            <MarginContainer big={true}>
                {
                    text.map((item: string) => (
                        <MarginContainer>
                            <Paragrapth align='justify'>
                                {item}
                            </Paragrapth>
                        </MarginContainer>
                    ))
                }
            </MarginContainer>

        </Page>
    );
};

export default Page19;
