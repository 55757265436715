import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    childrenContainerOverride: {
      width: '100%',
    },
    bannerContainerOverride: {
      alignItems: 'flex-start',
      margin: 'unset',
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    bannerStyleOverride: {
      // width: '100%',
      // marginLeft: 30,
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
    img: {
      marginLeft: '20px',
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      marginTop: '-60px',
      marginRight: '-60px',
      float: 'right',
      '@media only screen and (max-width: 600px)': {
        float: 'none',
        marginTop: '10px',
        margin: 'auto',
      },
      '@media only screen and (max-width: 361px)': {
        float: 'none',
        margin: 'auto',
      },
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    showMobile: {
      display: 'none',
      '@media only screen and (max-width: 600px)': {
        display: 'block',
        margin: 'auto',
        marginBottom: '30px',
        width: '75%',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'block',
        margin: 'auto',
        marginBottom: '20px',
        width: '80%',
      },
    },
  }),
);

export default useStyles;
