/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CardTest } from '@orientaction/components';
import { Grid, Theme, useMediaQuery } from '@material-ui/core';
import { FC } from 'react';
import useStyle from './style';

interface IFirstEtage {
  listFirstEtape: any[];
}

const FirstEtage: FC<IFirstEtage> = ({ listFirstEtape }) => {
  const classe = useStyle();

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const hasPriority = !!listFirstEtape.filter((item: any) => item.priority === 1).length;

  return (
    <>
      {hasPriority && (
        <>
          <Grid container={true} spacing={1}>
            <Grid item={true} xl={4} lg={4} md={4} sm={6} xs={12}>
              <div className={classe.containerCard}>
                <div className={classe.isFavorite}>
                  <CardTest
                    status="actif"
                    key={listFirstEtape[0].id}
                    id={listFirstEtape[0].id}
                    isArchived={false}
                    isDesabled={false}
                    title={listFirstEtape[0].title}
                    description={listFirstEtape[0].description}
                    image={listFirstEtape[0].image}
                    time={listFirstEtape[0].time}
                    isFavorite={true}
                    category={listFirstEtape[0].category}
                    testId={listFirstEtape[0].id}
                  />
                </div>
              </div>
            </Grid>

            <Grid item={true} xl={8} lg={8} md={8} sm={6} xs={12}>
              <Grid container={true} spacing={1}>
                {listFirstEtape.slice(1, isSm ? 4 : 9).map((item: any) => (
                  <Grid item={true} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <CardTest
                      status="actif"
                      key={item.id}
                      id={item.id}
                      isArchived={false}
                      isDesabled={false}
                      title={item.title}
                      description={item.description}
                      image={item.image}
                      time={item.time}
                      isFavorite={false}
                      category={item.category}
                      testId={item.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {isSm && (
            <>
              <Grid container={true} spacing={1} className={classe.marginTop}>
                {listFirstEtape.slice(5, 9).map((item: any) => (
                  <Grid item={true} xl={6} lg={6} md={6} sm={6} xs={12}>
                    <CardTest
                      status="actif"
                      key={item.id}
                      id={item.id}
                      isArchived={false}
                      isDesabled={false}
                      title={item.title}
                      description={item.description}
                      image={item.image}
                      time={item.time}
                      isFavorite={false}
                      category={item.category}
                      testId={item.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FirstEtage;
