import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    imageBtn: {
      width: '90%',
      height: 'auto',
      display: 'block',
      marginTop: 45,
    },
    displayFlexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    titleHeader: {
      fontSize: 45,
      color: 'white',
      textTransform: 'none',
      '@media only screen and (max-width: 600px)': {
        padding: '40px 0px 0px 0px',
        textAlign: 'center',
        fontSize: 35,
      },
      '@media only screen and (max-width: 361px)': {
        padding: '40px 0px 0px 0px',
        textAlign: 'center',
        fontSize: 35,
      },
    },
    header: {
      background: "transparent linear-gradient(106deg, #000A8C 0%, #E63C3C 47%, #FFDC37 100%) 0% 0% no-repeat padding-box",
    },
    imageCenterMobile: {
      '@media only screen and (max-width: 600px)': {
        display: 'flex',
        justifyContent: 'center',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
  })
);
