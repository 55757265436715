import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  drawerContent: {
    width: '50%',
    borderTop: '2px solid #23296F',
    borderRadius: 16,
  },
  closeBtn: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: '0.001rem',
    top: '0.001rem',
    color: '#fff',
  },
  contentChild: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
  },
  title: {
    lineHeight: '30px',
    color: '#fff',
    textTransform: 'none',
    marginBottom: 20,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '2px solid #23296F',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  titleContainer: {
    marginTop: 25,
  },
}));

export default useStyles;
