import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { publicIconPath } from '@orientaction/utils';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import style from './style';

interface IProps {
  accordionDetailsComponent?: any;
  accordionSummaryComponent?: any;
  summaryText: string;
  detailsText: string;
  accordionStyleOverride?: string;
  index: number;
  handleDelete: any;
  handleEdit: any;
  codeColor?: string;
  minInterval?: number;
  maxInterval?: number;
  diagram?: any;
}

const OaAccordion: FC<IProps> = ({
  summaryText,
  detailsText,
  accordionSummaryComponent,
  accordionDetailsComponent,
  accordionStyleOverride,
  index,
  handleDelete,
  handleEdit,
  codeColor,
  minInterval,
  maxInterval,
  diagram,
}) => {
  const classes = style();

  const oaAccordionSummary = useMemo(() => {
    return (
      accordionSummaryComponent || (
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon style={{ color: '#000A8C' }} />}>
          <div className={classes.flexContainer}>
            <div className={classes.circle}>
              <span className={classes.number}>N°{`${index + 1}`}</span>
            </div>
            <div className={classes.summaryText}>
              <Typography className={classes.summaryTextH6} variant="h6">
                {ReactHtmlParser(summaryText)}
              </Typography>
            </div>
            <div>
              <IconButton className={classes.iconButton} onClick={handleEdit(index)}>
                <img src={`${publicIconPath}/oa_edit.svg`} alt="Edit Icon" />
              </IconButton>
              <IconButton className={classes.iconButton} onClick={handleDelete(index)}>
                <img src={`${publicIconPath}/oa_trash.svg`} alt="Custom Icon" />
              </IconButton>
            </div>
          </div>
        </AccordionSummary>
      )
    );
  }, [
    accordionSummaryComponent,
    classes.accordionSummary,
    classes.circle,
    classes.flexContainer,
    classes.iconButton,
    classes.number,
    classes.summaryText,
    classes.summaryTextH6,
    handleDelete,
    handleEdit,
    index,
    summaryText,
  ]);

  return (
    <div className={classNames(classes.root, accordionStyleOverride)}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        {oaAccordionSummary}
        {diagram === 5 && (
          <div style={{ display: 'block', marginLeft: 5 }}>
            <Typography className={classes.variableNameLabel} component="span">
              Couleur personnalisée :{' '}
            </Typography>
            {codeColor && (
              <Typography className={classes.variableNameText} component="span">
                {codeColor}
              </Typography>
            )}
          </div>
        )}
        {diagram === 4 &&
          (minInterval !== null || minInterval !== undefined) &&
          (maxInterval !== null || maxInterval !== undefined) && (
            <div style={{ display: 'block', marginLeft: 5 }}>
              <Typography className={classes.variableNameLabel} component="span">
                Intervalle de déclenchement : entre{' '}
                <span className={classes.variableNameText}>{minInterval}</span> et{' '}
                <span className={classes.variableNameText}>{maxInterval}</span>
              </Typography>
            </div>
          )}
        <AccordionDetails className={classes.accordionDetails}>
          {accordionDetailsComponent() ?? ''}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default OaAccordion;
