/* eslint-disable no-dupe-keys */
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    grab: {
      cursor: 'move',
    },
    modalContent: {
      fontSize: 14,
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
    modalText: {
      fontSize: 14,
      fontFamily: 'Poppins',
      fontWeight: 500,
    },
  })
);

export default useStyles;
