import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: '2.5vh 2vh',
      borderRadius: '8px',
      backgroundColor: 'white',
      [theme.breakpoints.down(800)]: {
        marginBottom: '5%',
      },
    },
    resultDetailsTitle: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      color: '#0E1247',
      margin: 0,
      padding: 0,
      [theme.breakpoints.down(800)]: {
        margin: '2vh 1.5vh',
        fontSize: '16px',
      },
    },
    resultDetailsContentElement: {
      // maxWidth: '769px',
    },
    resultDetailsParagraph: {
      margin: '1vh 0',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '170%',
      color: '#0E1247',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 1.5vh',
        fontSize: '16px',
        lineHeight: '150%',
      },
    },
    marginFistViewTop: {
      marginTop: 40,
      [theme.breakpoints.down(800)]: {
        marginTop: 20,
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '18.5px',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      padding: '25px',
    },
    contentNoPadding: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      height: '100%',
    },
    padding25: {
      padding: '25px',
      '@media only screen and (max-width: 600px)': {
        padding: '25px 20px',
      },
      '@media only screen and (max-width: 361px)': {
        padding: '25px 20px',
      },
    },
    content2: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      height: '100%',
    },
    whenEqual: {
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#0E1247',
      marginTop: '6vh',
      [theme.breakpoints.down(800)]: {
        marginTop: '3vh',
      },
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '498px',
      margin: '0 auto',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 0',
        lineHeight: '150%',
        fontSize: '16px',
        width: '95%',
      },
    },
    description2: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      color: '#0E1247',
    },
    imgPiramide: {
      width: '100%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
    podium: {
      width: '100%',
      height: '100%',
      margin: '0 auto',
      marginTop: 40,
      '@media only screen and (max-width: 600px)': {
        '& canvas': {
          height: '600px !important',
        },
      },
      '@media only screen and (max-width: 361px)': {
        '& canvas': {
          height: '600px !important',
        },
      },
    },
    flexContainer: {
      display: 'flex',
      gap: 5,
      flexWrap: 'wrap',
      marginTop: 15,
      marginBottom: 15,
    },
    btnCard: {
      borderRadius: 50,
      display: 'block',
      margin: '0px',
      textTransform: 'none',
      padding: '10px 20px',
      marginBottom: 0,
      '@media only screen and (max-width: 600px)': {
        marginTop: 20,
        marginBottom: 0,
      },
      '@media only screen and (max-width: 361px)': {
        marginTop: 20,
        marginBottom: 0,
      },
    },
  })
);
