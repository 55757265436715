// eslint-disable-next-line simple-import-sort/imports
import Card from '@material-ui/core/Card';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import useStyles from './style';
import { FC } from 'react';
import { useBook1 } from '@orientaction/hooks_book';
import { cloneDeep, debounce } from 'lodash';

interface IlistAsq {
  label: string;
  name: string;
  value: string;
  isTextArea?: boolean;
}

interface Ilist {
  listAsq: IlistAsq[];
  title: string;
}
interface IArrayAskAnswer {
  list: Ilist[];
  readOnly?: boolean;
}

let typeTimeout: any;

const ArrayAskAnswer: FC<IArrayAskAnswer> = ({ list, readOnly }) => {
  const classes = useStyles();
  const { book, updateObjectifSupreme } = useBook1();
  const updateValue = debounce((e, i) => {
    handleChangeGlobalState(e, i);
  }, 1000);
  const onKeyDown = (event: any, index: number) => {
    if (readOnly) return;
    updateValue(event, index)
  };

  const handleChange = (e: any, index: number) => {
    if (readOnly) return;
    updateValue(e, index)
  };

  const handleChangeGlobalState = (e: any, index: number) => {
    if (readOnly) return;
    const valueProtected = cloneDeep(book.objectifSupreme);
    valueProtected[index] = e.target.value;
    updateObjectifSupreme(valueProtected);
  };

  return (
    <Card
      variant="outlined"
      style={{
        borderRadius: '10px',
      }}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <div className={classes.borderTable}>
            {list.map((item: Ilist) => (
              <>
                <div className={classes.cardBgGrey}>
                  <Typography component="p" className={classes.h7} align="center">
                    {item.title}
                  </Typography>
                </div>

                {item.listAsq.map((soousItem: IlistAsq, index: number) => (
                  <>
                    <Grid
                      container={true}
                      style={{ borderTop: '1px solid black' }}
                      justifyContent="flex-end">
                      <Grid
                        item={true}
                        xs={12}
                        md={4}
                        className={classes.borderNone}
                        style={{ textAlign: 'center' }}>
                        <Typography component="p" className={classes.h6}>
                          {soousItem.label}
                        </Typography>
                      </Grid>
                      <Grid
                        item={true}
                        xs={12}
                        md={8}
                        style={{ textAlign: 'center', overflow: 'hidden' }}>
                        {soousItem.isTextArea ? (
                          <TextField
                            id="outlined-multiline-static"
                            multiline={true}
                            rows={2}
                            name={soousItem.value || ''}
                            variant="outlined"
                            className={classes.textArea}
                            placeholder="Aa"
                            onChange={e => handleChange(e, index)}
                            onKeyDown={e => onKeyDown(e, index)}
                            onBlur={e => handleChangeGlobalState(e, index)}
                            defaultValue={book.objectifSupreme[index]}
                            InputProps={{
                              readOnly: readOnly as boolean,
                            }}
                          />
                        ) : (
                          <Typography component="p" className={classes.h6}>
                            {soousItem.value}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </>
                ))}
              </>
            ))}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
export default ArrayAskAnswer;
