// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, TitlePage, Page, TitleChapitre } from '@orientaction/components';
import useStyles from './style';
import { MarginContainer } from '@orientaction/commons';
import Img from '../../Img';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page28 = (props: any) => {
  const classe = useStyles();

  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_chapitre5.png' : 'chapitre5.jpg';
  }, [language]);

  return (
    <Page id={28}>
      <TitleChapitre nbr={5} />

      <MarginContainer>
        <TitlePage>
          OPTION N°4 :<br />
          CRÉER / REPRENDRE UNE ENTREPRISE
        </TitlePage>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          On dit que c’est l’une des dernières grandes aventures modernes ! Avez-vous envie
          d’aventure ? On dit que cette aventure est risquée. Est-ce vraiment plus risqué que de
          travailler pour une organisation dont on ne maîtrise ni la stratégie, ni le volume des
          effectifs ?
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth align="justify">
          La création/reprise d’entreprise est de toutes les options possibles, celle qui prête le
          plus aux fantasmes, entre rêves et scénarios catastrophes. Soyez raisonnable et explorez
          calmement cette possibilité qui offre de réelles perspectives de développement personnel
          et professionnel.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Img src={image} alt="Chaque homme doit inventer son chemin." className={classe.img} />
      </MarginContainer>
    </Page>
  );
};
export default Page28;
