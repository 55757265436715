/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitlePage } from '@orientaction/components';
import { Grid, Typography } from '@material-ui/core';
import { BUSINESSCREATION, TAKEBACKTHEENTERPRISE } from './context';
import useStyles from './style';

const Page32 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={32}>

            <TitlePage>
                QUELLE EST LA DIFFÉRENCE ENTRE<br />
                CRÉATION ET REPRISE D’ENTREPRISE ?
            </TitlePage>

            <Grid
                container={true}
                alignItems="stretch"
                direction="row"
            >

                <Grid item={true} md={6} xs={12}>

                    <div className={classes.tableContainer}>

                        <div className={classes.head}>
                            CRÉATION D’ENTREPRISE
                        </div>

                        {
                            BUSINESSCREATION.map((item: string) => (

                                <div className={classes.bodyContent}>
                                    <MarginContainer>
                                        <Typography style={{ color: 'black' }}>
                                            {item || ""}
                                        </Typography>
                                    </MarginContainer>
                                </div>

                            ))
                        }

                    </div>

                </Grid>

                <Grid item={true} md={6} xs={12}>

                    <div className={classes.tableContainer}>

                        <div className={classes.head}>
                            REPRISE D’ENTREPRISE
                        </div>

                        {
                            TAKEBACKTHEENTERPRISE.map((item: string) => (

                                <div className={classes.bodyContent}>

                                    <MarginContainer>
                                        <Typography style={{ color: 'black' }}>
                                            {item || ""}
                                        </Typography>
                                    </MarginContainer>

                                </div>

                            ))
                        }

                    </div>

                </Grid>

            </Grid>
        </Page>
    );
};

export default Page32;
