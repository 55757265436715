import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  drawerContent: {
    width: '40%',
  },
  closeBtn: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: '0.001rem',
    top: '0.001rem',
    color: '#23296F',
  },
  contentChild: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
  },
  title: {
    lineHeight: '30px',
    color: '#23296F',
    textTransform: 'none',
    marginBottom: 20,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EDEEF2',
    borderBottom: '3px solid #23296F',
  },
  titleContainer: {
    marginTop: 25,
  },
});

export default useStyles;
