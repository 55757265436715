import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& input': {
        padding: '14px 14px 14px 14px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E3E8F0',
        borderRadius: 3,
      },
    },
    keyword: {
      marginRight: 20,
      width: 320,
      '& .MuiAutocomplete-inputRoot': {
        paddingTop: 4,
        paddingBottom: 5,
      },
    },
    filterValue: {
      marginRight: 20,
      width: 320,
    },
    btn: {
      letterSpacing: 0,
      opacity: 1,
      border: '1px solid',
      borderImage: 'linear-gradient(to top, #000A8C  50% , #E63C3C 0% , #FFDC27 100%) 1',
      borderRadius: 4,
      textTransform: 'none',
      height: 46,
      backgroundColor: '#FFFFFF',
      color: '#0E1247',
    },
    resultFilter: {
      display: 'flex',
      marginBottom: 15,
    },
    filters: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: 5,
    },
    chip: {
      margin: '10px',
      '&.MuiChip-root': {
        margin: [4, 0],
        color: '#000a8c',
        background: '#000A8C26 0% 0% no-repeat padding-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'baseline',
        '& .MuiSvgIcon-root': {
          color: '#000a8c !important',
        },
        '& .MuiSvgIcon-root:hover': {
          color: '#000A8C26 !important',
        },
      },
    },
  })
);

export default useStyles;
