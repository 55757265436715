/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, Title } from '@orientaction/components';

const Page20 = (props: any) => {
  return (
    <Page id={20} readOnly={props.readOnly}>
      <Title
        title="LE CAPITAL SOCIAL"
        firstTitle="‘‘ Toute personne sur la planète peut être reliée à n’importe
        quelle autre, au travers d’un enchaînement d’au plus six
        poignées de mains. ’’"
        firtsecondTitle="Frigyes Karinthy"
        secondTitle="‘‘Créer des réseaux, c’est mettre les gens en relation.
        Et mettre les gens en relation c’est créer sa chance. ’’"
        secondSousTitle="Philippe Gabilliet"
      />

      <MarginContainer>
        <Paragrapth align="justify">
          Le capital social est constitué par notre réseau et notre aptitude à le mobiliser afin
          d’atteindre un objectif. Le capital social, tout comme le capital économique, renvoie à
          deux composantes : le réseau effectif que vous possédez et le réseau que vous êtes prêt(e)
          à mobiliser.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Peut-être avez-vous un(e) membre de votre famille, installé(e) à un bon poste dans une
          organisation (entreprise, association, administration)... mais allez-vous le(a) solliciter
          afin d’obtenir un stage pour l’un de vos enfants ou vous aider à trouver un emploi ?
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Le capital social fait appel à plusieurs compétences : l’aptitude à créer des relations
          avec les autres, l’aptitude à transformer ces relations en intérêts réciproques, la
          capacité à demander, à solliciter un service et à rendre des services en retour. Le
          capital social s’inscrit dans une vision utilitariste de l’autre.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Certaines personnes éprouvent de nombreux freins, que nous développerons par la suite, à
          user de leur réseau pour faire aboutir leurs projets, et ce, même si cela peut constituer
          un facteur déterminant de leur réussite, voire une condition indispensable.
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

export default Page20;
