const INITIAL_STATE: any = {
  id: 0,
  indoorWeather: null,
  toAttendToYourNeeds: {},
  toSpecifiedYourNeed: {},
  testPersonality: [],
  figure6: '',
  adjectifs: [],
  selectAdjectif: [],
  response1: {},
  response2: {},
  response3: {},
  response4: {},
  response5: {},
  response6: {},
  response7: {},
  response8: {},
  response9: {},
  response10: {},
  response11: {},
  response12: [],
  response13: [],
  response14: '',
  response15: '',
  dataColor1: [],
  dataColor11: [],
  dataColor2: [],
  dataColor21: [],
  expressionAB: [],
  personalQuality: [],
  quality: [],
  progressionAxe: [],
  progression: [],
  progressionText: [],
  rolePrefere: [],
  rolePossible: [],
  roleAEviter: [],
  verbes: [],
  responseCondition1: {},
  responseCondition2: {},
  responseCondition3: {},
  responseCondition4: {},
  responseCondition5: {},
  responseCondition6: {},
  responseCondition7: {},
};

const stateBook2 = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'ID': {
      return { ...state, id: action.payload };
    }
    case 'INDOORWEATHER': {
      return { ...state, indoorWeather: action.payload };
    }
    case 'TOATTENDTOYOURNEEDS': {
      return { ...state, toAttendToYourNeeds: action.payload };
    }
    case 'TOSPECIFIEDYOURNEED': {
      return { ...state, toSpecifiedYourNeed: action.payload };
    }
    case 'TESTPERSONALITY': {
      return { ...state, testPersonality: action.payload };
    }
    case 'FIGURE6': {
      return { ...state, figure6: action.payload };
    }
    case 'ADJECTIFS': {
      return { ...state, adjectifs: action.payload };
    }
    case 'SELECTADJECTIF': {
      return { ...state, selectAdjectif: action.payload };
    }
    case 'RESPONSE1': {
      return { ...state, response1: action.payload };
    }
    case 'RESPONSE2': {
      return { ...state, response2: action.payload };
    }
    case 'RESPONSE3': {
      return { ...state, response3: action.payload };
    }
    case 'RESPONSE4': {
      return { ...state, response4: action.payload };
    }
    case 'RESPONSE5': {
      return { ...state, response5: action.payload };
    }
    case 'RESPONSE6': {
      return { ...state, response6: action.payload };
    }
    case 'RESPONSE7': {
      return { ...state, response7: action.payload };
    }
    case 'RESPONSE8': {
      return { ...state, response8: action.payload };
    }
    case 'RESPONSE9': {
      return { ...state, response9: action.payload };
    }
    case 'RESPONSE10': {
      return { ...state, response10: action.payload };
    }
    case 'RESPONSE11': {
      return { ...state, response11: action.payload };
    }
    case 'RESPONSE12': {
      return { ...state, response12: action.payload };
    }
    case 'RESPONSE13': {
      return { ...state, response13: action.payload };
    }
    case 'RESPONSE14': {
      return { ...state, response14: action.payload };
    }
    case 'RESPONSE15': {
      return { ...state, response15: action.payload };
    }
    case 'DATACOLOR1': {
      return { ...state, dataColor1: action.payload };
    }
    case 'DATACOLOR11': {
      return { ...state, dataColor11: action.payload };
    }
    case 'DATACOLOR2': {
      return { ...state, dataColor2: action.payload };
    }
    case 'DATACOLOR21': {
      return { ...state, dataColor21: action.payload };
    }
    case 'EXPRESSIONAB': {
      return { ...state, expressionAB: action.payload };
    }
    case 'PERSONALQUALITY': {
      return { ...state, personalQuality: action.payload };
    }
    case 'QUALITY': {
      return { ...state, quality: action.payload };
    }
    case 'PROGRESSIONAXE': {
      return { ...state, progressionAxe: action.payload };
    }
    case 'PROGRESSION': {
      return { ...state, progression: action.payload };
    }
    case 'PROGRESSIONTEXT': {
      return { ...state, progressionText: action.payload };
    }
    case 'ROLEPREFERE': {
      return { ...state, rolePrefere: action.payload };
    }
    case 'ROLEPOSSIBLE': {
      return { ...state, rolePossible: action.payload };
    }
    case 'ROLEAEVITER': {
      return { ...state, roleAEviter: action.payload };
    }
    case 'VERBES': {
      return { ...state, verbes: action.payload };
    }
    case 'RESPONSECONDITION1': {
      return { ...state, responseCondition1: action.payload };
    }
    case 'RESPONSECONDITION2': {
      return { ...state, responseCondition2: action.payload };
    }
    case 'RESPONSECONDITION3': {
      return { ...state, responseCondition3: action.payload };
    }
    case 'RESPONSECONDITION4': {
      return { ...state, responseCondition4: action.payload };
    }
    case 'RESPONSECONDITION5': {
      return { ...state, responseCondition5: action.payload };
    }
    case 'RESPONSECONDITION6': {
      return { ...state, responseCondition6: action.payload };
    }
    case 'RESPONSECONDITION7': {
      return { ...state, responseCondition7: action.payload };
    }
    default:
      return state;
  }
};

export default stateBook2;
