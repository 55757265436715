/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/button-has-type */
/* eslint-disable prettier/prettier */
import { Grid, Typography } from '@material-ui/core';
import { gameVariablesTemplateActions } from '@orientaction/api-actions';
import { gameBookValues, gameVariables, gameVariableValues } from '@orientaction/reducers';
import { labelBlockToShow } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import EditorCitation from '../../EditorCitation';
import EditorImg from '../../EditorImg';
import style from '../style';
import { styleSelect } from '../styleSelect';
import EditorQuill from '@orientaction/components/EditorQuill/index';

const ModelVariable3 = (props: any) => {
  const classes = style();
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [position, setPosition] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const valuesBooksTemplate = useSelector(gameBookValues);
  const allVariables = useSelector(gameVariables);
  const valuesVariables = useSelector(gameVariableValues);

  const {
    onChoiceClick,
    indexVariable
  } = props;
  const [typeBloc, setTypeBloc] = useState<Array<string>>(['normal', 'normal']);
  const [restitution, setRestitution] = useState<any>({});
  const optionsValue = [
    {
      value: 'normal',
      label: 'Normal',
    },
    {
      value: 'citation',
      label: 'Citation',
    },
    {
      value: 'encyclopedia',
      label: 'Encyclopédie',
    },
  ];

  const handleFieldChange = (field: string, value: any) => {
    dispatch(gameVariablesTemplateActions.updateField(field, value));
  };

  const handleOpen = (event: any) => {
    onChoiceClick(true);
    event.preventDefault();
  };

  const handleFileUrlChange = (urlC: string) => {
    setUrl(urlC);
  };
  const handleFileChange = (fileC: any) => {
    handleFieldChange('files', fileC);
    //  setFile(fileC);
  };

  const handleDeleteFile = () => {
    setFile(null);
    setUrl('');
  };
  const handleChangeSelect = (selected: any, numberBlock: number) => {
    setTypeBloc((prevTypes) => {
      const newTypes = [...prevTypes];
      newTypes[numberBlock] = selected;
      return newTypes;
    });
  };

  useEffect(() => {
    if (allVariables) {
      console.log({valuesVariables, all: allVariables[indexVariable]})
      dispatch(gameVariablesTemplateActions.setAllValues(allVariables[indexVariable]));
    } 
  }, [indexVariable, allVariables])


  return (
    <div
      className={classes.container}
      onClick={(e) => e.stopPropagation()}
      key={indexVariable}
    >
      <Grid container={true} style={{ height: '100%' }}>
        <Grid
          item={true}
          sm={6}
          className={classes.blockContentParent}
          style={{ borderRight: '1px solid #E1E1E1' }}
        >
          <div className={classes.blockContent} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className={classes.blockType}>
              <Typography variant="h5" className={classes.title}>
                {labelBlockToShow[valuesVariables.contentForType1]}
              </Typography>
              <div className={classes.blockTypeInside}>
                <Typography variant="h5" className={classes.titleType}>
                  Type de bloc :
                </Typography>
                <Select
                  className={classes.select}
                  options={optionsValue}
                  value={optionsValue.find(
                    (option) => option.value === valuesVariables.contentForType1,
                  )}
                  onChange={(selected) => {
                    handleChangeSelect(selected?.value, 0);
                    handleFieldChange('contentForType1', selected?.value);
                  }}
                  styles={styleSelect}
                />
              </div>
            </div>
            <div className={classes.blockText} style={{ minHeight: 649 }}>
              {valuesVariables.contentForType1 === 'normal' && (
                <EditorQuill
                  isSmall={true}
                  styleEditor={null}
                  defaultValue={valuesVariables.p1 || ''}
                  onChange={(content) => {
                    dispatch(gameVariablesTemplateActions.updateField('p1', content))
                  }}
                />
              )}
              {valuesVariables.contentForType1 === 'citation' && (
                <EditorCitation
                  key={valuesVariables.id}
                  fieldUpdate="citationContent1"
                  fieldUpdateTwo="citationAuthor1"
                  defaultValue={valuesVariables.citationContent1}
                  defaultAutor={valuesVariables.citationAuthor1}
                  isVariable={true}
                />
              )}
              {valuesVariables.contentForType1 === 'encyclopedia' && (
                <EditorQuill
                  isSmall={true}
                  styleEditor={null}
                  defaultValue={valuesVariables.informationEncyclopedia1 || ''}
                  onChange={(content) => {
                    dispatch(gameVariablesTemplateActions.updateField('informationEncyclopedia1', content));
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid item={true} sm={6} className={classes.blockContentParent}>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <div className={classes.blockType}>
              <Typography variant="h5" className={classes.title}>
                {labelBlockToShow[valuesVariables.contentForType2]}
              </Typography>
              <div className={classes.blockTypeInside}>
                <Typography variant="h5" className={classes.titleType}>
                  Type de bloc :
                </Typography>
                <Select
                  className={classes.select}
                  options={optionsValue}
                  value={optionsValue.find(
                    (option) => option.value === valuesVariables.contentForType2,
                  )}
                  onChange={(selected) => {
                    if (selected) {
                      handleChangeSelect(selected.value, 1);
                      handleFieldChange('contentForType2', selected.value);
                    }
                  }}
                  styles={styleSelect}
                />
              </div>
            </div>
            <div className={classes.blockText2}>
              {valuesVariables.contentForType2 === 'normal' && (
                <EditorQuill
                  isSmall={false}
                  styleEditor={null}
                  defaultValue={valuesVariables.p2 || ''}
                  onChange={(content) => {
                    dispatch(gameVariablesTemplateActions.updateField('p2', content))
                  }}
                />
              )}
              {valuesVariables.contentForType2 === 'citation' && (
                <EditorCitation
                  fieldUpdate="citationContent2"
                  fieldUpdateTwo="citationAuthor2"
                  defaultValue={valuesVariables.citationContent2}
                  defaultAutor={valuesVariables.citationAuthor2}
                  isVariable={true}
                />
              )}
              {valuesVariables.contentForType2 === 'encyclopedia' && (
                <EditorQuill
                  isSmall={false}
                  styleEditor={null}
                  defaultValue={valuesVariables.informationEncyclopedia2 || ''}
                  onChange={(content) => {
                    dispatch(gameVariablesTemplateActions.updateField('informationEncyclopedia2', content));
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={classes.blockContent}
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Typography variant="h5" className={classes.title}>
              Choix
            </Typography>
            <div
              className="wrapperchoices"
              style={{ fontFamily: 'Poppins', fontSize: 12, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, height: '100%', backgroundColor: 'rgb(241, 241, 241)' }}
            >
              Pas de personnalisation disponible <br />
              (l'utisateur a un bouton qui lui permet de retourner à sa liste de jeux)
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModelVariable3;
