import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  gradientBG: {
    background: 'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 20%, #000A8C 100%) 0% 0% padding-box',
  },
  noPadding: {
    '&>div': {
      '@media(min-width: 1250px)': {
        padding: '0px !important',
      },
    },
  },
  layout: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    // maxWidth: 'calc(100% - 205px)',
    height: '100%',
    overflow: 'hidden',
    '&>div': {
      height: '100vh',
      padding: theme.spacing(0, 3),
      color: theme.palette.primary.main,
      overflow: 'auto',
      '@media(min-width: 1750px)': {
        padding: '56px 120px 28px 80px',
      },
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));
