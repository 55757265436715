/* eslint-disable no-use-before-define */
import { Grid, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import { publicBook3Path } from '@orientaction/utils';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../Img';
import useStyles from './style';

const Page16 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={16}>
      <div className={classes.cardBgGrey}>
        <MarginContainer small={true}>
          <Grid container={true}>
            <Grid item={true} xs={12} md={12}>
              {/* <Img
                                src='hesitation_laurent.jpg'
                                alt='hesitation_laurent'
                                className={classes.title}
                            /> */}
              <div style={{ marginBottom: 80 }}>
                <SimpleBanner bannerStyleOverride={classes.bannerStyleOverride}>
                  <Typography
                    component="h1"
                    className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                  >
                    LES HÉSITATIONS DE LAURENT
                  </Typography>
                </SimpleBanner>
              </div>
            </Grid>
          </Grid>

          <div className={classes.content}>
            <div className={classes.contentIt}>
              <img
                src={`${publicBook3Path}/hesitation.jpg`}
                alt="hesitation"
                className={classes.img}
              />
            </div>

            <div className={classes.contentIt}>
              <MarginContainer small={true}>
                <Paragrapth align="justify" addBackground={true}>
                  Laurent est chef de projet informatique dans une compagnie d’assurance. Il juge
                  son travail peu épanouissant. Il faut dire qu’en 15 ans le service informatique
                  est passé de 10 à 450 collaborateurs. Cela a entraîné une industrialisation des
                  tâches.
                </Paragrapth>
              </MarginContainer>

              <MarginContainer small={true}>
                <Paragrapth align="justify" addBackground={true}>
                  On ne gère plus un projet de A à Z comme autrefois, seulement de A à B. Les
                  relations entre collègues sont devenues plus distantes et moins chaleureuses.
                  Laurent regrette cette ambiance conviviale d’autrefois. Par ailleurs, Laurent
                  travaille loin de son domicile.
                </Paragrapth>
              </MarginContainer>
            </div>
          </div>
        </MarginContainer>

        <div className={classes.simpleContent}>
          <MarginContainer small={true}>
            <Paragrapth align="justify" addBackground={true}>
              Il habite en province mais travaille à Paris. Il fait 1H30 de transport chaque matin
              et chaque soir. À l’issue de son bilan de compétences, il décide d’acheter une licence
              de taxi avant de renoncer. On lui a proposé une évolution en interne. Il compte sur ce
              changement pour retrouver une bouffée d’oxygène.
            </Paragrapth>
          </MarginContainer>

          <MarginContainer>
            <Paragrapth align="justify" addBackground={true}>
              Il est vrai que Laurent est la source principale de revenus de la famille. Il gagne
              près de 4000 euros net/mois. Quelques mois après, je revois Laurent dans le cadre d’un
              entretien de suivi. Laurent m’avoue que le changement n’a pas entraîné d’amélioration
              de sa situation. Au début, il a ressenti un vrai mieux, mais rapidement, le mal-être
              est revenu.
            </Paragrapth>
          </MarginContainer>
        </div>
      </div>
    </Page>
  );
};

export default Page16;
