import { ClickAwayListener, Grow, ListItemIcon, Paper, Popper } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import InputIcon from '@material-ui/icons/Input';
import style from './style';

const ProfileMenu = ({
  open,
  anchorRef,
  handleToggle,
  handleClose,
  goToProfileEdit,
  logout,
  user,
  switchRole,
}: any) => {
  const cls = style();
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition={true}
      disablePortal={true}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <List component="nav" aria-label="main mailbox folders">

                <ListItem button={true} onClick={goToProfileEdit}>
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon className={cls.colorBlue} />
                  </ListItemIcon>
                  <ListItemText primary="Mon profil" className={cls.colorBlue} />
                </ListItem>

                {user.hasMultipleRole && (
                  <ListItem button={true} onClick={switchRole}>
                    <ListItemIcon>
                      <AutorenewIcon className={cls.colorBlue} />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${user?.company_user_role?.id === 4 ? 'Bénéficiaire' : 'Consultant'
                        }`}
                      className={cls.colorBlue}
                    />
                  </ListItem>
                )}

                <ListItem
                  button={true}
                  onClick={logout}
                  data-cy="logout-btn"
                >
                  <ListItemIcon>
                    <InputIcon className={cls.colorBlue} />
                  </ListItemIcon>
                  <ListItemText primary="Déconnexion" className={cls.colorBlue} />
                </ListItem>
                
              </List>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default ProfileMenu;
