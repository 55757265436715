import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    mediaCard: {
        width: '100%',
        height: 'auto',
    },
    h7: {
        fontFamily: 'Poppins',
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '16px',
        color: '#0E1247',
        textAlign: 'center',
        display: 'inline-block',
        style: 'normal',
        alignItems: 'center',
        padding: 20,
    },
    h6: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '16px',
        color: '#0E1247',
        textAlign: 'center',
        display: 'inline-block',
        style: 'normal',
        alignItems: 'center',
        padding: 20,
    },
    categorieB: {
        color: '#fff !important',
        background: '#2c57a5',
        fontSize: '25px',
    },
    categorieA: {
        color: '#2c57a5',
        background: '#fff',
        fontSize: '25px',
    },
    texteTitle: {
        font: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '19.6px',
        color: '#9799B1',
        display: 'inline-block',
        style: 'normal'
    },
    textCard: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '19.6px',
        color: '#0E1247',
        style: 'normal'
    },
    cardBgGrey: {
        background: '#e8eef4'
    },
    borderTable: {
        borderRadius: '1px solid black',
    },
    textArea: {
        width: '99.6%',
        height: '99%',
        border: "1px solid #0E1247",
        "& div": {
            height: '100% !important',
        }
    },
    borderNone: {
        borderRight: '1px solid black',
        '@media only screen and (max-width: 600px)': {
            borderRight: 'none',
        },
        '@media only screen and (max-width: 361px)': {
            borderRight: 'none',
        },
    },
});