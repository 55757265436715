import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
     
        bodycenter: {
            alignItems: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        },
    })
);
