/* eslint-disable react/destructuring-assignment */
import ScienceTechnologySuggestion from './ScienceTechnologySuggestion';

const Suggestion = () => {
  return (
    <>
      <ScienceTechnologySuggestion />
    </>
  );
};

export default Suggestion;
