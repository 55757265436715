/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState } from 'react';
import { LayoutResultat } from '@orientaction/layouts';
import { columnsMesRessources } from '../../utils/constants';
import { Table } from '@orientaction/components';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { useAlert, useEbook } from "@orientaction/hooks";
import dateFormat from 'dateformat';
import useStyles from './style';
import { getFromLS, isAdmin } from '@orientaction/utils';
import { ContainerAdmin, MarginContainer } from '@orientaction/commons';

const AdminEbookUser = (props: any) => {

  const classes = useStyles();

  const history = useHistory();

  const param: any = useParams();

  const alert = useAlert();

  const [allBook, setAllBook] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(0);

  const { width } = props;

  const { getMyBookRequest, books } = useEbook({ mode: 'none', user: 'none' });

  const user = JSON.parse(getFromLS('userInfos') || '');

  const myBook = async (text: string, currentPage: number, data: string,) => {

    setLoading(true);

    try {

      const reponse = await getMyBookRequest(text, currentPage, data);

      const bookMaped: any = reponse?.data?.list
        .sort((a: any, b: any) => a.attributes.id - b.attributes.id)
        .map((bookTemplate: any) => {
          return [
            {
              value: bookTemplate.attributes.name,
            },
            {
              value: "Ebook",
            },
            {
              value: `${dateFormat(bookTemplate.attributes?.DateAttribuate, 'dd/mm/yyyy, HH:MM')}`,
            },
            {
              value: bookTemplate.attributes?.dateConsultation ? 'Lu' : 'Non lu',
            },
            {
              value: (
                <Button variant="outlined" onClick={updateDateConsultationBtn(bookTemplate)}>
                  Consulter
                </Button>
              ),
            },
            {
              value: `${bookTemplate.attributes?.dateConsultation ? dateFormat(new Date(bookTemplate.attributes?.dateConsultation), 'dd/mm/yyyy, HH:MM') : 'Pas encore consulté'}`,
            },
          ]
        });

      setAllBook(bookMaped);

    } catch (error) {
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    myBook(filter, page, param.id);
  }, [param.id, filter, page]);

  const handleSearch = (e: any) => {
    setFilter(e.target.value);
  };

  const updateDateConsultationBtn = (data: any) => (e: any) => {
    if (isAdmin(user)) {
      history.replace(`/${data.attributes.route}/${data.id}/readOnly`);
    } else {
      history.replace(`/${data.attributes.route}/${data.id}`);
    }
  }

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  }

  return (
    <LayoutResultat
      isWhite={true}
      showLogo={width === 'xs'}
      toBack="/admin/users-resultats"
    >

      <ContainerAdmin>

        <div className={classes.paddingY}>

          <Grid
            container={true}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item={true} xs={12} md={3}>
              <Typography variant="h2">
                Ressources{' '}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Paper component="form" className={classes.root}>
                <InputBase className={classes.input} placeholder="Chercher une ressource" onChange={handleSearch} />
                <IconButton type="submit" className={classes.iconButton} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
          </Grid>

          <Typography style={{ marginTop: '15px' }} className={classes.sousTitre}>
            <strong>Bilan de compétences</strong>
          </Typography>

          {loading ? (
            <div className={classes.bodycenter}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <MarginContainer>
                <Table
                  rowsPerPage={10}
                  load={loading}
                  thead={columnsMesRessources}
                  bodyData={allBook}
                  handleChangePage={handleChangePage}
                  currentPage={page}
                  count={allBook.length}
                />
              </MarginContainer>
            </>
          )}

        </div>

      </ContainerAdmin>

    </LayoutResultat>
  );
};

export default withWidth()(AdminEbookUser);
