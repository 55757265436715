import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(1),
      border: '1px solid #CDCEDA',
      borderRadius: '4px',
      padding: 0,
    },
    iconButton: {
      padding: 10,
    },
    btnPrimary: {
      borderWidth: '2.5px',
      borderStyle: 'solid',
      borderImage: 'linear-gradient(to top, #000A8C  50% , #E63C3C 0% , #FFDC27 100%) 1',
      width: '100%',
      color: '#0E1247',
      backgroundColor: 'white',
      overflow: 'hidden',
    },
    search: {
      width: '80%',
      margin: '0  auto',
      display: 'flex',
      alignItems: 'center',
    },
    btn: {
      color: 'white',
      letterSpacing: 0,
      opacity: 1,
      background: '#0E1247',
      borderRadius: 3,
      textTransform: 'none',
      height: 46,
    },
    searchButton: {
      marginLeft: 20,
    },
  }),
);

export default useStyles;
