/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { Page, TitlePage, TitleNbr } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import ArrayScore from '../../components/ArrayScore';
import { list, item } from './constant';
import List from './item';
import { useBook4 } from '@orientaction/hooks_book';

const Page34 = (props: any) => {

  const { softSkill, setSoftSkill } = useBook4();

  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setSoftSkill({
      ...softSkill,
      [name]: value
    });

  };

  return (
    <Page id={34} readOnly={props.readOnly}>
      <TitlePage>
        IDENTIFIEZ ICI VOS
        <br />« SOFT SKILLS » DOMINANTES
      </TitlePage>

      <MarginContainer>
        <TitleNbr
          number={1}
          text="Notez les scores obtenus au test dans ce tableau."
        />
      </MarginContainer>
      <ArrayScore
        body={list}
        current={softSkill}
        onChange={handleChange}
      />

      <MarginContainer>
        <TitleNbr
          number={2}
          text="Au-delà du test, choisissez les cinq « soft skills »
          qui vous caractérisent le mieux :"
        />
      </MarginContainer>

      <MarginContainer>
        <List />
      </MarginContainer>

    </Page>
  );
};

export default Page34;
