export const list1 = [
    {
        label1: 'INTRODUCTION',
        label2: 'SE PRÉPARER AU CHANGEMENT'
    },
    {
        label1: 'ÉTAPE 1 ',
        label2: 'CONSTRUIRE UN PROJET QUI A DU SENS'
    },
    {
        label1: 'ÉTAPE 2',
        label2: 'MIEUX CONNAÎTRE VOS BESOINS, VOS MOTIVATIONS ET VOTRE PERSONNALITÉ'
    },
]   

export const list2 = [
    {
        label1: 'ÉTAPE 4',
        label2: 'IDENTIFIER VOS COMPÉTENCES ET ÉVALUER VOTRE POTENTIEL'
    },
    {
        label1: 'ÉTAPE 5',
        label2: 'VALIDER VOTRE PROJET ET ÉLABORER VOTRE PLAN D’ACTION'
    },
]   