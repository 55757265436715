import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { publicVideoPath } from '../../../../utils/constants';
import AuthHeader from '../../Commons/AuthHeader';
import VideoText from '../../Commons/VideoText';

const Right = () => {
  const cls = style();
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <>
      <AuthHeader />
      <div className={cls.right}>
        <video
          poster="poster.JPG"
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          className={cls.video}>
          <source src={`${publicVideoPath}/orientaction.m4v`} type="video/mp4" />
        </video>
        <div className={cls.content} />
        <VideoText
          title={
            !isMobile
              ? "« Il y a trois choses extrêmement dures : l'acier, le diamant et se connaître soi-même » Benjamin Franklin"
              : ''
          }
          subtitle="Obtenez une description complète de qui vous êtes pour faire les bons choix dans la vie"
          items={[
            'Tests de personnalité gratuits ',
            'Retrouvez tous vos résultats',
            'Passez nos nouveaux tests',
            'Partagez nos tests avec vos proches',
          ]}
        />
      </div>
    </>
  );
};
const style = makeStyles((theme: Theme) => ({
  right: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    minHeight: 467,
  },
  videoContainer: {},
  video: {
    zIndex: -1,
    position: 'absolute',
    top: 0,
    right: '-80%',
    width: '233%',
    '@media(min-width: 600px) and (max-width: 874px)': {
      width: '165%',
      right: '-33%',
    },
    '@media(min-width: 875px) and (max-width: 1249px)': {
      width: '133%',
      right: '-27%',
    },
    '@media(min-width: 1250px) and (max-width: 1500px)': {
      width: '238%',
      right: '-85%',
    },
    '@media(min-width: 1500px)': {
      width: '180%',
      right: '-38%',
    },
  },
  content: {
    width: '100%',
    height: '100%',
    background: '#0E1247',
    opacity: 0.4,
    position: 'absolute',
    zIndex: -1,
  },
}));
export default Right;
