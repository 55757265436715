/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { publicImgMajorTest } from '@orientaction/utils';
import React from 'react';

import { useStyles } from './style';

interface HeaderPreferenceProps {
  isPrefJeune?: boolean;
}
const HeaderPreference: React.FC<HeaderPreferenceProps> = ({ isPrefJeune }) => {
  const classe = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <div className={classe.header}>
      <Grid container={true} justifyContent="center">
        <Grid item={true} md={6} className={classe.displayFlexAlignCenter}>
          {!isPrefJeune ? (
            <Typography variant="h2" className={classe.titleHeader}>
              Préférences®, <br />
              test des {isTab ? <br /> : ''} 32 personnalités
            </Typography>
          ) : (
            <Typography variant="h2" className={classe.titleHeader}>
              Préférences® Jeunes <br />
            </Typography>
          )}
        </Grid>
        <Grid item={true} md={3} className={classe.imageCenterMobile}>
          <img
            src={
              !isPrefJeune
                ? `${publicImgMajorTest}/banniere_pref.png`
                : `${publicImgMajorTest}/street_resize_button_square_jeune.png`
            }
            alt="couverture intro"
            className={classe.imageBtn}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default HeaderPreference;
