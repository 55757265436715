/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { MarginContainer } from '@orientaction/commons';
import { Page, TitlePage, ParagrapthListNumber, Title } from '@orientaction/components';
import Img from '../../Img';
import useStyles from './style';

const Page39 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={39}>

            <Title
                title="CHOIX N°5 : ET SI FINALEMENT VOUS DÉCIDIEZ DE NE PAS CHANGER ?"
                firstTitle=" ‘‘ Le plus heureux des hommes est celui qui désire le
                moins le changement de son état. ’’"
                firtsecondTitle="Marquise du Châtelet"
                secondTitle="‘‘ Quand il est nécessaire de changer, il est nécessaire de
                ne pas changer. ’’"
                secondSousTitle="Proverbe français"
            />

            <MarginContainer>
                <Img
                    src='plusieurChemin.jpg'
                    alt='ilPleut'
                    className={classes.img}
                />
            </MarginContainer>

            <MarginContainer>
                <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
                    Cette option est sans doute celle qui vous offre le plus de sécurité. Vous conservez
                    la sécurité chèrement acquise dans votre emploi ainsi que les avantages acquis au
                    cours de votre carrière chez votre employeur. Certes, vous restez dans un métier et un
                    environnement professionnel qui ne vous procurent pas ou peu de satisfaction, mais
                    en même temps, vous répondez à vos besoins de sécurité matérielle et financière.
                </Typography>
            </MarginContainer>

            <MarginContainer>
                <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
                    Cela vous permet d’assumer vos responsabilités. Pour vivre au mieux cette absence
                    de changement, deux solutions s’offrent à vous :
                </Typography>
            </MarginContainer>

            <MarginContainer>
                <ParagrapthListNumber
                    list={[
                        {
                            label: 'Investir d’autres domaines de vie',
                            text: `c’est la solution choisie par presque
                                    20% des personnes que nous accompagnons. Vous vous ennuyez au travail,
                                    vous éprouvez de la lassitude ou du désintérêt sans que cette lassitude ou
                                    ce désintérêt vous plonge dans un état de mal-être ? Peut-être qu’investir un
                                    autre domaine de vie (famille, couple, vie sociale, passion personnelle), vous
                                    permettra de retrouver le sourire et le plaisir de vivre au quotidien. Après tout, il
                                    n’y a pas que le travail dans la vie !`,
                            number: 1
                        },
                    ]}
                />

            </MarginContainer>

            <MarginContainer>
                <ParagrapthListNumber
                    list={[
                        {
                            label: 'Changer son regard sur son travail',
                            text: `investir un autre domaine de vie vous aidera
                                    à changer votre regard sur votre travail. Être heureux(se) au travail, c’est aussi
                                    un choix. Vous pouvez choisir d’être attentif(ve) aux bons moments, aux missions
                                    satisfaisantes et éluder les autres moments qui vous déplaisent. Parfois, nous avons
                                    tendance à trop regarder la bouteille à moitié vide plutôt que la bouteille à moitié
                                    pleine. En retrouvant de la satisfaction ailleurs, vous retrouverez de la satisfaction
                                    dans votre travail.`,
                            number: 2
                        },
                    ]}
                />

            </MarginContainer>
        </Page>
    );
};

export default Page39;
