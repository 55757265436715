import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Grid } from '@material-ui/core';
import { useBook4 } from '@orientaction/hooks_book';
import { FC } from 'react';
import useStyle from './style';
import { list, item } from './constant';


const SelectItem: FC = () => {

    const { softSkill, setSoftSkill } = useBook4();

    const handleChange = (e: any) => {

        const { name, value } = e.target;

        setSoftSkill({
            ...softSkill,
            myChoise: {
                ...softSkill?.myChoise,
                [name]: {
                    ...softSkill?.myChoise?.[name],
                    label: value
                }
            }
        });

    }

    const classe = useStyle();

    return (
        <>

            {item.map((oneItem: any, index: number) => (

                <div>

                    <Grid
                        container={true}
                        direction="row"
                        className={classe.margin}
                    >

                        <Grid item={true} xs={2} md={1}>
                            <span className={classe.span}>
                                <strong>{oneItem.number}</strong>
                            </span>
                        </Grid>

                        <Grid item={true} xs={10} md={11}>

                            <FormControl className={classe.formControl}>

                                <Select
                                    name={oneItem.number}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={softSkill?.myChoise?.[oneItem.number]?.label || ""}
                                    onChange={handleChange}
                                >
                                    {
                                        list.map((itemList: any) => (
                                            <MenuItem value={itemList.label}>{itemList.label}</MenuItem>
                                        ))
                                    }
                                </Select>

                            </FormControl>

                        </Grid>

                    </Grid>

                </div>

            ))}

        </>
    );
};

export default SelectItem;
