import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    contentLoader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
    timelineAlignLeft: {
      marginTop: 50,
      '& .MuiTimelineItem-missingOppositeContent:before': {
        flex: 0,
        content: '',
      },
      [theme.breakpoints.down(900)]: {
        marginTop: 10,
      },
    },
    colorWhite: {
      color: 'white',
    },
    btnHistory: {
      position: 'absolute',
      top: '90px',
      right: 0,
      [theme.breakpoints.down(800)]: {
        top: '80px',
      },
    },
    overrideTitleStyle: {
      fontSize: '42px !important',
      [theme.breakpoints.down(900)]: {
        fontSize: '22px !important',
      }
    },
    overrideSubtitleStyle: {
      marginTop: 10,
    },
    overrideChildrenStyle: {
      marginTop: '0px !important',
    },
    dotStyleOverride: {
      '& .MuiTimelineDot-root': {
        padding: '12px !important',
      },
    },
    timelineContentOverrideStyle: {
      '& .MuiTimelineSeparator-root': {
        marginLeft: 4,
      },
    },
    horizontalLineOverrideStyle: {
      top: '0.85rem !important',
      left: 45,
      borderBottom: '2px solid #fff',
      width: 40,
    },
    cardMarginBottomStyle: {
      marginBottom: '5vh !important',
    },
    lastCardMarginBottomStyle: {
      marginBottom: '-1vh !important',
    },
  })
);
