/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { FC } from 'react';
import { useStyles } from './style';

interface IFirstView {
  img: string;
  description: string;
}

const FirstView: FC<IFirstView> = ({ img, description }) => {
  const classe = useStyles();

  return (
    <div className={classe.root}>
      <MarginContainer mormal={true}>
        <h1 className={classe.title}>Vos résultats - Test des besoins professionnels</h1>
      </MarginContainer>

      <div className={classe.content}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={7} lg={6} xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img src={img} className={classe.imgPiramide} alt="Pyramide" />
            </div>
          </Grid>

          <Grid md={5} lg={6} xs={12}>
            <Typography className={classe.description}>{description}</Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FirstView;
