import { Grid } from '@material-ui/core';
import { imgBook4 } from '@orientaction/utils';
import { FC } from 'react';

interface IProps {
  filename?: string;
  alt?: string;
  style?: any;
  className?: any;
}

const Img: FC<IProps> = ({ filename = '', alt = 'image', style, className }) => {
  return (
    <>
      <Grid container={true} direction="row" justifyContent="center" alignItems="center">
        <img src={`${imgBook4}/${filename}`} alt={alt} className={className} style={style} />
      </Grid>
    </>
  );
};

export default Img;
