// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    bookChoiceContainer: {
      backgroundColor: '#EDEEF2',
      color: '#0E1247',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      marginTop: 10,
    },
    bookChoiceLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      color: '#0E1247',
      fontWeight: 700,
      fontSize: '16px',
      marginBottom: 10,
    },

    btn: {
      color: 'white',
      letterSpacing: 0,
      opacity: 1,
      background: '#0E1247',
      borderRadius: 3,
      textTransform: 'none',
      height: 46,
    },
    searchButton: {
      marginLeft: 20,
    },
  })
);
