import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  itemContainer: {
    marginTop: 15,
  },
  buttonContainer: {
    marginTop: 10,
  },
  button: {
    color: '#000a8c',
    border: '1px solid #000a8c',
    paddingLeft: 20,
    paddingRight: 20,
    textTransform: 'none',
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
    '@media only screen and (max-width: 391px)': {
      display: 'none',
    },
  },
  responsetitle: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700,
    color: '#23296F',
  },
  responsesContainer: {
    width: '100%',
  },
}));
