// eslint-disable-next-line no-use-before-define
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { Loading } from '@orientaction/commons';
import clsx from 'clsx';
import { FC } from 'react';
import { DialogContent, DialogTitle } from './Dialog';
import { useStyles } from './style';

interface ModalProps extends DialogProps {
  title?: string;
  textValidate?: string;
  noCancelButton?: boolean;
  textCancel?: string;
  onCancel: () => void;
  children?: any;
  className?: string;
  isLoading?: boolean;
  onConfirm?: () => void;
  type: string;
  loadingGetHierachy: boolean;
  hasSubordinate: boolean;
}

const ExtendedModal: FC<ModalProps> = ({
  title,
  className = '',
  textCancel = 'Annuler',
  textValidate = 'Supprimer',
  onCancel,
  open,
  children,
  isLoading = false,
  noCancelButton = false,
  type,
  onConfirm,
  loadingGetHierachy,
  hasSubordinate,
  ...modalProps
}) => {
  const classes = useStyles();

  const saveText = isLoading ? `${textValidate}...` : textValidate;

  return (
    <Dialog
      //  onClose={close}
      aria-labelledby="customized-dialog-title"
      open={open}
      {...modalProps}
      className={clsx(classes.modal, className)}>
      {loadingGetHierachy ? (
        <div className={classes.loading}>
          <Loading />
        </div>
      ) : (
        <>
          <DialogTitle id="customized-dialog-title" onClose={onCancel}>
            {title || ''}
          </DialogTitle>

          {children && !hasSubordinate && <DialogContent>{children}</DialogContent>}

          {hasSubordinate && (
            <DialogContent>
              <div className={classes.modalContent}>
                <Typography variant="h6" className={classes.modalText}>
                  Attention vous ne pouvez pas supprimer ce compte avant d’avoir réassigné tous les
                  utilisateurs qui lui sont liés
                </Typography>
              </div>
            </DialogContent>
          )}

          {onConfirm && (
            <div className={classes.containerBtn}>
              <Grid
                container={true}
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">
                {!noCancelButton && (
                  <Grid item={true}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      style={{ textTransform: 'none' }}
                      disabled={isLoading}>
                      {textCancel}
                    </Button>
                  </Grid>
                )}

                {!hasSubordinate && (
                  <Grid item={true}>
                    <Button
                      onClick={onConfirm}
                      style={{ textTransform: 'none' }}
                      className={classes.validate}
                      disabled={isLoading}
                      data-cy="btn-on-confirm-modal">
                      {saveText}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default ExtendedModal;
