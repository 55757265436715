import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '88%',
      margin: '0 auto',
      display: 'block',
      position: 'relative',
      [theme.breakpoints.down(800)]: {
        width: '90%',
      },
    },
    bodycenter: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnHistory: {
      position: 'fixed',
      top: '13vh',
      right: 25,
      zIndex: 20,
      [theme.breakpoints.down(800)]: {
        top: 'auto !important',
        bottom: '20px',
        position: 'fixed',
      },
    },
    resultTitle: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '20px',
      },
    },
    grid: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'row wrap',
      marginBottom: 80,
    },
    first: {
      width: '100%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
    titleText: {
      fontSize: 20,
      color: 'white',
      textAlign: 'center',
      marginBottom: 40,
    },
  })
);
