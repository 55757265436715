import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  quill: {
    '& > .ql-toolbar.ql-snow': {
      backgroundColor: '#CDCEDA !important',
    },
    '& > .ql-container.ql-snow': {
      backgroundColor: 'white !important',
    },
  },
  quillWhiteBackground: {
    '& > .ql-container': {
      '& > .ql-snow': {
        backgroundColor: 'white !important',
      },
    },
  },
  quillMultiline: {
    '& > .ql-container': {
      height: 200,
    },
  },
  error: {
    border: `2px solid ${theme.palette.error.main}`,
  },
}));

export default useStyles;
