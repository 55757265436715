/* eslint-disable no-return-await */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ApiActions } from '@orientaction/api-actions';
import { Switch } from '@orientaction/commons';
import { Modal, RadioGrp, TableContent } from '@orientaction/components';
import { useAdminUser, useAlert, useResulatOfTest, useStopRender } from '@orientaction/hooks';
import { MinorTestResults } from '@orientaction/pages';
import { MajorTest, Variable } from '@orientaction/services';
import {
  getApiUrlByTestId,
  getFromLS,
  getTestColumnsByTestId,
  isMajorTest,
  minorTestBaseColumns,
} from '@orientaction/utils';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';
import {
  colorPreferencePersonality,
  standartCoulomsPreferencePersonality,
} from '../Coulom/PreferencePersonality';
import { colorMeanValue, standartCoulomrMeanValue } from '../Coulom/meaning-value';
import { colorNeed, standartCoulomsNeed } from '../Coulom/need';
import { colorNeedPro, standartCoulomsNeedPro } from '../Coulom/need-pro';
import { colorSoftSkill, standartCoulomrSoftSkill } from '../Coulom/softSkill';
import { studyStandardColumns, studyTableHeaderBgColor } from '../Coulom/study';
import style from './style';

const majorTestIds = [1, 2, 3, 4, 5, 6, 7];
interface IResultArray {
  company?: any;
  typeTestChecked?: any;
}

const Result: FC<IResultArray> = ({ company, typeTestChecked }) => {
  const { currentPagination, setCurrentPagination } = useAdminUser();

  // local states
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState<any>({});
  const [inputAutocomplete, setInputAutocomplete] = useState<string>('');
  const [filterValue, setfilterValue] = useState<any>('');
  const [operator, setOperator] = useState<any>({
    value: null,
    error: '',
  });
  const [autocompleOptions, setAutocompleteOptions] = useState([]);

  // constants
  const token = getFromLS('userToken') || '';

  // hooks
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar({
    style: {
      backgroundColor: '#f44336',
    },
  });

  // redux states
  const testFilters: any = useSelector((state: any) => state?.api?.testFilters);
  const testSelected: any = useSelector((state: any) => state?.api?.testSelected);
  const userStatusFilters = useSelector((state: any) => state?.api?.userTestFilters);
  const refetch = useSelector((state: any) => state?.api?.refetch);

  const changeInputAutocomplete = (value: string) => {
    setInputAutocomplete(value);
  };
  const classes = style();
  const [open, setOpen] = useState(false);
  const [idDelete, setIdDelete] = useState(0);

  const handleChangePage = (event: any, newPage: number) => {
    setCurrentPagination(newPage);
  };

  const { openSimpleAlert } = useAlert();

  const { setRef, ref } = useStopRender();

  const {
    count,
    listResult,
    getResultTest,
    setCount,
    setListOfResult,
    deleteResultOfTest,
  } = useResulatOfTest(company);

  const isTestMajor = useMemo(() => isMajorTest(testSelected.id), [testSelected.id]);

  const getTheTest = async (id: number, pageCurrent: number) => {
    setLoading(true);
    setCount(0);

    try {
      await getResultTest({
        idTest: id as number,
        page: pageCurrent as number,
      });
    } catch (error) {
      openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const onDelete = (proos: any) => {
    setIdDelete(proos.id);
    setOpen(true);
  };

  const onConfirm = async () => {
    setLoading(true);
    try {
      await deleteResultOfTest({
        idTest: typeTestChecked,
        idTemplate: idDelete,
      });
      const removeIt = listResult.filter((x: any) => x.id !== idDelete);
      setListOfResult(removeIt);
      setIdDelete(0);
      setOpen(false);
    } catch (error) {
      openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  // Delete element filter from allFilter
  const deleteFilter = async (filter: any, index: number) => {
    dispatch(ApiActions.removeTestFilters({ column: filter.column, index }));
    dispatch(ApiActions.setRefetch(true));
  };

  const debouncedUpdateUserActiveTestFilter = useRef(
    debounce(async () => {
      dispatch(ApiActions.updateUserActiveTestFilters());
      dispatch(ApiActions.setRefetch(true));
    }, 300),
  ).current;

  const debouncedUpdateUserRattachedTestFilter = useRef(
    debounce(async () => {
      dispatch(ApiActions.updateUserRattachedTestFilters());
      dispatch(ApiActions.setRefetch(true));
    }, 300),
  ).current;

  const getMinorTestColumns = async (testId: string) => {
    // common basic columns for all minor tests
    const baseColumns = minorTestBaseColumns;

    // fetch dynamic columns (variables)
    const vars = await Variable.getVariableFilterByTest(token, testId);
    const dynamicColumns = vars?.data?.data.map((variable: any) => {
      return {
        id: variable.id,
        key: `variable_${variable.id}`,
        columnName: variable.attributes.name,
        label: variable.attributes.name,
        type: 'number',
        entity: 'variables',
      };
    });
    const allColumns = baseColumns.concat(dynamicColumns);
    return allColumns;
  };

  // Handle change key for the second filter
  const changeKey = (event: any, newValue: any) => {
    setKeyword(newValue);
  };

  // Handle change operator for the second filter
  const changeOperator = (value: string) => {
    // change test filter operator start
    setOperator(value);
  };

  const changeTestFilterValue = (e: any) => {
    changeFilterValue(e.target.value);
  };

  const changeFilterValue = (value: string) => {
    setfilterValue(value);
  };

  const fetchMajorTestByFilter = async () => {
    setLoading(true);
    try {
      const apiUrl = await getApiUrlByTestId(testSelected.id);
      const { data }: any = await MajorTest.getMajorTestResults(
        company,
        apiUrl,
        token,
        testFilters,
        userStatusFilters,
        currentPagination,
      );
      console.log('data.list', data.list);
      setListOfResult(data.list);
      setCount(data?.count);
    } catch (error) {
      openSnackbar(
        'Le filtre que vous avez sélectionné ne fonctionne pas avec les caractères que vous avez entrés.',
      );
    } finally {
      setLoading(false);
      dispatch(ApiActions.setRefetch(false));
    }
  };

  const fetchMinorTestVariables = async () => {
    try {
      const minorTestAllColumns: any = await getMinorTestColumns(typeTestChecked);
      setAutocompleteOptions(minorTestAllColumns);
    } catch (error) {
      openSimpleAlert();
    }
  };

  const addFilters = () => {
    if (keyword && operator && filterValue) {
      // push filters into redux store
      if (isTestMajor) {
        dispatch(
          ApiActions.setTestFilters({
            key: keyword.label,
            column: keyword.columnName,
            type: keyword.type,
            operator,
            value: filterValue,
            entity: keyword.entity,
          }),
        );
      } else {
        dispatch(
          ApiActions.setTestFilters({
            key: keyword.label,
            column: keyword.columnName,
            type: keyword.type,
            operator,
            value: filterValue,
            entity: keyword.entity,
            variableAlias: keyword.key,
            id: keyword.id,
          }),
        );
      }

      dispatch(ApiActions.setRefetch(true));

      // clean fields
      setKeyword('');
      setOperator('');
      setfilterValue('');
    }
  };

  /**
   * START effects
   */

  useEffect(() => {
    if (
      typeTestChecked &&
      (typeTestChecked !== ref?.typeTestChecked || currentPagination !== ref?.page) &&
      majorTestIds.includes(typeTestChecked)
    ) {
      setListOfResult([]);

      let currPage = currentPagination;

      if (typeTestChecked !== ref?.typeTestChecked) {
        setCurrentPagination(0);
        currPage = 0;
      }

      setRef({
        typeTestChecked: typeTestChecked as any,
        page: currPage as any,
      });

      getTheTest(typeTestChecked, currentPagination);
    }
  }, [typeTestChecked, ref, currentPagination]);

  useEffect(() => {
    if (typeTestChecked && !isTestMajor) {
      // minor test: fetch variables in order to construct dynamic columns for autocomplete options
      fetchMinorTestVariables();
    }
    if (typeTestChecked && isTestMajor) {
      // get columns and set autocomplete options
      const majorTestAllColumns = getTestColumnsByTestId(testSelected.id);
      setAutocompleteOptions(majorTestAllColumns);
    }
  }, [typeTestChecked]);

  // call on filter's change in ordrer to trigger api call to update results based on new filters
  useEffect(() => {
    if (isTestMajor && refetch) {
      fetchMajorTestByFilter();
    }
  }, [refetch, testFilters]);

  /**
   * END of effects
   */

  return (
    <>
      <div className={classes.resultFilter}>
        {!loading && !!typeTestChecked && !!autocompleOptions.length && (
          <>
            <Autocomplete
              options={autocompleOptions}
              getOptionLabel={(option: any) => option.label.toUpperCase()}
              getOptionSelected={(option, value) => option?.label === value?.label}
              noOptionsText="Pas de mots clé disponible"
              onChange={changeKey}
              className={clsx(classes.keyword)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Saisissez un mot clé ..."
                  className={clsx(classes.input)}
                  variant="outlined"
                />
              )}
              inputValue={keyword?.label?.toUpperCase() || inputAutocomplete?.toUpperCase() || ''}
              onInputChange={(e: any) => changeInputAutocomplete(e?.target?.value || '')}
            />
            <RadioGrp
              defaultValue={operator}
              radios={[
                {
                  label: '=',
                  value: '=',
                },
                {
                  label: '≠',
                  value: '≠',
                },
                {
                  label: '>',
                  value: '>',
                },
                {
                  label: '<',
                  value: '<',
                },
              ]}
              onChange={changeOperator}
              name="operator"
            />
            <TextField
              id="outlined-start-adornment"
              placeholder="Saisissez une valeur"
              value={filterValue || ''}
              variant="outlined"
              className={clsx(classes.input, classes.filterValue)}
              onChange={changeTestFilterValue}
            />
            <Button variant="contained" className={classes.btn} onClick={addFilters}>
              Filtrer
            </Button>
          </>
        )}
      </div>
      <div className={classes.filters}>
        {testFilters.map((filter: any, index: number) => (
          <Chip
            key={Math.random()}
            className={classes.chip}
            color="primary"
            label={`${filter?.key?.toUpperCase()} ${filter?.operator} ${filter?.value}`}
            clickable={true}
            onDelete={() => deleteFilter(filter, index)}
          />
        ))}
      </div>

      <div>
        <Switch
          checked={userStatusFilters.isActive}
          onChange={debouncedUpdateUserActiveTestFilter}
          label="Utilisateurs actifs"
        />
        {company === 1 ? (
          <Switch
            checked={userStatusFilters.isRattached}
            onChange={debouncedUpdateUserRattachedTestFilter}
            label="Utilisateurs rattachés"
          />
        ) : null}
      </div>

      {typeTestChecked === 1 && (
        <TableContent
          loading={loading}
          bgColor={colorMeanValue}
          array={listResult}
          coulom={standartCoulomrMeanValue}
          onDelete={onDelete}
          pagination={{
            count: count || 0,
            onChangePage: handleChangePage as any,
            page: currentPagination as number,
            rowsPerPage: 10,
          }}
        />
      )}

      {typeTestChecked === 2 && (
        <TableContent
          loading={loading}
          bgColor={colorNeed}
          array={listResult}
          coulom={standartCoulomsNeed}
          onDelete={onDelete}
          pagination={{
            count: count || 0,
            onChangePage: handleChangePage as any,
            page: currentPagination as number,
            rowsPerPage: 10,
          }}
        />
      )}

      {typeTestChecked === 3 && (
        <TableContent
          loading={loading}
          bgColor={colorNeedPro}
          array={listResult}
          coulom={standartCoulomsNeedPro}
          onDelete={onDelete}
          pagination={{
            count: count || 0,
            onChangePage: handleChangePage as any,
            page: currentPagination as number,
            rowsPerPage: 10,
          }}
        />
      )}

      {typeTestChecked === 4 && (
        <TableContent
          loading={loading}
          bgColor={colorPreferencePersonality}
          array={listResult}
          coulom={standartCoulomsPreferencePersonality}
          onDelete={onDelete}
          pagination={{
            count: count || 0,
            onChangePage: handleChangePage as any,
            page: currentPagination as number,
            rowsPerPage: 10,
          }}
        />
      )}

      {typeTestChecked === 5 && (
        <TableContent
          loading={loading}
          bgColor={colorSoftSkill}
          array={listResult}
          coulom={standartCoulomrSoftSkill}
          onDelete={onDelete}
          pagination={{
            count: count || 0,
            onChangePage: handleChangePage as any,
            page: currentPagination as number,
            rowsPerPage: 10,
          }}
        />
      )}

      {typeTestChecked === 6 && (
        <>
          <TableContent
            loading={loading}
            bgColor={studyTableHeaderBgColor}
            array={listResult}
            coulom={studyStandardColumns}
            onDelete={onDelete}
            pagination={{
              count: count || 0,
              onChangePage: handleChangePage as any,
              page: currentPagination as number,
              rowsPerPage: 10,
            }}
          />
        </>
      )}
      {typeTestChecked === 7 && (
        <TableContent
          loading={loading}
          bgColor={colorPreferencePersonality}
          array={listResult}
          coulom={standartCoulomsPreferencePersonality}
          onDelete={onDelete}
          pagination={{
            count: count || 0,
            onChangePage: handleChangePage as any,
            page: currentPagination as number,
            rowsPerPage: 10,
          }}
        />
      )}
      {!!typeTestChecked && !isTestMajor && (
        <MinorTestResults testId={typeTestChecked} company={company} />
      )}
      <Modal
        onConfirm={onConfirm}
        onCancel={onCancel}
        isLoading={loading}
        title="Voulez-vous supprimer cette ligne?"
        open={open}
      />
    </>
  );
};

export default Result;
