export const context = [
    {
        title: 'CRÉER UNE MICRO-ENTREPRISE',
        id: 1,
        text: `Grâce au régime de la micro-entreprise, vous pouvez aujourd’hui créer n’importe
                quelle activité complémentaire. Voici trois exemples de personnes que nous avons
                accompagnées avec leurs projets respectifs :`,
        listContext: [
            {
                id: 1,
                label: `Sonia, cadre dans les assurances a profité d’un contrat de 32 heures`,
                text: ` pour créer une activité de bien-être en parallèle de son métier. Trois soirs par
                semaine entre 17h et 21h, elle reçoit des patient(e)s stressé(e)s. Cela lui fait
                un complément de revenu et en même temps donne du sens à sa vie. 
                <div style='margin:0.75vh 0 0 0'/>
                    Elle soigne et guérit : quelle récompense !
                </div>`
            },
            {
                id: 2,
                label: `Yohann, animateur socio-culturel a créé une micro-entreprise de e-commerce `,
                text: ` sur internet. Il achète et vend des DVD sur des boutiques internet. Cela
                lui permet de se faire un complément de salaire et de s’éclater dans
                l’activité pour laquelle il est réellement fait : le commerce.`
            },
            {
                id: 3,
                label: `Robert, professeur de mathématiques`,
                text: ` en lycée a créé sa micro-entreprise
                spécialisée dans le développement d’applications pour smartphones. Il a
                ainsi créé des cours en ligne, des calculatrices en ligne. 
                    <div style='margin:0.75vh 0 0 0'/>
                        Il ne gagne pas beaucoup d’argent mais il est heureux de créer et de développer
                        des services utiles pour les lycéens(nes) et les étudiants(es).
                    </div>`
            }
        ]
    },
    {
        title: 'DEVENIR REPRÉSENTANT(E) DU PERSONNEL',
        id: 2,
        text: `Dans la vie, il n’y a pas que la création d’entreprise, voici donc d’autres idées.
                Être représentant(e) du personnel est une charge importante dans une organisation
                (entreprise, association, administration). Les représentant(e)s des salarié(e)s sont
                là pour discuter et négocier avec les représentant(e)s de la direction tout ce qui
                touche à la rémunération, l’organisation ou la santé au travail. Intégrer le CHSCT,
                s’occuper du CE, devenir délégué(e) du personnel, autant de missions qui peuvent vous permettre de vous réaliser et d’acquérir de nouvelles compétences qui pourront
                être utiles pour une évolution professionnelle ultérieure. Pensez-y.`,
        listContext: []
    }
]