import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import { AdminTestContext } from '@orientaction/Context';
import { useContext } from 'react';
import ResponseForm from '../Create/ResponseForm';
import style from './style';

const ResponseDrawer = () => {
  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const handleClose = () => {
    updateTest({ responseDrawerOpen: false, responseToEdit: undefined });
  };

  const label = test.responseToEdit ? 'Modifier' : 'Ajouter';

  return (
    <Drawer
      anchor="right"
      open={test.responseDrawerOpen}
      classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
      <div className={classes.headerContainer}>
        <IconButton className={classes.closeBtn} onClick={handleClose}>
          <ArrowIcon fontSize="small" />
        </IconButton>
        <div className={classes.titleContainer}>
          <Typography variant="h3" className={classes.title}>
            {`${label} une réponse`}
          </Typography>
        </div>
      </div>
      <div className={classes.contentChild}>
        <ResponseForm />
      </div>
    </Drawer>
  );
};

export default ResponseDrawer;
