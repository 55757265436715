/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2, imgBookIntro } from '../../../../utils/constants';

const Page19 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={19}>
      <div>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              color="primary"
              gutterBottom={true}
              className="opacity07">
              CHANGER, C’EST BON POUR LA SANTÉ !
            </Typography>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: '20px'
              }}>
              <strong>‘‘ Je vous prescris une évolution professionnelle ’’</strong>
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '40px', marginBottom: '40px' }} />
      <div>
        <div className={classes.textWithImageContainer}>
          <div className={classes.width30} />
          <div className={classes.width70}>
            <TitleNbr number={1} text="LE TRAVAIL, FACTEUR DE BONNE SANTÉ" />
          </div>
        </div>
        <div>
          <img className={classes.img} src={`${imgBookIntro}/hammer.png`} alt="logo" />
          <div style={{display: "block"}}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '10px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Le jour où un médecin prescrira ce type de remède n’est pas arrivé. Pourtant, de très
              nombreuses études le démontrent : avoir un métier épanouissant est un facteur de bonne
              santé. C’est moins de stress, donc une meilleure santé au quotidien.

            </Typography>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Bien choisir son métier, c’est augmenter ses chances d’être et de rester en bonne
              santé!
            </Typography>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Cela est d’autant plus vrai que certains métiers pénibles entraînent des maladies
              chroniques (maux de dos, troubles musculosquelettiques, maladies respiratoires ou
              allergies, etc.).
            </Typography>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Pour les personnes qui souffrent de ces maladies professionnelles (reconnues ou pas),
              une évolution professionnelle est la seule véritable solution pour aller mieux.
            </Typography>
          </div>
        </div>
        <br />
        <br />
        <TitleNbr number={2} text="LES EFFETS DU STRESS AU TRAVAIL" />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Les études médicales montrent que les personnes qui travaillent dans un environnement
              professionnel stressant ont plus de risques de développer des maladies
              cardio-vasculaires, de souffrir d’anxiété et de dépression.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Par ailleurs, le stress est la cause d’un grand nombre de maladies bénignes. En
              affaiblissant vos défenses immunitaires, il vous rend plus sensible aux maladies
              (rhume, grippe, etc.).
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Il est également l’une des causes des problèmes de dos et est en cause dans les
              troubles de la sexualité (impuissance, frigidité, etc.) qui minent la vie de couple. En d’autres termes, avoir un travail stressant peut avoir des
              répercussions extrêmement graves sur votre santé.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
      <div>
        <TitleNbr
          number={3}
          text="CHANGER D’ENVIRONNEMENT PROFESSIONNEL, LE MEILLEUR DES REMÈDES"
        />
        <Grid container={true} spacing={1} justifyContent="center">
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Le remède apporté par les médecins à la plupart des symptômes physiques et
              psychologiques est un médicament.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Or, une personne souffrant de stress lié à son travail ou de problèmes physiques liés
              à son environnement professionnel (comme dans les métiers pénibles) n’a pas d’autres
              solutions, si elle veut aller mieux, que de changer de travail.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Les médicaments ne traitent que les symptômes, rarement la cause de la maladie.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Certes, un traitement médicamenteux permettra d’éviter une aggravation des symptômes
              et de les limiter, mais il ne permettra pas à la personne de retrouver un état
              d’équilibre et de bien-être durable.
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <Typography
              className={classes.paragraphe}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                marginTop: '15px',
                textAlign: 'justify',
                fontWeight: 'lighter',
              }}>
              Voilà pourquoi il est important, si vous présentez l’un des symptômes décrits
              ci-dessus, d’entreprendre un travail sur vous pour évoluer sur le plan professionnel.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '30%',
      height: 'auto',
      display: 'block',
      marginRight: '20px',
      marginTop: '-50px',
      float: 'left',
      '@media only screen and (max-width: 600px)': {
        width: '80%',
        float: 'none',
        marginTop: 0,
      },
      '@media only screen and (max-width: 361px)': {
        width: '90%',
        float: 'none',
        marginTop: 0,
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4',
    },
    testimony: {
      fontSize: '17px',
    },
    textWithImageContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    width30: {
      width: 'calc(30% + 20px)',
      '@media only screen and (max-width: 600px)': {
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'none',
      },
    },
    width70: {
      width: 'calc(70% - 20px)',
      display: 'block',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
  })
);
export default Page19;
