const INITIAL_STATE: any = {
  current: {
    isView: false,
    isUpdate: false,
    company_user_role: 1,
    company: 1,
    service: null,
    services: [],
    tests: [],
    books: [],
    selectedService: null,
  },
  dialog: {
    open: false,
    title: ' ',
    paragraph: ' ',
    textBtn: ' ',
    type: ' ',
    id: ' ',
    isActivated: false,
  },
  listAdminPanel: [],
  listAdminUser: {},
  listUpHierachy: [],
  idCurrent: '',
  loadingList: false,
  openDrawer: false,
  company: 1,
  userTypeConnected: 1,
  tabs: 1,
  loading: false,
  readonly: true,
  viewDisable: true,
  loadingHierachy: false,
  countTabs: {},
  currentPagination: 0,
  textFilter: '',
  rattachement: true,
  activeSubmit: false,
  actived: true,
};

const adminUser = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'CURRENT': {
      return { ...state, current: action.payload };
    }
    case 'LISTADMINUSER': {
      return { ...state, listAdminUser: action.payload };
    }
    case 'IDCURRENT': {
      return { ...state, idCurrent: action.payload };
    }
    case 'LOADINGLIST': {
      return { ...state, loadingList: action.payload };
    }
    case 'OPENDRAWER': {
      return { ...state, openDrawer: action.payload };
    }
    case 'COMPANY': {
      return { ...state, company: action.payload };
    }
    case 'USERSTYPECONNECTED': {
      return { ...state, userTypeConnected: action.payload };
    }
    case 'DIALOG': {
      return { ...state, dialog: action.payload };
    }
    case 'TABS': {
      return { ...state, tabs: action.payload };
    }
    case 'ACTIVED': {
      return { ...state, actived: action.payload };
    }

    case 'LOADING': {
      return { ...state, loading: action.payload };
    }
    case 'LOADINGHIERACHY': {
      return { ...state, loadingHierachy: action.payload };
    }
    case 'READONLY': {
      return { ...state, readonly: action.payload };
    }
    case 'VIEWDISABLE': {
      return { ...state, viewDisable: action.payload };
    }
    case 'LISTADMINPANEL': {
      return { ...state, listAdminPanel: action.payload };
    }
    case 'COUNT_TABS': {
      return { ...state, countTabs: action.payload };
    }
    case 'PAGE': {
      return { ...state, currentPagination: action.payload };
    }
    case 'TEXT_FILTER': {
      return { ...state, textFilter: action.payload };
    }
    case 'LIST_UPHIERCHY': {
      return { ...state, listUpHierachy: action.payload };
    }
    case 'RATTACHEMENT': {
      return { ...state, rattachement: action.payload };
    }
    case 'ACTIVESUBMIT': {
      return { ...state, activeSubmit: action.payload };
    }
    default:
      return state;
  }
};

export default adminUser;
