// eslint-disable-next-line simple-import-sort/imports
import {
    Paragrapth,
    Page,
} from '@orientaction/components';
import useStyles from "../../style";


const Page12 = (props: any) => {

    const classe = useStyles();

    return (
        <Page id={12}>

            <div>
                <Paragrapth
                    align="justify"
                >

                    Dans notre vie, nous relevons d’incroyables défis.<br /><br />
                    Cela nous demande d’incroyables sacrifices, mais nous les réalisons car nous avons
                    besoin de réussir, de réussir dans la vie. Gravir les échelons, parvenir à un statut
                    reconnu, obtenir des lettres de noblesse, connaître la célébrité, etc.<br /><br />
                    Ces formes de réussite nous procurent un plaisir et une satisfaction parfois
                    extrêmement éphémères, mais elles n’en demeurent pas moins la réponse à deux
                    besoins essentiels de tout être humain : le besoin de reconnaissance et le besoin
                    d’éternité.

                </Paragrapth>

                <br />
                <br />

                <Paragrapth
                    align="justify"
                    bold={true}
                    colorBlue={true}
                >

                    Voulez-vous réussir DANS la vie ou réussir VOTRE vie ?

                </Paragrapth>

                <br />
                <br />

                <Paragrapth
                    align="justify"
                >

                    J’ai accompagné des dizaines de femmes et d’hommes qui occupaient des
                    fonctions de cadres dirigeant(e)s.<br /><br />
                    Ils(Elles) avaient étudié dans les plus grandes écoles et exercé les responsabilités
                    les plus élevées, mais à l’âge de cinquante ans ils(elles) ressentaient comme un
                    manque.<br /><br />
                    Ils(Elles) étaient fortuné(e)s, reconnu(e)s et puissant(e)s. Ils(Elles) avaient tout
                    ce dont peut rêver une personne ambitieuse, mais pourtant ils(elles) étaient
                    malheureux(ses).<br /><br />
                    Leur réussite ne les nourrissait plus. La richesse, les honneurs et le pouvoir ne leur
                    apportaient plus de satisfaction. Ils(Elles) avaient besoin d’autre chose.
                    Ces femmes et ces hommes recherchaient plus de sens dans leur travail.<br /><br />
                    Ils(Elles) avaient besoin de se sentir réellement utiles.<br /><br />
                    Ils(Elles) avaient besoin d’un rapport plus simple et plus direct aux autres.<br /><br />
                    Ils(Elles) voulaient mieux respecter leur biorythme et leur santé en ne s’imposant
                    plus d’efforts excessifs.<br /><br />
                    Ils(Elles) avaient besoin de consacrer plus de temps à entretenir des relations
                    humaines de qualité.<br /><br />
                    Ils(Elles) avaient besoin d’un meilleur équilibre entre leur vie personnelle et leur vie
                    professionnelle.<br /><br />
                    Ils(Elles) voulaient se réaliser pleinement au travers d’un travail respectueux de leurs
                    valeurs.

                </Paragrapth>
                <br />
                <br />
                <br />
                <Paragrapth
                    align="center"
                    bold={true}
                    colorBlue={true}
                >
                    <strong>
                        Ayant réussi DANS la vie, ces hommes et ces femmes<br />
                        voulaient réussir LEUR vie !
                    </strong>
                </Paragrapth>
            </div>

        </Page>
    );
};
export default Page12;
