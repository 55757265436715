import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 10,
    background: 'white',
    borderRadius: 5,
  },
  iconButtonV: {
    padding: 5,
  },
  accordionDetails: {
    color: theme.palette.primary.dark,
  },
  variableNameLabel: {
    color: theme.palette.primary.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    paddingLeft: 10,
  },
  variableNameText: {
    color: theme.palette.success.dark,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'uppercase',
    paddingLeft: 3,
  },
  accordionSummary: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  circle: {
    position: 'relative',
    width: '3vw',
    height: '3vw',
    borderRadius: '50%',
    background: '#000A8C',
  },
  number: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: 12,
  },
  summaryText: {
    width: '70%',
  },
  summaryTextH6: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#0E1247',
  },
  btnPrimary: {
    background: '#000A8C',
    textTransform: 'none',
    width: 'calc(100% - 20px)',
    height: '100%',
    marginLeft: 10,
  },
  btnPrimary2: {
    background: '#000A8C',
    textTransform: 'none',
  },
  drawerContent: {
    width: '50%',
    borderTop: '2px solid #23296F',
    borderRadius: 16,
  },
  closeBtnDrawer: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: '0.001rem',
    top: '0.001rem',
    color: '#fff',
  },
  contentChild: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
  },
  titleDrawer: {
    lineHeight: '30px',
    color: '#fff',
    textTransform: 'none',
    marginBottom: 20,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: '2px solid #23296F',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  titleContainer: {
    marginTop: 25,
  },
  variableFormTitle: {
    fontFamily: 'Poppins',
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    color: theme.palette.primary.light,
    textAlign: 'center',
  },
  error: {
    '& div fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  marginTop: {
    marginTop: 15,
  },
  textHeader: {
    display: 'none',
    color: 'white',
    marginLeft: 0,
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  formInput: {
    width: '100%',
    '& div fieldset': {
      borderColor: '#E4E4F2',
      top: '-6px',
    },
    '& div input': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 14,
    },
  },
  chip: {
    height: '100%',
    width: 'calc(100% - 20px)',
    marginLeft: 10,
    justifyContent: 'space-between',
  }
}));
