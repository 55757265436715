// eslint-disable-next-line simple-import-sort/imports
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  drawerContent: {
    width: '35%',
  },
  closeBtn: {
    position: 'absolute',
    width: 50,
    height: 50,
    right: '5px',
    top: '5px',
  },
  contentChild: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 30,
  },
  title: {
    lineHeight: '30px',
    fontWeight: 'bold',
    color: '#000A8C',
    textTransform: 'none',
    marginBottom: 20,
  },
  rootStyle: {
    background: '#000a8c6b',
  },
});

const DrawerRight = ({ children, openDrawer, titleDrawer, callBack }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openDrawer);

  useEffect(() => {
    setOpen(openDrawer);
  }, [openDrawer]);

  const closeDrawer = () => {
    callBack(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      //    onClose={closeDrawer}
      classes={{ root: classes.rootStyle, paper: classes.drawerContent }}
    >
      <IconButton className={classes.closeBtn} onClick={closeDrawer}>
        <ClearIcon />
      </IconButton>
      <div className={classes.contentChild}>
        <Typography
          variant="h3"
          className={classes.title}
          data-cy="title-Drawer-create-edit-admin-benef"
        >
          {titleDrawer}
        </Typography>
        {children}
      </div>
    </Drawer>
  );
};
export default DrawerRight;
