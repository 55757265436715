// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    bookChoiceContainer: {
      backgroundColor: '#EDEEF2',
      color: '#0E1247',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      marginTop: 10,
      paddingLeft: 10,
    },
    bookChoiceLabel: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      color: '#0E1247',
      fontSize: '16px',
      marginBottom: 10,
    },
    borderTop: {
      borderTop: '3px solid #0E1247',
      borderLeft: '3px solid #0E1247',
      borderRight: '3px solid #0E1247',
      paddingBottom: 10,
    },
    borderBottom: {
      borderBottom: '3px solid #0E1247',
      borderLeft: '3px solid #0E1247',
      borderRight: '3px solid #0E1247',
      marginTop: 0,
    },
    radioGroup: {
      border: '3px solid #0E1247',
      marginTop: 10,
      backgroundColor: '#EDEEF2',
      padding: 8,
      // '&.MuiFormControlLabel-root': {
      //   marginLeft: 0,
      //   marginRight: 0,
      // },
    },
    radioGroupBaseClass: {
      color: '#0E1247',
      fontSize: 14,
      fontStyle: 'normal',
      fontFamily: 'Poppins',
      fontWeight: 400,
    },
    radioChecked: {},
    radioUnchecked: {
      // backgroundColor: 'red',
    },
    groupedChecboxBaseStyle: {
      // margin: '5px 0 5px 0',
      // width: '-webkit-fill-available',
      display: 'flex',
      justifyContent: 'start',
    },
    specialCheckboxDisabled: {
      backgroundColor: '#fff',
    },
    needsStyle: {
      paddingTop: 5,
      borderTop: '3px solid #0E1247',
      borderRight: '3px solid #0E1247',
      borderLeft: '3px solid #0E1247',
      marginTop: 10,
      backgroundColor: '#EDEEF2',
    },
    needProStyle: {
      borderBottom: '3px solid #0E1247',
      borderRight: '3px solid #0E1247',
      borderLeft: '3px solid #0E1247',
      marginBottom: 10,
      backgroundColor: '#EDEEF2',
      paddingBottom: 10,
      marginTop: 0,
    },
    selectItemContainer: {
      paddgin: 3,
    },
    whiteBackground: {
      backgroundColor: '#fff',
      marginRight: 15,
    },
  })
);
