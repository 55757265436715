import { Typography } from '@material-ui/core';
import style from '../../../commonStyle';

const EpicureanSuggestion = () => {
  const classes = style();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        😍 TA VOIE IDÉALE : LE BACHELOR
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          Après une filière générale ou technologique débouchant sur des résultats moyens au
          baccalauréat, tu intégreras une école privée post-Bac dans le cadre dun Bachelor.💪
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Après cette première séquence de formation, tu pourras poursuivre tes études en Master
              au sein de la même école où tu auras su te faire une place au soleil et cultiver tes
              réseaux.
            </Typography>
          </li>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              Tes études seront sous le signe des expériences et des découvertes : voyages, stages a
              l&apos;étranger, junior entreprise et bien sûr, faire la fête 🥳 !!!
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionQuote} component="p">
          « Le plaisir est le commencement et la fin de la vie heureuse. »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Épicure
      </Typography>
    </div>
  );
};

export default EpicureanSuggestion;
