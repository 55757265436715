/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { imgBook1} from '@orientaction/utils';
import { Page } from '@orientaction/components';

const Page30 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={30}>
      <img className={classes.img} src={`${imgBook1}/happy.png`} alt="logo" />
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: '100%',
      height: 'auto',
    },
  })
);
export default Page30;
