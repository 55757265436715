import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ClearIcon from '@material-ui/icons/Clear';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTest } from '@orientaction/hooks';
import { getFromLS, publicLogoPath, publicSvgPath } from '@orientaction/utils';
import classNames from 'classnames';
import floor from 'lodash/floor';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import style from './style';

interface IProps {
  children: any;
  isGradient?: boolean;
}

const MinorTestLayout: FC<IProps> = ({ children, isGradient = true }) => {
  const history = useHistory();

  const classes = style();
  const user = JSON.parse(getFromLS('userInfos') || '{}');

  const mainLink = user?.role?.id === 3 ? '/results' : '/main';

  const { progress } = useTest();

  return (
    <>
      <div className={classes.layout}>
        <div className={classes.topLineContainer} />
        <div className={classes.bottomLineContainer} />
        <div className={classNames(classes.content, classes.gradientBG)}>
          <AppBar position="sticky" color="transparent" elevation={0} className={classes.appBar}>
            <nav className={classes.navbar}>
              <Grid container={true} spacing={1} alignItems="center">
                <Grid item={true} xs={4} sm={4} md={4}>
                  <div
                    className={classes.logoContainer}
                    onClick={() => (user ? history.push(mainLink) : history.push('/'))}>
                    <picture className={classes.logo}>
                      <source
                        media="(min-width:650px)"
                        srcSet={`${publicLogoPath}/e-orientaction.png`}
                      />
                      <img
                        style={{ width: '100%' }}
                        src={`${publicLogoPath}/e-orientaction.png`}
                        alt="logo-orientation"
                      />
                    </picture>
                    {/* <img className={cls.logo} src={`${publicLogoPath}/logo-orientaction.png`} alt="logo" /> */}
                  </div>
                </Grid>
                <Grid item={true} xs={6} sm={4} md={8} className={classes.flexCenter}>
                  <div className={classes.progressBar}>
                    <LinearProgress
                      classes={{
                        colorPrimary: classes.colorPrimary,
                        barColorPrimary: classes.barColorPrimary,
                      }}
                      variant="determinate"
                      value={progress}
                    />
                  </div>
                  <p style={{ marginLeft: '5px' }}>{floor(progress)}%</p>
                </Grid>
              </Grid>
              <Grid
                item={true}
                xs={2}
                sm={2}
                md={4}
                className={classNames(classes.flexCenter, classes.flexCenterMobile)}>
                <Hidden only={['xs', 'sm']}>
                  <span
                    style={{
                      textAlign: 'left',
                      lineHeight: 1.2,
                      paddingLeft: 40,
                      paddingRight: 20,
                    }}>
                    <Grid container={true} spacing={1}>
                      <Grid item={true} sm={2}>
                        <PhoneIcon style={{ fontSize: '18px' }} />
                      </Grid>
                      <Grid item={true} sm={10}>
                        <div>02 43 72 25 88*</div>
                        <div style={{ fontSize: '12px' }}>*Numéro national non surtaxé</div>
                      </Grid>
                    </Grid>
                  </span>
                  <Button
                    className={classes.btnContacter}
                    onClick={() =>
                      window.open('https://www.orientaction-groupe.com/contact/', '_blank')
                    }>
                    <MailOutlineIcon style={{ fontSize: '18px' }} />
                    <span style={{ textAlign: 'left', lineHeight: 1.2, marginLeft: 10 }}>
                      <div>Nous</div>
                      <div>Contacter</div>
                    </span>
                  </Button>
                </Hidden>
                <Hidden only={['xl', 'lg', 'md']}>
                  <IconButton
                    className={classes.btnIcon}
                    onClick={() => history.push('/user/tests')}
                    style={{ float: 'right' }}>
                    <ClearIcon className={classes.styleIcon} />
                  </IconButton>
                </Hidden>
              </Grid>
            </nav>
          </AppBar>
          {children}
        </div>
      </div>
    </>
  );
};

export default MinorTestLayout;
