import { ApiActions } from '@orientaction/api-actions';
import { PreferencePersonalityTestResultService } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '../utils/utils';

const usePreferencePersonalityTestResult = () => {
  const dispatch = useDispatch();

  const preferencePersonalityResultsState: any = useSelector(
    (state: any) => state?.api?.preferencePersonalityTestResults,
  );

  const token = getFromLS('userToken') || '';

  const getPreferencePersonalityTestResults = async () => {
    try {
      const preferencePersonalityTestResults =
        await PreferencePersonalityTestResultService.getPreferencePersonalityTestResults(token);

      dispatch(
        ApiActions.setPreferencePersonalityTestResults(
          preferencePersonalityTestResults?.data?.data,
        ),
      );
    } catch (error) {
      console.log('error on getting preferencePersonalityTestResults ', error);
    }
  };

  const getMyResult = async (id: number) => {
    return PreferencePersonalityTestResultService.getMyResultPrefrerencePersonality(token, id);
  };

  const getMyResultJeune = async (id: number) => {
    return PreferencePersonalityTestResultService.getMyResultPrefrerenceJeune(token, id);
  };

  const getMyResumeElement = async (data: any) => {
    return PreferencePersonalityTestResultService.getMyResumeElement(token, data);
  };

  return {
    preferencePersonalityTestResults: preferencePersonalityResultsState,
    getPreferencePersonalityTestResults,
    getMyResult,
    getMyResultJeune,
    getMyResumeElement,
  };
};

export default usePreferencePersonalityTestResult;
