/* eslint-disable no-shadow */
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import style from './style';

interface IItemChoice {
  id: string;
  title: string;
  isSelected?: boolean;
  handleClick?: () => void;
  number: number;
}

const ItemChoice: FC<IItemChoice> = ({ id, title, isSelected = false, handleClick, number }) => {
  const classes = style();
  return (
    <>
      {number > 1 && <div className={classes.separator} />}
      <div onClick={handleClick} className={classes.container} style={{ height: '100%' }}>
        <div className={classes.container2}>
          <div
            className={classes.container3}
            style={{
              background: isSelected ? '#000A8C' : '#FAFAFA',
              color: isSelected ? '#FAFAFA' : '#000A8C',
            }}>
            <Typography
              variant="h4"
              className={classes.title2}
              style={isSelected ? { color: 'white' } : {}}>
              {`Choix ${number}`}
            </Typography>
          </div>
        </div>
        <div
          className={classes.textChoice}
          style={{
            background: isSelected
              ? 'linear-gradient(180deg, #FFDC27 0%, #E63C3C 48.96%, #000A8C 100%)'
              : '#FAFAFA',
          }}>
          <Typography
            variant="h4"
            className={classes.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </div>
    </>
  );
};

export default ItemChoice;
