import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipTime: {
            margin: '0.25vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        iconeTime: {
            fontSize: 20,
            marginRight: 7,
            marginTop: 5,
            [theme.breakpoints.down('xs')]: {
                fontSize: 17,
            }
        },
        textTime: {
            fontSize: 16,
            fontFamily: 'Poppins',
            fontWeight: 300,
            [theme.breakpoints.down('xs')]: {
                fontSize: 13,
            }
        },

    })
);
