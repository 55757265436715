// eslint-disable-next-line simple-import-sort/imports
import {
  Paragrapth,
  TitleNbr,
  Page,
} from '@orientaction/components';
import useStyles from "../../style";

const Page09 = (props: any) => {

  const classe = useStyles();

  return (
    <Page
      id={9}
    >

      <div
      >

        <Paragrapth
          align="justify"
        >
          Pour donner du sens à votre vie et à votre carrière professionnelle (le travail ne tient-il
          pas une place considérable dans nos vies ?), il faut apporter des réponses claires à
          trois questions essentielles :
        </Paragrapth>
        <br />
        <br />
        <TitleNbr
          number={1}
          text="QUEL EST L’OBJECTIF SUPRÊME DE MA CARRIÈRE ?"
        />
        <br />
        <Paragrapth
          align="justify"
        >
          Quelle est la carrière que je veux avoir, la vie que je veux vivre ? Que dois-je avoir
          réalisé pour me sentir fier(ère) et accompli(e) lorsque je prendrai ma retraite ?
        </Paragrapth>
        <br />
        <br />
        <TitleNbr
          number={2}
          text="QUELLE PLACE VOUDRAIS-JE OCCUPER DANS LA SOCIÉTÉ ?"
        />
        <br />
        <Paragrapth
          align="start"
        >
          Qui ai-je envie d’être ? Que voudrais-je représenter pour les autres ? Pour qui ai-je
          envie d’être utile et de quelle manière puis-je l’être ?
        </Paragrapth>
        <br />
        <br />
        <TitleNbr
          number={3}
          text="QUELLES SONT MES VALEURS PROFONDES ?"
        />
        <br />
        <Paragrapth
          align="justify"
        >
          Quelles sont mes croyances les plus fortes, tellement ancrées en moi qu’elles dirigent
          ma vie ?<br /><br />
          Qu’ai-je envie de défendre et de promouvoir, pour moi et pour les autres ?
          Quelle est la source de mon engagement ?<br /><br />
          Les exercices proposés dans ce livret vont vous aider à apporter vos propres réponses
          à ces trois questions essentielles.<br /><br />
          Vous commencez maintenant un travail sur vous-même, une réflexion qui vous
          promet peut-être quelques belles nuits blanches !<br /><br />
          Gardez le cap, car les réponses qui seront les fruits de ce travail introspectif vous
          permettront de construire la vie professionnelle et personnelle la plus heureuse et la
          plus épanouie possible.<br /><br />
          Elles vous aideront à choisir le métier qui vous correspond vraiment, celui
          qui vous permettra d’être réellement utile aux autres :
        </Paragrapth>
        <br />
        <br />
        <br />
        <br />
        <Paragrapth
          align="center"
          bold={true}
          colorBlue={true}
        >
          <strong>
            Un métier qui a du sens et qui vous épanouit,<br />
            qui vous permet de bâtir un équilibre pro/perso satisfaisant.
          </strong>
        </Paragrapth>

      </div>

    </Page>
  );
};

export default Page09;
