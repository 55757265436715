// eslint-disable-next-line simple-import-sort/imports
import {
  Paragrapth,
  TitlePage,
  Page,
  TitleChapitre,
  Img,
} from '@orientaction/components';
import useStyles from "../../style";

const Page10 = (props: any) => {

  const classe = useStyles();

  return (
    <Page
      id={10}
    >

      <TitleChapitre nbr={2} />

      <TitlePage>
        QUEL EST L’OBJECTIF SUPRÊME<br />
        DE VOTRE VIE ?
      </TitlePage>

      <Paragrapth align='justify'>
        Vous êtes chanceux(se), vous allez vivre vieux(vieille) ! Très vieux(vieille) même !!<br /><br />
        Jusqu’à 100 ans !!! Évidemment, vous avez envie de faire quelque chose de tout ce
        temps ! Un truc qui vous rende fier(ère) et qui ne laisse place à aucun regret.!<br /><br />
        Lorsque vous aurez atteint ce grand âge, vous voudrez vous retourner et, embrassant
        du regard votre existence avec un large sourire, vous dire :<br /><br />
        « Bon sang ! J’ai eu la vie que je voulais avoir, j’ai accompli ce que je voulais
        accomplir, je suis heureux(se) ».
      </Paragrapth>
      <br />
      <br />
      <br />
      <Paragrapth
        align="center"
        bold={true}
        colorBlue={true}
      >
        <strong>
          C’est cela, l’objectif suprême de votre vie.<br />
          C’est ce que vous ne devez jamais perdre de vue.
        </strong>
      </Paragrapth>

      <Img
        src='9.png'
        alt="Grand Mere"
        style={{
          width: "50%",
          margin: "4vh 0"
        }}
      />

    </Page>
  );
};
export default Page10;
