export const list = [
    {
        id: 1,
        label: `Je veux changer de métier mais le nouveau projet professionnel que j’ai
        défini est-il vraiment celui qui me correspond ?`
    },
    {
        id: 2,
        label: `Je veux quitter mon entreprise, mais ne vais-je pas abandonner ma
        sécurité et une bonne rémunération pour une situation précaire et
        difficile ?`
    },
    {
        id: 3,
        label: `Je veux créer une entreprise, mais mon concept est-il viable ? Suis-je
        certain(e) que je ne vais pas échouer ?`
    },
    {
        id: 4,
        label: `J’ai un projet, mais ai-je vraiment les compétences pour réussir dans ce
        que je veux entreprendre ?`
    }
]