/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { useMediaQuery, Theme, makeStyles, Button, Grid, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { FC } from 'react';
import { useStyles } from './style';

interface ICard {
  item: any;
}

const Card: FC<ICard> = ({ item }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const classe = useStyles();

  return (
    <div className={classe.content}>
      <div>
        <img src={item.img} alt={item.key} className={classe.img} />
      </div>

      <div className={classe.contentResult}>
        <MarginContainer>
          <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item={true}>
              <Typography
                className={classe.title}
                style={{
                  color: item.color,
                }}>
                {item?.label || ''}
              </Typography>
            </Grid>

            <Grid item={true}>
              <Typography>{item?.score || 0}</Typography>
            </Grid>
          </Grid>
        </MarginContainer>

        <MarginContainer>
          <Typography className={classe.yourPrincipe}>{item?.description[0] || ''}</Typography>
        </MarginContainer>

        <MarginContainer>
          <Typography className={classe.description}>{item?.description[1] || ''}</Typography>
        </MarginContainer>

        <MarginContainer>
          <Grid container={true} direction="row" justifyContent="flex-end" alignItems="center">
            <Grid item={true}>
              <div className={classe.btn}>
                {!isMobile && <div>Découvrir</div>}

                <div>
                  <ArrowForwardIcon className={classe.iconBtn} />
                </div>
              </div>
            </Grid>
          </Grid>
        </MarginContainer>
      </div>
    </div>
  );
};

export default Card;
