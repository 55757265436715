import { ApiActions } from '@orientaction/api-actions';
import { MeaningValueTestResultService } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '../utils/utils';

const useMeaningValueTestResult = () => {
  const dispatch = useDispatch();
  const meaningValueTestResultsState: any = useSelector(
    (state: any) => state?.api?.meaningValueTestResults
  );

  const token = getFromLS('userToken') || '';

  const getMeaningValueTestResults = async () => {
    try {
      const meaningValueTestResults: any =
        await MeaningValueTestResultService.getMeaningValueTestResults(token);
      dispatch(ApiActions.setMeaningValueTestResults(meaningValueTestResults?.data?.data));
    } catch (error) {
      console.log('error on getting meaningValueTestResults ', error);
    }
  };

  const getMyResult = async (id: number) => {
    return MeaningValueTestResultService.getMyResultMeaningValue(token, id);
  };

  const getMyResumeElement = async (data: any) => {
    return MeaningValueTestResultService.getMyResumeElement(token, data);
  };

  return {
    meaningValueTestResults: meaningValueTestResultsState,
    getMeaningValueTestResults,
    getMyResult,
    getMyResumeElement,
  };
};

export default useMeaningValueTestResult;
