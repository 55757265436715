/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Page } from '@orientaction/components';
import { publicSvgPath } from '../../../../utils/constants';

const Page02 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={2}>
      <div className={classes.textCenterTop}>
        <Typography variant="h6" component="h5" style={{ fontSize: 30 }}>
          EMERIC LEBRETON
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom={true}>
          DOCTEUR EN PSYCHOLOGIE
        </Typography>
      </div>
      <div className="header__right">
        <div className="header__content bg--gradient">
          <div className="header__content-text">
            <h1 className="header__content-title">
              <span className="header__content-wrap">
                <span className="header__content-value">
                  <span className="decoration decoration--blue">
                    <img
                      src="/images/Book1/quote-blue.png"
                      alt="Quote blue"
                      className="quote-blue"
                    />
                  </span>
                  <span className="number">5</span>
                </span>

                <span className="header__content-desc">
                  <span className="title">Étapes pour réussir</span>
                  <span className="subtitle">Votre évolution professionnelle </span>
                </span>

                <span className="decoration decoration--yellow">
                  <img
                    src="/images/Book1/quote-yellow.png"
                    alt="Quote yellow"
                    className="quote-yellow"
                  />
                </span>
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div style={{ marginTop: '70px', marginBottom: '20px' }}>
        <div>
          <Typography
            variant="h1"
            component="h1"
            style={{ marginBottom: '10px', color: '#2b56a5', fontSize: '35px' }}
          >
            <img
              src={`${publicSvgPath}/point.png`}
              alt="Chapitre title"
              className={classes.imgFloat}
            />
            INTRODUCTION
          </Typography>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom={true}
            className="opacity07"
            style={{ fontWeight: '100' }}
          >
            <span>SE PRÉPARER AU CHANGEMENT</span>
          </Typography>
        </div>
        <div className={classes.trait} />
        <div style={{ marginTop: '50px' }}>
          <Typography
            style={{
              marginBottom: '10px',
              fontWeight: 'lighter',
              fontSize: '1.125rem',
              color: '#2b56a5',
            }}
          >
            <strong style={{ fontWeight: 'bold' }}>ÉTAPE 1</strong> : CONSTRUIRE UN PROJET QUI A DU
            SENS
          </Typography>
          <Typography
            style={{
              marginBottom: '10px',
              fontWeight: 'lighter',
              fontSize: '1.125rem',
              color: '#2b56a5',
            }}
          >
            <strong style={{ fontWeight: 'bold' }}>ÉTAPE 2</strong> : MIEUX CONNAÎTRE VOS BESOINS,
            VOS MOTIVATIONS ET VOTRE PERSONNALITÉ
          </Typography>
          <Typography
            style={{
              marginBottom: '10px',
              fontWeight: 'lighter',
              fontSize: '1.125rem',
              color: '#2b56a5',
            }}
          >
            <strong style={{ fontWeight: 'bold' }}>ÉTAPE 3</strong> : EXPLORER TOUS LES POSSIBLES
          </Typography>
          <Typography
            style={{
              marginBottom: '10px',
              fontWeight: 'lighter',
              fontSize: '1.125rem',
              color: '#2b56a5',
            }}
          >
            <strong style={{ fontWeight: 'bold' }}>ÉTAPE 4</strong> : IDENTIFIER VOS COMPÉTENCES ET
            ÉVALUER VOTRE POTENTIEL
          </Typography>
          <Typography
            style={{
              marginBottom: '10px',
              fontWeight: 'lighter',
              fontSize: '1.125rem',
              color: '#2b56a5',
            }}
          >
            <strong style={{ fontWeight: 'bold' }}>ÉTAPE 5</strong> : VALIDER VOTRE PROJET ET
            ÉLABORER VOTRE PLAN D’ACTION
          </Typography>
        </div>
      </div>
      <div style={{ marginBottom: '100px', marginTop: '100px' }}>
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-justify-content-xs-center">
          <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-7">
            <div className="book__footer--image bg--gradient--other mb-5">
              <img
                className="jss150"
                src="/images/Book1/logo_oa_dark_blue_no_text_under.png"
                alt="logo"
              />
            </div>
            <p className="text-center text-blue">LEADER DU BILAN DE COMPÉTENCES</p>
          </div>
        </div>
      </div>
    </Page>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: '980px',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 19,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
      color: '#2b56a5',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '14px',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    imgFloat: {
      width: '45px',
      height: 'auto',
      float: 'left',
      marginLeft: '-60px',
      marginTop: '5px',
      '@media only screen and (max-width: 600px)': {
        display: 'none',
      },
      '@media only screen and (max-width: 361px)': {
        display: 'none',
      },
    },
  }),
);

export default Page02;
