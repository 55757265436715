/* eslint-disable no-use-before-define */
import { Page, TitleNbr, Paragrapth, TitlePage, TitleChapitre } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { context } from './context';
import Item from './item';
import { useLanguage } from '@orientaction/hooks';

const Page34 = (props: any) => {
  const { language } = useLanguage();

  return (
    <Page id={34}>
      <TitleChapitre nbr={4} />

      <TitlePage showSpanYellow={false}>POUR ALLER PLUS LOIN</TitlePage>

      <MarginContainer mormal={true}>
        <div style={{ textAlign: 'start' }}>
          <Paragrapth align="start">
            Vous souhaitez être accompagné(e) dans la mise en œuvre de votre projet, voici trois
            prestations qui peuvent vous intéresser.
          </Paragrapth>
        </div>
      </MarginContainer>

      <MarginContainer mormal={true}>
        <div style={{ textAlign: 'start' }}>
          <Paragrapth align="start">
            Contactez-nous sur info@orientaction.com, au{' '}
            <Paragrapth align="start" colorBlue={true} bold={true}>
              02 43 72 25 88*{' '}
            </Paragrapth>{' '}
            (*numéro national non surtaxé) ou sur{' '}
            <Paragrapth align="start" colorBlue={true} bold={true}>
              www.orientaction-groupe.com
            </Paragrapth>{' '}
            (rubrique « Nous contacter ») pour plus d’informations.
          </Paragrapth>
        </div>
      </MarginContainer>

      {context.map((item: any) => (
        <Item
          title={item.title}
          id={item.id}
          endText={item.endText}
          paragraph={item.paragraph}
          img={language === 'GB' ? item.en_img : item.img}
        />
      ))}
    </Page>
  );
};

export default Page34;
