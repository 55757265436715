/* eslint-disable no-shadow */
import { Typography } from '@material-ui/core';
import { FC } from 'react';
import style from './style';

interface IItemSquare {
  title: string;
  isSelected?: boolean;
  handleClick?: () => void;
}

const ItemChoiceSquare: FC<IItemSquare> = ({ title, isSelected = false, handleClick }) => {
  const classes = style();
  return (
    <div
      onClick={handleClick}
      className={classes.container3}
      style={{
        background: isSelected ? '#000A8C' : 'white',
        color: isSelected ? 'white' : '#000A8C',
        borderColor: isSelected ? '#000A8C' : '#B0B5FF',
      }}>
      <Typography
        variant="h4"
        className={classes.title2}
        style={isSelected ? { color: 'white' } : {}}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  );
};

export default ItemChoiceSquare;
