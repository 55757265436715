// Formated data to filter format that is recognized by strapi
export const getStrapiFilter: any = (testFilter: any, isHarmony = false) => {
  if (testFilter.type === 'number') {
    if (testFilter.entity === 'users_permissions_users') {
      const userRelationColumnName = isHarmony ? 'user' : 'users_permissions_user';
      return {
        [userRelationColumnName]: {
          [testFilter.column]: {
            [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: +testFilter.value,
          },
        },
      };
    }
    return {
      [testFilter.column]: {
        [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
      },
    };
  }
  if (testFilter.type === 'string') {
    if (testFilter.entity === 'users_permissions_users') {
      const userRelationColumnName = isHarmony ? 'user' : 'users_permissions_user';
      return {
        [userRelationColumnName]: {
          [testFilter.column]: {
            [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
          },
        },
      };
    }
  }

  if (testFilter.type === 'date') {
    return {
      realization_date_string: {
        [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
      },
    };
  }

  return {
    [testFilter.column]: {
      [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
    },
  };
};

const getStrapiOperatorBySymbol = (symbol: string, type: string) => {
  switch (symbol) {
    case '=':
      return type === 'number' ? '$eq' : '$containsi';
    case '≠':
      return type === 'number' ? '$ne' : '$notContainsi';
    case '>':
      return type === 'number' ? '$gte' : '$notContainsi';
    case '<':
      return type === 'number' ? '$lte' : '$notContainsi';

    default:
      return '$eq';
  }
};

export const getApiUrlByTestId = async (id: number) => {
  switch (id) {
    case 1:
      return 'meaning-value-test-results';
    case 2:
      return 'needs-test-results';
    case 3:
      return 'needs-pro-test-results';
    case 4:
      return 'preference-personality-results';
    case 5:
      return 'results';

    case 6:
      return 'study-test-results';

    case 7:
      return 'preference-jeune-results';
    default:
      return 'custom-test';
  }
};

export const getUserStatusStrapiFilter = (userStatusfilter: any, isHarmony = false) => {
  // relationship with user column name is not the same for major tests
  const userRelation = isHarmony ? 'user' : 'users_permissions_user';

  // construct filter for non rattached

  const rattachedFilter = userStatusfilter?.isRattached
    ? {
        [userRelation]: {
          rattachement: {
            $eq: true,
          },
        },
      }
    : {
        [userRelation]: {
          $or: [
            {
              rattachement: {
                $eq: false,
              },
            },
            {
              rattachement: {
                $eq: null,
              },
            },
          ],
        },
      };
  return [
    {
      [userRelation]: {
        isActivated: {
          $eq: userStatusfilter.isActive,
        },
      },
    },
    rattachedFilter,
  ];
};

export const getMinorTestStrapiFilter: any = (testFilter: any) => {
  if (testFilter.type === 'number') {
    if (testFilter.entity === 'users_permissions_users') {
      const userRelationColumnName = 'users_permissions_users';
      return {
        [userRelationColumnName]: {
          [testFilter.column]: {
            [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: +testFilter.value,
          },
        },
      };
    }
    if (testFilter.entity === 'variables') {
      return {
        response_user_variables: {
          value: {
            [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: +testFilter.value,
          },
          variable: {
            id: {
              $eq: testFilter.id,
            },
          },
        },
      };
    }
    return {
      [testFilter.column]: {
        [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
      },
    };
  }
  if (testFilter.type === 'string') {
    if (testFilter.entity === 'users_permissions_users') {
      const userRelationColumnName = 'users_permissions_users';
      return {
        [userRelationColumnName]: {
          [testFilter.column]: {
            [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
          },
        },
      };
    }
  }

  if (testFilter.type === 'date') {
    return {
      realizationDate: {
        [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
      },
    };
  }

  return {
    [testFilter.column]: {
      [getStrapiOperatorBySymbol(testFilter.operator, testFilter.type)]: testFilter.value,
    },
  };
};
