import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  button: {
    background:
      'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    width: 'fit-content',
    height: 48,
    color: '#FFFFFF',
    fontWeight: 600,
    padding: '9px 14px',
  },
}));
