import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        btnPrimary: {
            padding: "2vh 2.5vh",
            textTransform: 'none',
            background:
                'transparent linear-gradient(230deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
        },
        btnNormal: {
            margin: '0 1vh',
            padding: "2vh 2.5vh",
            textTransform: 'none',
            border: '1px solid #BCBCBC',
        },
    })
);

export default useStyles