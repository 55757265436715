/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBackIosRounded } from '@material-ui/icons';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { forgotPassword } from '../../../../services/user.service';
import { emailMatch, publicSvgPath } from '../../../../utils/constants';
import locale from './locale.json';

interface IProps {
  handleAccountConfirm: any;
}

const ForgotPasswordForm: FC<IProps> = ({ handleAccountConfirm }) => {
  const [registerError, setRegisterError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const cls = style();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<any>({
    mode: 'onChange',
  });
  const goToLogin = async (data: any) => {
    try {
      setIsLoading(true);
      const response = await forgotPassword(data);
      if (response?.data?.ok === true) {
        setIsLoading(false);
        handleAccountConfirm(true);
      }
      setRegisterError('Une erreur est survenue');
      setIsLoading(false);
    } catch (error: any) {
      setRegisterError('Une erreur est survenue');
      setIsLoading(false);
    }
  };
  const goBack = async (data: any) => {
    history.push('/');
  };

  return (
    <div className={cls.formContainer}>
      <form className={cls.form} onSubmit={handleSubmit(goToLogin)}>
        <div className={cls.fieldWithLabel}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Champ obligatoire',
              pattern: {
                value: emailMatch,
                message: 'Veuillez saisir une adresse valide',
              },
            }}
            render={({ field }) => (
              <>
                <label htmlFor="email">
                  <h5 className={cls.labelText}>{locale.emailLabel}</h5>
                </label>
                <Input
                  placeholder={locale.emailPlaceholder}
                  {...field}
                  type="text"
                  className={cls.input}
                />
                <span className={cls.errorField}>{(errors as any).email && (errors as any).email.message}</span>
              </>
            )}
          />
        </div>
        {registerError?.trim()?.length > 0 && <h5 className={cls.errorField}>{registerError}</h5>}
        <Box className={cls.btnConnectContainer}>
          <Button type="submit" className={cls.btnConnect}>
            {isLoading ? `${locale.sendText}...` : locale.sendText}
          </Button>
        </Box>
        <Box className={cls.iconBackContainer} onClick={goBack}>
          <img className={cls.backImage} src={`${publicSvgPath}/arrowBack.svg`} alt="back_image" />{' '}
          <div className={cls.backText}>Retour</div>
        </Box>
      </form>
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
    '@media (min-width: 1250px)': {
      maxWidth: 410,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldWithLabel: {
    marginTop: 20,
    width: '100%',
  },
  errorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginTop: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  inputPassword: {
    paddingRight: 5,
  },
  labelText: {
    margin: 'auto',
    font: 'normal normal bold 14px ITC Avant Garde Gothic Std Medium',
  },
  btnConnectContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    width: '100%',
  },
  btnConnect: {
    width: '100%',
    background:
      'transparent linear-gradient(201deg, #FFDC27 0%, #E63C3C 50%, #000A8C 100%) 0% 0% no-repeat padding-box',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#fff',
  },
  forgotPasswordLink: {
    color: '#000A8Cc !important',
    textDecoration: 'underline',
    font: 'normal normal bold 11px/15px ITC Avant Garde Gothic Std Medium',
    marginTop: 20,
  },
  iconBackContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 40,
    cursor: 'pointer',
  },
  backText: {
    color: theme.palette.primary.main,
    font: 'normal normal bold 12px/16px ITC Avant Garde Gothic Std Medium',
    paddingTop: 2,
  },
  backImage: {
    cursor: 'pointer',
  },
}));

export default ForgotPasswordForm;
