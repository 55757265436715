/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook1 } from '@orientaction/utils';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Page } from '@orientaction/components';
import { useBook1 } from "@orientaction/hooks_book";

const Page16 = (props: any) => {

  const classes = useStyles();

  const { book, updateMajory_A } = useBook1();

  const handleChange = (event: any) => {
    const { name, checked } = event.target;
    updateMajory_A({ [name]: checked });
  };

  return (
    <Page id={16} readOnly={props.readOnly}>
      <div style={{ marginTop: '50px', marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" style={{ marginBottom: '10px' }}>
            RÉSULTATS DU TEST :
          </Typography>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            RÉUSSIR DANS LA VIE OU RÉUSSIR SA VIE ?
          </Typography>
        </div>
        <div className={clsx(classes.trait, classes.positionCenter)} />
        <div style={{ marginTop: '20px', marginBottom: '20' }}>
          <Grid container={true} spacing={2} justifyContent="center">
            <Grid item={true} xs={4}>
              <img className={classes.img} src={`${imgBook1}/chap.png`} alt="logo" />
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ marginTop: '50px' }}>
        <Typography variant="h3" component="h3" style={{ display: 'flex', fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '50px', textAlign: 'left', fontWeight: 'lighter' }}>
          <span className={classes.number}>1</span>
          <span className={classes.h3}>VOUS AVEZ UNE MAJORITÉ DE</span>
          <span className={clsx(classes.letter, classes.categorieA)}>A</span>
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Ce qui compte le plus pour vous aujourd’hui (et qui comptera le plus demain) est de réussir <span className={classes.boldText}> DANS </span> la vie. Vous êtes une personne extrêmement ambitieuse.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous avez envie d’occuper une place de premier plan et de jouer un rôle important dans la société.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous désirez gagner beaucoup d’argent, avoir du pouvoir et bénéficier d’une certaine notoriété.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous êtes animé(e) par un besoin de reconnaissance sociale lié à une ambition personnelle ou au désir d’occuper un statut équivalent à celui de vos parents.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Pour atteindre vos objectifs ambitieux, vous êtes prêt(e) à faire beaucoup de sacrifices. Beaucoup travailler ne vous fait pas peur.
        </Typography>
        <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
          Vous considérez votre carrière comme LA priorité. Vous avez envie de relever des défis importants
        </Typography>
        <Typography className={classes.boldText} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '30px', textAlign: 'justify', fontStyle: 'italic' }}>
          Pour aller plus loin, identifiez ce qui est le vrai moteur de votre ambition :
        </Typography>
      </div>
      <div style={{ marginTop: '50px', marginBottom: '30px' }}>
        <div className={classes.cardBgGrey} >
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={!!book.majoryA.richesse} color="primary" onChange={handleChange} name="richesse" />}
                label={
                  <div style={{color: 'black'}}>
                    <span className={classes.boldText}>La richesse : </span> devenir le(la) plus riche possible et gagner le plus d’argent possible. C’est à l’aune de votre fortune que vous mesurez votre réussite.
                  </div>
                }
                style={{ paddingTop: 7, paddingBottom: 7 }}
              />
              <FormControlLabel
                control={<Checkbox checked={!!book.majoryA.pouvoir} color="primary" onChange={handleChange} name="pouvoir" />}
                label={
                  <div style={{color: 'black'}}>
                    <span className={classes.boldText}>Le pouvoir :  </span> avoir une grande influence sur les évènements et les personnes même en demeurant dans l’ombre. C’est à cette faculté que vous mesurez votre réussite.
                  </div>
                }
                style={{ paddingTop: 7, paddingBottom: 7 }}
              />
              <FormControlLabel
                control={<Checkbox checked={!!book.majoryA.notorite} color="primary" onChange={handleChange} name="notorite" />}
                label={
                  <div style={{color: 'black'}}>
                    <span className={classes.boldText}>La notoriété : </span> être reconnu(e) par le plus grand nombre, être écouté(e) et apprécié(e). C’est à votre notoriété que vous mesurez votre réussite.
                  </div>
                }
                style={{ paddingTop: 7, paddingBottom: 7 }}
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto'
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#e8eef4'
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    letter: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10%',
      padding: '7px',
    },
    categorieB: {
      color: '#fff !important',
      background: '#2c57a5',
    },
    categorieA: {
      color: '#2c57a5',
      background: '#fff',
      border: '1px solid #2c57a5'
    },
    h3: {
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#000A8C',
      fontWeight: 'bold',
    },
    boldText: {
      color: '#000A8C',
      fontWeight: 800,
    },
  })
);
export default Page16;
