import { Grid, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { Paragrapth } from '@orientaction/components';
import { FC } from 'react';
import { AnyObject } from 'yup/lib/types';
import useStyle from './style';

interface IParagrapthListNumber {
  list: AnyObject[];
  paragraphAlign?: any;
  overrideParagraphStyle?: any;
}

const ParagraphListNumberWithMultipleParagraphs: FC<IParagrapthListNumber> = ({
  list,
  paragraphAlign = 'start',
  overrideParagraphStyle = '',
}) => {
  const classe = useStyle();

  return (
    <div className={classe.root}>
      {list.map((item: any, index: number) => (
        <div className={classe.container}>
          <Grid container={true} justifyContent="flex-start" alignItems="center">
            <Grid item={true} xs={12}>
              <Grid container={true} direction="row" spacing={1}>
                <Grid item={true} xs={2} md={1}>
                  <div className={classe.span}>
                    <strong>{item.number}</strong>
                  </div>
                </Grid>

                <Grid item={true} xs={10} md={11}>
                  <Paragrapth align={paragraphAlign} addBackground={true}>
                    {item.label && (
                      <Paragrapth
                        align={paragraphAlign}
                        bold={true}
                        colorBlue={true}
                        addBackground={true}>
                        {item.label} :{' '}
                      </Paragrapth>
                    )}
                  </Paragrapth>
                  {item.texts.map((text: any, i: any) => (
                    <Paragrapth
                      overrideParagraphStyle={overrideParagraphStyle}
                      align={paragraphAlign}
                      addBackground={true}>
                      {text}
                  </Paragrapth>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ))}
    </div>
  );
};

export default ParagraphListNumberWithMultipleParagraphs;
