export const context = [
    {
        id: 1,
        title: 'RÉSULTATS DU TEST : PARTIE 1',
        listContext: [],
        verification: [
            {
                label: ' Votre score de A est',
                name: 'A',
                point: 8
            },
            {
                label: ' Votre score de B est',
                name: 'B',
                point: 4
            },
            {
                label: ' Votre score de C est',
                name: 'C',
                point: 2
            }
        ]
    },
    {
        id: 2,
        title: 'RÉSULTATS DU TEST : PARTIE 2',
        listContext: [],
        verification: [
            {
                label: 'NOMBRE DE X',
                name: 'X'
            },
            {
                label: 'NOMBRE DE Y',
                name: 'Y'
            },
            {
                label: 'NOMBRE DE Z',
                name: 'Z'
            }
        ]
    },
    {
        id: 3,
        title: 'VOTRE SCORE SE SITUE ENTRE 68 ET 96',
        paragraph: `Vous avez un véritable tempérament d’entrepreneur(se). Vous êtes indépendant(e),
        fonceur(se), déterminé(e), apte à assumer vos responsabilités. Vous avez le sens des affaires
        et l’envie de réussir. Attention à ne pas prendre trop de risques, à trop vouloir foncer. Il faudra
        peut-être apprendre à vous modérer ainsi qu’à gérer votre temps et vos forces.`,
        verification: [],
        listContext: [
            {
                title: 'Vous avez une majorité de X ',
                content: `Vous êtes un(e) décideur(se), il faut que vous soyez le(a)
                patron(ne). Votre ambition va de pair avec votre caractère et vous saurez déployer toute votre
                énergie pour réussir. Si vous devez encadrer une équipe, choisissez-la bien : il faudra qu’elle
                ait de la « trempe » pour vous suivre !`
            },
            {
                title: 'Vous avez une majorité de Y ',
                content: `Vous êtes créatif(ve) ou intuitif(ve). Si vous voulez créer votre
                entreprise, c’est afin de pouvoir exprimer votre créativité ou suivre votre intuition, sans être bridé(e) par les carcans d’une structure rigide. Vous devrez faire des efforts sur vous-même
                pour ne pas négliger les problèmes d’organisation, d’administration, de gestion, inhérents à
                la vie de votre entreprise.`
            },
            {
                title: 'Vous avez une majorité de Z ',
                content: `Vous êtes un(e) organisateur(trice). Plutôt que d’être
                poussé(e) par l’ambition ou la créativité, vous allez vous attacher à mettre en place quelque
                chose d’organisé, avec une structure stable qui permette de vous installer dans la durée en
                mesurant les risques.`
            }
        ]
    },
    {
        id: 4,
        title: 'VOTRE SCORE SE SITUE ENTRE 46 ET 68',
        paragraph: ` Vous avez envie d’entreprendre, sans toutefois être un(e) « entrepreneur(se) né(e) ». Peut-
        être vous posez-vous encore des questions sur l’opportunité ou la faisabilité de votre projet.
        L’important est de savoir s’il s’agit d’hésitations, de doutes, ou de simples réflexions de
        prudence quant à votre organisation personnelle.`,
        verification: [],
        listContext: [
            {
                title: 'Vous avez une majorité de X',
                content: ` Votre tempérament est celui d’un(e) décideur(se): les
                questions que vous vous posez sont certainement plus des questions d’organisation que
                l’expression d’un doute sur vous-même.`
            },
            {
                title: 'Vous avez une majorité de Y',
                content: `Vous avez des idées, de l’intuition, mais vous avez aussi une
                sensibilité qui vous fragilise. Attention à ce que vos idées ne restent pas velléitaires.`
            },
            {
                title: 'Vous avez une majorité de Z ',
                content: `Vous aimeriez bien vous lancer mais vous êtes un peu trop
                rigoriste. Vous voulez vous assurer de tout, c’est bien. Mais pour se lancer, il y a forcément une
                part de risque qu’il faut accepter.`
            }
        ]
    },
    {
        id: 5,
        title: 'VOTRE SCORE SE SITUE ENTRE 24 ET 46',
        paragraph: `Vous n’avez pas le profil d’un(e) entrepreneur(se). Vous avez trop besoin de la structure d’une
        organisation pour vous donner les repères qui vous permettent d’être efficace dans votre
        travail comme dans vos prises de décisions.`,
        verification: [],
        listContext: [
            {
                title: 'Vous avez une majorité de X',
                content: `Vous avez le profil d’un(e) chef(fe) de service, d’un(e)
                patron(ne) d’unité, qui sait prendre des décisions et ses responsabilités dans la structure
                de l’entreprise. Votre ambition est celle de la progression hiérarchique. Attention à ne pas
                manifester un autoritarisme trop dur vis-à-vis de vos collaborateurs(trices).`
            },
            {
                title: 'Vous avez une majorité de Y',
                content: `Vous êtes le(a) créatif (ve) de service, celui (celle) qui a
                des idées, et vous faites passer la satisfaction personnelle avant l’ambition professionnelle.
                Marginal(e) ou relativement indépendant(e) vis-à-vis de la structure d’une entreprise, vous
                en avez néanmoins besoin pour vous sécuriser et ne pas avoir à vous battre.`
            },
            {
                title: 'Vous avez une majorité de Z ',
                content: `Organisateur(trice), rigoureux(se), vous êtes tout à fait l’homme
                (la femme) de la structure, celui (celle) qui, selon les cas, met en place les procédures, les
                applique ou les fait appliquer. On peut le dire, vous êtes aux antipodes de l’entrepreneur(se).`
            }
        ]
    },
]