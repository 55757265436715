/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page } from '@orientaction/components';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../Img';
import { context } from './context';
import useStyles from './style';

const Page30 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={30}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          {/* <Img
                        src='55ans.jpg'
                        alt='55ans'
                        className={classes.img}
                    /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              À 55 ANS ...
            </Typography>
          </SimpleBanner>
        </MarginContainer>

        <Img src="cafe.jpg" alt="cafe" className={classes.imgillustration} />

        <div className={classes.content}>
          {context.map((text: any) => (
            <MarginContainer>
              <Typography className={classes.paragraphe}>{text}</Typography>
            </MarginContainer>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Page30;
