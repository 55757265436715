import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '36px',
      color: '#FFFFFF',
      [theme.breakpoints.down(800)]: {
        fontSize: '18.5px',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      padding: '25px',
    },
    contentNoPadding: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      height: '100%',
    },
    padding25: {
      padding: '25px',
    },
    content2: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      padding: '25px',
    },
    whenEqual: {
      textAlign: 'center',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#0E1247',
      marginTop: '6vh',
      [theme.breakpoints.down(800)]: {
        marginTop: '3vh',
      },
    },
    description: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      width: '498px',
      margin: '0 auto',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 0',
        lineHeight: '150%',
        fontSize: '16px',
        width: '95%',
      },
    },
    description2: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '16px',
      color: '#0E1247',
    },
    imgPiramide: {
      width: '100%',
      [theme.breakpoints.down(800)]: {
        width: '100%',
      },
    },
    podium: {
      width: '70%',
      margin: '0 auto',
      marginTop: 100,
      [theme.breakpoints.down(800)]: {
        width: '100%',
        marginTop: 40,
      },
    },
    marginFistViewTop: {
      marginTop: 40,
      [theme.breakpoints.down(800)]: {
        marginTop: 20,
      },
    },
    flexContainer: {
      display: 'flex',
      gap: 5,
      flexWrap: 'wrap',
      marginTop: 25,
      marginBottom: 15,
    },
    btnCard: {
      borderRadius: 50,
      display: 'block',
      textTransform: 'none',
      padding: '9.5px 20px 10px',
    },
    flexBtn: {
      display: 'flex',
      width: '100%',
      gap: 5,
      justifyContent: 'flex-end',
    },
  })
);
