/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
  useMeaningValueTestResult,
  useRedirectToRootPage,
  useAlert,
  useCompassMenu,
  useTestRights,
  useLanguage,
} from '@orientaction/hooks';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './style';
import Podium from './Podium';
import Card from '../Components/Card';
import Description from '../Components/Description';

const ValueCard: FC = () => {
  const { language } = useLanguage();

  // reminder: meaning/value test id is 1
  const { canUserAccessTest } = useTestRights(1);
  const { meaningTestLastResult } = useCompassMenu();

  const { getMyResult } = useMeaningValueTestResult();

  const [result, setMyResult] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const { goToRootPage } = useRedirectToRootPage();

  const alert = useAlert();

  const getResultat = async (idTest: number) => {
    setLoading(true);

    try {
      const { data } = await getMyResult(+idTest);
      console.log('------------', data);
      setMyResult(data.data.resultat);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (meaningTestLastResult?.id && typeof meaningTestLastResult?.id === 'number') {
      getResultat(meaningTestLastResult?.id);
    }
  }, [meaningTestLastResult?.id]);

  const classe = useStyles();

  const showNeutralVersion = !result;
  console.log('canUserAccessTest', canUserAccessTest);

  return (
    <Card
      to={`${language === 'GB' ? `/en/tests/meaning-values` : `/tests/meaning-value`}`}
      disable={showNeutralVersion}
      loading={loading}
      to_resultat={`/resultat-meaning-value/${meaningTestLastResult?.id}`}
      withAccess={canUserAccessTest}
    >
      <div className={classe.root}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <div className={classe.podium}>
              <Podium first={result?.[0]} second={result?.[1]} third={result?.[2]} />
            </div>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <Description>
              Voici vos valeurs directrices. Vos valeurs directrices guident vos pensées et vos
              comportements. Elles sont à la source du sens que vous donnez à vos actions. Agir
              conformément à vos valeurs vous permettra de développer votre motivation et votre
              bien-être. Vous vous sentirez aligné(e) entre ce que vous êtes et ce que vous faites.
            </Description>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default ValueCard;
