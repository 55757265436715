/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC } from 'react';
import { useStyles } from './style';


interface IScore {
    listScore: any[];
    color: string;
}

const Score: FC<IScore> = ({
    listScore,
    color
}) => {

    const classe = useStyles();

    return (
        <>
            <div className={classe.root}>

                {
                    listScore.map((item: any, index: number) =>
                        <>

                            <div
                                className={classe.score}
                                style={{
                                    position: 'relative',
                                    backgroundColor: item?.isMax ? color : '#EDEEF2',
                                    justifyContent: index === 0 ? 'flex-start' : 'flex-end',
                                    color: item?.isMax ? '#fff' : 'black',
                                    fontWeight: item?.isMax ? 'bold' : 500,
                                    width: `${item?.pourcentage}%`,
                                    zIndex:  item?.isMax ? 0 : 5,
                                    wordBreak: 'normal'
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        zIndex: 20,
                                    }}
                                >
                                    {`${Math.round(((item?.pourcentage) + Number.EPSILON) * 100) / 100}%`}
                                </div>
                            </div>

                        </>
                    )
                }

            </div>

            <div className={classe.root}>

                {
                    listScore.map((item: any, index: number) =>
                        <>
                            <div
                                className={classe.textContent}
                                style={{
                                    display: 'flex',
                                    fontWeight: item?.isMax ? 'bold' : 500,
                                    justifyContent: index === 0 ? 'flex-start' : 'flex-end',
                                }}
                            >
                                {item?.label || ""}
                            </div>

                        </>
                    )
                }
            </div>

        </>

    );
};

export default Score; 