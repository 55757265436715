/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC } from 'react';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import MySelect from './MySelect';

const Option = (props: any) => {
  const { label, isSelected } = props;
  return (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={isSelected} onChange={() => null} /> <label>{label}</label>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: 'Tous',
  value: 0,
};

const ValueContainer = ({ children, ...props }: any) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val: any) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>;
};

const MultiValue = (props: any) => {
  const { data } = props;
  let labelToBeDisplayed = `${data.label}, `;
  if (data.value === allOption.value) {
    labelToBeDisplayed = 'Tous';
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const animatedComponents = makeAnimated();

interface IEnterpriseSelect {
  updateTest: any;
  test: any;
  companies: any;
}

const EnterpriseSelect: FC<IEnterpriseSelect> = ({ updateTest, test, companies }) => {
  const handleChange = (selected: any) => {
    updateTest({ ...test, enterpriseSelected: selected });
  };

  return (
    <MySelect
      options={companies}
      allOption={allOption}
      isMulti={true}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
        MultiValue,
        ValueContainer,
        animatedComponents,
      }}
      onChange={handleChange}
      allowSelectAll={true}
      value={test?.enterpriseSelected}
      defaultValue={test?.enterpriseSelected}
      hasError={!!test?.manageError?.enterpriseSelected}
    />
  );
};

export default EnterpriseSelect;
