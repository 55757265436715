import AppBar from '@material-ui/core/AppBar';
import { Footer } from '@orientaction/components';
import { publicLogoPath } from '@orientaction/utils';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import style from './publicStudyStyle';

const PublicStudyLayout = ({
  children,
  isaddBackground,
  noPadding,
  classname,
  isNew,
  isNew2,
  showLink = true,
  footer,
}: any) => {
  localStorage.clear();
  const cls = style();
  const history = useHistory();

  const goToLogin = () => {
    history.push('/');
  };

  return (
    <div className={clsx(cls.layout, classname)}>
      <div
        className={clsx(
          cls.content,
          isaddBackground ? cls.grayBG : null,
          noPadding ? cls.noPadding : null,
          isNew ? cls.imageBG : null,
          isNew2 ? cls.imageBG2 : null
        )}>
        <AppBar position="sticky" color="transparent" elevation={0} className={cls.appBar}>
          <nav className={cls.navbar}>
            <div className={cls.flexContainer}>
              <div className={cls.logoContainer} onClick={goToLogin}>
                <picture className={cls.logo}>
                  <source srcSet={`${publicLogoPath}/e-orientaction.png`} />

                  <img
                    src={`${publicLogoPath}/e-orientaction.png`}
                    alt="logo-orientation"
                    className={cls.logoImg}
                  />
                </picture>
              </div>
            </div>
          </nav>
        </AppBar>

        <div className={cls.children}>
          {children}
          {footer && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default PublicStudyLayout;
