/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  Typography,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { emailMatch } from '@orientaction/utils';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import style from './style';
import useContactForm from './useContactForm';

interface IProps {
  open: boolean;
  test: {
    id: number;
    label: string;
  };
  handleClose: () => void;
}

const ContactForm: FC<IProps> = ({ open, handleClose, test }) => {
  const classes = style();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>({});
  const [formError, setFormError] = useState<any>({});

  const { user, postUserContactInfo } = useContactForm(test.id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<any>({
    mode: 'onChange',
  });

  const handlePhoneNumberChange = (value: any) => {
    setCurrent({
      ...current,
      phone: value,
    });
  };

  const formExtraVerification = () => {
    const errorForm: any = { ...formError };
    if (!current.phone || current.phone.length <= 2) {
      errorForm.phone = 'Champ obligatoire';
    } else {
      delete errorForm.phone;
    }

    setFormError(errorForm);

    if (Object.keys(errorForm).length) {
      return true;
    }

    return false;
  };

  const resetForm = () => {
    setCurrent({});
    setFormError({});
  };

  const onSubmitHandler = async (data: any) => {
    const hasExtraError = formExtraVerification();
    if (hasExtraError) {
      return false;
    }
    current.phone = `+${current.phone}`;
    // check if french phone number
    if (current?.phone?.startsWith(330 as any)) {
      // Remove zero if second number part start with it
      const secondPart = current?.phone?.substring(3);
      current.phone = `+33${secondPart}`;
    }
    setIsLoading(true);
    postUserContactInfo({ ...data, phone: current.phone })
      .then(() => {
        resetForm();
        reset();
        handleClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={open} className={classes.paperRounded} maxWidth="lg">
      <IconButton
        aria-label="upload picture"
        component="span"
        style={{ position: 'absolute', top: '5px', right: '5px', color: '#242424', zIndex: 11 }}
        onClick={handleClose}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
      <DialogContent className={classes.dialogContentContainer} dividers={false}>
        <Typography className={classes.dialogContentTitle} component="h1">
          Contacter notre service formation
        </Typography>
        <form className={classes.formContainer} onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={6}>
              <Controller
                name="lastname"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <>
                    <Input
                      placeholder="Nom*"
                      {...field}
                      type="text"
                      className={classes.input}
                      name="lastname"
                      disableUnderline={true}
                    />

                    {errors?.lastname?.message && (
                      <span className={classes.errorField}>{(errors as any).lastname.message}</span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <Controller
                name="firstname"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <>
                    <Input
                      placeholder="Prénom*"
                      {...field}
                      type="text"
                      className={classes.input}
                      name="firstname"
                      disableUnderline={true}
                    />
                    {errors?.firstname?.message && (
                      <span className={classes.errorField}>
                        {(errors as any).firstname.message}
                      </span>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <Grid container={true} spacing={3} className={classes.emailContainer}>
            <Grid item={true} xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                defaultValue={user.email || ''}
                rules={{
                  required: 'Champ obligatoire',
                  pattern: {
                    value: emailMatch,
                    message: 'Veuillez saisir une adresse e-mail valide',
                  },
                }}
                render={({ field }) => (
                  <>
                    <Input
                      placeholder="Email*"
                      {...field}
                      type="text"
                      name="email"
                      className={classes.input}
                      disabled={user.email}
                      disableUnderline={true}
                    />
                    {errors?.email?.message && (
                      <span className={classes.errorField}>{(errors as any).email.message}</span>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <PhoneInput
                inputProps={{
                  name: 'phone',
                }}
                specialLabel=""
                country="fr"
                value={current.phone || ''}
                onChange={handlePhoneNumberChange}
                masks={{
                  fr: '..........',
                }}
                preferredCountries={['fr', 'be', 'ch', 'ca']}
                preserveOrder={['preferredCountries']}
                inputStyle={{
                  width: '100%',
                  height: 49,
                  marginTop: 5,
                  borderRadius: 0,
                  border: `1px solid #E3E8F0`,
                }}
                inputClass={classes.phoneInputCustomStyle}
              />
              {formError?.phone && <span className={classes.errorField}>Champ obligatoire</span>}
            </Grid>
          </Grid>
          <Grid container={true} className={classes.interFieldsMarginTop}>
            <Grid item={true} xs={12} sm={12}>
              <div className={classes.dynamicDisabledSelect}>
                <div className={classes.placeholderContainer}>
                  <span className={classes.placeholderFirstPart}>
                    Contactez notre service formation pour :{' '}
                  </span>
                  <span>{test.label}</span>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container={true}
            className={clsx(classes.textareaContainer, classes.interFieldsMarginTop)}
          >
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{
                required: 'Champ obligatoire',
              }}
              render={({ field }) => (
                <>
                  <textarea
                    {...field}
                    className={classes.messageTextArea}
                    rows={10}
                    placeholder="Votre message*"
                  />

                  {errors?.message?.message && (
                    <span className={classes.errorField}>{(errors as any).message.message}</span>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid container={true} className={classes.cguMainContainer}>
            <Grid className={classes.cguContainer} item={true} xs={12} sm={12}>
              <Controller
                name="cgu"
                control={control}
                defaultValue={false}
                rules={{
                  required: 'Champ obligatoire',
                }}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    className={classes.checkBoxCgu}
                    disableFocusRipple={true}
                    disableRipple={true}
                    disableTouchRipple={true}
                    id="cgu"
                    color="primary"
                    data-cy="cgu-cy"
                  />
                )}
              />
              <label htmlFor="cgu">
                <h5 className={classes.cguAcceptText}>
                  En soumettant ce formulaire, j’accepte que les informations saisies dans ce
                  formulaire soient utilisées pour permettre à ORIENTACTION de me recontacter.*
                </h5>
              </label>
            </Grid>
            <Grid item={true} xs={12} sm={12}>
              {errors?.cgu?.message && (
                <span className={classes.errorField}>{(errors as any).cgu.message}</span>
              )}
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid className={classes.validateButtonContainer} item={true} xs={12} sm={12}>
              <Button type="submit" className={classes.validateButton} disabled={isLoading}>
                <span className={classes.validateButtonLabel}>Valider ma demande</span>
                {isLoading ? (
                  <CircularProgress size={14} color="secondary" />
                ) : (
                  <ArrowForwardIcon />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ContactForm;
