// eslint-disable-next-line simple-import-sort/imports
import { Grid, Typography } from '@material-ui/core';
import { Paragrapth, Page, TitleBgRed, Img, Title } from '@orientaction/components';
import useStyles from '../../style';
import { useLanguage } from '@orientaction/hooks';
import { useMemo } from 'react';

const Page33 = (props: any) => {
  const classe = useStyles();

  const { language } = useLanguage();

  const image = useMemo(() => {
    return language === 'GB' ? 'en_57.png' : '57.png';
  }, [language]);

  return (
    <Page id={33}>
      <Grid container={true} direction="row" justifyContent="center">
        <Grid item={true} md={12} xs={12}>
          <Paragrapth align="justify">
            Connaître ses valeurs, c’est savoir qui l’on est. Les valeurs sont ce qui ne changera
            pas en nous, elles sont le socle de toute notre vie ! Nos valeurs ont été forgées au
            cours de notre histoire. Nos parents nous ont transmis certains principes, puis l’école.
            Nous avons également fait de nombreuses rencontres dont certaines ont marqué à jamais
            nos vies. Nous avons fait des expériences et des choix. Nous avons vécu. Nos valeurs
            sont le produit de tout cela. Elles sont intimement liées à des souvenirs et à de
            puissantes émotions{' '}
            <Paragrapth align="start" colorBlue={true}>
              (colère, joie, enthousiasme, excitation, peur, etc.).
            </Paragrapth>
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">
            En étant à l’écoute de ses émotions, on découvre les valeurs qui constituent le socle de
            sa propre personnalité. Ce socle est ce sur quoi l’on peut s’appuyer pour agir. C’est un
            principe directeur, une motivation et une énergie.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">
            Connaître ses valeurs, c’est aussi savoir ce qu’on ne veut pas. Elles sont des principes
            intangibles. Les bafouer rend malheureux(se). Les respecter, c’est s’ouvrir à une source
            inépuisable de satisfaction. Vivre et travailler en cohérence avec ses valeurs, c’est
            vivre en harmonie avec soi-même.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">
            Afin de vous aider à mieux connaître vos valeurs, nous vous proposons de réaliser le
            test de la page suivante, spécialement élaboré par ORIENTACTION. Prenez votre temps pour
            cela, c’est un moment important, une étape essentielle pour mieux vous connaître, faire
            les bons choix, prendre confiance et réussir.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">
            Les lettres figurant en tête des colonnes du tableau sont les initiales des dix valeurs
            universelles théorisées par Shalom Schwartz, psychologue social israélien.
          </Paragrapth>
        </Grid>
      </Grid>
      <Grid container={true} spacing={7} style={{ marginTop: '15px' }}>
        <Grid item={true} md={6} xs={12}>
          <Img src={image} alt="Img" style={{ width: '100%' }} />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Paragrapth align="justify">
            Après avoir réalisé le test, entourez les lettres dont les notes sont les plus fortes
            puis découvrez leur description dans les pages suivantes.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">
            Cet exercice en deux temps vous permettra de mieux cerner vos valeurs principales,
            celles qui guideront vos pas à l’avenir.
          </Paragrapth>
          <br />
          <br />
          <Paragrapth align="justify">Rappelez-vous ces mots de Benjamin Franklin :</Paragrapth>
          <br />
          <br />
          <br />
          <Paragrapth align="center" colorBlue={true}>
            <strong>
              « Il y a trois choses extrêmement dures : le diamant, l’acier et se connaître soi-même
              ».
            </strong>
          </Paragrapth>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Page33;
