/* eslint-disable prettier/prettier */
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  loader: {
    color: 'rgb(19, 67, 119)',
  },
  text: {
    marginLeft: theme.spacing(2),
    color: 'rgb(19, 67, 119)',
  },
}));

const Loader = ({
  open,
  text,
  textColor,
}: {
  open: boolean;
  text?: string;
  textColor?: string;
}) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Paper className={classes.loaderContainer}>
        <CircularProgress className={classes.loader} />
        <Typography
          variant="h6"
          className={classes.text}
          style={{ color: textColor || 'rgb(19, 67, 119)' }}
        >
          {text || 'Chargement en cours...'}
        </Typography>
      </Paper>
    </Backdrop>
  );
};

export default Loader;
