import './index.css';
import HyperDX from '@hyperdx/browser';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { getHdxServiceName } from './utils';
import initFacebookSDK from './utils/initFacebookSDK';

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
};

// if (process?.env?.REACT_APP_HDX_KEY) {
//   const url = process.env.REACT_APP_API_FRONT_URL;
//   const apiUrl = process.env.REACT_APP_API_ENDPOINT;

//   const urlWithoutHttps = url?.replace('https://', '');
//   const apiUrlWithoutHttps: string = apiUrl?.replace('https://', '') || '';

//   const serviceName = getHdxServiceName(urlWithoutHttps);
//   console.log('serviceName...', serviceName);

//   if (serviceName) {
//     HyperDX.init({
//       apiKey: process.env.REACT_APP_HDX_KEY,
//       service: serviceName,
//       tracePropagationTargets: [new RegExp(apiUrlWithoutHttps, 'i')],
//       consoleCapture: true,
//       advancedNetworkCapture: true,
//     });
//   }
// }

initFacebookSDK().then(() => {
  renderApp();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
