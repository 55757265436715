import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      margin: '0 auto',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      marginBottom: '2vh',
      color: '#23296F',
      textTransform: 'uppercase',
      wordBreak: 'normal',
      [theme.breakpoints.down(800)]: {
        fontSize: '14px',
      },
    },
    content: {
      backgroundColor: '#FFFFFF',
      borderRadius: '16px',
      padding: '1.5vh',
      margin: '3vh 0',
    },
    description: {
      wordBreak: 'normal',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      color: '#0E1247',
      lineHeight: '180%',
      margin: '0 auto',
      [theme.breakpoints.down(800)]: {
        margin: '2vh 0',
        lineHeight: '150%',
        fontSize: '16px',
        width: '100%',
      },
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    elementContent: {
      width: '95%',
      marginLeft: '15px',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down(800)]: {
        marginLeft: '0px',
        width: '100%',
        flexDirection: 'column-reverse',
      }
    },
    contentFlex: {
      margin: '2vh 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    switch: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      textTransform: 'uppercase',
      color: '#23296F'
    }
  }),
);
