/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';

interface IProps {
  handleAccountConfirm: any;
  showAccountConfirm: boolean;
}

const RegisterForm: FC<IProps> = ({ handleAccountConfirm, showAccountConfirm }) => {
  const [step, setStep] = useState(0);

  const [current, setCurrent] = useState<any>({});

  const handlePrevious = ({ data }: any) => {
    setStep(0);
    setCurrent(data);
  };

  return (
    <>
      {step === 0 && (
        <Step1
          nextStep={setStep}
          setData={setCurrent}
          showAccountConfirm={showAccountConfirm}
          handleAccountConfirm={handleAccountConfirm}
          dataStep1={current}
        />
      )}
      {step === 1 && (
        <Step2
          handlePrevious={handlePrevious}
          dataStep1={current}
          handleAccountConfirm={handleAccountConfirm}
          showAccountConfirm={showAccountConfirm}
        />
      )}
    </>
  );
};

export default RegisterForm;
