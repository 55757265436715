/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, TitleNbr } from '@orientaction/components';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { useBook3 } from "@orientaction/hooks_book";
import clsx from 'clsx';
import { context } from './context';
import Item from './item';
import useStyles from './style';

const Page35 = (props: any) => {

    const classes = useStyles();

    const { stylePersonnel, setStylePersonnel } = useBook3();

    const handleChange = (e: any) => {

        const { name, checked } = e.target;

        const nameIt = name.split('-')[1];

        const groupBy = name.split('-')[0];

        if (checked) {
            stylePersonnel[groupBy] = [nameIt];
        }

        if (!checked) {
            stylePersonnel[groupBy] = []
        }

        setStylePersonnel(stylePersonnel);

    }

    const clickAction = (data: any) => (e: any) => {
        e.preventDefault();
        handleChange({ target: data });
    }

    return (
        <Page id={35} readOnly={props.readOnly}>

            <MarginContainer>
                <Item
                    label1='II'
                    label2='2<sup>e</sup> PARTIE : QUEL EST VOTRE « STYLE PROFESSIONNEL » ?'
                />
            </MarginContainer>

            <MarginContainer>
                <Paragrapth align='justify'>
                    Voici 12 questions suivies de 3 propositions de réponse, désignées par les lettres X, Y et Z .
                    Pour chaque question, choisissez la réponse qui vous caractérise le mieux
                    en entourant la lettre choisie. Vous ne devez choisir qu’une seule réponse.
                </Paragrapth>
            </MarginContainer>

            <Grid
                container={true}
                direction='row'
            >

                {
                    [
                        {
                            start: 0,
                            end: 6
                        },
                        {
                            start: 6,
                            end: 12
                        }
                    ].map((el: any) => (
                        <>
                            <Grid item={true} xs={12} sm={12} md={6}>

                                {
                                    context.slice(el.start, el.end).map((item: any) => (

                                        <div className={classes.bodyContent}>
                                            <MarginContainer>

                                                <TitleNbr
                                                    number={item.id}
                                                    text={item.title || ""}
                                                />

                                                <div className={classes.contentAsk}>

                                                    {
                                                        item.ask.map((itemAsk: any, index: number) => <>

                                                            <Grid
                                                                container={true}
                                                                direction='row'
                                                                justifyContent="center"
                                                                alignItems="center"
                                                            >

                                                                <Grid item={true} xs={2}>
                                                                    <div
                                                                        className={clsx({
                                                                            [classes.desactived]: true,
                                                                            [classes.actived]: (stylePersonnel[item.id] || []).includes(itemAsk.name)
                                                                        })}
                                                                        onClick={clickAction({
                                                                            name: `${item.id}-${itemAsk.name}`,
                                                                            checked: !(stylePersonnel[item.id] || []).includes(itemAsk.name)
                                                                        })}
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        {itemAsk.name}.
                                                                    </div>

                                                                </Grid>

                                                                <Grid item={true} xs={10}>

                                                                    <div
                                                                        onClick={clickAction({
                                                                            name: `${item.id}-${itemAsk.name}`,
                                                                            checked: !(stylePersonnel[item.id] || []).includes(itemAsk.name)
                                                                        })}
                                                                        style={{
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >
                                                                        <Paragrapth
                                                                            align='justify'
                                                                            colorBlue={true}
                                                                        >
                                                                            {itemAsk.label || ""}
                                                                        </Paragrapth>
                                                                    </div>

                                                                </Grid>

                                                            </Grid>

                                                        </>)
                                                    }

                                                </div>

                                            </MarginContainer>

                                        </div>

                                    ))
                                }
                            </Grid>

                        </>))}

            </Grid>

        </Page>
    );
};

export default Page35;
