/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */

import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { imgBook2 } from '../../../../utils/constants';
import clsx from 'clsx';
import { TitleNbr, Page, TableActivity } from '@orientaction/components';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC, useEffect, useState } from 'react';
import { useBook2 } from "@orientaction/hooks_book";

const Page30 = (props: any) => {
  const classes = useStyles();
  const activities = [
    {
      title: "DÉCIDER",
      tableau: [
        'Arrêter',
        'Choisir',
        'Conclure',
        'Déterminer',
        'Éliminer',
        'Fixer',
        'Juger',
        'Opter',
        'Régler',
        'Résoudre',
        'Trancher',
      ],
    },
    {
      title: "GÉRER",
      tableau: [
        'Acquérir',
        'Amortir',
        'Budgéter',
        'Comptabiliser',
        'Consolider',
        'Économiser',
        'Enrichir',
        'Équilibrer',
        'Exploiter',
        'Gagner',
        'Investir',
        'Optimiser',
        'Rentabiliser',
      ],
    },
    {
      title: "DIRIGER",
      tableau: [
        'Animer',
        'Commander',
        'Conduire',
        'Confier',
        'Définir',
        'Déléguer',
        'Gouverner',
        'Guider',
        'Impulser',
        'Inspirer',
        'Instituer',
        'Manager',
        'Piloter',
        'Présider',
      ],
    },
    {
      title: "ADMINISTRER",
      tableau: [
        'Classer',
        'Compter',
        'Enregistrer',
        'Établir',
        'Gérer',
        'Inventorier',
        'Ranger',
        'Recenser',
        'Régir',
        'Répertorier',
      ],
    },
    {
      title: "PRODUIRE",
      tableau: [
        'Appliquer',
        'Effectuer',
        'Exécuter',
        'Faire',
        'Réaliser',
      ],
    },
  ];
  const activities1 = [
    {
      title: "ORGANISER",
      tableau: [
        'Aménager',
        'Anticiper',
        'Arranger',
        'Coordonner',
        'Distribuer',
        'Établir',
        'Planifier',
        'Préparer',
        'Prévoir',
        'Programmer',
        'Répartir',
        'Structurer',
      ],
    },
    {
      title: "COMMUNIQUER",
      tableau: [
        'Dialoguer',
        'Discuter',
        'Échanger',
        'Écouter',
        'Exprimer',
        'Informer',
        'Interviewer',
        'Négocier',
        'Partager',
        'Rédiger',
        'Renseigner',
        'Transmettre',
      ],
    },
    {
      title: "DÉVELOPPER",
      tableau: [
        'Accroître',
        'Améliorer',
        'Augmenter',
        'Commercialiser',
        'Conquérir',
        'Élargir',
        'Étendre',
        'Déclencher',
        'Implanter',
        'Lancer',
        'Progresser',
        'Promouvoir',
      ],
    },
    {
      title: "CHERCHER",
      tableau: [
        'Analyser',
        'Calculer',
        'Consulter',
        'Enquêter',
        'Étudier',
        'Examiner',
        'Expérimenter',
        'Observer',
        'Prospecter',
        'Rechercher',
        'Sonder',
      ],
    },
    {
      title: "FORMER",
      tableau: [
        'Animer',
        'Apprendre',
        'Conduire',
        'Développer',
        'Éduquer',
        'Entraîner',
        'Éveiller',
        'Instruire',
        'Sensibiliser',
        'Transformer',
      ],
    },
  ];
  const activities2 = [
    {
      title: "CONTRÔLER",
      tableau: [
        'Vérifier',
        'Enquêter',
        'Éprouver',
        'Évaluer',
        'Examiner',
        'Expérimenter',
        'Mesurer',
        'Prouver',
        'Superviser',
        'Surveiller',
        'Tester',
        'Valider',
      ],
    },
    {
      title: "CRÉER",
      tableau: [
        'Adapter',
        'Améliorer',
        'Concevoir',
        'Construire',
        'Découvrir',
        'Élaborer',
        'Imaginer',
        'Innover',
        'Inventer',
        'Renouveler',
        'Transformer',
        'Trouver',
      ],
    },
    {
      title: "NÉGOCIER",
      tableau: [
        'Acheter',
        'Argumenter',
        'Conclure',
        'Consulter',
        'Convaincre',
        'Démontrer',
        'Discuter',
        'Influencer',
        'Persuader',
        'Arbitrer',
        'Proposer',
        'Sélectionner',
      ],
    },
    {
      title: "CONSEILLER",
      tableau: [
        'Aider',
        'Clarifier',
        'Comprendre',
        'Diagnostiquer',
        'Éclairer',
        'Écouter',
        'Guider',
        'Inciter',
        'Orienter',
        'Préconiser',
        'Proposer',
        'Recommander',
      ],
    },
    {
      title: "AUTRES",
      tableau: [
        'À préciser'
      ],
    },
  ];
  const { setVerbes, verbes } = useBook2();
  const [defaultValue, setDefaultValue] = useState<any>(verbes);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, title: string) => {
    const found = findIndex(defaultValue, function(o: any) { return o.title === title && o.value === (event.target as HTMLInputElement).value})
    let data = cloneDeep(defaultValue);
    if (found === -1) {
      if (defaultValue.length < 10) {
        const newData = {
          title,
          value: (event.target as HTMLInputElement).value,
        };
        data = [...data, newData];
      }
    } else {
      data.splice(found, 1);
    }
    setVerbes(data);
    setDefaultValue(data);
  };

  return (
    <Page id={32} pageNoTruncate={true} readOnly={props.readOnly}>
      <div style={{ marginBottom: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h1" component="h1" gutterBottom={true} className="opacity07">
            LES ACTIVITÉS QUI VOUS RENDENT
            <br />
            HEUREUX(SE) ET PERFORMANT(E)
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            La motivation instrumentale s’appuie sur le « plaisir de faire ». Le plaisir procuré par 
            l’activité permet souvent, par effet de répétition, de développer un haut niveau 
            d’efficacité dans cette activité<sup>2</sup>. 
          </Typography>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter' }}>
            L’objectif de cet exercice est justement d’identifier ces activités que vous aimez 
            faire et dans lesquelles vous êtes bon(ne). Pour cela, au sein de cette liste de 
            verbes d’action, soulignez dix verbes correspondant à des actions qui vous rendent 
            heureux(se) et dans lesquelles vous êtes performant(e). 
          </Typography>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Grid container={true} >
            {activities.map((activity: any) => {
              return (
                <Grid item={true} sm={true} xs={12} style={{maxHeight: '100%'}}>
                  <TableActivity activity={activity} onChange={handleChange} defaultPropsValue={defaultValue} />
                </Grid>
              )
            })}
          </Grid>
          <Grid container={true} >
            {activities1.map((activity: any) => {
              return (
                <Grid item={true} sm={true} xs={12} style={{maxHeight: '100%'}}>
                  <TableActivity activity={activity} onChange={handleChange} defaultPropsValue={defaultValue} />
                </Grid>
              )
            })}
          </Grid>
          <Grid container={true} >
            {activities2.map((activity: any) => {
              return (
                <Grid item={true} sm={true} xs={12} style={{maxHeight: '100%'}}>
                  <TableActivity activity={activity} onChange={handleChange} defaultPropsValue={defaultValue} />
                </Grid>
              )
            })}
          </Grid>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '20px', textAlign: 'justify', fontWeight: 'lighter',fontSize: '12px' }}>
            <sup>2</sup>Prenez le cas d’une personne qui aime « taper à l’ordinateur ». Elle aura tendance à écrire 
            très souvent à l’ordinateur. Au début, elle sera malhabile, mais à force de répéter cette action, 
            de s’entraîner, elle finira par savoir taper à l’ordinateur extrêmement vite et sans commettre 
            d’erreur. C’est ce type d’activité que nous cherchons à vous faire identifier ici. 
          </Typography>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px'
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    opacity07: {
      opacity: 0.7
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe2: {
      color: '#000A8C',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
          textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
          textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto'
    },
    cardBgGrey: {
      padding: 15,
      background: '#E6E6E6'
    },
    number: {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      borderRadius: '50%',
      padding: '7px',
      background: '#fff350',
    },
    h3: {
      fontSize: '25px',
      display: 'flex',
      width: 'calc(100% - 20px)',
      alignItems: 'center',
      marginRight: '10px',
      marginLeft: '10px',
      color: '#000A8C',
      fontStyle: 'bold',
      textAlign: 'left',
    },
  })
);
export default Page30;
