/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { DrawerRight } from '@orientaction/components';
import React, { FC } from 'react';
import CreateEditEntreprise from './CreateEditEntreprise';

interface IForm {
  setOpenDrawer: (data: boolean) => void;
  openDrawer: boolean;
  isView: boolean;
  isUpdate: boolean;
  data: any;
  cancel: () => void;
  actionAdmin: (data: any) => void;
}

const Form: FC<IForm> = ({
  setOpenDrawer,
  openDrawer,
  data,
  isView,
  isUpdate,
  cancel,
  actionAdmin,
}) => {
  const callBackDrawer = () => {
    setOpenDrawer(false);
  };

  const returnMode = () => {
    if (isView) {
      return 'View';
    }

    if (isUpdate) {
      return 'Update';
    }

    return 'Create';
  };

  const returnDate = () => {
    if (isView || isUpdate) {
      return data;
    }

    return {};
  };

  const titleDrawer = () => {
    if (isView) {
      return 'Consulter l’espace entreprise';
    }

    if (isUpdate) {
      return 'Modifier l’espace entreprise';
    }

    return 'Création un espace entreprise';
  };

  return (
    <DrawerRight openDrawer={openDrawer} callBack={callBackDrawer} titleDrawer={titleDrawer()}>
      <CreateEditEntreprise
        setOpenDrawer={setOpenDrawer}
        cancel={cancel}
        data={returnDate()}
        mode={returnMode()}
        openDrawer={openDrawer}
        actionAdmin={actionAdmin}
      />
    </DrawerRight>
  );
};

export default Form;
