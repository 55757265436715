/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setMessage = (message: string): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'MESSAGE',
            payload: { message: message as any }
        });
    };


export const setOpen = (open: boolean): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'OPEN',
            payload: { open: open as any }
        });
    };

export const setSeverity = (severity: string): AppThunk =>
    (dispatch) => {
        dispatch({
            type: 'SERVEITY',
            payload: { severity: severity as any }
        });
    };

