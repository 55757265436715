import { makeStyles, Theme } from '@material-ui/core/styles';

const style = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '95%',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldWithLabel: {
    marginTop: 20,
    width: '100%',
  },
  errorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginTop: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  inputPassword: {
    paddingRight: 5,
  },
  labelText: {
    margin: 'auto',
    color: '#0E1247',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
  },
  btnConnectContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  btnConnect: {
    width: '60%',
    backgroundColor: '#000A8C',
    borderRadius: 3,
    opacity: 1,
    textTransform: 'none',
    color: '#fff',
    alignSelf: 'center',
    paddingTop: 25,
    paddingBottom: 25,
    '& .MuiButton-label': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
    },
    '&:hover': {
      color: '#000A8C;',
      border: '1px solid #000A8C',
    },
  },
  loginLink: {
    marginTop: 20,
    cursor: 'pointer',
  },
  loginLinkText: {
    color: '#000A8C',
    textDecoration: 'underline',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    marginTop: 9,
    marginBottom: 5,
    '@media (max-width: 600px)': {
      fontSize: 12,
    },
  },
  passwordErrorField: {
    color: theme.palette.warning.main,
    font: 'normal normal bold 10px/14px ITC Avant Garde Gothic Std Medium',
    minHeight: 20,
    maxWidth: 330,
  },
  eyeImg: {
    cursor: 'pointer',
    zIndex: 1,
  },
  inputWitAdornment: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  formContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  checkBoxCguContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
  checkBoxCgu: {
    '&:focus, &.Mui-focusVisible': {
      boxShadow: 'none',
    },
    '&:hover': {
      background: 'none',
    },
    '&.Mui-checked': {
      color: `${theme.palette.primary.main} !important`,
      '&:hover': {
        background: 'none',
      },
    },
    marginLeft: -13,
  },
  cguLinkText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.primary.main,
    '@media (max-width: 600px)': {
      fontSize: 12,
    },
  },
  cguAcceptText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.primary.main,
    '@media (max-width: 600px)': {
      fontSize: 10,
    },
  },
}));

export default style;
