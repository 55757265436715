import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  accordionContainer: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: 10,
  },
  btnPrimary: {
    textTransform: 'none',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&.MuiButton-root.Mui-disabled': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  textHeader: {
    display: 'none',
    marginLeft: 0,
    '@media(min-width: 600px)': {
      display: 'block',
    },
  },
  grab: {
    cursor: 'move',
  },
  fullWidth: {
    width: '100%',
  },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 700,
  },
  modalContent: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  modalText: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 500,
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: 15,
  },
}));
