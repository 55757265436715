export const ContextLisa = [
    `Lisa est responsable communication dans une PME spécialisée
dans la vente de véhicules automobiles. Elle est employée dans
un service de deux personnes. L’entreprise est située à plus de
45 minutes de trajet de son domicile.`,
    `Lisa a vingt-cinq ans et trois ans d’expériences professionnelles
réussies. Consciente que son entreprise actuelle ne peut pas
lui offrir de perspectives, elle décide de changer. Elle se met
à « l’écoute du marché ». Deux mois après le début de ses
recherches, elle trouve une opportunité dans un cabinet conseil.`,
    `L’entreprise, située à seulement vingt-cinq minutes de trajet
de son domicile, lui propose un poste de cadre avec une
rémunération de 500 euros brut supérieure à celle perçue sur
son ancien poste. Lisa saute le pas et décide de changer !`
]


