/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { publicSvgPath, dataPreference } from '@orientaction/utils';
import { useEffect, useState } from 'react';
import CircularProgressCustom from '../CircularProgressCustom';
import ModalCardPref from '../ModalCardPref';
import { useStyles } from './style';

const CardPreference = (props: any) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isTab = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [showLess, setShowLess] = useState<boolean>(true);

  useEffect(() => {
    setShowLess(isTab);
  }, [isTab]);

  return (
    <Card className={classes.cardStyle}>
      <div>
        <div
          className={classes.titlehead}
          style={{ background: dataPreference[props.pref.category].color }}>
          <h3 className={classes.titleText}>{props.pref.title}</h3>
        </div>
        <img
          src={`${publicSvgPath}/${dataPreference[props.pref.category][props.pref.key]?.img}`}
          alt=""
          style={{ width: '100%', height: 'auto' }}
        />
        <CardContent className={classes.cardContent}>
          <div className={classes.positionCircular}>
            <CircularProgressCustom
              value={
                !props.pref.score[0].isMax
                  ? props.pref.score[1].pourcentage
                  : props.pref.score[0].pourcentage
              }
              color={dataPreference[props.pref.category].color}
              size={103}
              fontSize={18}
              fontSizeFloat={12}
              isPercent={true}
            />
          </div>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.titleContent}
            style={{ textTransform: 'uppercase' }}>
            VOUS ÊTES {props.pref.youAre}
          </Typography>
          <br />
          <Typography variant="body2" color="textSecondary" component="p">
            <div style={{ color: 'black', marginBottom: '10px', fontSize: 16 }}>
              Votre style général
            </div>
            <span
              className={`${showLess ? classes.limitText : classes.noLimit} `}
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line react/destructuring-assignment
                __html: dataPreference[props.pref.category][props.pref.key]?.text1,
              }}
            />
          </Typography>
          {isTab && (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              onClick={() => setShowLess(!showLess)}
              style={{
                color: 'black',
                margin: '17px 0px 20px',
                fontSize: 16,
                textDecoration: 'underline',
                textUnderlineOffset: 2,
              }}>
              {showLess ? 'Voir plus' : 'Voir moins'}
            </Typography>
          )}
        </CardContent>
      </div>
      <CardActions className={classes.cardFooter}>
        <Button
          endIcon={<img src={`${publicSvgPath}/bouton_click.svg`} alt="couverture intro" />}
          onClick={() => setIsOpen(true)}
          style={{ textTransform: 'none' }}>
          En savoir plus
        </Button>
      </CardActions>
      <ModalCardPref
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        color={dataPreference[props.pref.category]?.color}
        img={`${publicSvgPath}/${dataPreference[props.pref.category][props.pref.key]?.img}`}
        label={props.pref.label}
        title={props.pref.title}
        colorSecondary={dataPreference[props.pref.category]?.secondColor}
        opacity={!!dataPreference[props.pref.category]?.opacity}
        value={
          !props.pref.score[0].isMax
            ? props.pref.score[1].pourcentage
            : props.pref.score[0].pourcentage
        }
        text1={dataPreference[props.pref.category][props.pref.key]?.text1}
        text2={dataPreference[props.pref.category][props.pref.key]?.text2}
        list1={dataPreference[props.pref.category][props.pref.key]?.list1}
        list2={dataPreference[props.pref.category][props.pref.key]?.list2}
        youAre={props.pref.youAre}
        listText={dataPreference[props.pref.category][props.pref.key]?.listText}
      />
    </Card>
  );
};

CardPreference.defaultProps = {
  isSoftSkills: false,
};

export default CardPreference;
