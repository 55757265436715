export const listExp = [
    {
        label: 'Expérience n°1',
        name: 'exp1'
    },
    {
        label: 'Expérience n°2',
        name: 'exp2'
    },
    {
        label: 'Expérience n°3',
        name: 'exp3'
    }
]