/* eslint-disable no-use-before-define */
import { Grid, Typography } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth } from '@orientaction/components';
import clsx from 'clsx';
import SimpleBanner from '../../../components/SimpleBanner';
import Img from '../../Img';
import { ContextLisa } from './context';
import useStyles from './style';

const Page27 = (props: any) => {
  const classes = useStyles();

  return (
    <Page id={27}>
      <div className={classes.cardBgGrey}>
        <MarginContainer>
          <Grid container={true} justifyContent="center" alignItems="flex-start">
            <Grid item={true} xs={12} md={12}>
              {/* <Img
                src="progression_lisa.jpg"
                alt="progression_lisa"
                style={{
                  width: '100%',
                }}
              /> */}
              <SimpleBanner>
                <Typography
                  component="h1"
                  className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
                >
                  LA PROGRESSION DE LISA
                </Typography>
              </SimpleBanner>
            </Grid>

            <Grid item={true} xs={12} md={3}>
              <Img src="Lisa.jpg" alt="Lisa" className={classes.img} />
            </Grid>

            <Grid item={true} xs={12} md={9}>
              {ContextLisa.map((itemList: any) => (
                <MarginContainer>
                  <Paragrapth align="start" addBackground={true}>
                    {itemList}
                  </Paragrapth>
                </MarginContainer>
              ))}
            </Grid>
          </Grid>
        </MarginContainer>
      </div>
    </Page>
  );
};

export default Page27;
