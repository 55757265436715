import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  variableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  },
  input: {
    flex: 1,
    border: `1px solid #E3E8F0`,
    width: '100%',
    marginBottom: 5,
    height: 48,
    '&:before': {
      display: 'none',
    },
    '& input': {
      fontSize: 13,
      padding: 10,
    },
  },
  h4: {
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 900,
    color: '#0E1247',
    marginLeft: 10,
  },
}));
