import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  subtitle: {
    color: '#000A8C',
    fontSize: 26,
    marginTop: 15,
  },
  visibilityPermission: {
    width: '100%',
  },
  select: {
    fontFamily: 'Poppins',
  },
  textError: {
    color: 'red',
  },
}));
