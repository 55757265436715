/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setCurrent =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'CURRENT',
        payload: data,
      });
    };

export const setActived =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'ACTIVED',
        payload: data,
      });
    };

export const setListAdminUser =
  (data: any[]): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'LISTADMINUSER',
        payload: data,
      });
    };

export const setIdCurrent =
  (id: string): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'IDCURRENT',
        payload: id,
      });
    };

export const setLoadingList =
  (bool: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'LOADINGLIST',
        payload: bool,
      });
    };

export const setOpenDrawer =
  (bool: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'OPENDRAWER',
        payload: bool,
      });
    };

export const setCompany =
  (number: number): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'COMPANY',
        payload: number,
      });
    };

export const setUserTypeConnected =
  (data: number): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'USERSTYPECONNECTED',
        payload: data,
      });
    };

export const setDialog =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'DIALOG',
        payload: data,
      });
    };

export const setTabs =
  (data: number): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'TABS',
        payload: data,
      });
    };

export const setLoading =
  (data: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'LOADING',
        payload: data,
      });
    };

export const setReadOnly =
  (data: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'READONLY',
        payload: data,
      });
    };

export const setViewDisable =
  (data: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'VIEWDISABLE',
        payload: data,
      });
    };

export const setLoadingHierachy =
  (data: boolean): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'LOADINGHIERACHY',
        payload: data,
      });
    };

export const setlistAdminPanel =
  (data: any[]): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'LISTADMINPANEL',
        payload: data,
      });
    };

export const setCountTabs =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'COUNT_TABS',
        payload: data,
      });
    };

export const setCurrentPagination =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'PAGE',
        payload: data,
      });
    };

export const setTextFilter =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'TEXT_FILTER',
        payload: data,
      });
    };

export const setListUphierchy =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'LIST_UPHIERCHY',
        payload: data,
      });
    };

export const setRattachement =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'RATTACHEMENT',
        payload: data,
      });
    };

export const setActiveSubmit =
  (data: any): AppThunk =>
    (dispatch) => {
      dispatch({
        type: 'ACTIVESUBMIT',
        payload: data,
      });
    };
