import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  pie: {
    width: 300,
    height: 300,
    '@media only screen and (max-width: 600px)': {
      width: 250,
      height: 250,
    },
  },
}));

export default useStyles;
