/* eslint-disable no-use-before-define */

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TitleNbr } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook2 } from '../../../../utils/constants';

const Page07 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={7} pageNoTruncate={true}>
      <div style={{ marginTop: '50px' }}>
        <div>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="h1"
              component="h1"
              gutterBottom={true}
              className="opacity07">
              POURQUOI NOUS AVONS CRÉÉ
              <br />
              CETTE MÉTHODE ET CE QUI VA VOUS
              <br />
              ARRIVER EN L’APPLIQUANT
            </Typography>
          </div>
          <div className={classes.colorPrimaryBook} style={{ textAlign: 'center', marginTop: '50px' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20
              }}>
              <strong>‘‘ La meilleure façon de prédire l’avenir, c’est de le créer. ’’</strong>
            </Typography>
            <br />
            <Typography
              className={classes.testimony}
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter',fontSize: 20, }}>
              Peter Drucker
            </Typography>
          </div>
          <div
            className={clsx(classes.positionCenter)}
            style={{ marginTop: '40px', marginBottom: '40px' }}
          />
          <div style={{ textAlign: 'center' }}>
            <Typography
              className={classes.testimony}
              style={{
                fontFamily: 'ITC Avant Garde Gothic Std Medium',
                fontWeight: 'bold',
                fontStyle: 'italic',
                fontSize: 20,
              }}>
              <strong>
                ‘‘ Pour s’améliorer, il faut changer. Donc, pour être parfait, il faut avoir changé
                souvent. ’’
              </strong>
            </Typography>
            <br />
            <Typography
              style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', fontWeight: 'lighter',fontSize: 20 }}>
              Winston Churchill
            </Typography>
          </div>
        </div>
      </div>
      <div className={clsx(classes.trait, classes.positionCenter)} style={{ marginTop: '50px', marginBottom: '50px' }} />
      <div >
        <TitleNbr number={1} text="CHANGER EN MODE SÉRÉNITÉ" isLight={true} />
        <div className={classes.cardBgGrey} style={{marginTop: 20}}>
          <Grid container={true} justifyContent="center">
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Je voudrais faire autre chose… Parfois, j’ai envie de tout quitter, 
                    de partir vivre à l’autre bout du monde !’’
                </Typography>
                <br />
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Thibault, 35 ans, cadre dans la fonction publique
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘J’en ai assez, il faut que cela change !’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Estelle, 43 ans, boulangère
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Ce travail ne me plaît pas et cela fait 15 ans que cela dure.
                  Je ne comprends pas pourquoi je n’ai pas changé avant… Je veux changer de vie !’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Xavier, 38 ans, comptable
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Je n’ai qu’un désir, exercer un autre métier, mais je ne sais pas quoi faire… Je voudrais
                  changer, mais pensez-vous que cela soit raisonnable en ce moment ?’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Frantz, technicien informatique, 33 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Je m’ennuie tellement, j’aurais dû changer avant, mais je n’ai pas osé…’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Hubert, gestionnaire d’assurances, 55 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Changer, pourquoi pas, mais si je change, est-ce que je suis sûre de retrouver quelque
                  chose de mieux ?’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Laura, commerciale, 28 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Je n’ai jamais aimé mon métier, ni mes collègues, ni mon patron;
                  pourtant j’exerce ce métier depuis 35 ans…’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Sylvie, responsable de magasin, 56 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘J’ai plein de projets en tête, mais je ne sais pas si cela va marcher.’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Théo, vendeur, 22 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘J’ai un rêve, créer ma propre entreprise, mais mon conjoint n’est pas d’accord.
                  Il me dit que je ne suis pas réaliste…’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Estelle, esthéticienne, 31 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘J’en ai parlé à mon responsable des ressources humaines : il m’a répondu que ce n’était
                  pas possible! Pourtant, c’est vraiment ce que je voudrais faire, enfin je crois…’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Hubert, gestionnaire de patrimoine, 57 ans
                </Typography>
              </div>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item={true} xs={12}>
              <div style={{ textAlign: 'center' }}>
                <Typography
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 13,
                    color: 'black',
                  }}>
                  ‘‘Je sais que je suis faite pour faire autre chose, mais je ne sais pas quoi !’’
                </Typography>
                <br />
                <Typography
                  color="primary"
                  className={classes.testimony}
                  style={{
                    fontFamily: 'ITC Avant Garde Gothic Std Medium',
                    fontWeight: 'lighter',
                    fontSize: 12,
                  }}>
                  Catherine, photographe, 48 ans
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      minHeight: '1150px',
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    paragraphe1: {
      fontSize: '12px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 40,
      background: '#e8eef4',
      textAlign: 'center',
    },
    testimony: {
      fontSize: '20px',
      color: "#2b56a5"
    },
    colorPrimaryBook : {
      color: "#2b56a5"
    },
    divider: {
      marginTop: '30px',
      marginBottom: '30px',
      width: "80%",
    }
  })
);
export default Page07;
