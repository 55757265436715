import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import { appLanguage } from '@orientaction/reducers';
import clsx from 'clsx';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './style';

interface IProps {
  img: string;
  favoriteVariable: any;
}

const PyramidBlock: FC<IProps> = ({ img, favoriteVariable }) => {
  const theme: any = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const lang: string = useSelector(appLanguage);

  const getImg = (key: string): string => {
    switch (key) {
      case 'physiological_pro':
        return '/images/need/Physiologique.png';

      case 'security_pro':
        return '/images/need/en_secPyramid.png';

      case 'horizontal_belonging_pro':
        return '/images/need/en_Appartenance_H.png';

      case 'vertical_belonging_pro':
        return '/images/need/en_appartenance_V.png';

      case 'accomplishment_pro':
        return '/images/need/en_accomplissement.png';

      case 'self_realization_pro':
        return '/images/need/en_realisation_soi.png';

      case 'spirituality_pro':
        return '/images/need/en_spiritualite.png';

      case 'eternity_pro':
        return '/images/need/en_eternity.png';

      default:
        return '';
    }
  };

  return (
    <Grid className={classes.needProPyramidBlockContainer} container={true}>
      <Grid className={classes.pyramidItem} item={true} md={8} lg={8} xs={12}>
        <Grid className={classes.contentNoPadding} container={true}>
          <Grid item={true} md={5} sm={12}>
            <div className={classes.padding25}>
              {!isSmallScreen && (
                <Typography variant="h4" color="primary" className={`${classes.title}`}>
                  PYRAMIDE DES BESOINS
                </Typography>
              )}

              <Typography
                variant="body2"
                color="primary"
                className={clsx(
                  classes.description1,
                  classes.paragraphMarginTop,
                  classes.mobileNoParagraphMarginTop,
                )}
              >
                Voici votre besoin principal dans votre vie professionnelle
              </Typography>

              <Typography
                variant="h4"
                color="primary"
                className={classes.favoriteLabel}
                style={{ color: favoriteVariable?.color }}
              >
                {favoriteVariable?.label}
              </Typography>

              <Typography
                variant="body2"
                color="primary"
                className={clsx(classes.description2, classes.paragraphMarginTop)}
              >
                Voici votre besoin principal dans votre vie professionnelle. La satisfaction de vos
                besoins est à la source de votre bien-être et de votre motivation. Il est important
                que votre métier, vos conditions de travail et de rémunération, ainsi que vos
                relations professionnelles contribuent pleinement à satisfaire ce besoin.
              </Typography>
            </div>
          </Grid>

          <Grid className={classes.pyramidContainer} item={true} md={7} sm={12}>
            <img
              src={lang !== 'FR' ? getImg(favoriteVariable.key) : img}
              className={classes.imgPyramid}
              alt="Pyramide"
            />
          </Grid>
          {isSmallScreen && (
            <Typography
              variant="h4"
              color="primary"
              className={clsx(classes.title, classes.smallScreenTitle)}
            >
              PYRAMIDE DES BESOINS
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid className={classes.applicationItem} item={true} md={4} lg={4} xs={12}>
        <Grid
          direction="column"
          className={classes.content2}
          container={true}
          xs={12}
          style={{ paddingBottom: 16 }}
        >
          <Typography variant="h6" color="primary" className={`${classes.title}`}>
            APPLICATIONS CONCRÈTES
          </Typography>

          <Typography variant="h6" color="primary" className={`${classes.title}`}>
            DANS VOTRE VIE PROFESSIONNELLE
          </Typography>

          <Typography
            variant="body2"
            color="primary"
            className={clsx(classes.description2, classes.paragraphMarginTop)}
          >
            Si vous ne vous sentez pas pleinement satisfait(e) dans votre vie professionnelle, c’est
            sans doute parce que certains de vos besoins ne sont pas satisfaits. Dans le domaine
            professionnel, cela peut être d’avoir une rémunération que vous jugez insuffisante,
            d’avoir des relations de mauvaise qualité avec vos collègues ou encore de ne pas avoir
            un poste suffisamment stimulant par rapport à vos attentes.
          </Typography>

          <Typography
            variant="body2"
            color="primary"
            className={clsx(classes.description2, classes.paragraphMarginTop)}
          >
            Tous ces éléments risquent d’entraîner de la démotivation. Au contraire, si vos besoins
            sont satisfaits, alors vous vous sentirez pleinement motivé(e) par votre travail. Voilà
            pourquoi il faut être à l’écoute de vos besoins et être capable de les expliciter à
            votre manager. Cela lui permettra de tenter d’y répondre. Vous vous sentirez compris(e)
            et vous aurez le sentiment de progresser…
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PyramidBlock;
