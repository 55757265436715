export const defaultData = [
    {
        title: 'VOS SAVOIR-FAIRE',
        paragraph: 'Notez ici vos trois savoir-faire principaux, ceux dans lesquels vous êtes meilleur(e) que les autres.',
        id: 1,
        children: [
            {
                label: 'SAVOIR-FAIRE 1',
                name: 'A1'
            },
            {
                label: 'SAVOIR-FAIRE 2',
                name: 'A2'
            },
            {
                label: 'SAVOIR-FAIRE 3',
                name: 'A3'
            }
        ]
    },
    {
        title: 'VOS SAVOIR-ÊTRE',
        paragraph: `Notez ici les trois qualités personnelles principales qui vous caractérisent. Ce sont des attitudes
        que vous adoptez naturellement, qui vous différencient positivement des autres et vous permettent de vous
        adapter aux codes et aux rituels sociaux.`,
        id: 2,
        children: [
            {
                label: 'SAVOIR-ÊTRE 1',
                name: 'B1'
            },
            {
                label: 'SAVOIR-ÊTRE 2',
                name: 'B2'
            },
            {
                label: 'SAVOIR-ÊTRE 3',
                name: 'B3'
            }
        ]
    },
    {
        title: 'VOS SAVOIRS',
        id: 3,
        paragraph: `Notez ici les trois savoirs principaux qui vous caractérisent. Ils correspondent à l’ensemble de
        vos connaissances. Il s’agit de votre encyclopédie personnelle.`,
        children: [
            {
                label: 'SAVOIR 1',
                name: 'C1'
            },
            {
                label: 'SAVOIR 2',
                name: 'C2'
            },
            {
                label: 'SAVOIR 3',
                name: 'C3'
            }
        ]
    }
]