import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    containerCard: {
        borderRadius: '10px',
        overflow: 'hidden',
        backgroundColor: 'white',
        height: '100%',
    },
    isFavorite: {
        borderLeftStyle: 'solid',
        height: '100%',
        borderImage: 'linear-gradient(to bottom, rgba(255, 220, 39, 1), rgba(230, 60, 60, 1),rgba(0, 10, 140, 1)) 1',
    },
    marginTop: {
        marginTop: '0.5vh'
    }
}));
