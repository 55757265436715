import { ContainerAdmin } from '@orientaction/commons';
import CreateTestForm from '@orientaction/components/Admin/Test/Create';
import StickyFooter from '@orientaction/components/Admin/Test/Create/StickyFooter';
import QuestionDrawer from '@orientaction/components/Admin/Test/QuestionDrawer';
import ResponseDrawer from '@orientaction/components/Admin/Test/ResponseDrawer';
import VariableDrawer from '@orientaction/components/Admin/Test/VariableDrawer';
import CreateTestLayout from '@orientaction/components/Layout/CreateTestLayout';
import { AdminTestContext } from '@orientaction/Context';
import { useContext, useEffect, useRef } from 'react';
import style from './style';

const CreateTestContent = () => {
  const { test } = useContext<any>(AdminTestContext);

  const classes = style();
  const refLeft = useRef<any>();
  if (test.loading) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%)',
        }}>
        Chargement en cours...
      </div>
    );
  }

  return (
    <>
      <CreateTestLayout isaddBackground={true}>
        <ContainerAdmin>
          <div ref={refLeft} className={classes.content}>
            <CreateTestForm />
          </div>
        </ContainerAdmin>

        <VariableDrawer />
        <QuestionDrawer />
        <ResponseDrawer />
      </CreateTestLayout>
      <div className={classes.footerWrapper}>
        <StickyFooter />
      </div>
    </>
  );
};

export default CreateTestContent;
