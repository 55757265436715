/* eslint-disable no-use-before-define */
import useStyles from './style';
import { FC } from 'react';
import { Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Paragrapth } from '@orientaction/components';
import clsx from 'clsx';


interface IArrayYesNo {
    list: any[];
    current: any;
    handleChange: (data: any) => void;
}

const ArrayYesNo: FC<IArrayYesNo> = ({
    list, current, handleChange
}) => {

    const classes = useStyles();

    const hangeChecked = (name: any, value: boolean) => (e: any) => {
        handleChange({
            name: name as string,
            value: value as boolean
        });
    }

    return (
        <>
            <div className={classes.border}>

                {
                    list.map((oneList: any) => (
                        <>
                            <Grid
                                container={true}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className={classes.titlePage}
                            >

                                <Grid item={true} lg={11} md={11} sm={11} xs={12}>
                                    <div>
                                        {oneList.firstColumn}
                                    </div>
                                </Grid>

                            </Grid>

                            <Grid
                                container={true}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >

                                {
                                    oneList.children.map((children: any) => (

                                        <Grid
                                            item={true}
                                            xs={12}
                                            className={classes.contentArray}
                                        >

                                            <Grid
                                                container={true}
                                            >

                                                <Grid item={true} lg={10} md={10} sm={10} xs={12}>

                                                    <div className={classes.textContent}>
                                                        <Paragrapth align='left'>
                                                            {children?.content || ""}
                                                        </Paragrapth>
                                                    </div>

                                                </Grid>

                                                <Grid item={true} lg={1} md={1} sm={1} xs={6} onClick={hangeChecked(children?.name, true)}>
                                                    <div
                                                        className={clsx({
                                                            [classes.notActived]: true,
                                                            [classes.actived]: (current[children?.name] === true)
                                                        })}
                                                        onClick={hangeChecked(children?.name, true)}
                                                    >
                                                        Oui
                                                    </div>
                                                </Grid>

                                                <Grid item={true} lg={1} md={1} sm={1} xs={6} >
                                                    <div
                                                        className={clsx({
                                                            [classes.notActived]: true,
                                                            [classes.actived]: (current[children?.name] === false)
                                                        })}
                                                        onClick={hangeChecked(children?.name, false)}
                                                    >
                                                        Non
                                                    </div>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    ))
                                }

                            </Grid>
                        </>

                    ))
                }

            </div>

        </>

    );
};

export default ArrayYesNo;
