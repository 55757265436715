import { Grid, Typography } from "@material-ui/core";
import { FC } from "react";
import useStyle from '../style';

interface ITitleUpCase {
    size?: "small" | "big";
    children: any;
    total?: number;
}

const TitleUpCase: FC<ITitleUpCase> = ({ size = "big", children, total }: any) => {

    const classe = useStyle();

    const capitalizeFirstLetter = (string: string) => {
        return <div>
            <div style={{ display: "inline-block", fontSize: size === "big" ? 50 : 30 }}>{string.charAt(0).toUpperCase()}</div>
            <div style={{ display: "inline-block", width: '120px' }}> {string.slice(1)}</div>
            {total ? <div style={{ display: "inline-block",  fontSize: '23px'}}> : <strong style={{marginLeft: '5px'}}>{total}</strong></div> : total === 0 ? <div style={{ display: "inline-block",  fontSize: '23px'}}> : <strong style={{marginLeft: '5px'}}>0</strong></div> : ''}
        </div>;
    }

    return (
        <div>
            <Typography className={classe.titleUpCase}>
                {capitalizeFirstLetter(children)}
            </Typography>
        </div>
    );

};

export default TitleUpCase;
