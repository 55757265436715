export const paragraph = [
  `Le second type d’évolution interne est une évolution horizontale.`,
  `Ce type d’évolution est le plus courant car contrairement aux évolutions verticales,
  les évolutions horizontales sont fréquentes. Cela tient au fait qu’elles sont beaucoup
  moins recherchées. Souvent, il n’y pas d’intérêt matériel à évoluer horizontalement :
  ni augmentation de salaire, ni amélioration du statut, ni promotion. Les évolutions
  horizontales n’attirent pas car elles ne paient pas ! Pourtant, elles apportent souvent
  une nouvelle motivation et donnent un nouveau souffle dans son travail.`,
  `Une évolution horizontale est très souvent une vraie bouffée d’oxygène. Découvrir
  un nouveau métier, développer de nouvelles connaissances et de nouvelles
  compétences, nouer des relations avec de nouveaux collègues et un(e) nouveau
  (elle) manager(euse).`,
  `Elle permet également de quitter un service au sein duquel il y a une mauvaise
  ambiance. Une évolution horizontale est ainsi tout à fait adaptée aux personnes qui
  veulent changer non à cause de leur métier, qu’elles aiment, mais à cause de la
  qualité de leurs relations avec leurs collègues ou leur manager(euse).`
];


export const list = {
  'context': {
    'label': "LE CONTEXTE",
    'listParagraph': [
      `Votre employeur propose des opportunités
      d’évolution horizontale intéressantes.`,
      `Votre employeur offre une diversité de métiers et
      de contextes professionnels.`,
      `Votre employeur vous rémunère de façon
      supérieure à ce que vous pourriez espérer gagner
      chez un autre employeur du même secteur.`,
      `Vous avez acquis certains avantages comme un
      temps partiel.`,
      `Les autres employeurs susceptibles de vous offrir
      des opportunités intéressantes sont très éloignés
      de votre domicile.`,
      `Dans votre organisation, les postes sont évolutifs.
      Quand on propose, on est écouté. Il s’agit d’une
      organisation flexible.`,
      `Dans le service dans lequel vous travaillez,
      les relations avec vos collègues et vos
      managers(euses) sont de mauvaise qualité.`
    ]
  },
  'you': {
    'label': "VOUS",
    'listParagraph': [
      `Votre besoin de sécurité financière et/ou de
      sécurité de l’emploi est important.`,
      `Vous avez besoin de nouveauté, envie
      d’apprendre, de découvrir, de rencontrer.`,
      `Vous souhaitez conserver votre niveau de vie,
      or un changement d’employeur risquerait
      d’entraîner une baisse de salaire pendant un
      certain temps.`,
      `Vous avez besoin de conserver les avantages
      acquis dans l’organisation de votre travail.`,
      `Vous n’êtes pas mobile géographiquement. Vous
      souhaitez continuer à vivre dans la ville où vous
      habitez actuellement.`,
      `Vous êtes prêt(e) à faire des propositions
      concrètes à votre employeur pour faire
      évoluer votre poste. Vous avez envie de
      changer d’ambiance, de collègues et/ou de
      manager(euse) pour retrouver des relations plus
      sereines.`
    ]

  }
}
