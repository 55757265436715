import { Button, Chip, Drawer, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { publicIconPath } from '@orientaction/utils';
import style from './style';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/HighlightOffOutlined';
import OaLabel from '@orientaction/commons/OaLabel';
import classNames from 'classnames';
import Modal from '@orientaction/components/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gameVariablesTemplateActions } from '@orientaction/api-actions';

interface IItemVariable {
  index: number;
  name: string;
  handleChangeName?: (index: number, name: string) => void;
  handleDelete?: (index: number) => void;
  dataVariable?: any;
  handleDeleteRestitution?: (index: number) => void;
  edit?: boolean;
}

const ItemVariable: FC<IItemVariable> = ({
  index,
  name,
  handleChangeName,
  handleDelete,
  dataVariable,
  handleDeleteRestitution,
  edit,
}) => {
  const classes = style();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [variableName, setVariableName] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalDeleteRestitution, setOpenModalDeleteRestitution] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpenDrawer(false);
    setVariableName(name);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!variableName.trim().length) {
      setError(true);
      return;
    }
    if (handleChangeName) {
      handleChangeName(index - 1, variableName);
    }
    setOpenDrawer(false);
  };

  const handleDeleteItem = () => {
    if (handleDelete) {
      handleDelete(index - 1);
      setOpenModalDelete(false);
    }
  };
  const handleDeleteItemRestitution = () => {
    if (handleDeleteRestitution) {
      handleDeleteRestitution(index - 1);
      setOpenModalDeleteRestitution(false);
    }
  };
  const goToModel = () => {
    dispatch(gameVariablesTemplateActions.setAllValuesModel(dataVariable));
    console.log({dataVariable});
    if (dataVariable?.template_game) {
      history.push(
        !edit
          ? `/admin/game/edit-variable/${index - 1}`
          : `/admin/game/edit-variable/${index - 1}/edit`
      );
    } else {
      history.push(
        !edit
          ? `/admin/game/model-variable/${index - 1}`
          : `/admin/game/model-variable/${index - 1}/edit`
      );
    }
  };

  useEffect(() => {
    setVariableName(name);
  }, [name]);

  return (
    <>
      <Grid container={true} direction="row" justifyContent="center" alignItems="stretch">
        <Grid item={true} sm={5} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={classes.flexContainer}>
            <div className={classes.circle}>
              <span className={classes.number}>{`N°${index}`}</span>
            </div>
            <div className={classes.summaryText}>
              <Typography className={classes.summaryTextH6} variant="h6">
                {name}
              </Typography>
            </div>
            <div>
              <IconButton className={classes.iconButtonV} onClick={() => setOpenDrawer(true)}>
                <img src={`${publicIconPath}/oa_edit.svg`} alt="Edit Icon" />
              </IconButton>
              <IconButton className={classes.iconButtonV} onClick={() => setOpenModalDelete(true)}>
                <img src={`${publicIconPath}/oa_trash.svg`} alt="Custom Icon" />
              </IconButton>
            </div>
          </div>
        </Grid>
        <Grid item={true} sm={4}>
          {dataVariable.template_game ? (
            <Chip
              className={classes.chip}
              label={dataVariable.title}
              onClick={e => {
                e.stopPropagation();
                goToModel();
              }}
              onDelete={() => setOpenModalDeleteRestitution(true)}
            />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.btnPrimary}
              onClick={goToModel}>
              Ajouter une restitution
            </Button>
          )}
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openDrawer}
        classes={{ root: classes.rootStyle, paper: classes.drawerContent }}>
        <div className={classes.headerContainer}>
          <IconButton className={classes.closeBtnDrawer} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
          <div className={classes.titleContainer}>
            <Typography variant="h3" className={classes.titleDrawer}>
              Modifier une variable
            </Typography>
          </div>
        </div>
        <div className={classes.contentChild}>
          <form onSubmit={handleSubmit}>
            <Typography className={classes.variableFormTitle} component="h3">
              Variable {`N°${index}`}
            </Typography>
            <div>
              <OaLabel>Nom de la variable</OaLabel>
              <TextField
                id="test-variable-name"
                variant="outlined"
                className={classNames(classes.formInput, error && classes.error)}
                name="variableName"
                value={variableName}
                onChange={e => setVariableName(e.target.value)}
              />
            </div>
            <div className={classes.marginTop}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.btnPrimary2}>
                Sauvegarder
              </Button>
            </div>
          </form>
        </div>
      </Drawer>
      <Modal
        data-cy="cancel-test-create-modal"
        open={openModalDelete}
        onCancel={() => setOpenModalDelete(false)}
        onConfirm={handleDeleteItem}
        textCancel="Non"
        title=""
        textValidate="Oui">
        <h3 style={{ color: '#000A8C' }}>Êtes-vous sur de vouloir supprimer cette variable ?</h3>
      </Modal>
      <Modal
        data-cy="cancel-test-create-modal"
        open={openModalDeleteRestitution}
        onCancel={() => setOpenModalDeleteRestitution(false)}
        onConfirm={handleDeleteItemRestitution}
        textCancel="Non"
        title=""
        textValidate="Oui">
        <h3 style={{ color: '#000A8C' }}>Êtes-vous sur de vouloir supprimer cette restitution ?</h3>
      </Modal>
    </>
  );
};

export default ItemVariable;
