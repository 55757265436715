/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../store';

export const setMyResult =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'MYRESULT',
      payload: data,
    });
  };

export const setDialog =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'DIALOG',
      payload: data,
    });
  };

export const setMyElement =
  (data: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: 'MYELEMENT',
      payload: data,
    });
  };
