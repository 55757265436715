/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Button, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { ModalCardHarmony, UserResultRadarGraph } from '@orientaction/components';
import { dataHarmony, publicSvgPath } from '@orientaction/utils';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { take } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './style';

Chart.register(ChartDataLabels);

interface IresultToShow {
  resultToShow: any;
}

const FirstView: FC<IresultToShow> = ({ resultToShow }) => {
  const classes = useStyles();
  const [harmony, setHarmony] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  useEffect(() => {
    console.log({ resultToShow });
  }, []);

  const setValueHarmony = (elem: any) => {
    setHarmony(elem);
    setIsOpen(true);
  };

  return (
    <>
      <Grid container={true} spacing={2} className={classes.marginFistViewTop}>
        <Grid item={true} md={8} lg={8} xs={12}>
          <div className={classes.contentNoPadding}>
            <div className={classes.padding25}>
              <Grid container={true} justifyContent="space-between">
                <Grid item={true}>
                  <Typography
                    variant="h4"
                    color="primary"
                    className={`${classes.title}`}
                    style={{ color: 'black', fontSize: 21 }}
                  >
                    SYNTHÈSE DE VOS RÉSULTATS
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <a href="#softskills" style={{ textDecoration: 'none' }}>
                    <Button
                      className={classes.btnCard}
                      style={{ background: '#000E8C', color: 'white' }}
                    >
                      Voir le détail des soft skills
                    </Button>
                  </a>
                </Grid>
              </Grid>
              <div className={classes.podium}>
                {resultToShow && <UserResultRadarGraph resultToShow={resultToShow} />}
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          item={true}
          container={true}
          md={4}
          lg={4}
          xs={12}
          direction="column"
          style={{ flexWrap: 'nowrap' }}
        >
          <Grid item={true} xs={12} style={{ paddingBottom: 16 }}>
            <div className={classes.content2}>
              <div className={classes.padding25}>
                <Typography
                  variant="h4"
                  color="primary"
                  className={`${classes.title}`}
                  style={{ color: 'black', fontSize: 21 }}
                >
                  DÉCOUVREZ VOS 16 SOFT SKILLS
                </Typography>

                <Typography
                  variant="body2"
                  color="primary"
                  className={`${classes.description2}`}
                  style={{ marginTop: 25 }}
                >
                  Les soft skills ou compétences douces sont des compétences que l’on acquiert par
                  l’expérience, par opposition aux hard skills qui s’acquièrent dans le cadre d’une
                  formation ou de ses études. <br /> <br />
                  <b>
                    Les soft skills sont aujourd’hui la clef de la réussite professionnelle.
                  </b>{' '}
                  <br /> Les développer vous aidera à devenir un(e) collaborateur (trice) reconnu(e)
                  et apprécié(e) dans son organisation. Dans votre sphère de vie personnelle, cela
                  fera de vous une personne accomplie et respectée pour ses qualités humaines. Le
                  fait de les tester est une première étape avant de progresser. À vous de jouer !
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item={true} xs={12}>
            <div className={classes.content2}>
              <div className={classes.padding25}>
                <Typography
                  variant="h4"
                  color="primary"
                  className={`${classes.title}`}
                  style={{ color: 'black', fontSize: 21 }}
                >
                  VOS 3 SOFT SKILLS LES + DÉVELOPPÉES
                </Typography>
                <div className={classes.flexContainer}>
                  {take(dataHarmony(resultToShow), 3)?.map((elem) => (
                    <Button
                      variant="contained"
                      style={{ background: elem.color }}
                      color="primary"
                      onClick={() => setValueHarmony(elem)}
                      disableElevation={true}
                    >
                      {elem.key}
                    </Button>
                  ))}
                </div>
                <Typography
                  variant="body2"
                  color="primary"
                  className={`${classes.description2}`}
                  style={{ marginTop: 15, fontStyle: 'italic' }}
                >
                  Cliquez sur une soft skills pour en savoir + !
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
        <ModalCardHarmony
          open={isOpen}
          onCancel={() => setIsOpen(false)}
          color={harmony?.color}
          img={`${publicSvgPath}/${harmony?.img}`}
          label={harmony?.key}
          colorSecondary={harmony?.colorSecondary}
          opacity={!!harmony?.opacity}
          value={harmony?.value}
          text1={harmony?.text1}
          listeAccordeon={harmony?.listeAccordeon}
          listEmploi1={harmony?.listEmploi1}
          text2={harmony?.text2}
          title={harmony?.title}
          citation={harmony?.citation}
          titleAd={harmony?.titleAd}
          descriptionAd={harmony?.descriptionAd}
          imgAd={`${publicSvgPath}/${harmony?.imgAd}`}
          useGrid={harmony?.useGrid}
        />
      </Grid>
      {/* <Explication view={!isMobile} /> */}
    </>
  );
};

export default FirstView;
