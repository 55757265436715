export const paragraph = [
   `Le premier type d’évolution interne est une évolution verticale. Ce type d’évolution
est réservé aux plus ambitieux(ses), à celles et ceux qui souhaitent ardemment
exercer des responsabilités. Les évolutions verticales consistent à gravir les échelons
afin de se rapprocher du sommet de la pyramide managériale. L’objectif est
évidemment d’exercer toujours plus de pouvoir dans l’organisation, d’améliorer à
la fois son statut social, son niveau de rémunération et les avantages matériels et
symboliques que l’on tire de sa fonction. Pour atteindre cet objectif, il faut se battre.
Les prétendant(e)s sont souvent très nombreux(ses).`,
   `D’autant plus nombreux(ses) que ces dernières années, le nombre d’échelons
et donc de managers(euses) dans les organisations (entreprise, association,
administration) n’a cessé de diminuer. Trois types de contexte peuvent néanmoins
favoriser les évolutions verticales :`
];

export const list = {
   'context': {
      'label': "LE CONTEXTE",
      'listParagraph': [
         `Des postes à responsabilité vont se libérer
          prochainement. Votre organisation a
          l’habitude de recruter en interne.`,
         `Votre employeur vous rémunère de façon
          supérieure à ce que vous pourriez espérer
          gagner chez un autre employeur.`,
         `Votre employeur vous offre la possibilité
          de vous former en finançant une formation
          supplémentaire.`,
         `Les autres employeurs susceptibles de
          vous offrir des opportunités intéressantes
          sont très éloignés de votre domicile.`,
         `Votre employeur est prêt à donner
          des responsabilités plus fortes aux
          collaborateurs(trices) qui s’engagent.`
      ]
   },
   'you': {
      'label': "VOUS",
      'listParagraph': [
         `Votre besoin de réussite sociale est fort.
       Vous avez besoin de diriger, d’assumer des
       responsabilités, d’avoir du pouvoir.`,
         `Vous êtes disponible. Vous avez du temps
       et de l’énergie à revendre. Vous êtes en
       parfaite santé et résistant(e) au stress.`,
         `Vous avez soif d’apprendre, de développer
       de nouvelles connaissances et de nouvelles
       compétences.`,
         `Vous n’êtes pas mobile géographiquement.
       Vous souhaitez continuer à vivre dans la
       ville où vous habitez actuellement.`,
         `Vous aimez commander, diriger,
       représenter. Même si vous ne savez pas le
       faire, vous avez envie de le faire.`
      ]
   }
}
