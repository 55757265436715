/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, ArrayYouContext } from '@orientaction/components';
import {  list } from './context';

const Page20 = (props: any) => {

    return (
        <Page id={20}>
      
            <MarginContainer big={true}>

                <MarginContainer mormal={true}>

                    <Paragrapth
                        align='center'
                        bold={true}
                        colorBlue={true}
                    >
                        DANS QUELS CAS CHOISIR <br />
                        UNE RECONVERSION PROFESSIONNELLE :
                    </Paragrapth>

                </MarginContainer>

                <MarginContainer mormal={true}>

                    <ArrayYouContext objectList={list} />
                    
                </MarginContainer>

            </MarginContainer>

        </Page>
    );
};

export default Page20;
