/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarginContainer } from '@orientaction/commons';
import { Page, Paragrapth, Title } from '@orientaction/components';

const Page16 = () => {
  return (
    <Page id={16}>
      <Title
        title="LE CAPITAL ÉCONOMIQUE"
        firstTitle="‘‘ Investir dans la formation, c’est conjuguer au présent mais
        aussi au futur le souci des hommes et le souci des résultats. ’’"
        firtsecondTitle="Philippe Bloch"
        secondTitle="‘‘Les investissements d’aujourd’hui sont les profits de demain
        et les emplois d’après-demain. ’’"
        secondSousTitle="Helmut Schmidt"
      />

      <MarginContainer>
        <Paragrapth align="justify">
          Le capital économique correspond à nos ressources financières et patrimoniales. Ce sont
          des éléments essentiels, car ces ressources sont la condition de la réussite d’un certain
          nombre de projets, création/reprise d’entreprise ou reconversion professionnelle
          notamment. Elles permettront le cas échéant de solliciter un prêt, pour créer/reprendre
          une entreprise ou financer une formation.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Une évaluation précise de votre capital économique est donc indispensable avant de vous
          lancer dans un projet d’évolution professionnelle, et ce, afin d’en évaluer la
          faisabilité. Mais plus que le capital économique réel, il convient d’évaluer le capital
          économique mobilisable, c’est-à-dire la part de patrimoine que vous êtes effectivement
          prêt(e) à mobiliser pour réaliser votre projet.
        </Paragrapth>
      </MarginContainer>
      <MarginContainer>
        <Paragrapth align="justify">
          Certaines personnes sont prêtes à hypothéquer ou à vendre leur résidence principale pour
          financer un projet de création/reprise d’entreprise, d’autres refuseront d’investir le
          moindre centime. Chacun fait ses choix. L’expérience montre qu’en réalité, au-delà de son
          évaluation objective, le capital économique est un élément très subjectif.
        </Paragrapth>
      </MarginContainer>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    cardBgGrey: {
      padding: 20,
      margin: '2vh 0',
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  })
);

export default Page16;
