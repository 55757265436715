/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable import/prefer-default-export */
import { AppThunk } from '../../store';

export const setIndoorWeather =
  (nbr: number): AppThunk =>
  dispatch => {
    dispatch({
      type: 'INDOORWEATHER',
      payload: nbr,
    });
  };

export const setToAttendToYoursNeeds =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'TOATTENDTOYOURNEEDS',
      payload: data,
    });
  };

export const setToSpecifiedYourNeed =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'TOSPECIFIEDYOURNEED',
      payload: data,
    });
  };

export const setId =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ID',
      payload: data,
    });
  };

export const setTestPersonality =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'TESTPERSONALITY',
      payload: data,
    });
  };
export const setFigure6 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'FIGURE6',
      payload: data,
    });
  };
export const setAdjectifs =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ADJECTIFS',
      payload: data,
    });
  };
export const setSelectAdjectif =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'SELECTADJECTIF',
      payload: data,
    });
  };
export const setResponse1 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE1',
      payload: data,
    });
  };
export const setResponse2 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE2',
      payload: data,
    });
  };
export const setResponse3 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE3',
      payload: data,
    });
  };
export const setResponse4 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE4',
      payload: data,
    });
  };
export const setResponse5 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE5',
      payload: data,
    });
  };
export const setResponse6 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE6',
      payload: data,
    });
  };
export const setResponse7 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE7',
      payload: data,
    });
  };
export const setResponse8 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE8',
      payload: data,
    });
  };
export const setResponse9 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE9',
      payload: data,
    });
  };
export const setResponse10 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE10',
      payload: data,
    });
  };
export const setResponse11 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE11',
      payload: data,
    });
  };
export const setResponse12 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE12',
      payload: data,
    });
  };
export const setResponse13 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE13',
      payload: data,
    });
  };
export const setResponse14 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE14',
      payload: data,
    });
  };

export const setResponse15 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSE15',
      payload: data,
    });
  };
export const setDataColor1 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'DATACOLOR1',
      payload: data,
    });
  };
export const setDataColor11 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'DATACOLOR11',
      payload: data,
    });
  };
export const setDataColor2 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'DATACOLOR2',
      payload: data,
    });
  };
export const setDataColor21 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'DATACOLOR21',
      payload: data,
    });
  };
export const setExpressionAB =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'EXPRESSIONAB',
      payload: data,
    });
  };
export const setPersonalQuality =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PERSONALQUALITY',
      payload: data,
    });
  };
export const setQuality =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'QUALITY',
      payload: data,
    });
  };
export const setProgressionAxe =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PROGRESSIONAXE',
      payload: data,
    });
  };
export const setProgression =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PROGRESSION',
      payload: data,
    });
  };
export const setProgressionText =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'PROGRESSIONTEXT',
      payload: data,
    });
  };
export const setRolePrefere =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ROLEPREFERE',
      payload: data,
    });
  };
export const setRolePossible =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ROLEPOSSIBLE',
      payload: data,
    });
  };
export const setRoleAEviter =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'ROLEAEVITER',
      payload: data,
    });
  };
export const setVerbes =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'VERBES',
      payload: data,
    });
  };

export const setResponseCondition1 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION1',
      payload: data,
    });
  };
export const setResponseCondition2 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION2',
      payload: data,
    });
  };
export const setResponseCondition3 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION3',
      payload: data,
    });
  };
export const setResponseCondition4 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION4',
      payload: data,
    });
  };
export const setResponseCondition5 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION5',
      payload: data,
    });
  };
export const setResponseCondition6 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION6',
      payload: data,
    });
  };
export const setResponseCondition7 =
  (data: any): AppThunk =>
  dispatch => {
    dispatch({
      type: 'RESPONSECONDITION7',
      payload: data,
    });
  };