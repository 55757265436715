/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, TableDestin } from '@orientaction/components';
import { useBook1 } from '@orientaction/hooks_book';
import {
  figure32_page1,
  figure32_page2,
  figure32_page3,
  figure32_page4,
  figure32_page5,
} from '@orientaction/utils';
import { cloneDeep, differenceBy } from 'lodash';
import concat from 'lodash/concat';
import { useState } from 'react';

const Page26 = (props: any) => {
  const classes = useStyles();

  const { book, updateFigureOfDestiny } = useBook1();

  const allFiguresProtected = concat(
    figure32_page1,
    figure32_page2,
    figure32_page3,
    figure32_page4,
    figure32_page5
  );
  const [allFigures1, setAllFigures1] = useState(cloneDeep(allFiguresProtected.slice(0)));
  const [allFigures2, setAllFigures2] = useState(cloneDeep(allFiguresProtected.slice(0)));
  const [allFigures3, setAllFigures3] = useState(cloneDeep(allFiguresProtected.slice(0)));

  const handleChangeFigure = (e: any, index: number) => {
    const copiedFigure = cloneDeep(book.figureOfDestiny);
    const dataType = { type: e.target.value };
    copiedFigure[index] = dataType;

    updateFigureOfDestiny(copiedFigure);

    if (index === 0) {
      setAllFigures2(differenceBy(allFiguresProtected, removeFromIndex(copiedFigure, 1), 'type'));
      setAllFigures3(differenceBy(allFiguresProtected, removeFromIndex(copiedFigure, 2), 'type'));
    } else if (index === 1) {
      setAllFigures1(differenceBy(allFiguresProtected, removeFromIndex(copiedFigure, 0), 'type'));
      setAllFigures3(differenceBy(allFiguresProtected, removeFromIndex(copiedFigure, 2), 'type'));
    } else if (index === 2) {
      setAllFigures1(differenceBy(allFiguresProtected, removeFromIndex(copiedFigure, 0), 'type'));
      setAllFigures2(differenceBy(allFiguresProtected, removeFromIndex(copiedFigure, 1), 'type'));
    }
  };

  const removeFromIndex = (array: any, index: number) => {
    return array.map((elem: any, i: number) => {
      let el = elem;
      if (index === i) {
        el = '';
      }
      return el;
    });
  };

  return (
    <Page id={26} readOnly={props.readOnly}>
      <TableDestin head={false} figure32={figure32_page5} />
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Card variant="outlined" style={{ borderRadius: '10px' }}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <div className={classes.borderTable}>
                <Grid container={true} justifyContent="flex-end">
                  <Grid
                    item={true}
                    xs={12}
                    style={{ textAlign: 'center' }}
                    className={classes.cardBgGrey}>
                    <Typography component="p" className={classes.h7}>
                      Notez ci-dessous vos trois figures du destin prioritaires en les classant par
                      ordre de préférence :
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container={true}
                  style={{ borderTop: '1px solid black' }}
                  justifyContent="flex-end">
                  <Grid
                    item={true}
                    xs={12}
                    sm={4}
                    style={{ textAlign: 'center', borderRight: '1px solid black' }}>
                    <Typography component="p" className={classes.h6}>
                      FIGURE DU DESTIN N°1
                    </Typography>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={book.figureOfDestiny ? book.figureOfDestiny[0]?.type || '' : ''}
                        onChange={(e: any) => handleChangeFigure(e, 0)}
                        placeholder="Entreprise"
                        style={{ borderRadius: '10px', border: '1px solid black' }}
                        displayEmpty={true}>
                        <MenuItem value="">Cliquer ici</MenuItem>
                        {allFigures1?.map(figureToShow => {
                          return (
                            <MenuItem value={figureToShow.type} key={figureToShow.type}>
                              {figureToShow.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item={true}
                    xs={12}
                    sm={4}
                    style={{ textAlign: 'center', borderRight: '1px solid black' }}>
                    <Typography component="p" className={classes.h6}>
                      FIGURE DU DESTIN N°2
                    </Typography>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={book.figureOfDestiny ? book.figureOfDestiny[1]?.type || '' : ''}
                        onChange={(e: any) => handleChangeFigure(e, 1)}
                        placeholder="Entreprise"
                        style={{ borderRadius: '10px', border: '1px solid black' }}
                        displayEmpty={true}>
                        <MenuItem value="">Cliquer ici</MenuItem>
                        {allFigures2?.map(figureToShow => {
                          return (
                            <MenuItem value={figureToShow.type} key={figureToShow.type}>
                              {figureToShow.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item={true} xs={12} sm={4} style={{ textAlign: 'center' }}>
                    <Typography component="p" className={classes.h6}>
                      FIGURE DU DESTIN N°3
                    </Typography>
                    <FormControl variant="outlined" style={{ width: '100%' }}>
                      <Select
                        value={book.figureOfDestiny ? book.figureOfDestiny[2]?.type || '' : ''}
                        onChange={(e: any) => handleChangeFigure(e, 2)}
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ borderRadius: '10px', border: '1px solid black' }}
                        displayEmpty={true}>
                        <MenuItem value="">Cliquer ici</MenuItem>
                        {allFigures3?.map(figureToShow => {
                          return (
                            <MenuItem value={figureToShow.type} key={figureToShow.type}>
                              {figureToShow.type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '100%',
      height: 'auto',
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 5,
      background: '#e8eef4',
    },
    borderTable: {
      borderRadius: '1px solid black',
    },
    textArea: {
      width: '100%',
      height: 'auto',
      border: '0px !important',
    },
    h7: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#0E1247',
      textAlign: 'center',
      display: 'inline-block',
      style: 'normal',
      alignItems: 'center',
      padding: 10,
    },
    h6: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '16px',
      color: '#0E1247',
      textAlign: 'center',
      display: 'inline-block',
      style: 'normal',
      alignItems: 'center',
      padding: 20,
    },
    circle: {
      width: '150px',
      height: '150px',
      margin: '20px',
      shapeOutside: 'circle(50% at 30%)',
      clipPath: 'circle(50% at 0%)',
      background: 'lightblue',
    },
  })
);
export default Page26;
