/* eslint-disable jsx-a11y/label-has-associated-control */
import Skeleton from '@material-ui/lab/Skeleton';
import { MarginContainer } from '@orientaction/commons';
import { FC } from 'react';
import useStyle from './style';
import UserProfileFormContent from './UseProfileFormContent';

interface IProps {
  user: any;
  updateUser: any;
  loading: boolean;
  loadingGetUser: boolean;
}

const UserProfileForm: FC<IProps> = ({ user, updateUser, loading, loadingGetUser }) => {
  const classe = useStyle();

  return (
    <>
      {loadingGetUser ? (
        <>
          <MarginContainer big={true}>
            <div className={classe.rootSkeleton}>
              {[0, 1, 2, 3, 4, 5].map((item: any) => (
                <MarginContainer key={item}>
                  <Skeleton variant="rect" height={50} width="100%" />
                </MarginContainer>
              ))}
            </div>
          </MarginContainer>
        </>
      ) : (
        <UserProfileFormContent loading={loading} user={user} updateUser={updateUser} />
      )}
    </>
  );
};

export default UserProfileForm;
