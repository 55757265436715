import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    listContainer: {
        background: "#FFFFFF",
        border: "1px solid #CDCEDA",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "100%",
        margin: "1vh 0",
        padding: "0.5vh"
    },
    cardItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: " 6px 8px",
        width: "230px",
        height: "32px",
        left: "4px",
        top: "370px",
        background: "rgba(0, 10, 140, 0.15)",
        borderRadius: "4px",
    },
    typography: {
        fontWeight: 300,
        color: "#000A8C"
    },
    colorIcon: {
        color: "#000A8C"
    },
    button: {
        width: "100%",
        margin: "2vh 0",
        border: "1px solid red",
        color: "#0E1247",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "140 %",
        // linear-gradient(269.64deg, #FFDC27 0%, #E63C3C 48.96%, #000A8C 100%)",
    },
    contentAssignation: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px",
        position: "static",
        width: "95%",
        height: "auto",
        left: "0px",
        top: "494px",
        background: "#EDEEF2",
        borderRadius: "4px",
        flex: "none",
        order: 6,
        alignSelf: "stretch",
        flexGrow: 0,
        margin: " 24px 0px",
    },
    formControlSelect: {
        backgroundColor: "#fff",
        width: '100%',
        '&>svg': {
            color: '#0E1247',
        },
        '&>fieldset': {
            borderColor: '#0E1247'
        },
    },
    textContent: {
        position: "static",
        width: "472px",
        height: "40px",
        left: "calc(50 % - 472px / 2)",
        top: "calc(50 % - 40px / 2 - 96px)",
        fontFamily: 'Poppins',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "140 %",
        display: "flex",
        alignItems: "center",
        color: "#0E1247",
        flex: "none",
        order: 0,
        alignSelf: "stretch",
        flexGrow: 0,
    },
    formInput: {
        width: '100%',
        '& div fieldset': {
            borderColor: '#0E1247',
        },
        '& div input': {
            paddingTop: 12,
            paddingBottom: 12,
        },
    },
});

export default useStyles;