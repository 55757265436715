import { Grid, Typography } from '@material-ui/core';
import { Paragrapth } from '@orientaction/components';
import classnames from 'classnames';
import { FC } from 'react';
import { AnyObject } from 'yup/lib/types';
import useStyle from './style';
import parse from 'react-html-parser';

interface IParagrapthListNumber {
  list: AnyObject[];
  paragraphAlign?: any;
  overrideRootStyle?: any;
  overrideContainerStyle?: any;
  overrideSpanStyle?: any;
  addParagraphBackground?: boolean;
  withTwoPoint?: boolean;
  textBlue?: boolean;
}

const ParagrapthListNumber: FC<IParagrapthListNumber> = ({
  list,
  paragraphAlign = 'start',
  overrideRootStyle = '',
  overrideContainerStyle = '',
  overrideSpanStyle = '',
  addParagraphBackground = true,
  withTwoPoint = false,
  textBlue = false
}) => {
  const classe = useStyle();

  return (
    <div className={classnames(classe.root, overrideRootStyle)}>
      {list.map((item: any, index: number) => (
        <div>

          <Grid
            container={true}
            direction="row"
            className={classe.margin}
          >
            <Grid item={true} xs={2} md={1}>

              <span className={classnames(classe.span, overrideSpanStyle)}>
                <strong>{item.number}</strong>
              </span>

            </Grid>

            <Grid item={true} xs={10} md={11}>

              <Paragrapth
                align={paragraphAlign}
                addBackground={addParagraphBackground}
                colorBlue={textBlue}
              >
                {item.label && (
                  <Paragrapth
                    align={paragraphAlign}
                    bold={true}
                    colorBlue={true}
                    addBackground={addParagraphBackground}
                  >
                    {`${item.label}`}
                  </Paragrapth>
                )}
                {' '}
                {withTwoPoint ? ' : ' : ' '}
                {parse(item.text)}
              </Paragrapth>

            </Grid>

          </Grid>


        </div>
      ))}
    </div>
  );
};

export default ParagrapthListNumber;
