import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      [theme.breakpoints.down(800)]: {
        padding: '2vh',
      },
    },
    title: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
    },
    contentLoader: {
      width: '100%',
      height: '20vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    imgPiramide: {
      width: '150%',
      [theme.breakpoints.down(800)]: {
        width: '110%',
        margin: '0 auto',
        position: 'relative',
        left: -17,
      },
    },
    contentPiramyd: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
