/* eslint-disable no-use-before-define */
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page } from '@orientaction/components';
import clsx from 'clsx';
import { useState } from 'react';
import { imgBook1, publicSvgPath } from '../../../../utils/constants';
import SimpleBanner from '../../components/SimpleBanner';

const Page21 = (props: any) => {
  const classes = useStyles();
  return (
    <Page id={21}>
      <div className={classes.cardBgGrey}>
        <div style={{ marginBottom: '50px' }}>
          {/* <img
            className={classes.imgTailleur}
            src={`${publicSvgPath}/troistailleur.png`}
            alt="logo"
          /> */}
          <SimpleBanner>
            <Typography
              component="h1"
              className={clsx(classes.bannerTitleContainer, classes.bannerTitle)}
            >
              LES TROIS TAILLEURS DE PIERRE
            </Typography>
          </SimpleBanner>
        </div>
        <img className={classes.img} src={`${imgBook1}/tailleur.png`} alt="logo" />
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Cette histoire se déroule il y a très longtemps, à l’époque où l’on construisait de si
          grands édifices qu’une vie d’homme ne suffisait pas à les achever.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Afin que ces constructions puissent aboutir, un maître-architecte fut nommé pour former
          les apprentis qui auraient la lourde tâche, une fois qu’il serait mort, de poursuivre la
          construction.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le maître-architecte, jugeant leur apprentissage presque terminé, envoya ses élèves dans
          les carrières afin d’évaluer leurs pratiques et leurs connaissances dans la taille des
          pierres et des moellons.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Il les laissa travailler seuls pendant plusieurs semaines avant de leur rendre visite pour
          évaluer leurs capacités et leurs talents. S’approchant de l’un d’eux, il dit :
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          <strong>« Dis-moi, mon cher élève, qu’es-tu en train de faire ? »</strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          L’élève releva la tête et jeta un regard sombre en direction de son maître. Il semblait
          très mécontent de son sort et du difficile travail qu’il avait à effectuer.
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          <strong>
            « Maître, vous savez bien que je taille durement des pierres toute la journée et que je
            suis un tailleur de pierre. »
          </strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le maître poursuivit son inspection et aperçut un deuxième élève en train de tailler la
          pierre :
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          <strong>« Dis-moi, mon cher élève, qu’es-tu en train de faire ? ».</strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          L’apprenti se mit debout et avec respect lui répondit :
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          <strong>
            « Maître, je m’applique chaque jour à tailler la pierre et à mettre en œuvre les
            enseignements que vous m’avez prodigués.»
          </strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          Le maître sourit. Il encouragea l’apprenti à poursuivre son travail et continua son
          chemin. Soudain, son regard fut attiré par un troisième apprenti. Celui-ci semblait
          extrêmement concentré sur sa tâche. Il semblait totalement absorbé par elle. S’approchant
          de lui, le maître vit l’élève qui, après avoir taillé sa pierre, la polissait avec Amour.
          Il l’interrogea :
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          <strong>« Dis-moi, mon cher élève, qu’es-tu en train de faire ? ».</strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          L’élève se releva et, levant la pierre devant lui, répondit, la voix vibrante d’émotion :
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '10px',
            textAlign: 'justify',
            fontWeight: 'lighter',
          }}
        >
          <strong>« Maître, moi, je construis une cathédrale.»</strong>
        </Typography>
        <Typography
          className={classes.paragraphe}
          style={{
            fontFamily: 'ITC Avant Garde Gothic Std Medium',
            marginTop: '20px',
            textAlign: 'right',
            fontWeight: 'lighter',
            fontStyle: 'italic',
            fontSize: '12px',
          }}
        >
          Extrait de Ce que j’aimerais te dire (2018)
        </Typography>
      </div>
    </Page>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
      height: 'auto',
      padding: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    bannerTitleContainer: {
      margin: 'auto',
      marginTop: -83,
      [theme.breakpoints.down(1300)]: {
        marginTop: -90,
      },
    },
    bannerTitle: {
      fontStyle: 'normal',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      color: 'white',
      fontSize: 20,
      textAlign: 'center',
      [theme.breakpoints.down(1300)]: {
        fontSize: 14,
      },
    },
    pos: {
      marginBottom: 12,
    },
    textCenterTop: {
      textAlign: 'center',
    },
    img: {
      width: '40%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      float: 'right',
      transform: 'translateX(30px)',
      '@media only screen and (max-width: 600px)': {
        transform: 'translateX(0px)',
      },
      '@media only screen and (max-width: 361px)': {
        transform: 'translateX(0px)',
      },
    },
    imgTailleur: {
      width: '60%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
      '@media only screen and (max-width: 600px)': {
        width: '100%',
      },
      '@media only screen and (max-width: 361px)': {
        width: '100%',
      },
    },
    trait: {
      backgroundColor: 'yellow',
      width: '75px',
      height: '5px',
      marginTop: '50px',
      marginBottom: '50px',
    },
    divider: {
      width: '90%',
      height: '1px',
      borderTop: '1px dashed grey',
    },
    flexPosition: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    positionRelative: {
      position: 'relative',
    },
    positionAbsolute: {
      position: 'absolute',
    },
    opacity07: {
      opacity: 0.7,
    },
    logo: {
      width: '50%',
      height: 'auto',
      display: 'block',
      margin: 'auto',
    },
    paragraphe: {
      fontSize: '15px',
      color: 'black',
      '@media only screen and (max-width: 600px)': {
        textAlign: 'left !important',
      },
      '@media only screen and (max-width: 361px)': {
        textAlign: 'left !important',
      },
    },
    testimony: {
      fontSize: '17px',
    },
    positionCenter: {
      display: 'block',
      margin: 'auto',
    },
    cardBgGrey: {
      padding: 20,
      background: '#e8eef4',
      fontStyle: 'italic',
      '@media only screen and (max-width: 600px)': {
        padding: 20,
      },
      '@media only screen and (max-width: 361px)': {
        padding: 15,
      },
    },
  }),
);
export default Page21;
