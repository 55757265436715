// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;

export const getEqualityVariable = async (token: string, idTest: string) => {
  const searchParams = qs.stringify(
    {
      filters: {
        test: {
          id: idTest,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(`${url}/api/equality-variables?populate=*&${searchParams}`, 'GET', {}, getHeaders(token));
};
