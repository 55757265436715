/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
  useMeaningValueTestResult,
  useRedirectToRootPage,
  useAlert,
  useCompassMenu,
  useLanguage,
} from '@orientaction/hooks';
import { FC, useContext, useEffect, useState } from 'react';
import { useStyles } from './style';
import Podium from './Podium';
import Card from '../Components/Card';
import Description from '../Components/Description';
import CardButton from '../Components/Button';
import { CompassUserContext } from '@orientaction/Context';

/**
 * This card should be shown while user is:
 *  - A rattached beneficiary who has meaning test result
 *  - A rattached beneficiary who has right to see the test
 *  - A rattached beneficiary with no more right on test bit has result
 */

const ValueCard: FC = () => {
  const [result, setMyResult] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const { language } = useLanguage();

  const { userInfos } = useContext<any>(CompassUserContext);

  // TO REFACTOR: Actually we fetch last test in order to have ID for redirection purpose,
  // so heavy in my opinion
  const { meaningTestLastResult } = useCompassMenu();

  const currentTest = userInfos?.service?.major_tests_templates?.find((test: any) => test.id === 1);

  const shouldBuy = currentTest?.shouldBuy || !userInfos?.rattachement || false;

  const hasAccess = currentTest?.hasRight || currentTest?.hasResult || false;

  const { getMyResult } = useMeaningValueTestResult();

  const { goToRootPage } = useRedirectToRootPage();

  const alert = useAlert();

  const getResultat = async (idTest: number) => {
    setLoading(true);

    try {
      // The purpose of this is to fetch the three best scores to display in image
      const { data } = await getMyResult(+idTest);
      setMyResult(data.data.resultat);
    } catch (err) {
      if (
        err?.response?.data?.error?.name === 'PolicyError' &&
        err?.response?.data?.error?.status === 403
      ) {
        alert.openSimpleAlert('Cette page ne vous est pas accessible.');
        return goToRootPage();
      }
      alert.openSimpleAlert();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (meaningTestLastResult?.id && typeof meaningTestLastResult?.id === 'number') {
      getResultat(meaningTestLastResult?.id);
    }
  }, [meaningTestLastResult?.id]);

  const classe = useStyles();

  const showNeutralVersion = !hasAccess || !currentTest?.hasResult;

  const withoutAccessRedirectButton = shouldBuy && (
    <CardButton redirectUri="https://orientaction.kneo.me/shop/view/D0A37F">
      Acheter le test
    </CardButton>
  );

  return (
    <Card
      to={`${language === 'GB' ? `/en/tests/meaning-values` : `/tests/meaning-value`}`}
      disable={showNeutralVersion}
      loading={loading}
      to_resultat={`/resultat-meaning-value/${meaningTestLastResult?.id}`}
      withAccess={hasAccess}
      withoutAccessRedirectButton={withoutAccessRedirectButton}
      testId={meaningTestLastResult?.id}
    >
      <div className={classe.root}>
        <Grid container={true} direction="row" justifyContent="center" alignItems="center">
          <Grid md={6} lg={6} xs={12}>
            <div className={classe.podium}>
              <Podium first={result?.[0]} second={result?.[1]} third={result?.[2]} />
            </div>
          </Grid>

          <Grid md={6} lg={6} xs={12}>
            <Description>
              Voici vos valeurs directrices. Vos valeurs directrices guident vos pensées et vos
              comportements. Elles sont à la source du sens que vous donnez à vos actions. Agir
              conformément à vos valeurs vous permettra de développer votre motivation et votre
              bien-être. Vous vous sentirez aligné(e) entre ce que vous êtes et ce que vous faites.
            </Description>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default ValueCard;
