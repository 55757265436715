// eslint-disable-next-line import/no-unresolved
import {
  convertDatestringToIsodate,
  getHeaders,
  getMinorTestStrapiFilter,
  makeRequest,
} from '@orientaction/utils';
import config from './config';

const qs = require('qs');

const url = config.api_url;
const { export_url } = config;

export const postResponseUsers = async (token: string, data: any) => {
  return makeRequest(`${url}/api/custom-response-users`, 'POST', data, getHeaders(token));
};
export const getResponseUsers = async (token: string, idUser: string) => {
  return makeRequest(
    `${url}/api/response-users?filters[show][$ne]=false&filters[users_permissions_users][id][$eq]=${idUser}&sort[1]=createdAt%3Adesc&populate=*`,
    'GET',
    {},
    getHeaders(token)
  );
};
export const getResponseTestUser = async (token: string, idResponse: string) => {
  const params = qs.stringify(
    {
      filters: {
        show: {
          $ne: false,
        },
      },
      populate: ['test', 'test.diagram', 'responses', 'responses.variable'],
      sort: ['createdAt:desc'],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return makeRequest(
    `${url}/api/response-users/${idResponse}?${params}`,
    'GET',
    {},
    getHeaders(token)
  );
};
export const getResponseTestUserGroupById = async (
  token: string,
  idResponse: string,
  idTest: string
) => {
  return makeRequest(
    `${url}/api/response-users?filters[show][$ne]=false&filters[users_permissions_users][id][$eq]=${idResponse}&filters[test][id][$eq]=${idTest}&sort[1]=createdAt%3Adesc&populate=test`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getAllResponseUsers = async (
  token: string,
  filter = '',
  moreFilter = [],
  paginationPage = 1,
  rattachement = true,
  isActivated = true,
  company?: any
) => {
  let transformedMoreFilter: any = [];
  if (Array.isArray(moreFilter) && moreFilter.length > 0) {
    transformedMoreFilter = moreFilter?.map((item: any) => {
      if (item?.createdAt) {
        return {
          ...item,
          createdAt: {
            $contains: convertDatestringToIsodate(item.createdAt.$eq),
          },
        };
      }
      return item;
    });
  }

  const searchParams = qs.stringify(
    {
      filters: {
        $and: [
          {
            show: {
              $ne: false,
            },
            test: {
              id: {
                $eq: filter,
              },
            },
            users_permissions_users: {
              company: {
                id: {
                  $eq: company,
                },
              },
              isActivated: {
                $eq: isActivated,
              },
              rattachement: {
                $eq: rattachement,
              },
            },
          },
        ].concat(transformedMoreFilter),
      },
      pagination: {
        page: paginationPage,
        pageSize: 10,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return makeRequest(
    `${url}/api/response-users?${searchParams}&sort[1]=createdAt%3Adesc&populate[0]=test&populate[1]=users_permissions_users&populate[2]=responses.variable`,
    'GET',
    {},
    getHeaders(token)
  );
};

/**
 * Duplicate getAllResponseUsers function to avoid regression, refactor later
 */

export const fetchAllResponseUsers = async (
  token: string,
  filter = '',
  moreFilter = [],
  paginationPage = 1,
  rattachement = true,
  isActivated = true,
  company?: any
) => {
  let transformedMoreFilter: any = [];
  if (Array.isArray(moreFilter) && moreFilter.length > 0) {
    transformedMoreFilter = moreFilter?.map((item: any) => {
      if (item?.createdAt) {
        return {
          ...item,
          createdAt: {
            $contains: convertDatestringToIsodate(item.createdAt.$eq),
          },
        };
      }
      return item;
    });
  }

  const searchParams = qs.stringify(
    {
      filters: {
        $and: [
          {
            show: {
              $ne: false,
            },
            test: {
              id: {
                $eq: filter,
              },
            },
            users_permissions_users: {
              company: {
                id: {
                  $eq: company,
                },
              },
              isActivated: {
                $eq: isActivated,
              },
              rattachement: {
                $eq: rattachement,
              },
            },
          },
        ].concat(transformedMoreFilter),
      },
      pagination: {
        page: paginationPage,
        pageSize: 10,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return makeRequest(
    `${url}/api/response-users?${searchParams}&sort[1]=createdAt%3Adesc&populate[0]=test&populate[1]=users_permissions_users&populate[2]=responses.variable`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const exportResponseUsers = async (
  token: string,
  testId: string,
  rattachement = true,
  isActivated = true,
  filters?: any
) => {
  let transformedMoreFilter: any = [];
  if (Array.isArray(filters) && filters.length > 0) {
    transformedMoreFilter = filters?.map((item: any) => {
      const itemTransformed = getMinorTestStrapiFilter(item);
      return itemTransformed;
    });
  }
  const searchParams = qs.stringify(
    {
      filters: {
        $and: [
          {
            show: {
              $ne: false,
            },
            test: {
              id: {
                $eq: testId,
              },
            },
            users_permissions_users: {
              isActivated: {
                $eq: isActivated,
              },
              rattachement: {
                $eq: rattachement,
              },
            },
          },
        ].concat(transformedMoreFilter),
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return makeRequest(
    `${export_url}/api/response-users?${searchParams}&sort[1]=createdAt%3Adesc&populate[0]=test&populate[1]=users_permissions_users&populate[2]=responses.variable&isExport=true`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const putResponseUsers = async (token: string, idResponse: string, data: any) => {
  return makeRequest(`${url}/api/response-users/${idResponse}`, 'PUT', data, getHeaders(token));
};

export const getAllResponseUsersNoFilter = async (token: string, page: number) => {
  const searchParams = qs.stringify(
    {
      pagination: {
        page,
        pageSize: 10,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/response-users?${searchParams}&filters[show][$ne]=false&sort=id&populate[0]=test&populate[1]=users_permissions_users&populate[2]=responses.variable`,
    'GET',
    {},
    getHeaders(token)
  );
};

export const getMinorTestResults = async (
  token: string,
  testId = 0,
  moreFilter = [],
  paginationPage = 1,
  rattachement = true,
  isActivated = true,
  company?: any
) => {
  let transformedMoreFilter: any = [];
  if (Array.isArray(moreFilter) && moreFilter.length > 0) {
    transformedMoreFilter = moreFilter?.map((item: any) => {
      const itemTransformed = getMinorTestStrapiFilter(item);
      return itemTransformed;
    });
  }

  const searchParams = qs.stringify(
    {
      filters: {
        $and: [
          {
            show: {
              $ne: false,
            },
            test: {
              id: {
                $eq: testId,
              },
            },
            users_permissions_users: {
              company: {
                id: {
                  $eq: company,
                },
              },
              isActivated: {
                $eq: isActivated,
              },
              rattachement: {
                $eq: rattachement,
              },
            },
          },
        ].concat(transformedMoreFilter),
      },
      pagination: {
        page: paginationPage,
        pageSize: 10,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return makeRequest(
    `${url}/api/response-users?${searchParams}&sort[1]=createdAt%3Adesc&populate[0]=test&populate[1]=users_permissions_users&populate[2]=responses.variable`,
    'GET',
    {},
    getHeaders(token)
  );
};
