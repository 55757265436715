import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '90%',
      margin: '0 auto',
      [theme.breakpoints.up(1697)]: {
        width: '85%',
      },
      [theme.breakpoints.down(500)]: {
        width: '95%',
      },
    },
    contentLoader: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down(900)]: {
        height: '100vh',
        width: '100%',
      },
    },
    btnHistory: {
      position: 'absolute',
      top: '6%',
      right: 0,
      [theme.breakpoints.down(1440)]: {
        top: '6%',
      },
      [theme.breakpoints.down(800)]: {
        top: '2%',
      },
    },
  })
);
