/* eslint-disable no-use-before-define */
import Typography from '@material-ui/core/Typography';
import { MarginContainer } from '@orientaction/commons';
import { Page, ParagrapthListNumber, Title, Paragrapth } from '@orientaction/components';
import useStyles from './style';
import { text } from './context';


const Page24 = (props: any) => {

    const classes = useStyles();

    return (
        <Page id={24}>

            <Title
                title="CHOIX N°3 : CHANGER D’EMPLOYEUR ?"
                firstTitle="‘‘ Il n’y a rien de négatif dans le changement, si c’est dans
                la bonne direction. ’’"
                firtsecondTitle="Winston Churchill"
                secondTitle="‘‘ Changement d’herbage réjouit les chevaux. ’’"
                secondSousTitle="Proverbe français"
            />

            <MarginContainer>

                <Typography className={classes.paragraphe} style={{ fontFamily: 'ITC Avant Garde Gothic Std Medium', marginTop: '10px', textAlign: 'justify', fontWeight: 'lighter' }}>
                    Changer d’employeur signifie continuer à exercer le même métier, mais dans
                    un environnement professionnel différent. L’avantage est que vous pourrez vous
                    appuyer sur votre expérience et vos compétences pour les proposer à un nouvel
                    employeur et ainsi négocier des conditions différentes.
                </Typography>
                
            </MarginContainer>

            <MarginContainer>

                <Typography className={classes.paragraphe}
                    style={{}}>
                    Un tel changement peut apporter plusieurs choses : une meilleure rémunération,
                    de meilleures conditions de travail ou de transport et/ou de nouvelles perspectives
                    professionnelles, plus de latitude décisionnelle ou une meilleure ambiance.
                </Typography>

            </MarginContainer>

            <MarginContainer>
                
                <Typography color="primary" className={classes.testimony}>
                    UN CHANGEMENT D’EMPLOYEUR SE FAIT GÉNÉRALEMENT EN TROIS TEMPS :
                </Typography>

            </MarginContainer>

            <MarginContainer big={true}>

                <ParagrapthListNumber
                    list={[
                        {
                            text: `Vous préparez vos outils de recrutement (CV, lettre de motivation, mise à
                                jour de votre profil sur LinkedIn) et vous analysez le marché de l’emploi sur
                                la zone géographique visée. Dans certains cas, une négociation avec votre
                                employeur actuel peut avoir lieu.`,
                            number: 1
                        },
                        {
                            text: `Vous adressez vos candidatures spontanées et répondez aux offres. Il s’ensuit
                            des entretiens de recrutement. Ces entretiens auront pour objectif de
                            vérifier que le poste proposé par le nouvel employeur correspond bien à vos
                            aspirations.`,
                            number: 2
                        },
                        {
                            text: `Vous démissionnez et signez votre nouveau contrat de travail. Vous vous
intégrez à la nouvelle organisation (entreprise, association, administration).
Le temps est venu maintenant de profiter pleinement des bienfaits de ce
nouveau job !`,
                            number: 3
                        },
                    ]}
                />

            </MarginContainer>

            <MarginContainer  big={true}>
                {
                    text.map((item: string) => (
                        <MarginContainer>
                            <Paragrapth align='justify'>
                                {item}
                            </Paragrapth>
                        </MarginContainer>
                    ))
                }
            </MarginContainer>

        </Page>
    );
};

export default Page24;
