// eslint-disable-next-line simple-import-sort/imports
import { Grid } from '@material-ui/core';
import {
    Paragrapth,
    TitlePage,
    Page,
    TitleChapitre,
} from '@orientaction/components';
import LensIcon from '@material-ui/icons/Lens';
import useStyles from "../../style";


const Page07 = (props: any) => {

    const classe = useStyles();

    const listTitre = (data: string[]) => {
        return data.map((item: string) => <div>

            <Paragrapth
                bold={true}
                align="start"
                colorBlue={true}
            >
                <span
                    style={{
                        color: "yellow",
                        margin: "0 0.5vh",

                    }}
                ><LensIcon fontSize='small' style={{ fontSize: "1vh" }} />
                </span>
                <strong>{item || ""}</strong>
            </Paragrapth>
            <br />
        </div>

        )
    }
    return (
        <Page
            id={7}

        >

            <TitleChapitre nbr={1} />

            <TitlePage>
                POURQUOI DONNER DU SENS <br />
                À VOTRE VIE PROFESSIONNELLE ?
            </TitlePage>

            {
                listTitre([
                    "Quel but voulez-vous donner à votre carrière ?",
                    "Avez-vous un destin professionnel ?",
                    "Qu’est-ce qui fait que vos actions ont un sens aujourd’hui ?",
                    "Qu’est-ce qui fait qu’elles en auront encore plus demain ?",
                    "Pourquoi vous êtes-vous levé(e) ce matin ?",
                    "Pour quelle cause seriez-vous prêt(e) à vous engager ?",
                    "Quel métier choisir pour exprimer vos valeurs ?"
                ])
            }
             <br />
             <br />
            <Paragrapth align='justify'>
                Chaque jour, des millions de personnes se rendent au travail. Elles se lèvent tôt,
                affrontent les transports, font preuve d’une intelligence extraordinaire pour s’organiser.
                Une fois parvenues chez leur employeur, elles passent des heures à chercher des
                solutions à des problèmes insolubles, à mettre sur pied des stratégies pour corriger
                des erreurs ou résoudre des conflits.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                À chaque seconde, il faut qu’elles se dépassent pour atteindre de nouveaux
                objectifs toujours plus ambitieux. 
                <Paragrapth align='justify' colorBlue={true}>
                    Chaque journée est un incroyable défi !
                </Paragrapth>
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Pour relever ces défis, il faut une énergie considérable. La condition indispensable
                pour trouver cette énergie est que ce travail et cette vie aient un sens. C’est dans le
                sens donné à l’action que nous trouvons l’énergie de l’action.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Les personnes qui ont conscience de ce qu’elles font et de pourquoi elles le font
                sont mues par une volonté inébranlable. Elles sont concentrées sur leur objectif et
                chaque fois qu’elles atteignent cet objectif, cette volonté se renforce. Ces personnes
                ont quelque chose de plus que les autres qui les aide à réussir.
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify'>
                Lorsque l’on réfléchit à son avenir professionnel, il est donc capital de commencer
                par répondre à ces deux questions : quel est le sens de ma carrière et quel est le
                sens de ma vie ?
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='justify' colorBlue={true}>
                <strong>À ces questions, il faut apporter des réponses claires.</strong>
            </Paragrapth>
            <br />
            <br />
            <Paragrapth align='start'>
                C’est ainsi que l’on peut trouver sa place au sein de la société, que l’on parvient à
                se sentir utile et important(e) et à trouver, dans cette raison d’être personnelle, une
                source incroyable de courage et d’énergie pour affronter les épreuves et relever les
                défis quotidiens de la vie professionnelle.
            </Paragrapth>
        </Page>
    );
};
export default Page07;
