/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import { Layout, Modal, ProviderDnd } from '@orientaction/components';
import { BtnPrimary } from '@orientaction/commons';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import { IcontentICategoryTest } from '@orientaction/types';
import { useCategoryTest } from '@orientaction/hooks';
import { useStyles } from './style';
import DragAndDrop from './DragAndDrop';
import Footer from './Footer';
import { useEffect, useState } from 'react';
import { randomNumber, reConstructArray, randomNumberExclusion } from '@orientaction/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';

const GestionCategorie = (props: any) => {
  const classe = useStyles();

  const {
    list,
    loading,
    loadingUpdate,
    modal,
    setList,
    resetModal,
    setModal,
    updateCategoryTest,
    getManageCategory,
    setLoading,
  } = useCategoryTest();

  const history = useHistory();

  useEffect(() => {
    getManageCategory({
      isDesabled: false,
      isArchived: false,
    });
  }, []);

  const createCategorie = (e: any) => {
    e.preventDefault();

    const lastedArray: any[] = [...list];

    const nbr: any = randomNumberExclusion(
      1,
      100,
      lastedArray.map(el => el.id)
    );

    lastedArray.unshift({
      id: nbr,
      name: '',
      contentList: [],
      type: 'create',
    });

    setList(reConstructArray(lastedArray));
  };

  const handleSubmit = (e: any) => {
    const validateList = list.map((x: IcontentICategoryTest) => {
      const is: any = x;
      is.required = !is.name;
      return is;
    });

    setList(validateList);

    if (validateList.filter((y: IcontentICategoryTest) => y.required).length) {
      return;
    }

    updateCategoryTest();
  };

  const handleOpenModal = () => {
    setModal({
      type: 'cancel_all',
      open: true,
      title: 'Êtes-vous sûr de vouloir annuler les modifications ?',
      textCancel: 'Non',
      textValidate: 'Oui',
      noComfirmButton: false,
    });
  };

  const cancelIt = () => {
    resetModal();
  };

  const onConfirmUpdate = () => {
    if (modal.type === 'cancel_all') {
      setLoading(true);

      setTimeout(() => {
        history.push('/admin/test');
      }, 1000);
    }

    if (modal.type === 'delete_category') {
      const findIndex = list.findIndex(
        (x: IcontentICategoryTest) => x && x?.id === modal.id_category
      );

      list[findIndex] = {
        ...list[findIndex],
        type: 'delete',
      };

      setList(list);
    }

    resetModal();
  };

  return (
    <Layout isWhite={true} noPadding={true} showLink={false}>
      <ProviderDnd>
        <div className={classe.root}>
          <div className={classe.content}>
            <Grid container={true} alignItems="center" justifyContent="space-between">
              <Grid item={true}>
                <Typography className={classe.title}>Gestion des catégories</Typography>
              </Grid>

              <Grid data-cy="category-creation-btn-container" item={true}>
                <BtnPrimary
                  isPrimary={true}
                  startIcon={<AddIcon />}
                  disabled={loadingUpdate}
                  onClick={createCategorie}>
                  Créer une nouvelle catégorie
                </BtnPrimary>
              </Grid>
            </Grid>

            {loading ? (
              <div className={classe.loadingFullWidth}>
                <CircularProgress />
              </div>
            ) : (
              <div className={classe.rootDragAndDrop}>
                <DragAndDrop />
              </div>
            )}
          </div>
        </div>
      </ProviderDnd>

      <Footer>
        <div className={classe.footer}>
          <BtnPrimary onClick={handleOpenModal} disabled={loadingUpdate}>
            Annuler
          </BtnPrimary>

          <BtnPrimary isPrimary={true} disabled={loadingUpdate} onClick={handleSubmit}>
            {loadingUpdate ? 'Enregistrement...' : 'Enregistrer'}
          </BtnPrimary>
        </div>
      </Footer>

      <Modal
        title=""
        textCancel={modal.textCancel}
        textValidate={modal.textValidate}
        onCancel={cancelIt}
        noComfirmButton={modal.noComfirmButton}
        open={modal.open}
        onConfirm={onConfirmUpdate}>
        <h3 className={classe.titleModal}>{modal.title}</h3>
      </Modal>
    </Layout>
  );
};

export default GestionCategorie;
