import { FC } from "react";
import { publicBook5Path } from '@orientaction/utils';
import { Grid } from '@material-ui/core';

interface Iimg {
    src: string;
    alt: string;
    style?: any;
    className?: any;

}

const Img: FC<Iimg> = ({ src, alt, style, className }) => {

    return (
        <>
            <Grid
                container={true}
                direction='row'
                justifyContent="center"
                alignItems="center"
            >

                <img
                    src={`${publicBook5Path}/${src}`}
                    alt={alt}
                    className={className}
                    style={style}
                />

            </Grid>

        </>
    );

};

export default Img;
