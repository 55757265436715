import { ApiActions } from '@orientaction/api-actions';
import { NeedsProTestResultService } from '@orientaction/services';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '../utils/utils';

const useNeedsProTestResult = () => {
  const dispatch = useDispatch();
  const needsProTestResultsState: any = useSelector(
    (state: any) => state?.api?.needsProTestResults,
  );

  const token = getFromLS('userToken') || '';

  const getNeedsProTestResults = async () => {
    try {
      const needsProTestResults = await NeedsProTestResultService.getNeedsProTestResults(token);
      dispatch(ApiActions.setNeedsProTestResults(needsProTestResults?.data?.data));
    } catch (error) {
      console.log('error on getting needsProTestResults ', error);
    }
  };

  const getMyResult = async (id: number) => {
    return NeedsProTestResultService.getMyResultNeedPro(token, id);
  };

  const getMyResumeElement = async (data: any) => {
    return NeedsProTestResultService.getMyResumeElement(token, data);
  };

  return {
    needsProTestResults: needsProTestResultsState,
    getNeedsProTestResults,
    getMyResult,
    getMyResumeElement,
  };
};

export default useNeedsProTestResult;
