export const listSomaire = [
    {
        text: `IDENTIFIER VOS COMPÉTENCES
        ET ÉVALUER VOTRE POTENTIEL`,
        number: '7',
        isBold: true,
    },
    {
        text: 'LE MODÈLE DE L’ARBRE',
        number: '10',
        isBold: false,
    },
    {
        text: ' LES 4 CAPITAUX (LES RACINES)',
        number: '15',
        isBold: true,
    },
    {
        text: 'LE CAPITAL ÉCONOMIQUE',
        number: '16',
    },
    {
        text: 'LE CAPITAL SOCIAL',
        number: '19',
    },
    {
        text: 'LE CAPITAL CULTUREL',
        number: '24',
    },
    {
        text: 'LE CAPITAL SYMBOLIQUE',
        number: '27',
    },
    {
        text: 'LES « SOFT SKILLS » (LE TRONC)',
        number: '30',
        isBold: true,
    },
    {
        text: '« SOFT SKILLS » ET « HARD SKILLS »',
        number: '31',
    },
    {
        text: 'TESTEZ MAINTENANT VOS « SOFT SKILLS » AVEC HARMONY',
        number: '33',
    },
    {
        text: 'IDENTIFIEZ ICI VOS « SOFT SKILLS » DOMINANTES',
        number: '34',
    },
    {
        text: 'LES 3 SAVOIRS (LES BRANCHES)',
        number: '36',
        isBold: true,
    },
    {
        text: 'QUELS SONT VOS 3 SAVOIRS (LES BRANCHES) ?',
        number: '37',
    },
    {
        text: 'VOTRE EXPÉRIENCE PROFESSIONNELLE LA PLUS MARQUANTE',
        number: '38',
    },
    {
        text: 'VOTRE EXPÉRIENCE PERSONNELLE LA PLUS MARQUANTE',
        number: '39',
    },
    {
        text: 'RETOUR SUR VOTRE PRINCIPAL DIPLÔME',
        number: '40',
    },
    {
        text: 'LA SYNTHÈSE DE VOS COMPÉTENCES',
        number: '41',
    },
    {
        text: 'MAINTENANT, METTEZ À JOUR VOTRE CV !',
        number: '42',
    },
    {
        text: 'VOTRE VALEUR AJOUTÉE (LES FRUITS)',
        number: '44',
        isBold: true,
    },
    {
        text: 'QUELLE EST VOTRE VALEUR AJOUTÉE ?',
        number: '45',
    },
]