import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  subtitle: {
    color: '#000A8C',
    fontSize: 26,
    marginTop: 15,
  },
  stockageMode: {
    '&>svg': {
      color: '#0E1247',
    },
    '&>fieldset': {
      borderColor: '#0E1247',
    },
    '&>div': {
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  select: {
    fontFamily: 'Poppins',
  },
}));
