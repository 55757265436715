/* eslint-disable no-useless-return */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import OrientButton from '@orientaction/commons/OrientButton';
import React from 'react';
import useStyles from './style';

interface ISimpleModal {
  handleClose: () => void;
  handleClick?: () => void;
  textClick?: string;
  open: boolean
}

const SimpleModalTest: React.FC<ISimpleModal> = ({handleClose, open, handleClick, textClick}) => {  
  const classes = useStyles();

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.containerPaper}
      >
        <DialogTitle id="alert-dialog-title">Bonjour,</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography
              style={{
                fontFamily: 'Poppins',
                marginTop: '0px',
                textAlign: 'left',
                fontWeight: 400,
              }}>
              Vous accédez à la banque de tests E-Orientaction.<br /><br />
              Concernant les tests majeurs (valeurs, personnalité, etc.), c’est votre consultant(e) qui détermine vos droits d’accès.<br /><br />
              Concernant les tests mineurs, vous pouvez passer 10 tests gratuitement. Concernant ces 10 tests mineurs et au-delà si vous souscrivez un abonnement, sachez que votre consultant(e) n’a pas accès à vos résultats, car ils ne sont pas en lien direct avec votre prestation.<br /><br />
              Ces 10 tests vous sont offerts par ORIENTACTION.<br /><br />
              La Team E-Orientaction<br />
              contact@orientaction.com
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {textClick && 
            <OrientButton onClick={handleClick} >
              {textClick}
            </OrientButton>
          }
        </DialogActions>
      </Dialog>
  );
};

export default SimpleModalTest;
