import { getFromLS } from '@orientaction/utils';

export const canIUpdate = (isUpdate: boolean, currentCompanyUserRole?: number) => {

    const userInfos = JSON.parse(getFromLS('userInfos') || '');

    const { company_user_role, role } = userInfos;

    if (role?.type === "admin") {
        return true;
    }

    if (role?.type === "authenticated") {

        if (!isUpdate) {
            return true;
        }

        if (company_user_role?.id === 1) {
            return true;
        }

        if (currentCompanyUserRole) {

            const condition = ((company_user_role?.id + 1) < (currentCompanyUserRole)) && isUpdate;

            if (condition) {

                return true;

            }

        }

        return false;

    }

    return false;

}