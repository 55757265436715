// eslint-disable-next-line import/no-unresolved
import { getHeaders, makeRequest, retry } from '@orientaction/utils';
import config from './config';

const url = config.api_url;

// export const getBookTemplates = async (token: string) => {
//   return makeRequest(`${url}/api/book-templates?populate=*`, 'GET', {}, getHeaders(token));
// };

export const getUserBooks = async (token: string, data: any) => {
  return makeRequest(`${url}/api/user-books/${data.userId}`, 'GET', {}, getHeaders(token));
};

export const getMyBook = async (token: string, id: any, filter: string, page: number) => {
  return makeRequest(`${url}/api/get-my-book`, 'POST', { id, filter, page }, getHeaders(token));
};

export const updateBook = async (token: string, data: any) => {
  // return makeRequest(`${url}/api/update-book/${data.id}`, 'PUT', data, getHeaders(token));
  return updateBookWithRetry(token, data);
};

export const updateBookForRetry = async ({token, data} : {token: string, data: any}) => {
  return makeRequest(`${url}/api/update-book/${data.id}`, 'PUT', data, getHeaders(token));
};

export const updateBookWithRetry = async (token: string, data: any) => {
  return retry(
    updateBookForRetry, {
    retries: 5,
    retryIntervalMs: 750,
    args: {token, data}
  });
};

export const getMyValueBook = async (token: string, data: any) => {
  if (localStorage.getItem('wglang') !== 'fr' && localStorage.getItem('book')) {
    data.id = localStorage.getItem('book');
  }
  return makeRequest(`${url}/api/get-my-value-book`, 'POST', data, getHeaders(token));
};
