// put style here
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    deleteFileTextContainer: {
      marginLeft: '3.7rem',
      marginTop: 7,
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      marginBottom: 5,
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        textAlign: 'center',
      },
    },
  })
);
