import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { publicLogoPath } from '@orientaction/utils';


const AuthHeader = () => {
  const cls = style();
  return (
    <div className={`${cls.logoContainer} header`}>
      <a href="https://www.orientaction-groupe.com/" target="_blank" rel="noreferrer">
        <picture className={cls.logo}>
          <source media="(min-width:650px)" srcSet={`${publicLogoPath}/e-orientaction.png`} />

          <img src={`${publicLogoPath}/e-orientaction.png`} alt="logo-orientation" className={cls.LogoImg} />
        </picture>
        {/* <img className={cls.logo} src={`${publicSvgPath}/logo-orientaction.png`} alt="logo" /> */}
      </a>
    </div>
  );
};

const style = makeStyles((theme: Theme) => ({
  logoContainer: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up(1250)]: {
      justifyContent: 'flex-start',
      padding: 10,
      textAlign: 'center',
      marginTop: 20,
    },
  },
  logo: {},
  LogoImg: {
    width: 250
  }
}));

export default AuthHeader;
