const baseUrl = process.env.REACT_APP_API_ENDPOINT;

const fetchApi = async (
  url: string,
  method: string,
  data: any = null,
  isFormData = false,
): Promise<any> => {
  const headers: Headers = new Headers();

  if (!isFormData) {
    headers.append('Content-Type', 'application/json');
  }

  const token: string | null = localStorage.getItem('userToken');
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const config: any = {
    method,
    headers,
    body: isFormData ? data : data ? JSON.stringify(data) : null,
  };

  if (method === 'GET' || method === 'DELETE') {
    delete config.body;
  }

  try {
    const response: Response = await fetch(`${baseUrl}${url}`, config);

    if (!response.ok) {
      if (response.status === 401) {
        // This is not good practice, I followed the logic that was there before. We must refactor as much as possible to implement good practice
        window.location.replace('/');
      }

      if (response.status === 403) {
        console.log('Forbiden');
      }

      const errorBody: any = await response.json();
      throw new Error(JSON.stringify(errorBody) || 'Une erreur réseau est survenue');
    }

    return response.status === 204 ? null : method === 'DELETE' ? response : response.json();
  } catch (error) {
    console.error('Erreur lors de la requête:', error);
    throw error;
  }
};

export const apiServiceNative: any = {
  get: (url: string): Promise<any> => fetchApi(url, 'GET'),
  post: (url: string, data: any, isFormData = false): Promise<any> =>
    fetchApi(url, 'POST', data, isFormData),
  delete: (url: string): Promise<any> => fetchApi(url, 'DELETE'),
  put: (url: string, data: any, isFormData = false): Promise<any> =>
    fetchApi(url, 'PUT', data, isFormData),
};
