/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { FC, useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { useStyles } from './style';
import {
  useAlert,
  useNeedsProTestResult,
  useMyResulatOfTest,
  useNeedsTestResult,
} from '@orientaction/hooks';
import { useMediaQuery, Theme, Typography, IconButton, CircularProgress } from '@material-ui/core';
import { MarginContainer } from '@orientaction/commons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface IDrawerView {
  open: boolean;
  handleClose: () => void;
}

const DrawerView: FC<IDrawerView> = ({ open, handleClose }) => {
  const { getNeedsTestResultDetails } = useNeedsTestResult();

  const [loading, setLoading] = useState(false);

  const { setMyElement, myElement, dialog } = useMyResulatOfTest();

  const alert = useAlert();

  const fetchData = async () => {
    setLoading(true);

    try {
      const reponse = await getNeedsTestResultDetails({ ...dialog.data });
      setMyElement(reponse.data.data);
    } catch (err: any) {
      alert.setOpenAlert(true, 'Impossible de recuperer ses elements .', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const classes = useStyles();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Drawer anchor={isMobile ? 'bottom' : 'right'} open={open} onClose={handleClose}>
      <div
        className={clsx({
          [classes.content]: true,
          [classes.desktop]: !isMobile,
          [classes.mobile]: isMobile,
        })}>
        <div
          className={classes.head}
          style={{
            backgroundColor: dialog?.data?.color || myElement?.color,
            color: myElement?.keyWord?.startsWith('eternity') ? '#0E1247' : 'white',
          }}>
          {dialog?.data?.label || myElement?.title || ''}
        </div>
        <IconButton onClick={handleClose} className={classes.handleClose}>
          {isMobile ? (
            <ArrowBackIcon
              className={
                myElement?.keyWord?.startsWith('eternity') ? classes.colorBlue : classes.colorWhite
              }
            />
          ) : (
            <HighlightOffIcon
              className={
                myElement?.keyWord?.startsWith('eternity') ? classes.colorBlue : classes.colorWhite
              }
            />
          )}
        </IconButton>

        {loading ? (
          <div className={classes.contentLoader}>
            <CircularProgress style={{ color: dialog?.data?.color }} />
          </div>
        ) : (
          <>
            {!isMobile && (
              <div className={classes.imgContent}>
                <img src={myElement?.urlImg} className={classes.img} alt="Apercu" />
              </div>
            )}

            <MarginContainer>
              <div className={classes.contentDescription}>
                <Typography className={classes.score}>
                  <b>Votre score : {myElement?.score || ''}</b>
                </Typography>

                <MarginContainer>
                  <Typography component="p" className={classes.paragraph}>
                    <b>{myElement?.paragraph?.title || ''}</b> {myElement?.paragraph?.paragraph}
                  </Typography>
                </MarginContainer>

                <MarginContainer>
                  <div
                    className={classes.contentFavorite}
                    style={{
                      backgroundColor: myElement?.bgEnvironement,
                    }}>
                    <ul>
                      {(myElement?.environement || []).map((item: any) => {
                        return (
                          <li>
                            <Typography component="p" className={classes.paragraph}>
                              <b>{item?.title || ''}</b> {item?.paragraph || ''}
                            </Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </MarginContainer>
              </div>
            </MarginContainer>

            {isMobile && (
              <div className={classes.imgContent}>
                <img src={myElement?.urlImg} className={classes.img} alt="Apercu" />
              </div>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default DrawerView;
