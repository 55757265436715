import { getHeaders, makeRequest } from '@orientaction/utils';
import config from './config';

const url = config.api_url;

export const register = async (data: any, token: string) => {
  return makeRequest(`${url}/api/company-user-admin-create`, 'POST', data, getHeaders(token));
};

export const update = async (data: any, token: string) => {
  return makeRequest(
    `${url}/api/company-user-admin-update/${data.id}`,
    'PUT',
    data,
    getHeaders(token),
  );
};

export const reassigned = async (data: any, token: string) => {
  return makeRequest(`${url}/api/company-user-admin-reassigned`, 'POST', data, getHeaders(token));
};

export const disableAdmin = async (id: any, isActivated: boolean, token: string) => {
  return makeRequest(
    `${url}/api/users/${id}`,
    'PUT',
    { isActivated: isActivated as any },
    getHeaders(token),
  );
};

export const deleteAdmin = async (data: any, token: string) => {
  return makeRequest(`${url}/api/delete-custom`, 'POST', data, getHeaders(token));
};

export const test = async (token: string) => {
  return makeRequest(`http://localhost:4400/test/return401`, 'GET', {}, getHeaders(token));
};

export const rattachBeneficiary = async (data: any, token: string) => {
  console.log('rattach', data);
  return makeRequest(`${url}/api/company-user-admin-create`, 'POST', data, getHeaders(token));
};
