import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  little: {
    margin: '2vh 0',
  },
  small: {
    margin: '1vh 0',
  },
  mormal: {
    margin: '4vh 0',
  },
  big: {
    margin: '6vh 0',
  },
  veryBig: {
    margin: '10vh 0',
  },
  realyVeryBig: {
    margin: '17vh 0',
  },
  marginH: {
    marginLeft: 5,
    marginRight: 5,
  }
}));
