import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FC, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

const Thead: FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <TableHead className={classes.tableTitle}>
      <TableRow>{children}</TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles((theme: any) => ({
  tableTitle: {
    textTransform: 'uppercase',
    background: '#000A8C26 0% 0% no-repeat padding-box',
    borderRadius: '3px 3px 0px 0px',
    '& th': {
      color: '#000A8C',
      fontSize: 13,
      fontFamily: 'ITC Avant Garde Gothic Std Bold',
      letterSpacing: 0,
    },
    '& th, td': {
      minWidth: 25,
      lineHeight: '18px',
      padding: '11px 20px',
      whiteSpace: 'nowrap',
    },
  },
}));

export default Thead;
