import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.up('xs')]: {
        width: '92.5%',
      },
    },
    rootH: {
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        width: '96.5%',
      },
    },
    input: {
      width: '98%',
      marginLeft: theme.spacing(1),
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        '& .MuiOutlinedInput-root': {
          height: 40,
        },
      },
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    inputXs: {
      display: 'none',
      margin: '2vh 0 2vh 0',
      [theme.breakpoints.up('xs')]: {
        display: 'block',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    inputMd: {
      display: 'block',
      marginTop: 20,
      marginBottom: 10,
    },
    colorWhite: {
      color: 'white',
    },
    bodycenter: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
    cardContent: {
      marginRight: '-3%',
      [theme.breakpoints.down(600)]: {
        marginRight: '0%',
      },
    },
    cardContainer: {
      width: '30%',
      marginRight: '3%',
      float: 'left',
      [theme.breakpoints.between(1650, 2000)]: {
        marginBottom: '3.5vh',
      },
      [theme.breakpoints.down(1450)]: {
        width: '48%',
      },
      [theme.breakpoints.down(600)]: {
        float: 'none',
        width: '100%',
      },
    },
    both: {
      clear: 'both',
    },
    searchItem: {
      marginRight: 8,
    },
    searchPaper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
    },
    searchInput: {
      marginLeft: theme.spacing(1),
      width: '100%',
    },
    searchIconButton: {
      padding: 10,
    },
    btnTests: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#E63C3C',
      transition: 'all 0.7s ease allow-discrete',
      '&:hover': {
        background: 'linear-gradient(94.99deg, #000A8C 0%, #E63C3C 50%, #FFDC37 100%)',
      },
      color: 'white',
      padding: '15px 25px',
      minWidth: 250,
      [theme.breakpoints.down('xs')]: {
        minWidth: 100,
      },
      height: 'auto',
      '& div': {
        textTransform: 'none',
        textAlign: 'left',
        fontFamily: 'Poppins',
      },
      '& .MuiButton-label': {
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    drawerContent: {
      borderTop: '2px solid #23296F',
      borderRadius: 16,
      overflowX: 'hidden',
      minHeight: 300,
      maxHeight: '70vh',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    rootStyle: {
      background: '#000a8c6b',
    },
    contentDrawer: {
      position: 'relative',
      width: '50%',
      margin: '20px auto',
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        width: '95%',
      },
    },
    titleTests: {
      fontSize: 30,
      fontFamily: 'Poppins',
      textTransform: 'none',
      lineHeight: '36px',
    },
    separator: {
      width: '100%',
      height: 1,
      background: '#E4E4F2',
      margin: '25px 0px 25px',
    },
    testCard: {
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #5151512b',
      padding: '15px 25px',
      fontFamily: 'Poppins',
      fontWeight: 400,
      color: '#000A8C',
      margin: '15px 0px',
      cursor: 'pointer',
      transition: 'all 0.4s ease allow-discrete',
      backgroundColor: 'white',
      '&:hover': {
        color: 'white',
        background:
          'linear-gradient(88.68deg, #000A8C 2.25%, #E63C3C 47.27%, #FFDC37 97.75%) 0% 0% no-repeat padding-box',
      },
    },
    subTitleTests: {
      color: '#A9AAB8 !important',
      fontSize: 14,
      fontStyle: 'italic',
    },
    iconBtnImage: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      top: '1.5%',
      right: '1%',
    },
  })
);
