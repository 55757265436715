import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignCenter: {
      textAlign: 'center',
    },
    cardBgGrey: {
      padding: 10,
      background: '#e8eef4',
    },
    cardBlue: {
      width: 45,
      height: 45,
      background: '#2B56A5',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '2.5vh',
    },
    label2: {
      color: '#2B56A5',
      margin: 0,
      padding: 0,
      fontSize: '2vh',
    },
    contentAsk: {
      width: '90%',
      margin: '1vh auto',
    },
    childrenOverrideStyle: {
      color: '#000 !important',
    },
    textOverrideStyle: {
      color: '#000 !important',
    },
    puceContentOverrideStyle: {
      borderRadius: '0px !important',
    },
    textContainerOverrideStyle: {
      color: '#000',
    },
  })
);

export default useStyles;
