import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    flexWrap: 'wrap-reverse',
    maxHeight: '100%',
  },
  layout: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
  },
  content: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    '&>div': {
      height: 'calc(100vh - 90px)',
      padding: theme.spacing(4, 3),
      color: theme.palette.primary.main,
      overflow: 'auto',
      '@media(min-width: 1750px)': {
        padding: '0px 0px 0px 0px',
      },
    },
  },
}));
