/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import Typography from '@material-ui/core/Typography';
import {
  Modal,
  Search,
  CreateEditEntreprise,
  Switch,
  Layout,
  TableContent,
} from '@orientaction/components';
import { Company } from '@orientaction/services';
import { Grid, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { standartCouloms } from './ColoumTable/coulomsData';
import style from './style';
import { useAlert, useCompany, useMe } from '@orientaction/hooks';
import { getFromLS } from '@orientaction/utils';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';
import dateFormat from 'dateformat';
import { ContainerAdmin, MarginContainer } from '@orientaction/commons';

const AdminEntreprise = (props: any) => {
  const [listCompagny, setListCompagny] = useState<any[] | []>([]);

  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  const [loadingGetAll, setLoadingGetAll] = useState(false);

  const token = getFromLS('userToken') || '';

  const user = getFromLS('userInfos') ? JSON.parse(getFromLS('userInfos') || '') : undefined;

  const [keyWordFilter, setKeyWordFilter] = useState('');

  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);

  const [viewDisable, setViewDisable] = useState(false);

  const { me } = useMe();

  const history = useHistory();

  const [openComfimModal, setOpenComfimModal] = useState({
    open: false,
    title: '',
    paragraph: '',
    textBtn: '',
    type: '',
    id: '',
    isActivated: false,
  });

  const [openDrawer, setOpenDrawer] = useState(false);

  const [current, setCurrent] = useState({
    isView: false,
    isUpdate: false,
  });

  const classes = style();

  const { getCompanies } = useCompany();

  const onDelete = (data: any) => {
    setOpenComfimModal({
      open: true,
      title: 'Supprimer le compte',
      paragraph: 'Êtes-vous sûr de vouloir supprimer ce compte ?',
      textBtn: 'Supprimer ce compte',
      type: 'delete',
      id: data.id,
      isActivated: false,
    });
  };

  const cancel = () => {
    setOpenDrawer(false);

    setOpenComfimModal({
      open: true,
      title: `Annuler la ${current.isUpdate ? 'modification' : 'création'} du compte`,
      paragraph: `Êtes-vous sûr de vouloir annuler la ${
        current.isUpdate ? 'modification' : 'création'
      } de ce compte ?`,
      textBtn: `Annuler la ${current.isUpdate ? 'modification' : 'création'}`,
      type: 'cancel',
      id: '',
      isActivated: false,
    });
  };

  const Comfirm = async () => {
    if (openComfimModal.type === 'desable') {
      setLoading(true);

      await Company.CompaniesDisabled(token, {
        id: openComfimModal.id,
        isActivated: !openComfimModal.isActivated,
      })
        .then((res: any) => {
          updatesLine(openComfimModal.id, res.data.data);

          setOpenComfimModal({
            open: false,
            title: ' ',
            paragraph: ' ',
            textBtn: ' ',
            type: ' ',
            id: ' ',
            isActivated: false,
          });
        })
        .catch((err: any) => {
          alert.setOpenAlert(
            true,
            'Une erreur est survenue, merci de réessayer plus tard',
            'error'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (openComfimModal.type === 'delete') {
      setLoading(true);

      await Company.deleteCompanies(token, openComfimModal.id)
        .then(() => {
          deleteLine(openComfimModal.id);

          setOpenComfimModal({
            open: false,
            title: ' ',
            paragraph: ' ',
            textBtn: ' ',
            type: ' ',
            id: ' ',
            isActivated: false,
          });
        })
        .catch((err: any) => {
          alert.setOpenAlert(
            true,
            'Une erreur est survenue, merci de réessayer plus tard',
            'error'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (openComfimModal.type === 'cancel') {
      setOpenDrawer(false);

      setOpenComfimModal({
        open: false,
        title: ' ',
        paragraph: ' ',
        textBtn: ' ',
        type: ' ',
        id: ' ',
        isActivated: false,
      });
    }
  };

  const handleClose = () => {
    if (openComfimModal.type === 'cancel') {
      setOpenDrawer(true);
    }

    setOpenComfimModal({
      open: false,
      title: ' ',
      paragraph: ' ',
      textBtn: ' ',
      type: ' ',
      id: ' ',
      isActivated: false,
    });
  };

  const getCompagny = async (propsFilter: any) => {
    setLoadingGetAll(true);

    try {
      const { data } = await Company.getfilter(token, {
        keyWord: propsFilter.keyWord,
        isActivated: !propsFilter.isActivated,
        page: propsFilter.page,
      });

      setListCompagny(data.listFilter);

      setCount(data.count);
    } finally {
      setLoadingGetAll(false);
    }
  };

  useEffect(() => {
    if (!openDrawer) {
      getCompagny({
        keyWord: keyWordFilter,
        isActivated: viewDisable,
        page,
      });
    }
  }, [keyWordFilter, viewDisable, page, openDrawer]);

  const addNewAdmin = () => {
    setOpenDrawer(true);

    setCurrent({
      isUpdate: false,
      isView: false,
    });
  };

  const onEdit = (data: any) => {
    console.log('data', data);

    setCurrent({
      ...data,
      isView: !!data.isView,
      isUpdate: true,
    });

    setOpenDrawer(true);
  };

  const oneDisable = (data: any) => {
    setOpenComfimModal({
      open: true,
      title: `${data.isActivated ? 'Désactiver' : 'Activer'} cet espace entreprise`,
      paragraph: `Êtes-vous sûr de vouloir ${
        data.isActivated ? 'désactiver' : 'activer'
      }  cet espace entreprise  ?`,
      textBtn: ` ${data.isActivated ? 'Désactiver' : 'Activer'} cet espace  `,
      type: 'desable',
      id: data.id,
      isActivated: data.isActivated,
    });
  };

  const deleteLine = (id: any) => {
    const listAdmin: any[] = listCompagny || [];

    const filter = listAdmin.filter((item: any) => item.id !== id);

    setListCompagny(filter);
  };

  const updatesLine = (id: any, attributes?: any) => {
    const listAdmin: any[] = listCompagny || [];

    const filter = listAdmin.map((item: any) => {
      if (item.id === id) {
        return {
          ...attributes,
          createdAt: dateFormat(new Date(attributes.createdAt), 'dd/mm/yyyy'),
        };
      }

      return item;
    });

    setListCompagny(filter);

    return filter;
  };

  const actionAdmin = (data: any) => {
    if (current.isUpdate) {
      updatesLine(data.id, data.attributes);

      return;
    }

    const listAdmin: any[] = listCompagny || [];

    listAdmin.unshift({
      id: data.id,
      ...data.attributes,
      createdAt: dateFormat(new Date(data.attributes.createdAt), 'dd/mm/yyyy'),
    });

    setListCompagny(listAdmin);
  };

  const handleDisable = (e: any) => {
    const { checked } = e.target;

    setViewDisable(checked);
  };

  const cancelRedirect = (e: any) => {
    history.push('/admin/users-resultats');
  };

  const handleFilterChange = async (keyWord: string) => {
    setKeyWordFilter(keyWord);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  if (!user) {
    return null;
  }

  return (
    <Layout isaddBackground={true}>
      <div>
        <ContainerAdmin>
          <MarginContainer big={true}>
            <Typography variant="h2">Administration des espaces entreprise</Typography>
          </MarginContainer>

          <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item={true} xs={2}>
              <Button
                variant="contained"
                className={clsx(classes.searchButton, classes.btn)}
                disabled={false}
                onClick={cancelRedirect}>
                <KeyboardArrowLeftIcon />
                <span>Retour</span>
              </Button>
            </Grid>

            <Grid item={true} xs={5}>
              <Grid container={true} direction="row">
                <Grid item={true} xs={4}>
                  <Switch
                    checked={!!viewDisable}
                    onChange={handleDisable}
                    label="Espaces inactifs"
                  />
                </Grid>

                <Grid item={true} xs={8}>
                  <Search
                    handleFilterChange={handleFilterChange}
                    addNew={addNewAdmin}
                    wordFilter={keyWordFilter}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <MarginContainer>
            <TableContent
              bgColor="#23296F"
              loading={loadingGetAll}
              array={listCompagny}
              coulom={standartCouloms}
              oneEdit={onEdit}
              oneDisable={oneDisable}
              onDelete={onDelete}
              pagination={{
                count: count as number,
                onChangePage: handleChangePage as any,
                page: +page as number,
                rowsPerPage: 10 as number,
              }}
            />
          </MarginContainer>
        </ContainerAdmin>
      </div>

      <CreateEditEntreprise
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        data={current}
        isView={current.isView}
        isUpdate={current.isUpdate}
        cancel={cancel}
        actionAdmin={actionAdmin}
      />

      <Modal
        open={!!openComfimModal.open}
        onCancel={handleClose}
        onConfirm={Comfirm}
        textCancel="Annuler"
        title={openComfimModal.title || ''}
        textValidate={loading ? 'Chargemment ...' : openComfimModal.textBtn}>
        <div className={classes.modalContent}>
          <Typography variant="h6" className={classes.modalText}>
            {openComfimModal.paragraph || ''}
          </Typography>
        </div>
      </Modal>
    </Layout>
  );
};

export default AdminEntreprise;
