/* eslint-disable react/destructuring-assignment */
import { MarginContainer, TextArea } from '@orientaction/commons';
import { Page, TitleNbr, TitlePage, Paragrapth } from '@orientaction/components';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import { useBook4 } from '@orientaction/hooks_book';
import { defaultData } from './constant';


const Page38 = (props: any) => {

  const classes = useStyles();

  const { yourBestProfessionalExperience, setYourBestProfessionalExperience } = useBook4();

  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setYourBestProfessionalExperience({
      ...yourBestProfessionalExperience,
      oneExperience: {
        ...yourBestProfessionalExperience?.oneExperience,
        [name]: value
      }
    });

  };

  const handleChangeList = (group: any) => (e: any) => {

    const { name, value } = e.target;

    setYourBestProfessionalExperience({
      ...yourBestProfessionalExperience,
      oneExperience: {
        ...yourBestProfessionalExperience?.oneExperience,
        [group]: {
          ...yourBestProfessionalExperience?.oneExperience?.[group],
          [name]: value
        }
      }
    });

  };

  return (
    <Page id={38} readOnly={false}>
      <TitlePage showSpanYellow={false}>
        VOTRE EXPÉRIENCE PROFESSIONNELLE
        <br />
        LA PLUS MARQUANTE
      </TitlePage>
      <Grid container={true}>
        <Grid
          container={true}
          item={true}
          style={{ marginTop: 20 }}
          spacing={1}
          xs={12}
          sm={6}
          direction="row"
          alignItems="center"
        >
          <Grid item={true} >

            <Paragrapth
              colorBlue={true}
              align="justify"
            >
              Intitulé :
            </Paragrapth>

          </Grid>

          <Grid item={true} xs={12} sm={9}>
            <TextArea
              name="init"
              onChange={handleChange}
              value={yourBestProfessionalExperience?.oneExperience?.init || ""}
              veryLitleTextField={true}
              readOnly={props.readOnly}
            />
          </Grid>
        </Grid>
        <Grid
          container={true}
          style={{ marginTop: 20 }}
          spacing={1}
          xs={12}
          sm={6}
          direction="row"
          alignItems="center"
        >
          <Grid item={true} >

            <Paragrapth
              colorBlue={true}
              align="justify"
            >
              Année :
            </Paragrapth>

          </Grid>

          <Grid item={true} xs={12} sm={9}>
            <TextArea
              name="date"
              onChange={handleChange}
              value={yourBestProfessionalExperience?.oneExperience?.date || ""}
              veryLitleTextField={true}
              readOnly={props.readOnly}
            />
          </Grid>

        </Grid>
      </Grid>


      <MarginContainer>

        <Paragrapth
          colorBlue={true}
          align="justify"
        >
          Éléments de contexte :
        </Paragrapth>

        <TextArea
          name="elementContext"
          onChange={handleChange}
          value={yourBestProfessionalExperience?.oneExperience?.elementContext || ""}
          litleTextField={true}
          readOnly={props.readOnly}
        />

      </MarginContainer>

      <MarginContainer>

        {

          defaultData.map((item: any) => (

            <>

              <MarginContainer>

                <TitleNbr number={item.id} text={item.title} />

                <MarginContainer mormal={true}>

                  {

                    item.label && (

                      <Paragrapth
                        colorBlue={true}
                        align="justify"
                      >
                        {item.label || ""}
                      </Paragrapth>

                    )
                  }

                  {
                    item.name && (
                      <TextArea
                        name={item.name}
                        onChange={handleChange}
                        value={yourBestProfessionalExperience?.oneExperience?.[item.name] || ""}
                        litleTextField={true}
                        readOnly={props.readOnly}
                      />
                    )
                  }

                  {
                    item.children && (
                      <>
                        <Grid
                          container={true}
                          spacing={1}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {
                            (item.children.length > 0) && item.children.map((children: any) => (
                              <>
                                <Grid container={true} item={true} xs={12} spacing={2}>
                                  <Grid item={true}>

                                    <Paragrapth
                                      colorBlue={true}
                                      align="justify"
                                    >
                                      {children.label || ""}
                                    </Paragrapth>

                                  </Grid>

                                  <Grid item={true} xs={12} sm={9}>
                                    <TextArea
                                      name={children.name}
                                      onChange={handleChangeList(item.id)}
                                      value={yourBestProfessionalExperience?.oneExperience?.[item.id]?.[children.name] || ""}
                                      litleTextField={true}
                                      readOnly={props.readOnly}
                                    />
                                  </Grid>
                                </Grid>

                              </>
                            ))
                          }
                        </Grid>
                      </>
                    )
                  }

                </MarginContainer>

              </MarginContainer>

            </>

          ))

        }

      </MarginContainer>

    </Page>
  );
};

export default Page38;
