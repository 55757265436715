/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line simple-import-sort/imports
import { Paragrapth, Page } from '@orientaction/components';
import { MarginContainer, TextArea } from '@orientaction/commons';
import useStyles from './style';
import TitleNbr from '../../components/TitleNbr';
import { arrayTextField2, arrayTextField1 } from './constant';
import Array from '../../components/ArrayForm';
import { useBook4 } from '@orientaction/hooks_book';

const Page29 = (props: any) => {
  const classes = useStyles();

  const { diplomeExperience, setDiplomeExperience } = useBook4();

  const handleChange = (e: any) => {

    const { name, value } = e.target;

    setDiplomeExperience({
      ...diplomeExperience,
      [name]: value
    });

  };

  const handleChangeArrayState = (isWho: string) => (groupBy: string) => (e: any) => {

    const { name, value } = e.target;

    setDiplomeExperience({
      ...diplomeExperience,
      [isWho]: {
        ...diplomeExperience?.[isWho],
        [groupBy]: {
          ...diplomeExperience?.[isWho]?.[groupBy],
          [name]: value
        }
      }
    });

  };

  return (
    <Page
      id={29}
      readOnly={false}
    >
      <TitleNbr number={1} text="VOS DIPLÔMES" />

      <MarginContainer>
        <Paragrapth align="justify" colorBlue={true} bold={true}>
          Parmi vos formations,
          <br />
          notez ici le nom des écoles qui disposent d’une certaine notoriété.
        </Paragrapth>
      </MarginContainer>

      <MarginContainer>
        <Paragrapth overrideParagraphStyle={classes.overrideParagraphStyle} align="justify">
          Attention : le rayonnement d’un établissement peut être départemental, régional, national
          ou international. Il peut concerner certaines personnes ou un très grand nombre de
          personnes.
        </Paragrapth>
      </MarginContainer>

      <Array
        readOnly={props.readOnly}
        listArray={arrayTextField1}
        handleChangeArray={handleChangeArrayState('diplome')}
        current={diplomeExperience?.diplome}
      />

      <MarginContainer>

        <TitleNbr number={1} text="VOS EXPÉRIENCES" />
        <MarginContainer>
          <Paragrapth align="justify" colorBlue={true} bold={true}>
            Parmi vos employeurs(ses),
            <br />
            notez ici le nom de ceux(celles) qui disposent d’une certaine notoriété.
          </Paragrapth>
        </MarginContainer>

        <Array
          readOnly={props.readOnly}
          listArray={arrayTextField2}
          handleChangeArray={handleChangeArrayState('experience')}
          current={diplomeExperience?.experience}
        />

        <MarginContainer mormal={true}>
          <Paragrapth
            colorBlue={true}
            overrideParagraphStyle={classes.overrideTextStyle}
            align="justify">
            Quelles actions pourriez-vous mettre en œuvre pour développer votre capital symbolique ?
          </Paragrapth>
        </MarginContainer>
      </MarginContainer>

      <TextArea
        onChange={handleChange}
        name="WhatAreYouDoing"
        value={diplomeExperience?.WhatAreYouDoing || ""}
        placeholder=""
        largeTextField={true}
        readOnly={props.readOnly}
      />

    </Page>
  );
};

export default Page29;
