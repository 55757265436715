import { FC } from 'react';
import { Pie } from 'react-chartjs-2';
import { mockData } from './constant';
import style from './style';

interface IProps {
  data: any;
}

const Diagram: FC<IProps> = ({ data }) => {
  const classes = style();

  const processedData = {
    datasets: [
      {
        labels: data.map((i: any) => i.label),
        data: data.map((i: any) => i.value),
        backgroundColor: data.map((i: any) => i.color),
        borderWidth: 1,
        datalabels: {
          labels: {
            name: {
              align: 'end' as const,
              anchor: 'end' as const,
              color: 'white',
              font: {
                size: 50,
                family: 'Poppins',
              },
              display: false,
              clamp: false,
              clip: false,
            },
          },
        },
      },
    ],
    // labels: data.map((i: any) => i.label),
  };
  return (
    <Pie
      className={classes.pie}
      data={processedData}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: true,
            color: mockData.map((i: any) => 'white'),
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 18,
                },
                align: 'center',
                padding: 0,
              },
            },
            formatter(value: any) {
              return `${value}%`;
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
};

export default Diagram;
