import Typography from '@material-ui/core/Typography';
import { FC } from 'react';
import PersoBlock from './Perso';
import ProBlock from './Pro';
import style from './style';

interface IProps {
  data: any;
}

const TopVariablesBlock: FC<IProps> = ({ data }) => {
  return (
    <>
      <ProBlock proData={data.pro} />
      <PersoBlock persoData={data.perso} />
    </>
  );
};

export default TopVariablesBlock;
