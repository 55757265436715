/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable function-paren-newline, no-extra-boolean-cast */
import { ApiActions } from '@orientaction/api-actions';
import { apiState } from '@orientaction/reducers';
import { ResponseUser, TestUser, Variable } from '@orientaction/services';
import pick from 'lodash/pick';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFromLS } from '../utils/utils';

interface dataApi {
  tests: any[];
  responses: any[];
  testUserResponse: any[];
  testsUser: any[];
  oneResponseUser: any;
  postResponseUsers: (responseUser: any) => Promise<void>;
  postResponseUserConnected: (responseUser: any, tokenCustom: any) => Promise<void>;
  getResponseUsers: (idUser: string) => Promise<void>;
  getResponseTestUser: (idResponseUser: string) => Promise<void>;
  getResponseTestUserGroupById: (idUser: string, idTest: string) => Promise<void>;
}

const useResponseUser = () => {
  const [customTestFilter, setCustomTestFilter] = useState<any>(null);
  const dispatch = useDispatch();

  const data: dataApi = useSelector(apiState);

  const token = getFromLS('userToken') || '';

  const postResponseUsers = async (responseUser: any) => {
    let dataPosted: any;
    if (responseUser.users_permissions_users[0] !== 'undefined') {
      dataPosted = await ResponseUser.postResponseUsers(token, { data: responseUser });
      await TestUser.postTestUser(token, {
        data: pick(responseUser, ['test', 'users_permissions_users']),
      });
      return dataPosted.data.data;
    }
    return dispatch(ApiActions.setResponses(responseUser));
  };

  const postResponseUserConnected = async (responseUser: any, tokenCustom: any) => {
    const dataPosted = await ResponseUser.postResponseUsers(tokenCustom, { data: responseUser });
    await TestUser.postTestUser(token, {
      data: pick(responseUser, ['test', 'users_permissions_users']),
    });
    dispatch(ApiActions.setResponses(null));
    return dataPosted.data.data;
  };

  const getResponseUsers = async (idUser: string) => {
    const responseUser = await ResponseUser.getResponseUsers(token, idUser);
    dispatch(ApiActions.setTestsUser(responseUser.data.data));
    return responseUser.data.data;
  };

  const getResponseTestUser = async (idResponseUser: string) => {
    const oneResponseUser = await ResponseUser.getResponseTestUser(token, idResponseUser);
    dispatch(ApiActions.setoneResponseUser(oneResponseUser.data.data));
    return oneResponseUser.data.data;
  };

  const getResponseTestUserGroupById = async (idUser: string, idTest: string) => {
    const responseUser = await ResponseUser.getResponseTestUserGroupById(token, idUser, idTest);
    return responseUser.data.data;
  };

  const getAllResponseUsers = async (
    filter?: string,
    filterToAdd?: any,
    paginationPage?: number,
    filterUser?: any,
    isActivated?: boolean,
    company?: any
  ) => {
    const responseUsers = await ResponseUser.getAllResponseUsers(
      token,
      filter,
      filterToAdd,
      paginationPage,
      filterUser,
      isActivated,
      company
    );
    return responseUsers.data;
  };

  const putResponseUsers = async (idResponse: string, dataToPut: any) => {
    const responseUpdated = await ResponseUser.putResponseUsers(token, idResponse, dataToPut);
    return responseUpdated.data.data;
  };

  const exportCustomTests = async (
    testId: string,
    rattachement = true,
    isActivated = true,
    filters?: any
  ) => {
    const responseUsers = await ResponseUser.exportResponseUsers(
      token,
      testId,
      rattachement,
      isActivated,
      filters
    );
    return responseUsers.data;
  };

  const getAllResponseUsersNoFilter = async (pageSize: number) => {
    const responseUsers = await ResponseUser.getAllResponseUsersNoFilter(token, pageSize);
    return responseUsers.data;
  };

  const getTestVariables = async (testId: string) => {
    const response = await Variable.getVariableFilterByTest(token, testId);
    return response.data;
  };

  return {
    ...data,
    getTestVariables,
    postResponseUsers,
    postResponseUserConnected,
    getResponseUsers,
    getResponseTestUser,
    getResponseTestUserGroupById,
    getAllResponseUsers,
    exportCustomTests,
    putResponseUsers,
    getAllResponseUsersNoFilter,
    setCustomTestFilter,
    customTestFilter,
  };
};

export default useResponseUser;
