/* eslint-disable no-use-before-define */
import { MarginContainer } from '@orientaction/commons';
import { Page, TitlePage } from '@orientaction/components';
import Grid from '@material-ui/core/Grid';
import Img from '../../components/Img';
import { publicSvgPath } from '@orientaction/utils';

const Page35 = (props: any) => {

    return (
        <Page id={35} center={true}>

            <TitlePage>
                NOUS VOUS SOUHAITONS<br />
                TOUS NOS VŒUX DE RÉUSSITE<br />
                POUR VOTRE PROJET !
            </TitlePage>

            <MarginContainer>
                <Img
                    src='heureux.jpg'
                    alt='heureux'
                    style={{ width: '85%' }}
                />
            </MarginContainer>

            <MarginContainer big={true}>
                <Grid container={true} spacing={2} justifyContent="center">
                    <Grid item={true} xs={5}>
                        <img src={`${publicSvgPath}/logoDesktop.svg`} alt="logo" style={{ width: '100%' }} />
                    </Grid>
                </Grid>
            </MarginContainer>

        </Page>
    );
};

export default Page35;
