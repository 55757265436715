import { makeStyles, useTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: '1vh',
        padding: '1.5vh'
    },
    backgroundSeleted: {
        background: 'rgba(220,220,220,0.2)'
    }
}));