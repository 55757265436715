const INITIAL_STATE: any = {
  code: localStorage?.getItem('wglang')?.toUpperCase() || '',
};

const languageReducer = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return { ...state, code: action.payload.message };
    }

    default:
      return state;
  }
};

export default languageReducer;
