/* eslint-disable import/no-unresolved */
// eslint-disable-next-line simple-import-sort/exports
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  scrollButtons: {
    '&:first-child': {
      borderRadius: '50px',
      position: 'absolute',
      zIndex: 100,
      minHeight: '48px',
      backgroundColor: '#EDEEF2',
    },
  },
})(Tabs);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: 'white',
    width: 'auto',
    position: 'relative',
    float: 'left',
    borderRadius: '50px',
    [theme.breakpoints.down(1440)]: {
      width: '100%',
    },
  },
}));

const a11yProps = (index: any) => {
  return {
    id: `simple-tabpanel-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export { a11yProps, AntTabs, useStyles };
