export const defaultData = [
    {
        title: 'LES 3 MATIÈRES PRINCIPALES ENSEIGNÉES DANS VOTRE DIPLÔME',
        id: 1,
        children: [
            {
                label: '1',
                name: 'A1'
            },
            {
                label: '2',
                name: 'A2'
            },
            {
                label: '3',
                name: 'A3'
            }
        ]
    },
    {
        title: 'LES 3 DIFFÉRENCES AVEC LES DIPLÔMES ÉQUIVALENTS',
        id: 2,
        children: [
            {
                label: '1',
                name: 'B1'
            },
            {
                label: '2',
                name: 'B2'
            },
            {
                label: '3',
                name: 'B3'
            }
        ]
    },
    {
        title: 'LES 3 QUALITÉS PERSONNELLES DÉVELOPPÉES',
        id: 3,
        children: [
            {
                label: '1',
                name: 'C1'
            },
            {
                label: '2',
                name: 'C2'
            },
            {
                label: '3',
                name: 'C3'
            }
        ]
    },
    {
        title: 'LES 3 FORMATIONS COURTES QUI POURRAIENT BOOSTER VOTRE PROFIL',
        id: 4,
        children: [
            {
                label: '1',
                name: 'D1'
            },
            {
                label: '2',
                name: 'D2'
            },
            {
                label: '3',
                name: 'D3'
            }
        ]
    }
]

export const defaultCheked = [
    {
        title: 'Très reconnu',
        id: 1,
        name: 'hightRecognized'
    },
    {
        title: 'Moyennement reconnu',
        id: 1,
        name: 'mediumRecognized'
    },
    {
        title: 'Peu reconnu',
        id: 1,
        name: 'lessRecognized'
    },
]