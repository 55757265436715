import { Button, Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { AdminTestContext } from '@orientaction/Context';
import OaLabel from '@orientaction/commons/OaLabel';
import ResponseSelect from '@orientaction/components/TestVisibilityPermissionSection/ResponseSelect';
import { isNumeric } from '@orientaction/utils';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { getNextId } from '../../utils';
import style from './style';

const ResponseForm = () => {
  const [title, setTitle] = useState<any>('');
  const [variableValue, setValue] = useState<any>();
  const [variableValueString, setValueString] = useState<any>();
  const [order, setOrder] = useState<any>();
  const [variable, setVariable] = useState<any>();
  const [errors, setErrors] = useState({
    title: false,
    variableValue: false,
    order: false,
    variable: false,
  });

  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const handleSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (title.trim().length < 1) {
      setErrors({ ...errors, title: true });
      return;
    }

    if (!isInterval && !variable) {
      setErrors({ ...errors, variable: true });
      return;
    }

    if (!isNumeric(variableValue) || variableValueString === '') {
      setErrors({ ...errors, variableValue: true });
      return;
    }

    if (!order) {
      setErrors({ ...errors, order: true });
      return;
    }

    if (test.responseToEdit) {
      const objectToMove = {
        id: test.responseToEdit.id,
        title,
        variableValue,
        order,
        variable,
        isNew: false,
      };
      const currentIndex = test.questionToCreate.responses.findIndex(
        (obj: any) => obj.id === objectToMove.id
      );

      // Remove the object from its current position
      if (currentIndex !== -1) {
        test.questionToCreate.responses.splice(currentIndex, 1);
      }

      // Move the object to the desired position (e.g., first position)
      test.questionToCreate.responses.splice(order - 1, 0, objectToMove);
      const rearrangedArray = test.questionToCreate.responses.map((arr: any, index: number) => ({
        ...arr,
        order: index + 1,
      }));

      updateTest({
        questionToCreate: {
          ...test.questionToCreate,
          responses: rearrangedArray,
        },
        responseDrawerOpen: false,
        responseToEdit: undefined,
      });
    } else if (order >= test.questionToCreate.responses.length + 1) {
      const responseId = getNextId(test.questionToCreate.responses);
      const newArray = [
        ...test.questionToCreate.responses,
        { id: responseId, title, variableValue, order, variable, isNew: true },
      ];
      // rearrange order
      const rearrangedArray = newArray.map((arr: any, index: number) => ({
        ...arr,
        order: index + 1,
      }));
      updateTest({
        questionToCreate: { ...test.questionToCreate, responses: rearrangedArray },
        responseDrawerOpen: false,
      });
    } else {
      const responseId = getNextId(test.questionToCreate.responses);
      const beforeInsert = test.questionToCreate.responses.slice(0, order - 1);
      const afterInsert = test.questionToCreate.responses.slice(order - 1);
      const newArray = [
        ...beforeInsert,
        { id: responseId, title, variableValue, order, variable, isNew: true },
        ...afterInsert,
      ];
      // rearrange order
      const rearrangedArray = newArray.map((arr: any, index: number) => ({
        ...arr,
        order: index + 1,
      }));
      updateTest({
        questionToCreate: { ...test.questionToCreate, responses: rearrangedArray },
        responseDrawerOpen: false,
      });
    }
  };

  const handleTitleChange = (e: any) => {
    setTitle(e.target.value);
    if (errors.title) {
      setErrors({ ...errors, title: false });
    }
  };

  const handleValueChange = (e: any) => {
    if (parseInt(e.target.value, 10) >= 0 || e.target.value === '') {
      setValue(+e.target.value);
      setValueString(e.target.value);
      if (errors.variableValue) {
        setErrors({ ...errors, variableValue: false });
      }
    }
  };

  const handleOrderChange = (e: any) => {
    if (!test.responseToEdit) {
      if (+e.target.value > test.questionToCreate.responses.length) {
        const newOrder = test.questionToCreate.responses.length + 1;
        setOrder(newOrder);
      } else {
        setOrder(+e.target.value);
      }
    } else if (+e.target.value > test.questionToCreate.responses.length) {
      const newOrder = test.questionToCreate.responses.length;
      setOrder(newOrder);
    } else {
      setOrder(+e.target.value);
    }
    if (errors.order) {
      setErrors({ ...errors, order: false });
    }
  };

  const handleVariableChange = (data: any) => {
    setVariable(data);
  };

  useEffect(() => {
    if (test.responseToEdit) {
      setTitle(test?.responseToEdit?.title);
      setValue(test?.responseToEdit?.variableValue);
      setOrder(test?.responseToEdit?.order);
      setValueString(test?.responseToEdit?.variableValue);

      const variableAssociated = (test?.variables || []).filter((v: any) => {
        const responseVariables = test?.responseToEdit?.variable;
        const variablesArray = Array.isArray(responseVariables)
          ? responseVariables
          : responseVariables
          ? [responseVariables]
          : [];

        return variablesArray.some((rv: any) => rv?.id === v?.id);
      });

      setVariable(
        variableAssociated.map((c: any) => {
          return { ...c, value: c.id, label: c.name };
        })
      );
    } else {
      const newOrder = test.questionToCreate.responses.length + 1;
      setOrder(newOrder);
    }
  }, [test.questionToCreate.responses.length, test.responseToEdit, test?.variables]);

  const responseIndex = test.responseToEdit
    ? (test?.questionToCreate?.responses || []).findIndex(
        (r: any) => r.id === test.responseToEdit.id
      )
    : test.questionToCreate.responses.length;

  const { diagram } = test.test;

  const isInterval = diagram?.id === 4;

  return (
    <form className={classes.flexContainer} onSubmit={handleSubmit}>
      <Typography className={classes.responseFormTitle} component="h2">
        RÉPONSE N°{responseIndex + 1}
      </Typography>
      <div className={classNames(classes.marginTop, classes.fullWidth)}>
        <OaLabel>Titre de la réponse</OaLabel>
        <TextField
          id="outlined-basic"
          variant="outlined"
          className={classNames({ [classes.formInput]: true }, { [classes.error]: errors.title })}
          name="variableName"
          onChange={handleTitleChange}
          value={title}
        />
      </div>
      {!isInterval && (
        <div className={classNames(classes.marginTop, classes.fullWidth)}>
          <OaLabel>Restitutions liées</OaLabel>
          {/*       <Select
          placeholder="Sélectionnez"
          options={test.variables}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          value={variable}
          onChange={handleVariableChange}
          isMulti={true}
          className={classNames({
            [classes.formSelect]: true,
            [classes.inputError]: errors.variable,
          })}
        /> */}
          <ResponseSelect
            variableList={test.variables.map((c: any) => {
              return { ...c, value: c.id, label: c.name };
            })}
            variables={variable}
            updateVariable={handleVariableChange}
            error={errors.variable}
          />
        </div>
      )}
      <Grid container={true}>
        <Grid item={true} md={6} spacing={4}>
          <OaLabel>{isInterval ? 'Score' : 'Valeur'}</OaLabel>
          <TextField
            id="response-value"
            variant="outlined"
            className={classNames(classes.textField, errors.variableValue && classes.error)}
            name="responseValue"
            onChange={handleValueChange}
            value={variableValueString}
            inputProps={{
              type: 'number',
              min: 0,
              step: 1,
            }}
          />
        </Grid>
        <Grid item={true} md={6}>
          <OaLabel>{`Ordre d'affichage`}</OaLabel>
          <TextField
            id="response-order"
            variant="outlined"
            className={classNames(classes.textField, errors.order && classes.error)}
            name="responseOrder"
            onChange={handleOrderChange}
            value={order}
            inputProps={{
              type: 'number',
              min: 0,
              step: 1,
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.marginTop}>
        <Button id="response-submit" type="submit" className={classes.btnPrimary}>
          <span className={classes.textHeader}>Sauvegarder</span>
        </Button>
      </div>
    </form>
  );
};

export default ResponseForm;
