/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { useMediaQuery, Theme } from '@material-ui/core';
import Details from '../Details';
import Explication from '../Explication';
import { useStyles } from './style';
import { FC } from 'react';

interface IResultToShow {
    resultToShow: any;
    localText: any;
}

const ViewResume: FC<IResultToShow> = ({
    resultToShow, localText
}) => {

    const classes = useStyles();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <>
            <div className={classes.card}>
                <Details
                    imageSrc={localText.problem_solving.imageSrc}
                    title={localText.problem_solving.title}
                    color={localText.problem_solving.color}
                    note={resultToShow?.problem_solving || 0}
                    description={localText.problem_solving.description}
                    decisiveProfessions={localText.problem_solving.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.confidence.imageSrc}
                    title={localText.confidence.title}
                    color={localText.confidence.color}
                    note={resultToShow?.confidence || 0}
                    description={localText.confidence.description}
                    decisiveProfessions={localText.confidence.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.emotional_intelligence.imageSrc}
                    title={localText.emotional_intelligence.title}
                    color={localText.emotional_intelligence.color}
                    note={resultToShow?.emotional_intelligence || 0}
                    description={localText.emotional_intelligence.description}
                    decisiveProfessions={localText.emotional_intelligence.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.empathy.imageSrc}
                    title={localText.empathy.title}
                    color={localText.empathy.color}
                    note={resultToShow?.empathy || 0}
                    description={localText.empathy.description}
                    decisiveProfessions={localText.empathy.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.communication.imageSrc}
                    title={localText.communication.title}
                    color={localText.communication.color}
                    note={resultToShow?.communication || 0}
                    description={localText.communication.description}
                    decisiveProfessions={localText.communication.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.time_management.imageSrc}
                    title={localText.time_management.title}
                    color={localText.time_management.color}
                    note={resultToShow?.time_management || 0}
                    description={localText.time_management.description}
                    decisiveProfessions={localText.time_management.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.stress_management.imageSrc}
                    title={localText.stress_management.title}
                    color={localText.stress_management.color}
                    note={resultToShow?.stress_management || 0}
                    description={localText.stress_management.description}
                    decisiveProfessions={localText.stress_management.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.creativity.imageSrc}
                    title={localText.creativity.title}
                    color={localText.creativity.color}
                    note={resultToShow?.creativity || 0}
                    description={localText.creativity.description}
                    decisiveProfessions={localText.creativity.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.entrepreneurial_spirit.imageSrc}
                    title={localText.entrepreneurial_spirit.title}
                    color={localText.entrepreneurial_spirit.color}
                    note={resultToShow?.entrepreneurial_spirit || 0}
                    description={localText.entrepreneurial_spirit.description}
                    decisiveProfessions={localText.entrepreneurial_spirit.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.daring.imageSrc}
                    title={localText.daring.title}
                    color={localText.daring.color}
                    note={resultToShow?.daring || 0}
                    description={localText.daring.description}
                    decisiveProfessions={localText.daring.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.motivation.imageSrc}
                    title={localText.motivation.title}
                    color={localText.motivation.color}
                    note={resultToShow?.motivation || 0}
                    description={localText.motivation.description}
                    decisiveProfessions={localText.motivation.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.vision.imageSrc}
                    title={localText.vision.title}
                    color={localText.vision.color}
                    note={resultToShow?.vision || 0}
                    description={localText.vision.description}
                    decisiveProfessions={localText.vision.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.presence.imageSrc}
                    title={localText.presence.title}
                    color={localText.presence.color}
                    note={resultToShow?.presence || 0}
                    description={localText.presence.description}
                    decisiveProfessions={localText.presence.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.collective_sense.imageSrc}
                    title={localText.collective_sense.title}
                    color={localText.collective_sense.color}
                    note={resultToShow?.collective_sense || 0}
                    description={localText.collective_sense.description}
                    decisiveProfessions={localText.collective_sense.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.curiousity.imageSrc}
                    title={localText.curiousity.title}
                    color={localText.curiousity.color}
                    note={resultToShow?.curiousity || 0}
                    description={localText.curiousity.description}
                    decisiveProfessions={localText.curiousity.decisiveProfessions}
                />
                <Details
                    imageSrc={localText.critical_mind.imageSrc}
                    title={localText.critical_mind.title}
                    color={localText.critical_mind.color}
                    note={resultToShow?.critical_mind || 0}
                    description={localText.critical_mind.description}
                    decisiveProfessions={localText.critical_mind.decisiveProfessions}
                />
            </div>

            <Explication view={isMobile} />

        </>

    );
};

export default ViewResume;
