/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
// eslint-disable-next-line simple-import-sort/imports
import { CircularProgress, Grid, Typography, useMediaQuery, Theme } from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useResponseUser, useResultsClient, useAlert } from '@orientaction/hooks';
import localText from '../../../components/UserResult/WithResult/locale.json';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import { NoResult, Layout, HistoryMajorTest, CardHarmony } from '@orientaction/components';
import { MarginContainer } from '@orientaction/commons';
import { useStyles } from './style';
import BtnCanceled from '../BtnCanceled';
import FirstView from './FirstView';
import HeaderHarmony from './HeaderHarmony';
import CardGoFar from './CardGoFar';
import { take } from 'lodash';
import { dataHarmony } from '@orientaction/utils';
import ContactForm from '../ContactForm';

Chart.register(ChartDataLabels);

const ResultatHarmony = () => {
  const classes = useStyles();

  const { id: testId }: any = useParams();

  const { results, loading, resultToShow, getResults, company_user_role } = useResultsClient(
    'email@test.com',
    testId,
  );

  const [loadPage, setLoadPage] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    getResults(testId);
  }, [testId]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [sizeCard, setSizeCard] = useState(isMobile ? 3 : 16);

  const seeMore = () => {
    if (sizeCard === 16) {
      setSizeCard(3);
    }
    if (sizeCard === 3) {
      setSizeCard(16);
    }
  };

  useEffect(() => {
    setSizeCard(isMobile ? 3 : 16);
  }, [isMobile]);

  const openContactModal = () => {
    setOpenContactForm((prev: boolean) => !prev);
  };

  return (
    <Layout isaddBackground={true} noPadding={true} isClassic={true} footer={true}>
      <HeaderHarmony />
      {loading || loadPage ? (
        <div className={classes.bodycenter}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          {results.length === 0 ? (
            <NoResult />
          ) : (
            <>
              <div className={classes.btnHistory}>
                <HistoryMajorTest
                  testResults={results || []}
                  idTest={+testId}
                  toRedirect="/resultat-harmony"
                />
              </div>

              <div className={classes.root}>
                <BtnCanceled id={company_user_role} isBack={true} />

                {/* <MarginContainer mormal={true}>
                  <h1 className={classes.resultTitle}>
                    Vos résultats - Test Harmony 16 soft skills{' '}
                  </h1>
                </MarginContainer> */}

                <div className={classes.grid}>
                  <div className={classes.first}>
                    <FirstView resultToShow={resultToShow} />
                  </div>
                </div>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={classes.titleText}
                  id="softskills"
                >
                  EN SAVOIR + SUR LES SOFT SKILLS
                </Typography>
                <Grid container={true} style={{ marginTop: 50, marginBottom: 35 }} spacing={3}>
                  {take(dataHarmony(resultToShow), sizeCard)?.map((res: any) => (
                    <Grid item={true} xs={12} md={3}>
                      <CardHarmony harmony={res} />
                    </Grid>
                  ))}
                </Grid>
                {isMobile && (
                  <div style={{ width: '100%' }}>
                    <Typography
                      variant="h2"
                      color="textPrimary"
                      onClick={seeMore}
                      style={{
                        fontSize: 16,
                        textDecoration: 'underline',
                        textUnderlineOffset: 4,
                        textAlign: 'center',
                        color: 'white',
                        fontWeight: 400,
                        marginBottom: 80,
                        textTransform: 'none',
                      }}
                      id="softskills"
                    >
                      Voir {sizeCard === 3 ? '+' : 'moins'} de soft skills
                    </Typography>
                  </div>
                )}
                <Grid
                  container={true}
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginBottom: 50 }}
                >
                  <Grid item={true} md={10}>
                    <CardGoFar handleContactClick={openContactModal} />
                  </Grid>
                </Grid>
              </div>
              <ContactForm
                test={{ id: 5, label: 'Harmony®, le test des soft skills' }}
                open={openContactForm}
                handleClose={openContactModal}
              />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default withWidth()(ResultatHarmony);
