export const listForm = [
    {
        name: 'yourNeed1',
        title: 'Votre besoin de :'
    },
    {
        name: 'explication1',
        title: 'Expliquez'
    },
    {
        name: 'yourNeed2',
        title: 'Votre besoin de :'
    },
    {
        name: 'explication2',
        title: 'Expliquez'
    },
    {
        name: 'yourNeed3',
        title: 'Votre besoin de :'
    },
    {
        name: 'explication3',
        title: 'Expliquez'
    },
]