const INITIAL_STATE: any = {
  rotate: { deg: 0 },
  loading: false,
  hasCompass: true,
  meaningTestLastResult: {},
  needsTestLastResult: {},
  needsProTestLastResult: {},
  preferenceTestLastResult: {},
  harmonyTestLastResult: {},
  majorTestsAccess: [],
};

const appCompass = (state = INITIAL_STATE, action: any): any => {
  switch (action.type) {
    case 'ROTATE': {
      return {
        ...state,
        rotate: action.payload,
      };
    }
    case 'LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'HASCOMPASS': {
      return {
        ...state,
        hasCompass: action.payload,
      };
    }
    case 'MEANINGTESTLASTRESULT': {
      return {
        ...state,
        meaningTestLastResult: action.payload,
      };
    }
    case 'NEEDSTESTLASTRESULT': {
      return {
        ...state,
        needsTestLastResult: action.payload,
      };
    }
    case 'NEEDSPROTESTLASTRESULT': {
      return {
        ...state,
        needsProTestLastResult: action.payload,
      };
    }
    case 'PREFERENCETESTLASTRESULT': {
      return {
        ...state,
        preferenceTestLastResult: action.payload,
      };
    }
    case 'HARMONYTESTLASTRESULT': {
      return {
        ...state,
        harmonyTestLastResult: action.payload,
      };
    }
    case 'MAJORTESTSACCESS': {
      return {
        ...state,
        majorTestsAccess: action.payload,
      };
    }
    default:
      return state;
  }
};

export default appCompass;
