/* eslint-disable no-use-before-define */
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useState } from 'react';

type Props = {
  name: string;
  radios: RadioValue[];
  onChange: (value: string) => void;
  defaultValue?: string;
  className?: string;
  withDivider?: boolean;
};

interface RadioValue {
  label: string;
  value: string;
  disabled?: boolean;
}

const MyRadio = withStyles({
  root: {
    display: 'none',
    '& + span': {
      padding: '10px 17px',
      border: '1px solid #E3E8F0',
      fontFamily: 'ITC Avant Garde Gothic Std Medium',
      letterSpacing: 0,
      borderRadius: 3,
      color: '#9C9DB7',
      background: '#fff',
    },
    '& + span:hover': {
      color: '#000A8C',
      background: '#000A8C26',
      border: '1px solid #000A8C',
    },
    margin: 0,
  },
  checked: {
    '& + span': {
      color: '#000A8C',
      background: '#000A8C26',
      border: '1px solid #000A8C',
    },
  },
})((props: RadioProps) => <Radio color="default" {...props} />);

const RadioGrp: FC<Props> = ({
  className = '',
  defaultValue,
  name,
  radios = [],
  onChange,
  withDivider = false,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };
  return (
    <RadioGroup
      className={clsx(classes.radioGrp, className)}
      aria-label={name}
      name={name}
      value={defaultValue}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}>
      {radios.map((r, i) => (
        <div className={classes.radioItemContainer} key={Math.random()}>
          {withDivider && i !== 0 && <Divider orientation="vertical" className={classes.divider} />}
          <FormControlLabel
            key={r.value}
            className={classes.controlLabel}
            value={r.value}
            control={<MyRadio />}
            label={r.label}
          />
        </div>
      ))}
    </RadioGroup>
  );
};

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGrp: {
      display: 'flex !important',
      'flex-direction': 'row !important',
      width: 'fit-content',
      borderRadius: 4,
    },
    controlLabel: {
      margin: '0 !important',
    },
    radioItemContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      marginRight: 20,
    },
    divider: {
      color: 'black',
      '&.MuiDivider-vertical': {
        height: '50%',
      },
    },
  })
);

export default RadioGrp;
