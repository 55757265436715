export const fakeUser = {
  id: 42369,
  username: 'John Doe',
  email: 'john.doe@orientaction.com',
  confirmed: true,
  firstname: 'John',
  lastname: 'Doe',
  isActivated: true,
  dayOfBirth: '1985-05-22',
  description: 'test',
  photoUri: null,
  Direction: 46390,
  manager_3: 46391,
  manager_2: 46392,
  manager_1: 46430,
  rattachement: true,
  hasMultipleRole: null,
  isCompassActivated: true,
  role: {
    id: 1,
    name: 'Authenticated',
  },
  company: {
    id: 1,
    name: 'ORIENTACTION',
    companyUserRoleAlias: {
      manager_1: 'Consultant',
      manager_2: 'Responsable zone',
      manager_3: 'Responsable région ',
    },
    isActivated: true,
  },
  company_user_role: {
    id: 5,
    name: 'Bénéficiaires',
    hierarchy_level: 0,
  },
};
