import { Typography } from '@material-ui/core';
import style from '../../../commonStyle';

const TheoristSuggestion = () => {
  const classes = style();
  return (
    <div className={classes.suggestionContainer}>
      <Typography className={classes.title} component="h1">
        😍 TA VOIE IDÉALE : LICENCE UNIVERSITAIRE
      </Typography>
      <div className={classes.suggestionContent}>
        <Typography className={classes.suggestionSubtitle} component="p">
          L’Université est ton environnement idéal car on y étudie une seule matière de façon
          spécialisée.
        </Typography>
        <Typography className={classes.suggestionSubtitle} component="p">
          De plus le contexte t&apos;offrira une grande autonomie dans ton travail et dans ton
          organisation ce qui correspond au mieux à ta personnalité.
        </Typography>
        <ul className={classes.suggestionList}>
          <li>
            <Typography className={classes.suggestionListItem} component="p">
              L’Université est le lieu de formation des expert(e)s. Les enseignements sont
              théoriques et portent plutôt sur des concepts que sur des applications pratiques. Tu
              apprécieras ce type d’enseignements complexes qui te permettront d’acquérir des clefs
              pour comprendre le monde.
            </Typography>
          </li>
        </ul>
        <Typography className={classes.suggestionQuote} component="p">
          « La théorie, c&apos;est quand on sait tout et que rien ne fonctionne. La pratique,
          c&apos;est quand tout fonctionne et que personne ne sait pourquoi. Ici, nous avons réuni
          théorie et pratique : Rien ne fonctionne... et personne ne sait pourquoi ! »
        </Typography>
      </div>
      <Typography className={classes.suggestionQuoteAuthor} component="p">
        Albert Einstein
      </Typography>
    </div>
  );
};

export default TheoristSuggestion;
