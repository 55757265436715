/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import OaLabel from '@orientaction/commons/OaLabel';
import RichEditor from '@orientaction/commons/RichEditor';
import StyledDropzone from '@orientaction/commons/StyledDropzone';
import { AdminTestContext } from '@orientaction/Context';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import style from './style';

const placeholder = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span style={{ fontSize: 26, margin: 8 }}>+</span>
      <span>Importer une image</span>
      <span>
        <i>1920*1360 pixels</i>
      </span>
    </div>
  );
};

const VariableForm = () => {
  const [variableName, setVariableName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [codeColor, setCodeColor] = useState('');
  const [errors, setErrors] = useState({ variableName: false, title: false, minInterval: false, maxInterval: false });
  const [minInterval, setMinInterval] = useState('');
  const [maxInterval, setMaxInterval] = useState('');

  const quillRef: any = useRef();

  const { test, updateTest } = useContext<any>(AdminTestContext);

  const classes = style();

  const initializeState = useCallback(() => {
    const { variableToEdit } = test;
    setVariableName(variableToEdit.name);
    setTitle(variableToEdit.title);
    setDescription(variableToEdit.description);
    setImage(variableToEdit.image);
    setCodeColor(variableToEdit?.codeColor);
    setMinInterval(variableToEdit?.minInterval?.toString());
    setMaxInterval(variableToEdit?.maxInterval?.toString());
  }, []);

  const handleNameChange = (e: any) => {
    setVariableName(e.target.value);
    if (errors.variableName) {
      setErrors((state: any) => ({ ...state, variableName: false }));
    }
  };

  const handleTitleChange = (value: string) => {
    setTitle(value);
    if (errors.title) {
      setErrors((state: any) => ({ ...state, title: false }));
    }
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  const handleCodeColorChange = (e: any) => {
    setCodeColor(e.target.value);
  };

  const resetLocalState = () => {
    setVariableName('');
    setTitle('');
    setDescription('');
    setImage('');
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!variableName?.trim().length) {
      setErrors((state: any) => ({ ...state, variableName: true }));
      return;
    }

    if (!title?.trim().length) {
      setErrors((state: any) => ({ ...state, title: true }));
      return;
    }

    if (!variableName?.trim().length) {
      setErrors((state: any) => ({ ...state, variableName: true }));
      return;
    }

    if (!title?.trim().length) {
      setErrors((state: any) => ({ ...state, title: true }));
      return;
    }

    if (isInterval && !minInterval?.trim().length) {
      setErrors((state: any) => ({ ...state, minInterval: true }));
      return;
    }

    if (isInterval && !maxInterval?.trim().length) {
      setErrors((state: any) => ({ ...state, maxInterval: true }));
      return;
    }

    if (test.variableToEdit) {
      const { index } = test.variableToEdit;
      const { variables } = test;
      const firstSlice = variables.slice(0, index);
      const secondSlice = variables.slice(index + 1);
      updateTest({
        variables: [
          ...firstSlice,
          {
            id: test.variableToEdit.id,
            name: variableName,
            title,
            description,
            image,
            isNew: false,
            codeColor,
            minInterval: parseInt(minInterval, 10),
            maxInterval: parseInt(maxInterval, 10),
          },
          ...secondSlice,
        ],
        variableDrawerOpen: false,
        variableToEdit: undefined,
      });
    } else {
      const lastVariable = test.variables[test.variables.length - 1];
      const variables = [
        ...test.variables,
        {
          id: lastVariable ? +lastVariable.id + 1 : 1,
          name: variableName,
          title,
          description,
          image,
          isNew: true,
          codeColor,
          minInterval: parseInt(minInterval, 10),
          maxInterval: parseInt(maxInterval, 10),
        },
      ];
      updateTest({
        variables,
        variableDrawerOpen: false,
      });
    }
    resetLocalState();
  };

  const handleFileUrlChange = (url: string) => {
    setImage(url);
  };

  const handleDeleteFile = () => {
    setImage('');
  };

  useEffect(() => {
    if (test.variableToEdit) {
      initializeState();
    }
  }, [initializeState, test.variableToEdit]);

  const variableNumber = test.variableToEdit
    ? test?.variableToEdit?.index + 1
    : test?.variables?.length + 1;

  const { diagram } = test.test;
  const isSimpleDiagram = diagram?.id === 1;
  const isFullScore = diagram?.id === 5;
  const isInterval = diagram?.id === 4;

  const handleMinIntervalValueChange = (e: any) => {
    if ((parseInt(e.target.value, 10) >= 0 || e.target.value === '')) {
      setMinInterval(e.target.value === '' ? '' : parseInt(e.target.value, 10).toString());
      if (errors.minInterval) {
        setErrors({ ...errors, minInterval: false });
      }
    }
  };

  const handleMaxIntervalValueChange = (e: any) => {
    if ((parseInt(e.target.value, 10) >= 0 || e.target.value === '')) {
      setMaxInterval(e.target.value === '' ? '' : parseInt(e.target.value, 10).toString());
      if (errors.minInterval) {
        setErrors({ ...errors, maxInterval: false });
      }
    }
  };

  return (
    <form className={classes.flexContainer} onSubmit={handleSubmit}>
      <Typography className={classes.variableFormTitle} component="h3">
        Restitution N°{variableNumber}
      </Typography>
      <div className={classNames(classes.marginTop, classes.fullWidth)}>
        <OaLabel>Nom de la restitution</OaLabel>
        <TextField
          id="test-variable-name"
          variant="outlined"
          className={classNames(classes.formInput, errors.variableName && classes.error)}
          name="variableName"
          value={variableName}
          onChange={handleNameChange}
        />
      </div>
      <div className={classNames(classes.marginTop, classes.fullWidth)}>
        <OaLabel>Titre qui apparaitra lors du résultat</OaLabel>
        <RichEditor
          value={title}
          handleChange={handleTitleChange}
          placeholder="Titre que verront les bénéficiaires sur la page de résultats"
          hasError={errors.title}
        />
      </div>
      <div className={classNames(classes.marginTop, classes.fullWidth)}>
        <OaLabel>Description de la restitution</OaLabel>
        <RichEditor
          ref={quillRef}
          value={description}
          handleChange={handleDescriptionChange}
          placeholder="Texte qui sera affiché sur la page de résultats"
          isMultiline={true}
        />
      </div>
      {isFullScore && (
        <div className={classNames(classes.marginTop, classes.fullWidth)}>
          <OaLabel>
            Couleur personnalisée <span>(en code hexadécimal, ex : #FF5733)</span>
          </OaLabel>
          <TextField
            id="test-code-color"
            variant="outlined"
            className={classNames(classes.formInput)}
            name="codeColor"
            value={codeColor}
            onChange={handleCodeColorChange}
            placeholder="Ex : #FF5733"
          />
        </div>
      )}
      {isInterval && (
        <div className={classNames(classes.marginTop, classes.fullWidth)}>
          <OaLabel>
            Intervalle de déclenchement de la variable à afficher en restitution
          </OaLabel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10, textWrap: 'nowrap' }}>Score compris entre</span>
            <TextField
              id="test-min-interval"
              variant="outlined"
              className={classNames(classes.formInput, errors.minInterval && classes.error)}
              name="minInterval"
              value={minInterval}
              onChange={handleMinIntervalValueChange}
              placeholder="Min."
            />
            <span style={{ marginRight: 10, marginLeft: 10 }}>et</span>
            <TextField
              id="test-max-interval"
              variant="outlined"
              className={classNames(classes.formInput, errors.maxInterval && classes.error)}
              name="maxInterval"
              value={maxInterval}
              onChange={handleMaxIntervalValueChange}
              placeholder="Max."
            />
          </div>
        </div>
      )}
      {!isSimpleDiagram && (
        <div className={classNames(classes.marginTop, classes.fullWidth)}>
          <OaLabel>{`Image qui s'affichera sur la page du résultat du test`}</OaLabel>
          <StyledDropzone
            fileUrl={image}
            handleFileUrlChange={handleFileUrlChange}
            placeholder={placeholder}
            sectionTop={true}
            error={!!test?.manageError?.image}
            pathPrefix="testVariableImages"
          />
          <div onClick={handleDeleteFile} className={classes.replaceTextContainer}>
            Remplacer
          </div>
        </div>
      )}
      <div className={classes.marginTop}>
        <Button type="submit" className={classes.btnPrimary}>
          <span className={classes.textHeader}>Sauvegarder</span>
        </Button>
      </div>
    </form>
  );
};

export default VariableForm;
