import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#0E1247',
            fontSize: '13px',
            fontFamily: 'ITC Avant Garde Gothic Std Bold',
            letterSpacing: 0,
        },
        formInput: {
            width: '95%',
            border: '1px solid #BCBCBC',
            borderRadius: '0.5vh',
            fontWeight: 'bold'
        },

        textDelete: {
            fontSize: '15px',
        },
        require: {
            fontSize: '13px',
            margin: '2vh 0',
            color: '#FF4E4E'
        },
        delete: {
            width: '150px',
            border: '1px solid #BCBCBC',
            color: '#FF4E4E',
            padding: '0.5vh 0',
            margin: '0 auto',
            borderRadius: '7px',
            cursor: 'pointer',
            fontWeight: 300
        },
        iconDragDrop: {
            color: '#9799B1',
            cursor: 'move'
        },
        iconDelete: {
            position: 'relative',
            float: 'right',
            color: '#FF4E4E',
            top: '-5px'
        }
    })
);