import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line no-unused-vars
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customtable: {
      overflow: 'auto',
      position: 'relative',
      paddingBottom: '100px',
    },
    tableContainer: {
      width: 'auto',
      '& td.disabled, & td.disabled a': {
        color: '#B3BAC5 !important',
      },
      flex: '1',
    },
    table: {
      minWidth: 650,
    },
    tableLink: {
      color: '#030F85',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    tableFooter: {
      padding: '20px 0',
    },
    tableCell: {
      '&.MuiTableCell-root': {
        fontSize: 15,
        letterSpacing: 0,
        color: '#0E1247',
        padding: 21,
        //   whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
          padding: 10,
          width: 150,
        },
      },
    },
    tableCellEmpty: {
      '&.MuiTableCell-root': {
        padding: 32,
        minWidth: '99vw !important',
      },
    },
    bodycenter: {
      margin: 'auto',
      width: 20,
      height: 20,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    noResultText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      color: 'white',
    },
    BodyMissingTextContainer: {
      textAlign: 'center',
      padding: '15px',
    },
    TableRow: {
      cursor: 'pointer',
      background: '#fff',
    },
    relative: {
      position: 'relative',
    },
    cursorDefault: {
      cursor: 'initial',
    },
    isCustomer: {
      backgroundColor: 'rgba(255,255,255,0.2)',
      '&.MuiTableCell-head': {
        color: 'white',
      },
    },
  })
);
