import { getFromLS, isAdmin, isRootUser } from '@orientaction/utils';
import { FC } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

const notAuthenticatedPaths = ['/', '/signup', '/forgot-password', '/reset-password'];

const PrivateRoute = ({
  component: Component,
  isProtected = false,
  isRootOnly = false,
  ...rest
}: any) => {
  const { pathname } = useLocation();

  const user = getFromLS('userInfos') ? JSON.parse(getFromLS('userInfos') || '') : undefined;
  if (user?.isTemporaryPassword && !notAuthenticatedPaths.includes(pathname)) {
    return (
      <Redirect
        to={{
          pathname: '/user/modify-password',
        }}
      />
    );
  }
  if (isRootOnly) {
    const isRoot = user ? isRootUser(user) : false;
    return (
      <Route
        {...rest}
        render={props =>
          isRoot ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }

  if (isProtected) {
    const isUserAdmin = user ? isAdmin(user) : false;
    return (
      <Route
        {...rest}
        render={props =>
          isUserAdmin ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;
