import HorizontalDiagramContainer from '@orientaction/components/Beneficiary/Result/Horizontal';
import PieDiagramContainer from '@orientaction/components/Beneficiary/Result/Pie';
import { LayoutResultat } from '@orientaction/layouts';
import style from './style';

const Result = () => {
  const classes = style();
  return (
    <LayoutResultat isGradient={false} isNew2={true}>
      Result
      <div className={classes.resultPaperDiagramContainer}>
        {/* <HorizontalDiagramContainer /> */}
        {/* <PieDiagramContainer /> */}
        Test
      </div>
    </LayoutResultat>
  );
};

export default Result;
